import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { subscriberStatus } from 'src/global.variables';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-app-users',
  templateUrl: './app-users.component.html',
  styleUrls: ['./app-users.component.scss'],
})
export class AppUsersComponent implements OnInit {
  pushData: any;
  dialogref: any;

  sendNotifData: any;
  message: any;
  title: any;
  allDevices: boolean = false;
  showTypeAhead: boolean = true;
  showSearchBox: boolean = false;
  globalFilter: any;

  previous: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;
  pushNotificationTable = {
    columns: [
      {
        header: 'Name',
        nestedValue: (row: any) => {
          return row?.user?.full_name;
        },
      },
      { header: 'Created Time', name: 'created_at', datetimeObj: true },
      {
        header: 'Device',
        nestedValue: (row: any) => {
          return `${row?.device?.device_os} (${row?.device?.browser})`;
        },
      },
    ],
  };
  appUserTableDetail = [
    {
      name: 'full_name',
      header: 'Name',
      sortKey: 'full_name',
      searchKey: 'full_name',
    },
    {
      name: 'email',
      header: 'Email',
      sortKey: 'email',
      searchKey: 'email',
    },
    {
      name: 'subscriber_name',
      header: 'Subscriber',
      sortKey: 'subscriber_name',
      searchKey: 'subscriber_name',
    },
    {
      header: 'Sub Status',
      sortKey: 'subscriber_status',
      searchKey: 'subscriber_status',
      name: 'subscriber_status',
      nestedValue: (row: any) => {
        const statusDict: any = subscriberStatus.statusValues;

        if (
          statusDict.hasOwnProperty(row?.subscriber_status) &&
          row?.subscriber_status
        ) {
          return statusDict[row.subscriber_status];
        }

        return '';
      },
      conditionalColumnDataStyle: (row: any) => {
        const statusStyleDict: any = subscriberStatus.statusStyles;
        if (
          statusStyleDict.hasOwnProperty(row?.subscriber_status) &&
          row?.subscriber_status
        ) {
          return statusStyleDict[row.subscriber_status];
        }

        return {};
      },
    },
    {
      name: 'last_login',
      header: 'Last Login',
      datetimeObj: true,
      dateRangeKey: 'last_login',
      sortKey: 'last_login',
    },
    {
      name: 'last_event_time',
      header: 'Last Activity Time',
      datetimeObj: true,
      dateRangeKey: 'last_event_time',
      sortKey: 'last_event_time',
    },
    {
      name: 'last_event',
      header: 'Last Activity',
      sortKey: 'last_event',
      searchKey: 'last_event',
    },
    {
      name: 'user_status',
      header: 'User Status',
      sortKey: 'user_status',
      searchKey: 'user_status',
    },
    {
      name: 'is_registered',
      header: 'Signed Up',
      sortKey: 'is_registered',
    },
    {
      name: 'user_groups',
      header: 'Groups',
      nestedValue: (row: any) => {
        return row?.user_groups?.join('\n');
      },
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },
    {
      name: 'time_zone',
      header: 'Time Zone',
    },
  ];
  lockedUserTableDetail = [
    {
      name: 'full_name',
      header: 'Name',
      sortKey: 'full_name',
      searchKey: 'full_name',
    },
    {
      name: 'email',
      header: 'Email',
      sortKey: 'email',
      searchKey: 'email',
    },
    {
      name: 'subscriber_name',
      header: 'Subscriber',
      sortKey: 'subscriber_name',
      searchKey: 'subscriber_name',
    },
    {
      name: 'last_login',
      header: 'Last Login',
      datetimeObj: true,
      dateRangeKey: 'last_login',
      sortKey: 'last_login',
    },
    {
      name: 'last_event_time',
      header: 'Last Activity Time',
      datetimeObj: true,
      dateRangeKey: 'last_event_time',
      sortKey: 'last_event_time',
    },
    {
      name: 'last_event',
      header: 'Last Activity',
      sortKey: 'last_event',
      searchKey: 'last_event',
    },

    {
      name: 'subscriber_auth_failure_count',
      header: 'Auth Failure Count',
    },
    {
      name: 'user_auth_failure_count',
      header: 'User Auth Failure Count',
    },
    {
      name: 'last_auth_try',
      header: 'Last Auth Failed Time',
      sortKey: 'last_auth_try',
      searchKey: 'last_auth_try',
      datetimeObj: true,
    },
  ];

  //app user variables
  appUserData: any = [];
  appUserDataBakup: any = [];
  lockedUserData: any;
  appUserFilter: string = 'all';
  appUserFilterBtnDict: any = {
    all: 'Active Users',
    active: 'Inactive Users',
    inactive: 'All Users',
  };

  constructor(
    private adminService: AdminService,
    private dialog: NbDialogService,
    private toasterService: NbToastrService,
    private spinnerService: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getDevices();
    this.getAppUsers();
    this.getLockedUsers();
  }
  getDevices() {
    let params: any = {};
    if (this.globalFilter) {
      params.full_name = this.globalFilter;
    }
    if (this.rows) {
      params['page_size'] = this.rows;
    }
    if (this.previous) {
      params['page'] = this.pageNum;
    }

    this.adminService.getPushDevices(params).subscribe((res: any) => {
      this.pushData = res;
      this.totalRows = res?.count;
      this.totalPages = Math.ceil(this.totalRows / this.rows);
    });
  }

  sendNotification(item: any, template: TemplateRef<any>) {
    this.sendNotifData = item;

    this.dialogref = this.dialog.open(template, {
      context: {},
    });
  }
  sendMessage() {
    let body: any = {
      user_id: this.sendNotifData?.user?.id,
      title: this.title,
      message: this.message,
    };
    if (!this.allDevices) {
      body.device_id = this.sendNotifData?.device?.id;
    }
    this.adminService.sendNotification(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.toasterService.success('', 'Notification message has been sent');
      }
    });
  }
  togglePrimary(event: any) {
    this.allDevices = event;
  }

  onFilterApply(event: any) {
    this.showSearchBox = true;
    this.pageNum = 1;
    if (event?.target?.value?.length === 0) {
      this.showTypeAhead = true;
      this.globalFilter = '';
      this.getDevices();
    }
    if (event?.target?.value && event?.target?.value?.length >= 3) {
      this.getDevices();
      this.showTypeAhead = false;
    }
  }

  onClearFilter(filterType?: string) {
    this.showSearchBox = false;
    this.globalFilter = '';
    this.showTypeAhead = true;

    this.getDevices();
  }

  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getDevices();
  }

  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getDevices();
  }

  getAppUsers() {
    this.adminService.getAppusers().subscribe((res: any) => {
      this.appUserData = res?.data;
      this.appUserDataBakup = JSON.parse(JSON.stringify(res?.data || []));
    });
  }

  filterUsers() {
    const backupData = JSON.parse(JSON.stringify(this.appUserDataBakup));
    switch (this.appUserFilter) {
      case 'all':
        this.appUserFilter = 'active';
        this.appUserData = backupData?.filter(
          (item: any) => item?.subscriber_status?.toLowerCase() !== 'inactive'
        );
        break;
      case 'active':
        this.appUserFilter = 'inactive';
        this.appUserData = backupData?.filter(
          (item: any) => item?.subscriber_status?.toLowerCase() === 'inactive'
        );
        break;
      case 'inactive':
        this.appUserFilter = 'all';
        this.appUserData = backupData;
        break;
    }
  }
  redirectUserHistory(event: any) {
    this.router.navigate([
      '/gtadmin/user-history',
      { uKey: String(event?.id), fullName: event?.full_name },
    ]);
  }
  getLockedUsers() {
    this.adminService.getAppusers({ locked_users: 1 }).subscribe((res: any) => {
      this.lockedUserData = res?.data;
    });
  }
  unlockUser(userData: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      context: {
        confirmation: true,
        title: 'Are you sure?',
        message:
          'This will unlock the user and will give the user access to login to the app.',
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.adminService
          .manageAppUser({ sub_user_id: userData?.id })
          .subscribe((res: any) => {
            if (res?.status === 'success') {
              this.toasterService.success('', 'Unlocked User Successfully');
              this.getDevices();
              this.getAppUsers();
              this.getLockedUsers();
            }
          });
      }
    });
  }
}
