<nb-card class="gt-p-1">
  <div class="flex-center">
    <nb-form-field class="sc-search-input-box">
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          nbInput
          #customerAutoInput
          fullWidth
          placeholder="Filter Beta Users"
          [(ngModel)]="globalFilter"
          (keyup)="onFilterApply($event)"
        />
        <label>Filter Beta Users</label>
      </div>
      <nb-icon
        nbSuffix
        class="ico-color"
        *ngIf="showTypeAhead === true"
        [icon]="'search-outline'"
        pack="eva"
        (click)="onFilterApply(true)"
      ></nb-icon>
      <nb-icon
        nbSuffix
        class="ico-color"
        *ngIf="showTypeAhead === false"
        [icon]="'close-outline'"
        pack="eva"
        (click)="onClearFilter()"
      ></nb-icon>
    </nb-form-field>
    <div class="sc-add-btn">
      <button
        class="sc-mt-0"
        style="width: 10rem"
        size="giant"
        nbButton
        (click)="openTemplate(addBetaSubscriberBox)"
      >
        + Invite Beta User
      </button>
    </div>
    <div class="sc-add-btn">
      <button
        class="sc-mt-0"
        style="width: 10rem"
        size="giant"
        nbButton
        (click)="openTemplate(betaFileUploadTemplate)"
      >
        + Bulk Import
      </button>
    </div>
    <div class="sc-add-btn">
      <button
        class="sc-mt-0"
        style="width: 10rem"
        size="giant"
        nbButton
        (click)="
          bulkExpire = true;
          selectedSubscribers = [];
          expiryTime = null;
          changeReason = null;
          getBetaSubscribers(betaUserTemplate)
        "
      >
        Bulk Expire
      </button>
    </div>
    <div class="sc-add-btn">
      <button
        class="sc-mt-0"
        style="width: 10rem"
        size="giant"
        nbButton
        (click)="
          bulkExpire = false;
          selectedSubscribers = [];
          expiryTime = null;
          changeReason = null;
          getInvitedSubscribers(betaUserTemplate)
        "
      >
        Bulk Turn ON
      </button>
    </div>
    <div class="sc-add-btn">
      <nb-select
        style="width: 10rem"
        size="giant"
        fullWidth
        placeholder="Beta Status"
        (selectedChange)="selectBetaType($event)"
      >
        <nb-option
          *ngFor="
            let type of [
              { id: 0, value: 'All' },
              { id: 1, value: 'Beta Active' },
              { id: 2, value: 'Beta Inactive' }
            ]
          "
          [value]="type.id"
          >{{ type.value }}</nb-option
        >
      </nb-select>
    </div>
  </div>

  <app-table
    [desktopTableData]="betaSubscriberTableData?.results"
    [desktopTableDetails]="tableColumns.columns"
    [tableActionItems]="tableColumns.actionColumn"
    (rowClick)="onRowClick($event)"
  >
  </app-table>
  <!-- Pagination section begin -->
  <div class="sc-mt-6">
    <app-pagination-control
      [pageSize]="rows"
      [pageNum]="pageNum"
      [previous]="previous"
      [totalRows]="totalRows"
      [totalPages]="totalPages"
      (onClickPagination)="onClickPagination($event)"
      (onChangePagination)="onChangePagination($event)"
    >
    </app-pagination-control>
  </div>

  <div class="sc-head4" class="sc-text-center" *ngIf="reportData?.results">
    Beta User History
  </div>

  <div *ngIf="reportData?.results">
    <app-table
      [desktopTableData]="reportData?.results"
      [desktopTableDetails]="betaHistory.columns"
      (rowClick)="onHistoryRowClick($event)"
    >
    </app-table>

    <!-- Pagination section begin -->
    <div class="sc-mt-6">
      <app-pagination-control
        [pageSize]="rowsHistory"
        [pageNum]="pageNumHistory"
        [previous]="previousHistory"
        [totalRows]="totalRowsHistory"
        [totalPages]="totalPagesHistory"
        (onClickPagination)="onClickPaginationHistory($event)"
        (onChangePagination)="onChangePaginationHistory($event)"
      >
      </app-pagination-control>
    </div>

    <ng-container *ngIf="selectedHistory">
      <div class="gt-event-details">
        <div *ngFor="let item of selectedHistory?.event_data | keyvalue">
          <div *ngIf="item?.value" class="row row-cols-2">
            <div class="col-4">
              <small>{{ item.key }}</small>
            </div>
            <div class="col-8">
              <strong
                ><small> {{ item.value }}</small>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</nb-card>

<ng-template #addBetaSubscriberBox let-bSRef="dialogRef">
  <nb-card class="dialog-box">
    <nb-card-header>
      Add Beta Subscriber
      <nb-icon
        (click)="bSRef.close()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <form [formGroup]="betaSubscriberForm" #f="ngForm" appFormEnterAction>
        <div class="form-floating">
          <!-- TODO: Add a email validation -->
          <input
            class="form-control"
            type="text"
            fullWidth
            nbInput
            id="email"
            formControlName="email"
            autocomplete="off"
            placeholder="Email"
            autocorrect="off"
            autocapitalize="off"
            [status]="
              betaSubscriberForm.controls['email'].dirty
                ? betaSubscriberForm.controls['email'].invalid
                  ? 'danger'
                  : 'primary'
                : ''
            "
            [attr.aria-invalid]="
              betaSubscriberForm.controls['email'].invalid &&
              betaSubscriberForm.controls['email'].touched
                ? true
                : null
            "
          />
          <label for="email">Email</label>
        </div>
        <div class="form-floating">
          <input
            class="form-control"
            nbInput
            fullWidth
            placeholder="Test User Expiry"
            [nbDatepicker]="dateTimePicker1"
            formControlName="test_user_validity"
          />
          <label>Test User Expiry</label>
          <nb-datepicker #dateTimePicker1></nb-datepicker>
        </div>

        <div class="row row-cols-2">
          <div class="col">
            <button
              type="button"
              fullWidth
              (click)="onClearForm()"
              nbButton
              size="giant"
              ghost
            >
              Clear
            </button>
          </div>
          <div class="col">
            <button
              fullWidth
              [disabled]="!betaSubscriberForm.valid"
              nbButton
              size="giant"
              (click)="createBetaSUbscriber(); bSRef.close()"
            >
              Invite
            </button>
          </div>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</ng-template>

<!-- dialog box for expiring multiple beta user status-->
<ng-template #betaUserTemplate let-data let-betaUserTemplate="dialogRef">
  <nb-card class="gt-p-small dialog-box">
    <nb-card-header>
      Are you sure to bulk {{ bulkExpire ? "expire" : "activate" }} beta
      accounts?
      <nb-icon
        (click)="
          betaUserTemplate.close();
          bulkExpire = true;
          selectedSubscribers = [];
          expiryTime = null;
          changeReason = null;
          boolSelectSubscribers = false
        "
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon
    ></nb-card-header>
    <nb-card-body>
      <div>
        {{
          boolSelectSubscribers
            ? selectedSubscribers?.length
            : betaSubscribers?.count
        }}
        beta accounts will be affected.

        <div class="sc-floating-right">
          <nb-toggle
            status="primary"
            toggle-width="1.25"
            toggle-height="0.25"
            (checkedChange)="manualSelect($event)"
            >{{
              boolSelectSubscribers ? "Select All" : "Manual select"
            }}</nb-toggle
          >
        </div>
      </div>
      <div *ngIf="boolSelectSubscribers">
        <nb-tag-list class="nb-tag-list">
          <nb-tag
            *ngFor="let group of selectedSubscribers"
            [text]="group.name"
            removable
            (remove)="removeSubscribers(group)"
          ></nb-tag>
        </nb-tag-list>
        <div class="form-floating">
          <input
            class="form-control"
            type="text"
            nbTagInput
            fullWidth
            placeholder="Select/Search  Beta Users"
            (input)="serachBetaSubscribers($event)"
            [nbAutocomplete]="autoGrpNgModel"
          />
          <label>Select/Search Beta Users</label>
        </div>
        <nb-autocomplete #autoGrpNgModel>
          <nb-option
            *ngFor="let subscriber of betaSubscribers.results"
            (click)="
              addSubscribers(subscriber?.id, subscriber?.subscriber_name)
            "
            >{{ subscriber?.subscriber_name }}
          </nb-option></nb-autocomplete
        >
      </div>
      <div *ngIf="!expiryTime"></div>

      <div *ngIf="expiryTime">
        The account beta fetures will be valid till
        {{ expiryTime | customdate : "d MMM y HH:mm" }}
      </div>
      <div class="col sc-mt-9">
        <textarea
          class="form-control"
          fullWidth
          rows="5"
          type="text"
          placeholder="Add a Reason..."
          [(ngModel)]="changeReason"
        ></textarea>
      </div>
      <div class="row row-cols-2 sc-mt-9">
        <div class="col">
          <div class="form-floating" *ngIf="!expiryTime">
            <input
              class="form-control"
              nbInput
              fullWidth
              placeholder="Test User Expiry"
              [nbDatepicker]="dateTimePicker2"
              [(ngModel)]="expiryTime"
            />
            <label>Test User Expiry</label>
            <nb-datepicker #dateTimePicker2></nb-datepicker>
          </div>
          <button
            fullWidth
            size="giant"
            ghost
            (click)="
              bulkExpire = true;
              selectedSubscribers = [];
              expiryTime = null;
              changeReason = null
            "
            nbButton
            class="sc-mt-0"
            *ngIf="expiryTime"
          >
            Clear
          </button>
        </div>
        <div class="col">
          <button
            fullWidth
            size="giant"
            nbButton
            class="sc-mt-0"
            (click)="bulkUpdateBeta(); betaUserTemplate.close()"
            [disabled]="!changeReason"
          >
            {{ bulkExpire ? "Expire" : "Activate" }}
          </button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<!-- dialog box for changing beta user status-->
<ng-template #singlebetaUserTemplate let-data let-betaUserTemplate="dialogRef">
  <nb-card class="gt-p-small dialog-box">
    <nb-card-header>
      Are you sure to
      {{
        betaUserData?.test_user
          ? singleExpiryTime
            ? "change Validity of "
            : "turn off Beta for"
          : "turn on Beta for"
      }}
      the {{ betaUserData?.subscriber_name }}?
      <nb-icon
        (click)="
          betaUserTemplate.close();
          singlechangeReason = null;
          singleExpiryTime = null
        "
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon
    ></nb-card-header>
    <nb-card-body>
      <div *ngIf="!singleExpiryTime">
        {{
          betaUserData?.test_user
            ? "This action will turn of the accounts beta features now"
            : "This action will give the account beta features from now"
        }}
      </div>

      <div *ngIf="singleExpiryTime">
        The account beta fetures will be valid till
        {{ singleExpiryTime | customdate : "d MMM y HH:mm" }}
      </div>
      <div class="col sc-mt-9">
        <textarea
          class="form-control"
          fullWidth
          rows="5"
          type="text"
          placeholder="Add a Reason..."
          [(ngModel)]="singlechangeReason"
        ></textarea>
      </div>
      <div class="row row-cols-2 sc-mt-9">
        <div class="col">
          <div class="form-floating" *ngIf="!singleExpiryTime">
            <input
              class="form-control"
              nbInput
              fullWidth
              placeholder="Test User Expiry"
              [nbDatepicker]="dateTimePicker2"
              [(ngModel)]="singleExpiryTime"
            />
            <label>Test User Expiry</label>
            <nb-datepicker #dateTimePicker2></nb-datepicker>
          </div>
          <button
            fullWidth
            size="giant"
            ghost
            (click)="singlechangeReason = null; singleExpiryTime = null"
            nbButton
            class="sc-mt-0"
            *ngIf="singleExpiryTime"
          >
            Clear
          </button>
        </div>
        <div class="col">
          <button
            fullWidth
            size="giant"
            nbButton
            class="sc-mt-0"
            (click)="changeBetaFeat(); betaUserTemplate.close()"
            [disabled]="!singlechangeReason"
          >
            {{
              betaUserData?.test_user
                ? singleExpiryTime
                  ? "Change Validity"
                  : "Turn off Beta"
                : "Turn On Beta"
            }}
          </button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
<!-- dialog box for changing beta user status-->
<ng-template
  #betaFileUploadTemplate
  let-data
  let-betaUploadTemplate="dialogRef"
>
  <nb-card class="dialog-box">
    <nb-card-header
      >Upload File

      <nb-icon
        (click)="betaUploadTemplate.close()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <em>
        You must upload file which must contain emails in the first column
      </em>
      <div class="row row-cols-2 sc-mt-9">
        <div class="col">
          <div class="form-floating">
            <input
              class="form-control"
              nbInput
              fullWidth
              placeholder="Test User Expiry"
              [nbDatepicker]="dateTimePicker4"
              [(ngModel)]="bulkImportExpiry"
            />
            <label>Test User Expiry</label>
            <nb-datepicker #dateTimePicker4></nb-datepicker>
          </div>
        </div>
        <div class="col">
          <nb-toggle
            size="giant"
            toggle-width="1.25"
            toggle-height="0.25"
            (checkedChange)="reInvitation($event)"
            >{{
              forcedReInvite ? "Turn off Re invite" : "Turn on Re Invite"
            }}</nb-toggle
          >
        </div>
      </div>

      <div class="col sc-mt-9" *ngIf="forcedReInvite">
        <textarea
          class="form-control"
          fullWidth
          rows="5"
          type="text"
          placeholder="Add a Reason..."
          [(ngModel)]="bulkImportReason"
        ></textarea>
      </div>
      <div class="col sc-mt-9">
        <input type="file" (change)="handleFileInput($event)" />
      </div>

      <div class="flex-center">
        <button
          nbButton
          size="giant"
          (click)="onFileSubmit(); betaUploadTemplate.close()"
          [disabled]="!uploadedFile"
        >
          Import File
        </button>
      </div>
    </nb-card-body></nb-card
  >
</ng-template>

<!-- re-invite /revoke beta user-->
<ng-template #revokeReInviteTemplate let-revokeRef="dialogRef">
  <nb-card class="dialog-box">
    <nb-card-header>
      {{
        updateBetaStaus
          ? updateBetaStaus == "reInvite"
            ? "Resend Sign Up Link"
            : "Revoke User"
          : "Select an action"
      }}
      <nb-icon
        (click)="
          revokeRef.close();
          expireRevokeReason = null;
          updateBetaStaus = null;
          reInviteTimeValidity = null
        "
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <div class="row row-cols-2">
        <div class="col">
          <button
            nbButton
            size="giant"
            fullWidth
            (click)="updateBetaStaus = 'reInvite'"
            [status]="updateBetaStaus == 'reInvite' ? 'success' : 'basic'"
          >
            Resend User Sign In Link
          </button>
        </div>
        <div class="col">
          <button
            nbButton
            [status]="updateBetaStaus == 'revoke' ? 'success' : 'basic'"
            size="giant"
            fullWidth
            (click)="updateBetaStaus = 'revoke'"
          >
            Revoke
          </button>
        </div>
      </div>
      <div class="sc-mt-6 form-floating" *ngIf="updateBetaStaus == 'reInvite'">
        <input
          class="form-control"
          nbInput
          fullWidth
          placeholder="Test User Expiry"
          [nbDatepicker]="dateTimePicker3"
          [(ngModel)]="reInviteTimeValidity"
        />
        <label>Test User Expiry</label>
        <nb-datepicker #dateTimePicker3></nb-datepicker>
      </div>
      <div class="sc-mt-6">
        <textarea
          class="form-control"
          fullWidth
          rows="5"
          type="text"
          placeholder="Add a Reason..."
          [(ngModel)]="expireRevokeReason"
        ></textarea>
      </div>
      <div class="sc-mt-6 row row-cols-2">
        <div class="col">
          <button
            nbButton
            size="giant"
            fullWidth
            (click)="
              expireRevokeReason = null;
              updateBetaStaus = null;
              reInviteTimeValidity = null
            "
          >
            Clear
          </button>
        </div>
        <div class="col">
          <button
            nbButton
            size="giant"
            fullWidth
            (click)="expireReInviteUser(); revokeRef.close()"
            [disabled]="!(expireRevokeReason && updateBetaStaus)"
          >
            Submit
          </button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
