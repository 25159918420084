<nb-card class="gt-p-small dialog-box">
  <nb-card-header class="sc-text-center">Upload Via</nb-card-header>

  <div class="p-2">
    <button
      class="mb-3"
      nbButton
      fullWidth
      size="giant"
      hero
      status="primary"
      (click)="onDismis('device'); uploadType = 'device'"
    >
      <nb-icon
        icon="folder-outline"
        pack="eva"
        style="color: var(--color-basic-300); width: 35%; height: 35%"
      ></nb-icon>
      <span class="small-font">Library</span>
    </button>

    <button
      nbButton
      size="giant"
      fullWidth
      status="primary"
      (click)="onDismis('live'); uploadType = 'live'"
      [disabled]="!hasCameraOnDevice"
    >
      <nb-icon
        icon="camera-outline"
        pack="eva"
        style="color: var(--color-basic-300); width: 35%; height: 35%"
      ></nb-icon>
      <span class="small-font">Camera</span>
    </button>
  </div>

  <div class="row row-cols-1 mt-3">
    <div>
      <button nbButton fullWidth size="giant" ghost (click)="onDismis('close')">
        Cancel
      </button>
    </div>
  </div>
</nb-card>
