<div id="registration">
  <div class="mobile-only">
    <div class="gt-logo-mobile mb-0">
      <img
        src="../../../assets/images/Guard_Tracker-Stacked.png"
        alt="Guard Tracker"
      />
    </div>
  </div>
  <div class="large-view-only">
    <div class="gt-logo-desktop mb-0">
      <img
        src="../../../assets/images/Guard_Tracker-Lateral_Long_default.png"
        alt="Guard Tracker"
      />
    </div>
  </div>
  <div class="sc-text-center mb-5" *ngIf="!showSignInLink">
    <h4>Sign Up</h4>
  </div>
  <section>
    <nb-alert *ngIf="error" outline="basic" role="alert">
      {{ error }}
    </nb-alert>
    <section>
      <nb-alert *ngIf="message" outline="basic" role="alert">
        {{ message }}
      </nb-alert>
    </section>
  </section>
 

  <!-- Registration Form starts -->
  <form
    *ngIf="!message && !showDetailForm"
    [formGroup]="registerForm"
    #form="ngForm"
    aria-labelledby="title"
  >
    <nb-stepper #stepper1 *ngIf="isFetchedRegisterDetails === false">
      <!-- Step 1: Company and Address -->
      <nb-step [stepControl]="startForm" label="profile">
        <h5 class="mb-3">Step 1: Your Company Details</h5>
        <ng-container>
          <!-- Company Name -->
          <div class="form-floating">
            <input
              [status]="
                registerForm.controls['company_name'].touched
                  ? registerForm.controls['company_name'].invalid
                    ? 'danger'
                    : 'basic'
                  : ''
              "
              class="form-control"
              fullWidth
              type="text"
              nbInput
              formControlName="company_name"
              id="company_name"
              placeholder="Company Name"
            /><label for="last_name">Company Name</label>
          </div>

          <!-- Address -->
          <div class="form-floating">
            <input
              class="form-control"
              #addressAutoInput
              id="addressSearch"
              (input)="onAddressSearch($event)"
              nbInput
              fullWidth
              type="text"
              placeholder="Address Lookup"
              [formControl]="searchControl"
              [nbAutocomplete]="autoNgModel"
            />
            <label>Address Lookup</label>
          </div>
          <nb-autocomplete
            #autoNgModel
            (selectedChange)="addressSelected($event)"
          >
            <nb-option *ngIf="!enteredAddresslookup" [value]="'add'"
              ><strong> Enter address manually</strong>
            </nb-option>
            <nb-option
              *ngFor="let item of addrLookupData$ | async"
              [value]="item"
            >
              <div>
                <span
                  ><b>{{ item.address1 + " " + item.address2 }}</b
                  >,</span
                ><br />
                <small
                  >{{ item.address3 ? item.address3 + " " : "" }}
                  {{ item.city_name }} {{ item.state_name }}
                  {{ item.postcode }}</small
                >
              </div>
            </nb-option>
          </nb-autocomplete>
          <div *ngIf="enteredAddresslookup === true">
            <nb-alert
              outline="danger"
              style="font-weight: 500"
              *ngIf="
                registerForm.controls['address1'].invalid ||
                registerForm.controls['address2'].invalid ||
                registerForm.controls['postcode'].invalid ||
                registerForm.controls['city_name'].invalid
              "
            >
              Unable to get exact address, please fill in missing
              information</nb-alert
            >
            <div class="form-floating">
              <input
                [status]="
                  registerForm.controls['address1'].touched
                    ? registerForm.controls['address1'].invalid
                      ? 'danger'
                      : 'basic'
                    : ''
                "
                class="form-control"
                type="tel"
                fullWidth
                nbInput
                id="address1"
                formControlName="address1"
                placeholder="Street Number"
              />
              <label for="address1">Street Number</label>
            </div>
            <div class="form-floating">
              <input
                [status]="
                  registerForm.controls['address2'].touched
                    ? registerForm.controls['address2'].invalid
                      ? 'danger'
                      : 'basic'
                    : ''
                "
                class="form-control"
                type="text"
                nbInput
                fullWidth
                id="address2"
                formControlName="address2"
                autocomplete="off"
                placeholder="Street"
              />
              <label for="address2">Street Name</label>
            </div>
            <div class="form-floating">
              <input
                class="form-control"
                nbInput
                type="text"
                fullWidth
                id="address3"
                formControlName="address3"
                autocomplete="off"
                placeholder="Unit"
              />
              <label for="address3">Address Prefix (Suite/Lot/Unit)</label>
            </div>
            <div class="form-floating">
              <input
                [status]="
                  registerForm.controls['city_name'].touched
                    ? registerForm.controls['city_name'].invalid
                      ? 'danger'
                      : 'basic'
                    : ''
                "
                class="form-control"
                type="text"
                nbInput
                fullWidth
                id="city_name"
                formControlName="city_name"
                autocomplete="off"
                (input)="forceUppercaseConditionally($event)"
                placeholder="City"
              />
              <label for="city_name">Suburb</label>
            </div>
            <div class="form-floating">
              <input
                [status]="
                  registerForm.controls['postcode'].touched
                    ? registerForm.controls['postcode'].invalid
                      ? 'danger'
                      : 'basic'
                    : ''
                "
                class="form-control"
                type="tel"
                nbInput
                fullWidth
                id="postcode"
                formControlName="postcode"
                autocomplete="off"
                placeholder="Post Code"
              />
              <label for="postcode">Post Code</label>
            </div>

            <nb-select
              fullWidth
              placeholder="State"
              class="form-control"
              formControlName="state_code"
              [(selected)]="selectedItem"
            >
              <nb-option *ngFor="let state of stateData" [value]="state.code">{{
                state.code
              }}</nb-option>
            </nb-select>
          </div>

          <div id="agree" class="row row-cols-2">
            <nb-checkbox
              class="col-1"
              (checkedChange)="acceptPrivacyPolicy($event)"
            ></nb-checkbox>

            <div class="col-11">
              I agree to the
              <a
                class="terms-link pointer"
                href="https://guardtracker.com.au/terms.html"
                target="_blank"
                >Terms of Service</a
              >
              and
              <a
                class="terms-link pointer"
                href="https://guardtracker.com.au/privacy.html"
                target="_blank"
                >Privacy Policy</a
              >
              <!-- TODO: Add create privacy component and link to dialog showing component -->
            </div>
          </div>

          <div class="row">
            <div class="col-6 offset-6">
              <button
                size="giant"
                nbButton
                (click)="saveRegisterDetail()"
                [disabled]="
                  registerForm.controls['company_name'].invalid ||
                  registerForm.controls['address1'].invalid ||
                  registerForm.controls['address2'].invalid ||
                  registerForm.controls['city_name'].invalid ||
                  registerForm.controls['postcode'].invalid ||
                  !isPolicyAccepted
                "
                nbStepperNext
                fullWidth
                id="nextbtn1"
                status="primary"
              >
                Next
              </button>
            </div>
          </div>
        </ng-container>
      </nb-step>

      <!-- Step 2: First & Last Name -->
      <nb-step>
        <h5>Step 2: Your Name</h5>
        <ng-container>
          <!-- First Name -->
          <div class="form-floating">
            <input
              [status]="
                registerForm.controls['first_name'].touched
                  ? registerForm.controls['first_name'].invalid
                    ? 'danger'
                    : 'basic'
                  : ''
              "
              class="form-control"
              type="text"
              nbInput
              fullWidth
              id="first_name"
              formControlName="first_name"
              maxlength="150"
              autocomplete="off"
              placeholder="First Name"
            />
            <label>First Name</label>
          </div>
          <!-- Last Name -->
          <div class="form-floating">
            <input
              [status]="
                registerForm.controls['last_name'].touched
                  ? registerForm.controls['last_name'].invalid
                    ? 'danger'
                    : 'basic'
                  : ''
              "
              fullWidth
              class="form-control"
              type="text"
              nbInput
              id="last_name"
              formControlName="last_name"
              autocomplete="off"
              placeholder="Last Name"
              maxlength="150"
            />
            <label>Last Name</label>
          </div>

          <div class="row row-cols-2">
            <div class="col">
              <button
                size="giant"
                type="button"
                ghost
                nbButton
                nbStepperPrevious
                fullWidth
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                size="giant"
                class="sc-floating-right"
                (click)="saveRegisterDetail()"
                [disabled]="
                  registerForm.controls['first_name'].invalid ||
                  registerForm.controls['last_name'].invalid
                "
                fullWidth
                nbButton
                nbStepperNext
                status="primary"
              >
                Next
              </button>
            </div>
          </div>
        </ng-container>
        <!-- Verify Verification code section -->
      </nb-step>

      <!-- Step 3: Email Address -->
      <nb-step>
        <h5>Step 3: Email Address</h5>
        <ng-container>
          <!-- Email Section -->
          <!-- TODO: Add a email validation -->
          <div class="form-floating">
            <input
              class="form-control"
              nbInput
              id="input-email"
              name="email"
              placeholder="Email address"
              fullWidth
              formControlName="email"
              [status]="
                registerForm.controls['email'].dirty
                  ? registerForm.controls['email'].invalid
                    ? 'danger'
                    : 'primary'
                  : ''
              "
              [attr.aria-invalid]="
                registerForm.controls['email'].invalid &&
                registerForm.controls['email'].touched
                  ? true
                  : null
              "
              autocorrect="off"
              autocapitalize="off"
            /><label for="input-email">Email address</label>
          </div>
        </ng-container>
        <div class="row row-cols-2">
          <div class="col">
            <button
              size="giant"
              type="button"
              nbButton
              ghost
              nbStepperPrevious
              fullWidth
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              size="giant"
              nbButton
              [disabled]="registerForm.controls['email'].invalid"
              nbStepperNext
              status="primary"
              (click)="sendVerificationCode(); saveRegisterDetail()"
              fullWidth
            >
              Next
            </button>
          </div>
        </div>
      </nb-step>

      <!-- Step 4: Password -->
      <nb-step>
        <ng-container *ngIf="!showSignInLink">
          <h5>Step 4: Set Password</h5>
          <nb-form-field>
            <div class="form-floating">
              <input
                class="form-control"
                nbInput
                fullWidth
                [type]="getInputType()"
                name="password"
                id="password"
                formControlName="password"
                placeholder="Password"
                [status]="'success'"
                [minlength]="8"
                [status]="
                  registerForm.controls['password'].dirty
                    ? registerForm.controls['password'].invalid
                      ? 'danger'
                      : 'primary'
                    : ''
                "
              />
              <label for="password">Password</label>
            </div>

            <nb-icon
              nbSuffix
              (click)="toggleShowPassword()"
              [icon]="showPassword ? 'eye-outline' : 'eye-off-outline'"
              pack="eva"
            >
            </nb-icon>
          </nb-form-field>

          <small
            class="error-message"
            *ngIf="registerForm.controls['password'].hasError('minlength')"
            >Must have at least 8 characters</small
          >

          <div class="form-floating">
            <input
              class="form-control"
              nbInput
              placeholder="Confirm Password"
              fullWidth
              [type]="getInputType()"
              name="confirm_password"
              id="confirm_password"
              formControlName="confirm_password"
              placeholder="Confirm Password"
              [status]="
                registerForm.controls['confirm_password'].dirty
                  ? registerForm.controls['confirm_password'].invalid
                    ? 'danger'
                    : 'primary'
                  : ''
              "
              [minlength]="8"
            />
            <label for="confirm_password">Confirm Password</label>
            <small
              class="error-message"
              *ngIf="
                registerForm.controls['confirm_password'].value &&
                registerForm.controls['confirm_password'].invalid
              "
              >Passwords do not match</small
            >
          </div>
          <div class="row row-cols-2">
            <div class="col">
              <button
                size="giant"
                type="button"
                ghost
                nbButton
                nbStepperPrevious
                fullWidth
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                size="giant"
                nbButton
                class="sc-floating-right"
                nbStepperNext
                fullWidth
                [disabled]="
                  registerForm.controls['password'].invalid ||
                  registerForm.controls['confirm_password'].invalid
                "
                status="primary"
              >
                Next
              </button>
            </div>
          </div>
        </ng-container>
      </nb-step>

      <!-- Step 5: Verification code -->
      <nb-step>
        <ng-container *ngIf="gotVerificationCode && restartSignUp == false">
          <h5>Step 5: Verification</h5>
          <span>Please enter the verification code we sent to your email</span>
          <div class="form-floating">
            <input
              nbInput
              [maxlength]="4"
              oninput="this.value = this.value.toUpperCase()"
              fullWidth
              class="form-control"
              type="text"
              name="vCode"
              id="ver-vode"
              formControlName="vCode"
              placeholder="Verification Code"
              (input)="verifyVerificationCode($event)"
            />
            <label for="ver-vode">Verification Code</label>
          </div>
        </ng-container>
      </nb-step>
    </nb-stepper>
    <!-- Verification code Section if user got verification code and fetch the data from local storage-->
    <nb-stepper #stepper1 *ngIf="isFetchedRegisterDetails === true">
      <nb-step>
        <!-- Verify Verification code section -->
        <ng-container *ngIf="restartSignUp === false">
          <span>Please enter the verification code we sent to your email</span>
          <div class="form-floating">
            <input
              nbInput
              [maxlength]="4"
              oninput="this.value = this.value.toUpperCase()"
              fullWidth
              class="form-control"
              type="text"
              name="vCode"
              id="ver-vode"
              formControlName="vCode"
              placeholder="Verification Code"
              (input)="verifyVerificationCode($event)"
            />
            <label for="ver-vode">Verification Code</label>
          </div>
        </ng-container>
      </nb-step>
    </nb-stepper>
  </form>
  <ng-container *ngIf="restartSignUp === true">
    <div class="table-card-data">
      <div>{{ restartMessage }}</div>
      <button
        size="giant"
        nbButton
        fullWidth
        style="color: black"
        (click)="refresh()"
      >
        Try Again
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="showSignInLink && !message">
    <button
      size="giant"
      size="giant"
      nbButton
      fullWidth
      (click)="signInLinkOrRoute()"
    >
      {{ incompleteRegistration ? "Get Link" : "Sign In" }}
    </button>

    <!-- <div>Already have an account? <a routerLink="/login">Login</a></div> -->
  </ng-container>
</div>
