<nb-card>
  <nb-card-body>
    <div>
      <h5>Support Requests</h5>
    </div>
    <div class="my-2">
      <button
        (click)="
          showDefaultFeedbacks
            ? (showDefaultFeedbacks = false)
            : (showDefaultFeedbacks = true);
          getFeedback()
        "
        nbButton
        status="primary"
      >
        {{ showDefaultFeedbacks ? "Show All" : "Show Unresolved" }}
      </button>
    </div>
  </nb-card-body>

  <div>
    <app-table
      [desktopTableData]="feedbackData"
      [desktopTableDetails]="feedbackTableView.colums"
      (rowClick)="oneventClick($event)"
      [apiFilter]="'true'"
      (sortColumn)="sortColumn($event)"
      (searchColumn)="searchColumn($event)"
    ></app-table>
  </div>
  <!-- Pagination section begin -->
  <div class="sc-mt-6">
    <app-pagination-control
      [pageSize]="rows"
      [pageNum]="pageNum"
      [previous]="previous"
      [totalRows]="totalRows"
      [totalPages]="totalPages"
      (onClickPagination)="onClickPagination($event)"
      (onChangePagination)="onChangePagination($event)"
    >
    </app-pagination-control>
  </div>
</nb-card>

<!-- feedback details and comments section-->

<nb-card *ngIf="selectedEvent">
  <nb-card-body class="gt-p-1">
    <div class="strong">Feedback ID: {{ selectedEvent?.feedback_key }}</div>
    <div class="gt-event-details col-6" *ngIf="selectedEvent?.device">
      <div *ngIf="selectedEvent?.details?.app_version">
        <span class="key_title">App Version: </span>
        {{ selectedEvent?.details?.app_version }}
      </div>
      <div>
        <span class="key_title">Received: </span>
        {{ selectedEvent?.created_at | customdate : "d MMM y HH:mm" }}
      </div>
      <div class="sc-mt-6">
        <span class="key_title">User:</span>
        {{ selectedEvent?.created_by?.full_name }}
        <span
          *ngIf="
            selectedEvent?.created_by?.email ===
            selectedEvent?.subscriber?.email
          "
        >
          (Subscriber Admin)</span
        >
      </div>
      <div *ngIf="selectedEvent?.details?.user_groups?.length">
        <span class="key_title">User Groups: </span>
        {{ selectedEvent?.details?.user_groups }}
      </div>
      <div *ngIf="selectedEvent?.details?.current_user_groups?.length">
        <span class="key_title">Current User Groups: </span>
        {{ selectedEvent?.details?.current_user_groups }}
      </div>
      <div *ngIf="selectedEvent?.details?.last_read_at">
        <span class="key_title">Last Read at: </span>
        {{
          selectedEvent?.details?.last_read_at | customdate : "d MMM y HH:mm"
        }}
      </div>
      <div>
        <span class="key_title">Email: </span>
        <a
          href="mailto:{{
            selectedEvent?.created_by?.email
          }}?subject=Guard Tracker Feedback | Submitted: {{
            selectedEvent?.created_at | date : 'd MMM y HH:mm'
          }} &body={{ selectedEvent?.feedback }}"
          >{{ selectedEvent?.created_by?.email }}</a
        >
      </div>

      <div class="mt-2">
        <span class="key_title">Browser:</span>
        {{ selectedEvent?.device?.browser }}
        {{ selectedEvent?.device?.browser_version }} ({{
          selectedEvent?.device?.device_os
        }})
      </div>

      <div>
        <span class="key_title">Device:</span>
        {{ selectedEvent?.device?.device }}
      </div>
    </div>
    <div class="row row-cols-2">
      <div class="col sc-mt-6">
        <!-- timestamp -->
        <div class="chat-info">
          {{ selectedEvent?.created_at | customdate : "d MMM y HH:mm" }} |
          {{ selectedEvent?.created_by?.full_name }}
        </div>

        <div class="chat-data">
          <div class="feedback-title">
            {{ selectedEvent?.feedback }}
          </div>
        </div>

        <!--updates section-->
        <div
          *ngIf="selectedEvent?.updates?.length > 0"
          class="list-fixed-length"
        >
          <div *ngFor="let update of selectedEvent?.updates; let isLast = last">
            <div>
              <!-- timestamp -->
              <div class="chat-info">
                {{ update?.updated_at | customdate : "d MMM y HH:mm" }} |
                {{ update?.updated_by?.full_name }}
              </div>
              <!-- comment / photo -->
              <div
                class="chat-data"
                *ngIf="update?.event_action?.key === 1"
                [ngClass]="isLast && selectedEvent?.is_deleted ? 'danger' : ''"
              >
                <div>
                  {{
                    update?.description === "null" ||
                    update?.description === "undefined"
                      ? ""
                      : update?.description
                  }}
                </div>
                <div
                  *ngIf="isLast && selectedEvent?.is_deleted"
                  class="danger-text-info"
                >
                  Feedback Deleted
                </div>
              </div>
              <div class="chat-data" *ngIf="update?.event_action?.key === 0">
                {{ update?.updated_by?.full_name }} changed status to
                {{ update?.status?.value }}

                <div>
                  Reason:
                  {{
                    update?.description === "null" ||
                    update?.description === "undefined"
                      ? ""
                      : update?.description
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row-cols-1 gy-2" *ngIf="!selectedEvent?.is_deleted">
          <div class="col">
            <textarea
              rows="5"
              class="form-control"
              type="text"
              placeholder="Start typing"
              [(ngModel)]="newComment"
            ></textarea>
          </div>
          <div class="col">
            <button
              (click)="addComment()"
              nbButton
              size="giant"
              fullWidth
              [disabled]="!newComment"
            >
              Add
            </button>
          </div>
          <div>
            <button
              (click)="actionClick(editFeedbackTemplate)"
              nbButton
              size="giant"
              fullWidth
            >
              Change Status
            </button>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="sc-text-center">Screen Shot</div>
        <img
          class="zoomImage"
          (click)="onOpenImage($event, imgRef)"
          *ngIf="uploadedImage"
          src="{{ uploadedImage }}"
          alt="unplash"
          style="height: 500px; max-width: 100%"
        />
        <span *ngIf="!uploadedImage">File not uploaded</span>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<ng-template #imgRef let-data let-imgTempRef="dialogRef">
  <nb-card style="margin: 0">
    <nb-card-body (click)="imgTempRef.close()">
      <img
        src="{{ fullImgSrc }}"
        style="
          max-width: 100%;
          cursor: pointer;
          max-height: 95vh;
          border: 3px var(--background-basic-color-3) solid;
          border-radius: 2%;
        "
        class="modal-hover-opacity"
      />
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template
  #editFeedbackTemplate
  let-data
  let-editFeedbackTemplateRef="dialogRef"
>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header>
      Change Status WARNING: this will be published to the subscriber
      <nb-icon
        (click)="editFeedbackTemplateRef.close()"
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <form [formGroup]="feedbackForm" (ngSubmit)="onClickSubmit()">
        <div class="col">
          <nb-select
            fullWidth
            placeholder="Action Status"
            size="giant"
            formControlName="status"
          >
            <nb-option
              *ngFor="let option of feedbcackstatusList"
              [value]="option[1]"
              >{{ option[1] }}</nb-option
            >
          </nb-select>
        </div>

        <div class="col">
          <textarea
            rows="5"
            class="form-control"
            fullWidth
            formControlName="description"
            nbInput
            type="text"
            placeholder="Description/Reason for change"
          ></textarea>
        </div>

        <div class="row row-cols-2">
          <div class="col">
            <button
              fullWidth
              (click)="onClearForm()"
              nbButton
              size="giant"
              type="button"
              ghost
            >
              Clear
            </button>
          </div>
          <div class="col">
            <button
              fullWidth
              size="giant"
              [disabled]="!feedbackForm.valid"
              nbButton
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</ng-template>
