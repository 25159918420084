<nb-card>
  <nb-alert
    status="danger"
    *ngIf="!userTimeZone"
    routerLink="/gtadmin/profile"
    class="pointer"
    >Please set a Time Zone First. Go to Profile</nb-alert
  >
  <!-- <div>
    <app-gt-table
      [desktopTableBackendData]="adminReportData?.results"
      [desktopTableDetails]="adminReportsTableData"
      (rowClick)="onRowClick($event, reportTemplate)"
      [otherParams]="otherParamsAdminReports"
    >
    </app-gt-table>
  </div> -->
</nb-card>

<nb-card class="gt-p-1">
  <form [formGroup]="adminReportForm">
    <div class="row row-cols-2 gt-p-1">
      <div class="form-floating">
        <input
          class="form-control"
          nbInput
          type="text"
          fullWidth
          formControlName="mail_time"
          [ngxMatTimepicker]="timePicker1"
          readonly
        />
        <label>Mail Time</label>

        <ngx-mat-timepicker
          (timeSet)="formatTime($event)"
          #timePicker1
          [hoursOnly]="true"
        ></ngx-mat-timepicker>
      </div>
      <div></div>
      <div class="sc-mt-6">
        <div class="sc-head6">Recipients</div>
        <table>
          <tbody>
            <tr *ngFor="let user of superUserListData" class="pointer">
              <td>
                <nb-checkbox
                  [checked]="user.selected"
                  (checkedChange)="addRemoveSuperUser($event, user)"
                >
                </nb-checkbox>
              </td>
              <td (click)="addRemoveSuperUser(!user?.selected, user)">
                <span class="strong">{{ user?.full_name }}</span>
                {{ user?.email }}
                <span class="small-font danger-text-info strong"
                  >[{{ user?.time_zone ? user?.time_zone : "UTC" }}]</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="sc-mt-6">
        <div class="sc-head6">Report Types</div>
        <table>
          <tbody>
            <tr *ngFor="let reportType of reportTypes" class="pointer">
              <td>
                <nb-checkbox
                  [checked]="reportType.selected"
                  (checkedChange)="addRemoveReportType($event, reportType)"
                >
                </nb-checkbox>
              </td>
              <td
                (click)="addRemoveReportType(!reportType?.selected, reportType)"
              >
                {{ reportType?.full_name }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row row-cols-3 row-cols-lg-4">
      <div class="col" *ngIf="selectedAdminReport?.id">
        <button
          type="button"
          (click)="deleteAdminReport()"
          nbButton
          size="giant"
          ghost
          fullWidth
        >
          delete
        </button>
      </div>
      <div class="col" *ngIf="!userTimeZone">
        <button
          type="button"
          routerLink="/gtadmin/profile"
          nbButton
          size="giant"
          status="danger"
          fullWidth
        >
          set time zone
        </button>
      </div>
      <div class="col" *ngIf="userTimeZone">
        <button
          [disabled]="
            !adminReportForm.valid ||
            !selectedSuperUsers?.length ||
            !selectedReportTypes?.length
          "
          nbButton
          size="giant"
          fullWidth
          (click)="createUpdateAdminReport()"
        >
          {{ selectedAdminReport?.id ? "Update" : "Create" }}
        </button>
      </div>
    </div>
  </form>
</nb-card>
