import { Component, OnInit, Optional } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/auth/auth.service';
import { PagesService } from 'src/app/pages/pages.service';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { MustMatch } from '../../must-match-validator';
import { DataCheckService } from '../../service/data-check.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  passwordForm: UntypedFormGroup = new UntypedFormGroup(
    {
      password: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
      confirm_password: new UntypedFormControl(null, [
        MustMatch('password', 'confirm_password'),
      ]),
    },
    {
      validators: MustMatch('password', 'confirm_password'),
    }
  );
  showPassword: boolean = false;
  userData: any;
  isSuperUser: any;
  constructor(
    @Optional() protected dialogRef: NbDialogRef<ChangePasswordComponent>,
    private spinnerService: NgxSpinnerService,
    private toasterService: NbToastrService,
    private router: Router,
    private profileService: ProfileService,
    private appService: AppService,
    private pageService: PagesService,
    private dataCheckService: DataCheckService
  ) {
    this.userData = this.appService.getUserData();
    this.isSuperUser = this.dataCheckService.isSuperUser();
  }

  ngOnInit(): void {}
  changePassword(val?: any) {
    if (this.passwordForm.valid) {
      if (this.isSuperUser === true) {
        this.profileService
          .changeAdminPassword(this.passwordForm.value)
          .subscribe((response) => {
            if (response['status'] == 'success') {
              if (this.isSuperUser === true) {
                this.pageService.setMessage({
                  errorMessage: '',
                  successMessage: response['message'],
                });
                this.dialogRef.close();
              }
              this.pageService.setMessage({
                errorMessage: '',
                successMessage: response['message'],
              });
              this.spinnerService.hide();
            }
          });
      } else {
        this.profileService
          .changePassword(this.passwordForm.value)
          .subscribe((response) => {
            if (response['status'] == 'success') {
              this.pageService.setMessage({
                errorMessage: '',
                successMessage: response['message'],
              });
              this.dialogRef.close();
            } else {
              this.pageService.setMessage({
                errorMessage: '',
                successMessage: response['message'],
              });
              this.spinnerService.hide();
            }
          });
      }
    } else {
    }
  }
  //Method to change the password input type
  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  onCloseDialogue() {
    this.dialogRef.close();
  }
}
