<div class="gt-p-small sc-mt-6">
  <div class="sc-head6 sc-text-center mb-4">Site Keys</div>

  <div class="row row-cols-1 row-cols-md-2">
    <div *ngFor="let keyInfo of keyListData" class="col">
      <div
        class="d-flex company-list"
        (click)="
          keyInfo?.key_count > 0 ? (keyInfo.expanded = !keyInfo?.expanded) : ''
        "
      >
        <div class="flex-grow-1">{{ keyInfo?.name }}</div>
        <div>{{ keyInfo?.key_count }}</div>
      </div>
      <div class="sc-mt-6 p-3" *ngIf="keyInfo?.expanded">
        <div *ngFor="let siteKeyInfo of keyInfo?.site_info">
          <div
            class="d-flex site-list"
            (click)="
              siteKeyInfo?.key_count > 0
                ? (siteKeyInfo.expanded = !siteKeyInfo?.expanded)
                : ''
            "
          >
            <div class="flex-grow-1">{{ siteKeyInfo?.name }}</div>
            <div>{{ siteKeyInfo?.key_count }}</div>
          </div>
          <div class="sc-mt-6 p-3" *ngIf="siteKeyInfo?.expanded">
            <div *ngFor="let key of siteKeyInfo?.keys">
              <div class="key-list">
                {{ key }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
