<nb-card class="dialog-box">
  <nb-card-body>
    <form
      [formGroup]="passwordForm"
      (ngSubmit)="changePassword()"
      appFormEnterAction
    >
      <nb-form-field>
        <div class="form-floating">
          <input
            class="form-control"
            nbInput
            fullWidth
            [type]="getInputType()"
            name="password"
            id="password"
            formControlName="password"
            placeholder="New Password"
            [status]="'success'"
            [minlength]="8"
            [status]="
              passwordForm.controls['password'].dirty
                ? passwordForm.controls['password'].invalid
                  ? 'danger'
                  : 'primary'
                : ''
            "
          />
          <label for="password">New Password</label>
        </div>
        <nb-icon
          nbSuffix
          (click)="toggleShowPassword()"
          [icon]="showPassword ? 'eye-outline' : 'eye-off-outline'"
          pack="eva"
        >
        </nb-icon>
      </nb-form-field>
      <small
        class="error-message"
        *ngIf="passwordForm.controls['password'].hasError('minlength')"
        >Must have at least 8 characters</small
      >

      <div class="form-floating">
        <input
          class="form-control"
          nbInput
          fullWidth
          [type]="getInputType()"
          name="confirm_password"
          id="confirm_password"
          formControlName="confirm_password"
          placeholder="Confirm Password"
          [status]="'success'"
          [minlength]="8"
          [status]="
            passwordForm.controls['confirm_password'].dirty
              ? passwordForm.controls['confirm_password'].invalid
                ? 'danger'
                : 'primary'
              : ''
          "
        />
        <label for="confirm_password">Confirm Password</label>
      </div>
      <small
        class="error-message"
        *ngIf="
          passwordForm.controls['confirm_password'].value &&
          passwordForm.controls['confirm_password'].invalid
        "
        >Passwords do not match</small
      >

      <div class="row row-cols-2">
        <div class="col">
          <button
            nbButton
            size="giant"
            fullWidth
            ghost
            (click)="onCloseDialogue()"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            nbButton
            id="form-enter-submit-btn"
            size="giant"
            [disabled]="!passwordForm.valid"
            fullWidth
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
