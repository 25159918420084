import { formatDate } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { PagesService } from 'src/app/pages/pages.service';
import {
  checkPointHistoryTable,
  userUpdateTable,
} from 'src/app/pages/tableDetails';
import { browserGpsInfo, checkScanDistanceGreater, formatDateTimeStamp } from 'src/global.variables';
import { AdminService } from '../../../admin.service';

@Component({
  selector: 'app-subscriber-app-data',
  templateUrl: './subscriber-app-data.component.html',
  styleUrls: ['./subscriber-app-data.component.scss'],
})
export class SubscriberAppDataComponent implements OnInit {
  @Input() subscriberId: string = '';

  checkPointListSubscriber: any = [];
  tableViewCheckPointListSubscriber: any = [
    {
      header: 'Client',
      name: 'company',
      toolTipData: (row: any) => {
        return row?.update_count
          ? `${row?.update_count} updates till now.`
          : 'No updates yet.';
      },
      sortKey: 'company__company_name',
    },
    {
      header: '',

      nestedValue: (row: any) => {
        return row?.qr_code ? 'QR' : '';
      },
      sortKey: 'qr_code',
    },
    {
      header: 'Checkpoint Name',
      name: 'name',

      sortKey: 'name',
      toolTipData: (row: any) => {
        return row?.scanned_count
          ? `${row?.scanned_count} scans till now.`
          : 'No scans yet.';
      },
    },
    {
      header: 'Min Distance',

      nestedValue: (row: any) => {
        return row?.qr_code
          ? row?.is_locked
            ? row?.min_distance
            : 'N/A'
          : row?.min_distance;
      },
    },
    {
      header: 'Location',
      name: 'partial_address',
    },
    {
      header: 'Gps',
      nestedValue: (row: any) => {
        return `${row?.latitude}, ${row?.longitude}`;
      },
    },
  ];

  totalRowsCheckpointsSubscriber: number = 0;
  previousCheckpointsSubscriber: number = 0;
  rowsCheckpointsSubscriber: number = 10;
  pageNumCheckpointsSubscriber: number = 1;
  totalPagesCheckpointsSubscriber: number = 0;
  otherParamsCheckpointsSubscriber: any = {};
  sortKeyCheckpointsSubscriber: any;
  miscCheckpointsSubscriber: any = {
    checkPointHistoryTable: checkPointHistoryTable.desktopTable,
  };

  clientListSubscriber: any = [];
  tableViewClientListSubscriber: any = [
    {
      header: 'Name',
      name: 'company_name',
      sortKey: 'company_name',
    },
    {
      header: 'Address',

      nestedValue: (row: any) => {
        return row?.address?.full_address;
      },
    },
  ];

  totalRowsClientsSubscriber: number = 0;
  previousClientsSubscriber: number = 0;
  rowsClientsSubscriber: number = 10;
  pageNumClientsSubscriber: number = 1;
  totalPagesClientsSubscriber: number = 0;
  otherParamsClientsSubscriber: any = {};
  sortKeyClientsSubscriber: any;
  miscClientsSubscriber: any = {};

  alarmResponseJobListSubscriber: any = [];
  tableViewAlarmResponseJobListSubscriber: any = [
    {
      header: 'Last Updated Date',
      name: 'modified_date',
      datetimeObj: true,
      dateRangeKey: 'modified_date',
      sortKey: 'modified_date',
    },
    {
      header: 'Site Name',
      name: 'site_name',
      sortKey: 'site_name',
      searchKey: 'site_name',
      cardRowStyle: {
        'font-weight': 'bold',
      },
    },
    {
      header: 'Client',
      name: 'company',
      sortKey: 'company__company_name',
      searchKey: 'company__company_name',
    },
    {
      header: 'Job Key',
      name: 'job_key',
      sortKey: 'job_key',
      searchKey: 'job_key',
    },
    {
      header: 'Ext. Job Id',
      name: 'ext_job_id',
      sortKey: 'ext_job_id',
      searchKey: 'ext_job_id',
    },
    {
      header: 'Address',
      name: 'sites',
      sortKey: 'sites',
      searchKey: 'sites',
    },
    {
      header: 'Response Type',
      name: 'response_type',
      sortKey: 'response_type__name',
    },
    {
      header: 'Monitoring Company',
      name: 'monitoring_company',
      sortKey: 'monitoring_company__company_name',
    },

    {
      header: 'Assigned To',
      name: 'assignee',
      nestedValue: (row: any) => {
        return this.getNestedAssignees(row);
      },

      searchKey: 'assignee',
    },

    {
      header: 'Status',
      name: 'status',
    },
  ];

  totalRowsAlarmResponseJobsSubscriber: number = 0;
  previousAlarmResponseJobsSubscriber: number = 0;
  rowsAlarmResponseJobsSubscriber: number = 10;
  pageNumAlarmResponseJobsSubscriber: number = 1;
  totalPagesAlarmResponseJobsSubscriber: number = 0;
  otherParamsAlarmResponseJobsSubscriber: any = {};
  sortKeyAlarmResponseJobsSubscriber: any;
  miscAlarmResponseJobsSubscriber: any = {};

  patrolHistoryListSubscriber: any = [];
  tableViewPatrolHistoryListSubscriber: any = userUpdateTable.desktopTable;

  totalRowsPatrolHistorySubscriber: number = 0;
  previousPatrolHistorySubscriber: number = 0;
  rowsPatrolHistorySubscriber: number = 10;
  pageNumPatrolHistorySubscriber: number = 1;
  totalPagesPatrolHistorySubscriber: number = 0;
  otherParamsPatrolHistorySubscriber: any = {};
  sortKeyPatrolHistorySubscriber: any;
  miscPatrolHistorySubscriber: any = {};
  searchParamsPatrolHistorySubscriber: any = {};

  siteListSubscriber: any = [];
  tableViewSiteListSubscriber: any = [
    {
      header: 'Name',
      name: 'company_name',
      sortKey: 'company_name',
    },
    {
      header: 'Client',

      nestedValue: (row: any) => {
        return row?.company?.company_name;
      },
    },
    {
      header: 'Address',

      nestedValue: (row: any) => {
        return row?.address?.full_address;
      },
    },
  ];

  totalRowsSitesSubscriber: number = 0;
  previousSitesSubscriber: number = 0;
  rowsSitesSubscriber: number = 10;
  pageNumSitesSubscriber: number = 1;
  totalPagesSitesSubscriber: number = 0;
  otherParamsSitesSubscriber: any = {};
  sortKeySitesSubscriber: any;
  searchParamsSitesSubscriber: any = {};
  miscSitesSubscriber: any = {};

  incidentListSubscriber: any = [];
  tableViewIncidentListSubscriber: any = [
    {
      header: 'Date',
      name: 'incident_time',
      datetimeObj: true,
      sortKey: 'incident_time',
    },

    {
      header: 'Incident Type',
      name: 'incident_type',
      sortKey: 'incident_type',
    },

    {
      header: 'Site',
      name: 'site_name',
      sortKey: 'site_name',
      searchKey: 'site_name',
    },

    {
      header: 'Client',
      name: 'company_name',
      sortKey: 'site__company__company_name',
      searchKey: 'site__company__company_name',
    },

    {
      header: 'Address',
      name: 'full_address',
      sortKey: 'full_address',
      searchKey: 'full_address',
    },

    {
      header: 'Reference No.',
      name: 'incident_key',
      searchKey: 'incident_key',
    },
    {
      header: 'Report Sent',
      name: 'submitted_at',
      datetimeObj: true,
      dateRangeKey: 'submitted_at',
      sortKey: 'submitted_at',
    },
  ];

  totalRowsIncidentsSubscriber: number = 0;
  previousIncidentsSubscriber: number = 0;
  rowsIncidentsSubscriber: number = 10;
  pageNumIncidentsSubscriber: number = 1;
  totalPagesIncidentsSubscriber: number = 0;
  otherParamsIncidentsSubscriber: any = {};
  sortKeyIncidentsSubscriber: any;
  searchParamsIncidentsSubscriber: any = {};
  miscIncidentsSubscriber: any = {};

  userListSubscriber: any = [];
  tableViewUserListSubscriber: any = [
    {
      header: 'Name',
      name: 'full_name',
      sortKey: 'full_name',
    },

    {
      header: 'Email',
      name: 'email',
    },
    {
      header: 'Group',
      name: 'user_group',
      nestedValue: (row: any) => {
        let userGroups = [
          ...row?.['user_group']?.map((item: any) => {
            return item?.name;
          }),
        ].join(', ');

        return userGroups;
      },
      sortKey: 'user_group',
      cardRowStyle: {
        'font-weight': 'bold',
      },
    },
    {
      header: 'Last Update',
      name: 'last_activity_time',
      datetimeObj: true,
      sortKey: 'last_activity_time',
    },
    {
      header: 'Clocked In At',
      name: 'clocked_in_time',
      sortKey: 'clocked_in_time',
      nestedValue: (row: any) => {
        if (row?.user_status === 'pending' && !row?.clocked_in_time) {
          return `[USER NOT SIGNED-IN YET]`;
        } else if (row?.clocked_in_time !== null) {
          return String(
            formatDateTimeStamp(row?.clocked_in_time, 'd MMM y hh:mm', 'en_US')
          );
        }
        return '';
      },
      conditionalColumnStyle: (row: any) => {
        if (row?.['user_status'] === 'pending' && !row?.clocked_in_time) {
          return {
            color: 'var(--color-danger-300)',
          };
        } else {
          return {};
        }
      },
    },
  ];

  totalRowsUsersSubscriber: number = 0;
  previousUsersSubscriber: number = 0;
  rowsUsersSubscriber: number = 10;
  pageNumUsersSubscriber: number = 1;
  totalPagesUsersSubscriber: number = 0;
  otherParamsUsersSubscriber: any = {};
  sortKeyUsersSubscriber: any;
  searchParamsUsersSubscriber: any = {};
  miscUsersSubscriber: any = {};

  browserGpsInfo: any = browserGpsInfo;
  selectedEventDetailedInfo: any = {};

  isAdmin: boolean = true;
  isDispatchUser: boolean = false;
  constructor(
    private spinnerService: NgxSpinnerService,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private pageService: PagesService
  ) {}

  ngOnInit(): void {
    this.getCheckpointsSubscriber();
    this.getClientsSubscriber();
    this.getAlarmResponseJobsSubscriber();
    this.getPatrolHistorySubscriber();
    this.getSitesSubscriber();
    this.getUsersSubscriber();
  }
  getNestedAssignees(rowData: any, maxNumber: any = 2) {
    if (rowData?.assignee?.length > maxNumber) {
      return `${rowData?.assignee?.slice(0, maxNumber)} ...`;
    } else {
      return rowData?.assignee;
    }
  }
  getCheckpointsSubscriber() {
    this.spinnerService.show();
    let params: any = { subscriber: this.subscriberId };
    if (this.rowsCheckpointsSubscriber) {
      params['rows'] = this.rowsCheckpointsSubscriber;
    }
    if (this.previousCheckpointsSubscriber) {
      params['previous'] = this.previousCheckpointsSubscriber;
    }

    //  if (this.globalFilter) {
    //    params['name'] = this.globalFilter;
    //  }
    //  if (this.sortKey) {
    //    params['sort_key'] = this.sortKey;
    //  }
    if (this.miscCheckpointsSubscriber?.invalid) {
      params['is_invalid'] = 1;
    }
    this.adminService
      .subscriberRelatedGETApis('checkpoint', params)
      .subscribe((response: any) => {
        this.spinnerService.hide();
        this.checkPointListSubscriber = response?.data;
        this.otherParamsCheckpointsSubscriber.paginationData = {
          tableTotalRows: response?.total_size,
          tablePreviousRows: this.previousCheckpointsSubscriber,
          tableRows: this.rowsCheckpointsSubscriber,
          tablePageNum: this.pageNumCheckpointsSubscriber,
          tableTotalPages: this.totalPagesCheckpointsSubscriber,
        };
      });
  }
  onChangePaginationCheckpointsSubscriber(data: any) {
    this.previousCheckpointsSubscriber =
      data?.paginationData?.tablePreviousRows;
    this.pageNumCheckpointsSubscriber = data?.paginationData?.tablePageNum;
    this.rowsCheckpointsSubscriber = data?.paginationData?.tableRows;

    this.otherParamsCheckpointsSubscriber.paginationData = {
      ...data?.paginationData,
    };
    this.getCheckpointsSubscriber();
  }
  sortColumnCheckpointsSubscriber(event: any) {
    this.sortKeyCheckpointsSubscriber = event?.sortList;

    this.getCheckpointsSubscriber();
  }
  validateCheckpointsSubscriber() {
    this.getCheckpointsSubscriber();
  }
  onSelectCheckpointsSubscriber(event: any, template: TemplateRef<any>) {
    let params: any = { subscriber: this.subscriberId, additional_info: 1 };
    this.spinnerService.show();
    this.adminService
      .subscriberRelatedGETApis(`checkpoint/${event?.id}`, params)
      .subscribe((response: any) => {
        this.spinnerService.hide();

        this.miscCheckpointsSubscriber.detailData = response?.data;
        this.miscCheckpointsSubscriber.history = response?.history || [];
        this.miscCheckpointsSubscriber.scanDetails =
          response?.scan_details || [];
        this.dialogService.open(template, {
          context: {},
          dialogClass: 'model-full',
        });
      });
  }
  getClientsSubscriber() {
    this.spinnerService.show();
    let params: any = { subscriber: this.subscriberId };
    if (this.rowsClientsSubscriber) {
      params['rows'] = this.rowsClientsSubscriber;
    }
    if (this.previousClientsSubscriber) {
      params['previous'] = this.previousClientsSubscriber;
    }

    //  if (this.globalFilter) {
    //    params['name'] = this.globalFilter;
    //  }
    //  if (this.sortKey) {
    //    params['sort_key'] = this.sortKey;
    //  }
    if (this.miscClientsSubscriber?.invalid) {
      params['is_inactive'] = 1;
    }
    this.adminService
      .subscriberRelatedGETApis('company', params)
      .subscribe((response: any) => {
        this.spinnerService.hide();
        this.clientListSubscriber = response?.data;
        this.otherParamsClientsSubscriber.paginationData = {
          tableTotalRows: response?.total_size,
          tablePreviousRows: this.previousClientsSubscriber,
          tableRows: this.rowsClientsSubscriber,
          tablePageNum: this.pageNumClientsSubscriber,
          tableTotalPages: this.totalPagesClientsSubscriber,
        };
      });
  }
  onChangePaginationClientsSubscriber(data: any) {
    this.previousClientsSubscriber = data?.paginationData?.tablePreviousRows;
    this.pageNumClientsSubscriber = data?.paginationData?.tablePageNum;
    this.rowsClientsSubscriber = data?.paginationData?.tableRows;

    this.otherParamsClientsSubscriber.paginationData = {
      ...data?.paginationData,
    };
    this.getClientsSubscriber();
  }
  sortColumnClientsSubscriber(event: any) {
    this.sortKeyClientsSubscriber = event?.sortList;

    this.getClientsSubscriber();
  }
  getSitesSubscriber() {
    this.spinnerService.show();
    let params: any = { subscriber: this.subscriberId };
    if (this.rowsSitesSubscriber) {
      params['rows'] = this.rowsSitesSubscriber;
    }
    if (this.previousSitesSubscriber) {
      params['previous'] = this.previousSitesSubscriber;
    }

    //  if (this.globalFilter) {
    //    params['name'] = this.globalFilter;
    //  }
    //  if (this.sortKey) {
    //    params['sort_key'] = this.sortKey;
    //  }
    if (this.miscSitesSubscriber?.invalid) {
      params['is_inactive'] = 1;
    }
    this.adminService
      .subscriberRelatedGETApis('site', params)
      .subscribe((response: any) => {
        this.spinnerService.hide();
        this.siteListSubscriber = response?.data;
        this.otherParamsSitesSubscriber.paginationData = {
          tableTotalRows: response?.total_size,
          tablePreviousRows: this.previousSitesSubscriber,
          tableRows: this.rowsSitesSubscriber,
          tablePageNum: this.pageNumSitesSubscriber,
          tableTotalPages: this.totalPagesSitesSubscriber,
        };
      });
  }
  onChangePaginationSitesSubscriber(data: any) {
    this.previousSitesSubscriber = data?.paginationData?.tablePreviousRows;
    this.pageNumSitesSubscriber = data?.paginationData?.tablePageNum;
    this.rowsSitesSubscriber = data?.paginationData?.tableRows;

    this.otherParamsSitesSubscriber.paginationData = {
      ...data?.paginationData,
    };
    this.getSitesSubscriber();
  }
  sortColumnSitesSubscriber(event: any) {
    this.sortKeySitesSubscriber = event?.sortList;

    this.getSitesSubscriber();
  }
  searchColumnSiteSubscriber(event: any) {
    this.searchParamsSitesSubscriber = event?.searchParams || {};

    this.getSitesSubscriber();
  }
  getAlarmResponseJobsSubscriber() {
    this.spinnerService.show();
    let params: any = { subscriber: this.subscriberId };
    if (this.rowsAlarmResponseJobsSubscriber) {
      params['rows'] = this.rowsAlarmResponseJobsSubscriber;
    }
    if (this.previousAlarmResponseJobsSubscriber) {
      params['previous'] = this.previousAlarmResponseJobsSubscriber;
    }

    //  if (this.globalFilter) {
    //    params['name'] = this.globalFilter;
    //  }
    //  if (this.sortKey) {
    //    params['sort_key'] = this.sortKey;
    //  }
    if (this.miscAlarmResponseJobsSubscriber?.invalid) {
      params['is_inactive'] = 1;
    }
    this.adminService
      .subscriberRelatedPOSTApis('job/list_jobs', {}, params)
      .subscribe((response: any) => {
        this.spinnerService.hide();
        this.alarmResponseJobListSubscriber = response?.data?.values;
        this.otherParamsAlarmResponseJobsSubscriber.paginationData = {
          tableTotalRows: response?.data?.total_size,
          tablePreviousRows: this.previousAlarmResponseJobsSubscriber,
          tableRows: this.rowsAlarmResponseJobsSubscriber,
          tablePageNum: this.pageNumAlarmResponseJobsSubscriber,
          tableTotalPages: this.totalPagesAlarmResponseJobsSubscriber,
        };
      });
  }
  onChangePaginationAlarmResponseJobsSubscriber(data: any) {
    this.previousAlarmResponseJobsSubscriber =
      data?.paginationData?.tablePreviousRows;
    this.pageNumAlarmResponseJobsSubscriber =
      data?.paginationData?.tablePageNum;
    this.rowsAlarmResponseJobsSubscriber = data?.paginationData?.tableRows;

    this.otherParamsAlarmResponseJobsSubscriber.paginationData = {
      ...data?.paginationData,
    };
    this.getAlarmResponseJobsSubscriber();
  }
  sortColumnAlarmResponseJobsSubscriber(event: any) {
    this.sortKeyAlarmResponseJobsSubscriber = event?.sortList;

    this.getAlarmResponseJobsSubscriber();
  }

  getPatrolHistorySubscriber() {
    this.spinnerService.show();
    let params: any = { subscriber: this.subscriberId };
    let body: any = { event_type: 'patrol', is_manager: true };
    if (this.rowsPatrolHistorySubscriber) {
      body['rows'] = this.rowsPatrolHistorySubscriber;
    }
    if (this.previousPatrolHistorySubscriber) {
      body['previous'] = this.previousPatrolHistorySubscriber;
    }
    if (this.searchParamsPatrolHistorySubscriber)
      body.search_filters = this.searchParamsPatrolHistorySubscriber;

    //  if (this.globalFilter) {
    //    params['name'] = this.globalFilter;
    //  }
    //  if (this.sortKey) {
    //    params['sort_key'] = this.sortKey;
    //  }
    if (this.miscPatrolHistorySubscriber?.invalid) {
      params['is_inactive'] = 1;
    }
    this.adminService
      .subscriberRelatedPOSTApis('events/', body, params)
      .subscribe((response: any) => {
        this.spinnerService.hide();
        this.patrolHistoryListSubscriber = response?.data;
        this.otherParamsPatrolHistorySubscriber.paginationData = {
          tableTotalRows: response?.total_size,
          tablePreviousRows: this.previousPatrolHistorySubscriber,
          tableRows: this.rowsPatrolHistorySubscriber,
          tablePageNum: this.pageNumPatrolHistorySubscriber,
          tableTotalPages: this.totalPagesPatrolHistorySubscriber,
        };
      });
  }
  onChangePaginationPatrolHistorySubscriber(data: any) {
    this.previousPatrolHistorySubscriber =
      data?.paginationData?.tablePreviousRows;
    this.pageNumPatrolHistorySubscriber = data?.paginationData?.tablePageNum;
    this.rowsPatrolHistorySubscriber = data?.paginationData?.tableRows;

    this.otherParamsPatrolHistorySubscriber.paginationData = {
      ...data?.paginationData,
    };
    this.getPatrolHistorySubscriber();
  }
  sortColumnPatrolHistorySubscriber(event: any) {
    this.sortKeyPatrolHistorySubscriber = event?.sortList;

    this.getPatrolHistorySubscriber();
  }
  searchColumnPatrolHistorySubscriber(event: any) {
    this.searchParamsPatrolHistorySubscriber = event?.searchParams || {};

    this.getPatrolHistorySubscriber();
  }
  onSelectHistoryDataSubscriber(event: any, template: TemplateRef<any>) {
    this.selectedEventDetailedInfo.selectedEvent = event;
    this.selectedEventDetailedInfo.scanDistanceInfo =
      checkScanDistanceGreater(event);
    this.dialogService.open(template, {
      context: {},
      dialogClass: 'model-full',
    });
  }

  getLastCommentEditedDateTime(history: any) {
    let sortedComments = history?.sort(
      (a: any, b: any) =>
        new Date(a?.updated_at).getTime() - new Date(b?.updated_at).getTime()
    );
    return sortedComments[sortedComments?.length - 1];
  }
  getPromptValue(promptData: any) {
    return Array.isArray(promptData?.value)
      ? promptData?.value?.join(', ')
      : promptData?.value;
  }
  getIncidentsSubscriber() {
    if (!this.miscUsersSubscriber.selectedUser?.id) return;
    this.spinnerService.show();
    let params: any = {
      subscriber: this.subscriberId,
      subscriber_user_id: this.miscUsersSubscriber.selectedUser?.id,
    };
    if (this.rowsIncidentsSubscriber) {
      params['rows'] = this.rowsIncidentsSubscriber;
    }
    if (this.previousIncidentsSubscriber) {
      params['previous'] = this.previousIncidentsSubscriber;
    }

    //  if (this.globalFilter) {
    //    params['name'] = this.globalFilter;
    //  }
    //  if (this.sortKey) {
    //    params['sort_key'] = this.sortKey;
    //  }
    if (this.miscIncidentsSubscriber?.invalid) {
      params['is_inactive'] = 1;
    }
    this.adminService
      .subscriberRelatedPOSTApis('incident/list_incidents', {}, params)
      .subscribe((response: any) => {
        this.spinnerService.hide();
        this.incidentListSubscriber = response?.data;
        this.otherParamsIncidentsSubscriber.paginationData = {
          tableTotalRows: response?.total_size,
          tablePreviousRows: this.previousIncidentsSubscriber,
          tableRows: this.rowsIncidentsSubscriber,
          tablePageNum: this.pageNumIncidentsSubscriber,
          tableTotalPages: this.totalPagesIncidentsSubscriber,
        };
      });
  }
  onChangePaginationIncidentsSubscriber(data: any) {
    this.previousIncidentsSubscriber = data?.paginationData?.tablePreviousRows;
    this.pageNumIncidentsSubscriber = data?.paginationData?.tablePageNum;
    this.rowsIncidentsSubscriber = data?.paginationData?.tableRows;

    this.otherParamsIncidentsSubscriber.paginationData = {
      ...data?.paginationData,
    };
    this.getIncidentsSubscriber();
  }
  sortColumnIncidentsSubscriber(event: any) {
    this.sortKeyIncidentsSubscriber = event?.sortList;

    this.getIncidentsSubscriber();
  }
  searchColumnIncidentSubscriber(event: any) {
    this.searchParamsIncidentsSubscriber = event?.searchParams || {};

    this.getIncidentsSubscriber();
  }
  onSelectIncidentsSubscriber(event: any, template: TemplateRef<any>) {
    if (!this.miscUsersSubscriber.selectedUser?.id) return;
    this.spinnerService.show();
    let params: any = {
      subscriber: this.subscriberId,
      subscriber_user_id: this.miscUsersSubscriber.selectedUser?.id,
    };
    this.adminService
      .subscriberRelatedGETApis(`incident/${event?.id}`, params)
      .subscribe((response: any) => {
        this.spinnerService.hide();

        this.miscIncidentsSubscriber.detailData = response?.data;
        this.miscIncidentsSubscriber.emailRecipients =
          response?.recipient_emails?.join('\n ');
        this.miscIncidentsSubscriber.isCreatedUser = Boolean(
          this.miscUsersSubscriber.selectedUser?.id ===
            this.miscIncidentsSubscriber?.detailData?.subscriber_user?.id
        );
        this.miscIncidentsSubscriber.isIncidentSubmitted = Boolean(
          this.miscIncidentsSubscriber.detailData?.submitted_at
        );
        this.miscIncidentsSubscriber.incidentCommentFiles = [
          ...response?.history?.filter((item: any) =>
            //1 = added comment
            //2 = added photo
            //11 = uploaded photo
            [2, 11].includes(item?.event_action)
          ),
        ];
        this.dialogService.open(template, {
          context: {},
          dialogClass: 'model-full',
        });
      });
  }
  getUsersSubscriber() {
    this.spinnerService.show();
    let params: any = { subscriber: this.subscriberId };
    if (this.rowsUsersSubscriber) {
      params['rows'] = this.rowsUsersSubscriber;
    }
    if (this.previousUsersSubscriber) {
      params['previous'] = this.previousUsersSubscriber;
    }

    //  if (this.globalFilter) {
    //    params['name'] = this.globalFilter;
    //  }
    //  if (this.sortKey) {
    //    params['sort_key'] = this.sortKey;
    //  }
    if (this.miscUsersSubscriber?.invalid) {
      params['is_inactive'] = 1;
    }
    this.adminService
      .subscriberRelatedGETApis('user', params)
      .subscribe((response: any) => {
        this.spinnerService.hide();
        this.userListSubscriber = response?.data;
        if (!this.miscUsersSubscriber.selectedUser)
          this.miscUsersSubscriber.selectedUser = this.userListSubscriber?.find(
            (user: any) => user?.user_group?.length === 4
          );
        this.getIncidentsSubscriber();
        this.otherParamsUsersSubscriber.paginationData = {
          tableTotalRows: response?.total_size,
          tablePreviousRows: this.previousUsersSubscriber,
          tableRows: this.rowsUsersSubscriber,
          tablePageNum: this.pageNumUsersSubscriber,
          tableTotalPages: this.totalPagesUsersSubscriber,
        };
      });
  }
  onChangePaginationUsersSubscriber(data: any) {
    this.previousUsersSubscriber = data?.paginationData?.tablePreviousRows;
    this.pageNumUsersSubscriber = data?.paginationData?.tablePageNum;
    this.rowsUsersSubscriber = data?.paginationData?.tableRows;

    this.otherParamsUsersSubscriber.paginationData = {
      ...data?.paginationData,
    };
    this.getUsersSubscriber();
  }
  sortColumnUsersSubscriber(event: any) {
    this.sortKeyUsersSubscriber = event?.sortList;

    this.getUsersSubscriber();
  }
  searchColumnUserSubscriber(event: any) {
    this.searchParamsUsersSubscriber = event?.searchParams || {};

    this.getUsersSubscriber();
  }
  selectUserSubscriber(event: any) {
    this.miscUsersSubscriber.selectedUser = event;
  }
}
