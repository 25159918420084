<div class="gy-3 row row-cols-md-2" *ngIf="listLicenseData?.length">
  <!-- Card Sections split into 3 sections-->
  <div *ngFor="let data of listLicenseData">
    <nb-card
      [ngStyle]="cardGroupDetails ? cardGroupDetails(data) : ''"
      class="contact-card"
    >
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="table-card-data pointer">
            <div *ngFor="let indCard of cardIndividualDetails">
              <!--row object is a datetime object-->
              <div
                *ngIf="indCard?.datetimeObj == true"
                [ngStyle]="indCard?.cardRowStyle"
              >
                {{
                  indCard.nestedValue
                    ? (indCard.nestedValue(data)
                      | date
                        : (indCard.dateTimeFormat
                            ? indCard.dateTimeFormat
                            : "d MMM y HH:mm"))
                    : (data[indCard.name]
                      | date
                        : (indCard.dateTimeFormat
                            ? indCard.dateTimeFormat
                            : "d MMM y HH:mm"))
                }}
              </div>
              <!--row object is a normal string-->
              <div
                *ngIf="indCard?.datetimeObj != true"
                [ngStyle]="indCard?.cardRowStyle"
              >
                {{
                  indCard.nestedValue
                    ? indCard.nestedValue(data)
                    : data[indCard.name]
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nb-card>
  </div>
</div>
