import { Component, OnInit, Optional } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from '../../users/users.service';
import { AddEditUsersComponent } from '../../users/add-edit-users/add-edit-users.component';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-add-edit-view-user-group',
  templateUrl: './add-edit-view-user-group.component.html',
  styleUrls: ['./add-edit-view-user-group.component.scss'],
})
export class AddEditViewUserGroupComponent implements OnInit {
  selectedUserGroup: any;

  userListData: any;

  groupId: any;
  userDetailsTableView: any = [
    {
      header: 'Name',
      name: 'full_name',
      nestedValue: (row: any) => {
        if (row?.['full_name']) return row?.['full_name'];
        else return '[USER NOT SIGNED-IN YET]';
      },
      sortKey: 'full_name',
    },
    {
      header: 'Email',
      name: 'email',
    },
  ];

  constructor(
    @Optional() protected dialogRef: NbDialogRef<AddEditViewUserGroupComponent>,
    private userService: UsersService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private pageService: PagesService
  ) {
    this.route.params.subscribe({
      next: (params) => {
        if (params?.['uGKey']) {
          this.getUserGroupById(params?.['uGKey']);
        }
      },
    });
  }

  ngOnInit(): void {}

  formatData(data: any) {
    this.selectedUserGroup = data;
    this.groupId = this.selectedUserGroup?.group_id;

    this.userListData = this.selectedUserGroup?.users;
  }

  getUserGroupById(id: Number) {
    this.spinnerService.show();
    this.userService.getUserGroupById(id).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.formatData(response['data']);
      } else {
        this.pageService.setMessage({
          errorMessage: response['message'],
          successMessage: '',
        });
      }
      this.spinnerService.hide();
    });
  }
  onCloseDialogue(val = 'submit') {
    this.dialogRef.close(val);
  }

  adminCheck(user: any) {
    if (this.groupId === 1) {
      if (user.is_admin) return false;
    }
    return true;
  }
  openUserDialogBox(user: any) {
    const dialogRefUser = this.dialogService.open(AddEditUsersComponent, {
      context: {
        editUser: true,
        detailData: user,
      },
    });
    dialogRefUser.onClose.subscribe((value: any) => {
      if (value === 'Yes') {
      }
    });
  }
}
