<nb-card class="gt-p-small">
  <div class="sc-head4 strong sc-text-center">
    {{ selectedUserGroup?.name }}
  </div>
  <div class="mobile-only">
    <div
      class="row row-cols-md-2 row-cols-xxl-3 row-cols-xxxl-6"
      *ngIf="userListData?.length"
    >
      <div *ngFor="let assignee of userListData" class="col gy-2">
        <nb-card class="contact-card" (click)="openUserDialogBox(assignee)">
          <div class="table-card-data">
            <div>{{ assignee.full_name }}</div>
            <div>
              <span>
                {{ assignee.email }}
              </span>
            </div>
          </div>
        </nb-card>
      </div>
    </div>
  </div>
  <div class="large-view-only">
    <app-table
      [desktopTableData]="userListData"
      [desktopTableDetails]="userDetailsTableView"
      (rowClick)="openUserDialogBox($event)"
    ></app-table>
  </div>
</nb-card>
