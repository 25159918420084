<nb-card class="dialog-box">
  <nb-card-header>
    {{ title }}
    <nb-icon
      (click)="onDismiss()"
      [icon]="'close-outline'"
      class="sc-floating-right close-icon"
      pack="eva"
    ></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <div *ngIf="message">
      <nb-alert *ngIf="alertDanger" [status]="alertStatus">{{
        message
      }}</nb-alert>
      <p
        *ngIf="!alertDanger"
        [innerHTML]="message"
        [ngStyle]="messageStyle"
      ></p>
    </div>
    <!-- TODO: Add a email validation -->
    <div *ngIf="body">
      <div class="form-floating">
        <input
          fullWidth
          type="email"
          nbInput
          [(ngModel)]="bodyData"
          class="form-control"
          placeholder="New Email Address"
          autocorrect="off"
          autocapitalize="off"
          #emailInput="ngModel"
        />
        <label>New Email</label>
      </div>
      <div
        *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)"
        class="text-danger"
      >
        Please enter a valid email address.
      </div>
      <div class="row row-cols-2">
        <div class="col" (click)="onDismiss()">
          <button tabindex="-1" nbButton ghost size="giant" fullWidth>
            Cancel
          </button>
        </div>
        <div id="confirmBtn" class="col" (click)="onConfirm()">
          <button
            fullWidth
            nbButton
            size="giant"
            [disabled]="emailInput.invalid"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="confirmation">
      <div class="sc-text-center">
        To confirm, type {{ randomNUmber }} in the field:
      </div>
      <input
        fullWidth
        type="tel"
        placeholder="{{ randomNUmber }}"
        nbInput
        [(ngModel)]="confirmNumber"
        (keyup.enter)="onConfirm()"
      />
    </ng-container>
    <div class="row row-cols-2" *ngIf="!body">
      <div class="col" (click)="onDismiss()">
        <button tabindex="-1" nbButton ghost size="giant" fullWidth>
          Cancel
        </button>
      </div>
      <div id="confirmBtn" class="col">
        <button
          fullWidth
          nbButton
          size="giant"
          (click)="onConfirm()"
          [disabled]="confirmation && confirmNumber != randomNUmber"
        >
          Confirm
        </button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
