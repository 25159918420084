import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  NbDialogRef,
  NbStepperComponent,
  NbToastrService,
} from '@nebular/theme';
import { AppService } from 'src/app/app.service';
import { ProfileService } from '../profile/profile.service';
import { UsersService } from '../users/users.service';
import { formatDate } from '@angular/common';
import { PagesService } from '../pages.service';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-app-checks',
  templateUrl: './app-checks.component.html',
  styleUrls: ['./app-checks.component.scss'],
})
export class AppChecksComponent implements OnInit {
  passwordForm: UntypedFormGroup = new UntypedFormGroup({
    password: new UntypedFormControl(null, [
      Validators.required,
      Validators.minLength(8),
    ]),
    confirm_password: new UntypedFormControl(),
    v_code: new UntypedFormControl(null),
  });
  verifyForm: any;
  nameForm: any;
  userPhoneForm: UntypedFormGroup = new UntypedFormGroup({
    last_4: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(4),
      Validators.minLength(4),
    ]),
  });
  showErrorMsg: boolean = false;
  mobileNumberTries: number = 3;

  newlicense: any;
  showLicenseFields: any;
  profileForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(''),
    first_name: new UntypedFormControl('', Validators.required),
    last_name: new UntypedFormControl('', Validators.required),
    mobile_number: new UntypedFormControl(null),
  });
  licenseForm: UntypedFormGroup = new UntypedFormGroup({
    issuer_state: new UntypedFormControl(null, [Validators.required]),
    license_class: new UntypedFormControl(null, [Validators.required]),
    license_number: new UntypedFormControl(null, [Validators.required]),
    valid_till: new UntypedFormControl(null, [Validators.required]),
  });
  guardLicenseForm: any;
  classData: any = [];
  showLicenseAddClass: boolean = false;
  stateData: any;
  licenseClassData: any;
  userData: any;
  isSuperUser: any;
  startForm: any;
  endForm: any;
  selectedItem: any;

  currentStatus: string = 'profileDetails';
  showPassword: boolean = false;
  today = new Date();
  addCancelButton: boolean = false;

  constructor(
    private appService: AppService,
    private dialogRef: NbDialogRef<AppChecksComponent>,
    private profileService: ProfileService,
    private userService: UsersService,
    private router: Router,
    private pageService: PagesService,
    private dataCheckService: DataCheckService,
    private spinnerService: NgxSpinnerService
  ) {
    this.userData = this.appService.getUserData();
    this.isSuperUser = this.dataCheckService.isSuperUser();
    this.getStates();
    this.getCurrentStatus();
    this.today.setDate(this.today.getDate() - 1);
  }
  getCurrentStatus() {
    this.spinnerService.show();
    if (this.dataCheckService.hasPasswordSet() === true) {
      this.currentStatus = 'passwordSet';
      this.addCancelButton = true;
      this.spinnerService.hide();
    } else {
      this.currentStatus = 'profileDetails';
    }
  }
  onCloseDialogue() {
    this.dialogRef.close();
    window.localStorage.removeItem('resetPasswordPWA');
    window.sessionStorage.removeItem('resetPasswordPWA');
  }

  ngOnInit(): void {
    this.spinnerService.show();
    if (
      this.userData?.profile?.first_name ||
      this.userData?.profile?.last_name
    ) {
      this.profileForm.controls['first_name'].setValue(
        this.userData.profile.first_name
      );
      this.profileForm.controls['last_name'].setValue(
        this.userData.profile.last_name
      );
    }
    this.spinnerService.hide();
  }

  addNewClass(newLicence: string) {
    if (this.guardLicenseForm.value['issuer_state']) {
      let data = {
        issuer_state: this.guardLicenseForm.value['issuer_state'],
        license_class_name: newLicence,
      };
      this.userService.addNewClass(data).subscribe((response: any) => {
        this.classData = [...this.classData, response['data']];
        this.showLicenseAddClass = false;
        this.guardLicenseForm.controls['license_class'].setValue(
          response['data'].license_class_name
        );
      });
    } else {
      this.pageService.setMessage({
        successMessage: '',
        errorMessage: 'Please select the state to add new licence guard.',
      });
    }
  }

  getStates() {
    this.appService.getStates(1).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateData = response['data'];
      }
    });
  }

  selectState(data: any) {
    this.userService.getClassByState(data.id).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.classData = response['data'];
        this.licenseClassData = response['data'];
      } else {
      }
    });
  }

  onLicenceClassSearch(event: any) {
    this.showLicenseAddClass = false;
    if (event?.target?.value?.length === 0) {
      this.licenseClassData = this.classData;
    } else {
      this.licenseClassData = this.classData.filter((item: any) =>
        item.license_class_name
          ?.toLowerCase()
          .includes(event?.target?.value?.toLowerCase())
      );
      if (!this.licenseClassData?.length) this.showLicenseAddClass = true;
    }
  }

  updateProfileData() {
    if (this.profileForm.valid) {
      if (!this.profileForm.value?.mobile_number) {
        this.profileForm.controls['mobile_number'].setValue(null);
      }

      this.profileForm.controls['email'].setValue(this.userData.profile.email);
      let requestData = { data: this.profileForm.value };
      requestData['data'] = this.profileForm.value;
      if (this.licenseForm.valid) {
        this.licenseForm.value.valid_till = formatDate(
          this.licenseForm.value.valid_till,
          'yyyy-MM-dd',
          'en'
        );
        requestData.data['license_data'] = this.licenseForm.value;
      }
      if (this.isSuperUser === true) {
        this.userService
          .editSuperAdmin(requestData, this.userData?.profile?.id)
          .subscribe((response: any) => {
            this.pageService.setMessage({
              successMessage: 'Profile Updated',
              errorMessage: '',
            });
            this.spinnerService.show();
            if (this.dataCheckService.hasPasswordSet() === false) {
              this.currentStatus = 'passwordSet';
              this.spinnerService.hide();
            } else {
              this.dialogRef.close('close');
            }

            this.userData.profile = response;
            this.appService.setUserData(this.userData);
            // this.showProfileDlg = false;
            // this.dialogRef.close('close');
          });
      } else {
        this.profileService
          .updateProfileDetails(requestData)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.pageService.setMessage({
                errorMessage: '',
                successMessage: response['message'],
              });
              if (this.dataCheckService.hasPasswordSet() === false) {
                this.currentStatus = 'passwordSet';
              } else {
                this.dialogRef.close('close');
              }

              this.userData.profile = response['data'];
              this.appService.setUserData(this.userData);
              // this.showProfileDlg = false;
              // this.dialogRef.close('close');
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    }
  }
  changePassword() {
    this.spinnerService.show();
    this.userData = this.appService.getUserData();
    {
      if (this.isSuperUser === true) {
        this.profileService
          .changeAdminPassword(this.passwordForm.value)
          .subscribe((response) => {
            if (response['status'] == 'success') {
              let userData = this.appService.getUserData();
              userData.user_token = response.user_token;
              userData.checks.password_set = true;
              localStorage.removeItem('linkfrom');
              this.appService.setUserData(userData);
              this.dialogRef.close('close');
            }
            this.spinnerService.hide();
          });
      } else {
        this.profileService
          .changePassword(this.passwordForm.value)
          .subscribe((response) => {
            if (response['status'] == 'success') {
              this.pageService.setMessage({
                errorMessage: '',
                successMessage: response['message'],
              });
              let userData = this.appService.getUserData();
              userData.user_token = response.user_token;
              userData.checks.password_set = true;
              localStorage.removeItem('linkfrom');
              localStorage.removeItem('resetPasswordPWA');
              sessionStorage.removeItem('resetPasswordPWA');
              this.appService.setUserData(userData);
              this.dialogRef.close('close');
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.spinnerService.hide();
          });
      }
    }
  }
  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
