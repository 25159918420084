<nb-card>
  <nb-tabset>
    <nb-tab tabTitle="Plans">
      <div class="sc-mt-6 sc-floating-right">
        <button
          size="giant"
          nbButton
          (click)="disabledPlans = !disabledPlans; getAllSubscriptionPlans()"
        >
          {{ disabledPlans ? "Active Plans" : "Disabled Plans" }}
        </button>
      </div>
      <div class="sc-mt-6">
        <button
          size="giant"
          nbButton
          (click)="openAddSubsriptionPlan()"
          status="primary"
        >
          Add Plan
        </button>
      </div>

      <div>
        <app-gt-table
          [desktopTableBackendData]="subscriptionTableData?.results"
          [desktopTableDetails]="planTableDetail.columns"
          [tableActionIcons]="planTableDetail.actionColumn"
          [otherParams]="otherParamsPlan"
          (actionClick)="actionClick($event, planSubscribersTemplate)"
          (paginationChange)="onChangePagination($event)"
        >
        </app-gt-table>
      </div>
    </nb-tab>
    <nb-tab tabTitle="Coupons">
      <div class="sc-mt-6">
        <button
          size="giant"
          nbButton
          (click)="openCouponTemplate(couponTemplate)"
          status="primary"
        >
          Add Coupon
        </button>
      </div>
      <div>
        <app-gt-table
          [desktopTableBackendData]="couponListData?.results"
          [desktopTableDetails]="couponTableDetail.columns"
          [tableActionIcons]="couponTableDetail.actionColumn"
          (actionClick)="onCouponActionClick($event, couponTemplate)"
          [otherParams]="otherParams"
        >
        </app-gt-table>
      </div>
    </nb-tab>
  </nb-tabset>
</nb-card>

<ng-template
  #planSubscribersTemplate
  let-data
  let-planSubscribersTemplate="dialogRef"
>
  <nb-card class="dialog-box">
    <nb-card-header
      >Subscribers
      <nb-icon
        (click)="planSubscribersTemplate.close()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <app-table
        [desktopTableData]="data"
        [desktopTableDetails]="subscriberPlanDetailTable"
        (rowClick)="onSubscriberRowClick($event)"
      >
      </app-table>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #couponTemplate let-data let-couponTemplate="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header
      >{{ data?.id ? "Edit" : "Add" }} Coupon
      <nb-icon
        (click)="couponTemplate.close(); onClearCouponForm()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <form [formGroup]="couponForm">
        <div class="form-floating">
          <input
            class="form-control"
            fullWidth
            formControlName="name"
            nbInput
            type="text"
            placeholder="Coupon Name"
          />
          <label>Coupon Name</label>
        </div>
        <label> Coupon Type</label>
        <nb-radio-group
          class="d-flex justify-content-center"
          formControlName="coupon_type"
        >
          <nb-radio *ngFor="let _type of couponTypes" [value]="_type[0]">
            {{ _type[1] | titlecase }}
          </nb-radio>
        </nb-radio-group>
        <nb-form-field>
          <div class="form-floating">
            <input
              class="form-control"
              fullWidth
              formControlName="code"
              nbInput
              type="text"
              placeholder="Coupon Code"
              [minlength]="6"
              [maxlength]="10"
            />
            <label>Coupon Code</label>
          </div>
          <nb-icon
            (click)="generateCouponCode()"
            [icon]="'plus-outline'"
            nbSuffix
            pack="eva"
          ></nb-icon>
        </nb-form-field>

        <div
          class="form-floating"
          nbTooltip="The number of emails/accounts this coupon can be used"
        >
          <input
            class="form-control"
            fullWidth
            formControlName="usage_limit"
            nbInput
            pattern="^\d*(\.\d{0,2})?$"
            type="number"
            placeholder="Coupon Usage Limit"
          />
          <label>Coupon Usage Limit</label>
        </div>

        <div
          class="form-floating"
          nbTooltip="The number indicates the number of times this coupon will be applied to the same subscriber on periodic subscription"
        >
          <input
            class="form-control"
            fullWidth
            formControlName="repeatable"
            nbInput
            pattern="^\d*(\.\d{0,2})?$"
            type="number"
            placeholder="Repeat Count"
          />
          <label>Repeat Count</label>
        </div>
        <div class="d-flex gt-p-1">
          <div
            *ngIf="[2].includes(couponForm.value?.coupon_type)"
            class="gt-p-1"
          >
            <nb-checkbox formControlName="include_users"
              >Include Users</nb-checkbox
            >
          </div>
          <div
            class="gt-p-1"
            *ngIf="[2].includes(couponForm.value?.coupon_type)"
          >
            <nb-checkbox formControlName="include_transactions"
              >Include Transactions</nb-checkbox
            >
          </div>
        </div>

        <div class="form-floating" *ngIf="couponForm.value?.coupon_type === 1">
          <input
            class="form-control"
            fullWidth
            formControlName="trial_period"
            nbInput
            pattern="^\d*(\.\d{0,2})?$"
            type="number"
            placeholder="Enter Number of Days"
          />
          <label>Trial Period(Days)</label>
        </div>

        <div class="form-floating" *ngIf="couponForm.value?.coupon_type === 2">
          <input
            class="form-control"
            fullWidth
            formControlName="discount_percentage"
            nbInput
            pattern="^\d*(\.\d{0,2})?$"
            type="number"
            placeholder="Enter Percent Amount"
          />
          <label>Discount Percent</label>
        </div>

        <div class="form-floating" *ngIf="couponForm.value?.coupon_type === 3">
          <input
            class="form-control"
            fullWidth
            formControlName="discount_amount"
            nbInput
            pattern="^\d*(\.\d{0,2})?$"
            type="number"
            placeholder="Enter Amount"
          />
          <label>Discount Amount</label>
        </div>

        <div *ngIf="couponForm.value?.coupon_type === 4">
          <div>
            <nb-checkbox
              [checked]="lockPlan"
              (checkedChange)="lockPlan = !lockPlan"
              >Lock Plan</nb-checkbox
            >
          </div>
          <div *ngIf="effectivePlan?.id">
            <div class="strong mb-2 mt-2">
              <span class="sc-floating-right">
                <nb-icon
                  pack="eva"
                  icon="close-outline"
                  (click)="effectivePlan = null"
                ></nb-icon> </span
              >Over ride plan
            </div>

            <div>
              <span class="badge-key">Plan Name</span>
              <span class="badge-value"> {{ effectivePlan?.name }}</span>
            </div>
            <div>
              <span class="badge-key">Plan Price</span>
              <span class="badge-value"> {{ effectivePlan?.amount }} AUD</span>
            </div>
            <ng-container *ngIf="effectivePlan?.plan_type?.id === 1">
              <div>
                <span class="badge-key">Users</span>
                <span class="badge-value">
                  {{ effectivePlan?.user_cost }}$ per user</span
                >
              </div>
            </ng-container>
            <ng-container *ngIf="effectivePlan?.plan_type?.id === 2">
              <div>
                <span class="badge-key">Trasnactions</span>
                <span class="badge-value">
                  {{ effectivePlan?.transaction_price }}$ per user</span
                >
              </div>
            </ng-container>
            <div>
              <nb-alert outline="warning" role="alert">
                This coupon can only be applied to
                {{
                  effectivePlan?.plan_type?.id === 2 ? "transaction" : "user"
                }}
                based plans.
              </nb-alert>
            </div>
          </div>

          <div class="form-floating" *ngIf="!effectivePlan?.id">
            <input
              class="form-control"
              type="text"
              nbTagInput
              fullWidth
              placeholder="Over riding Plan"
              [nbAutocomplete]="autosubOverRidePlanNgModel"
            />
            <label>Over riding Plan</label>
          </div>
          <nb-autocomplete #autosubOverRidePlanNgModel>
            <nb-option (click)="openAddSubsriptionPlan(true)"
              >Add Protected Plan
            </nb-option>
            <nb-option
              *ngFor="let plan of protectdPlans"
              (click)="effectivePlan = plan"
              >{{ plan.name }}
            </nb-option></nb-autocomplete
          >
        </div>

        <div>
          <nb-tag-list class="nb-tag-list">
            <nb-tag
              *ngFor="let plan of selectedPlans"
              [text]="plan.name"
              removable
              (remove)="removePlan(plan)"
            ></nb-tag>
          </nb-tag-list>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              nbTagInput
              nbTooltip="coupons will be applicable to the selected plans if any"
              fullWidth
              placeholder="Applicable  Plans"
              [nbAutocomplete]="autosubPlanNgModel"
            />
            <label>Applicable Plans</label>
          </div>
          <nb-autocomplete #autosubPlanNgModel>
            <nb-option *ngFor="let plan of normalPlans" (click)="addPlan(plan)"
              >{{ plan.name }}
            </nb-option></nb-autocomplete
          >
        </div>
        <div>
          <nb-tag-list class="nb-tag-list">
            <nb-tag
              *ngFor="let group of selectedSubscribers"
              [text]="group.name"
              removable
              (remove)="removeSubscribers(group)"
            ></nb-tag>
          </nb-tag-list>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              nbTooltip="coupons will be applicable to the selected accounts if any"
              nbTagInput
              fullWidth
              placeholder="Select/Search Applicable  Subscribers"
              (input)="searchSubscribers($event)"
              [nbAutocomplete]="autoGrpNgModel"
            />
            <label>Applicable Subscribers</label>
          </div>
          <nb-autocomplete #autoGrpNgModel>
            <nb-option
              *ngFor="let subscriber of dropdownSubscribers?.results"
              (click)="
                addSubscribers(subscriber?.subscriber_id, subscriber?.name)
              "
              >{{ subscriber?.name }}
            </nb-option></nb-autocomplete
          >
        </div>

        <div class="row row-cols-2">
          <div class="col-12" *ngIf="data?.id">
            <button
              type="button"
              (click)="
                couponForm.value?.is_deleted
                  ? (couponForm.value.is_deleted = false)
                  : (couponForm.value.is_deleted = true);
                createUpdateCoupon();
                couponTemplate.close()
              "
              nbButton
              size="giant"
              [status]="couponForm.value.is_deleted ? 'success' : 'danger'"
              hero
              fullWidth
            >
              {{ couponForm.value.is_deleted ? "Restore" : "Disable" }}
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              (click)="onClearCouponForm()"
              nbButton
              size="giant"
              ghost
              fullWidth
            >
              Clear
            </button>
          </div>
          <div class="col">
            <button
              [disabled]="checkCouponFormValidity()"
              nbButton
              size="giant"
              fullWidth
              (click)="createUpdateCoupon(); couponTemplate.close()"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</ng-template>
