import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss'],
})
export class WhatsNewComponent implements OnInit {
  appVersionUpdates: any;

  constructor(private dialogRef: NbDialogRef<WhatsNewComponent>) {}

  ngOnInit(): void {}
  onDismis(close?: any) {
    // Close the dialog, return false
    this.dialogRef.close(close);
  }
}
