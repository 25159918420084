import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NbAuthComponent } from '@nebular/auth';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { ErrorPage503Component } from '../error-page503/error-page503.component';
import { BetaRegisterComponent } from './beta-register/beta-register.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ExternalAppComponent } from './external-app/external-app.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RegistrationComponent } from './registration/registration.component';

const routes: Routes = [
  {
    path: '',
    component: NbAuthComponent,
    children: [
      { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
      {
        path: 'error',
        component: ErrorPageComponent,
      },
      {
        path: 'delete-account',
        component: DeleteAccountComponent,
      },

      {
        path: 'login/:key',
        component: LoginComponent,
        canActivate: [AuthGuard],
      },

      {
        path: 'register',
        component: RegistrationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'activate/:key',
        component: LoginComponent,
        canActivate: [AuthGuard],
      },

      {
        // this path is for completing the regsitration process of beta users
        path: 'beta/:key',
        component: BetaRegisterComponent,
        canActivate: [AuthGuard],
      },

      {
        path: 'gt/:key',
        component: ExternalAppComponent,
      },
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: '503',
        component: ErrorPage503Component,
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
