import { Clipboard } from '@angular/cdk/clipboard';
import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { PagesService } from '../../pages.service';
import { AdminService } from '../admin.service';
import { AddSubscriptionPlanComponent } from './add-subscription-plan/add-subscription-plan.component';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss'],
})

// PLAN_TYPE_USER = 1, _("user")
// PLAN_TYPE_TRANSACTION = 2, _("transaction")
export class SubscriptionPlansComponent implements OnInit {
  subscriptionPlanForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    plan_interval: new UntypedFormControl('', Validators.required),
    plan_interval_name: new UntypedFormControl(),
    plan_level: new UntypedFormControl('', Validators.required),
    plan_level_name: new UntypedFormControl(),
    plan_type: new UntypedFormControl(1, Validators.required),
    amount: new UntypedFormControl(0, Validators.required),
    is_active: new UntypedFormControl(true),
    discount: new UntypedFormControl(),
    pricing_description: new UntypedFormControl(),
    is_protected: new UntypedFormControl(false),
    sort_value: new UntypedFormControl(0, Validators.required),
    user_cost: new UntypedFormControl(0, Validators.required),
    allowed_transactions: new UntypedFormControl(0),
    transaction_price: new UntypedFormControl(0),
    allowed_users: new UntypedFormControl(1, Validators.required),
    trial_days: new UntypedFormControl(0),
    trial_users_allowed: new UntypedFormControl(0),
  });

  couponForm: UntypedFormGroup = new UntypedFormGroup({
    coupon_type: new UntypedFormControl('', Validators.required),
    code: new UntypedFormControl('', Validators.required),
    usage_limit: new UntypedFormControl(0, Validators.required),
    name: new UntypedFormControl('', Validators.required),
    is_deleted: new UntypedFormControl(false),
    details: new UntypedFormControl(),
    repeatable: new UntypedFormControl(0),
    trial_period: new UntypedFormControl(),
    discount_percentage: new UntypedFormControl(),
    discount_amount: new UntypedFormControl(),
    include_transactions: new UntypedFormControl(),
    include_users: new UntypedFormControl(),
  });

  otherParams: any;

  subscriptionTableData: any;
  totalSubscrptionPlans: any;
  previous: number = 0;
  pageNum: number = 1;
  rows: number = 10;
  totalRows: number = 0;
  totalPages: number = 0;

  normalPlans: any = [];

  disabledPlans: boolean = false;

  couponTableDetail = {
    columns: [
      { header: 'Coupon Name', name: 'name' },
      { header: 'Code', name: 'code' },
      { header: 'Usage Limit', name: 'usage_limit' },

      {
        header: 'Coupon Type',
        nestedValue: (row: any) => {
          return row?.coupon_type?.name;
        },
      },
      {
        header: 'Repeat',
        nestedValue: (row: any) => {
          return row?.details?.repeatable;
        },
      },
      {
        header: 'Lock Plan',
        nestedValue: (row: any) => {
          return row?.details?.lock_plan;
        },
      },
      {
        header: 'Created On',
        name: 'created_at',
        datetimeObj: true,
      },
      { header: 'Disabled', name: 'is_deleted' },
      { header: 'Action', name: '', showAction: true },
    ],
    actionColumn: [
      {
        icon: 'copy-outline',
        status: 'primary',
        title: 'Copy Coupon',
        type: 'copy',
        show: 'inline',
      },
      {
        icon: 'edit-2-outline',
        status: 'primary',
        confirm: true,
        type: 'edit',
        show: 'inline',
        title: 'Edit Coupon',
      },
    ],
  };
  planTableDetail = {
    columns: [
      { header: 'Plan Slot', name: 'sort_value' },
      { header: 'Name', name: 'name' },
      {
        header: 'Amount',
        nestedValue: (row: any) => {
          return `${row?.['amount']} ${row?.['currency']}`;
        },
      },
      { header: 'Trial Days', name: 'trial_days' },
      { header: 'Trial Users', name: 'trial_users_allowed' },
      { header: 'Alwd Users', name: 'allowed_users' },
      { header: 'User Price', name: 'user_cost' },
      { header: 'Alwd Trn', name: 'allowed_transactions' },
      { header: 'Trn  Price', name: 'transaction_price' },
      { header: 'Subscriber Count', name: 'count' },
      {
        header: 'Plan Type',
        nestedValue: (row: any) => {
          return row?.plan_type?.name;
        },
      },
      {
        header: 'Plan level',
        nestedValue: (row: any) => {
          return row?.['plan_level']?.['name'];
        },
      },
      {
        header: 'Plan Interval',
        nestedValue: (row: any) => {
          return row?.['plan_interval']?.['name'];
        },
      },
      {
        header: 'Coupon',
        nestedValue: (row: any) => {
          if (row?.is_protected) {
            return '🟩';
          }
          return;
        },
      },
      {
        header: 'Created On',
        name: 'created_date',
        datetimeObj: true,
      },
      {
        header: 'Updated On',
        name: 'modified_date',
        datetimeObj: true,
      },
      { header: 'Action', name: '', showAction: true },
    ],
    actionColumn: [
      {
        icon: 'trash-outline',
        status: 'danger',
        confirm: true,
        title: 'Delete Plan',
        type: 'disable',
        show: 'inline',
        condition: function (row: any) {
          return row.is_active && row?.can_delete;
        },
      },
      {
        icon: 'person-done-outline',
        status: 'success',
        confirm: true,
        title: 'Enable Plan',
        type: 'enable',
        show: 'inline',
        condition: function (row: any) {
          return !row.is_active;
        },
      },

      {
        icon: 'person-delete-outline',
        status: 'warning',
        title: 'Disable Plan.',
        confirm: true,
        type: 'disable',
        show: 'inline',
        condition: function (row: any) {
          return row.is_active && !row?.can_delete;
        },
      },
      {
        icon: 'edit-2-outline',
        status: 'primary',
        confirm: true,
        type: 'edit',
        show: 'inline',
        title: 'Edit Subscription',
      },
      {
        icon: 'info-outline',
        status: 'primary',
        confirm: true,
        type: 'info',
        show: 'inline',
        title: 'View Subscribers',
      },
    ],
  };
  subscriberPlanDetailTable = [
    { header: 'Name', name: 'name' },
    {
      header: 'Started Date',
      name: 'subscription_date',
    },
    {
      header: 'Started End',
      name: 'subscription_end',
    },
  ];
  couponListData: any;
  selectedCoupon: any;
  couponTypes: any;
  dialogRef: any;
  selectedSubscribers: any = [];
  selectedPlans: any = [];
  dropdownSubscribers: any;
  effectivePlan: any;
  protectdPlans: any = [];
  activePlans: any = [];
  otherParamsPlan: any = {
    tableRowStyle: (rowData: any) => {
      if (!rowData.is_active) {
        return {
          opacity: '0.5',
        };
      }
      return {};
    },
  };
  lockPlan: boolean = false;
  constructor(
    private toastrService: NbToastrService,
    private _location: Location,
    private spinnerService: NgxSpinnerService,
    private adminService: AdminService,
    private dialogService: NbDialogService,
    private router: Router,
    private pageService: PagesService,
    private clipboard: Clipboard
  ) {
    this.getAllSubscriptionPlans();
  }

  ngOnInit(): void {}
  getNormalPlans() {
    this.spinnerService.show();
    let params: any = { normal: 1 };

    this.spinnerService.show();
    this.adminService
      .getAllSubscriptionPlansForAdmin(params)
      .subscribe((response: any) => {
        this.normalPlans = response || [];
      });
  }
  getProtectedPlans() {
    this.spinnerService.show();
    let params: any = { protected: 1 };

    this.spinnerService.show();
    this.adminService
      .getAllSubscriptionPlansForAdmin(params)
      .subscribe((response: any) => {
        this.protectdPlans = response || [];
      });
  }
  getAllSubscriptionPlans() {
    this.spinnerService.show();
    let params: any = {};

    if (this.rows) {
      params['page_size'] = this.rows;
    }
    if (this.previous) {
      params['page'] = this.pageNum;
    }
    if (this.disabledPlans) {
      params['disabled'] = 1;
    }
    this.spinnerService.show();
    this.adminService
      .getAllSubscriptionPlansForAdmin(params)
      .subscribe((response: any) => {
        this.subscriptionTableData = response;

        this.totalSubscrptionPlans = response.count;
        this.totalRows = response?.count;
        this.totalPages = Math.ceil(this.totalRows / this.rows);

        this.otherParamsPlan.paginationData = {
          tableTotalRows: response.count,
          tablePreviousRows: this.previous,
          tableRows: this.rows,
          tablePageNum: this.pageNum,
          tableTotalPages: this.totalPages,
        };
        this.spinnerService.hide();
      });
    this.getAllCoupons();
    this.getCouponTypes();
    this.getNormalPlans();
    this.getProtectedPlans();
  }
  patchFormValues(planData: any) {
    this.subscriptionPlanForm.addControl(
      'id',
      new UntypedFormControl(planData?.id)
    );
    this.subscriptionPlanForm.patchValue(planData);
    this.subscriptionPlanForm.controls['plan_level_name'].setValue(
      planData?.plan_level?.name
    );
    this.subscriptionPlanForm.controls['plan_interval_name'].setValue(
      planData?.plan_interval?.name
    );
    this.subscriptionPlanForm.controls['plan_level'].setValue(
      planData?.plan_level?.id
    );
    this.subscriptionPlanForm.controls['plan_interval'].setValue(
      planData?.plan_interval?.id
    );
    this.subscriptionPlanForm.controls['plan_type'].setValue(
      planData?.plan_type?.id
    );
  }

  actionClick(data: any, template?: TemplateRef<any>) {
    this.patchFormValues(data.event);
    if (data.type == 'info') {
      this.onRowClick(data?.event, template);
    } else if (data.type == 'edit') {
      const dialogRef = this.dialogService.open(AddSubscriptionPlanComponent, {
        context: {
          subscriptionPlanForm: this.subscriptionPlanForm,
          updateView: true,
        },
        dialogClass: 'model-full',
      });
      dialogRef.onClose.subscribe((value) => {
        if (value === 'close') {
          this.spinnerService.hide();
          this.getAllSubscriptionPlans();
        }
        this.subscriptionPlanForm.reset();
      });
    } else {
      if (data.type === 'enable') {
        this.subscriptionPlanForm.controls['is_active'].setValue(true);
      } else if (data.type === 'disable') {
        this.subscriptionPlanForm.controls['is_active'].setValue(false);
      }

      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        context: {
          confirmation: Boolean(data?.event?.can_delete),
          title: 'Are you sure?',
          message: `${
            data.type === 'enable'
              ? 'Enable'
              : data?.event?.can_delete
              ? 'Delete'
              : 'Disable'
          } this plan`,
        },
      });
      dialogRef.onClose.subscribe((value: any) => {
        if (value === 'Yes') {
          this.spinnerService.show();
          this.adminService
            .editSubscriptionPlan(this.subscriptionPlanForm.value)
            .subscribe((response: any) => {
              this.getAllSubscriptionPlans();
              this.spinnerService.hide();
            });
        }
      });
    }
  }

  onRowClick(data: any, template?: any) {
    if (data.count == 0) {
      this.toastrService.show('No Subscriber with this Plan');
    } else {
      data.expand = !data.expand;
      this.adminService
        .getSubscribersBySubscription(data.id)
        .subscribe((response: any) => {
          if (response) {
            const dialogRef = this.dialogService.open(template, {
              context: response,
              dialogClass: 'model-full',
            });
            dialogRef.onClose.subscribe((value) => {
              if (value === 'close') {
                this.spinnerService.hide();
                this.getAllSubscriptionPlans();
              }
            });
            let index = this.subscriptionTableData.results.findIndex(
              (item: any) => item.id == data.id
            );
            if (index != -1) {
              this.subscriptionTableData.results[index].expandedData = response;
            }
          }
        });
    }
  }
  openAddSubsriptionPlan(is_protected = false) {
    this.subscriptionPlanForm.controls['is_protected'].setValue(is_protected);
    const dialogRef = this.dialogService.open(AddSubscriptionPlanComponent, {
      context: {
        subscriptionPlanForm: this.subscriptionPlanForm,
      },
      dialogClass: 'model-full',
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'close') {
        this.spinnerService.hide();
        this.getAllSubscriptionPlans();
        this.getProtectedPlans();
      }
      this.subscriptionPlanForm.reset();
    });
  }
  goBack() {
    this._location.back();
  }

  onChangePagination(data: any) {
    this.previous = data?.paginationData?.tablePreviousRows;
    this.pageNum = data?.paginationData?.tablePageNum;
    this.rows = data?.paginationData?.tableRows;

    this.otherParamsPlan.paginationData = {
      ...data?.paginationData,
    };

    this.getAllSubscriptionPlans();
  }
  onSubscriberRowClick(data: any) {
    this.router.navigate([
      '/gtadmin/subscribers-detail',
      { sKey: String(data?.subscriber_id) },
    ]);
  }
  onClearCouponForm() {
    this.selectedSubscribers = [];
    this.selectedPlans = [];
    this.couponForm.reset();
  }
  openCouponTemplate(template: TemplateRef<any>) {
    this.selectedCoupon = undefined;
    this.couponForm.reset();
    this.dialogRef = this.dialogService.open(template, {
      context: {},
      dialogClass: 'model-full',
    });
  }

  getCouponTypes() {
    this.adminService.getCouponTypes().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.couponTypes = response?.data?.coupon_types;
      }
      this.spinnerService.hide();
    });
  }
  getAllCoupons() {
    this.adminService.getAllCoupons().subscribe((response: any) => {
      this.couponListData = response;

      this.spinnerService.hide();
    });
  }

  editCoupon(couponData: any, template: TemplateRef<any>) {
    this.selectedCoupon = couponData;
    this.selectedSubscribers = couponData?.details?.applicable_subscribers
      ?.length
      ? couponData?.details?.applicable_subscribers
      : [];
    this.selectedPlans = couponData?.details?.applicable_plans?.length
      ? couponData?.details?.applicable_plans
      : [];
    this.lockPlan = couponData?.details?.lock_plan;
    this.effectivePlan = couponData?.details?.effective_plan;
    this.couponForm.patchValue({
      repeatable: couponData?.details?.repeatable,
      code: couponData?.code,
      coupon_type: couponData?.coupon_type?.id,
      trial_period: couponData?.details?.trial_period,
      discount_percentage: couponData?.details?.discount_percentage,
      discount_amount: couponData?.details?.discount_amount,
      usage_limit: couponData?.usage_limit,
      is_deleted: couponData?.is_deleted,
      name: couponData?.name,
      include_transactions: couponData?.details?.include_transactions,
      include_users: couponData?.details?.include_users,
    });
    this.dialogRef = this.dialogService.open(template, {
      context: { id: couponData?.id },
      dialogClass: 'model-full',
    });
  }
  createUpdateCoupon() {
    this.spinnerService.show();

    // (TYPE_TRIAL = 1), _('trial');
    // (TYPE_DISCOUNT_PERCENT = 2), _('discount percent');
    // (TYPE_DISCOUNT_AMOUNT = 3), _('discount amount');
    //  TYPE_PLAN_OVERRIDE = 4, _("plan over-ride")
    let details: any = {
      repeatable: this.couponForm.value?.repeatable || 0,
    };

    const planIds = [
      ...new Set(this.selectedPlans?.map((plan: any) => plan?.id)),
    ];

    if (planIds?.length) {
      details['applicable_plans'] = planIds;
    }
    const subscriberIds = [
      ...new Set(
        this.selectedSubscribers?.map((subscriber: any) => subscriber?.id)
      ),
    ];

    if (subscriberIds?.length) {
      details['applicable_subscribers'] = subscriberIds;
    }
    if (this.effectivePlan?.id && this.couponForm.value?.coupon_type === 4) {
      details['effective_plan'] = this.effectivePlan?.id;
      details['lock_plan'] = this.lockPlan;
    }
    if (this.couponForm.value?.coupon_type === 1) {
      details['trial_period'] = this.couponForm.value?.trial_period;
    }
    if (this.couponForm.value?.coupon_type === 2) {
      details['discount_percentage'] =
        this.couponForm.value?.discount_percentage;
      details['include_transactions'] =
        this.couponForm.value?.include_transactions || false;
      details['include_users'] = this.couponForm.value?.include_users || false;
    }
    if (this.couponForm.value?.coupon_type === 3) {
      details['discount_amount'] = this.couponForm.value?.discount_amount;
      details['include_transactions'] =
        this.couponForm.value?.include_transactions || false;
      details['include_users'] = this.couponForm.value?.include_users || false;
    }
    let body = {
      coupon_type: this.couponForm.value?.coupon_type,
      usage_limit: this.couponForm.value?.usage_limit,
      code: this.couponForm.value?.code,
      details: details,
      is_deleted: this.couponForm.value?.is_deleted || false,
      name: this.couponForm.value?.name,
    };
    if (this.selectedCoupon?.id) {
      this.adminService
        .editCoupon(this.selectedCoupon.id, body)
        .subscribe((res: any) => {
          this.spinnerService.hide();
          this.onClearCouponForm();
          this.dialogRef?.close;
          this.couponListData = res;
          this.pageService.setMessage({
            errorMessage: '',
            successMessage: 'Coupon Updated',
          });
        });
    } else {
      this.adminService.createCoupon(body).subscribe((res: any) => {
        this.spinnerService.hide();
        this.onClearCouponForm();
        this.dialogRef?.close;
        this.couponListData = res;
        this.pageService.setMessage({
          errorMessage: '',
          successMessage: 'Coupon Created',
        });
        window.location.reload();
      });
    }
  }
  removePlan(planData: any): void {
    this.selectedPlans = this.selectedPlans.filter(
      (sId: any) => sId?.id !== planData?.id
    );
  }

  addPlan(planData: any): void {
    if (this.selectedPlans.some((plan: any) => plan?.id === planData?.id)) {
      this.pageService.setMessage({
        errorMessage: 'Plan Already added',
        successMessage: '',
      });
    } else {
      this.selectedPlans.push({
        id: planData?.id,
        name: planData?.name,
      });
    }
  }

  removeSubscribers(subscriber: any): void {
    this.selectedSubscribers = this.selectedSubscribers.filter(
      (sId: any) => sId?.id !== subscriber?.id
    );
  }

  addSubscribers(id: number, name: string): void {
    if (
      this.selectedSubscribers.some((subscriber: any) => subscriber?.id === id)
    ) {
      this.pageService.setMessage({
        errorMessage: 'Subscriber Already added',
        successMessage: '',
      });
    } else {
      this.selectedSubscribers.push({
        id: id,
        name: name,
      });
    }
  }
  searchSubscribers(event?: any) {
    let params: any = {
      page_size: 10,
      page: 1,
    };
    if (event?.target?.value?.length > 3) {
      params.subscriber_name = event?.target?.value;

      this.adminService
        .getSubscribersListForAdmin(params)
        .subscribe((response: any) => {
          this.dropdownSubscribers = response;
        });
    }
  }
  checkCouponFormValidity(): boolean {
    if (this.couponForm.value?.coupon_type === 1) {
      return !(this.couponForm.value?.trial_period && this.couponForm.valid);
    } else if (this.couponForm.value?.coupon_type === 2) {
      return !(
        this.couponForm.value?.discount_percentage && this.couponForm.valid
      );
    } else if (this.couponForm.value?.coupon_type === 3) {
      return !(this.couponForm.value?.discount_amount && this.couponForm.valid);
    } else if (this.couponForm.value?.coupon_type === 4) {
      return !(this.effectivePlan?.id && this.couponForm.valid);
    } else {
      return true;
    }
  }
  onCouponActionClick(couponData: any, template: TemplateRef<any>) {
    if (couponData?.type === 'copy') {
      this.clipboard.copy(couponData?.event?.code);
      this.toastrService.success(
        couponData?.event?.code,
        'Code copied to Clipboard!'
      );
    } else {
      this.editCoupon(couponData?.event, template);
    }
  }
  generateCode(length = 6): string {
    const characters = '0123456789ABCDEF';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters[Math.floor(Math.random() * characters.length)];
    }
    return result;
  }
  generateCouponCode(length = 6) {
    this.couponForm.controls['code'].setValue(this.generateCode(length));
  }
}
