<div class="card p-1 w-50 m-auto">
  <form [formGroup]="smarNetConnectForm" class="row-cols-1 gy-2">
    <div class="form-floating">
      <input
        class="form-control"
        nbInput
        fullWidth
        type="text"
        formControlName="username"
        id="username"
        placeholder="Username"
        autocorrect="off"
        autocapitalize="off"
      />
      <label for="username">User Name</label>
    </div>
    <div class="form-floating">
      <input
        class="form-control"
        nbInput
        fullWidth
        type="password"
        formControlName="password"
        id="password"
        placeholder="Password"
        autocorrect="off"
        autocapitalize="off"
      />
      <label for="password">Password</label>
    </div>

    <div class="form-floating">
      <input
        class="form-control"
        nbInput
        fullWidth
        type="number"
        formControlName="smart_net_id"
        id="smart_net_id"
        placeholder="Smart Net ID"
        autocorrect="off"
        autocapitalize="off"
      />
      <label for="smart_net_id">Smart Net ID</label>
    </div>

    <div class="col sc-mt-6">
      <button
        (click)="getData()"
        nbButton
        size="giant"
        fullWidth
        status="success"
        [disabled]="!smarNetConnectForm.valid"
      >
        Submit
      </button>
    </div>
  </form>
</div>
<nb-tabset>
  <nb-tab tabTitle="Jobs">
    <app-gt-table
      [desktopTableUIData]="smartNetJobList"
      [desktopTableDetails]="smartNetJobListTableDetail"
      [otherParams]="{ paginationData: true }"
      (rowClick)="onJobSelection($event, eventDetailRef)"
    >
    </app-gt-table>
  </nb-tab>
  <nb-tab tabTitle="Company">
    <app-gt-table
      [desktopTableUIData]="smartNetCompanyList"
      [desktopTableDetails]="smartNetCompanyListTableDetail"
      [otherParams]="{ paginationData: true }"
      (rowClick)="onCompanySelection($event, eventDetailRef)"
    >
    </app-gt-table>
  </nb-tab>
</nb-tabset>

<ng-template #eventDetailRef let-eventDetailRef="dialogRef">
  <nb-card>
    <nb-card-header
      >Event Detail
      <nb-icon
        (click)="eventDetailRef.close()"
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
      ></nb-icon
    ></nb-card-header>
  </nb-card>
  <div class="row row-cols-5">
    <div *ngFor="let key of objectKeys(eventDetail)">
      <div class="col">
        <ng-template
          *ngTemplateOutlet="
            dictLoopRef;
            context: { key: key, value: eventDetail }
          "
        ></ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-dictKey="key" let-dictValue="value" #dictLoopRef>
  <div class="badge-key">{{ dictKey }}</div>
  <ng-container *ngIf="isObject(dictValue[dictKey])">
    <div *ngFor="let innerKey of objectKeys(dictValue[dictKey])" class="ps-4">
      <ng-template
        *ngTemplateOutlet="
          dictLoopRef;
          context: { key: innerKey, value: dictValue[dictKey] }
        "
      ></ng-template>
    </div>
  </ng-container>
  <ng-container *ngIf="!isObject(dictValue[dictKey])">
    <div class="badge-value">{{ dictValue[dictKey] }}</div>
  </ng-container>
</ng-template>
