<div class="mobile-only">
  <div class="gt-logo-mobile">
    <img
      src="../../../assets/images/Guard_Tracker-Stacked.png"
      alt="Guard Tracker"
    />
  </div>
</div>
<div class="large-view-only">
  <div class="gt-logo-desktop">
    <img
      src="../../../assets/images/Guard_Tracker-Lateral_Long_default.png"
      alt="Guard Tracker"
    />
  </div>
</div>
<div class="sc-text-center">
  <h2>Error 404 Page Not Found</h2>
  <small>This page does not exist</small>
</div>

<button nbButton size="giant" matRipple fullWidth (click)="goToHome()">
  Go Back
</button>
