import { Component, OnInit } from '@angular/core';
import { PagesService } from '../pages.service';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'app-key-audit',
  templateUrl: './key-audit.component.html',
  styleUrls: ['./key-audit.component.scss'],
})
export class KeyAuditComponent implements OnInit {
  keyListData: any = [];
  constructor(
    private userService: UsersService,
    private pageService: PagesService
  ) {}

  ngOnInit(): void {
    this.getKeyList();
  }
  getKeyList() {
    let body: any = {
      event_type: 'site_keys',
    };

    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.keyListData = response['data'];
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }
}
