import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { browserGpsInfo } from 'src/global.variables';
import { PagesService } from '../pages.service';
import { ProfileService } from '../profile/profile.service';
import { sosAlertDetailView } from '../tableDetails';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'app-sos-alerts',
  templateUrl: './sos-alerts.component.html',
  styleUrls: ['./sos-alerts.component.scss'],
})
export class SosAlertsComponent implements OnInit {
  sosAlerts: any = [];

  selectedAlert: any;

  currentSOSAlertCount: number = 0;
  isAlertActive: boolean = false;

  acknowledgeForm: UntypedFormGroup = new UntypedFormGroup({
    inquired: new UntypedFormControl(false, Validators.required),
    comment: new UntypedFormControl(null, Validators.required),
  });

  initialValues = { ...this.acknowledgeForm.value };

  // sos alert history values
  @ViewChild('detailsSection')
  detailsSection!: ElementRef;

  selectedEvent: any;
  browserGpsInfo: any = browserGpsInfo;
  userEventDetails: any = sosAlertDetailView.mobileTable;
  userEventDetailsTableView: any = sosAlertDetailView.desktopTable;
  //pagination components
  previousSOSAlertsHistory: number = 0;
  pageSizeSOSAlertsHistory: number = 10;
  pageNumSOSAlertsHistory: number = 1;
  totalPagesSOSAlertsHistory: number = 0;
  totalRowsSOSAlertsHistory: number = 0;
  sortKeysSOSAlertsHistory: any = ['-updated_at'];
  searchParamsSOSAlertsHistory: any;
  sosAlertHistoryData: any = [];
  dateRangeSOSAlertsHistory: any;
  tableStyle = {
    'overflow': 'auto',
    'max-height': '600px',
  };
  guardAlertData: any;

  constructor(
    private userService: UsersService,
    private spinnerService: NgxSpinnerService,
    private profileService: ProfileService,
    private pageService: PagesService,
    private dialogService: NbDialogService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe({
      next: (params: any) => {
        try {
          const alertCounts = Number(params?.alertCounts);
          if (alertCounts && alertCounts !== this.currentSOSAlertCount) {
            this.currentSOSAlertCount = alertCounts;
            this.isAlertActive = true;

            this.getSOSAlerts();
            this.getSOSAlertsHistory();
          }
        } catch (error) {}
      },
    });
  }

  ngOnInit(): void {
    if (!this.currentSOSAlertCount) {
      this.getSOSAlerts();
      this.getSOSAlertsHistory();

      this.router.navigate([], {});
    }
    this.pageService.sosAlertActive.subscribe((value: any) => {
      this.isAlertActive = value === 'alertActive';
    });
  }

  getSOSAlerts() {
    this.spinnerService.show();

    let body: any = {
      event_type: 'sos_alerts',
      search_filters: {},
      rows: 25,
    };

    this.userService
      .getUserUpdateLog(body, { pending: 1 })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.sosAlerts = response?.data;
          if (this.sosAlerts?.length === 1) {
            this.showGps(0);
          }

          this.spinnerService.hide();
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
          this.spinnerService.hide();
        }
      });
  }
  showGps(selectedIndex: number) {
    this.sosAlerts?.forEach(
      (alert: any, index: number) => (alert.showGps = index === selectedIndex)
    );
  }
  openAlertTemplate(template: any, alert: any) {
    this.guardAlertData = alert;
    this.acknowledgeForm.reset(this.initialValues);
    this.dialogService.open(template, {
      context: { data: this.guardAlertData },
    });
  }
  muteAlert() {
    this.pageService.sosAlertActive.next('turnOff');
  }
  acknowledgeAlert() {
    this.spinnerService.show();
    this.profileService
      .updateUser(
        { alert_id: this.selectedAlert?.id, ...this.acknowledgeForm.value },
        { acknowledge_sos: 1 }
      )
      .subscribe((response: any) => {
        if (response?.status == 'success') {
          this.spinnerService.hide();
          this.pageService.setMessage({
            successMessage: response?.message,
            errorMessage: '',
          });

          this.sosAlerts = response?.data;
          if (this.sosAlerts?.length === 1) {
            setTimeout(() => {
              this.showGps(0);
            }, 500);
          }
          this.getSOSAlertsHistory();
          if (!this.sosAlerts?.length) {
            this.currentSOSAlertCount = 0;

            this.router
              .navigate(['/sos-alerts'])
              .then(() => window.location.reload());
          }
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response?.message,
          });
          this.spinnerService.hide();
        }
      });
  }
  getSOSAlertsHistory() {
    this.spinnerService.show();

    let body: any = {
      event_type: 'sos_alerts',
      search_filters: {},
    };

    if (this.dateRangeSOSAlertsHistory)
      body.date_range = this.dateRangeSOSAlertsHistory;
    if (this.sortKeysSOSAlertsHistory)
      body.sort_key = this.sortKeysSOSAlertsHistory;
    if (this.searchParamsSOSAlertsHistory)
      body.search_filters = this.searchParamsSOSAlertsHistory;
    if (this.pageSizeSOSAlertsHistory)
      body.rows = this.pageSizeSOSAlertsHistory;
    if (this.previousSOSAlertsHistory)
      body.previous = this.previousSOSAlertsHistory;

    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.sosAlertHistoryData = response['data'];

        this.totalRowsSOSAlertsHistory = response?.total_size;
        this.totalPagesSOSAlertsHistory = Math.ceil(
          this.totalRowsSOSAlertsHistory / this.pageSizeSOSAlertsHistory
        );

        this.spinnerService.hide();
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }
  onTableRowClickSOSAlertsHistory(event: any) {
    this.selectedEvent = event;
    this.tableStyle = {
      'overflow': 'auto',
      'max-height': '35vh',
    };

    setTimeout(() => {
      this.detailsSection.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    });
  }

  handleDateChangeSOSAlertsHistory(event: any) {
    if (event?.end) {
      this.dateRangeSOSAlertsHistory = event;

      this.getSOSAlertsHistory();
    }
  }
  sortColumnSOSAlertsHistory(body: any) {
    this.sortKeysSOSAlertsHistory = body?.sortList || [];
    this.searchParamsSOSAlertsHistory = body?.searchParams || {};
    this.getSOSAlertsHistory();
  }
  searchColumnSOSAlertsHistory(event: any) {
    this.dateRangeSOSAlertsHistory = event?.date || null;
    this.searchParamsSOSAlertsHistory = event?.searchParams || {};
    this.getSOSAlertsHistory();
  }
  hideDetails() {}

  onChangePaginationSOSAlertsHistory(event: any) {
    this.previousSOSAlertsHistory = event.previous;
    this.pageNumSOSAlertsHistory = event.pageNum;
    this.pageSizeSOSAlertsHistory = event.pageSize;
    this.getSOSAlertsHistory();
  }
}
