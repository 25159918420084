import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { convertTime12to24, convertTime24to12 } from 'src/global.variables';
import { PagesService } from '../../pages.service';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-admin-report',
  templateUrl: './admin-report.component.html',
  styleUrls: ['./admin-report.component.scss'],
})
export class AdminReportComponent implements OnInit {
  adminReportForm: UntypedFormGroup = new UntypedFormGroup({
    report_type: new UntypedFormControl(),
    period: new UntypedFormControl(1, Validators.required),
    mail_time: new UntypedFormControl('10:00', Validators.required),
    recipients: new UntypedFormControl(),
  });
  previousAdminReports: number = 0;
  pageNumAdminReports: number = 1;
  rowsAdminReports: number = 10;
  totalRowsAdminReports: number = 0;
  totalPagesAdminReports: number = 0;

  otherParamsAdminReports: any;

  adminReportsTableData: any = [
    {
      header: 'Recipients',
      nestedValue: (row: any) => {
        return row?.recipients
          ?.map((item: any) => {
            return item?.full_name;
          })
          .join(', ');
      },
    },
    {
      header: 'Report Types',
      nestedValue: (row: any) => {
        return row?.report_type
          ?.map((item: any) => {
            return item?.full_name;
          })
          .join(', ');
      },
    },
    { header: 'Mail Time', name: 'mail_time' },
    {
      header: 'Created On',
      name: 'created_at',
      datetimeObj: true,
    },
  ];
  adminReportData: any;
  reportTypes: any = [];
  selectedAdminReport: any = {};
  superUserListData: any = [];
  selectedSuperUsers: any = [];
  selectedReportTypes: any = [];
  userTimeZone: any;
  constructor(
    private spinnerService: NgxSpinnerService,
    private adminService: AdminService,
    private dialogService: NbDialogService,
    private pageService: PagesService,
    private router: Router
  ) {
    let promises = [this.getReportTypes(), this.getSuperUsers()];

    Promise.all(promises).then(() => {
      this.spinnerService.show();
      setTimeout(() => {
        this.getAllReports();
      }, 1000);
    });
  }

  ngOnInit(): void {}
  getReportTypes() {
    this.adminService.getAdminReportTypes().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.reportTypes = response?.data?.report_types;
        this.userTimeZone = response?.data?.time_zone;
      }
    });
  }
  getAllReports() {
    this.adminService.getAllAdminReports().subscribe((response: any) => {
      this.formatReportData(response);
      this.spinnerService.hide();
    });
  }
  formatReportData(response: any) {
    this.adminReportData = response;
    if (this.adminReportData?.results?.length) {
      this.onRowClick(this.adminReportData?.results[0]);
    }
  }

  onRowClick(rowData: any) {
    this.selectedAdminReport = rowData;
    this.selectedReportTypes = rowData?.report_type;
    this.selectedSuperUsers = rowData?.recipients;
    let endTime = convertTime24to12(rowData?.mail_time);

    this.adminReportForm.controls['mail_time'].setValue(endTime);
    this.makeReportTypeSelectionChanges();
    this.makeSuperUserSelectionChanges();
  }
  deleteAdminReport() {
    this.spinnerService.show();
    if (this.selectedAdminReport?.id) {
      this.adminService
        .deleteAdminReport(this.selectedAdminReport.id)
        .subscribe((res: any) => {
          window.location.reload();
        });
    }
  }
  createUpdateAdminReport() {
    this.spinnerService.show();
    this.adminReportForm.value.recipients = [
      ...new Set(this.selectedSuperUsers?.map((user: any) => user?.id)),
    ];
    this.adminReportForm.value.report_type = [
      ...new Set(
        this.selectedReportTypes?.map((reportType: any) => reportType?.id)
      ),
    ];
    this.adminReportForm.value.mail_time = convertTime12to24(
      this.adminReportForm.value.mail_time
    );

    if (this.selectedAdminReport?.id) {
      this.adminService
        .editAdminReport(
          this.selectedAdminReport.id,
          this.adminReportForm.value
        )
        .subscribe((res: any) => {
          this.spinnerService.hide();
          this.formatReportData(res);
          this.pageService.setMessage({
            errorMessage: '',
            successMessage: 'Admin Report Updated',
          });
        });
    } else {
      this.adminService
        .createAdminReport(this.adminReportForm.value)
        .subscribe((res: any) => {
          this.spinnerService.hide();
          this.formatReportData(res);

          this.pageService.setMessage({
            errorMessage: '',
            successMessage: 'Admin Report Created',
          });
        });
    }
  }
  getSuperUsers() {
    let params: any = {};

    this.adminService.getSuperUsers(params).subscribe((res: any) => {
      this.superUserListData = res?.results?.filter(
        (user: any) =>
          !['system@guardtracker.live', 'system@guardtracker.com.au'].includes(
            user?.email
          )
      );
    });
  }
  formatTime(event: any) {
    this.adminReportForm.value.mail_time = convertTime12to24(event);
  }
  removeSelectedUser(removeUser: any) {
    this.selectedSuperUsers = this.selectedSuperUsers?.filter(
      (superUser: any) => superUser?.id !== removeUser?.id
    );
    this.makeSuperUserSelectionChanges();
  }
  addSelectedSuperUser(addSuperUser: any) {
    if (addSuperUser?.id) {
      if (
        this.selectedSuperUsers?.some(
          (superUser: any) => superUser?.id === addSuperUser?.id
        )
      ) {
        this.pageService.setMessage({
          errorMessage: 'Super User already added',
          successMessage: '',
        });
      } else {
        this.selectedSuperUsers.push(addSuperUser);
        if (
          !this.superUserListData?.some(
            (superUser: any) => superUser?.id === addSuperUser?.id
          )
        ) {
          this.superUserListData.push(addSuperUser);
        }
      }

      this.makeSuperUserSelectionChanges();
    }
  }
  addRemoveSuperUser(event: any, superUser: any) {
    if (event) {
      this.addSelectedSuperUser(superUser);
    } else {
      this.removeSelectedUser(superUser);
    }
  }
  makeSuperUserSelectionChanges() {
    this.superUserListData.forEach((superUser: any) => {
      superUser.selected = this.selectedSuperUsers?.some(
        (selectedSuperUser: any) => selectedSuperUser?.id === superUser?.id
      );
    });
    this.adminReportForm.value.recipients = [
      ...new Set(this.selectedSuperUsers?.map((user: any) => user?.id)),
    ];
    this.adminReportForm.value.report_type = [
      ...new Set(
        this.selectedReportTypes?.map((reportType: any) => reportType?.id)
      ),
    ];
  }
  removeSelectedReportType(removeReportType: any) {
    this.selectedReportTypes = this.selectedReportTypes?.filter(
      (reportType: any) => reportType?.id !== removeReportType?.id
    );
    this.makeReportTypeSelectionChanges();
  }
  addSelectedReportType(addSuperUser: any) {
    if (addSuperUser?.id) {
      if (
        this.selectedReportTypes?.some(
          (reportType: any) => reportType?.id === addSuperUser?.id
        )
      ) {
        this.pageService.setMessage({
          errorMessage: 'Super User already added',
          successMessage: '',
        });
      } else {
        this.selectedReportTypes.push(addSuperUser);
        if (
          !this.reportTypes?.some(
            (reportType: any) => reportType?.id === addSuperUser?.id
          )
        ) {
          this.reportTypes.push(addSuperUser);
        }
      }

      this.makeReportTypeSelectionChanges();
    }
  }
  addRemoveReportType(event: any, reportType: any) {
    if (event) {
      this.addSelectedReportType(reportType);
    } else {
      this.removeSelectedReportType(reportType);
    }
  }
  makeReportTypeSelectionChanges() {
    this.reportTypes.forEach((reportType: any) => {
      reportType.selected = this.selectedReportTypes?.some(
        (selectedReportType: any) => selectedReportType?.id === reportType?.id
      );
    });
    this.adminReportForm.value.recipients = [
      ...new Set(this.selectedSuperUsers?.map((user: any) => user?.id)),
    ];
    this.adminReportForm.value.report_type = [
      ...new Set(
        this.selectedReportTypes?.map((reportType: any) => reportType?.id)
      ),
    ];
  }
}
