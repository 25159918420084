import { Component, OnInit, TemplateRef } from '@angular/core';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../admin.service';
import { AddEditUsersComponent } from '../../users/add-edit-users/add-edit-users.component';
import { AppService } from 'src/app/app.service';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { PagesService } from '../../pages.service';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-super-users',
  templateUrl: './super-users.component.html',
  styleUrls: ['./super-users.component.scss'],
})
export class SuperUsersComponent implements OnInit {
  superUserColumns = [
    { header: 'Name', name: 'full_name' },
    { header: 'Email', name: 'email' },
    { header: 'Join Time', name: 'date_joined', datetimeObj: true },
    { header: 'Last Login', name: 'last_login', datetimeObj: true },
  ];

  feedBackTable = {
    columns: [
      { header: 'Option', name: 'option' },
      { header: 'Created Time', name: 'created_at', datetimeObj: true },
      {
        header: 'Created by',
        nestedValue: (row: any) => {
          return row?.['user']?.['full_name'];
        },
      },
      { header: 'Delete', name: '', showAction: true },
    ],
    actionColumns: [
      {
        icon: 'trash-2-outline',
        status: 'danger',
        confirm: true,
        type: 'delete',
        show: 'inline',
      },
    ],
  };
  showTypeAhead: boolean = true;
  showSearchBox: boolean = false;
  globalFilter: any;
  userData: any;

  previous: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;

  // feedback variables
  feedbackOptions: any;
  newFeedBack: any;
  dialogRef: any;

  constructor(
    private adminService: AdminService,
    private appService: AppService,
    private dialogService: NbDialogService,
    private spinnerService: NgxSpinnerService,
    private pageService: PagesService
  ) {
    this.getSuperUsers();
    this.getFeedBackOptions();
  }

  ngOnInit(): void {}
  getSuperUsers() {
    let params: any = {};
    if (this.globalFilter) {
      params.full_name = this.globalFilter;
    }
    if (this.rows) {
      params['page_size'] = this.rows;
    }
    if (this.previous) {
      params['page'] = this.pageNum;
    }

    this.adminService.getSuperUsers(params).subscribe((res: any) => {
      this.userData = res;
      this.totalRows = res?.count;
      this.totalPages = Math.ceil(this.totalRows / this.rows);
    });
  }
  onFilterApply(event: any) {
    this.showSearchBox = true;
    this.pageNum = 1;
    if (event?.target?.value?.length === 0) {
      this.showTypeAhead = true;
      this.globalFilter = '';
      this.getSuperUsers();
    }
    if (event?.target?.value && event?.target?.value?.length >= 3) {
      this.getSuperUsers();
      this.showTypeAhead = false;
    }
  }

  onClearFilter(filterType?: string) {
    this.showSearchBox = false;
    this.globalFilter = '';
    this.showTypeAhead = true;

    this.getSuperUsers();
  }

  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getSuperUsers();
  }

  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getSuperUsers();
  }
  openAddUserForm() {
    const dialogRefUser = this.dialogService.open(AddEditUsersComponent, {
      context: { super_admin: true },
    });
    dialogRefUser.onClose.subscribe((value) => {
      if (value !== 'close') {
        this.getSuperUsers();
      }
    });
  }
  openTemplate(template: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(template, {
      context: {},
    });
  }

  getFeedBackOptions() {
    this.adminService.getFeedBack().subscribe((res: any) => {
      this.feedbackOptions = res;
    });
  }
  deleteFeedBack(item: any) {
    this.adminService.deleteFeedback(item?.id).subscribe((res: any) => {
      this.getFeedBackOptions();
    });
  }
  saveNewFeedBack() {
    let userData = this.appService.getUserData();
    this.adminService
      .saveFeedBack({
        option: this.newFeedBack,
        user: userData?.profile?.id,
      })
      .subscribe((res: any) => {
        this.getFeedBackOptions();
      });
    this.newFeedBack = null;
  }
}
