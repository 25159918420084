import { Component, Input, NgZone, OnInit } from '@angular/core';

import { Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-add-subscription-plan',
  templateUrl: './add-subscription-plan.component.html',
  styleUrls: ['./add-subscription-plan.component.scss'],
})
export class AddSubscriptionPlanComponent implements OnInit {
  planLevel: any;
  planInterval: any;
  addDiscount: any;
  @Input() updateView: any;
  @Input() subscriptionPlanForm: any;
  selectedPlan: any = { name: '' };
  selectedInterval: any = { name: '' };
  currentTransactionPrice: any;
  planTypes: any;
  constructor(
    private toastrService: NbToastrService,
    protected dialogRef: NbDialogRef<AddSubscriptionPlanComponent>,
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService,
    private ngZone: NgZone
  ) {
    this.addDiscount = false;
  }

  ngOnInit(): void {
    if (this.updateView === true) {
      this.currentTransactionPrice =
        this.subscriptionPlanForm?.value?.transaction_price;
    }
    this.getPlanAndIntervals();
  }
  getPlanAndIntervals() {
    this.adminService.getPlanLevelAndInterval().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.planLevel = response['data'].levels;
        this.planInterval = response['data'].intervals;
        this.planTypes = response['data'].types;
        if (!this.updateView) {
          this.subscriptionPlanForm.controls['sort_value'].setValue(
            response?.data?.next_order
          );
        }
      }
      this.spinnerService.hide();
    });
  }
  setPlanActive(value: boolean) {
    this.subscriptionPlanForm.controls['is_active'].setValue(value);
  }
  setPlanProtection(value: boolean) {
    this.subscriptionPlanForm.controls['is_protected'].setValue(value);
  }
  onCloseDialogue() {
    this.dialogRef.close('close');
  }
  onClearForm() {
    this.subscriptionPlanForm.reset();
  }
  onClickSubmit() {
    if (this.addDiscount == false) {
      this.subscriptionPlanForm.controls['discount'].setValue(0);
    }
    if (this.updateView === true) {
      if (this.subscriptionPlanForm.valid) {
        this.adminService
          .editSubscriptionPlan(this.subscriptionPlanForm.value)
          .subscribe((res: any) => {
            this.toastrService.show('Plan Updated');
            this.onClearForm();
            this.onCloseDialogue();
          });
      }
    } else {
      if (this.subscriptionPlanForm.valid) {
        this.subscriptionPlanForm.controls['is_active'].setValue(true);
        this.adminService
          .createSubscriptionPlan(this.subscriptionPlanForm.value)
          .subscribe((res: any) => {
            this.toastrService.show('New Plan Created');
            this.onClearForm();
            this.onCloseDialogue();
          });
      }
    }
  }
  selectPlanInterval(value: any) {
    this.selectedInterval = value;
    this.subscriptionPlanForm.controls['plan_interval_name'].setValue(
      this.selectedInterval
    );
    this.planInterval.forEach((element: any) => {
      if (element.name === value) {
        this.subscriptionPlanForm.controls['plan_interval'].setValue(
          element?.value
        );
      }
    });
  }
  selectPlanLevel(value: any) {
    this.selectedPlan = value;
    this.subscriptionPlanForm.controls['plan_level_name'].setValue(
      this.selectedPlan
    );
    this.planLevel.forEach((element: any) => {
      if (element.name === value) {
        this.subscriptionPlanForm.controls['plan_level'].setValue(
          element?.value
        );
      }
    });
  }
  planTypeChange(event: any) {
    this.subscriptionPlanForm.controls['plan_type'].setValue(event);
    // PLAN_TYPE_USER = 1, _("user")
    // PLAN_TYPE_TRANSACTION = 2, _("transaction")
    if (event === 1) {
      this.subscriptionPlanForm.controls['allowed_transactions'].setValue(0);
      this.subscriptionPlanForm.controls['transaction_price'].setValue(0);
      this.subscriptionPlanForm.controls[
        'allowed_transactions'
      ].clearValidators();
      this.subscriptionPlanForm.controls['transaction_price'].clearValidators();

      // Add validators for allowed_users and user_cost
      this.subscriptionPlanForm.controls['allowed_users'].setValidators(
        Validators.required
      );
      this.subscriptionPlanForm.controls['user_cost'].setValidators(
        Validators.required
      );
      this.subscriptionPlanForm.updateValueAndValidity();
      this.subscriptionPlanForm.controls['user_cost'].updateValueAndValidity();
    } else {
      this.subscriptionPlanForm.controls['allowed_users'].setValue(0);
      this.subscriptionPlanForm.controls['user_cost'].setValue(0);
      this.subscriptionPlanForm.controls['allowed_transactions'].setValidators(
        Validators.required
      );
      this.subscriptionPlanForm.controls['transaction_price'].setValidators(
        Validators.required
      );
      this.subscriptionPlanForm.controls['allowed_users'].clearValidators();
      this.subscriptionPlanForm.controls['user_cost'].clearValidators();
      this.subscriptionPlanForm.updateValueAndValidity();
    }
  }
}
