import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { DataCheckService } from '../shared/service/data-check.service';
declare let html2canvas: any;

@Injectable({
  providedIn: 'root',
})
export class PagesService {
  // this key will be used to check whther the user
  // is in the middle of updating job/client or scan checkpoints
  isOnEvent = new Subject();
  // this key will be used to pass data to pages component from child component about service worker related operations
  sWOperations = new Subject();

  // this key will be used to pass the updated client count to
  // camera component, so that the user can cancel the upload
  //if there are no clients near him after he takes an image
  addPhotoClientCount = new Subject();

  // this key will be used to pass the true or false to and from pages to sos alert component, so that the alert can be beep alert can be stopped from both places
  sosAlertActive = new Subject();

  routeChangeSubject = new Subject();
  private message = new BehaviorSubject<any>({
    successMessage: '',
    errorMessage: '',
    timeOut: 5,
  });

  constructor(
    private http: HttpClient,
    private dataCheckService: DataCheckService
  ) {}
  setMessage(message: any) {
    this.message.next(message);
    this.notifyUser();
  }
  returnMessage() {
    return this.message.asObservable();
  }
  refreshTokenApi(isSuperUser: number = 0) {
    return this.http.get('api/refresh_token/', {
      params: { is_super_user: isSuperUser },
    });
  }
  sendToSW(data: any) {
    let payload: any = { type: 'userData' };
    Object.keys(data).forEach((key) => {
      if (['access', 'profile', 'subscriber'].includes(key)) {
        payload[key] = data[key];
      }
    });

    navigator?.serviceWorker?.controller?.postMessage(payload);
  }
  async refreshToken() {
    // check whether token_expiry key is present in localStorage if so, refresh it 5 day before it expires and update localStorage
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const tokenExpiry = userData?.token_expiry;

    if (!tokenExpiry) return false;

    const isSuperUser = this.dataCheckService.isSuperUser() ? 1 : 0;
    const diffDays = this.calculateDateDifference(tokenExpiry);

    if (diffDays > 5) return false;

    const response = await lastValueFrom(this.refreshTokenApi(isSuperUser));
    localStorage.setItem('userData', JSON.stringify(response));
    this.sendToSW(response);

    return response;
  }

  calculateDateDifference(tokenExpiry: any) {
    const date1 = new Date(tokenExpiry);
    const date2 = new Date();

    return Math.floor(
      (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24)
    );
  }

  playChord(duration: any, frequencies: any, volume: any) {
    return new Promise<void>((resolve, reject) => {
      duration = duration || 200;
      volume = volume || 100;

      try {
        const myAudioContext = new AudioContext();
        let gainNode = myAudioContext.createGain();
        gainNode.connect(myAudioContext.destination);
        gainNode.gain.value = volume * 0.01;

        frequencies.forEach((frequency: number) => {
          let oscillatorNode = myAudioContext.createOscillator();
          oscillatorNode.connect(gainNode);
          oscillatorNode.frequency.value = frequency;
          oscillatorNode.type = 'sine';
          oscillatorNode.start(myAudioContext.currentTime);
          oscillatorNode.stop(myAudioContext.currentTime + duration * 0.001);
        });

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  getUserData() {
    return JSON.parse(localStorage.getItem('userData') || '{}');
  }
  getNotificationVolume() {
    let volume = 30;
    const userPreferences = this.getUserData()?.preferences || {};
    // the variable used will seem strange. Initally there was only mute and unmute option. Recycling the key so that it wont be wasted.
    if ('mute_notification' in userPreferences) {
      if (userPreferences?.mute_notification === true) {
        volume = 0;
      } else if (userPreferences?.mute_notification === false) {
        volume = 30;
      } else {
        volume = userPreferences?.mute_notification;
      }
    } else {
      volume = 30;
    }
    return volume;
  }
  async notifyUser(
    duration: number = 200,
    frequencies: any = [1318.51],
    volume?: number
  ) {
    let systemVolume = volume || this.getNotificationVolume();

    if (systemVolume) {
      this.playChord(duration, frequencies, volume);
    }
  }
}
