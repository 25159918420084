<nb-card class="gt-p-1">
  <div>
    <button
      size="giant"
      nbButton
      (click)="openTemplate(StatusTemplate); editData = null"
    >
      Add Status
    </button>
  </div>

  <div>
    <app-table
      [desktopTableData]="JobStatus"
      [desktopTableDetails]="columns"
      [tableActionItems]="actionColumns"
      (actionClick)="actionClick($event, StatusTemplate)"
    ></app-table>
  </div>
  <!-- Pagination section begin -->
  <div class="sc-mt-6">
    <app-pagination-control
      [pageSize]="rows"
      [pageNum]="pageNum"
      [previous]="previous"
      [totalRows]="totalRows"
      [totalPages]="totalPages"
      (onClickPagination)="onClickPagination($event)"
      (onChangePagination)="onChangePagination($event)"
    >
    </app-pagination-control>
  </div>
</nb-card>

<ng-template #StatusTemplate let-data let-StatusRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header>
      {{ editData ? "Edit Status" : "Add new Status" }}
      <nb-icon
        (click)="onCloseDialogue()"
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <form [formGroup]="appStatusForm" (ngSubmit)="onClickSubmit()">
        <div class="form-floating">
          <input
            class="form-control"
            fullWidth
            formControlName="name"
            nbInput
            type="text"
          />
          <label for="inputService">Name</label>
        </div>
        <div class="form-floating">
          <input
            class="form-control"
            fullWidth
            formControlName="description"
            nbInput
            type="text"
          />
          <label for="inputService">Description</label>
        </div>
        <div class="row row-cols-2">
          <div class="col">
            <label
              for="statu_identity"
              nbTooltip="This field is for the 'code' to distinguish the functions of a particular status."
              >Status Identity</label
            >
            <nb-radio-group
              formControlName="status_identity"
              name="statu_identity"
            >
              <nb-radio
                *ngFor="let iStatus of identityStatuses"
                [value]="iStatus[0]"
              >
                {{ iStatus[1] }}
              </nb-radio>
            </nb-radio-group>
          </div>
          <div class="col">
            <label
              for="statu_sharing"
              nbTooltip="Determines who can see this particular status when this job type is selected."
              >Status Sharing</label
            >
            <nb-radio-group
              formControlName="sharing_status"
              name="statu_sharing"
            >
              <nb-radio
                *ngFor="let status of sharedStatuses"
                [value]="status[0]"
              >
                {{ status[1] }}
              </nb-radio>
            </nb-radio-group>
          </div>
        </div>

        <div class="sc-mt-6">
          <nb-tabset>
            <nb-tab tabTitle="Before Select">
              <div class="row row-cols-3 gy-2">
                <div class="col">Background Color</div>
                <div class="col">
                  <input
                    nbInput
                    type="text"
                    size="giant"
                    fullWidth
                    [(ngModel)]="statusStyle['snubbed']['background-color']"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col">
                  <input
                    nbInput
                    fullWidth
                    size="giant"
                    type="color"
                    [(ngModel)]="statusStyle['snubbed']['background-color']"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col">Text Color</div>
                <div class="col">
                  <input
                    nbInput
                    type="text"
                    size="giant"
                    fullWidth
                    [(ngModel)]="statusStyle['snubbed']['color']"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col">
                  <input
                    nbInput
                    fullWidth
                    size="giant"
                    type="color"
                    [(ngModel)]="statusStyle['snubbed']['color']"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col">Border Color</div>
                <div class="col">
                  <input
                    nbInput
                    type="text"
                    size="giant"
                    fullWidth
                    [(ngModel)]="statusStyle['snubbed']['border-color']"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col">
                  <input
                    nbInput
                    fullWidth
                    size="giant"
                    type="color"
                    [(ngModel)]="statusStyle['snubbed']['border-color']"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>
            </nb-tab>
            <nb-tab tabTitle="After Select">
              <div class="row row-cols-3 gy-2">
                <div class="col">Background Color</div>
                <div class="col">
                  <input
                    nbInput
                    type="text"
                    size="giant"
                    fullWidth
                    [(ngModel)]="statusStyle['selected']['background-color']"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col">
                  <input
                    nbInput
                    fullWidth
                    size="giant"
                    type="color"
                    [(ngModel)]="statusStyle['selected']['background-color']"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col">Text Color</div>
                <div class="col">
                  <input
                    nbInput
                    type="text"
                    size="giant"
                    fullWidth
                    [(ngModel)]="statusStyle['selected']['color']"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col">
                  <input
                    nbInput
                    fullWidth
                    size="giant"
                    type="color"
                    [(ngModel)]="statusStyle['selected']['color']"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col">Border Color</div>
                <div class="col">
                  <input
                    nbInput
                    type="text"
                    size="giant"
                    fullWidth
                    [(ngModel)]="statusStyle['selected']['border-color']"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col">
                  <input
                    nbInput
                    fullWidth
                    size="giant"
                    type="color"
                    [(ngModel)]="statusStyle['selected']['border-color']"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>
            </nb-tab>
          </nb-tabset>
          <div class="sc-mt-9 row row-cols-2">
            <div class="col">
              <button
                nbButton
                size="giant"
                fullWidth
                [ngStyle]="statusStyle.snubbed"
              >
                {{
                  appStatusForm.value?.name
                    ? appStatusForm.value?.name
                    : "TEST WORD"
                }}
              </button>
            </div>
            <div class="col">
              <button
                nbButton
                size="giant"
                fullWidth
                [ngStyle]="statusStyle.selected"
              >
                {{
                  appStatusForm.value?.name
                    ? appStatusForm.value?.name
                    : "TEST WORD"
                }}
              </button>
            </div>
          </div>
        </div>
        <div class="form-floating">
          <nb-checkbox status="basic" formControlName="is_active"
            >Is Active</nb-checkbox
          >
        </div>

        <div class="row row-cols-2">
          <div class="col">
            <button
              fullWidth
              (click)="onClearForm()"
              nbButton
              size="giant"
              ghost
            >
              Clear
            </button>
          </div>
          <div class="col">
            <button
              fullWidth
              size="giant"
              [disabled]="!appStatusForm.valid"
              nbButton
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</ng-template>
