<nb-card class="gt-p-small">
  <!-- MOBILE VIEW -->
  <div class="mobile-only">
    <nb-card-body>
      <div class="d-flex">
        <div class="flex-grow-1">
          <nb-form-field class="sc-search-input-box">
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                nbInput
                #customerAutoInput
                fullWidth
                placeholder="Search"
                [(ngModel)]="globalFilter"
                (keyup)="onFilterApply($event); paginationChange = true"
              />
              <label>Search</label>
            </div>
            <nb-icon
              nbSuffix
              *ngIf="showTypeAhead === true"
              [icon]="'search-outline'"
              pack="eva"
              (click)="paginationChange = true; onFilterApply(true)"
            ></nb-icon>
            <nb-icon
              nbSuffix
              *ngIf="showTypeAhead === false"
              [icon]="'close-outline'"
              class="sc-floating-right"
              pack="eva"
              (click)="paginationChange = true; onClearFilter()"
            ></nb-icon>
          </nb-form-field>
        </div>
      </div>
      <div class="col" *ngIf="isAdmin || isDispatchUser === true">
        <button
          fullWidth
          size="giant"
          (click)="showAllIncidents()"
          nbButton
          [status]="defaultIncidents ? 'primary' : 'basic'"
        >
          {{ defaultIncidents ? "Show All" : "Reset" }}
        </button>
      </div>
      <div>
        <button
          title="Log Incident"
          hero
          status="primary"
          type="button"
          fullWidth
          nbButton
          size="giant"
          (click)="addIncident()"
        >
          + Log Incident
        </button>
      </div>

      <div class="row">
        <app-card-list
          [listCardData]="incidentList"
          [listCardDetails]="incidentCardDetails"
          (rowClick)="onSelectIncident($event)"
        ></app-card-list>
      </div>
      <div>
        <app-pagination-control
          [pageSize]="rows"
          [pageNum]="pageNum"
          [previous]="previous"
          [totalRows]="totalRows"
          [totalPages]="totalPages"
          (onClickPagination)="onClickPagination($event)"
          (onChangePagination)="onChangePagination($event)"
        >
        </app-pagination-control>
      </div>
    </nb-card-body>
  </div>
  <!-- DESKTOP VIEW -->
  <div class="large-view-only">
    <div class="d-flex">
      <div class="flex-grow-1">
        <nb-form-field class="sc-search-input-box">
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              nbInput
              #customerAutoInput
              fullWidth
              placeholder="Search"
              [(ngModel)]="globalFilter"
              (keyup)="onFilterApply($event); paginationChange = true"
            />
            <label>Search</label>
          </div>
          <nb-icon
            nbSuffix
            *ngIf="showTypeAhead === true"
            [icon]="'search-outline'"
            pack="eva"
            (click)="paginationChange = true; onFilterApply(true)"
          ></nb-icon>
          <nb-icon
            nbSuffix
            *ngIf="showTypeAhead === false"
            [icon]="'close-outline'"
            class="sc-floating-right"
            pack="eva"
            (click)="paginationChange = true; onClearFilter()"
          ></nb-icon>
        </nb-form-field>
      </div>

      <div class="sc-add-btn">
        <button
          title="Log Incident"
          hero
          status="primary"
          type="button"
          nbButton
          size="giant"
          class="sc-mt-0"
          (click)="addIncident()"
        >
          + Log Incident
        </button>
      </div>
      <div class="sc-add-btn" *ngIf="isAdmin || isDispatchUser === true">
        <button
          fullWidth
          class="sc-mt-0"
          size="giant"
          (click)="showAllIncidents()"
          nbButton
          [status]="defaultIncidents ? 'primary' : 'basic'"
        >
          {{ defaultIncidents ? "Show All" : "Reset" }}
        </button>
      </div>
    </div>

    <div *ngIf="incidentList">
      <!-- TABLE OF Incidents -->
      <div class="col">
        <app-gt-table
          [desktopTableBackendData]="incidentList"
          [desktopTableDetails]="incidentListTableDetails"
          [tableStyle]="tableStyle"
          [otherParams]="otherParams"
          (sortColumn)="sortColumn($event)"
          (searchColumn)="searchColumn($event)"
          (rowClick)="onSelectIncident($event)"
          (paginationChange)="onChangeTablePagination($event)"
        >
        </app-gt-table>
      </div>
    </div>
  </div>
</nb-card>
