import { Location, formatDate } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { NbDialogService } from '@nebular/theme';
import * as Highcharts from 'highcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { formatDateTimeStamp } from 'src/global.variables';
import { AppChecksComponent } from '../../app-checks/app-checks.component';
import { PagesService } from '../../pages.service';
import { ProfileService } from '../../profile/profile.service';
import { AdminService } from '../admin.service';
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit {
  dashBoardData: any;
  dashBoardHighcharts = Highcharts;
  pieData: any = [];
  barChartOptons: any = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Statistics',
    },

    yAxis: {
      stackLabels: {
        style: {
          color: 'black',
          fontWeight: 'bold',
        },
        enabled: true,
        crop: false,
        overflow: 'none',
      },
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        pointWidth: 100,
        point: {
          events: {
            click: (event: any) => {
              if (
                ['JOINED SUBSCRIBERS', 'INVITED SUBSCRIBERS'].includes(
                  event?.point?.category
                )
              ) {
                this.router.navigate(['gtadmin/beta-users']);
              } else if (event?.point?.category === 'SUBSCRIBERS') {
                this.router.navigate(['gtadmin/subscribers']);
              }
            },
          },
        },
      },
      column: {
        stacking: 'normal',
        pointPadding: 0,
        groupPadding: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
  };
  fileUploadChart: any = JSON.parse(JSON.stringify(this.barChartOptons));
  pieChartOptions: any = {
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 30,
      },
    },
    credits: { enabled: false },
    plotOptions: {
      pie: {
        innerSize: 100,
        depth: 10,
      },
      showInLegend: true,
      series: {
        dataLabels: {
          format: '{point.name}: {point.y}',
        },
      },
    },
  };
  dailyTransactionChartOptions: any = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Daily Transaction',
    },

    yAxis: {
      stackLabels: {
        style: {
          color: 'black',
          fontWeight: 'bold',
        },
        enabled: true,
        crop: false,
        overflow: 'none',
        formatter: function (this: any) {
          return `${this.total} $`;
        },
      },
      title: {
        text: 'Dollars',
      },
    },
    plotOptions: {
      series: {
        pointWidth: 50,
      },
      column: {
        stacking: 'normal',
        pointPadding: 0,
        groupPadding: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
  };
  showBarChart: boolean = false;
  showChart: boolean = false;
  showUserChart: boolean = false;
  showJobChart: boolean = false;
  showSubChart: boolean = false;
  showTransactionChart: boolean = false;
  userData: any;

  exceptionColumns = [
    {
      header: ' At',
      name: 'updated_at',
      datetimeObj: true,
    },
    {
      header: 'Error',
      name: 'exception',
    },
  ];
  //error-log params
  errorLogColumns: any = [
    {
      header: 'Level Name',
      name: 'level',
    },
    {
      header: 'Occured At',
      name: 'updated_at',
      datetimeObj: true,
      dateTimeFormat: 'd MMM y HH:mm:ss',
    },

    {
      header: 'Error',
      name: 'message',
    },
    {
      header: 'Line Number',
      name: 'line_no',
    },
    {
      header: 'User',
      nestedValue: (row: any) => {
        return row?.updated_by?.full_name;
      },
    },
    {
      header: 'IP',
      nestedValue: (row: any) => {
        return row?.details?.ip;
      },
    },
  ];

  previousErrorLogs: number = 0;
  rowsErrorLogs: number = 10;
  pageNumErrorLogs: number = 1;
  totalPagesErrorLogs: number = 0;
  totalRowsErrorLogs: number = 0;
  errorLogData: any;
  otherParamsErrorLog: any = {};

  dailyStoragePrevious: number = 0;
  dailyStoragePageSize: number = 10;
  dailyStoragePageNum: number = 1;
  dailyStorageTotalPages: number = 0;
  dailyStorageTotalRows: number = 0;
  showDailyUsageChart: boolean = false;

  //shedule report params

  scheduleData: any;

  uploadedReportTableDetail = [
    {
      header: 'Report Type',
      name: 'report_type',
      searchKey: 'report_type',
      sortKey: 'report_type',
    },
    {
      header: 'Subscriber',
      name: 'subscriber_name',
      searchKey: 'subscriber__name',
      sortKey: 'subscriber__name',
    },

    {
      header: 'Client',
      name: 'company_name',
      searchKey: 'company_name',
      sortKey: 'company_name',
    },
    {
      header: 'File Size (MB)',
      name: 'file_size',
    },
    {
      header: 'Uploaded At',
      name: 'uploaded_at',
      datetimeObj: true,
    },
    {
      header: 'Delete On',
      name: 'delete_date',
      datetimeObj: true,
      dateTimeFormat: 'd MMM y',
    },
  ];
  schdeuleDataTableDetail = [
    {
      header: 'Report Name',
      name: 'report_name',
    },
    {
      header: 'Subscriber',
      name: 'subscriber_name',
      searchKey: 'company__subscriber__name',
      sortKey: 'company__subscriber__name',
    },

    {
      header: 'Client',
      name: 'company_name',
      searchKey: 'company__company_name',
      sortKey: 'company__company_name',
    },
    {
      header: 'User Mail Time',
      name: 'mail_time',
    },
    {
      header: 'Local Mail Time',
      name: 'utc_mail_time',
      datetimeObj: true,
    },
    {
      header: 'Is Today',
      name: 'is_today',
    },
    {
      header: 'Last Ran At',
      name: 'last_ran_at',
      datetimeObj: true,
      sortKey: 'last_ran_at',
      dateRangeKey: 'last_ran_at',
    },
    {
      header: 'Repeat Type',
      name: 'repeat_type',
      searchKey: 'schedule__repeat_type',
      sortKey: 'schedule__repeat_type',
    },
    {
      header: 'Start Day',
      name: 'start_day',
      datetimeObj: true,
      dateRangeKey: 'schedule__start_day',
      dateTimeFormat: 'd MMM y',
      sortKey: 'schedule__start_day',
    },
  ];
  userTableData: any = [];
  userTableDetail = [
    {
      header: 'User Type',
      name: 'user_type',
    },
    {
      header: 'User Count',
      name: 'user_count',
    },
  ];
  showAwsChart: boolean = false;

  awsWeeklyStorageOptons: any = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Weekly Storage Information',
    },
    credits: { enabled: false },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: '%',
      },
    },

    yAxis: {
      stackLabels: {
        style: {
          color: 'black',
          fontWeight: 'bold',
        },
        enabled: true,
        crop: false,
        overflow: 'none',
        formatter: function (this: any) {
          return `${this.total} MB`;
        },
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointPadding: 0,
        groupPadding: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
  };
  awsChartOptons: any = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Files Uploaded Average',
    },
    credits: { enabled: false },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: '%',
      },
    },

    yAxis: {
      min: 0,
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'black',
        },
      },
      title: {
        text: 'File Count',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      dataLabels: {
        enabled: true,
      },
    },
  };
  awsDailyStorageOptons: any = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Daily Storage Information',
    },
    credits: { enabled: false },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: '%',
      },
    },

    yAxis: {
      stackLabels: {
        style: {
          color: 'black',
          fontWeight: 'bold',
        },
        enabled: true,
        crop: false,
        overflow: 'none',
        formatter: function (this: any) {
          return `${this.total} MB`;
        },
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointPadding: 0,
        groupPadding: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
  };
  awsData: any;

  // reminder components STARTS
  reminderForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(),
    expiring_time: new UntypedFormControl(new Date(), Validators.required),
    send_warning: new UntypedFormControl(1),
  });
  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };
  reminderDetailsTableView: any = [
    {
      header: 'Expiry Time',
      name: 'expiring_time',
      datetimeObj: true,
      sortKey: 'expiring_time',
      dateTimeFormat: 'd MMM y',
      conditionalColumnStyle: (row: any) => {
        let today = new Date();
        const seventhDay = today.setDate(today.getDate() + 7);

        if (
          new Date(row?.expiring_time).getTime() > new Date().getTime() &&
          new Date(row?.expiring_time).getTime() <
            new Date(seventhDay).getTime()
        ) {
          return {
            'background-color': 'var(--color-danger-500)',
            'color': 'var(--color-basic-100)',
            'width': 'fit-content',
            'font-size': '0.7rem',
          };
        }
        return {};
      },
    },
    {
      header: 'Created At',
      name: 'created_at',
      datetimeObj: true,
      sortKey: 'created_at',
    },

    {
      header: 'Name',
      name: 'name',
    },
    {
      header: 'Description',
      name: 'description',
    },
    {
      header: 'Warning Mail',
      name: 'send_warning',
    },
    {
      header: 'Mail Date',
      nestedValue: (row: any) => {
        return row?.send_warning
          ? [
              ...row?.mail_dates?.map((item: any) => {
                return formatDateTimeStamp(item, 'd MMM y', 'en_US');
              }),
            ].join(', ')
          : null;
      },
    },
  ];

  dateRange: any;
  apiFilter: boolean = true;
  sortKeys: any = [];

  searchParams: any;
  totalCount: number = 0;
  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  reminderData: any;
  selectedReminder: any;
  nbAlertMessages: any;
  // reminded components ENDS

  bigTabs = {
    tab1: { title: 'Payments', showTab: false },
    tab2: { title: 'Analytics', showTab: false },
    tab3: { title: 'Event Logs', showTab: false },
    tab4: { title: 'Scheduled Reports', showTab: false },
    tab5: { title: 'Reminders', showTab: false },
    tab6: { title: 'S3 Consumption', showTab: false },
    tab7: { title: 'S3 Upload Reports', showTab: false },
    tab8: { title: 'Cancelled', showTab: false },
    tab9: { title: 'Manager Report', showTab: false },
  };

  totalCountSchedules: number = 0;
  previousSchedules: number = 0;
  pageSizeSchedules: number = 20;
  pageNumSchedules: number = 1;
  totalPagesSchedules: number = 0;
  sortKeySchedules: any;
  searchParamsSchedules: any;
  otherParamsSchedule: any = {};

  totalCountUploadedReports: number = 0;
  previousUploadedReports: number = 0;
  pageSizeUploadedReports: number = 10;
  pageNumUploadedReports: number = 1;
  totalPagesUploadedReports: number = 0;
  sortKeyUploadedReports: any;
  searchParamsUploadedReports: any;
  otherParamsUploadedReports: any = {};
  uploadedReportData: any = [];

  cancelledReportData: any = [];
  cancelledReportTimeFrameData: any = { month: [], year: [] };
  cancelledReportTableDetail = [
    {
      header: 'Feedback',
      name: 'feedback',
      searchKey: 'feedback',
      sortKey: 'feedback',
    },
    {
      header: 'Count',
      name: 'count',
    },
  ];
  cancelledReportTimeFrameTableDetail: any = {
    month: [
      {
        header: 'Month',
        name: 'month',
        datetimeObj: true,
        dateRangeKey: 'month',
        dateTimeFormat: 'MMMM YYYY',
        sortKey: 'month',
      },
      {
        header: 'Count',
        name: 'count',
      },
    ],
    year: [
      {
        header: 'Year',
        name: 'year',
        datetimeObj: true,
        dateRangeKey: 'year',
        dateTimeFormat: 'YYYY',
        sortKey: 'year',
      },
      {
        header: 'Count',
        name: 'count',
      },
    ],
  };

  // failed reports variables
  previousFailedReports: number = 0;
  rowsFailedReports: number = 10;
  pageNumFailedReports: number = 1;
  totalPagesFailedReports: number = 0;
  totalRowsFailedReports: number = 0;
  failedReportsData: any;
  otherParamsFailedReports: any = {};
  failedReportColumns: any = [
    {
      header: 'Report',
      name: 'report_name',
    },

    {
      header: 'Client',
      name: 'company_name',
    },
    {
      header: 'Next Retry At',

      nestedValue: (row: any) => {
        return row?.retry_in_progress
          ? 'Retrying'
          : row?.next_attempt_at
          ? formatDate(row?.next_attempt_at, 'd MMM HH:mm', 'en_US')
          : '';
      },
    },
    {
      header: 'Error Details',
      columnDataStyle: {
        'white-space': 'pre-wrap',
      },
      nestedValue: (row: any) => {
        return [
          ...row?.details?.map((item: any) => {
            return `${formatDate(
              item?.error_at * 1000,
              'd MMM HH:mm',
              'en_US'
            )}      ${item?.error_mg}`;
          }),
        ].join('\n\n');
      },
    },
    {
      header: 'Success',
      name: 'successful_at',
      datetimeObj: true,
      dateTimeFormat: 'd MMM HH:mm',
    },
  ];
  constructor(
    private adminService: AdminService,
    private _location: Location,
    private spinnerService: NgxSpinnerService,
    private appService: AppService,
    private dialogService: NbDialogService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private profileService: ProfileService,
    private pageService: PagesService,
    private swUpdate: SwUpdate
  ) {
    this.userData = this.appService.getUserData();
    this.getErrorLog();
    this.getFailedReports();
    this.getDashBoardChartData();
  }
  getDashBoardChartData() {
    this.adminService.getAdminDashBoard().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.dashBoardData = response['data'];

        if (this.dashBoardData) {
          let data = this.dashBoardData['users'];

          if (data.hasOwnProperty('total')) delete data.total;
          this.getPieData(data, 'users');
          this.showUserChart = true;
        }
        let barChartData: any = [];
        let barChartHeaders: any = [];

        for (const [k, v] of Object.entries(this.dashBoardData)) {
          if (!(v instanceof Object)) {
            barChartData.push(v);
            let word = k.split('_').join(' ').toUpperCase();
            barChartHeaders.push(word);
          }
        }

        this.barChartOptons.series = [{ data: barChartData }];
        this.barChartOptons.xAxis = { categories: barChartHeaders };
        let barChartDataFileUploads: any = [];
        let barChartHeadersFileUploads: any = [];
        const fileUploads = this.dashBoardData?.file_uploads || {};
        for (const [k, v] of Object.entries(fileUploads)) {
          if (!(v instanceof Object)) {
            barChartDataFileUploads.push(v);
            let word = k.split('_').join(' ').toUpperCase();
            barChartHeadersFileUploads.push(word);
          }
        }

        this.fileUploadChart.series = [{ data: barChartDataFileUploads }];
        this.fileUploadChart.xAxis = { categories: barChartHeadersFileUploads };
        this.fileUploadChart.title.text = 'File Uploads';

        this.showBarChart = true;

        if (
          !this.userData?.profile?.first_name ||
          !this.userData?.profile?.last_name ||
          this.dataCheckService.hasPasswordSet() === false
        ) {
          const dialogRefUser = this.dialogService.open(AppChecksComponent, {
            context: {},
          });
          dialogRefUser.onClose.subscribe((value: any) => {});
        }
      }
    });
  }
  getScheduledReports() {
    this.spinnerService.show();
    let body: any = { scheduled_reports: 1 };

    if (this.searchParamsSchedules) {
      body['search_filters'] = this.searchParamsSchedules;
    }
    if (this.pageSizeSchedules) {
      body['rows'] = this.pageSizeSchedules;
    }
    if (this.previousSchedules) {
      body['previous'] = this.previousSchedules;
    }
    if (this.sortKeySchedules) {
      body['order_by'] = this.sortKeySchedules;
    }
    this.adminService.getAdminDashBoard(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.scheduleData = response?.data;
        this.otherParamsSchedule.paginationData = {
          tableTotalRows: response?.total_count,
          tablePreviousRows: this.previousSchedules,
          tableRows: this.pageSizeSchedules,
          tablePageNum: this.pageNumSchedules,
          tableTotalPages: this.totalPagesSchedules,
        };
        this.spinnerService.hide();
      } else {
        this.spinnerService.hide();
      }
    });
  }
  getUploadedReports() {
    this.spinnerService.show();
    let body: any = { uploaded_reports: 1 };

    if (this.searchParamsUploadedReports) {
      body['search_filters'] = this.searchParamsUploadedReports;
    }
    if (this.pageSizeUploadedReports) {
      body['rows'] = this.pageSizeUploadedReports;
    }
    if (this.previousUploadedReports) {
      body['previous'] = this.previousUploadedReports;
    }
    if (this.sortKeyUploadedReports) {
      body['order_by'] = this.sortKeyUploadedReports;
    }
    this.adminService.getAdminDashBoard(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.uploadedReportData = response?.data;
        this.otherParamsUploadedReports.paginationData = {
          tableTotalRows: response?.total_count,
          tablePreviousRows: this.previousUploadedReports,
          tableRows: this.pageSizeUploadedReports,
          tablePageNum: this.pageNumUploadedReports,
          tableTotalPages: this.totalPagesUploadedReports,
        };
        this.spinnerService.hide();
      } else {
        this.spinnerService.hide();
      }
    });
  }

  ngOnInit(): void {
    this.profileService.getNotificationCount().subscribe((response: any) => {
      if (response['status'] == 'success') {
        setTimeout(() => {
          this.pageService.routeChangeSubject.next(response);
        }, 100);
      }
    });
    if (
      sessionStorage.getItem('updateAvailable') === 'true' &&
      this.swUpdate?.isEnabled
    ) {
      //making sure that the user is in home page and is doing nothing, so that we can trigger the swupdate
      sessionStorage.removeItem('updateAvailable');

      this.swUpdate?.activateUpdate().then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      });
    }
  }
  tst(event: any) {
    let data: any = {};
    if (this.dashBoardData) {
      if (event.tabTitle === 'Jobs') {
        this.showUserChart = false;
        this.showJobChart = true;
        this.showSubChart = false;
        data = this.dashBoardData['jobs'];
        if (data.hasOwnProperty('total')) delete data.total;
        this.getPieData(data, 'jobs');
      } else if (event.tabTitle === 'Users') {
        this.showUserChart = true;
        this.showJobChart = false;
        this.showSubChart = false;
        data = this.dashBoardData['users'];
        if (data.hasOwnProperty('total')) delete data.total;
        this.getPieData(data, 'users');
      } else if (event.tabTitle === 'Subscriptions') {
        this.showUserChart = false;
        this.showJobChart = false;
        this.showSubChart = true;
        data = this.dashBoardData['subscriptions'];
        this.getPieData(data, 'subscriptions');
      } else if (event.tabTitle === 'Transactions') {
        let days: any = [];
        let totalAmount: any = [];
        this.dashBoardData?.money_transactions.forEach((item: any) => {
          days.push(item?.date__date);
          totalAmount.push(item?.total_balance);
        });

        if (days && totalAmount) {
          this.dailyTransactionChartOptions.xAxis = {
            categories: days,
          };
          this.dailyTransactionChartOptions.series = [
            {
              data: totalAmount,
            },
          ];
          this.showTransactionChart = true;
        }
      }
    }
    if (event.tabTitle == this.bigTabs.tab4.title) {
      this.getScheduledReports();
    }

    if (event.tabTitle == this.bigTabs.tab7.title) {
      this.getUploadedReports();
    }

    if (event.tabTitle == this.bigTabs.tab5.title) {
      this.getReminders();
    }

    if (event.tabTitle == this.bigTabs.tab6.title) {
      this.getAnalyticsData();
    }

    if (event.tabTitle == this.bigTabs.tab1.title) {
      this.bigTabs.tab1.showTab = true;
    }
    if (event.tabTitle == this.bigTabs.tab2.title) {
      this.bigTabs.tab2.showTab = true;
    }
    if (event.tabTitle == this.bigTabs.tab3.title) {
      this.bigTabs.tab3.showTab = true;
    }
    if (event.tabTitle == this.bigTabs.tab9.title) {
      this.bigTabs.tab9.showTab = true;
    }
    if (event.tabTitle == this.bigTabs.tab8.title) {
      this.getCancelledData();
    }
  }
  getCancelledData() {
    this.spinnerService.show();
    let body: any = { cancelled_records: 1 };

    this.adminService.getAdminDashBoard(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.cancelledReportData = response?.data;
        this.cancelledReportTimeFrameData = response?.timeframe_data;

        this.spinnerService.hide();
      } else {
        this.spinnerService.hide();
      }
    });
  }

  getPieData(data: any = {}, type: string) {
    this.pieData = [];
    this.userTableData = [];
    for (const [k, v] of Object.entries(data)) {
      this.pieData.push([k.toUpperCase(), v]);
      this.userTableData.push({
        user_type: k.toUpperCase(),
        user_count: v,
      });
    }

    this.pieChartOptions.series = [
      {
        name: type.toUpperCase(),
        data: this.pieData,
      },
    ];
    this.pieChartOptions.title = { text: `${type.toUpperCase()} Count` };
    this.showChart = true;
  }
  goBack() {
    this._location.back();
  }

  getErrorLog() {
    let params: any = {};
    this.spinnerService.show();
    if (this.rowsErrorLogs) {
      params['page_size'] = this.rowsErrorLogs;
    }
    if (this.pageNumErrorLogs) {
      params['page'] = this.pageNumErrorLogs;
    }

    this.adminService.getAllErrors(params).subscribe((response: any) => {
      this.errorLogData = response?.results;
      this.totalRowsErrorLogs = response?.count;
      this.totalPagesErrorLogs = Math.ceil(
        this.totalRowsErrorLogs / this.rowsErrorLogs
      );
      this.otherParamsErrorLog.paginationData = {
        tableTotalRows: response?.count,
        tablePreviousRows: this.previousErrorLogs,
        tableRows: this.rowsErrorLogs,
        tablePageNum: this.pageNumErrorLogs,
        tableTotalPages: this.totalPagesErrorLogs,
      };
      this.spinnerService.hide();
    });
  }
  onChangePaginationErrorLog(data: any) {
    this.previousErrorLogs = data?.paginationData?.tablePreviousRows;
    this.pageNumErrorLogs = data?.paginationData?.tablePageNum;
    this.rowsErrorLogs = data?.paginationData?.tableRows;

    this.otherParamsErrorLog.paginationData = {
      ...data?.paginationData,
    };
    this.getErrorLog();
  }
  onChangePaginationSchedules(data: any) {
    this.previousSchedules = data?.paginationData?.tablePreviousRows;
    this.pageNumSchedules = data?.paginationData?.tablePageNum;
    this.pageSizeSchedules = data?.paginationData?.tableRows;

    this.otherParamsSchedule.paginationData = {
      ...data?.paginationData,
    };
    this.getScheduledReports();
  }
  onChangePaginationUploadedReports(data: any) {
    this.previousUploadedReports = data?.paginationData?.tablePreviousRows;
    this.pageNumUploadedReports = data?.paginationData?.tablePageNum;
    this.pageSizeUploadedReports = data?.paginationData?.tableRows;

    this.otherParamsUploadedReports.paginationData = {
      ...data?.paginationData,
    };
    this.getUploadedReports();
  }

  getAwsData() {
    this.awsChartOptons.series = this.awsData?.chart_data?.avg_data;
    this.awsChartOptons.xAxis = {
      categories: this.awsData?.chart_data?.subscribers,
    };
    let days: any = [];
    let storage: any = [];
    this.dailyStorageTotalRows = this.awsData?.aws_daily?.length;
    this.dailyStorageTotalPages = Math.ceil(
      this.dailyStorageTotalRows / this.dailyStoragePageSize
    );

    const awsDailyData = JSON.parse(JSON.stringify(this.awsData?.aws_daily));
    const initialDailyGraph = awsDailyData?.slice(0, this.dailyStoragePageSize);
    initialDailyGraph?.forEach((item: any) => {
      days.push(item?.date);
      storage.push(item?.storage_used);
    });
    if (days && storage) {
      this.awsDailyStorageOptons.xAxis = {
        categories: days,
      };
      this.awsDailyStorageOptons.series = [
        {
          name: 'Storage Space Used (MB)',
          data: storage,
        },
      ];
    }
    let weeks: any = [];
    let weeklyStorage: any = [];

    if (weeks && weeklyStorage) {
      this.awsWeeklyStorageOptons.xAxis = {
        categories: weeks,
      };
      this.awsWeeklyStorageOptons.series = [
        {
          name: 'Storage Space Used (MB)',
          data: weeklyStorage,
        },
      ];
    }
  }
  getAnalyticsData() {
    let body = {};
    let params: any = { aws: 1 };

    this.adminService
      .getAnalyticsData(body, params)
      .subscribe((response: any) => {
        this.awsData = response['data']?.aws;

        this.getAwsData();
      });
  }
  onClickPaginationDailyStorage(paginationData: any) {
    this.showDailyUsageChart = false;
    this.dailyStoragePrevious = paginationData.previous;
    this.dailyStoragePageNum = paginationData.pageNum;
    this.dailyStoragePageSize = paginationData.pageSize;

    const awsDailyData = JSON.parse(JSON.stringify(this.awsData?.aws_daily));

    const initialDailyGraph = awsDailyData?.slice(
      this.dailyStoragePrevious,
      this.dailyStoragePageSize * this.dailyStoragePageNum
    );
    let days: any = [];
    let storage: any = [];
    initialDailyGraph?.forEach((item: any) => {
      days.push(item?.date);
      storage.push(item?.storage_used);
    });

    if (days && storage) {
      this.awsDailyStorageOptons.xAxis = {
        categories: days,
      };
      this.awsDailyStorageOptons.series = [
        {
          name: 'Storage Space Used (MB)',
          data: storage,
        },
      ];
      setTimeout(() => {
        this.showDailyUsageChart = true;
      }, 100);
    }
  }

  // reminder components STARTS
  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.getReminders();
  }
  getReminders() {
    let params: any = {};
    if (this.sortKeys?.length) {
      params.ordering = this.sortKeys[0];
    }
    this.adminService.getAllReminders(params).subscribe((res: any) => {
      this.reminderData = res;

      this.showNbAlertMessages();
    });
  }
  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.getReminders();
  }
  handleDateChange(event: any) {
    if (event?.end) {
      this.dateRange = event;
    }
  }
  sortColumn(body: any) {
    this.sortKeys = body?.sortList || [];
    this.searchParams = body?.searchParams || {};
    this.getReminders();
  }
  searchColumn(event: any) {
    this.dateRange = event?.date || null;
    this.searchParams = event?.searchParams || {};
    this.getReminders();
  }
  sortColumnSchedules(body: any) {
    this.sortKeySchedules = body?.sortList || [];
    this.searchParamsSchedules = body?.searchParams || {};
    this.getScheduledReports();
  }
  searchColumnSchedules(event: any) {
    this.searchParamsSchedules = event?.searchParams || {};
    this.getScheduledReports();
  }
  sortColumnUploadedReports(body: any) {
    this.sortKeyUploadedReports = body?.sortList || [];
    this.searchParamsUploadedReports = body?.searchParams || {};
    this.getUploadedReports();
  }
  searchColumnUploadedReports(event: any) {
    this.searchParamsUploadedReports = event?.searchParams || {};
    this.getUploadedReports();
  }

  openTemplate(template: TemplateRef<any>) {
    this.selectedReminder = null;
    this.reminderForm.reset();
    {
      const dialogref = this.dialogService.open(template, {
        context: {},
      });
    }
  }
  addReminder() {
    if (this.selectedReminder && this.selectedReminder?.id) {
      this.adminService
        .editReminder(this.selectedReminder?.id, this.reminderForm.value)
        .subscribe((res: any) => {
          this.getReminders();
        });
    } else {
      this.adminService
        .createNewReminder(this.reminderForm.value)
        .subscribe((res: any) => {
          this.getReminders();
        });
    }
  }
  onReminderClick(event: any, template: TemplateRef<any>) {
    this.selectedReminder = event;
    this.reminderForm.patchValue(this.selectedReminder);
    this.reminderForm.controls['expiring_time'].setValue(
      new Date(this.selectedReminder?.expiring_time)
    );

    const dialogref = this.dialogService.open(template, {
      context: {},
    });
  }
  deleteReminder(reminder: any) {
    let dialogMsg =
      'Removing reminder means the reminder will be not be in the database and will not be alerted.';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: 'Are you sure?',
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((dialogResponse) => {
      if (dialogResponse === 'Yes') {
        this.adminService.deleteReminder(reminder?.id).subscribe((res: any) => {
          this.getReminders();
        });
      }
    });
  }
  showNbAlertMessages() {
    let today = new Date();

    const seventhDay = today.setDate(today.getDate() + 7);
    this.nbAlertMessages = this.reminderData?.results.filter(
      (reminder: any) =>
        new Date(reminder?.expiring_time).getTime() > new Date().getTime() &&
        new Date(reminder?.expiring_time).getTime() <
          new Date(seventhDay).getTime()
    );
  }

  getFailedReports() {
    let params: any = {};
    this.spinnerService.show();
    if (this.rowsFailedReports) {
      params['page_size'] = this.rowsFailedReports;
    }
    if (this.pageNumFailedReports) {
      params['page'] = this.pageNumFailedReports;
    }

    this.adminService.getAllFailedReports(params).subscribe((response: any) => {
      this.failedReportsData = response?.results;
      this.totalRowsFailedReports = response?.count;
      this.totalPagesFailedReports = Math.ceil(
        this.totalRowsFailedReports / this.rowsFailedReports
      );
      this.otherParamsFailedReports.paginationData = {
        tableTotalRows: response?.count,
        tablePreviousRows: this.previousFailedReports,
        tableRows: this.rowsFailedReports,
        tablePageNum: this.pageNumFailedReports,
        tableTotalPages: this.totalPagesFailedReports,
      };
      this.spinnerService.hide();
    });
  }
  onChangePaginationFailedReports(data: any) {
    this.previousFailedReports = data?.paginationData?.tablePreviousRows;
    this.pageNumFailedReports = data?.paginationData?.tablePageNum;
    this.rowsFailedReports = data?.paginationData?.tableRows;

    this.otherParamsFailedReports.paginationData = {
      ...data?.paginationData,
    };
    this.getErrorLog();
  }
  retrySendingFailedReport(data: any) {
    if (new Date(data?.created_at).getDate() !== new Date().getDate()) {
      this.pageService.setMessage({
        errorMessage: 'Report Date diffferent from today',
        successMessage: '',
      });
      return;
    }
    if (data?.retry_in_progress) {
      this.pageService.setMessage({
        errorMessage: 'Retry in Progress',
        successMessage: '',
      });
      return;
    }
    if (data?.successful_at) {
      this.pageService.setMessage({
        successMessage: 'Already Successful',
        errorMessage: '',
      });
    } else {
      let dialogMsg = 'This will retry sending the report to the users.';
      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        context: {
          title: 'Are you sure?',
          message: dialogMsg,
          confirmation: true,
        },
      });
      dialogRef.onClose.subscribe((dialogResponse) => {
        if (dialogResponse === 'Yes') {
          this.adminService
            .retryFailedReport(data?.id)
            .subscribe((res: any) => {
              if (res?.status === 'failure') {
                this.pageService.setMessage({
                  successMessage: '',
                  errorMessage: res?.message,
                });
              }
              this.getFailedReports();
            });
        }
      });
    }
  }
}
