<nb-card class="dialog-box gt-p-small">
  <nb-card-header
    >{{ editSite ? "Edit Site" : "Add New Site" }}
    <nb-icon
      (click)="onCloseDialogue()"
      [icon]="'close-outline'"
      class="sc-floating-right close-icon"
      pack="eva"
    ></nb-icon>
  </nb-card-header>
  <div>
    <div *ngIf="showFrom === 'addJob'">
      <div class="form-floating">
        <input
          class="form-control"
          fullWidth
          [(ngModel)]="selectedClient"
          (input)="onClientSearch($event)"
          nbInput
          autofocus="true"
          type="text"
          id="clientInputField"
          placeholder="Search & Select Client"
          [nbAutocomplete]="clientACMob"
        />

        <label>Search & Select Client</label>
      </div>

      <nb-autocomplete #clientACMob (selectedChange)="onClientSelect($event)">
        <nb-option [value]="' '">
          <b> Add New Client</b>
        </nb-option>
        <nb-option *ngFor="let item of customerList" [value]="item">
          <div>
            <b>{{ item?.company_name }}</b>
            <div>
              <small>{{ item?.address?.full_address }}</small>
            </div>
          </div>
        </nb-option>
      </nb-autocomplete>
    </div>
    <form *ngIf="!editSite" [formGroup]="siteForm" appFormEnterAction>
      <nb-stepper #stepper1>
        <nb-step>
          <div class="form-floating">
            <input
              class="form-control"
              #addressAutoInput
              fullWidth
              (input)="onAddressSearch($event)"
              nbInput
              id="addressLookupField"
              type="text"
              placeholder="Address Lookup"
              [formControl]="searchControl"
              [nbAutocomplete]="autoNgModel"
            />
            <label for="addressLookup">Site Address Lookup</label>
          </div>
          <nb-autocomplete
            #autoNgModel
            (selectedChange)="addressSelected($event)"
          >
            <nb-option [value]="' '"
              ><b> Enter address manually </b>
            </nb-option>
            <nb-option
              *ngFor="let item of addrLookupData$ | async"
              [value]="item"
              (click)="addressSelected(item)"
            >
              <div>
                <span
                  ><b>{{ item.address1 + " " + item.address2 }}</b
                  >,</span
                ><br />
                <small
                  >{{ item.address3 ? item.address3 + " " : "" }}
                  {{ item.city_name }} {{ item.state_name }}
                  {{ item.postcode }}</small
                >
              </div>
            </nb-option>
          </nb-autocomplete>
          <div class="row row-cols-2">
            <div class="col">
              <button
                size="giant"
                ghost
                nbButton
                fullWidth
                (click)="onCloseDialogue()"
              >
                Cancel
              </button>
            </div>
            <div class="col">
              <button
                id="submitbtn"
                fullWidth
                nbButton
                nbStepperNext
                size="giant"
                [disabled]="!addressValue"
              >
                Next
              </button>
            </div>
          </div>
        </nb-step>
        <nb-step>
          <div class="form-floating">
            <input
              class="form-control"
              formControlName="company_name"
              fullWidth
              nbInput
              placeholder="Site Name"
              type="text"
              autocomplete="off"
            />
            <label for="address1">Site Name</label>
          </div>
          <nb-alert
            outline="danger"
            style="font-weight: 500"
            *ngIf="
              siteForm.controls['address1'].invalid ||
              siteForm.controls['address2'].invalid ||
              siteForm.controls['postcode'].invalid ||
              siteForm.controls['city_name'].invalid
            "
          >
            Unable to get exact address, please fill in missing
            information</nb-alert
          >
          <div class="form-floating">
            <input
              class="form-control"
              type="tel"
              fullWidth
              nbInput
              id="address1"
              maxlength="30"
              formControlName="address1"
              placeholder="Street Number"
            />
            <label for="address1">Street Number</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              nbInput
              fullWidth
              id="address2"
              formControlName="address2"
              maxlength="100"
              autocomplete="off"
              placeholder="Street"
            />
            <label for="address2">Street Name</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              nbInput
              type="text"
              fullWidth
              id="address3"
              formControlName="address3"
              maxlength="100"
              autocomplete="off"
              placeholder="Unit"
            />
            <label for="address3">Address Prefix (Suite/Lot/Unit)</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              nbInput
              fullWidth
              id="city_name"
              formControlName="city_name"
              maxlength="100"
              autocomplete="off"
              placeholder="City"
              (input)="forceUppercaseConditionally($event)"
              style="text-transform: uppercase"
            />
            <label for="city_name">Suburb</label>
          </div>

          <div class="form-floating">
            <input
              class="form-control"
              type="tel"
              nbInput
              fullWidth
              id="postcode"
              formControlName="postcode"
              autocomplete="off"
              placeholder="Post Code"
              maxlength="30"
            />
            <label for="postcode">Post Code</label>
          </div>
          <nb-select
            fullWidth
            placeholder="State"
            formControlName="state_code"
            size="giant"
          >
            <nb-option *ngFor="let state of stateData" [value]="state.code">{{
              state.code
            }}</nb-option>
          </nb-select>
          <div class="row row-cols-2">
            <div class="col">
              <button nbStepperPrevious size="giant" ghost nbButton fullWidth>
                Back
              </button>
            </div>
            <div class="col">
              <button
                id="submitbtn"
                (click)="updateData()"
                fullWidth
                nbButton
                size="giant"
                [disabled]="!siteForm.valid"
              >
                Submit
              </button>
            </div>
          </div>
        </nb-step>
      </nb-stepper>
    </form>

    <form *ngIf="editSite" [formGroup]="siteForm" appFormEnterAction>
      <nb-stepper #stepper1>
        <nb-step *ngIf="siteName">
          <div class="form-floating">
            <input
              class="form-control"
              formControlName="company_name"
              fullWidth
              nbInput
              placeholder="Site Name"
              type="text"
              autocomplete="off"
            />
            <label for="address1">Site Name</label>
          </div>
          <div class="row row-cols-2">
            <div class="col">
              <button
                (click)="onCloseDialogue()"
                size="giant"
                ghost
                nbButton
                fullWidth
              >
                Cancel
              </button>
            </div>
            <div class="col">
              <button
                id="submitbtn"
                (click)="updateData()"
                fullWidth
                nbButton
                size="giant"
              >
                Submit
              </button>
            </div>
          </div>
        </nb-step>
        <nb-step *ngIf="!siteName">
          <div class="form-floating">
            <input
              class="form-control"
              #addressAutoInput
              fullWidth
              (input)="onAddressSearch($event)"
              nbInput
              id="addressLookupField"
              type="text"
              placeholder="Address Lookup"
              [formControl]="searchControl"
              [nbAutocomplete]="autoNgModel"
            />
            <label for="addressLookup">Site Address Lookup</label>
          </div>
          <nb-autocomplete
            #autoNgModel
            (selectedChange)="addressSelected($event)"
          >
            <nb-option [value]="' '"
              ><b> Enter address manually </b>
            </nb-option>
            <nb-option
              *ngFor="let item of addrLookupData$ | async"
              [value]="item"
              (click)="addressSelected(item)"
            >
              <div>
                <span
                  ><b>{{ item.address1 + " " + item.address2 }}</b
                  >,</span
                ><br />
                <small
                  >{{ item.address3 ? item.address3 + " " : "" }}
                  {{ item.city_name }} {{ item.state_name }}
                  {{ item.postcode }}</small
                >
              </div>
            </nb-option>
          </nb-autocomplete>
          <div class="form-floating">
            <input
              class="form-control"
              formControlName="company_name"
              fullWidth
              nbInput
              placeholder="Site Name"
              type="text"
              autocomplete="off"
            />
            <label for="address1">Site Name</label>
          </div>
          <nb-alert
            outline="danger"
            style="font-weight: 500"
            *ngIf="
              siteForm.controls['address1'].invalid ||
              siteForm.controls['address2'].invalid ||
              siteForm.controls['postcode'].invalid ||
              siteForm.controls['city_name'].invalid
            "
          >
            Unable to get exact address, please fill in missing
            information</nb-alert
          >
          <div class="form-floating">
            <input
              class="form-control"
              type="tel"
              fullWidth
              nbInput
              id="address1"
              maxlength="30"
              formControlName="address1"
              placeholder="Street Number"
            />
            <label for="address1">Street Number</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              nbInput
              fullWidth
              id="address2"
              formControlName="address2"
              maxlength="100"
              autocomplete="off"
              placeholder="Street"
            />
            <label for="address2">Street Name</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              nbInput
              type="text"
              fullWidth
              id="address3"
              formControlName="address3"
              maxlength="100"
              autocomplete="off"
              placeholder="Unit"
            />
            <label for="address3">Address Prefix (Suite/Lot/Unit)</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              nbInput
              fullWidth
              id="city_name"
              formControlName="city_name"
              maxlength="100"
              autocomplete="off"
              placeholder="City"
              (input)="forceUppercaseConditionally($event)"
              style="text-transform: uppercase"
            />
            <label for="city_name">Suburb</label>
          </div>

          <div class="form-floating">
            <input
              class="form-control"
              type="tel"
              nbInput
              fullWidth
              id="postcode"
              formControlName="postcode"
              autocomplete="off"
              placeholder="Post Code"
              maxlength="30"
            />
            <label for="postcode">Post Code</label>
          </div>
          <nb-select
            fullWidth
            placeholder="State"
            formControlName="state_code"
            size="giant"
            [(selected)]="selectedState"
          >
            <nb-option *ngFor="let state of stateData" [value]="state.code">{{
              state.code
            }}</nb-option>
          </nb-select>
          <div class="row row-cols-2">
            <div class="col">
              <button
                (click)="onCloseDialogue()"
                size="giant"
                ghost
                nbButton
                fullWidth
              >
                Cancel
              </button>
            </div>
            <div class="col">
              <button
                id="submitbtn"
                (click)="updateData()"
                fullWidth
                nbButton
                size="giant"
              >
                Submit
              </button>
            </div>
          </div>
        </nb-step>
      </nb-stepper>
    </form>
  </div>
</nb-card>
