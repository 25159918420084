<nb-card class="dialog-box gt-p-small">
  <nb-card-header>Add new Payment Card
    <nb-icon (click)="onCloseDialogue()" [icon]="'close-outline'" class="sc-floating-right close-icon"
      pack="eva"></nb-icon>
  </nb-card-header>
  <ng-container *ngIf="showSqForm">
    <input class="form-control sc-mt-6 " fullWidth type="text" [(ngModel)]="cardHolderName" id="name"
      placeholder="Cardholder name" />
    <div id="card-container" class="mt-2"></div>

    <button nbButton fullWidth id="pay-btn" size="large" status="primary" [disabled]="!cardHolderName">
      Add Card
    </button>
    <div class="sc-list">
      Please note that we do not save your credit card details. These details
      are saved securely via our payment partner
      <a *ngIf="!isPWAApp" href="https://squareup.com/au/en/legal/general/ua" target="_">SquareUp</a>
      <span *ngIf="isPWAApp">SquareUp</span>.
    </div>
  </ng-container>
</nb-card>