import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  FormControl,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit {
  @Input() dateRange: any;
  @Input() showFrom: any;
  DateTimeForm: UntypedFormGroup = new UntypedFormGroup({
    end_time: new FormControl(null),
    start_time: new FormControl(null),
    start_day: new FormControl(null),
    end_day: new FormControl(null, Validators.required),
  });

  @ViewChild('endTimeInputRef')
  endTimeInputRef!: ElementRef;
  startDate: any;
  endDate: any;
  startTime: any;
  startDateAndTime: any;
  endTime: any;
  endDateAndTime: any;
  errorMessage: any;
  constructor(
    protected dialogRef: NbDialogRef<DateTimePickerComponent>,
    private toasterService: NbToastrService
  ) {}

  ngOnInit(): void {
    if (!this.dateRange) {
      this.dateRange = {
        start: new Date(new Date().setDate(new Date().getDate() - 1)),
        end: new Date(new Date().setDate(new Date().getDate() + 1)),
      };
      this.dateRange.start.setHours(0, 0, 0, 0);
    }
    this.startDate = this.dateRange?.start;
    this.startDateAndTime = this.dateRange?.start;

    this.endDate = this.dateRange?.end;
    this.endDateAndTime = this.dateRange?.end;
    this.checkStartTime();
    this.checkEndTime();

    this.shiftClick('startDay');
  }
  checkStartTime() {
    var abc: any;
    abc = new Date(this.dateRange?.start);
    abc.setHours(parseInt(abc.getHours()));
    abc.setMinutes(parseInt(abc.getMinutes()));
    abc.setSeconds(5);
    abc = abc.toLocaleTimeString('en-Gb', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      hourCycle: 'h23',
    });
    abc = abc.replace(':05', '');
    this.startTime = abc;
    var [hours, minutes] = this.startTime.split(':');
    this.startDateAndTime.setHours(parseInt(hours));
    this.startDateAndTime.setMinutes(parseInt(minutes));
  }
  checkEndTime() {
    var abc: any;
    abc = new Date(this.dateRange?.end);
    abc.setHours(parseInt(abc.getHours()));
    abc.setMinutes(parseInt(abc.getMinutes()));
    abc.setSeconds(5);
    abc = abc.toLocaleTimeString('en-Gb', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      hourCycle: 'h23',
    });
    abc = abc.replace(':05', '');
    this.endTime = abc;

    var [hours, minutes] = this.endTime.split(':');
    this.endDateAndTime.setHours(parseInt(hours));
    this.endDateAndTime.setMinutes(parseInt(minutes));
  }
  formateStartDate(event: any) {
    this.startDate = event;
    this.startDateAndTime = event;
    this.shiftClick('startTime');
  }
  formateEndDate(event: any) {
    this.endDate = event;
    this.endDateAndTime = event;
    this.shiftClick('endTime');
  }
  formatStartTime(event: any) {
    this.errorMessage = '';
    var abc: any;
    abc = new Date();
    this.startTime = this.convertTime12to24(event);
    var [hours, minutes] = this.startTime.split(':');
    this.startDateAndTime = new Date(this.startDate);
    this.startDateAndTime.setHours(parseInt(hours));
    this.startDateAndTime.setMinutes(parseInt(minutes));

    abc.setHours(parseFloat(this.startTime) + 1);
    abc.setMinutes(0);
    abc.setSeconds(5);
    abc = abc.toLocaleTimeString('en-Gb', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      hourCycle: 'h23',
    });
    abc = abc.replace(':05', '');
    this.endTime = abc;
    this.shiftClick('endDay');
  }
  formatEndTime(event: any) {
    this.endTime = this.convertTime12to24(event);
    var [hours, minutes] = this.endTime.split(':');
    this.endDateAndTime = new Date(this.endDate);
    this.endDateAndTime.setHours(parseInt(hours));
    this.endDateAndTime.setMinutes(parseInt(minutes));
    var abc;
    abc = new Date();

    if (this.startDateAndTime.getTime() >= this.endDateAndTime.getTime()) {
      this.errorMessage = 'Invalid end date and time';
      // abc.setHours(parseFloat(event) - 1);
      // abc.setMinutes(0);
      // abc.setSeconds(5);
      // abc = abc.toLocaleTimeString('en-Gb', {
      //   hour: '2-digit',
      //   minute: '2-digit',
      //   second: '2-digit',
      //   hour12: true,
      //   hourCycle: 'h23',
      // });
      // abc = abc.replace(':05', '');
      // this.startTime = abc;
      // this.startDate = null;
      // this.startTime = null;
      setTimeout(() => {
        this.endTime = null;
      });
    }
  }

  clearTime(event: any) {
    this.endTime = '';
    this.DateTimeForm.controls['end_time'].setValue('');
  }
  convertTime12to24(time12h: any) {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours == '12') {
      hours = '00';
    }

    if (modifier.toUpperCase() === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }
  convertTime24to12(time: any) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
      time.splice(3, 1);
    }
    return time.join(''); // return adjusted time or original string
  }
  onDismiss() {
    this.dialogRef.close('close');
  }
  saveDateRange() {
    this.dateRange.start = this.startDateAndTime;
    this.dateRange.end = this.endDateAndTime;
    this.dialogRef.close(this.dateRange);
  }
  shiftClick(elementID: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementID);
      element?.click();
      element?.focus();
    }, 100);
  }
}
