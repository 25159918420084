<!--normal cases-->
<nb-card *ngIf="pageLoaded">
  <nb-card-body
    class="p-2"
    *ngIf="hasPreviouslySubscribed && !currentSubscription"
  >
    <nb-alert status="warning" class="mb-3">Subscription Expired </nb-alert>
  </nb-card-body>
  <nb-card-body
    class="overflow-hidden gt-p-small"
    *ngIf="currentSubscription && !showApplyCouponForm && !addUser"
  >
    <div class="row row-cols-lg-2">
      <div class="col-12 col-lg-10">
        <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-xl-2">
          <div class="col-12 col-lg-5 col-xl-5">
            <span class="badge-key">Your Plan Details</span>
            <span class="badge-value">
              {{ currentSubscription?.plan?.name }}

              <br />

              <span
                >${{
                  currentSubscription?.app_coupon?.payable_amount
                    ? currentSubscription?.app_coupon?.payable_amount
                    : currentSubscription?.plan?.final_amount
                }}</span
              ><span> per month</span>
              <ng-container *ngIf="currentSubscription?.plan?.plan_type == 2">
                <!-- transaction price payment plan-->
                <br />
                <span class="small"
                  >{{
                    currentSubscription?.plan?.allowed_transactions
                  }}
                  transactions included</span
                >
                <br />
                <span class="small"
                  >{{
                    getRoundOffValue(
                      currentSubscription?.plan?.transaction_price
                    )
                  }}c per transaction thereafter</span
                >
              </ng-container>
              <ng-container *ngIf="currentSubscription?.plan?.plan_type == 1">
                <!-- user price payment plan-->
                <br />
                <span
                  >${{
                    getRoundOffValue(
                      currentSubscription?.plan?.user_cost,
                      false
                    )
                  }}
                  per additional user per month</span
                >
                <br />
                <br />
              </ng-container>
            </span>
            <ng-container *ngIf="currentSubscription?.plan?.plan_type == 2">
              <div>
                <span class="badge-key"> Transactions This Month</span>
                <span class="badge-value">
                  <span
                    [ngStyle]="
                      currentSubscription?.transactions?.total_transactions >
                      currentSubscription?.plan?.allowed_transactions
                        ? {
                            'color': 'var(--color-warning-600)',
                            'font-weight': 600
                          }
                        : {}
                    "
                  >
                    {{ currentSubscription?.transactions?.total_transactions }}
                  </span>
                  /
                  <span class="small">
                    {{ currentSubscription?.plan?.allowed_transactions }}
                  </span>
                </span>
              </div>

              <div
                id="excess-usage"
                *ngIf="
                  currentSubscription?.transactions?.total_transactions >
                  currentSubscription?.plan?.allowed_transactions
                "
              >
                <span class="badge-key">Excess Usage</span>
                <span class="badge-value">
                  <span class="strong">
                    ${{
                      (
                        (currentSubscription?.transactions?.total_transactions -
                          currentSubscription?.plan?.allowed_transactions) *
                        currentSubscription?.plan?.transaction_price
                      ).toFixed(2)
                    }}
                  </span>

                  <br />
                  <span class="small">
                    {{
                      currentSubscription?.transactions?.total_transactions -
                        currentSubscription?.plan?.allowed_transactions
                    }}
                    transactions @
                    {{
                      getRoundOffValue(
                        currentSubscription?.plan?.transaction_price
                      )
                    }}c each
                  </span>
                </span>
              </div>
            </ng-container>
            <ng-container *ngIf="currentSubscription?.plan?.plan_type == 1">
              <div
                id="excess-usage"
                *ngIf="
                  currentSubscription?.allotted_slots >
                  currentSubscription?.plan?.allowed_users
                "
              >
                <span class="badge-key">Additional Users</span>
                <span class="badge-value">
                  {{
                    currentSubscription?.allotted_slots -
                      currentSubscription?.plan?.allowed_users
                  }}
                </span>
              </div>

              <div>
                <span class="badge-key">Total Users</span>
                <span class="badge-value">
                  <span>
                    {{ currentSubscription?.allotted_slots }}
                  </span>
                </span>
              </div>
            </ng-container>
          </div>

          <div class="col-12 col-lg-7 col-xl-7">
            <div *ngIf="!queuedSelectedPlanData?.plan?.id">
              <span class="badge-key">
                {{
                  currentSubscription?.recurring_plan
                    ? "Renewal Details"
                    : "Subscription Ends"
                }}</span
              >
              <span
                [ngClass]="
                  currentSubscription?.recurring_plan
                    ? 'badge-value '
                    : 'badge-value danger-text-info'
                "
              >
                <span *ngIf="currentSubscription?.recurring_plan">
                  <span class="strong">
                    ${{
                      (
                        (currentSubscription?.app_coupon?.payable_amount
                          ? currentSubscription?.app_coupon?.payable_amount
                          : currentSubscription?.plan?.final_amount) +
                        (totalAddedUsers >
                        currentSubscription?.plan?.allowed_users
                          ? (totalAddedUsers -
                              currentSubscription?.plan?.allowed_users) *
                            currentSubscription?.plan?.user_cost
                          : 0)
                      ).toFixed(2)
                    }}</span
                  >
                  will be deducted on
                </span>
                {{
                  getBillingDate(currentSubscription) | date : "d MMM yyy"
                }}</span
              >
              <ng-container *ngIf="currentSubscription?.recurring_plan">
                <span class="badge-value">
                  <ng-template
                    *ngTemplateOutlet="
                      pricebreakdownTemplate;
                      context: {
                        subscriptionPlan: currentSubscription,
                        allottedSlots: totalAddedUsers
                      }
                    "
                  ></ng-template>
                </span>
              </ng-container>
              <div
                *ngIf="
                  !currentSubscription?.recurring_plan && paymentCards?.length
                "
              >
                <button
                  nbButton
                  (click)="changeSubscriptionRecursion(currentSubscription)"
                  status="success"
                >
                  Turn on auto renew
                </button>
              </div>
            </div>
            <div *ngIf="queuedSelectedPlanData?.plan?.id">
              Your plan will change on
              <span class="strong">
                {{
                  getBillingDate(currentSubscription) | date : "d MMM yyy"
                }}</span
              >
              <br />
              <br />

              <span class="badge-key">Plan Details</span>

              <span class="badge-value">
                {{ queuedSelectedPlanData?.plan?.name }}

                <br />
                <span
                  >${{
                    queuedSelectedPlanData?.app_coupon?.payable_amount
                      ? queuedSelectedPlanData?.app_coupon?.payable_amount
                      : queuedSelectedPlanData?.plan?.final_amount
                  }}</span
                ><span class="small"> per month</span>

                <ng-container
                  *ngIf="queuedSelectedPlanData?.plan?.plan_type == 2"
                >
                  <!-- transaction price payment plan-->
                  <br />
                  <span class="small"
                    >{{
                      queuedSelectedPlanData?.plan?.allowed_transactions
                    }}
                    transactions included</span
                  >
                  <br />
                  <span class="small"
                    >{{
                      getRoundOffValue(
                        queuedSelectedPlanData?.plan?.transaction_price
                      )
                    }}c per transaction thereafter</span
                  >
                </ng-container>
                <ng-container
                  *ngIf="queuedSelectedPlanData?.plan?.plan_type == 1"
                >
                  <!-- user price payment plan-->

                  <br />
                  <span class="small"
                    >{{ queuedSelectedPlanData?.plan?.allowed_users }} users
                    included</span
                  >
                  <br />
                  <span class="small"
                    >${{
                      getRoundOffValue(
                        queuedSelectedPlanData?.plan?.user_cost,
                        false
                      )
                    }}
                    per additional user per month</span
                  >
                </ng-container>
              </span>
              <ng-container
                *ngIf="queuedSelectedPlanData?.plan?.plan_type == 1"
              >
                <div class="badge-value">
                  <span class="strong">
                    ${{
                      (
                        (queuedSelectedPlanData?.app_coupon?.payable_amount
                          ? queuedSelectedPlanData?.app_coupon?.payable_amount
                          : queuedSelectedPlanData?.plan?.final_amount) +
                        (totalAddedUsers >
                        queuedSelectedPlanData?.plan?.allowed_users
                          ? (totalAddedUsers -
                              queuedSelectedPlanData?.plan?.allowed_users) *
                            queuedSelectedPlanData?.plan?.user_cost
                          : 0)
                      ).toFixed(2)
                    }}</span
                  >
                  will be deducted on

                  {{ getBillingDate(currentSubscription) | date : "d MMM yyy" }}
                  <ng-template
                    *ngTemplateOutlet="
                      pricebreakdownTemplate;
                      context: {
                        subscriptionPlan: queuedSelectedPlanData,
                        allottedSlots: totalAddedUsers
                      }
                    "
                  ></ng-template>
                </div>
              </ng-container>
              <br />
              <br />
              <button
                *ngIf="queuedSubscriptions?.length"
                status="danger"
                ghost
                nbButton
                (click)="deleteQueuedPlan(queuedSelectedPlanData)"
              >
                Cancel Plan Change
              </button>
            </div>
            <span
              *ngIf="
                paymentCards?.length == 0 && currentSubscription?.recurring_plan
              "
              class="badge-value strong danger-text-info"
            >
              No credit card has been saved, please add one before
              {{ getBillingDate(currentSubscription) | date : "d MMM yyy" }}
              to ensure your service is not distrupted
            </span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-2 ps-2"
        *ngIf="
          !queuedSubscriptions?.length &&
          currentSubscription?.is_active &&
          paymentCards?.length &&
          !isSubscriberLocked
        "
      >
        <div class="row row-cols-1">
          <div class="col" *ngIf="currentSubscription?.recurring_plan">
            <button
              fullWidth
              ghost
              nbButton
              (click)="changeSubscriptionRecursion(currentSubscription)"
            >
              Turn off auto renew
            </button>
          </div>
          <div class="col">
            <button
              fullWidth
              nbButton
              (click)="showPlans = !showPlans; showApplyCouponForm = false"
            >
              {{ showPlans ? "Cancel" : "Change Plan" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
  <ng-container
    *ngIf="subscriptions?.length && showPlans === false && addUser === false"
  >
    <!-- MOBILE VIEW -->
    <nb-card-body class="mobile-only gt-p-small">
      <nb-tabset class="sc-mt-6">
        <nb-tab
          tabTitle="Saved Cards"
          *ngIf="!expiredSubscriptions || currentSubscription"
        >
          <div class="gt-payment-card" *ngFor="let savedCard of paymentCards">
            <div
              class="card__front card__part"
              [ngClass]="!savedCard?.is_primary ? 'card__inactive' : ''"
              (click)="changeCardStatus(savedCard)"
            >
              <div class="row mb-4">
                <div class="card__primary">
                  <span *ngIf="savedCard?.is_primary" class="small"
                    ><nb-icon
                      nbSuffix
                      status="success"
                      icon="checkmark-outline"
                      pack="eva"
                    >
                    </nb-icon
                    >PRIMARY</span
                  >
                </div>

                <div
                  class="card__front-logo"
                  style="display: flex; flex-direction: row-reverse"
                >
                  {{ savedCard.brand }}
                </div>
              </div>

              <div class="card_number">{{ savedCard.card_num }}</div>
              <div class="card__space-75">
                <span class="card__label">Card Holder Name</span>
                <div class="card__info">{{ savedCard.holder_name }}</div>
              </div>
              <div class="card__space-25">
                <span class="card__label">Expiry</span>
                <div class="card__info">{{ savedCard.expiry }}</div>
              </div>
            </div>
            <div class="delete-icon">
              <nb-icon
                (click)="deleteCard(savedCard)"
                class="delete-icon-color"
                pack="eva"
                [icon]="'trash-2-outline'"
              ></nb-icon>
            </div>
          </div>

          <div class="card__btn-new" (click)="addNewCard()">+ Add Card</div>

          <div>
            <nb-card class="sc-list">
              <div>
                Please note that we do not save your credit card details. These
                details are saved securely via our payment partner
                <a
                  *ngIf="!isPWAApp"
                  href="https://squareup.com/au/en/legal/general/ua"
                  target="_"
                  >SquareUp</a
                >
                <span *ngIf="isPWAApp">SquareUp</span>
              </div>
            </nb-card>
          </div>
        </nb-tab>
        <nb-tab tabTitle="Invoices" *ngIf="subscriptionInvoices?.length">
          <app-card-list
            [listCardData]="subscriptionInvoices"
            [listCardDetails]="invoiceDetails"
            [cardActionIcons]="invoiceIcons"
            (rowClick)="downloadInvoice($event)"
          ></app-card-list>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>

    <!-- DESKTOP VIEW -->
    <nb-card-body class="large-view-only overflow-hidden">
      <nb-tabset class="sc-mt-6">
        <nb-tab
          tabTitle="Saved Cards"
          *ngIf="!expiredSubscriptions || currentSubscription"
        >
          <div class="card__container">
            <div
              class="gt-payment-card pointer"
              *ngFor="let savedCard of paymentCards"
            >
              <div
                class="card__front card__part"
                [ngClass]="
                  !savedCard?.is_primary ? 'pointer card__inactive' : ''
                "
                (click)="changeCardStatus(savedCard)"
              >
                <div class="row mb-4">
                  <div class="card__primary">
                    <span *ngIf="savedCard?.is_primary" class="small"
                      ><nb-icon
                        nbSuffix
                        status="success"
                        icon="checkmark-outline"
                        pack="eva"
                      >
                      </nb-icon
                      >PRIMARY</span
                    >
                  </div>
                  <div
                    class="card__front-logo"
                    style="display: flex; flex-direction: row-reverse"
                  >
                    {{ savedCard.brand }}
                  </div>
                </div>

                <div class="card_number">{{ savedCard.card_num }}</div>
                <div class="card__space-75">
                  <span class="card__label">Card Holder Name</span>
                  <div class="card__info">{{ savedCard.holder_name }}</div>
                </div>
                <div class="card__space-25">
                  <span class="card__label">Expiry</span>
                  <div class="card__info">{{ savedCard.expiry }}</div>
                </div>
              </div>
              <div class="delete-icon">
                <nb-icon
                  (click)="deleteCard(savedCard)"
                  class="delete-icon-color"
                  pack="eva"
                  [icon]="'trash-2-outline'"
                ></nb-icon>
              </div>
            </div>

            <div class="card__btn-new pointer" (click)="addNewCard()">
              + Add Card
            </div>
          </div>

          <div>
            <nb-card class="sc-list">
              <div>
                Please note that we do not save your credit card details. These
                details are saved securely via our payment partner
                <a
                  *ngIf="!isPWAApp"
                  href="https://squareup.com/au/en/legal/general/ua"
                  target="_"
                  >SquareUp</a
                >
                <span *ngIf="isPWAApp">SquareUp</span>
              </div>
            </nb-card>
          </div>
        </nb-tab>
        <nb-tab tabTitle="Invoices" *ngIf="subscriptionInvoices?.length">
          <app-card-list
            [listCardData]="subscriptionInvoices"
            [listCardDetails]="invoiceDetails"
            [cardActionIcons]="invoiceIcons"
            (rowClick)="downloadInvoice($event)"
          ></app-card-list>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>
  </ng-container>

  <nb-card-body
    *ngIf="
      showPlans === true &&
      showPaymentForm === false &&
      showApplyCouponForm === false &&
      addUser === false
    "
    class="gt-p-1"
    [ngClass]="
      (hasPreviouslySubscribed && !currentSubscription) || currentSubscription
        ? ''
        : 'desktop-padding-margin'
    "
  >
    <!-- First Time subscriber header -->
    <ng-container
      *ngIf="
        !exBeta &&
        firstTimeSubscriber &&
        !currentSubscription &&
        subscriptions.length === 0
      "
    >
      <div class="sc-text-center mb-3">
        <h5>Step 6: Select Plan</h5>
      </div>
    </ng-container>

    <!-- EXPIRED / EXISTING SUBSCRIBERS -->
    <ng-container
      *ngIf="
        expiredSubscriptions?.length &&
        !currentSubscription &&
        subscriptions.length > 0
      "
    >
      <div class="sc-text-center mb-3">
        <h5>Subscribe</h5>
        <div class="mb-3">
          To reactivate Guard Tracker access, please subscribe to a plan.
        </div>
        <div class="mb-3">Change plans later or cancel at any time.</div>
      </div>
    </ng-container>

    <!-- Ex Beta user's header -->
    <ng-container
      *ngIf="exBeta && !currentSubscription && subscriptions.length === 0"
    >
      <div class="sc-text-center mb-3">
        <h5>Beta Program Ended</h5>
        <div class="mb-3">Thank you for participating in our beta program.</div>
        <div class="mb-3">
          To continue your use of Guard Tracker, please subscribe to a plan.
        </div>
        <div class="mb-3">Change plans later or cancel at any time.</div>
      </div>
    </ng-container>

    <div id="pricing-container">
      <div class="text-center mb-3">
        All pricing is inclusive of GST and in Australian Dollars
      </div>
      <div class="flex-container">
        <div
          class="pricing-plan"
          *ngFor="let plan of appPlans"
          [ngClass]="
            plan?.recommended ? 'border border-primary-subtle border-4' : ''
          "
        >
          <h4 class="plan-name">{{ plan.name }}</h4>

          <div class="description mb-1">
            Includes {{ plan?.allowed_users }} Users
          </div>
          <hr />

          <div *ngIf="plan?.trial_days && isTrialApplicable" class="mt-1">
            <span class="price">$0</span> for the first
            {{ plan.trial_days }} days
            <p class="mt-1 mb-0 description">then</p>
          </div>

          <div [ngClass]="!plan?.trial_days || !isTrialApplicable ? '' : ''">
            <div class="mb-0">
              <span class="price mt-2">${{ plan.final_amount }} </span>per month
            </div>
            <ng-container *ngIf="plan?.plan_type == 1">
              <div class="mt-1 mb-3">
                <span class="strong">
                  ${{ getRoundOffValue(plan?.user_cost, false) }}
                </span>
                per additional user
              </div>
            </ng-container>

            <ng-container *ngIf="plan?.plan_type == 2">
              <!-- transaction price payment plan-->
              <div class="small mb-0">
                {{ plan?.allowed_transactions }} transactions included
              </div>
              <div class="small">
                {{ getRoundOffValue(plan?.transaction_price) }}c per transaction
                thereafter
              </div>
            </ng-container>
          </div>
          <div class="text-center small" *ngIf="plan?.recommended">
            Save <span class="strong">${{ plan?.amtSavable }} </span> per month
            switching to this plan
          </div>

          <div
            class="btn-align-bottom btn-select-plan pointer"
            (click)="onPlanClick(plan)"
          >
            Select
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-body *ngIf="showPaymentForm === true || addUser === true">
    <app-payment-form
      [selectedPlan]="selectedPlan"
      [currentSubscription]="currentSubscription"
      [totalAddedUsers]="totalAddedUsers"
      (emitData)="cancelPaymentForm()"
    >
    </app-payment-form>
  </nb-card-body>

  <nb-card-body *ngIf="showApplyCouponForm === true">
    <div class="p-2 col-lg-7 col-xl-5 m-auto">
      <div class="mb-2">
        <h5
          class="plan-name mb-4"
          [ngStyle]="
            dataAfterCouponApplied ? { color: 'var(--color-success-500)' } : {}
          "
        >
          {{ queuedPlanData?.plan?.name }}
        </h5>
        <div class="mb-0 mt-1 sc-head6">
          <span> ${{ queuedPlanData?.plan?.final_amount }}</span> per month
        </div>
        <ng-container *ngIf="queuedPlanData?.plan?.plan_type == 2">
          <!-- transaction price payment plan-->
          <br />
          <span class="small"
            >{{ queuedPlanData?.plan?.allowed_transactions }} transactions
            included</span
          >
          <br />
          <span class="small"
            >{{ getRoundOffValue(queuedPlanData?.plan?.transaction_price) }}c
            per transaction thereafter</span
          >
        </ng-container>
        <ng-container *ngIf="queuedPlanData?.plan?.plan_type == 1">
          <!-- user price payment plan-->
          <div class="mt-2">
            ${{ getRoundOffValue(queuedPlanData?.plan?.user_cost, false) }} per
            additional user
          </div>

          <div class="mt-5 mb-2">
            <!-- Display Extra Users and Pricing -->
            <div>
              <div class="mb-3">
                <!-- Headers -->
                <div class="row row-cols-4">
                  <div class="col-6 px-1 strong"></div>
                  <div class="col-1 px-1 strong text-center">Qty</div>
                  <div class="col-3 px-2 strong text-end">Unit Price</div>
                  <div class="col-2 px-1 strong text-end">Total</div>
                </div>

                <!-- Base Plan -->
                <div class="row row-cols-4 my-2">
                  <div class="col-6 px-1">
                    {{ queuedPlanData?.plan?.name }} ({{
                      queuedPlanData?.plan?.allowed_users
                    }}
                    Users)
                  </div>
                  <div class="col-1 px-1 text-center">1</div>
                  <div class="col-3 px-1 text-end">
                    ${{ (queuedPlanData?.plan?.final_amount).toFixed(2) }}
                  </div>
                  <div class="col-2 px-1 text-end">
                    ${{ (queuedPlanData?.plan?.final_amount).toFixed(2) }}
                  </div>
                </div>

                <!-- Additional Guards -->
                <ng-container
                  *ngIf="allottedSlots > queuedPlanData?.plan?.allowed_users"
                >
                  <div class="row row-cols-4">
                    <div class="col-6 px-1">Additional User(s)</div>
                    <div class="col-1 px-1 text-center">
                      {{ allottedSlots - queuedPlanData?.plan?.allowed_users }}
                    </div>
                    <div class="col-3 px-1 text-end">
                      ${{ (queuedPlanData?.plan?.user_cost).toFixed(2) }}
                    </div>
                    <div class="col-2 px-1 text-end">
                      ${{ getExtraUserCost().toFixed(2) }}
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="isCouponApplied">
        <!-- Display the applied coupon with a remove option -->
        <div class="coupon-applied-container mb-2">
          <span>Coupon {{ couponCode }} Applied</span>
          <label class="remove-coupon-button" (click)="removeAppliedCoupon()">
            Remove
          </label>
        </div>
      </ng-container>
      <ng-container *ngIf="showCouponInput && !isCouponApplied">
        <div class="coupon-container">
          <input
            class="form-control"
            fullWidth
            type="text"
            [(ngModel)]="couponCode"
            id="couponCode"
            placeholder="Coupon code"
            (input)="forceUppercaseConditionally($event)"
          />
          <button
            class="apply-button mt-0 w-50"
            nbButton
            status="primary"
            (click)="onApplyCouponCode()"
            size="giant"
            [disabled]="!couponCode"
          >
            Apply
          </button>
        </div>
        <div *ngIf="errorCouponCode === true" class="text-danger mb-3">
          Invalid coupon
        </div>
      </ng-container>
      <div
        class="sc-text-align-right"
        *ngIf="!showCouponInput && !isSubscriberLocked"
      >
        <button
          nbButton
          status="basic"
          ghost
          (click)="showCouponInput = !showCouponInput"
          size="tiny"
        >
          Apply Coupon
        </button>
      </div>

      <ng-container *ngIf="queuedPlanData?.plan?.plan_type == 1">
        <div class="total-section mt-2">
          <div class="payable-amount row row-cols-2">
            <span class="col-9 sc-text-align-right">GST</span>
            <span class="amount-to-pay ms-3 col-3">${{ getGSTAmount() }}</span>
          </div>

          <div class="payable-amount strong row row-cols-2">
            <span class="col-9 sc-text-align-right">Total with GST</span>
            <span class="amount-to-pay ms-3 col-3"
              >${{ getTotalAmount().toFixed(2) }}</span
            >
          </div>
        </div>
      </ng-container>
      <div class="small-font sc-text-align-right me-3 mt-2">
        Change plans later or cancel at any time
      </div>
      <div class="mt-2 mb-3">
        <nb-alert>
          This plan will take effect on your next billing cycle [{{
            getBillingDate(currentSubscription) | date : "d MMMM yyyy"
          }}]
        </nb-alert>
      </div>
      <div class="row row-cols-2">
        <div class="col">
          <button
            size="giant"
            nbButton
            fullWidth
            ghost
            (click)="
              queuedPlanData = null;
              showApplyCouponForm = false;
              removeAppliedCoupon()
            "
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            size="giant"
            nbButton
            status="primary"
            fullWidth
            (click)="queueSubscription()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </nb-card-body>

  <div
    class="sc-text-align-right"
    *ngIf="
      (!showPlans && showPaymentForm === false && hasPreviouslySubscribed) ||
      (currentSubscription &&
        currentSubscription?.recurring_plan === false &&
        !showPlans &&
        addUser === false)
    "
  >
    <button
      nbButton
      (click)="deleteSubscriberTemplate(deleteAccountTemp)"
      size="giant"
      ghost
    >
      Close Account
    </button>
  </div>
</nb-card>

<ng-template #deleteAccountTemp let-delRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header>WARNING </nb-card-header>
    <nb-card-body>
      <p class="strong">THIS ACTION CANNOT BE UNDONE!</p>
      <p class="strong danger-text-info">
        BY CLOSING YOUR ACCOUNT YOU AGREE TO HAVE ALL YOUR DATA DELETED.
      </p>
      <nb-stepper #stepper>
        <nb-step>
          <div class="sc-text-center">
            To confirm deletion, type {{ randomNumber }} in the field:
          </div>
          <input
            tabindex="1"
            autofocus
            fullWidth
            type="tel"
            placeholder="{{ randomNumber }}"
            nbInput
            (input)="checkValidation($event)"
          />
        </nb-step>
        <nb-step>
          <div class="sc-text-center">Reason for account closure</div>
          <nb-select fullWidth placeholder="Reason">
            <nb-option
              *ngFor="let reason of feedbackOptions"
              [value]="reason"
              (click)="selectDeleteReason(reason?.option)"
              >{{ reason?.option }}</nb-option
            >
          </nb-select>
          <div *ngIf="showReasonText" class="sc-mt-6">
            <textarea
              autofocus
              nbInput
              fullWidth
              rows="4"
              placeholder="Your valuable feedback"
              autocomplete="off"
              [(ngModel)]="cancelReason"
              style="resize: none"
            ></textarea>
          </div>
        </nb-step>
      </nb-stepper>

      <div class="row row-cols-2">
        <div
          class="col"
          (click)="delRef.close(); cancelReason = null; showReasonText = false"
        >
          <button
            tabindex="-1"
            id="cancelBtn"
            nbButton
            ghost
            fullWidth
            size="giant"
          >
            Cancel
          </button>
        </div>
        <div class="col" (click)="deleteSubscriber()">
          <button
            fullWidth
            nbButton
            status="danger"
            [disabled]="!cancelReason"
            size="giant"
          >
            Delete
          </button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #transactionTemplate let-transactRef="dialogRef" let-data>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header
      >Transaction Break down
      <nb-icon
        (click)="transactRef.close(); rowData = null"
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body *ngIf="!rowData">
      <app-gt-table
        [desktopTableUIData]="data?.transactions?.data"
        [desktopTableDetails]="transactionHeaders"
        [otherParams]="{ paginationData: true }"
        (rowClick)="onTransactionRowClick($event)"
      >
      </app-gt-table>
    </nb-card-body>
    <nb-card-body *ngIf="rowData">
      <div class="row row-cols-1">
        <div class="col">
          <button nbButton size="giant" (click)="rowData = null" fullWidth>
            {{ rowData[0].date }}
          </button>
        </div>
      </div>

      <div
        *ngFor="let transaction of subscriberTransactions"
        class="sc-mt-9 row row-cols-1"
      >
        <div class="col">
          <span class="badge-key" (click)="expandColumns(transaction)">
            <nb-icon
              class="ico-color"
              [icon]="transaction.expanded ? 'arrow-down' : 'arrow-right'"
              pack="eva"
            ></nb-icon
            >{{ transaction?.name }} - {{ transaction?.data?.length }}</span
          >

          <div *ngIf="transaction?.expanded" class="sc-mt-6">
            <app-table
              [desktopTableData]="transaction?.data"
              [desktopTableDetails]="transactionEventHeaders"
            ></app-table>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template
  let-subscriptionPlan="subscriptionPlan"
  let-allottedSlots="allottedSlots"
  #pricebreakdownTemplate
>
  <!--price breakdown template-->
  <div class="p-2 ps-0 mt-2">
    <!-- Headers -->
    <div class="row row-cols-4 mb-3">
      <div class="col-4"></div>
      <div class="col-2 strong text-center">Qty</div>
      <div class="col-3 strong text-end">Unit Price</div>
      <div class="col-3 strong text-end">Sub Total</div>
    </div>

    <!-- Base Plan -->
    <div class="row row-cols-4 mb-3">
      <div class="col-4">
        {{ subscriptionPlan?.plan?.name }}
        <div class="small">
          Includes {{ subscriptionPlan?.plan?.allowed_users }} Users
        </div>
      </div>
      <div class="col-2 text-center">1</div>
      <div class="col-3 text-end">
        ${{ (subscriptionPlan?.plan?.final_amount).toFixed(2) }}
      </div>
      <div class="col-3 text-end">
        ${{ (subscriptionPlan?.plan?.final_amount).toFixed(2) }}
      </div>
    </div>

    <!-- Additional Guards -->
    <div
      class="row row-cols-4 mb-3"
      *ngIf="allottedSlots - subscriptionPlan?.plan?.allowed_users > 0"
    >
      <div class="col-4">Additional User</div>
      <div class="col-2 text-center">
        {{ allottedSlots - subscriptionPlan?.plan?.allowed_users }}
      </div>
      <div class="col-3 text-end">
        ${{ subscriptionPlan?.plan?.user_cost?.toFixed(2) }}
      </div>
      <div class="col-3 text-end">
        ${{
          (
            subscriptionPlan?.plan?.user_cost *
            (allottedSlots - subscriptionPlan?.plan?.allowed_users)
          ).toFixed(2)
        }}
      </div>
    </div>

    <div class="strong mt-2 text-end">{{ allottedSlots }} Users Total</div>

    <div class="strong mt-2 text-end">
      ${{
        (
          (subscriptionPlan?.app_coupon?.payable_amount
            ? subscriptionPlan?.app_coupon?.payable_amount
            : subscriptionPlan?.plan?.final_amount) +
          (totalAddedUsers > subscriptionPlan?.plan?.allowed_users
            ? (totalAddedUsers - subscriptionPlan?.plan?.allowed_users) *
              subscriptionPlan?.plan?.user_cost
            : 0)
        ).toFixed(2)
      }}
      per month
    </div>
  </div>
</ng-template>
