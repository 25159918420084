import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  NbDialogRef,
  NbDialogService,
  NbStepperComponent,
  NbToastrService,
} from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { PagesService } from 'src/app/pages/pages.service';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { AppService } from '../../../app.service';
import { CustomerService } from '../../../pages/customer/customer.service';
import { SaveNewAddressComponent } from '../save-new-address/save-new-address.component';
@Component({
  selector: 'app-add-client',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss'],
})
export class AddCustomerComponent implements OnInit {
  isAdmin: any;
  enteredAddresslookup: boolean;
  @Input() updateView: any;
  @Input() companyData: any;
  @Input() customerName: any;
  @Output() getEmitData = new EventEmitter();
  @ViewChild('stepper') stepper: any = NbStepperComponent;
  addrLookupData$: Observable<any> | undefined;
  addressObjectValue: string | undefined;
  nameForm: any;
  diffForm: any;
  startForm: any;
  clientForm: UntypedFormGroup = new UntypedFormGroup({
    company_name: new UntypedFormControl('', [
      Validators.required,
      Validators.max(30),
    ]),
    addressLookup: new UntypedFormControl(''),
    is_individual: new UntypedFormControl(0),
    address1: new UntypedFormControl('', [Validators.required]),
    address2: new UntypedFormControl('', [Validators.required]),
    address3: new UntypedFormControl(' '),
    city_name: new UntypedFormControl('', [Validators.required]),
    postcode: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.max(99999999)])
    ),
    state_code: new UntypedFormControl('', [Validators.required]),
    longitude: new UntypedFormControl('', [Validators.required]),
    latitude: new UntypedFormControl('', [Validators.required]),
  });
  searchControl: FormControl = new FormControl();
  formSubmitted: any;
  country: any;
  state: any;
  selectedItem: any;
  addressValue: any;
  isIndividual: any;
  stateData: any = [];
  countryData: any;
  ipAddress: any;
  companyTypes = [
    { name: 'Business', value: 0, status: 'primary' },
    { name: 'Individual', value: 1, status: 'info' },
  ];
  linearMode = true;
  showMap: boolean = false;
  lastApiResponseHadData: boolean = true;
  lastSearchStr: any;
  apiCallMade: boolean = false;
  constructor(
    @Optional() protected dialogRef: NbDialogRef<AddCustomerComponent>,
    private toastrService: NbToastrService,
    private spinnerService: NgxSpinnerService,
    private customerService: CustomerService,
    private appService: AppService,
    private profileService: ProfileService,
    private pageService: PagesService,
    private dialogService: NbDialogService
  ) {
    this.ipAddress = window.localStorage.getItem('ipAddress');
    this.searchControl.valueChanges.subscribe((data: any) => {
      if (typeof data === 'object') {
        this.searchControl.setValue(data.full_address);
      }
    });
    this.enteredAddresslookup = false;
  }

  ngOnInit(): void {
    this.isIndividual = false;

    this.clientForm.addControl('state_code', new UntypedFormControl());
    if (this.updateView === true) {
      this.clientForm.reset(this.companyData.address);
      this.clientForm.controls['postcode'].setValue(
        this.companyData.address.postcode
      );
      this.clientForm.controls['company_name'].setValue(
        this.companyData.company_name
      );

      this.clientForm.controls['city_name'].setValue(
        this.companyData.address.city_name
      );
      this.clientForm.controls['state_code'].setValue(
        this.companyData.address.state_code
      );
      this.country = this.companyData.address.country_name;
      this.state = this.companyData.address.state_code;
    }

    if (!this.updateView) {
      this.clientForm.removeControl('name');
      this.clientForm.addControl('company_name', new UntypedFormControl());
      this.clientForm.addControl('is_individual', new UntypedFormControl());
      this.clientForm.controls['is_individual'].setValue(0);
      this.clientForm.controls['is_individual'].valueChanges.subscribe(
        (value: any) => {
          this.clientForm.get('company_name')?.setValue(null);
        }
      );
      if (this.customerName?.trim()) {
        this.clientForm.controls['company_name'].setValue(
          this.customerName?.trim()
        );
      }
    }
    this.clientForm.addControl('country_name', new UntypedFormControl());
    this.getCountries();
  }
  toggleLinearMode() {
    this.linearMode = !this.linearMode;
  }
  changeCompanyType(type: any) {
    if (type == 'Business') {
      this.isIndividual = false;
    } else {
      this.isIndividual = true;
      this.clientForm.get('company_name')?.clearValidators();
      this.clientForm.get('is_individual')?.setValue(1);
      this.clientForm.removeControl('name');
    }
    this.shiftFocus('company_name');
  }
  changeAddress(state: any) {
    this.clientForm.controls['postcode'].setValue(null);
  }
  getCountries(data?: any) {
    this.appService.getAddress().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.countryData = response['data'];
        let defaultCountry = this.countryData.filter(
          (country: any) => country.name == 'Australia'
        )[0];
        if (defaultCountry) {
          this.clientForm.get('country_name')?.setValue(defaultCountry.name);
          this.getStates(defaultCountry.id);
        } else {
          this.clientForm.get('country_name')?.setValue('Australia');
        }
      } else {
      }
    });
  }
  getStates(countryId: any) {
    this.appService.getStates(+countryId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateData = response['data'];
      } else {
      }
    });
  }
  onAddressSearch(addressSearch: any) {
    if (addressSearch?.target?.value?.length > 2) {
      if (this.lastSearchStr) {
        !this.searchControl.value.includes(this.lastSearchStr)
          ? (this.apiCallMade = true)
          : (this.apiCallMade = false);
      } else {
        this.apiCallMade = true;
      }
      if (this.apiCallMade || this.lastApiResponseHadData) {
        this.profileService
          .addressLookup(addressSearch.target.value)
          .subscribe((res: any) => {
            this.addrLookupData$ = of(res['data']);
            this.addrLookupData$.subscribe((result: any) => {
              this.lastApiResponseHadData = result.length > 0;
            });
            this.lastSearchStr = this.searchControl.value;
          });
      }
    }
  }
  addressSelected(address: any) {
    if (address === ' ') {
      this.addPlace();
    } else if (address && typeof address === 'object') {
      this.addrLookupData$ = undefined;
      this.addressValue = address?.address1 + ' ' + address?.address2;
      this.addressObjectValue = address;
      this.clientForm.patchValue({
        address1: address?.address1 || '',
        address2: address?.address2 || '',
        address3: address?.address3 || '',
        city_name: address?.city_name,
        postcode: address?.postcode,
        state_code: address?.state_code,
        country_name: address?.country_name,
        latitude: address?.latitude,
        longitude: address?.longitude,
      });

      this.enteredAddresslookup = true;
      var element = <HTMLInputElement>document.getElementById('next1btn');
      element.disabled = false;
      element.focus();
    }
  }
  onCloseDialogue(companyData?: any) {
    if (companyData) {
      this.dialogRef.close(companyData);
    } else {
      this.dialogRef.close('close');
    }
  }
  onClearForm() {
    this.clientForm.reset();
  }
  closeUpdateCustomer() {
    this.getEmitData.emit(false);
    this.dialogRef.close();
  }
  getContactFormDetails(event: any) {}
  updateData() {
    this.formSubmitted = true;

    if (this.clientForm.valid) {
      var requestData = this.clientForm.value;
      requestData['time_offset'] = new Date().getTimezoneOffset();
      this.spinnerService.show();
      if (!this.updateView) {
        this.customerService.addCompany(requestData).subscribe((response) => {
          if (response['status'] == 'success') {
            this.spinnerService.hide();
            let subscriberStats = JSON.parse(
              localStorage.getItem('subscriberStats') || '{}'
            );

            subscriberStats.company_count =
              (subscriberStats?.company_count || 0) + 1;

            localStorage.setItem(
              'subscriberStats',
              JSON.stringify(subscriberStats)
            );

            this.onCloseDialogue(response);
            this.clientForm.reset();

            this.updateView = false;
          } else {
            this.spinnerService.hide();
            this.pageService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
      } else {
        requestData['id'] = this.companyData.id;
        this.customerService
          .updateCompanyDetailsById(requestData)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();

              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this.onCloseDialogue(response);

              this.updateView = false;
            } else {
              this.spinnerService.hide();
              this.pageService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      }
    } else {
      this.toastrService.warning('Warning', 'Please fill the required fields');
    }
  }
  addPlace() {
    this.showMap = false;
    const dialogRef = this.dialogService.open(SaveNewAddressComponent, {
      dialogClass: 'model-full',
      context: {},
    });
    dialogRef.onClose.subscribe((value) => {
      if (value != 'close') {
        if (value) {
          this.addressSelected(value);
          if (this.searchControl.value === ' ') {
            this.searchControl.setValue(
              value?.address1
                ? value?.address1
                : '' +
                    ' ' +
                    value?.address2 +
                    ' ' +
                    value?.city_name +
                    ' ' +
                    value?.state_code +
                    ' ' +
                    value?.postcode
            );
          }
        }
      } else {
        this.searchControl.setValue('');
      }
    });
  }
  nextStepper(event: any) {
    if (this.clientForm?.value?.company_name) {
      this.stepper.next();
      this.shiftFocus('addressLookup');
    }
  }
  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element.focus();
    });
  }
  forceUppercaseConditionally(event: any) {
    this.clientForm.controls['city_name'].setValue(
      event.target.value.toUpperCase()
    );
  }
}
