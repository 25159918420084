<nb-card class="gt-p-1">
  <nb-card-header>
    <div class="flex-center">
      <nb-form-field class="sc-search-input-box">
        <div class="form-floating">
          <input
            class="form-control"
            type="text"
            nbInput
            #customerAutoInput
            fullWidth
            placeholder="Filter Users"
            [(ngModel)]="globalFilter"
            (keyup)="onFilterApply($event)"
          />
          <label>Filter Users</label>
        </div>
        <nb-icon
          nbSuffix
          *ngIf="showTypeAhead === true"
          [icon]="'search-outline'"
          pack="eva"
          (click)="onFilterApply(true)"
        ></nb-icon>
        <nb-icon
          nbSuffix
          *ngIf="showTypeAhead === false"
          [icon]="'close-outline'"
          class="sc-floating-right"
          pack="eva"
          (click)="onClearFilter()"
        ></nb-icon>
      </nb-form-field>
      <div class="sc-add-btn">
        <button
          title="Add User"
          nbButton
          class="sc-mt-0"
          size="giant"
          (click)="openAddUserForm()"
        >
          + Add Super User
        </button>
      </div>
    </div>
  </nb-card-header>

  <app-table
    [desktopTableData]="userData?.results"
    [desktopTableDetails]="superUserColumns"
  >
  </app-table>

  <!-- Pagination section begin -->
  <div class="sc-mt-6">
    <app-pagination-control
      [pageSize]="rows"
      [pageNum]="pageNum"
      [previous]="previous"
      [totalRows]="totalRows"
      [totalPages]="totalPages"
      (onClickPagination)="onClickPagination($event)"
      (onChangePagination)="onChangePagination($event)"
    >
    </app-pagination-control>
  </div>
</nb-card>

<!--feed back options section-->

<nb-card class="gt-p-1">
  <nb-card-header>
    FeedBack Options
    <button
      nbButton
      size="giant"
      class="sc-floating-right"
      (click)="openTemplate(feedBackTemp)"
    >
      Add new FeedBack
    </button></nb-card-header
  >

  <app-table
    [desktopTableData]="feedbackOptions?.results"
    [desktopTableDetails]="feedBackTable.columns"
    [tableActionItems]="feedBackTable.actionColumns"
    (actionClick)="deleteFeedBack($event.event)"
  >
  </app-table>
</nb-card>

<!-- dialog box for adding new feeback-->
<ng-template #feedBackTemp let-data let-feedBackRef="dialogRef">
  <nb-card class="dialog-box gt-p-1">
    <nb-card-body>
      <textarea
        class="form-control"
        fullWidth
        rows="5"
        type="text"
        placeholder="Add a feedback option..."
        [(ngModel)]="newFeedBack"
      ></textarea>
      <div class="row row-cols-2">
        <div class="col">
          <button
            fullWidth
            status="danger"
            (click)="feedBackRef.close(); newFeedBack = null"
            nbButton
          >
            Close
          </button>
        </div>
        <div class="col">
          <button
            fullWidth
            status="success"
            (click)="saveNewFeedBack(); feedBackRef.close()"
            nbButton
            [disabled]="!newFeedBack"
          >
            Save
          </button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
