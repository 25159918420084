import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DOCUMENT } from '@angular/common';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { startAuthentication } from '@simplewebauthn/browser';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { AuthService } from '../../auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  loginForm: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl(null, Validators.required),
    auth_code: new UntypedFormControl(null),
    remember_login: new UntypedFormControl(true),
    password: new UntypedFormControl(null),
  });

  refreshToken: any;

  userEmailForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });
  forgotPasswordVcode: boolean = false;
  deviceInfo: any;
  message: any;
  showPassword: any;
  vCodeShowPassword: any = false;
  linkExpired: any;
  userLinkedSubscribers: any = [];
  selectSubscriberToken: any;
  fidoRegistered: any;
  fidoLogin: boolean = false;
  //the below value will change depending on the response of the 'verify_login' api.
  showForm = 'userNameForm';
  showFullLoginForm: boolean | undefined;
  userNameForm: any;
  debug: boolean = false;
  rememberLogin: number = 0;
  pwaApp: boolean = false;
  isSuperUser: any;
  sendMeSignInLinkClicked: boolean = false;

  hideSignInLInkForgotPasswordBtn: boolean = false;

  dialogRef: any;
  inviteeEmail: any;

  isPasswordAlreadyset: boolean = false;
  presetEmail: string = '';
  currentAppVersion: string = '';
  previouslyEnteredEmail: string = '';

  constructor(
    @Inject(DOCUMENT) private _document: any,
    public router: Router,
    private spinnerService: NgxSpinnerService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private appService: AppService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private profileService: ProfileService,
    private dataCheckService: DataCheckService,
    private dialogService: NbDialogService
  ) {
    // when user logins using fido; it opens a seperate window via the browser,
    //whcih we are not able to control as of yet.So if any errors occur on the FIDO;
    //the screen gets stuck on that screen and not our app. This is a cheeky way to bypass that issue.
    // we will store the response data or error message in localStorage
    //and just reloads the page forcefully taking the screen of the FIDO intermediate screen
    //and use the response stored and show it in our app page
    let subscribers = JSON.parse(localStorage.getItem('response') || '{}');

    if (subscribers?.data?.length) {
      this.getMultipleSubscriber(subscribers, true);
      setTimeout(() => {
        this.clearLocalStorage();
      }, 1000);
    } else {
      // check whether there is email present in the localstorage beacuse the user had clicked remember me
      let savedEmail = localStorage.getItem('email');
      if (savedEmail) {
        this.spinnerService.show();
        this.presetEmail = savedEmail;
        this.loginForm.controls['username'].setValue(savedEmail);
        this.loginForm.controls['remember_login'].setValue(true);
        this.checkUserName();
      }
    }

    let errorMessage = localStorage.getItem('errorMessage');
    if (errorMessage) {
      this.message = errorMessage;
      setTimeout(() => {
        this.clearLocalStorage();
      }, 1000);
    }

    const unblockMessage = localStorage.getItem('unblock_account');
    if (unblockMessage) {
      this.message = unblockMessage;
      setTimeout(() => {
        this.clearLocalStorage();
      }, 1000);
    }
  }
  clearLocalStorage() {
    const ipAddress = localStorage.getItem('ipAddress');
    localStorage.clear();
    if (ipAddress) {
      localStorage.setItem('ipAddress', ipAddress);
    } else {
      this.appService.setIp();
    }
  }
  getMultipleSubscriber(response: any, fidoLogin = false) {
    this.message = null;
    this.userLinkedSubscribers = response['data'];
    this.selectSubscriberToken = response['token'];
    this.rememberLogin = response['remember_login'] == true ? 1 : 0;
    this.fidoLogin = fidoLogin ? true : false;
  }
  checkAuthFailureInfo(info: any) {
    if (
      info?.auth_code_failure_count > 4 &&
      new Date().getTime() - new Date(info?.last_modified_at).getTime() <
        10 * 60 * 1000
    ) {
      this.hideSignInLInkForgotPasswordBtn = Boolean(
        info?.auth_code_failure_count > 4 &&
          new Date().getTime() - new Date(info?.last_modified_at).getTime() <
            10 * 60 * 1000
      );
    }
  }

  ngOnInit(): void {
    this.isSuperUser = this.dataCheckService.isSuperUser();
    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.pwaApp = true;
      this.loginForm.controls['remember_login'].setValue(true);
      this.rememberLogin = 1;
    } else {
      this.pwaApp = false;
    }

    this.appService.permissionErrorAlert.subscribe((value: any) => {
      if (value === 'locationDisabled') {
        this.message = 'Error: Location access not given';
      }
    });

    var element = document.querySelector('nb-card');
    element?.setAttribute('id', 'loginCard');
    element?.classList.add('display-login');

    this.spinnerService.show();

    var key = this.route.snapshot.paramMap.get('key');

    if (key) {
      if (this.router.url.includes('login')) {
        this.appService
          .gtExternalLink('register_login/check_gt_link', {}, key)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();
              if (response?.data) {
                this.getMultipleSubscriber(response);
              } else {
                this.appService.setUserData(response);
                this.loginSuccessAction(response);
              }
            } else {
              this.message = response['message'];
              this.spinnerService.hide();
            }
          });
      } else {
        this.appService
          .gtExternalLink('register_login/check_gt_link', {}, key)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();
              this.appService.setUserData(response);
            } else {
              this.message = response['message'];
              this.refreshToken = response['token'];
              this.linkExpired = true;
              this.spinnerService.hide();
            }
          });
      }
    } else {
      this.route.queryParams.subscribe((params: any) => {
        this.inviteeEmail = params?.invitee_email;
      });
      if (this.inviteeEmail) {
        this.spinnerService.show();
        this.presetEmail = this.inviteeEmail;
        this.loginForm.controls['username'].setValue(this.inviteeEmail);
        this.loginForm.controls['remember_login'].setValue(true);
        this.router.navigate(['/login']);
        this.checkUserName();
      }
      this.spinnerService.hide();
    }
  }
  checkEmail() {
    if (this.previouslyEnteredEmail !== this.loginForm.value?.username) {
      this.previouslyEnteredEmail = this.loginForm.value?.username;
      this.loginForm.controls['password'].clearValidators();
      this.loginForm.controls['password'].updateValueAndValidity();
      this.loginForm.controls['auth_code'].clearValidators();
      this.loginForm.controls['auth_code'].updateValueAndValidity();

      this.showForm = 'userNameForm';
    }
  }
  ngAfterViewInit(): void {
    var element = document.querySelector('nb-card');
    element?.setAttribute('id', 'loginCard');
    element?.classList.add('display-login');
    this.appVersionCheck();
  }
  selectSubscriber(subscriber: any) {
    this.spinnerService.show();
    localStorage.clear();
    this.authService
      .selectSubscriber(
        this.selectSubscriberToken,
        subscriber.id,
        this.rememberLogin
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          if (this.fidoLogin) response.checks.fido_login = true;
          this.appService.setUserData(response);
          this.loginSuccessAction(response);
        } else {
          this.message = response['message'];
          this.spinnerService.hide();
        }
      });
  }

  sendVCode() {
    this.spinnerService.show();
    this.message = null;
    this.authService
      .sendVCode({ data: this.loginForm.value })
      .subscribe((response: any) => {
        this.spinnerService.hide();
        this.message = response['message'];

        this.loginForm.controls['password'].clearValidators();
        this.loginForm.controls['password'].updateValueAndValidity();
        this.loginForm.controls['auth_code'].setValidators(Validators.required);
        this.forgotPasswordVcode = true;
        this.showForm = 'vCodeForm';
        window.localStorage.setItem('resetPasswordPWA', 'true');
        window.sessionStorage.setItem('resetPasswordPWA', 'true');
      });
  }
  async getIP() {
    let ip: any;

    if (window.localStorage.getItem('ipAddress')) {
      ip = window.localStorage.getItem('ipAddress');
    } else {
      this.appService.getIP().subscribe((resp: any) => {
        ip = resp['ip'];
        window.localStorage.setItem('ipAddress', ip);
      });
    }
    return ip;
  }
  async loginSuccessAction(response: any) {
    delete response['message'];
    let userData = this.authService.getUserData();
    if (!userData) userData = response;
    userData['status'] = 'verified';
    this.authService.setUserData(userData);
    this.isSuperUser = this.dataCheckService.isSuperUser();

    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.deviceInfo['ip'] = await this.getIP();
    this.authService.checkDevice().subscribe((response) => {
      this.spinnerService.hide();
    });
    this.spinnerService.hide();

    if (this.isSuperUser === true) {
      this.router.navigate(['/gtadmin/dashboard']);
    } else {
      localStorage.setItem('loginSuccess', 'true');
      this.router.navigate(['./dashboard']);
    }
  }

  refreshExpiredLink() {
    this.profileService
      .refreshAndSendRegistrationLink(
        this.userEmailForm.value,
        this.refreshToken
      )
      .subscribe((response: any) => {
        this.message = response['message'];
        if (response['status'] == 'success') {
          this.linkExpired = false;
        }
      });
  }

  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }
  hideShowPassword() {
    this.showPassword = !this.showPassword;
  }
  hideVcodeShowPassword() {
    this.vCodeShowPassword = !this.vCodeShowPassword;
  }
  ngOnDestroy(): void {}

  async fidoStartAuth(responseBody: any) {
    try {
      let authResp = await startAuthentication(JSON.parse(responseBody));

      if (authResp) {
        this.authService
          .fidoAuthenticate(
            {
              ...authResp,
              ...this.loginForm.value,
              isPwa: Boolean(
                window.matchMedia('(display-mode: standalone)').matches
              ),
            },
            { verify: 1 }
          )
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              if (response?.data) {
                localStorage.setItem('response', JSON.stringify(response));
                window.location.reload();
              } else {
                response.checks.fido_login = true;
                this.appService.setUserData(response);
                this.loginSuccessAction(response);
              }
            } else {
              localStorage.setItem('errorMessage', response['message']);
              window.location.reload();
            }
          });
      }
    } catch (err: any) {
      localStorage.setItem('errorMessage', String(err));
      window.location.reload();
    }
  }

  authenticate() {
    this.spinnerService.show();
    var element = <HTMLInputElement>(
      document.getElementById('passwordInputField')
    );
    element.hidden = true;

    this.authService
      .fidoAuthenticate(this.loginForm.value)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.fidoStartAuth(response['data']);
        } else {
          this.message = response['message'];
        }
      });
  }
  checkUserName() {
    this.spinnerService.show();
    let requestData = {
      data: this.loginForm.value,
      url: 'register_login/verify_login/',
    };
    this.message = null;
    this.isPasswordAlreadyset = false;
    this.authService.verifyLogin(requestData).subscribe((response: any) => {
      if (response['status'] === 'success') {
        this.checkAuthFailureInfo(response?.data);
        this.fidoRegistered = response['data']?.fido_credentials;
        this.isPasswordAlreadyset = response?.data?.password_set;

        this.showForm = 'passwordForm';
        if (this.showForm === 'vCodeForm') {
          this.sendVCode();
          // case where this the first time login of an user. send v-code to mail
          this.loginForm.controls['auth_code'].setValidators(
            Validators.required
          );
          this.loginForm.controls['password'].removeValidators(
            Validators.required
          );
          var element = <HTMLInputElement>document.getElementById('verCode');
          element?.focus();
        }
        if (this.showForm === 'passwordForm') {
          var element = <HTMLInputElement>(
            document.getElementById('inputPassword')
          );
          element?.focus();
          // case where user/ email has logged into the app at-least once
          this.loginForm.controls['auth_code'].removeValidators(
            Validators.required
          );
          this.loginForm.controls['password'].setValidators(
            Validators.required
          );
        }
      } else {
        var element = <HTMLInputElement>(
          document.getElementById('inputPassword')
        );

        element?.focus();
        // even if the user entered email address is not in our db;
        // we will show the user password field and sign in button;
        //from there he can call with fake data or can modify the details and try loggin in
        this.showForm = 'dummyPasswordForm';
        this.loginForm.controls['auth_code'].removeValidators(
          Validators.required
        );
        this.loginForm.controls['password'].setValidators(Validators.required);
      }
      this.spinnerService.hide();
    });
  }

  signIn(url: String) {
    this.recaptchaV3Service.execute('score').subscribe(
      (token) => {
        // handle captcha token
        this.spinnerService.show();
        let requestData = {
          data: {
            ...this.loginForm.value,
            recaptcha_token: token,
            isPwa: Boolean(
              window.matchMedia('(display-mode: standalone)').matches
            ),
          },
          url: url,
        };

        this.authService.verifyLogin(requestData).subscribe((response: any) => {
          if (response['status'] === 'success') {
            if (response?.data) {
              this.getMultipleSubscriber(response);
            } else {
              this.appService.setUserData(response);
              this.loginSuccessAction(response);
            }
          } else {
            this.message = response['message'];
          }
          this.checkAuthFailureInfo(response);
          this.spinnerService.hide();
        });
      },
      (error) => {
        if (error.message === 'timeout') {
          window.location.reload();
        }
      }
    );
  }
  login() {
    if (this.showForm === 'userNameForm') {
      //step 1: check whether the entered email address is valid
      this.checkUserName();
    }
    if (
      this.showForm === 'vCodeForm' ||
      (this.pwaApp && this.forgotPasswordVcode)
    ) {
      //step 2:if user is first time user check v-code sent to their mail
      this.signIn('register_login/login_with_code/');
    }
    if (
      this.showForm === 'passwordForm' ||
      this.showForm === 'dummyPasswordForm'
    ) {
      //step 3:if user has logged in at-least once or entered email is not in our db.
      //In either case check again username + password is in the db
      this.signIn('register_login/login/');
    }
  }
  sendSignInLink() {
    this.recaptchaV3Service.execute('score').subscribe((token) => {
      this.spinnerService.show();
      this.loginForm.controls['remember_login'].setValue(true);

      this.authService
        .sendSignInLink({
          data: {
            ...this.loginForm.value,
            recaptcha_token: token,
          },
        })
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.sendMeSignInLinkClicked = true;
            this.message = response['message'];
            this.spinnerService.hide();
          }
          if (response['status'] == 'failure') {
            this.message = response['message'];
            this.spinnerService.hide();
          } else {
            this.sendMeSignInLinkClicked = true;
            this.loginForm.get('username')?.disable();
            this.message =
              'We have sent you a link to sign in, please check your email including your junk/spam folder.';
            this.spinnerService.hide();
          }
        });
    });
  }

  async appVersionCheck() {
    const versionData: any = await this.appService.appVersionCheck();
    const latestVersion = versionData?.latestVersion;
    const currentVersion = versionData?.currentVersion;
    this.currentAppVersion = currentVersion?.version;
    if (
      currentVersion?.version &&
      latestVersion?.last_item?.version !== currentVersion?.version
    ) {
      this.appService.updateAppVersionIndexedDb(
        latestVersion?.last_item?.version
      );
      this.appService.updateApp();
    }
    if (!currentVersion) {
      this.appService.updateAppVersionIndexedDb(
        latestVersion?.last_item?.version
      );
    }
  }
}
