import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AddUserComponent } from './shared/add-user/add-user.component';
import { LoadGuard } from './shared/guards/load.guard';

export const routes: Routes = [
  {
    path: 'users/:key',
    component: AddUserComponent,
  },

  {
    path: '',
    pathMatch: 'full',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
        canLoad: [LoadGuard],
      },
    ],
  },

  {
    path: 'login',
    redirectTo: 'login',
    pathMatch: 'full',
    canLoad: [LoadGuard],
  },
];
const config: ExtraOptions = {
  useHash: false,
};
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
