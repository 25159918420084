<div
  class="gy-3 row row-cols-md-2 row-cols-xxl-3 row-cols-xxxl-6"
  *ngIf="listCardData?.length"
>
  <!-- Card Sections split into 3 sections-->
  <div *ngFor="let data of listCardData">
    <nb-card
      [ngStyle]="cardGroupDetails ? cardGroupDetails(data) : ''"
      [ngClass]="showTableFrom === 'jobsPage' ? 'sc-card' : 'contact-card'"
    >
      <div class="row">
        <div
          [ngClass]="
            cardActionIcons
              ? 'col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11'
              : 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'
          "
        >
          <div class="table-card-data">
            <div
              *ngFor="let indCard of cardIndividualDetails"
              (click)="onRowClick(data)"
            >
              <!-- rows where objects are datetime objects STARTS-->

              <!--style whole row either based on condition or common to all -->
              <div
                *ngIf="indCard?.datetimeObj == true"
                [ngStyle]="
                  indCard?.conditionalRowStyle
                    ? indCard?.conditionalRowStyle(data)
                    : indCard?.cardRowStyle
                "
              >
                <!--a row is typically divided into 3 prefix, main content and suffix, style each of them based on conditions or common -->
                <span
                  [ngStyle]="
                    indCard?.conditionalPrefixStyle
                      ? indCard?.conditionalPrefixStyle(data)
                      : indCard?.prefixStyle
                  "
                >
                  <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
                  {{
                    indCard?.prefixNestedValue
                      ? indCard?.prefixNestedValue(data)
                      : indCard?.prefix
                      ? indCard?.prefix
                      : ""
                  }}
                </span>
                <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
                <!-- main content which will be passed from the backend STARTS-->
                <span
                  [ngStyle]="
                    indCard?.conditionalRowDataStyle
                      ? indCard?.conditionalRowDataStyle(data)
                      : indCard?.rowDataStyle
                  "
                >
                  {{
                    indCard.nestedValue
                      ? (indCard.nestedValue(data)
                        | customdate
                          : (indCard.dateTimeFormat
                              ? indCard.dateTimeFormat
                              : "d MMM y HH:mm"))
                      : (data[indCard.name]
                        | customdate
                          : (indCard.dateTimeFormat
                              ? indCard.dateTimeFormat
                              : "d MMM y HH:mm"))
                  }}
                </span>
                <!-- main content which will be passed from the backend ENDS-->
                <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
                <span
                  [ngStyle]="
                    indCard?.conditionalSuffixStyle
                      ? indCard?.conditionalSuffixStyle(data)
                      : indCard?.suffixStyle
                  "
                >
                  {{
                    indCard?.suffixNestedValue
                      ? indCard?.suffixNestedValue(data)
                      : indCard?.suffix
                      ? indCard?.suffix
                      : ""
                  }}
                </span>
                <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
              </div>
              <!-- rows where objects are datetime objects ENDS-->

              <!-- rows where objects are normal objects STARTS-->

              <!--style whole row either based on condition or common to all -->
              <div
                *ngIf="indCard?.datetimeObj !== true"
                [ngStyle]="
                  indCard?.conditionalRowStyle
                    ? indCard?.conditionalRowStyle(data)
                    : indCard?.cardRowStyle
                "
              >
                <!--a row is typically divided into 3 prefix, main content and suffix, style each of them based on conditions or common -->
                <span
                  [ngStyle]="
                    indCard?.conditionalPrefixStyle
                      ? indCard?.conditionalPrefixStyle(data)
                      : indCard?.prefixStyle
                  "
                >
                  <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
                  {{
                    indCard?.prefixNestedValue
                      ? indCard?.prefixNestedValue(data)
                      : indCard?.prefix
                      ? indCard?.prefix
                      : ""
                  }}
                </span>
                <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
                <!-- main content which will be passed from the backend STARTS-->
                <span
                  [ngStyle]="
                    indCard?.conditionalRowDataStyle
                      ? indCard?.conditionalRowDataStyle(data)
                      : indCard?.rowDataStyle
                  "
                >
                  {{
                    indCard.nestedValue
                      ? indCard.nestedValue(data)
                      : data[indCard.name]
                  }}
                </span>
                <!-- main content which will be passed from the backend ENDS-->
                <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
                <span
                  [ngStyle]="
                    indCard?.conditionalSuffixStyle
                      ? indCard?.conditionalSuffixStyle(data)
                      : indCard?.suffixStyle
                  "
                >
                  {{
                    indCard?.suffixNestedValue
                      ? indCard?.suffixNestedValue(data)
                      : indCard?.suffix
                      ? indCard?.suffix
                      : ""
                  }}
                </span>
                <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
              </div>
              <!-- rows where objects are normal objects ENDS-->
            </div>

            <div *ngIf="data?.users" class="display-flex-wrap">
              <div *ngFor="let assignee of slicedData(data?.users)">
                <nb-user
                  (click)="onAssigneeSelect(assignee)"
                  size="small"
                  onlyPicture
                  [name]="assignee?.full_name"
                  [picture]="assignee?.profile_img_url"
                >
                </nb-user>
              </div>
              <div
                class="view-more-less"
                (click)="showAllAssignees(data?.users)"
                *ngIf="data?.users.length > 3 && slicedUsersCount <= 3"
              >
                View All
              </div>
            </div>

            <!-- Show Details Button -->
            <button
              *ngIf="showDetailsCondition"
              nbButton
              status="primary"
              size="medium"
              class="mt-2"
              (click)="showDetails(data)"
            >
              See Details
            </button>
            <!-- Call Guard Button -->
            <ng-container *ngIf="showCallGuardButton">
              <!-- add 2 call btns ; one disabled if there is no phone number ; had to split because even though btn disbled the href was triggering-->
              <ng-container *ngIf="data?.mobile_number"
                ><a
                  href="tel:{{ data?.mobile_number }}"
                  style="color: white !important"
                  ><div>
                    <button
                      nbButton
                      status="success"
                      fullWidth
                      class="nbbutton-long-text mt-2"
                    >
                      <nb-icon
                        class="me-3"
                        [icon]="'phone-call'"
                        style="color: white !important"
                        pack="eva"
                      ></nb-icon>
                      Call
                    </button>
                  </div>
                </a>
              </ng-container>
              <ng-container *ngIf="!data?.mobile_number">
                <div
                  nbTooltip="{{
                    data?.full_name
                  }} has not provided a phone number"
                >
                  <button
                    nbButton
                    status="success"
                    fullWidth
                    class="nbbutton-long-text mt-2"
                    disabled
                  >
                    <nb-icon
                      class="me-3"
                      [icon]="'phone-call'"
                      style="color: white !important"
                      pack="eva"
                    ></nb-icon>
                    Call
                  </button>
                </div></ng-container
              >
            </ng-container>
          </div>
        </div>

        <!-- ICONS -->
        <div
          class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 table-card-data sc-card-icon-padding"
          *ngIf="cardActionIcons"
        >
          <div class="row">
            <div *ngFor="let actionIcon of cardActionIcons">
              <nb-icon
                class="table-icon-size ico-color"
                nbSpinnerStatus="primary"
                (click)="onActionClick(data, actionIcon.condition(data).type)"
                [icon]="actionIcon.condition(data).icon"
                [status]="actionIcon.condition(data).status"
                [title]="actionIcon.condition(data).title"
                pack="eva"
              ></nb-icon>
            </div>
          </div>
        </div>
      </div>
    </nb-card>
  </div>
</div>
