import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { globalMonitoringCompanyInfo } from 'src/global.variables';
import { CustomerService } from '../customer/customer.service';
import { PagesService } from '../pages.service';
import { AddEditMonitoringCompanyComponent } from './add-edit-monitoring-company/add-edit-monitoring-company.component';
@Component({
  selector: 'app-monitoring-company',
  templateUrl: './monitoring-company.component.html',
  styleUrls: ['./monitoring-company.component.scss'],
})
export class MonitoringCompanyComponent implements OnInit {
  customerDetails: any;
  customerView: any;
  detailData: any;
  globalFilter: any;
  isFilterApplied: boolean = false;
  clientCardDetils: any = {};
  showTypeAhead: boolean = true;
  userData: any;
  clientCount: number = 0;
  previous: number = 0;
  pageSize: number = 5;
  pageNum: number = 1;
  totalPages: number = 0;
  clientPaginationData: any = {};
  totalClientCount: number = 0;
  searchRemovalCap: number = 5;
  userGroup: any;
  isDispatchUser: boolean = false;
  isAdmin: any;
  showAutoCustomerDetails$: Observable<any> | undefined;

  dialogref: any;
  updateView: any;
  companyData: any;
  cardActionIcons = globalMonitoringCompanyInfo?.icons;
  lastSearchStr: any;
  lastApiResponseHadData: boolean = true;
  apiCallMade: boolean = false;
  constructor(
    private customerService: CustomerService,
    private spinnerService: NgxSpinnerService,
    private _location: Location,
    private dialogService: NbDialogService,
    private appService: AppService,

    private pageService: PagesService,
    private dataCheckService: DataCheckService
  ) {
    this.clientCardDetils = {
      individualDetails: [
        {
          name: 'company_name',
          cardRowStyle: { 'font-weight': 'bolder' },
        },
        {
          name: 'address',
          cardRowStyle: {
            'font-size': 'small',
          },
        },
      ],
    };
  }

  ngOnInit(): void {
    this.userData = this.appService.getUserData();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.pageSize = Number(this.userData?.preferences?.client_page_size || 5);
    this.spinnerService.show();
    this.fetchMonitoringCompanyList();
  }
  fetchMonitoringCompanyList() {
    let params: any = {};
    if (this.pageSize) {
      params['rows'] = this.pageSize;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    if (this.globalFilter) {
      params['search_str'] = this.globalFilter;
    }
    this.spinnerService.show();
    this.customerService
      .fetchMonitoringCompanyList(params)
      .subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.isFilterApplied = false;

          this.customerDetails = res['data'];
          this.lastApiResponseHadData = this.customerDetails.length > 0;
          this.lastSearchStr = this.globalFilter;
          this.clientCount = res['total_size'];

          this.totalClientCount = res?.['monitoring_company_count'] || 0;
          this.totalPages = Math.ceil(this.clientCount / this.pageSize);
          this.clientPaginationData[this.pageNum] = [...this.customerDetails];
        }
        this.spinnerService.hide();
      });
  }
  goBack() {
    if (this.customerView == true) {
      this.globalFilter = '';
      this.customerView = false;
    } else {
      this._location.back();
    }
  }

  onFilterApply(event: any) {
    if (event?.target?.value?.length === 0) {
      this.showTypeAhead = true;

      this.globalFilter = '';
      this.fetchMonitoringCompanyList();
    }
    if (event?.target?.value && event?.target?.value?.length > 2) {
      if (this.lastSearchStr) {
        !this.globalFilter.includes(this.lastSearchStr)
          ? (this.apiCallMade = true)
          : (this.apiCallMade = false);
      } else {
        this.apiCallMade = true;
      }
      if (this.apiCallMade || this.lastApiResponseHadData) {
        this.isFilterApplied = true;
        this.fetchMonitoringCompanyList();
        this.showTypeAhead = true;
      }
    }
    if (
      event === true &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.isFilterApplied = true;
      this.previous = 0;
      this.clientPaginationData = {};
      this.fetchMonitoringCompanyList();
      this.showTypeAhead = false;
    } else if (
      event.key === 'Enter' &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.previous = 0;
      this.isFilterApplied = true;
      this.clientPaginationData = {};
      this.fetchMonitoringCompanyList();
      this.showTypeAhead = false;
    }
  }
  onClearFilter() {
    this.pageNum = 1;
    this.previous = 0;
    this.clientPaginationData = {};
    this.globalFilter = '';
    this.showTypeAhead = true;
    this.isFilterApplied = false;
    this.spinnerService.show();
    this.fetchMonitoringCompanyList();
  }
  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.fetchMonitoringCompanyList();
    if (this.globalFilter) this.showTypeAhead = false;
  }
  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.clientPaginationData = {};
    this.fetchMonitoringCompanyList();
    if (this.globalFilter) this.showTypeAhead = false;
  }
  openAddCompanyForm() {
    this.updateView = false;
    this.dialogref = this.dialogService.open(
      AddEditMonitoringCompanyComponent,
      {
        context: { updateView: this.updateView },
      }
    );
    this.dialogref.onClose.subscribe((value: any) => {
      if (value != 'close') {
        this.fetchMonitoringCompanyList();
      }
    });
  }
  openEditCompanyForm(data: any) {
    this.companyData = data;
    this.updateView = true;
    this.dialogref = this.dialogService.open(
      AddEditMonitoringCompanyComponent,
      {
        context: { updateView: this.updateView, companyData: this.companyData },
      }
    );
    this.dialogref.onClose.subscribe((value: any) => {
      if (value != 'close') {
        this.fetchMonitoringCompanyList();
      }
    });
  }
  deleteCompany(data: any) {
    let dialogMsg = 'WARNING: This action cannot be reversed.';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: 'Delete Company',
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.spinnerService.show();
        this.customerService
          .deleteCompany(data.id, { delete: 1 })
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.fetchMonitoringCompanyList();
              this.spinnerService.hide();
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.spinnerService.hide();
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    });
  }
}
