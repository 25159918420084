import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';
import 'leaflet.fullscreen';
import 'leaflet.markercluster';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { mapLayers } from 'src/global.variables';
@Component({
  selector: 'app-drag-map',
  templateUrl: './drag-map.component.html',
  styleUrls: ['./drag-map.component.scss'],
})
export class DragMapComponent implements OnInit, AfterViewInit {
  markerLatLon: any = {};
  @Input() latLon: any;
  @Output('emitData') emitData = new EventEmitter();

  marker: any;
  default_zoom = 5;
  incremental_zoom = 2;
  baselayers: any = mapLayers;
  defaultLayer = this.baselayers['Default'];
  pwaApp: boolean = Boolean(
    window.matchMedia('(display-mode: standalone)').matches
  );
  constructor(
    private spinnerService: NgxSpinnerService,
    private appService: AppService
  ) {}
  ngOnInit(): void {}
  async getLatLon() {
    if (this.latLon) {
      this.markerLatLon = this.latLon;
      this.default_zoom = 16;
    } else {
      this.spinnerService.show();
      this.default_zoom = 17;
      this.latLon = await this.appService.getGpsCoordinates();
      this.markerLatLon = this.latLon;
      this.emitData.emit({ lat: this.latLon?.lat, lng: this.latLon?.lon });
      this.spinnerService.hide();
    }
  }
  async ngAfterViewInit() {
    await this.getLatLon();

    const osmLayer = L.tileLayer(
      'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      {
        maxZoom: 18,
        minZoom: 10,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution:
          '&copy; <a href="https://maps.google.com/help/terms_maps.html">Google Maps</a> ',
      }
    );
    let center;
    center = L.latLng(this.markerLatLon.lat, this.markerLatLon.lon);
    var container: any = L.DomUtil.get('mapId');
    if (container != null) {
      container._leaflet_id = null;
    }

    let _map = L.map('mapId', {
      center: center,
      dragging: true,
      zoom: this.default_zoom,
      layers: [osmLayer],
      fullscreenControl: true,
      fullscreenControlOptions: {
        // optional
        title: 'Show me the fullscreen !',
        titleCancel: 'Exit fullscreen mode',
      },
    });
    L.control.layers(this.baselayers, {}).addTo(_map);
    this.baselayers['Default'].addTo(_map);
    const markerIcon = L.icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      iconUrl: 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png',
      shadowUrl:
        'https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png',
    });
    const markerCluster = new L.MarkerClusterGroup();
    var app = this;

    this.marker = L.marker(
      new L.LatLng(this.markerLatLon.lat, this.markerLatLon.lon),
      {
        icon: markerIcon,
        draggable: false,
      }
    );
    markerCluster.addLayer(this.marker);
    // function that checks whether drag of existing marker happened on a map
    this.marker.on('dragend', function (event: any) {
      var marker = event.target;
      var position = marker.getLatLng();
      marker.setLatLng(new L.LatLng(position.lat, position.lng), {
        draggable: false,
      });

      app.emitData.emit(position);
      _map.panTo(new L.LatLng(position.lat, position.lng));
    });

    // function that checks whether drag of existing marker happened on a map
    _map.on('click', (event) => {
      var position = event.latlng;
      app.emitData.emit(position);
      this.marker.setLatLng([position.lat, position.lng]);
      let zoom = this.default_zoom + this.incremental_zoom;
      this.default_zoom = zoom;
      _map.fitBounds(markerCluster.getBounds(), { maxZoom: zoom });
    });
    _map.on('zoom', (event) => {
      if (event.target?._zoom) this.default_zoom = event.target?._zoom;
    });

    _map.addLayer(markerCluster);
    _map.dragging.enable();
    if (this.pwaApp == true) {
      let embeddedLinkElement: any = document.querySelectorAll(
        '.leaflet-control-attribution.leaflet-control'
      );
      if (embeddedLinkElement) {
        embeddedLinkElement.forEach((element: any) => {
          element.classList.add('disable-pointer-events');
        });
      }
    }
    this.spinnerService.hide();
  }
}
