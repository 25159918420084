import { DatePipe, formatDate } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddEditUsersComponent } from 'src/app/pages/users/add-edit-users/add-edit-users.component';
import { CustomerService } from '../../../pages/customer/customer.service';
import { JobsService } from '../../../pages/jobs/jobs.service';
import { PagesService } from '../../../pages/pages.service';
import { DataCheckService } from '../../service/data-check.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ContactComponent } from '../contact/contact.component';

@Component({
  selector: 'app-event-scheuduler',
  templateUrl: './event-scheuduler.component.html',
  styleUrls: ['./event-scheuduler.component.scss'],
})
export class EventScheudulerComponent implements OnInit {
  @Output() onEmitData = new EventEmitter();
  @Input() detailData: any;
  @Input() scheduleData: any;

  usersList: any = [];

  eventDateRange: any;
  dynamicLastDays: any;
  disableInputField: boolean = false;
  repeatTypes = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
  ];
  mailValues = [{ value: '' }];
  schedulerForm: UntypedFormGroup = new UntypedFormGroup({
    mail_time: new UntypedFormControl('', [Validators.required]),
    // start_time: new UntypedFormControl('',null),
    start_day: new UntypedFormControl('', []),
    end_day: new UntypedFormControl(null, []),
    repeat_times: new UntypedFormControl(null),
    repeat_type: new UntypedFormControl(null, [Validators.required]),
    repeat_details: new UntypedFormControl(null, []),
    repeat_interval: new UntypedFormControl(null, []),
    position: new UntypedFormControl(null, []),
    week_day: new UntypedFormControl(null, []),
    month_repeat_case: new UntypedFormControl(null, []),
  });
  selectedUsers: any = [];
  reportName: any;
  isAdmin: any;
  dialogref: any;
  contactForm: UntypedFormGroup = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    title: new UntypedFormControl(),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    mobile_number: new UntypedFormControl(null, [
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9]*$'),
    ]),
  });
  mailTime: any;
  startDate = new Date(new Date().setDate(new Date().getDate() - 1));
  isDispatchUser: boolean = false;

  constructor(
    private toasterService: NbToastrService,
    @Optional() protected dialogRef: NbDialogRef<EventScheudulerComponent>,
    private jobService: JobsService,
    private spinnerService: NgxSpinnerService,
    private customerService: CustomerService,
    private pageService: PagesService,
    private dialogService: NbDialogService,
    private dataCheckService: DataCheckService,
    private router: Router,
    private datePipe: DatePipe
  ) {
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
      }
    });
  }

  ngOnInit(): void {
    if (window.localStorage.getItem('userData')) {
      this.isAdmin = this.dataCheckService.isUserAdmin();
      this.isDispatchUser = this.dataCheckService.isDispatchUser();
    }
    if (this.scheduleData) {
      this.formatScheduleData(this.scheduleData);
      this.getUsersApi({
        union: 1,
        company_id: this.detailData?.id,
      });
    } else {
      var currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate.setMinutes(0);
      this.schedulerForm.controls['start_day'].setValue(currentDate);
      this.schedulerForm.controls['repeat_type'].setValue('daily');
      this.checkMailTime(currentDate);
    }
  }
  checkMailTime(currentDate: any) {
    var abc: any;
    abc = new Date(currentDate);
    abc.setHours(parseInt(abc.getHours()));
    abc.setMinutes(parseInt(abc.getMinutes()));
    abc.setSeconds(5);
    abc = abc.toLocaleTimeString('en-Gb', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      hourCycle: 'h23',
    });
    abc = abc.replace(':05', '');
    this.schedulerForm.controls['mail_time'].setValue(abc);
    this.mailTime = this.convertTime12to24(abc);
  }
  goBack(data: any) {
    if (data) {
      this.dialogRef.close(data);
    } else {
      this.dialogRef.close('false');
    }
    this.schedulerForm.reset();
    this.selectedUsers = [];
    this.reportName = null;
  }
  getTime(endTime: string) {
    let randomDate = new Date(); // This will give you the current date. You can replace it with any date you want.

    // Split the endTime into hours, minutes and seconds
    let timeParts = endTime.split(':');

    // Set the hours, minutes and seconds of randomDate
    randomDate.setHours(parseInt(timeParts[0]));
    randomDate.setMinutes(parseInt(timeParts[1]));
    randomDate.setSeconds(parseInt(timeParts[2]));

    return randomDate;
  }
  convertTime24to12(time: any) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
      time.splice(3, 1);
    }
    return time.join(''); // return adjusted time or original string
  }
  formatScheduleData(schedule: any) {
    this.reportName = schedule?.name;
    this.schedulerForm.controls['start_day'].setValue(
      schedule?.schedule?.start_day
    );

    let endTime = this.convertTime24to12(schedule?.mail_time);

    this.schedulerForm.controls['mail_time'].setValue(endTime);
    this.mailTime = this.convertTime12to24(endTime);
    this.schedulerForm.controls['repeat_type'].setValue(
      schedule?.schedule?.repeat_type
    );

    schedule?.report_users?.forEach((item: any) => {
      this.selectedUsers.push(item);
    });
  }

  onClearForm() {
    this.schedulerForm.reset();
  }
  formateStartAndEndDate(event: any) {}

  addMoreEmail() {
    this.mailValues.push({ value: '' });
  }
  onCloseDialogue() {
    this.dialogRef.close('false');
  }

  clearFilters() {}
  convertTime12to24(time12h: any) {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours == '12') {
      hours = '00';
    }

    if (modifier.toUpperCase() === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }
  removeUser(deleteUser: any): void {
    this.selectedUsers = this.selectedUsers.filter(
      (user: any) => user?.pk !== deleteUser?.pk
    );

    if (deleteUser?.id === 0 || !this.selectedUsers.length)
      this.disableInputField = false;
  }

  addUser(user: any): void {
    if (this.selectedUsers.some((_user: any) => _user?.pk === user?.pk)) {
      this.toasterService.warning('', 'User/Email already added');
    } else {
      let data: any;
      if (user?.pk) {
        data = {
          pk: user?.pk,
          email: user?.user_email ? user?.user_email : user.email,
          first_name: user?.user_first_name
            ? user?.user_first_name
            : user.first_name,
          last_name: user?.user_last_name
            ? user?.user_last_name
            : user.last_name,
          full_name: user?.user_full_name
            ? user?.user_full_name
            : user.full_name,
          location_contact_id: user?.location_contact_id
            ? user?.location_contact_id
            : user.is_only_location_contact,
          mobile_number: user?.user_mobile_number
            ? user?.user_mobile_number
            : user.mobile_number,
        };
      } else {
        data = {
          pk: user?.subscriber_user_id,
          email: user?.email,
          first_name: user?.first_name,
          last_name: user?.last_name,
          full_name: user?.full_name,
          location_contact_id: user?.id,
          mobile_number: user?.user_mobile_number,
        };
      }

      this.selectedUsers.push(data);
      this.usersList = this.usersList.filter(
        (value1: any) =>
          !this.selectedUsers.some((value2: any) => value1.pk === value2.pk)
      );
    }
  }
  getUsersApi(params: any) {
    this.customerService
      .searchLocationContacts(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          let users = response['data'];
          users = users.filter(
            (value1: any) =>
              !this.selectedUsers.some((value2: any) => value1.pk === value2.pk)
          );
          this.usersList = users;
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  getUsers(event: any = null) {
    if (this.selectedUsers.some((user: any) => user?.id === 0)) {
      this.toasterService.danger(
        '',
        'Already selected anyone. Remove that to add particular users'
      );
    } else {
      let value = event?.target?.value;
      if (value?.length > 2) {
        let params: any = {
          union: 1,
          company_id: this.detailData?.id,
          search_str: value,
        };
        this.getUsersApi(params);
      }
    }
  }
  addToContactsTemp() {
    if (this.isAdmin) {
      let clientData: any = this.detailData;
      this.dialogref = this.dialogService.open(ContactComponent, {
        context: {
          clientData: clientData,
          contactForm: this.contactForm,
          patrolReportUsers: true,
          contact_type: 'pc',
          reportId: this.scheduleData?.id,
        },
        dialogClass: 'model-full',
      });
      this.dialogref.onClose.subscribe((value: any) => {
        this.spinnerService.hide();

        if (value !== 'false') {
          if (this.scheduleData) {
            if (value?.length) {
              let lastAddedContact = value[value?.length - 1];
              if (lastAddedContact?.user_email || lastAddedContact?.email) {
                this.addUser(lastAddedContact);
              }
            }
          } else {
            if (value) {
              let lastAddedContact = value;
              if (lastAddedContact?.user_email || lastAddedContact?.email) {
                this.addUser(lastAddedContact);
              }
            }
          }
          this.shiftFocus('description');
        }
      });
    }
  }
  formatTime(event: any) {
    this.mailTime = this.convertTime12to24(event);
  }
  async getRequestData(listData: any) {
    return new Promise(async (resolve) => {
      if (!listData.length) resolve(null);
      let data: any = {};
      await Promise.all(
        listData.map((item: any) => {
          const {
            pk,
            id,
            full_name,
            email,
            is_only_location_contact,
            location_contact_id,
            ...rest
          } = item;
          data[item.pk] = {
            ...rest,
          };
        })
      );

      resolve(data);
    });
  }
  async saveSchedule() {
    this.spinnerService.show();
    if (this.schedulerForm.valid) {
      this.spinnerService.show();
      this.schedulerForm.value.start_day = formatDate(
        this.schedulerForm.value.start_day,
        'yyyy-MM-dd',
        'en'
      );
      let requestData: any = this.schedulerForm?.value;
      let timezoneOffset = new Date();
      requestData['time_offset'] = timezoneOffset.getTimezoneOffset();
      requestData['mail_time'] = this.mailTime;
      requestData['user_ids'] = await this.getRequestData(this.selectedUsers);
      requestData['name'] = this.reportName;
      this.customerService
        .updateClient(this.detailData?.id, requestData, {
          mail_report: 1,
        })
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.goBack(response);
          } else {
            this.pageService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
          this.spinnerService.hide();
        });
    }
  }
  async updateSchedule() {
    if (this.scheduleData?.id && this.schedulerForm.valid) {
      this.spinnerService.show();
      this.schedulerForm.value.start_day = formatDate(
        this.schedulerForm.value.start_day,
        'yyyy-MM-dd',
        'en'
      );
      let requestData: any = this.schedulerForm?.value;
      let timezoneOffset = new Date();
      requestData['time_offset'] = timezoneOffset.getTimezoneOffset();
      requestData['mail_time'] = this.mailTime;
      requestData['user_ids'] = await this.getRequestData(this.selectedUsers);
      requestData['name'] = this.reportName;
      requestData['report_id'] = this.scheduleData?.id;
      this.customerService
        .updateClient(this.detailData?.id, requestData, {
          mail_report: 1,
        })
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.goBack(response);
          } else {
            this.pageService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
          this.spinnerService.hide();
        });
    }
  }

  deleteSchdeule() {
    if (this.scheduleData?.id) {
      this.dialogref = this.dialogService.open(ConfirmDialogComponent, {
        context: {
          title: 'Are you sure?',
          message:
            'Delete schedule means the contacts wont receive any mails of the patrol updates',
        },
      });
      this.dialogref.onClose.subscribe((value: any) => {
        if (value === 'Yes') {
          this.spinnerService.show();
          this.customerService
            .updateClient(
              this.detailData?.id,
              { report_id: this.scheduleData?.id },
              {
                delete_mail_report: 1,
              }
            )
            .subscribe((response: any) => {
              if (response['status'] == 'success') {
                this.goBack(response);
              } else {
                this.pageService.setMessage({
                  successMessage: '',
                  errorMessage: response['message'],
                });
              }
              this.spinnerService.hide();
            });
        }
      });
    }
  }
  shiftFocus(elementId: string, time?: number) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element.focus();
    }, time);
  }
  openUserTemplate(user: any) {
    this.dialogref = this.dialogService.open(ContactComponent, {
      context: {
        contactData: user,
        contact_type: 'pc',
        clientData: this.detailData,
        reportId: this.scheduleData?.id,
      },
      dialogClass: 'model-full',
    });
    this.dialogref.onClose.subscribe((dialogResponse: any) => {
      if (dialogResponse !== 'false') {
        // Update the user with the updated data
        this.selectedUsers = dialogResponse;
        this.scheduleData.report_users = dialogResponse;
      }
    });
  }
  openUserDialogBox(user: any) {
    const dialogRefUser = this.dialogService.open(AddEditUsersComponent, {
      context: {
        editUser: true,
        detailData: user,
      },
    });
    dialogRefUser.onClose.subscribe((value: any) => {});
  }
}
