<nb-card>
  <nb-tabset>
    <nb-tab tabTitle="Licenced">
      <nb-alert
        *ngIf="userDataList && !hasKeys(userDataList[0])"
        outline="basic"
        style="font-weight: 500"
        >No licenses have been added by any Guards.</nb-alert
      >
      <div *ngIf="showChart && userDataList[0] && hasKeys(userDataList[0])">
        <highcharts-chart
          [Highcharts]="highChartComponent"
          [options]="pieChartOptions"
          style="width: 100%; height: 400px; display: block"
        >
        </highcharts-chart>
      </div>
      <div class="sc-mt-6 sc-head5">
        {{ selectedItem }}
      </div>
      <div clas="sc-mt-6" *ngIf="expandedData?.length">
        <app-card-list
          class="mobile-only"
          [listCardData]="expandedData"
          [listCardDetails]="licencedGuardDetails"
          (rowClick)="openGuardInfo($event)"
        ></app-card-list>
        <app-gt-table
          class="large-view-only"
          [desktopTableUIData]="expandedData"
          [desktopTableDetails]="licenceDetailsDesktopTable"
          (rowClick)="openGuardInfo($event)"
        >
        </app-gt-table>
      </div>
    </nb-tab>
    <nb-tab tabTitle="Un-Licenced">
      <nb-alert
        *ngIf="userDataList && !hasKeys(userDataList[1])"
        outline="basic"
        style="font-weight: 500"
        >No Users without License.</nb-alert
      >
      <div clas="sc-mt-6" *ngIf="userDataList && hasKeys(userDataList[1])">
        <app-card-list
          class="mobile-only"
          [listCardData]="userDataList?.[1]?.users"
          [listCardDetails]="unLicnesedGuardDetails"
          (rowClick)="openGuardInfo($event)"
        ></app-card-list>
        <app-gt-table
          class="large-view-only"
          [desktopTableUIData]="userDataList?.[1]?.users"
          [desktopTableDetails]="unLicnesedGuardTableDetails"
          (rowClick)="openGuardInfo($event)"
        >
        </app-gt-table>
      </div>
    </nb-tab>
  </nb-tabset>
</nb-card>
