import { Component, OnInit, TemplateRef } from '@angular/core';
import { NbDialogService, NbDialogRef } from '@nebular/theme';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-pwa-installation-guide',
  templateUrl: './pwa-installation-guide.component.html',
  styleUrls: ['./pwa-installation-guide.component.scss'],
})
export class PwaInstallationGuideComponent implements OnInit {
  installationSteps: any = [
    {
      imgSrc: '../../../assets/screenshots/iPhone2.png',
      description: '1. Share Menu',
    },
    {
      imgSrc: '../../../assets/screenshots/iPhone3.png',
      description: '2. Add to Home Screen.',
    },
    {
      imgSrc: '../../../assets/screenshots/iPhone_Add_Home.png',
      description: '3. Add',
    },
  ];
  currentStep = 0;
  fullImgSrc: any;
  constructor(
    private deviceService: DeviceDetectorService,
    private dialog: NbDialogService,
    protected ref: NbDialogRef<PwaInstallationGuideComponent>
  ) {}

  ngOnInit(): void {
    let device = this.deviceService.getDeviceInfo();
    if (device.device === 'iPhone') {
      if (device.browser == 'Chrome') {
        this.installationSteps = [];
        this.installationSteps = [
          {
            imgSrc: '../../../assets/screenshots/iPhone2.png',
            description: 'Click on the share icon showing at Top right',
          },
          {
            imgSrc: '../../../assets/screenshots/iPhone3.png',
            description: 'Select Add to Home Screen option.',
          },
          {
            imgSrc: '../../../assets/screenshots/iPhone_Add_Home.png',
            description:
              'Here you can check/edit name and your app will be installed!',
          },
        ];
      } else if (device.browser === 'Safari') {
        this.installationSteps = [];
        this.installationSteps = [
          {
            imgSrc: '../../../assets/screenshots/iPhone2_Safari.png',
            description: 'Click on the share icon showing at Bottom middle',
          },
          {
            imgSrc: '../../../assets/screenshots/iPhone3_Safari.png',
            description: 'Select Add to Home Screen option.',
          },
          {
            imgSrc: '../../../assets/screenshots/iPhone_Add_Home_Safari.png',
            description:
              'Here you can check/edit name and your app will be installed!',
          },
        ];
      }
    }
  }
  navigate(step: number) {
    this.currentStep += step;
  }
  onOpenImage(event: any, requestRef: TemplateRef<any>) {
    const imgElem = event.target;
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;
    this.fullImgSrc = srcAttr.nodeValue;
    this.ref = this.dialog.open(requestRef, {
      closeOnBackdropClick: true,
      context: {},
    });
  }
  close(data?: any) {
    this.ref.close('close');
  }
}
