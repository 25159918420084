<nb-card style="padding: 0 1rem" class="dialog-box">
  <ng-container>
    <nb-card-header class="sc-text-center">
      Change Log
      <nb-icon
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
        (click)="onDismis()"
      >
      </nb-icon>
    </nb-card-header>
    <div class="notification-list py-4">
      <div class="small-font">
        <span class="fw-bold"
          >v{{ appVersionUpdates?.last_item?.version }}
          <em>
            Released
            {{
              appVersionUpdates?.last_item?.updated_at | customdate : "d MMM y"
            }}</em
          ></span
        >

        <div style="white-space: pre-line" class="sc-mt-6">
          {{ appVersionUpdates?.last_item?.commit_messages }}
        </div>
        <hr />
      </div>

      <ng-container *ngIf="appVersionUpdates?.previous_changes?.length">
        <!--previous version updates-->
        <div
          *ngFor="
            let version of appVersionUpdates?.previous_changes;
            let l = last
          "
          class="small-font"
        >
          <span class="fw-bold"
            >v{{ version?.version }}
            <em>
              Released
              {{ version?.updated_at | customdate : "d MMM y" }}</em
            ></span
          >

          <div style="white-space: pre-line" class="sc-mt-6">
            {{ version?.commit_messages }}
          </div>
          <hr *ngIf="!l" />
        </div>
      </ng-container>
    </div>
  </ng-container>
</nb-card>
