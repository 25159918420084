import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { subscriberStatus } from 'src/global.variables';
import { PagesService } from '../../pages.service';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss'],
})
export class SubscribersComponent implements OnInit {
  subscriberTableData: any;
  subscriberData: any;

  subscriberStorageTable: any = [
    {
      header: 'Subscriber',
      name: 'name',
      sortKey: 'name',
      searchKey: 'name',
    },
    {
      header: 'Total Users',
      nestedValue: (row: any) => {
        return row?.stats?.user_count;
      },
    },
    {
      header: 'Storage',
      nestedValue: (row: any) => {
        if (row?.aws_storage?.subscriber_used_storage) {
          return `${
            row?.aws_storage?.subscriber_used_storage
          } ${row?.aws_storage?.unit?.toUpperCase()}`;
        } else {
          return;
        }
      },
    },
  ];
  inactiveSubscriberColumns: any = [
    {
      header: 'Subscriber',
      name: 'name',
      sortKey: 'name',
      searchKey: 'name',
    },

    {
      header: 'Total Users',
      nestedValue: (row: any) => {
        if (row?.subscription?.allotted_slots) {
          return (
            row?.stats?.user_count + '/' + row?.subscription?.allotted_slots
          );
        } else {
          return row?.stats?.user_count;
        }
      },
    },

    {
      header: 'Clients',
      nestedValue: (row: any) => {
        return row?.stats?.client_count;
      },
    },
    {
      header: 'Last Activity Time',
      name: 'last_activity_time',
      datetimeObj: true,
      sortKey: 'last_activity_time',
    },

    {
      header: 'Last Activity',
      name: 'last_activity',
      sortKey: 'last_activity',
    },
    {
      header: 'Last Activity User',
      name: 'last_activity_user',
      sortKey: 'last_activity_user',
    },
    {
      header: 'Started',
      name: 'created_date',
      datetimeObj: true,
      sortKey: 'created_date',
    },
  ];
  tableColumns = {
    columns: [
      {
        header: 'Status',
        sortKey: 'status',
        searchKey: 'status',
        nestedValue: (row: any) => {
          const statusDict: any = subscriberStatus.statusValues;

          if (statusDict.hasOwnProperty(row?.status) && row?.status) {
            return statusDict[row.status];
          }

          return '';
        },
        conditionalColumnDataStyle: (row: any) => {
          const statusStyleDict: any = subscriberStatus.statusStyles;
          if (statusStyleDict.hasOwnProperty(row?.status) && row?.status) {
            return statusStyleDict[row.status];
          }

          return {};
        },
      },
      {
        header: 'Subscriber',
        name: 'name',
        sortKey: 'name',
        searchKey: 'name',
      },
      {
        header: 'Plan Name',
        nestedValue: (row: any) => {
          return row?.subscription?.subscription_plan?.name;
        },
      },
      {
        header: 'MRR ($)',
        nestedValue: (row: any) => {
          if (row?.subscription?.subscription_plan?.amount) {
            return '$' + row?.subscription?.subscription_plan?.amount;
          } else {
            return;
          }
        },
        tableColumnStyle: {
          'text-align': 'center',
        },
      },
      {
        header: 'Add. User Rate ($)',
        nestedValue: (row: any) => {
          if (row?.subscription?.subscription_plan?.user_cost) {
            return '$' + row?.subscription?.subscription_plan?.user_cost;
          } else {
            return;
          }
        },
        tableColumnStyle: {
          'text-align': 'center',
        },
      },

      {
        header: 'Guards',
        nestedValue: (row: any) => {
          return row?.subscription?.subscription_plan?.allowed_users;
        },
        tableColumnStyle: {
          'text-align': 'center',
        },
      },

      {
        header: 'Add. Guards',
        nestedValue: (row: any) => {
          if (
            row?.subscription?.allotted_slots >=
            row?.subscription?.subscription_plan?.allowed_users
          ) {
            return (
              row?.subscription?.allotted_slots -
              row?.subscription?.subscription_plan?.allowed_users
            );
          }
          return;
        },
        tableColumnStyle: {
          'text-align': 'center',
        },
      },

      {
        header: 'Total Users',
        nestedValue: (row: any) => {
          if (row?.subscription?.allotted_slots) {
            return (
              row?.stats?.user_count + '/' + row?.subscription?.allotted_slots
            );
          } else {
            return row?.stats?.user_count;
          }
        },
        tableColumnStyle: {
          'text-align': 'center',
        },
      },

      {
        header: 'Clients',
        nestedValue: (row: any) => {
          return row?.stats?.client_count;
        },
      },
      {
        header: 'Started',
        name: 'created_date',
        datetimeObj: true,
        sortKey: 'created_date',
      },

      {
        header: 'Trial Ends On',
        name: 'trial_end_validity',
        datetimeObj: true,
        sortKey: 'trial_end_validity',
      },
    ],
  };
  mobileCardSubscriberDetails: any;
  mobileCardDetails = [
    {
      header: 'Status',
      sortKey: 'status',
      searchKey: 'status',
      cardRowStyle: { float: 'right' },
      nestedValue: (row: any) => {
        const statusDict: any = subscriberStatus.statusValues;

        if (statusDict.hasOwnProperty(row?.status) && row?.status) {
          return statusDict[row.status];
        }

        return '';
      },
      conditionalRowDataStyle: (row: any) => {
        const statusStyleDict: any = subscriberStatus.statusStyles;

        if (statusStyleDict.hasOwnProperty(row?.status) && row?.status) {
          return statusStyleDict[row.status];
        }

        return {};
      },
    },
    {
      header: 'Subscriber',
      name: 'name',
      sortKey: 'name',
      searchKey: 'name',
    },
    {
      header: 'Plan Name',
      nestedValue: (row: any) => {
        return row?.subscription?.subscription_plan?.name;
      },
    },
    {
      header: 'MRR ($)',
      nestedValue: (row: any) => {
        if (row?.subscription?.subscription_plan?.amount) {
          return '$' + row?.subscription?.subscription_plan?.amount;
        } else {
          return;
        }
      },
      tableColumnStyle: {
        'text-align': 'center',
      },
    },
    {
      header: 'Add. User Rate ($)',
      nestedValue: (row: any) => {
        if (row?.subscription?.subscription_plan?.user_cost) {
          return '$' + row?.subscription?.subscription_plan?.user_cost;
        } else {
          return;
        }
      },
      tableColumnStyle: {
        'text-align': 'center',
      },
    },

    {
      header: 'Guards',
      nestedValue: (row: any) => {
        return row?.subscription?.subscription_plan?.allowed_users;
      },
      tableColumnStyle: {
        'text-align': 'center',
      },
    },

    {
      header: 'Add. Guards',
      nestedValue: (row: any) => {
        if (
          row?.subscription?.allotted_slots >=
          row?.subscription?.subscription_plan?.allowed_users
        ) {
          return (
            row?.subscription?.allotted_slots -
            row?.subscription?.subscription_plan?.allowed_users
          );
        }
        return;
      },
      tableColumnStyle: {
        'text-align': 'center',
      },
    },

    {
      header: 'Total Users',
      nestedValue: (row: any) => {
        if (row?.subscription?.allotted_slots) {
          return (
            row?.stats?.user_count + '/' + row?.subscription?.allotted_slots
          );
        } else {
          return row?.stats?.user_count;
        }
      },

      tableColumnStyle: {
        'text-align': 'center',
      },
    },

    {
      header: 'Clients',
      nestedValue: (row: any) => {
        return row?.stats?.client_count;
      },
    },
    {
      header: 'Started',
      name: 'created_date',
      datetimeObj: true,
      sortKey: 'created_date',
    },

    {
      header: 'Trial Ends On',
      name: 'trial_end_validity',
      datetimeObj: true,
      sortKey: 'trial_end_validity',
    },
  ];
  subscriberListTableDetail: any;
  totalsubscribers: any;
  reportData: any;
  tableHeadersHistory = {
    tableID: 'gt-admin-grid',
    columns: [
      {
        header: 'Amount',
        name: 'amount',
      },
      {
        header: 'Description',
        name: 'description',
      },
      {
        header: 'Updated On',
        name: 'date',
        datetimeObj: true,
      },
      {
        header: 'User',
        nestedValue: (row: any) => {
          return row?.['transacted_by']?.['full_name'];
        },
      },
    ],
    actionColumn: [
      {
        icon: 'edit-2-outline',
        status: 'primary',
        confirm: true,
        type: 'edit',
        show: 'inline',
      },
    ],
  };
  showData: boolean = false;
  showTypeAhead: boolean = true;
  showSearchBox: boolean = false;

  previous: number = 0;

  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;

  betaSubscriberForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', Validators.required),
    test_user_validity: new UntypedFormControl(''),
  });

  //bulk update variables
  expiryTime: any;
  changeReason: any;
  dropdownSubscribers: any;
  boolSelectSubscribers: boolean = false;
  selectedSubscribers: any = [];
  bulkExpire: boolean = false;
  closedAccounts: boolean = false;
  otherParamsSubscribers: any = {};
  otherParamsTrialHistory: any = {};
  sortKey: any;
  inactiveAccounts: boolean = false;

  trialDays: number = 0;
  searchParams: any;
  subscriberStorageData: boolean = false;
  dialogRef: any;

  constructor(
    private spinnerService: NgxSpinnerService,
    private adminService: AdminService,
    private _location: Location,
    private dialogService: NbDialogService,
    private router: Router,
    private pageService: PagesService
  ) {
    this.subscriberListTableDetail = this.tableColumns.columns;
    this.mobileCardSubscriberDetails = this.mobileCardDetails;
    this.getAllSubscribers();
    this.getWalletHistory();
  }

  ngOnInit(): void {}

  getAllSubscribers() {
    this.spinnerService.show();
    let params: any = {};

    if (this.closedAccounts) {
      params.closed_accounts = 1;
    }
    if (this.inactiveAccounts) {
      params.inactive = 1;
    }
    if (this.searchParams) {
      Object.entries(this.searchParams).forEach(
        ([key, value]) => (params[key] = value)
      );
    }
    if (this.rows) {
      params['page_size'] = this.rows;
    }
    if (this.pageNum) {
      params['page'] = this.pageNum;
    }
    if (this.sortKey) {
      params['order_by'] = this.sortKey;
    }
    if (this.subscriberStorageData) {
      params['aws_storge'] = 1;
    }

    this.adminService
      .getSubscribersListForAdmin(params)
      .subscribe((response: any) => {
        this.subscriberTableData = response;
        this.totalsubscribers = response.count;
        this.totalRows = response?.count;
        this.totalPages = Math.ceil(this.totalRows / this.rows);
        this.otherParamsSubscribers.paginationData = {
          tableTotalRows: response?.count,
          tablePreviousRows: this.previous,
          tableRows: this.rows,
          tablePageNum: this.pageNum,
          tableTotalPages: this.totalPages,
        };
        this.spinnerService.hide();
      });
  }
  getWalletHistory() {
    let params = { event_name: 'WALLET' };
    this.adminService.getEventLogs(params).subscribe((response: any) => {
      if (response['status'] === 'success') {
        this.reportData = response?.data;
        this.showData = true;
      }
    });
  }

  onRowClick(data: any) {
    this.router.navigate([
      '/gtadmin/subscribers-detail',
      { sKey: String(data?.subscriber_id) },
    ]);
  }
  openAddSubsriptionPlan() {}
  goBack() {
    this._location.back();
  }

  onChangePagination(data: any) {
    this.previous = data?.paginationData?.tablePreviousRows;
    this.pageNum = data?.paginationData?.tablePageNum;
    this.rows = data?.paginationData?.tableRows;

    this.otherParamsSubscribers.paginationData = {
      ...data?.paginationData,
    };
    this.getAllSubscribers();
  }
  searchColumn(seachData: any) {
    this.searchParams = seachData?.searchParams || {};
    this.getAllSubscribers();
  }

  sortColumn(body: any) {
    this.sortKey = body?.sortList || [];

    this.getAllSubscribers();
  }

  openTemplate(template: TemplateRef<any>) {
    {
      this.dialogRef = this.dialogService.open(template, {
        context: {},
      });
    }
  }

  manualSelect(event: any) {
    this.boolSelectSubscribers = event;
  }
  removeSubscribers(subscriber: any): void {
    this.selectedSubscribers = this.selectedSubscribers.filter(
      (sId: any) => sId?.id !== subscriber?.id
    );
  }

  addSubscribers(id: number, name: string): void {
    if (this.selectedSubscribers.some((user: any) => user?.id === id)) {
      this.pageService.setMessage({
        errorMessage: 'Subscriber Already added',
        successMessage: '',
      });
    } else {
      this.selectedSubscribers.push({
        id: id,
        name: name,
      });
    }
  }
  searchSubscribers(event?: any) {
    let params: any = {
      page_size: 10,
      page: 1,
    };
    if (event?.target?.value) {
      params.subscriber_name = event?.target?.value;
    }
    this.spinnerService.show();
    this.adminService
      .getSubscribersListForAdmin(params)
      .subscribe((response: any) => {
        this.dropdownSubscribers = response;

        this.spinnerService.hide();
      });
  }
  bulkUpdateSubscriberTrial(body: any, params: any) {
    this.spinnerService.show();
    this.adminService
      .changeSubscriberTrial(body, params)
      .subscribe((response: any) => {
        this.spinnerService.hide();
        if (response?.status === 'success') {
          this.pageService.setMessage({
            successMessage: 'Updated Successfully',
            errorMessage: '',
          });
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response?.message,
          });
        }
      });
  }
  bulkUpdate() {
    let params: any = { bulk_update: 1 };
    let body: any = { reason: this.changeReason };
    if (this.expiryTime) {
      body.trial_end = this.expiryTime;
    }
    if (this.boolSelectSubscribers) {
      if (!this.selectedSubscribers?.length) {
        this.pageService.setMessage({
          errorMessage: 'Please select beta users',
          successMessage: '',
        });
      } else {
        body.subscriber_ids = [
          ...new Set([...this.selectedSubscribers].map((item) => item.id)),
        ];
        this.bulkUpdateSubscriberTrial(body, params);
      }
    } else {
      this.bulkUpdateSubscriberTrial(body, params);
    }
  }
  closedNormalSubscribers() {
    this.resetTable();
    this.subscriberStorageData = false;
    this.inactiveAccounts = false;
    this.closedAccounts = !this.closedAccounts;
    this.subscriberListTableDetail = [...this.tableColumns.columns];

    if (this.closedAccounts) {
      this.subscriberListTableDetail.push({
        header: 'Ends On',
        name: 'grace_period_end',
        datetimeObj: true,
        sortKey: 'grace_period_end',
        conditionalColumnStyle: (row: any) => {
          return {
            'background-color': 'var(--color-danger-500)',
            'color': 'var(--color-basic-100)',
            'width': 'fit-content',
            'font-size': '0.7rem',
          };
        },
      });
    }
    this.getAllSubscribers();
  }
  getNormalInactiveSubscribers() {
    this.inactiveAccounts = !this.inactiveAccounts;
    this.subscriberStorageData = false;
    this.closedAccounts = false;
    this.resetTable();
    if (this.inactiveAccounts) {
      this.subscriberListTableDetail = this.inactiveSubscriberColumns;
      this.mobileCardSubscriberDetails = this.inactiveSubscriberColumns;
    } else {
      this.subscriberListTableDetail = this.tableColumns.columns;
      this.mobileCardSubscriberDetails = this.mobileCardDetails;
    }
    this.getAllSubscribers();
  }
  getSubscriberStorage() {
    this.inactiveAccounts = false;
    this.closedAccounts = false;
    this.subscriberStorageData = !this.subscriberStorageData;
    this.resetTable();
    if (this.subscriberStorageData) {
      // this has been set to a low number because s3 takes a long time to fetch data for subscribers.
      this.rows = 5;
      this.subscriberListTableDetail = this.subscriberStorageTable;
      this.mobileCardSubscriberDetails = this.subscriberStorageTable;
    } else {
      this.subscriberListTableDetail = this.tableColumns.columns;
      this.mobileCardSubscriberDetails = this.mobileCardDetails;
    }
    this.getAllSubscribers();
  }
  resetTable() {
    this.rows = 10;
    this.previous = 0;
    this.pageNum = 1;
    this.totalPages = 0;
    this.totalRows = 0;
  }
}
