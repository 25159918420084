import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CheckpointService {
  constructor(private http: HttpClient) {}

  addCheckPoint(requestData: any) {
    const body = {
      ...requestData,
    };

    return this.http
      .post('/api/check_point/', body)
      .pipe(map((response: any) => response));
  }

  //Checkpoint APIs
  getCheckpoints(params?: any) {
    return this.http.get(`/api/check_point/`, { params: params });
  }
  deleteCheckpointById(id: any, body?: any) {
    return this.http
      .post(`/api/check_point/${id}/delete/`, body)
      .pipe(map((response: any) => response));
  }
  updateCheckpoint(cpId: string, body: any, params?: any) {
    return this.http
      .put(`/api/check_point/${cpId}/`, body, { params: params })
      .pipe(map((response: any) => response));
  }
  getRouteById(id: any) {
    return this.http.get(`/api/route/${id}/`).pipe(map((response) => response));
  }
  getCheckPointDetailsById(id: any, params?: any) {
    return this.http
      .get(`/api/check_point/${id}/`, { params: params })
      .pipe(map((response) => response));
  }

  //Route APIs

  addRoute(requestData: any) {
    const body = {
      ...requestData,
    };
    return this.http
      .post('/api/route/', body)
      .pipe(map((response: any) => response));
  }
  getRoutes(params: any) {
    return this.http.get(`/api/route/`, { params: params });
  }
  editRouteById(requestData: any) {
    const body = {
      ...requestData,
    };
    return this.http
      .put(`/api/route/${requestData.id}/`, body)
      .pipe(map((response: any) => response));
  }

  deleteRouteById(id: any) {
    return this.http.delete(`/api/route/${id}`);
  }
  enableDisableRoute(id: any) {
    return this.http.get(`/api/route/${id}/enable_disable_routes/`);
  }

  updateClient(requestData: any) {
    const body = {
      ...requestData,
    };
    return this.http
      .post(`/api/patrol/add_update/`, body)
      .pipe(map((response: any) => response));
  }
  //qr code APIs
  registerQRCode(body?: any, params?: any) {
    return this.http
      .post(`/api/check_point/register_qr/`, body, { params: params })
      .pipe(map((response: any) => response));
  }
  //qr code APIs
  downloadQRCodes(body?: any, params?: any) {
    return this.http
      .post(`/api/check_point/download_qr/`, body, {
        params: params,
      })
      .pipe(map((response: any) => response));
  }
  //missed checkpoints
  missedCheckpoints(body?: any, params?: any) {
    return this.http
      .post(`/api/check_point/missed_checkpoints/`, body, {
        params: params,
      })
      .pipe(map((response: any) => response));
  }
}
