import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { PermissionsService } from '../permissions.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  userData: any;
  isSuperUser: any;

  cameraPermission: boolean = false;
  locationPermission: boolean = false;
  notificationPermission: boolean = false;
  deviceInfo: any;
  instruction: any;
  camerError: any;
  constructor(
    private appService: AppService,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private dataCheckService: DataCheckService,
    private permissionsService: PermissionsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.spinnerService.hide();
    this.userData = this.appService.getUserData();
    this.isSuperUser = this.dataCheckService.isSuperUser();
    await this.checkPermissions();
  }
  goToHome() {
    if (this.userData && this.userData.access) {
      if (this.isSuperUser === true) {
        this.router.navigate(['gtadmin/dashboard']);
      } else {
        this.router.navigate(['/dashboard']);
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  async checkPermissions() {
    this.cameraPermission =
      await this.permissionsService.checkCameraPermission();
    this.locationPermission =
      await this.permissionsService.checkLocationPermission();
    this.notificationPermission =
      await this.permissionsService.checkNotificationPermission();
  }

  requestCameraPermission() {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then((stream) => {
        this.cameraPermission = true;
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        this.camerError = error;
      });
  }
  requestLocationPermission() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // Permission granted
      },
      (error) => {
        console.error('Location permission denied', error);
      }
    );
    window.location.reload();
  }
  requestNotificationPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        this.notificationPermission = true;
        window.location.reload();
      } else {
        console.error('Notification permission denied');
      }
    });
  }
}
