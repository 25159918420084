<nb-card class="gt-p-1">
  <nb-card-header>
    {{ individualJobTypeData ? "Edit Job Type" : "Add new Job Type" }}
  </nb-card-header>
  <nb-card-body class="gt-p-small">
    <form [formGroup]="jobTypeForm">
      <div class="row row-cols-2">
        <div class="col p-3">
          <div class="form-floating">
            <input
              class="form-control"
              fullWidth
              formControlName="name"
              nbInput
              type="text"
            />
            <label for="inputService">Name</label>
          </div>
          <!--status flow section STARTS-->
          <div
            cdkDropList
            #currentList="cdkDropList"
            [cdkDropListData]="selectedStatuses"
            class="drag-list sc-mt-6"
            (cdkDropListDropped)="drop($event)"
          >
            <table class="table drag-box">
              <thead>
                <tr>
                  <th>Name</th>
                  <th
                    nbTooltip="Determines who can see this particular status when this job type is selected."
                  >
                    Status Visibilty
                  </th>
                  <th
                    nbTooltip="This field is for the 'code' to distinguish the functions of a particular status."
                  >
                    Status Identity
                  </th>
                  <th>Reverse</th>

                  <th>
                    <nb-icon
                      *ngIf="statusDropDownList?.length"
                      [icon]="'plus'"
                      pack="eva"
                      ghost
                      (click)="openAddStatusTemplate(addStatusTemplate)"
                    >
                    </nb-icon>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of selectedStatuses; let i = index"
                  cdkDrag
                  [cdkDragDisabled]="
                    [1, 2, 3].includes(item?.status_identity?.value)
                  "
                  nbTooltip="You cannot change the order of this status"
                  [nbTooltipDisabled]="
                    [1, 2, 3].includes(item?.status_identity?.value)
                      ? false
                      : true
                  "
                  [ngClass]="
                    [1, 2, 3].includes(item?.status_identity?.value)
                      ? ''
                      : 'cursor-grab grab-item'
                  "
                >
                  <td
                    nbTooltip="{{ item?.description }}"
                    [nbTooltipDisabled]="!item?.description"
                    nbTooltipPlacement="left"
                  >
                    {{ item?.name }}
                  </td>
                  <td>{{ item?.sharing_status?.name }}</td>
                  <td>{{ item?.status_identity?.name }}</td>
                  <td>
                    <nb-checkbox
                      *ngIf="i > 0"
                      [disabled]="!jobTypeForm.value.reverse"
                      [checked]="item?.reverse"
                      (checkedChange)="item.reverse = !item?.reverse"
                    ></nb-checkbox>
                  </td>

                  <td>
                    <nb-icon
                      [icon]="'trash'"
                      pack="eva"
                      ghost
                      (click)="removeSavedStatus(item)"
                    >
                    </nb-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--status flow section ENDS-->
        </div>
        <div class="col p-3">
          <div class="form-floating">
            <input
              class="form-control"
              fullWidth
              formControlName="description"
              nbInput
              type="text"
            />
            <label for="inputService">Description</label>
          </div>
          <div class="sc-mt-9">
            <div class="row row-cols-2 gy-3">
              <div class="col">
                <nb-checkbox formControlName="enable_comments"
                  >Comments</nb-checkbox
                >
              </div>
              <div class="col">
                <nb-checkbox formControlName="is_active">Active</nb-checkbox>
              </div>

              <div class="col">
                <nb-checkbox formControlName="enable_assignees">
                  Assignees</nb-checkbox
                >
              </div>
              <div class="col">
                <nb-checkbox formControlName="free_flow">Free Flow</nb-checkbox>
              </div>
              <div class="col">
                <nb-checkbox formControlName="enable_uploads">
                  Uploads</nb-checkbox
                >
              </div>
              <div class="col">
                <nb-checkbox
                  formControlName="reverse"
                  (checkedChange)="changeReverse($event)"
                  >Is Reverse</nb-checkbox
                >
              </div>
            </div>

            <div class="sc-mt-6">
              Reopen Job Before
              <input type="number" formControlName="re_open_jobs" /> Hour(s)
            </div>
            <div class="sc-mt-6">
              <label
                for="job_identity_"
                nbTooltip="This field is for the 'code' to distinguish the functions of a particular job type."
                >Job Type Identity</label
              >
              <nb-radio-group
                class="d-flex"
                formControlName="type_identity"
                name="job_identity_"
              >
                <nb-radio
                  *ngFor="let iType of jobTypeIdentities"
                  [value]="iType[0]"
                >
                  {{ iType[1] }}
                </nb-radio>
              </nb-radio-group>
              <div class="sc-mt-6">
                <nb-alert>
                  <i
                    >To add more, add under v0 > models > job_type > JobType >
                    IdentityChoices
                  </i>
                </nb-alert>
              </div>
            </div>

            <div class="sc-mt-6">
              <nb-tabset>
                <nb-tab tabTitle="Before Select">
                  <div class="row row-cols-3 gy-2">
                    <div class="col">Background Color</div>
                    <div class="col">
                      <input
                        nbInput
                        type="text"
                        size="giant"
                        fullWidth
                        [(ngModel)]="
                          jobTypeStyle['snubbed']['background-color']
                        "
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="col">
                      <input
                        nbInput
                        fullWidth
                        size="giant"
                        type="color"
                        [(ngModel)]="
                          jobTypeStyle['snubbed']['background-color']
                        "
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="col">Text Color</div>
                    <div class="col">
                      <input
                        nbInput
                        type="text"
                        size="giant"
                        fullWidth
                        [(ngModel)]="jobTypeStyle['snubbed']['color']"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="col">
                      <input
                        nbInput
                        fullWidth
                        size="giant"
                        type="color"
                        [(ngModel)]="jobTypeStyle['snubbed']['color']"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="col">Border Color</div>
                    <div class="col">
                      <input
                        nbInput
                        type="text"
                        size="giant"
                        fullWidth
                        [(ngModel)]="jobTypeStyle['snubbed']['border-color']"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="col">
                      <input
                        nbInput
                        fullWidth
                        size="giant"
                        type="color"
                        [(ngModel)]="jobTypeStyle['snubbed']['border-color']"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                </nb-tab>
                <nb-tab tabTitle="After Select">
                  <div class="row row-cols-3 gy-2">
                    <div class="col">Background Color</div>
                    <div class="col">
                      <input
                        nbInput
                        type="text"
                        size="giant"
                        fullWidth
                        [(ngModel)]="
                          jobTypeStyle['selected']['background-color']
                        "
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="col">
                      <input
                        nbInput
                        fullWidth
                        size="giant"
                        type="color"
                        [(ngModel)]="
                          jobTypeStyle['selected']['background-color']
                        "
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="col">Text Color</div>
                    <div class="col">
                      <input
                        nbInput
                        type="text"
                        size="giant"
                        fullWidth
                        [(ngModel)]="jobTypeStyle['selected']['color']"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="col">
                      <input
                        nbInput
                        fullWidth
                        size="giant"
                        type="color"
                        [(ngModel)]="jobTypeStyle['selected']['color']"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="col">Border Color</div>
                    <div class="col">
                      <input
                        nbInput
                        type="text"
                        size="giant"
                        fullWidth
                        [(ngModel)]="jobTypeStyle['selected']['border-color']"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="col">
                      <input
                        nbInput
                        fullWidth
                        size="giant"
                        type="color"
                        [(ngModel)]="jobTypeStyle['selected']['border-color']"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                </nb-tab>
              </nb-tabset>
              <div class="sc-mt-9 row row-cols-2">
                <div class="col">
                  <button
                    nbButton
                    size="giant"
                    fullWidth
                    [ngStyle]="jobTypeStyle.snubbed"
                  >
                    {{
                      jobTypeForm.value?.name
                        ? jobTypeForm.value?.name
                        : "TEST WORD"
                    }}
                  </button>
                </div>
                <div class="col">
                  <button
                    nbButton
                    size="giant"
                    fullWidth
                    [ngStyle]="jobTypeStyle.selected"
                  >
                    {{
                      jobTypeForm.value?.name
                        ? jobTypeForm.value?.name
                        : "TEST WORD"
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row row-cols-2 sc-mt-9 m-auto w-50">
        <div class="col">
          <button fullWidth (click)="onClearForm()" nbButton size="giant" ghost>
            Clear
          </button>
        </div>
        <div class="col">
          <button
            fullWidth
            size="giant"
            status="success"
            [disabled]="!(jobTypeForm.valid && selectedStatuses?.length)"
            nbButton
            (click)="onClickSubmit()"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>

<ng-template #addStatusTemplate let-data let-addStatusTemplate="dialogRef">
  <nb-card class="dialog-box gt-p-1">
    <nb-card-body>
      <div>
        <table class="table drag-box">
          <thead>
            <tr>
              <th>Name</th>
              <th
                nbTooltip="Determines who can see this particular status when this job type is selected."
              >
                Status Visibilty
              </th>
              <th
                nbTooltip="This field is for the 'code' to distinguish the functions of a particular status."
              >
                Status Identity
              </th>
              <th>Add</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of statusDropDownList">
              <td
                nbTooltip="{{ item?.description }}"
                [nbTooltipDisabled]="!item?.description"
                nbTooltipPlacement="left"
              >
                {{ item?.name }}
              </td>
              <td>{{ item?.sharing_status?.name }}</td>
              <td>{{ item?.status_identity?.name }}</td>

              <td>
                <nb-icon
                  [icon]="'plus'"
                  pack="eva"
                  status="success"
                  (click)="addStatus(item)"
                >
                </nb-icon>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination section begin -->
      <div>
        <app-pagination-control
          [pageSize]="rows"
          [pageNum]="pageNum"
          [previous]="previous"
          [totalRows]="totalRows"
          [totalPages]="totalPages"
          (onClickPagination)="onClickPagination($event)"
          (onChangePagination)="onChangePagination($event)"
        >
        </app-pagination-control>
      </div>
      <div>
        <button
          fullWidth
          size="giant"
          ghost
          (click)="addStatusTemplate.close()"
          nbButton
        >
          Close
        </button>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
