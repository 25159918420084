<nb-tabset (changeTab)="changeTab($event)">
  <nb-tab tabTitle="{{ nbTabsUsers.tab1 }}">
    <nb-card-body>
      <!-- MOBILE VIEW -->
      <div class="mobile-only">
        <div *ngIf="totalUserCount > searchRemovalCap">
          <nb-form-field class="sc-search-input-box">
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                nbInput
                fullWidth
                placeholder="'Search Users"
                [(ngModel)]="globalFilter"
                (keyup)="onFilterApply($event)"
                [nbAutocomplete]="autoNgModel"
              />
              <label> Search Users</label>
            </div>
            <nb-icon
              nbSuffix
              *ngIf="showTypeAhead === true"
              [icon]="'search-outline'"
              pack="eva"
              status="basic"
              (click)="onFilterApply(true)"
            ></nb-icon>
            <nb-icon
              nbSuffix
              *ngIf="showTypeAhead === false"
              [icon]="'close-outline'"
              pack="eva"
              status="basic"
              (click)="onClearFilter()"
            ></nb-icon>
          </nb-form-field>
        </div>
        <div *ngIf="isAdmin">
          <button
            class="status-button"
            fullWidth
            status="primary"
            hero
            size="giant"
            (click)="openAddUserForm()"
            nbButton
          >
            + Add Guard
          </button>
          <div *ngIf="!isTestAccount">
            <span class="badge-key"> Total Guards</span>
            <span class="badge-value">
              <span> {{ totalAddedUsers }} &nbsp; </span>
              <span *ngIf="allotedUsers"> /&nbsp; {{ allotedUsers }} </span>
            </span>
          </div>
          <nb-alert
            class="p-2"
            outline="info"
            accent="info"
            *ngIf="recommendedPlan && isSubscriberAdmin"
          >
            <div class="text-center">
              We recommend switching your plan to
              <span class="strong">{{ recommendedPlan }}</span> for a better
              rate
              <div class="mt-2">
                <button
                  class="m-0"
                  nbButton
                  status="primary"
                  hero
                  (click)="changePlan()"
                >
                  See Plans
                </button>
              </div>
            </div>
          </nb-alert>
        </div>

        <app-card-list
          [listCardData]="userPaginationData[pageNum]"
          [listCardDetails]="userCardDetails"
          (rowClick)="rowClick($event)"
        ></app-card-list>
        <div *ngIf="userCount > 1">
          <app-pagination-control
            [pageSize]="pageSize"
            [pageNum]="pageNum"
            [previous]="previous"
            [totalRows]="totalUserCount"
            [totalPages]="totalPages"
            (onClickPagination)="onChangePagination($event)"
            (onChangePagination)="onChangePagination($event)"
          >
          </app-pagination-control>
        </div>
      </div>

      <!-- DESKTOP VIEW -->
      <div class="large-view-only">
        <div class="flex-center" *ngIf="totalUserCount > searchRemovalCap">
          <nb-form-field class="sc-search-input-box">
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                nbInput
                fullWidth
                placeholder=" 'Search Users"
                [(ngModel)]="globalFilter"
                (keyup)="onFilterApply($event)"
                [nbAutocomplete]="autoNgModel"
              />
              <label> Search Users</label>
            </div>
            <nb-icon
              nbSuffix
              *ngIf="showTypeAhead === true"
              [icon]="'search-outline'"
              pack="eva"
              status="basic"
              (click)="onFilterApply(true)"
            ></nb-icon>
            <nb-icon
              nbSuffix
              *ngIf="showTypeAhead === false"
              [icon]="'close-outline'"
              pack="eva"
              status="basic"
              (click)="onClearFilter()"
            ></nb-icon>
          </nb-form-field>

          <div class="sc-add-btn">
            <button
              title="Add User"
              nbButton
              class="sc-mt-0"
              status="primary"
              hero
              size="giant"
              (click)="openAddUserForm()"
            >
              + Add Guard
            </button>
          </div>
        </div>

        <div class="sc-head4 mt-3" *ngIf="!isTestAccount">
          <span>Total Guards</span>
          <span>
            {{ totalAddedUsers }}
            <span *ngIf="allotedUsers">
              /
              {{ allotedUsers }}
            </span>
          </span>
          <div class="my-2">
            <nb-alert
              class="d-inline w-auto"
              outline="info"
              accent="info"
              *ngIf="recommendedPlan && isSubscriberAdmin"
            >
              We recommend switching your plan to
              <span class="strong"> {{ recommendedPlan }} </span>
              for a better rate
              <button
                nbButton
                status="primary"
                hero
                (click)="changePlan()"
                class="ms-2"
              >
                See Plans
              </button>
            </nb-alert>
          </div>
        </div>

        <div
          class="col-sm-6 col-lg-3 sc-pb-0"
          *ngIf="!(totalUserCount > searchRemovalCap)"
        >
          <button
            fullWidth
            size="giant"
            (click)="openAddUserForm()"
            nbButton
            status="primary"
            hero
            size="giant"
          >
            + Add Guard
          </button>
        </div>

        <div>
          <app-table
            *ngIf="viewType == 'tableView'"
            [desktopTableData]="userPaginationData[pageNum]"
            [desktopTableDetails]="userDetailsTableView"
            [tableStyle]="tableStyle"
            [apiFilter]="true"
            (sortColumn)="sortColumn($event)"
            (searchColumn)="searchColumn($event)"
            (rowClick)="rowClick($event)"
          ></app-table>
        </div>

        <div *ngIf="userCount > 1">
          <app-pagination-control
            [pageSize]="pageSize"
            [pageNum]="pageNum"
            [previous]="previous"
            [totalRows]="totalUserCount"
            [totalPages]="totalPages"
            (onClickPagination)="onChangePagination($event)"
            (onChangePagination)="onChangePagination($event)"
          >
          </app-pagination-control>
        </div>
      </div>

      <!-- USER LIST -->
      <nb-autocomplete #autoNgModel>
        <nb-option *ngFor="let item of users" (click)="rowClick(item)">
          <div>
            <strong
              >{{
                item.full_name
                  ? item.full_name
                  : item.name
                  ? item.name
                  : item.email
              }}
              <small *ngIf="adminCheck(item)">(Admin)</small>
            </strong>
          </div>
        </nb-option>
      </nb-autocomplete>

      <div *ngIf="totalUserCount < 1">
        <nb-alert accent="danger">No Users Found</nb-alert>
      </div>
    </nb-card-body>
  </nb-tab>
  <nb-tab tabTitle="{{ nbTabsUsers.tab2 }}">
    <app-user-group *ngIf="userGroupTab"></app-user-group>
  </nb-tab>
</nb-tabset>
