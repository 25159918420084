import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  BehaviorSubject,
  Observable,
  Subject,
  lastValueFrom,
  map,
  timeout,
} from 'rxjs';
import { gTDB } from './db';
@Injectable({
  providedIn: 'root',
})
export class AppService {
  httpOptions: { headers: HttpHeaders };
  loginStatus = new BehaviorSubject<boolean>(false);
  offlineMode = new BehaviorSubject<boolean>(false);
  latLonSubscription: any;
  ipAddress: any;
  loadingText = new BehaviorSubject<any>('');

  // this key will be used to pass permission errors if any to control the status icon
  permissionErrorAlert = new Subject();

  constructor(
    private http: HttpClient,
    httpBackend: HttpBackend,
    private deviceService: DeviceDetectorService,
    private route: Router,
    private router: Router,
    private swUpdate: SwUpdate,
    private spinnerService: NgxSpinnerService
  ) {
    this.http = new HttpClient(httpBackend);
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        'Accept': 'application/json',
      }),
    };
  }

  isLoggedIn() {
    let loginStatus =
      this.getUserData() && this.getUserData().status === 'verified';
    this.loginStatus.pipe().subscribe((value) => {
      if (loginStatus !== value) this.loginStatus.next(loginStatus);
    });
    return loginStatus;
  }
  getUserData() {
    return JSON.parse(localStorage.getItem('userData') || '{}');
  }

  setUserData(data: any) {
    this.sendToSW(data);
    localStorage.setItem('userData', JSON.stringify(data));
  }
  sendToSW(data: any) {
    let payload: any = { type: 'userData' };
    Object.keys(data).forEach((key) => {
      if (['access', 'profile', 'subscriber'].includes(key)) {
        payload[key] = data[key];
      }
    });
    navigator?.serviceWorker?.controller?.postMessage(payload);
  }
  getToken() {
    if (this.getUserData()) {
      return this.getUserData().access;
    }
  }
  refreshToken() {
    return this.http.post('api/token/refresh/', {
      refresh: this.getUserData().refresh,
    });
  }

  getIP(): Observable<any> {
    return this.http.get('https://jsonip.com');
  }
  getGPS() {
    return this.http.get('https://freeipapi.com/api/json');
  }
  cityLookup(input: any) {
    const body = { search_str: input };
    return this.http.post('api/shared/suburb_lookup/', body);
  }
  getAddress() {
    return this.http.get('api/shared/country/');
  }
  getStates(countryId: any) {
    return this.http.get('api/shared/state/' + countryId + '/');
  }
  addressLookupShared(input: any, params?: any) {
    return this.http.post(
      'api/shared/address/',
      { search_str: input },
      { params: params }
    );
  }
  getSmartNetLinkStatus() {
    return this.http.get('api/client/smartnet/link_status/');
  }
  logOut() {
    let user = this.getUserData();
    let email = user?.profile?.email;
    let expiryToken = user?.token_expiry;
    localStorage.clear();
    if (expiryToken) {
      localStorage.setItem('email', email);
    }

    this.loginStatus.next(false);
    this.route.navigate(['/login']);
    window.location.reload();
  }
  async setIp() {
    try {
      this.getAwaitIP();
    } catch (error) {
      // Handle the error (e.g., log it or show a message)
      console.error('Error getting IP:', error);
    }
  }
  async handleIpApiError() {
    return await new Promise(async (resolve, reject) => {
      if (window.localStorage.getItem('ipAddress')) {
        resolve(window.localStorage.getItem('ipAddress'));
      }
      try {
        const TIMEOUT_IN_MS = 5000;
        this.backupApiCall()
          .pipe(
            timeout(TIMEOUT_IN_MS) // This will raise an error if no response is received within the timeout value
          )
          .subscribe({
            next: (resp: any) => {
              if (resp?.ipAddress) {
                window.localStorage.setItem('ipAddress', resp.ipAddress);
                resolve(resp.ipAddress);
              }
            },
            error: (err) => {
              if (err.name === 'TimeoutError') {
                console.log('The request timed out');
                resolve('null');
              } else {
                resolve('null');
              }
            },
          });
      } catch (error) {
        console.error('Error getting IP:', error);
        resolve('null');
      }
    });
  }
  backupApiCall(): Observable<any> {
    return this.http.get('https://freeipapi.com/api/json');
  }

  async getAwaitIP() {
    const TIMEOUT_IN_MS = 5000;
    return await new Promise(async (resolve, reject) => {
      try {
        this.getIP()
          .pipe(
            timeout(TIMEOUT_IN_MS) // This will raise an error if no response is received within the timeout value
          )
          .subscribe({
            next: (resp: any) => {
              if (resp?.ip) {
                window.localStorage.setItem('ipAddress', resp.ip);
                resolve(resp.ip);
              }
            },
            error: async (err) => {
              if (err.name === 'TimeoutError') {
                resolve(await this.handleIpApiError());
              } else {
                // Handle other errors
              }
            },
          });
        setTimeout(() => {
          if (!window.localStorage.getItem('ipAddress')) {
            this.handleIpApiError();
          }
        }, 5000);
      } catch (error) {
        console.error('Error getting IP:', error);
        resolve(await this.handleIpApiError());
      }
    });
  }

  async getAwaitFreeGPS() {
    return await new Promise((resolve, reject) => {
      this.getGPS().subscribe((resp: any) =>
        resolve({
          lat: resp?.latitude,
          lon: resp?.longitude,
          source: 2,
        })
      );
    });
  }
  async watchPostionWithLowAccuracy() {
    return await new Promise((resolve, reject) => {
      let lowAccuracystartTime = performance.now();
      let lowAccuracyWatchId = navigator.geolocation.watchPosition(
        async (position) => {
          if (position) {
            // if we are getting a postion we can stop the watchPostion then and there.
            navigator.geolocation.clearWatch(lowAccuracyWatchId);
          }
          return resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
            source: 1,
          });
        },
        async (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            // case where user has denied location access in browser
            this.spinnerService.hide();
            this.permissionErrorAlert.next('locationDisabled');
            // stop the clear watch in that case
            navigator.geolocation.clearWatch(lowAccuracyWatchId);
          } else {
            // add backup logic here. ie if endTime - startTime > x seconds find another way to fetch gps.
            if ((performance.now() - lowAccuracystartTime) / 1000 > 5) {
              //It's been 5 seconds since the app started to
              //fetch GPS with low accuracy.At this point,
              //we will fetch it from IP after clearing the low - accuracy watchPostion
              navigator.geolocation.clearWatch(lowAccuracyWatchId);

              resolve(await this.getAwaitFreeGPS());
            }
          }
        },
        {
          timeout: 1000,
          enableHighAccuracy: false,
          maximumAge: 5000,
        }
      );
    });
  }
  async watchPostionWithHighAccuracy() {
    return await new Promise((resolve, reject) => {
      let highAccuracystartTime = performance.now();
      let highAccuracyWatchId = navigator.geolocation.watchPosition(
        async (position) => {
          if (position) {
            // if we are getting a postion we can stop the watchPostion then and there.
            navigator.geolocation.clearWatch(highAccuracyWatchId);
          }

          return resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
            source: 0,
          });
        },
        async (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            // case where user has denied location access in browser
            this.spinnerService.hide();
            this.permissionErrorAlert.next('locationDisabled');
            // stop the clear watch in that case
            navigator.geolocation.clearWatch(highAccuracyWatchId);
          } else {
            // add backup logic here. ie if endTime - startTime > x seconds find another way to fetch gps.
            if ((performance.now() - highAccuracystartTime) / 1000 > 5) {
              //It's been 5 seconds since the app started to
              //fetch GPS with high accuracy.At this point,
              //we will fetch it from watchPostion with low accuracy after clearing the high-accuracywatchPostion
              navigator.geolocation.clearWatch(highAccuracyWatchId);

              resolve(await this.watchPostionWithLowAccuracy());
            }
          }
        },
        {
          timeout: 1000,
          enableHighAccuracy: true,
          maximumAge: 5000,
        }
      );
    });
  }

  getGpsCoordinates = async () => {
    return await this.watchPostionWithHighAccuracy();
  };

  checkAdduserLinkValidation(key: any) {
    return this.http
      .get(`api/register_login/check_link_validity/`, {
        params: { b64_string: key },
      })
      .pipe(map((response: any) => response));
  }
  async formDataApi(path: String, formData: any, admin = false, params?: any) {
    let auth_token = this.getToken();
    let headers = new HttpHeaders().append(
      'Authorization',
      `Bearer ${auth_token}`
    );

    formData.append(
      'device_info',
      JSON.stringify(await this.getDeviceDetail())
    );

    return await lastValueFrom(
      this.http.post(`${admin ? 'admin' : 'api'}/${path}/`, formData, {
        headers: headers,
        params: params,
      })
    );
  }
  updateProfileImage(path: String, formData: any, admin = false) {
    let auth_token = this.getToken();
    let headers = new HttpHeaders().append(
      'Authorization',
      `Bearer ${auth_token}`
    );

    return this.http.post(`${admin ? 'admin' : 'api'}/${path}/`, formData, {
      headers: headers,
    });
  }
  addFeedBack(requestData: any) {
    let body = {
      ...requestData,
    };
    return this.http
      .post(`api/external_api/save_feedback/`, body)
      .pipe(map((response: any) => response));
  }
  async getIp() {
    return new Promise(async (resolve) => {
      resolve(
        window.localStorage.getItem('ipAddress')
          ? window.localStorage.getItem('ipAddress')
          : await this.getAwaitIP()
      );
    });
  }

  async getDeviceInfo() {
    return new Promise(async (resolve) => {
      const deviceInfo = window.localStorage.getItem('deviceInfo');
      if (deviceInfo) {
        resolve(JSON.parse(deviceInfo));
      } else {
        const deviceInfo = this.deviceService.getDeviceInfo();
        window.localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
        resolve(deviceInfo);
      }
    });
  }

  async getDeviceDetail() {
    return new Promise(async (resolve) => {
      let gps: any = await this.getGpsCoordinates();
      let gpsRecord = { gps: gps, recorded_at: new Date().getTime() / 1000 };
      sessionStorage.setItem('gpsRecord', JSON.stringify(gpsRecord));
      const deviceInfo: any = await this.getDeviceInfo();
      resolve({
        gps: {
          ...gps,
          ip: await this.getIp(),
        },
        ...deviceInfo,
      });
    });
  }
  updateGpsCache() {
    this.getGpsCoordinates().then((gps: any) => {
      let gpsRecord = { gps: gps, recorded_at: new Date().getTime() / 1000 };
      sessionStorage.setItem('gpsRecord', JSON.stringify(gpsRecord));
    });
  }

  async gtExternalLink(url: any, body: any, token: any, addGps = true) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('token', token);
    headers = headers.append('Content-type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    let requestBody: any = body;
    if (addGps) {
      requestBody.device_info = await this.getDeviceDetail();
    }
    requestBody.isPwa = Boolean(
      window.matchMedia('(display-mode: standalone)').matches
    );

    return await lastValueFrom(
      this.http.post(`api/${url}/`, requestBody, { headers })
    );
  }
  gtBetaRegisterExternalLink(url: any, body: any, token: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('token', token);
    headers = headers.append('Content-type', 'application/json');
    headers = headers.append('Accept', 'application/json');

    let requestBody = { ...body };

    return this.http.post(`api/${url}/`, requestBody, { headers });
  }
  getReverseLocation(lat: any, lon: any) {
    // use this with caution ; this api may be deprecated or most probably restricted.
    let params = {
      location: `${lon}, ${lat}`,
      f: 'pjson',
      distance: 50000,
      outSR: '',
    };
    return this.http
      .get(
        'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode',
        { params: params }
      )
      .pipe(map((response: any) => response));
  }
  async updateApp() {
    if (this.swUpdate?.isEnabled) {
      try {
        if (await this.swUpdate.checkForUpdate()) {
          sessionStorage.setItem('updateAvailable', 'true');
        }
      } catch (error) {
        // Handle errors appropriately, e.g., log or display an error message.
        console.error('Update error:', error);
      }
    }
  }
  setLoadingText(text: any) {
    this.loadingText.next(text);
  }
  getLoadingText() {
    let loadingText = [
      'Fetching Needed Updates',
      'Getting Upgrades',
      "You're Being Upgraded",
    ];
    return loadingText[Math.floor(Math.random() * loadingText.length)];
  }
  returnLoadingTest() {
    return this.loadingText.asObservable();
  }

  getVersionInfo() {
    // api called in login to check whether the Ui has been updated or not
    return this.http.get('api/shared/get_version_info/');
  }

  async updateAppVersionIndexedDb(version: string) {
    await gTDB.userData.delete('appVersion');
    await gTDB.userData.add({ version: version }, 'appVersion');
  }
  async appVersionCheck(latestVersion?: any) {
    return new Promise(async (resolve) => {
      try {
        if (!this.swUpdate.isEnabled) {
          resolve(null);
        }
        const currentVersion: any = await this.getCurrentVersionInfo();

        if (latestVersion) {
          resolve({
            currentVersion: currentVersion,
            latestVersion: latestVersion,
          });
        } else {
          this.getVersionInfo().subscribe(async (response: any) => {
            if (response?.status === 'success') {
              const latestVersion = response;

              resolve({
                currentVersion: currentVersion,
                latestVersion: latestVersion,
              });
            }
          });
        }
      } catch (error) {
        resolve(null);
      }
    });
  }
  async getCurrentVersionInfo() {
    return new Promise(async (resolve) => {
      try {
        setTimeout(async () => {
          const data = await gTDB.userData.get('appVersion');

          resolve(data);
        }, 500);
      } catch (error) {
        console.log(error, 'INDEXED DB VERSION ERROR');
        resolve([]);
      }
    });
  }
}
