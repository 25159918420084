<nb-card class="dialog-box">
  <nb-card-header
    >{{ updateView ? "Edit" : "Add" }} Subscription Plan
    <nb-icon
      (click)="onCloseDialogue()"
      [icon]="'close-outline'"
      class="sc-floating-right"
      pack="eva"
    ></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="subscriptionPlanForm" (ngSubmit)="onClickSubmit()">
      <div
        class="form-floating"
        nbTooltip="WARNING: This will override the existing plan slot if has already been set. The plan slot also determines the order the plans are listed."
      >
        <input
          class="form-control"
          fullWidth
          pattern="^\d*(\.\d{0,4})?$"
          formControlName="sort_value"
          nbInput
          type="number"
          placeholder="Plan Slot"
        />
        <label>Plan Slot</label>
      </div>

      <div class="form-floating">
        <input
          class="form-control"
          fullWidth
          formControlName="name"
          nbInput
          type="text"
          placeholder="Plan Name"
        />
        <label for="inputName">Name</label>
      </div>

      <div>
        <nb-select
          fullWidth
          placeholder="Plan Interval"
          size="giant"
          formControlName="plan_interval_name"
          (selectedChange)="selectPlanInterval($event)"
        >
          <nb-option
            *ngFor="let planInterval of planInterval"
            [value]="planInterval.name"
            >{{ planInterval.name }}
          </nb-option>
        </nb-select>
      </div>
      <div>
        <nb-select
          [(selected)]="selectedPlan"
          fullWidth
          placeholder="Plan Level"
          size="giant"
          formControlName="plan_level_name"
          (selectedChange)="selectPlanLevel($event)"
        >
          <nb-option
            *ngFor="let planlevel of planLevel"
            [value]="planlevel.name"
            >{{ planlevel.name }}
          </nb-option>
        </nb-select>
      </div>
      <div class="form-floating">
        <input
          class="form-control"
          fullWidth
          formControlName="amount"
          nbInput
          pattern="^\d*(\.\d{0,2})?$"
          type="number"
          placeholder="Enter Amount"
        />
        <label>Amount</label>
      </div>
      <div class="form-floating">
        <input
          class="form-control"
          fullWidth
          formControlName="trial_days"
          nbInput
          pattern="^\d*(\.\d{0,2})?$"
          type="number"
          placeholder="Trial Days"
        />
        <label>Trial Days</label>
      </div>
      <div class="form-floating">
        <input
          class="form-control"
          fullWidth
          formControlName="trial_users_allowed"
          nbInput
          pattern="^\d*(\.\d{0,2})?$"
          type="number"
          placeholder="Trial Users"
        />
        <label>Trial Users</label>
      </div>
      <label> Plan Type</label>
      <nb-radio-group
        class="d-flex justify-content-center"
        formControlName="plan_type"
      >
        <nb-radio
          *ngFor="let _type of planTypes"
          [value]="_type[0]"
          (valueChange)="planTypeChange($event)"
        >
          {{ _type[1] | titlecase }}
        </nb-radio>
      </nb-radio-group>
      <div
        class="form-floating"
        *ngIf="subscriptionPlanForm.value.plan_type == 2"
      >
        <input
          class="form-control"
          fullWidth
          formControlName="allowed_transactions"
          nbInput
          pattern="^\d*(\.\d{0,2})?$"
          type="number"
          placeholder="Transaction Allowance"
        />
        <label>Transaction Allowance</label>
      </div>
      <div
        class="form-floating"
        *ngIf="subscriptionPlanForm.value.plan_type == 2"
      >
        <input
          class="form-control"
          fullWidth
          pattern="^\d*(\.\d{0,4})?$"
          formControlName="transaction_price"
          nbInput
          type="number"
          placeholder="Enter Transaction Price"
        />
        <label>Transaction Price</label>
      </div>
      <div
        class="form-floating"
        *ngIf="subscriptionPlanForm.value.plan_type == 1"
      >
        <input
          class="form-control"
          fullWidth
          formControlName="allowed_users"
          nbInput
          pattern="^\d*(\.\d{0,2})?$"
          type="number"
          placeholder="User Allowed"
        />
        <label>User Allowed</label>
      </div>
      <div
        class="form-floating"
        *ngIf="subscriptionPlanForm.value.plan_type == 1"
      >
        <input
          class="form-control"
          fullWidth
          pattern="^\d*(\.\d{0,4})?$"
          formControlName="user_cost"
          nbInput
          type="number"
          placeholder="Enter User Price"
        />
        <label>User Price</label>
      </div>
      <div
        class="form-floating"
        *ngIf="
          updateView === true &&
          currentTransactionPrice !=
            subscriptionPlanForm?.value?.transaction_price
        "
      >
        <input
          class="form-control"
          fullWidth
          formControlName="pricing_description"
          nbInput
          type="text"
          placeholder="Reason for changing price"
        />
        <label>Reason for changing price</label>
      </div>

      <div class="mt-2" nbTooltip="This plan can be accessed only via coupon">
        <nb-checkbox
          (checkedChange)="setPlanProtection($event)"
          formControlName="is_protected"
          >Only Available via Coupon</nb-checkbox
        >
      </div>

      <div class="row row-cols-2">
        <div class="col">
          <button
            type="button"
            (click)="onClearForm()"
            nbButton
            size="giant"
            ghost
            fullWidth
          >
            Clear
          </button>
        </div>
        <div class="col">
          <button
            type="submit"
            [disabled]="!subscriptionPlanForm.valid"
            nbButton
            size="giant"
            fullWidth
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
