import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { formatDate } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/auth/auth.service';
import { CheckpointService } from 'src/app/pages/checkpoints/checkpoint.service';
import { PagesService } from 'src/app/pages/pages.service';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { convertTime12to24 } from 'src/global.variables';
import { JobsService } from '../../jobs.service';

@Component({
  selector: 'app-patrol',
  templateUrl: './patrol.component.html',
  styleUrls: ['./patrol.component.scss'],
})
export class PatrolComponent implements OnInit {
  @Input() selectedStatus: any;
  formdata = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    checkpointName: new UntypedFormControl(''),
    assigneeName: new UntypedFormControl(),
    description: new UntypedFormControl(),
    patrol_brief: new UntypedFormControl(),
  });

  contactForm: UntypedFormGroup = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    title: new UntypedFormControl(),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    mobile_number: new UntypedFormControl('', [
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9]*$'),
    ]),
  });
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;
  addJobPressed: boolean = false;
  patrolJobType: any = {
    jobType: {
      name: 'job Type',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    name: {
      name: 'Name',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    checkpoints: {
      name: 'Checkpoints',
      value: '',
      edit: false,
      data: [],
      tempValue: '',
    },
    patrol_brief: {
      name: 'Patrol Brief',
      value: '',
      edit: false,
      data: [],
      tempValue: '',
    },
    patrol_brief_files: {
      name: 'Patrol Brief Files',
      value: '',
      edit: false,
      data: [],
      tempValue: '',
    },
    assignees: {
      name: 'Assignee',
      value: '',
      edit: false,
      data: [],
      tempValue: '',
    },

    schedule: {
      name: 'Schedule',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
  };

  checkpointListData: any = [];

  dialogRef: any;
  userData: any;

  schedulerForm: UntypedFormGroup = new UntypedFormGroup({
    end_time: new FormControl(null),
    start_time: new FormControl(null),
    start_day: new FormControl(new Date()),
    end_day: new FormControl(null, Validators.required),
    repeat_times: new UntypedFormControl(null),
    repeat_type: new UntypedFormControl(null, Validators.required),
    repeat_details: new UntypedFormControl([]),
    repeat_interval: new UntypedFormControl(1, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    position: new UntypedFormControl(null, []),
    week_day: new UntypedFormControl(null, []),
    month_repeat_case: new UntypedFormControl(null, []),
  });
  addSchedule: boolean = false;

  patrolRouteCpRows: number = 10;
  patrolRouteCpPrevious: number = 0;
  patrolRouteCpPageNum: number = 1;
  patrolRouteCpTotalPages: number = 0;

  patrolRouteCPList: any = [];
  patrolRouteCpTotalCount: number = 0;
  checkpointSearchList: any = [];

  assigneeList: any = [];
  searchFilteredCheckpoints: any = [];
  assigneeSearchList: any = [];
  scheduleStepperActive: boolean = false;
  intervalData = [{ name: 'No Welfare Checks', value: 0 }];
  intervalCheck: any;

  constructor(
    private jobService: JobsService,
    private dialogService: NbDialogService,
    private authService: AuthService,
    private pageService: PagesService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private spinnerService: NgxSpinnerService,
    private checkpointService: CheckpointService,

    private appService: AppService
  ) {
    this.userData = this.authService.getUserData();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();

    router.events.subscribe((event: any) => {
      // close dialog boxes if any are open when back is pressed
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });
  }

  ngOnInit(): void {
    this.patrolJobType.jobType.value = this.selectedStatus?.name;
    this.patrolJobType.jobType.data = this.selectedStatus;

    this.editOtherJobTypeItem('name');
  }

  clearValues(_field: string) {}

  onAddJob() {
    this.schedulerForm.value.start_time = convertTime12to24(
      this.schedulerForm.value.start_time
    );
    this.schedulerForm.value.end_time = convertTime12to24(
      this.schedulerForm.value.end_time
    );
    this.schedulerForm.value.start_day =
      this.schedulerForm.value.start_day == ''
        ? ''
        : formatDate(this.schedulerForm.value.start_day, 'yyyy-MM-dd', 'en');
    this.schedulerForm.value.end_day =
      this.schedulerForm.value.end_day == null
        ? null
        : formatDate(this.schedulerForm.value.end_day, 'yyyy-MM-dd', 'en');
    let timezoneOffset = new Date();

    this.addJobPressed = true;
    this.spinnerService.show();
    let data: any = {
      job_type_id: this.selectedStatus.id,
      time_offset: timezoneOffset.getTimezoneOffset(),
      ...this.schedulerForm.value,
      name: this.formdata.value?.name,
      patrol_brief: this.formdata.value?.patrol_brief,
      assignees: [
        ...new Set(
          this.patrolJobType.assignees?.data?.map(
            (assignee: any) => assignee?.id
          )
        ),
      ],
      checkpoints: this.patrolJobType.checkpoints?.data?.map(
        (checkpoint: any) => ({
          id: checkpoint?.id,
        })
      ),
    };

    data['interval_check'] = this.intervalCheck || 0;

    let formData: FormData = new FormData();
    formData.append('file', this.patrolJobType.patrol_brief_files.value);
    formData.append('form_data', JSON.stringify(data));
    let params: any = {};

    this.appService
      .formDataApi('roster_schedule/add_patrol_route', formData, false, params)
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.pageService.setMessage({
            errorMessage: '',
            successMessage: 'Job Dispatched',
          });
          sessionStorage.removeItem('selectedRosterDate');
          this.router.navigate(['/roster']);
        } else {
          this.addJobPressed = false;
          this.pageService.setMessage({
            errorMessage: response['message'],
            successMessage: '',
          });
        }

        this.spinnerService.hide();
      });
  }

  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);

      element?.focus();
    }, 100);
  }
  editOtherJobTypeItem(field: string) {
    for (let key in this.patrolJobType) {
      this.patrolJobType[key]['edit'] = false;
    }
    this.patrolJobType[field]['edit'] = true;
    if (window.innerWidth > 769) {
      this.shiftFocus(`desk${field}Field`);
    } else {
      this.shiftFocus(`mob${field}Field`);
    }
  }
  openTemplate(template: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(template, {
      context: {},
    });
  }

  formatScheduleForm() {
    this.scheduleStepperActive = true;
    let passedDate = sessionStorage.getItem('selectedRosterDate') || new Date();

    let startDateTime = passedDate ? new Date(passedDate) : new Date();
    let currentTime = new Date();

    startDateTime.setHours(
      currentTime.getHours(),
      currentTime.getMinutes(),
      currentTime.getSeconds(),
      currentTime.getMilliseconds()
    );

    this.schedulerForm.controls['repeat_type'].setValue('once');
    this.schedulerForm.controls['end_day'].setValue(startDateTime);
    this.schedulerForm.controls['start_day'].setValue(startDateTime);
    this.schedulerForm.controls['start_time'].setValue(
      this.getEndTime(startDateTime, passedDate)
    );
    let endDateTime = startDateTime.setHours(startDateTime.getHours() + 1);

    this.schedulerForm.controls['end_time'].setValue(
      this.getEndTime(endDateTime, passedDate)
    );
  }

  getEndTime(startDateTime?: any, passedDate?: any) {
    let date = new Date();
    if (startDateTime) {
      date = new Date(startDateTime);
    }

    let hours = date.getHours() + 1;

    if (hours > 23) {
      let nextDay = passedDate ? new Date(passedDate) : new Date();
      nextDay.setDate(nextDay.getDate() + 1);
      this.schedulerForm.controls['end_day'].setValue(nextDay);
      hours = 0;
    }

    let minutes = date.getMinutes();

    // Pad leading zero
    let minutesString = minutes < 10 ? '0' + minutes : '' + minutes;

    return `${hours}:${minutesString}`;
  }

  getPatrolRouteCheckpoints() {
    let params: any = {};
    if (this.patrolRouteCpRows) {
      params['rows'] = this.patrolRouteCpRows;
    }
    if (this.patrolRouteCpPrevious) {
      params['previous'] = this.patrolRouteCpPrevious;
    }

    this.checkpointService.getCheckpoints(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.patrolRouteCPList.push(...response['data']);
        this.patrolRouteCPList = this.patrolRouteCPList?.filter(
          (x: any, index: any, y: any) =>
            y?.findIndex((t: any) => t?.id === x?.id) === index
        );

        this.patrolRouteCpTotalCount = response['total_size'];
        this.patrolRouteCpTotalPages = Math.ceil(
          this.patrolRouteCpTotalCount / this.patrolRouteCpRows
        );
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }
  removeSelectedCP(selectedIndex: number) {
    this.patrolJobType.checkpoints.data =
      this.patrolJobType?.checkpoints?.data?.filter(
        (checkpoint: any, index: number) => index !== selectedIndex
      );
  }
  checkPointCounter(checkpointId: any) {
    return this.patrolJobType.checkpoints.data?.filter(
      (item: any) => item?.id === checkpointId
    )?.length;
  }
  addSelectedCP(addCheckpoint: any) {
    this.patrolJobType?.checkpoints?.data.push(addCheckpoint);
  }
  addAllCheckpoints() {
    this.patrolRouteCPList?.forEach((checkpoint: any) =>
      this.addSelectedCP(checkpoint)
    );
  }

  onLoadMore() {
    this.patrolRouteCpPrevious =
      this.patrolRouteCpPrevious + this.patrolRouteCpRows;

    this.getPatrolRouteCheckpoints();
  }

  searchCheckPoints(event?: any) {
    if (event.target.value?.length > 2) {
      let params: any = { name: event.target.value };

      this.checkpointService
        .getCheckpoints(params)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.patrolRouteCPList = response?.data;
            this.patrolRouteCpTotalCount = response['total_size'];
            this.patrolRouteCpTotalPages = Math.ceil(
              this.patrolRouteCpTotalCount / this.patrolRouteCpRows
            );
          } else {
            this.pageService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
        });
    } else if (!event.target.value?.length) {
      this.patrolRouteCPList = [];
      this.patrolRouteCpRows = 10;
      this.patrolRouteCpPrevious = 0;
      this.patrolRouteCpPageNum = 1;
      this.patrolRouteCpTotalPages = 0;
      this.getPatrolRouteCheckpoints();
    }
  }
  getPatrolRouteAssignees(event?: any) {
    this.jobService.getAssignees({}).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.assigneeList = response['data'];

        this.makeAssigneeSelectionChanges();
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }
  removeSelectedAssignee(deleteAssignee: any) {
    this.patrolJobType.assignees.data =
      this.patrolJobType?.assignees?.data?.filter(
        (assignee: any) => assignee?.id !== deleteAssignee?.id
      );
    this.makeAssigneeSelectionChanges();
  }
  addSelectedAssignee(addAssignee: any) {
    if (addAssignee?.id) {
      if (
        this.patrolJobType?.assignees?.data?.some(
          (assignee: any) => assignee?.id === addAssignee?.id
        )
      ) {
        this.pageService.setMessage({
          errorMessage: 'assignee already added',
          successMessage: '',
        });
      } else {
        this.patrolJobType?.assignees?.data.push(addAssignee);
        if (
          !this.assigneeList?.some(
            (assignee: any) => assignee?.id === addAssignee?.id
          )
        ) {
          this.assigneeList.push(addAssignee);
        }
      }

      this.makeAssigneeSelectionChanges();
    }
  }
  addRemovessignee(event: any, assignee: any) {
    if (event) {
      this.addSelectedAssignee(assignee);
    } else {
      this.removeSelectedAssignee(assignee);
    }
  }
  makeAssigneeSelectionChanges() {
    this.assigneeList.forEach((assignee: any) => {
      assignee.selected = this.patrolJobType?.assignees?.data?.some(
        (selectedAssignee: any) => selectedAssignee?.id === assignee?.id
      );
    });
  }

  searchAssignee(event?: any) {
    if (event.target.value?.length > 2) {
      let params: any = { full_name: event.target.value };
      this.jobService.getAssignees(params).subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.assigneeSearchList = response?.data?.filter(
            (value1: any) =>
              !this.patrolJobType?.assignees?.data?.some(
                (value2: any) => value1?.id === value2?.id
              )
          );
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
    }
  }
  updateInterval(interval: any) {
    this.intervalCheck = interval.value;
  }

  calculateTimeDifference(
    startTime: string,
    endTime: string,
    startDate?: Date,
    endDate?: Date
  ) {
    var startDateTime = startDate ? new Date(startDate) : new Date();
    var endDateTime = endDate ? new Date(endDate) : new Date();

    var [startHours, startMinutes] = startTime.split(':').map(Number);
    var [endHours, endMinutes] = endTime.split(':').map(Number);

    startDateTime.setHours(startHours, startMinutes);
    endDateTime.setHours(endHours, endMinutes);

    var timeDifference = endDateTime.getTime() - startDateTime.getTime();

    // Convert milliseconds to hours
    var hoursDifference = timeDifference / (1000 * 60 * 60);

    return hoursDifference;
  }
  getIntervalData() {
    let diffValue: any;
    const scheduleData: any = JSON.parse(
      JSON.stringify(this.schedulerForm.value)
    );
    if (scheduleData?.repeat_type === 'once') {
      diffValue = this.calculateTimeDifference(
        convertTime12to24(scheduleData.start_time),
        convertTime12to24(scheduleData.end_time),
        scheduleData.start_day,
        scheduleData.end_day
      );
    } else {
      diffValue = this.calculateTimeDifference(
        convertTime12to24(scheduleData.start_time),
        convertTime12to24(scheduleData.end_time)
      );
    }
    this.intervalData = [
      { name: 'No Welfare Checks', value: 0 },
      { name: 'Welfare Checks Every 5 mins', value: 5 },
      { name: 'Welfare Checks Every 15 mins', value: 15 },
      { name: 'Welfare Checks Every 30 mins', value: 30 },
    ];
    if (diffValue > 1) {
      this.intervalData.push({
        name: 'Welfare Checks Every 1 Hour',
        value: 60,
      });
    }
    if (diffValue > 2) {
      this.intervalData.push({
        name: 'Welfare Checks Every 2 Hours',
        value: 120,
      });
    }
  }
  handleFileInput(event: any) {
    this.patrolJobType.patrol_brief_files.value = event?.target?.files.item(0);
  }
  dropCheckPoint(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.patrolJobType?.checkpoints?.data,
      event.previousIndex,
      event.currentIndex
    );
  }
}
