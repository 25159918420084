import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { PagesService } from 'src/app/pages/pages.service';
import { CustomerService } from '../../../pages/customer/customer.service';
import { DataCheckService } from '../../service/data-check.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  primarySiteContact: boolean = false;
  @Input() contactForm: any;
  @Input() contact_type: any;
  @Output() getContactFormDetails = new EventEmitter();
  @Input() siteData: any;
  @Input() reportId: any;

  @Input() clientData: any;
  @Input() patrolReportUsers: boolean = false;
  @Input() addNewSiteContact: boolean = false;
  @Input() contactData: any;
  @ViewChild('confirmRef') confirmRef: any = TemplateRef<any>;
  contactList: any = [];
  showAddForm: boolean = false;
  userCardDetails = {
    individualDetails: [
      {
        nestedValue: (row: any) => {
          return row?.user?.full_name;
        },
      },
      {
        nestedValue: (row: any) => {
          return row?.user?.email;
        },
      },
    ],
  };
  searchStr: any;
  existingContacts: any = [];
  copyContactForm: any;
  patrolReportUser: any;
  editContact: boolean = false;
  isAdmin: boolean = false;
  editContactForm: UntypedFormGroup = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    title: new UntypedFormControl(),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    mobile_number: new UntypedFormControl(null, [
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9]*$'),
    ]),
  });
  lastApiResponseHadData: boolean = true;
  apiCallMade: boolean = false;
  lastSearchStr: string = '';
  constructor(
    @Optional() protected dialogRef: NbDialogRef<ContactComponent>,
    private customerService: CustomerService,
    private pageService: PagesService,
    private spinnerService: NgxSpinnerService,
    private dialogService: NbDialogService,
    private toasterService: NbToastrService,
    private dataCheckService: DataCheckService
  ) {
    this.isAdmin = this.dataCheckService.isUserAdmin();
  }

  ngOnInit(): void {
    if (!this.siteData && !this.clientData) {
      this.onCloseDialogue();
    } else {
      if (!this.patrolReportUsers) {
        this.existingContacts =
          (this.siteData
            ? this.siteData?.contacts
            : this.clientData?.contacts) || [];
      }
      if (this.addNewSiteContact === true) {
        this.showAddForm = true;
        this.contactForm.reset();
      }
    }
  }
  onCloseDialogue(val = false) {
    if (val) {
      if (this.patrolReportUser && this.patrolReportUsers) {
        this.existingContacts = [
          ...this.existingContacts.filter(
            (item: any) => item?.email === this.patrolReportUser
          ),
        ];
      }
      this.dialogRef.close(this.existingContacts);
    } else {
      this.dialogRef.close('false');
    }
  }
  onClearForm() {
    this.contactForm.reset();
  }
  getSiteContacts() {
    if (this.searchStr && this.searchStr.length > 2) {
      this.apiCallMade = true;
    }
    let params: any = {};

    if (this.siteData) {
      params['site_id'] = this.siteData?.id;
    }
    if (this.clientData) {
      params['company_id'] = this.clientData?.id;
    }

    if (this.searchStr) {
      params['search_str'] = this.searchStr;
    }
    if (this.contactForm?.value?.first_name) {
      params['search_str'] = this.contactForm?.value?.first_name;
    }
    if (this.lastSearchStr) {
      !this.searchStr.includes(this.lastSearchStr)
        ? (this.apiCallMade = true)
        : (this.apiCallMade = false);
    } else {
      this.apiCallMade = true;
    }
    if (this.apiCallMade || this.lastApiResponseHadData) {
      this.customerService
        .searchLocationContacts(params)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.contactList = response['data'];
            this.formatDropDownList();
            this.spinnerService.hide();
            this.lastApiResponseHadData = this.contactList.length > 0;
            this.lastSearchStr = this.searchStr;
          }
        });
    }
  }

  createContact() {
    var requestData: any = { id: 1, data: {} };
    requestData['data'] = this.contactForm.value;
    requestData['data']['contact_type'] = this.contact_type;
    if (this.contact_type === 'pc') {
      requestData['data']['report_mail_id'] = this.reportId;
    }
    if (this.contact_type === 'pc' && !this.reportId) {
      this.customerService
        .addContactSubscriberUser(requestData)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.existingContacts = response['data'];
            this.formatDropDownList();
            this.pageService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
            this.spinnerService.hide();
          } else {
            this.spinnerService.hide();
            this.pageService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
          this.onCloseDialogue(this.existingContacts);
          this.showAddForm = false;
          this.contactForm.reset();
        });
    } else if (this.siteData) {
      this.spinnerService.show();
      requestData['id'] = this.siteData?.id;
      requestData.data.is_primary = this.primarySiteContact;
      this.customerService
        .addSiteContactById(requestData)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.existingContacts = response['data'];
            this.formatDropDownList();
            this.pageService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
            this.spinnerService.hide();
          } else {
            this.spinnerService.hide();
            this.pageService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
          if (this.addNewSiteContact == true) {
            this.onCloseDialogue(true);
          } else {
            this.showAddForm = false;
            this.contactForm.reset();
          }
          this.onCloseDialogue(true);
        });
    } else if (this.clientData) {
      this.spinnerService.show();
      if (this.patrolReportUsers) {
        this.patrolReportUser = this.contactForm.value?.email;
      }

      requestData['id'] = this.clientData?.id;
      this.customerService
        .addCustomerContactById(requestData)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.existingContacts = response['data'];
            this.formatDropDownList();
            this.pageService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
            this.spinnerService.hide();
            if (this.patrolReportUsers) {
              this.onCloseDialogue(true);
            }
          } else {
            this.pageService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
            this.spinnerService.hide();
          }
          this.showAddForm = false;
          this.contactForm.reset();
        });
    }
  }
  togglePrimary(event: any) {
    this.primarySiteContact = event;
  }
  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element.focus();
    });
  }

  rowClick(event: any) {
    const dialogref = this.dialogService.open(this.confirmRef, {
      context: event,
    });
  }
  formatDropDownList() {
    this.contactList = this.contactList.filter(
      (value1: any) =>
        !this.existingContacts.some(
          (value2: any) =>
            (value1.id || value1.pk) === value2?.subscriber_user_id
        )
    );
  }

  onConfirm(user: any) {
    if (user) {
      var requestData: any = {
        id: 1,
        data: {
          first_name: user?.user_first_name,
          last_name: user?.user_last_name,
          mobile_number: user?.mobile_number ? user?.mobile_number : null,
          subscriber_user_id: user?.pk,
          contact_type: this.contact_type,
        },
      };

      if (this.siteData) {
        this.spinnerService.show();
        requestData['id'] = this.siteData?.id;
        requestData['contact_type'] = this.contact_type;
        requestData.data.is_primary = this.primarySiteContact;
        this.customerService
          .addSiteContactById(requestData)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.existingContacts = response['data'];
              this.formatDropDownList();
              this.spinnerService.hide();
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.spinnerService.hide();
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.contactForm.reset();
          });
      } else if (this.clientData) {
        this.spinnerService.show();
        requestData['id'] = this.clientData?.id;
        requestData['contact_type'] = this.contact_type;
        this.customerService
          .addCustomerContactById(requestData)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.existingContacts = response['data'];
              this.formatDropDownList();
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
              this.spinnerService.hide();
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
              this.spinnerService.hide();
            }
            this.contactForm.reset();
          });
      }
    }
  }
  addUser(subUser: any): void {
    if (this.existingContacts.some((user: any) => user?.id === subUser?.pk)) {
      this.toasterService.warning('', 'User already added');
    } else {
      if (this.siteData) {
        const dialogref = this.dialogService.open(this.confirmRef, {
          context: subUser,
        });
      } else {
        this.onConfirm(subUser);
      }
    }
  }
  removeUser(deleteUser: any): void {
    let dialogMsg = `Are you sure to remove this contact from ${
      this.siteData
        ? this.siteData?.company_name
        : this.clientData?.company_name
    }?`;
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: `Remove Contact ${deleteUser?.user?.full_name}`,
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.onDelete(deleteUser?.id);
      }
    });
  }
  onDelete(lcId: any) {
    if (lcId) {
      let contact_type = this.contact_type;
      if (this.siteData) {
        this.spinnerService.show();
        this.customerService
          .deleteSiteContact(this.siteData?.id, lcId, contact_type)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.existingContacts = response['data'];
              this.spinnerService.hide();
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.spinnerService.hide();
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.contactForm.reset();
          });
      } else if (this.clientData) {
        let contact_type = this.contact_type;
        this.spinnerService.show();
        this.customerService
          .deleteClientContact(this.clientData?.id, lcId, contact_type)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.existingContacts = response['data'];
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
              this.spinnerService.hide();
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
              this.spinnerService.hide();
            }
            this.contactForm.reset();
          });
      }
    }
  }
  selectContact(user: any) {
    this.contactForm.patchValue({
      first_name: user?.user_first_name,
      last_name: user?.user_last_name,
      mobile_number: user?.mobile_number ? user?.mobile_number : null,
      email: user?.user_email,
    });
  }
  onEditContact() {
    this.editContact = true;
    this.editContactForm.patchValue(this.contactData);
  }
  updateContact() {
    if (this.editContactForm.valid && this.contactData) {
      this.spinnerService.show();
      let requestData: any = { ...this.editContactForm.value };
      requestData['contact_type'] = this.contact_type;
      if (this.contact_type === 'pc') {
        requestData['report_mail_id'] = this.reportId;
      }
      if (this.siteData) {
        requestData['site_id'] = this.siteData?.id;
      }
      if (this.clientData) {
        requestData['company_id'] = this.clientData?.id;
      }
      this.customerService
        .updateLocationContact(
          this.contactData?.id || this.contactData?.location_contact_id,
          requestData
        )
        .subscribe((response: any) => {
          if (response?.status == 'success') {
            this.spinnerService.hide();
            this.dialogRef.close(response?.data);
          } else {
            this.pageService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
            this.spinnerService.hide();
          }
        });
    }
  }
}
