import { formatDate } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { convertTime12to24, convertTime24to12 } from 'src/global.variables';
@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerComponent implements OnInit {
  repeatInterval: any;
  selectedMonthType: any;
  startDate: any;
  endDate: any;
  startTime: any;
  startDateAndTime: any;
  endTime: any;
  endDateAndTime: any;
  selectedRepeatType: any;
  selectedWeekDays: any = [];
  errorMessage: any;
  validStartDate = new Date();

  constructor(
    @Optional() protected dialogRef: NbDialogRef<SchedulerComponent>,
    private ngZone: NgZone
  ) {}

  @Input() schedulerForm: any;
  @Input() dialogBox: boolean = true;
  @Input() editSchedule: any;
  @Input() repeatAllowed: boolean = true;

  @Output() emitData = new EventEmitter();
  showRecurringView: boolean = false;

  repeatTypes = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
  ];
  frequencyDetails = [
    {
      value: 1,
      name: 'first',
    },
    {
      value: 2,
      name: 'second',
    },
    {
      value: 3,
      name: 'third',
    },
    {
      value: 4,
      name: 'fourth',
    },
    {
      value: -1,
      name: 'last',
    },
  ];
  selectedDays: any = [];
  availabledays = Array.from({ length: 28 }, (_, i) => i + 1);

  ngOnInit(): void {
    this.validStartDate.setDate(this.validStartDate.getDate() - 1);
    if (this.editSchedule == true) {
      this.loadExistingData();
    } else {
      this.loadSessionData();
    }
    // this.shiftClick('startDay');
  }
  loadExistingData() {
    this.selectedRepeatType = this.schedulerForm.value.repeat_type;
    this.showRecurringView = this.selectedRepeatType === 'once' ? false : true;
    this.startDate = new Date(this.schedulerForm.value.start_day);
    this.endDate = new Date(this.schedulerForm.value.end_day);
    this.startDateAndTime = this.startDate;
    this.endDateAndTime = this.endDate;
    if (this.schedulerForm.value.start_time) {
      this.startTime = convertTime24to12(this.schedulerForm.value.start_time);
    } else {
      this.startTime = this.getEndTime(this.startDateAndTime);
      this.schedulerForm.controls['start_time'].setValue(this.startTime);
    }
    if (this.schedulerForm.value.end_time) {
      this.endTime = convertTime24to12(this.schedulerForm.value.end_time);
    } else {
      let endDateTime = this.startDateAndTime.setHours(
        this.startDateAndTime.getHours() + 1
      );
      this.endTime = this.getEndTime(endDateTime);
      this.schedulerForm.controls['end_time'].setValue(this.endTime);
    }

    this.repeatInterval = this.schedulerForm.value.repeat_interval;
    if (this.selectedRepeatType === 'weekly')
      this.selectedWeekDays = this.schedulerForm.value.repeat_details;
    if (this.schedulerForm.value.repeat_details) this.selectedMonthType = 'day';

    const scheduleStartTime = new Date(
      this.schedulerForm.value.start_day +
        ' ' +
        this.schedulerForm.value.start_time
    );

    if (scheduleStartTime.getTime() < new Date().getTime()) {
      this.validStartDate = new Date();
      let tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 1);
      this.schedulerForm.controls['start_day'].setValue(tomorrow);
      this.startDate = new Date(tomorrow);
    }
  }

  formatDateWithoutTime = (date: any) => {
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };

  loadNewData() {
    this.selectedRepeatType = 'once';

    // format start and end day on initial data
    this.startDate = new Date();
    this.endDate = new Date();
    this.endDateAndTime = new Date();
    this.schedulerForm.controls['start_day'].setValue(this.startDate);
    this.schedulerForm.controls['end_day'].setValue(this.endDate);

    // format start and end time on initial load
    this.startTime = this.getEndTime(this.startDateAndTime);
    this.startDateAndTime = new Date(this.startDate);
    this.schedulerForm.controls['start_time'].setValue(this.startTime);
    let endDateTime = this.startDateAndTime.setHours(
      this.startDateAndTime.getHours() + 1
    );
    this.endTime = this.getEndTime(endDateTime);
    this.schedulerForm.controls['end_time'].setValue(this.endTime);

    this.saveSessionData();
  }

  onCloseDialogue() {
    if (this.dialogBox) {
      this.dialogRef.close(false);
    } else {
      this.emitData.emit(false);
    }
  }
  changeMonthType(type: any) {
    this.schedulerForm.controls['month_repeat_case'].setValue(type);
    this.selectedMonthType = type;

    if (type == 'day') {
      this.schedulerForm.controls['position'].setValue(null);
      this.schedulerForm.controls['week_day'].setValue(null);
      this.schedulerForm.controls['repeat_details'].setValue([]);
    } else {
      this.selectedDays = null;
      this.schedulerForm.controls['position'].enable();
      this.schedulerForm.controls['week_day'].enable();
      this.schedulerForm.controls['repeat_details'].setValue([]);
    }
  }
  makeRecuringView() {
    this.showRecurringView = true;
    this.selectedRepeatType = 'daily';
    let endDate = new Date(this.startDate);
    endDate.setDate(endDate.getDate() + 14);
    this.endDate = endDate;
    this.endDateAndTime = endDate;
    this.schedulerForm.controls['end_day'].setValue(this.endDate);
    this.schedulerForm.controls['repeat_type'].setValue(
      this.selectedRepeatType
    );
    this.saveSessionData();
  }
  cancelRecuringView() {
    this.ngZone.run(() => {
      setTimeout(() => {
        this.schedulerForm.controls['repeat_details'].removeValidators(
          Validators.required
        );

        this.schedulerForm.controls['repeat_details'].updateValueAndValidity();
      });
    });
    this.showRecurringView = false;
    this.selectedRepeatType = 'once';
    this.schedulerForm.controls['repeat_type'].setValue(
      this.selectedRepeatType
    );
    this.saveSessionData();
  }

  formateStartDate(event: any) {
    this.startDate = event;
    this.endDate = event;
    this.endDateAndTime = event;
    this.schedulerForm.controls['start_day'].setValue(this.startDate);
    this.startDateAndTime = event;
    this.shiftClick('startTime');
  }
  formateEndDate(event: any) {
    this.endDate = event;
    this.endDateAndTime = event;
    this.schedulerForm.controls['end_day'].setValue(this.endDate);
    if (!this.showRecurringView) {
      this.shiftClick('endTime');
    }
  }

  formatEndTime(event: any) {
    this.endTime = convertTime12to24(event);
    var [hours, minutes] = this.endTime.split(':');
    this.endDateAndTime = new Date(this.endDate);
    this.endDateAndTime.setHours(parseInt(hours));
    this.endDateAndTime.setMinutes(parseInt(minutes));
    this.schedulerForm.controls['end_time'].setValue(this.endTime);
    if (this.startDateAndTime.getTime() >= this.endDateAndTime.getTime()) {
      this.errorMessage = 'Invalid end date and time';

      setTimeout(() => {
        this.endTime = null;
        this.schedulerForm.controls['end_time'].setValue(null);
      });
    }
  }

  formatStartTime(event: any) {
    this.startTime = convertTime12to24(event);
    var [hours, minutes] = this.startTime.split(':');
    this.startDateAndTime = new Date(this.startDate);
    this.startDateAndTime.setHours(parseInt(hours));
    this.startDateAndTime.setMinutes(parseInt(minutes));
    this.schedulerForm.controls['start_time'].setValue(this.startTime);
    this.schedulerForm.controls['end_day'].setValue(this.endDate);
    this.endTime = this.getEndTime(this.startDateAndTime);
    this.schedulerForm.controls['end_time'].setValue(this.endTime);
    if (this.showRecurringView) {
      this.shiftClick('endTime');
    }
  }

  clearTime(event: any) {
    this.endTime = '';
    this.schedulerForm.value.end_time = '';
  }
  updateRepeatDetails() {
    if (
      this.schedulerForm.value?.position &&
      this.schedulerForm.value?.week_day
    ) {
      let repeatDetails = this.schedulerForm.value?.repeat_details || [];
      repeatDetails.push({
        position: this.schedulerForm.value?.position,
        week_day: this.schedulerForm.value?.week_day,
      });
      this.schedulerForm.controls['repeat_details'].setValue(repeatDetails);
    }
  }
  getEndTime(startDateTime?: any) {
    let date = new Date();
    if (startDateTime) {
      date = new Date(startDateTime);
    }

    let hours = date.getHours() + 1;

    if (hours > 23) {
      let nextDay = new Date(this.startDateAndTime);
      nextDay.setDate(nextDay.getDate() + 1);

      this.endDate = nextDay;
      this.schedulerForm.controls['end_day'].setValue(nextDay);
      hours = 0;
    }
    let minutes = date.getMinutes();

    // Pad single digit minutes with a leading zero
    let minutesString = minutes < 10 ? '0' + minutes : '' + minutes;

    return `${hours > 12 ? hours - 12 : hours}:${minutesString} ${
      hours > 11 ? 'PM' : 'AM'
    }`;
  }

  onAlertDismis() {
    this.errorMessage = undefined;
  }
  submitSchedule() {
    this.schedulerForm.value.start_time = convertTime12to24(
      this.schedulerForm.value.start_time
    );
    this.schedulerForm.value.end_time = convertTime12to24(
      this.schedulerForm.value.end_time
    );
    this.schedulerForm.value.start_day =
      this.schedulerForm.value.start_day == ''
        ? ''
        : formatDate(this.schedulerForm.value.start_day, 'yyyy-MM-dd', 'en');
    this.schedulerForm.value.end_day =
      this.schedulerForm.value.end_day == null
        ? null
        : formatDate(this.schedulerForm.value.end_day, 'yyyy-MM-dd', 'en');
    let timezoneOffset = new Date();

    let data = {
      time_offset: timezoneOffset.getTimezoneOffset(),
      ...this.schedulerForm.value,
    };
    if (this.dialogBox) {
      this.dialogRef.close(data);
    } else {
      this.emitData.emit(data);
    }
    this.saveSessionData();
  }
  shiftClick(elementID: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementID);
      element?.click();
      element?.focus();
    }, 100);
  }

  onRepeatTypeChange(event: any) {
    this.selectedRepeatType = event;
    this.saveSessionData();
    this.schedulerForm.controls['repeat_details'].setValue([]);
    this.ngZone.run(() => {
      setTimeout(() => {
        this.schedulerForm.controls['repeat_details'].removeValidators(
          Validators.required
        );
        if (['monthly', 'weekly'].includes(event)) {
          this.schedulerForm.controls['repeat_details'].setValidators(
            Validators.required
          );
        }
        this.schedulerForm.controls['repeat_details'].updateValueAndValidity();
      });
    });
  }

  loadSessionData() {
    let schedulerData = JSON.parse(
      sessionStorage.getItem('schedulerData') || '{}'
    );

    if (schedulerData?.schdeuleData) {
      if (schedulerData?.schdeuleData?.formData) {
        this.schedulerForm.patchValue(schedulerData?.schdeuleData?.formData);
      }
      if (schedulerData?.schdeuleData?.selectedRepeatType) {
        this.selectedRepeatType =
          schedulerData?.schdeuleData?.selectedRepeatType;
        this.repeatInterval = schedulerData?.schdeuleData?.repeatInterval;
        this.selectedMonthType = schedulerData?.schdeuleData?.selectedMonthType;
        this.showRecurringView =
          this.selectedRepeatType == 'once' ? false : true;

        this.startDate = new Date(schedulerData?.schdeuleData?.startDate);
        this.endDate = new Date(schedulerData?.schdeuleData?.endDate);
        this.endDateAndTime = new Date(
          schedulerData?.schdeuleData?.endDateAndTime
        );

        // format start and end time on initial load
        this.startTime = this.getEndTime(this.startDateAndTime);
        this.startDateAndTime = new Date(this.startDate);

        let endDateTime = this.startDateAndTime.setHours(
          this.startDateAndTime.getHours() + 1
        );
        this.endTime = this.getEndTime(endDateTime);
      }
    } else {
      this.loadNewData();
    }
  }

  saveSessionData() {
    sessionStorage.setItem(
      'schedulerData',
      JSON.stringify({
        schdeuleData: {
          formData: this.schedulerForm.value,
          selectedRepeatType: this.selectedRepeatType,
          repeatInterval: this.repeatInterval,
          selectedMonthType: this.selectedMonthType,
          startDate: this.startDate,
          endDate: this.endDate,
          startTime: this.startTime,
          startDateAndTime: this.startDateAndTime,
          endTime: this.endTime,
          endDateAndTime: this.endDateAndTime,
        },
      })
    );
  }
}
