import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { PagesService } from '../../pages.service';
import { AdminService } from '../admin.service';
@Component({
  selector: 'app-app-routes',
  templateUrl: './app-routes.component.html',
  styleUrls: ['./app-routes.component.scss'],
})
export class AppRoutesComponent implements OnInit {
  appRoutes: any;
  dialogref: any;
  response: any;

  editData: any;
  appRouteForm: UntypedFormGroup = new UntypedFormGroup({
    route_title: new UntypedFormControl('', Validators.required),
    route_icon: new UntypedFormControl('', Validators.required),
    route_link: new UntypedFormControl('', Validators.required),
    route_order: new UntypedFormControl('', Validators.required),
    show_subscriber_admin: new UntypedFormControl(1),
    show_subscriber_dispatcher: new UntypedFormControl(1),
    show_subscriber_user: new UntypedFormControl(0),
  });
  totalRoutes: any;

  previous: number = 0;
  rows: number = 30;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;

  constructor(
    private adminService: AdminService,
    private dialog: NbDialogService,
    private spinnerService: NgxSpinnerService,
    private pageService: PagesService
  ) {}

  ngOnInit(): void {
    this.getAllRoutes();
  }

  getAllRoutes() {
    let params: any = {};
    this.spinnerService.show();
    if (this.rows) {
      params['page_size'] = this.rows;
    }
    if (this.previous) {
      params['page'] = this.pageNum;
    }
    this.adminService.getAppRoutes(params).subscribe((resp: any) => {
      {
        this.appRoutes = resp['results'];
        this.spinnerService.hide();
        this.totalRows = resp?.count;
        this.totalPages = Math.ceil(this.totalRows / this.rows);
      }
    });
  }
  openTemplate(template: TemplateRef<any>) {
    this.appRouteForm.reset();
    this.appRouteForm.controls['route_order'].setValue(this.totalRows);
    this.dialogref = this.dialog.open(template, {
      context: {},
    });
  }
  onCloseDialogue() {
    this.dialogref.close('close');
  }
  onClearForm() {
    this.spinnerService.hide();
    this.editData = null;
    this.appRouteForm.reset();
  }
  onClickSubmit() {
    if (this.appRouteForm.valid) {
      this.spinnerService.show();
      let requestData = this.appRouteForm.value;
      requestData.show_subscriber_admin = Boolean(
        requestData.show_subscriber_admin
      );
      requestData.show_subscriber_user = Boolean(
        requestData.show_subscriber_user
      );
      requestData.show_subscriber_dispatcher = Boolean(
        requestData.show_subscriber_dispatcher
      );
      if (this.editData) {
        this.adminService
          .editAppRoute(this.editData.id, requestData)
          .subscribe((response: any) => {
            this.onCloseDialogue();
            this.getAllRoutes();
            this.onClearForm();
          });
      } else {
        this.adminService
          .saveAppRoute(requestData)
          .subscribe((response: any) => {
            this.onCloseDialogue();
            this.getAllRoutes();
            this.onClearForm();
          });
      }
    }
  }
  editAppRoute(item: any, template: TemplateRef<any>) {
    this.appRouteForm.patchValue(item);
    this.editData = item;

    {
      this.dialogref = this.dialog.open(template, {
        context: {},
      });
    }
  }
  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;

    this.getAllRoutes();
  }

  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;

    this.getAllRoutes();
  }
  deleteRoute(data: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      context: {
        title: 'Are you sure?',
        message: `Delete route ${data?.route_title}`,
      },
    });
    dialogRef.onClose.subscribe((value: any) => {
      if (value === 'Yes') {
        this.adminService
          .deleteAppRoute(data?.id)
          .subscribe((response: any) => {
            this.getAllRoutes();
          });
      }
    });
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  async getNewOrder() {
    return new Promise((resolve) => {
      let newOrder: any = [];

      this.appRoutes?.forEach((item: any, index: number) => {
        let itemCopy = { ...item };
        delete itemCopy.id;
        newOrder.push({
          ...itemCopy,
          route_order: index,
        });
      });
      resolve(newOrder);
    });
  }
  async saveOrdering() {
    let newOrder = await this.getNewOrder();
    this.spinnerService.show();
    this.adminService
      .reorderAppRoutes({ data: newOrder })
      .subscribe((response: any) => {
        this.spinnerService.hide();
        this.getAllRoutes();
        this.onClearForm();
      });
  }
  forceUppercaseConditionally(event: any) {
    this.appRouteForm.controls['route_title'].setValue(
      event.target.value.toUpperCase()
    );
  }
}
