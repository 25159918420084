<nb-card class="gt-p-1">
  <div>
    <button
      size="giant"
      nbButton
      routerLink="/gtadmin/job-type/add"
      size="giant"
    >
      Add Job Type
    </button>
  </div>

  <div>
    <app-table
      [desktopTableData]="jobStatus"
      [desktopTableDetails]="columns"
      (rowClick)="rowClick($event)"
    ></app-table>
  </div>
  <!-- Pagination section begin -->
  <div class="sc-mt-6">
    <app-pagination-control
      [pageSize]="rows"
      [pageNum]="pageNum"
      [previous]="previous"
      [totalRows]="totalRows"
      [totalPages]="totalPages"
      (onClickPagination)="onClickPagination($event)"
      (onChangePagination)="onChangePagination($event)"
    >
    </app-pagination-control>
  </div>
</nb-card>
