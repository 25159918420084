import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient) {}

  getGuardData() {
    return this.http.get('/api/users/guards/');
  }
  getUserData(params: any) {
    return this.http.get('/api/users/', { params: params });
  }

  userEnableDisable(id: any) {
    return this.http
      .patch(`api/users/${id}/`, {})
      .pipe(map((response: any) => response));
  }
  deleteUserById(id: any) {
    const body = {};

    return this.http
      .post(`api/users/${id}/delete_user/`, body)
      .pipe(map((response: any) => response));
  }
  getClassByState(stateId: any) {
    return this.http.get(`api/security_license/`, {
      params: { stateId: stateId, byState: 1, lic_class: 1 },
    });
  }

  addNewClass(data: any) {
    const body = data;
    return this.http
      .post(`api/security_license/add_new_class/`, body)
      .pipe(map((response: any) => response));
  }
  addLicense(requestData: any, params?: any) {
    return this.http
      .post(`api/security_license/`, requestData, { params: params })
      .pipe(map((response: any) => response));
  }

  addUser(requestData: any, params?: any) {
    const body = requestData.data;
    return this.http
      .post(`api/users/`, body, { params: params })
      .pipe(map((response) => response));
  }
  editUser(requestData: any) {
    const body = requestData;
    return this.http
      .post(`api/users/${requestData.id}/`, body)
      .pipe(map((response) => response));
  }
  updateUser(requestData: any, params: any) {
    const body = requestData;
    return this.http
      .put(`api/users/${requestData.id}/`, body, { params: params })
      .pipe(map((response) => response));
  }
  getUserById(user_id: any) {
    return this.http.get(`api/users/${user_id}/`);
  }

  getSuperUserById(user_id: any) {
    return this.http.get(`admin/user/${user_id}/`, {});
  }
  deleteSuperUserById(user_id: any) {
    return this.http.post(`admin/user/${user_id}/delete/`, {});
  }
  addSuperAdmin(requestData: any) {
    const body = requestData.data;
    return this.http
      .post(`/admin/user/`, body)
      .pipe(map((response) => response));
  }
  editSuperAdmin(requestData: any, id: any) {
    const body = requestData.data;
    return this.http
      .put(`/admin/user/${id}/`, body)
      .pipe(map((response) => response));
  }
  getUserGroups(params: any) {
    return this.http.get('/api/user_group/', { params: params });
  }

  getUserGroupById(id: any) {
    return this.http.get(`/api/user_group/${id}/`, {});
  }

  saveUserGroup(requestData: any, params?: any) {
    const body = { ...requestData };

    return this.http
      .post('/api/user_group/', body, { params: params })
      .pipe(map((response) => response));
  }
  updateUserGroup(id: Number, requestData: any, params?: any) {
    const body = { ...requestData };

    return this.http
      .put(`/api/user_group/${id}/`, body, { params: params })
      .pipe(map((response) => response));
  }
  deleteUserGroup(id: Number) {
    return this.http.post(`/api/user_group/${id}/delete/`, {});
  }

  addUsersToGroup(id: any, requestData: any, params?: any) {
    const body = { ...requestData };

    return this.http
      .post(`/api/user_group/${id}/update_users/`, body, { params: params })
      .pipe(map((response) => response));
  }
  getUserUpdateLog(requestData?: any, params?: any) {
    const body = { ...requestData };
    return this.http
      .post('/api/events/', body, { params: params })
      .pipe(map((response) => response));
  }
  exportUserUpdateLog(requestData?: any, params?: any) {
    const body = { ...requestData };

    return this.http
      .post('/api/events/export/', body, { params: params })
      .pipe(map((response) => response));
  }

  getUserLastLocation(params?: any) {
    return this.http
      .get('/api/user_analytics/', { params: params })
      .pipe(map((response) => response));
  }
  getCurrentlyClockedUsers(params?: any) {
    return this.http
      .get('/api/users/', { params: params })
      .pipe(map((response) => response));
  }

  // internal-messaging apis
  getUserMessageList(params: any) {
    return this.http
      .get('/api/gt_message/', { params: params })
      .pipe(map((response) => response));
  }
  sendMessage(body: any, params?: any) {
    return this.http
      .post('/api/gt_message/', body, { params: params })
      .pipe(map((response) => response));
  }
}
