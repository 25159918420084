<ng-container>
  <div class="text-center my-2 fw-bold">{{ selectedUserName }}</div>
  <div class="d-flex">
    <div class="form-floating flex-grow-1">
      <input
        class="form-control"
        size="giant"
        nbInput
        fullWidth
        placeholder="Pick Date Range"
        readonly
        [value]="dateRangeValue"
        (click)="openRangePicker()"
      />
      <label>Pick Date Range</label>
    </div>
    <div class="sc-add-btn" *ngIf="totalRowsUserHistory">
      <button size="giant" nbButton class="sc-mt-0" (click)="sortData()">
        <nb-icon
          class="ico-color"
          pack="eva"
          [icon]="
            sortKey === 'updated_at'
              ? 'arrow-downward-outline'
              : 'arrow-upward-outline'
          "
        >
        </nb-icon>
      </button>
    </div>
  </div>
  <nb-tabset *ngIf="appUserHistory?.length">
    <nb-tab tabTitle="Map View" [lazyLoad]="true">
      <ng-container>
        <app-user-log
          [listData]="appUserHistory"
          [otherParams]="otherParamsUserHistory"
          (changePagination)="onSliderChangePagination($event)"
        >
        </app-user-log>
      </ng-container>
    </nb-tab>
    <nb-tab tabTitle="Table View" [lazyLoad]="true">
      <ng-container>
        <div class="row row-cols-2">
          <div class="col">
            <app-gt-table
              [desktopTableBackendData]="appUserHistory"
              [desktopTableDetails]="appUserHistoryTable"
              [otherParams]="otherParamsUserHistory"
              (rowClick)="selectHistoryEvent($event)"
              (paginationChange)="onChangeUserHistoryPagination($event)"
            >
            </app-gt-table>
          </div>
          <div class="col">
            <ng-container *ngIf="selectedHistory?.gps">
              <app-map-leaflet
                class="mt-3"
                [latLon]="selectedHistory.gps"
                mapId="guardHistoryLocationMobile"
              ></app-map-leaflet>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </nb-tab>
  </nb-tabset>
  <div *ngIf="!appUserHistory?.length" class="mt-5">
    <nb-alert outline="basic" style="font-weight: 500"
      >No Records Match Your Search Criteria</nb-alert
    >
  </div>
</ng-container>
