<div class="sc-text-center">
  <div class="mobile-only">
    <div class="gt-logo-mobile">
      <img
        src="../../../assets/images/Guard_Tracker-Stacked.png"
        alt="Guard Tracker"
      />
    </div>
  </div>
  <div class="large-view-only">
    <div class="gt-logo-desktop">
      <img
        src="../../../assets/images/Guard_Tracker-Lateral_Long_default.png"
        alt="Guard Tracker"
      />
    </div>
  </div>

  <div class="sc-mt-9">
    We have received your request to close your account and delete your data.
  </div>
</div>
