import { Location, formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import {
  NbDialogService,
  NbPopoverDirective,
  NbToastrService,
} from '@nebular/theme';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { gTDB } from 'src/app/db';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { ContactComponent } from 'src/app/shared/comp/contact/contact.component';
import { SchedulerComponent } from 'src/app/shared/comp/scheduler/scheduler.component';
import { WebcamImageComponent } from 'src/app/shared/comp/webcam-image/webcam-image.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import {
  browserGpsInfo,
  convertTime12to24,
  convertTime24to12,
  getFormattedRepeatDetail,
} from 'src/global.variables';
import { CustomerService } from '../../customer/customer.service';
import { AddEditMonitoringCompanyComponent } from '../../monitoring-company/add-edit-monitoring-company/add-edit-monitoring-company.component';
import { PagesService } from '../../pages.service';
import { ProfileService } from '../../profile/profile.service';
import { RosterScheduleService } from '../../roster-schedule/roster-schedule.service';
import { jobHistoryTable } from '../../tableDetails';
import { AddEditUsersComponent } from '../../users/add-edit-users/add-edit-users.component';
import { JobsService } from '../jobs.service';
@Component({
  selector: 'app-jobs-detail-events',
  templateUrl: './jobs-detail-events.component.html',
  styleUrls: ['./jobs-detail-events.component.scss'],
})
export class JobsDetailEventsComponent implements OnInit, OnDestroy {
  scrollPosition: any;

  @Input() jobId: any;
  @ViewChildren(NbPopoverDirective) popovers: any =
    QueryList<NbPopoverDirective>;
  @Output() emitData = new EventEmitter();
  detailData: any;
  mapInput: any = {};
  jobComments: any = [];
  availableJobStatus: any = [];
  imageList: any = [];
  jobHistory: any = [];
  assigneeIdList: any = [];
  users: any = [];
  newComment: any;
  newRequest: any;
  dialogRef: any;
  userData: any;
  userDetails: any;
  assigneeData: any = [];
  showMap: boolean = false;
  showComments: boolean = false;
  showFiles: boolean = false;

  showRequestDetailInput: boolean = false;
  isJobCompleted: boolean = false;
  messageInitialAlert: boolean = true;
  editJobDetails: boolean = false;
  selfRemoved: boolean = false;
  cardFlipped: boolean = false;
  jobUpdated: boolean = false;
  showAssignees: boolean = false;
  openAssigneeSelect: boolean = false;
  openDetails: boolean = false;
  hideJobbutton: boolean = false;
  showMore: boolean = false;
  contactForm: UntypedFormGroup = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    title: new UntypedFormControl(),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    mobile_number: new UntypedFormControl('', [
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9]*$'),
    ]),
  });

  jobDetailHistoryMobileView = jobHistoryTable.mobileTable;
  browserGpsInfo: any = browserGpsInfo;
  webcamImage: any;
  actualUploadFile: any;
  fileData: any = [];
  jobDetailData: any;
  fullImgSrc: any;
  jobDetailsTableView: any = [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      sortKey: 'updated_at',
      dateRangeKey: 'updated_at',
    },
    {
      header: 'Update By',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },

    {
      header: 'Update',
      name: 'action',
      sortKey: 'action',
      searchKey: 'action',
    },
  ];

  tableStyle = {
    'overflow': 'auto',
    'max-height': '400px',
  };
  showDetails: boolean = false;
  selectedEvent: any;
  mapLatLong: any;
  uploadedImage: any;
  cancelReason: any;

  statusList: any = [];
  siteContactList: any;
  siteContactListTemp: any;
  appliedFilters: any = [];
  filters: any = {
    updated_at: 0,
    user_name: 1,
    action: 2,
  };
  lastUpdateText: any;
  lastEventTime: any;
  lastEvent: any;
  lastEventCity: any;
  dateRange: any;
  backupData: any;
  cancelReasonData: any = {};
  jobEventDetails: any = {
    individualDetails: [
      {
        name: 'action',
        cardRowStyle: {
          'font-weight': 'bolder',
        },
      },

      {
        name: 'updated_at',

        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'user_name',

        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };
  jobStatus: any = {};
  jobCommentsFiles: any = [];
  subscriberUserId: any;
  isReopenJob: boolean = true;

  selectedJobStatus: any;
  selectedStatus: any = {};
  videoOptions: MediaTrackConstraints = {};
  cameraError: any;
  messageChannel = new MessageChannel();
  apidataUpdation: boolean = false;
  offlineMode: boolean = false;
  hasScheduleStarted: boolean = false;

  isAdmin: any;
  isDispatchUser: any;
  monitoringCompanyList: any = [];
  previousComment: any;
  mcInputValue: any;

  hideSpinner: boolean = true;

  externalNewJobId: any;
  externalOldJobId: any;

  todayDate: any = new Date();
  jobCreationDate: any = new Date();
  dateValue: any;
  timeValue: any;
  onsiteIntervalFn: any;
  onsiteTimeOutFn: any;
  onsiteTimer = '00:00:00';

  onsiteTime: any;
  dateTimeValue: any;

  countupValue: number = 0;
  showHistorySection: boolean = false;
  scheduleCheck: boolean = true;

  visibilityChangeHandler: any;

  scheduleDuration: any;
  intervalData: any = [];
  selectedInterval: any;
  updateSchedulerForm: UntypedFormGroup = new UntypedFormGroup({
    end_time: new FormControl(null),
    start_time: new FormControl(null),
    start_day: new FormControl(new Date()),
    end_day: new FormControl(null, Validators.required),
    repeat_times: new UntypedFormControl(null),
    repeat_type: new UntypedFormControl(null, Validators.required),
    repeat_details: new UntypedFormControl([]),
    repeat_interval: new UntypedFormControl(1, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    position: new UntypedFormControl(null, []),
    week_day: new UntypedFormControl(null, []),
    month_repeat_case: new UntypedFormControl(null, []),
  });

  startDate: any;
  endDate: any;
  startTime: any;
  startDateAndTime: any;
  endTime: any;
  endDateAndTime: any;
  showStartDetail: boolean = false;
  validStartDate = new Date();
  errorMessage: string = '';
  formattedRepeatDetails: any;
  canScheduleBeUpdated: boolean = false;
  showWelfareItem: boolean = true;
  availableStatus: any;
  schedulerForm: UntypedFormGroup = new UntypedFormGroup({
    end_time: new FormControl(null),
    start_time: new FormControl(null),
    start_day: new FormControl(new Date()),
    end_day: new FormControl(null, Validators.required),
    repeat_times: new UntypedFormControl(null),
    repeat_type: new UntypedFormControl(null, Validators.required),
    repeat_details: new UntypedFormControl([]),
    repeat_interval: new UntypedFormControl(1, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    position: new UntypedFormControl(null, []),
    week_day: new UntypedFormControl(null, []),
    month_repeat_case: new UntypedFormControl(null, []),
  });

  lastStatus: any;
  bottomSectionFixedHeight: string = '198 px';
  applyMaxHeight: boolean = false;
  uploadType: any;
  indexedDbListData: any = [];
  jobHistoryBackup: any = [];
  constructor(
    private toaster: NbToastrService,
    private spinnerService: NgxSpinnerService,
    private appService: AppService,
    private jobService: JobsService,
    private customerService: CustomerService,
    private dialog: NbDialogService,
    private profileService: ProfileService,
    private router: Router,
    private _location: Location,
    private pageService: PagesService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private dataCheckService: DataCheckService,
    private swUpdate: SwUpdate,
    private ngZone: NgZone,
    private sanitizer: DomSanitizer,
    private rsService: RosterScheduleService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });

    this.jobId = this.router.getCurrentNavigation()?.extras.state;

    this.userData = this.appService.getUserData();

    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();

    this.subscriberUserId = this.userData?.subscriber?.subscriber_user_id;
  }
  offlineChange() {
    this.offlineMode = !navigator?.onLine;
  }

  ngOnInit(): void {
    window.addEventListener('online', this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.offlineChange.bind(this));
    navigator?.serviceWorker?.controller?.postMessage(
      {
        type: 'INIT_JOB_DETAIL_PORT',
      },
      [this.messageChannel.port2]
    );
    this.offlineMode = !navigator?.onLine;
    // Listen to the response
    this.messageChannel.port1.onmessage = (event) => {
      if (event?.data?.data) {
        this.ngZone.run(() => {
          setTimeout(() => {
            this.detailData = event?.data?.data;
            this.formatData();
            setTimeout(() => {
              this.formatCachedList();
            }, 1000);
            this.apidataUpdation = false;
          }, 100);
        });
      }
    };
    this.getLoggedUserProfile();
    this.cdr.detectChanges();
    this.getJobDetails();

    this.visibilityChangeHandler = () => {
      if (document.visibilityState === 'visible') {
        this.getTimerValue();
      }
    };
  }
  getJobDetails() {
    let id = window.localStorage.getItem('urlId');
    this.pageService.isOnEvent.next(true);
    this.getJobInfo(id);
    //check whether offline and localhost, if so change apidataUpdation = false
    if (!this.swUpdate.isEnabled || this.offlineMode) {
      this.apidataUpdation = false;
    }
  }
  getJobInfo(id: any) {
    if (window.innerWidth < 770) {
      this.getMobileJobDetailById(id);
    } else {
      //large devices

      this.getLargeDevicesJobDetailById(id);
    }
  }
  updateOnlineStatus() {
    // case where user is not doing update, which implies we can update the ui from offline to online or vice versa
    let offlineReloadCheck = this.offlineMode;
    this.offlineMode = !navigator?.onLine;

    if (this.offlineMode == false && offlineReloadCheck) {
      this.pageService.sWOperations.next({ syncData: true });
    }
  }
  getLargeDevicesJobDetailById(jobId: any) {
    this.jobService.getJobById(jobId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.detailData = response['data'];

        this.formatData();
      }
      this.spinnerService.hide();
    });
  }

  getMobileJobDetailById(jobId: any) {
    if (!this.offlineMode) {
      this.apidataUpdation = true;
    }

    this.jobService.getMobileJobDetailById(jobId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.detailData = response['data'];

        this.formatData();
      }
      this.spinnerService.hide();
    });
  }
  getCancelledReason() {
    if (this.detailData?.history?.length > 2) {
      let jobHistory = this.detailData?.history;
      let cancelEvent = jobHistory[jobHistory?.length - 1];
      if (
        cancelEvent &&
        [2, 3].includes(this.detailData?.status?.status_identity?.value)
      ) {
        let cancelReason = jobHistory[jobHistory?.length - 2];

        this.cancelReasonData = {
          reason: cancelReason?.detail,
          time: cancelEvent?.updated_at,
          by: cancelReason?.user_name,
        };
      }
    }
  }
  formatSchedule() {
    this.updateSchedulerForm.patchValue(this.detailData?.roster_scheduler);

    this.startDate = new Date(this.updateSchedulerForm.value.start_day);
    this.endDate = new Date(this.updateSchedulerForm.value.end_day);
    this.startTime = convertTime24to12(
      this.updateSchedulerForm.value.start_time
    );
    var [hours, minutes] = this.startTime.split(':');
    this.startDateAndTime = new Date(this.startDate);
    this.startDateAndTime.setHours(parseInt(hours));
    this.startDateAndTime.setMinutes(parseInt(minutes));
    this.endTime = convertTime24to12(this.updateSchedulerForm.value.end_time);
    this.showStartDetail = this.startDate > new Date();
    this.formattedRepeatDetails = getFormattedRepeatDetail(
      this.detailData?.roster_scheduler?.repeat_type,
      this.detailData?.roster_scheduler?.repeat_details
    );

    let startDay = this.detailData?.schedule_event_date
      ? new Date(this.detailData?.schedule_event_date)
      : this.detailData?.roster_scheduler?.start_day
      ? new Date(this.detailData?.roster_scheduler?.start_day)
      : new Date();
    startDay.setDate(startDay.getDate() - 1);
    this.validStartDate = startDay;
    this.sheduleIfChecks();
  }
  async formatHistory() {
    this.jobHistory = JSON.parse(JSON.stringify(this.detailData?.history));
    this.jobHistoryBackup = JSON.parse(
      JSON.stringify(this.detailData?.history)
    );

    await this.formatCachedList();
  }
  formatCachedData(item: any) {
    let eventDict: any = {};
    if (item?.pathName === '/api/job/update_job/') {
      if (item?.image_source === 'live') {
        eventDict = { event_action: 8, action: 'Uploaded Image' };
      } else if (item?.image_source === 'device') {
        eventDict = { event_action: 19, action: 'File Uploaded' };
      } else {
        eventDict = { event_action: 7, action: 'Added Comment' };
      }
    }
    return {
      cachedData: true,
      detail: item?.comment || null,
      gps: item?.device_info?.gps,
      updated_at: item?.updated_at * 1000,
      recorded_at: item?.updated_at * 1000,
      index_db_id: item?.indexDBId,
      file: item?.file || null,
      subs_user_id: this.subscriberUserId,
      ...eventDict,
      device_details: {
        Ip: item?.device_info?.gps?.ip,
        Browser: `${item?.device_info?.browser} - ${item?.device_info?.browser_version}`,
        Device: `${item?.device_info?.device} - ${item?.device_info?.deviceType} `,
      },
    };
  }
  async formatCachedList() {
    this.jobHistory = JSON.parse(JSON.stringify(this.jobHistoryBackup));
    const loggedInUsersUpdates = this.jobHistory?.filter(
      (item: any) =>
        item?.subs_user_id == this.subscriberUserId &&
        [7, 8, 19].includes(item?.event_action)
    );

    this.indexedDbListData = await this.getAllIndexedDbItems();

    let remainingDataList: any = this.indexedDbListData?.filter(
      (cachedItem: any) =>
        cachedItem?.job_id === this.detailData?.id &&
        !loggedInUsersUpdates?.some(
          (item: any) =>
            item?.index_db_id == cachedItem?.indexDBId &&
            new Date(cachedItem?.updated_at * 1000).getTime() ===
              new Date(item?.recorded_at).getTime()
        )
    );

    remainingDataList?.forEach((item: any) => {
      let data = this.formatCachedData(item);

      if (
        !this.jobHistory?.some(
          (item: any) =>
            item?.cachedData && item?.index_db_id == data?.index_db_id
        )
      ) {
        this.jobHistory.push(data);
      }
    });
    this.jobHistory = this.jobHistory?.sort(
      (a: any, b: any) =>
        new Date(a?.updated_at).getTime() - new Date(b?.updated_at).getTime()
    );
    this.jobCommentsFiles = [
      ...this.jobHistory.filter((item: any) =>
        // 8, _("uploaded image")
        // 19, ("file uploaded")
        // 1, _("changed status")
        // 7, _("added comment")
        [8, 19, 7, 1].includes(item?.event_action)
      ),
    ];
    this.cdr.detectChanges();
    let updatedPatrolEvents: any = this.indexedDbListData
      ?.filter(
        (cachedItem: any) =>
          cachedItem?.job_id === this.detailData?.id &&
          cachedItem?.apiCallSuccess &&
          loggedInUsersUpdates?.some(
            (item: any) =>
              (item?.index_db_id == cachedItem?.indexDBId &&
                new Date(cachedItem?.updated_at * 1000).getTime() ===
                  new Date(item?.recorded_at).getTime()) ||
              (cachedItem?.id && item?.id && cachedItem?.id === item?.id)
          )
      )
      ?.map((item: any) => item?.indexDBId);

    this.deleteCachedData(updatedPatrolEvents);
  }

  formatData() {
    this.spinnerService.show();
    if (
      (this.detailData?.onsite_time || this.detailData?.on_site_time) &&
      this.detailData?.status?.status_identity?.value === 4
    ) {
      this.startCountup();
      document.addEventListener(
        'visibilitychange',
        this.visibilityChangeHandler
      );
    }
    if (this.detailData?.status?.status_identity?.value !== 4) {
      this.clearIntervalTimeoutFunction();
      document.removeEventListener(
        'visibilitychange',
        this.visibilityChangeHandler
      );
    }
    if (this.detailData?.roster_scheduler?.repeat_type) {
      this.formatSchedule();
      this.getTimeDifference();
    }

    this.formatAssigneeDropDown();
    this.newComment = '';
    this.getStatusTextandColor();
    this.formatHistory();
    if (this.detailData?.status?.status_identity?.value === 1) {
      this.isReopenJob = this.detailData?.can_reopen;
      // if completd job find the last status to reopen
      if (this.detailData?.can_reopen) this.findLastStatusId();
    }
    this.mapInput.lat = this.detailData.sites[0]?.address?.latitude;
    this.mapInput.lon = this.detailData.sites[0]?.address?.longitude;
    this.jobCreationDate = new Date(this.detailData?.history[0]?.updated_at);
    this.newRequest = this.detailData?.request_details;
    this.assigneeData = this.detailData?.assignee;
    this.siteContactListTemp = this.siteContactList?.filter((value1: any) => {
      if (
        !this.detailData?.location_contacts?.some(
          (value2: any) => value1.id === value2.id
        )
      )
        return value1;
    });
    this.assigneeIdList =
      this.detailData?.assignee?.map((item: any) => {
        return item.id;
      }) || [];

    if (
      this.detailData?.status?.status_identity?.value === 2 ||
      this.detailData?.status?.status_identity?.value === 3
    )
      this.getCancelledReason();
    this.selectedInterval = this.detailData?.interval_check
      ? this.detailData?.interval_check
      : this.intervalData[0].value;
    this.ngZone.run(() => {
      setTimeout(() => {
        this.calculateFixedBottomHeight();
        this.spinnerService.hide();
        this.applyMaxHeight =
          this.scheduleCheck &&
          (!(
            [1, 2, 3].includes(
              this.detailData?.status?.status_identity?.value
            ) ||
            ![2, 3].includes(this.detailData?.status?.status_identity?.value)
          ) ||
            (this.detailData?.status?.status_identity?.value === 1 &&
              this.detailData?.can_reopen) ||
            (this.detailData?.status?.status_identity?.value !== 1 &&
              (this.detailData?.service_type?.enable_comments === true ||
                this.detailData?.service_type?.enable_uploads === true)));
      }, 200);
    });
  }
  formatAssigneeDropDown() {
    let exisitingAssignees = this.getExisitingAssignees();
    this.users = this.users?.filter(
      (user: any) => !exisitingAssignees.includes(user?.id)
    );
  }

  expandContacts(contact: any) {
    contact.expandContact = !contact.expandContact;
  }

  clearInfo() {
    this.newComment = '';
    this.dialogRef?.close();
    this.newComment = '';

    this.webcamImage = null;
  }
  addComment(data?: any) {
    if (this.newComment && this.newComment.trim()) {
      this.spinnerService.show();
      let body: any = {
        job_id: this.detailData?.id,
        comment: this.newComment,
        jobDetails: {
          job_key: this.detailData?.job_key,
          company: this.detailData?.company?.company_name,
        },
      };
      if (Object.keys(data).length) {
        body.id = data?.id;
        body.edit_comment = 1;
      }
      this.jobService.jobUpdate(body).subscribe((response: any) => {
        this.clearInfo();
        this.formatHistory();
        if (this.offlineMode && response?.status == 'success') {
          this.pageService.setMessage({
            successMessage: 'Action Queued',
            errorMessage: '',
          });
        }

        if (response?.data) {
          this.detailData = response['data'];
          this.selectedEvent = null;
          this.formatData();
        }

        this.spinnerService.hide();
      });
    } else {
      this.toaster.warning('', 'Please add a comment');
    }
  }
  updateJobStatus(value: any) {
    if (value) {
      this.updateData({
        job_status_id: value.id,
      });
    }
  }
  nextJobSection(status: any) {
    if (status?.status_identity.value === 1) {
      let dialogMsg = 'Mark job as complete?';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        context: {
          title: 'Confirmation Needed',
          message: dialogMsg,
        },
      });
      dialogRef.onClose.subscribe((dialogResponse) => {
        if (dialogResponse === 'Yes') {
          this.updateData(status);
        }
      });
    } else {
      this.updateJobStatus(status);
    }
  }
  cancelJob() {
    if (this.detailData?.status?.status_identity?.value === 0) {
      let remainingAssignees = this.assigneeData
        .filter((item: any) => item.id !== this.subscriberUserId)
        .map((item: any) => {
          return item.id;
        });
      this.updateData({ new_assignee: remainingAssignees });

      this.selfRemoved = true;
    } else {
      this.deleteJob();
    }
  }

  getRequestDetails() {
    let requestData: any = {};
    requestData['method'] = 'put';
    requestData['url'] = 'api/job/' + this.detailData.id + '/';
    requestData['id'] = this.detailData.id;
    return requestData;
  }

  updateData(body: any) {
    let requestData = this.getRequestDetails();
    if (body) {
      if (this.updateCheck()) {
        this.spinnerService.show();
        requestData['data'] = body;
        this.jobService.updateJob(requestData).subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.pageService.setMessage({
              // successMessage: response['message'],
              errorMessage: '',
            });
            this.newComment = '';
            this.dialogRef?.close();
            if (!response?.data) {
              this._location.back();
            }
            this.detailData = response['data'];

            this.formatData();
            this.popovers.forEach((pop: { hide: () => void }) => {
              pop.hide();
            });
            this.appService.setLoadingText(' ');

            this.spinnerService.hide();

            if (
              this.detailData?.status?.status_identity?.value === 2 ||
              this.detailData?.status?.status_identity?.value === 1 ||
              this.detailData?.status?.status_identity?.value === 7 ||
              this.selfRemoved
            ) {
              this._location.back();
            }
          } else {
            this.appService.setLoadingText(' ');

            this.spinnerService.hide();

            this.toaster.danger('', response['message']);
          }
        });
      }
    }
  }
  openCamPopup(uploadType: string = 'live') {
    this.dialogRef = this.dialog.open(WebcamImageComponent, {
      context: {
        showFrom: 'updateJob',
        uploadType: uploadType,
      },
    });
    this.dialogRef.onClose.subscribe((value: any) => {
      if (value && value !== 'error') {
        this.webcamImage = value.webCamImage;
        this.actualUploadFile = value.actualFileUpload;
        this.uploadType = value?.uploadType;
        this.newComment = value.newComment;
        this.onFileSubmit();
      } else if (value == 'error') {
        this.cameraError = 'ERROR: Unable to access your camera';
      }
    });
  }
  openHistoryChangeTemplate(template: TemplateRef<any>, data: any) {
    this.dialogRef = this.dialog.open(template, {
      context: data,
    });
  }

  openContactsPopup(contactDialog: TemplateRef<any>) {
    this.dialogRef = this.dialog.open(contactDialog, {
      context: {},
    });
    this.dialogRef.onClose.subscribe((value: any) => {});
  }
 

  fileUpload(url: string, fileData: any) {
    fileData.append('image_source', this.uploadType);
    this.appService.formDataApi(url, fileData).then((response: any) => {
      this.clearInfo();
      this.formatHistory();
      if (this.offlineMode && response?.status == 'success') {
        this.pageService.setMessage({
          successMessage: 'Action Queued',
          errorMessage: '',
        });
      }

      if (response?.data) {
        this.detailData = response['data'];

        this.formatData();
      }
      this.appService.setLoadingText(' ');
      this.spinnerService.hide();
    });
  }
  //Method to upload the photo for selected site or job
  onFileSubmit() {
    this.appService.setLoadingText('Uploading Details');
    if (!this.actualUploadFile) {
      this.toaster.warning('', 'Please add a photo');
    } else {
      this.spinnerService.show();
      let fileData: FormData = new FormData();

      fileData.append('file', this.actualUploadFile);
      if (this.newComment) {
        fileData.append('comment', this.newComment);
      }
      let url = `job/update_job`;
      fileData.append('job_id', this.detailData?.id);
      fileData.append(
        'jobDetails',
        JSON.stringify({
          job_key: this.detailData?.job_key,
          company: this.detailData?.company?.company_name,
        })
      );

      this.fileUpload(url, fileData);
    }
  }

  getLoggedUserProfile() {
    this.profileService.fetchUserProfile().subscribe((res) => {
      if (res['status'] === 'success') {
        this.userDetails = res['data'];
        this.subscriberUserId = this.userDetails?.id;
      }
    });
  }
  getRequestUserLicCheck() {
    return this.userDetails?.license?.some(
      (item: any) =>
        item.issuer_state === this.detailData.sites[0]?.address?.state_code
    );
  }
  updateRequestDetails() {
    this.updateData({ request: this.newRequest });
  }
  updateCheck() {
    if (this.detailData?.has_been_24hrs) {
      this.toaster.danger(
        '',
        `You cannot make changes to a completed job after 24 hours`
      );
      return false;
    }

    return true;
  }

  deleteJob() {
    let newStatus = this.availableJobStatus.filter(
      (s: any) =>
        s?.status.status_identity.value === 2 ||
        s?.status.status_identity.value === 3
    )[0];

    let body = {
      job_status_id: newStatus?.status?.id,
      comment: this.cancelReason,
    };

    if (body) {
      this.updateData(body);
    }
  }
  openAssigneeDetail(assignee: any) {
    if (assignee.id) {
      const dialogRefUser = this.dialog.open(AddEditUsersComponent, {
        context: {
          editUser: true,
          detailData: assignee,
        },
      });
      dialogRefUser.onClose.subscribe((value: any) => {
        if (value === 'Yes') {
        }
      });
    }
  }

  validLicenseCheck(license: any) {
    for (let i = license.length - 1; i >= 0; i--) {
      if (
        license[i].issuer_state ===
        this.detailData.sites[0]?.address?.state_code
      ) {
        return license[i];
      }
    }
    return false;
  }

  getGuardDetails(guardsData: any) {
    let exisitingAssignees = this.getExisitingAssignees();

    for (let i = guardsData.length - 1; i >= 0; i--) {
      if (this.validLicenseCheck(guardsData[i].license)) {
        guardsData[i].validStateLicense = this.validLicenseCheck(
          guardsData[i].license
        );
      }
      if (exisitingAssignees.includes(guardsData[i].id)) {
        guardsData.splice(i, 1);
      }
    }

    return guardsData;
  }
  getAssignees(event: any = null) {
    let value = event?.target?.value;

    let params = {};
    if (value) {
      params = { full_name: value };
    }

    this.jobService.getAssignees(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.users = this.getGuardDetails(response['data']);
      } else {
        this.toaster.warning('Warning', response['message']);
      }
    });
  }

  getExisitingAssignees() {
    return (
      this.detailData?.assignee?.map((item: any) => {
        return item.id;
      }) || []
    );
  }
  onAssigneeDeselect(assignee: any) {
    this.updateData({ remove_assignee: assignee.id });
  }

  onAssigneeSelect(assignee: any) {
    if (
      assignee?.license?.some(
        (item: any) =>
          item.issuer_state === this.detailData.sites[0]?.address?.state_code
      ) ||
      assignee.status === 'ASSIGNED'
    ) {
      assignee.status = 'ASSIGNED';
      this.updateData({ new_assignee: assignee.id });
    } else {
      let dialogMsg: any = '';
      if (assignee.id === this.subscriberUserId) {
        dialogMsg =
          'You do not hold a licence for this location, are you sure you want to take the job?';
      } else {
        dialogMsg = `${
          assignee.full_name || assignee.email
        } does not hold a licence for this location, are you sure you want assign to ${
          assignee.full_name || assignee.email
        }?`;
      }
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        context: {
          title: 'Add Assignee',
          message: dialogMsg,
        },
      });
      dialogRef.onClose.subscribe((value) => {
        if (value === 'Yes') {
          this.updateData({ new_assignee: assignee.id });
        }
      });
    }
  }
  backupLastStatus() {
    const reopenStatus =
      this.availableJobStatus.find(
        (s: any) => s?.status.status_identity.value === 6
      ) ||
      this.availableJobStatus.find(
        (s: any) => s?.status.status_identity.value === 0
      );
    return reopenStatus;
  }
  findLastStatusId() {
    const statusChangeList = this.jobHistory?.filter(
      (item: any) => item?.event_action === 1
    );

    if (statusChangeList?.length) {
      // find the second last one since its in ascending order and last one will be the complete status
      const prevStatus = statusChangeList[statusChangeList.length - 2];
      this.lastStatus = this.availableJobStatus.find(
        (s: any) => s?.status?.id === prevStatus?.changed_status_id
      );
    }

    if (!this.lastStatus) {
      this.lastStatus = this.backupLastStatus();
    }
  }

  moveJobBack() {
    if (this.detailData?.has_been_24hrs) {
      this.toaster.danger(
        '',
        `You cannot make changes to a completed job after 24 hours`
      );
    } else {
      if (this.lastStatus?.status?.id) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          context: {
            title: 'Reopen Job',
            message:
              'WARNING: Site contacts will be notified if you reopen this job.',
          },
        });
        dialogRef.onClose.subscribe((value) => {
          if (value === 'Yes') {
            this.updateJobStatus(this.lastStatus?.status);
          }
        });
      }
    }
  }

  onTableRowClick(event: any) {
    this.showDetails = true;
    this.selectedEvent = event;

    if (event?.gps) this.mapLatLong = { ...event?.gps };
    this.uploadedImage = event?.file;

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 100);
  }
  onCardRowClick(event: any) {
    this.scrollPosition = window.scrollY;
    // this.showDetails = true;
    this.selectedEvent = event;
    this.tableStyle = {
      'overflow': 'auto',
      'max-height': '35vh',
    };
    if (event?.gps) this.mapLatLong = { ...event?.gps };
    this.uploadedImage = event?.file;
  }
  hideDetails() {
    this.showDetails = false;

    // let scrollPosition: any = window.localStorage.getItem('scrollToView');
    setTimeout(() => {
      window.scrollTo(0, this.scrollPosition);
    }, 100);
  }
  changeJobStatus(
    status: any,
    jobCancelTemplate: TemplateRef<any>,
    onsiteTimeTempalteRef: TemplateRef<any>,
    previousStatus?: any
  ) {
    if (
      status?.status?.status_identity.value === 4 ||
      status?.status?.status_identity.value === 5
    ) {
      this.dateValue = new Date();
      this.dateTimeValue = new Date();
      this.checkEndTime();
      const dialogRef = this.dialog.open(onsiteTimeTempalteRef, {
        context: { name: status?.status?.name },
        dialogClass: 'model-full',
      });
      dialogRef.onClose.subscribe((value) => {
        if (value !== false) {
          let newStatus = this.availableJobStatus.filter(
            (s: any) => s?.status?.id === status?.status?.id
          )[0];

          if (newStatus) {
            let body = {
              job_status_id: newStatus?.status?.id,
              user_provided_timestamp: this.dateTimeValue,
            };
            this.updateData(body);
          }
        } else {
          this.selectedStatus = {};
        }
      });
    } else {
      let newStatus = this.availableJobStatus.filter(
        (s: any) => s?.status?.id === status?.status?.id
      )[0];

      if (newStatus) {
        if (
          newStatus?.status?.status_identity?.value === 2 ||
          newStatus?.status?.status_identity?.value === 3
        ) {
          this.openCancelJobTemplate(jobCancelTemplate);
        } else if (newStatus?.status?.status_identity?.value === 1) {
          let dialogMsg = 'Mark job as complete?';
          this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
            context: {
              title: 'Confirmation Needed',
              message: dialogMsg,
            },
          });
          this.dialogRef.onClose.subscribe((dialogResponse: any) => {
            if (dialogResponse === 'Yes') {
              this.updateJobStatus(newStatus.status);
            } else {
              this.selectedStatus = {};
            }
          });
        } else if (newStatus?.status?.status_identity?.value === 7) {
          let dialogMsg =
            'WARNING: This action cannot be undone and will not notify the client.';
          this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
            context: {
              title: 'Confirmation Needed',
              message: dialogMsg,
            },
          });
          this.dialogRef.onClose.subscribe((dialogResponse: any) => {
            if (dialogResponse === 'Yes') {
              this.updateJobStatus(newStatus.status);
            } else {
              this.selectedStatus = {};
            }
          });
        } else {
          this.updateJobStatus(newStatus.status);
        }
      }
    }
  }
  getSiteContacts(modifySiteContactRef: TemplateRef<any>) {
    if (this.detailData?.status?.status_identity?.value === 0) {
      this.customerService
        .getSiteContacts(this.detailData?.sites[0]?.id)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.siteContactList = response['data'];

            this.siteContactListTemp = this.siteContactList?.filter(
              (value1: any) => {
                if (
                  !this.detailData?.location_contacts?.some(
                    (value2: any) => value1.id === value2.id
                  )
                )
                  return value1;
              }
            );
          }
        });
    }
    this.dialogRef = this.dialog.open(modifySiteContactRef);
  }
  addSiteContact(contactId: any) {
    this.updateData({ new_site_contact: contactId });
  }
  onSiteContactDeselect(contact: any) {
    this.updateData({ remove_site_contact: contact.id });
  }
  addLocationContact() {
    let siteData: any = this.detailData?.sites[0];
    siteData['contacts'] = this.siteContactList;

    const dialogRef = this.dialog.open(ContactComponent, {
      context: {
        contactForm: this.contactForm,
        siteData: siteData,
        addNewSiteContact: true,
        contact_type: 'lc',
      },
      dialogClass: 'model-full',
    });
    dialogRef.onClose.subscribe((value) => {
      if (value !== 'false') {
        this.siteContactList = value;
        let lastAddedContact =
          this.siteContactList[this.siteContactList.length - 1];

        this.addSiteContact(lastAddedContact?.id);
      }
    });
  }

  getStatusTextandColor() {
    this.availableJobStatus = this.detailData.service_type?.statuses;
    this.selectedJobStatus = { ...this.detailData?.status } || {};
    this.availableStatus = this.detailData.service_type?.statuses
      ?.map((obj: any) => {
        const statusValue = obj?.status?.status_identity?.value;
        if (statusValue === 2 || statusValue === 3) {
          return {
            ...obj,
            status: {
              ...obj.status,
              name: 'Cancel Job',
              color: 'var(--button-ghost-danger-text-color)',
            },
          };
        } else if (statusValue !== 6) {
          return obj;
        }
      })
      ?.filter(Boolean);
    this.statusList = this.detailData.service_type?.statuses
      ?.map((obj: any) => {
        const statusValue = obj?.status?.status_identity?.value;
        if (statusValue === 2 || statusValue === 3) {
          return {
            ...obj,
            status: {
              ...obj.status,
              name: 'Cancel Job',
              color: 'var(--button-ghost-danger-text-color)',
            },
          };
        } else if (
          statusValue !== 6 &&
          this.selectedJobStatus?.id !== obj?.status?.id
        ) {
          return obj;
        }
      })
      ?.filter(Boolean);

    this.statusList = this.filterStatuses(this.statusList, [
      this.detailData?.next_status?.forward_status,
      this.detailData?.next_status?.backward_status,
      this.detailData?.next_status?.reverse_status,
    ]);
    if (this.detailData?.status?.status_identity === 1) {
      this.isReopenJob = this.detailData?.can_reopen;
    }
  }
  mapsSelector(lat: any, lon: any) {
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.platform.indexOf('iPhone') != -1 ||
      navigator.platform.indexOf('iPad') != -1 ||
      navigator.platform.indexOf('iPod') != -1
    )
      window.open(`maps://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
    /* else use Google */ else
      window.open(`https://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
  }
  modifyAssignees(modifyAssigneeRef: TemplateRef<any>) {
    this.dialogRef = this.dialog.open(modifyAssigneeRef);
  }
  modifyRequestDetails(requestRef: TemplateRef<any>) {
    if (this.isAdmin === true || this.isDispatchUser === true) {
      this.dialogRef = this.dialog.open(requestRef);
    }
  }
  openSiteContactTemplate(data: any) {
    let siteData: any = this.detailData?.sites[0];

    this.dialogRef = this.dialog.open(ContactComponent, {
      context: {
        contactData: data,
        siteData: siteData,
      },
    });
    this.dialogRef.onClose.subscribe((dialogResponse: any) => {
      if (dialogResponse !== 'false') {
        this.getJobInfo(this.detailData?.id);
      }
    });
  }
  openAddCommentTemplate(requestRef: TemplateRef<any>) {
    this.dialogRef = this.dialog.open(requestRef, {
      context: {},
    });
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 1);
  }

  openCancelJobTemplate(requestRef: TemplateRef<any>) {
    let hasBackdrop = true;
    this.dialogRef = this.dialog.open(requestRef, {
      hasBackdrop,
      closeOnBackdropClick: true,
      context: {},
    });
    this.dialogRef.onClose.subscribe((dialogResponse: any) => {
      if (dialogResponse === 'Yes') {
      } else {
        this.selectedStatus = {};
      }
    });
  }

  redirectToClientPage() {
    this.router.navigate([
      '/view-client',
      { cKey: String(this.detailData?.company?.id) },
    ]);
  }

  onOpenImage(event: any, requestRef: TemplateRef<any>) {
    const imgElem = event.target;
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;
    this.fullImgSrc = srcAttr.nodeValue;
    this.dialogRef = this.dialog.open(requestRef, {
      closeOnBackdropClick: true,
      context: {},
    });
  }

  showOfflineMessage() {
    this.pageService.setMessage({
      successMessage: '',
      errorMessage: 'Feature not available while offline',
    });
  }
  editComment(commentData: any, requestRef: TemplateRef<any>) {
    this.newComment = commentData?.detail;
    this.previousComment = commentData?.detail;
    this.dialogRef = this.dialog.open(requestRef, {
      context: commentData,
    });
    this.dialogRef.onClose.subscribe((dialogResponse: any) => {
      if (dialogResponse !== 'close') {
        this.showDetails = false;
        this.selectedEvent = null;
      }
    });
  }
  onMcSearch(event: any) {
    this.mcInputValue = event?.target?.value;
    if (event?.target?.value.length > 2) {
      this.getMonitoringCompanyList({ search_str: event?.target?.value });
    }
  }

  getMonitoringCompanyList(params?: any) {
    // this.spinnerService.show();
    this.customerService
      .fetchMonitoringCompanyList(params)
      .subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.monitoringCompanyList = res['data'];
        }
        // this.spinnerService.hide();
      });
  }
  modifyMonitoringCompany(requestRef: TemplateRef<any>) {
    this.getMonitoringCompanyList();
    this.dialogRef = this.dialog.open(requestRef, {
      context: {},
    });
  }
  addMC(monitoringCompany: any) {
    this.updateData({ monitoring_company_id: monitoringCompany?.id });
    this.dialogRef?.close();
  }
  openAddMonitoringCompany() {
    const dialogRef = this.dialog.open(AddEditMonitoringCompanyComponent, {
      context: {
        updateView: false,
        name: this.mcInputValue,
      },
    });

    dialogRef.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.addMC(value?.data);
      }
    });
  }
  onOpenFile(file: any, template: TemplateRef<any>) {
    this.hideSpinner = false;
    if (file?.extension === '.pdf') {
      this.downloadFile(file);
    } else {
      this.dialogRef = this.dialog.open(template, {
        closeOnBackdropClick: true,
        context: file,
      });
    }
  }

  downloadFile(file: any) {
    const a = document.createElement('a');
    a.href = file?.file_url;
    a.download = `${file?.name}${file?.extension}`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  updateJobId() {
    this.updateData({ external_job_id: this.externalNewJobId });
  }

  modifyExternalJobId(template: TemplateRef<any>) {
    this.externalNewJobId = this.detailData?.ext_job_id;
    this.externalOldJobId = this.detailData?.ext_job_id;
    this.dialogRef = this.dialog.open(template, {
      context: {},
    });
    this.dialogRef.onClose.subscribe((value: any) => {});
  }

  formateNewStartDate(event: any) {
    this.dateTimeValue = event;
  }
  formatNewStartTime(event: any) {
    let startTime = convertTime12to24(event);
    var [hours, minutes] = startTime.split(':');
    let startDateTime = new Date(this.dateTimeValue);
    startDateTime.setHours(parseInt(hours));
    startDateTime.setMinutes(parseInt(minutes));

    if (startDateTime.getTime() > new Date().getTime()) {
      this.pageService.setMessage({
        successMessage: '',
        errorMessage: 'cannot set future date',
      });
    } else {
      this.timeValue = event;
      this.dateTimeValue = startDateTime;
    }
  }

  checkEndTime() {
    var abc: any;
    abc = new Date();
    abc.setHours(parseInt(abc.getHours()));
    abc.setMinutes(parseInt(abc.getMinutes()));
    abc.setSeconds(5);
    abc = abc.toLocaleTimeString('en-Gb', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      hourCycle: 'h23',
    });
    abc = abc.replace(':05', '');

    this.timeValue = abc;
  }
  getImgSrc(file: File): SafeUrl {
    let url = URL.createObjectURL(file);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  clearIntervalTimeoutFunction() {
    if (this.onsiteTimeOutFn) {
      clearTimeout(this.onsiteTimeOutFn);
    }
    if (this.onsiteIntervalFn) {
      clearInterval(this.onsiteIntervalFn);
    }
  }
  ngOnDestroy() {
    this.clearIntervalTimeoutFunction();
    document.removeEventListener(
      'visibilitychange',
      this.visibilityChangeHandler
    );
  }

  resyncTimer() {
    this.getTimerValue();
    this.onsiteTimeOutFn = setTimeout(() => {
      this.resyncTimer();
    }, 1000 * 180);
  }

  startCountup() {
    this.clearIntervalTimeoutFunction();
    this.onsiteTime = this.getOnsiteTime();
    this.ngZone.run(() => {
      this.resyncTimer();
      this.onsiteIntervalFn = setInterval(() => {
        this.getTimerValue();
      }, 1000);
    });
  }
  getTimerValue() {
    if (!this.onsiteTime) {
      this.onsiteTime = this.getOnsiteTime();
    }
    let diffInTime = new Date().getTime() - this.onsiteTime;
    let seconds = Math.floor(diffInTime / 1000);
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;
    this.onsiteTimer = `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(
      remainingSeconds
    )}`;
  }
  getOnsiteTime() {
    let onsiteTime =
      this.detailData.onsite_time || this.detailData?.on_site_time;
    return new Date(onsiteTime).getTime();
  }

  pad(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
  toggleHistorySection() {
    this.showHistorySection = !this.showHistorySection;
  }

  getTimeDifference() {
    let startTime = this.detailData?.schedule_event_date
      ? new Date(this.detailData?.schedule_event_date)
      : new Date();
    let endTime = this.detailData?.schedule_event_date
      ? new Date(this.detailData?.schedule_event_date)
      : new Date();

    if (this.detailData?.roster_scheduler?.repeat_type == 'once') {
      startTime = new Date(
        this.detailData?.roster_scheduler?.start_day +
          ' ' +
          this.detailData?.roster_scheduler?.start_time
      );
      endTime = new Date(
        this.detailData?.roster_scheduler?.end_day +
          ' ' +
          this.detailData?.roster_scheduler?.end_time
      );
    } else {
      if (
        this.detailData?.roster_scheduler?.start_time >
        this.detailData?.roster_scheduler?.end_time
      ) {
        endTime.setDate(startTime.getDate() + 1);
      }
      this.setTime(startTime, this.detailData?.roster_scheduler?.start_time);
      this.setTime(endTime, this.detailData?.roster_scheduler?.end_time);
    }

    this.scheduleDuration = this.getTimeDiff(startTime, endTime);

    this.scheduleCheckFn(startTime, endTime);
  }

  setTime(date: Date, time: string) {
    const [hours, minutes] = time?.split(':');
    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes));
  }

  getTimeDiff(startTime: any, endTime: any) {
    let diffInTime = endTime?.getTime() - startTime?.getTime();

    if (!diffInTime) return '00:00';
    let seconds = Math.floor(diffInTime / 1000);
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    this.intervalData = [
      { name: 'No Welfare Checks', value: 0 },
      { name: 'Welfare Checks Every 5 mins', value: 5 },
      { name: 'Welfare Checks Every 15 mins', value: 15 },
      { name: 'Welfare Checks Every 30 mins', value: 30 },
    ];
    if (hours > 1) {
      this.intervalData.push({
        name: 'Welfare Checks Every 1 Hour',
        value: 60,
      });
    }
    if (hours > 2) {
      this.intervalData.push({
        name: 'Welfare Checks Every 2 Hours',
        value: 120,
      });
    }
    return `${this.pad(hours)}:${this.pad(minutes)}`;
  }
  openIntervalCheckTemplate(template: TemplateRef<any>) {
    this.dialogRef = this.dialog.open(template);
  }
  updateInterval(interval: Number) {
    this.updateData({ interval_check: interval });
  }
  formatDateWithoutTime = (date: any) => {
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };
  scheduleCheckFn(startDatetime: any, endDateTime: any) {
    this.scheduleCheck =
      !this.detailData?.roster_scheduler?.repeat_type ||
      this.isAdmin ||
      this.isDispatchUser;
    if (!this.scheduleCheck) {
      const today = this.formatDateWithoutTime(new Date());

      this.ngZone.run(() => {
        this.scheduleCheck =
          this.formatDateWithoutTime(startDatetime) <= today &&
          this.formatDateWithoutTime(endDateTime) >= today;
      });
    }
  }

  getEndTime(startDateTime?: any) {
    let date = new Date();
    if (startDateTime) {
      date = new Date(startDateTime);
    }

    let hours = date.getHours() + 1;

    if (hours > 23) {
      let nextDay = new Date(this.startDateAndTime);
      nextDay.setDate(nextDay.getDate() + 1);

      this.endDate = nextDay;
      this.updateSchedulerForm.controls['end_day'].setValue(nextDay);
      hours = 0;
    }
    let minutes = date.getMinutes();

    // Pad single digit minutes with a leading zero
    let minutesString = minutes < 10 ? '0' + minutes : '' + minutes;

    return `${hours}:${minutesString}`;
  }
  formateStartDate(event: any) {
    this.startDate = event;
    this.endDate = event;
    this.endDateAndTime = event;
    this.updateSchedulerForm.controls['start_day'].setValue(this.startDate);
    this.startDateAndTime = event;
  }
  formateEndDate(event: any) {
    this.endDate = event;
    this.endDateAndTime = event;
    this.updateSchedulerForm.controls['end_day'].setValue(this.endDate);
    // if (!this.showRecurringView) {
    //   this.shiftClick('endTime');
    // }
  }

  formatEndTime(event: any) {
    this.endTime = convertTime12to24(event);
    var [hours, minutes] = this.endTime.split(':');
    this.endDateAndTime = new Date(this.endDate);
    this.endDateAndTime.setHours(parseInt(hours));
    this.endDateAndTime.setMinutes(parseInt(minutes));
    this.updateSchedulerForm.controls['end_time'].setValue(this.endTime);
    if (this.startDateAndTime.getTime() >= this.endDateAndTime.getTime()) {
      this.errorMessage = 'Invalid end date and time';

      setTimeout(() => {
        this.endTime = null;
        this.updateSchedulerForm.controls['end_time'].setValue(null);
      });
    }
  }

  formatStartTime(event: any) {
    this.startTime = convertTime12to24(event);
    var [hours, minutes] = this.startTime.split(':');
    this.startDateAndTime = new Date(this.startDate);
    this.startDateAndTime.setHours(parseInt(hours));
    this.startDateAndTime.setMinutes(parseInt(minutes));
    this.updateSchedulerForm.controls['start_time'].setValue(this.startTime);
    this.updateSchedulerForm.controls['end_day'].setValue(this.endDate);
    this.endTime = this.getEndTime(this.startDateAndTime);
    this.updateSchedulerForm.controls['end_time'].setValue(this.endTime);
    // if (this.showRecurringView) {
    //   this.shiftClick('endTime');
    // }
  }
  sheduleIfChecks() {
    const now = new Date();

    const startTime = new Date(
      this.detailData.roster_scheduler.start_day +
        ' ' +
        this.detailData.roster_scheduler.start_time
    );
    const endTime = new Date(
      this.detailData.roster_scheduler.end_day +
        ' ' +
        this.detailData.roster_scheduler.end_time
    );

    const canUpdateSchedule =
      endTime.getTime() > now.getTime() &&
      (!this.detailData?.schedule_event_date ||
        this.formatDateWithoutTime(
          new Date(this.detailData?.schedule_event_date)
        ) >= this.formatDateWithoutTime(now));

    if (!(this.isAdmin || this.isDispatchUser)) {
      this.canScheduleBeUpdated = false;
      this.showWelfareItem = canUpdateSchedule;

      return;
    }

    this.canScheduleBeUpdated = canUpdateSchedule;

    this.showWelfareItem = this.canScheduleBeUpdated;

    this.hasScheduleStarted = startTime.getTime() < new Date().getTime();
  }

  openScheduleTemplate(template: TemplateRef<any>) {
    if (this.isAdmin === true || this.isDispatchUser === true) {
      if (
        (this.detailData?.roster_scheduler?.repeat_type === 'once' &&
          this.hasScheduleStarted) ||
        (this.hasScheduleStarted &&
          this.formatDateWithoutTime(
            new Date(this.detailData?.schedule_event_date)
          ) == this.formatDateWithoutTime(new Date()))
      ) {
        if (this.detailData?.roster_scheduler?.repeat_type !== 'once') {
          this.updateSchedulerForm.controls['start_day'].setValue(
            this.detailData?.schedule_event_date
              ? new Date(this.detailData?.schedule_event_date)
              : this.startDate
          );
        }
        this.updateSchedulerForm.controls['end_day'].setValue(
          this.detailData?.schedule_event_date
            ? new Date(this.detailData?.schedule_event_date)
            : this.endDate
        );
        this.endDate = this.detailData?.schedule_event_date
          ? new Date(this.detailData?.schedule_event_date)
          : this.endDate;
        this.updateSchedulerForm.controls['repeat_type'].setValue('once');
        this.dialogRef = this.dialog.open(template);
      } else {
        this.schedulerForm.patchValue(this.detailData?.roster_scheduler);
        // this.schedulerForm.controls['start_day'].setValue(new Date());
        this.dialogRef = this.dialog.open(SchedulerComponent, {
          context: {
            schedulerForm: this.schedulerForm,
            editSchedule: true,
            repeatAllowed:
              this.detailData?.roster_scheduler?.repeat_type != 'once',
          },
        });
        this.dialogRef.onClose.subscribe((value: any) => {
          if (value) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              context: {
                title: 'Are you sure?',
              },
            });
            dialogRef.onClose.subscribe((dialogResponse) => {
              if (dialogResponse === 'Yes') {
                this.updateSchedule();
              }
            });

            this.schedulerForm.patchValue(value);
          }
        });
      }
    }
  }

  endDateDiffCheck() {
    if (
      this.formatDateWithoutTime(
        new Date(this.updateSchedulerForm.value.end_day)
      ) <
      this.formatDateWithoutTime(
        new Date(this.detailData.roster_scheduler.end_day)
      )
    ) {
      return true;
    }
    return false;
  }

  getUpdateScheduleParams() {
    let params: any = {};

    let paramKey: any = this.hasScheduleStarted
      ? 'started_schedule'
      : 'future_schedule';
    if (
      this.formatDateWithoutTime(
        new Date(this.detailData?.schedule_event_date)
      ) == this.formatDateWithoutTime(new Date()) &&
      this.hasScheduleStarted &&
      this.detailData?.roster_scheduler?.repeat_type !== 'once'
    ) {
      paramKey = 'started_job';
    }
    params[paramKey] = 1;
    return params;
  }
  formatScheduleForm(form: any) {
    form.value.start_time = convertTime12to24(form.value.start_time);
    form.value.end_time = convertTime12to24(form.value.end_time);
    form.value.start_day =
      form.value.start_day == ''
        ? ''
        : formatDate(form.value.start_day, 'yyyy-MM-dd', 'en');
    form.value.end_day =
      form.value.end_day == null
        ? null
        : formatDate(form.value.end_day, 'yyyy-MM-dd', 'en');
    let timezoneOffset = new Date();
    return {
      time_offset: timezoneOffset.getTimezoneOffset(),
      ...form.value,
    };
  }
  updateSchedule() {
    this.spinnerService.show();

    let data: any = {
      job_id: this.detailData?.id,
      time_offset: new Date().getTimezoneOffset(),
      ...((this.detailData?.roster_scheduler?.repeat_type === 'once' &&
        this.hasScheduleStarted) ||
      (this.hasScheduleStarted &&
        this.formatDateWithoutTime(
          new Date(this.detailData?.schedule_event_date)
        ) == this.formatDateWithoutTime(new Date()))
        ? this.formatScheduleForm(this.updateSchedulerForm)
        : this.schedulerForm.value),
    };
    this.rsService
      .updateRosterSchedule(
        this.detailData?.roster_scheduler?.id,
        data,
        this.getUpdateScheduleParams()
      )
      .subscribe((response: any) => {
        // this.formatData(response);
        if (response?.status === 'success') {
          if (
            this.detailData?.roster_scheduler?.repeat_type === 'once' &&
            this.hasScheduleStarted
          )
            this.getJobDetails();
          else {
            this.router.navigate(['/roster']);
          }
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }
  filterStatuses(statusArray: any, idArrays: any) {
    return statusArray.filter((status: any) => {
      for (const idArray of idArrays) {
        if (idArray && idArray.includes(status.status.id)) {
          return true;
        }
      }

      return false;
    });
  }
  getLastCommentEditedDateTime(history: any) {
    let sortedComments = history?.sort(
      (a: any, b: any) =>
        new Date(a?.updated_at).getTime() - new Date(b?.updated_at).getTime()
    );
    return sortedComments[sortedComments?.length - 1];
  }
  calculateFixedBottomHeight() {
    // Get the bottom-section-fixed element by ID

    const bottomSectionFixed = document.getElementById('bottom-section-fixed');

    if (bottomSectionFixed) {
      this.bottomSectionFixedHeight =
        bottomSectionFixed.offsetHeight.toString() === '0'
          ? '180' + 'px'
          : bottomSectionFixed.offsetHeight.toString() + 'px';
    }
  }
  async getAllIndexedDbItems() {
    return new Promise(async (resolve) => {
      try {
        setTimeout(async () => {
          const data = await gTDB.gtSavedData.toArray();
          resolve(data);
        }, 500);
      } catch (error) {
        console.log(error, 'INDEXED DB JOB DETaiL ERROR');
        resolve([]);
      }
    });
  }
  deleteCachedData(keys: []) {
    try {
      gTDB.gtSavedData.bulkDelete(keys);
    } catch (error) {
      console.log(error);
    }
  }
}
