<div class="my-3">
  <nb-tabset>
    <nb-tab tabTitle="Clients">
      <app-gt-table
        [desktopTableBackendData]="clientListSubscriber"
        [desktopTableDetails]="tableViewClientListSubscriber"
        [otherParams]="otherParamsClientsSubscriber"
        (sortColumn)="sortColumnClientsSubscriber($event)"
        (paginationChange)="onChangePaginationClientsSubscriber($event)"
      >
      </app-gt-table>
    </nb-tab>
    <nb-tab tabTitle="Sites">
      <app-gt-table
        [desktopTableBackendData]="siteListSubscriber"
        [desktopTableDetails]="tableViewSiteListSubscriber"
        [otherParams]="otherParamsSitesSubscriber"
        (sortColumn)="sortColumnSitesSubscriber($event)"
        (paginationChange)="onChangePaginationSitesSubscriber($event)"
        (searchColumn)="searchColumnSiteSubscriber($event)"
      >
      </app-gt-table>
    </nb-tab>
    <nb-tab tabTitle="User">
      <app-gt-table
        [desktopTableBackendData]="userListSubscriber"
        [desktopTableDetails]="tableViewUserListSubscriber"
        [otherParams]="otherParamsUsersSubscriber"
        (sortColumn)="sortColumnUsersSubscriber($event)"
        (paginationChange)="onChangePaginationUsersSubscriber($event)"
        (searchColumn)="searchColumnUserSubscriber($event)"
        (rowClick)="selectUserSubscriber($event)"
      >
      </app-gt-table>
    </nb-tab>
    <nb-tab tabTitle="Incidents">
      <div class="fw-bold my-3 text-center">
        {{ miscUsersSubscriber.selectedUser?.full_name }}
      </div>
      <app-gt-table
        [desktopTableBackendData]="incidentListSubscriber"
        [desktopTableDetails]="tableViewIncidentListSubscriber"
        [otherParams]="otherParamsIncidentsSubscriber"
        (sortColumn)="sortColumnIncidentsSubscriber($event)"
        (paginationChange)="onChangePaginationIncidentsSubscriber($event)"
        (searchColumn)="searchColumnIncidentSubscriber($event)"
        (rowClick)="onSelectIncidentsSubscriber($event, incidentDetailRef)"
      >
      </app-gt-table>
    </nb-tab>
    <nb-tab tabTitle="Response Jobs">
      <app-gt-table
        [desktopTableBackendData]="alarmResponseJobListSubscriber"
        [desktopTableDetails]="tableViewAlarmResponseJobListSubscriber"
        [otherParams]="otherParamsAlarmResponseJobsSubscriber"
        (sortColumn)="sortColumnAlarmResponseJobsSubscriber($event)"
        (paginationChange)="
          onChangePaginationAlarmResponseJobsSubscriber($event)
        "
      >
      </app-gt-table>
    </nb-tab>
    <nb-tab tabTitle="Roster" *ngIf="miscUsersSubscriber.selectedUser?.id">
      <app-subscriber-roster
        [subscriberId]="subscriberId"
        [subscriberUserId]="miscUsersSubscriber.selectedUser?.id"
        [isAdmin]="true"
      >
      </app-subscriber-roster>
    </nb-tab>
    <nb-tab tabTitle="Checkpoints">
      <div *ngIf="miscCheckpointsSubscriber?.qrToBeValidatedCoun" class="my-3">
        <button
          class="nbbutton-long-text sc-mt-0"
          nbButton
          hero
          status="danger"
          fullWidth
          size="giant"
          (click)="
            miscCheckpointsSubscriber.invalid =
              !miscCheckpointsSubscriber?.invalid;
            validateCheckpointsSubscriber()
          "
        >
          {{
            !miscCheckpointsSubscriber?.invalid
              ? "In valid checkpoints"
              : "Valid checkpoints"
          }}
        </button>
      </div>
      <app-gt-table
        [desktopTableBackendData]="checkPointListSubscriber"
        [desktopTableDetails]="tableViewCheckPointListSubscriber"
        [otherParams]="otherParamsCheckpointsSubscriber"
        (sortColumn)="sortColumnCheckpointsSubscriber($event)"
        (paginationChange)="onChangePaginationCheckpointsSubscriber($event)"
        (rowClick)="onSelectCheckpointsSubscriber($event, checkpointDetailRef)"
      >
      </app-gt-table>
    </nb-tab>
    <nb-tab tabTitle="Patrol History">
      <app-gt-table
        [desktopTableBackendData]="patrolHistoryListSubscriber"
        [desktopTableDetails]="tableViewPatrolHistoryListSubscriber"
        [otherParams]="otherParamsPatrolHistorySubscriber"
        (sortColumn)="sortColumnPatrolHistorySubscriber($event)"
        (paginationChange)="onChangePaginationPatrolHistorySubscriber($event)"
        (searchColumn)="searchColumnPatrolHistorySubscriber($event)"
        (rowClick)="
          onSelectHistoryDataSubscriber($event, selectedEventHistoryRef)
        "
      >
      </app-gt-table>
    </nb-tab>
  </nb-tabset>
</div>

<ng-template
  #selectedEventHistoryRef
  let-data
  let-selectedEventHistoryRef="dialogRef"
>
  <nb-card class="events-details-section">
    <nb-card-header
      >Event
      <nb-icon
        (click)="selectedEventHistoryRef.close()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <div class="row row-cols-2">
      <div class="col">
        <div
          *ngIf="
            [1, 2, 5].includes(
              selectedEventDetailedInfo?.selectedEvent?.event_action
            ) && selectedEventDetailedInfo?.selectedEvent?.detail
          "
          class="mt-2"
        >
          <div class="chat-info">
            <strong>{{
              selectedEventDetailedInfo?.selectedEvent?.action | titlecase
            }}</strong>
            <span>
              | Edited [{{
                getLastCommentEditedDateTime(
                  selectedEventDetailedInfo?.selectedEvent?.history_changes
                )?.updated_at | customdate : "d MMM y HH:mm"
              }}]</span
            >
          </div>
          <div class="chat-data fs-6">
            {{ selectedEventDetailedInfo?.selectedEvent?.detail }}
          </div>
        </div>
        <ng-container>
          <div class="sc-head6">Event Details</div>
          <div class="row row-cols-1 row-cols-xxl-2">
            <ng-container
              *ngFor="let indColumn of tableViewPatrolHistoryListSubscriber"
            >
              <ng-container
                *ngIf="
                  (indColumn.nestedValue
                    ? indColumn.nestedValue(
                        selectedEventDetailedInfo?.selectedEvent
                      )
                    : selectedEventDetailedInfo?.selectedEvent[indColumn.name]
                  )?.trim()
                "
              >
                <div>
                  <span class="badge-key" style="text-transform: uppercase">
                    {{ indColumn?.header }}</span
                  >
                  <ng-container *ngIf="indColumn?.datetimeObj == true">
                    <span class="badge-value">
                      {{
                        indColumn.nestedValue
                          ? (indColumn.nestedValue(
                              selectedEventDetailedInfo?.selectedEvent
                            )
                            | date
                              : (indColumn.dateTimeFormat
                                  ? indColumn.dateTimeFormat
                                  : "d MMM y HH:mm"))
                          : (selectedEventDetailedInfo?.selectedEvent[
                              indColumn.name
                            ]
                            | date
                              : (indColumn.dateTimeFormat
                                  ? indColumn.dateTimeFormat
                                  : "d MMM y HH:mm"))
                      }}
                    </span></ng-container
                  >
                  <ng-container *ngIf="!indColumn?.datetimeObj">
                    <span class="badge-value">
                      {{
                        indColumn.nestedValue
                          ? indColumn.nestedValue(
                              selectedEventDetailedInfo?.selectedEvent
                            )
                          : selectedEventDetailedInfo?.selectedEvent[
                              indColumn.name
                            ] == "null" ||
                            selectedEventDetailedInfo?.selectedEvent[
                              indColumn.name
                            ] == "undefined"
                          ? "N/A"
                          : selectedEventDetailedInfo?.selectedEvent[
                              indColumn.name
                            ]
                      }}
                    </span></ng-container
                  >
                </div>
              </ng-container>
            </ng-container>
            <div
              *ngIf="
                selectedEventDetailedInfo?.selectedEvent?.checkpoint_details
                  ?.min_distance
              "
            >
              <span class="badge-key" style="text-transform: uppercase">
                Geofence Radius (metres)</span
              >
              <span class="badge-value">{{
                selectedEventDetailedInfo?.selectedEvent?.checkpoint_details
                  ?.min_distance | number : "1.0-0"
              }}</span>
            </div>
          </div>
          <div
            *ngIf="selectedEventDetailedInfo.scanDistanceInfo?.scanDistance"
            class="fw-bold text-danger"
          >
            <span class="badge-value">
              Scanned
              {{ selectedEventDetailedInfo.scanDistanceInfo?.scanDistance }}M
              away from the Checkpoint</span
            >
          </div>
        </ng-container>

        <div
          *ngIf="
            selectedEventDetailedInfo?.selectedEvent?.recorded_at &&
            !selectedEventDetailedInfo?.selectedEvent?.delayed_event
          "
          nbTooltip="This event was captured when the user was offline"
        >
          <span class="badge-key" style="color: brown">CAPTURED TIME</span>
          <span class="badge-value strong">
            {{
              selectedEventDetailedInfo?.selectedEvent?.recorded_at
                | customdate : "d MMM y HH:mm"
            }}
          </span>
        </div>
        <ng-container
          *ngIf="selectedEventDetailedInfo?.selectedEvent?.prompts?.length"
        >
          <div
            *ngFor="
              let item of selectedEventDetailedInfo?.selectedEvent?.prompts
            "
          >
            <div class="sc-head6">
              {{ item?.prompt_data?.name ? item?.prompt_data?.name : "Prompt" }}
            </div>

            <div *ngFor="let dataItem of item?.data">
              <ng-template
                *ngTemplateOutlet="promptTemplate; context: { value: dataItem }"
              ></ng-template>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="selectedEventDetailedInfo?.selectedEvent?.device_details"
        >
          <div class="sc-head6">Device Details</div>
          <div class="row row-cols-1 row-cols-xxl-2">
            <div
              *ngFor="
                let item of selectedEventDetailedInfo?.selectedEvent
                  ?.device_details | keyvalue
              "
            >
              <div *ngIf="item?.value">
                <span class="badge-key" style="text-transform: uppercase">
                  {{ item.key }}</span
                >
                <span class="badge-value"> {{ item.value }} </span>
              </div>
            </div>

            <div>
              <span
                class="badge-key"
                nbTooltip="{{browserGpsInfo?.[selectedEventDetailedInfo?.selectedEvent?.gps?.source]?.description}}"
                nbTrigger="focus"
                [ngClass]="browserGpsInfo?.[selectedEventDetailedInfo?.selectedEvent?.gps?.source] ? 'strong' : ''"
                [nbTooltipDisabled]="!browserGpsInfo?.[selectedEventDetailedInfo?.selectedEvent?.gps?.source]"
              >
                LOCATION
              </span>
              <span
                class="badge-value"
                nbTooltip="{{browserGpsInfo?.[selectedEventDetailedInfo?.selectedEvent?.gps?.source]?.description}}"
                nbTrigger="focus"
                [nbTooltipDisabled]="!browserGpsInfo?.[selectedEventDetailedInfo?.selectedEvent?.gps?.source]"
                [ngClass]="browserGpsInfo?.[selectedEventDetailedInfo?.selectedEvent?.gps?.source] ? 'danger-text': ''"
              >
                {{ selectedEventDetailedInfo?.selectedEvent?.gps?.lat }},
                {{ selectedEventDetailedInfo?.selectedEvent?.gps?.lon }}
              </span>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col">
        <div
          [ngClass]="
            selectedEventDetailedInfo?.selectedEvent?.file
              ? 'row row-cols-md-2'
              : 'row row-cols-1'
          "
        >
          <div
            class="col-md-6"
            *ngIf="selectedEventDetailedInfo?.selectedEvent?.file"
          >
            <img
              class="event-image"
              src="{{ selectedEventDetailedInfo?.selectedEvent?.file }}"
              alt="unplash"
            />
          </div>
          <div
            [ngClass]="
              selectedEventDetailedInfo?.selectedEvent?.file
                ? 'col-md-6'
                : 'col'
            "
          >
            <app-map-leaflet
              *ngIf="selectedEventDetailedInfo?.selectedEvent?.gps"
              [latLon]="selectedEventDetailedInfo?.selectedEvent?.gps"
              [scannedCheckpointDetails]="
                selectedEventDetailedInfo?.selectedEvent?.checkpoint_details
              "
              [mapId]="'event-location'"
            ></app-map-leaflet>
            <div class="sc-text-center" style="font-size: small">
              {{ selectedEventDetailedInfo?.selectedEvent?.gps?.lat }},
              {{ selectedEventDetailedInfo?.selectedEvent?.gps?.lon }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </nb-card>
</ng-template>
<!--looped prompt template-->
<ng-template let-dataItem="value" #promptTemplate>
  <div *ngIf="dataItem?.description">
    <span class="badge-key" style="text-transform: uppercase">
      {{ dataItem?.description }}</span
    >
    <!--TODO: convert into pipe-->
    <span class="badge-value"> {{ getPromptValue(dataItem) }} </span>
  </div>

  <div *ngFor="let option of dataItem?.options">
    <ng-template
      *ngTemplateOutlet="promptTemplate; context: { value: option }"
    ></ng-template>
  </div>
</ng-template>

<ng-template #checkpointDetailRef let-checkpointDetailRef="dialogRef">
  <nb-card>
    <nb-card-header
      >Checkpoint
      <nb-icon
        (click)="checkpointDetailRef.close()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <div class="row row-cols-2">
      <!-- DETAILS SECTION -->
      <div class="col col-xl-8">
        <div class="badge-key">Checkpoint Name</div>
        <div class="row">
          <div class="d-flex">
            <div class="flex-grow-1 d-flex align-items-center">
              <img
                *ngIf="
                  miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
                    ?.qr_code
                "
                class="me-3"
                src="assets/images/qr-code-logo.png"
                alt=""
                width="64"
              />
              <div class="sc-head4 pointer">
                {{
                  miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
                    ?.name
                }}

                <nb-icon
                  class="ms-auto text-basic-color"
                  nbSuffix
                  icon="edit-2"
                  *ngIf="
                    miscCheckpointsSubscriber?.miscIncidentsSubscriber
                      ?.detailData?.is_validated
                      ? true
                      : miscCheckpointsSubscriber?.miscIncidentsSubscriber
                          ?.detailData?.address?.state_code
                      ? true
                      : false
                  "
                  pack="eva"
                ></nb-icon>
              </div>
            </div>
            <div class="sc-floating-right">
              <button
                nbButton
                status="danger"
                outline
                title="Delete Checkpoint"
              >
                <nb-icon icon="trash" pack="eva" status="danger"></nb-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="row sc-mt-9">
          <div>
            <span class="badge-key">Client</span>
            <span class="badge-value">{{
              miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
                ?.company
            }}</span>
          </div>
          <div>
            <span class="badge-key">Coordinates</span>
            <span class="badge-value"
              >{{
                miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
                  ?.latitude
              }},
              {{
                miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
                  ?.longitude
              }}</span
            >
          </div>

          <div
            *ngIf="
              !miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
                ?.qr_code ||
              miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
                ?.is_locked
            "
          >
            <span class="badge-key"
              >Geofence Radius (metres)
              <nb-icon
                class="ms-auto"
                nbSuffix
                [icon]="'alert-circle-outline'"
                pack="eva"
                nbTooltip="Checkpoint can only be scanned when within this distance of address"
                nbTrigger="focus"
              ></nb-icon
            ></span>

            <span class="badge-value">{{
              miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
                ?.min_distance | number : "1.0-0"
            }}</span>
          </div>

          <div
            *ngIf="
              miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
                ?.description
            "
          >
            <span class="badge-key">Description</span>
            <span class="badge-value">{{
              miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
                ?.description
            }}</span>
          </div>

          <div
            *ngIf="
              miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
                ?.qr_code
            "
          >
            <span class="badge-key">Checkpoint Value </span>
            <span class="badge-value">{{
              miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
                ?.qr_code
            }}</span>
          </div>
        </div>

        <div
          *ngIf="
            !miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
              ?.user_prompts?.length &&
            miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
              ?.is_validated
          "
        >
          <button nbButton class="giant">Add Post Scan Prompt</button>
        </div>

        <div
          *ngIf="
            miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
              ?.user_prompts?.length
          "
        >
          <span class="badge-key mb-2"
            >Post Scan Prompts
            <nb-icon pack="eva" status="primary" icon="plus-square"></nb-icon
          ></span>
          <span class="badge-value pointer">
            <div
              *ngFor="
                let prompt of miscCheckpointsSubscriber?.detailData
                  ?.user_prompts
              "
              class="p-2"
            >
              {{ prompt?.name }}
            </div>
            <div
              *ngIf="
                !miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
                  ?.user_prompts?.length
              "
            >
              No post scan prompts configured
            </div>
          </span>
        </div>

        <div>
          <div>
            <button
              *ngIf="
                !miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
                  ?.is_validated
              "
              nbButton
              hero
              status="primary"
              size="giant"
              class="nbbutton-long-text btnValidateCheckpoint"
            >
              Validate Checkpoint
            </button>
          </div>
        </div>
      </div>

      <!-- LEAFLET MAP SECTION -->
      <div class="col col-xl-4">
        <app-map-leaflet
          [latLon]="{
            lat: miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
              ?.latitude,
            lon: miscCheckpointsSubscriber?.miscIncidentsSubscriber?.detailData
              ?.longitude
          }"
        ></app-map-leaflet>
      </div>
    </div>
    <div>
      <!-- history section start -->
      <nb-tabset>
        <nb-tab tabTitle="Scan History">
          <div *ngIf="miscCheckpointsSubscriber?.scanDetails?.length">
            <div>
              <app-table
                [desktopTableData]="miscCheckpointsSubscriber?.scanDetails"
                [desktopTableDetails]="tableViewPatrolHistoryListSubscriber"
                (rowClick)="
                  onSelectHistoryDataSubscriber($event, selectedEventHistoryRef)
                "
              ></app-table>
            </div>
          </div>
          <div
            *ngIf="miscCheckpointsSubscriber?.scanDetails?.length < 1"
            class="gt-p-1"
          >
            <nb-alert outline="basic" style="font-weight: 500"
              >No scans recorded for this checkpoint</nb-alert
            >
          </div>
        </nb-tab>
        <nb-tab tabTitle="Changes">
          <div class="sc-mt-6">
            <app-table
              [desktopTableData]="miscCheckpointsSubscriber?.history"
              [desktopTableDetails]="
                miscCheckpointsSubscriber.checkPointHistoryTable
              "
              (rowClick)="
                onSelectHistoryDataSubscriber($event, selectedEventHistoryRef)
              "
            ></app-table>
          </div>
        </nb-tab>
      </nb-tabset>
    </div>
  </nb-card>
</ng-template>

<ng-template #incidentDetailRef let-incidentDetailRef="dialogRef">
  <nb-card>
    <nb-card-header
      >Incident
      <nb-icon
        (click)="incidentDetailRef.close()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <div class="large-view-only gt-p-1">
      <div
        *ngIf="!miscIncidentsSubscriber?.isIncidentSubmitted"
        class="strong mb-3 sc-head3"
      >
        Incident Report
        <span class="ms-3"> </span>
      </div>

      <ng-container>
        <div>
          <div
            class="row"
            *ngIf="
              miscIncidentsSubscriber?.isIncidentSubmitted &&
              (isAdmin || isDispatchUser)
            "
          >
            <div class="col my-0 col-md-12 col-lg-5 col-xxl-3">
              <button
                class="nbbutton-long-text"
                nbButton
                fullWidth
                size="large"
                status="primary"
                hero
              >
                Resend Incident Report
              </button>
            </div>
          </div>

          <div class="row row-cols-md-1 row-cols-lg-2 row-cols-xxl-3 gx-3">
            <!-- FIRST SECTION -->
            <div class="col">
              <div
                class="incident-details-section"
                *ngIf="miscIncidentsSubscriber?.detailData?.resolution_time"
              >
                <span class="badge-key">Report Sent</span>
                <span class="badge-value strong"
                  >{{
                    miscIncidentsSubscriber?.detailData?.resolution_time
                      | customdate : "d MMM y HH:mm"
                  }}
                </span>
              </div>

              <div class="incident-details-section">
                <span class="badge-key">Incident Type </span>
                <span class="badge-value strong">{{
                  miscIncidentsSubscriber?.detailData?.incident_type
                }}</span>
              </div>

              <div class="incident-details-section">
                <span class="badge-key">Site Name</span>
                <span class="badge-value strong">{{
                  miscIncidentsSubscriber?.detailData?.site?.company_name
                }}</span>
              </div>
              <div class="incident-details-section">
                <span class="badge-key">Address</span>
                <span class="badge-value">
                  {{
                    miscIncidentsSubscriber?.detailData?.site?.address
                      ?.full_address
                  }}
                </span>
              </div>
            </div>

            <!-- SECOND SECTION -->
            <div class="col">
              <div
                *ngIf="
                  miscIncidentsSubscriber?.isIncidentSubmitted &&
                  miscIncidentsSubscriber?.detailData?.resolution_time
                "
                class="incident-details-section"
              >
                <span class="badge-key">Recipient</span>
                <span class="badge-value">{{
                  miscIncidentsSubscriber?.emailRecipients
                }}</span>
              </div>

              <div class="incident-details-section">
                <span class="badge-key pointer"
                  >Client
                  <nb-icon
                    class="ms-3"
                    nbSuffix
                    icon="link-2-outline"
                    pack="eva"
                  ></nb-icon>
                </span>
                <span class="badge-value strong">{{
                  miscIncidentsSubscriber?.detailData?.company?.company_name
                }}</span>
              </div>

              <div class="incident-details-section">
                <span class="badge-key">Reference No.</span>
                <span class="badge-value">{{
                  miscIncidentsSubscriber?.detailData?.incident_key
                }}</span>
              </div>

              <div>
                <span class="badge-key"
                  >Incident Time
                  <nb-icon
                    *ngIf="
                      miscIncidentsSubscriber?.isCreatedUser &&
                      !miscIncidentsSubscriber?.isIncidentSubmitted
                    "
                    icon="edit-2"
                    class="color-basic-text ms-1"
                    pack="eva"
                  ></nb-icon>
                </span>
                <span class="badge-value"
                  >{{
                    miscIncidentsSubscriber?.detailData?.incident_time
                      | customdate : "d MMM y HH:mm"
                  }}
                </span>
              </div>
            </div>

            <!-- THIRD SECTION -->
            <div class="col">
              <div
                *ngIf="
                  !miscIncidentsSubscriber?.detailData?.information &&
                  miscIncidentsSubscriber?.isCreatedUser &&
                  !miscIncidentsSubscriber?.isIncidentSubmitted
                "
                class="my-2"
              >
                <button
                  nbButton
                  fullWidth
                  size="giant"
                  class="nbbutton-long-text"
                  status="primary"
                >
                  Details
                </button>
              </div>

              <div *ngIf="miscIncidentsSubscriber?.detailData?.information">
                <div
                  class="badge-key"
                  [ngClass]="
                    miscIncidentsSubscriber?.isCreatedUser &&
                    !miscIncidentsSubscriber?.isIncidentSubmitted
                      ? 'strong'
                      : ''
                  "
                >
                  Details
                  <nb-icon
                    *ngIf="
                      miscIncidentsSubscriber?.isCreatedUser &&
                      !miscIncidentsSubscriber?.isIncidentSubmitted
                    "
                    icon="edit-2"
                    class="color-basic-text ms-1"
                    pack="eva"
                  ></nb-icon>
                </div>
                <div class="badge-value">
                  {{ miscIncidentsSubscriber?.detailData?.information }}
                </div>
              </div>

              <div
                *ngIf="miscIncidentsSubscriber?.detailData?.corrective_actions"
              >
                <div
                  class="badge-key"
                  [ngClass]="
                    miscIncidentsSubscriber?.isCreatedUser &&
                    !miscIncidentsSubscriber?.isIncidentSubmitted
                      ? 'strong'
                      : ''
                  "
                >
                  Resolution
                  <nb-icon
                    *ngIf="
                      miscIncidentsSubscriber?.isCreatedUser &&
                      !miscIncidentsSubscriber?.isIncidentSubmitted
                    "
                    icon="edit-2"
                    class="color-basic-text ms-1"
                    pack="eva"
                  ></nb-icon>
                </div>
                <div class="badge-value">
                  {{ miscIncidentsSubscriber?.detailData?.corrective_actions }}
                </div>
              </div>

              <div
                *ngIf="
                  miscIncidentsSubscriber?.isCreatedUser &&
                  !miscIncidentsSubscriber?.isIncidentSubmitted
                "
              >
                <div class="my-2">
                  <button
                    nbButton
                    fullWidth
                    size="giant"
                    status="primary"
                    hero
                    class="nbbutton-long-text mb-0"
                  >
                    Add Photo
                  </button>
                </div>

                <div
                  *ngIf="
                    !miscIncidentsSubscriber?.detailData?.corrective_actions
                  "
                  class="my-2"
                >
                  <button
                    nbButton
                    fullWidth
                    size="giant"
                    class="nbbutton-long-text mb-0"
                    status="primary"
                  >
                    Resolution
                  </button>
                </div>
              </div>

              <div
                *ngIf="
                  !miscIncidentsSubscriber?.isIncidentSubmitted &&
                  (miscIncidentsSubscriber?.isCreatedUser ||
                    isAdmin ||
                    isDispatchUser)
                "
              >
                <div class="my-2">
                  <button nbButton fullWidth size="giant" status="primary" hero>
                    Send Incident Report
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- INCIDENT PHOTOS -->
          <div class="row row-cols-md-1 row-cols-lg-2 row-cols-xxl-3">
            <ng-container
              *ngIf="miscIncidentsSubscriber?.incidentCommentFiles?.length"
            >
              <div
                *ngFor="
                  let msg of miscIncidentsSubscriber?.incidentCommentFiles;
                  let i = index
                "
                class="col p-3 border"
              >
                <!-- Timestamp -->
                <div class="incident-photo-timestamp sc-floating-right">
                  {{ msg?.updated_at | customdate : "d MMM y HH:mm" }}
                </div>
                <!-- Image ID -->

                <div class="incident-photo-timestamp">
                  Photo
                  {{
                    msg?.event_action === 11 ? "[Device Library]" : "[Camera]"
                  }}: #{{ i + 1 }}
                </div>
                <!-- Image -->
                <img
                  *ngIf="msg?.file"
                  src="{{ msg?.file }}"
                  height="auto"
                  width="100%"
                  alt="Photo {{ i }}"
                  nbTooltip="{{ msg?.detail }}"
                  [nbTooltipDisabled]="
                    !msg?.detail ||
                    msg?.detail == 'null' ||
                    msg?.detail == 'undefined'
                  "
                />

                <!-- Comment -->
                <div
                  *ngIf="
                    msg?.detail &&
                    (msg?.detail != 'null' || msg?.detail != 'undefined')
                  "
                  class="incident-photo-comment-ellipsis mb-3"
                >
                  {{ msg?.detail }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </nb-card>
</ng-template>
