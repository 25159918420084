import { Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  NbDialogRef,
  NbDialogService,
  NbStepperComponent,
  NbToastrService,
} from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { CustomerService } from 'src/app/pages/customer/customer.service';
import { JobsService } from 'src/app/pages/jobs/jobs.service';
import { PagesService } from 'src/app/pages/pages.service';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { AddCustomerComponent } from '../add-customer/add-customer.component';
import { SaveNewAddressComponent } from '../save-new-address/save-new-address.component';

@Component({
  selector: 'app-add-edit-site',
  templateUrl: './add-edit-site.component.html',
  styleUrls: ['./add-edit-site.component.scss'],
})
export class AddEditSiteComponent implements OnInit {
  @Input() detailData: any;
  @Input() siteAddress: any;

  @Input() editSite: any;
  @Input() showFrom: any;
  @Input() siteName: boolean = true;
  @ViewChild('stepper1') stepper1: any = NbStepperComponent;
  siteForm: UntypedFormGroup = new UntypedFormGroup({
    addressLookup: new UntypedFormControl(''),
    company: new UntypedFormControl(null, Validators.required),
    company_name: new UntypedFormControl(),
    address1: new UntypedFormControl('', [Validators.required]),
    address2: new UntypedFormControl('', [Validators.required]),
    address3: new UntypedFormControl(''),
    city_name: new UntypedFormControl('', [Validators.required]),
    postcode: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.max(99999999)])
    ),
    longitude: new UntypedFormControl('', [Validators.required]),
    latitude: new UntypedFormControl('', [Validators.required]),
    country_name: new UntypedFormControl('Australia'),
    state_code: new UntypedFormControl(''),
  });
  searchControl: FormControl = new FormControl();
  addressValue: any;
  addressObjectValue: any;
  addrLookupData$: Observable<any> | undefined;
  stateData: any = [];
  firstAddress: any;
  latLon = { lat: 0, lon: 0 };
  customerList: any;
  clientTypedValue: any;
  dialogref: any;
  notGetExactAddress: boolean = false;
  lastApiResponseHadData: boolean = true;
  lastSearchStr: any;
  apiCallMade: boolean = false;
  selectedState: any;
  constructor(
    @Optional() protected dialogRef: NbDialogRef<AddEditSiteComponent>,
    private customerService: CustomerService,
    private spinnerService: NgxSpinnerService,
    private profileService: ProfileService,
    private appService: AppService,
    private pageService: PagesService,
    private dialogService: NbDialogService,
    private jobService: JobsService
  ) {
    this.searchControl.valueChanges.subscribe((data: any) => {
      if (typeof data === 'object') {
        this.searchControl.setValue(data.full_address);
      }
    });
  }

  ngOnInit(): void {
    if (this.showFrom === 'addJob') {
      this.getClientList();
    }

    this.formatSiteData(this.detailData);

    this.getStates(1);
    if (this.siteAddress) this.addressValue = this.siteAddress;
    if (this.showFrom === 'addJob') {
      this.shiftFocus('clientInputField');
    } else {
      this.shiftFocus('addressLookupField');
    }
  }
  getStates(countryId: any) {
    this.appService.getStates(+countryId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateData = response['data'];
      }
    });
  }

  onAddressSearch(addressSearch: any) {
    if (addressSearch?.target?.value?.length > 2) {
      if (this.lastSearchStr) {
        !this.searchControl.value.includes(this.lastSearchStr)
          ? (this.apiCallMade = true)
          : (this.apiCallMade = false);
      } else {
        this.apiCallMade = true;
      }
      if (this.apiCallMade || this.lastApiResponseHadData) {
        this.profileService
          .addressLookup(addressSearch.target.value)
          .subscribe((res: any) => {
            this.addrLookupData$ = of(res['data']);
            this.firstAddress = res['data']?.[0];
            this.addrLookupData$.subscribe((result: any) => {
              this.lastApiResponseHadData = result.length > 0;
            });
            this.lastSearchStr = this.searchControl.value;
          });
      }
    }
  }
  formatSiteData(data: any) {
    this.detailData = data;
    this.siteForm.patchValue(this.detailData);
    this.siteForm.controls['address1'].setValue(
      this.detailData?.address?.address1
    );
    this.siteForm.controls['address2'].setValue(
      this.detailData?.address?.address2
    );
    this.siteForm.controls['address3'].setValue(
      this.detailData?.address?.address3
    );
    this.siteForm.controls['city_name'].setValue(
      this.detailData?.address?.city_name
    );
    this.siteForm.controls['country_name'].setValue(
      this.detailData?.address?.country_name
    );
    this.siteForm.controls['postcode'].setValue(
      this.detailData?.address?.postcode
    );
    this.siteForm.controls['state_code'].setValue(
      this.detailData?.address?.state_code
    );
    this.siteForm.controls['latitude'].setValue(
      this.detailData?.address?.latitude
    );
    this.siteForm.controls['longitude'].setValue(
      this.detailData?.address?.longitude
    );
    this.siteForm.controls['company_name'].enable();
  }
  onCloseDialogue(data: any = 'close') {
    if (data != 'close') {
      data.selectedClient = this.selectedClient;
    }

    this.dialogRef.close(data);
  }
  addressSelected(address: any, addressFrom?: any) {
    if (address === ' ') {
      this.addPlace();
    } else if (address && typeof address === 'object') {
      this.addrLookupData$ = undefined;

      this.addressValue = address?.address1 + ' ' + address?.address2;
      this.addressObjectValue = address;
      this.siteForm.patchValue({
        address1: address?.address1,
        address2: address?.address2,
        address3: address?.address3,
        city_name: address?.city_name,
        postcode: address?.postcode,
        state_code: address?.state_code,
        country_name: address?.country_name,
        latitude: address?.latitude,
        longitude: address?.longitude,
        company_name:
          address?.address1 +
          ' ' +
          address?.address2 +
          ' ' +
          address?.city_name,
      });
      if (addressFrom === 'newAddress') {
        this.notGetExactAddress = true;
      }
      var element = <HTMLInputElement>document.getElementById('submitbtn');
      element.disabled = false;
      element.focus();
      this.stepper1.next();
    }
  }
  updateData() {
    this.spinnerService.show();
    if (this.siteForm.valid) {
      var requestData = { data: this.siteForm.getRawValue() };

      if (this.detailData?.id) {
        this.spinnerService.show();
        this.customerService
          .updateSiteById(requestData, this.detailData?.id)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this.onCloseDialogue(response['data']);
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.spinnerService.hide();
          });
      } else {
        this.spinnerService.show();
        this.customerService
          .addSiteInClient(requestData)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this.onCloseDialogue(response['data']);
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.spinnerService.hide();
          });
      }
    }
  }
  addPlace() {
    const dialogRef = this.dialogService.open(SaveNewAddressComponent, {
      dialogClass: 'model-full',
      context: {},
    });
    dialogRef.onClose.subscribe((value) => {
      if (value != 'close') {
        if (value) {
          this.addressSelected(value, 'newAddress');
          if (this.searchControl.value === ' ') {
            this.searchControl.setValue(
              value?.address1 +
                ' ' +
                value?.address2 +
                ' ' +
                value?.city_name +
                ' ' +
                value?.state_code +
                ' ' +
                value?.postcode
            );
          }
        }
      } else {
        this.searchControl.setValue('');
      }
    });
  }
  forceUppercaseConditionally(event: any) {
    this.siteForm.controls['city_name'].setValue(
      event.target.value.toUpperCase()
    );
  }
  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    });
  }
  getClientList() {
    this.customerService.fetchCustomerList().subscribe((response: any) => {
      if (response['status'] === 'success') {
        this.customerList = response['data'];
      }
    });
  }
  selectedClient: any;
  onClientSearch(event: any) {
    this.clientTypedValue = event.target.value;
    this.jobService
      .getSearchClients(this.clientTypedValue)
      .subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.customerList = res['data'];
        }
      });
  }
  onClientSelect(data: any) {
    if (data === ' ') {
      this.openAddCustomerForm();
    }
    if (data?.id) {
      this.siteForm.controls['company_name'].setValue(data?.company_name);
      this.siteForm.controls['company'].setValue(data?.id);
      this.selectedClient = data?.company_name;
      var element = <HTMLInputElement>(
        document.getElementById('clientInputField')
      );
      element.disabled = false;
      element.blur();
    }
  }
  openAddCustomerForm() {
    this.dialogref = this.dialogService.open(AddCustomerComponent, {
      context: { customerName: this.clientTypedValue },
      dialogClass: 'model-full',
    });

    this.dialogref.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.onClientSelect(value?.data);
        // this.value = value?.data?.company_name;
      }
    });
  }
}
