import { formatDate } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { PagesService } from '../../pages.service';
import { AdminService } from '../admin.service';
import { formatDateTimeStamp } from 'src/global.variables';

@Component({
  selector: 'app-release',
  templateUrl: './release.component.html',
  styleUrls: ['./release.component.scss'],
})
export class ReleaseComponent implements OnInit {
  // management cmd variables
  customManagementCmdData: any;
  perReleaseManagementCmdData: any;
  gitHistoryData: any = [];
  paginatedGitHistoryData: any = [];
  gitHistoryTable = [
    { header: 'Commit Id', name: 'commit_hash' },
    {
      header: 'Commit Time',
      name: 'commit_time',
      datetimeObj: true,
      dateRangeKey: 'commit_time',
      sortKey: 'commit_time',
    },
    {
      header: 'Last Updated',

      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
      nestedValue: (row: any) => {
        return `${String(
          formatDateTimeStamp(row?.updated_at, 'd MMM y HH:mm', 'en_US')
        )} by ${row?.updated_by}`;
      },
    },
    { header: 'Stack Type', name: 'stack_name', searchKey: 'stack_name' },
    {
      header: 'Noti. Sent',
      nestedValue: (row: any) => {
        return row?.management_command
          ? `${String(
              formatDateTimeStamp(row?.updated_at, 'd MMM y HH:mm', 'en_US')
            )} by ${row?.updated_by}`
          : '';
      },
    },
    { header: 'Version', name: 'version' },
  ];
  managementCmdTable = [
    { header: 'Title', name: 'title' },
    { header: 'Description', name: 'description' },
  ];
  dialogRef: any;
  managementTabs = {
    tab1: 'One Time Per Release',
    tab2: 'Custom',
  };
  selectedGitVersionData: any;
  selectedCommitMessages: any;
  newChangeLog: any;
  versionForm: UntypedFormGroup = new UntypedFormGroup({
    major: new UntypedFormControl('', Validators.required),
    minor: new UntypedFormControl('', Validators.required),
    patch: new UntypedFormControl('', Validators.required),
  });

  previousReleases: number = 0;
  rowsReleases: number = 10;
  pageNumReleases: number = 1;
  totalPagesReleases: number = 0;
  totalRowsReleases: number = 0;
  constructor(
    private adminService: AdminService,
    private appService: AppService,
    private dialogService: NbDialogService,
    private spinnerService: NgxSpinnerService,
    private pageService: PagesService
  ) {
    this.getManagementCommands();
  }

  ngOnInit(): void {}
  formatGitHistory() {
    this.paginatedGitHistoryData = this.gitHistoryData?.slice(
      0,
      this.rowsReleases
    );
    this.totalRowsReleases = this.gitHistoryData?.length;
    this.totalPagesReleases = Math.ceil(
      this.totalRowsReleases / this.rowsReleases
    );
  }
  getManagementCommands() {
    this.spinnerService.show();
    this.adminService.getManagementCmds().subscribe((response: any) => {
      this.gitHistoryData = response?.data;
      this.formatGitHistory();
      this.spinnerService.hide();
    });
  }
  managementRowClick(rowData: any, custom = true) {
    this.dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        confirmation: true,
        title: `Are you sure ?`,
        message: `Calling this '${rowData?.title}' fucntion will make irreversible changes to the database  `,
      },
    });
    let params: any = {};
    let body: any = {};
    if (custom) {
      params.management_commands = 1;
      body.management_cmd = rowData?.name;
    } else {
      body = {
        management_cmd: rowData?.name,
        u_id: rowData?.u_id,
        version_id: this.selectedGitVersionData?.id,
      };
    }
    this.dialogRef.onClose.subscribe((value: any) => {
      if (value === 'Yes') {
        this.spinnerService.show();
        this.adminService
          .callManagementCmd(body, params)

          .subscribe((res: any) => {
            if (res['status'] === 'success') {
              this.pageService.setMessage({
                successMessage: res['message'],
                errorMessage: '',
              });
              if (!custom) {
                this.selectedGitVersionData = null;
              }
              this.getManagementCommands();
              this.spinnerService.hide();
            } else {
              this.pageService.setMessage({
                errorMessage: res['message'],
                successMessage: '',
              });
            }
            this.spinnerService.hide();
          });
      }
    });
  }
  changeTab(event: any) {
    if (event.tabTitle === this.managementTabs.tab2) {
      this.adminService
        .getManagementCmds({ management_commands: 1 })
        .subscribe((response: any) => {
          this.customManagementCmdData = response?.data;
        });
    }
  }
  gitVersionClick(event: any) {
    this.selectedGitVersionData = event;
    this.selectedCommitMessages = this.selectedGitVersionData?.commit_messages;

    this.spinnerService.show();
    this.adminService
      .getManagementCmds({
        once_release_commands: 1,
        version_id: this.selectedGitVersionData?.id,
      })
      .subscribe((response: any) => {
        this.perReleaseManagementCmdData = response?.data;

        this.spinnerService.hide();
      });
  }
  udpateGitHistory(body?: any, params?: any) {
    this.spinnerService.show();
    this.adminService
      .editGitHistory(this.selectedGitVersionData?.id, body, params)

      .subscribe((res: any) => {
        if (res['status'] === 'success') {
          this.newChangeLog = null;
          this.selectedGitVersionData = null;
          this.gitHistoryData = res?.data;
          this.formatGitHistory();
          this.pageService.setMessage({
            successMessage: res['message'],
            errorMessage: '',
          });
          this.spinnerService.hide();
        } else {
          this.pageService.setMessage({
            errorMessage: res['message'],
            successMessage: '',
          });
        }
        this.spinnerService.hide();
      });
  }
  updateChangeLog() {
    if (this.selectedGitVersionData?.id && this.newChangeLog) {
      this.udpateGitHistory({
        change_log: this.newChangeLog,
      });
    }
  }
  getLocalTime(epochTime: any) {
    var date = new Date(epochTime * 1000);
    return date.toLocaleString();
  }
  splitVersion() {
    let versionValues = this.selectedGitVersionData?.version.split('.');

    if (versionValues?.[0]) {
      this.versionForm.controls['major'].setValue(versionValues?.[0]);
    }
    if (versionValues?.[1]) {
      this.versionForm.controls['minor'].setValue(versionValues?.[1]);
    }
    if (versionValues?.[2]) {
      this.versionForm.controls['patch'].setValue(versionValues?.[2]);
    }
  }
  openTemplate(template: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(template, {
      context: {},
    });
  }
  updateVersion() {
    if (this.selectedGitVersionData?.id && this.versionForm?.valid) {
      let newVersion = Object.values(this.versionForm.value).join('.');

      this.udpateGitHistory(
        {
          new_version: newVersion,
        },
        { version_change: 1 }
      );
    }
  }
  onClickReleasePagination(event: any) {
    this.previousReleases = event.previous;

    this.pageNumReleases = event.pageNum;
    this.rowsReleases = event.pageSize;
    this.paginatedGitHistoryData = this.gitHistoryData?.slice(
      this.previousReleases,
      this.rowsReleases * this.pageNumReleases
    );
  }
  onChangeReleasePagination(event: any) {
    this.previousReleases = event.previous;

    this.pageNumReleases = event.pageNum;
    this.rowsReleases = event.pageSize;

    this.paginatedGitHistoryData = this.gitHistoryData?.slice(
      this.previousReleases,
      this.rowsReleases * this.pageNumReleases
    );
  }
}
