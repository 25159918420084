import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { QrCodeComponent } from 'src/app/shared/comp/qr-code/qr-code.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { PagesService } from '../pages.service';
import { ProfileService } from '../profile/profile.service';
import { CheckpointService } from './checkpoint.service';

@Component({
  selector: 'app-checkpoint-and-routes',
  templateUrl: './checkpoint.component.html',
  styleUrls: ['./checkpoint.component.scss'],
})
export class CheckpointComponent implements OnInit {
  viewType: string = 'tableView';
  checkPointList: any;

  totalRows: number = 0;
  previous: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;

  globalFilter: any;
  showTypeAhead: boolean = true;
  showSearchBox: boolean = false;

  tabNames = {
    tab1: 'Checkpoints',
    tab2: 'QR Codes',
    tab3: 'Changes',
  };
  qrCodePage: boolean = false;
  // pagination variables for CP events

  tableStyle = {
    'overflow': 'auto',
    'max-height': '600px',
  };

  chekPointDetailsTableView: any = [
    {
      header: 'Checkpoint Name',
      name: 'name',

      sortKey: 'name',
      toolTipData: (row: any) => {
        return row?.scanned_count
          ? `${row?.scanned_count} scans till now.`
          : 'No scans yet.';
      },
    },
    {
      header: 'Client',
      name: 'company',
      toolTipData: (row: any) => {
        return row?.update_count
          ? `${row?.update_count} updates till now.`
          : 'No updates yet.';
      },
      sortKey: 'company__company_name',
      searchKey: 'company__company_name',
    },
    {
      header: '',

      nestedValue: (row: any) => {
        return row?.qr_code ? 'QR' : '';
      },
      sortKey: 'qr_code',
    },

    {
      header: 'Location',
      name: 'partial_address',
      searchKey: 'partial_address',
    },
  ];

  clientId: any;

  mapLatLong: any;
  selectedCp: any;
  sortKey: any;
  searchParams: any;
  userData: any;
  userGroup: any;
  isDispatchUser: boolean = false;
  isAdmin: any;
  paginationChange: boolean = false;

  validateQR: boolean = false;
  lastApiResponseHadData: boolean = true;
  lastSearchStr: any;
  apiCallMade: boolean = false;

  dialogref: any;

  qrToBeValidatedCount: number = 0;

  constructor(
    private checkpointService: CheckpointService,
    private spinnerService: NgxSpinnerService,
    private toasterService: NbToastrService,
    private dialogService: NbDialogService,
    private route: ActivatedRoute,
    private pageService: PagesService,
    private appService: AppService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private profileService: ProfileService
  ) {
    this.route.params.subscribe({
      next: (params: any) => {
        if (this.route.snapshot.fragment) {
          if (this.route.snapshot.fragment === 'validateQr') {
            this.validateQR = true;
          }
        }
      },
    });
  }

  ngOnInit(): void {
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.userData = this.appService.getUserData();
    let cpListViewType = this.userData?.preferences?.cpListViewType;
    if (window.innerWidth >= 770) {
      this.viewType = cpListViewType?.desktopView || 'tableView';
    } else {
      this.viewType = cpListViewType?.mobileView || 'cardView';
    }
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    if (this.clientId && (this.isAdmin || this.isDispatchUser)) {
      this.getCheckPoints(this.clientId);
    } else {
      if (this.isAdmin || this.isDispatchUser) {
        this.getCheckPoints();
      }
    }
  }

  getCheckPoints(clientId?: any) {
    let params: any = {};
    if (this.rows) {
      params['rows'] = this.rows;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    if (this.globalFilter) {
      params['name'] = this.globalFilter;
    }
    if (this.sortKey) {
      params['sort_key'] = this.sortKey;
    }
    if (this.validateQR) {
      params['is_invalid'] = 1;
    }
    if (this.searchParams) {
      Object.entries(this.searchParams).forEach(
        ([key, value]) => (params[key] = value)
      );
    }
    if (clientId) params['company_id'] = clientId;
    this.checkpointService.getCheckpoints(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.checkPointList = response['data'];
        this.lastApiResponseHadData = this.checkPointList.length > 0;
        this.lastSearchStr = this.globalFilter;
        this.totalRows = response['total_size'];
        this.qrToBeValidatedCount = response?.validate_qr_count;
        this.paginationChange = false;

        this.totalPages = Math.ceil(this.totalRows / this.rows);
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }

  addCheckpoints() {
    if (this.clientId) {
      this.router.navigate(
        [
          '/add-checkpoint',
          {
            clientId: this.clientId,
            clientName: this.checkPointList[0]?.company,
          },
        ],
        { relativeTo: this.route }
      );
    } else {
      this.router.navigate(['/add-checkpoint'], { relativeTo: this.route });
    }
  }
  deleteCheckPoint(event: any) {
    let dialogMsg = `WARNING: This action cannot be reversed.`;
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: `Delete Checkpoint ${event?.name}`,
        message: dialogMsg,
        alertDanger: true,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.checkpointService
          .deleteCheckpointById(event.id)
          .subscribe((response: any) => {
            this.toasterService.show('', response['message']);
            let subscriberStats = JSON.parse(
              localStorage.getItem('subscriberStats') || '{}'
            );
            subscriberStats.checkpoint_count =
              (subscriberStats?.checkpoint_count || 0) - 1;
            localStorage.setItem(
              'subscriberStats',
              JSON.stringify(subscriberStats)
            );
            this.getCheckPoints();
          });
      }
    });
  }

  onFilterApply(event: any) {
    this.showSearchBox = true;
    if (event?.target?.value?.length === 0) {
      this.showTypeAhead = true;
      this.globalFilter = '';
      this.getCheckPoints();
    }
    if (event?.target?.value && event?.target?.value?.length >= 3) {
      this.previous = 0;
      this.pageNum = 1;
      if (this.lastSearchStr) {
        !this.globalFilter.includes(this.lastSearchStr)
          ? (this.apiCallMade = true)
          : (this.apiCallMade = false);
      } else {
        this.apiCallMade = true;
      }
      if (this.apiCallMade || this.lastApiResponseHadData) {
        this.getCheckPoints();

        this.showTypeAhead = false;
      }
    }
  }

  onClearFilter(filterType?: string) {
    this.showSearchBox = false;
    this.globalFilter = '';
    this.showTypeAhead = true;
    this.previous = 0;
    this.pageNum = 1;
    this.getCheckPoints();
  }
  redirectCompany(cp: any) {
    this.router.navigate(['/view-client', { cKey: String(cp?.company_id) }]);
  }
  onCheckpointRedirect(event: any) {
    this.router.navigate(['/view-checkpoints', { cpKey: String(event?.id) }]);
  }

  onCheckPointClick(event: any) {
    this.mapLatLong = {
      lat: event?.latitude,
      lon: event?.longitude,
      geoFenceRadius: event?.min_distance,
    };
    this.selectedCp = event;
  }

  onClickPagination(event: any) {
    this.paginationChange = true;
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getCheckPoints();
  }
  onChangePagination(event: any) {
    this.paginationChange = true;
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getCheckPoints();
  }

  sortColumn(body: any) {
    this.sortKey = body?.sortList;
    this.searchParams = body?.searchParams || {};
    this.getCheckPoints();
  }

  searchColumn(event: any) {
    this.searchParams = event?.searchParams || {};
    this.paginationChange = true;
    this.getCheckPoints();
  }
  switchMobileView() {
    this.viewType == 'cardView'
      ? (this.viewType = 'mapView')
      : (this.viewType = 'cardView');
    this.updatePreferences();
  }
  switchLargeView() {
    this.viewType == 'tableView'
      ? (this.viewType = 'mapView')
      : (this.viewType = 'tableView');
    this.updatePreferences();
  }

  updatePreferences() {
    let body: any = {};
    let cpListViewType = this.userData?.preferences?.cpListViewType;

    if (window.innerWidth >= 770) {
      body = {
        desktopView: this.viewType,
        mobileView: cpListViewType?.mobileView || 'cardView',
      };
    } else {
      body = {
        mobileView: this.viewType,
        desktopView: cpListViewType?.desktopView || 'tableView',
      };
    }
    this.profileService
      .updateUser({ data: { cpListViewType: body } }, { save_preference: 1 })
      .subscribe((response: any) => {});

    let userData = this.appService.getUserData();
    if (userData?.preferences) {
      userData.preferences.cpListViewType = body;
      this.appService.setUserData(userData);
    }
  }
  changeTab(event: any) {
    this.qrCodePage = false;
    if (event.tabTitle === this.tabNames.tab2) {
      this.qrCodePage = true;
    }
  }
  openQRCode() {
    this.dialogref = this.dialogService.open(QrCodeComponent, {
      context: {},
    });
    this.dialogref.onClose.subscribe((value: any) => {
      if (value === true) {
        this.getCheckPoints();
        this.router.navigate(['/dashboard']);
      }
    });
  }
  reloadPage() {
    this.validateQR = false;
    this.router.navigate(['/checkpoints']);
    this.getCheckPoints();
  }
  validateCheckpoints() {
    this.validateQR = true;
    this.router.navigate(['/checkpoints']);
    this.getCheckPoints();
  }
}
