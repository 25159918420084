import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination-control',
  templateUrl: './pagination-control.component.html',
  styleUrls: ['./pagination-control.component.scss'],
})
export class PaginationControlComponent implements OnInit {
  @Input() pageSize: any; //How many entries the page will show
  @Input() pageNum: any; //In which page the user is now
  @Input() previous: any; //How many entries we have to laeave or in previous page
  @Input() totalRows: any; // Total data in the backend
  @Input() totalPages: any; // Calculate the pages i.e how many pages will be there

  @Output() onClickPagination = new EventEmitter(); // On click next or prev button
  @Output() onChangePagination = new EventEmitter(); //On change the size of page from dropdown

  paginationValues = [5, 10, 15, 20, 25];
  constructor() {}

  ngOnInit(): void {
    this.totalPages = Math.ceil(this.totalRows / this.pageSize);
  }

  onClickNext() {
    this.onClickPagination.emit({
      previous: this.previous,
      pageNum: this.pageNum,
      pageSize: this.pageSize,
    });
  }
  onClickPrev() {
    this.onClickPagination.emit({
      previous: Number(this.previous),
      pageNum: Number(this.pageNum),
      pageSize: Number(this.pageSize),
    });
  }
  onChangePaginationDropdown() {
    this.onChangePagination.emit({
      previous: 0,
      pageNum: 1,
      pageSize: Number(this.pageSize),
    });
    this.totalPages = Math.ceil(this.totalRows / this.pageSize);
  }
}
