<nb-card>
  <!-- MOBILE SECTION -->
  <div class="strong mb-3 ms-3">
    Add Job
    <span class="pointer">
      {{ selectedStatus?.name ? " > " + selectedStatus?.name : "" }}</span
    >
  </div>

  <div>
    <div
      class="row row-cols-md-3 row-cols-xxl-4 gy-2 gt-p-1 mx-0"
      *ngIf="!selectedStatus?.name"
    >
      <div *ngFor="let jobType of jobTypes">
        <button
          nbButton
          (click)="changeJobType(jobType)"
          fullWidth
          size="giant"
        >
          {{ jobType?.name }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="selectedStatus?.name">
    <!-- Other Job Type Section -->
    <ng-container *ngIf="![0, 4].includes(selectedStatus?.type_identity)">
      <app-other [selectedStatus]="selectedStatus"></app-other
    ></ng-container>
    <!-- Response Job Type Section -->
    <ng-container *ngIf="selectedStatus?.type_identity === 0">
      <app-response [selectedStatus]="selectedStatus"> </app-response>
    </ng-container>
    <!-- Patrol Job Type Section -->
    <ng-container *ngIf="selectedStatus?.type_identity === 4">
      <app-patrol [selectedStatus]="selectedStatus"> </app-patrol>
    </ng-container>
  </div>
</nb-card>
