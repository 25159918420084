<div class="background-color mt-1">
  <div class="section main-part-scroll">
    <div class="fullWidth">
      <ng-container *ngIf="isPageLoad">
        <ng-container *ngIf="updateTask === 'updateJob'">
          <div
            class="row row-cols-md-2 row-cols-xl-4"
            [ngStyle]="{
              'padding-bottom': bottomSectionFixedHeight
            }"
          >
            <ng-container
              *ngIf="
                (!updateTask || updateTask === 'updateJob') &&
                offlineMode &&
                offlineData?.savedData?.length
              "
            >
              <div class="sc-head6 mb-3">Queued Events</div>
              <!--show data in the indexed db-->

              <div *ngFor="let savedItem of offlineData?.savedData">
                <div class="mb-2">
                  <div>
                    <span class="badge-key">Time:</span>
                    <span class="badge-value">
                      {{
                        savedItem?.updated_at * 1000
                          | customdate : "d MMM y HH:mm:ss"
                      }}
                    </span>
                  </div>
                  <div
                    *ngIf="
                      savedItem?.pathName === '/api/patrol/welfare_check_in/'
                    "
                  >
                    <span class="badge-key"> Event:</span>
                    <span class="badge-value"> Welfare Check In</span>
                  </div>
                  <div
                    *ngIf="
                      savedItem?.pathName === '/api/patrol/scan_checkpoint/'
                    "
                  >
                    <span class="badge-key">Patrol Event:</span>
                    <span class="badge-value">
                      {{
                        savedItem?.qr_text ? "QR Scan" : "Checkpoint Scan"
                      }}</span
                    >
                    <div>
                      <span class="badge-key">Checkpoint:</span>
                      <span class="badge-value">
                        {{ jsonParse(savedItem?.checkpointDetail)?.name }}
                      </span>
                    </div>
                    <div>
                      <span class="badge-key">Client:</span>
                      <span class="badge-value">
                        {{ jsonParse(savedItem?.checkpointDetail)?.company }}
                      </span>
                    </div>
                  </div>
                  <div
                    *ngIf="savedItem?.pathName === '/api/patrol/add_update/'"
                  >
                    <span class="badge-key">Patrol Event:</span>
                    <span class="badge-value">
                      {{
                        savedItem?.file
                          ? "Add Photo"
                          : savedItem?.comment
                          ? "Add Comment"
                          : "Update"
                      }}</span
                    >
                    <div>
                      <span class="badge-key">Client:</span>
                      <span class="badge-value">
                        {{ savedItem?.company }}
                      </span>
                    </div>
                  </div>
                  <div *ngIf="savedItem?.pathName === '/api/job/update_job/'">
                    <span class="badge-key">JOB Event:</span>
                    <span class="badge-value"> Add Comment/Photo </span>
                    <div>
                      <span class="badge-key">Job:</span>
                      <span class="badge-value">
                        {{ savedItem?.jobDetails?.job_key }}
                      </span>
                    </div>
                    <div>
                      <span class="badge-key">Client:</span>
                      <span class="badge-value">
                        {{ savedItem?.jobDetails?.company }}
                      </span>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      savedItem?.pathName === '/api/check_point/register_qr/'
                    "
                  >
                    <span class="badge-key">Patrol Event:</span>
                    <span class="badge-value">QR Register </span>
                    <div>
                      <span class="badge-key">Checkpoint:</span>
                      <span class="badge-value">
                        {{ jsonParse(savedItem?.form_data)?.name }}
                      </span>
                    </div>
                    <div>
                      <span class="badge-key">Client:</span>
                      <span class="badge-value">
                        {{ jsonParse(savedItem?.form_data)?.company }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!offlineMode">
              <!-- SOS -->
              <div *ngIf="isPageLoad" class="mobile-only">
                <div *ngIf="isSOSButtonPressed" class="overlay"></div>
                <div
                  class="sos-btn-container"
                  [ngClass]="
                    isSOSButtonPressed ? 'sos-btn-container-highlight' : ''
                  "
                >
                  <button
                    fullWidth
                    nbButton
                    size="giant"
                    status="danger"
                    hero
                    [class.pressed]="isSOSButtonPressed"
                    (mousedown)="
                      handleSOSPress(true, alertSOSTemplate, confirmSOSTemplate)
                    "
                    (mouseup)="
                      handleSOSPress(
                        false,
                        alertSOSTemplate,
                        confirmSOSTemplate
                      )
                    "
                    (mouseleave)="
                      handleSOSPress(
                        false,
                        alertSOSTemplate,
                        confirmSOSTemplate
                      )
                    "
                    (touchstart)="
                      handleSOSPress(true, alertSOSTemplate, confirmSOSTemplate)
                    "
                    (touchend)="
                      handleSOSPress(
                        false,
                        alertSOSTemplate,
                        confirmSOSTemplate
                      )
                    "
                  >
                    Send SOS Alert
                  </button>
                </div>
              </div>
              <!-- Install App -->
              <div *ngIf="!isAppInstalled && isPageLoad" class="mobile-only">
                <button
                  fullWidth
                  nbButton
                  size="giant"
                  status="info"
                  (click)="installPWAApp()"
                >
                  Install App
                </button>
              </div>

              <!-- count of client  with checkpoints that do not have any report users configured -->
              <div
                *ngIf="isAdmin && dashBoardData?.add_report_users > 0"
                nbTooltip="Client with checkpoints that do not have any report users configured"
              >
                <button
                  fullWidth
                  hero
                  nbButton
                  size="giant"
                  status="warning"
                  class="nbbutton-long-text"
                  (click)="redirectToPage('addReportUsers')"
                >
                  Clients not setup for reports
                </button>
              </div>

              <div
                *ngIf="
                  (isAdmin || isDispatchUser) &&
                  dashBoardData?.invalid_qr_checkpoints > 0
                "
              >
                <button
                  class="nbbutton-long-text"
                  nbButton
                  status="danger"
                  fullWidth
                  hero
                  size="giant"
                  (click)="redirectToPage('validateQr')"
                >
                  Validate Checkpoints
                </button>
              </div>
              <div *ngIf="isPageLoad && subscriberStats?.company_count != 0">
                <button
                  fullWidth
                  nbButton
                  size="giant"
                  status="warning"
                  (click)="redirectToPage('incidents')"
                >
                  Incidents
                </button>
              </div>
              <!-- guards that have added licenses to their profile -->
              <!-- <div *ngIf="isAdmin" nbTooltip="Guards with license">
              <button
                fullWidth
                hero
                nbButton
                size="giant"
                status="primary"
                class="nbbutton-long-text"
                (click)="redirectToPage('licenceAudit')"
              >
                Licence Audit
              </button>
            </div> -->

              <ng-container
                *ngIf="
                  subscriberStats?.checkpoint_count === 0 ||
                  subscriberStats?.company_count === 0 ||
                  subscriberStats?.job_count == 0
                "
              >
                <!-- Add Client -->
                <div
                  *ngIf="
                    subscriberStats?.company_count == 0 &&
                    (isAdmin || isDispatchUser)
                  "
                >
                  <button
                    nbButton
                    size="giant"
                    [routerLink]="['/client']"
                    fullWidth
                  >
                    + Add Client
                  </button>
                </div>

                <!-- Add Checkpoint -->
                <div
                  *ngIf="
                    subscriberStats?.checkpoint_count === 0 &&
                    subscriberStats?.company_count > 0 &&
                    isAdmin
                  "
                >
                  <button
                    nbButton
                    size="giant"
                    [routerLink]="['/checkpoints']"
                    fullWidth
                  >
                    + Add Checkpoint
                  </button>
                </div>
              </ng-container>

              <!-- Patrol History -->
              <div
                *ngIf="
                  subscriberStats?.log_count > 0 && (isAdmin || isDispatchUser)
                "
              >
                <button
                  fullWidth
                  nbButton
                  size="giant"
                  (click)="redirectToPage('patrolHistory')"
                >
                  Patrol History
                </button>
              </div>

              <!-- Last 24 Hrs -->
              <div *ngIf="isGuardUser">
                <button
                  fullWidth
                  nbButton
                  size="giant"
                  (click)="redirectToPage('lastEvents')"
                >
                  Event Log
                </button>
              </div>

              <!-- Guard Tracker Last Live Location -->
              <div
                *ngIf="
                  (isAdmin || isDispatchUser) && subscriberStats?.live_count > 0
                "
              >
                <button
                  fullWidth
                  nbButton
                  size="giant"
                  (click)="redirectToPage('guardLocations')"
                >
                  Guard Locations
                </button>
              </div>

              <!-- Unassigned Jobs -->
              <div *ngIf="jobStats?.unassigned_jobs && isAdmin">
                <button
                  fullWidth
                  nbButton
                  size="giant"
                  (click)="redirectToPage('unassignedJobs')"
                >
                  Unassigned Jobs: {{ jobStats?.unassigned_jobs }}
                </button>
              </div>

              <!-- Stale Jobs -->
              <div
                *ngIf="jobStats?.stale_jobs && (isAdmin || isDispatchUser)"
                nbTooltip="Last update > 3 days"
              >
                <button
                  fullWidth
                  nbButton
                  size="giant"
                  status="danger"
                  hero
                  (click)="redirectToPage('staleJobs')"
                >
                  Stale Jobs: {{ jobStats?.stale_jobs }}
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="userPrompts?.length > 0">
              <!-- prompt btns-->
              <div *ngFor="let prompt of userPrompts">
                <button
                  fullWidth
                  nbButton
                  size="giant"
                  status="info"
                  class="nbbutton-long-text"
                  hero
                  (click)="
                    updateEvenDetails.promptData = prompt;
                    updateTask = 'userPromptInfo'
                  "
                >
                  {{ prompt?.name }}
                </button>
              </div>
            </ng-container>
            <div class="mobile-only">
              <app-update-events
                [detailType]="updateTask"
                (emitData)="closeDetailedHome()"
              >
              </app-update-events>
            </div>
          </div>
        </ng-container>

        <!--update tasks -->
        <div
          *ngIf="
            [
              'scanCheckPoint',
              'addClientComment',
              'addClientPhoto',
              'userPromptInfo'
            ].includes(updateTask)
          "
        >
          <div>
            <app-update-events
              [detailType]="updateTask"
              [details]="updateEvenDetails"
              (emitData)="closeDetailedHome()"
            ></app-update-events>
          </div>
        </div>
      </ng-container>
      <!-- MOBILE BUTTONS -->
      <ng-container>
        <div class="section mobile-only">
          <div class="section fixed-bottom" id="fixedBtn">
            <!--online buttons (check conditions are different)-->
            <ng-container
              *ngIf="
                !offlineMode &&
                subscriberStats &&
                updateTask == 'updateJob' &&
                subscriberStats?.company_count > 0
              "
            >
              <div class="row">
                <div
                  [ngClass]="
                    subscriberStats?.checkpoint_count > 0
                      ? 'row-cols-2 row m-0 p-0 pt-2'
                      : 'row-cols-1 row m-0 p-0 pt-2'
                  "
                >
                  <div
                    class="col"
                    *ngIf="subscriberStats?.checkpoint_count > 0"
                  >
                    <button
                      nbButton
                      fullWidth
                      size="giant"
                      status="primary"
                      hero
                      class="nbbutton-long-text"
                      (click)="updateTask = 'scanCheckPoint'"
                    >
                      Scan Checkpoint
                    </button>
                  </div>

                  <div class="col">
                    <button
                      nbButton
                      fullWidth
                      size="giant"
                      status="primary"
                      hero
                      class="nbbutton-long-text"
                      (click)="openQRCode()"
                      [disabled]="!cameraOnDevice"
                    >
                      Scan QR
                    </button>
                  </div>
                </div>

                <div class="row-cols-2 row m-0 p-0 py-2">
                  <div class="col">
                    <button
                      nbButton
                      fullWidth
                      size="giant"
                      status="primary"
                      hero
                      class="nbbutton-long-text"
                      (click)="updateTask = 'addClientComment'"
                    >
                      <nb-icon
                        icon="message-square-outline"
                        pack="eva"
                        style="color: var(--color-basic-300)"
                      ></nb-icon>
                    </button>
                  </div>

                  <div class="col">
                    <button
                      nbButton
                      fullWidth
                      size="giant"
                      status="primary"
                      hero
                      class="nbbutton-long-text"
                      (click)="updateTask = 'addClientPhoto'"
                    >
                      <nb-icon
                        icon="camera-outline"
                        pack="eva"
                        style="color: var(--color-basic-300)"
                      ></nb-icon>
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
            <!--offline buttons (check conditions are different)-->

            <div *ngIf="offlineMode">
              <ng-container *ngIf="updateTask == 'updateJob'">
                <div class="row">
                  <div
                    [ngClass]="
                      offlineData?.checkpointData?.data?.length
                        ? 'row-cols-2 row m-0 p-0 pt-2'
                        : 'row-cols-1 row m-0 p-0 pt-2'
                    "
                  >
                    <div
                      class="col"
                      *ngIf="offlineData?.checkpointData?.data?.length"
                    >
                      <button
                        nbButton
                        fullWidth
                        size="giant"
                        status="primary"
                        hero
                        class="nbbutton-long-text"
                        (click)="updateTask = 'scanCheckPoint'"
                      >
                        Scan Checkpoint
                      </button>
                    </div>

                    <div class="col">
                      <button
                        nbButton
                        fullWidth
                        size="giant"
                        status="primary"
                        hero
                        class="nbbutton-long-text"
                        (click)="openQRCode()"
                        [disabled]="!cameraOnDevice"
                      >
                        Scan QR
                      </button>
                    </div>
                  </div>

                  <div class="pb-2 mb-2 row-cols-2 row m-0 p-0">
                    <div
                      class="col"
                      *ngIf="offlineData?.siteData?.data?.length"
                    >
                      <button
                        nbButton
                        fullWidth
                        size="giant"
                        status="primary"
                        hero
                        class="nbbutton-long-text"
                        (click)="updateTask = 'addClientComment'"
                      >
                        <nb-icon
                          icon="message-square-outline"
                          pack="eva"
                          style="color: var(--color-basic-300)"
                        ></nb-icon>
                      </button>
                    </div>

                    <div
                      class="col"
                      *ngIf="offlineData?.siteData?.data?.length"
                    >
                      <button
                        nbButton
                        fullWidth
                        size="giant"
                        status="primary"
                        hero
                        class="nbbutton-long-text"
                        (click)="updateTask = 'addClientPhoto'"
                      >
                        <nb-icon
                          icon="camera-outline"
                          pack="eva"
                          style="color: var(--color-basic-300)"
                        ></nb-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #alertSOSTemplate let-alertSOSTemplate="dialogRef">
  <nb-card class="dialog-box p-2">
    <nb-card-header>
      SOS Not Sent
      <nb-icon
        (click)="alertSOSTemplate.close()"
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <nb-alert>Press and hold for at least 3 seconds to send SOS.</nb-alert>
    </nb-card-body>
    <div>
      <button
        nbButton
        fullWidth
        size="giant"
        status="primary"
        hero
        class="nbbutton-long-text"
        (click)="alertSOSTemplate.close()"
      >
        OK
      </button>
    </div>
  </nb-card>
</ng-template>
<ng-template #confirmSOSTemplate let-confirmSOSTemplate="dialogRef">
  <nb-card class="dialog-box p-2">
    <nb-card-header>
      SOS Sent
      <nb-icon
        (click)="confirmSOSTemplate.close()"
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <nb-alert
        >SOS has been sent, parties have been informed to contact you
        shortly.</nb-alert
      >
    </nb-card-body>
    <div>
      <button
        nbButton
        fullWidth
        size="giant"
        status="primary"
        hero
        class="nbbutton-long-text"
        (click)="confirmSOSTemplate.close()"
      >
        OK
      </button>
    </div>
  </nb-card>
</ng-template>
