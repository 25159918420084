<!-- MOBILE -->
<div class="mobile-only px-2">
  <form [formGroup]="formdata">
    <nb-stepper #stepper>
      <nb-step>
        <div class="form-floating">
          <input
            autofocus
            id="mobclientField"
            class="form-control"
            fullWidth
            formControlName="clientName"
            (input)="onClientSearch($event)"
            nbInput
            type="text"
            placeholder="Search & Select Client"
            [nbAutocomplete]="mobileORTACClient"
            autocomplete="off"
          />

          <label>Search & Select Client</label>
        </div>

        <nb-autocomplete
          #mobileORTACClient
          (selectedChange)="onClientSelect($event)"
        >
          <nb-option [value]="' '" *ngIf="isAdmin || isDispatchUser">
            <b> Add Client</b>
          </nb-option>
          <nb-option *ngFor="let item of clientList" [value]="item">
            <div>
              <div class="strong">{{ item?.company_name }}</div>

              <div class="small-font">
                {{ item?.address?.full_address }}
              </div>
            </div>
          </nb-option>
        </nb-autocomplete>
        <div class="row">
          <div class="col">
            <button
              size="giant"
              nbButton
              fullWidth
              ghost
              [routerLink]="['/roster']"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              size="giant"
              nbButton
              fullWidth
              nbStepperNext
              [disabled]="!otherJobType.client.data"
              (click)="shiftFocus('mobsiteField')"
            >
              Next
            </button>
          </div>
        </div>
      </nb-step>
      <nb-step>
        <div class="form-floating" *ngIf="otherJobType.client.data">
          <input
            id="mobsiteField"
            autofocus
            class="form-control"
            fullWidth
            formControlName="siteName"
            (input)="onSiteSearch($event)"
            nbInput
            type="text"
            placeholder="Search/Select Site"
            [nbAutocomplete]="mobileORTACSite"
            autocomplete="off"
          />
          <label>Search/Select Site</label>
        </div>

        <nb-autocomplete
          #mobileORTACSite
          (selectedChange)="onSelectSite($event)"
        >
          <nb-option [value]="' '" *ngIf="isAdmin || isDispatchUser">
            <b> Add Site</b>
          </nb-option>
          <nb-option *ngFor="let item of siteList" [value]="item">
            <div>
              <div class="strong">{{ item?.company_name }}</div>

              <div class="small-font">
                {{ item?.address?.full_address }}
              </div>
            </div>
          </nb-option>
        </nb-autocomplete>
        <div class="row">
          <div class="col">
            <button size="giant" nbButton fullWidth ghost nbStepperPrevious>
              Back
            </button>
          </div>
          <div class="col">
            <button
              size="giant"
              nbButton
              fullWidth
              nbStepperNext
              [disabled]="!otherJobType.site.data"
              (click)="shiftFocus('mobassigneeField')"
            >
              Next
            </button>
          </div>
        </div>
      </nb-step>
      <nb-step>
        <div
          class="form-floating"
          *ngIf="selectedStatus.enable_assignees === true"
        >
          <input
            class="form-control"
            id="mobassigneeField"
            fullWidth
            (input)="onAssigneeSearch($event)"
            nbInput
            type="text"
            placeholder="Assign Guard"
            formControlName="assigneeName"
            [nbAutocomplete]="assigneeNgModelMobile"
            autocomplete="off"
          />
          <label>Assign Guard</label>
        </div>
        <nb-autocomplete
          #assigneeNgModelMobile
          (selectedChange)="onAssigneeSelect($event)"
        >
          <nb-option *ngFor="let assignee of assigneeList" [value]="assignee">
            {{ assignee?.full_name }}
            <small *ngIf="!assignee?.validStateLicense">
              <nb-icon [icon]="'shield-off-outline'" pack="eva"> </nb-icon>
            </small>
          </nb-option>
        </nb-autocomplete>
        <div class="row">
          <div class="col">
            <button size="giant" nbButton fullWidth ghost nbStepperPrevious>
              Back
            </button>
          </div>
          <div class="col">
            <button
              size="giant"
              nbButton
              fullWidth
              nbStepperNext
              [disabled]="!otherJobType.site.data"
            >
              Next
            </button>
          </div>
        </div>
      </nb-step>
      <nb-step>
        <div class="col">
          <button
            (click)="openTemplate(addInfoDialogRef)"
            nbButton
            class="nbbutton-long-text"
            size="giant"
            fullWidth
            [ngClass]="otherJobType.ai.value ? '' : 'outline-btn'"
          >
            Additional Information
          </button>
        </div>
        <div class="form-floating">
          <input
            id="mobocField"
            class="form-control"
            fullWidth
            formControlName="siteContactName"
            nbInput
            type="text"
            placeholder="Onsite Contact (Optional)"
            [nbAutocomplete]="contactAutoNgModel"
            autocomplete="off"
          />
          <label>Onsite Contact (Optional)</label>
          <nb-autocomplete
            #contactAutoNgModel
            (selectedChange)="selectContact($event)"
          >
            <nb-option [value]="' '" *ngIf="isAdmin && otherJobType.site.data">
              <b> Add New Contact</b>
            </nb-option>
            <nb-option *ngFor="let contact of siteContacts" [value]="contact">{{
              contact?.full_name
            }}</nb-option>
          </nb-autocomplete>
        </div>
        <div class="row">
          <div class="col">
            <button size="giant" nbButton fullWidth ghost nbStepperPrevious>
              Back
            </button>
          </div>
          <div class="col">
            <button
              size="giant"
              nbButton
              fullWidth
              nbStepperNext
              [disabled]="!otherJobType.site.data"
              (click)="formatScheduleForm()"
            >
              Next
            </button>
          </div>
        </div>
      </nb-step>
      <nb-step>
        <app-scheduler
          *ngIf="scheduleStepperActive"
          [dialogBox]="false"
          [schedulerForm]="schedulerForm"
          [editSchedule]="true"
        ></app-scheduler>
        <div>
          <nb-select
            (click)="getIntervalData()"
            class="welfare-check-dropdown"
            fullWidth
            [selected]="intervalData[0].value"
            placeholder="Welfare Check Interval"
            status="primary"
            size="large"
            hero
          >
            <nb-option
              [value]="interval.value"
              *ngFor="let interval of intervalData"
              (click)="updateInterval(interval)"
              >{{ interval.name }}</nb-option
            >
          </nb-select>
        </div>
        <div>
          <button
            fullWidth
            type="button"
            nbStepperPrevious
            nbButton
            ghost
            size="giant"
          >
            Back
          </button>
          <button
            fullWidth
            (click)="onAddJob()"
            nbButton
            status="primary"
            size="giant"
            [disabled]="addJobPressed || !isScheduleFormValid()"
          >
            Add Job
          </button>
        </div>
      </nb-step>
    </nb-stepper>
  </form>
</div>

<!-- DESKTOP -->
<div class="large-view-only">
  <div class="row row-cols-2">
    <div class="col job-detail-section">
      <!--job details section-->
      <form [formGroup]="formdata">
        <nb-stepper #stepper1>
          <nb-step>
            <!--client section-->
            <div>
              <div class="form-floating edit-field">
                <input
                  autofocus
                  class="form-control"
                  fullWidth
                  formControlName="clientName"
                  (input)="onClientSearch($event)"
                  nbInput
                  type="text"
                  placeholder="Search & Select Client"
                  [nbAutocomplete]="clientACDesk"
                  id="deskclientField"
                />

                <label>Search & Select Client</label>
              </div>

              <nb-autocomplete
                #clientACDesk
                (selectedChange)="onClientSelect($event)"
              >
                <nb-option [value]="' '" *ngIf="isAdmin || isDispatchUser">
                  <b> Add New Client</b>
                </nb-option>
                <nb-option *ngFor="let item of clientList" [value]="item">
                  <div>
                    <div class="strong">
                      {{ item?.company_name }}
                    </div>

                    <div class="small-font">
                      {{ item?.address?.full_address }}
                    </div>
                  </div>
                </nb-option>
              </nb-autocomplete>
            </div>
            <div class="row">
              <div class="col">
                <button
                  size="giant"
                  nbButton
                  fullWidth
                  ghost
                  [routerLink]="['/roster']"
                >
                  Cancel
                </button>
              </div>
              <div class="col">
                <button
                  size="giant"
                  nbButton
                  fullWidth
                  nbStepperNext
                  [disabled]="!otherJobType.client.data"
                  (click)="shiftFocus('desksiteField')"
                >
                  Next
                </button>
              </div>
            </div>
          </nb-step>
          <nb-step>
            <!--site section-->
            <div>
              <div class="form-floating edit-field">
                <input
                  autofocus
                  class="form-control"
                  fullWidth
                  autocomplete="off"
                  formControlName="siteName"
                  (input)="onSiteSearch($event)"
                  nbInput
                  type="text"
                  placeholder="Search/Select Site"
                  [nbAutocomplete]="siteACDesk"
                  id="desksiteField"
                />
                <label>Search/Select Site</label>
              </div>

              <nb-autocomplete
                #siteACDesk
                (selectedChange)="onSelectSite($event)"
              >
                <nb-option [value]="' '" *ngIf="isAdmin">
                  <b> Add New Site</b>
                </nb-option>
                <nb-option *ngFor="let item of siteList" [value]="item">
                  <div>
                    <div>{{ item?.company_name }}</div>

                    <div class="small-font">
                      {{ item?.address?.full_address }}
                    </div>
                  </div>
                </nb-option>
              </nb-autocomplete>
            </div>
            <div class="row">
              <div class="col">
                <button size="giant" nbButton fullWidth ghost nbStepperPrevious>
                  Back
                </button>
              </div>
              <div class="col">
                <button
                  size="giant"
                  nbButton
                  fullWidth
                  nbStepperNext
                  [disabled]="!otherJobType.site.data"
                  (click)="shiftFocus('deskassigneeField')"
                >
                  Next
                </button>
              </div>
            </div>
          </nb-step>
          <nb-step>
            <!--assignee section-->
            <div>
              <div class="form-floating edit-field">
                <input
                  autofocus
                  class="form-control"
                  fullWidth
                  (input)="onAssigneeSearch($event)"
                  nbInput
                  type="text"
                  placeholder="Assign Guard"
                  [nbAutocomplete]="assigneeNgModelMobile"
                  formControlName="assigneeName"
                  autocomplete="off"
                  id="deskassigneeField"
                />
                <label>Assign Guard</label>
              </div>

              <nb-autocomplete
                #assigneeNgModelMobile
                (selectedChange)="onAssigneeSelect($event)"
              >
                <nb-option
                  *ngFor="let assignee of assigneeList"
                  [value]="assignee"
                >
                  {{ assignee?.full_name }}
                  <small *ngIf="!assignee?.validStateLicense">
                    <nb-icon [icon]="'shield-off-outline'" pack="eva">
                    </nb-icon>
                  </small>
                </nb-option>
              </nb-autocomplete>
            </div>
            <div class="row">
              <div class="col">
                <button size="giant" nbButton fullWidth ghost nbStepperPrevious>
                  Back
                </button>
              </div>
              <div class="col">
                <button
                  size="giant"
                  nbButton
                  fullWidth
                  nbStepperNext
                  [disabled]="!otherJobType.site.data"
                >
                  Next
                </button>
              </div>
            </div>
          </nb-step>
          <nb-step>
            <!--additional information section-->
            <div>
              <div class="edit-field">
                <textarea
                  class="form-control"
                  placeholder="Additional Information (Optional)"
                  cols="20"
                  rows="10"
                  wrap="hard"
                  maxlength="900"
                  fullWidth
                  type="text"
                  [(ngModel)]="otherJobType.ai.value"
                  formControlName="requestDetail"
                  id="deskaiField"
                ></textarea>
              </div>
            </div>
            <!--on site contact section-->
            <div>
              <div class="edit-field form-floating">
                <input
                  id="deskocField"
                  class="form-control"
                  fullWidth
                  formControlName="siteContactName"
                  nbInput
                  type="text"
                  placeholder="Onsite Contact (Optional)"
                  [nbAutocomplete]="contactAutoNgModeDesktop"
                  autocomplete="off"
                />
                <label>Onsite Contact (Optional)</label>
                <nb-autocomplete
                  #contactAutoNgModeDesktop
                  (selectedChange)="selectContact($event)"
                >
                  <nb-option [value]="' '">
                    <b> Add New Contact</b>
                  </nb-option>
                  <nb-option
                    *ngFor="let contact of siteContacts"
                    [value]="contact"
                    >{{ contact?.full_name }}</nb-option
                  >
                </nb-autocomplete>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button size="giant" nbButton fullWidth ghost nbStepperPrevious>
                  Back
                </button>
              </div>
              <div class="col">
                <button
                  size="giant"
                  nbButton
                  fullWidth
                  nbStepperNext
                  [disabled]="!otherJobType.site.data"
                  (click)="formatScheduleForm()"
                >
                  Next
                </button>
              </div>
            </div>
          </nb-step>
          <nb-step>
            <app-scheduler
              *ngIf="scheduleStepperActive"
              [dialogBox]="false"
              [schedulerForm]="schedulerForm"
              [editSchedule]="true"
            ></app-scheduler>
            <div>
              <nb-select
                (click)="getIntervalData()"
                class="welfare-check-dropdown"
                fullWidth
                [selected]="intervalData[0].value"
                placeholder="Welfare Check Interval"
                status="primary"
                hero
              >
                <nb-option
                  [value]="interval.value"
                  *ngFor="let interval of intervalData"
                  (click)="updateInterval(interval)"
                  >{{ interval.name }}</nb-option
                >
              </nb-select>
            </div>
            <div>
              <button
                fullWidth
                type="button"
                nbStepperPrevious
                nbButton
                ghost
                size="giant"
              >
                Back
              </button>
              <button
                fullWidth
                (click)="onAddJob()"
                nbButton
                status="primary"
                size="giant"
                [disabled]="addJobPressed || !isScheduleFormValid()"
              >
                Add Job
              </button>
            </div>
          </nb-step>
        </nb-stepper>
      </form>
    </div>
    <div class="col">
      <!--job site map section-->

      <div
        class="col"
        *ngIf="
          showSiteMap &&
          otherJobType.site?.data?.address?.latitude &&
          otherJobType.site?.data?.address?.longitude
        "
      >
        <app-map-leaflet
          [latLon]="{
            lat: otherJobType.site?.data?.address?.latitude,
            lon: otherJobType.site?.data?.address?.longitude
          }"
          [mapId]="'largeJob'"
        ></app-map-leaflet>
        <div
          *ngIf="
            otherJobType.site?.data?.address?.latitude &&
            otherJobType.site?.data?.address?.longitude
          "
          style="
            text-align: center;
            font-size: 0.75rem;
            text-transform: uppercase;
          "
        >
          Lat: {{ otherJobType.site?.data?.address?.latitude }} Lon:
          {{ otherJobType.site?.data?.address?.longitude }}
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Additional Information POPOVER / DIALOG -->
<ng-template #addInfoDialogRef let-data let-addInfoDialogRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center"
      >Additional Information
    </nb-card-header>

    <!-- Request Detail Section start-->
    <div class="row">
      <div class="col">
        <textarea
          class="form-control"
          placeholder="Additional Information"
          cols="20"
          rows="10"
          wrap="hard"
          maxlength="900"
          fullWidth
          type="text"
          [(ngModel)]="otherJobType.ai.value"
        ></textarea>
      </div>
    </div>

    <div class="row sc-mt-6">
      <div class="col flex-center">
        <button
          nbButton
          class="nbbutton-long-text"
          fullWidth
          size="giant"
          (click)="addInfoDialogRef.close(); addAI()"
        >
          {{ otherJobType.ai.value ? "Save" : "Close" }}
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>

<!--license reminder checkTemplate-->
<ng-template
  #licenceCheckTemplateRef
  let-data
  let-licenceCheckTemplateRef="dialogRef"
>
  <nb-card class="dialog-box">
    <nb-card-header
      >WARNING
      <nb-icon
        (click)="
          licenceCheckTemplateRef.close(); editOtherJobTypeItem('assignee')
        "
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <p>
        You have not added any security licences for
        {{ otherJobType?.site?.data?.address?.state_code }}
      </p>
      <p>
        Licences can be added in
        <span
          [routerLink]="['/company-profile']"
          (click)="ignoreLicenseCheck(); licenceCheckTemplateRef.close()"
          >My Company</span
        >
      </p>

      <div>
        <nb-checkbox
          (checkedChange)="licenseReminderField = !licenseReminderField"
          >Don't show this again</nb-checkbox
        >
      </div>

      <div class="row row-cols-2">
        <div class="col">
          <button
            nbButton
            matRipple
            fullWidth
            ghost
            size="giant"
            (click)="ignoreLicenseCheck(); licenceCheckTemplateRef.close()"
          >
            Ignore
          </button>
        </div>
        <div class="col">
          <button
            nbButton
            matRipple
            size="giant"
            (click)="showLicensePopup(); licenceCheckTemplateRef.close()"
            fullWidth
          >
            Add
          </button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
