<nb-card *ngIf="detailData">
  <!-- MOBILE VIEW -->
  <nb-card-body class="mobile-only">
    <div
      [ngClass]="
        isCreatedUser && !isIncidentSubmitted
          ? 'normal-div gt-p-small'
          : ' gt-p-small'
      "
      [ngStyle]="{
        'padding-bottom': bottomSectionFixedHeight
      }"
    >
      <div
        class="sc-floating-right"
        *ngIf="!isIncidentSubmitted"
        (click)="deleteIncident()"
      >
        <button
          nbButton
          status="danger"
          outline
          title="Delete Incident"
          size="small"
        >
          <nb-icon icon="trash" pack="eva" status="danger"></nb-icon>
        </button>
      </div>
      <div *ngIf="!isIncidentSubmitted" class="strong mb-3 mt-3">
        Incident Report
      </div>

      <div class="row">
        <div *ngIf="isIncidentSubmitted && detailData?.resolution_time">
          <div class="badge-key">Report Sent</div>
          <div class="badge-value">
            {{ detailData?.resolution_time | date : "d MMM y HH:mm" }}
          </div>
        </div>
        <div *ngIf="isIncidentSubmitted && detailData?.resolution_time">
          <div class="badge-key">Recipient</div>
          <div class="badge-value">{{ emailRecipients }}</div>
        </div>

        <div>
          <div
            class="badge-key"
            (click)="
              isCreatedUser && !isIncidentSubmitted
                ? openupdateIncidentTypeTemplate(incidentTypeTempalteRef)
                : ''
            "
          >
            Incident Type
            <nb-icon
              *ngIf="isCreatedUser && !isIncidentSubmitted"
              icon="edit-2"
              class="color-basic-text ms-1"
              pack="eva"
            ></nb-icon>
          </div>
          <div class="badge-value strong">{{ detailData?.incident_type }}</div>
        </div>

        <div>
          <div class="badge-key">Site Name</div>
          <div class="badge-value strong">
            {{ detailData?.site?.company_name }}
          </div>
        </div>

        <div>
          <div class="badge-key">Address</div>
          <div class="badge-value">
            {{ detailData?.site?.address?.full_address }}
          </div>
        </div>

        <div class="pointer">
          <div class="badge-key" (click)="redirectToClientPage()">
            Client
            <nb-icon
              class="ms-1 color-basic-text"
              nbSuffix
              [icon]="'link-outline'"
              pack="eva"
            >
            </nb-icon>
          </div>
          <div class="badge-value strong">
            {{ detailData?.company?.company_name }}
          </div>
        </div>

        <div>
          <div class="badge-key">Reference No.</div>
          <div class="badge-value">{{ detailData?.incident_key }}</div>
        </div>

        <div
          (click)="
            isCreatedUser && !isIncidentSubmitted
              ? openupdateIncidentTimeTemplate(incidentTimeTempalteRef)
              : ''
          "
        >
          <div class="badge-key">
            Incident Time
            <nb-icon
              *ngIf="isCreatedUser && !isIncidentSubmitted"
              icon="edit-2"
              class="color-basic-text ms-1"
              pack="eva"
            ></nb-icon>
          </div>
          <div class="badge-value">
            {{ detailData?.incident_time | date : "d MMM y HH:mm" }}
          </div>
        </div>

        <div
          *ngIf="detailData?.information"
          (click)="
            isCreatedUser && !isIncidentSubmitted
              ? openExtraInfoTemplate(extraInfoTempalteRef, 'an')
              : ''
          "
        >
          <div
            class="badge-key"
            [ngClass]="isCreatedUser && !isIncidentSubmitted ? 'strong' : ''"
          >
            Details
            <nb-icon
              *ngIf="isCreatedUser && !isIncidentSubmitted"
              icon="edit-2"
              class="color-basic-text ms-1"
              pack="eva"
            ></nb-icon>
          </div>
          <div class="badge-value">{{ detailData?.information }}</div>
        </div>

        <div
          *ngIf="detailData?.corrective_actions"
          (click)="
            isCreatedUser && !isIncidentSubmitted
              ? openExtraInfoTemplate(extraInfoTempalteRef, 'ca')
              : ''
          "
        >
          <div
            class="badge-key"
            [ngClass]="isCreatedUser && !isIncidentSubmitted ? 'strong' : ''"
          >
            Resolution
            <nb-icon
              *ngIf="isCreatedUser && !isIncidentSubmitted"
              icon="edit-2"
              class="color-basic-text ms-1"
              pack="eva"
            ></nb-icon>
          </div>
          <div class="badge-value">{{ detailData?.corrective_actions }}</div>
        </div>
      </div>

      <div
        *ngIf="
          !detailData?.information && isCreatedUser && !isIncidentSubmitted
        "
        class="my-1"
      >
        <button
          (click)="openExtraInfoTemplate(extraInfoTempalteRef, 'an')"
          nbButton
          fullWidth
          size="giant"
          class="nbbutton-long-text"
          status="primary"
        >
          Details
        </button>
      </div>

      <ng-container *ngIf="incidentCommentFiles?.length">
        <div
          *ngFor="let msg of incidentCommentFiles; let i = index"
          class="mt-3 border"
        >
          <!-- Timestamp -->
          <div class="incident-photo-timestamp sc-floating-right">
            {{ msg?.updated_at | date : "d MMM y HH:mm" }}
          </div>
          <!-- Image ID -->
          <div class="incident-photo-timestamp">
            Photo
            {{ msg?.event_action === 11 ? "[Device Library]" : "[Camera]" }}:
            #{{ i + 1 }}
          </div>
          <!-- Image -->
          <img
            (click)="onOpenImage($event, imgRef, msg)"
            *ngIf="msg?.file"
            src="{{ msg?.file }}"
            height="auto"
            width="100%"
          />

          <!-- Comment -->
          <div
            *ngIf="msg?.detail != 'null' || msg?.detail != 'undefined'"
            class="incident-photo-comment-ellipsis mb-3"
          >
            {{ msg?.detail }}
          </div>
        </div>
      </ng-container>
    </div>

    <div id="fixedBtn" class="bottom-div px-1 py-0">
      <div *ngIf="isCreatedUser && !isIncidentSubmitted">
        <div>
          <button
            (click)="openCamPopup()"
            nbButton
            fullWidth
            size="giant"
            status="primary"
            hero
            class="nbbutton-long-text mb-0"
          >
            Add Photo
          </button>
        </div>
        <div *ngIf="!detailData?.corrective_actions">
          <button
            (click)="openExtraInfoTemplate(extraInfoTempalteRef, 'ca')"
            nbButton
            fullWidth
            size="giant"
            class="nbbutton-long-text mb-0"
            status="primary"
          >
            Resolution
          </button>
        </div>
      </div>
      <div *ngIf="isIncidentSubmitted && (isAdmin || isDispatcher)">
        <div (click)="markResolved(resolTimeTemplateRef)">
          <button
            class="nbbutton-long-text my-1"
            nbButton
            fullWidth
            size="giant"
            status="primary"
            hero
          >
            Resend Incident Report
          </button>
        </div>
      </div>
      <div
        *ngIf="
          !isIncidentSubmitted && (isCreatedUser || isAdmin || isDispatcher)
        "
      >
        <div (click)="markResolved(resolTimeTemplateRef)">
          <button
            class="nbbutton-long-text"
            nbButton
            fullWidth
            size="giant"
            status="primary"
            hero
          >
            Send Incident Report
          </button>
        </div>
      </div>
    </div>

    <!-- MOBILE ONLY END-->
  </nb-card-body>

  <!-- DESKTOP VIEW -->
  <div class="large-view-only gt-p-1">
    <div *ngIf="!isIncidentSubmitted" class="strong mb-3 sc-head3">
      Incident Report
      <span class="ms-3">
        <button
          (click)="deleteIncident()"
          nbButton
          status="danger"
          outline
          title="Delete Incident"
          size="small"
        >
          <nb-icon icon="trash" pack="eva" status="danger"></nb-icon>
        </button>
      </span>
    </div>

    <ng-container>
      <div>
        <div
          class="row"
          *ngIf="isIncidentSubmitted && (isAdmin || isDispatcher)"
        >
          <div
            class="col my-0 col-md-12 col-lg-5 col-xxl-3"
            (click)="markResolved(resolTimeTemplateRef)"
          >
            <button
              class="nbbutton-long-text"
              nbButton
              fullWidth
              size="large"
              status="primary"
              hero
            >
              Resend Incident Report
            </button>
          </div>
        </div>

        <div class="row row-cols-md-1 row-cols-lg-2 row-cols-xxl-3 gx-3">
          <!-- FIRST SECTION -->
          <div class="col">
            <div
              class="incident-details-section"
              *ngIf="detailData?.resolution_time"
            >
              <span class="badge-key">Report Sent</span>
              <span class="badge-value strong"
                >{{ detailData?.resolution_time | date : "d MMM y HH:mm" }}
              </span>
            </div>

            <div class="incident-details-section">
              <span
                class="badge-key"
                (click)="
                  isCreatedUser && !isIncidentSubmitted
                    ? openupdateIncidentTypeTemplate(incidentTypeTempalteRef)
                    : ''
                "
                >Incident Type
                <nb-icon
                  *ngIf="isCreatedUser && !isIncidentSubmitted"
                  icon="edit-2"
                  class="color-basic-text ms-1"
                  pack="eva"
                ></nb-icon>
              </span>
              <span class="badge-value strong">{{
                detailData?.incident_type
              }}</span>
            </div>

            <div class="incident-details-section">
              <span class="badge-key">Site Name</span>
              <span class="badge-value strong">{{
                detailData?.site?.company_name
              }}</span>
            </div>
            <div class="incident-details-section">
              <span class="badge-key">Address</span>
              <span class="badge-value">
                {{ detailData?.site?.address?.full_address }}
              </span>
            </div>
          </div>

          <!-- SECOND SECTION -->
          <div class="col">
            <div
              *ngIf="isIncidentSubmitted && detailData?.resolution_time"
              class="incident-details-section"
            >
              <span class="badge-key">Recipient</span>
              <span class="badge-value">{{ emailRecipients }}</span>
            </div>

            <div class="incident-details-section">
              <span class="badge-key pointer" (click)="redirectToClientPage()"
                >Client
                <nb-icon
                  class="ms-3"
                  nbSuffix
                  icon="link-2-outline"
                  pack="eva"
                ></nb-icon>
              </span>
              <span class="badge-value strong">{{
                detailData?.company?.company_name
              }}</span>
            </div>

            <div class="incident-details-section">
              <span class="badge-key">Reference No.</span>
              <span class="badge-value">{{ detailData?.incident_key }}</span>
            </div>

            <div
              (click)="
                isCreatedUser && !isIncidentSubmitted
                  ? openupdateIncidentTimeTemplate(incidentTimeTempalteRef)
                  : ''
              "
            >
              <span class="badge-key"
                >Incident Time
                <nb-icon
                  *ngIf="isCreatedUser && !isIncidentSubmitted"
                  icon="edit-2"
                  class="color-basic-text ms-1"
                  pack="eva"
                ></nb-icon>
              </span>
              <span class="badge-value"
                >{{ detailData?.incident_time | date : "d MMM y HH:mm" }}
              </span>
            </div>
          </div>

          <!-- THIRD SECTION -->
          <div class="col">
            <div
              *ngIf="
                !detailData?.information &&
                isCreatedUser &&
                !isIncidentSubmitted
              "
              class="my-2"
            >
              <button
                (click)="openExtraInfoTemplate(extraInfoTempalteRef, 'an')"
                nbButton
                fullWidth
                size="giant"
                class="nbbutton-long-text"
                status="primary"
              >
                Details
              </button>
            </div>

            <div
              *ngIf="detailData?.information"
              (click)="
                isCreatedUser && !isIncidentSubmitted
                  ? openExtraInfoTemplate(extraInfoTempalteRef, 'an')
                  : ''
              "
            >
              <div
                class="badge-key"
                [ngClass]="
                  isCreatedUser && !isIncidentSubmitted ? 'strong' : ''
                "
              >
                Details
                <nb-icon
                  *ngIf="isCreatedUser && !isIncidentSubmitted"
                  icon="edit-2"
                  class="color-basic-text ms-1"
                  pack="eva"
                ></nb-icon>
              </div>
              <div class="badge-value">{{ detailData?.information }}</div>
            </div>

            <div
              *ngIf="detailData?.corrective_actions"
              (click)="
                isCreatedUser && !isIncidentSubmitted
                  ? openExtraInfoTemplate(extraInfoTempalteRef, 'ca')
                  : ''
              "
            >
              <div
                class="badge-key"
                [ngClass]="
                  isCreatedUser && !isIncidentSubmitted ? 'strong' : ''
                "
              >
                Resolution
                <nb-icon
                  *ngIf="isCreatedUser && !isIncidentSubmitted"
                  icon="edit-2"
                  class="color-basic-text ms-1"
                  pack="eva"
                ></nb-icon>
              </div>
              <div class="badge-value">
                {{ detailData?.corrective_actions }}
              </div>
            </div>

            <div *ngIf="isCreatedUser && !isIncidentSubmitted">
              <div class="my-2">
                <button
                  (click)="openCamPopup()"
                  nbButton
                  fullWidth
                  size="giant"
                  status="primary"
                  hero
                  class="nbbutton-long-text mb-0"
                >
                  Add Photo
                </button>
              </div>

              <div *ngIf="!detailData?.corrective_actions" class="my-2">
                <button
                  (click)="openExtraInfoTemplate(extraInfoTempalteRef, 'ca')"
                  nbButton
                  fullWidth
                  size="giant"
                  class="nbbutton-long-text mb-0"
                  status="primary"
                >
                  Resolution
                </button>
              </div>
            </div>

            <div
              *ngIf="
                !isIncidentSubmitted &&
                (isCreatedUser || isAdmin || isDispatcher)
              "
            >
              <div class="my-2">
                <button
                  nbButton
                  fullWidth
                  size="giant"
                  status="primary"
                  hero
                  (click)="markResolved(resolTimeTemplateRef)"
                >
                  Send Incident Report
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- INCIDENT PHOTOS -->
        <div class="row row-cols-md-1 row-cols-lg-2 row-cols-xxl-3">
          <ng-container *ngIf="incidentCommentFiles?.length">
            <div
              *ngFor="let msg of incidentCommentFiles; let i = index"
              class="col p-3 border"
            >
              <!-- Timestamp -->
              <div class="incident-photo-timestamp sc-floating-right">
                {{ msg?.updated_at | date : "d MMM y HH:mm" }}
              </div>
              <!-- Image ID -->

              <div class="incident-photo-timestamp">
                Photo
                {{
                  msg?.event_action === 11 ? "[Device Library]" : "[Camera]"
                }}: #{{ i + 1 }}
              </div>
              <!-- Image -->
              <img
                (click)="onOpenImage($event, imgRef, msg)"
                *ngIf="msg?.file"
                src="{{ msg?.file }}"
                height="auto"
                width="100%"
                alt="Photo {{ i }}"
                nbTooltip="{{ msg?.detail }}"
                [nbTooltipDisabled]="
                  !msg?.detail ||
                  msg?.detail == 'null' ||
                  msg?.detail == 'undefined'
                "
              />

              <!-- Comment -->
              <div
                *ngIf="
                  msg?.detail &&
                  (msg?.detail != 'null' || msg?.detail != 'undefined')
                "
                class="incident-photo-comment-ellipsis mb-3"
              >
                {{ msg?.detail }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</nb-card>

<!-- ADD COMMENT  DIALOG -->
<ng-template #commentRef let-commentRef="dialogRef" let-data>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center">
      {{ data?.detail ? "Edit Comment" : "Add Comment" }}

      <nb-icon
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
        (click)="commentRef.close(); newValue = ''"
      >
      </nb-icon>
    </nb-card-header>
    <div class="row-cols-1 gy-2">
      <div class="col">
        <textarea
          class="form-control"
          rows="10"
          cols="32"
          wrap="hard"
          maxlength="340"
          type="text"
          placeholder="Start typing"
          [(ngModel)]="newValue"
        ></textarea>
      </div>
      <div class="col sc-mt-6">
        <button
          (click)="addComment(data); commentRef.close()"
          nbButton
          size="giant"
          fullWidth
          [disabled]="
            data?.detail
              ? !newValue || previousTextValue === newValue
              : !newValue
          "
        >
          {{ data?.detail ? "Update" : "Add" }}
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>

<!--enlarge image-->
<!-- Modal -->
<ng-template #imgRef let-data let-imgTempRef="dialogRef">
  <nb-card style="margin: 0">
    <nb-card-body>
      <img
        (click)="imgTempRef.close()"
        src="{{ data?.src }}"
        style="
          max-width: 100%;
          cursor: pointer;
          max-height: 75vh;
          height: auto;
          border: 1px var(--background-basic-color-3) solid;
          border-radius: 0;
        "
        class="modal-hover-opacity"
      />

      <div>
        <!-- Timestamp -->
        <div class="incident-photo-timestamp">
          {{ data?.data?.updated_at | customdate : "d MMM y HH:mm" }}
          {{ data?.data?.user_name }}
        </div>
        <!-- Comment -->
        <div
          *ngIf="
            data?.data?.detail != 'null' || data?.data?.detail != 'undefined'
          "
          class="incident-photo-comment mb-3"
        >
          {{ data?.data?.detail }}
        </div>
      </div>

      <!-- Delete, Edit, Close -->
      <div
        class="row row-cols-3 p-1"
        *ngIf="isCreatedUser && !isIncidentSubmitted"
      >
        <div>
          <button
            nbButton
            class="my-0"
            fullWidth
            size="giant"
            outline
            status="danger"
            type="button"
            (click)="deletePhoto(data)"
          >
            <nb-icon pack="eva" status="danger" icon="trash"></nb-icon>
          </button>
        </div>
        <!-- Edit -->
        <div (click)="openCommentTemplate(commentRef, data?.data)">
          <button
            nbButton
            class="my-0"
            fullWidth
            size="giant"
            outline
            status="primary"
          >
            <nb-icon
              pack="eva"
              class="color-basic-text"
              icon="edit-2"
            ></nb-icon>
          </button>
        </div>
        <!-- Close -->
        <div (click)="imgTempRef.close()">
          <button nbButton class="my-0" fullWidth size="giant" outline>
            <nb-icon pack="eva" class="color-basic-text" icon="close"></nb-icon>
          </button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<!--template to show site map location-->
<ng-template #mapRef let-data>
  <nb-card class="dialog-box">
    <app-map-leaflet
      [latLon]="{ lat: data?.address?.latitude, lon: data?.address?.longitude }"
      [mapId]="'siteLocation'"
    ></app-map-leaflet>
    <!-- map section ends -->
  </nb-card>
</ng-template>

<!-- history changes to update model section-->
<ng-template #histChangeRef let-data let-histChangeRef="dialogRef">
  <nb-card style="margin: 0" class="dialog-box gt-p-small">
    <nb-card-body>
      <app-table
        [desktopTableData]="data"
        [desktopTableDetails]="histChangeTableView"
        [tableStyle]="tableStyle"
      ></app-table>
      <div class="sc-mt-6">
        <button
          (click)="histChangeRef.close()"
          nbButton
          size="giant"
          ghost
          fullWidth
        >
          Close
        </button>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<!-- edit corrective actions/ additonal notes  DIALOG -->
<ng-template
  #extraInfoTempalteRef
  let-extraInfoTempalteRef="dialogRef"
  let-data
>
  <nb-card class="dialog-box p-1 mx-1 my-0">
    <nb-card-header class="sc-text-center">
      {{ data?.extrainfoType == "ca" ? "Resolution" : "Incident Details" }}
    </nb-card-header>

    <div class="row">
      <div class="col my-2">
        <textarea
          class="form-control"
          rows="10"
          cols="32"
          wrap="hard"
          maxlength="340"
          type="text"
          placeholder="Start typing"
          [(ngModel)]="newValue"
        ></textarea>
      </div>

      <div class="my-1">
        <button
          (click)="updateExtraInfo(data); extraInfoTempalteRef.close()"
          nbButton
          size="giant"
          fullWidth
          [disabled]="previousTextValue === newValue"
          [status]="previousTextValue != newValue ? 'primary' : 'basic'"
        >
          Update
        </button>
      </div>
      <div class="my-1">
        <button
          (click)="extraInfoTempalteRef.close(); newValue = ''"
          nbButton
          fullWidth
          size="giant"
        >
          Cancel
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>

<!-- resolution time template-->
<ng-template
  #resolTimeTemplateRef
  let-data
  let-resolTimeTemplateRef="dialogRef"
>
  <nb-card style="margin: 0" class="dialog-box px-2 py-0">
    <nb-card-header>
      {{
        isIncidentSubmitted ? "Resend Incident Report" : " Incident Resolved"
      }}
      <nb-icon
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
        (click)="resolTimeTemplateRef.close()"
      >
      </nb-icon>
    </nb-card-header>
    <nb-card-body class="scrollable-y">
      <!--incident Time section-->
      <nb-alert *ngIf="resDateTimeErrorMessage" class="sc-mt-6">
        {{ resDateTimeErrorMessage }}
      </nb-alert>

      <div class="row row-cols-2 sc-mt-6 mb-2" *ngIf="!isIncidentSubmitted">
        <div class="col">
          <div class="form-floating">
            <input
              autofocus
              id="rDayField"
              nbInput
              class="form-control"
              fullWidth
              placeholder="Resolution Date"
              [nbDatepicker]="dateTimePicker2"
              [(ngModel)]="resolutionDay"
            /><label for="inputAssignee"> Resolution Date</label>
            <nb-datepicker
              (dateChange)="formateStartDate($event)"
              #start_day
              #dateTimePicker2
              [max]="todayDate"
              [min]="minResDate"
            ></nb-datepicker>
          </div>
        </div>
        <div class="col">
          <div class="form-floating">
            <input
              nbInput
              id="rTimeField"
              class="form-control"
              type="text"
              placeholder="Time"
              fullWidth
              [ngxMatTimepicker]="timePicker3"
              [(ngModel)]="resolutionTime"
            /><label for="inputAssignee"> Time </label>
            <ngx-mat-timepicker
              (timeSet)="formatStartTime($event)"
              #timePicker3
            ></ngx-mat-timepicker>
          </div>
        </div>
      </div>
      <div class="my-3">
        <nb-alert *ngIf="selectedUsers?.length === 0" class="sc-mt-6">
          No contacts added with this associated client!
        </nb-alert>
        <button
          nbButton
          class="sc-floating-right"
          *ngIf="selectedUsers?.length > 1 && !displayAllUsers"
          (click)="displayAllUsers = !displayAllUsers"
        >
          + {{ selectedUsers?.length - 1 }} more
        </button>
        <nb-list class="list-fixed-height">
          <nb-list-item
            *ngFor="let user of slicedData(selectedUsers)"
            class="mb-1"
          >
            <nb-checkbox
              [checked]="!user.isSnubbed"
              (checkedChange)="user.isSnubbed = !user?.isSnubbed"
              >{{
                user?.user_full_name ? user?.user_full_name : user.full_name
              }}
            </nb-checkbox>
          </nb-list-item>
        </nb-list>
      </div>
      <div class="row row-cols-1 mt-2">
        <div class="col">
          <button
            (click)="resolTimeTemplateRef.close(); submitIncident()"
            nbButton
            size="giant"
            fullWidth
            [disabled]="!resolutionDateTime || !hasUnsnubbedUsers()"
          >
            Send Report
          </button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
<!-- edit incident time  DIALOG -->
<ng-template
  #incidentTimeTempalteRef
  let-incidentTimeTempalteRef="dialogRef"
  let-data
>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center">
      Update Incident Time
    </nb-card-header>

    <div class="row">
      <div class="col my-2">
        <div class="row row-cols-2">
          <div class="col">
            <div class="form-floating">
              <input
                id="mobiDayField"
                nbInput
                class="form-control"
                fullWidth
                [ngModelOptions]="{ standalone: true }"
                placeholder="Incident Date"
                [nbDatepicker]="dateTimePicker0"
                [(ngModel)]="newDateValue"
              /><label for="inputAssignee"> Incident Date</label>
              <nb-datepicker
                (dateChange)="formateNewStartDate($event)"
                #start_day
                #dateTimePicker0
                [max]="todayDate"
              ></nb-datepicker>
            </div>
          </div>
          <div class="col">
            <div class="form-floating">
              <input
                nbInput
                id="mobiTimeField"
                class="form-control"
                type="text"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Incident Time"
                fullWidth
                [ngxMatTimepicker]="timePicker1"
                [(ngModel)]="newTimeValue"
              /><label for="inputAssignee"> Incident Time </label>
              <ngx-mat-timepicker
                (timeSet)="formatNewStartTime($event)"
                #timePicker1
              ></ngx-mat-timepicker>
            </div>
          </div>
        </div>
      </div>

      <div class="my-1">
        <button
          (click)="updateincidentTime(data); incidentTimeTempalteRef.close()"
          nbButton
          size="giant"
          fullWidth
          [disabled]="previousdateTimeValue === dateTimeValue"
          [status]="
            previousdateTimeValue != dateTimeValue ? 'primary' : 'basic'
          "
        >
          Update
        </button>
      </div>
      <div class="my-1">
        <button
          (click)="incidentTimeTempalteRef.close(); newValue = ''"
          nbButton
          fullWidth
          size="giant"
        >
          Cancel
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>
<!-- edit incident type  DIALOG -->
<ng-template
  #incidentTypeTempalteRef
  let-incidentTypeTempalteRef="dialogRef"
  let-data
>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center">
      Update Incident Type
    </nb-card-header>

    <div class="row">
      <div class="col my-2">
        <div class="row">
          <div class="form-floating">
            <input
              id="mobincidentTypeField"
              class="form-control"
              fullWidth
              nbInput
              type="text"
              (input)="onSearchIncidentType($event)"
              placeholder="Incident Type"
              [(ngModel)]="incidentTypeValue"
              autocomplete="off"
              [nbAutocomplete]="incidentTypeACMob"
              maxlength="60"
            />
            <label>Incident Type</label>
          </div>
          <nb-autocomplete #incidentTypeACMob>
            <nb-option (click)="onSelectIncidentType(' ')">
              <b> Add Incident Type</b>
            </nb-option>
            <nb-option
              *ngFor="let item of incidentTypeList"
              [value]="item"
              (click)="onSelectIncidentType(item)"
            >
              <div class="strong">{{ item?.name }}</div>
            </nb-option>
          </nb-autocomplete>
        </div>
      </div>

      <div class="my-1">
        <button
          (click)="incidentTypeTempalteRef.close(); newValue = ''"
          nbButton
          fullWidth
          size="giant"
        >
          Cancel
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>
<!-- Upload Type DIALOG -->
<ng-template #uploadTypeOption let-data let-uploadTypeOptionRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center">Upload Type</nb-card-header>

    <div class="row row-cols-2 mt-3">
      <div class="col">
        <button
          nbButton
          fullWidth
          size="giant"
          hero
          status="primary"
          (click)="uploadTypeOptionRef.close(); openCamPopup('device')"
        >
          + Library
        </button>
      </div>

      <div class="col">
        <button
          nbButton
          size="giant"
          fullWidth
          status="primary"
          (click)="uploadTypeOptionRef.close(); openCamPopup()"
        >
          + Camera
        </button>
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col">
        <button
          nbButton
          fullWidth
          size="giant"
          ghost
          (click)="uploadTypeOptionRef.close()"
        >
          Cancel
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>
