import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import * as L from 'leaflet';

import 'leaflet.fullscreen';
import { AppService } from 'src/app/app.service';

import { getDistanceUsingHaversine, mapLayers } from 'src/global.variables';

@Component({
  selector: 'app-user-log',
  templateUrl: './user-log.component.html',
  styleUrls: ['./user-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UserLogComponent implements OnInit, OnDestroy, OnChanges {
  @Input() listData: any = [];
  @Input() otherParams: any;
  @Output() changePagination = new EventEmitter();

  defaultZoom: number = 15;

  baselayers: any = mapLayers;

  currentSliderValue: number = 0;

  currentMarker: any = {};
  distanceFromPrevMarker: number = 0;
  timeDifference: string = '';
  mapData: any;

  index = 0;
  marker: any;
  polylines: any = [];
  browserGpsInfo: any = {
    0: 'High Accuracy',
    1: 'Low Accuracy',
    2: 'IP',
  };
  numOfRecords: number = 20;
  disableSlider: boolean = true;
  constructor(
    private cdr: ChangeDetectorRef,

    private appService: AppService
  ) {}
  ngOnDestroy(): void {
    this.mapData?.eachLayer((layer: any) => {
      this.mapData?.removeLayer(layer);
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.initialCheckPagination();
    }, 1500);
  }

  ngOnInit(): void {
    setTimeout(async () => {
      await this.loadMap();
    }, 1000);
  }

  getMarkerPin(color = 'rgb(255, 127, 0)') {
    const svgIcon = `
    <svg width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C8.13 2 5 5.13 5 9C5 13.25 10.25 20 11.08 21.08C11.37 21.44 11.68 21.74 12 22C12.32 21.74 12.63 21.44 12.92 21.08C13.75 20 19 13.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="${color}"/>
    </svg>
  `;

    return L.divIcon({
      className: 'custom-pin',
      iconSize: [60, 60], // Increased size of the icon
      iconAnchor: [30, 60], // Center the icon at the bottom
      popupAnchor: [0, -60], // Adjust the popup position
      html: svgIcon,
    });
  }
  onSliderChange(): void {
    const newIndex = this.currentSliderValue;

    if (newIndex > this.index) {
      for (let i = this.index; i < newIndex; i++) {
        this.mapData.removeLayer(this.polylines.pop());
      }
    } else if (newIndex < this.index) {
      for (let i = this.index; i > newIndex; i--) {
        const polyline = L.polyline(
          [this.getMarkerPostion(i - 1), this.getMarkerPostion(i)],
          { color: 'blue' }
        ).addTo(this.mapData);
        this.polylines.push(polyline);
      }
    }

    this.index = newIndex;

    this.marker.setLatLng(this.getMarkerPostion(this.index));
    this.mapData.setView(
      this.getMarkerPostion(this.index),
      this.mapData.getZoom()
    );
  }

  getMarkerPostion(index: number): any {
    this.currentMarker = this.listData[this.index];
    this.cdr.detectChanges();
    if (this.index > 1) {
      this.calculatePrevDistance();
    }

    return [this.listData[index]?.gps?.lat, this.listData[index]?.gps?.lon];
  }
  calculatePrevDistance() {
    this.distanceFromPrevMarker = 0;
    try {
      const prevMarker: any = this.listData[this.index - 1];
      this.calculateTimeDifference(prevMarker);
      const distanceInKm: any = getDistanceUsingHaversine(
        this.currentMarker?.gps?.lat,
        prevMarker?.gps?.lat,
        this.currentMarker?.gps?.lon,
        prevMarker?.gps?.lon
      );
      this.distanceFromPrevMarker = distanceInKm * 1000;
    } catch (error) {}
  }
  calculateTimeDifference(prevMarker: any) {
    let date1 = new Date(prevMarker?.updated_at);
    let date2 = new Date(this.currentMarker?.updated_at);

    let diffInMs = Math.abs(date2.getTime() - date1.getTime());

    let seconds = Math.floor((diffInMs / 1000) % 60);
    let minutes = Math.floor((diffInMs / 1000 / 60) % 60);
    let hours = Math.floor((diffInMs / (1000 * 60 * 60)) % 24);

    this.timeDifference = '';

    if (hours > 0) {
      this.timeDifference += `${hours} hours, `;
    }
    if (minutes > 0) {
      this.timeDifference += `${minutes} minutes, `;
    }
    this.timeDifference += ` ${seconds} seconds`;
  }

  async loadMap() {
    let mapContainer: any = L.DomUtil.get('appUsers');

    if (mapContainer != null) {
      mapContainer._leaflet_id = null;
    }
    let osmLayer: any;
    const savedLayer = localStorage.getItem('selectedLayer');
    if (savedLayer && this.baselayers[savedLayer]) {
      osmLayer = this.baselayers[savedLayer];
    } else {
      osmLayer = this.baselayers['Default'];
    }

    const center = L.latLng(this.getMarkerPostion(this.index));

    this.mapData = L.map('appUsers', {
      dragging: true,
      center: center,
      zoom: this.defaultZoom,
      layers: [osmLayer],
      fullscreenControl: true,
      fullscreenControlOptions: {
        // optional
        title: 'Show me the fullscreen !',
        titleCancel: 'Exit fullscreen mode',
      },
    });

    this.marker = L.marker(this.getMarkerPostion(this.index), {
      icon: this.getMarkerPin(),
    }).addTo(this.mapData);

    L.control.layers(this.baselayers, {}).addTo(this.mapData);

    this.mapData.dragging.enable();
  }
  checkPagination() {
    const paginationData = this.otherParams?.paginationData;
    const currentSliderValue =
      this.currentSliderValue + paginationData?.tablePreviousRows;

    if (
      paginationData?.tablePreviousRows + paginationData?.tableRows <
        paginationData?.tableTotalRows ||
      paginationData?.tablePreviousRows > 0
    ) {
      const interval: number = this.numOfRecords / 2;

      const newPreviousValue = Math.max(currentSliderValue - interval, 0);

      if (
        [0, 19].includes(this.currentSliderValue) &&
        newPreviousValue !== paginationData?.tablePreviousRows
      ) {
        this.disableSlider = true;

        this.changePagination.emit({
          previous: newPreviousValue,
          pageSize: interval + interval,
        });
      }
    }
  }

  initialCheckPagination() {
    const paginationData = this.otherParams?.paginationData;
    this.disableSlider = false;
    const interval: number = this.numOfRecords / 2;
    if (paginationData?.tablePreviousRows > 0) {
      this.currentSliderValue = interval;
    }
    this.polylines?.forEach((polyLine: any) => {
      this.mapData?.removeLayer(polyLine);
    });

    for (
      let i = this.listData.length - 1;
      i > Math.max(this.currentSliderValue, 0);
      i--
    ) {
      const polyline = L.polyline(
        [this.getMarkerPostion(i), this.getMarkerPostion(i - 1)],
        { color: 'blue' }
      )?.addTo(this.mapData);
      this.polylines.push(polyline);
    }
    this.index = this.currentSliderValue;

    this.marker.setLatLng(this.getMarkerPostion(this.index));
    this.mapData.setView(
      this.getMarkerPostion(this.index),
      this.mapData.getZoom()
    );
  }
}
