import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  addCompany(requestData: any) {
    const body = {
      ...requestData,
    };
    return this.http
      .post('/api/company/', body)
      .pipe(map((response: any) => response));
  }
  updateCompanyDetails(requestData?: any, params?: any) {
    const body = {
      ...requestData,
    };
    return this.http
      .post('/api/company_profile/', body, { params: params })
      .pipe(map((response) => response));
  }
  addCompanyLicense(requestData: any) {
    const body = {
      ...requestData,
    };
    return this.http
      .post('/api/company_profile/add_subscriber_license/', body)
      .pipe(map((response) => response));
  }

  updateCompanyDetailsById(requestData: any) {
    const body = {
      ...requestData,
    };
    return this.http
      .put(`/api/company/${requestData.id}/`, body)
      .pipe(map((response) => response));
  }
  enableDisableCompanyById(id: any) {
    const body = {};
    return this.http
      .patch(`/api/company/${id}/`, body)
      .pipe(map((response) => response));
  }
  enableDisableSiteById(id: any) {
    const body = {};
    return this.http
      .delete(`/api/site/${id}/`, body)
      .pipe(map((response) => response));
  }
  addCustomerContactById(requestData: any) {
    const body = {
      ...requestData.data,
    };
    return this.http
      .put(`/api/company/${requestData.id}/add_contact/`, body)
      .pipe(map((response) => response));
  }
  addSiteContactById(requestData: any) {
    const body = {
      ...requestData.data,
    };
    return this.http
      .put(`/api/site/${requestData.id}/add_contact/`, body)
      .pipe(map((response) => response));
  }
  fetchCustomerList(params?: any) {
    return this.http
      .get('/api/company/', { params: params })
      .pipe(map((response) => response));
  }
  getCompanyDetailById(id: any, params?: any) {
    return this.http.get(`/api/company/${id}/`, { params: params });
  }
  getCustomerContacts(id: any) {
    return this.http.get(`/api/company/${id}/contacts/`);
  }
  updateClient(id: any, body: any, params?: any) {
    return this.http
      .post(`/api/company/${id}/update/`, body, { params: params })
      .pipe(map((response) => response));
  }
  updateSite(id: any, body: any, params?: any) {
    return this.http
      .post(`/api/site/${id}/update/`, body, { params: params })
      .pipe(map((response) => response));
  }

  getCustomerReport(id: any, requestData: any) {
    return this.http.post(`/api/company/${id}/generate_report/`, requestData);
  }
  getSiteContacts(id: any, params?: any) {
    return this.http.get(`/api/site/${id}/contacts/`, { params: params });
  }
  searchLocationContacts(params: any) {
    return this.http.get(`/api/contacts/`, { params: params });
  }

  updateLocationContact(id: any, body: any, params?: any) {
    return this.http
      .put(`/api/contacts/${id}/`, body, { params: params })
      .pipe(map((response) => response));
  }
  getSites(params: any) {
    return this.http.get(`api/site/`, { params: params });
  }

  addSiteInClient(requestData: any) {
    const body = {
      ...requestData.data,
    };
    return this.http.post(`/api/site/`, body).pipe(map((response) => response));
  }
  updateSiteById(requestData: any, id: any) {
    const body = {
      ...requestData.data,
    };
    return this.http
      .put(`api/site/${id}/`, body)
      .pipe(map((response) => response));
  }
  getSiteById(id: any) {
    return this.http.get(`api/site/${id}/`);
  }
  deleteClientContact(id: any, contactId: any, contact_type: any) {
    let body = {
      contact_id: contactId,
      contact_type: contact_type,
    };
    return this.http.put(`api/company/${id}/delete_contact/`, body);
  }
  deleteSiteContact(id: any, contactId: any, contact_type: any) {
    let body = {
      contact_id: contactId,
      contact_type: contact_type,
    };
    return this.http.put(`api/site/${id}/delete_contact/`, body);
  }
  deleteLicense(id: any, is_subscriber_license: any) {
    return this.http.post(
      `api/security_license/${id}/delete_license/`,
      {},
      {
        params: { is_subscriber: is_subscriber_license },
      }
    );
  }
  editLocationContact(id: any, params?: any) {
    return this.http.get(`api/contacts/${id}/`, {
      params: params,
    });
  }

  downloadTemplateFile() {
    const downloadHttpOption = {
      responseType: 'blob' as 'json',
    };
    return this.http.get(`/api/file_import/`, downloadHttpOption);
  }

  //Monitoring Companies related APIs
  fetchMonitoringCompanyList(params?: any) {
    return this.http
      .get('/api/monitoring_company/', { params: params })
      .pipe(map((response) => response));
  }
  createMonitoringCompany(requestData?: any, params?: any) {
    return this.http
      .post('/api/monitoring_company/', requestData)
      .pipe(map((response) => response));
  }
  updateMonitoringCompanyDetailById(requestData: any) {
    const body = {
      ...requestData,
    };
    return this.http
      .put(`/api/monitoring_company/${requestData.id}/`, body)
      .pipe(map((response) => response));
  }
  deleteCompany(id: any, params?: any) {
    let body = {};
    return this.http.put(`api/monitoring_company/${id}/`, body, {
      params: params,
    });
  }

  //Response Type related APIs
  fetchResponseTypeList(params?: any) {
    return this.http
      .get('/api/response_type/', { params: params })
      .pipe(map((response) => response));
  }
  createResponseType(requestData?: any, params?: any) {
    return this.http
      .post('/api/response_type/', requestData)
      .pipe(map((response) => response));
  }
  updateResponseTypeDetailById(requestData: any) {
    const body = {
      ...requestData,
    };
    return this.http
      .put(`/api/response_type/${requestData.id}/`, body)
      .pipe(map((response) => response));
  }
  deleteResponseType(id: any, params?: any) {
    let body = {};
    return this.http.put(`api/response_type/${id}/`, body, {
      params: params,
    });
  }
  addContactSubscriberUser(requestData: any) {
    const body = {
      ...requestData.data,
    };
    return this.http
      .post(`/api/contacts/`, body)
      .pipe(map((response) => response));
  }
  //key audit apis
  getKeysAudit(body?: any, params?: any) {
    return this.http
      .post(`/api/contacts/`, body)
      .pipe(map((response) => response));
  }
}
