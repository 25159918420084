import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PagesService } from './pages.service';
import { ProfileService } from './profile/profile.service';

@Injectable()
export class NotificationCountResolver implements Resolve<number> {
  constructor(
    private profileService: ProfileService,
    private pagesService: PagesService
  ) {}

  resolve() {
    this.profileService.getNotificationCount().subscribe((response: any) => {
      if (response['status'] == 'success') {
        setTimeout(() => {
          this.pagesService.routeChangeSubject.next(response);
        }, 100);
      }
    });

    return 0;
  }
}
