<ng-container *ngIf="apidataUpdation === true && !offlineMode">
  <nb-alert class="mb-2" outline="basic">
    <small>
      Fetching Data from Server
      <div class="sc-floating-right">
        <div id="wave">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </small>
  </nb-alert>
</ng-container>

<nb-card *ngIf="detailData && !updateTask">
  <nb-card-body class="mobile-only">
    <div
      class="gt-p-small main-section"
      [ngStyle]="{
        'padding-bottom': scheduleCheck ? bottomSectionFixedHeight : null
      }"
    >
      <div
        class="sc-head4 pointer"
        (click)="openTemplate(updatePatrolNameTemplate, 'name')"
      >
        {{ detailData?.name }}
        <nb-icon
          *ngIf="isAdmin === true || isDispatchUser === true"
          pack="eva"
          icon="edit"
          class="color-basic-text ms-2"
        ></nb-icon>
      </div>

      <div>
        <ng-container *ngIf="patrolRouteStatus?.[patrolRouteStatusKey]?.value">
          <div class="badge-key">Status</div>
          <div [ngStyle]="patrolRouteStatus?.[patrolRouteStatusKey]?.style">
            {{ patrolRouteStatus?.[patrolRouteStatusKey]?.value }}
          </div>
        </ng-container>
        <div class="badge-key">Start Date</div>
        <div class="badge-value">
          {{
            detailData?.schedule_event_date
              ? (detailData?.schedule_event_date | customdate : "d MMM y")
              : (detailData?.roster_scheduler?.start_day
                | customdate : "d MMM y")
          }}
        </div>

        <div
          class="badge-key"
          (click)="
            (isAdmin === true || isDispatchUser === true) &&
            canScheduleBeUpdated
              ? openScheduleTemplate(changeScheduleTemplateRef)
              : ''
          "
        >
          Shift [Start - End]
          <nb-icon
            *ngIf="
              (isAdmin === true || isDispatchUser === true) &&
              canScheduleBeUpdated
            "
            pack="eva"
            icon="edit"
            class="color-basic-text ms-2"
          ></nb-icon>
        </div>
        <div class="badge-value">
          {{ detailData?.roster_scheduler?.start_time }} -
          {{ detailData?.roster_scheduler?.end_time }}
        </div>

        <div>
          <div class="badge-key">Job Duration</div>
          <div class="badge-value">{{ scheduleDuration }} Hours</div>
        </div>
        <div
          *ngIf="
            showWelfareItem &&
            (isAdmin || isDispatchUser || detailData?.interval_check > 0)
          "
        >
          <div
            class="badge-key"
            (click)="
              isAdmin === true || isDispatchUser === true
                ? openIntervalCheckTemplate(welfareIntervalTemplate)
                : ''
            "
          >
            Welfare Checks
            <nb-icon
              *ngIf="isAdmin === true || isDispatchUser === true"
              pack="eva"
              icon="edit"
              class="color-basic-text"
            ></nb-icon>
          </div>
          <div class="badge-value">{{ detailData?.interval_check }} mins</div>
        </div>
        <div *ngIf="isAdmin || isDispatchUser || detailData?.patrol_brief">
          <div
            class="badge-key"
            (click)="
              isAdmin === true || isDispatchUser === true
                ? openPatrolBriefInformationTemplate(requestRef)
                : ''
            "
          >
            Patrol Information
            <nb-icon
              *ngIf="isAdmin === true || isDispatchUser === true"
              pack="eva"
              icon="edit"
              class="color-basic-text"
            ></nb-icon>
          </div>
          <div class="badge-value content pre-line-text">
            {{ detailData?.patrol_brief ? detailData?.patrol_brief : "N/A" }}
          </div>
        </div>
        <div
          *ngIf="
            isAdmin || isDispatchUser || detailData?.patrol_brief_files?.length
          "
        >
          <div class="badge-key">
            <div>
              <label for="file-upload" class="patrol-brief-file">
                {{
                  isAdmin || isDispatchUser
                    ? "Attachments [" +
                      detailData?.patrol_brief_files?.length +
                      " / 3 Max]"
                    : "Attachments"
                }}
                <nb-icon
                  [pack]="'eva'"
                  [icon]="'file-add-outline'"
                  *ngIf="
                    (isAdmin || isDispatchUser) &&
                    detailData?.patrol_brief_files?.length < 3
                  "
                ></nb-icon>
              </label>
              <input
                *ngIf="
                  (isAdmin || isDispatchUser) &&
                  detailData?.patrol_brief_files?.length < 3
                "
                id="file-upload"
                type="file"
                accept="application/pdf, image/*"
                (change)="
                  (isAdmin || isDispatchUser) &&
                  detailData?.patrol_brief_files?.length < 3
                    ? handleFileInput($event)
                    : ''
                "
              />
            </div>
          </div>
          <div class="row row-cols-1 badge-value">
            <div
              *ngFor="let file of detailData?.patrol_brief_files; let i = index"
              class="col item-list pointer"
            >
              <div class="row row-cols-2">
                <div
                  class="col-10"
                  (click)="onOpenFile(file, previewTempRef)"
                  style="align-items: center; display: flex"
                >
                  <nb-badge
                    text="{{ file?.extension }}"
                    class="extension-badge"
                  >
                  </nb-badge>
                  File {{ i + 1 }}
                </div>
                <div class="col-2" *ngIf="isAdmin || isDispatchUser">
                  <nb-icon
                    class="sc-floating-right sc-mt-0"
                    nbSuffix
                    icon="trash"
                    pack="eva"
                    (click)="deleteFile(file)"
                  ></nb-icon>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="isAdmin === true || isDispatchUser === true">
          <div
            class="badge-key pointer"
            (click)="openTemplate(updateAssigneeTemplate, 'assignee')"
          >
            {{
              detailData?.subscriber_users?.length > 1
                ? "Assigned Guards"
                : "Assigned Guard"
            }}
            <nb-icon
              class="color-basic-text"
              nbSuffix
              icon="external-link-outline"
              pack="eva"
            ></nb-icon>
          </div>

          <div class="badge-value">
            <div class="row row-cols-auto">
              <div *ngIf="detailData?.subscriber_users?.length > 0">
                <div>
                  <span
                    class="pointer"
                    (click)="
                      openAssigneeDetail(detailData?.subscriber_users[0])
                    "
                  >
                    {{ detailData?.subscriber_users[0]?.full_name }}
                  </span>
                  <span
                    *ngIf="detailData?.subscriber_users?.length > 1"
                    class="color-basic-text strong pointer ms-3"
                    (click)="openTemplate(updateAssigneeTemplate, 'assignee')"
                  >
                    + {{ detailData?.subscriber_users?.length - 1 }} more</span
                  >
                </div>
              </div>

              <div
                (click)="openTemplate(updateAssigneeTemplate, 'assignee')"
                *ngIf="!detailData?.subscriber_users?.length"
                style="color: var(--color-danger-500)"
              >
                Not Assigned
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            class="badge-key"
            (click)="
              isAdmin === true || isDispatchUser === true
                ? openTemplate(updateCheckpointTemplate, 'checkpoint')
                : ''
            "
          >
            Checkpoints
            <nb-icon
              pack="eva"
              icon="edit"
              class="color-basic-text"
              *ngIf="isAdmin || isDispatchUser"
            ></nb-icon>
          </div>
          <div class="badge-value">
            <div
              *ngFor="let item of detailData?.check_points; let i = index"
              class="mb-4 d-flex align-items-start"
            >
              <div class="cp-scan-status" *ngIf="!item?.isScanned">
                <a
                  [href]="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    item?.address?.latitude +
                    ',' +
                    item?.address?.longitude
                  "
                  target="_blank"
                >
                  <nb-icon
                    class="cp-scanned-icon primary-500"
                    [icon]="'navigation-outline'"
                    pack="eva"
                  ></nb-icon>
                </a>
              </div>

              <div
                class="cp-scan-status"
                *ngIf="item?.isScanned && scheduleCheck"
              >
                <nb-icon
                  class="cp-scanned-icon"
                  pack="eva"
                  icon="checkmark"
                  status="success"
                ></nb-icon>
              </div>

              <div class="flex-grow-1 ms-3 me-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div>{{ item.name }}</div>
                    <div class="small">{{ item?.company_name }}</div>
                  </div>
                </div>
                <div class="small-font">{{ item?.address?.full_address }}</div>
              </div>

              <div *ngIf="item?.qr_code" class="me-3 cp-scanned-icon">
                <img
                  src="assets/images/qr-code-logo.png"
                  alt=""
                  width="26"
                  class="mb-0 pb-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="patrolLogUpdates?.length">
        <div class="badge-key">Updates</div>
        <div
          *ngFor="let msg of patrolLogUpdates.slice().reverse(); let i = index"
        >
          <div [ngClass]="msg?.file ? 'border-top' : ''">
            <!-- timestamp -->
            <div class="chat-info p-1">
              <span *ngIf="msg?.file">
                Photo
                {{ msg?.event_action === 7 ? "[Device Library]" : "[Camera]" }}
                |
              </span>
              {{ msg?.updated_at | customdate : "d MMM y HH:mm" }} |
              {{
                subscriberUserId == msg?.subs_user_id ? "You" : msg?.user_name
              }}
              <span
                *ngIf="
                  msg?.history_changes?.length &&
                  (subscriberUserId == msg?.subs_user_id ||
                    isAdmin ||
                    isDispatchUser)
                "
              >
                | Edited [{{
                  getLastCommentEditedDateTime(msg?.history_changes)?.updated_at
                    | customdate : "d MMM y HH:mm"
                }}]</span
              >
              <span class="float-end" *ngIf="msg?.cachedData">
                <nb-icon
                  icon="alert-triangle"
                  pack="eva"
                  status="warning"
                  nbTooltip="This update is not yet added to the server."
                >
                </nb-icon>
              </span>
            </div>

            <!-- comment / photo -->
            <div class="chat-data">
              <div
                (click)="
                  subscriberUserId == msg?.subs_user_id &&
                  !offlineMode &&
                  !msg?.cachedData &&
                  [1, 2].includes(msg?.event_action)
                    ? editComment(msg, commentRef)
                    : ''
                "
              >
                {{
                  msg?.detail === "null" || msg?.detail === "undefined"
                    ? ""
                    : msg?.detail
                }}
                <span *ngIf="msg?.event_action === 0" class="small-font"
                  >[Checkpoint Scan]
                </span>
                <span *ngIf="msg?.event_action === 6" class="small-font"
                  >[QR Scan]
                </span>
                <div
                  class="sc-floating-right"
                  *ngIf="
                    subscriberUserId == msg?.subs_user_id &&
                    !offlineMode &&
                    !msg?.cachedData &&
                    [1, 2].includes(msg?.event_action)
                  "
                >
                  <nb-icon pack="eva" icon="edit-outline"></nb-icon>
                </div>
              </div>
              <ng-container *ngIf="msg?.file">
                <ng-container *ngIf="msg?.cachedData">
                  <img
                    class="zoomImage"
                    [src]="getImgSrc(msg?.file)"
                    height="120"
                    width="120"
                /></ng-container>
                <ng-container *ngIf="!msg?.cachedData">
                  <img
                    class="zoomImage"
                    (click)="onOpenImage($event, imgRef)"
                    src="{{ msg?.file }}"
                    height="120"
                    width="120"
                /></ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div id="bottom-section-fixed" class="bottom-section" *ngIf="scheduleCheck">
      <ng-container *ngIf="hasQrCheckpoint">
        <div class="row row-cols-2 p-2">
          <div>
            <button
              nbButton
              fullWidth
              size="giant"
              status="primary"
              hero
              class="two-buttons-bottom nbbutton-long-text"
              (click)="updateTask = 'scanCheckPoint'"
            >
              Scan Checkpoint
            </button>
          </div>
          <div>
            <button
              nbButton
              fullWidth
              size="giant"
              status="primary"
              hero
              class="two-buttons-bottom nbbutton-long-text"
              (click)="openQRCode()"
            >
              Scan QR
            </button>
          </div>
        </div></ng-container
      >
      <ng-container *ngIf="!hasQrCheckpoint">
        <div class="px-2 my-0">
          <button
            nbButton
            fullWidth
            size="giant"
            status="primary"
            hero
            class="two-buttons-bottom"
            (click)="updateTask = 'scanCheckPoint'"
          >
            Scan Checkpoint
          </button>
        </div></ng-container
      >

      <div class="p-2">
        <div class="row row-cols-2">
          <div>
            <button
              class="nbbutton-long-text"
              nbButton
              fullWidth
              size="giant"
              status="primary"
              hero
              title="Add Comment"
              (click)="updateTask = 'addClientComment'"
            >
              <nb-icon
                icon="message-square-outline"
                pack="eva"
                style="color: var(--color-basic-300)"
              ></nb-icon>
            </button>
          </div>
          <div>
            <button
              class="nbbutton-long-text"
              nbButton
              fullWidth
              size="giant"
              title="Add Photo"
              hero
              status="primary"
              (click)="updateTask = 'addClientPhoto'"
            >
              <nb-icon
                icon="camera-outline"
                pack="eva"
                style="color: var(--color-basic-300)"
              ></nb-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-body class="large-view-only gt-p-1">
    <div
      class="sc-head4 pointer"
      (click)="openTemplate(updatePatrolNameTemplate, 'name')"
    >
      {{ detailData?.name }}
      <nb-icon
        *ngIf="isAdmin === true || isDispatchUser === true"
        pack="eva"
        icon="edit"
        class="color-basic-text ms-2"
      ></nb-icon>
    </div>
    <div class="sc-mt-6 row row cols-2">
      <div class="col">
        <ng-container *ngIf="patrolRouteStatus?.[patrolRouteStatusKey]?.value">
          <div class="badge-key">Status</div>
          <div [ngStyle]="patrolRouteStatus?.[patrolRouteStatusKey]?.style">
            {{ patrolRouteStatus?.[patrolRouteStatusKey]?.value }}
          </div>
        </ng-container>
        <div class="badge-key">Start Date</div>
        <div class="badge-value">
          {{
            detailData?.schedule_event_date
              ? (detailData?.schedule_event_date | customdate : "d MMM y")
              : (detailData?.roster_scheduler?.start_day
                | customdate : "d MMM y")
          }}
        </div>

        <div
          class="badge-key"
          (click)="
            (isAdmin === true || isDispatchUser === true) &&
            canScheduleBeUpdated
              ? openScheduleTemplate(changeScheduleTemplateRef)
              : ''
          "
        >
          Shift [Start - End]
          <nb-icon
            *ngIf="
              (isAdmin === true || isDispatchUser === true) &&
              canScheduleBeUpdated
            "
            pack="eva"
            icon="edit"
            class="color-basic-text ms-2"
          ></nb-icon>
        </div>
        <div class="badge-value">
          {{ detailData?.roster_scheduler?.start_time }} -
          {{ detailData?.roster_scheduler?.end_time }}
        </div>
        <div class="mb-4">
          <div
            class="badge-key"
            (click)="
              isAdmin === true || isDispatchUser === true
                ? openTemplate(updateCheckpointTemplate, 'checkpoint')
                : ''
            "
          >
            Checkpoints
            <nb-icon
              pack="eva"
              icon="edit"
              class="color-basic-text ms-2"
              *ngIf="isAdmin || isDispatchUser"
            ></nb-icon>
          </div>
          <div class="badge-value">
            <div
              *ngFor="let item of detailData?.check_points; let i = index"
              class="mb-4 d-flex align-items-start"
            >
              <div class="cp-scan-status" *ngIf="!item?.isScanned">
                <a
                  [href]="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    item?.address?.latitude +
                    ',' +
                    item?.address?.longitude
                  "
                  target="_blank"
                >
                  <nb-icon
                    class="cp-scanned-icon primary-500"
                    [icon]="'navigation-outline'"
                    pack="eva"
                  ></nb-icon>
                </a>
              </div>

              <div
                class="cp-scan-status"
                *ngIf="item?.isScanned && scheduleCheck"
              >
                <nb-icon
                  class="cp-scanned-icon"
                  pack="eva"
                  icon="checkmark"
                  status="success"
                ></nb-icon>
              </div>

              <div
                class="flex-grow-1 ms-3 me-2"
                [ngClass]="isAdmin || isDispatchUser ? 'pointer' : ''"
                (click)="
                  isAdmin || isDispatchUser ? viewCheckpointDetail(item) : null
                "
              >
                <div class="d-flex align-items-center">
                  <div>
                    <div>
                      {{ item.name }}
                      <span *ngIf="item?.qr_code"
                        ><img
                          src="assets/images/qr-code-logo.png"
                          alt=""
                          width="26"
                          class="mb-0 pb-0"
                      /></span>
                    </div>
                    <div class="small">{{ item?.company_name }}</div>
                  </div>
                </div>
                <div class="small-font">{{ item?.address?.full_address }}</div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="isAdmin === true || isDispatchUser === true">
          <div
            class="badge-key pointer"
            (click)="openTemplate(updateAssigneeTemplate, 'assignee')"
          >
            {{
              detailData?.subscriber_users?.length > 1
                ? "Assigned Guards"
                : "Assigned Guard"
            }}
            <nb-icon
              class="color-basic-text"
              nbSuffix
              icon="external-link-outline"
              pack="eva"
            ></nb-icon>
          </div>

          <div class="badge-value">
            <div class="row row-cols-auto">
              <div *ngIf="detailData?.subscriber_users?.length > 0">
                <div>
                  <span
                    class="pointer"
                    (click)="
                      openAssigneeDetail(detailData?.subscriber_users[0])
                    "
                  >
                    {{ detailData?.subscriber_users[0]?.full_name }}
                  </span>
                  <span
                    *ngIf="detailData?.subscriber_users?.length > 1"
                    class="color-basic-text strong pointer ms-3"
                    (click)="openTemplate(updateAssigneeTemplate, 'assignee')"
                  >
                    +
                    {{ detailData?.subscriber_users?.length - 1 }}
                    more</span
                  >
                </div>
              </div>

              <div
                (click)="openTemplate(updateAssigneeTemplate, 'assignee')"
                *ngIf="!detailData?.subscriber_users?.length"
                style="color: var(--color-danger-500)"
              >
                Not Assigned
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div>
          <div class="badge-key">Job Duration</div>
          <div class="badge-value">{{ scheduleDuration }} Hours</div>
        </div>

        <div
          *ngIf="
            showWelfareItem &&
            (isAdmin || isDispatchUser || detailData?.interval_check > 0)
          "
        >
          <div
            class="badge-key"
            (click)="
              isAdmin === true || isDispatchUser === true
                ? openIntervalCheckTemplate(welfareIntervalTemplate)
                : ''
            "
          >
            Welfare Checks
            <nb-icon
              *ngIf="isAdmin === true || isDispatchUser === true"
              pack="eva"
              icon="edit"
              class="color-basic-text"
            ></nb-icon>
          </div>
          <div class="badge-value">{{ detailData?.interval_check }} mins</div>
        </div>
        <div *ngIf="isAdmin || isDispatchUser || detailData?.patrol_brief">
          <div
            class="badge-key"
            (click)="
              isAdmin === true || isDispatchUser === true
                ? openPatrolBriefInformationTemplate(requestRef)
                : ''
            "
          >
            Patrol Information
            <nb-icon
              *ngIf="isAdmin === true || isDispatchUser === true"
              pack="eva"
              icon="edit"
              class="color-basic-text"
            ></nb-icon>
          </div>
          <div class="badge-value content pre-line-text">
            {{ detailData?.patrol_brief ? detailData?.patrol_brief : "N/A" }}
          </div>
        </div>
        <div
          *ngIf="
            isAdmin || isDispatchUser || detailData?.patrol_brief_files?.length
          "
        >
          <div class="badge-key">
            <div>
              <label for="file-upload" class="patrol-brief-file">
                {{
                  isAdmin || isDispatchUser
                    ? "Attachments [" +
                      detailData?.patrol_brief_files?.length +
                      " / 3 Max]"
                    : "Attachments"
                }}
                <nb-icon
                  [pack]="'eva'"
                  [icon]="'file-add-outline'"
                  *ngIf="
                    (isAdmin || isDispatchUser) &&
                    detailData?.patrol_brief_files?.length < 3
                  "
                ></nb-icon>
              </label>
              <input
                *ngIf="
                  (isAdmin || isDispatchUser) &&
                  detailData?.patrol_brief_files?.length < 3
                "
                id="file-upload"
                type="file"
                accept="application/pdf, image/*"
                (change)="
                  (isAdmin || isDispatchUser) &&
                  detailData?.patrol_brief_files?.length < 3
                    ? handleFileInput($event)
                    : ''
                "
              />
            </div>
          </div>
          <div class="row row-cols-1 badge-value">
            <div
              *ngFor="let file of detailData?.patrol_brief_files; let i = index"
              class="col item-list pointer"
            >
              <div class="row row-cols-2">
                <div
                  class="col-6"
                  (click)="onOpenFile(file, previewTempRef)"
                  style="align-items: center; display: flex"
                >
                  <nb-badge
                    text="{{ file?.extension }}"
                    class="extension-badge"
                  >
                  </nb-badge>
                  File {{ i + 1 }}
                </div>
                <div class="col-6" *ngIf="isAdmin || isDispatchUser">
                  <nb-icon
                    class="sc-mt-0"
                    nbSuffix
                    icon="trash"
                    pack="eva"
                    (click)="deleteFile(file)"
                  ></nb-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row row-cols-sm-1 row-cols-xl-2 mt-2 me-5"
      *ngIf="scheduleCheck"
    >
      <div class="col my-0 col-xl-4">
        <button
          nbButton
          fullWidth
          size="large"
          (click)="updateTask = 'addClientComment'"
        >
          + Add Comment
        </button>
      </div>
    </div>

    <ng-container *ngIf="isAdmin || isDispatchUser">
      <!-- TABLE -->
      <div
        [ngClass]="
          selectedEvent ? 'mt-4 row row-cols-1' : 'mt-4 row row-cols-2'
        "
      >
        <div
          [ngClass]="selectedEvent ? 'col-5 pointer' : 'col-12 pointer'"
          #target
        >
          <app-gt-table
            [desktopTableUIData]="patrolUpdateLog"
            [desktopTableDetails]="patrolLogTableDetail"
            [otherParams]="{ paginationData: true }"
            (rowClick)="onTableRowClick($event)"
          >
          </app-gt-table>
        </div>
        <div class="col-7 sc-mt-9" *ngIf="selectedEvent">
          <div
            [ngClass]="
              selectedEvent?.file ? 'row row-cols-md-2' : 'row row-cols-1'
            "
          >
            <div class="col-md-6" *ngIf="selectedEvent?.file">
              <img
                class="zoomImage"
                (click)="onOpenImage($event, imgRef)"
                *ngIf="selectedEvent?.file"
                src="{{ selectedEvent?.file }}"
                alt="unplash"
                style="max-height: 100%; max-width: 100%"
              />
            </div>
            <div [ngClass]="selectedEvent?.file ? 'col-md-6' : 'col'">
              <app-map-leaflet
                *ngIf="selectedEvent?.gps"
                [latLon]="selectedEvent?.gps"
                [scannedCheckpointDetails]="selectedEvent?.checkpoint_details"
                [mapId]="'jobHistoryLocation'"
              ></app-map-leaflet>
            </div>
          </div>
        </div>
      </div>

      <!-- SELECTED DETAILS -->
      <div
        class="row row-cols-2 mt-3"
        *ngIf="selectedEvent?.detail || selectedEvent?.device_details"
      >
        <div class="col">
          <div
            *ngIf="
              [1, 2, 5].includes(selectedEvent?.event_action) &&
              selectedEvent?.detail
            "
            class="mt-2"
          >
            <div class="chat-info">
              <strong>{{ selectedEvent?.action | titlecase }}</strong>
              <span
                *ngIf="
                  selectedEvent?.history_changes?.length &&
                  (subscriberUserId == selectedEvent?.subs_user_id ||
                    isAdmin ||
                    isDispatchUser)
                "
              >
                | Edited [{{
                  getLastCommentEditedDateTime(selectedEvent?.history_changes)
                    ?.updated_at | customdate : "d MMM y HH:mm"
                }}]</span
              >
            </div>
            <div
              class="chat-data fs-6"
              (click)="
                canEditComment(selectedEvent)
                  ? editComment(selectedEvent, commentRef)
                  : ''
              "
            >
              {{ selectedEvent?.detail }}
              <div
                class="sc-floating-right"
                *ngIf="canEditComment(selectedEvent)"
              >
                <nb-icon pack="eva" icon="edit-outline"></nb-icon>
              </div>
            </div>
          </div>
          <div class="row row-cols-1 row-cols-xxl-2">
            <ng-container *ngFor="let indColumn of patrolLogTableDetail">
              <ng-container
                *ngIf="
                  (indColumn?.nestedValue
                    ? indColumn?.nestedValue(selectedEvent)
                    : selectedEvent[indColumn.name]
                  )?.trim()
                "
              >
                <div>
                  <span class="badge-key" style="text-transform: uppercase">
                    {{ indColumn?.header }}</span
                  >
                  <ng-container *ngIf="indColumn?.datetimeObj == true">
                    <span class="badge-value">
                      {{
                        indColumn?.nestedValue
                          ? (indColumn?.nestedValue(selectedEvent)
                            | date
                              : (indColumn.dateTimeFormat
                                  ? indColumn.dateTimeFormat
                                  : "d MMM y HH:mm"))
                          : (selectedEvent[indColumn.name]
                            | date
                              : (indColumn.dateTimeFormat
                                  ? indColumn.dateTimeFormat
                                  : "d MMM y HH:mm"))
                      }}
                    </span></ng-container
                  >
                  <ng-container *ngIf="!indColumn?.datetimeObj">
                    <span class="badge-value">
                      {{
                        indColumn?.nestedValue
                          ? indColumn?.nestedValue(selectedEvent)
                          : selectedEvent[indColumn.name] == "null" ||
                            selectedEvent[indColumn.name] == "undefined"
                          ? "N/A"
                          : selectedEvent[indColumn.name]
                      }}
                    </span></ng-container
                  >
                </div>
              </ng-container>
            </ng-container>
            <div *ngIf="selectedEvent?.checkpoint_details?.min_distance">
              <span class="badge-key" style="text-transform: uppercase">
                Geofence Radius (metres)</span
              >
              <span class="badge-value">{{
                selectedEvent?.checkpoint_details?.min_distance
                  | number : "1.0-0"
              }}</span>
            </div>
          </div>
          <div
            *ngIf="scanDistanceInfo?.scanDistance"
            class="fw-bold text-danger"
          >
            <span class="badge-value">
              Scanned {{ scanDistanceInfo?.scanDistance }}M away from the
              Checkpoint</span
            >
          </div>
          <div
            *ngIf="selectedEvent?.recorded_at && !selectedEvent?.delayed_event"
            nbTooltip="This event was captured when the user was offline"
          >
            <span class="badge-key" style="color: brown">CAPTURED TIME</span>
            <span class="badge-value strong">
              {{ selectedEvent?.recorded_at | customdate : "d MMM y HH:mm" }}
            </span>
          </div>
          <ng-container *ngIf="selectedEvent?.prompts?.length">
            <div *ngFor="let item of selectedEvent?.prompts">
              <div class="sc-head6 sc-text-center">
                {{
                  item?.prompt_data?.name ? item?.prompt_data?.name : "Prompt"
                }}
              </div>

              <div *ngFor="let dataItem of item?.data">
                <ng-template
                  *ngTemplateOutlet="
                    promptTemplate;
                    context: { value: dataItem }
                  "
                ></ng-template>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col">
          <ng-container *ngIf="selectedEvent?.device_details">
            <div class="sc-head6">Device Details</div>
            <div class="row row-cols-1 row-cols-xxl-2">
              <div
                *ngFor="let item of selectedEvent?.device_details | keyvalue"
              >
                <div *ngIf="item?.value">
                  <span class="badge-key" style="text-transform: uppercase">
                    {{ item.key }}</span
                  >
                  <span class="badge-value"> {{ item.value }} </span>
                </div>
              </div>

              <div>
                <span
                  class="badge-key"
                  nbTooltip="{{browserGpsInfo?.[selectedEvent?.gps?.source]?.description}}"
                  nbTrigger="focus"
                  [ngClass]="browserGpsInfo?.[selectedEvent?.gps?.source] ? 'strong' : ''"
                  [nbTooltipDisabled]="!browserGpsInfo?.[selectedEvent?.gps?.source]"
                >
                  LOCATION
                </span>
                <span
                  class="badge-value"
                  nbTooltip="{{browserGpsInfo?.[selectedEvent?.gps?.source]?.description}}"
                  nbTrigger="focus"
                  [nbTooltipDisabled]="!browserGpsInfo?.[selectedEvent?.gps?.source]"
                  [ngClass]="browserGpsInfo?.[selectedEvent?.gps?.source] ? 'danger-text': ''"
                >
                  {{ selectedEvent?.gps?.lat }},
                  {{ selectedEvent?.gps?.lon }}
                </span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </nb-card-body>
</nb-card>
<div class="gt-p-small">
  <div
    *ngIf="
      [
        'scanCheckPoint',
        'addClientComment',
        'addClientPhoto',
        'updateJob'
      ].includes(updateTask)
    "
  >
    <app-update-events
      [detailType]="updateTask"
      [uploadType]="uploadType"
      [details]="{
        filterPatrolData: filterPatrolData,
        patrolRouteId: detailData?.id
      }"
      (emitData)="closeDetailedHome()"
    >
    </app-update-events>
  </div>
</div>

<ng-template
  #updateCheckpointTemplate
  let-data
  let-updateCheckpointTemplate="dialogRef"
>
  <nb-card class="gt-p-small" [ngClass]="largeView ? '' : 'dialog-box'">
    <nb-card-header>
      Select Checkpoints

      <span *ngIf="selectedCheckpoints?.length">
        [{{ selectedCheckpoints?.length }} Selected]
      </span>

      <nb-icon
        (click)="updateCheckpointTemplate.close()"
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
      ></nb-icon>
    </nb-card-header>

    <div class="row row-cols-2 my-3" *ngIf="!largeView">
      <div class="col">
        <button
          nbButton
          fullWidth
          size="giant"
          ghost
          (click)="updateCheckpointTemplate.close()"
        >
          cancel
        </button>
      </div>

      <div class="col">
        <button
          type="submit"
          nbButton
          fullWidth
          size="giant"
          [disabled]="checkpointUpdateDisabling()"
          (click)="updatePatrolRoute('checkpoint')"
        >
          Submit
        </button>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-lg-2">
      <div
        class="col order-1 order-lg-2"
        [ngStyle]="
          largeView ? { 'max-height': '80vh', 'overflow-y': 'auto' } : {}
        "
      >
        <table class="w-100">
          <tbody cdkDropList (cdkDropListDropped)="dropNewCheckPoint($event)">
            <tr
              *ngFor="let checkpoint of selectedCheckpoints; let i = index"
              class="pointer"
            >
              <td>
                <div class="d-flex">
                  <div class="me-2" cdkDrag>
                    <nb-icon [icon]="'menu-outline'" pack="eva"></nb-icon>
                  </div>
                  <div class="flex-grow-1">
                    {{ checkpoint?.name }} [{{
                      checkpoint?.company
                        ? checkpoint?.company
                        : checkpoint?.company_name
                    }}]
                    <div class="small-font">
                      {{ checkpoint?.address?.full_address }}
                    </div>
                  </div>
                  <div *ngIf="checkpoint?.qr_code" class="me-2">
                    <img
                      src="assets/images/qr-code-logo.png"
                      alt=""
                      width="26"
                      class="mb-0 pb-0"
                    />
                  </div>
                  <div (click)="removeSelectedCP(i)">
                    <button nbButton status="danger" size="small">Del</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="order-2 order-lg-1"
        [ngClass]="
          largeView
            ? selectedCheckpoints?.length
              ? 'col'
              : 'col col-lg-12'
            : 'col'
        "
        [ngStyle]="
          largeView ? { 'max-height': '80vh', 'overflow-y': 'auto' } : {}
        "
      >
        <ng-container *ngIf="!largeView">
          <hr />
        </ng-container>
        <table class="w-100">
          <div class="form-floating mt-2">
            <input
              class="form-control"
              type="text"
              nbTagInput
              fullWidth
              placeholder="Filter Checkpoints"
              (input)="searchCheckPoints($event)"
            />
            <label>Filter Checkpoints</label>
            <!-- USER LIST -->
          </div>
          <tbody>
            <tr *ngFor="let checkpoint of patrolRouteCPList" class="pointer">
              <td (click)="addSelectedCP(checkpoint)">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    {{ checkpoint?.name }} [{{ checkpoint?.company }}]
                    <div class="small-font">
                      {{ checkpoint?.address?.full_address }}
                    </div>
                  </div>
                  <div *ngIf="checkpoint?.qr_code" class="me-2">
                    <img
                      src="assets/images/qr-code-logo.png"
                      alt=""
                      width="26"
                      class="mb-0 pb-0"
                    />
                  </div>
                  <div>
                    <button nbButton status="primary" size="small">add</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="sc-mt-6"
          *ngIf="
            patrolRouteCpTotalPages > 1 &&
            patrolRouteCpTotalCount != patrolRouteCPList?.length
          "
        >
          <button
            type="button"
            nbButton
            fullWidth
            size="giant"
            (click)="onLoadMore()"
          >
            Load More
          </button>
        </div>
      </div>
    </div>

    <div class="row row-cols-2 my-3">
      <div class="col">
        <button
          nbButton
          fullWidth
          size="giant"
          ghost
          (click)="updateCheckpointTemplate.close()"
        >
          cancel
        </button>
      </div>

      <div class="col">
        <button
          type="submit"
          nbButton
          fullWidth
          size="giant"
          [disabled]="checkpointUpdateDisabling()"
          (click)="updatePatrolRoute('checkpoint')"
        >
          Submit
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>

<ng-template
  #updateAssigneeTemplate
  let-data
  let-updateAssigneeTemplate="dialogRef"
>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header>
      Select Assignees

      <span *ngIf="selectedAssignees?.length">
        [{{ selectedAssignees?.length }} Selected]
      </span>

      <nb-icon
        (click)="updateAssigneeTemplate.close()"
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <div>
      <div class="sc-mt-6">
        <div class="form-floating">
          <input
            class="form-control"
            id="mobassigneeField"
            fullWidth
            (input)="searchAssignee($event)"
            nbInput
            type="text"
            placeholder="Assign Guard"
            [nbAutocomplete]="assigneeNgModelDesk"
            autocomplete="off"
          />
          <label>Assign Guard</label>
        </div>
        <nb-autocomplete #assigneeNgModelDesk>
          <nb-option
            *ngFor="let assignee of assigneeSearchList"
            (click)="addSelectedAssignee(assignee)"
          >
            {{ assignee?.full_name }}
          </nb-option>
        </nb-autocomplete>

        <div class="sc-mt-6">
          <table>
            <tbody>
              <tr *ngFor="let assignee of assigneeList" class="pointer">
                <td>
                  <nb-checkbox
                    [checked]="assignee.selected"
                    (checkedChange)="addRemovessignee($event, assignee)"
                  >
                  </nb-checkbox>
                </td>
                <td (click)="addRemovessignee(!assignee?.selected, assignee)">
                  {{ assignee?.full_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row row-cols-1 sc-mt-6">
        <div class="col">
          <button
            type="submit"
            nbButton
            fullWidth
            size="giant"
            [disabled]="assigneeUpdateDisabling()"
            (click)="updatePatrolRoute('assignee')"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </nb-card>
</ng-template>

<ng-template
  #changeScheduleTemplateRef
  let-changeScheduleTemplateRef="dialogRef"
>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center">
      Change Schedule
      <nb-icon
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
        (click)="changeScheduleTemplateRef.close()"
      >
      </nb-icon>
    </nb-card-header>
    <form [formGroup]="updateSchedulerForm">
      <div class="sc-mt-6">
        <div class="row my-0 row-cols-2">
          <div class="form-floating">
            <input
              nbInput
              class="form-control"
              fullWidth
              id="endDay"
              placeholder="End Date"
              [nbDatepicker]="dateTimePicker1"
              formControlName="end_day"
              [(ngModel)]="endDate"
            /><label for="inputAssignee">End</label>
            <nb-datepicker
              (dateChange)="formateEndDate($event)"
              #end_day
              #dateTimePicker1
              [min]="validStartDate"
            ></nb-datepicker>
          </div>
          <div class="form-floating">
            <input
              nbInput
              class="form-control"
              type="text"
              placeholder="Job End Time"
              fullWidth
              id="endTime"
              [(ngModel)]="endTime"
              formControlName="end_time"
              [ngxMatTimepicker]="timePicker1"
            /><label for="inputAssignee">Job End Time</label>
            <ngx-mat-timepicker
              (timeSet)="formatEndTime($event)"
              #timePicker1
            ></ngx-mat-timepicker>
          </div>
        </div>
      </div>
    </form>
    <div class="sc-mt-6">
      <button
        (click)="updateSchedule(); changeScheduleTemplateRef.close()"
        nbButton
        fullWidth
        size="giant"
      >
        Update schedule
      </button>
    </div>
  </nb-card>
</ng-template>

<ng-template
  #updatePatrolNameTemplate
  let-data
  let-updatePatrolNameTemplate="dialogRef"
>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header>
      Update Patrol Name

      <nb-icon
        (click)="updatePatrolNameTemplate.close()"
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
      ></nb-icon>
    </nb-card-header>

    <div class="form-floating sc-mt-6">
      <input
        class="form-control"
        id="mobassigneeField"
        fullWidth
        [(ngModel)]="newPatrolName"
        nbInput
        type="text"
        autocomplete="off"
        placeholder="Patrol Route Name"
      />
      <label>Patrol Route Name</label>
    </div>

    <div class="row row-cols-1 sc-mt-6">
      <div class="col">
        <button
          type="submit"
          nbButton
          fullWidth
          size="giant"
          [disabled]="newPatrolName === detailData?.name"
          (click)="updatePatrolRoute('name')"
        >
          Submit
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>

<ng-template
  #welfareIntervalTemplate
  let-data
  let-welfareIntervalTemplate="dialogRef"
>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center">Welfare Checks </nb-card-header>

    <nb-select
      class="welfare-check-dropdown"
      fullWidth
      placeholder="Welfare Checks"
      size="giant"
      [selected]="selectedInterval"
    >
      <nb-option
        *ngFor="let interval of intervalData"
        [value]="interval.value"
        (click)="updateInterval(interval.value)"
        >{{ interval.name }}</nb-option
      >
    </nb-select>

    <div class="row row-cols-1 sc-mt-6">
      <div class="col">
        <button
          nbButton
          fullWidth
          size="giant"
          ghost
          (click)="welfareIntervalTemplate.close()"
        >
          Cancel
        </button>
      </div>
    </div>

    <!-- Request Detail Section end-->
  </nb-card>
</ng-template>

<!-- ADD COMMENT POPOVER / DIALOG -->
<ng-template #commentRef let-commentRef="dialogRef" let-data>
  <nb-card class="dialog-box">
    <nb-card-header class="sc-text-center">
      {{ data?.detail ? "Edit Comment" : "Add Comment" }}

      <nb-icon
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
        (click)="commentRef.close(); newComment = ''"
      >
      </nb-icon>
    </nb-card-header>
    <div class="row-cols-1 gy-2">
      <div class="col">
        <textarea
          class="form-control"
          rows="10"
          cols="32"
          wrap="hard"
          maxlength="340"
          type="text"
          placeholder="Start typing"
          [(ngModel)]="newComment"
        ></textarea>
      </div>
      <div class="col sc-mt-6">
        <button
          (click)="addComment(data); commentRef.close()"
          nbButton
          size="giant"
          fullWidth
          [disabled]="
            data?.detail
              ? !newComment || previousComment === newComment
              : !newComment
          "
        >
          {{ data?.detail ? "Update" : "Add" }}
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>
<ng-template #imgRef let-data let-imgTempRef="dialogRef">
  <nb-card style="margin: 0">
    <nb-card-body (click)="imgTempRef.close()">
      <img
        src="{{ fullImgSrc }}"
        style="
          max-width: 100%;
          cursor: pointer;
          max-height: 95vh;
          border: 3px var(--background-basic-color-3) solid;
          border-radius: 2%;
        "
        class="modal-hover-opacity"
      />
    </nb-card-body>
  </nb-card>
</ng-template>
<!--enlarge image template-->

<ng-template #previewTempRef let-data let-previewTempRef="dialogRef">
  <nb-card style="margin: 0" class="gt-p-small">
    <nb-card-header>
      <div>
        {{ data?.description }}
        <div class="sc-floating-right">
          <nb-icon
            pack="eva"
            icon="close-outline"
            (click)="previewTempRef.close()"
          ></nb-icon>
        </div>
      </div>
    </nb-card-header>
    <div>
      <div style="position: absolute; left: 50%; top: 50%" *ngIf="!hideSpinner">
        <div id="wave">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>

      <img
        src="{{ data?.file_url }}"
        style="max-width: 100%; max-height: 90vh"
        class="modal-hover-opacity"
        (load)="hideSpinner = true"
      />
    </div>
  </nb-card>
</ng-template>
<!--enlarge image template-->

<ng-template #requestRef let-requestRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center">
      Patrol Brief Information</nb-card-header
    >

    <!-- Request Detail Section start-->
    <div class="row">
      <div class="col">
        <textarea
          class="form-control"
          placeholder="  Patrol Brief Information"
          cols="20"
          rows="5"
          wrap="hard"
          fullWidth
          type="text"
          [(ngModel)]="newInfromation"
        ></textarea>
      </div>
    </div>

    <div class="row row-cols-2 sc-mt-6">
      <div class="col">
        <button
          nbButton
          fullWidth
          size="giant"
          ghost
          (click)="requestRef.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          nbButton
          size="giant"
          [disabled]="newInfromation === detailData?.patrol_brief"
          fullWidth
          (click)="updatePatrolRoute('patrol_brief'); requestRef.close()"
        >
          Update
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>

<!--looped prompt template-->
<ng-template let-dataItem="value" #promptTemplate>
  <div *ngIf="dataItem?.description">
    <span class="badge-key" style="text-transform: uppercase">
      {{ dataItem?.description }}</span
    >
    <!--TODO: convert into pipe-->
    <span class="badge-value"> {{ getPromptValue(dataItem) }} </span>
  </div>

  <div *ngFor="let option of dataItem?.options">
    <ng-template
      *ngTemplateOutlet="promptTemplate; context: { value: option }"
    ></ng-template>
  </div>
</ng-template>
