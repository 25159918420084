import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { formatDateTimeStamp } from 'src/global.variables';
import { PagesService } from './../pages.service';
import { AddEditUsersComponent } from './add-edit-users/add-edit-users.component';
import { UsersService } from './users.service';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  globalFilter: any;
  showTypeAhead: boolean = true;
  users: any;

  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRegisteredUsers: number = 0;
  userPaginationData: any = {};
  totalUserCount: any;
  nbTabsLicnese = { tab1: 'Licenced', tab2: 'Unlicenced' };
  nbTabsUsers = { tab1: 'Guards', tab2: 'Groups' };
  viewType: string = 'cardView';
  userDetailsTableView: any = [
    {
      header: 'Name',
      name: 'full_name',
      sortKey: 'full_name',
    },

    {
      header: 'Email',
      name: 'email',
    },
    {
      header: 'Group',
      name: 'user_group',
      nestedValue: (row: any) => {
        let userGroups = [
          ...row?.['user_group']?.map((item: any) => {
            return item?.name;
          }),
        ].join(', ');

        return userGroups;
      },
      sortKey: 'user_group',
      cardRowStyle: {
        'font-weight': 'bold',
      },
    },
    {
      header: 'Last Update',
      name: 'last_activity_time',
      datetimeObj: true,
      sortKey: 'last_activity_time',
    },
    {
      header: 'Clocked In At',
      name: 'clocked_in_time',
      sortKey: 'clocked_in_time',
      nestedValue: (row: any) => {
        if (row?.user_status === 'pending' && !row?.clocked_in_time) {
          return `[USER NOT SIGNED-IN YET]`;
        } else if (row?.clocked_in_time !== null) {
          return String(
            formatDateTimeStamp(row?.clocked_in_time, 'd MMM y hh:mm', 'en_US')
          );
        }
        return '';
      },
      conditionalColumnStyle: (row: any) => {
        if (row?.['user_status'] === 'pending' && !row?.clocked_in_time) {
          return {
            color: 'var(--color-danger-300)',
          };
        } else {
          return {};
        }
      },
    },
  ];
  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };
  userCardDetails = {
    individualDetails: [
      {
        nestedValue: (row: any) => {
          if (row?.['user_status'] == 'pending')
            return '[USER NOT SIGNED-IN YET]';
          else return;
        },
        cardRowStyle: {
          color: 'var(--color-danger-300)',
        },
      },

      {
        name: 'full_name',
        cardRowStyle: {
          'font-weight': 'bolder',
          'word-wrap': 'break-word',
        },
      },
      {
        name: 'email',
        cardRowStyle: {
          'font-size': 'small',
          'word-wrap': 'break-word',
        },
      },
      {
        nestedValue: (row: any) => {
          let userGroups = [
            ...row?.['user_group']?.map((item: any) => {
              return item?.name;
            }),
          ].join(', ');

          return userGroups;
        },
        cardRowStyle: {
          'font-size': '0.75rem',
          'white-space': 'pre-line',
          'font-style': 'oblique',
        },
      },
      {
        prefixNestedValue: (row: any) => {
          return row?.last_activity_time ? 'Last update at ' : '';
        },
        name: 'last_activity_time',
        dateTimeFormat: 'd MMM y HH:mm',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': '0.75rem',
          'word-wrap': 'break-word',
          'margin-top': '0.5rem',
          'padding-top': '0',
        },
        conditionalRowDataStyle: (row: any) => {
          if (row?.last_activity_time) {
            return { 'font-weight': 600 };
          }
          return {};
        },
      },
      {
        prefixNestedValue: (row: any) => {
          return row?.clocked_in_time ? 'Clocked In at ' : '';
        },
        name: 'clocked_in_time',
        dateTimeFormat: 'd MMM y HH:mm',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': '0.75rem',
          'word-wrap': 'break-word',
          'margin': '0',
          'padding-top': '0',
        },
        conditionalRowDataStyle: (row: any) => {
          if (row?.clocked_in_time) {
            return { 'font-weight': 600 };
          }
          return {};
        },
      },
    ],
  };

  searchRemovalCap: number = 5;
  userCount: number = 0;
  userData: any;
  dialogref: any;
  isAdmin: any;

  userGroupTab: boolean = false;
  sortKey: any;
  searchFilters: any;
  allotedUsers: any;
  totalAddedUsers: any;
  isSubscriberAdmin: boolean = false;
  isTestAccount: boolean = this.dataCheckService.isTestAccount();
  recommendedPlan: any;

  constructor(
    private route: ActivatedRoute,
    private userService: UsersService,
    private dialog: NbDialogService,
    private router: Router,
    private appService: AppService,
    private spinnerService: NgxSpinnerService,
    private dataCheckService: DataCheckService,
    private pagesService: PagesService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
      }
    });
    this.userData = this.appService.getUserData();
    this.isAdmin = this.dataCheckService.isUserAdmin();

    this.isSubscriberAdmin = this.dataCheckService.isSubscriberAdmin();

    if (JSON.parse(localStorage.getItem('appChecks') || '{}')?.userCount) {
      this.totalRegisteredUsers = JSON.parse(
        localStorage.getItem('appChecks') || '{}'
      )?.userCount;
    }
  }
  ngOnInit(): void {
    let userData = this.appService.getUserData();
    this.pageSize = userData?.preferences?.user_page_size || 10;
    this.getUsers();
    if (window.innerWidth >= 770) {
      this.viewType = 'tableView';
    } else {
      this.viewType = 'cardView';
    }
  }
  onFilterSearch() {
    let params: any = { rows: 5, globalFilter: this.globalFilter };

    this.userService.getUserData(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        if (window.innerWidth >= 770) {
          this.viewType = 'tableView';
        } else {
          this.viewType = 'cardView';
        }
        this.users = response['data'];
      }
    });
  }

  onFilterApply(event: any) {
    if (event?.target?.value && event?.target?.value?.length >= 3) {
      this.onFilterSearch();
      this.showTypeAhead = true;
    }
    if (
      event === true &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.previous = 0;
      this.userPaginationData = {};
      this.getUsers();
      this.showTypeAhead = false;
    } else if (
      event.key === 'Enter' &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.previous = 0;

      this.userPaginationData = {};
      this.getUsers();
      this.showTypeAhead = false;
    }
  }
  onClearFilter() {
    this.clearValues();
    this.sortKey = null;
    this.searchFilters = null;
    this.getUsers();
  }
  getUsers(val = false) {
    let params: any = {};
    if (this.pageSize) {
      params['rows'] = this.pageSize;
      let userData = this.appService.getUserData();
      if (userData?.preferences) {
        userData.preferences.user_page_size = this.pageSize;
        this.appService.setUserData(userData);
      }
    }

    if (this.previous) {
      params['previous'] = this.previous;
    }
    if (this.globalFilter) {
      params['globalFilter'] = this.globalFilter;
    }

    if (this.searchFilters) {
      Object.entries(this.searchFilters).forEach(
        ([key, value]) => (params[key] = value)
      );
    }

    if (this.sortKey) {
      params['sort_key'] = this.sortKey;
    }

    if (!this.userPaginationData?.[this.pageNum]?.length || this.globalFilter) {
      this.spinnerService.show();
      this.userService.getUserData(params).subscribe((response: any) => {
        if (response['status'] == 'success') {
          if (val) {
            if (window.innerWidth >= 770) {
              this.viewType = 'tableView';
            } else {
              this.viewType = 'cardView';
            }
            this.users = response['data'];
          } else {
            this.recommendedPlan = response?.recommended_plan;
            this.userPaginationData[this.pageNum] = [...response['data']];

            this.totalUserCount = response['total_user_count'];
            this.userCount = response['total_size'];
            this.totalAddedUsers = response['total_user_count'];
            this.allotedUsers = response['allotted_slots'];
            this.route.params.subscribe({
              next: (params: any) => {
                if (this.route.snapshot.fragment) {
                  if (this.route.snapshot.fragment === 'userAdded') {
                    this.router.navigate(['/users']);
                    this.openAddUserForm();
                  }
                }
              },
            });
            if (this.totalUserCount === 0) {
              this.openAddUserForm();
            }

            localStorage.setItem(
              'appChecks',
              JSON.stringify({
                userCount: this.totalUserCount || 0,
              })
            );
            this.totalPages = Math.ceil(this.totalUserCount / this.pageSize);
          }
        }
        this.spinnerService.hide();
      });
    }
  }

  rowClick(event: any) {
    this.dialogref = this.dialog.open(AddEditUsersComponent, {
      context: {
        editUser: true,
        detailData: event,
        fromUserPage: true,
      },
    });
    this.dialogref.onClose.subscribe((value: any) => {
      this.userPaginationData = {};
      this.getUsers();
    });
  }
  changePlan() {
    if (this.isSubscriberAdmin) {
      this.router.navigate(['/subscription'], {
        fragment: 'changePlan',
      });
    }
  }
  openAddUserForm() {
    if (this.totalAddedUsers >= this.allotedUsers && !this.isTestAccount) {
      if (!this.isSubscriberAdmin) {
        this.pagesService.setMessage({
          successMessage: '',
          errorMessage:
            'Warning: Users exceeded than allowed slots, Contact your Admin to extend more slots!',
        });
      } else {
        this.router.navigate(['/subscription'], {
          fragment: 'addUser',
        });
      }
    } else {
      this.dialogref = this.dialog.open(AddEditUsersComponent, {
        context: { fromUserPage: true },
      });
      this.dialogref.onClose.subscribe((value: any) => {
        if (value !== 'close') {
          this.userPaginationData = {};
          this.getUsers();
        }
      });
    }
  }

  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.getUsers();
    if (this.globalFilter) this.showTypeAhead = false;
  }
  onChangePagination(event: any) {
    this.userPaginationData = {};
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.getUsers();
    if (this.globalFilter) this.showTypeAhead = false;
  }
  adminCheck(user: any) {
    if (user?.user_group?.some((grp: any) => grp?.name === 'Administrators')) {
      return true;
    }
    return false;
  }

  openTemplate(template: TemplateRef<any>, eventData: any) {
    this.dialogref = this.dialog.open(template, {
      context: eventData,
    });
  }

  changeTab(event: any) {
    if (event?.tabTitle === this.nbTabsUsers.tab2) {
      this.userGroupTab = true;
    }
  }

  sortColumn(body: any) {
    this.sortKey = body?.sortList || [];
    this.clearValues();
    this.getUsers();
  }
  searchColumn(event: any) {
    this.searchFilters = event?.searchParams;
    this.clearValues();
    this.getUsers();
  }
  clearValues() {
    this.previous = 0;
    this.previous = 0;
    this.pageNum = 1;
    this.pageNum = 1;
    this.globalFilter = '';
    this.userPaginationData = {};
    this.showTypeAhead = true;
  }
}
