import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateTimePickerComponent } from 'src/app/shared/comp/date-time-picker/date-time-picker.component';
import { formatDateTimeStamp } from 'src/global.variables';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.scss'],
})
export class UserHistoryComponent implements OnInit {
  appUserHistory: any = [];
  browserGpsInfo: any = {
    0: 'High Accuracy',
    1: 'Low Accuracy',
    2: 'IP',
  };
  appUserHistoryTable: any = [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      sortKey: 'updated_at',
      dateTimeFormat: 'd MMM y HH:mm:ss',
    },
    {
      header: 'Event Type',
      name: 'event_type',
    },
    {
      header: 'Source',
      nestedValue: (row: any) => {
        return this.browserGpsInfo?.[row?.gps?.source];
      },
    },
  ];
  otherParamsUserHistory: any = {};
  previousUserHistory: number = 0;
  rowsUserHistory: number = 20;
  pageNumUserHistory: number = 1;
  totalPagesUserHistory: number = 0;
  totalRowsUserHistory: number = 0;
  selectedUserId: any;
  selectedUserName: any;
  selectedHistory: any = {};
  dateRangeValue: string = '';
  dateRange: any = { start: Date, end: Date };
  sortKey: string = '-updated_at';
  constructor(
    private adminService: AdminService,
    private dialog: NbDialogService,
    private toasterService: NbToastrService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe({
      next: (params) => {
        if (params?.['uKey']) {
          this.selectedUserId = params?.['uKey'];
          this.selectedUserName = params?.['fullName'];
        }
      },
    });
  }

  ngOnInit(): void {
    const today = new Date();
    today.setDate(new Date().getDate() - 1);

    this.dateRange = {
      start: today,
      end: new Date(),
    };

    this.dateRangeValue = `${String(
      formatDateTimeStamp(this.dateRange?.start, 'd MMM y HH:mm', 'en_US')
    )} - ${String(
      formatDateTimeStamp(this.dateRange?.end, 'd MMM y HH:mm', 'en_US')
    )}`;
    this.spinnerService.show();
    this.getLocationHistory(this.selectedUserId);
  }
  selectHistoryEvent(event: any) {
    this.selectedHistory = null;
    setTimeout(() => {
      this.selectedHistory = event;
    }, 200);
  }
  openRangePicker() {
    const dialogeRef = this.dialog.open(DateTimePickerComponent, {
      context: {
        dateRange: this.dateRange,
        showFrom: 'tablePage',
      },
    });
    dialogeRef.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.dateRange = value;
        this.appUserHistory = [];
        this.previousUserHistory = 0;
        this.rowsUserHistory = 20;
        this.pageNumUserHistory = 1;
        this.totalPagesUserHistory = 0;
        this.totalRowsUserHistory = 0;
        this.spinnerService.show();
        this.getLocationHistory(this.selectedUserId);
        this.dateRangeValue = `${String(
          formatDateTimeStamp(this.dateRange?.start, 'd MMM y HH:mm', 'en_US')
        )} - ${String(
          formatDateTimeStamp(this.dateRange?.end, 'd MMM y HH:mm', 'en_US')
        )}`;
      }
    });
  }
  sortData() {
    this.appUserHistory = [];
    this.previousUserHistory = 0;
    this.rowsUserHistory = 20;
    this.pageNumUserHistory = 1;
    this.totalPagesUserHistory = 0;
    this.totalRowsUserHistory = 0;
    this.sortKey = this.sortKey === 'updated_at' ? '-updated_at' : 'updated_at';
    this.getLocationHistory(this.selectedUserId);
  }

  onSliderChangePagination(event: any) {
    // this.appUserHistory = [];

    this.previousUserHistory = event.previous;

    this.rowsUserHistory = event.pageSize;
    this.getLocationHistory(this.selectedUserId);
  }
  getLocationHistory(userId: any) {
    this.selectedUserId = userId;
    let params = {
      sub_user_id: userId,
      rows: this.rowsUserHistory,
      previous: this.previousUserHistory,
      location_history: 1,

      start_date: this.dateRange.start?.getTime() / 1000,
      end_date: this.dateRange.end?.getTime() / 1000,
      sort_key: this.sortKey,
    };

    this.adminService.getAppusers(params).subscribe((res: any) => {
      this.appUserHistory = JSON.parse(JSON.stringify([...res?.data]));
      this.totalRowsUserHistory = res?.total_size;
      this.totalPagesUserHistory = Math.ceil(
        res?.total_size / this.rowsUserHistory
      );
      this.otherParamsUserHistory.paginationData = {
        tableTotalRows: res?.total_size,
        tablePreviousRows: this.previousUserHistory,
        tableRows: this.rowsUserHistory,
        tablePageNum: this.pageNumUserHistory,
        tableTotalPages: this.totalPagesUserHistory,
      };
      this.spinnerService.hide();
    });
  }

  onChangeUserHistoryPagination(data: any) {
    this.appUserHistory = [];
    this.previousUserHistory = data?.paginationData?.tablePreviousRows;
    this.pageNumUserHistory = data?.paginationData?.tablePageNum;
    this.rowsUserHistory = data?.paginationData?.tableRows;

    this.otherParamsUserHistory.paginationData = {
      ...data?.paginationData,
    };
    this.spinnerService.show();
    this.getLocationHistory(this.selectedUserId);
  }
}
