import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbAuthModule } from '@nebular/auth';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbActionsModule,
  NbAlertModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbFormFieldModule,
  NbGlobalLogicalPosition,
  NbIconModule,
  NbInputModule,
  NbSelectModule,
  NbSidebarModule,
  NbStepperModule,
  NbThemeModule,
  NbToastrModule,
  NbTooltipModule,
} from '@nebular/theme';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ErrorPage503Component } from '../error-page503/error-page503.component';
import { AuthRoutingModule } from './auth-routing.module';
import { BetaRegisterComponent } from './beta-register/beta-register.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ExternalAppComponent } from './external-app/external-app.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RegistrationComponent } from './registration/registration.component';

@NgModule({
  declarations: [
    LoginComponent,
    DeleteAccountComponent,
    ExternalAppComponent,
    PageNotFoundComponent,
    ErrorPageComponent,
    ErrorPage503Component,
    BetaRegisterComponent,
    RegistrationComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    NbAuthModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NbInputModule,
    NbFormFieldModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NbEvaIconsModule,
    NbIconModule,
    NbCardModule,
    NbSelectModule,
    NbActionsModule,
    NbAutocompleteModule,
    NbSidebarModule.forRoot(),
    NbThemeModule.forRoot(),
    NbToastrModule.forRoot({
      position: NbGlobalLogicalPosition.TOP_START,
      status: 'basic',
      icon: '',
      destroyByClick: true,
      duration: 10000,
    }),
    NbStepperModule,
    NbTooltipModule,
  ],
})
export class AuthModule {}
