<nb-card class="gt-p-small">
  <nb-tabset>
    <nb-tab tabTitle="Missed Welfare Check">
      <div class="mobile-only" *ngIf="!selectedMissedWelfare">
        <div class="col form-floating sc-mt-6">
          <input
            class="form-control"
            size="giant"
            nbInput
            fullWidth
            placeholder="Pick Date Range"
            readonly
            [value]="dateRangeValueMissedWelfareCheck"
            (click)="openRangePicker()"
          />
          <label>Pick Date Range</label>
        </div>
        <div class="sc-mt-6">
          <app-card-list
            *ngIf="missedWelfareData?.length"
            [listCardData]="missedWelfareData"
            [listCardDetails]="mobileDetailMissedWelfareCheck"
            (rowClick)="selectedMissedWelfare = $event"
            [showCallGuardButton]="true"
          ></app-card-list>
          <div *ngIf="totalPagesMissedWelfareCheck > 0">
            <app-pagination-control
              [pageSize]="pageSizeMissedWelfareCheck"
              [pageNum]="pageNumMissedWelfareCheck"
              [previous]="previousMissedWelfareCheck"
              [totalRows]="totalRowsMissedWelfareCheck"
              [totalPages]="totalPagesMissedWelfareCheck"
              (onClickPagination)="
                onChangePaginationMobileMissedWelfareCheck($event)
              "
              (onChangePagination)="
                onChangePaginationMobileMissedWelfareCheck($event)
              "
            >
            </app-pagination-control>
          </div>
        </div>
      </div>
      <div class="large-view-only">
        <app-gt-table
          [desktopTableBackendData]="missedWelfareData"
          [desktopTableDetails]="tableDetailMissedWelfareCheck"
          [otherParams]="otherParamsMissedWelfareCheck"
          (searchColumn)="searchColumnMissedWelfareCheck($event)"
          (sortColumn)="sortColumnMissedWelfareCheck($event)"
          (paginationChange)="onChangePaginationMissedWelfareCheck($event)"
          (rowClick)="selectedMissedWelfare = $event"
        >
        </app-gt-table>
      </div>
      <div *ngIf="missedWelfareData?.length == 0" class="my-3">
        <nb-alert outline="basic" style="font-weight: 500"
          >No Records Match Your Search Criteria</nb-alert
        >
      </div>
      <div class="sc-mt-9" *ngIf="selectedMissedWelfare">
        <div class="mobile-only">
          <app-card-list
            [listCardData]="[selectedMissedWelfare]"
            [listCardDetails]="mobileDetailMissedWelfareCheck"
          ></app-card-list>
        </div>

        <div class="mt-2">
          <app-map-leaflet
            *ngIf="selectedMissedWelfare?.gps"
            [latLon]="selectedMissedWelfare?.gps"
            mapId="missedWelfareItemMobile"
          ></app-map-leaflet>
        </div>

        <div class="sc-text-center small-font">
          {{ selectedMissedWelfare?.gps?.lat }},
          {{ selectedMissedWelfare?.gps?.lon }}
        </div>
        <div class="my-2 fixed-back-btn mobile-only">
          <button
            nbButton
            size="giant"
            fullWidth
            status="primary"
            (click)="selectedMissedWelfare = null"
          >
            Back
          </button>
        </div>
      </div>
    </nb-tab>
    <nb-tab tabTitle="Missed Checkpoints">
      <div class="mobile-only">
        <div class="col form-floating sc-mt-6">
          <input
            class="form-control"
            size="giant"
            nbInput
            fullWidth
            placeholder="Pick Date Range"
            readonly
            [value]="dateRangeValueMissedCheckpoint"
            (click)="openRangePickerMissedCheckpoint()"
          />
          <label>Pick Date Range</label>
        </div>
        <div class="sc-mt-6">
          <app-card-list
            *ngIf="missedCheckpointData?.length"
            [listCardData]="missedCheckpointData"
            [listCardDetails]="mobileDetailMissedCheckpoint"
          ></app-card-list>
          <div *ngIf="totalPagesMissedCheckpoint > 0">
            <app-pagination-control
              [pageSize]="pageSizeMissedCheckpoint"
              [pageNum]="pageNumMissedCheckpoint"
              [previous]="previousMissedCheckpoint"
              [totalRows]="totalRowsMissedCheckpoint"
              [totalPages]="totalPagesMissedCheckpoint"
              (onClickPagination)="
                onChangePaginationMobileMissedCheckpoint($event)
              "
              (onChangePagination)="
                onChangePaginationMobileMissedCheckpoint($event)
              "
            >
            </app-pagination-control>
          </div>
        </div>
      </div>
      <div class="large-view-only">
        <app-gt-table
          [desktopTableBackendData]="missedCheckpointData"
          [desktopTableDetails]="tableDetailMissedCheckpoint"
          [otherParams]="otherParamsMissedCheckpoint"
          (searchColumn)="searchColumnMissedCheckpoint($event)"
          (sortColumn)="sortColumnMissedCheckpoint($event)"
          (paginationChange)="onChangePaginationMissedCheckpoint($event)"
        >
        </app-gt-table>
      </div>
      <div *ngIf="missedCheckpointData?.length == 0" class="my-3">
        <nb-alert outline="basic" style="font-weight: 500"
          >No Records Match Your Search Criteria</nb-alert
        >
      </div>
    </nb-tab>
  </nb-tabset>
</nb-card>
