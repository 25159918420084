<nb-card>
  <!--mobile view for feedbacks-->
  <nb-card-body class="gt-p-small mobile-only">
    <div *ngIf="!selectedEvent">
      <div class="d-flex">
        <div class="flex-grow-1">
          <nb-form-field>
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                nbInput
                #customerAutoInput
                fullWidth
                placeholder="Search"
                [(ngModel)]="globalFilter"
                (keyup)="onFilterApply($event)"
              />
              <label>Search</label>
            </div>
            <nb-icon
              nbSuffix
              *ngIf="showTypeAhead === true"
              [icon]="'search-outline'"
              pack="eva"
              (click)="onFilterApply(true)"
            ></nb-icon>
            <nb-icon
              nbSuffix
              *ngIf="showTypeAhead === false"
              [icon]="'close-outline'"
              class="sc-floating-right"
              pack="eva"
              (click)="onClearFilter()"
            ></nb-icon>
          </nb-form-field>
        </div>

        <div>
          <div class="sc-add-btn">
            <button
              type="button"
              nbButton
              size="giant"
              class="sc-mt-0 nbbutton-long-text"
              (click)="showAllFeedbacks()"
            >
              {{ showDefaultFeedbacks ? " Show All" : "Reset" }}
            </button>
          </div>
        </div>
      </div>
      <div>
        <button
          size="giant"
          class="status-button"
          fullWidth
          hero
          status="primary"
          (click)="openAddFeedbackForm(feedbackRef)"
          nbButton
        >
          + New
        </button>
      </div>
      <app-card-list
        [listCardData]="feedbacks"
        [listCardDetails]="mobileTableDetail"
        (rowClick)="onRowClick($event)"
      ></app-card-list>
      <div>
        <!-- Pagination section begin -->
        <app-pagination-control
          [pageSize]="pageSize"
          [pageNum]="pageNum"
          [previous]="previous"
          [totalRows]="totalRows"
          [totalPages]="totalPages"
          (onClickPagination)="onClickPagination($event)"
          (onChangePagination)="onChangePagination($event)"
        >
        </app-pagination-control>
      </div>
    </div>
    <div *ngIf="selectedEvent" class="sc-mt-6">
      <!-- seleted event section-->

      <div>
        <button nbButton size="giant" fullWidth (click)="hideDetails()">
          Back
        </button>
      </div>
      <div class="col">
        <button
          (click)="openTemplate(deleteRef)"
          nbButton
          size="small"
          outline
          fullWidth
          status="danger"
          class="nbbutton-long-text"
        >
          Delete
        </button>
      </div>
      <div class="sc-mt-6">
        <app-card-list
          [listCardData]="[selectedEvent]"
          [listCardDetails]="mobileTableDetail"
        ></app-card-list>
      </div>

      <div class="sc-mt-6">
        <!--comments section-->
        <div
          *ngIf="selectedEvent?.updates?.length > 0"
          class="list-fixed-length"
        >
          <div *ngFor="let update of selectedEvent?.updates">
            <div>
              <!-- timestamp -->
              <div class="chat-info">
                {{ update?.updated_at | customdate : "d MMM y HH:mm" }} |
                {{
                  update?.updated_by?.is_superuser
                    ? "Support Team"
                    : update?.updated_by?.full_name
                }}
              </div>
              <!-- comment / photo -->
              <div class="chat-data" *ngIf="update?.event_action?.key === 1">
                <div>
                  {{
                    update?.description === "null" ||
                    update?.description === "undefined"
                      ? ""
                      : update?.description
                  }}
                </div>
              </div>
              <div class="chat-data" *ngIf="update?.event_action?.key === 0">
                {{
                  update?.updated_by?.is_superuser
                    ? "Support Team"
                    : update?.updated_by?.full_name
                }}
                changed status to
                {{ update?.status?.value }}

                <div>
                  Reason:
                  {{
                    update?.description === "null" ||
                    update?.description === "undefined"
                      ? ""
                      : update?.description
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row-cols-1 gy-2"
          *ngIf="selectedEvent?.status != 'COMPLETED'"
        >
          <div class="col">
            <textarea
              rows="3"
              class="form-control"
              type="text"
              placeholder="Add comment"
              [(ngModel)]="newComment"
            ></textarea>
          </div>
          <div class="col">
            <button
              (click)="addComment()"
              nbButton
              size="giant"
              fullWidth
              [disabled]="!newComment"
              class="nbbutton-long-text"
            >
              Send
            </button>
          </div>
        </div>

        <!--details of the seleted event-->
      </div>
    </div>
  </nb-card-body>

  <!--desktop large view for feedbacks-->

  <nb-card-body class="large-view-only gt-p-small">
    <div class="d-flex">
      <div class="flex-grow-1">
        <nb-form-field class="sc-search-input-box">
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              nbInput
              #customerAutoInput
              fullWidth
              placeholder="Search"
              [(ngModel)]="globalFilter"
              (keyup)="onFilterApply($event)"
            />
            <label>Search</label>
          </div>
          <nb-icon
            nbSuffix
            *ngIf="showTypeAhead === true"
            [icon]="'search-outline'"
            pack="eva"
            (click)="onFilterApply(true)"
          ></nb-icon>
          <nb-icon
            nbSuffix
            *ngIf="showTypeAhead === false"
            [icon]="'close-outline'"
            class="sc-floating-right"
            pack="eva"
            (click)="onClearFilter()"
          ></nb-icon>
        </nb-form-field>
      </div>
      <div class="sc-add-btn">
        <button
          title="Add Checkpoint"
          type="button"
          hero
          status="primary"
          nbButton
          size="giant"
          class="sc-mt-0"
          (click)="openAddFeedbackForm(feedbackRef)"
        >
          + New
        </button>
      </div>
      <div>
        <div class="sc-add-btn">
          <button
            type="button"
            nbButton
            size="giant"
            class="sc-mt-0 nbbutton-long-text"
            (click)="showAllFeedbacks()"
          >
            {{ showDefaultFeedbacks ? " Show All" : "Reset" }}
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <app-table
          [desktopTableData]="feedbacks"
          [desktopTableDetails]="deskTopTableDetail"
          [tableStyle]="tableStyle"
          (sortColumn)="sortColumn($event)"
          [apiFilter]="'true'"
          (rowClick)="onRowClick($event)"
        ></app-table>
        <!-- Pagination section begin -->
        <div>
          <app-pagination-control
            [pageSize]="pageSize"
            [pageNum]="pageNum"
            [previous]="previous"
            [totalRows]="totalRows"
            [totalPages]="totalPages"
            (onClickPagination)="onClickPagination($event)"
            (onChangePagination)="onChangePagination($event)"
          >
          </app-pagination-control>
        </div>
      </div>
      <div class="col">
        <div
          class="mt-2"
          *ngIf="selectedEvent"
          style="overflow-y: auto; max-height: 70vh"
        >
          <div class="col-xl-3">
            <button
              (click)="openTemplate(deleteRef)"
              nbButton
              outline
              fullWidth
              status="danger"
              size="medium"
            >
              Delete
            </button>
          </div>
          <div
            *ngIf="selectedEvent?.updates?.length > 0"
            class="list-fixed-length"
          >
            <div *ngFor="let update of selectedEvent?.updates">
              <div>
                <!-- timestamp -->
                <div class="chat-info">
                  {{ update?.updated_at | customdate : "d MMM y HH:mm" }} |
                  {{
                    update?.updated_by?.is_superuser
                      ? "Support Team"
                      : update?.updated_by?.full_name
                  }}
                </div>
                <!-- comment / photo -->
                <div class="chat-data" *ngIf="update?.event_action?.key === 1">
                  <div>
                    {{
                      update?.description === "null" ||
                      update?.description === "undefined"
                        ? ""
                        : update?.description
                    }}
                  </div>
                </div>
                <div class="chat-data" *ngIf="update?.event_action?.key === 0">
                  {{
                    update?.updated_by?.is_superuser
                      ? "Support Team"
                      : update?.updated_by?.full_name
                  }}
                  changed status to
                  {{ update?.status?.value }}

                  <div>
                    Reason:
                    {{
                      update?.description === "null" ||
                      update?.description === "undefined"
                        ? ""
                        : update?.description
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row-cols-1 gy-2"
            *ngIf="selectedEvent?.status != 'COMPLETED'"
          >
            <div class="col">
              <textarea
                rows="5"
                class="form-control"
                type="text"
                placeholder="Add comment"
                [(ngModel)]="newComment"
              ></textarea>
            </div>
            <div class="col">
              <button
                (click)="addComment()"
                nbButton
                size="giant"
                fullWidth
                [disabled]="!newComment"
              >
                Submit
              </button>
            </div>
          </div>

          <!--details of the seleted event-->
        </div>
      </div>
    </div>

    <div class="row row-cols-md-3 mt-2"></div>
  </nb-card-body>
</nb-card>

<ng-template #imgRef let-data let-imgTempRef="dialogRef">
  <nb-card style="margin: 0">
    <nb-card-body (click)="imgTempRef.close()">
      <img
        src="{{ fullImgSrc }}"
        style="
          max-width: 100%;
          cursor: pointer;
          max-height: 95vh;
          border: 3px var(--background-basic-color-3) solid;
          border-radius: 2%;
        "
        class="modal-hover-opacity"
      />
    </nb-card-body>
  </nb-card>
</ng-template>

<!-- delete feedback dialoge -->
<ng-template #deleteRef let-deleteRef="dialogRef">
  <nb-card class="dialog-box">
    <nb-card-header>
      Delete Submission
      <nb-icon
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
        (click)="deleteRef.close(); deleteReason = null"
      >
      </nb-icon>
    </nb-card-header>
    <nb-card-body>
      <div class="form-floating">
        <input
          class="form-control"
          fullWidth
          nbInput
          type="text"
          [(ngModel)]="deleteReason"
          placeholder="Reason"
        /><label>Reason</label>
      </div>

      <div class="flex-center">
        <button
          nbButton
          type="button"
          status="danger"
          [disabled]="!deleteReason"
          (click)="deleteFeedBack()"
          fullWidth
        >
          Delete
        </button>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
<!-- ADD FEEDBACK POPOVER / DIALOG -->
<ng-template #feedbackRef let-feedbackRef="dialogRef">
  <nb-card class="contact-form">
    <nb-card-header class="sc-text-center">
      Contact Form
      <nb-icon
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
        (click)="
          feedbackRef.close('close'); showHideDraggableFeedbackBtn(false)
        "
      ></nb-icon>
    </nb-card-header>
    <form [formGroup]="feedbackForm" class="row-cols-1 gy-2">
      <div class="col">
        <textarea
          class="form-control"
          cols="32"
          rows="10"
          wrap="hard"
          maxlength="340"
          placeholder="Need help or have feedback? Please tell us how we can help."
          formControlName="feedback"
        ></textarea>
      </div>

      <div class="col sc-mt-6">
        <button
          (click)="
            addFeedback();
            feedbackRef.close();
            showHideDraggableFeedbackBtn(false)
          "
          nbButton
          size="giant"
          fullWidth
          [disabled]="!feedbackForm.valid"
        >
          Submit
        </button>
      </div>
    </form>
  </nb-card>
</ng-template>
