<nb-card [ngClass]="showFrom == 'add-route' ? 'dialog-box' : ''">
  <nb-card-header>
    <span>{{
      showHeaderName == true && nameValue ? nameValue : "Add Checkpoint"
    }}</span
    ><span *ngIf="routeName">(for {{ routeName }})</span>
  </nb-card-header>
  <nb-card-body class="mobile-only gt-p-1">
    <form [formGroup]="checkPointForm">
      <nb-stepper #stepper>
        <nb-step [stepControl]="startForm" label="Name">
          <div class="row row-cols-2">
            <div class="col" (click)="useCurrentLocation()">
              <button
                nbButton
                class="nbbutton-long-text"
                size="giant"
                fullWidth
              >
                My Location
              </button>
            </div>

            <div class="col">
              <button
                nbButton
                size="giant"
                nbStepperNext
                fullWidth
                [disabled]="!nameValue && !addressValue"
                (click)="clientId ? selectClient() : shiftFocus('cpName')"
              >
                Next
              </button>
            </div>
          </div>
          <div class="form-floating">
            <input
              id="addressSearch"
              class="form-control"
              #addressAutoInput
              (input)="onAddressSearch($event)"
              nbInput
              fullWidth
              autofocus
              autocomplete="off"
              type="text"
              placeholder="Address Lookup"
              [formControl]="searchControl"
              [nbAutocomplete]="autoNgModel"
            /><label for="addressLookup">Address Lookup</label>
          </div>

          <nb-autocomplete
            #autoNgModel
            (selectedChange)="addressSelected($event)"
          >
            <nb-option [value]="' '"
              ><b> Enter address manually </b>
            </nb-option>
            <nb-option
              *ngFor="let item of addrLookupData$ | async"
              [value]="item"
            >
              <div>
                <span
                  ><b>{{ item.address1 + " " + item.address2 }}</b
                  >,</span
                ><br />
                <small
                  >{{ item.address3 ? item.address3 + " " : "" }}
                  {{ item.city_name }} {{ item.state_name }}
                  {{ item.postcode }}</small
                >
              </div>
            </nb-option>
          </nb-autocomplete>
          <div *ngIf="showMap && latLon && mobileView">
            <app-map-leaflet
              [latLon]="latLon"
              [mapId]="'mapid'"
              [cursorDraggable]="true"
              (emitData)="emitData($event)"
              (updateMinDistance)="updateMinDistance($event)"
            ></app-map-leaflet>
            <div
              *ngIf="
                checkPointForm.value?.latitude &&
                checkPointForm.value?.longitude
              "
              style="
                text-align: center;
                font-size: 0.7rem;
                text-transform: uppercase;
              "
            >
              Lat: {{ checkPointForm.value?.latitude }} Lon:
              {{ checkPointForm.value?.longitude }}
              <div>
                <em> Drag the marker to set coordinates </em>
              </div>
            </div>
          </div>
        </nb-step>

        <nb-step [stepControl]="midForm" label="Name">
          <div class="form-floating">
            <input
              class="form-control"
              fullWidth
              formControlName="name"
              id="cpName"
              nbInput
              type="text"
              [maxlength]="100"
              [(ngModel)]="nameValue"
              placeholder="Checkpoint Name"
            />
            <label for="inputService">Checkpoint Name</label>
          </div>

          <div class="form-floating">
            <input
              autocomplete="off"
              class="form-control"
              fullWidth
              id="clientName"
              formControlName="company"
              (input)="onClientSearch($event)"
              nbInput
              type="text"
              placeholder="Search & Select Client"
              autocomplete="off"
              [nbAutocomplete]="clientACMob"
            />

            <label>Search & Select Client</label>
          </div>

          <nb-autocomplete
            #clientACMob
            (selectedChange)="onClientSelect($event)"
          >
            <nb-option [value]="' '">
              <b> Add New Client</b>
            </nb-option>
            <nb-option *ngFor="let item of customerDetails" [value]="item">
              <div>
                <b>{{ item?.company_name }}</b>
                <div>
                  <small>{{ item?.address?.full_address }}</small>
                </div>
              </div>
            </nb-option>
          </nb-autocomplete>

          <div class="form-floating">
            <input
              class="form-control"
              fullWidth
              id="description"
              formControlName="description"
              nbInput
              type="text"
              placeholder="Description (Optional)"
            /><label for="inputAssignee">Description (Optional)</label>
          </div>
          <div class="row row-cols-2">
            <div class="col">
              <button
                type="button"
                (click)="showHeaderName = false; goBack()"
                nbButton
                fullWidth
                nbStepperPrevious
                ghost
                size="giant"
              >
                Back
              </button>
            </div>
            <div class="col" *ngIf="availableUserPrompts?.length">
              <button
                type="button"
                nbButton
                [disabled]="!checkPointForm.valid"
                fullWidth
                size="giant"
                nbStepperNext
              >
                Next
              </button>
            </div>
            <div class="col" *ngIf="!availableUserPrompts?.length">
              <button
                type="submit"
                [disabled]="!checkPointForm.valid"
                nbButton
                fullWidth
                size="giant"
                (click)="onClickSubmit()"
              >
                Add
              </button>
            </div>
          </div>
        </nb-step>
        <nb-step>
          <span>Post Scan Prompts</span>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              nbTagInput
              fullWidth
              placeholder="Search"
              (input)="searchPrompts($event)"
              [nbAutocomplete]="autoGrpNgModel"
            />
            <label>Search</label>
          </div>
          <nb-autocomplete #autoGrpNgModel>
            <nb-option
              *ngFor="let userPrompt of userPromptsSearchResults"
              (click)="addSelectedUserPrompt(userPrompt)"
              >{{ userPrompt.name }}
            </nb-option></nb-autocomplete
          >

          <table class="user-prompt-table">
            <tbody>
              <tr
                *ngFor="let prompt of availableUserPrompts; let i = index"
                class="pointer"
              >
                <td>
                  <nb-checkbox
                    [checked]="prompt.selected"
                    (checkedChange)="addRemoveUserPrompt($event, prompt)"
                  >
                  </nb-checkbox>
                </td>

                <td (click)="addRemoveUserPrompt(!prompt?.selected, prompt)">
                  {{ prompt?.name }}
                </td>
              </tr>
            </tbody>
          </table>

          <div
            class="sc-mt-6"
            *ngIf="
              userPromptsTotalPages > 1 &&
              userPromptsTotalCount != availableUserPrompts?.length
            "
          >
            <button
              type="button"
              nbButton
              fullWidth
              size="giant"
              (click)="onLoadMore()"
            >
              Load More
            </button>
          </div>

          <div class="row row-cols-2">
            <div class="col">
              <button
                type="button"
                nbButton
                fullWidth
                nbStepperPrevious
                ghost
                size="giant"
              >
                Back
              </button>
            </div>
            <div class="col" *ngIf="selectedUserPrompts?.length < 2">
              <button
                type="submit"
                [disabled]="!checkPointForm.valid"
                nbButton
                fullWidth
                size="giant"
                (click)="onClickSubmit()"
              >
                Add
              </button>
            </div>
            <div class="col" *ngIf="selectedUserPrompts?.length > 1">
              <button nbStepperNext nbButton fullWidth size="giant">
                Order
              </button>
            </div>
          </div>
        </nb-step>
        <nb-step>
          <div
            cdkDropList
            #currentList="cdkDropList"
            [cdkDropListData]="selectedUserPrompts"
            class="drag-drop-container drag-list sc-mt-6"
            (cdkDropListDropped)="drop($event)"
          >
            <table class="drag-box">
              <tbody>
                <tr
                  *ngFor="let prompt of selectedUserPrompts; let i = index"
                  cdkDrag
                >
                  <td>
                    <div class="pe-1">
                      <nb-icon icon="menu" pack="eva"></nb-icon>
                    </div>
                  </td>

                  <td>
                    {{ prompt?.name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row row-cols-2">
            <div class="col">
              <button
                type="button"
                nbButton
                fullWidth
                nbStepperPrevious
                ghost
                size="giant"
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                type="submit"
                [disabled]="!checkPointForm.valid"
                nbButton
                fullWidth
                size="giant"
                (click)="onClickSubmit()"
              >
                Add
              </button>
            </div>
          </div>
        </nb-step>
      </nb-stepper>
    </form>
  </nb-card-body>

  <!-- DESKTOP VIEW -->
  <nb-card-body class="large-view-only gt-p-1">
    <form [formGroup]="checkPointForm" (ngSubmit)="onClickSubmit()">
      <div class="row row-cols-2">
        <div class="col">
          <div class="mobile-only">
            <button
              type="button"
              (click)="useCurrentLocation()"
              nbButton
              fullWidth
              size="giant"
            >
              <nb-icon [icon]="'pin-outline'" pack="eva" status="basic">
              </nb-icon>
              Use Current Location
            </button>
          </div>
          <div class="form-floating">
            <input
              id="addressSearch"
              class="form-control"
              #addressAutoInput
              (input)="onAddressSearch($event)"
              nbInput
              fullWidth
              autofocus
              autocomplete="off"
              type="text"
              placeholder="Address Lookup"
              [formControl]="searchControl"
              [nbAutocomplete]="autoNgModel"
            /><label for="addressLookup">Address Lookup</label>
          </div>

          <nb-autocomplete
            #autoNgModel
            (selectedChange)="addressSelected($event)"
          >
            <nb-option [value]="' '"
              ><b> Enter address manually </b>
            </nb-option>
            <nb-option
              *ngFor="let item of addrLookupData$ | async"
              [value]="item"
              (click)="addressSelected(item)"
            >
              <div>
                <span
                  ><b>{{ item.address1 + " " + item.address2 }}</b
                  >,</span
                ><br />
                <small
                  >{{ item.address3 ? item.address3 + " " : "" }}
                  {{ item.city_name }} {{ item.state_name }}
                  {{ item.postcode }}</small
                >
              </div>
            </nb-option>
          </nb-autocomplete>
          <div class="form-floating">
            <input
              class="form-control"
              fullWidth
              formControlName="name"
              nbInput
              id="cpName"
              (keyup.enter)="shiftFocus('clientName')"
              type="text"
              [(ngModel)]="nameValue"
              placeholder="Checkpoint Name"
            />
            <label for="inputService">Checkpoint Name</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              fullWidth
              id="clientName"
              formControlName="company"
              (input)="onClientSearch($event)"
              nbInput
              autocomplete="off"
              type="text"
              placeholder="Search & Select Client"
              [nbAutocomplete]="clientACMob"
            />

            <label>Search & Select Client</label>
          </div>

          <nb-autocomplete
            #clientACMob
            (selectedChange)="onClientSelect($event)"
          >
            <nb-option [value]="' '">
              <b> Add New Client</b>
            </nb-option>
            <nb-option *ngFor="let item of customerDetails" [value]="item">
              <div>
                <b>{{ item?.company_name }}</b>
                <div>
                  <small>{{ item?.address?.full_address }}</small>
                </div>
              </div>
            </nb-option>
          </nb-autocomplete>

          <div class="form-floating mb-3">
            <input
              id="description"
              class="form-control"
              fullWidth
              formControlName="description"
              nbInput
              type="text"
              placeholder="Description (Optional)"
            /><label for="inputAssignee">Description (Optional)</label>
          </div>
          <ng-container *ngIf="reOrderPrompts">
            <div
              cdkDropList
              #currentList="cdkDropList"
              [cdkDropListData]="selectedUserPrompts"
              class="drag-drop-container drag-list sc-mt-6"
              (cdkDropListDropped)="drop($event)"
            >
              <table class="drag-box">
                <tbody>
                  <tr
                    *ngFor="let prompt of selectedUserPrompts; let i = index"
                    class="grab-item"
                  >
                    <td cdkDrag>
                      <div class="pe-1 cursor-grab">
                        <nb-icon
                          icon="menu"
                          pack="eva"
                          class="cursor-grab"
                        ></nb-icon>
                      </div>
                    </td>

                    <td>
                      {{ prompt?.name }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div></ng-container
          >
          <ng-container *ngIf="!reOrderPrompts && availableUserPrompts?.length">
            <div class="sc-head6">Post Scan Prompts</div>
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                nbTagInput
                fullWidth
                placeholder="Search"
                (input)="searchPrompts($event)"
                [nbAutocomplete]="autoGrpNgModel"
              />
              <label>Search</label>
            </div>
            <nb-autocomplete #autoGrpNgModel>
              <nb-option
                *ngFor="let userPrompt of userPromptsSearchResults"
                (click)="addSelectedUserPrompt(userPrompt)"
                >{{ userPrompt.name }}
              </nb-option></nb-autocomplete
            >

            <table class="user-prompt-table">
              <tbody>
                <div class="row row-cols-2">
                  <tr
                    *ngFor="let prompt of availableUserPrompts; let i = index"
                  >
                    <div class="col pointer">
                      <td>
                        <nb-checkbox
                          [checked]="prompt.selected"
                          (checkedChange)="addRemoveUserPrompt($event, prompt)"
                        >
                        </nb-checkbox>
                      </td>

                      <td
                        (click)="addRemoveUserPrompt(!prompt?.selected, prompt)"
                      >
                        {{ prompt?.name }}
                      </td>
                    </div>
                  </tr>
                </div>
              </tbody>
            </table>
          </ng-container>
          <div
            class="d-flex flex-row-reverse"
            *ngIf="selectedUserPrompts?.length > 1"
          >
            <div>
              <button
                type="button"
                nbButton
                fullWidth
                status="primary"
                size="medium"
                (click)="reOrderPrompts = !reOrderPrompts"
              >
                {{ reOrderPrompts ? "Select/Deselect" : "Order" }}
              </button>
            </div>
          </div>

          <div
            class="sc-mt-6"
            *ngIf="
              userPromptsTotalPages > 1 &&
              userPromptsTotalCount != availableUserPrompts?.length &&
              !reOrderPrompts
            "
          >
            <button
              type="button"
              nbButton
              fullWidth
              size="giant"
              (click)="onLoadMore()"
            >
              Load More
            </button>
          </div>

          <div class="row row-cols-2">
            <div class="col">
              <button
                type="button"
                (click)="onClearForm()"
                nbButton
                fullWidth
                size="giant"
                ghost
              >
                Clear
              </button>
            </div>
            <div class="col">
              <button
                type="submit"
                [disabled]="!checkPointForm.valid"
                nbButton
                fullWidth
                size="giant"
              >
                Add Checkpoint
              </button>
            </div>
          </div>
        </div>
        <div class="col" *ngIf="showMap && latLon && !mobileView">
          <app-map-leaflet
            [latLon]="latLon"
            [mapId]="'largeCP'"
            [cursorDraggable]="true"
            (emitData)="emitData($event)"
            (updateMinDistance)="updateMinDistance($event)"
          ></app-map-leaflet>
          <div
            *ngIf="
              checkPointForm.value?.latitude && checkPointForm.value?.longitude
            "
            style="
              text-align: center;
              font-size: 0.75rem;
              text-transform: uppercase;
            "
          >
            Lat: {{ checkPointForm.value?.latitude }} Lon:
            {{ checkPointForm.value?.longitude }}
            <div>
              <em> Drag the marker to set coordinates </em>
            </div>
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
