import { DatePipe, formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { ContactComponent } from 'src/app/shared/comp/contact/contact.component';
import { DateTimePickerComponent } from 'src/app/shared/comp/date-time-picker/date-time-picker.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import {
  browserGpsInfo,
  checkScanDistanceGreater,
  formatDateTimeStamp,
} from 'src/global.variables';
import { CheckpointService } from '../checkpoints/checkpoint.service';
import { CustomerService } from '../customer/customer.service';
import { JobsService } from '../jobs/jobs.service';
import { PagesService } from '../pages.service';
import { UserPromptService } from '../user-prompts/user-prompt.service';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  billingReportEmailForm: UntypedFormGroup = new UntypedFormGroup({
    emails: new UntypedFormControl('', [Validators.required, Validators.email]),
    file_name: new UntypedFormControl(''),
  });
  userEventDetails: any = {
    individualDetails: [
      {
        name: 'updated_at',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'user_name',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'action',
        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 'bold',
        },
      },
      {
        name: 'detail',
      },
      {
        name: 'company_name',
      },

      {
        name: 'address',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };
  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };
  basicUserEventDetailsTableView: any = [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
      dateRangeTimePicker: true,
    },
    {
      header: 'Event Type',
      name: 'action',
    },

    {
      header: 'Checkpoint',
      columnDataStyle: {
        'white-space': 'pre-line',
      },
      nestedValue: (row: any) => {
        return [0, 6].includes(row?.event_action)
          ? [row?.detail, row?.address].join('\n')
          : '';
      },
    },
    {
      header: 'Guard',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ];
  userEventDetailsTableView = [...this.basicUserEventDetailsTableView];
  jobReportTableDetailsMobile = {
    individualDetails: [
      {
        name: 'job_key',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },

      {
        name: 'created_at',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'company_name',
        cardRowStyle: {
          'font-weight': 'bold',
        },
      },

      {
        name: 'site_address',
        cardRowStyle: {
          'font-size': '0.6rem',
          'font-weight': 'bold',
        },
      },

      {
        name: 'job_type',

        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'ext_job_id',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },
    ],
  };
  clockedUsersTableDetailsMobile = {
    individualDetails: [
      {
        name: 'user_name',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },

      {
        name: 'updated_at',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'action',
        cardRowStyle: {
          'font-weight': 'bold',
        },
      },

      {
        name: 'site_address',
        cardRowStyle: {
          'font-size': '0.6rem',
          'font-weight': 'bold',
        },
      },

      {
        name: 'job_type',

        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'ext_job_id',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },
    ],
  };
  jobCompletedReportTableDetails = [
    {
      header: 'Client',
      name: 'company_name',
      searchKey: 'company_name',
      sortKey: 'company_name',
    },
    {
      header: 'Created At',
      name: 'created_at',
      datetimeObj: true,
      dateRangeKey: 'created_at',
      sortKey: 'created_at',
      dateRangeTimePicker: true,
    },

    {
      header: 'Onsite',
      name: 'on_site_date',
      datetimeObj: true,
    },
    {
      header: 'Offsite',
      name: 'off_site_date',
      datetimeObj: true,
    },
    {
      header: 'Time Onsite',

      nestedValue: (row: any) => {
        return this.getTimeDifference(row);
      },
    },
    {
      header: 'Completed',
      sortKey: 'latest_update',
      name: 'completed_date',
      datetimeObj: true,
    },
    {
      header: 'Site Name',
      name: 'site_name',
    },
    {
      header: 'Address',
      name: 'site_address',
      searchKey: 'site_address',
      columnDataStyle: {},
    },
    {
      header: 'Job Type',
      name: 'job_type',
    },
    {
      header: 'Job ID',
      name: 'job_key',
    },
    {
      header: 'Ext. Job ID',
      name: 'ext_job_id',
    },
    {
      header: 'Add. Info.',
      name: 'additional_information',
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },

    {
      header: 'Comments',

      nestedValue: (row: any) => {
        let sortedComments = row?.job_comments?.sort(
          (a: any, b: any) =>
            new Date(a?.comment_time).getTime() -
            new Date(b?.comment_time).getTime()
        );

        let comments = sortedComments?.map(
          (commentObj: any) => commentObj?.comment
        );

        return comments?.join('\n\n');
      },
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },
    {
      header: 'Guard',
      nestedValue: (row: any) => {
        return row?.assignees?.join('\n');
      },
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },
  ];
  jobCancelledReportTableDetails = [
    {
      header: 'Client',
      name: 'company_name',
      searchKey: 'company_name',
      sortKey: 'company_name',
    },
    {
      header: 'Created At',
      name: 'created_at',
      datetimeObj: true,
      dateRangeKey: 'created_at',
      sortKey: 'created_at',
      dateRangeTimePicker: true,
    },
    {
      header: 'Cancelled',
      sortKey: 'latest_update',
      name: 'cancelled_date',
      datetimeObj: true,
    },
    {
      header: 'Cancel Reason',
      nestedValue: (row: any) => {
        let sortedComments = row?.job_comments?.sort(
          (a: any, b: any) =>
            new Date(a?.comment_time).getTime() -
            new Date(b?.comment_time).getTime()
        );
        let comments = sortedComments?.map(
          (commentObj: any) => commentObj?.comment
        );
        return comments?.length > 0 ? comments[comments.length - 1] : '';
      },
    },
    {
      header: 'Site Name',
      name: 'site_name',
    },
    {
      header: 'Address',
      name: 'site_address',
      searchKey: 'site_address',
      columnDataStyle: {},
    },
    {
      header: 'Job Type',
      name: 'job_type',
    },
    {
      header: 'Job ID',
      name: 'job_key',
    },
    {
      header: 'Ext. Job ID',
      name: 'ext_job_id',
    },
    {
      header: 'Add. Info.',
      name: 'additional_information',
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },

    {
      header: 'Comments',

      nestedValue: (row: any) => {
        let sortedComments = row?.job_comments?.sort(
          (a: any, b: any) =>
            new Date(a?.comment_time).getTime() -
            new Date(b?.comment_time).getTime()
        );
        let comments = sortedComments?.map(
          (commentObj: any) => commentObj?.comment
        );
        return comments?.join('\n\n');
      },
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },
    {
      header: 'Guard',
      nestedValue: (row: any) => {
        return row?.assignees?.join('\n');
      },
      columnDataStyle: {
        'white-space': 'pre-line',
      },
    },
  ];
  jobReportTableDetails = this.jobCompletedReportTableDetails;
  clockedUserTableDetails = [
    {
      header: 'Guard',
      name: 'user_name',
      searchKey: 'user_name',
      sortKey: 'user_name',
    },
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
    },
    {
      header: 'Event Type',
      sortKey: 'action',
      name: 'action',
    },
  ];
  dateRange: any = {
    start: new Date(new Date().setDate(new Date().getDate() - 1)),
    end: new Date(new Date().setDate(new Date().getDate() + 1)),
  };
  contactForm: UntypedFormGroup = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    title: new UntypedFormControl(),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    mobile_number: new UntypedFormControl(null, [
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9]*$'),
    ]),
  });

  apiFilter: boolean = true;
  userEvents: any;

  selectedEvent: any;
  mapLatLong: any;
  uploadedImage: any;
  selectedEventType: string = 'patrol';
  sortKeys: any = [];
  selectedClient: any;
  showDetails: boolean = false;
  customerDetails: any;
  siteData: any;
  searchParams: any;
  totalRows: number = 0;
  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;

  scrollPosition: any;
  browserGpsInfo: any = browserGpsInfo;
  showTable: boolean = false;

  dialogeRef: any;
  dateRangeValue: any;
  allowPDFExport: boolean = false;
  allowBillingReportExport: boolean = false;
  clientName: any;
  selectedUsers: any = [];
  usersList: any = [];
  subscriberUserId: any;
  newComment: any;
  dialogref: any;

  previousComment: any;
  nbTabs = {
    tab1: 'Patrols',
    tab2: 'Jobs',
    tab3: 'Discrepancies',
    tab4: 'User Prompts',
  };

  jobReportTypes = ['Completed', 'Cancelled'];
  selectedJobReport: String = 'Completed';
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;

  scanDistanceInfo: any = {};
  activeTab: string = this.nbTabs.tab1;
  userPrompts: any = [];
  selectedPromptIds: any = [];
  constructor(
    private toasterService: NbToastrService,
    private userService: UsersService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private jobService: JobsService,
    private customerService: CustomerService,
    private dialog: NbDialogService,
    private pageService: PagesService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private appService: AppService,
    private checkpointService: CheckpointService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private userPromptService: UserPromptService
  ) {
    this.dateRange.start.setHours(0, 0, 0, 0);
    this.dateRangeValue = `${String(
      formatDate(this.dateRange?.start, 'dd/MM/yy HH:mm', 'en_US')
    )} - ${String(formatDate(this.dateRange?.end, 'dd/MM/yy HH:mm', 'en_US'))}`;
    let userData = this.appService.getUserData();

    this.subscriberUserId = userData?.subscriber?.subscriber_user_id;
  }

  ngOnInit(): void {
    if (this.selectedEventType === 'patrol') {
      this.sortKeys = ['updated_at'];
    }
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.getHomeScreenPrompts();
  }

  getHomeScreenPrompts() {
    this.userPromptService
      .getHomeUserPromptList({})
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.userPrompts = response?.data;
        }
      });
  }
  jobReportChange(event: any) {
    this.selectedJobReport = event;

    this.jobReportTableDetails =
      event === 'Completed'
        ? this.jobCompletedReportTableDetails
        : this.jobCancelledReportTableDetails;

    this.getUserUpdateLog();
  }
  getTimeDifference(rowData: any) {
    const { on_site_date, off_site_date } = rowData;

    // * onsite and offsite time exists; else return null
    // * onsite and offsite hour and minute match || onsite > offsite; return 0;
    // * onsite < offsite; return difference

    if (off_site_date && on_site_date) {
      let date1 = new Date(on_site_date);
      let date2 = new Date(off_site_date);

      if (
        date1 > date2 ||
        (date1.getHours() === date2.getHours() &&
          date1.getMinutes() === date2.getMinutes())
      ) {
        return 0;
      }

      const diffInMs = date2.getTime() - date1.getTime();
      const diffInHours = diffInMs / (1000 * 60 * 60);
      const hours = Math.floor(diffInHours);
      const minutes = Math.round((diffInHours - hours) * 60);

      // Pad the hours and minutes with leading zeros
      const hours_str = hours < 10 ? '0' + hours : hours;
      const minutes_str = minutes < 10 ? '0' + minutes : minutes;

      return `${hours_str}:${minutes_str}`;
    } else {
      return 0;
    }
  }

  getUserUpdateLog() {
    this.spinnerService.show();
    let timezoneOffset = new Date();
    let body: any = {
      event_type: this.selectedEventType,
      company_id: this.selectedClient?.id,
      time_offset: timezoneOffset.getTimezoneOffset(),
      user_prompt_ids: this.selectedPromptIds,
    };
    if (this.dateRange) body.date_range = this.dateRange;

    if (this.sortKeys) body.sort_key = this.sortKeys;
    if (this.searchParams) body.search_filters = this.searchParams;

    if (this.pageSize) {
      body['rows'] = this.pageSize;
    }
    if (this.previous) {
      body['previous'] = this.previous;
    }
    if (this.selectedJobReport) {
      body['job_report_type'] = this.selectedJobReport?.toLowerCase();
    }

    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.userEvents = response['data'];
        this.allowPDFExport = true;
        this.allowBillingReportExport = true;
        this.showDetails = false;
        this.spinnerService.hide();
        this.totalRows = response['total_size'];

        this.totalPages = Math.ceil(this.totalRows / this.pageSize);
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }
  clearAll() {
    this.selectedClient = null;
    this.clientName = null;
    this.showTable = false;
    this.userEvents = null;
    this.totalPages = 0;
    this.selectedUsers = [];
    this.billingReportEmailForm.reset();
    this.searchParams = null;
    this.selectedPromptIds = [];
    this.userPrompts?.forEach((item: any) => {
      item.selected = false;
    });

    if (this.selectedEventType === 'job_report') {
      this.dateRange = null;
      this.dateRangeValue = null;
      this.sortKeys = [];
    } else {
      this.sortKeys = ['updated_at'];
      this.dateRange = {
        start: new Date(new Date().setDate(new Date().getDate() - 1)),
        end: new Date(new Date().setDate(new Date().getDate() + 1)),
      };
      this.dateRange.start.setHours(0, 0, 0, 0);
      this.dateRangeValue = `${String(
        formatDate(this.dateRange?.start, 'dd/MM/yy HH:mm', 'en_US')
      )} - ${String(
        formatDate(this.dateRange?.end, 'dd/MM/yy HH:mm', 'en_US')
      )}`;
    }
  }

  onTableRowClick(event: any) {
    this.scrollPosition = window.scrollY;
    this.showDetails = true;
    this.selectedEvent = event;
    this.tableStyle = {
      'overflow': 'auto',
      'max-height': '35vh',
    };

    if (event?.gps) this.mapLatLong = { ...event?.gps };
    this.uploadedImage = event?.file;

    window.scrollTo(0, document.body.scrollHeight);
    this.scanDistanceInfo = checkScanDistanceGreater(this.selectedEvent);
  }

  handleDateChange(event: any) {
    if (event?.end) {
      this.dateRange = event;
    }
  }
  sortColumn(body: any) {
    this.sortKeys = body?.sortList || [];
    this.searchParams = body?.searchParams || {};
    this.getUserUpdateLog();
  }
  searchColumn(event: any) {
    this.dateRange = event?.date || null;
    this.searchParams = event?.searchParams || {};
    this.getUserUpdateLog();
  }
  onClientSearch(event: any) {
    this.selectedClient = null;
    let value = event.target.value;
    this.jobService.getSearchClients(value).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.customerDetails = res['data']?.filter(
          (client: any) => client?.id != this.selectedClient?.id
        );
      }
    });
  }
  onClientSelect(data: any) {
    this.clearAll();
    setTimeout(() => {
      this.cdr.markForCheck();
      this.clientName = data?.company_name;

      if (data != this.selectedClient) {
        this.selectedClient = data;

        if (this.activeTab === this.nbTabs.tab1) {
          if (this.selectedClient) {
            this.searchData();
          }
        }
        if (this.activeTab === this.nbTabs.tab4) {
          if (!this.selectedClient?.id) {
            this.pageService.setMessage({
              successMessage: '',
              errorMessage: 'Select a Client',
            });
            return;
          }
          if (this.selectedPromptIds?.length && this.selectedClient?.id) {
            this.searchData();
          }
        }
      }
    }, 100);
  }
  selectUnselectPrompt(prompt: any) {
    prompt.selected = !prompt?.selected;
    this.selectedPromptIds = [
      ...new Set(
        this.userPrompts
          ?.filter((item: any) => item?.selected)
          ?.map((item: any) => item?.id)
      ),
    ];
  }
  searchData() {
    this.selectedPromptIds = [
      ...new Set(
        this.userPrompts
          ?.filter((item: any) => item?.selected)
          ?.map((item: any) => item?.id)
      ),
    ];
    this.showTable = true;
    if (this.selectedClient?.id) {
      this.getUserUpdateLog();
    }
  }
  exportData(exportType: any) {
    this.spinnerService.show();
    let userIds = [...new Set([...this.selectedUsers].map((item) => item.id))];
    if (!userIds?.length && this.selectedEventType === 'patrol') {
      this.spinnerService.hide();
      return;
    }
    let timezoneOffset = new Date();
    let body: any = {
      event_type: this.selectedEventType,
      company_id: this.selectedClient?.id,
      export_type: exportType,
      user_ids: userIds,
      time_offset: timezoneOffset.getTimezoneOffset(),
      user_prompt_ids: this.selectedPromptIds,
    };

    //TODO: Check the date range and make the default date range if date range filter is cleared
    // if (!this.dateRange) {
    //   this.dateRange = {
    //     start: new Date(new Date().setDate(new Date().getDate() - 1)),
    //     end: new Date(new Date().setDate(new Date().getDate() + 1)),
    //   };
    // }
    if (this.dateRange) body.date_range = this.dateRange;

    if (this.sortKeys) body.sort_key = this.sortKeys;
    if (this.searchParams) body.search_filters = this.searchParams;

    this.userService.exportUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.allowPDFExport = false;
        this.pageService.setMessage({
          successMessage: response['message'],
          errorMessage: '',
        });
        this.clearAll();

        this.spinnerService.hide();
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }

  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.getUserUpdateLog();
  }
  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.getUserUpdateLog();
  }

  hideDetails() {
    this.showDetails = false;

    // let scrollPosition: any = window.localStorage.getItem('scrollToView');
    setTimeout(() => {
      window.scrollTo(0, this.scrollPosition);
    }, 100);
  }

  openRangePicker() {
    this.dialogeRef = this.dialog.open(DateTimePickerComponent, {
      context: {
        dateRange: this.dateRange,
        showFrom: 'tablePage',
      },
    });
    this.dialogeRef.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.dateRange = value;
        this.dateRangeValue = `${String(
          formatDateTimeStamp(this.dateRange?.start, 'd MMM y HH:mm', 'en_US')
        )} - ${String(
          formatDateTimeStamp(this.dateRange?.end, 'd MMM y HH:mm', 'en_US')
        )}`;
        if (this.selectedEventType == 'job_report') {
          this.getUserUpdateLog();
        } else if (this.selectedEventType === 'patrol') {
          if (this.selectedClient) {
            this.searchData();
          }
        } else {
          this.getUserUpdateLog();
        }
      }
    });
  }
  openTemplate(template: TemplateRef<any>) {
    this.dialogeRef = this.dialog.open(template, {
      context: {},
    });
  }
  removeUser(deleteUser: any): void {
    this.selectedUsers = this.selectedUsers.filter(
      (user: any) => user?.id !== deleteUser?.id
    );
  }

  addUser(id: number, name: string, user: any): void {
    if (this.selectedUsers.some((user: any) => user?.id === id)) {
      this.toasterService.warning('', 'User already added');
    } else {
      this.selectedUsers.push({
        id: id,
        name: name,
        email: user?.email,
        mobile_number: user?.mobile_number,
      });
      this.usersList = this.usersList?.filter(
        (value1: any) =>
          !this.selectedUsers.some((value2: any) => value1.pk === value2.id)
      );
    }
  }
  getUsers(event: any = null) {
    let value = event?.target?.value;
    let params: any = { union: 1, company_id: this.selectedClient?.id };
    if (value) {
      params['search_str'] = value;
    }

    this.customerService
      .searchLocationContacts(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          let users = response['data'];
          users = users.filter(
            (value1: any) =>
              !this.selectedUsers.some((value2: any) => value1.pk === value2.id)
          );
          this.usersList = users;
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  editComment(commentData: any, requestRef: TemplateRef<any>) {
    this.newComment = commentData?.detail;
    this.previousComment = commentData?.detail;
    this.dialogref = this.dialog.open(requestRef, {
      context: commentData,
    });
  }
  addComment(data: any) {
    if (this.newComment && this.newComment.trim()) {
      this.spinnerService.show();
      let body = {
        company_id: data?.company_id,
        comment: this.newComment,
        id: data?.id,
        edit_comment: 1,
      };
      this.checkpointService.updateClient(body).subscribe((response: any) => {
        this.getUserUpdateLog();
        this.newComment = '';
        this.showDetails = false;
        this.spinnerService.hide();
      });
    }
  }
  openHistoryChangeTemplate(template: TemplateRef<any>, data: any) {
    this.dialogref = this.dialog.open(template, {
      context: data,
    });
  }
  addToContactsTemp() {
    let clientData: any = this.selectedClient;
    this.dialogref = this.dialog.open(ContactComponent, {
      context: {
        clientData: clientData,
        contactForm: this.contactForm,
        patrolReportUsers: true,
        contact_type: 'lc',
      },
      dialogClass: 'model-full',
    });
    this.dialogref.onClose.subscribe((value: any) => {
      this.spinnerService.hide();

      if (value !== 'false') {
        if (value?.length) {
          let lastAddedContact = value[value?.length - 1];

          if (lastAddedContact)
            this.addUser(
              lastAddedContact?.id,
              lastAddedContact?.full_name
                ? lastAddedContact.full_name
                : lastAddedContact.email,
              lastAddedContact
            );
        }
      }
    });
  }
  changeTab(event: any) {
    this.showDetails = false;
    this.clearAll();
    this.activeTab = event.tabTitle;
    this.userEventDetailsTableView = [...this.basicUserEventDetailsTableView];
    if (event.tabTitle === this.nbTabs.tab1) {
      this.selectedEventType = 'patrol';
      this.sortKeys = ['updated_at'];
    } else if (event.tabTitle === this.nbTabs.tab2) {
      this.dateRange = {
        start: new Date(new Date().setDate(new Date().getDate() - 30)),
        end: new Date(new Date().setDate(new Date().getDate() + 1)),
      };
      this.dateRangeValue = `${String(
        formatDateTimeStamp(this.dateRange?.start, 'd MMM y HH:mm', 'en_US')
      )} - ${String(
        formatDateTimeStamp(this.dateRange?.end, 'd MMM y HH:mm', 'en_US')
      )}`;
      this.sortKeys = [];
      this.searchParams = null;
      this.selectedEventType = 'job_report';
      this.getUserUpdateLog();
    } else if (event.tabTitle === this.nbTabs.tab3) {
      this.selectedEventType = 'exceptions';
      this.sortKeys = ['updated_at'];
      this.showTable = true;
      this.userEventDetailsTableView = this.userEventDetailsTableView?.filter(
        (item: any) => item?.name !== 'action'
      );

      this.userEventDetailsTableView.splice(-1, 0, {
        header: 'Client',
        name: 'company_name',
      });
      this.getUserUpdateLog();
    } else if (event.tabTitle === this.nbTabs.tab4) {
      this.userEventDetailsTableView = [
        {
          header: 'Date',
          name: 'updated_at',
          datetimeObj: true,
          dateRangeKey: 'updated_at',
          sortKey: 'updated_at',
          dateRangeTimePicker: true,
        },

        {
          header: 'Prompt',

          nestedValue: (row: any) => {
            const promptData = row?.prompts?.[0]?.prompt_data;
            return promptData?.name;
          },
        },
        {
          header: 'Guard',
          name: 'user_name',
          sortKey: 'user_name',
          searchKey: 'user_name',
        },
      ];
      this.selectedEventType = 'patrol';
      this.sortKeys = ['updated_at'];
    }
  }
  onJobTableRowClick(event: any) {
    this.spinnerService.show();
    this.router.navigateByUrl(`/job-detail/${event?.job_key}`, {
      state: event?.job_id,
    });
    window.localStorage.setItem('urlId', event?.job_id);
  }
  mailBillingReport() {
    // this.spinnerService.show();

    let timezoneOffset = new Date();
    let body: any = {
      event_type: this.selectedEventType,
      export_type: 'csv',
      time_offset: timezoneOffset.getTimezoneOffset(),
      job_report_type: this.selectedJobReport?.toLowerCase(),
    };

    if (this.dateRange) body.date_range = this.dateRange;

    if (this.sortKeys) body.sort_key = this.sortKeys;
    if (this.searchParams) body.search_filters = this.searchParams;

    this.userService.exportUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.allowBillingReportExport = false;
        this.pageService.setMessage({
          successMessage: response['message'],
          errorMessage: '',
        });
        this.clearAll();

        this.spinnerService.hide();
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }
  onClockedUserRowClick(event: any) {
    this.scrollPosition = window.scrollY;
    this.showDetails = true;
    this.selectedEvent = event;
    this.tableStyle = {
      'overflow': 'auto',
      'max-height': '35vh',
    };
    if (event?.gps) this.mapLatLong = { ...event?.gps };
  }
  canEditComment(eventData: any) {
    return (
      this.subscriberUserId == eventData?.subs_user_id &&
      eventData?.company_id &&
      new Date().getTime() - new Date(eventData?.updated_at).getTime() <
        72 * 3600 * 1000
    );
  }
  getLastCommentEditedDateTime(history: any) {
    let sortedComments = history?.sort(
      (a: any, b: any) =>
        new Date(a?.updated_at).getTime() - new Date(b?.updated_at).getTime()
    );
    return sortedComments[sortedComments?.length - 1];
  }
  getPromptValue(promptData: any) {
    return Array.isArray(promptData?.value)
      ? promptData?.value?.join(', ')
      : promptData?.value;
  }
}
