import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { DataCheckService } from '../service/data-check.service';

@Injectable({
  providedIn: 'root',
})
export class ProtectGuard implements CanActivate {
  constructor(
    private router: Router,
    private appService: AppService,
    private dataCheckService: DataCheckService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    var userData = this.appService.getUserData();
    let isSuperUser = this.dataCheckService.isSuperUser();
    if (userData != null && userData.access) {
      //check 1: is the logged in user is a super user then he can access only admin pages
      if (isSuperUser) {
        if (
          (route.url && route.url[0]?.path === 'gtadmin') ||
          !route.url?.length
        ) {
          return true;
        } else {
          this.router.navigate(['404']);
          return false;
        }
      }
      //check 1: is the logged in user is a normal user then he can access only gt-app pages
      else {
        if (route.url && route.url[0]?.path === 'gtadmin') {
          this.router.navigate(['404']);
          return false;
        } else {
          return true;
        }
      }
    } else {
      this.router.navigate(['./login']);
      return false;
    }
  }
}
