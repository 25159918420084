// orientation-lock.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OrientationLockService {
  lockOrientation(): void {
    window.addEventListener('orientationchange', () => {
      if (window.innerWidth < 770) {
        if (window.screen && window.screen.orientation) {
          if (
            window.screen.orientation.type === 'landscape-primary' ||
            window.screen.orientation.type === 'landscape-secondary'
          ) {
            // If in landscape mode, enter fullscreen mode
            setTimeout(() => {
              this.enterFullscreenMode();
            }, 1000);
          }
        }
      }
    });
  }

  private enterFullscreenMode(): void {
    const documentElement = document.documentElement as any;
    if (documentElement.requestFullscreen) {
      documentElement
        .requestFullscreen()
        .then(() => {
          this.lockScreenOrientation();
        })
        .catch((error: any) => {
          console.error('Could not enter fullscreen mode:', error);
        });
    } else {
      this.exitFullscreenMode();
    }
  }

  private lockScreenOrientation(): void {
    const screenOrientation = screen.orientation as any;
    if (window.screen && window.screen.orientation && screenOrientation.lock) {
      screenOrientation
        .lock('portrait')
        .catch((error: any) => {
          console.error('Could not lock screen orientation:', error);
        })
        .finally(() => {});
    }
  }

  private exitFullscreenMode(): void {
    if (document.exitFullscreen) {
      document.exitFullscreen().catch((error: any) => {
        console.error('Could not exit fullscreen mode:', error);
      });
    }
  }

  unlockOrientation() {
    if (window.screen && window.screen.orientation) {
      try {
        (window.screen.orientation as any).unlock();
      } catch (error) {
        console.error('Error unlocking screen orientation:', error);
      }
    }
  }
}
