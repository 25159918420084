import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
export interface DialogData {
  title: string;
  message: string;
  messageStyle: any;
}
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  dialogData: DialogData | undefined;
  title: any;
  message: any;
  messageStyle: any;
  body: any;
  bodyData: any;
  confirmation: boolean = false;
  confirmNumber: any;
  randomNUmber: number = 0;
  valid: boolean = false;
  alertDanger: boolean = true;
  @Input() alertStatus: string = 'danger';
  constructor(
    protected ref: NbDialogRef<ConfirmDialogComponent>,
    private toastrService: NbToastrService
  ) {}

  ngOnInit(): void {
    this.randomNUmber = this.randomInteger(1000, 9999);
    var element = <HTMLInputElement>document.getElementById('confirmBtn');
    if (element) {
      element.disabled = false;
      element.focus();
    }
  }
  onConfirm(): void {
    if (this.body) {
      this.ref.close({ data: this.bodyData, value: 'Yes' });
    }
    if (this.confirmation) {
      if (this.randomNUmber == this.confirmNumber) {
        this.ref.close('Yes');
      } else {
        this.toastrService.warning('', `Numbers don't match`);
      }
    }
    if (!this.confirmation) this.ref.close('Yes');
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.ref.close('No');
  }
  randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
