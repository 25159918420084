<div>
  <app-card-list
    *ngIf="showUserGroups"
    [listCardData]="userGroupPaginationData[pageNum]"
    [listCardDetails]="userGroupCardDetails"
    (rowClick)="rowClick($event)"
  ></app-card-list>

  <!-- Regitster user pagination BEGINS-->
  <div *ngIf="totalPages > 1">
    <app-pagination-control
      [pageSize]="pageSize"
      [pageNum]="pageNum"
      [previous]="previous"
      [totalRows]="totalUserCount"
      [totalPages]="totalPages"
      (onClickPagination)="onChangePagination($event)"
      (onChangePagination)="onChangePagination($event)"
    >
    </app-pagination-control>
  </div>

  <div *ngIf="totalUserCount < 1 && !showUserGroups">
    <nb-alert accent="danger">No User Groups Found</nb-alert>
  </div>
  <ng-container *ngIf="desktopView">
    <div class="sc-head6 mt-4 ps-3">Event Logs</div>
    <app-user-updates-log [eventType]="'userGroup'"> </app-user-updates-log>
  </ng-container>
</div>
