import { formatDate } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { AdminService } from 'src/app/pages/admin/admin.service';
import { JobsService } from 'src/app/pages/jobs/jobs.service';
import { PagesService } from 'src/app/pages/pages.service';
import { RosterScheduleService } from 'src/app/pages/roster-schedule/roster-schedule.service';
import { getFormattedRepeatDetail } from 'src/global.variables';

@Component({
  selector: 'app-subscriber-roster',
  templateUrl: './subscriber-roster.component.html',
  styleUrls: ['./subscriber-roster.component.scss'],
})
export class SubscriberRosterComponent implements OnInit {
  @Input() subscriberId: string = '';
  @Input() subscriberUserId: string = '';
  @Input() isAdmin: boolean = false;
  @Input() isDispatchUser: boolean = false;

  startDay = new Date();
  endDay: any;
  weekDays: any;

  groupBy: string = 'company';

  dialogRef: any;

  mobileRosterData: any;
  mobileselectedDate: any;

  // new section

  rosterScheduleData: any = [];
  mobileRosterScheduleData: any = [];
  statusData: any;
  cardStyling: any = { status: {}, groupStyle: {} };

  today = new Date();

  deleteOptionSelected: any;

  conflictData: any = [];
  guardsList: any = [];

  desktopDayDuration: number = 6;
  rangeSlider: any;

  searchResults: any = [];
  searchResultsBackup: any = [];
  tempSearchResults: any = [];
  searchDateRange: any;

  schedulerForm: UntypedFormGroup = new UntypedFormGroup({
    end_time: new FormControl(null),
    start_time: new FormControl(null),
    start_day: new FormControl(new Date()),
    end_day: new FormControl(null, Validators.required),
    repeat_times: new UntypedFormControl(null),
    repeat_type: new UntypedFormControl(null, Validators.required),
    repeat_details: new UntypedFormControl([]),
    repeat_interval: new UntypedFormControl(1, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    position: new UntypedFormControl(null, []),
    week_day: new UntypedFormControl(null, []),
    month_repeat_case: new UntypedFormControl(null, []),
  });
  groupByData: any;
  constructor(
    private spinnerService: NgxSpinnerService,
    private pageService: PagesService,
    private jobService: JobsService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private appService: AppService,

    private rsService: RosterScheduleService,
    private adminService: AdminService
  ) {}
  getStartDay(date: any) {
    this.mobileselectedDate = new Date(date);
    let date1 = this.mobileselectedDate;
    date1.setHours(0, 0, 0, 0);
    let date2 = new Date();
    date2.setHours(0, 0, 0, 0);
    let weekDays: any =
      (date1.getTime() - date2.getTime()) / (24 * 60 * 60 * 1000);
    let startDay = new Date();
    const weekLength =
      window.innerWidth < 770 ? 7 : this.desktopDayDuration + 1;
    startDay.setDate(
      startDay.getDate() + weekLength * Math.floor(weekDays / weekLength)
    );

    this.startDay = startDay;
  }

  ngOnInit(): void {
    this.getEndDay();

    setTimeout(() => {
      this.spinnerService.show();
      this.getRosterSchedules();
    }, 500);
  }

  getEndDay() {
    let endDay = new Date(this.startDay.getTime());
    const days = window.innerWidth < 770 ? 6 : this.desktopDayDuration;
    endDay.setDate(this.startDay.getDate() + days);
    this.endDay = endDay;
  }

  generateWeekDays(starDay: any, endDay: any) {
    const week = [];
    let startDate = new Date(starDay);
    startDate.setHours(0, 0, 0, 0);

    while (startDate <= endDay) {
      week.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }

    return week;
  }
  getDay(dateString: string) {
    const date = new Date(dateString);
    let weekDays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return window.innerWidth < 770
      ? weekDays[date.getDay()]?.slice(0, 2)
      : weekDays[date.getDay()];
  }

  previousWeek() {
    this.mobileselectedDate = null;
    let startDay = new Date(this.startDay.getTime());
    startDay.setDate(
      this.startDay.getDate() -
        (window.innerWidth < 770 ? 7 : this.desktopDayDuration + 1)
    );
    this.startDay = startDay;
    this.getEndDay();
    this.getRosterSchedules();
  }

  nextWeek() {
    this.mobileselectedDate = null;
    let startDay = new Date(this.startDay.getTime());
    startDay.setDate(
      this.startDay.getDate() +
        (window.innerWidth < 770 ? 7 : this.desktopDayDuration + 1)
    );
    this.startDay = startDay;
    this.getEndDay();
    this.getRosterSchedules();
  }

  formatMobileRosterSchedules(dateTime: any) {
    let date = formatDate(dateTime, 'yyyy-MM-dd', 'en');
    this.mobileselectedDate = date;

    this.router.navigate(['/roster'], {
      fragment: this.mobileselectedDate,
    });

    this.mobileRosterScheduleData = [];

    this.rosterScheduleData?.forEach((schedule: any) => {
      if (
        schedule?.event_dates?.includes(date) &&
        (this.isAdmin || this.isDispatchUser
          ? true
          : this.isGuardPresentCheck(schedule, date)) &&
        (schedule?.repeat_type !== 'once' ||
          (schedule?.repeat_type == 'once' &&
            formatDate(new Date(schedule?.start_day), 'yyyy-MM-dd', 'en') ===
              date))
      ) {
        this.mobileRosterScheduleData.push(schedule);
      }
    });
    this.mobileRosterScheduleData?.sort((a: any, b: any) => {
      let timeA = new Date('1970-01-01T' + a.start_time + 'Z');
      let timeB = new Date('1970-01-01T' + b.start_time + 'Z');
      return timeA.getTime() - timeB.getTime();
    });
  }
  isGuardPresentCheck(scheduleData: any, date: any) {
    let assignees = this.getEventAssigneeList(scheduleData, date);

    return assignees?.some(
      (assignee: any) => assignee?.id == this.subscriberUserId
    );
  }

  showHourByData(hour: number, scheduleData: any) {
    let [startHour, startMin, startSec] = scheduleData?.start_time
      ?.split(':')
      ?.map(Number);
    let [endHour, endMin, endSec] = scheduleData?.end_time
      ?.split(':')
      ?.map(Number);

    // Convert times to minutes past midnight
    let startMinutes = startHour * 60 + startMin;
    let endMinutes = endHour * 60 + endMin;
    let hourMinutes = hour * 60;

    if (
      (startMinutes <= hourMinutes && hourMinutes < endMinutes) ||
      (startMinutes < hourMinutes + 60 && hourMinutes + 60 <= endMinutes)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getDateStr(date: any) {
    return formatDate(new Date(date), 'yyyy-MM-dd', 'en_US');
  }

  // openEventDetailPage(
  //   scheduleData: any,
  //   date: any,
  //   template: TemplateRef<any>
  // ) {
  //   if (
  //     this.trashIconIf(scheduleData, date) &&
  //     scheduleData?.repeat_type !== 'once'
  //   ) {
  //     this.dialogRef = this.dialogService.open(template, {
  //       context: { schedule: scheduleData, date: date },
  //     });
  //   } else {
  //     if (scheduleData?.job_details) {
  //       this.spinnerService.show();
  //       this.openJobDetailPage(scheduleData, date);
  //     } else {
  //       this.spinnerService.show();
  //       this.openPatrolRoutePage(scheduleData, date);
  //     }
  //   }
  // }

  isNotPastDate(scheduleData: any, date: any) {
    let startDateTime: any = new Date(date);
    if (scheduleData?.repeat_type == 'once') {
      startDateTime = new Date(
        scheduleData?.start_day + ' ' + scheduleData?.start_time
      );
    } else {
      this.setTime(startDateTime, scheduleData?.start_time);
    }

    return Boolean(startDateTime < new Date());
  }
  addExceptionDate(scheduleData: any, date?: any) {
    this.spinnerService.show();
    const formattedEventDate = formatDate(
      new Date(date || this.mobileselectedDate),
      'yyyy-MM-dd',
      'en_US'
    );
    let body: any = { exception_dates: [formattedEventDate] };
    if (scheduleData?.job_details?.job_type) {
      body.job_id = this.getJobId(scheduleData, date);
    }
    this.rsService
      .updateRosterSchedule(scheduleData?.id, body, { remove_date: 1 })
      .subscribe((response: any) => {
        // this.formatData(response);
        if (response?.status === 'success') {
          this.getRosterSchedules();
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }
  arraysHaveSameItems(arr1: any[], arr2: any[]): boolean {
    // Create sets from the arrays
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);

    if (set1.size !== set2.size) {
      return false;
    }

    return Array.from(set1).every((item) => set2.has(item));
  }

  formatRosterScheduleData(response: any) {
    this.dialogRef?.close();
    this.weekDays = this.generateWeekDays(this.startDay, this.endDay);

    if (response?.status == 'success') {
      if (response?.data) {
        this.rosterScheduleData = response?.data;
        if (this.isAdmin || this.isDispatchUser) {
          this.findWeeklyAssigneeConflicts();
        }

        const formattedWeekDays = this.weekDays?.map((date: any) =>
          this.formatDateWithoutTime(new Date(date))
        );

        this.groupByData = response?.group_by_data?.filter((group: any) => {
          return group.event_data.some((event: any) => {
            if (event.repeat_type === 'once') {
              return (
                formattedWeekDays.includes(
                  this.formatDateWithoutTime(new Date(event.start_day))
                ) &&
                (this.isAdmin || this.isDispatchUser
                  ? true
                  : formattedWeekDays.some((date: any) =>
                      this.isGuardPresentCheck(event, date)
                    ))
              );
            } else {
              return formattedWeekDays.some(
                (date: any) =>
                  event?.event_dates?.includes(
                    formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
                  ) &&
                  (this.isAdmin || this.isDispatchUser
                    ? true
                    : this.isGuardPresentCheck(event, date))
              );
            }
          });
        });
        this.groupByData.forEach((groupItem: any) => {
          groupItem?.event_data?.sort((a: any, b: any) => {
            let timeA = new Date('1970-01-01T' + a.start_time + 'Z');
            let timeB = new Date('1970-01-01T' + b.start_time + 'Z');
            return timeA.getTime() - timeB.getTime();
          });
        });

        if (window.innerWidth < 770) {
          this.formatMobileRosterSchedules(
            this.mobileselectedDate || this.startDay
          );
        }
      }
    } else {
      this.pageService.setMessage({
        successMessage: '',
        errorMessage: response['message'],
      });
    }
  }
  getRosterSchedules() {
    let reqParams: any = {
      start_day: formatDate(new Date(this.startDay), 'yyyy-MM-dd', 'en_US'),
      end_day: formatDate(new Date(this.endDay), 'yyyy-MM-dd', 'en_US'),
      subscriber: this.subscriberId,
      subscriber_user_id: this.subscriberUserId,
    };
    if (window.innerWidth >= 770) {
      reqParams.group_by = this.groupBy;
    }

    this.spinnerService.show();
    this.adminService
      .subscriberRelatedGETApis(
        'roster_schedule/schedule_roster',

        reqParams
      )
      .subscribe((response: any) => {
        this.formatRosterScheduleData(response);

        this.spinnerService.hide();
      });
  }

  formatRepeatDetail(scheduleData: any) {
    return getFormattedRepeatDetail(
      scheduleData?.repeat_type,
      scheduleData?.repeat_details
    );
  }

  avoidConflictforCancelledJobs(scheduleData: any, date: any) {
    return this.statusData?.some(
      (status: any) =>
        status.name === this.getJobStatus(scheduleData, date) &&
        [2, 3].includes(status?.status_identity?.value)
    );
  }

  getScheduleSpan(scheduleData: any) {
    if (scheduleData?.repeat_type == 'once') {
      return 3;
    }
    return 1;
  }
  getJobStatus(scheduleData: any, date: any) {
    return this.formatDateWithoutTime(new Date(date)) >=
      this.formatDateWithoutTime(new Date())
      ? scheduleData?.job_details?.schedule_details?.status?.name
        ? scheduleData?.job_details?.schedule_details?.status?.name
        : date
        ? scheduleData?.job_details?.schedule_details?.[
            formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
          ]?.status?.name
        : ''
      : '';
  }
  getEventAssignees(scheduleData: any, date: any) {
    if (!(this.isAdmin || this.isDispatchUser)) {
      return [];
    }
    return this.getEventAssigneeList(scheduleData, date);
  }
  getEventAssigneeList(scheduleData: any, date: any) {
    return scheduleData?.patrol_route_details
      ? scheduleData?.patrol_route_details?.schedule_details?.assignee_data
        ? scheduleData?.patrol_route_details?.schedule_details?.assignee_data
        : date
        ? scheduleData?.patrol_route_details?.schedule_details?.[
            formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
          ]?.assignee_data
        : []
      : scheduleData?.job_details?.schedule_details?.assignee_data
      ? scheduleData?.job_details?.schedule_details?.assignee_data
      : date
      ? scheduleData?.job_details?.schedule_details?.[
          formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
        ]?.assignee_data
      : [];
  }
  getWelfareCheckTime(scheduleData: any, date: any) {
    return scheduleData?.patrol_route_details
      ? scheduleData?.patrol_route_details?.schedule_details?.interval_check
        ? scheduleData?.patrol_route_details?.schedule_details?.interval_check
        : date
        ? scheduleData?.patrol_route_details?.schedule_details?.[
            formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
          ]?.interval_check
        : ''
      : scheduleData?.job_details?.schedule_details?.interval_check
      ? scheduleData?.job_details?.schedule_details?.interval_check
      : date
      ? scheduleData?.job_details?.schedule_details?.[
          formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
        ]?.interval_check
      : '';
  }
  getJobId(scheduleData: any, date: any) {
    return scheduleData?.job_details?.schedule_details?.job_id
      ? scheduleData?.job_details?.schedule_details?.job_id
      : date
      ? scheduleData?.job_details?.schedule_details?.[
          formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
        ]?.job_id
      : scheduleData?.job_details?.job_id;
  }
  getPatrolId(scheduleData: any, date: any) {
    return scheduleData?.patrol_route_details?.schedule_details?.patrol_route_id
      ? scheduleData?.patrol_route_details?.schedule_details?.patrol_route_id
      : date
      ? scheduleData?.patrol_route_details?.schedule_details?.[
          formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
        ]?.patrol_route_id
      : scheduleData?.patrol_route_details?.patrol_route_id;
  }
  getPatrolCheckpointCount(scheduleData: any, date: any) {
    return scheduleData?.patrol_route_details?.schedule_details
      ?.checkpoint_count
      ? scheduleData?.patrol_route_details?.schedule_details?.checkpoint_count
      : date
      ? scheduleData?.patrol_route_details?.schedule_details?.[
          formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
        ]?.checkpoint_count
      : scheduleData?.patrol_route_details?.checkpoint_count;
  }
  getPatrolRouteName(scheduleData: any, date: any) {
    return scheduleData?.patrol_route_details?.schedule_details?.name
      ? scheduleData?.patrol_route_details?.schedule_details?.name
      : date
      ? scheduleData?.patrol_route_details?.schedule_details?.[
          formatDate(new Date(date), 'yyyy-MM-dd', 'en_US')
        ]?.name
      : scheduleData?.patrol_route_details?.name;
  }
  getOnceWeekDayArray(scheduleData: any) {
    if (scheduleData?.event_dates?.length === 1) {
      return this.weekDays;
    } else {
      let eventDates = JSON.parse(JSON.stringify(scheduleData?.event_dates));
      eventDates.shift();

      let newArray = this.weekDays?.filter(
        (item: any) =>
          !eventDates?.includes(
            formatDate(new Date(item), 'yyyy-MM-dd', 'en_US')
          )
      );

      return newArray;
    }
  }
  getStartEndDateTimeArray(scheduleData: any, date: any) {
    const getTime = (date: Date, time: string) => {
      const [hours, minutes] = time.split(':').map(Number);
      date.setHours(hours, minutes);
      return date;
    };

    if (scheduleData?.repeat_type === 'once') {
      const startTime = new Date(
        scheduleData?.start_day + ' ' + scheduleData?.start_time
      );
      const endTime = new Date(
        scheduleData?.end_day + ' ' + scheduleData?.end_time
      );

      return [[startTime, endTime]];
    }

    const startTime = getTime(new Date(date), scheduleData?.start_time);
    const endTime = getTime(new Date(date), scheduleData?.end_time);

    if (scheduleData?.start_time > scheduleData?.end_time) {
      const startDate = new Date(date);
      startDate.setDate(startDate.getDate() - 1);
      const startTimeYesterDay = getTime(startDate, scheduleData?.start_time);
      const endTimeToday = endTime;
      const startTimeToday = startTime;
      const endDate = new Date(date);
      endDate.setDate(endDate.getDate() + 1);
      const endTimeTomorrow = getTime(endDate, scheduleData?.end_time);
      return [
        [startTimeYesterDay, endTimeToday],
        [startTimeToday, endTimeTomorrow],
      ];
    }

    return [[startTime, endTime]];
  }
  getStartEndDateTime(scheduleData: any) {
    let startTime = new Date();
    let endTime = new Date();

    if (scheduleData?.repeat_type == 'once') {
      startTime = new Date(
        scheduleData?.start_day + ' ' + scheduleData?.start_time
      );
      endTime = new Date(scheduleData?.end_day + ' ' + scheduleData?.end_time);
    } else {
      if (scheduleData?.start_time > scheduleData?.end_time) {
        endTime.setDate(endTime.getDate() + 1);
      }

      this.setTime(startTime, scheduleData?.start_time);
      this.setTime(endTime, scheduleData?.end_time);
    }

    return [startTime, endTime];
  }
  getTimeDifference(scheduleData: any) {
    let [startTime, endTime] = this.getStartEndDateTime(scheduleData);

    return this.getTimerValue(startTime, endTime);
  }

  setTime(date: Date, time: string) {
    const [hours, minutes] = time?.split(':');
    date.setHours(parseInt(hours), parseInt(minutes));
  }

  getTimerValue(startTime: any, endTime: any) {
    let diffInTime = endTime?.getTime() - startTime?.getTime();

    if (!diffInTime) return '0';
    let seconds = Math.floor(diffInTime / 1000);
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);

    return `${hours}:${this.pad(minutes)}`;
  }

  pad(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
  goToToday() {
    this.startDay = new Date();
    this.mobileselectedDate = new Date();
    this.getEndDay();

    this.getRosterSchedules();
  }
  trashIconIf(scheduleData: any, date: any) {
    if (!(this.isAdmin || this.isDispatchUser)) {
      return false;
    }
    let startTime = new Date(date);
    if (scheduleData?.repeat_type == 'once') {
      startTime = new Date(
        scheduleData?.start_day + ' ' + scheduleData?.start_time
      );
    } else {
      this.setTime(startTime, scheduleData?.start_time);
    }
    return Boolean(startTime > new Date());
  }
  openTemplate(template: TemplateRef<any>, scheduleData: any, date: any) {
    let startDateTime: any = new Date(date);
    if (scheduleData?.repeat_type == 'once') {
      startDateTime = new Date(
        scheduleData?.start_day + ' ' + scheduleData?.start_time
      );
    } else {
      this.setTime(startDateTime, scheduleData?.start_time);
    }

    if (!this.trashIconIf(scheduleData, date)) {
      this.pageService.setMessage({
        successMessage: '',
        errorMessage: 'Selected Date has been passed',
      });
      return;
    }
    this.dialogRef = this.dialogService.open(template, {
      context: { ...scheduleData, selectedDate: date },
    });
  }

  hasEventOnDay(day: any, grpEvent: any) {
    return grpEvent?.event_data?.some(
      (schedule: any) =>
        schedule?.event_dates?.includes(
          formatDate(new Date(day), 'yyyy-MM-dd', 'en_US')
        ) &&
        (this.isAdmin ||
          this.isDispatchUser ||
          this.isGuardPresentCheck(schedule, day)) &&
        (schedule?.repeat_type !== 'once' ||
          (schedule?.repeat_type == 'once' &&
            formatDate(new Date(day), 'yyyy-MM-dd', 'en_US') ===
              formatDate(new Date(schedule?.start_day), 'yyyy-MM-dd', 'en_US')))
    );
  }

  async parseDailySchedules(day: any) {
    const assigneeDict: any = {};
    const conflictDict: any = {};

    await Promise.all(
      this.rosterScheduleData.map(async (scheduleData: any) => {
        if (
          scheduleData?.event_dates?.includes(
            formatDate(new Date(day), 'yyyy-MM-dd', 'en_US')
          ) &&
          !this.avoidConflictforCancelledJobs(scheduleData, day)
        ) {
          const assignees = await this.getEventAssignees(scheduleData, day);
          if (assignees?.length) {
            const timigList = this.getStartEndDateTimeArray(scheduleData, day);

            await Promise.all(
              timigList.map((startEndTiming: any) => {
                assignees.map(async (assignee: any) => {
                  if (assignee?.id in assigneeDict) {
                    assigneeDict[assignee.id].forEach((timing: any) => {
                      if (
                        startEndTiming[0].getTime() <
                          timing.endTime.getTime() &&
                        timing.startTime.getTime() < startEndTiming[1].getTime()
                      ) {
                        if (assignee.id in conflictDict) {
                          if (
                            !conflictDict[assignee.id][
                              'conflictSchedules'
                            ].includes(scheduleData)
                          ) {
                            conflictDict[assignee.id]['conflictSchedules'].push(
                              scheduleData
                            );
                          }
                        } else {
                          conflictDict[assignee.id] = {
                            assigneeData: assignee,
                            conflictSchedules: [
                              scheduleData,
                              timing.scheduleData,
                            ],
                          };
                        }
                      }

                      assigneeDict[assignee.id].push({
                        startTime: startEndTiming[0],
                        endTime: startEndTiming[1],
                        scheduleData: scheduleData,
                      });
                    });
                  } else {
                    assigneeDict[assignee.id] = [
                      {
                        startTime: startEndTiming[0],
                        endTime: startEndTiming[1],
                        scheduleData: scheduleData,
                      },
                    ];
                  }
                });
              })
            );
          }
        }
      })
    );

    return conflictDict;
  }

  async findWeeklyAssigneeConflicts() {
    this.conflictData = [];

    const now = new Date();
    now.setHours(0, 0, 0, 0);
    await Promise.all(
      this.weekDays.map(async (day: any) => {
        if (
          this.formatDateWithoutTime(day) >= this.formatDateWithoutTime(now)
        ) {
          const conflicts = await this.parseDailySchedules(day);
          if (Object.keys(conflicts)?.length) {
            this.conflictData.push({ eventDate: day, conflictInfo: conflicts });
          }
        }
      })
    );
  }
  openConflictTemplate(template: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(template);
  }
  getConflictAssigneeInfo(value: any) {
    return value?.assigneeData;
  }
  getConflictSchedules(value: any) {
    return value?.conflictSchedules;
  }

  getFullName(userDict: any) {
    try {
      return userDict?.full_name?.trim() || 'Assigned';
    } catch (error) {
      return 'Assigned';
    }
  }

  formatDateWithoutTime = (date: any) => {
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };
  isGuardInConflict(date: any, guardId: any, scheduleData: any) {
    const formattedDate = this.formatDateWithoutTime(new Date(date));
    const onceStartTime = this.formatDateWithoutTime(
      new Date(`${scheduleData?.start_day} ${scheduleData?.start_time}`)
    );
    const endStartTime = this.formatDateWithoutTime(
      new Date(`${scheduleData?.end_day} ${scheduleData?.end_time}`)
    );

    return this.conflictData?.some((conflict: any) => {
      const formattedEventDate = this.formatDateWithoutTime(
        new Date(conflict?.eventDate)
      );
      const conflictSchedules =
        conflict?.conflictInfo?.[guardId]?.conflictSchedules;

      if (scheduleData?.repeat_type === 'once') {
        return (
          onceStartTime <= formattedEventDate &&
          endStartTime >= formattedEventDate &&
          conflictSchedules?.some(
            (schedule: any) => schedule?.id === scheduleData?.id
          )
        );
      } else {
        return (
          formattedEventDate === formattedDate &&
          conflictSchedules?.some(
            (schedule: any) => schedule?.id === scheduleData?.id
          )
        );
      }
    });
  }
  isScheduleInConflict(date: any, scheduleData: any) {
    const formattedDate = this.formatDateWithoutTime(new Date(date));
    const onceStartTime = this.formatDateWithoutTime(
      new Date(`${scheduleData?.start_day} ${scheduleData?.start_time}`)
    );
    const endStartTime = this.formatDateWithoutTime(
      new Date(`${scheduleData?.end_day} ${scheduleData?.end_time}`)
    );

    return this.conflictData?.some((conflict: any) => {
      const formattedEventDate = this.formatDateWithoutTime(
        new Date(conflict?.eventDate)
      );
      return Object.entries(conflict?.conflictInfo).some(
        ([k, v]: [string, any]) => {
          return v?.conflictSchedules?.some((schedule: any) => {
            if (schedule?.id !== scheduleData?.id) {
              return false;
            }
            if (scheduleData?.repeat_type === 'once') {
              return (
                onceStartTime <= formattedEventDate &&
                endStartTime >= formattedEventDate
              );
            }
            return formattedEventDate === formattedDate;
          });
        }
      );
    });
  }

  ngOnDestroy(): void {
    window.onpopstate = null;
  }

  handleDateChange(event: any) {
    if (event?.end) {
      this.searchDateRange = event;
      let reqParams: any = {
        start_day: formatDate(
          new Date(this.searchDateRange?.start),
          'yyyy-MM-dd',
          'en_US'
        ),
        end_day: formatDate(
          new Date(this.searchDateRange?.end),
          'yyyy-MM-dd',
          'en_US'
        ),
      };

      this.spinnerService.show();
      this.rsService
        .getRosterSchedules(reqParams)
        .subscribe((response: any) => {
          this.searchResultsBackup = JSON.parse(JSON.stringify(response?.data));
          this.searchJobs(response?.data);

          this.spinnerService.hide();
        });
    }
  }
  async findDaysJobs(date: any, searchString?: any) {
    let indDay = formatDate(new Date(date), 'yyyy-MM-dd', 'en_US');
    if (searchString) {
      return await Promise.all(
        this.tempSearchResults?.filter(
          (scheduleData: any) =>
            this.filterJobResults(scheduleData, indDay, searchString) &&
            scheduleData?.event_dates?.includes(indDay) &&
            (this.isAdmin ||
              this.isDispatchUser ||
              this.isGuardPresentCheck(scheduleData, date)) &&
            (scheduleData?.repeat_type !== 'once' ||
              (scheduleData?.repeat_type == 'once' &&
                formatDate(
                  new Date(scheduleData?.start_day),
                  'yyyy-MM-dd',
                  'en'
                ) === indDay))
        )
      );
    } else {
      return await Promise.all(
        this.tempSearchResults?.filter(
          (scheduleData: any) =>
            scheduleData?.event_dates?.includes(indDay) &&
            (this.isAdmin ||
              this.isDispatchUser ||
              this.isGuardPresentCheck(scheduleData, date)) &&
            (scheduleData?.repeat_type !== 'once' ||
              (scheduleData?.repeat_type == 'once' &&
                formatDate(
                  new Date(scheduleData?.start_day),
                  'yyyy-MM-dd',
                  'en'
                ) === indDay))
        )
      );
    }
  }
  async searchJobs(responseData: any, searchString?: any) {
    this.searchResults = [];
    this.tempSearchResults = JSON.parse(JSON.stringify(responseData));

    for (let i = 0; i <= this.getNoOfDays(); i++) {
      let startDate = new Date(this.searchDateRange?.start);
      startDate.setDate(startDate.getDate() + i);
      const dayJobs = await this.findDaysJobs(startDate, searchString);

      if (dayJobs?.length) {
        this.searchResults.push({
          eventDate: startDate,
          dayJobs: dayJobs,
        });
      }
    }
  }
  filterJobResults(scheduleData: any, indDay: any, searchString: any) {
    const jobType = scheduleData?.patrol_route_details
      ? 'Patrol'
      : scheduleData?.job_details?.job_type;
    return (
      scheduleData?.job_details?.company_name
        ?.toLowerCase()
        .includes(searchString?.toLowerCase()) ||
      scheduleData?.job_details?.site_name
        ?.toLowerCase()
        .includes(searchString?.toLowerCase()) ||
      this.getPatrolRouteName(scheduleData, indDay)
        ?.toLowerCase()
        .includes(searchString?.toLowerCase()) ||
      this.getJobStatus(scheduleData, indDay)
        ?.toLowerCase()
        .includes(searchString?.toLowerCase()) ||
      jobType?.toLowerCase().includes(searchString?.toLowerCase()) ||
      this.getEventAssigneeList(scheduleData, indDay)?.some((user: any) =>
        user?.full_name?.toLowerCase().includes(searchString?.toLowerCase())
      )
    );
  }

  filterJobs(event: any) {
    this.searchJobs(this.searchResultsBackup, event.target.value);
  }
  getNoOfDays() {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(this.searchDateRange?.start).getTime();
    const secondDate = new Date(this.searchDateRange?.end).getTime();

    return Math.round(Math.abs((firstDate - secondDate) / oneDay));
  }
  openSearchTemplate(template: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(template, {
      context: {},
    });
  }
}
