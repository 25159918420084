<div style="padding-left: 2rem; padding-right: 2rem">
  <div class="mobile-only">
    <div class="gt-logo-mobile">
      <img
        src="../../../assets/images/Guard_Tracker-Stacked.png"
        alt="Guard Tracker"
      />
    </div>
  </div>
  <div class="large-view-only">
    <div class="gt-logo-desktop">
      <img
        src="../../../assets/images/Guard_Tracker-Lateral_Long_default.png"
        alt="Guard Tracker"
      />
    </div>
  </div>
  <nb-card>
    <div class="text-center text-md-start">
      <h2>Permissions Required</h2>
      <div class="mb-4">
        Guard Tracker needs access to your camera and location, please enable
        these your browser settings and try again.
      </div>
      <div>
        <div class="mb-3">
          <div class="mb-1">
            <span class="strong">Camera </span>
            <span class="permission-icon">
              <nb-icon
                [icon]="
                  cameraPermission
                    ? 'checkmark-circle-2-outline'
                    : 'alert-triangle-outline'
                "
                [status]="cameraPermission ? 'success' : 'danger'"
              ></nb-icon>
            </span>
          </div>
          <!-- Use catch block error here `DOMException: Permission denied` -->
          <div *ngIf="!cameraPermission">
            Please enable access in your browser settings.
          </div>
          <div class="my-3" *ngIf="!cameraPermission">
            <button
              class="btn btn-sm btn-danger"
              (click)="requestCameraPermission()"
            >
              Request Access
            </button>
          </div>
        </div>
        <div>
          <div class="mb-1">
            <span class="strong">Location </span>
            <span class="permission-icon">
              <nb-icon
                [icon]="
                  locationPermission
                    ? 'checkmark-circle-2-outline'
                    : 'alert-triangle-outline'
                "
                [status]="locationPermission ? 'success' : 'danger'"
              ></nb-icon>
            </span>
          </div>
          <div *ngIf="!locationPermission" class="my-3">
            Please enable this your browser settings and reload the app.
          </div>
          <div class="my-3">
            <button class="btn btn-sm btn-danger" *ngIf="!locationPermission">
              Reload
            </button>
          </div>
        </div>
        <div>
          <div class="mb-3">
            <span class="strong">Notifications </span>
            <span class="permission-icon">
              <nb-icon
                [icon]="
                  notificationPermission
                    ? 'checkmark-circle-2-outline'
                    : 'alert-triangle-outline'
                "
                [status]="notificationPermission ? 'success' : 'danger'"
              ></nb-icon>
            </span>
          </div>
          <div *ngIf="!notificationPermission">
            Please enable access in your browser settings.
          </div>
          <div class="my-3" *ngIf="!notificationPermission">
            <button
              class="btn btn-sm btn-danger"
              (click)="requestNotificationPermission()"
            >
              Request Access
            </button>
          </div>
        </div>
      </div>
    </div>
  </nb-card>
  <div class="mt-5">
    <button size="giant" nbButton matRipple fullWidth (click)="goToHome()">
      Go Back
    </button>
  </div>
</div>
