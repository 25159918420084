<nb-card>
  <input
    #fileInput
    id="fileInput"
    nbInput
    style="display: none; cursor: pointer"
    type="file"
    accept="image/*"
    (change)="handleFileInput($event.target)"
  />
  <nb-card-body class="mobile-only gt-p-small">
    <div class="col">
      <div class="row row-cols-2">
        <div
          [ngClass]="
            imageUploaded
              ? 'col col-7 subscriber-logo-container'
              : 'col-6 offset-3 subscriber-logo-container'
          "
        >
          <img
            class="subscriber-logo-mobile"
            [src]="subscriberLogoUrl"
            alt="Logo"
          />
        </div>

        <div class="col col-5" *ngIf="isAdmin">
          <div class="row row-cols-1" *ngIf="imageUploaded">
            <div class="col">
              <button
                nbButton
                class="nbbutton-long-text"
                fullWidth
                size="giant"
                (click)="openCamPopup()"
              >
                Change Logo
              </button>
            </div>
            <div class="col">
              <button
                nbButton
                class="nbbutton-long-text"
                fullWidth
                (click)="removeImage()"
                size="giant"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!imageUploaded && isAdmin">
        <div class="col sc-text-center">
          <button status="basic" nbButton size="small" (click)="openCamPopup()">
            + Add Logo
          </button>
        </div>
      </div>
    </div>

    <div class="sc-mt-9">
      <div class="row">
        <div (click)="isAdmin ? openTemplate(companyDialog) : ''">
          <span class="badge-key">
            Name
            <nb-icon
              [pack]="'eva'"
              [icon]="'edit-2-outline'"
              *ngIf="isAdmin"
            ></nb-icon
          ></span>
          <span class="badge-value strong"> {{ companyDetails?.name }}</span>
        </div>
        <div>
          <span class="badge-key"> Address</span>
          <span class="badge-value">
            {{ companyDetails?.address?.full_address }}</span
          >
        </div>
        <div *ngIf="companyDetails?.phone">
          <span class="badge-key">Phone</span>
          <span class="badge-value"> {{ companyDetails?.phone }}</span>
        </div>
        <div>
          <span class="badge-key">Email</span>
          <span class="badge-value"> {{ companyDetails?.email }}</span>
        </div>
      </div>
    </div>

    <div class="sc-mt-6" *ngIf="hasMultipleAssociation === true">
      <button
        class="nbbutton-long-text"
        size="giant"
        nbButton
        status="basic"
        fullWidth
        (click)="changeSubscriber()"
      >
        Change Association
      </button>
    </div>
    <div
      style="
        border-top: 0.5rem solid var(--background-basic-color-3);
        padding: 0.5rem;
      "
    >
      <div class="sc-mt-6" *ngIf="companyLicenses?.length">
        <app-card-list
          [listCardData]="companyLicenses"
          [listCardDetails]="licenseDetails"
          [cardActionIcons]="isAdmin ? cardActionIcons : []"
          (actionClick)="isAdmin ? deleteLicense($event?.event) : ''"
        >
        </app-card-list>
      </div>
      <div class="mt-3" *ngIf="isAdmin">
        <button
          nbButton
          class="nbbutton-long-text"
          status="primary"
          fullWidth
          size="giant"
          (click)="addNewLicense()"
        >
          + Add Company Master Licence
        </button>
      </div>
    </div>
  </nb-card-body>

  <nb-card-body class="large-view-only gt-p-1">
    <div style="display: flex">
      <div class="profile-dp">
        <div *ngIf="imageUploaded" class="div.stretchy-wrapper">
          <img
            class="subscriber-logo"
            src="{{ subscriberLogoUrl }}"
            alt="Logo"
          />
        </div>
        <div
          *ngIf="!imageUploaded"
          class="div.stretchy-wrapper pointer"
          (click)="isAdmin ? openCamPopup() : ''"
        >
          <img
            class="subscriber-logo"
            src="{{ subscriberLogoUrl }}"
            alt="Logo"
          />
        </div>
        <div class="row" *ngIf="!imageUploaded && isAdmin">
          <div class="col">
            <button
              nbButton
              status="basic"
              fullWidth
              size="medium"
              (click)="openCamPopup()"
            >
              + Add Logo
            </button>
          </div>
        </div>

        <div *ngIf="imageUploaded && isAdmin">
          <div>
            <button nbButton fullWidth size="small" (click)="openCamPopup()">
              Change Logo
            </button>
          </div>
          <div>
            <button nbButton fullWidth (click)="removeImage()" size="small">
              Clear
            </button>
          </div>
        </div>
      </div>
      <div style="margin: 0rem; flex-grow: 1; max-width: 1080px" class="mt-2">
        <div class="row">
          <div>
            <span class="badge-key"> Name</span>
            <span class="badge-value strong"> {{ companyDetails?.name }}</span>
          </div>
          <div>
            <span class="badge-key"> Address</span>
            <span class="badge-value">
              {{ companyDetails?.address?.full_address }}</span
            >
          </div>
          <div *ngIf="companyDetails?.phone">
            <span class="badge-key">Phone</span>
            <span class="badge-value"> {{ companyDetails?.phone }}</span>
          </div>
          <div>
            <span class="badge-key">Email</span>
            <span class="badge-value"> {{ companyDetails?.email }}</span>
          </div>
        </div>
      </div>

      <div class="profile-buttons">
        <div class="row row-cols-1">
          <div class="col" *ngIf="isAdmin">
            <button
              nbButton
              status="basic"
              fullWidth
              (click)="openTemplate(companyDialog)"
            >
              Update Details
            </button>
          </div>

          <div class="col" *ngIf="hasMultipleAssociation === true">
            <button
              nbButton
              status="basic"
              fullWidth
              (click)="changeSubscriber()"
            >
              Switch Company
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="sc-mt-9">
      <strong
        >Company Licences
        <button
          nbButton
          class="gt-edit-btn"
          (click)="addNewLicense()"
          *ngIf="isAdmin"
        >
          + Add Company Master Licence
        </button></strong
      >
      <div class="mt-2">
        <app-card-list
          [listCardData]="companyLicenses"
          [listCardDetails]="licenseDetails"
          [cardActionIcons]="isAdmin ? cardActionIcons : []"
          (actionClick)="isAdmin ? deleteLicense($event?.event) : ''"
        >
        </app-card-list>
      </div>
    </div>
    <ng-container *ngIf="isAdmin === true || isDispatchUser === true">
      <div class="mt-5 ms-3 sc-head6">Event Logs</div>
      <app-user-updates-log [eventType]="'subscriber'"> </app-user-updates-log>
    </ng-container>
  </nb-card-body>
</nb-card>

<ng-template #companyDialog let-cpRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <form [formGroup]="companyForm" appFormEnterAction>
      <div *ngIf="companyDetails?.name" class="form-floating">
        <input
          class="form-control"
          nbInput
          type="text"
          fullWidth
          id="name"
          formControlName="name"
          placeholder="Company Name"
        />
        <label for="username">Company Name</label>
      </div>
      <div class="form-floating">
        <input
          class="form-control"
          nbInput
          type="text"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          placeholder="Email Address"
          fullWidth
          formControlName="email"
          placeholder="Email"
        />
        <label for="phone">Email</label>
      </div>
      <div class="form-floating">
        <input
          class="form-control"
          nbInput
          type="tel"
          minlength="10"
          maxlength="10"
          fullWidth
          id="phone"
          (input)="mobileNumberCheck()"
          formControlName="phone"
          placeholder="Telephone"
        />
        <label for="phone">Telephone</label>
      </div>

      <div class="form-floating">
        <input
          class="form-control"
          #addressAutoInput
          (input)="onAddressSearch($event)"
          nbInput
          fullWidth
          type="text"
          formControlName="addressLookup"
          placeholder="Address Lookup"
          [nbAutocomplete]="autoNgModel"
        />
        <label>Address Lookup</label>
      </div>
      <nb-autocomplete #autoNgModel>
        <nb-option
          *ngFor="let item of addrLookupData$ | async"
          [value]="item"
          (click)="addressSelected(item)"
        >
          <div>
            <span
              ><b>{{ item.address1 + " " + item.address2 }}</b
              >,</span
            ><br />
            <small
              >{{ item.address3 ? item.address3 + " " : "" }}
              {{ item.city_name }} {{ item.state_name }}
              {{ item.postcode }}</small
            >
          </div>
        </nb-option>
      </nb-autocomplete>
      <div>
        <div class="form-floating">
          <input
            class="form-control"
            type="tel"
            fullWidth
            nbInput
            id="address1"
            formControlName="address1"
            maxlength="30"
            placeholder="Street Number"
          />
          <label for="address1">Street Number</label>
        </div>
        <div class="form-floating">
          <input
            class="form-control"
            type="text"
            nbInput
            fullWidth
            id="address2"
            formControlName="address2"
            maxlength="100"
            autocomplete="off"
            placeholder="Street"
          />
          <label for="address2">Street Name</label>
        </div>
        <div class="form-floating">
          <input
            class="form-control"
            nbInput
            type="text"
            fullWidth
            id="address3"
            formControlName="address3"
            maxlength="100"
            autocomplete="off"
            placeholder="Unit"
          />
          <label for="address3">Address Prefix (Suite/Lot/Unit)</label>
        </div>
        <div class="form-floating">
          <input
            class="form-control"
            type="text"
            nbInput
            fullWidth
            id="city_name"
            formControlName="city_name"
            maxlength="100"
            autocomplete="off"
            placeholder="City"
            (input)="forceUppercaseConditionally($event)"
          />
          <label for="city_name">Suburb</label>
        </div>

        <div class="form-floating">
          <input
            class="form-control"
            type="number"
            nbInput
            fullWidth
            id="postcode"
            formControlName="postcode"
            autocomplete="off"
            placeholder="Post Code"
            maxlength="30"
          />
          <label for="postcode">Post Code</label>
        </div>

        <nb-select
          fullWidth
          placeholder="State"
          size="giant"
          formControlName="state_code"
          [(selected)]="selectedItem"
        >
          <nb-option *ngFor="let state of stateData" [value]="state.code">{{
            state.code
          }}</nb-option>
        </nb-select>
      </div>
      <div class="row row-cols-2">
        <div class="col">
          <button
            nbButton
            size="giant"
            fullWidth
            ghost
            (click)="cpRef.close('close')"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            id="submitbtn"
            nbButton
            size="giant"
            fullWidth
            [disabled]="!companyForm.valid"
            (click)="updateData(); cpRef.close()"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </nb-card>
</ng-template>
