import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-event-logs',
  templateUrl: './event-logs.component.html',
  styleUrls: ['./event-logs.component.scss'],
})
export class EventLogsComponent implements OnInit {
  toasterService: any;

  eventsTableView: any = [
    {
      header: 'User',
      nestedValue: (row: any) => {
        return row?.['updated_by']?.['full_name'];
      },
    },
    {
      header: 'Updated At',
      name: 'updated_at',
      datetimeObj: true,
    },

    {
      header: 'Event',
      name: 'event_name',
    },
    {
      header: 'Action',
      name: 'event_type',
    },
  ];

  previous: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;
  selectedEvent: any;

  constructor(
    private adminService: AdminService,
    private _location: Location,
    private spinnerService: NgxSpinnerService
  ) {
    this.getEventLogs();
  }
  eventData: any;
  ngOnInit(): void {}
  getEventLogs() {
    this.spinnerService.show();
    let params: any = {};

    if (this.rows) {
      params['page_size'] = this.rows;
    }
    if (this.previous) {
      params['page'] = this.pageNum;
    }

    this.adminService.getEventLogs(params).subscribe((response: any) => {
      this.eventData = response?.results;
      this.totalRows = response?.count;
      this.totalPages = Math.ceil(this.totalRows / this.rows);

      this.spinnerService.hide();
    });
  }
  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getEventLogs();
  }

  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getEventLogs();
  }

  goBack() {
    this._location.back();
  }
  oneventClick(event: any) {
    this.selectedEvent = event;
  }
}
