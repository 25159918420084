<div class="mobile-only gt-p-small">
  <ng-container *ngIf="!selectedUser?.id">
    <div *ngFor="let user of userMessageList" (click)="getUserMessage(user)">
      <div class="d-flex user-message-list">
        <div class="flex-grow-1">{{ user?.full_name }}</div>
        <div *ngIf="user?.unread">{{ user?.unread }}</div>
      </div>
    </div>
    <div class="mt-1 mx-1">
      <div class="fixed-add-btn">
        <button
          class="button"
          nbButton
          shape="rectangle"
          status="primary"
          (click)="openTemplate(guardTemplate)"
        >
          <span>+</span>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="selectedUser?.id">
    <div [style.padding-bottom]="bottomSectionFixedHeight + ' !important'">
      <div class="sc-head5 sc-text-center">{{ selectedUser?.full_name }}</div>

      <div
        *ngFor="let message of messageList"
        [ngClass]="message?.sender?.id == subscriberUserId ? 'sender' : ''"
      >
        <div class="user-message">
          <div class="small-font">
            {{ message?.created_at | customdate : "d MMM y HH:mm" }}
            <span
              *ngIf="message?.event_key"
              (click)="redirectToJob(message)"
              class="job-event"
            >
              | {{ message?.event_key }}</span
            >
          </div>
          <div style="font-size: 0.85rem">
            {{ message?.message }}
          </div>
          <img
            class="zoomImage"
            (click)="onOpenImage($event, imgRef)"
            *ngIf="message?.file"
            src="{{ message?.file }}"
            height="120"
            width="120"
          />
          <div
            class="small-font sc-floating-right"
            *ngIf="message?.sender?.id == subscriberUserId && message?.read_at"
          >
            Read at : {{ message?.read_at | customdate : "d MMM y HH:mm" }}
          </div>
        </div>
      </div>
    </div>
    <div id="bottom-section-fixed" class="bottom-section">
      <div class="d-flex">
        <div class="flex-grow-1">
          <div
            *ngIf="selectedJobData?.event_id"
            (click)="selectedJobData = {}"
            style="
              background-color: bisque;
              font-size: 0.6rem;
              font-weight: 800;
            "
          >
            Link : {{ selectedJobData?.job_id }}
            <div class="sc-floating-right">x</div>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              nbInput
              fullWidth
              type="text"
              [(ngModel)]="chatMessage"
              size="medium"
              placeholder="Message"
            />
            <label>Message</label>
          </div>
        </div>
        <div class="ms-1" *ngIf="jobListData?.length">
          <button
            style="height: 100%"
            nbButton
            size="medium"
            fullWidth
            status="info"
            (click)="linkJob(jobTemplate)"
          >
            @
          </button>
        </div>
        <div class="ms-1">
          <button
            style="height: 100%"
            nbButton
            size="medium"
            fullWidth
            status="info"
            (click)="openCamPopupDialog()"
          >
            +
          </button>
        </div>
        <div class="ms-1">
          <button
            style="height: 100%"
            nbButton
            size="medium"
            fullWidth
            status="success"
            [disabled]="!chatMessage"
            (click)="sendMessage()"
          >
            >
          </button>
        </div>
      </div>

      <div class="pt-2">
        <button
          nbButton
          size="giant"
          fullWidth
          status="primary"
          (click)="goBack()"
        >
          Back
        </button>
      </div>
    </div>
  </ng-container>
</div>

<div class="large-view-only gt-p-small">
  <div class="row row-cols-2">
    <div class="col-4" style="overflow-y: scroll">
      <ng-container>
        <div
          *ngFor="let user of userMessageList"
          (click)="this.messageList = []; getUserMessage(user)"
        >
          <div class="d-flex user-message-list">
            <div class="flex-grow-1">{{ user?.full_name }}</div>
            <div *ngIf="user?.unread">{{ user?.unread }}</div>
          </div>
        </div>
        <div class="mt-1 mx-1 sc-text-center">
          <div>
            <button
              class="button"
              nbButton
              shape="rectangle"
              status="primary"
              (click)="openTemplate(guardTemplate)"
            >
              <span>Start Chat</span>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div
      class="col-8"
      style="overflow-y: scroll; padding: 1rem; height: 90vh"
      #scrollMe
      [scrollTop]="scrollMe.scrollHeight"
    >
      <ng-container *ngIf="selectedUser?.id">
        <div [style.padding-bottom]="bottomSectionFixedHeight + ' !important'">
          <div class="sc-head5 sc-text-center">
            {{ selectedUser?.full_name }}
          </div>
          <div
            *ngIf="totalRows > messageList?.length"
            class="mb-2 sc-text-center"
          >
            <button
              class="button"
              nbButton
              shape="rectangle"
              (click)="getOldMessages()"
            >
              See Old Messages
            </button>
          </div>

          <div
            *ngFor="let message of messageList"
            [ngClass]="message?.sender?.id == subscriberUserId ? 'sender' : ''"
          >
            <div class="user-message">
              <div class="small-font">
                {{ message?.created_at | customdate : "d MMM y HH:mm" }}
                <span
                  *ngIf="message?.event_key"
                  (click)="redirectToJob(message)"
                  class="job-event"
                >
                  | {{ message?.event_key }}</span
                >
              </div>
              <div style="font-size: 0.85rem">
                {{ message?.message }}
              </div>
              <img
                class="zoomImage"
                (click)="onOpenImage($event, imgRef)"
                *ngIf="message?.file"
                src="{{ message?.file }}"
                height="120"
                width="120"
              />
              <div
                class="small-font sc-floating-right"
                *ngIf="
                  message?.sender?.id == subscriberUserId && message?.read_at
                "
              >
                Read at : {{ message?.read_at | customdate : "d MMM y HH:mm" }}
              </div>
            </div>
          </div>
        </div>
        <div id="bottom-section-fixed">
          <div class="d-flex">
            <div class="flex-grow-1">
              <div
                *ngIf="selectedJobData?.event_id"
                (click)="selectedJobData = {}"
                style="
                  background-color: bisque;
                  font-size: 0.6rem;
                  font-weight: 800;
                "
              >
                Link : {{ selectedJobData?.job_id }}
                <div class="sc-floating-right">x</div>
              </div>
              <div class="form-floating">
                <input
                  class="form-control"
                  nbInput
                  fullWidth
                  type="text"
                  [(ngModel)]="chatMessage"
                  size="medium"
                  placeholder="Message"
                />
                <label>Message</label>
              </div>
            </div>
            <div class="ms-1" *ngIf="jobListData?.length">
              <button
                style="height: 100%"
                nbButton
                size="medium"
                fullWidth
                status="info"
                (click)="linkJob(jobTemplate)"
              >
                @
              </button>
            </div>
            <div class="ms-1">
              <button
                style="height: 100%"
                nbButton
                size="medium"
                fullWidth
                status="info"
                (click)="openCamPopupDialog()"
              >
                File
              </button>
            </div>
            <div class="ms-1">
              <button
                style="height: 100%"
                nbButton
                size="medium"
                fullWidth
                status="success"
                [disabled]="!chatMessage"
                (click)="sendMessage()"
              >
                SEND
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #guardTemplate let-data let-guardTemplate="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header>
      Select Guard

      <nb-icon
        (click)="guardTemplate.close()"
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <div>
      <div class="sc-mt-6">
        <div class="form-floating">
          <input
            class="form-control"
            id="mobassigneeField"
            fullWidth
            (input)="searchAssignee($event)"
            nbInput
            type="text"
            placeholder="Assign Guard"
            [nbAutocomplete]="assigneeNgModelDesk"
            autocomplete="off"
          />
          <label>Assign Guard</label>
        </div>
        <nb-autocomplete
          #assigneeNgModelDesk
          (selectedChange)="
            newChat = true;
            getUserMessage($event);
            guardTemplate.close();
            messageList = []
          "
        >
          <nb-option
            *ngFor="let assignee of assigneeSearchList"
            [value]="assignee"
          >
            {{ assignee?.full_name }}
          </nb-option>
        </nb-autocomplete>

        <div class="sc-mt-6">
          <table>
            <tbody>
              <tr *ngFor="let assignee of assigneeList" class="pointer">
                <td
                  (click)="
                    newChat = true;
                    getUserMessage(assignee);
                    guardTemplate.close();
                    messageList = []
                  "
                >
                  {{ assignee?.full_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </nb-card>
</ng-template>
<!-- Modal -->
<ng-template #imgRef let-data let-imgTempRef="dialogRef">
  <nb-card style="margin: 0">
    <nb-card-body (click)="imgTempRef.close()">
      <img
        src="{{ data?.src }}"
        style="
          max-width: 100%;
          cursor: pointer;
          max-height: 95vh;
          border: 3px var(--background-basic-color-3) solid;
          border-radius: 2%;
        "
        class="modal-hover-opacity"
      />
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #jobTemplate let-data let-jobTemplate="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header>
      Select Job

      <nb-icon
        (click)="jobTemplate.close()"
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
      ></nb-icon>
    </nb-card-header>

    <div class="sc-mt-6">
      <div *ngIf="jobListData?.length" class="list-fixed-height row mt-1">
        <app-card-list
          [listCardData]="jobListData"
          [listCardDetails]="jobCardsDetails"
          [showTableFrom]="'jobsPage'"
          (rowClick)="selectedJobData = $event; jobTemplate.close()"
        ></app-card-list>
      </div>
    </div>
  </nb-card>
</ng-template>
