import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NbDialogService,
  NbStepperComponent,
  NbToastrService,
} from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { CheckpointService } from '../checkpoint.service';

import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { AddCustomerComponent } from 'src/app/shared/comp/add-customer/add-customer.component';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { SaveNewAddressComponent } from 'src/app/shared/comp/save-new-address/save-new-address.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import {
  browserGpsInfo,
  checkScanDistanceGreater,
  getDistanceUsingHaversine,
} from 'src/global.variables';
import { JobsService } from '../../jobs/jobs.service';
import { PagesService } from '../../pages.service';
import { ProfileService } from '../../profile/profile.service';
import { checkPointScanTable } from '../../tableDetails';
import { UserPromptService } from '../../user-prompts/user-prompt.service';

@Component({
  selector: 'app-view-checkpoint',
  templateUrl: './view-checkpoint.component.html',
  styleUrls: ['./view-checkpoint.component.scss'],
})
export class ViewCheckpointComponent implements OnInit, OnDestroy {
  mapLatLong = { lat: -33.88434016724868, lon: 151.19734676150136 };
  @ViewChild('stepper') stepper: any = NbStepperComponent;

  checkPointForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    company: new UntypedFormControl('', Validators.required),
    company_id: new UntypedFormControl('', Validators.required),
    addressLookup: new UntypedFormControl(''),
    min_distance: new UntypedFormControl(100, [
      Validators.max(1000),
      Validators.required,
    ]),

    description: new UntypedFormControl(),
    address1: new UntypedFormControl(),
    address2: new UntypedFormControl(),
    address3: new UntypedFormControl(),
    city_name: new UntypedFormControl(''),
    state_code: new UntypedFormControl(''),
    country_name: new UntypedFormControl(''),
    postcode: new UntypedFormControl(''),
    longitude: new UntypedFormControl('', [Validators.required]),
    latitude: new UntypedFormControl('', [Validators.required]),
    is_locked: new UntypedFormControl(false),
  });

  userData: any;
  tableStyle = {
    'overflow': 'auto',
    'max-height': '600px',
  };
  pendCheckpointDetails: any = {
    individualDetails: [
      {
        name: 'remaining_time',
        cardRowStyle: {
          color: 'red',
        },
      },
      {
        nestedValue: (row: any) => {
          return row?.['check_point']?.['name'];
        },
        cardRowStyle: {
          'font-weight': 'bolder',
        },
      },
      {
        nestedValue: (row: any) => {
          return row?.['check_point']?.['company'];
        },
        cardRowStyle: {
          'font-weight': 'bolder',
          'font-size': 'small',
        },
      },
      {
        name: 'distance',
        suffix: 'Km',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },
      {
        name: 'route_name',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };

  isAdmin: any;
  upComingCheckPoints: any;
  cpId: any;

  detailData: any;
  showDetails: boolean = false;
  selectedEvent: any;
  uploadedImage: any;
  userEvents: any;

  userEventDetailsTableView: any = checkPointScanTable.desktopTable;

  dateRange: any;
  minKm: number = 5;
  maxKm: number = 1000;
  nearestKmValue = 100;
  backupMinDistanceValue: number = 0;
  // checkpoint history log
  checkpointHistoryData: any;
  checkpointHistoryTable = [
    {
      header: 'Action',
      name: 'action',
    },
    {
      header: 'Detail',
      name: 'detail',
    },
    {
      header: 'User',
      name: 'user_name',
    },
    {
      header: 'Updated At',
      name: 'updated_at',
      datetimeObj: true,
    },
  ];
  showHistoryDetails: boolean = false;
  selectedHistoryEvent: any;
  mapLatLongHistory: any;
  dialogref: any;
  browserGpsInfo: any = browserGpsInfo;

  //validate checkpoint
  addrLookupData$: Observable<any> | any;
  showMap: boolean = false;
  gps: any;
  originalLatLng: any = {};
  customerDetails: any;
  dialogRef: any;
  pinIsFar: boolean = false;

  // prompt variables
  selectedUserPrompts: any = [];
  availableUserPrompts: any = [];
  userPromptsRows: number = 5;
  userPromptsPrevious: number = 0;
  userPromptsPageNum: number = 1;
  userPromptsTotalPages: number = 0;
  userPromptsTotalCount: number = 0;
  userPromptsSearchResults: any = [];
  scanDistanceInfo: any = {};

  mobileView: boolean = window.innerWidth < 770;

  constructor(
    private checkpointService: CheckpointService,
    private spinnerService: NgxSpinnerService,
    private pageService: PagesService,
    private appService: AppService,
    private toasterService: NbToastrService,
    private dialogService: NbDialogService,
    private dataCheckService: DataCheckService,
    private profileService: ProfileService,
    private _location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private jobService: JobsService,
    private userPromptService: UserPromptService
  ) {
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
      }
      this.updateGeoDistance();
    });
    this.userData = this.appService.getUserData();
    this.isAdmin = this.dataCheckService.isUserAdmin();

    this.route.params.subscribe({
      next: (params) => {
        if (params?.['cpKey']) {
          this.cpId = params?.['cpKey'];

          this.getCheckPointDetails();
        }
      },
    });
  }
  updateGeoDistance() {
    if (
      this.nearestKmValue !== this.backupMinDistanceValue &&
      this.nearestKmValue <= this.maxKm &&
      this.nearestKmValue >= this.minKm &&
      this.detailData?.id &&
      this.detailData?.min_distance
    ) {
      this.updateCP(this.checkPointForm);
    }
  }

  ngOnInit(): void {
    this.getUserPrompts();
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('geoFenceMinRadius');
  }

  redirectCompany(company_id: any) {
    this.router.navigate(['/view-client', { cKey: String(company_id) }]);
  }
  getCheckPointDetails() {
    let params: any = {};
    if (window.innerWidth >= 770) {
      params['additional_info'] = 1;
    }
    this.checkpointService
      .getCheckPointDetailsById(this.cpId, params)
      .subscribe((resp: any) => {
        this.formatData(resp);
      });
  }
  onCPHistoryClick(event: any) {
    this.showHistoryDetails = true;
    this.selectedHistoryEvent = event;
    this.tableStyle = {
      'overflow': 'auto',
      'max-height': '35vh',
    };

    if (event?.gps) this.mapLatLongHistory = { ...event?.gps };
  }

  mapsSelector(lat: any, lon: any) {
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.platform.indexOf('iPhone') != -1 ||
      navigator.platform.indexOf('iPad') != -1 ||
      navigator.platform.indexOf('iPod') != -1
    )
      window.open(`maps://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
    /* else use Google */ else
      window.open(`https://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
  }
  onPendingCheckPointClick(event: any) {
    this.mapsSelector(
      event?.check_point?.address?.latitude,
      event?.check_point?.address?.longitude
    );
  }
  getTimeDifference() {
    this.upComingCheckPoints?.forEach((item: any) => {
      if (item?.end_time) {
        let d1 = Date.parse(item.end_time);
        let d2: Date = new Date();
        let diff = d1 - d2.getTime();

        let diffHrs = Math.floor((diff % 86400000) / 3600000); // hours
        let diffMins = Math.round(((diff % 86400000) % 3600000) / 60000); // minutes
        let returnString = '';
        if (diffHrs) returnString += `${diffHrs} hour(s) & `;
        returnString += `${diffMins} mins remaining`;
        item.remaining_time = returnString;
      }
    });
  }
  deleteCheckPoint(event: any) {
    let dialogMsg = `WARNING: This action cannot be reversed.`;
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: `Delete Checkpoint`,
        message: dialogMsg,
        alertDanger: true,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.checkpointService
          .deleteCheckpointById(event.id)
          .subscribe((response: any) => {
            this.toasterService.show('', response['message']);
            let subscriberStats = JSON.parse(
              localStorage.getItem('subscriberStats') || '{}'
            );
            subscriberStats.checkpoint_count =
              (subscriberStats?.checkpoint_count || 0) - 1;
            localStorage.setItem(
              'subscriberStats',
              JSON.stringify(subscriberStats)
            );
            this._location.back();
          });
      }
    });
  }
  onTableRowClick(event: any) {
    this.showDetails = true;
    this.selectedEvent = event;
    this.tableStyle = {
      'overflow': 'auto',
      'max-height': '35vh',
    };

    if (event?.gps) this.mapLatLong = { ...event?.gps };
    this.uploadedImage = event?.file;
    this.scanDistanceInfo = checkScanDistanceGreater(this.selectedEvent);
  }
  handleDateChange(event: any) {
    if (event?.end) {
      this.dateRange = event;
    }
  }
  openTemplate(template: TemplateRef<any>) {
    {
      this.dialogref = this.dialogService.open(template, {
        context: {},
      });
    }
  }

  formatData(response: any) {
    this.pinIsFar = false;
    this.showMap = false;
    this.detailData = response['data'];
    let btnValidateElement: any = document.querySelectorAll(
      '.btnValidateCheckpoint'
    );
    if (!this.detailData?.address?.state_code) {
      btnValidateElement.forEach((element: any) => {
        element.disabled = true;
      });
    }
    this.userEvents = response['scan_details'];
    this.checkpointHistoryData = response['history'];

    sessionStorage.setItem('geoFenceMinRadius', this.detailData?.min_distance);
    this.originalLatLng.lat = this.detailData?.address?.latitude;
    this.originalLatLng.lon = this.detailData?.address?.longitude;
    this.selectedUserPrompts = [...this.detailData?.user_prompts];
    this.makeSelectionChanges();
    this.nearestKmValue = this.detailData?.min_distance;
    this.backupMinDistanceValue = this.detailData?.min_distance;
    this.checkPointForm.patchValue(this.detailData);
    this.checkPointForm.patchValue({
      name: this.detailData?.name,
      company: this.detailData?.company,
      company_id: this.detailData?.company_id,
      addressLookup: this.detailData?.address?.full_address,
      min_distance: this.detailData?.min_distance,
      description: this.detailData?.description,
      address1: this.detailData?.address?.address1,
      address2: this.detailData?.address?.address2,
      address3: this.detailData?.address?.address3,
      city_name: this.detailData?.address?.city_name,
      state_code: this.detailData?.address?.state_code,
      country_name: this.detailData?.address?.country_name,
      postcode: this.detailData?.address?.postcode,
      latitude: this.detailData?.latitude,
      longitude: this.detailData?.longitude,
    });
    if (this.detailData?.longitude && this.detailData?.latitude) {
      this.gps = {
        lat: this.detailData?.latitude,
        lon: this.detailData?.longitude,
      };
    }
  }

  updateCP(form: any, validate = false) {
    let params: any = {};
    if (window.innerWidth >= 770) {
      params['additional_info'] = 1;
    }
    if (!this.detailData?.is_validated) {
      params['validate'] = 1;
    }

    this.spinnerService.show();
    this.checkpointService
      .updateCheckpoint(
        this.detailData?.id,
        {
          ...form.value,
          user_prompt_ids: [
            ...new Set([...this.selectedUserPrompts].map((item) => item?.id)),
          ],
        },
        params
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.formatData(response);
          this.dialogref?.close();
          this.spinnerService.hide();
          this.pageService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
          if (validate) {
            if (response?.validate_qr_count) {
              this.router.navigate(['/checkpoints'], {
                fragment: 'validateQr',
              });
            } else {
              this.router.navigate(['/checkpoints']);
            }
          }
        } else {
          this.showMap = false;
          this.spinnerService.hide();
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  onClickScanHistory() {
    this.router.navigate(
      ['/user-events', { cpKey: String(this.detailData?.id) }],
      {
        fragment: 'checkpointScans',
      }
    );
  }
  onAddressSearch(addressSearch?: any) {
    if (addressSearch?.target?.value?.length > 2) {
      this.profileService
        .addressLookup(addressSearch?.target?.value)
        .subscribe((res: any) => {
          this.addrLookupData$ = of(res['data']);
        });
    }
  }
  shiftFocus(elementId: string, time?: number) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    }, time);
  }

  addressSelected(address: any) {
    if (address === ' ') {
      this.addPlace();
    } else if (address && typeof address === 'object') {
      this.addrLookupData$ = undefined;

      this.checkPointForm.controls['name'].setValue(
        address?.address1 + ' ' + address?.address2
      );

      this.gps = { lat: address?.latitude, lon: address?.longitude };
      this.checkPointForm.controls['address1'].setValue(address?.address1);
      this.checkPointForm.controls['addressLookup'].setValue(
        address?.address1 +
          ' ' +
          address?.address2 +
          ' ' +
          address?.city_name +
          ' ' +
          address?.state_code +
          ' ' +
          address?.postcode
      );

      this.checkPointForm.controls['address2'].setValue(address?.address2);
      this.checkPointForm.controls['address3'].setValue(address?.address3);
      this.checkPointForm.controls['city_name'].setValue(address?.city_name);
      this.checkPointForm.controls['postcode'].setValue(address?.postcode);
      this.checkPointForm.controls['state_code'].setValue(address?.state_code);
      this.checkPointForm.controls['country_name'].setValue(
        address?.country_name
      );
      this.checkPointForm.controls['latitude'].setValue(address?.latitude);
      this.checkPointForm.controls['longitude'].setValue(address?.longitude);
      let distanceInKm = Number(
        getDistanceUsingHaversine(
          this.originalLatLng.lat,
          this.gps.lat,
          this.originalLatLng.lon,
          this.gps.lon
        )
      );

      this.pinIsFar = distanceInKm > 1;
      this.showMap = true;
      this.disableId('addressSearch');
      this.disableId('addressSearchEdit');
    }
  }
  disableId(idVal: string = 'addressSearch') {
    var element = <HTMLInputElement>document.getElementById(idVal);
    if (element) {
      element.disabled = false;
      element.blur();
    }
  }

  emitData(event: any, addCheck = false) {
    this.checkPointForm.controls['latitude'].setValue(event.lat);
    this.checkPointForm.controls['longitude'].setValue(event.lng);

    this.gps.lat = event.lat;
    this.gps.lon = event.lng == undefined ? event.lon : event.lng;
    if (addCheck) {
      let distanceInKm = Number(
        getDistanceUsingHaversine(
          this.originalLatLng.lat,
          this.gps.lat,
          this.originalLatLng.lon,
          this.gps.lon
        )
      );

      this.pinIsFar = distanceInKm > 1;
    }
  }
  updateMinDistance(event: any) {
    // method to update the min distance emitted from the map component
    this.checkPointForm.controls['min_distance'].setValue(event);
  }
  addPlace(context = { showFrom: 'cpPage' }) {
    this.showMap = false;
    const dialogRef = this.dialogService.open(SaveNewAddressComponent, {
      dialogClass: 'model-full',
      context: context,
    });
    dialogRef.onClose.subscribe((value: any) => {
      if (value != 'close') {
        if (value) {
          this.addressSelected(value);
        }
      } else {
        this.addressSelected(this.detailData?.address);
      }
    });
  }
  onClientSelect(data: any) {
    if (data === ' ') {
      this.openAddCustomerForm();
    }
    if (data?.id) {
      this.checkPointForm.controls['company_id'].setValue(data.id);
      this.checkPointForm.controls['company'].setValue(data.company_name);
    }
  }
  openAddCustomerForm() {
    this.dialogref = this.dialogService.open(AddCustomerComponent, {
      context: { customerName: this.checkPointForm.value?.company },
      dialogClass: 'model-full',
    });

    this.dialogref.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.onClientSelect(value?.data);
      }
    });
  }
  onClientSearch(event: any) {
    if (event.target.value?.length > 2) {
      this.jobService
        .getSearchClients(event.target.value)
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.customerDetails = res['data'];
          }
        });
    }
  }
  stepperSkipStep() {
    if (!this.checkPointForm.value.is_locked) {
      this.stepper.previous();
      this.stepper.previous();
    } else {
      this.showMap = false;
      this.stepper.previous();
      setTimeout(() => {
        this.showMap = true;
      }, 100);
    }
  }
  stepperSkipNExtStep() {
    if (!this.checkPointForm.value.is_locked && this.detailData?.qr_code) {
      this.stepper.next();
      this.stepper.next();
    } else {
      this.showMap = false;
      this.stepper.next();
      setTimeout(() => {
        this.showMap = true;
      }, 100);
    }
  }

  showMapTrue() {
    this.showMap = false;

    setTimeout(() => {
      this.showMap = true;
    }, 100);
  }
  resetValues() {
    this.pinIsFar = false;
    this.showMap = false;

    this.originalLatLng.lat = this.detailData?.address?.latitude;
    this.originalLatLng.lon = this.detailData?.address?.longitude;

    this.checkPointForm.patchValue({
      name: this.detailData?.name,
      company: this.detailData?.company,
      company_id: this.detailData?.company_id,
      addressLookup: this.detailData?.address?.full_address,
      min_distance: this.detailData?.min_distance,
      description: this.detailData?.description,
      address1: this.detailData?.address?.address1,
      address2: this.detailData?.address?.address2,
      address3: this.detailData?.address?.address3,
      city_name: this.detailData?.address?.city_name,
      state_code: this.detailData?.address?.state_code,
      country_name: this.detailData?.address?.country_name,
      postcode: this.detailData?.address?.postcode,
      latitude: this.detailData?.latitude,
      longitude: this.detailData?.longitude,
    });
    if (this.detailData?.longitude && this.detailData?.latitude) {
      this.gps = {
        lat: this.detailData?.latitude,
        lon: this.detailData?.longitude,
      };
    }
  }

  getPromptDetail(promptData: any) {
    this.router.navigate([
      '/user-prompt',
      {
        uPKey: String(promptData?.id),
      },
    ]);
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  searchPrompts(event: any) {
    if (event.target.value?.length > 2) {
      const params: any = { search_str: event.target.value };
      this.userPromptService
        .getUserPromptList(params)
        .subscribe((response: any) => {
          this.userPromptsSearchResults = response?.data?.filter(
            (value1: any) =>
              !this.selectedUserPrompts.some(
                (value2: any) => value1?.id === value2?.id
              )
          );
        });
    }
  }
  onLoadMore() {
    this.userPromptsPrevious = this.userPromptsPrevious + this.userPromptsRows;

    this.getUserPrompts();
  }
  getUserPrompts(event?: any) {
    let params: any = {};
    if (this.userPromptsRows) {
      params['rows'] = this.userPromptsRows;
    }
    if (this.userPromptsPrevious) {
      params['previous'] = this.userPromptsPrevious;
    }

    if (event?.target?.value) {
      params['name'] = event.target.value;
    }

    this.userPromptService
      .getUserPromptList(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          response?.data.forEach((item1: any) => {
            if (
              !this.availableUserPrompts.some(
                (item2: any) => item2?.id === item1?.id
              )
            ) {
              this.availableUserPrompts.push(item1);
            }
          });
          this.makeSelectionChanges();

          this.userPromptsTotalCount = response['total_size'];
          this.userPromptsTotalPages = Math.ceil(
            this.userPromptsTotalCount / this.userPromptsRows
          );
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  removeSelectedUserPrompt(deleteUserPrompt: any) {
    this.selectedUserPrompts = this.selectedUserPrompts?.filter(
      (savedPrompt: any) => savedPrompt?.id !== deleteUserPrompt?.id
    );

    setTimeout(() => {
      this.makeSelectionChanges();
      this.showMap = true;
    }, 100);
  }
  addSelectedUserPrompt(userPrompt: any) {
    this.showMap = false;
    if (
      this.selectedUserPrompts?.some(
        (savedPrompt: any) => savedPrompt?.id === userPrompt?.id
      )
    ) {
      this.pageService.setMessage({
        errorMessage: 'Checkpoint Already Selected',
        successMessage: '',
      });
    } else {
      this.selectedUserPrompts.push(userPrompt);
      if (
        !this.availableUserPrompts?.some(
          (savedPrompt: any) => savedPrompt?.id === userPrompt?.id
        )
      ) {
        this.availableUserPrompts.push(userPrompt);
      }
    }

    setTimeout(() => {
      this.makeSelectionChanges();
      this.showMap = true;
    }, 100);
  }
  addRemoveUserPrompt(event: any, userPrompt: any) {
    if (event) {
      this.addSelectedUserPrompt(userPrompt);
    } else {
      this.removeSelectedUserPrompt(userPrompt);
    }
  }
  makeSelectionChanges() {
    this.selectedUserPrompts?.forEach((savedUserPrompt: any) => {
      if (
        !this.availableUserPrompts?.some(
          (userPrompt: any) => userPrompt?.id === savedUserPrompt?.id
        )
      ) {
        this.availableUserPrompts.push(savedUserPrompt);
      }
    });

    this.availableUserPrompts.forEach((userPrompt: any) => {
      userPrompt.selected = this.selectedUserPrompts?.some(
        (selectedUp: any) => selectedUp?.id === userPrompt?.id
      );
    });
  }
  getPromptValue(promptData: any) {
    return Array.isArray(promptData?.value)
      ? promptData?.value?.join(', ')
      : promptData?.value;
  }
  lockUnlock() {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: 'Are you sure?',
        message: this.detailData?.is_locked
          ? 'Allow this checkpoint to be scanned from any where?'
          : 'Lock checkpoint so guards can only scan within geofence radius?',
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.checkPointForm.controls['is_locked'].setValue(
          !this.detailData?.is_locked
        );
        this.updateCP(this.checkPointForm);
      }
    });
  }
}
