<div class="mt-3">
  <nb-alert *ngIf="subscriberData?.grace_period_end" status="danger">
    Account Will Be Permanently Deleted on
    {{ subscriberData?.grace_period_end | customdate : "d MMM y" }}
  </nb-alert>
</div>
<div
  [ngStyle]="subscriberData?.statusStyle"
  *ngIf="subscriberData?.accountStatus"
>
  {{ subscriberData?.accountStatus }}
</div>
<nb-card [ngStyle]="subscriberData?.is_active ? {} : { opacity: '0.6' }">
  <nb-card-header>
    <div class="row row-cols-2">
      <div class="col-3" *ngIf="subscriberData?.logo_url">
        <div lass="div.stretchy-wrapper">
          <img
            class="subscriber-logo"
            src="{{ subscriberData?.logo_url }}"
            alt="{{ subscriberData?.name }}"
          />
        </div>
      </div>
      <div class="col-9 sc-head4">
        <div>
          {{ subscriberData?.name
          }}<nb-badge
            *ngIf="subscriberData?.test_user"
            status="danger"
            text="BETA VALIDITY: {{
              subscriberData?.test_user_validity
                ? (subscriberData?.test_user_validity | date : 'd MMM y HH:mm')
                : 'UNLIMITED'
            }}"
            class="extension-badge"
          >
          </nb-badge>
          <nb-badge
            status="danger"
            *ngIf="dateTimeCheck(subscriberData?.trial_end_validity)"
            text="TRIAL VALIDITY: {{
              subscriberData?.trial_end_validity | date : 'd MMM y HH:mm'
            }}"
            class="extension-badge"
          >
          </nb-badge>
        </div>

        <div class="sc-mt-6">
          {{ subscriberData?.address?.full_address }}
        </div>
      </div>
    </div>
  </nb-card-header>
  <div class="mt-3">
    <div>
      <div class="row row-cols-3 sc-mt-6">
        <div>
          <span class="badge-key">Joined On</span>
          <span class="badge-value">
            {{
              subscriberData?.created_date | customdate : "d MMM y HH:mm"
            }}</span
          >
        </div>
        <div>
          <span class="badge-key">Address</span>
          <span class="badge-value">
            {{ subscriberData?.address?.full_address }}</span
          >
        </div>
        <div>
          <span class="badge-key">Email</span>
          <span class="badge-value"
            ><a href="mailto:{{ subscriberData?.email }}">{{
              subscriberData?.email
            }}</a></span
          >
        </div>
        <div>
          <span class="badge-key">Mobile</span>
          <span class="badge-value"> {{ subscriberData?.phone }}</span>
        </div>
        <div (click)="openWalletTemplate(walletChangeRef)" class="pointer">
          <span class="badge-key"
            >Wallet Balance
            <nb-icon
              pack="eva"
              icon="edit"
              status="danger"
              class="sc-floating-right"
            ></nb-icon
          ></span>
          <span class="badge-value">
            {{ subscriberData?.wallet_balance }} $</span
          >
        </div>

        <div>
          <span class="badge-key">AWS Storage Used</span>
          <span class="badge-value">
            {{ subscriberData?.aws_storage?.subscriber_used_storage }}
            {{ subscriberData?.aws_storage?.unit | uppercase }}</span
          >
        </div>
        <div *ngIf="subscriberData?.grace_period_end">
          <span class="badge-key danger-text-info strong"
            >Grace Period Ends ON</span
          >
          <span class="badge-value danger-text-info strong">
            {{ subscriberData?.grace_period_end | customdate : "d MMM y" }}
          </span>
        </div>
      </div>
      <div class="d-flex my-4">
        <div class="me-3">
          <button
            fullWidth
            nbButton
            (click)="disableEnableSubscriber()"
            [status]="subscriberData?.is_active ? 'warning' : 'info'"
            hero
          >
            {{
              subscriberData?.is_active
                ? "Disable Account"
                : "Cancel Deletion / Enable Account"
            }}
          </button>
        </div>
        <div>
          <button
            fullWidth
            nbButton
            (click)="sendWeeklyReport()"
            status="primary"
            hero
          >
            Send Weekly Report
          </button>
        </div>
      </div>

      <nb-tabset (changeTab)="changeTab($event)">
        <nb-tab tabTitle="Users">
          <div>
            <app-gt-table
              [desktopTableUIData]="subscriberUsers"
              [desktopTableDetails]="subscriberUserTable"
              [otherParams]="{ paginationData: true }"
            >
            </app-gt-table>
          </div>
        </nb-tab>
        <nb-tab tabTitle="Locked Plan" *ngIf="subscriberData?.locked_plan">
          <app-gt-table
            [desktopTableUIData]="[subscriberData?.locked_plan]"
            [desktopTableDetails]="lockedPlanTableColumn"
            [otherParams]="{ paginationData: true }"
          >
          </app-gt-table>
          <div *ngIf="subscriberData?.locked_plan">
            <button
              nbButton
              size="giant"
              (click)="lockUnlockAccount()"
              class="w-25"
              status="danger"
              hero
            >
              Unlock Plan
            </button>
          </div>
        </nb-tab>
        <nb-tab tabTitle="Micro Transactions">
          <div class="form-floating">
            <input
              class="form-control"
              nbInput
              fullWidth
              placeholder="Pick Date Range"
              [nbDatepicker]="formpicker"
              [(ngModel)]="dateRange"
            />
            <label for="date_range">Pick Date Range</label>
            <nb-rangepicker
              #formpicker
              (rangeChange)="handleDateChange($event)"
            ></nb-rangepicker>
          </div>
          <app-gt-table
            [desktopTableUIData]="microTransactionTableData"
            [desktopTableDetails]="[
              {
                header: 'Event',
                name: 'name',
                searchKey: 'name',
                sortKey: 'name'
              },
              {
                header: 'Count',
                name: 'count',

                sortKey: 'count'
              }
            ]"
            [otherParams]="{ paginationData: true }"
            (rowClick)="openDetailedTransactionView($event?.name)"
          >
          </app-gt-table>
        </nb-tab>

        <nb-tab tabTitle="Subscriptions">
          <div>
            <app-gt-table
              [desktopTableUIData]="subscriberSubscriptions"
              [desktopTableDetails]="subscriptionTableDetail"
              [otherParams]="otherParamsSubscriptions"
              (rowClick)="forceResetSubscription($event, resetSubscription)"
            >
            </app-gt-table>
          </div>

          <div class="mt-5">
            <div class="sc-head4">Past Subscriptions</div>
            <app-gt-table
              [desktopTableUIData]="pastSubscriberSubscriptions"
              [desktopTableDetails]="subscriptionTableDetail"
              [otherParams]="{ paginationData: true }"
            >
            </app-gt-table>
          </div>
        </nb-tab>

        <nb-tab tabTitle="Wallet Transactions">
          <div>
            <app-gt-table
              [desktopTableUIData]="walletTransactions"
              [desktopTableDetails]="walletHeaders"
              [otherParams]="{ paginationData: true }"
            >
            </app-gt-table>
          </div>
        </nb-tab>
        <nb-tab tabTitle="Manager Notes">
          <div>
            <app-gt-table
              [desktopTableUIData]="managerNotes"
              [desktopTableDetails]="managerNotesTable"
              [otherParams]="{ paginationData: true }"
            >
            </app-gt-table>
          </div>
        </nb-tab>
      </nb-tabset>

      <div class="my-3">
        <ng-container *ngIf="subscriberId">
          <app-subscriber-app-data [subscriberId]="subscriberId">
          </app-subscriber-app-data>
        </ng-container>
      </div>

      <div>
        <div class="sc-head4">Subscriber Statistics</div>
        <app-gt-table
          [desktopTableUIData]="subscriberData?.pie_chart_data || []"
          [desktopTableDetails]="[
            {
              header: 'Type',
              name: 'name',
              searchKey: 'name',
              sortKey: 'name'
            },
            {
              header: 'Count',
              name: 'y',
              sortKey: 'name'
            }
          ]"
          [otherParams]="{ paginationData: true }"
        >
        </app-gt-table>
      </div>
    </div>
  </div>
</nb-card>

<ng-template #transactionTemplate let-data let-transactRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header
      >Price Break down
      <nb-icon
        (click)="transactRef.close()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <div>
        <app-gt-table
          [desktopTableUIData]="data?.data?.transactions?.data"
          [desktopTableDetails]="dailyTtransactionHeaders"
          [otherParams]="{ paginationData: true }"
        >
        </app-gt-table>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
<!-- trial change template-->
<ng-template #trialChangeTemplate let-data let-trialChangeRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header
      >Change Trial
      <nb-icon
        (click)="trialChangeRef.close()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <form [formGroup]="trialChangeForm" appFormEnterAction>
        <div class="form-floating">
          <input
            class="form-control"
            fullWidth
            nbInput
            [nbDatepicker]="dateTimePicker2"
            formControlName="trial_end"
            placeholder="Trial End Date"
          /><label> Trial End Date</label>
          <nb-datepicker #dateTimePicker2></nb-datepicker>
        </div>

        <div class="form-floating sc-mt-6">
          <textarea
            class="form-control"
            fullWidth
            rows="5"
            type="text"
            maxlength="300"
            formControlName="reason"
            placeholder="Change Reason"
          ></textarea>
          <label>Change Reason</label>
        </div>
        <div class="flex-center">
          <button
            nbButton
            size="giant"
            (click)="changeTrial(); trialChangeRef.close()"
            [disabled]="!trialChangeForm.valid"
          >
            {{
              trialChangeForm.value?.trial_end
                ? "Change Trial End"
                : "End Trial Now"
            }}
          </button>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</ng-template>

<!--wallet amount change template-->
<ng-template #walletChangeRef let-data let-walletChangeRef="dialogRef">
  <nb-card class="dialog-box gt-p-1">
    <nb-card-header
      >Edit Subscriber
      <nb-icon
        (click)="walletChangeRef.close()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <form [formGroup]="subscriberWalletForm">
        <div class="form-floating">
          <input
            class="form-control"
            fullWidth
            formControlName="balance"
            nbInput
            type="number"
            placeholder="Enter Amount"
          />
          <label for="inputAmount">Amount</label>
        </div>

        <div class="form-floating">
          <textarea
            class="form-control"
            fullWidth
            rows="5"
            type="text"
            maxlength="300"
            placeholder="Add a Reason..."
            formControlName="description"
          ></textarea>
        </div>
        <div class="row row-cols-2">
          <div class="col">
            <button
              type="button"
              (click)="subscriberWalletForm.reset()"
              nbButton
              size="giant"
              ghost
              fullWidth
            >
              Clear
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              (click)="onWalletUpdate(); walletChangeRef.close()"
              [disabled]="!subscriberWalletForm.valid"
              nbButton
              size="giant"
              fullWidth
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      <div *ngIf="subscriberData?.wallet_history">
        <div class="sc-head5" class="sc-text-center">Wallet History</div>
        <div>
          <table>
            <tbody>
              <tr *ngFor="let history of subscriberData?.wallet_history">
                <td>
                  {{ history.modified_at | customdate : "d MMM y HH:mm" }}
                </td>
                <td>{{ history.event_text }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template
  #microTransactionTemplateRef
  let-data
  let-microTransactionTemplateRef="dialogRef"
>
  <nb-card class="gt-p-small dialog-box">
    <nb-card-header class="sc-text-center">
      {{ data?.header }}
    </nb-card-header>
    <nb-card-body>
      <div>
        <app-gt-table
          [desktopTableUIData]="individualMicroTransactions"
          [desktopTableDetails]="transactionHeaders"
          [otherParams]="{ paginationData: true }"
        >
        </app-gt-table>
      </div>

      <div>
        <button
          fullWidth
          ghost
          size="giant"
          (click)="microTransactionTemplateRef.close()"
          nbButton
        >
          Close
        </button>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #resetSubscription let-data let-resetSubscription="dialogRef">
  <nb-card class="dialog-box gt-p-1">
    <nb-card-header
      >Resest Subscription
      <nb-icon
        (click)="resetSubscription.close()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <nb-alert status="danger"
        >Select a date from which the subscriber will be shifted to the orginal
        records.</nb-alert
      >
      <div>
        <div class="sc-head4">Current Data</div>
        <div>
          <span class="badge-key">Amount</span>
          <span class="badge-value">
            {{ data?.subscription_plan?.amount }}</span
          >
        </div>
        <ng-container *ngIf="data?.subscription_plan?.plan_type === 1">
          <div>
            <span class="badge-key">Allowed Users</span>
            <span class="badge-value">
              {{ data?.subscription_plan?.allowed_users }}</span
            >
          </div>
          <div>
            <span class="badge-key">User Price</span>
            <span class="badge-value">
              {{ data?.subscription_plan?.user_cost }} AUD</span
            >
          </div>
        </ng-container>
        <ng-container *ngIf="data?.subscription_plan?.plan_type === 2">
          <div>
            <span class="badge-key">Allowed Transactions</span>
            <span class="badge-value">
              {{ data?.subscription_plan?.allowed_transactions }}</span
            >
          </div>
          <div>
            <span class="badge-key">Transaction Price</span>
            <span class="badge-value">
              {{ data?.subscription_plan?.transaction_price }} AUD</span
            >
          </div>
        </ng-container>
      </div>
      <div class="mt-2">
        <div class="sc-head4">Future Data</div>
        <div>
          <span class="badge-key">Amount</span>
          <span class="badge-value">
            {{
              data?.subscription_plan?.selected_subscription_plan?.amount
                ? data?.subscription_plan?.selected_subscription_plan?.amount
                : data?.subscription_plan?.app_subscription_plan?.amount
            }}</span
          >
        </div>
        <ng-container *ngIf="data?.subscription_plan?.plan_type === 1">
          <div>
            <span class="badge-key">Allowed Users</span>
            <span class="badge-value">
              {{
                data?.subscription_plan?.selected_subscription_plan
                  ?.allowed_users
                  ? data?.subscription_plan?.selected_subscription_plan
                      ?.allowed_users
                  : data?.subscription_plan?.app_subscription_plan
                      ?.allowed_users
              }}</span
            >
          </div>
          <div>
            <span class="badge-key">User Price</span>
            <span class="badge-value">
              {{
                data?.subscription_plan?.selected_subscription_plan?.user_cost
                  ? data?.subscription_plan?.selected_subscription_plan
                      ?.user_cost
                  : data?.subscription_plan?.app_subscription_plan?.user_cost
              }}
              AUD</span
            >
          </div>
        </ng-container>
        <ng-container *ngIf="data?.subscription_plan?.plan_type === 2">
          <div>
            <span class="badge-key">Allowed Transactions</span>
            <span class="badge-value">
              {{
                data?.subscription_plan?.app_subscription_plan
                  ?.allowed_transactions
              }}</span
            >
          </div>
          <div>
            <span class="badge-key">Transaction Price</span>
            <span class="badge-value">
              {{
                data?.subscription_plan?.app_subscription_plan
                  ?.allowed_transactions
              }}
              AUD</span
            >
          </div>
        </ng-container>
      </div>

      <div>
        <nb-select fullWidth placeholder="Reset Date" size="giant">
          <nb-option
            *ngFor="let date of data?.reset_dates"
            [value]="date"
            (click)="forceResetDate(date, data); resetSubscription.close()"
            >{{ date | customdate : "d MMM y" }}</nb-option
          >
        </nb-select>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
