<!-- MOBILE -->

<div class="strong mb-3 ms-3">Log Incident</div>
<div class="mobile-only px-2">
  <form [formGroup]="formdata">
    <ng-container>
      <div class="form-floating">
        <input
          autofocus
          id="mobclientField"
          class="form-control"
          fullWidth
          formControlName="clientName"
          (input)="onClientSearch($event)"
          nbInput
          type="text"
          placeholder="Client"
          [nbAutocomplete]="mobileORTACClient"
          autocomplete="off"
        />

        <label>Client</label>
      </div>

      <nb-autocomplete
        #mobileORTACClient
        (selectedChange)="onClientSelect($event)"
      >
        <nb-option [value]="' '" *ngIf="isAdmin || isDispatchUser">
          <b> Add Client</b>
        </nb-option>
        <nb-option *ngFor="let item of clientList" [value]="item">
          <div>
            <div class="strong">{{ item?.company_name }}</div>

            <div class="small-font">
              {{ item?.address?.full_address }}
            </div>
          </div>
        </nb-option>
      </nb-autocomplete>
      <div class="form-floating" *ngIf="incidentDict?.client?.data?.id">
        <input
          id="mobsiteField"
          autofocus
          class="form-control"
          fullWidth
          formControlName="siteName"
          (input)="onSiteSearch($event)"
          nbInput
          type="text"
          placeholder="Site Name"
          [nbAutocomplete]="siteACMob"
          autocomplete="off"
        />
        <label>Site Name</label>
      </div>
      <nb-autocomplete #siteACMob (selectedChange)="onSelectSite($event)">
        <nb-option [value]="' '" *ngIf="isAdmin">
          <b> Add Site</b>
        </nb-option>
        <nb-option *ngFor="let item of siteList" [value]="item">
          <div>
            <div class="strong">
              {{ item?.company_name }}
            </div>
            <div class="small-font">
              {{ item?.address?.full_address }}
            </div>
          </div>
        </nb-option>
      </nb-autocomplete>

      <ng-container *ngIf="incidentDict.site.data">
        <!--display this section only after a site has been selected-->

        <div class="form-floating">
          <input
            id="mobincidentTypeField"
            class="form-control"
            fullWidth
            nbInput
            type="text"
            (input)="onItSearch($event)"
            placeholder="Incident Type"
            [(ngModel)]="incidentDict.incidentType.value"
            [ngModelOptions]="{ standalone: true }"
            autocomplete="off"
            [nbAutocomplete]="incidentTypeACMob"
            [maxlength]="60"
          />
          <label>Incident Type</label>
        </div>

        <nb-autocomplete #incidentTypeACMob>
          <nb-option [value]="' '" (click)="onSelectIncidentType(' ')">
            <b> Add Incident Type</b>
          </nb-option>
          <nb-option
            *ngFor="let item of incidentTypeList"
            [value]="item"
            (click)="onSelectIncidentType(item)"
          >
            <div class="strong">{{ item?.name }}</div>
          </nb-option>
        </nb-autocomplete>
        <!--incident Time section-->
        <div class="row row-cols-2">
          <div class="col">
            <div class="form-floating">
              <input
                nbInput
                class="form-control"
                fullWidth
                [ngModelOptions]="{ standalone: true }"
                placeholder="Incident Date"
                [nbDatepicker]="dateTimePicker0"
                [(ngModel)]="incidentDict.iDay.value"
              /><label for="inputAssignee"> Incident Date</label>
              <nb-datepicker
                (dateChange)="formateStartDate($event)"
                #start_day
                #dateTimePicker0
                [max]="todayDate"
              ></nb-datepicker>
            </div>
          </div>
          <div class="col">
            <div class="form-floating">
              <input
                nbInput
                id="mobiTimeField"
                class="form-control"
                type="text"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Incident Time"
                fullWidth
                [ngxMatTimepicker]="timePicker1"
                [(ngModel)]="incidentDict.iTime.value"
              /><label for="inputAssignee"> Incident Time </label>
              <ngx-mat-timepicker
                (timeSet)="formatStartTime($event)"
                #timePicker1
              ></ngx-mat-timepicker>
            </div>
          </div>
        </div>
      </ng-container>
      <div>
        <button
          fullWidth
          [disabled]="
            !(
              incidentDict.site.data &&
              !addIncidentButtonPressed &&
              incidentDict.incidentType.data?.id
            )
          "
          nbButton
          status="primary"
          size="giant"
          (click)="onAddIncident()"
        >
          Log Incident
        </button>
        <button
          fullWidth
          id="cancel-btn"
          type="button"
          [routerLink]="['/incidents']"
          nbButton
          ghost
          size="giant"
        >
          Cancel
        </button>
      </div>
    </ng-container>
  </form>
</div>

<!-- DESKTOP -->
<div class="large-view-only">
  <div class="row row-cols-2">
    <div class="col item-detail-section">
      <!--job details section-->

      <form [formGroup]="formdata">
        <!--client section-->
        <div>
          <div
            (click)="editInputField('client')"
            class="item-detail-event"
            *ngIf="!incidentDict.client.edit"
          >
            <span class="item-detail-key">{{ incidentDict.client.name }}</span>
            <span class="item-detail-value"
              >{{ incidentDict.client.value }}
              <span class="item-detail-icon">
                <nb-icon icon="edit-outline" pack="eva"> </nb-icon>
              </span>
            </span>
          </div>
          <div class="form-floating" *ngIf="incidentDict.client.edit">
            <input
              autofocus
              id="deskclientField"
              class="form-control"
              fullWidth
              formControlName="clientName"
              (input)="onClientSearch($event)"
              nbInput
              type="text"
              placeholder="Search & Select Client"
              [nbAutocomplete]="mobileORTACClient"
              autocomplete="off"
            />

            <label>Search & Select Client</label>
          </div>

          <nb-autocomplete
            #mobileORTACClient
            (selectedChange)="onClientSelect($event)"
          >
            <nb-option [value]="' '" *ngIf="isAdmin || isDispatchUser">
              <b> Add Client</b>
            </nb-option>
            <nb-option *ngFor="let item of clientList" [value]="item">
              <div>
                <div class="strong">{{ item?.company_name }}</div>

                <div class="small-font">
                  {{ item?.address?.full_address }}
                </div>
              </div>
            </nb-option>
          </nb-autocomplete>
        </div>
        <!--site section-->
        <div *ngIf="incidentDict.client.data?.id">
          <div
            (click)="editInputField('site')"
            class="item-detail-event"
            *ngIf="!incidentDict.site.edit"
          >
            <span class="item-detail-key">{{ incidentDict.site.name }}</span>
            <span class="item-detail-value"
              >{{ incidentDict.site.value }}
              <span class="item-detail-icon">
                <nb-icon icon="edit-outline" pack="eva"> </nb-icon>
              </span>
            </span>
          </div>
          <div class="form-floating edit-field" *ngIf="incidentDict.site.edit">
            <input
              autofocus
              class="form-control"
              fullWidth
              formControlName="siteName"
              (input)="onSiteSearch($event)"
              nbInput
              type="text"
              autocomplete="off"
              placeholder="Search/Select Site"
              [nbAutocomplete]="siteACDesk"
              id="desksiteField"
            />
            <label>Search/Select Site</label>
          </div>

          <nb-autocomplete #siteACDesk (selectedChange)="onSelectSite($event)">
            <nb-option [value]="' '" *ngIf="isAdmin">
              <b> Add New Site</b>
            </nb-option>
            <nb-option *ngFor="let item of siteList" [value]="item">
              <div>
                <div class="strong">
                  {{ item?.company_name }}
                </div>
                <div class="small-font">
                  {{ item?.address?.full_address }}
                </div>
              </div>
            </nb-option>
          </nb-autocomplete>
        </div>

        <ng-container *ngIf="incidentDict.site.data">
          <!--display this section only after a site has been selected-->

          <!--incident Type section-->
          <div>
            <div
              (click)="editInputField('incidentType')"
              class="item-detail-event"
              *ngIf="!incidentDict.incidentType.edit"
            >
              <span class="item-detail-key">{{
                incidentDict.incidentType.name
              }}</span>
              <span class="item-detail-value"
                >{{ incidentDict.incidentType.value }}
                <span class="item-detail-icon">
                  <nb-icon icon="edit-outline" pack="eva"> </nb-icon>
                </span>
              </span>
            </div>

            <div class="form-floating" *ngIf="incidentDict.incidentType.edit">
              <input
                id="deskincidentTypeField"
                class="form-control"
                fullWidth
                nbInput
                type="text"
                (input)="onItSearch($event)"
                placeholder="Incident Type"
                [(ngModel)]="incidentDict.incidentType.value"
                [ngModelOptions]="{ standalone: true }"
                autocomplete="off"
                [nbAutocomplete]="incidentTypeACDesk"
                [maxlength]="60"
              />
              <label>Incident Type</label>
            </div>

            <nb-autocomplete #incidentTypeACDesk>
              <nb-option [value]="' '" (click)="onSelectIncidentType(' ')">
                <b> Add Incident Type</b>
              </nb-option>
              <nb-option
                *ngFor="let item of incidentTypeList"
                [value]="item"
                (click)="onSelectIncidentType(item)"
              >
                <div class="strong">{{ item?.name }}</div>
              </nb-option>
            </nb-autocomplete>
          </div>

          <!--incident Time section-->

          <div class="row row-cols-2">
            <div class="col">
              <div class="form-floating">
                <input
                  nbInput
                  class="form-control"
                  fullWidth
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="Incident Date"
                  [nbDatepicker]="dateTimePicker2"
                  [(ngModel)]="incidentDict.iDay.value"
                /><label for="inputAssignee"> Incident Date</label>
                <nb-datepicker
                  (dateChange)="formateStartDate($event)"
                  #start_day
                  [max]="todayDate"
                  #dateTimePicker2
                ></nb-datepicker>
              </div>
            </div>
            <div class="col">
              <div class="form-floating">
                <input
                  nbInput
                  id="deskiTimeField"
                  class="form-control"
                  type="text"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="Incident Time"
                  fullWidth
                  [ngxMatTimepicker]="timePicker3"
                  [(ngModel)]="incidentDict.iTime.value"
                /><label for="inputAssignee"> Incident Time </label>
                <ngx-mat-timepicker
                  (timeSet)="formatStartTime($event)"
                  #timePicker3
                ></ngx-mat-timepicker>
              </div>
            </div>
          </div>
        </ng-container>
        <div>
          <button
            fullWidth
            [disabled]="
              !(
                incidentDict.site.data &&
                !addIncidentButtonPressed &&
                incidentDict.incidentType.data?.id
              )
            "
            nbButton
            status="primary"
            size="giant"
            (click)="onAddIncident()"
          >
            Log Incident
          </button>
          <button
            fullWidth
            type="button"
            [routerLink]="['/jobs']"
            nbButton
            ghost
            size="giant"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
    <div class="col">
      <!--job site map section-->

      <div
        class="col"
        *ngIf="
          showSiteMap &&
          incidentDict.site?.data?.address?.latitude &&
          incidentDict.site?.data?.address?.longitude
        "
      >
        <app-map-leaflet
          [latLon]="{
            lat: incidentDict.site?.data?.address?.latitude,
            lon: incidentDict.site?.data?.address?.longitude
          }"
          [mapId]="'largeJob'"
        ></app-map-leaflet>
        <div
          *ngIf="
            incidentDict.site?.data?.address?.latitude &&
            incidentDict.site?.data?.address?.longitude
          "
          style="
            text-align: center;
            font-size: 0.75rem;
            text-transform: uppercase;
          "
        >
          Lat: {{ incidentDict.site?.data?.address?.latitude }} Lon:
          {{ incidentDict.site?.data?.address?.longitude }}
        </div>
      </div>
    </div>
  </div>
</div>
