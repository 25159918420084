<nb-card class="dialog-box">
  <nb-card-header
    >Pick the Date Range
    <nb-icon
      (click)="onDismiss()"
      [icon]="'close-outline'"
      class="sc-floating-right close-icon"
      pack="eva"
    ></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <nb-alert *ngIf="errorMessage" class="fade show" outline="warning">{{
      errorMessage
    }}</nb-alert>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="form-floating">
          <input
            id="startDay"
            nbInput
            class="form-control"
            fullWidth
            placeholder="Pick Date"
            [nbDatepicker]="dateTimePicker0"
            [(ngModel)]="startDate"
          /><label for="inputAssignee">
            {{
              showFrom === "incidentPage" ? "Incident Date" : "Start Date"
            }}</label
          >
          <nb-datepicker
            (dateChange)="formateStartDate($event)"
            #start_day
            #dateTimePicker0
          ></nb-datepicker>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="form-floating">
          <input
            nbInput
            id="startTime"
            class="form-control"
            type="text"
            placeholder="Pic Time"
            fullWidth
            [ngxMatTimepicker]="timePicker1"
            [(ngModel)]="startTime"
          /><label for="inputAssignee">
            {{ showFrom === "incidentPage" ? "Incident Time" : "Start Time" }}
          </label>
          <ngx-mat-timepicker
            (timeSet)="formatStartTime($event)"
            #timePicker1
          ></ngx-mat-timepicker>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="form-floating">
          <input
            nbInput
            id="endDay"
            class="form-control"
            fullWidth
            placeholder="Pick Date"
            [nbDatepicker]="dateTimePicker1"
            [(ngModel)]="endDate"
            [disabled]="!startDate"
          /><label for="inputAssignee">
            {{
              showFrom === "incidentPage" ? "Resolved Date" : "End Date"
            }}</label
          >
          <nb-datepicker
            (dateChange)="formateEndDate($event)"
            #end_day
            #dateTimePicker1
            [min]="startDate"
          ></nb-datepicker>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="form-floating">
          <input
            nbInput
            id="endTime"
            class="form-control"
            type="text"
            placeholder="Pick Time"
            fullWidth
            [(ngModel)]="endTime"
            [disabled]="!startTime"
            [ngxMatTimepicker]="timePicker2"
          /><label for="inputAssignee">{{
            showFrom === "incidentPage" ? "Resolved Time" : "End Time"
          }}</label>
          <ngx-mat-timepicker
            (timeSet)="formatEndTime($event)"
            #timePicker2
            [min]="startTime"
          ></ngx-mat-timepicker>
        </div>
      </div>
    </div>
    <div class="row-cols-2">
      <button
        class="sc-floating-right"
        nbButton
        type="button"
        (click)="saveDateRange()"
        id="form-enter-submit-btn"
        size="giant"
        [disabled]="!(startDate && endDate && startTime && endTime)"
      >
        Apply
      </button>
    </div>
  </nb-card-body>
</nb-card>
