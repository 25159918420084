<nb-layout (click)="lockScreen()" *ngIf="isSuperUser">
  <!--header for super users  STARTS-->

  <nb-layout-header fixed>
    <div class="header-left">
      <div
        class="small-view-only"
        #menu
        #toggleButton
        (click)="toggleSidebar($event)"
      >
        <nb-icon class="ms-3 me-1" [icon]="'more-vertical-outline'" pack="eva">
        </nb-icon>
      </div>
    </div>

    <div class="header-center">
      <div id="gt-logo-home" class="pointer">
        <img
          [src]="
            '../../../assets/images/Guard_Tracker-Lateral_Long_' +
            currentTheme +
            '.png'
          "
          alt="Guard Tracker"
          (click)="navigateHome()"
        />
      </div>
    </div>

    <div class="header-right">
      <!-- PROFILE -->
      <div id="profile-icon" *ngIf="userMenu" class="ms-2">
        <nb-user
          id="user-menu"
          [nbContextMenu]="userMenu"
          onlyPicture
          [name]="loggedInUser?.name"
          [picture]="loggedInUser?.profile"
        >
        </nb-user>
      </div>
    </div>
  </nb-layout-header>

  <!-- SIDEBAR MENU-->
  <nb-sidebar id="menuSside" responsive>
    <div
      class="row row-cols-2 side-menu-toggle"
      style="padding-left: 0 !important"
    >
      <div
        class="side-menu-toggle side-menu-toggle-button"
        (click)="toggleSidebar($event)"
      >
        <div class="side-menu-toggle-button-icon">
          <nb-icon [icon]="'menu-arrow-outline'" pack="eva"></nb-icon>
        </div>
      </div>
      <div class="col-10 overflow-hidden">
        <nb-menu
          id="otheMenuMob"
          tag="menu"
          [items]="items"
          autoCollapse="true"
        ></nb-menu>
      </div>
    </div>
    <div class="collapse-icon-container">
      <nb-menu id="otherMenuItemsCompact" tag="menu" [items]="items"></nb-menu>
    </div>
    <div class="large-view-only">
      <nb-menu
        id="otheMenu"
        tag="menu"
        [items]="items"
        autoCollapse="true"
      ></nb-menu>
    </div>
  </nb-sidebar>

  <!-- MAIN CONTENT -->
  <nb-layout-column>
    <!-- display success and error messgaes of all components here-->
    <div class="global-alert-container">
      <div class="global-alert show fade">
        <nb-alert
          class="global-alert-width"
          *ngIf="successMessage"
          outline="basic"
          [status]="messageStatus"
          (click)="clearAlert()"
          >{{ successMessage }}</nb-alert
        >
      </div>
      <div class="global-alert">
        <nb-alert
          class="global-alert-width"
          *ngIf="errorMessage"
          outline="basic"
          role="alert"
          [status]="messageStatus"
          (click)="clearAlert()"
        >
          {{ errorMessage }}
        </nb-alert>
      </div>
    </div>
    <!-- MAIN ROUTER OUTLET WHICH WILL SHOW THE COMPONENTS -->
    <div>
      <router-outlet></router-outlet>
    </div>

    <div id="gt-caret">
      <nb-icon
        class="sc-floating-right"
        *ngIf="windowScrolled === true"
        (click)="scrollToTop()"
        [icon]="'arrowhead-up-outline'"
        pack="eva"
      ></nb-icon>
    </div>
  </nb-layout-column>

  <!--header for super users  ENDS-->
</nb-layout>

<nb-layout (click)="lockScreen()" *ngIf="!isSuperUser" id="mainContainer">
  <!--HEADER FOR APP USERS  STARTS-->
  <!--offline Header-->

  <nb-layout-header fixed *ngIf="!isAppOnline">
    <div class="header-center">
      <div id="gt-logo-home" class="pointer" style="padding-left: 0.5rem">
        <img
          [src]="
            '../../../assets/images/Guard_Tracker-Lateral_Long_' +
            currentTheme +
            '.png'
          "
          alt="Guard Tracker"
          (click)="navigateHome()"
        />
      </div>
    </div>
  </nb-layout-header>
  <!--offline header ENDS-->

  <!-- HEADER -->

  <nb-layout-header fixed *ngIf="isAppOnline">
    <div
      class="header-left"
      *ngIf="
        isSubscriptionActive &&
        (isAdmin || (isClockedIn && !showWelfareCheckButton))
      "
    >
      <div
        class="small-view-only"
        #menu
        #toggleButton
        (click)="toggleSidebar($event)"
      >
        <nb-icon class="ms-3 me-1" [icon]="'more-vertical-outline'" pack="eva">
        </nb-icon>
      </div>
    </div>

    <div class="header-center">
      <div id="gt-logo-home" class="pointer">
        <img
          [src]="
            '../../../assets/images/Guard_Tracker-Lateral_Long_' +
            currentTheme +
            '.png'
          "
          alt="Guard Tracker"
          (click)="navigateHome()"
        />
      </div>
    </div>

    <div class="header-right">
      <!-- NOTIFICATIONS (HEADER) -->
      <div
        id="notifications"
        *ngIf="
          (notificationCount > 0 ||
            (sosAlertCount && (isAdmin || isDispatchUser))) &&
          isSubscriptionActive
        "
        (click)="openNotifcationTemplate(notificationPopover)"
      >
        <nb-icon
          class="big-menu-icon"
          [icon]="'bell-outline'"
          pack="eva"
          [class.blink]="sosAlertCount && (isAdmin || isDispatchUser)"
        >
        </nb-icon>
        <nb-badge
          *ngIf="notificationCount > 0"
          text="{{ notificationCount ? notificationCount : 1 }}"
          status="danger"
        >
        </nb-badge>
      </div>

      <!-- PROFILE -->
      <div
        (click)="arrangeUserMenu()"
        id="profile-icon"
        *ngIf="userMenu"
        class="ms-2"
      >
        <nb-user
          id="user-menu"
          [nbContextMenu]="userMenu"
          onlyPicture
          [name]="loggedInUser?.name"
          [picture]="loggedInUser?.profile"
        >
        </nb-user>
      </div>
    </div>
  </nb-layout-header>

  <!-- SIDEBAR -->
  <nb-sidebar
    id="menuSside"
    responsive
    *ngIf="
      isAppOnline &&
      isSubscriptionActive &&
      (isAdmin || (isClockedIn && !showWelfareCheckButton))
    "
  >
    <div
      class="row row-cols-2 side-menu-toggle"
      style="padding-left: 0 !important"
    >
      <div
        class="side-menu-toggle side-menu-toggle-button"
        (click)="toggleSidebar($event)"
      >
        <div class="side-menu-toggle-button-icon">
          <nb-icon [icon]="'menu-arrow-outline'" pack="eva"></nb-icon>
        </div>
      </div>
      <div class="col-10 overflow-hidden">
        <nb-menu
          id="otheMenuMob"
          tag="menu"
          [items]="items"
          autoCollapse="true"
        ></nb-menu>
        <nb-menu
          id="settingsMenuMob"
          tag="menu"
          [items]="settingItems"
          autoCollapse="true"
        ></nb-menu>
      </div>
    </div>
    <div class="collapse-icon-container">
      <nb-menu id="otherMenuItemsCompact" tag="menu" [items]="items"></nb-menu>
      <nb-menu id="settingsMenuMob" tag="menu" [items]="settingItems"></nb-menu>
    </div>
    <div class="large-view-only">
      <nb-menu
        id="otheMenu"
        tag="menu"
        [items]="items"
        autoCollapse="true"
      ></nb-menu>
      <nb-menu
        id="settingsMenu"
        tag="menu"
        [items]="settingItems"
        autoCollapse="true"
      ></nb-menu>
    </div>
  </nb-sidebar>

  <!-- MAIN CONTENT -->
  <nb-layout-column>
    <!-- FEEDBACK BUTTON -->
    <div class="p-2 mt-2" *ngIf="!isSubscriptionActive && !isSubscriberAdmin">
      <nb-alert class="subscription-msg" outline="basic">
        Invalid Subscription
      </nb-alert>
    </div>

    <ng-container *ngIf="isAppOnline && isPreviousSubscribed">
      <div
        cdkDrag
        [ngStyle]="permissionErrorStatus?.message ? { 'z-index': '1000' } : {}"
        id="globalFeedbackbtn"
        (click)="capture('draggableBtn', feedbackRef)"
        (cdkDragStarted)="handleDragStart($event)"
        (cdkDragEnded)="onDragEnded($event)"
        class="draggable-button background-transparent"
      >
        <nb-icon
          title="Submit Feedback"
          class="big-menu-icon feedback-icon"
          icon="question-mark-circle-outline"
          pack="eva"
        ></nb-icon>
      </div>
    </ng-container>

    <div *ngIf="isSubscriptionActive" class="mobile-only">
      <div class="d-flex status-indicator">
        <div
          nbTooltip="{{
            permissionErrorStatus?.message
              ? permissionErrorStatus?.message
              : isInBackgroundSync
              ? 'Synchronizing Data'
              : isAppOnline
              ? 'Online'
              : 'Offline: Events will be uploaded once you are back online'
          }}"
          nbTooltipPlacement="bottom"
          #tooltip="nbTooltip"
          [nbTooltipStatus]="permissionErrorStatus?.message ? 'danger' : ''"
        >
          <span style="vertical-align: top"> Status </span>
          <nb-icon
            [icon]="
              permissionErrorStatus?.icon
                ? permissionErrorStatus?.icon
                : isInBackgroundSync
                ? 'alert-triangle'
                : isAppOnline
                ? 'checkmark-circle-2'
                : 'wifi-off'
            "
            pack="eva"
            [status]="
              permissionErrorStatus?.status
                ? permissionErrorStatus?.status
                : isInBackgroundSync
                ? 'warning'
                : isAppOnline
                ? 'success'
                : 'danger'
            "
          >
          </nb-icon>
        </div>
        <!-- status icon to show whether there is background sync by service worker and user offline/online status-->
      </div>
    </div>

    <nb-card
      *ngIf="showTrialExpireCountDown"
      [ngClass]="expiresTimeDifference < 0 ? 'danger-text-info' : 'trial-text'"
    >
      {{ trialStatus }}
    </nb-card>

    <!-- display success and error messgaes of all components here-->
    <div class="global-alert-container">
      <div class="global-alert show fade">
        <nb-alert
          class="global-alert-width"
          *ngIf="successMessage"
          outline="basic"
          [status]="messageStatus"
          (click)="clearAlert()"
          >{{ successMessage }}</nb-alert
        >
      </div>
      <div class="global-alert">
        <nb-alert
          class="global-alert-width"
          *ngIf="errorMessage"
          outline="basic"
          role="alert"
          [status]="messageStatus"
          (click)="clearAlert()"
        >
          {{ errorMessage }}
        </nb-alert>
      </div>
    </div>
    <ng-container *ngIf="isAppOnline">
      <!-- display success and error messgaes of all components here ENDS-->
      <!--sos alerts manage-->
      <ng-container *ngIf="sosAlertCount > 0 && (isAdmin || isDispatchUser)">
        <div class="d-flex m-2">
          <div class="flex-grow-1 me-1">
            <div
              [class.blink]="sosAlertCount && (isAdmin || isDispatchUser)"
              class="w-100 btn btn-lg rounded-1"
              (click)="showSOSAlerts()"
            >
              SOS Alerts
            </div>
          </div>
          <div *ngIf="isAlertActive">
            <button nbButton size="large" (click)="stopAlert()">
              <nb-icon pack="eva" icon="volume-off-outline"></nb-icon>
            </button>
          </div>
        </div>
      </ng-container>
      <!--welfare check button STARTS-->
      <div
        class="control-status-example sc-text-center px-2 mt-1"
        *ngIf="
          isGuardUser &&
          showWelfareCheckButton &&
          isClockedIn &&
          isSubscriptionActive
        "
      >
        <button
          nbButton
          size="giant"
          hero
          (click)="welfareCheckIn()"
          status="danger"
          class="me-1"
        >
          Check In (Welfare Check)
        </button>
      </div>
      <!--welfare check button ENDS-->
      <!-- Clock In/Out button for Guards STARTS-->

      <div
        class="control-status-example sc-text-align-right px-2 mt-1"
        *ngIf="
          isSubscriptionActive &&
          !showWelfareCheckButton &&
          isHomeComponent &&
          !isClockedIn
        "
      >
        <button
          nbButton
          size="giant"
          hero
          (click)="clockedInOut()"
          status="info"
          class="me-1"
        >
          <i
            [ngClass]="
              isClockedIn ? 'fas fa-sign-in-alt' : 'fas fa-sign-out-alt'
            "
            class="me-1"
          ></i>

          Clock In
        </button>
      </div>
    </ng-container>

    <div
      *ngIf="
        isSuperUser ||
        ((isSubscriptionActive || allowedPaths) &&
          (isAdmin ||
            allowedPaths ||
            (isClockedIn && !showWelfareCheckButton))) ||
        isSubscriberAdmin
      "
    >
      <router-outlet></router-outlet>
    </div>

    <div id="gt-caret">
      <nb-icon
        class="sc-floating-right"
        *ngIf="windowScrolled === true"
        (click)="scrollToTop()"
        [icon]="'arrowhead-up-outline'"
        pack="eva"
      ></nb-icon>
    </div>
  </nb-layout-column>

  <!--HEADER FOR APP USERS  ENDS-->
</nb-layout>

<!-- popover for showing notifications -->
<ng-template #notificationPopover let-data let-notificationPopover="dialogRef">
  <nb-card
    *ngIf="notificationData?.length > 0"
    class="notification-list dialog-box gt-p-small"
  >
    <nb-card-header
      >Notifications
      <nb-icon
        (click)="notificationPopover.close()"
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
      ></nb-icon
    ></nb-card-header>

    <nb-card-body>
      <div
        *ngFor="let notification of notificationData"
        class="contact-card mb-1 scrollable-y"
      >
        <nb-icon
          *ngIf="!notification.is_persistent"
          [icon]="'close-outline'"
          class="sc-floating-right ico-color"
          status="primary"
          pack="eva"
          (click)="deleteNotification(notification)"
        >
        </nb-icon>
        <small
          (click)="
            redirectNotification(notification); notificationPopover.close()
          "
        >
          <strong>{{ notification.notification_msg }}</strong>
        </small>
        <div>
          <small>
            {{ notification.created_at | customdate : "d MMM y HH:mm" }}
          </small>
        </div>
      </div>
      <div>
        <app-pagination-control
          [pageSize]="rows"
          [pageNum]="pageNum"
          [previous]="previous"
          [totalRows]="totalRows"
          [totalPages]="totalPages"
          (onClickPagination)="onChangeNotificationPagination($event)"
          (onChangePagination)="onChangeNotificationPagination($event)"
        >
        </app-pagination-control>
      </div>
    </nb-card-body>
    <div class="mt-2">
      <button
        *ngIf="notificationData?.length > 0"
        fullWidth
        nbButton
        size="giant"
        (click)="clearNotifications(); notificationPopover.close()"
      >
        Clear Notifications
      </button>
    </div>
  </nb-card>

  <nb-card *ngIf="notificationData?.length === 0">
    <nb-card-header> You have no notifications </nb-card-header>
  </nb-card>
</ng-template>

<!-- ADD FEEDBACK POPOVER / DIALOG -->
<ng-template #feedbackRef let-feedbackRef="dialogRef">
  <nb-card class="contact-form">
    <nb-card-header class="sc-text-center">
      Contact Form
      <nb-icon
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
        (click)="
          feedbackRef.close('close'); showHideDraggableFeedbackBtn(false)
        "
      ></nb-icon>
    </nb-card-header>
    <form [formGroup]="feedbackForm" class="row-cols-1 gy-2">
      <div class="col">
        <textarea
          class="form-control"
          cols="32"
          rows="10"
          wrap="hard"
          maxlength="340"
          placeholder="Need help or have feedback? Please tell us how we can help."
          formControlName="feedback"
        ></textarea>
      </div>

      <div class="col sc-mt-6">
        <button
          (click)="
            addFeedback();
            feedbackRef.close();
            showHideDraggableFeedbackBtn(false)
          "
          nbButton
          size="giant"
          fullWidth
          [disabled]="!feedbackForm.valid"
        >
          Submit
        </button>
      </div>
    </form>
  </nb-card>
</ng-template>
