import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { CustomerService } from 'src/app/pages/customer/customer.service';

@Component({
  selector: 'app-file-import',
  templateUrl: './file-import.component.html',
  styleUrls: ['./file-import.component.scss'],
})
export class FileImportComponent implements OnInit {
  uploadedFile: any;
  constructor(
    protected dialogRef: NbDialogRef<FileImportComponent>,
    private appService: AppService,
    private spinnerService: NgxSpinnerService,
    private toasterService: NbToastrService,
    private clientService: CustomerService
  ) {}

  ngOnInit(): void {}
  downloadTemplate() {
    this.spinnerService.show();
    this.clientService.downloadTemplateFile().subscribe((response): any => {
      var downloadURL = window.URL.createObjectURL(<any>response);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = `Client Template.csv`;
      link.click();
      this.spinnerService.hide();
    });
  }
  onCloseDialogue() {
    this.dialogRef.close('close');
  }
  onFileSubmit() {
    if (this.uploadedFile) {
      this.spinnerService.show();
      let fileData: FormData = new FormData();
      fileData.append('file', this.uploadedFile);

      this.appService
        .formDataApi(`file_import`, fileData)
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.toasterService.show('', response['message']);
            this.dialogRef.close();
          } else {
            this.toasterService.show('', response['message']);
          }
          this.spinnerService.hide();
        });
    }
  }

  handleFileInput(event: any) {
    this.uploadedFile = event?.target?.files.item(0);
  }
  uploadFile() {
    var button: any = document.getElementById('fileInput');
    button.click();
  }
}
