<nb-card>
  <!-- MOBILE VIEW starts -->
  <nb-card-header *ngIf="!detailData?.is_active">
    <nb-alert class="mb-1 mt-1" outline="basic"> DELETED CLIENT </nb-alert>
  </nb-card-header>

  <nb-card-body class="mobile-only gt-p-small">
    <div
      [ngClass]="
        (isAdmin || isDispatchUser) && detailData?.is_active
          ? 'col pointer'
          : 'col'
      "
      (click)="
        (isAdmin || isDispatchUser) && detailData?.is_active
          ? openEditTemplate()
          : ''
      "
    >
      <div class="sc-head4">
        <b>
          {{ detailData?.company_name }}
          <nb-icon
            [pack]="'eva'"
            [icon]="'edit-2-outline'"
            *ngIf="
              (isAdmin === true || isDispatchUser === true) &&
              detailData?.is_active
            "
          ></nb-icon>
        </b>
      </div>

      <div class="sc-head5">
        {{ detailData?.address?.full_address }}
      </div>
    </div>
    <ng-container *ngIf="!selectedSite">
      <div
        *ngIf="(hasJobs || hasCheckPoints) && (isAdmin || isDispatchUser)"
        [ngClass]="
          hasJobs && hasCheckPoints ? 'row row-cols-2 ' : 'row row-cols-1'
        "
      >
        <div class="col" (click)="openJobView()" *ngIf="hasJobs">
          <button class="nbbutton-long-text" nbButton size="giant" fullWidth>
            Jobs
          </button>
        </div>
        <div class="col" (click)="openCPView()" *ngIf="hasCheckPoints">
          <button class="nbbutton-long-text" nbButton size="giant" fullWidth>
            Checkpoints
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!selectedSite">
      <div [ngClass]="detailData?.is_active ? '' : 'deleted-client'">
        <!--client information section STARTS-->
        <div
          class="sc-mt-6"
          *ngIf="
            isDispatchUser || isAdmin || (!isAdmin && detailData?.information)
          "
        >
          <div
            [ngClass]="
              detailData?.information
                ? 'gt-form-box-border gt-form-box'
                : 'gt-form-box'
            "
          >
            <div
              [ngClass]="
                isAdmin || isDispatchUser ? 'header pointer' : 'header'
              "
              (click)="
                isAdmin === true || isDispatchUser === true
                  ? openInformationTemplate(requestRef)
                  : ''
              "
            >
              Client Information
              <nb-icon
                [pack]="'eva'"
                [icon]="'edit-2-outline'"
                *ngIf="isAdmin === true || isDispatchUser === true"
              ></nb-icon>
            </div>
            <div *ngIf="detailData?.information" class="content pre-line-text">
              {{ detailData?.information }}
            </div>
          </div>
        </div>
        <!--client information section ENDS-->
        <!--client files  section STARTS-->
        <div
          class="sc-mt-9"
          *ngIf="isAdmin || isDispatchUser || (!isAdmin && clientFiles?.length)"
        >
          <div
            [ngClass]="
              clientFiles?.length
                ? 'gt-form-box-border gt-form-box'
                : 'gt-form-box'
            "
          >
            <div
              [ngClass]="
                isAdmin || isDispatchUser ? 'header pointer' : 'header'
              "
              (click)="
                isAdmin || (isDispatchUser && clientFiles?.length < 10)
                  ? openFileUploadTemplate(uploadRef)
                  : ''
              "
            >
              {{
                isAdmin || isDispatchUser
                  ? "Client Files [" + clientFiles.length + " / 10 Max]"
                  : "Client Files"
              }}
              <nb-icon
                [pack]="'eva'"
                [icon]="'file-add-outline'"
                *ngIf="(isAdmin || isDispatchUser) && clientFiles?.length < 10"
              ></nb-icon>
            </div>
            <div class="row row-cols-1 sc-mt-6">
              <div
                *ngFor="let file of slicedClientFiles"
                class="col item-list pointer"
              >
                <div class="row row-cols-2">
                  <div
                    class="col-10"
                    (click)="onOpenFile(file, previewTempRef)"
                    style="align-items: center; display: flex"
                  >
                    <nb-badge
                      text="{{ file?.extension }}"
                      class="extension-badge"
                    >
                    </nb-badge>
                    {{ file?.description }}
                  </div>
                  <div class="col-2" *ngIf="isAdmin || isDispatchUser">
                    <nb-icon
                      class="sc-floating-right sc-mt-0"
                      nbSuffix
                      icon="trash"
                      pack="eva"
                      (click)="deleteFile(file)"
                    ></nb-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="sc-mt-6" *ngIf="clientFiles?.length > 3">
              <button
                nbButton
                size="small"
                fullWidth
                ghost
                (click)="
                  slicedClientFiles?.length < clientFiles?.length
                    ? (slicedClientFiles = clientFiles)
                    : (slicedClientFiles = clientFiles.slice(0, 3))
                "
              >
                {{
                  slicedClientFiles?.length < clientFiles?.length
                    ? "Expand"
                    : "Collapse"
                }}
                <nb-badge
                  text="{{ clientFiles?.length }}"
                  status="primary"
                  class="badge"
                  *ngIf="slicedClientFiles?.length < clientFiles?.length"
                >
                </nb-badge>
              </button>
            </div>
          </div>
        </div>
        <!--client files section ENDS-->

        <!-- SITES -->
        <!--client information section STARTS-->
        <div class="sc-mt-9">
          <div class="gt-form-box-border gt-form-box">
            <div
              [ngClass]="
                isAdmin || isDispatchUser ? 'header pointer' : 'header'
              "
              (click)="isAdmin || isDispatchUser ? addNewSite() : ''"
            >
              Sites
              <nb-icon
                [pack]="'eva'"
                [icon]="'plus-outline'"
                *ngIf="isAdmin || isDispatchUser"
              ></nb-icon>
            </div>
            <div
              class="person-list"
              *ngIf="!siteData?.length"
              style="color: var(--color-danger-500)"
            >
              No Sites Available
            </div>
            <div class="sc-mt-6" *ngIf="showSiteList">
              <nb-form-field class="sc-search-input-box">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="text"
                    nbInput
                    fullWidth
                    placeholder="Filter Sites"
                    [(ngModel)]="globalFilter"
                    (keyup)="onFilterApply($event)"
                  />
                  <label>Filter Sites</label>
                </div>
                <nb-icon
                  nbSuffix
                  *ngIf="showTypeAhead === true"
                  [icon]="'search-outline'"
                  pack="eva"
                  (click)="onFilterApply(true)"
                ></nb-icon>
                <nb-icon
                  nbSuffix
                  *ngIf="showTypeAhead === false"
                  [icon]="'close-outline'"
                  pack="eva"
                  (click)="onClearFilter()"
                ></nb-icon>
              </nb-form-field>

              <div
                class="row row-cols-2"
                *ngFor="let site of fullSiteList; let i = index"
              >
                <div class="col-10 item-list">
                  <span (click)="getSiteDetailById(site?.id)">{{
                    site?.company_name
                  }}</span>
                </div>
                <div class="col-2">
                  <nb-icon
                    *ngIf="isAdmin"
                    [pack]="'eva'"
                    [icon]="'trash'"
                    class="float-end"
                    (click)="deleteSite(site)"
                  ></nb-icon>
                </div>
              </div>
              <div>
                <app-pagination-control
                  [pageSize]="pageSize"
                  [pageNum]="pageNum"
                  [previous]="previous"
                  [totalRows]="totalSiteCount"
                  [totalPages]="totalPages"
                  (onClickPagination)="onClickPagination($event)"
                  (onChangePagination)="onChangePagination($event)"
                >
                </app-pagination-control>
              </div>
              <div class="sc-mt-6" *ngIf="!globalFilter">
                <button
                  nbButton
                  size="small"
                  fullWidth
                  ghost
                  (click)="showSiteList = false"
                >
                  Collapse
                </button>
              </div>
            </div>

            <div class="row row-cols-1 sc-mt-6" *ngIf="!showSiteList">
              <div
                class="row row-cols-2"
                *ngFor="let site of siteData; let i = index"
              >
                <div class="col-10 item-list">
                  <span (click)="getSiteDetailById(site?.id)">{{
                    site?.company_name
                  }}</span>
                </div>
                <div class="col-2">
                  <nb-icon
                    *ngIf="isAdmin"
                    [pack]="'eva'"
                    [icon]="'trash'"
                    class="float-end"
                    (click)="deleteSite(site)"
                  ></nb-icon>
                </div>
              </div>
              <div class="col">
                <button
                  *ngIf="siteCount > 3"
                  nbButton
                  ghost
                  size="small"
                  fullWidth
                  (click)="showSiteList = true; getCustomerSitesById()"
                >
                  Expand
                  <nb-badge
                    text="{{ siteCount }}"
                    status="primary"
                    class="badge"
                  >
                  </nb-badge>
                </button>
              </div>
            </div>
          </div>
        </div>

        <nb-alert
          class="mx-2 mb-0 mt-2"
          *ngIf="
            (isAdmin || isDispatchUser) &&
            (userListData.length < 1 || patrolReportSchedules?.length < 1)
          "
          status="danger"
        >
          WARNING: Not all reports have been setup for job and/or patrol
          reports.
        </nb-alert>

        <nb-tabset class="sc-mt-6">
          <nb-tab tabTitle="Patrol Reports" *ngIf="isAdmin || isDispatchUser">
            <nb-alert
              class="mx-2 mb-0 small-text"
              *ngIf="patrolReportSchedules?.length < 1"
              status="danger"
            >
              No one is receiving patrol reports for checkpoint scans.
            </nb-alert>

            <div
              *ngIf="patrolReportSchedules?.length > 0"
              class="small-text mb-2"
            >
              Reports for completed patrols will be sent to:
            </div>

            <div class="row row-cols-1 mt-2">
              <div
                *ngFor="let schedule of patrolReportSchedules; let i = index"
                class="contact-card"
                (click)="openSchedulerTemplate(schedule)"
              >
                <div class="strong">{{ schedule?.name }}</div>

                <div>
                  <small>
                    {{ schedule?.schedule?.repeat_type | titlecase }} @
                    {{ schedule?.mail_time }}
                  </small>
                </div>
              </div>
            </div>
            <div class="mt-3" *ngIf="isAdmin || isDispatchUser">
              <button
                nbButton
                fullWidth
                class="nbbutton-long-text"
                (click)="openSchedulerTemplate()"
                size="giant"
              >
                + Add Patrol Report
              </button>
            </div>
          </nb-tab>

          <nb-tab tabTitle="Other Reports" *ngIf="isAdmin || isDispatchUser">
            <nb-alert
              class="mx-2 mb-0 small-text"
              *ngIf="userListData.length < 1"
              status="danger"
            >
              No one is receiving reports for completed jobs.
            </nb-alert>

            <div *ngIf="userListData.length > 0" class="small-text mb-2">
              Reports for completed jobs will be sent to:
            </div>

            <div class="row row-cols-auto">
              <div *ngFor="let contact of userListData">
                <div
                  class="contact-card"
                  (click)="
                    contact?.is_only_location_contact
                      ? openSiteContactTemplate(contact, detailData, null, 'jc')
                      : openUserDialogBox(contact)
                  "
                >
                  <div>
                    {{ contact?.full_name }}
                  </div>
                  <div
                    *ngIf="contact?.full_name != contact?.email"
                    class="small-font"
                  >
                    {{ contact?.email }}
                  </div>
                </div>
              </div>
              <div>
                <button
                  *ngIf="clientData?.users?.length > 3"
                  nbButton
                  class="gt-edit-btn sc-mt-0"
                  (click)="
                    userListData?.length > 3
                      ? (userListData = clientData?.users?.slice(0, 3))
                      : (userListData = clientData?.users)
                  "
                  status="warning"
                  outline
                >
                  {{ userListData?.length > 3 ? "View Less" : "View All" }}
                </button>
              </div>
            </div>
            <div class="mt-3" *ngIf="isAdmin || isDispatchUser">
              <button
                nbButton
                fullWidth
                class="nbbutton-long-text"
                (click)="openTemplate(editUserDialog)"
                size="giant"
              >
                Modify
              </button>
            </div>
          </nb-tab>

          <!--global contacts section-->
          <nb-tab tabTitle="Client Contacts">
            <div class="row row-cols-auto sc-mt-6 gy-2">
              <div *ngFor="let contact of contactListTemp" class="col">
                <div
                  class="contact-card"
                  (click)="
                    openSiteContactTemplate(contact, detailData, null, 'lc')
                  "
                >
                  {{ contact?.full_name }}
                </div>
              </div>
              <div
                (click)="addToContactsTemp()"
                class="person-list"
                *ngIf="!contactListTemp?.length"
              >
                No Contacts Have Been Added To This Client Yet
              </div>
            </div>

            <div *ngIf="isAdmin || isDispatchUser">
              <button
                nbButton
                class="nbbutton-long-text mt-5"
                fullWidth
                (click)="addToContactsTemp()"
                size="giant"
              >
                Modify
              </button>
            </div>
          </nb-tab>
        </nb-tabset>
      </div>
    </ng-container>
    <!-- client delete/restore button-->
    <ng-container *ngIf="isAdmin && !selectedSite">
      <div (click)="archiveUnarchive()">
        <button
          class="nbbutton-long-text"
          nbButton
          size="medium"
          fullWidth
          [status]="detailData?.is_active ? 'danger' : 'success'"
        >
          {{ detailData?.is_active ? "Delete Client" : "Restore" }}
        </button>
      </div>
    </ng-container>

    <!--site expanded section starts-->
    <ng-container *ngIf="selectedSite">
      <div>
        <div>
          <button
            nbButton
            class="nbbutton-long-text"
            size="large"
            fullWidth
            (click)="
              selectedSite = null; showSiteList = false; getCustomerDetailById()
            "
          >
            BACK
          </button>
        </div>

        <div class="sc-head6 mt-3 mb-3">
          <b
            >{{ selectedSite?.company_name }}
            <nb-icon
              *ngIf="isAdmin || isDispatchUser"
              pack="eva"
              icon="edit-outline"
              (click)="editSite(selectedSite)"
            ></nb-icon
          ></b>

          <nb-icon
            *ngIf="isAdmin"
            [pack]="'eva'"
            [icon]="'trash'"
            class="float-end"
            status="danger"
            (click)="deleteSite(selectedSite)"
          ></nb-icon>
        </div>
        <div>
          {{ selectedSite?.address?.full_address }}
          <nb-icon
            *ngIf="isAdmin || isDispatchUser"
            pack="eva"
            icon="edit-outline"
            (click)="editSite(selectedSite, false)"
          ></nb-icon>
        </div>

        <div class="mt-2">
          <app-map-leaflet
            [latLon]="{
              lat: selectedSite?.address?.latitude,
              lon: selectedSite?.address?.longitude
            }"
            [mapId]="'siteLocation'"
          ></app-map-leaflet>
        </div>

        <!--site information section STARTS-->
        <div
          class="sc-mt-6"
          *ngIf="
            isAdmin || isDispatchUser || (!isAdmin && selectedSite?.information)
          "
        >
          <div
            [ngClass]="
              selectedSite?.information
                ? 'gt-form-box-border gt-form-box'
                : 'gt-form-box'
            "
          >
            <div
              [ngClass]="
                isAdmin || isDispatchUser ? 'header pointer' : 'header'
              "
              (click)="
                isAdmin || isDispatchUser
                  ? openInformationTemplate(requestRef, true)
                  : ''
              "
            >
              Site Information
              <nb-icon
                [pack]="'eva'"
                [icon]="'edit-2-outline'"
                *ngIf="isAdmin || isDispatchUser"
              ></nb-icon>
            </div>
            <div
              *ngIf="selectedSite?.information"
              class="content pre-line-text"
            >
              {{ selectedSite?.information }}
            </div>
          </div>
        </div>
        <!--site information section ENDS-->
        <!--sManager Notes section STARTS-->
        <div class="sc-mt-6" *ngIf="isAdmin">
          <div
            [ngClass]="
              selectedSite?.manager_notes
                ? 'gt-form-box-border gt-form-box'
                : 'gt-form-box'
            "
          >
            <div
              [ngClass]="isAdmin ? 'header pointer' : 'header'"
              (click)="
                isAdmin ? openManagerInfoTemplate(requestManagerRef) : ''
              "
            >
              Manager Notes
              <nb-icon
                [pack]="'eva'"
                [icon]="'edit-2-outline'"
                *ngIf="isAdmin"
              ></nb-icon>
            </div>
            <div
              *ngIf="selectedSite?.manager_notes"
              class="content pre-line-text"
            >
              {{ selectedSite?.manager_notes }}
            </div>
          </div>
        </div>
        <!--Manager Notes section ENDS-->
        <!--site files  section STARTS-->
        <div
          class="sc-mt-6"
          *ngIf="
            isAdmin || isDispatchUser || (!isAdmin && selectedSiteFiles?.length)
          "
        >
          <div
            [ngClass]="
              selectedSiteFiles?.length
                ? 'gt-form-box-border gt-form-box'
                : 'gt-form-box'
            "
          >
            <div
              [ngClass]="
                isAdmin || isDispatchUser ? 'header pointer' : 'header'
              "
              (click)="
                (isAdmin || isDispatchUser) && selectedSiteFiles?.length < 10
                  ? openFileUploadTemplate(uploadRef)
                  : ''
              "
            >
              {{
                isAdmin || isDispatchUser
                  ? "Site Files [" + selectedSiteFiles?.length + " / 10 Max]"
                  : "Site Files"
              }}
              <nb-icon
                [pack]="'eva'"
                [icon]="'file-add-outline'"
                *ngIf="
                  (isAdmin || isDispatchUser) && selectedSiteFiles?.length < 10
                "
              ></nb-icon>
            </div>
            <div class="row row-cols-1 sc-mt-6">
              <div
                *ngFor="let file of slicedSelectedSiteFiles"
                class="col item-list pointer"
              >
                <div class="row row-cols-2">
                  <div
                    class="col-10"
                    (click)="onOpenFile(file, previewTempRef)"
                    style="align-items: center; display: flex"
                  >
                    <nb-badge
                      text="{{ file?.extension }}"
                      class="extension-badge"
                    >
                    </nb-badge>
                    {{ file?.description }}
                  </div>
                  <div class="col-2" *ngIf="isAdmin || isDispatchUser">
                    <nb-icon
                      class="sc-floating-right sc-mt-0"
                      nbSuffix
                      icon="trash"
                      pack="eva"
                      (click)="deleteFile(file)"
                    ></nb-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="sc-mt-6" *ngIf="selectedSiteFiles?.length > 3">
              <button
                nbButton
                size="small"
                fullWidth
                outline
                (click)="
                  slicedSelectedSiteFiles?.length < selectedSiteFiles?.length
                    ? (slicedSelectedSiteFiles = selectedSiteFiles)
                    : (slicedSelectedSiteFiles = selectedSiteFiles.slice(0, 3))
                "
              >
                {{
                  slicedSelectedSiteFiles?.length < selectedSiteFiles?.length
                    ? "Expand"
                    : "Collapse"
                }}
                <nb-badge
                  text="{{ selectedSiteFiles?.length }}"
                  status="primary"
                  class="badge"
                  *ngIf="
                    slicedSelectedSiteFiles?.length < selectedSiteFiles?.length
                  "
                >
                </nb-badge>
              </button>
            </div>
          </div>
        </div>
        <!--site files section ENDS-->

        <!--site keys  section STARTS-->
        <div
          class="sc-mt-6"
          *ngIf="
            isAdmin || isDispatchUser || (!isAdmin && selectedSiteKeys?.length)
          "
        >
          <div
            [ngClass]="
              selectedSiteKeys?.length
                ? 'gt-form-box-border gt-form-box'
                : 'gt-form-box'
            "
          >
            <div
              [ngClass]="
                isAdmin || isDispatchUser ? 'header pointer' : 'header'
              "
              (click)="
                (isAdmin || isDispatchUser) && selectedSiteKeys?.length < 10
                  ? openSiteKeysTemplate(siteKeyRef)
                  : ''
              "
            >
              {{
                isAdmin || isDispatchUser
                  ? "Site Keys [" + selectedSiteKeys?.length + " / 10 Max]"
                  : "Site Keys"
              }}
              <nb-icon
                [pack]="'eva'"
                [icon]="'edit-outline'"
                *ngIf="
                  (isAdmin || isDispatchUser) && selectedSiteKeys?.length < 10
                "
              ></nb-icon>
            </div>
            <div class="row row-cols-1 sc-mt-6">
              <div *ngFor="let siteKey of slicedSelectedSiteKeys">
                <div class="row row-cols-2">
                  <div
                    class="col-10"
                    style="align-items: center; display: flex"
                  >
                    <ul class="key-list">
                      <li>&nbsp; {{ siteKey }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="sc-mt-6" *ngIf="selectedSiteKeys?.length > 3">
              <button
                nbButton
                size="small"
                fullWidth
                ghost
                (click)="
                  slicedSelectedSiteKeys?.length < selectedSiteKeys?.length
                    ? (slicedSelectedSiteKeys = selectedSiteKeys)
                    : (slicedSelectedSiteKeys = selectedSiteKeys.slice(0, 3))
                "
              >
                {{
                  slicedSelectedSiteKeys?.length < selectedSiteKeys?.length
                    ? "Expand"
                    : "Collapse"
                }}
                <nb-badge
                  text="{{ selectedSiteKeys?.length }}"
                  status="primary"
                  class="badge"
                  *ngIf="
                    slicedSelectedSiteKeys?.length < selectedSiteKeys?.length
                  "
                >
                </nb-badge>
              </button>
            </div>
          </div>
        </div>
        <!--site keys section ENDS-->

        <nb-tabset class="sc-mt-6">
          <!-- Site Contact for job reports send to -->
          <nb-tab tabTitle="Job Reports" *ngIf="isAdmin || isDispatchUser">
            <div *ngIf="siteReportUsers.length > 0" class="small-text mb-2">
              Reports for completed jobs will be sent to:
            </div>

            <div class="row row-cols-auto">
              <div *ngFor="let contact of slicedSiteReportUsers">
                <div
                  class="contact-card"
                  (click)="
                    contact?.is_only_location_contact
                      ? openSiteContactTemplate(
                          contact,
                          null,
                          selectedSite,
                          'jc'
                        )
                      : openUserDialogBox(contact)
                  "
                >
                  <div>
                    {{ contact?.full_name }}
                  </div>
                  <div
                    *ngIf="contact?.full_name != contact?.email"
                    class="small-font"
                  >
                    {{ contact?.email }}
                  </div>
                </div>
              </div>
              <div>
                <button
                  *ngIf="siteReportUsers?.length > 3"
                  nbButton
                  class="gt-edit-btn sc-mt-0"
                  (click)="
                    slicedSiteReportUsers?.length > 3
                      ? (slicedSiteReportUsers = siteReportUsers?.slice(0, 3))
                      : (slicedSiteReportUsers = siteReportUsers)
                  "
                  status="warning"
                  outline
                >
                  {{
                    slicedSiteReportUsers?.length > 3 ? "View Less" : "View All"
                  }}
                </button>
              </div>
            </div>
            <div class="mt-3" *ngIf="isAdmin || isDispatchUser">
              <button
                nbButton
                fullWidth
                class="nbbutton-long-text"
                (click)="openTemplate(editUserDialog)"
                size="giant"
              >
                Update Recipients
              </button>
            </div>
          </nb-tab>
          <!--global contacts section-->
          <nb-tab tabTitle="Site Contacts">
            <div class="row row-cols-auto sc-mt-6 gy-2">
              <div *ngFor="let contact of siteContactListTemp" class="col">
                <div
                  class="contact-card"
                  (click)="
                    openSiteContactTemplate(contact, null, selectedSite, 'lc')
                  "
                >
                  {{ contact?.full_name }}
                </div>
              </div>
              <div
                (click)="isAdmin || isDispatchUser ? addNewSiteContact() : ''"
                class="person-list"
                *ngIf="!siteContactListTemp?.length"
              >
                No Contacts Have Been Added To This Site Yet
              </div>
            </div>

            <div *ngIf="isAdmin || isDispatchUser">
              <button
                nbButton
                class="nbbutton-long-text mt-5"
                fullWidth
                (click)="addNewSiteContact()"
                size="giant"
              >
                Update Site Contacts
              </button>
            </div>
          </nb-tab>
        </nb-tabset>
      </div>
    </ng-container>
    <!--site expanded section ends-->
  </nb-card-body>
  <!-- MOBILE VIEW ends -->

  <!-- DESKTOP VIEW starts -->
  <nb-card-body class="large-view-only gt-p-1">
    <ng-container *ngIf="!showSiteList">
      <div class="row">
        <div class="col">
          <div class="row">
            <div
              [ngClass]="
                (isAdmin || isDispatchUser) && detailData?.is_active
                  ? 'col pointer'
                  : 'col'
              "
            >
              <div class="sc-head4 strong">
                <span
                  (click)="
                    (isAdmin || isDispatchUser) && detailData?.is_active
                      ? openEditTemplate()
                      : ''
                  "
                  >{{ detailData?.company_name }}</span
                >
                &nbsp;
                <nb-icon
                  *ngIf="isAdmin && detailData?.is_active"
                  [pack]="'eva'"
                  [icon]="'trash-2-outline'"
                  status="danger"
                  (click)="archiveUnarchive()"
                ></nb-icon>
              </div>

              <div class="sc-head5">
                {{ detailData?.address?.full_address }}
              </div>
            </div>

            <div
              class="col"
              style="max-width: 30rem"
              *ngIf="(hasJobs || hasCheckPoints) && (isAdmin || isDispatchUser)"
            >
              <div class="row row-cols-2">
                <div class="col" (click)="openJobView()" *ngIf="hasJobs">
                  <button nbButton size="giant" fullWidth>Jobs</button>
                </div>
                <div class="col" (click)="openCPView()" *ngIf="hasCheckPoints">
                  <button
                    nbButton
                    size="giant"
                    fullWidth
                    class="nbbutton-long-text"
                  >
                    Checkpoints
                  </button>
                </div>
              </div>
            </div>

            <div
              *ngIf="isAdmin && !detailData?.is_active"
              (click)="archiveUnarchive()"
              class="col-2"
            >
              <button
                class="nbbutton-long-text"
                nbButton
                size="giant"
                fullWidth
                status="success"
              >
                Restore
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- SECTION: SITES, GLOBAL CONTACTS, & SEND PATROL REPORTS TO  -->
      <div
        class="row row-cols-3 sc-mt-9"
        [ngClass]="detailData?.is_active ? '' : 'deleted-client'"
      >
        <!-- SITES -->
        <div class="col">
          <button
            nbButton
            ghost
            fullWidth
            size="tiny"
            style="
              color: var(--text-color-basic);
              border-bottom: 1px solid var(--border-basic-color-5);
              border-radius: 0;
              width: 80%;
            "
            class="mb-1"
            (click)="getCustomerSitesById(); showSiteList = true"
          >
            Sites
            <nb-icon
              [pack]="'eva'"
              [icon]="'edit-2-outline'"
              *ngIf="isAdmin || isDispatchUser"
            ></nb-icon>
          </button>

          <div class="row row-cols-1" style="width: 80%">
            <div class="d-flex" *ngFor="let site of siteData; let i = index">
              <div
                class="flex-grow-1 pointer"
                (click)="getSiteDetailById(site?.id); getCustomerSitesById()"
              >
                {{ site?.company_name }}
              </div>
              <div>
                <nb-icon
                  *ngIf="isAdmin"
                  [pack]="'eva'"
                  [icon]="'trash'"
                  status="danger"
                  class="float-end"
                  (click)="deleteSite(site)"
                ></nb-icon>
              </div>
            </div>
            <div class="col">
              <button
                *ngIf="siteCount > 3"
                nbButton
                size="small"
                fullWidth
                class="w-50"
                (click)="getCustomerSitesById(); showSiteList = true"
              >
                View All Sites
                <nb-badge text="{{ siteCount }}" status="primary" class="badge">
                </nb-badge>
              </button>
            </div>
          </div>

          <div
            class="person-list"
            *ngIf="!siteData?.length"
            style="color: var(--color-danger-500)"
          >
            No Sites Available
          </div>
        </div>

        <!-- GLOBAL CONTACTS -->
        <div class="col">
          <button
            nbButton
            ghost
            fullWidth
            size="tiny"
            style="
              color: var(--text-color-basic);
              border-bottom: 1px solid var(--border-basic-color-5);
              border-radius: 0;
              width: 80%;
            "
            class="pointer mb-1"
            (click)="addToContactsTemp()"
          >
            Contacts
            <nb-icon
              [pack]="'eva'"
              [icon]="'edit-2-outline'"
              *ngIf="isAdmin || isDispatchUser"
            ></nb-icon>
          </button>

          <div class="row row-cols-auto gy-2">
            <div *ngFor="let contact of contactListTemp" class="col">
              <div
                class="contact-card"
                (click)="
                  openSiteContactTemplate(contact, detailData, null, 'lc')
                "
              >
                {{ contact?.full_name }}
              </div>
            </div>

            <div
              (click)="addToContactsTemp()"
              class="person-list sc-text-center"
              *ngIf="!contactListTemp?.length"
              style="width: 80%"
            >
              <button *ngIf="isAdmin || isDispatchUser" nbButton fullWidth>
                + Add Contact
              </button>
            </div>
          </div>
        </div>

        <!-- CLIENT INFORMATION  -->
        <div
          class="col"
          *ngIf="
            isAdmin === true ||
            isDispatchUser === true ||
            (!isAdmin && detailData?.information)
          "
        >
          <button
            nbButton
            ghost
            fullWidth
            size="tiny"
            style="
              color: var(--text-color-basic);
              border-bottom: 1px solid var(--border-basic-color-5);
              border-radius: 0;
              width: 80%;
            "
            [ngClass]="
              isAdmin === true || isDispatchUser === true ? ' pointer' : ''
            "
            (click)="
              isAdmin === true || isDispatchUser === true
                ? openInformationTemplate(requestRef)
                : ''
            "
          >
            Client Information
            <nb-icon
              [pack]="'eva'"
              [icon]="'edit-2-outline'"
              *ngIf="isAdmin === true || isDispatchUser === true"
            ></nb-icon>
          </button>
          <div class="sc-mt-6 section-content mt-0 pre-line-text">
            {{ detailData?.information }}
          </div>
        </div>
        <div class="col" *ngIf="isAdmin || isDispatchUser">
          <button
            nbButton
            ghost
            fullWidth
            size="tiny"
            style="
              color: var(--text-color-basic);
              border-bottom: 1px solid var(--border-basic-color-5);
              border-radius: 0;
              width: 80%;
            "
            class="pointer mb-1"
            (click)="openTemplate(editUserDialog)"
          >
            Send Job Reports To
            <nb-icon
              [pack]="'eva'"
              [icon]="'edit-2-outline'"
              *ngIf="isAdmin || isDispatchUser"
            ></nb-icon>
          </button>

          <div
            *ngIf="clientData?.users?.length == 0"
            style="width: 80%"
            (click)="openTemplate(editUserDialog)"
          >
            <button *ngIf="isAdmin || isDispatchUser" nbButton fullWidth>
              + Add Contact
            </button>
          </div>

          <div class="row row-cols-auto gy-2">
            <div *ngFor="let contact of userListData">
              <div
                class="contact-card"
                (click)="
                  contact?.is_only_location_contact
                    ? openSiteContactTemplate(contact, detailData, null, 'jc')
                    : openUserDialogBox(contact)
                "
              >
                <div>
                  {{ contact?.full_name }}
                </div>
                <div
                  *ngIf="contact?.full_name != contact?.email"
                  class="small-font"
                >
                  {{ contact?.email }}
                </div>
              </div>
            </div>
            <div>
              <button
                *ngIf="clientData?.users?.length > 3"
                nbButton
                class="gt-edit-btn sc-mt-0"
                (click)="
                  userListData?.length > 3
                    ? (userListData = clientData?.users?.slice(0, 3))
                    : (userListData = clientData?.users)
                "
                status="warning"
                outline
              >
                {{ userListData?.length > 3 ? "View Less" : "View All" }}
              </button>
            </div>
          </div>
        </div>

        <div class="col" *ngIf="isAdmin || isDispatchUser">
          <button
            nbButton
            ghost
            fullWidth
            size="tiny"
            style="
              color: var(--text-color-basic);
              border-bottom: 1px solid var(--border-basic-color-5);
              border-radius: 0;
              width: 80%;
            "
            class="pointer mb-1"
            (click)="isAdmin || isDispatchUser ? openSchedulerTemplate() : ''"
          >
            Schedule Scan Reports
            <nb-icon
              [pack]="'eva'"
              [icon]="'edit-2-outline'"
              *ngIf="isAdmin || isDispatchUser"
            ></nb-icon>
          </button>
          <div class="row row-cols-auto">
            <div *ngFor="let schedule of patrolReportSchedules; let i = index">
              <div
                class="contact-card"
                (click)="openSchedulerTemplate(schedule)"
              >
                <div class="strong">{{ schedule?.name }}</div>

                <div>
                  <small>
                    {{ schedule?.schedule?.repeat_type | titlecase }} @
                    {{ schedule?.mail_time }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--file upload section-->
        <div
          class="col"
          *ngIf="isAdmin || isDispatchUser || (!isAdmin && clientFiles?.length)"
        >
          <button
            nbButton
            ghost
            fullWidth
            size="tiny"
            style="
              color: var(--text-color-basic);
              border-bottom: 1px solid var(--border-basic-color-5);
              border-radius: 0;
              width: 80%;
            "
            class="pointer mb-1"
            (click)="
              (isAdmin || isDispatchUser) && clientFiles?.length < 10
                ? openFileUploadTemplate(uploadRef)
                : ''
            "
          >
            Client Files
            <nb-icon
              [pack]="'eva'"
              [icon]="'edit-2-outline'"
              *ngIf="(isAdmin || isDispatchUser) && clientFiles?.length < 10"
            ></nb-icon>
          </button>
          <div class="row row-cols-1">
            <div
              *ngFor="let file of slicedClientFiles"
              class="col item-list pointer gy-3"
            >
              <div class="row">
                <div class="d-flex section-content client-files-section">
                  <div class="d-flex">
                    <nb-badge
                      text="{{ file?.extension }}"
                      class="extension-badge"
                    ></nb-badge>
                    <div
                      class="pointer"
                      (click)="onOpenFile(file, previewTempRef)"
                    >
                      {{ file?.description }}
                    </div>
                  </div>

                  <div *ngIf="isAdmin || isDispatchUser">
                    <nb-icon
                      class="sc-floating-right sc-mt-0"
                      nbSuffix
                      icon="trash"
                      pack="eva"
                      (click)="deleteFile(file)"
                    ></nb-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!selectedSite && (isAdmin || isDispatchUser)">
        <!-- history section start -->

        <div
          [ngClass]="showDetails ? 'row row-cols-2' : 'row row-cols-1'"
          class="sc-mt-6"
        >
          <div
            [ngClass]="showDetails ? 'col-md-12 col-lg-6' : 'col-12'"
            #target
          >
            <app-table
              [desktopTableData]="clientHistory"
              [desktopTableDetails]="userEventDetailsTableView"
              [tableStyle]="tableStyle"
              (rowClick)="onTableRowClick($event)"
              [otherParms]="{ dateRange: dateRange }"
            >
            </app-table>
          </div>
          <div class="col-md-12 col-lg-6" *ngIf="showDetails">
            <div
              [ngClass]="uploadedImage ? 'row row-cols-md-2' : 'row row-cols-1'"
            >
              <div class="col-md-6" *ngIf="uploadedImage">
                <img
                  *ngIf="uploadedImage"
                  src="{{ uploadedImage }}"
                  alt="unplash"
                  style="max-height: 100%; max-width: 100%"
                />
              </div>
              <div [ngClass]="uploadedImage ? 'col-md-6' : 'col'">
                <app-map-leaflet
                  *ngIf="mapLatLong"
                  [latLon]="mapLatLong"
                  [mapId]="'jobHistoryLocation'"
                >
                </app-map-leaflet>
              </div>
            </div>
          </div>
        </div>
        <div
          class="gt-event-details"
          *ngIf="selectedEvent?.detail || selectedEvent?.device_details"
        >
          <div class="row row-cols-2">
            <div *ngIf="selectedEvent?.detail" class="col-7">
              <strong>{{ selectedEvent?.action | titlecase }}</strong>
              <pre class="sc-head6">{{ selectedEvent?.detail }}</pre>
            </div>
            <div class="col-5">
              <div
                *ngFor="let item of selectedEvent?.device_details | keyvalue"
              >
                <div *ngIf="item?.value" class="row row-cols-2">
                  <div class="col-4">
                    <small>{{ item.key }}</small>
                  </div>
                  <div class="col-8">
                    <strong
                      ><small> {{ item.value }}</small>
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <!--site full list view section-->
    <ng-container *ngIf="showSiteList">
      <div class="col">
        <p class="sc-head4">
          <b> {{ detailData?.company_name }}</b>
        </p>

        <div class="sc-head5">
          {{ detailData?.address?.full_address }}
        </div>
      </div>
      <div class="sc-mt-6">
        <div class="col-5">
          <div class="flex-center">
            <nb-form-field class="sc-search-input-box">
              <div class="form-floating">
                <input
                  class="form-control"
                  type="text"
                  nbInput
                  fullWidth
                  placeholder="Filter Sites"
                  [(ngModel)]="globalFilter"
                  (keyup)="onFilterApply($event)"
                />
                <label>Filter Sites</label>
              </div>
              <nb-icon
                class="ico-color"
                nbSuffix
                *ngIf="showTypeAhead === true"
                [icon]="'search-outline'"
                pack="eva"
                (click)="onFilterApply(true)"
              ></nb-icon>
              <nb-icon
                class="ico-color"
                nbSuffix
                *ngIf="showTypeAhead === false"
                [icon]="'close-outline'"
                pack="eva"
                (click)="onClearFilter()"
              ></nb-icon>
            </nb-form-field>
            <div
              class="sc-add-btn"
              *ngIf="isAdmin === true || isDispatchUser === true"
            >
              <button
                class="sc-mt-0"
                title="Add Site"
                size="giant"
                nbButton
                type="button"
                (click)="addNewSite()"
              >
                + Add Site
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <button
              size="medium"
              nbButton
              type="button"
              (click)="
                showSiteList = false;
                selectedSite = null;
                fullSiteList = [];
                getCustomerDetailById()
              "
            >
              <nb-icon
                class="ico-bulk-upload"
                pack="eva"
                icon="close-outline"
                pack="eva"
              ></nb-icon>
              Close View Sites
            </button>
          </div>
        </div>

        <div class="row row-cols-2 sc-mt-9">
          <div [ngClass]="selectedSite ? 'col-4 ' : 'col-12 '">
            <div>
              <div
                *ngFor="let site of fullSiteList; let i = index"
                [ngClass]="
                  selectedSite?.id == site?.id
                    ? 'col py-2 site-list selectedSite'
                    : 'col py-2 site-list'
                "
              >
                <span (click)="getSiteDetailById(site?.id)">
                  {{ site?.company_name }}</span
                >

                <span>
                  <nb-icon
                    class="sc-floating-right sc-mt-0"
                    nbSuffix
                    icon="trash"
                    pack="eva"
                    status="danger"
                    (click)="deleteSite(site)"
                  ></nb-icon>
                </span>
              </div>
            </div>

            <!-- TODO: Only show if client has more than 5 sites -->
            <div *ngIf="totalSiteCount > 5">
              <app-pagination-control
                [pageSize]="pageSize"
                [pageNum]="pageNum"
                [previous]="previous"
                [totalRows]="totalSiteCount"
                [totalPages]="totalPages"
                (onClickPagination)="onClickPagination($event)"
                (onChangePagination)="onChangePagination($event)"
              >
              </app-pagination-control>
            </div>
          </div>

          <!-- ONLY SHOW THIS COL-8 IF onSelectSite  -->
          <div class="col-8" *ngIf="selectedSite">
            <div class="row row-cols-2">
              <div class="col">
                <div
                  [ngClass]="
                    isAdmin || isDispatchUser ? 'pointer sc-head4' : 'sc-head4'
                  "
                  (click)="
                    isAdmin || isDispatchUser ? editSite(selectedSite) : ''
                  "
                >
                  {{ selectedSite?.company_name }}
                  <nb-icon
                    *ngIf="isAdmin || isDispatchUser"
                    pack="eva"
                    icon="edit-outline"
                  ></nb-icon>
                </div>
                <div
                  [ngClass]="
                    isAdmin || isDispatchUser ? 'pointer strong' : 'strong'
                  "
                  (click)="
                    isAdmin || isDispatchUser
                      ? editSite(selectedSite, false)
                      : ''
                  "
                >
                  {{ selectedSite?.address?.full_address }}
                  <nb-icon
                    *ngIf="isAdmin || isDispatchUser"
                    pack="eva"
                    icon="edit-outline"
                  ></nb-icon>
                </div>

                <!--site information section starts-->
                <div
                  class="sc-mt-9"
                  *ngIf="
                    isAdmin ||
                    isDispatchUser ||
                    (!isAdmin && selectedSite?.information)
                  "
                >
                  <button
                    nbButton
                    ghost
                    fullWidth
                    size="tiny"
                    style="
                      color: var(--text-color-basic);
                      border-bottom: 1px solid var(--border-basic-color-5);
                      border-radius: 0;
                      width: 80%;
                    "
                    class="pointer mb-1"
                    (click)="
                      isAdmin || isDispatchUser
                        ? openInformationTemplate(requestRef, true)
                        : ''
                    "
                  >
                    Site Information
                    <nb-icon
                      [pack]="'eva'"
                      [icon]="'edit-2-outline'"
                      *ngIf="isAdmin || isDispatchUser"
                    ></nb-icon>
                  </button>

                  <div
                    *ngIf="selectedSite?.information"
                    class="content pre-line-text"
                    style="width: 80%"
                  >
                    {{ selectedSite?.information }}
                  </div>
                </div>
                <!--site information section ends-->
                <!--Manager Notes section starts-->
                <div class="sc-mt-9" *ngIf="isAdmin">
                  <button
                    nbButton
                    ghost
                    fullWidth
                    size="tiny"
                    style="
                      color: var(--text-color-basic);
                      border-bottom: 1px solid var(--border-basic-color-5);
                      border-radius: 0;
                      width: 80%;
                    "
                    class="pointer mb-1"
                    (click)="
                      isAdmin || isDispatchUser
                        ? openManagerInfoTemplate(requestManagerRef)
                        : ''
                    "
                  >
                    Manager Notes
                    <nb-icon
                      [pack]="'eva'"
                      [icon]="'edit-2-outline'"
                      *ngIf="isAdmin"
                    ></nb-icon>
                  </button>

                  <div
                    *ngIf="selectedSite?.manager_notes"
                    class="content pre-line-text"
                    style="width: 80%"
                  >
                    {{ selectedSite?.manager_notes }}
                  </div>
                </div>
                <!--Manager Notes section ends-->
                <!--site files section starts-->
                <div
                  class="sc-mt-9"
                  *ngIf="
                    isAdmin ||
                    isDispatchUser ||
                    (!isAdmin && selectedSiteFiles?.length)
                  "
                >
                  <button
                    nbButton
                    ghost
                    fullWidth
                    size="tiny"
                    style="
                      color: var(--text-color-basic);
                      border-bottom: 1px solid var(--border-basic-color-5);
                      border-radius: 0;
                      width: 80%;
                    "
                    class="pointer mb-1"
                    (click)="
                      (isAdmin || isDispatchUser) &&
                      selectedSiteFiles?.length < 10
                        ? openFileUploadTemplate(uploadRef)
                        : ''
                    "
                  >
                    {{
                      isAdmin || isDispatchUser
                        ? "Site Files [" +
                          selectedSiteFiles?.length +
                          " / 10 Max]"
                        : "Site Files"
                    }}
                    <nb-icon
                      [pack]="'eva'"
                      [icon]="'file-add-outline'"
                      *ngIf="isAdmin && selectedSiteFiles?.length < 10"
                    ></nb-icon>
                  </button>

                  <div class="row row-cols-1 sc-mt-6" style="width: 80%">
                    <div
                      *ngFor="let file of slicedSelectedSiteFiles"
                      class="col item-list pointer"
                    >
                      <div class="row row-cols-2">
                        <div
                          class="col-10"
                          (click)="onOpenFile(file, previewTempRef)"
                          style="align-items: center; display: flex"
                        >
                          <nb-badge
                            text="{{ file?.extension }}"
                            class="extension-badge"
                          >
                          </nb-badge>
                          {{ file?.description }}
                        </div>
                        <div class="col-2" *ngIf="isAdmin">
                          <nb-icon
                            class="sc-floating-right sc-mt-0"
                            nbSuffix
                            icon="trash"
                            pack="eva"
                            status="danger"
                            (click)="deleteFile(file)"
                          ></nb-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="sc-mt-6"
                    *ngIf="selectedSiteFiles?.length > 3"
                    style="width: 80%"
                  >
                    <button
                      nbButton
                      size="small"
                      fullWidth
                      outline
                      (click)="
                        slicedSelectedSiteFiles?.length <
                        selectedSiteFiles?.length
                          ? (slicedSelectedSiteFiles = selectedSiteFiles)
                          : (slicedSelectedSiteFiles = selectedSiteFiles.slice(
                              0,
                              3
                            ))
                      "
                    >
                      {{
                        slicedSelectedSiteFiles?.length <
                        selectedSiteFiles?.length
                          ? "Expand"
                          : "Collapse"
                      }}
                      <nb-badge
                        text="{{ selectedSiteFiles?.length }}"
                        status="primary"
                        class="badge"
                        *ngIf="
                          slicedSelectedSiteFiles?.length <
                          selectedSiteFiles?.length
                        "
                      >
                      </nb-badge>
                    </button>
                  </div>
                </div>
                <!--site files section ends-->

                <!--site keys section starts-->
                <div
                  class="sc-mt-9"
                  *ngIf="
                    isAdmin ||
                    isDispatchUser ||
                    (!isAdmin && selectedSiteKeys?.length)
                  "
                >
                  <button
                    nbButton
                    ghost
                    fullWidth
                    size="tiny"
                    style="
                      color: var(--text-color-basic);
                      border-bottom: 1px solid var(--border-basic-color-5);
                      border-radius: 0;
                      width: 80%;
                    "
                    class="pointer mb-1"
                    (click)="
                      (isAdmin || isDispatchUser) &&
                      selectedSiteKeys?.length < 10
                        ? openSiteKeysTemplate(siteKeyRef)
                        : ''
                    "
                  >
                    {{
                      isAdmin || isDispatchUser
                        ? "Site Keys [" +
                          selectedSiteKeys?.length +
                          " / 10 Max]"
                        : "Site Keys"
                    }}
                    <nb-icon
                      [pack]="'eva'"
                      [icon]="'edit-2-outline'"
                      *ngIf="isAdmin || isDispatchUser"
                    ></nb-icon>
                  </button>

                  <div class="row row-cols-1 sc-mt-6" style="width: 80%">
                    <div *ngFor="let siteKey of slicedSelectedSiteKeys">
                      <div class="row row-cols-2">
                        <div
                          class="col-10"
                          style="align-items: center; display: flex"
                        >
                          <ul class="mb-0 key-list">
                            <li>&nbsp; {{ siteKey }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sc-mt-6" *ngIf="selectedSiteKeys?.length > 3">
                    <button
                      nbButton
                      size="small"
                      fullWidth
                      ghost
                      (click)="
                        slicedSelectedSiteKeys?.length <
                        selectedSiteKeys?.length
                          ? (slicedSelectedSiteKeys = selectedSiteKeys)
                          : (slicedSelectedSiteKeys = selectedSiteKeys.slice(
                              0,
                              3
                            ))
                      "
                    >
                      {{
                        slicedSelectedSiteKeys?.length <
                        selectedSiteKeys?.length
                          ? "Expand"
                          : "Collapse"
                      }}
                      <nb-badge
                        text="{{ selectedSiteKeys?.length }}"
                        status="primary"
                        class="badge"
                        *ngIf="
                          slicedSelectedSiteKeys?.length <
                          selectedSiteKeys?.length
                        "
                      >
                      </nb-badge>
                    </button>
                  </div>
                </div>
                <!--site keys section ends-->

                <!--job report users section starts-->
                <div class="sc-mt-9" *ngIf="isAdmin || isDispatchUser">
                  <button
                    nbButton
                    ghost
                    fullWidth
                    size="tiny"
                    style="
                      color: var(--text-color-basic);
                      border-bottom: 1px solid var(--border-basic-color-5);
                      border-radius: 0;
                      width: 80%;
                    "
                    class="pointer mb-1"
                    (click)="openTemplate(editUserDialog)"
                  >
                    Send Job Reports To
                    <nb-icon
                      [pack]="'eva'"
                      [icon]="'edit-2-outline'"
                      *ngIf="isAdmin || isDispatchUser"
                    ></nb-icon>
                  </button>

                  <div class="row row-cols-auto gy-2">
                    <div *ngFor="let contact of slicedSiteReportUsers">
                      <div
                        class="contact-card"
                        (click)="
                          contact?.is_only_location_contact
                            ? openSiteContactTemplate(
                                contact,
                                null,
                                selectedSite,
                                'jc'
                              )
                            : openUserDialogBox(contact)
                        "
                      >
                        <div>
                          {{ contact?.full_name }}
                        </div>
                        <div
                          *ngIf="contact?.full_name != contact?.email"
                          class="small-font"
                        >
                          {{ contact?.email }}
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        *ngIf="siteReportUsers?.length > 3"
                        nbButton
                        class="gt-edit-btn sc-mt-0"
                        (click)="
                          slicedSiteReportUsers?.length > 3
                            ? (slicedSiteReportUsers = siteReportUsers?.slice(
                                0,
                                3
                              ))
                            : (slicedSiteReportUsers = siteReportUsers)
                        "
                        status="warning"
                        outline
                      >
                        {{
                          slicedSiteReportUsers?.length > 3
                            ? "View Less"
                            : "View All"
                        }}
                      </button>
                    </div>
                  </div>
                </div>
                <!--job report users section ends-->
                <!--site contacts section starts-->
                <div class="sc-mt-9">
                  <button
                    nbButton
                    ghost
                    fullWidth
                    size="tiny"
                    style="
                      color: var(--text-color-basic);
                      border-bottom: 1px solid var(--border-basic-color-5);
                      border-radius: 0;
                      width: 80%;
                    "
                    class="pointer mb-1"
                    (click)="
                      isAdmin || isDispatchUser ? addNewSiteContact() : ''
                    "
                  >
                    Site Contacts
                    <nb-icon
                      [pack]="'eva'"
                      [icon]="'edit-2-outline'"
                      *ngIf="isAdmin || isDispatchUser"
                    ></nb-icon>
                  </button>

                  <div class="row row-cols-auto sc-mt-6">
                    <div
                      *ngFor="let contact of siteContactListTemp"
                      class="col"
                    >
                      <div
                        class="contact-card"
                        (click)="
                          openSiteContactTemplate(
                            contact,
                            null,
                            selectedSite,
                            'lc'
                          )
                        "
                      >
                        {{ contact?.full_name }}
                      </div>
                    </div>
                    <div
                      (click)="
                        isAdmin || isDispatchUser ? addNewSiteContact() : ''
                      "
                      class="person-list"
                      *ngIf="!siteContactListTemp?.length"
                    >
                      No Contacts Assigned to this Site
                    </div>
                  </div>
                </div>
                <!--site contacts section ends-->
              </div>

              <div class="col">
                <div *ngIf="mapLatLong">
                  <div>
                    <app-map-leaflet
                      [latLon]="mapLatLong"
                      [mapId]="'jobHistoryLocation'"
                    ></app-map-leaflet>
                    <div class="sc-text-center">
                      Lat: {{ mapLatLong?.lat }} Lon: {{ mapLatLong?.lon }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- history section end -->
  </nb-card-body>
  <!-- DESKTOP VIEW ends -->
</nb-card>

<!-- MAP SECTION -->
<ng-template #mapRef let-data>
  <nb-card class="dialog-box">
    <app-map-leaflet
      [latLon]="{ lat: data?.address?.latitude, lon: data?.address?.longitude }"
      [mapId]="'clientLocation'"
    ></app-map-leaflet>
    <!-- MAP SECTION ends -->
  </nb-card>
</ng-template>

<!-- SEND Job REPORTS DIALOGUE -->
<ng-template #editUserDialog let-uDRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-body>
      <nb-tag-list class="nb-tag-list">
        <nb-tag
          *ngFor="let user of selectedUsers"
          [text]="user.full_name"
          removable
          (remove)="removeUser(user)"
          nbTooltip="{{ user?.email }}  |
          {{ user?.mobile_number }}"
        ></nb-tag>
      </nb-tag-list>
      <div class="form-floating">
        <input
          class="form-control"
          [disabled]="disableInputField"
          type="text"
          nbTagInput
          fullWidth
          placeholder="Contact Name"
          (input)="userStr.length > 2 ? getUsers($event) : ''"
          [(ngModel)]="userStr"
          [nbAutocomplete]="autoNgModel"
        />
        <label>Contact Name</label>
      </div>
      <nb-autocomplete #autoNgModel>
        <nb-option
          *ngIf="isAdmin || isDispatchUser"
          (click)="addToContactsTemp(true)"
        >
          Add New Contact
        </nb-option>
        <nb-option *ngFor="let user of usersList" (click)="addUser(user)">
          <div>
            {{ user?.user_full_name
            }}<nb-badge
              *ngIf="user?.location_contact_id"
              text="Onsite Contact"
              class="extension-badge"
            >
            </nb-badge>
            <div>
              <small>
                {{ user?.user_full_name ? user.user_email : "" }}
                {{
                  user?.user_mobile_number
                    ? " , " + user?.user_mobile_number
                    : ""
                }}
              </small>
            </div>
          </div>
        </nb-option>
      </nb-autocomplete>

      <div class="row row-cols-2 sc-mt-6">
        <div class="col">
          <button size="giant" (click)="uDRef.close()" nbButton fullWidth ghost>
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            size="giant"
            (click)="addRemoveUsers(); uDRef.close()"
            nbButton
            fullWidth
          >
            Close
          </button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<!-- Client Information POPOVER / DIALOG -->
<ng-template #requestRef let-data let-requestRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center">
      {{
        data?.siteInfo ? "Site Information" : "Client Information"
      }}</nb-card-header
    >

    <!-- Request Detail Section start-->
    <div class="row">
      <div class="col">
        <textarea
          class="form-control"
          placeholder="{{
            data?.siteInfo ? 'Site Information' : 'Client Information'
          }}"
          cols="20"
          rows="5"
          wrap="hard"
          fullWidth
          type="text"
          [(ngModel)]="newInfromation"
        ></textarea>
      </div>
    </div>

    <div class="row row-cols-2 sc-mt-6">
      <div class="col">
        <button
          nbButton
          fullWidth
          size="giant"
          ghost
          (click)="requestRef.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          nbButton
          size="giant"
          fullWidth
          (click)="
            requestRef.close();
            data?.siteInfo ? updateSiteInformation() : updateClientInformation()
          "
        >
          Update
        </button>
      </div>
    </div>

    <!-- Request Detail Section end-->
  </nb-card>
</ng-template>

<!-- Client file Upload DIALOG -->
<ng-template #uploadRef let-data let-uploadRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center">Add File</nb-card-header>

    <div class="sc-mt-6" *ngIf="fileUploadError">
      <nb-alert outline="danger" style="font-weight: 500">
        {{ fileUploadError }}</nb-alert
      >
    </div>
    <div class="sc-mt-6 filenameclip">
      <input type="file" (change)="handleFileInput($event)" />
    </div>

    <div class="form-floating sc-mt-6">
      <input
        class="form-control"
        placeholder="File Description"
        fullWidth
        maxlength="30"
        type="text"
        [(ngModel)]="fileDescription"
      /><label>File Description</label>
    </div>

    <div class="row row-cols-2 sc-mt-6">
      <div class="col">
        <button
          nbButton
          fullWidth
          size="giant"
          ghost
          (click)="
            uploadRef.close();
            fileUploadError = null;
            fileDescription = null;
            uploadFile = null
          "
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          nbButton
          size="giant"
          [disabled]="!uploadFile || !fileDescription"
          fullWidth
          (click)="onUploadFile(); uploadRef.close()"
        >
          Upload
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>

<!--enlarge image template-->

<ng-template #previewTempRef let-data let-previewTempRef="dialogRef">
  <nb-card style="margin: 0" class="gt-p-small">
    <nb-card-header>
      <div>
        {{ data?.description }}
        <div class="sc-floating-right">
          <nb-icon
            pack="eva"
            icon="close-outline"
            (click)="previewTempRef.close()"
          ></nb-icon>
        </div>
      </div>
    </nb-card-header>
    <div>
      <div style="position: absolute; left: 50%; top: 50%" *ngIf="!hideSpinner">
        <div id="wave">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>

      <img
        src="{{ data?.file_url }}"
        style="max-width: 100%; max-height: 90vh"
        class="modal-hover-opacity"
        (load)="hideSpinner = true"
      />
    </div>
  </nb-card>
</ng-template>
<!--enlarge image template-->

<ng-template #siteKeyRef let-data let-siteKeyRef="dialogRef">
  <nb-card style="margin: 0" class="dialog-box gt-p-small">
    <nb-card-header>
      <div>
        Site Keys
        <div class="sc-floating-right">
          <nb-icon
            pack="eva"
            icon="close-outline"
            (click)="siteKeyRef.close()"
          ></nb-icon>
        </div>
      </div>
    </nb-card-header>
    <div class="site-key-list">
      <div *ngFor="let siteKey of siteKeyList; let i = index" class="sc-mt-6">
        <div class="row row-cols-3 gy-2">
          <div class="col-8 form-floating">
            <input
              [id]="'siteKey' + i"
              class="form-control"
              placeholder="Site Key"
              fullWidth
              maxlength="30"
              type="text"
              [(ngModel)]="siteKey.value"
            /><label>Site Key</label>
          </div>
          <div class="col-2" (click)="addNewSiteKeyField(i)" *ngIf="i < 10">
            <button
              nbButton
              size="giant"
              [disabled]="i + 1 < siteKeyList?.length || !siteKeyList[i]?.value"
              fullWidth
            >
              <nb-icon pack="eva" icon="plus"></nb-icon>
            </button>
          </div>
          <div class="col-2" *ngIf="i < 10" (click)="deleteSiteKeyField(i)">
            <button
              nbButton
              size="giant"
              [disabled]="!siteKeyList[i]?.value"
              fullWidth
            >
              <nb-icon pack="eva" icon="trash"></nb-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-cols-2 sc-mt-6">
      <div class="col">
        <button
          nbButton
          fullWidth
          size="giant"
          ghost
          (click)="siteKeyRef.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          nbButton
          size="giant"
          [disabled]=""
          fullWidth
          (click)="siteKeyRef.close(); updateSiteKeys()"
        >
          Save
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>

<!-- Manager Notes POPOVER / DIALOG -->
<ng-template #requestManagerRef let-data let-requestManagerRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center"> Manager Notes</nb-card-header>

    <!-- Request Detail Section start-->
    <div class="row">
      <div class="col">
        <textarea
          class="form-control"
          placeholder="Manager Notes"
          cols="20"
          rows="5"
          wrap="hard"
          fullWidth
          type="text"
          [(ngModel)]="newInfromation"
        ></textarea>
      </div>
    </div>

    <div class="row row-cols-2 sc-mt-6">
      <div class="col">
        <button
          nbButton
          fullWidth
          size="giant"
          ghost
          (click)="requestManagerRef.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          nbButton
          size="giant"
          fullWidth
          (click)="requestManagerRef.close(); updateSiteManagerNotes()"
        >
          Update
        </button>
      </div>
    </div>

    <!-- Request Detail Section end-->
  </nb-card>
</ng-template>
