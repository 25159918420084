import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { JobsService } from 'src/app/pages/jobs/jobs.service';
import { PagesService } from 'src/app/pages/pages.service';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { DataCheckService } from '../../service/data-check.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-multiple-scubcribers',
  templateUrl: './multiple-scubcribers.component.html',
  styleUrls: ['./multiple-scubcribers.component.scss'],
})
export class MultipleScubcribersComponent implements OnInit {
  userLinkedSubscribers: any;

  constructor(
    protected dialogRef: NbDialogRef<MultipleScubcribersComponent>,
    private appService: AppService,
    private spinnerService: NgxSpinnerService,
    private toasterService: NbToastrService,
    private profileService: ProfileService,
    private dialog: NbDialogService,
    private jobService: JobsService,
    private pageService: PagesService,
    private dataCheckService: DataCheckService,
    private route: Router
  ) {
    this.getAssociatedCompanies();
  }

  ngOnInit(): void {}
  getAssociatedCompanies() {
    this.spinnerService.show();
    this.profileService
      .associatedCompanies({}, { get_companies: 1 })
      .subscribe((res: any) => {
        if (res['status'] === 'success') {
          this.spinnerService.hide();
          this.userLinkedSubscribers = res['data'];
        }
      });
  }
  onCloseDialogue() {
    this.dialogRef.close('close');
  }
  selectSubscriber(subscriber: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      context: {
        // confirmation: true,
        title: `Change Account`,
        // message: `Changing subscriber means all the actions performed after this will be associated to  ${subscriber?.name} `,
      },
    });
    dialogRef.onClose.subscribe(async (value) => {
      if (value === 'Yes') {
        this.spinnerService.show();
        let userData = this.appService.getUserData();
        if (this.dataCheckService.isGuardUserClockendIn()) {
          await this.clockOutUser();
        }
        const changeAccountRes: any = await this.changeAccount(
          subscriber.id,
          userData?.token_expiry
        );
        if (changeAccountRes['status'] === 'success') {
          localStorage.clear();
          delete changeAccountRes['message'];
          changeAccountRes.checks.fido_login = userData?.checks?.fido_login
            ? true
            : false;
          this.appService.setUserData(changeAccountRes);

          // Call the three nearest APIs
          await this.callNearestApis();
        }
      }
    });
  }
  clockOutUser() {
    this.spinnerService.hide();
    let params = {};
    params = {
      clock_out: 1,
    };
    this.spinnerService.show();
    this.profileService.guardClockedInOut(params).subscribe((response: any) => {
      if (response?.status == 'success') {
        // this.spinnerService.hide();
        this.pageService.setMessage({
          successMessage: response?.message,
          errorMessage: '',
        });
        let userData = this.appService.getUserData();
        userData.user_token = response?.user_token;
        this.appService.setUserData(userData);
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response?.message,
        });
        this.spinnerService.hide();
      }
    });
  }
  async changeAccount(subscriberId: number, tokenExpiry: any) {
    return new Promise((resolve, reject) => {
      this.profileService
        .associatedCompanies({
          subscriber_id: subscriberId,
          remember_login: tokenExpiry ? 1 : 0,
        })
        .subscribe(
          (res: any) => resolve(res),
          (err) => reject(err)
        );
    });
  }
  callNearestApis() {
    let promises = [
      this.getNearestCheckpointList(),
      this.getNearestSiteList(),
      this.getNearestJobs(),
    ];
    Promise.all(promises).then(() => {
      this.spinnerService.show();
      this.dialogRef.close('close');
      this.route.navigate(['/dashboard']).then(()=> window.location.reload());
      this.spinnerService.hide();
    });
  }

  getNearestCheckpointList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.jobService.getNearestCheckpointList().subscribe(
        (response: any) => resolve(response),
        (error: any) => reject(error)
      );
    });
  }

  getNearestSiteList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.jobService.getNearesSiteList().subscribe(
        (response: any) => resolve(response),
        (error: any) => reject(error)
      );
    });
  }

  getNearestJobs(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.jobService
        .getNearestJobs({ time_offset: new Date().getTimezoneOffset() })
        .subscribe(
          (response: any) => resolve(response),
          (error: any) => reject(error)
        );
    });
  }
}
