<nb-card class="dialog-box">
  <nb-card-header>
    {{ incidentTypeData?.id ? "Edit Incident Type" : "Add New Incident Type" }}
    <nb-icon
      (click)="onCloseDialogue('close')"
      [icon]="'close-outline'"
      class="sc-floating-right close-icon"
      pack="eva"
    ></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="incidentTypeForm" appFormEnterAction>
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          nbInput
          id="incidentTypeName"
          formControlName="name"
          autocomplete="off"
          placeholder="Incident Type"
          autofocus
          fullWidth
          maxlength="256"
        />
        <label for="incidentTypeName">Incident Type</label>
      </div>

      <div class="row row-cols-2">
        <div class="col">
          <button
            (click)="onCloseDialogue('close')"
            nbButton
            size="giant"
            fullWidth
            ghost
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            id="next1btn"
            type="button"
            (click)="updateData()"
            [disabled]="!incidentTypeForm.valid"
            nbButton
            size="giant"
            fullWidth
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
