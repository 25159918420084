import { Location, formatDate } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  NbDialogService,
  NbStepperComponent,
  NbToastrService,
} from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/auth/auth.service';
import { CustomerService } from 'src/app/pages/customer/customer.service';
import { PagesService } from 'src/app/pages/pages.service';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { AddCustomerComponent } from 'src/app/shared/comp/add-customer/add-customer.component';
import { AddEditLicenseDetailsComponent } from 'src/app/shared/comp/add-edit-license-details/add-edit-license-details.component';
import { AddEditSiteComponent } from 'src/app/shared/comp/add-edit-site/add-edit-site.component';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { ContactComponent } from 'src/app/shared/comp/contact/contact.component';
import { SchedulerComponent } from 'src/app/shared/comp/scheduler/scheduler.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { convertTime12to24 } from 'src/global.variables';
import { JobsService } from '../../jobs.service';

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.scss'],
})
export class OtherComponent implements OnInit {
  @Input() selectedStatus: any;
  @ViewChild('licenceCheckTemplateRef') licenceCheckTemplateRef: any;
  formdata = new UntypedFormGroup({
    clientName: new UntypedFormControl('', Validators.required),
    siteName: new UntypedFormControl('', Validators.required),
    siteContactName: new UntypedFormControl(''),
    monitoringCompanyName: new UntypedFormControl(''),
    responseTypeName: new UntypedFormControl(''),
    assigneeName: new UntypedFormControl(),
    requestDetail: new UntypedFormControl(),
  });
  @ViewChild('stepper') stepper: any = NbStepperComponent;
  @ViewChild('stepper1') stepper1: any = NbStepperComponent;
  contactForm: UntypedFormGroup = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    title: new UntypedFormControl(),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    mobile_number: new UntypedFormControl('', [
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9]*$'),
    ]),
  });
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;
  siteLicenceReminderCheck: boolean = false;
  licenseReminderField: boolean = false;
  showSiteMap: boolean = false;
  addJobPressed: boolean = false;
  scheduleStepperActive: boolean = false;
  otherJobType: any = {
    jobType: {
      name: 'job Type',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    client: {
      name: 'Client',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    site: { name: 'Site', value: '', edit: false, data: null, tempValue: '' },
    assignee: {
      name: 'Assignee',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    ai: {
      name: 'Additional Information',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    oc: {
      name: 'On Site Contact',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    schedule: {
      name: 'Schedule',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
  };

  clientList: any = [];
  siteList: any = [];
  siteContacts: any = [];
  assigneeList: any = [];
  companyLicenseList: any = [];
  dialogRef: any;
  userData: any;
  intervalData = [{ name: 'No Welfare Checks', value: 0 }];
  intervalCheck: any;
  schedulerForm: UntypedFormGroup = new UntypedFormGroup({
    end_time: new FormControl(null),
    start_time: new FormControl(null),
    start_day: new FormControl(new Date()),
    end_day: new FormControl(null, Validators.required),

    repeat_times: new UntypedFormControl(null),
    repeat_type: new UntypedFormControl(null, Validators.required),
    repeat_details: new UntypedFormControl([]),
    repeat_interval: new UntypedFormControl(1, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    position: new UntypedFormControl(null, []),
    week_day: new UntypedFormControl(null, []),
    month_repeat_case: new UntypedFormControl(null, []),
  });
  addSchedule: boolean = false;
  constructor(
    private toastrService: NbToastrService,
    private _location: Location,
    private jobService: JobsService,
    private profileService: ProfileService,
    private dialogService: NbDialogService,
    private customerService: CustomerService,
    private authService: AuthService,
    private pageService: PagesService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private spinnerService: NgxSpinnerService
  ) {
    this.userData = this.authService.getUserData();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.siteLicenceReminderCheck = this.userData?.checks?.license_reminder;

    router.events.subscribe((event: any) => {
      // close dialog boxes if any are open when back is pressed
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });
  }

  ngOnInit(): void {
    this.otherJobType.jobType.value = this.selectedStatus?.name;
    this.otherJobType.jobType.data = this.selectedStatus;

    this.editOtherJobTypeItem('client');
    this.getAssignees();
  }
  clearValues(_field: string) {
    if (_field === 'client') {
      this.otherJobType.client = {
        ...this.otherJobType.client,
        ...{ value: '', data: null, tempValue: '' },
      };
      this.otherJobType.site = {
        ...this.otherJobType.site,
        ...{ value: '', data: null, tempValue: '' },
      };
      this.otherJobType.oc = {
        ...this.otherJobType.oc,
        ...{ value: '', data: null, tempValue: '' },
      };
    } else if (_field == 'site') {
      this.otherJobType.site = {
        ...this.otherJobType.site,
        ...{ value: '', data: null, tempValue: '' },
      };
      this.otherJobType.oc = {
        ...this.otherJobType.oc,
        ...{ value: '', data: null, tempValue: '' },
      };
    } else if (_field == 'oc') {
      this.otherJobType.oc = {
        ...this.otherJobType.oc,
        ...{ value: '', data: null, tempValue: '' },
      };
    }
  }
  onClientSearch(event: any) {
    // in case add client auto fill the form
    this.clearValues('client');
    this.otherJobType.client.tempValue = event.target.value;
    if (event.target.value?.length > 2) {
      this.jobService
        .getSearchClients(event.target.value)
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.clientList = res['data'];
          }
        });
    }
  }
  onClientSelect(data: any) {
    if (data === ' ') {
      this.openAddCustomerForm();
    }
    if (data?.id) {
      this.clearValues('site');
      this.otherJobType.client.value = data?.company_name;
      this.otherJobType.client.data = data;
      this.formdata.controls['clientName'].setValue(data?.company_name);
      this.editOtherJobTypeItem('site');
      this.stepper.next();
      this.stepper1.next();
      this.customerService
        .getSites({ company: data?.id, is_active: 1 })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.siteList = res['data'];
          }
        });
    }
  }
  openAddCustomerForm() {
    this.dialogRef = this.dialogService.open(AddCustomerComponent, {
      context: { customerName: this.otherJobType.client.tempValue },
      dialogClass: 'model-full',
    });

    this.dialogRef.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.onClientSelect(value?.data);
        // this.value = value?.data?.company_name;
      }
    });
  }

  onSiteSearch(event: any) {
    // in case add client auto fill the form
    this.clearValues('site');
    this.otherJobType.site.tempValue = event.target.value;
    if (event.target.value?.length > 2) {
      this.customerService
        .getSites({
          company: this.otherJobType.client.data?.id,
          search_str: event.target.value,
          is_active: 1,
        })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.siteList = res['data'];
          }
        });
    }
  }
  doSiteLicenseCheck() {
    if (
      !this.companyLicenseList.some(
        (i: any) => i === this.otherJobType.site?.data?.address?.state_code
      )
    ) {
      this.dialogRef = this.dialogService.open(this.licenceCheckTemplateRef, {
        context: {},
        closeOnEsc: true,
      });
      this.dialogRef.onClose.subscribe((value: any) => {
        this.editOtherJobTypeItem('assignee');
      });
    }
  }
  ignoreLicenseCheck() {
    this.siteLicenceReminderCheck = false;
    this.editOtherJobTypeItem('assignee');
    if (this.licenseReminderField === true) {
      let params = { stop_notification: 1 };
      this.customerService
        .updateCompanyDetails({}, params)
        .subscribe((response: any) => {
          this.toastrService.show(response['message']);
          this.userData['checks']['license_reminder'] = false;
          this.authService.setUserData(this.userData);
        });
    }
  }
  async onSelectSite(data: any) {
    if (data === ' ') {
      this.openAddSiteForm();
    }

    if (data?.id) {
      this.clearValues('oc');
      this.showSiteMap = true;
      this.otherJobType.site.value = data?.company_name;
      this.otherJobType.site.data = data;
      this.formdata.controls['siteName'].setValue(data?.company_name);
      this.getSiteContacts(data.id);
      this.assigneeList = await this.getGuardDetails(this.assigneeList);
      if (this.siteLicenceReminderCheck && this.isAdmin == true) {
        this.doSiteLicenseCheck();
      } else {
        this.editOtherJobTypeItem('assignee');
      }
      this.stepper.next();
      this.stepper1.next();
    }
  }
  openAddSiteForm() {
    if (this.otherJobType.client.data) {
      this.dialogRef = this.dialogService.open(AddEditSiteComponent, {
        context: {
          detailData: { company: this.otherJobType.client.data?.id },
          siteAddress: this.otherJobType.site.tempValue,
        },
        dialogClass: 'model-full',
      });
      this.dialogRef.onClose.subscribe((value: any) => {
        if (value !== 'close') {
          this.onSelectSite(value);
        }
      });
    } else {
      this.pageService.setMessage({
        errorMessage: 'Please select a client first',
        successMessage: '',
      });
    }
  }
  getSiteContacts(id: any) {
    this.customerService.getSiteContacts(id).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.siteContacts = response['data'];
      } else {
        this.pageService.setMessage({
          errorMessage: 'ERROR MESSAGE',
          successMessage: '',
        });
      }
    });
  }
  selectContact(contact: any) {
    if (contact == ' ') {
      this.addNewContactForm();
    }
    if (contact?.id) {
      this.otherJobType.oc.value = contact?.full_name;
      this.otherJobType.oc.data = contact;
      this.formdata.controls['siteContactName'].setValue(contact?.full_name);
    }
  }
  addNewContactForm() {
    if (this.otherJobType.site.data) {
      let siteData: any = this.otherJobType.site.data;
      siteData['contacts'] = this.siteContacts;
      this.dialogRef = this.dialogService.open(ContactComponent, {
        context: {
          contactForm: this.contactForm,
          siteData: siteData,
          addNewSiteContact: true,
          contact_type: 'lc',
        },
        dialogClass: 'model-full',
      });
      this.dialogRef.onClose.subscribe((value: any) => {
        if (value !== 'false') {
          if (value && value?.length) {
            this.siteContacts = value;

            let lastAddedContact =
              this.siteContacts[this.siteContacts?.length - 1];
            if (lastAddedContact) this.selectContact(lastAddedContact);
          }
        }
      });
    }
  }
  validLicenseCheck(guardLicenses: any) {
    return guardLicenses.some(
      (license: any) =>
        license.issuer_state ===
        this.otherJobType.site?.data?.address?.state_code
    );
  }
  async getGuardDetails(guardsData: any) {
    return new Promise((resolve) => {
      guardsData?.forEach((guard: any) => {
        guard['validStateLicense'] = this.validLicenseCheck(guard?.license);
      });

      resolve(guardsData);
    });
  }
  onAssigneeSearch(event: any) {
    if (event.target.value?.length > 2) {
      this.getAssignees({ full_name: event?.target?.value });
    }
  }
  getAssignees(params?: any) {
    this.jobService.getAssignees(params).subscribe(async (response: any) => {
      if (response['status'] == 'success') {
        this.assigneeList = await this.getGuardDetails(response['data']);
      }
    });
  }
  onAssigneeSelect(assignee: any) {
    if (assignee?.id) {
      this.otherJobType.assignee.value = assignee?.full_name;
      this.otherJobType.assignee.data = assignee;
      this.formdata.controls['assigneeName'].setValue(assignee?.full_name);
      if (assignee?.validStateLicense) {
        this.editOtherJobTypeItem('ai');
      } else {
        let dialogMsg;
        if (this.userData.profile.email === assignee.email) {
          dialogMsg =
            'You have not added a security licence for this location on your profile, are you sure you want to take this job?';
        } else {
          dialogMsg = `${
            assignee.full_name || assignee.email
          } does not hold a security licence for this location, assign to ${
            assignee.full_name || assignee.email
          } anyway?`;
        }
        this.dialogRef = this.dialogService.open(ConfirmDialogComponent, {
          context: {
            title: 'Job Assignment',
            message: dialogMsg,
          },
        });
        this.dialogRef.onClose.subscribe((value: any) => {
          if (value === 'Yes') {
            this.otherJobType.assignee.value = assignee?.full_name;
            this.otherJobType.assignee.data = assignee;
            this.formdata.controls['assigneeName'].setValue(
              assignee?.full_name
            );

            if (window.innerWidth > 769) {
              var element2 = <HTMLInputElement>(
                document.getElementById(`deskassigneeField`)
              );
              element2?.blur();
              this.stepper.next();
              this.stepper1.next();
            } else {
              var element = <HTMLInputElement>(
                document.getElementById(`mobassigneeField`)
              );

              element?.blur();
              this.stepper.next();
              this.stepper1.next();
            }
          } else {
            this.otherJobType.assignee.value = '';
            this.otherJobType.assignee.data = null;
            this.formdata.controls['assigneeName'].setValue(null);
          }
        });
      }
    }
  }
  onAddJob() {
    this.addJobPressed = true;
    this.spinnerService.show();
    let data: any = {
      job_type_id: this.selectedStatus.id,
      company_id: this.otherJobType?.client?.data?.id,
      location: [this.otherJobType?.site?.data?.id],
      is_abandoned: false,
      request: this.formdata.value.requestDetail,
      new_assignee: this.otherJobType?.assignee?.data?.id,
      location_contact_id: this.otherJobType?.oc?.data?.id,
    };
    let params: any = {};
    if (this.addSchedule) {
      data['interval_check'] = this.intervalCheck;

      this.schedulerForm.value.start_time = convertTime12to24(
        this.schedulerForm.value.start_time
      );
      this.schedulerForm.value.end_time = convertTime12to24(
        this.schedulerForm.value.end_time
      );
      this.schedulerForm.value.start_day =
        this.schedulerForm.value.start_day == ''
          ? ''
          : formatDate(this.schedulerForm.value.start_day, 'yyyy-MM-dd', 'en');
      this.schedulerForm.value.end_day =
        this.schedulerForm.value.end_day == null
          ? null
          : formatDate(this.schedulerForm.value.end_day, 'yyyy-MM-dd', 'en');
      let timezoneOffset = new Date();
      params.save_schedule = 1;
      data = {
        time_offset: timezoneOffset.getTimezoneOffset(),
        ...this.schedulerForm.value,
        ...data,
      };
    }
    this.jobService.createJob(data, params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.pageService.setMessage({
          errorMessage: '',
          successMessage: 'Job Dispatched',
        });
        this.router.navigate(['/roster']);
      } else {
        this.addJobPressed = false;
        this.pageService.setMessage({
          errorMessage: res['message'],
          successMessage: '',
        });
      }

      this.spinnerService.hide();
    });
  }

  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);

      element?.focus();
    }, 100);
  }
  editOtherJobTypeItem(field: string) {
    for (let key in this.otherJobType) {
      this.otherJobType[key]['edit'] = false;
    }
    // this.otherJobType[field]['edit'] = true;
    if (window.innerWidth > 769) {
      this.shiftFocus(`desk${field}Field`);
    } else {
      this.shiftFocus(`mob${field}Field`);
    }
  }
  openTemplate(template: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(template, {
      context: {},
    });
  }
  getCompanyLicenses() {
    let params = { license_check: 1 };
    this.profileService
      .fetchCompanyDetails(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.userData['checks']['license_reminder'] =
            response?.data?.license_reminder;
          this.siteLicenceReminderCheck = response?.data?.license_reminder;
          this.authService.setUserData(this.userData);
          this.companyLicenseList = response?.data?.license.map(
            (i: any) => i?.issuer_state
          );
        }
      });
  }
  showLicensePopup() {
    this.dialogRef = this.dialogService.open(AddEditLicenseDetailsComponent, {
      context: {
        licenseState: this.otherJobType?.site?.data?.address?.state_code,
        companyLicense: true,
      },
      dialogClass: 'model-full',
    });
    this.dialogRef.onClose.subscribe((value: any) => {
      if (value != 'close') {
        this.companyLicenseList = value;
        this.dialogRef.close();
      }
    });
  }
  addAI() {
    this.formdata.controls['requestDetail'].setValue(
      this.otherJobType.ai.value
    );
  }
  openScheduleComponent() {
    if (!this.otherJobType.schedule.data) this.formatScheduleForm();
    this.dialogRef = this.dialogService.open(SchedulerComponent, {
      context: { schedulerForm: this.schedulerForm, editSchedule: true },
    });
    this.dialogRef.onClose.subscribe((value: any) => {
      if (value) {
        this.addSchedule = true;
        this.otherJobType.schedule.data = value;
        this.schedulerForm.patchValue(value);
      }
    });
  }
  formatScheduleForm() {
    this.scheduleStepperActive = true;
    this.addSchedule = true;
    let passedDate = sessionStorage.getItem('selectedRosterDate') || new Date();

    let startDateTime = passedDate ? new Date(passedDate) : new Date();
    let currentTime = new Date();

    startDateTime.setHours(
      currentTime.getHours(),
      currentTime.getMinutes(),
      currentTime.getSeconds(),
      currentTime.getMilliseconds()
    );

    this.schedulerForm.controls['repeat_type'].setValue('once');
    this.schedulerForm.controls['end_day'].setValue(startDateTime);
    this.schedulerForm.controls['end_day'].updateValueAndValidity();
    this.schedulerForm.controls['end_day'].clearAsyncValidators();
    this.schedulerForm.controls['start_day'].setValue(startDateTime);
    this.schedulerForm.controls['start_time'].setValue(
      this.getEndTime(startDateTime, passedDate)
    );
    let endDateTime = startDateTime.setHours(startDateTime.getHours() + 1);

    this.schedulerForm.controls['end_time'].setValue(
      this.getEndTime(endDateTime, passedDate)
    );
    sessionStorage.removeItem('selectedRosterDate');
  }

  getEndTime(startDateTime?: any, passedDate?: any) {
    let date = new Date();
    if (startDateTime) {
      date = new Date(startDateTime);
    }

    let hours = date.getHours() + 1;

    if (hours > 23) {
      let nextDay = passedDate ? new Date(passedDate) : new Date();
      nextDay.setDate(nextDay.getDate() + 1);
      this.schedulerForm.controls['end_day'].setValue(nextDay);
      hours = 0;
    }

    let minutes = date.getMinutes();

    // Pad leading zero
    let minutesString = minutes < 10 ? '0' + minutes : '' + minutes;

    return `${hours}:${minutesString}`;
  }

  updateInterval(interval: any) {
    this.intervalCheck = interval.value;
  }
  isScheduleFormValid() {
    this.schedulerForm.controls['end_day'].updateValueAndValidity();
    this.schedulerForm.controls['end_day'].clearAsyncValidators();
    return true;
  }
  calculateTimeDifference(
    startTime: string,
    endTime: string,
    startDate?: Date,
    endDate?: Date
  ) {
    var startDateTime = startDate ? new Date(startDate) : new Date();
    var endDateTime = endDate ? new Date(endDate) : new Date();

    var [startHours, startMinutes] = startTime.split(':').map(Number);
    var [endHours, endMinutes] = endTime.split(':').map(Number);

    startDateTime.setHours(startHours, startMinutes);
    endDateTime.setHours(endHours, endMinutes);

    var timeDifference = endDateTime.getTime() - startDateTime.getTime();

    // Convert milliseconds to hours
    var hoursDifference = timeDifference / (1000 * 60 * 60);

    return hoursDifference;
  }
  getIntervalData() {
    let diffValue: any;
    const scheduleData: any = JSON.parse(
      JSON.stringify(this.schedulerForm.value)
    );
    if (scheduleData?.repeat_type === 'once') {
      diffValue = this.calculateTimeDifference(
        convertTime12to24(scheduleData.start_time),
        convertTime12to24(scheduleData.end_time),
        scheduleData.start_day,
        scheduleData.end_day
      );
    } else {
      diffValue = this.calculateTimeDifference(
        convertTime12to24(scheduleData.start_time),
        convertTime12to24(scheduleData.end_time)
      );
    }
    this.intervalData = [
      { name: 'No Welfare Checks', value: 0 },
      { name: 'Welfare Checks Every 5 mins', value: 5 },
      { name: 'Welfare Checks Every 15 mins', value: 15 },
      { name: 'Welfare Checks Every 30 mins', value: 30 },
    ];
    if (diffValue > 1) {
      this.intervalData.push({
        name: 'Welfare Checks Every 1 Hour',
        value: 60,
      });
    }
    if (diffValue > 2) {
      this.intervalData.push({
        name: 'Welfare Checks Every 2 Hours',
        value: 120,
      });
    }
  }
}
