import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';
import { DataCheckService } from '../service/data-check.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private appService: AppService,
    private dataCheckService: DataCheckService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    var userLoggedIn = this.appService.isLoggedIn();
    let isSuperUser = this.dataCheckService.isSuperUser();
    if (userLoggedIn) {
      if (isSuperUser === true) {
        this.router.navigate(['gtadmin/dashboard']);
      } else {
        this.router.navigate(['/dashboard']);
      }
    }
    if (next.url[0].path == 'activate') {
      return true;
    }

    return true;
  }
}
