import { Component, NgZone, OnDestroy, OnInit, Optional } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { PagesService } from 'src/app/pages/pages.service';
import { SubscriptionService } from 'src/app/pages/subscription/subscription.service';
import { v4 as uuidv4 } from 'uuid';
import { DynamicScriptLoadService } from '../../service/dynamic-script-load.service';
declare var Square: any;
@Component({
  selector: 'app-add-payment-card',
  templateUrl: './add-payment-card.component.html',
  styleUrls: ['./add-payment-card.component.scss'],
})
export class AddPaymentCardComponent implements OnInit, OnDestroy {
  showSqForm: boolean = false;
  cardHolderName: any;
  isPWAApp: boolean = Boolean(
    window.matchMedia('(display-mode: standalone)').matches
  );

  constructor(
    @Optional() protected dialogRef: NbDialogRef<AddPaymentCardComponent>,
    private spinnerService: NgxSpinnerService,
    private subscriptionService: SubscriptionService,
    private scriptLoader: DynamicScriptLoadService,
    private pageService: PagesService,
    private ngZone: NgZone
  ) {}
  ngOnDestroy(): void {
    this.showSqForm = false;
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.scriptLoader
      .load('square-script')
      .then((data) => {
        setTimeout(() => {
          this.addCard();
        }, 1000);
      })
      .catch((error) => console.error(error));
  }
  onCloseDialogue(data: any = 'close') {
    this.dialogRef.close(data);
  }
  async addCard() {
    this.subscriptionService
      .getPaymentProviderConfigs()
      .subscribe(async (response: any) => {
        if (response['status'] == 'success') {
          var cardPreData = {};
          this.ngZone.run(() => {
            // Show spinner immediately
            this.showSqForm = true;
          });

          cardPreData = {
            postalCode: '123456',
          };

          const paymentProcessorConfig = response['data'];
          const payments = Square.payments(
            paymentProcessorConfig.app_id,
            paymentProcessorConfig.location_id
          );

          const card = await payments.card(cardPreData);

          setTimeout(() => {
            card.attach('#card-container');
            this.spinnerService.hide();
          }, 100);

          const button = document.getElementById('pay-btn');
          button?.addEventListener('click', (e) => {
            this.spinnerService.show();
            e.preventDefault();

            card
              .tokenize()
              .then((result: any) => {
                if (result.token) {
                  this.subscriptionService
                    .addNewPaymnetCard({
                      source: result.token,
                      card_holder_name: this.cardHolderName,
                      idempotency_key: uuidv4(),
                    })
                    .subscribe(async (response: any) => {
                      if (response?.status == 'success') {
                        this.spinnerService.hide();
                        this.pageService.setMessage({
                          successMessage: response?.message,
                          errorMessage: '',
                        });
                        this.onCloseDialogue();
                      } else {
                        this.pageService.setMessage({
                          successMessage: '',
                          errorMessage: response?.message,
                        });
                        this.spinnerService.hide();
                      }
                    });
                } else {
                  this.spinnerService.hide();
                }
              })
              .catch((error: any) => {
                // Handle tokenization error here
                console.error('Error tokenizing card:', error);
                this.spinnerService.hide();
              });
          });
        }
      });
  }
}
