import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-smart-net',
  templateUrl: './smart-net.component.html',
  styleUrls: ['./smart-net.component.scss'],
})
export class SmartNetComponent implements OnInit {
  smartNetJobList: any = [];
  smartNetJobListTableDetail = [
    {
      header: 'JobID',
      name: 'JobID',
    },
    {
      header: 'CreateDateTime',
      name: 'CreateDateTime',
      datetimeObj: true,
    },
    {
      header: 'Request',
      name: 'Request',
    },
    {
      header: 'JobNumber',
      name: 'JobNumber',
    },
    {
      header: 'Priority',
      name: 'Priority',
    },
    {
      header: 'OrderNumber',
      name: 'OrderNumber',
    },
    {
      header: 'serviceType',
      nestedValue: (row: any) => {
        return row?.serviceType?.description;
      },
    },
    {
      header: 'JobViewID',
      name: 'JobViewID',
    },
    {
      header: 'Status',
      name: 'Status',
    },
    {
      header: 'location',
      nestedValue: (row: any) => {
        return row?.location?.name;
      },
    },
    {
      header: 'provider',
      nestedValue: (row: any) => {
        return row?.provider?.name;
      },
    },
    {
      header: 'bill_to',
      nestedValue: (row: any) => {
        return row?.bill_to?.id;
      },
    },
    {
      header: 'requester',
      nestedValue: (row: any) => {
        return row?.requester?.name;
      },
    },
  ];

  eventDetail: any;

  smartNetCompanyList: any = [];
  smartNetCompanyListTableDetail = [
    {
      header: 'id',
      name: 'id',
    },
    {
      header: 'CreateDateTime',
      name: 'CreateDateTime',
      datetimeObj: true,
    },
    {
      header: 'TimeZoneID',
      name: 'TimeZoneID',
    },
    {
      header: 'SystemUserID',
      name: 'SystemUserID',
    },
    {
      header: 'Suburb',
      name: 'Suburb',
    },
    {
      header: 'SuCode',
      name: 'SuCode',
    },
    {
      header: 'CompanyCode',
      name: 'CompanyCode',
    },
    {
      header: 'CompanyID',
      name: 'CompanyID',
    },
    {
      header: 'CompanyName',
      name: 'CompanyName',
    },
    {
      header: 'CompanyRoleID',
      name: 'CompanyRoleID',
    },
    {
      header: 'CountryName',
      name: 'CountryName',
    },
    {
      header: 'Inactive',
      name: 'Inactive',
    },
    {
      header: 'IsBillTo',
      name: 'IsBillTo',
    },
    {
      header: 'IsLocation',
      name: 'IsLocation',
    },
    {
      header: 'IsProvider',
      name: 'IsProvider',
    },
    {
      header: 'IsRequester',
      name: 'IsRequester',
    },
    {
      header: 'PostCode',
      name: 'PostCode',
    },
    {
      header: 'State',
      name: 'State',
    },
    {
      header: 'StreetName',
      name: 'StreetName',
    },
    {
      header: 'StreetNumber',
      name: 'StreetNumber',
    },
    {
      header: 'Suburb',
      name: 'Suburb',
    },
  ];
  localServer: boolean = true;
  smarNetConnectForm = new UntypedFormGroup({
    username: new UntypedFormControl('provider1', Validators.required),
    smart_net_id: new UntypedFormControl(195, Validators.required),
    password: new UntypedFormControl('provider1', Validators.required),
  });
  constructor(
    private adminService: AdminService,
    private dialogService: NbDialogService
  ) {}

  ngOnInit(): void {}
  getSmartNetJobList() {
    let params: any = { request_data: 'job' };
    if (this.localServer) {
      params.docker = 1;
    }
    this.adminService
      .getSmartNetData({ ...this.smarNetConnectForm.value }, params)
      .subscribe((resp: any) => {
        this.smartNetJobList = resp?.data;
      });
  }
  getSmartNetCompanyList() {
    let params: any = { request_data: 'company' };
    if (this.localServer) {
      params.docker = 1;
    }
    this.adminService
      .getSmartNetData({ ...this.smarNetConnectForm.value }, params)
      .subscribe((resp: any) => {
        this.smartNetCompanyList = resp?.data;
      });
  }
  getData() {
    this.getSmartNetJobList();
    this.getSmartNetCompanyList();
  }
  onJobSelection(jobData: any, jobDetailRef: any) {
    let params: any = {
      request_data: 'job',
      job_id: jobData?.JobID,
      request_type: 'retrieve',
    };
    if (this.localServer) {
      params.docker = 1;
    }
    this.adminService
      .getSmartNetData({ ...this.smarNetConnectForm.value }, params)
      .subscribe((resp: any) => {
        this.eventDetail = resp?.data;
        this.dialogService.open(jobDetailRef);
      });
  }
  onCompanySelection(companyData: any, companyDetailRef: any) {
    let params: any = {
      request_data: 'company',
      company_id: companyData?.CompanyID,
      request_type: 'retrieve',
    };
    if (this.localServer) {
      params.docker = 1;
    }
    this.adminService
      .getSmartNetData({ ...this.smarNetConnectForm.value }, params)
      .subscribe((resp: any) => {
        this.eventDetail = resp?.data;
        this.dialogService.open(companyDetailRef);
      });
  }
  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  isObject(item: any): boolean {
    return item && typeof item === 'object' && item.constructor === Object;
  }
}
