import { Component } from '@angular/core';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  loaderTypeSelected: string = 'ball-clip-rotate';
  title = 'Guard Tracker';
  deviceInfo: any;
  loadingText = ' ';
  isDotLive: boolean = false;
  isPwa = Boolean(window.matchMedia('(display-mode: standalone)').matches);
  constructor(private appService: AppService) {
    // try {
    //   let hostName = window.location.hostname?.split('.');
    //   this.isDotLive = hostName[hostName?.length - 1] === 'live';
    // } catch (error) {}
    this.loaderTypeSelected = 'ball-clip-rotate';
    if (!localStorage.getItem('ipAddress')) {
      this.appService.setIp();
    }

    this.appService.returnLoadingTest().subscribe((msg: any) => {
      this.loadingText = msg;
    });
  }
  openInSystemBrowser(event: any) {
    event.preventDefault();
    var url = event.target.href;
    window.open(url, '_system');
    window.close();
    // href="https://app.guardtracker.com.au/"
    // target="_blank"
  }
}
