import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { AdminService } from '../../admin.service';
import { PagesService } from 'src/app/pages/pages.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-job-status',
  templateUrl: './job-status.component.html',
  styleUrls: ['./job-status.component.scss'],
})
export class JobStatusComponent implements OnInit {
  columns = [
    {
      header: 'Status Name',
      name: 'name',
    },
    {
      header: 'Description',
      name: 'description',
    },
    {
      header: 'Created On',
      name: 'created_date',
      datetimeObj: true,
    },
    { header: 'Edit', name: '', showAction: true },
  ];
  actionColumns = [
    {
      icon: 'edit-2-outline',
      status: 'primary',
      confirm: true,
      type: 'edit',
      show: 'inline',
      title: 'Enable Status',
    },
    {
      icon: 'eye-off-outline',
      status: 'success',
      confirm: true,
      title: 'Disable Status',
      type: 'Enable',
      show: 'inline',
      condition: function (row: any) {
        return row.is_active;
      },
    },

    {
      icon: 'eye-outline',
      status: 'warning',
      title: 'Disable Status.',
      confirm: true,
      type: 'Disable',
      show: 'inline',
      condition: function (row: any) {
        return !row.is_active;
      },
    },
  ];
  JobStatus: any;
  dialogref: any;
  response: any;
  editData: any;
  appStatusForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
    is_active: new UntypedFormControl(1),
    status_identity: new UntypedFormControl(0, Validators.required),
    sharing_status: new UntypedFormControl(0, Validators.required),
  });
  totalStatuss: any;
  previous: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;
  identityStatuses: any = [];
  identityStatusesDuplicate: any = [];
  sharedStatuses: any;
  statusStyle: any = { selected: {}, snubbed: {} };

  constructor(
    private adminService: AdminService,
    private dialog: NbDialogService,
    private pageService: PagesService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getAllStatusList();
  }
  getAllStatusList() {
    let params: any = {};
    if (this.rows) {
      params['page_size'] = this.rows;
    }
    if (this.previous) {
      params['page'] = this.pageNum;
    }
    this.spinnerService.show();
    this.adminService.getJobStatusList(params).subscribe((resp: any) => {
      {
        this.JobStatus = resp['results'];
        this.identityStatuses = resp['identity_statuses'];
        this.identityStatusesDuplicate = resp['identity_statuses'];
        this.sharedStatuses = resp['shared_statuses'];
        this.totalRows = resp?.count;
        this.totalPages = Math.ceil(this.totalRows / this.rows);
        this.spinnerService.hide();
      }
    });
  }
  openTemplate(template: TemplateRef<any>) {
    this.statusStyle = { selected: {}, snubbed: {} };
    this.identityStatuses = [...this.identityStatusesDuplicate];

    {
      this.dialogref = this.dialog.open(template, {
        context: {},
      });
    }
  }
  onCloseDialogue() {
    this.onClearForm();
    this.dialogref.close('close');
  }
  onClearForm() {
    this.statusStyle = { selected: {}, snubbed: {} };
    this.editData = null;
    this.appStatusForm.reset();
  }
  onClickSubmit() {
    if (this.appStatusForm.valid) {
      this.spinnerService.show();
      let requestData = this.appStatusForm.value;

      requestData['additional_info'] = {
        style: this.statusStyle,
      };
      if (this.editData) {
        this.adminService
          .editJobStatus(this.editData.id, requestData)
          .subscribe((response: any) => {
            if (response?.status == 'failure') {
              this.pageService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
            this.onCloseDialogue();
            this.getAllStatusList();
          });
      } else {
        this.adminService
          .createJobStatus(requestData)
          .subscribe((response: any) => {
            this.onCloseDialogue();
            this.getAllStatusList();
          });
      }
    }
  }
  patchValue(item: any) {
    this.appStatusForm.patchValue(item);
    this.appStatusForm.controls['status_identity'].setValue(
      item.status_identity.value
    );
    this.appStatusForm.controls['sharing_status'].setValue(
      item.sharing_status.value
    );
  }
  editAppStatus(item: any, template: TemplateRef<any>) {
    if (
      item?.additional_info?.style?.hasOwnProperty('snubbed') ||
      item?.additional_info?.style?.hasOwnProperty('selected')
    ) {
      this.statusStyle = item?.additional_info?.style;
    } else {
      this.statusStyle = { selected: {}, snubbed: {} };
    }

    this.identityStatuses = [...this.identityStatusesDuplicate];
    this.patchValue(item);
    if (
      !this.identityStatuses?.some(
        (value2: any) => item.status_identity.value === value2[0]
      )
    ) {
      this.identityStatuses.push([
        item.status_identity.value,
        item.status_identity.name,
      ]);
    }
    this.editData = item;

    {
      this.dialogref = this.dialog.open(template, {
        context: {},
      });
    }
  }
  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getAllStatusList();
  }

  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getAllStatusList();
  }
  actionClick(data: any, template: TemplateRef<any>) {
    if (data?.type == 'edit') {
      this.editAppStatus(data?.event, template);
    } else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        context: {
          title: 'Are you sure?',
          message: `${data?.type == 'Enable' ? 'Disable' : 'Enable'} ${
            data?.event?.name
          } status`,
        },
      });
      dialogRef.onClose.subscribe((value: any) => {
        if (value === 'Yes') {
          let requestData: any;
          requestData = { ...data };
          requestData?.type === 'Enable'
            ? (requestData.event.is_active = false)
            : (requestData.event.is_active = true);
          this.patchValue(requestData.event);
          this.adminService
            .editJobStatus(data?.event?.id, this.appStatusForm.value)
            .subscribe((response: any) => {
              this.getAllStatusList();
            });
        }
      });
    }
  }
}
