import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbGlobalLogicalPosition, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from '../../app.service';
import { ProfileService } from '../../pages/profile/profile.service';
import { UsersService } from '../../pages/users/users.service';
import { PagesService } from 'src/app/pages/pages.service';
import { DataCheckService } from '../service/data-check.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
  successMessage: any;
  showSuccessMsg: any;
  selectForm: UntypedFormGroup | undefined;
  nameForm: UntypedFormGroup | undefined;
  showErrorMsg: any;
  refreshToken: any;
  userPhoneForm: UntypedFormGroup = new UntypedFormGroup({
    last_4: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(4),
      Validators.minLength(4),
    ]),
  });
  userEmailForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });
  userForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    mobile: new UntypedFormControl(''),
    first_name: new UntypedFormControl(''),
    last_name: new UntypedFormControl(''),
  });
  guardLicenseForm: UntypedFormGroup = new UntypedFormGroup({
    issuer_state: new UntypedFormControl(null, [Validators.required]),
    license_class: new UntypedFormControl(null, [Validators.required]),
    license_number: new UntypedFormControl(null, [Validators.required]),
    valid_till: new UntypedFormControl(null, [Validators.required]),
  });
  usersBatchForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.email]),
    mobile: new UntypedFormControl(''),
    group: new UntypedFormControl(''),
  });
  showNewLicenceForm: any;
  selectedItem: any;
  classData: any[] = [];
  stateData: any;
  newlicense: any;
  linearMode: any;
  showLicenseFields: any;
  guardLicenseData: any;
  message: any;
  logicalPositions = NbGlobalLogicalPosition;
  showPhoneValidation: any;
  showLicenseAddClass: boolean = false;
  licenseClassData: any = [];
  userData: any;
  isSuperUser: any;
  constructor(
    private profileService: ProfileService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private toasterService: NbToastrService,
    private appService: AppService,
    private userService: UsersService,
    private router: Router,
    private pageService: PagesService,
    private dataCheckService: DataCheckService
  ) {
    this.classData = [];
    this.showLicenseFields = false;
    this.getStates();
    this.showSuccessMsg = false;
    this.showErrorMsg = false;
    this.showPhoneValidation = true;
  }

  ngOnInit(): void {
    this.userData = this.appService.getUserData();
    this.isSuperUser = this.dataCheckService.isSuperUser();
    this.userForm.controls['email'].setValue(this.userData.profile.email);
    this.spinnerService.show();
    var key = this.route.snapshot.paramMap.get('key');
    const position = this.logicalPositions.BOTTOM_END;

    this.spinnerService.hide();
  }
  addNewClass(newLicence: string) {
    if (this.guardLicenseForm.value['issuer_state']) {
      let data = {
        issuer_state: this.guardLicenseForm.value['issuer_state'],
        license_class_name: newLicence,
      };
      this.userService.addNewClass(data).subscribe((response: any) => {
        this.classData = [...this.classData, response['data']];
        this.showLicenseAddClass = false;
        this.guardLicenseForm.controls['license_class'].setValue(
          response['data'].license_class_name
        );
      });
    } else {
      this.toasterService.warning(
        'Warning',
        'Please select the state to add new licence guard.'
      );
    }
  }
  addNewLicence() {
    this.showNewLicenceForm = true;
    this.guardLicenseForm.reset();
  }
  getStates() {
    this.appService.getStates(1).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateData = response['data'];
      }
    });
  }
  toggleLinearMode() {
    this.linearMode = !this.linearMode;
  }
  selectState(data: any) {
    this.userService.getClassByState(data.id).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.classData = response['data'];
        this.licenseClassData = response['data'];
      } else {
      }
    });
  }
  checkPhoneValidation(event: any) {
    if (event?.target?.value && event?.target?.value?.length >= 4) {
      this.profileService
        .checkPhoneValidation(this.userPhoneForm.value)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.showPhoneValidation = false;
            let userData = this.appService.getUserData();
            if (userData?.profile?.first_name || userData?.profile?.last_name)
              if (this.isSuperUser === true) {
                this.router.navigate(['gtadmin/dashboard']);
              } else {
                this.router.navigate(['/dashboard']);
              }
          } else {
            this.showPhoneValidation = true;

            this.showErrorMsg = response['data'];
          }
        });
    }
  }
  refreshExpiredLink() {
    this.profileService
      .refreshAndSendRegistrationLink(
        this.userEmailForm.value,
        this.refreshToken
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.showErrorMsg = undefined;
          this.showSuccessMsg = true;
          this.successMessage = response['message'];
        } else {
          this.showErrorMsg = response['message'];
        }
      });
  }
  registerUser() {
    if (this.userForm.valid) {
      var requestData = { data: this.userForm.value, id: '' };
      if (this.guardLicenseForm.valid) {
        this.guardLicenseForm.value.valid_till = formatDate(
          this.guardLicenseForm.value.valid_till,
          'yyyy-MM-dd',
          'en'
        );
        requestData.data.license_data = this.guardLicenseForm.value;
      }
      this.profileService
        .addUserViaLinkProfile(requestData)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.pageService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
            this.guardLicenseForm.reset();
            this.profileService.fetchUserProfile().subscribe((respose: any) => {
              let userData = this.appService.getUserData();
              userData.profile.access = response['data'].access;
              userData.profile.first_name = response['data'].first_name;
              userData.profile.last_name = response['data'].last_name;
              this.appService.setUserData(userData);
              if (this.isSuperUser === true) {
                this.router.navigate(['gtadmin/dashboard']);
              } else {
                this.router.navigate(['/dashboard']);
              }
            });
          } else {
            this.pageService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
        });
    }
  }
  onLicenceClassSearch(event: any) {
    this.showLicenseAddClass = false;
    if (event?.target?.value?.length === 0) {
      this.licenseClassData = this.classData;
    } else {
      this.licenseClassData = this.classData.filter((item: any) =>
        item.license_class_name
          ?.toLowerCase()
          .includes(event?.target?.value?.toLowerCase())
      );
      if (!this.licenseClassData?.length) this.showLicenseAddClass = true;
    }
  }
}
