<div>
  <div class="gt-logo-mobile">
    <img
      src="../../../assets/images/Guard_Tracker-Stacked.png"
      alt="Guard Tracker"
    />
  </div>
</div>
<div class="sc-text-center mt-5">
  <h2>Temporarily Down for Maintenance</h2>
  <p>
    Guard Tracker is temporarily unavailable due to maintenance, we will be back
    online soon.
  </p>
  <p class="text-center">
    Retrying in <span class="text-danger">{{ countDownValue }}</span> seconds
    ...
  </p>
</div>
