<nb-card class="p-2 desktop-padding-margin col-xl-6 m-auto">
  <div class="gt-p-small row">
    <ng-container class="col-md-12" *ngIf="showForm">
      <!-- non paymnet related view, ie when any trial coupons is applied-->
      <ng-container *ngIf="trialCoupon && isCouponApplied">
        <!-- Display the applied coupon with a remove option -->
        <div class="mt-3">
          <h5 class="plan-name">
            {{ dataAfterCouponApplied.name }}
          </h5>
        </div>
        <div class="mb-2 mt-2">
          Coupon applied for a {{ dataAfterCouponApplied?.trial_days }} day
          trial
        </div>

        <div class="coupon-applied-container mb-2">
          <span>Coupon {{ couponCode }} Applied</span>
          <label class="remove-coupon-button" (click)="removeAppliedCoupon()">
            Remove
          </label>
        </div>

        <div class="row row-cols-2 sc-mt-6">
          <div class="col">
            <button
              nbButton
              fullWidth
              ghost
              status="basic"
              (click)="onCloseDialogue()"
              size="large"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              size="large"
              nbButton
              id="card-pay-btn"
              fullWidth
              [status]="'primary'"
              (click)="applyTrialCoupon()"
            >
              Continue
            </button>
          </div>
        </div>
      </ng-container>
      <!-- payment related view-->
      <ng-container *ngIf="!trialCoupon">
        <ng-container *ngIf="existingSlots">
          <!--add user slots case-->
          <div class="mb-2">
            <div class="">
              <div class="row">
                <div class="align-self-center col-lg-4 col-sm-12">
                  Add Additional User(s) <br />
                  ${{ selectedPlan?.user_cost.toFixed(2) }}
                  each per month
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="counter-buttons">
                    <button
                      status="primary"
                      outline
                      class="m-1"
                      nbButton
                      (click)="decrement()"
                      [disabled]="minAllottedSlots >= allottedSlots"
                    >
                      -
                    </button>
                    <span class="mx-3 strong">{{ allottedSlots }}</span>
                    <button
                      class="m-1"
                      status="primary"
                      outline
                      nbButton
                      (click)="increment()"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3 mt-1">
            <span> Total Users: </span>
            <!-- TO show the total added users :{{ totalAddedUsers }}/ -->
            <span>
              {{ existingSlots }} >>>
              <span class="sc-head5">
                {{ existingSlots + allottedSlots }}
              </span>
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="!existingSlots">
          <!-- periodic subscription case-->
          <div class="mb-2">
            <h5
              class="plan-name mb-2"
              [ngStyle]="
                dataAfterCouponApplied
                  ? { color: 'var(--color-success-500)' }
                  : {}
              "
            >
              {{ selectedPlan?.name }}
            </h5>

            <div class="mb-4">
              Includes {{ selectedPlan?.allowed_users }} Users
            </div>

            <div *ngIf="selectedPlan?.trial_days && isTrialApplicable">
              <span class="strong"> $0 </span>
              for the first {{ selectedPlan.trial_days }} days limited to
              {{ selectedPlan?.trial_users_allowed }} users
              <div class="my-1">then</div>
            </div>

            <div>
              <span>${{ (selectedPlan?.final_amount).toFixed(2) }}</span> per
              month
            </div>

            <ng-container *ngIf="selectedPlan?.plan_type == 2">
              <!-- transaction price payment plan-->
              <br />
              <span class="small"
                >{{ selectedPlan?.allowed_transactions }} transactions
                included</span
              >
              <br />
              <span class="small"
                >{{ getRoundOffValue(selectedPlan?.transaction_price) }}c per
                transaction thereafter</span
              >
            </ng-container>

            <ng-container *ngIf="selectedPlan?.plan_type == 1">
              <!-- user price payment plan-->
              <div>
                ${{
                  getRoundOffValue(selectedPlan?.user_cost, false).toFixed(2)
                }}
                per additional user
              </div>
              <!-- case where this is not the first subscription or there is no trial days in the plan or the user is beta-->

              <div
                class="mt-4"
                *ngIf="!selectedPlan?.trial_days || !isTrialApplicable"
              >
                <div>
                  <div class="row">
                    <div
                      class="align-self-center col-md-3 col-lg-2 col-xl-2 col-sm-12 mt-0"
                    >
                      Additional Users
                    </div>
                    <div class="col-lg-4 col-sm-9 mt-0">
                      <div class="counter-buttons">
                        <button
                          status="primary"
                          outline
                          class="m-1"
                          nbButton
                          (click)="decrement()"
                          [disabled]="minAllottedSlots >= allottedSlots"
                        >
                          -
                        </button>
                        <span class="mx-3 strong">{{
                          allottedSlots - selectedPlan?.allowed_users
                        }}</span>
                        <button
                          class="m-1"
                          status="primary"
                          outline
                          nbButton
                          (click)="increment()"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <ng-container>
                    <div class="mb-3">
                      <!-- Headers -->
                      <div class="row row-cols-4 mb-3">
                        <div class="col-6 px-1 strong"></div>
                        <div class="col-1 px-1 strong text-center">Qty</div>
                        <div class="col-3 px-2 strong text-end">Unit Price</div>
                        <div class="col-2 px-1 strong text-end">Sub Total</div>
                      </div>

                      <!-- Base Plan -->
                      <div class="row row-cols-4 mb-3">
                        <div class="col-6 px-1">
                          {{ selectedPlan?.name }} <br />(Includes
                          {{ selectedPlan?.allowed_users }} Users)
                        </div>
                        <div class="col-1 px-1 text-center">1</div>
                        <div class="col-3 px-1 text-end">
                          ${{ (selectedPlan?.final_amount).toFixed(2) }}
                        </div>
                        <div class="col-2 px-1 text-end">
                          ${{ (selectedPlan?.final_amount).toFixed(2) }}
                        </div>
                      </div>

                      <!-- Additional Guards -->
                      <div
                        class="row row-cols-4 mb-3"
                        *ngIf="allottedSlots - selectedPlan?.allowed_users > 0"
                      >
                        <div class="col-6 px-1">Additional User</div>
                        <div class="col-1 px-1 text-center">
                          {{ allottedSlots - selectedPlan?.allowed_users }}
                        </div>
                        <div class="col-3 px-1 text-end">
                          ${{ getUserCost().toFixed(2) }}
                        </div>
                        <div class="col-2 px-1 text-end">
                          ${{
                            (
                              getUserCost() *
                              (allottedSlots - selectedPlan?.allowed_users)
                            ).toFixed(2)
                          }}
                        </div>
                      </div>

                      <div class="strong mt-2 text-end">
                        {{ allottedSlots }} Users Total
                      </div>

                      <div class="strong mt-2 text-end">
                        ${{ getPaymentAmount() }} per month
                      </div>
                    </div>
                  </ng-container>

                  <!-- <div>{{ totalAddedUsers }}</div> -->
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <div *ngIf="showFullView()">
          <div class="row row-cols-2">
            <div
              *ngIf="!showSqForm && savedPaymentCards?.length > 0"
              class="col-8 col-sm-8 col-md-9 small-heading"
            >
              Select Card
            </div>
            <div class="row-cols-1 mx-auto">
              <button
                class="mb-0"
                nbButton
                fullWidth
                *ngIf="showSqForm && savedPaymentCards?.length > 0"
                (click)="showSqForm = false; paymentFormInitialized = false"
              >
                Saved Cards
              </button>
            </div>
          </div>

          <div *ngIf="savedPaymentCards?.length && !showSqForm" class="mt-1">
            <div class="row gy-3">
              <div class="card__container">
                <div
                  class="gt-payment-card pointer"
                  *ngFor="let savedCard of savedPaymentCards"
                >
                  <div
                    class="card__front card__part"
                    [ngClass]="
                      !savedCard?.is_primary ? 'pointer card__inactive' : ''
                    "
                    [ngClass]="{
                      active: selectedPlan.selectedCard?.id == savedCard.id
                    }"
                    (click)="checkCardExpiration(savedCard)"
                  >
                    <div class="row mb-4">
                      <div class="card__primary">
                        <span *ngIf="savedCard?.is_primary" class="small"
                          ><nb-icon
                            nbSuffix
                            status="success"
                            icon="checkmark-outline"
                            pack="eva"
                          >
                          </nb-icon
                          >PRIMARY</span
                        >
                      </div>
                      <div
                        class="card__front-logo"
                        style="display: flex; flex-direction: row-reverse"
                      >
                        {{ savedCard.brand }}
                      </div>
                    </div>

                    <div class="card_number">{{ savedCard.card_num }}</div>
                    <div class="card__space-75">
                      <span class="card__label">Card Holder Name</span>
                      <div class="card__info">{{ savedCard.holder_name }}</div>
                    </div>
                    <div class="card__space-25">
                      <span class="card__label">Expiry</span>
                      <div class="card__info">{{ savedCard.expiry }}</div>
                    </div>
                  </div>
                  <div class="delete-icon">
                    <nb-icon
                      (click)="deleteCard(savedCard)"
                      class="delete-icon-color"
                      pack="eva"
                      [icon]="'trash-2-outline'"
                    ></nb-icon>
                  </div>
                </div>
                <div class="card__btn-new pointer" (click)="toggleSqForm()">
                  + Add Card
                </div>
                <div class="sc-list">
                  Please note that we do not save your credit card details.
                  These details are saved securely via our payment partner
                  <a
                    *ngIf="!isPWAApp"
                    href="https://squareup.com/au/en/legal/general/ua"
                    target="_"
                    >SquareUp</a
                  >
                  <span *ngIf="isPWAApp">SquareUp</span>.
                </div>
              </div>
            </div>
            <br />
          </div>
          <!-- Add the coupon code input field -->

          <!-- LOAD CARDHOLDER NAME AND SQUARE CARD INPUT FIELDS -->
          <div
            *ngIf="showSqForm && !processing"
            id="card-container"
            class="sc-mt-9"
          >
            <input
              class="form-control sc-mb-6"
              fullWidth
              type="text"
              [(ngModel)]="cardHolderName"
              id="name"
              placeholder="Cardholder name"
            />
          </div>

          <div class="">
            <div class="sc-list" *ngIf="showSqForm">
              Please note that we do not save your credit card details. These
              details are saved securely via our payment partner
              <a
                *ngIf="!isPWAApp"
                href="https://squareup.com/au/en/legal/general/ua"
                target="_"
                >SquareUp</a
              >
              <span *ngIf="isPWAApp">SquareUp</span>.
            </div>

            <ng-container *ngIf="isCouponApplied">
              <!-- Display the applied coupon with a remove option -->
              <div class="coupon-applied-container mb-2">
                <span
                  >{{ couponCode }} ({{
                    dataAfterCouponApplied.name
                  }}
                  Applied)</span
                >
                <label
                  class="remove-coupon-button"
                  (click)="removeAppliedCoupon()"
                >
                  Remove
                </label>
              </div>
            </ng-container>
            <ng-container *ngIf="showCouponInput && !isCouponApplied">
              <div class="coupon-container mb-2">
                <input
                  class="form-control"
                  fullWidth
                  type="text"
                  [(ngModel)]="couponCode"
                  id="couponCode"
                  placeholder="Coupon code"
                  (input)="forceUppercaseConditionally($event)"
                />
                <button
                  class="apply-button mt-0 w-50"
                  nbButton
                  status="primary"
                  (click)="onApplyCouponCode()"
                  size="giant"
                  [disabled]="!couponCode"
                >
                  Apply
                </button>
              </div>
              <div *ngIf="errorCouponCode === true" class="text-danger">
                Invalid coupon
              </div>
            </ng-container>
            <div
              class="sc-text-align-right"
              *ngIf="!showCouponInput && !existingSlots && !isSubscriberLocked"
            >
              <button
                nbButton
                status="basic"
                ghost
                (click)="showCouponInput = !showCouponInput"
                size="medium"
              >
                Apply Coupon
              </button>
            </div>

            <ng-container *ngIf="existingSlots">
              <div class="mb-3">
                <!-- Headers -->
                <div class="row row-cols-4">
                  <div class="col-6 px-1 strong"></div>
                  <div class="col-1 px-1 strong text-center">Qty</div>
                  <div class="col-3 px-2 strong text-end">Unit Price</div>
                  <div class="col-2 px-1 strong text-end">Total</div>
                </div>

                <!-- Values -->
                <div class="row row-cols-4">
                  <div class="col-6 px-1">
                    Additional User <br /><span
                      *ngIf="currentSubscription?.recurring_plan"
                      >Renews
                      {{
                        getBillingDate(currentSubscription)
                          | date : "d MMMM yyyy"
                      }}</span
                    >
                  </div>
                  <div class="col-1 px-1 text-center">{{ allottedSlots }}</div>
                  <div class="col-3 px-1 text-end">
                    ${{ getUserCost().toFixed(2) }}
                  </div>
                  <div class="col-2 px-1 text-end">
                    ${{ getPaymentAmount() }}
                  </div>
                </div>
              </div>
            </ng-container>

            <div
              class="total-section"
              *ngIf="!selectedPlan?.trial_days || !isTrialApplicable"
            >
              <div class="payable-amount row row-cols-2">
                <span class="col-9 sc-text-align-right">GST</span>
                <span class="amount-to-pay ms-3 col-3"
                  >${{ getGSTAmount() }}</span
                >
              </div>

              <div class="payable-amount strong row row-cols-2">
                <span class="col-9 sc-text-align-right">Total with GST</span>
                <span class="amount-to-pay ms-3 col-3"
                  >${{ getPaymentAmount() }}</span
                >
              </div>
            </div>

            <div class="small-font sc-text-align-right mt-2 me-3">
              Change plans later or cancel at any time
            </div>
          </div>
        </div>
        <div class="my-3">
          All pricing is inclusive of GST and in Australian Dollars
        </div>
        <div class="row row-cols-2 mt-0">
          <div class="col mt-0">
            <button
              *ngIf="!isSubscriberLocked"
              nbButton
              fullWidth
              ghost
              status="basic"
              (click)="onCloseDialogue()"
              size="large"
            >
              Cancel
            </button>
          </div>

          <div class="col mt-0" *ngIf="this.showFullView()">
            <button
              *ngIf="showSqForm && !processing"
              nbButton
              fullWidth
              [disabled]="!cardHolderName"
              id="pay-btn"
              size="large"
              status="primary"
            >
              Pay
            </button>

            <button
              size="large"
              *ngIf="!showSqForm"
              nbButton
              id="card-pay-btn"
              fullWidth
              status="primary"
              [disabled]="!selectedPlan.selectedCard || paymentFormInitialized"
              (click)="onCardSelected()"
            >
              Pay
            </button>
            <div id="payment-status-container"></div>
          </div>

          <div class="col mt-0" *ngIf="!this.showFullView()">
            <button
              nbButton
              fullWidth
              size="large"
              status="primary"
              (click)="createSubscription()"
            >
              Next
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</nb-card>
