<nb-card class="dialog-box">
  <nb-card-header>
    {{ updateView ? "Edit Company" : "Add New Company" }}
    <nb-icon
      (click)="onCloseDialogue('close')"
      [icon]="'close-outline'"
      class="sc-floating-right close-icon"
      pack="eva"
    ></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="companyForm" appFormEnterAction>
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          nbInput
          id="company_name"
          formControlName="company_name"
          autocomplete="off"
          placeholder="Company Name"
          autofocus
          fullWidth
        />
        <label for="company_name">Company Name</label>
      </div>

      <div class="row row-cols-2">
        <div class="col">
          <button
            (click)="onCloseDialogue('close')"
            nbButton
            size="giant"
            fullWidth
            ghost
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            id="next1btn"
            type="button"
            (click)="updateData()"
            [disabled]="!companyForm.valid"
            nbButton
            size="giant"
            fullWidth
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
