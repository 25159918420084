import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { jobCardComponentDetail } from 'src/global.variables';
import { ProfileService } from '../profile/profile.service';
import { JobsService } from './jobs.service';
@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit, OnDestroy {
  // redirect components
  notificationRoute: any;
  jobType: any;
  customerId: any;
  stalePeriod: any = 3;
  searchRemovalCap: number = 5;
  // manual pagination components
  previous: number = 0;
  pageSize: number = 5;
  totalRows: any;
  pageNum: number = 1;
  totalPages: number = 0;
  mobilePageSize = 5;
  // manual pagination components

  statusData: any = [];
  userData: any;
  totalJobs: any;
  dropDownJobs: any;

  defaultJob: boolean = true;
  listJobData: any = [];
  paginationChange: boolean = false;

  // showing data as card list instead of table
  jobCardsDetails: any = {
    // text styling can be updated here

    groupDetails: (row: any) => {
      if (
        row?.status == 'Closed' ||
        row?.status_identity == 2 ||
        row?.status_identity == 3
      ) {
        return {
          opacity: '0.5',
        };
      } else {
        for (let i = 0; i < this.statusData.length; i++) {
          if (this.statusData[i].name === row?.status) {
            return {
              'border-color': `${this.statusData[i].additional_info?.style?.snubbed['border-color']}`,
            };
          }
        }
      }

      return {};
    },
  };
  isFilterApplied: boolean = false;
  showTypeAhead: boolean = true;
  globalFilter: any;

  totalJobCount: any;

  viewType: string = 'cardView';
  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };
  otherParams: any = {
    saveFilters: true,
    tableRowStyle: (rowData: any) => {
      if (
        rowData?.status &&
        (rowData?.status == 'Closed' ||
          rowData?.status_identity == 2 ||
          rowData?.status_identity == 3)
      ) {
        return {
          opacity: '0.5',
        };
      }
      return {};
    },
  };

  jobDetailsTableView: any = [
    {
      header: 'Last Updated Date',
      name: 'modified_date',
      datetimeObj: true,
      dateRangeKey: 'modified_date',
      sortKey: 'modified_date',
      conditionalColumnStyle: (row: any) => {
        if (
          new Date(row?.modified_date).getTime() <
            new Date().setDate(new Date().getDate() - this.stalePeriod) &&
          row?.status !== 'Closed' &&
          (row?.status_identity != 2 || row?.status_identity != 3)
        ) {
          return {
            'color': 'var(--color-basic-100)',
            'background-color': 'var(--color-danger-500)',
          };
        }
        return {};
      },
    },
    {
      header: 'Site Name',
      name: 'site_name',
      sortKey: 'site_name',
      searchKey: 'site_name',
      cardRowStyle: {
        'font-weight': 'bold',
      },
    },
    {
      header: 'Client',
      name: 'company',
      sortKey: 'company__company_name',
      searchKey: 'company__company_name',
    },
    {
      header: 'Job Key',
      name: 'job_key',
      sortKey: 'job_key',
      searchKey: 'job_key',
    },
    {
      header: 'Ext. Job Id',
      name: 'ext_job_id',
      sortKey: 'ext_job_id',
      searchKey: 'ext_job_id',
    },
    {
      header: 'Address',
      name: 'sites',
      sortKey: 'sites',
      searchKey: 'sites',
    },
    {
      header: 'Response Type',
      name: 'response_type',
      sortKey: 'response_type__name',
    },
    {
      header: 'Monitoring Company',
      name: 'monitoring_company',
      sortKey: 'monitoring_company__company_name',
    },

    {
      header: 'Assigned To',
      name: 'assignee',
      nestedValue: (row: any) => {
        return this.getNestedAssignees(row);
      },

      searchKey: 'assignee',
    },

    {
      header: 'Status',
      name: 'status',
      dropDownList: () => {
        return this.statusData?.filter(
          (status: any) => status?.status_identity?.value !== 7
        );
      },
      conditionalColumnStyle: (row: any) => {
        if (row?.status) {
          let style: any = { 'float': 'right', 'font-size': 'small' };

          this.statusData.forEach((element: any) => {
            if (element.name === row?.status) {
              style = {
                'color': `${element.additional_info?.style?.snubbed?.color}`,
                'background-color': `${element.additional_info?.style?.snubbed['background-color']}`,

                'padding': '0 0.5rem',
                'text-align': 'center',
              };
            }
          });

          return style;
        } else {
          return { 'text-align': 'center', 'font-size': 'small' };
        }
      },
      dropdownKey: 'status_id',
    },
  ];

  sortKeys: any = [];
  dateRange: any;
  searchBody: any;
  loading = false;
  infinteJobList: any = [];
  infinteJobListJobCount: number = 0;

  completeStatus: any;
  isDispatchUser: boolean = false;
  isAdmin: boolean = false;
  userGroup: any;
  lastApiResponseHadData: boolean = true;
  lastSearchStr: any;
  apiCallMade: boolean = false;
  jobFilters: boolean = false;

  constructor(
    private jobService: JobsService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private appService: AppService,
    private router: Router,
    private profileService: ProfileService,
    private dataCheckService: DataCheckService
  ) {
    this.customerId = this.route.snapshot.paramMap.get('clientId');
    this.jobType = this.route.snapshot.fragment;
    this.notificationRoute = this.route.snapshot.paramMap.get(
      'notificationStateJobId'
    );

    if (this.notificationRoute) {
      this.getDataById(this.notificationRoute);
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      // Access the 'cancel button is clicked from add response page' query parameter
      if (localStorage.getItem('isCancelResponseJob') !== 'true') {
        localStorage.setItem(
          'isCancelResponseJob',
          params['cancel'] === 'true' ? 'true' : 'false'
        );
      }
    });
    this.userData = this.appService.getUserData();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.pageSize = Number(this.userData?.preferences?.job_page_size || 5);
    // if not admin or dispatcher redirect the user back to dashboard
    if (!this.isAdmin && !this.isDispatchUser) {
      this.router.navigate(['/dashboard']).then(() => {
        window.location.reload();
      });
    }
    if (
      (this.isAdmin || this.isDispatchUser) &&
      !this.route.snapshot.fragment
    ) {
      this.jobType = 'defaultJobs';
    }
    let jobListViewType = this.userData?.preferences?.jobListViewType;
    if (window.innerWidth >= 770) {
      this.viewType = jobListViewType?.desktopView || 'tableView';
    } else {
      this.viewType = jobListViewType?.mobileView || 'cardView';
    }

    this.loadFiltersFromStorage();
  }
  ngOnDestroy(): void {
    // if (this.navigationEndSubscription) {
    //   this.navigationEndSubscription.unsubscribe();
    // }
    window.onpopstate = null;
  }
  getNestedAssignees(rowData: any, maxNumber: any = 2) {
    if (rowData?.assignee?.length > maxNumber) {
      return `${rowData?.assignee?.slice(0, maxNumber)} ...`;
    } else {
      return rowData?.assignee;
    }
  }

  getJobStatuses() {
    this.jobService.getJobParams().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.statusData = response.statuses;

        this.statusData.push({
          id: '0',
          name: 'Closed',
          additional_info: {
            style: {
              snubbed: {
                'color': 'var(--color-basic-1100)',
                'background-color': 'var(--color-basic-100)',
                'border-color': 'var(--color-basic-1100)',
                'border': '1px solid var(--color-basic-1100)',
              },
            },
          },
        });

        this.findDeviceData();
      }
    });
  }

  openAddJobForm() {
    if (
      localStorage.getItem('isCancelResponseJob') === 'true' &&
      this.totalJobCount === 0
    ) {
      localStorage.removeItem('isCancelResponseJob');
      this.router.navigate(['/dashboard'], { relativeTo: this.route });
    } else {
      this.router.navigate(['/add-job'], { relativeTo: this.route });
    }
  }
  openIndividualJob(job: any) {
    sessionStorage.setItem(
      window.innerWidth >= 770 ? 'largeView' : 'mobileView',
      JSON.stringify(this.jobFilters)
    );
    this.spinnerService.show();
    this.router.navigateByUrl(`/job-detail/${job.job_key}`, {
      state: job.id,
    });
    window.localStorage.setItem('urlId', job.id);
  }
  getDataById(jobId: any) {
    this.jobService.getJobById(jobId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.openIndividualJob(response['data']);

        this.spinnerService.hide();
      } else {
        this.spinnerService.hide();
      }
    });
  }

  onFilterSearch() {
    let body = {
      rows: 5,
      search_str: this.globalFilter,
      ...this.getJobTypeFilter(),
    };
    if (this.lastSearchStr) {
      !this.globalFilter.includes(this.lastSearchStr)
        ? (this.apiCallMade = true)
        : (this.apiCallMade = false);
    } else {
      this.apiCallMade = true;
    }
    if (this.apiCallMade || this.lastApiResponseHadData) {
      this.jobService.fetchJobDetails(body, {}).subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.dropDownJobs = [...response['data'].values];
          this.lastApiResponseHadData = this.dropDownJobs.length > 0;
          this.lastSearchStr = this.globalFilter;
        }
      });
    }
  }
  onFilterApply(event: any) {
    if (event?.target?.value && event?.target?.value?.length >= 3) {
      this.isFilterApplied = true;
      this.onFilterSearch();
      this.showTypeAhead = true;
    }
    if (
      event === true &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.isFilterApplied = true;
      this.pageNum = 1;
      this.previous = 0;
      this.paginationChange = true;
      this.findDeviceData();
      this.showTypeAhead = false;
    } else if (
      event.key === 'Enter' &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.pageNum = 1;
      this.previous = 0;
      this.isFilterApplied = true;
      this.paginationChange = true;
      this.findDeviceData();
      this.showTypeAhead = false;
    }
  }

  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.paginationChange = true;

    this.findDeviceData();
  }
  onChangePagination(event: any) {
    this.previous = event?.paginationData?.tablePreviousRows;
    this.pageNum = event?.paginationData?.tablePageNum;
    this.pageSize = event?.paginationData?.tableRows;
    this.otherParams.paginationData = {
      ...event?.paginationData,
    };
    this.findDeviceData();
  }
  sortColumn(body: any) {
    this.sortKeys = body?.sortList || [];
    this.paginationChange = true;
    this.findDeviceData();
  }
  sortField(column: any) {
    column['sortOrder'] =
      column?.sortOrder === 'asc'
        ? 'desc'
        : column?.sortOrder === 'desc'
        ? null
        : 'asc';

    this.jobDetailsTableView.forEach((item: any) => {
      if (item?.name !== column?.name) {
        item.sortOrder = null;
      }
    });

    let sortKeyValue: any = column?.sortKey
      ? typeof column?.sortKey === 'string'
        ? column?.sortKey
        : column?.name
      : column?.name;
    let sortKey: any =
      column?.sortOrder == 'desc'
        ? `-${sortKeyValue}`
        : column?.sortOrder == 'asc'
        ? sortKeyValue
        : '';

    this.sortKeys = [];
    if (sortKey) this.sortKeys = [sortKey];

    this.paginationChange = true;
    this.totalRows = null;
    this.previous = 0;
    this.pageNum = 1;

    this.findDeviceData();
  }
  switchMobileView() {
    this.viewType == 'cardView'
      ? (this.viewType = 'mapView')
      : (this.viewType = 'cardView');
    this.previous = 0;
    this.getMobileJobs();
    this.updatePreferences();
  }
  switchTableCardView() {
    this.viewType == 'tableView'
      ? (this.viewType = 'mapView')
      : (this.viewType = 'tableView');

    this.searchBody = null;
    this.updatePreferences();
    this.getLargeDevicesJobs();
  }
  updatePreferences() {
    let body: any = {};
    let jobListViewType = this.userData?.preferences?.jobListViewType;

    if (window.innerWidth >= 770) {
      body = {
        desktopView: this.viewType,
        mobileView: jobListViewType?.mobileView || 'cardView',
      };
    } else {
      body = {
        mobileView: this.viewType,
        desktopView: jobListViewType?.desktopView || 'cardView',
      };
    }
    this.profileService
      .updateUser({ data: { jobListViewType: body } }, { save_preference: 1 })
      .subscribe((response: any) => {});

    let userData = this.appService.getUserData();
    if (userData?.preferences) {
      userData.preferences.jobListViewType = body;
      this.appService.setUserData(userData);
    }
  }

  searchColumn(event: any) {
    this.dateRange = event?.date || null;
    this.searchBody = event?.searchParams || {};
    this.paginationChange = true;

    this.getLargeDevicesJobs();
  }
  findDeviceData() {
    // function to dynamically find screen width and determine whther data is to be processed for mobile or large devices

    this.jobCardsDetails.individualDetails = jobCardComponentDetail(
      this.statusData,
      this.stalePeriod,
      this.isAdmin || this.isDispatchUser
    );

    if (window.innerWidth < 770) {
      this.pageSize = this.mobilePageSize;
      // mobile devices
      this.infinteJobList = [];
      this.infinteJobListJobCount = 0;

      this.getMobileJobs();
    } else {
      //large devices

      this.getLargeDevicesJobs();
    }
  }
  getJobTypeFilter() {
    let body: any = {};

    if (this.customerId) {
      body['company_id'] = this.customerId;
    } else {
      if (this.jobType == 'staleJobs') {
        body.stale_days = this.stalePeriod;
      } else if (this.jobType == 'unassignedJobs') {
        body.unassigned_jobs = 1;
      } else if (this.jobType == 'defaultJobs') {
        body.desktop_default_list = 1;
        this.defaultJob = true;
      } else if (this.jobType == 'allJobs') {
        this.defaultJob = false;
      }

      this.router.navigate(['/jobs'], { fragment: this.jobType });
    }
    if (this.sortKeys) body.sort_key = this.sortKeys;
    if (this.globalFilter) {
      body.search_str = this.globalFilter;
    }

    if (this.searchBody) body.search_filter = this.searchBody;

    return body;
  }
  getInfinteLoadData() {
    if (this.loading) {
      return;
    }

    if (
      this.totalRows != null &&
      this.infinteJobListJobCount >= this.totalRows
    ) {
      return;
    }
    if (window.innerWidth >= 770) {
      return;
    }

    let param: any = {};
    let body = this.getJobTypeFilter();

    body.rows = this.mobilePageSize;

    this.loading = true;

    if (this.previous) {
      body['previous'] = this.previous;
    }

    this.spinnerService.show();
    this.jobService.fetchJobDetails(body, param).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.totalJobs = response['data'].total_size;
        this.infinteJobList.push([...response['data'].values]);
        this.totalJobCount = response?.['job_count'] || 0;
        this.previous = this.previous + this.mobilePageSize;
        this.infinteJobListJobCount =
          this.infinteJobListJobCount + [...response['data'].values]?.length;
        if (
          this.totalJobCount === 0 &&
          (this.isAdmin || this.isDispatchUser === true)
        ) {
          this.openAddJobForm();
        }
        this.loading = false;
        this.totalRows = response['data'].total_size;
        this.paginationChange = false;
        this.totalPages = Math.ceil(this.totalRows / this.pageSize);
        this.saveJobFilters(body, param);
      }
      this.spinnerService.hide();
    });
  }
  getMapMobileList() {
    if (window.innerWidth >= 770) {
      return;
    }

    let param: any = {};
    let body = this.getJobTypeFilter();

    body.rows = this.mobilePageSize;

    if (this.previous) {
      body['previous'] = this.previous;
    }

    this.spinnerService.show();
    this.jobService.fetchJobDetails(body, param).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.paginationChange = false;
        this.totalJobs = response['data'].total_size;
        this.totalJobCount = response?.['job_count'] || 0;
        if (
          this.totalJobCount === 0 &&
          (this.isAdmin || this.isDispatchUser === true)
        ) {
          this.openAddJobForm();
        }
        this.listJobData = response['data'].values;
        this.totalRows = response['data'].total_size;
        this.totalPages = Math.ceil(this.totalRows / this.pageSize);
        this.saveJobFilters(body, param);
      }
      this.spinnerService.hide();
    });
  }

  getMobileJobs() {
    if (this.viewType === 'mapView') {
      this.getMapMobileList();
    } else {
      this.getInfinteLoadData();
    }
  }
  getLargeDevicesJobs() {
    let param: any = { large_devices: 1 };
    let body = this.getJobTypeFilter();

    if (this.pageSize) {
      body['rows'] = this.pageSize;
      let userData = this.appService.getUserData();
      if (userData?.preferences) {
        userData.preferences.job_page_size = this.pageSize;
        this.appService.setUserData(userData);
      }
    }
    if (this.previous) {
      body['previous'] = this.previous;
    }

    this.spinnerService.show();

    this.jobService.fetchJobDetails(body, param).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.totalJobs = response['data'].total_size;
        this.totalJobCount = response?.['job_count'] || 0;
        if (
          this.totalJobCount === 0 &&
          (this.isAdmin || this.isDispatchUser === true)
        ) {
          this.openAddJobForm();
        }
        this.listJobData = response['data'].values;
        this.paginationChange = false;
        this.totalRows = response['data'].total_size;
        this.totalPages = Math.ceil(this.totalRows / this.pageSize);
        this.otherParams.paginationData = {
          tableTotalRows: this.totalRows,
          tablePreviousRows: this.previous,
          tableRows: this.pageSize,
          tablePageNum: this.pageNum,
          tableTotalPages: this.totalPages,
        };

        this.saveJobFilters(body, param);
      }
      this.spinnerService.hide();
    });
  }
  resetFilters(jobType = 'jobs') {
    this.globalFilter = null;
    this.previous = 0;
    this.pageNum = 1;
    this.jobType = jobType;
    this.totalRows = null;
    this.paginationChange = true;
    this.customerId = null;
    this.otherParams.paginationData = {
      tableTotalRows: this.totalRows,
      tablePreviousRows: this.previous,
      tableRows: this.pageSize,
      tablePageNum: this.pageNum,
      tableTotalPages: this.totalPages,
    };
    this.router.navigate(['/jobs'], { fragment: this.jobType });
    this.findDeviceData();
  }

  showAllJobsDesktop() {
    this.jobType = this.jobType == 'allJobs' ? 'defaultJobs' : 'allJobs';
    this.defaultJob = !this.defaultJob;
    this.resetFilters(this.jobType);
  }
  saveJobFilters(body: any, params: any) {
    this.jobFilters = {
      ...body,
      ...params,
      jobType: this.jobType,
      jobDetailsTableView: this.jobDetailsTableView,
    };
  }
  loadFiltersFromStorage() {
    const filterKey: string =
      window.innerWidth >= 770 ? 'largeView' : 'mobileView';
    const filters = JSON.parse(sessionStorage.getItem(filterKey) || '{}');
    if (Object.keys(filters)?.length) {
      this.otherParams.loadFilters = true;
      this.customerId = filters?.company_id;
      this.jobType = filters?.jobType;
      this.stalePeriod = filters?.stale_days;
      this.searchBody = filters?.search_filter || {};
      this.globalFilter = filters?.search_str;
      this.sortKeys = filters?.sort_key || [];

      this.jobDetailsTableView.forEach((item: any) => {
        filters?.jobDetailsTableView?.forEach((column: any) => {
          if (item?.name == column?.name) {
            item.sortOrder = column?.sortOrder;
          }
        });
      });
    }
    sessionStorage.removeItem(filterKey);
    this.getJobStatuses();
  }
}
