import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { WebcamImage } from 'ngx-webcam';
import { PagesService } from 'src/app/pages/pages.service';
import { UploadTypeComponent } from '../upload-type/upload-type.component';

@Component({
  selector: 'app-webcam-image',
  templateUrl: './webcam-image.component.html',
  styleUrls: ['./webcam-image.component.scss'],
})
export class WebcamImageComponent implements OnInit, OnDestroy {
  @Input() showFrom: any;
  @Input() uploadType: string = 'live';
  deviceId: string = '';
  imageUrl: any;
  webcamImage: any;
  showWebcam: any;
  actualUploadFile: any;
  fileData: any = [];
  cameraError: any;
  btnLable: string = 'Take Photo';
  newComment: any;
  isCameraOpen: boolean = false;
  apidataUpdation: boolean = true;
  clientCount: number = 0;
  dialogeRef: any;
  currentDevice: any;
  constructor(
    protected camdialogueref: NbDialogRef<WebcamImageComponent>,
    private pageService: PagesService,
    private dialogService: NbDialogService
  ) {}
  environment = 'environment';
  async ngOnInit(): Promise<void> {
    var ele: any = document.querySelector('.cdk-overlay-container');
    ele.style.backgroundColor = '#000000';
    this.newComment = null;
    if (this.showFrom === 'scanCheckpoint') {
      this.btnLable = 'Scan Checkpoint';
    }

    if (this.showFrom === 'scanCheckpoint') {
      this.uploadType = 'live';
      this.openCamera();
    } else {
      this.selectDeviceUploadType();
    }
    this.pageService.addPhotoClientCount.subscribe((value: any) => {
      this.apidataUpdation = value?.apidataUpdation;
      this.clientCount = value?.clientCount;
    });
  }

  //Method trigger the captured image
  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage.imageAsDataUrl;
    const arr = webcamImage.imageAsDataUrl.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    this.actualUploadFile = new File(
      [u8arr],
      `Upload_${(this.fileData.length = 1)}`,
      { type: 'image/jpeg' }
    );
  }

  //File submit to parent component
  onFileSubmit() {
    this.showWebcam = false;
    let image = {
      webCamImage: this.webcamImage,
      actualFileUpload: this.actualUploadFile,
      uploadType: this.uploadType,
    };
    this.camdialogueref.close(image);
  }

  close() {
    this.camdialogueref.close();
  }

  //Showing this button only with update client or job
  onSavePhoto() {
    let image = {
      webCamImage: this.webcamImage,
      actualFileUpload: this.actualUploadFile,
      newComment: this.newComment,
      uploadType: this.uploadType,
    };
    this.camdialogueref.close(image);
  }

  ngOnDestroy(): void {
    var ele: any = document.querySelector('.cdk-overlay-container');
    ele.style.backgroundColor = null;
  }

  handleFileInput(item: any) {
    this.actualUploadFile = item.files.item(0);
    if (this.showFrom === 'scanCheckpoint') {
      this.onFileSubmit();
    } else {
      //Show image preview
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageUrl = event.target.result;
      };
      reader.readAsDataURL(this.actualUploadFile);
      this.isCameraOpen = false;
    }
  }
  openCamera() {
    const elementId =
      this.uploadType === 'live' ? 'fileInputLive' : 'fileInput';
    var button: any = document.getElementById(elementId);
    button.click();
    setTimeout(() => {
      this.isCameraOpen = true;
    }, 1000);
  }

  selectDeviceUploadType() {
    this.dialogeRef = this.dialogService.open(UploadTypeComponent, {});
    this.dialogeRef.onClose.subscribe((value: any) => {
      if (value === 'close') {
        this.camdialogueref.close();
      } else {
        this.uploadType = value;
        this.openCamera();
      }
    });
  }
  retry() {
    if (this.showFrom === 'scanCheckpoint') {
      this.uploadType = 'live';
      this.openCamera();
    } else {
      this.selectDeviceUploadType();
    }
  }
}
