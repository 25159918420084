<nb-card class="p-1">
  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
    <div class="mt-2" *ngFor="let alert of sosAlerts; let i = index">
      <nb-card class="contact-card">
        <div class="table-card-data pointer">
          <div class="d-flex">
            <div class="flex-grow-1">
              <div class="strong">
                {{ alert.user_name }}
              </div>
              <div class="small-font strong">
                {{ alert?.updated_at | customdate : "d MMM y HH:mm" }}
              </div>
            </div>
            <div (click)="showGps(i)" *ngIf="!alert?.showGps">
              <nb-icon
                class="me-3"
                [icon]="'info-outline'"
                pack="eva"
              ></nb-icon>
            </div>
          </div>

          <div class="row row-cols-2 mt-2">
            <div
              (click)="
                selectedAlert = alert;
                openAlertTemplate(acknowledgeTemplate, alert)
              "
            >
              <button
                nbButton
                status="primary"
                fullWidth
                class="nbbutton-long-text"
              >
                Acknowledge
              </button>
            </div>
            <!-- add 2 call btns ; one disabled if there is no phone number ; had to split because even though btn disbled the href was triggering-->
            <ng-container *ngIf="alert?.mobile_number"
              ><div>
                <a
                  href="tel:{{ alert?.mobile_number }}"
                  style="color: white !important"
                >
                  <button
                    nbButton
                    status="success"
                    fullWidth
                    class="nbbutton-long-text"
                  >
                    <nb-icon
                      class="me-3"
                      [icon]="'phone-call'"
                      style="color: white !important"
                      pack="eva"
                    ></nb-icon>
                    Call
                  </button></a
                >
              </div>
            </ng-container>
            <ng-container *ngIf="!alert?.mobile_number">
              <div
                nbTooltip="{{
                  alert?.user_name
                }} has not provided a phone number"
              >
                <button
                  nbButton
                  status="success"
                  fullWidth
                  class="nbbutton-long-text"
                  disabled
                >
                  <nb-icon
                    class="me-3"
                    [icon]="'phone-call'"
                    style="color: white !important"
                    pack="eva"
                  ></nb-icon>
                  Call
                </button>
              </div></ng-container
            >
          </div>
          <ng-container *ngIf="alert?.showGps">
            <div class="mt-2">
              <app-map-leaflet
                [latLon]="alert?.gps"
                [mapId]="alert?.userId"
              ></app-map-leaflet>
            </div>
          </ng-container>
        </div>
      </nb-card>
    </div>
  </div>
</nb-card>
<div class="sc-head6 sc-text-center">History</div>

<div class="mobile-only mb-3 gt-p-small">
  <div class="d-flex" *ngIf="!selectedEvent">
    <div class="flex-grow-1 form-floating">
      <input
        class="form-control"
        nbInput
        fullWidth
        placeholder="Pick Date Range"
        [nbDatepicker]="formpicker"
        [(ngModel)]="dateRangeSOSAlertsHistory"
      />
      <label for="date_range">Pick Date Range</label>
      <nb-rangepicker
        #formpicker
        (rangeChange)="handleDateChangeSOSAlertsHistory($event)"
      ></nb-rangepicker>
    </div>
  </div>

  <div class="mt-1">
    <ng-container *ngIf="!selectedEvent">
      <div *ngIf="sosAlertHistoryData?.length">
        <app-card-list
          [listCardData]="sosAlertHistoryData"
          [listCardDetails]="userEventDetails"
          (rowClick)="onTableRowClickSOSAlertsHistory($event)"
        ></app-card-list>

        <div class="sc-mt-6">
          <app-pagination-control
            [pageSize]="pageSizeSOSAlertsHistory"
            [pageNum]="pageNumSOSAlertsHistory"
            [previous]="previousSOSAlertsHistory"
            [totalRows]="totalRowsSOSAlertsHistory"
            [totalPages]="totalPagesSOSAlertsHistory"
            (onClickPagination)="onChangePaginationSOSAlertsHistory($event)"
            (onChangePagination)="onChangePaginationSOSAlertsHistory($event)"
          >
          </app-pagination-control>
        </div>
      </div>
      <div *ngIf="sosAlertHistoryData?.length == 0" class="my-3">
        <nb-alert outline="basic" style="font-weight: 500"
          >No Records Found</nb-alert
        >
      </div>
    </ng-container>
  </div>
</div>

<!-- Show Table on Desktop View -->
<div class="large-view-only gt-p-1">
  <app-table
    [desktopTableData]="sosAlertHistoryData"
    [desktopTableDetails]="userEventDetailsTableView"
    [otherParms]="{ dateRange: dateRangeSOSAlertsHistory }"
    [tableStyle]="tableStyle"
    (rowClick)="onTableRowClickSOSAlertsHistory($event)"
    (sortColumn)="sortColumnSOSAlertsHistory($event)"
    (searchColumn)="searchColumnSOSAlertsHistory($event)"
    [apiFilter]="true"
  ></app-table>

  <div class="sc-mt-6">
    <app-pagination-control
      [pageSize]="pageSizeSOSAlertsHistory"
      [pageNum]="pageNumSOSAlertsHistory"
      [previous]="previousSOSAlertsHistory"
      [totalRows]="totalRowsSOSAlertsHistory"
      [totalPages]="totalPagesSOSAlertsHistory"
      (onClickPagination)="onChangePaginationSOSAlertsHistory($event)"
      (onChangePagination)="onChangePaginationSOSAlertsHistory($event)"
    >
    </app-pagination-control>
  </div>
  <div *ngIf="sosAlertHistoryData?.length == 0" class="my-3">
    <nb-alert outline="basic" style="font-weight: 500"
      >No Records Found</nb-alert
    >
  </div>
</div>

<div
  #detailsSection
  class="row row-cols-1 row-cols-sm-1 row-cols-md-2 gt-p-small"
  *ngIf="selectedEvent"
>
  <div class="col-md-5">
    <nb-card class="events-details-section">
      <ng-container>
        <div class="sc-head6">Event Details</div>
        <div class="row row-cols-1 row-cols-xxl-2">
          <ng-container *ngFor="let indColumn of userEventDetailsTableView">
            <ng-container
              *ngIf="
                (indColumn.nestedValue
                  ? indColumn.nestedValue(selectedEvent)
                  : selectedEvent[indColumn.name]
                )?.trim()
              "
            >
              <div>
                <span class="badge-key" style="text-transform: uppercase">
                  {{ indColumn?.header }}</span
                >
                <ng-container *ngIf="indColumn?.datetimeObj == true">
                  <span class="badge-value">
                    {{
                      indColumn.nestedValue
                        ? (indColumn.nestedValue(selectedEvent)
                          | date
                            : (indColumn.dateTimeFormat
                                ? indColumn.dateTimeFormat
                                : "d MMM y HH:mm"))
                        : (selectedEvent[indColumn.name]
                          | date
                            : (indColumn.dateTimeFormat
                                ? indColumn.dateTimeFormat
                                : "d MMM y HH:mm"))
                    }}
                  </span></ng-container
                >
                <ng-container *ngIf="!indColumn?.datetimeObj">
                  <span class="badge-value">
                    {{
                      indColumn.nestedValue
                        ? indColumn.nestedValue(selectedEvent)
                        : selectedEvent[indColumn.name] == "null" ||
                          selectedEvent[indColumn.name] == "undefined"
                        ? "N/A"
                        : selectedEvent[indColumn.name]
                    }}
                  </span></ng-container
                >
              </div>
            </ng-container>
          </ng-container>
          <div *ngIf="selectedEvent?.acknowledge_info?.updated_at">
            <span class="badge-key"> Guard has been contacted</span>
            <span class="badge-value">{{
              selectedEvent?.acknowledge_info?.inquired ? "Yes" : "No"
            }}</span>
          </div>
          <div *ngIf="selectedEvent?.acknowledge_info?.comment">
            <span class="badge-key" style="text-transform: uppercase">
              Details and actions taken</span
            >
            <span class="badge-value">{{
              selectedEvent?.acknowledge_info?.comment
            }}</span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedEvent?.device_details">
        <div class="sc-head6">Device Details</div>
        <div class="row row-cols-1 row-cols-xxl-2">
          <div *ngFor="let item of selectedEvent?.device_details | keyvalue">
            <div *ngIf="item?.value">
              <span class="badge-key" style="text-transform: uppercase">
                {{ item.key }}</span
              >
              <span class="badge-value"> {{ item.value }} </span>
            </div>
          </div>

          <div>
            <span
              class="badge-key"
              nbTooltip="{{browserGpsInfo?.[selectedEvent?.gps?.source]?.description}}"
              nbTrigger="focus"
              [ngClass]="browserGpsInfo?.[selectedEvent?.gps?.source] ? 'strong' : ''"
              [nbTooltipDisabled]="!browserGpsInfo?.[selectedEvent?.gps?.source]"
            >
              LOCATION
            </span>
            <span
              class="badge-value"
              nbTooltip="{{browserGpsInfo?.[selectedEvent?.gps?.source]?.description}}"
              nbTrigger="focus"
              [nbTooltipDisabled]="!browserGpsInfo?.[selectedEvent?.gps?.source]"
              [ngClass]="browserGpsInfo?.[selectedEvent?.gps?.source] ? 'danger-text': ''"
            >
              {{ selectedEvent?.gps?.lat }},
              {{ selectedEvent?.gps?.lon }}
            </span>
          </div>
        </div>
      </ng-container>
    </nb-card>
  </div>

  <div class="col-md-7">
    <app-map-leaflet
      *ngIf="selectedEvent?.gps"
      [latLon]="selectedEvent?.gps"
      [mapId]="'event-location'"
    ></app-map-leaflet>
    <div class="sc-text-center" style="font-size: small">
      {{ selectedEvent?.gps?.lat }},
      {{ selectedEvent?.gps?.lon }}
    </div>
  </div>
</div>
<div *ngIf="selectedEvent" class="pb-2 fixed-back-btn mobile-only">
  <button
    nbButton
    size="giant"
    fullWidth
    status="primary"
    (click)="selectedEvent = null"
  >
    Back
  </button>
</div>

<ng-template #acknowledgeTemplate let-data let-acknowledgeTemplate="dialogRef">
  <nb-card class="dialog-box p-2">
    <nb-card-header>
      Acknowledge SOS
      <button
        *ngIf="isAlertActive"
        nbButton
        size="large"
        (click)="muteAlert()"
        status="info"
        class="sc-floating-right"
      >
        <nb-icon
          pack="eva"
          icon="volume-off-outline"
          style="color: white !important"
        ></nb-icon>
      </button>
    </nb-card-header>
    <div class="my-1" *ngIf="guardAlertData">
      <app-map-leaflet
        [latLon]="guardAlertData?.gps"
        [mapId]="'alertMapId'"
      ></app-map-leaflet>
    </div>
    <form [formGroup]="acknowledgeForm">
      <div class="mt-2">
        Guard has been contacted?
        <nb-radio-group
          formControlName="inquired"
          name="ng-model-group"
          style="display: flex; justify-content: center"
        >
          <nb-radio [value]="true"> Yes </nb-radio>
          <nb-radio [value]="false"> No </nb-radio>
        </nb-radio-group>
      </div>
      <div class="col mt-2">
        <textarea
          class="form-control"
          placeholder="Details and actions taken"
          cols="20"
          rows="5"
          wrap="hard"
          maxlength="900"
          fullWidth
          type="text"
          formControlName="comment"
        ></textarea>
      </div>
    </form>

    <div class="row row-cols-2 my-2">
      <div class="col" (click)="acknowledgeTemplate.close()">
        <button nbButton ghost size="giant" fullWidth>Cancel</button>
      </div>
      <div id="confirmBtn" class="col">
        <button
          fullWidth
          nbButton
          size="giant"
          (click)="acknowledgeTemplate.close(); acknowledgeAlert()"
          [disabled]="!acknowledgeForm.valid"
        >
          Confirm
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>
