<nb-card class="dialog-box">
  <nb-card-header>
    <nb-icon
      (click)="onCloseDialogue('close')"
      [icon]="'close-outline'"
      class="sc-floating-right close-icon"
      pack="eva"
    ></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="addressForm" appFormEnterAction>
      <nb-stepper #stepper [linear]="linearMode">
        <nb-step [stepControl]="mapForm" label="Map">
          <div class="form-floating">
            <input
              class="form-control"
              (input)="thridPartySearchAddress($event)"
              nbInput
              fullWidth
              type="text"
              placeholder="Address Lookup"
              [nbAutocomplete]="thirdPartySearchAutoComplete"
              [(ngModel)]="selectedThirdPartyFullAddress"
              [ngModelOptions]="{ standalone: true }"
            />
            <label>Address Lookup</label>
          </div>
          <nb-autocomplete #thirdPartySearchAutoComplete>
            <nb-option
              *ngFor="let item of thirdPartyAddresses"
              (click)="thirdPartyAddressSelected(item)"
            >
              <div class="small-font">
                {{ item?.properties?.full_address }}
              </div>
            </nb-option>
          </nb-autocomplete>
          <div class="map-height" *ngIf="showMap">
            <app-drag-map (emitData)="getLatLon($event)" [latLon]="latLon">
            </app-drag-map>
          </div>

          <div
            *ngIf="selectedLatlon?.lat || selectedLatlon?.lon"
            style="
              text-align: center;
              font-size: 0.75rem;
              text-transform: uppercase;
            "
          >
            Lat: {{ selectedLatlon?.lat }} Lon: {{ selectedLatlon?.lon }}
          </div>

          <div class="row row-cols-2 sc-mt-6">
            <div class="col">
              <button
                nbButton
                ghost
                (click)="showHideMap()"
                fullWidth
                size="giant"
                *ngIf="selectedLatlon"
              >
                My Location
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                nbStepperNext
                nbButton
                fullWidth
                (click)="findAddress()"
                [disabled]="!selectedLatlon"
                size="giant"
              >
                Next
              </button>
            </div>
          </div>
        </nb-step>
        <nb-step
          [stepControl]="mapForm"
          label="Map"
          *ngIf="showFrom === 'cpPage'"
        >
          <nb-alert
            outline="danger"
            style="font-weight: 500"
            *ngIf="!addressForm.valid"
          >
            Unable to get exact address, please fill in missing
            information</nb-alert
          >

          <div class="form-floating">
            <input
              required
              type="tel"
              nbInput
              class="form-control"
              fullWidth
              id="address1"
              maxlength="30"
              formControlName="address1"
              autocomplete="off"
              placeholder="Street No."
            /><label for="address1">Street Number</label>
          </div>
          <div class="form-floating">
            <input
              required
              type="text"
              nbInput
              class="form-control"
              id="address2"
              maxlength="100"
              formControlName="address2"
              fullWidth
              autocomplete="off"
              placeholder="Street"
            /><label for="address2">Street Name</label>
          </div>
          <div class="form-floating">
            <input
              type="text"
              nbInput
              class="form-control"
              fullWidth
              id="address3"
              maxlength="100"
              formControlName="address3"
              autocomplete="off"
              placeholder="Unit"
            />
            <label for="address3">Address Prefix (Suite/Lot/Unit)</label>
          </div>
          <div class="form-floating">
            <input
              (input)="forceUppercaseConditionally($event)"
              required
              type="text"
              nbInput
              class="form-control"
              fullWidth
              id="city_name"
              maxlength="100"
              formControlName="city_name"
              autocomplete="off"
              placeholder="City"
            /><label for="city">Suburb</label>
          </div>

          <div class="form-floating">
            <input
              required
              type="tel"
              nbInput
              class="form-control"
              fullWidth
              id="postcode"
              maxlength="30"
              formControlName="postcode"
              autocomplete="off"
              placeholder="Post Code"
            /><label for="postcode">Post Code</label>
          </div>
          <nb-select
            required
            size="giant"
            fullWidth
            placeholder="State"
            formControlName="state_code"
          >
            <nb-option *ngFor="let state of stateData" [value]="state.code">{{
              state.code
            }}</nb-option>
          </nb-select>
          <div class="row row-cols-2">
            <div class="col">
              <button nbButton nbStepperPrevious ghost size="giant" fullWidth>
                Back
              </button>
            </div>
            <div class="col">
              <button
                [disabled]="!addressForm.valid"
                nbButton
                fullWidth
                size="giant"
                (click)="updateData()"
              >
                Submit
              </button>
            </div>
          </div>
        </nb-step>
      </nb-stepper>
    </form>
  </nb-card-body>
</nb-card>
