<div class="gt-p-small">
  <div class="form-floating my-3 col-xs-12 col-lg-6">
    <input
      type="text"
      class="form-control"
      [(ngModel)]="promptName"
      placeholder="Prompt Name"
    />
    <label>Prompt Name</label>
  </div>

  <ng-container *ngIf="promptName || paginatedPromptData?.length">
    <ng-container *ngIf="paginatedPromptData?.length > 1">
      <div class="py-2 border-top border-5 strong row">
        <div class="col-lg-4 mb-1">
          <button
            nbButton
            [disabled]="page === 0"
            (click)="changePagination(false)"
            class="mx-3"
          >
            <
          </button>

          <span>
            Page {{ page + 1 }} of {{ paginatedPromptData?.length }}
          </span>
          <button
            nbButton
            [disabled]="page + 1 === paginatedPromptData?.length"
            (click)="changePagination()"
            class="mx-3"
          >
            >
          </button>
        </div>
      </div>
    </ng-container>

    <div class="row row-cols-2 col-lg-6">
      <div class="d-flex my-3">
        <nb-checkbox
          [checked]="showOnHome"
          (checkedChange)="showOnHome = !showOnHome"
          >Show on Home</nb-checkbox
        >
        <nb-icon
          [icon]="'alert-circle-outline'"
          class="ms-2"
          pack="eva"
          nbTooltip="When box is ticked, guards will be able to trigger prompts manually from home page and link to a client."
          nbTrigger="focus"
          status="primary"
        ></nb-icon>
      </div>
      <div class="col" *ngIf="checkPointTriggers?.length">
        <button
          nbButton
          status="primary"
          class="nbbutton-long-text"
          (click)="openTemplate(checkPointTriggerTempalte)"
          fullWidth
        >
          Checkpoints
        </button>
      </div>
    </div>

    <div class="col-lg-6 mb-1">
      <button
        nbButton
        status="info"
        (click)="previewPrompts()"
        [disabled]="!paginatedPromptData?.length"
      >
        Preview
      </button>
      <button
        nbButton
        (click)="savePrompts()"
        [disabled]="disabeSaveButton()"
        status="success"
        class="mx-2"
      >
        {{ userPromptDetailedData?.id ? "Update" : "Save" }}
      </button>
      <button
        nbButton
        (click)="copyPrompt()"
        *ngIf="
          promptName !== userPromptDetailedData?.name &&
          userPromptDetailedData?.id
        "
        [disabled]="promptName === userPromptDetailedData?.name"
        status="primary"
        class="mx-2"
      >
        Save As
      </button>
    </div>

    <div
      cdkDropList
      [cdkDropListData]="selectedPagePrompts"
      class="col-xs-12 col-lg-6"
      (cdkDropListDropped)="dropSection($event)"
    >
      <div
        *ngFor="let field of selectedPagePrompts; let i = index"
        class="mb-3 card p-2"
        cdkDrag
        [cdkDragData]="field"
        [ngClass]="{ dragging: dragging }"
        (cdkDragStarted)="onDragStarted($event)"
        (cdkDragEnded)="onDragEnded($event)"
      >
        <div class="text-center cursor-move" cdkDragHandle>
          <nb-icon
            class="cursor-move"
            [icon]="'menu-outline'"
            pack="eva"
          ></nb-icon>
        </div>
        <div>
          <ng-template
            *ngTemplateOutlet="
              formTemplate;
              context: {
                value: field,
                index: i,
                arrayList: selectedPagePrompts,
                mainSection: true
              }
            "
          ></ng-template>
          <!-- <div class="mt-2">
            <label for="positionSelect-{{ i }}">Move to position:</label>
            <select
              id="positionSelect-{{ i }}"
              (change)="moveSection(i, $event)"
              [value]="i"
            >
              <option
                *ngFor="let pos of selectedPagePrompts; let idx = index"
                [value]="idx"
              >
                {{ idx + 1 }}
              </option>
            </select>
          </div> -->
        </div>
        <div class="border-bottom border-3"></div>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-lg-2">
      <div class="col">
        <div class="row row-cols-2">
          <div *ngFor="let opt of addPromptOptions">
            <button
              fullWidth
              nbButton
              status="primary"
              hero
              size="giant"
              class="nbbutton-long-text"
              (click)="addItem(opt)"
            >
              {{ opt.promptLabel }}
            </button>
          </div>
          <div class="col">
            <button
              class="nbbutton-long-text"
              nbButton
              (click)="removeCurrentPage()"
              *ngIf="paginatedPromptData?.length > 1"
              status="danger"
              size="giant"
              fullWidth
            >
              Delete Page
            </button>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="paginatedPromptData?.length > 1">
      <div class="py-2 strong row">
        <div class="col-lg-4 mb-1">
          <button
            nbButton
            [disabled]="page === 0"
            (click)="changePagination(false)"
            class="mx-3"
          >
            <
          </button>

          <span>
            Page {{ page + 1 }} of {{ paginatedPromptData?.length }}
          </span>
          <button
            nbButton
            [disabled]="page + 1 === paginatedPromptData?.length"
            (click)="changePagination()"
            class="mx-3"
          >
            >
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="row row-cols-3 row-cols-md-4 sc-mt-6">
    <div class="col">
      <button nbButton size="giant" ghost (click)="goBack()" fullWidth>
        Cancel
      </button>
    </div>
    <div>
      <button
        nbButton
        size="giant"
        status="info"
        (click)="previewPrompts()"
        fullWidth
        [disabled]="!paginatedPromptData?.length"
      >
        Preview
      </button>
    </div>
    <div class="col">
      <button
        nbButton
        size="giant"
        fullWidth
        (click)="savePrompts()"
        [disabled]="disabeSaveButton()"
        status="success"
      >
        {{ userPromptDetailedData?.id ? "Update" : "Save" }}
      </button>
    </div>
  </div>
</div>

<ng-template
  #formFieldOptionsTemplateRef
  let-formFieldOptionsTemplateRef="dialogRef"
  let-data
>
  <nb-card class="dialog-box gt-p-1">
    <div class="row row-cols-2">
      <div *ngFor="let opt of addPromptOptions">
        <button
          fullWidth
          nbButton
          size="giant"
          status="primary"
          class="nbbutton-long-text"
          hero
          (click)="
            addItem(opt, data?.option); formFieldOptionsTemplateRef.close()
          "
        >
          {{ opt.promptLabel }}
        </button>
      </div>
    </div>

    <div class="mt-4">
      <button
        nbButton
        size="giant"
        class="nbbutton-long-text"
        fullWidth
        status="danger"
        (click)="formFieldOptionsTemplateRef.close()"
      >
        Cancel
      </button>
    </div>
  </nb-card>
</ng-template>

<ng-template
  let-field="value"
  let-index="index"
  let-arrayList="arrayList"
  let-mainSection="mainSection"
  let-nestedKey="nestedKey"
  let-outerField="outerField"
  #formTemplate
>
  <div class="my-2">
    <div class="strong d-flex">
      <!-- // TODO: use pipe-->
      <span *ngIf="mainSection" class="me-1">SECTION {{ index + 1 }}: </span>
      <span *ngIf="!mainSection" class="me-1"
        >{{ outerField.description }} > {{ nestedKey }}
      </span>
      <span *ngIf="mainSection" class="me-1">
        {{ getPromptLabel(field) }}
      </span>
      <nb-icon
        pack="eva"
        icon="trash"
        class="error-message ms-auto"
        (click)="deleteSection(arrayList, index)"
      ></nb-icon>
    </div>
  </div>
  <div>
    <ng-container *ngIf="field.type === 'output'">
      <div class="row row-cols-1">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="field.description"
            placeholder="Message*"
          />
          <label>Message*</label>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="field.type === 'input'">
      <div>
        <div class="row row-cols-1">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="field.description"
              placeholder="Description / Question*"
            />
            <label>Description / Question*</label>
          </div>

          <ng-container *ngIf="field?.field === 'checkbox'">
            <div
              cdkDropList
              #currentList="cdkDropList"
              [cdkDropListData]="field.options"
              class="drag-drop-container drag-list mt-3"
              (cdkDropListDropped)="drop($event)"
              *ngIf="field.options?.length"
            >
              <div
                *ngFor="let opt of field.options; let i = index"
                class="mb-3"
              >
                <div
                  class="d-flex drag-box col-xxl-6"
                  [ngClass]="
                    opt?.selectSubOptions?.length ? 'nested-prompt-heading' : ''
                  "
                >
                  <div class="d-flex flex-grow-1 grab-item">
                    <div class="pe-1 cursor-grab" cdkDrag>
                      <nb-icon
                        nbPrefix
                        icon="menu"
                        pack="eva"
                        class="cursor-grab"
                      ></nb-icon>
                    </div>
                    <div class="flex-grow-1">{{ opt?.key }}</div>
                  </div>

                  <div
                    (click)="field?.options?.splice(i, 1)"
                    class="mx-4"
                    *ngIf="!field?.singleChoice"
                  >
                    <nb-icon
                      icon="trash"
                      class="color-basic-text"
                      pack="eva"
                    ></nb-icon>
                  </div>
                  <!--nested feature-->
                  <div
                    (click)="
                      openTemplate(formFieldOptionsTemplateRef, field, opt)
                    "
                  >
                    <nb-icon icon="plus" pack="eva" status="primary"></nb-icon>
                  </div>
                </div>
                <!--nested feature structure-->
                <div>
                  <ng-container *ngIf="opt?.selectSubOptions?.length">
                    <div
                      *ngFor="
                        let subField of opt?.selectSubOptions;
                        let subIndex = index
                      "
                      class="nested-prompt"
                    >
                      <ng-template
                        *ngTemplateOutlet="
                          formTemplate;
                          context: {
                            value: subField,
                            index: subIndex,
                            arrayList: opt?.selectSubOptions,
                            mainSection: false,
                            nestedKey: opt?.key,
                            outerField: field
                          }
                        "
                      ></ng-template>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <ng-container *ngIf="field?.singleChoice !== 1">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Add Option"
                      [(ngModel)]="tempOption"
                    />
                    <label> Add Answer </label>
                  </div>
                </div>
                <div class="ms-1">
                  <button
                    nbButton
                    size="giant"
                    [disabled]="!tempOption"
                    status="primary"
                    (click)="
                      tempOption ? field.options.push({ key: tempOption }) : '';
                      tempOption = ''
                    "
                  >
                    <nb-icon
                      nbSuffix
                      icon="plus"
                      pack="eva"
                      style="color: var(--button-filled-primary) !important"
                    ></nb-icon>
                  </button>
                </div>
              </div>

              <div class="form-floating">
                <input
                  type="tel"
                  (input)="maxSelectionsAllowedValidation($event, field)"
                  nbTooltip="This determines the maximum number of options a user can select."
                  class="form-control"
                  [(ngModel)]="field.allowedSelections"
                  placeholder="Answers Allowed "
                />
                <label>Answers Allowed</label>
              </div>
            </ng-container>
          </ng-container>

          <div class="form-floating" *ngIf="field?.field !== 'checkbox'">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="field.value"
              placeholder="Default Value (optional)"
            />
            <label>Default Value</label>
          </div>

          <div class="col">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="requiredField"
                [(ngModel)]="field.required"
              />
              <label for="requiredField">Required</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                [(ngModel)]="field.showInReports"
                id="reportField"
              />
              <label for="reportField"> Include in client reports</label>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template
  #checkPointTriggerTempalte
  let-checkPointTriggerTempalte="dialogRef"
>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header>
      Checkpoint
      <nb-icon
        (click)="checkPointTriggerTempalte.close()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon
    ></nb-card-header>

    <div class="mt-2">
      <div
        *ngFor="let cp of checkPointTriggers"
        (click)="viewCheckpointDetail(cp); checkPointTriggerTempalte.close()"
        class="p-2 pointer"
      >
        <div class="table-card-data pointer">
          <div>
            <div *ngIf="cp?.qr_code" class="mb-0 pb-0 sc-floating-right">
              <img
                src="assets/images/qr-code-logo.png"
                alt=""
                width="26"
                *ngIf="cp?.qr_code"
                class="mb-0 pb-0"
              />
            </div>
            <span class="strong">
              {{ cp?.name }}
            </span>
          </div>
          <div class="mt-2">
            {{ cp?.company_name }}
          </div>
        </div>
      </div>
    </div>
  </nb-card>
</ng-template>
