import { formatDate } from '@angular/common';

export const companyTableDetails = {
  mobileTable: {
    individualDetails: [
      {
        name: 'action',
        cardRowStyle: {
          'font-weight': 'bolder',
        },
      },
      {
        name: 'updated_at',

        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'user_name',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  },
  desktopTable: [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
    },

    {
      header: 'Action',
      name: 'action',
      searchKey: 'action',
    },
    {
      header: 'Value',
      name: 'detail',
    },

    {
      header: 'Guard',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ],
};
export const userGroupTableDetails = {
  mobileTable: {
    individualDetails: [
      {
        name: 'action',
        cardRowStyle: {
          'font-weight': 'bolder',
          'float': 'right',
        },
      },
      {
        name: 'user_group_name',
        cardRowStyle: {
          'font-weight': 'bolder',
        },
      },

      {
        name: 'updated_at',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'user_name',

        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  },
  desktopTable: [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
    },

    {
      header: 'User Group',
      name: 'user_group_name',
    },
    {
      header: 'Action',
      name: 'action',
    },
    {
      header: 'Value',
      name: 'detail',
    },

    {
      header: 'Guard',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ],
};

export const subscriberTableDetails = {
  mobileTable: {
    individualDetails: [
      {
        name: 'action',
        cardRowStyle: {
          'font-weight': 'bolder',
          'float': 'right',
        },
      },
      {
        name: 'detail',
        cardRowStyle: {},
      },

      {
        name: 'updated_at',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'user_name',

        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  },
  desktopTable: [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
    },

    {
      header: 'Action',
      name: 'action',
    },

    {
      header: 'Value',
      name: 'detail',
    },

    {
      header: 'Guard',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ],
};

export const userLastLocation = {
  mobileTable: {
    individualDetails: [
      {
        name: 'action',
        cardRowStyle: {
          float: 'right',
        },
      },
      {
        name: 'user_name',
        cardRowStyle: {
          'font-weight': 'bolder',
        },
      },

      {
        name: 'updated_at',

        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  },
  desktopTable: [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
    },
    {
      header: 'Action',
      name: 'action',
    },

    {
      header: 'Guard',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ],
};
export const sosAlertDetailView = {
  desktopTable: [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
    },

    {
      header: 'Guard',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
    {
      header: 'Acknowledgment',
      columnDataStyle: {
        'white-space': 'pre-line',
      },
      nestedValue: (row: any) => {
        return row?.acknowledge_info?.updated_at
          ? [
              row?.acknowledge_info?.user_name,
              formatDate(
                row?.acknowledge_info?.updated_at,
                'd MMM y HH:mm',
                'en_US'
              ),
            ].join('\n')
          : '';
      },
    },
  ],
  mobileTable: {
    individualDetails: [
      {
        name: 'updated_at',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'user_name',
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        nestedValue: (row: any) => {
          return row?.acknowledge_info?.updated_at
            ? `Acknowledged by ${
                row?.acknowledge_info?.user_name
              } @ ${formatDate(
                row?.acknowledge_info?.updated_at,
                'd MMM y HH:mm',
                'en_US'
              )}`
            : '';
        },
      },
    ],
  },
};

// Used for Patrol History desktop and mobile
export const userUpdateTable = {
  desktopTable: [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
    },
    {
      header: 'Event Type',
      name: 'action',
    },
    {
      header: 'Company',
      name: 'company_name',
    },
    {
      header: 'Checkpoint',
      columnDataStyle: {
        'white-space': 'pre-line',
      },
      nestedValue: (row: any) => {
        return [0, 6].includes(row?.event_action)
          ? [row?.detail, row?.address].join('\n')
          : '';
      },
    },
    {
      header: 'Guard',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ],
  mobileTable: {
    individualDetails: [
      {
        name: 'updated_at',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'user_name',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'action',
        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 'bold',
          'text-transform': 'uppercase',
        },
      },
      {
        name: 'company_name',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'detail',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  },
};
// Used for scan checkpoint history desktop and mobile
export const checkPointScanTable = {
  desktopTable: [
    {
      header: 'Scanned At',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
    },
    {
      header: 'Client',
      name: 'company_name',
    },
    {
      header: 'Checkpoint',
      name: 'detail',
    },

    {
      header: 'Guard',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ],
  mobileTable: {
    individualDetails: [
      {
        name: 'company_name',
        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 'bold',
        },
      },
      {
        name: 'detail',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'updated_at',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },

      {
        name: 'user_name',

        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 'bolder',
        },
      },
    ],
  },
};
export const checkPointHistoryTable = {
  desktopTable: [
    {
      header: 'On',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
    },
    {
      header: 'Checkpoint',
      name: 'check_point_name',
    },
    {
      header: 'Client',
      name: 'company_name',
    },
    {
      header: 'Action',
      name: 'action',
    },
    {
      header: 'Change',
      name: 'detail',
    },

    {
      header: 'Guard',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ],
  mobileTable: {
    individualDetails: [
      {
        name: 'user_name',

        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },
      {
        name: 'updated_at',

        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'action',
        cardRowStyle: {
          'font-weight': 'bolder',
        },
      },
      {
        name: 'detail',
        cardRowStyle: {},
      },
      {
        name: 'check_point_name',
      },
      {
        name: 'company_name',
        cardRowStyle: { 'font-size': 'small' },
      },
    ],
  },
};
//Used for job history desktop and mobile
export const jobHistoryTable = {
  mobileTable: {
    individualDetails: [
      {
        name: 'updated_at',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 'bold',
        },
      },
      {
        name: 'action',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'user_name',

        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },
    ],
  },
};

// beta user feedback tableDetails
export const betaFeedbackTable = {
  desktopTable: [
    {
      header: 'Submitted',
      name: 'created_at',
      datetimeObj: true,
      sortKey: 'created_at',
    },

    {
      header: 'Description',
      name: 'feedback',
    },

    {
      header: 'Status',
      name: 'status',
    },
  ],
  mobileTable: {
    individualDetails: [
      {
        name: 'status',
        // prefix: 'Status:',
        cardRowStyle: {
          // 'float': 'right',
          'font-size': '0.8rem',
          'font-weight': 'bold',
        },
      },

      {
        name: 'created_at',
        prefix: 'Submitted:',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'feedback',
        cardRowStyle: {
          'margin-top': '1rem',
        },
      },
    ],
  },
};
