<!--mobile-only view html-->
<div class="p-1 mobile-only">
  <div style="display: flex; justify-content: space-between">
    <div
      *ngFor="let date of weekDays"
      class="mobile-calendar"
      [ngStyle]="
        (date | date : 'yyyy-MM-dd') ===
        (mobileselectedDate | date : 'yyyy-MM-dd')
          ? {
              'background-color': 'var(--color-primary-500)',
              'color': 'var(--color-basic-200)'
            }
          : ['Su', 'Sa'].includes(getDay(date))
          ? {
              'font-weight': '700'
            }
          : {}
      "
      (click)="formatMobileRosterSchedules(date)"
    >
      <div>
        {{ getDay(date) }}
      </div>
      <div>
        {{ date | date : "dd" }}
      </div>
      <div>
        {{ date | date : "MMM" }}
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-around my-2">
    <div class="w-25 p-1">
      <button
        (click)="previousWeek()"
        nbButton
        fullWidth
        status="basic"
        size="medium"
        title="left"
      >
        <nb-icon
          pack="eva"
          icon="chevron-left"
          class="text-basic-color"
        ></nb-icon>
      </button>
    </div>
    <div class="w-50 p-1">
      <button
        *ngIf="
          (mobileselectedDate | date : 'dd MM YYYY') !=
          (today | date : 'dd MM YYYY')
        "
        (click)="goToToday()"
        nbButton
        fullWidth
        status="basic"
        size="medium"
      >
        TODAY
      </button>
    </div>
    <div class="w-25 p-1">
      <button
        (click)="nextWeek()"
        nbButton
        fullWidth
        status="basic"
        size="medium"
        title="right"
      >
        <nb-icon
          pack="eva"
          icon="chevron-right"
          class="text-basic-color"
        ></nb-icon>
      </button>
    </div>
  </div>

  <ng-container *ngIf="conflictData?.length && (isAdmin || isDispatchUser)">
    <div
      class="sc-text-center"
      (click)="openConflictTemplate(conflictTemplate)"
    >
      <button nbButton fullWidth hero status="danger" size="large">
        Conflicts
      </button>
    </div>
  </ng-container>

  <div class="mt-2">
    <!--data section-->
    <div *ngFor="let schedule of mobileRosterScheduleData">
      <div
        (click)="
          openEventDetailPage(schedule, mobileselectedDate, choiceTemplateRef)
        "
        [ngStyle]="schedule?.patrol_route_details ? {} : cardStyling?.groupStyle?.[getJobStatus(schedule, mobileselectedDate)]"
        class="scheduled-item"
      >
        <div
          class="row"
          [ngClass]="isAdmin || isDispatchUser ? 'row-cols-2' : 'row-cols-1'"
        >
          <div
            class="col-4"
            *ngIf="isAdmin || isDispatchUser"
            style="
              display: flex;
              flex-direction: column-reverse;
              justify-content: space-between;
            "
          >
            <div class="event-type">
              {{
                schedule?.patrol_route_details
                  ? "Patrol"
                  : (schedule?.job_details?.job_type | titlecase)
              }}
            </div>
            <div
              class="assignee-list"
              *ngIf="getEventAssignees(schedule, mobileselectedDate)?.length"
            >
              <div>
                <span
                  [ngClass]="
                    isGuardInConflict(
                      mobileselectedDate,
                      getEventAssignees(schedule, mobileselectedDate)[0]?.id,
                      schedule
                    )
                      ? 'conflictGuard'
                      : 'strong'
                  "
                >
                  {{
                    getFullName(
                      getEventAssignees(schedule, mobileselectedDate)[0]
                    )
                  }}
                </span>
              </div>
              <button
                nbButton
                class="mt-1"
                size="tiny"
                *ngIf="
                  getEventAssignees(schedule, mobileselectedDate)?.length > 1 &&
                  !schedule?.showAllAssignees
                "
                (click)="
                  schedule.showAllAssignees = true;
                  $event.stopImmediatePropagation()
                "
              >
                {{
                  getEventAssignees(schedule, mobileselectedDate)?.length - 1
                }}
                more
              </button>
              <div *ngIf="schedule?.showAllAssignees">
                <div
                  *ngFor="
                    let assignee of getEventAssignees(
                      schedule,
                      mobileselectedDate
                    ).slice(1)
                  "
                >
                  <span
                    [ngClass]="
                      isGuardInConflict(
                        mobileselectedDate,
                        assignee?.id,
                        schedule
                      )
                        ? 'conflictGuard'
                        : 'strong'
                    "
                  >
                    {{ getFullName(assignee) }}
                  </span>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                !getEventAssignees(schedule, mobileselectedDate)?.length &&
                (isAdmin || isDispatchUser)
              "
              class="danger-text-info strong"
            >
              Unassigned
            </div>
          </div>
          <div [ngClass]="isAdmin || isDispatchUser ? 'col-8 ' : 'col-12'">
            <div
              class="sc-floating-right"
              *ngIf="trashIconIf(schedule, mobileselectedDate)"
            >
              <nb-icon
                pack="eva"
                icon="trash"
                class="trash-icon"
                (click)="
                  openTemplate(
                    deleteScheduleTemplate,
                    schedule,
                    mobileselectedDate
                  );
                  $event.stopImmediatePropagation()
                "
              ></nb-icon>
            </div>
            <span
              *ngIf="schedule?.job_details?.job_type"
              [ngStyle]="cardStyling?.status?.[getJobStatus(schedule, mobileselectedDate)]"
              [ngClass]="
                !(isAdmin || isDispatchUser) ? 'sc-floating-right' : ''
              "
            >
              {{ getJobStatus(schedule, mobileselectedDate) }}
            </span>
            <div *ngIf="!(isAdmin || isDispatchUser)" class="strong">
              {{
                schedule?.patrol_route_details
                  ? "Patrol"
                  : (schedule?.job_details?.job_type | titlecase)
              }}
            </div>
            <div class="strong">
              {{ schedule?.start_time }} - {{ schedule?.end_time }}
            </div>

            <div>
              {{ getTimeDifference(schedule) }}
              Hours
            </div>

            <div *ngIf="schedule?.job_details?.job_type">
              <!--job html code-->

              <div class="strong">
                {{ schedule?.job_details?.site_name }}
              </div>
              <div>
                {{ schedule?.job_details?.company_name }}
              </div>
            </div>

            <div *ngIf="schedule?.patrol_route_details">
              <!--patrol route html code-->
              <div>
                {{ getPatrolRouteName(schedule, mobileselectedDate) }}
              </div>

              <div>
                <nb-icon pack="eva" icon="pin"></nb-icon>
                {{ getPatrolCheckpointCount(schedule, mobileselectedDate) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- NO job present for the date-->
    <div
      class="centered"
      *ngIf="!mobileRosterScheduleData?.length && mobileselectedDate"
    >
      <nb-alert outline="basic">
        No jobs rostered for
        {{ mobileselectedDate | customdate : "d MMM y" }}</nb-alert
      >
    </div>
  </div>
</div>
<div class="mt-1 mx-1 mobile-only">
  <div class="fixed-zoom-btn">
    <button
      class="button"
      nbButton
      shape="semi-round"
      (click)="openSearchTemplate(searchTemplateRef)"
    >
      <span
        ><nb-icon
          pack="eva"
          icon="maximize-outline"
          status="info"
          style="width: 2.5rem; height: 2.5rem"
        ></nb-icon
      ></span>
    </button>
  </div>
</div>
<div class="mt-1 mx-1 mobile-only" *ngIf="isAdmin || isDispatchUser">
  <div class="fixed-add-btn">
    <button
      class="button"
      nbButton
      shape="rectangle"
      status="primary"
      (click)="addStaticSchedule(mobileselectedDate)"
    >
      <span>+</span>
    </button>
  </div>
</div>

<!-- DESKTOP VIEW -->
<div class="large-view-only mt-1">
  <div class="roster-functions">
    <div class="function-child">
      <button
        (click)="previousWeek()"
        nbButton
        fullWidth
        status="basic"
        size="giant"
        title="previous"
      >
        <nb-icon
          pack="eva"
          icon="chevron-left"
          class="color-basic-text"
        ></nb-icon>
      </button>
    </div>

    <div class="function-child">
      <button
        *ngIf="
          (mobileselectedDate | date : 'dd MM YYYY') !=
          (today | date : 'dd MM YYYY')
        "
        (click)="goToToday()"
        nbButton
        fullWidth
        size="giant"
      >
        Today
      </button>
    </div>

    <div class="function-child">
      <button
        (click)="nextWeek()"
        nbButton
        fullWidth
        status="basic"
        title="right"
        size="giant"
        title="next"
      >
        <nb-icon
          pack="eva"
          icon="chevron-right"
          class="color-basic-text"
        ></nb-icon>
      </button>
    </div>
    <div class="function-child">
      <button
        (click)="openSearchTemplate(searchTemplateRef)"
        nbButton
        size="giant"
        fullWidth
        status="primary"
        hero
      >
        Search Jobs
      </button>
    </div>

    <div class="function-child">
      <button
        *ngIf="conflictData?.length && (isAdmin || isDispatchUser)"
        (click)="openConflictTemplate(conflictTemplate)"
        nbButton
        status="danger"
        size="giant"
        fullWidth
      >
        Conflicts
      </button>
    </div>

    <div class="function-child">
      <div class="slider-section">
        <div class="strong">Show {{ desktopDayDuration + 1 }} Days</div>
        <input
          class="range-slider-input"
          type="range"
          min="1"
          max="6"
          [value]="desktopDayDuration"
          step="1"
          pattern="\d*"
          title="daysToShow"
        />
      </div>
    </div>
  </div>

  <div class="roster-table">
    <table class="">
      <thead>
        <tr>
          <th
            *ngFor="let date of weekDays"
            [ngClass]="isAdmin || isDispatchUser ? 'pointer' : ''"
            (click)="addStaticSchedule(date)"
            [ngStyle]="
              ['Sunday', 'Saturday'].includes(getDay(date))
                ? { 'font-weight': '700' }
                : { 'font-weight': '400' }
            "
          >
            <div class="weekday">
              <div>
                <div>
                  {{ date | customdate : "d MMM y" }}
                </div>
                <div>
                  {{ getDay(date) }}
                </div>
              </div>

              <div *ngIf="isAdmin || isDispatchUser" class="addJob">+</div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="groupByData?.length">
        <tr *ngFor="let grpEvent of groupByData">
          <ng-container *ngIf="grpEvent?.event_data?.length">
            <td *ngFor="let date of weekDays">
              <div
                class="client-name strong"
                *ngIf="hasEventOnDay(date, grpEvent)"
              >
                {{ grpEvent?.detail?.company_name }}
              </div>

              <div *ngFor="let schedule of grpEvent?.event_data">
                <div
                  class="scheduled-item"
                  [ngStyle]="schedule?.patrol_route_details ? {} : cardStyling?.groupStyle?.[getJobStatus(schedule, date)]"
                  (click)="
                    openEventDetailPage(schedule, date, choiceTemplateRef);
                    $event.stopImmediatePropagation()
                  "
                  *ngIf="
                    schedule?.event_dates?.includes(
                      date | date : 'yyyy-MM-dd'
                    ) &&
                    (isAdmin ||
                      isDispatchUser ||
                      isGuardPresentCheck(schedule, date)) &&
                    (schedule?.repeat_type !== 'once' ||
                      (schedule?.repeat_type == 'once' &&
                        (schedule?.start_day | date : 'yyyy-MM-dd') ===
                          (date | date : 'yyyy-MM-dd')))
                  "
                >
                  <div
                    class="conflict-detected"
                    *ngIf="isScheduleInConflict(date, schedule)"
                  >
                    Conflict Detected
                  </div>

                  <div
                    class="sc-floating-right"
                    *ngIf="trashIconIf(schedule, date)"
                  >
                    <nb-icon
                      pack="eva"
                      icon="trash"
                      class="trash-icon"
                      (click)="
                        openTemplate(deleteScheduleTemplate, schedule, date);
                        $event.stopImmediatePropagation()
                      "
                    ></nb-icon>
                  </div>
                  <div
                    class="row"
                    [ngClass]="
                      isAdmin || isDispatchUser ? 'row-cols-2' : 'row-cols-1'
                    "
                  >
                    <div
                      class="col-4"
                      *ngIf="isAdmin || isDispatchUser"
                      style="
                        display: flex;
                        flex-direction: column-reverse;
                        justify-content: space-between;
                      "
                    >
                      <div class="event-type">
                        {{
                          schedule?.patrol_route_details
                            ? getPatrolRouteName(schedule, date)
                            : (schedule?.job_details?.job_type | titlecase)
                        }}
                      </div>
                      <div
                        *ngIf="getEventAssignees(schedule, date)?.length"
                        class="assignee-list"
                      >
                        <div>
                          <span
                            [ngClass]="
                              isGuardInConflict(
                                date,
                                getEventAssignees(schedule, date)[0]?.id,
                                schedule
                              )
                                ? 'conflictGuard'
                                : 'strong'
                            "
                          >
                            {{
                              getFullName(getEventAssignees(schedule, date)[0])
                            }}
                          </span>
                        </div>
                        <button
                          class="mt-1"
                          nbButton
                          size="tiny"
                          *ngIf="
                            getEventAssignees(schedule, date)?.length > 1 &&
                            !schedule?.showAllAssignees
                          "
                          (click)="
                            schedule.showAllAssignees = true;
                            $event.stopImmediatePropagation()
                          "
                        >
                          {{ getEventAssignees(schedule, date)?.length - 1 }}
                          more
                        </button>
                        <div *ngIf="schedule?.showAllAssignees">
                          <div
                            *ngFor="
                              let assignee of getEventAssignees(
                                schedule,
                                date
                              ).slice(1)
                            "
                          >
                            <span
                              [ngClass]="
                                isGuardInConflict(date, assignee?.id, schedule)
                                  ? 'conflictGuard'
                                  : 'strong'
                              "
                            >
                              {{ getFullName(assignee) }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="
                          !getEventAssignees(schedule, date)?.length &&
                          (isAdmin || isDispatchUser)
                        "
                        class="danger-text-info strong"
                      >
                        Unassigned
                      </div>
                    </div>

                    <div
                      [ngClass]="
                        isAdmin || isDispatchUser ? 'col-8 ' : 'col-12'
                      "
                    >
                      <span
                        *ngIf="schedule?.job_details?.job_type"
                        [ngStyle]="cardStyling?.status?.[getJobStatus(schedule, date)]"
                        [ngClass]="
                          !(isAdmin || isDispatchUser)
                            ? 'sc-floating-right'
                            : ''
                        "
                      >
                        {{ getJobStatus(schedule, date) }}
                      </span>

                      <div *ngIf="!(isAdmin || isDispatchUser)" class="strong">
                        {{
                          schedule?.patrol_route_details
                            ? ""
                            : (schedule?.job_details?.job_type | titlecase)
                        }}
                      </div>

                      <div class="strong">
                        {{ schedule?.start_time }} -
                        {{ schedule?.end_time }}
                      </div>

                      <div>{{ getTimeDifference(schedule) }} Hours</div>

                      <div *ngIf="schedule?.job_details?.job_type">
                        <!--job html code-->

                        <div class="strong">
                          {{ schedule?.job_details?.site_name }}
                        </div>
                      </div>
                      <div *ngIf="schedule?.patrol_route_details">
                        <!--patrol route html code-->
                        <div *ngIf="!(isAdmin || isDispatchUser)">
                          {{ getPatrolRouteName(schedule, date) }}
                        </div>

                        <div>
                          <nb-icon pack="eva" icon="pin"></nb-icon>
                          {{ getPatrolCheckpointCount(schedule, date) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template
  #deleteScheduleTemplate
  let-deleteScheduleTemplate="dialogRef"
  let-data
>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header
      >Delete
      <nb-icon
        (click)="deleteScheduleTemplate.close()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <div>
      <div class="sc-mt-6" *ngIf="data?.repeat_type !== 'once'">
        <nb-radio-group [(ngModel)]="deleteOptionSelected">
          <nb-radio [value]="1">
            This
            {{
              data?.job_details?.job_type
                ? data?.job_details?.job_type + " job"
                : "patrol"
            }}
          </nb-radio>
          <nb-radio [value]="2"> This and all future occurrences </nb-radio>
        </nb-radio-group>
      </div>
      <div class="sc-mt-6" *ngIf="data?.repeat_type == 'once'">
        <nb-alert status="danger">
          This
          {{
            data?.job_details?.job_type
              ? data?.job_details?.job_type + " job"
              : "patrol"
          }}</nb-alert
        >
      </div>
    </div>
    <div class="sc-mt-6">
      <button
        nbButton
        size="giant"
        fullWidth
        (click)="deleteScheduleEvents(data); deleteScheduleTemplate.close()"
      >
        Delete
      </button>
    </div>
  </nb-card>
</ng-template>

<ng-template #conflictTemplate let-conflictTemplate="dialogRef">
  <nb-card class="dialog-box">
    <nb-card-header
      >Conflicts
      <nb-icon
        (click)="conflictTemplate.close()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <div *ngFor="let conflictItem of conflictData">
      <div class="sc-head5 sc-text-center sc-mt-6">
        {{ conflictItem?.eventDate | customdate : "d MMM y" }}
      </div>
      <div
        *ngFor="let conflict of conflictItem?.conflictInfo | keyvalue"
        class=""
      >
        <span class="sc-head5 mb-3 ms-3">
          {{
            getConflictAssigneeInfo(conflict?.value)?.full_name ||
              getConflictAssigneeInfo(conflict?.value)?.email
          }}
        </span>
        <div
          *ngFor="let conflictSchedule of getConflictSchedules(conflict?.value)"
          class="m-1"
        >
          <div
            (click)="
              openEventDetailPage(
                conflictSchedule,
                conflictItem?.eventDate,
                choiceTemplateRef
              );
              conflictTemplate.close()
            "
            [ngStyle]="conflictSchedule?.patrol_route_details ? {} : cardStyling?.groupStyle?.[getJobStatus(conflictSchedule, conflictItem?.eventDate)]"
            class="conflict-item"
            *ngIf="
              !conflictSchedule?.exception_dates?.includes(
                conflictItem?.eventDate | date : 'yyyy-MM-dd'
              )
            "
          >
            <div>
              <div class="strong">
                {{
                  conflictSchedule?.patrol_route_details
                    ? "Patrol"
                    : (conflictSchedule?.job_details?.job_type | titlecase)
                }}
              </div>
              <div class="sc-floating-right">
                {{ getTimeDifference(conflictSchedule) }} Hours
              </div>
              <div class="strong">
                {{ conflictSchedule?.start_time }} -
                {{ conflictSchedule?.end_time }}
              </div>
            </div>
            <div *ngIf="conflictSchedule?.job_details?.job_type">
              <!--job html code-->

              <span
                class="sc-floating-right"
                [ngStyle]="cardStyling?.status?.[getJobStatus(conflictSchedule, conflictItem?.eventDate)]"
              >
                {{ getJobStatus(conflictSchedule, conflictItem?.eventDate) }}
              </span>
              <div class="strong">
                {{ conflictSchedule?.job_details?.site_name }}
              </div>
              <div>
                {{ conflictSchedule?.job_details?.company_name }}
              </div>
            </div>

            <div *ngIf="conflictSchedule?.patrol_route_details">
              <!--patrol route html code-->
              <div>
                {{
                  getPatrolRouteName(conflictSchedule, conflictItem?.eventDate)
                }}
              </div>

              <div>
                <nb-icon pack="eva" icon="pin"></nb-icon>
                {{
                  getPatrolCheckpointCount(
                    conflictSchedule,
                    conflictItem?.eventDate
                  )
                }}
              </div>
            </div>
            <div class="sc-mt-6 d-flex mx-auto" *ngIf="conflictItem?.eventDate">
              <button
                class="mx-auto"
                nbButton
                status="warning"
                (click)="
                  reassigneGuards(
                    modifyAssigneeRef,
                    conflictSchedule,
                    conflictItem?.eventDate,
                    getConflictAssigneeInfo(conflict?.value)
                  );
                  $event.stopImmediatePropagation();
                  conflictTemplate.close()
                "
              >
                Reassign Guard
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nb-card>
</ng-template>

<!-- Dialogue for assign users -->
<ng-template #modifyAssigneeRef let-data let-modifyAssigneeRef="dialogRef">
  <nb-card class="dialog-box">
    <nb-card-header class="sc-text-center"> Assigned Guards </nb-card-header>
    <nb-card-body>
      <div class="form-floating">
        <input
          class="form-control"
          fullWidth
          (input)="getAssignees($event, data)"
          nbInput
          type="text"
          placeholder="Search/Select Assignee"
          [nbAutocomplete]="assigneeNgModel"
        /><label>Search/Select Guards</label>
      </div>
      <nb-autocomplete #assigneeNgModel>
        <nb-option
          (click)="onAssigneeSelect(guard, data)"
          *ngFor="let guard of guardsList"
          [value]="guard.full_name"
          >{{ guard?.full_name ? guard.full_name : guard.email }}
          <small *ngIf="guard?.validStateLicense">
            <nb-icon
              class="sc-floating-right"
              [icon]="'done-all-outline'"
              pack="eva"
              [attr.aria-label]="'User with a valid state license'"
            >
            </nb-icon>
            <!-- {{ guard?.validStateLicense.license_class }} -->
          </small>
        </nb-option>
      </nb-autocomplete>

      <div class="sc-mt-6">
        <button
          nbButton
          fullWidth
          size="giant"
          (click)="modifyAssigneeRef.close()"
        >
          Close
        </button>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #choiceTemplateRef let-data let-choiceTemplateRef="dialogRef">
  <nb-card class="dialog-box">
    <nb-card-header>
      Choose
      <nb-icon
        (click)="choiceTemplateRef.close()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <div class="row row-cols-1">
        <div
          class="col"
          (click)="
            data?.schedule?.job_details?.job_type
              ? openJobDetailPage(data?.schedule, data?.date)
              : openPatrolRoutePage(data?.schedule, data?.date);
            choiceTemplateRef.close()
          "
        >
          <button nbButton size="giant" fullWidth>
            Open this
            {{
              data?.schedule?.job_details?.job_type
                ? data?.schedule?.job_details?.job_type
                : ("Patrol" | titlecase)
            }}
            job
          </button>
        </div>
        <div
          class="col"
          (click)="
            choiceTemplateRef.close(); editSchedule(data?.schedule, data?.date)
          "
        >
          <button nbButton size="giant" fullWidth>Edit Schedule</button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #searchTemplateRef let-data let-searchTemplateRef="dialogRef">
  <nb-card class="dialog-box">
    <nb-card-header>
      Search Jobs
      <nb-icon
        (click)="searchTemplateRef.close()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <div class="flex-grow-1 form-floating">
        <input
          class="form-control"
          nbInput
          fullWidth
          placeholder="Pick Date Range"
          [nbDatepicker]="formpicker"
          [(ngModel)]="searchDateRange"
        />
        <label for="date_range">Pick Date Range</label>
        <nb-rangepicker
          #formpicker
          (rangeChange)="handleDateChange($event)"
        ></nb-rangepicker>
      </div>
      <nb-form-field class="sc-search-input-box" *ngIf="searchDateRange">
        <div class="form-floating">
          <input
            class="form-control"
            type="text"
            nbInput
            #customerAutoInput
            fullWidth
            placeholder="Filter Jobs"
            (keyup)="filterJobs($event)"
          />
          <label>Filter Jobs</label>
        </div>
      </nb-form-field>
      <div *ngIf="!searchResults?.length" class="my-3">
        <nb-alert outline="basic" style="font-weight: 500"
          >No Records Match Your Search Criteria</nb-alert
        >
      </div>

      <div *ngFor="let searchItem of searchResults">
        <div class="sc-head5 sc-text-center sc-mt-6">
          {{ searchItem?.eventDate | customdate : "d MMM y" }}
        </div>
        <div *ngFor="let schedule of searchItem?.dayJobs">
          <div
            (click)="
              openEventDetailPage(
                schedule,
                searchItem?.eventDate,
                choiceTemplateRef
              );
              searchTemplateRef.close()
            "
            [ngStyle]="schedule?.patrol_route_details ? {} : cardStyling?.groupStyle?.[getJobStatus(schedule, searchItem?.eventDate)]"
            class="scheduled-item"
          >
            <div
              class="row"
              [ngClass]="
                isAdmin || isDispatchUser ? 'row-cols-2' : 'row-cols-1'
              "
            >
              <div
                class="col-4"
                *ngIf="isAdmin || isDispatchUser"
                style="
                  display: flex;
                  flex-direction: column-reverse;
                  justify-content: space-between;
                "
              >
                <div class="event-type">
                  {{
                    schedule?.patrol_route_details
                      ? "Patrol"
                      : (schedule?.job_details?.job_type | titlecase)
                  }}
                </div>
                <div
                  class="assignee-list"
                  *ngIf="
                    getEventAssignees(schedule, searchItem?.eventDate)?.length
                  "
                >
                  <div>
                    <span
                      [ngClass]="
                        isGuardInConflict(
                          searchItem?.eventDate,
                          getEventAssignees(schedule, searchItem?.eventDate)[0]
                            ?.id,
                          schedule
                        )
                          ? 'conflictGuard'
                          : 'strong'
                      "
                    >
                      {{
                        getFullName(
                          getEventAssignees(schedule, searchItem?.eventDate)[0]
                        )
                      }}
                    </span>
                  </div>
                  <button
                    nbButton
                    class="mt-1"
                    size="tiny"
                    *ngIf="
                      getEventAssignees(schedule, searchItem?.eventDate)
                        ?.length > 1 && !schedule?.showAllAssignees
                    "
                    (click)="
                      schedule.showAllAssignees = true;
                      $event.stopImmediatePropagation()
                    "
                  >
                    {{
                      getEventAssignees(schedule, searchItem?.eventDate)
                        ?.length - 1
                    }}
                    more
                  </button>
                  <div *ngIf="schedule?.showAllAssignees">
                    <div
                      *ngFor="
                        let assignee of getEventAssignees(
                          schedule,
                          searchItem?.eventDate
                        ).slice(1)
                      "
                    >
                      <span
                        [ngClass]="
                          isGuardInConflict(
                            searchItem?.eventDate,
                            assignee?.id,
                            schedule
                          )
                            ? 'conflictGuard'
                            : 'strong'
                        "
                      >
                        {{ getFullName(assignee) }}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="
                    !getEventAssignees(schedule, searchItem?.eventDate)
                      ?.length &&
                    (isAdmin || isDispatchUser)
                  "
                  class="danger-text-info strong"
                >
                  Unassigned
                </div>
              </div>
              <div [ngClass]="isAdmin || isDispatchUser ? 'col-8 ' : 'col-12'">
                <div
                  class="sc-floating-right"
                  *ngIf="trashIconIf(schedule, searchItem?.eventDate)"
                >
                  <nb-icon
                    pack="eva"
                    icon="trash"
                    class="trash-icon"
                    (click)="
                      openTemplate(
                        deleteScheduleTemplate,
                        schedule,
                        searchItem?.eventDate
                      );
                      $event.stopImmediatePropagation()
                    "
                  ></nb-icon>
                </div>
                <span
                  *ngIf="schedule?.job_details?.job_type"
                  [ngStyle]="cardStyling?.status?.[getJobStatus(schedule, searchItem?.eventDate)]"
                  [ngClass]="
                    !(isAdmin || isDispatchUser) ? 'sc-floating-right' : ''
                  "
                >
                  {{ getJobStatus(schedule, searchItem?.eventDate) }}
                </span>
                <div *ngIf="!(isAdmin || isDispatchUser)" class="strong">
                  {{
                    schedule?.patrol_route_details
                      ? "Patrol"
                      : (schedule?.job_details?.job_type | titlecase)
                  }}
                </div>
                <div class="strong">
                  {{ schedule?.start_time }} - {{ schedule?.end_time }}
                </div>

                <div>
                  {{ getTimeDifference(schedule) }}
                  Hours
                </div>

                <div *ngIf="schedule?.job_details?.job_type">
                  <!--job html code-->

                  <div class="strong">
                    {{ schedule?.job_details?.site_name }}
                  </div>
                  <div>
                    {{ schedule?.job_details?.company_name }}
                  </div>
                </div>

                <div *ngIf="schedule?.patrol_route_details">
                  <!--patrol route html code-->
                  <div>
                    {{ getPatrolRouteName(schedule, searchItem?.eventDate) }}
                  </div>

                  <div>
                    <nb-icon pack="eva" icon="pin"></nb-icon>
                    {{
                      getPatrolCheckpointCount(schedule, searchItem?.eventDate)
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
