<nb-card class="dialog-box" *ngIf="!contactData">
  <nb-card-header>
    <b> {{ siteData ? "Add Onsite Contact" : "Add Contact " }}</b>

    <nb-icon
      (click)="onCloseDialogue(true)"
      [icon]="'close-outline'"
      class="sc-floating-right close-icon"
      pack="eva"
    ></nb-icon>
    <div
      *ngIf="
        ((clientData?.company_name || siteData?.company_name) && showAddForm) ||
        patrolReportUsers
      "
    >
      <small>
        {{
          siteData?.company_name
            ? siteData?.company_name
            : clientData?.company_name
        }}
      </small>
    </div>
  </nb-card-header>

  <nb-card-body *ngIf="!showAddForm && !patrolReportUsers">
    <nb-tag-list class="nb-tag-list">
      <nb-tag
        *ngFor="let user of existingContacts"
        [text]="user?.full_name ? user?.full_name : user?.email"
        removable
        (remove)="removeUser(user)"
        nbTooltip="{{ user?.email }}  |
          {{ user?.mobile_number }}"
      ></nb-tag>
    </nb-tag-list>
    <div class="form-floating">
      <input
        class="form-control"
        type="text"
        nbTagInput
        fullWidth
        placeholder="Contact Name"
        [(ngModel)]="searchStr"
        (input)="searchStr?.length > 2 ? getSiteContacts() : ''"
        [nbAutocomplete]="autoNgModel"
      />
      <label>Contact Name</label>
    </div>
    <nb-autocomplete #autoNgModel>
      <nb-option (click)="showAddForm = !showAddForm; contactForm.reset()">
        Add New Contact
      </nb-option>

      <nb-option *ngFor="let user of contactList" (click)="addUser(user)"
        ><div>
          {{ user?.user_full_name ? user.user_full_name : user.user_email }}
          <div>
            <small>
              {{ user?.user_full_name ? user.user_email : "" }}
              {{
                user?.user_mobile_number ? " , " + user?.user_mobile_number : ""
              }}
            </small>
          </div>
        </div>
      </nb-option></nb-autocomplete
    >

    <div class="row row-cols-1 sc-mt-6">
      <div class="col">
        <button
          size="giant"
          (click)="onCloseDialogue(true)"
          nbButton
          fullWidth
          ghost
        >
          Close
        </button>
      </div>
    </div>
  </nb-card-body>

  <nb-card-body *ngIf="showAddForm || patrolReportUsers">
    <form [formGroup]="contactForm" style="padding: 0 1rem">
      <div class="form-floating form-floating-field">
        <input
          class="form-control"
          id="first_name"
          formControlName="first_name"
          autocomplete="off"
          nbInput
          autofocus
          fullWidth
          id="firstName"
          placeholder="First Name"
          [(ngModel)]="searchStr"
          (input)="
            contactForm?.value?.first_name?.length > 2 ? getSiteContacts() : ''
          "
          (keyup.enter)="shiftFocus('lastName')"
          [nbAutocomplete]="autoNgModelField"
        />
        <label>First Name</label>
        <nb-autocomplete #autoNgModelField>
          <nb-option
            *ngFor="let user of contactList"
            (click)="selectContact(user)"
            ><div>
              {{ user?.user_full_name ? user.user_full_name : user.user_email }}
              <div>
                <small>
                  {{ user?.user_full_name ? user.user_email : "" }}
                  {{
                    user?.user_mobile_number
                      ? " , " + user?.user_mobile_number
                      : ""
                  }}
                </small>
              </div>
            </div>
          </nb-option></nb-autocomplete
        >
      </div>

      <div class="form-floating form-floating-field">
        <input
          fullWidth
          class="form-control"
          type="text"
          nbInput
          id="lastName"
          (keyup.enter)="shiftFocus('email')"
          formControlName="last_name"
          autocomplete="off"
          placeholder="Last Name"
        />
        <label>Last Name</label>
      </div>

      <div class="form-floating form-floating-field">
        <input
          fullWidth
          class="form-control"
          type="text"
          nbInput
          id="email"
          (keyup.enter)="shiftFocus('phone')"
          formControlName="email"
          autocomplete="off"
          placeholder="Email"
          autocorrect="off"
          autocapitalize="off"
        />
        <label>Email</label>
      </div>
      <div class="form-floating form-floating-field">
        <input
          fullWidth
          class="form-control"
          type="tel"
          nbInput
          minlength="10"
          maxlength="10"
          id="phone"
          formControlName="mobile_number"
          autocomplete="off"
          placeholder="Telephone"
        />
        <label> Telephone </label>
        <ng-container
          *ngIf="
            contactForm.controls['mobile_number'].invalid &&
            contactForm.controls['mobile_number'].touched
          "
        >
          <p
            class="error-message"
            *ngIf="contactForm.controls['mobile_number'].errors"
          >
            Mobile should be 10 digit number
          </p>
        </ng-container>
      </div>
      <div *ngIf="siteData?.length">
        <nb-checkbox
          status="primary"
          class="pointer"
          (checkedChange)="togglePrimary($event)"
        >
          Make Default for
          {{ siteData?.company_name ? siteData?.company_name : "site" }}
        </nb-checkbox>
      </div>
      <div>
        <div class="row row-cols-2">
          <div class="col">
            <button
              size="giant"
              type="button"
              (click)="
                patrolReportUsers || addNewSiteContact
                  ? onCloseDialogue(true)
                  : contactForm.reset();
                showAddForm = !showAddForm
              "
              nbButton
              ghost
              fullWidth
            >
              {{ patrolReportUsers || addNewSiteContact ? "Close" : "Back" }}
            </button>
          </div>
          <div class="col">
            <button
              size="giant"
              fullWidth
              type="submit"
              [disabled]="!contactForm.valid"
              nbButton
              (click)="createContact()"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
<ng-template #confirmRef let-data let-confirmDRef="dialogRef">
  <nb-card class="dialog-box-box gt-p-small">
    <nb-card-body>
      <div>
        Are you sure you want to add {{ data?.full_name }} to
        {{ siteData ? siteData?.company_name : clientData?.company_name }}
      </div>
      <div class="sc-mt-6" *ngIf="siteData">
        <nb-checkbox
          status="primary"
          class="pointer"
          (checkedChange)="togglePrimary($event)"
        >
          Make Default for
          <b>
            {{ siteData ? siteData?.company_name : clientData?.company_name }}
          </b>
        </nb-checkbox>
      </div>

      <div class="row row-cols-2 sc-mt-9">
        <div class="col" (click)="confirmDRef.close()">
          <button nbButton ghost size="giant" fullWidth>Cancel</button>
        </div>
        <div
          id="confirmBtn"
          class="col"
          (click)="onConfirm(data); confirmDRef.close()"
        >
          <button fullWidth nbButton size="giant">Yes</button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<nb-card class="dialog-box" *ngIf="contactData">
  <nb-card-header>
    <nb-icon
      (click)="onCloseDialogue()"
      [icon]="'close-outline'"
      class="sc-floating-right"
      pack="eva"
    ></nb-icon>
    <div class="sc-text-center mt-5 mb-4">
      <h4 class="contact-name">
        {{ contactData?.full_name }}
        <nb-icon
          *ngIf="
            isAdmin &&
            !editContact &&
            (contactData?.id || contactData?.location_contact_id)
          "
          (click)="onEditContact()"
          class="mx-3 big-menu-icon"
          [icon]="'edit'"
          pack="eva"
        ></nb-icon>
      </h4>
    </div>
    <div class="sc-text-center mb-3" *ngIf="!editContact">
      <a href="mailto: {{ contactData?.email }}">
        <nb-icon
          class="mx-3 big-menu-icon color-info-500"
          [icon]="'email'"
          pack="eva"
        ></nb-icon>
      </a>
      <a
        *ngIf="contactData?.mobile_number"
        href="tel:{{ contactData?.mobile_number }}"
      >
        <nb-icon
          class="mx-3 big-menu-icon color-success-400"
          [icon]="'phone-call'"
          pack="eva"
        ></nb-icon
      ></a>
    </div>
  </nb-card-header>

  <nb-card-body *ngIf="!editContact">
    <div class="col mt-3 mb-5">
      <div class="mb-4">
        <div class="profile-label">Email</div>
        <div class="profile-value">
          {{ contactData?.email }}
        </div>
      </div>

      <div *ngIf="contactData?.mobile_number">
        <div class="profile-label">Phone</div>
        <div class="profile-value">
          {{ contactData?.mobile_number }}
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-body *ngIf="editContact">
    <form [formGroup]="editContactForm" style="padding: 0 1rem">
      <div class="form-floating form-floating-field">
        <input
          class="form-control"
          id="first_name"
          formControlName="first_name"
          autocomplete="off"
          nbInput
          autofocus
          fullWidth
          id="firstName"
          placeholder="First Name"
          (keyup.enter)="shiftFocus('lastName')"
        />
        <label>First Name</label>
      </div>

      <div class="form-floating form-floating-field">
        <input
          fullWidth
          class="form-control"
          type="text"
          nbInput
          id="lastName"
          (keyup.enter)="shiftFocus('phone')"
          formControlName="last_name"
          autocomplete="off"
          placeholder="Last Name"
        />
        <label>Last Name</label>
      </div>

      <div class="form-floating form-floating-field">
        <input
          fullWidth
          class="form-control"
          type="text"
          nbInput
          id="email"
          formControlName="email"
          autocomplete="off"
          placeholder="Email"
          autocorrect="off"
          autocapitalize="off"
          readonly
        />
        <label>Email</label>
      </div>
      <div class="form-floating form-floating-field">
        <input
          fullWidth
          class="form-control"
          type="tel"
          nbInput
          minlength="10"
          maxlength="10"
          id="phone"
          formControlName="mobile_number"
          autocomplete="off"
          placeholder="Telephone"
        />
        <label> Telephone </label>
        <ng-container
          *ngIf="
            editContactForm.controls['mobile_number'].invalid &&
            editContactForm.controls['mobile_number'].touched
          "
        >
          <p
            class="error-message"
            *ngIf="editContactForm.controls['mobile_number'].errors"
          >
            Mobile should be 10 digit number
          </p>
        </ng-container>
      </div>

      <div>
        <div class="row row-cols-2">
          <div class="col">
            <button
              size="giant"
              type="button"
              (click)="editContact = false"
              nbButton
              ghost
              fullWidth
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              size="giant"
              fullWidth
              type="submit"
              [disabled]="!editContactForm.valid"
              nbButton
              (click)="updateContact()"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
