import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-add-edit-job-type',
  templateUrl: './add-edit-job-type.component.html',
  styleUrls: ['./add-edit-job-type.component.scss'],
})
export class AddEditJobTypeComponent implements OnInit {
  individualJobTypeData: any;
  jobTypeForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
    is_active: new UntypedFormControl(1, Validators.required),
    enable_comments: new UntypedFormControl(1),
    enable_uploads: new UntypedFormControl(1),
    enable_assignees: new UntypedFormControl(1),
    free_flow: new UntypedFormControl(1),
    reverse: new UntypedFormControl(1),
    re_open_jobs: new UntypedFormControl(24),
    type_identity: new UntypedFormControl(0),
    statuses: new UntypedFormControl([]),
    additional_info: new UntypedFormControl({}),
  });

  // status list pagination
  previous: number = 0;
  rows: number = 15;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;

  statusData: any = [];
  selectedStatuses: any = [];
  selectedStatusName: any = [];
  statusDropDownList: any = [];
  statusDropDownListDuplicate: any = [];
  jobTypeStyle: any = { selected: {}, snubbed: {} };
  jobTypeId: any;
  dialogref: any;
  jobTypeIdentities: any = [];
  constructor(
    private adminService: AdminService,
    private dialog: NbDialogService,
    private toasterService: NbToastrService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private _location: Location
  ) {
    this.route.params.subscribe({
      next: (params) => {
        if (params?.['jTKey']) {
          this.jobTypeId = params?.['jTKey'];
          this.getJobTypeById();
        }
      },
    });
  }

  ngOnInit(): void {
    this.getAllStatusList();
  }
  getAllStatusList() {
    let params: any = {};
    if (this.rows) {
      params['page_size'] = this.rows;
    }
    if (this.previous) {
      params['page'] = this.pageNum;
    }
    this.adminService.getJobStatusList(params).subscribe((resp: any) => {
      this.statusData = resp;
      this.totalRows = resp?.count;
      this.totalPages = Math.ceil(this.totalRows / this.rows);
      this.jobTypeIdentities = resp?.job_type_identities;
      this.autoAddStatus();
    });
  }
  autoAddStatus() {
    this.statusData?.results?.forEach((status: any) => {
      if (!this.jobTypeId) {
        if ([1, 2, 3].includes(status?.status_identity?.value)) {
          this.addStatus(status);
        }
      }

      this.statusDropDownListDuplicate.push(status);
    });
    setTimeout(() => {
      this.statusDropDownList = this.statusDropDownListDuplicate.filter(
        (item: any) =>
          !this.selectedStatuses?.some(
            (savedItem: any) => item?.id == savedItem?.id
          )
      );
    }, 500);
  }

  onClearForm() {
    this.jobTypeStyle = { selected: {}, snubbed: {} };
    this.individualJobTypeData = null;
    this.jobTypeForm.reset();
    this.selectedStatuses = [];
    this.statusDropDownList = [];
    this.statusDropDownListDuplicate = [];
    this.autoAddStatus();
  }
  onClickSubmit() {
    if (this.jobTypeForm.valid && this.selectedStatuses?.length) {
      this.spinnerService.show();
      let saveStatuses: any = [];
      this.selectedStatuses?.forEach((status: any, index: number) => {
        saveStatuses.push({
          job_status_id: status?.id,
          position: index,
          reverse: status?.reverse,
        });
      });
      this.jobTypeForm.controls['statuses'].setValue(saveStatuses);
      let requestData = this.jobTypeForm.value;
      requestData['additional_info'] = {
        style: this.jobTypeStyle,
      };
      if (this.individualJobTypeData) {
        this.adminService
          .editJobtype(this.individualJobTypeData.id, requestData)
          .subscribe((response: any) => {
            this.toasterService.success('', 'Job Type Updated');
            this.formatIndividualData(response);
          });
      } else {
        this.adminService
          .createJobtype(requestData)
          .subscribe((response: any) => {
            this._location.back();
            this.spinnerService.hide();
            this.toasterService.success('', 'Job Type Created');
          });
      }
    }
  }

  openAddStatusTemplate(template: TemplateRef<any>) {
    {
      this.dialogref = this.dialog.open(template, {
        context: {},
      });
    }
  }

  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getAllStatusList();
  }

  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getAllStatusList();
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  async addStatus(item: any) {
    let statusExists = this.selectedStatuses.some(
      (status: any) => status?.id === item.id
    );

    if (statusExists) {
      this.toasterService.warning('', 'Status already exists');
    } else {
      this.jobTypeForm.controls['reverse'].setValue(false);
      if ([2, 3].includes(item?.status_identity?.value)) {
        this.selectedStatuses.push(item);
      } else if (item?.status_identity?.value == 1) {
        if (this.selectedStatuses?.length == 0) {
          this.selectedStatuses.push(item);
        } else if (this.selectedStatuses?.length == 1) {
          let lastItem =
            this.selectedStatuses[this.selectedStatuses?.length - 1];
          if ([2, 3].includes(lastItem?.status_identity?.value)) {
            this.selectedStatuses.unshift(item);
          } else {
            this.selectedStatuses.push(item);
          }
        } else {
          let lastItem =
            this.selectedStatuses[this.selectedStatuses?.length - 1];
          let secondLastItem =
            this.selectedStatuses[this.selectedStatuses?.length - 2];
          let indexPos = 0;
          if ([2, 3].includes(lastItem?.status_identity?.value)) {
            indexPos = -1;
          }
          if ([2, 3].includes(secondLastItem?.status_identity?.value)) {
            indexPos = -2;
          }
          if (indexPos) {
            this.selectedStatuses.splice(indexPos, 0, item);
          } else {
            this.selectedStatuses.push(item);
          }
        }
      } else {
        this.selectedStatuses.unshift(item);
      }
      this.statusDropDownList = this.statusDropDownListDuplicate.filter(
        (item: any) =>
          !this.selectedStatuses?.some(
            (savedItem: any) => item?.id == savedItem?.id
          )
      );
      if (this.statusDropDownList?.length === 0) {
        this.dialogref?.close();
      }
    }
  }
  removeSavedStatus(item: any) {
    this.selectedStatuses = this.selectedStatuses.filter(
      (sStatus: any) => item?.id != sStatus?.id
    );
    this.statusDropDownList = this.statusDropDownListDuplicate.filter(
      (item: any) =>
        !this.selectedStatuses?.some(
          (savedItem: any) => item?.id == savedItem?.id
        )
    );
  }

  getJobTypeById() {
    this.spinnerService.show();
    this.adminService
      .getJobtypeById(this.jobTypeId)
      .subscribe((response: any) => {
        this.formatIndividualData(response);
      });
  }
  formatIndividualData(response: any) {
    this.individualJobTypeData = response;
    this.selectedStatuses = this.individualJobTypeData?.statuses;
    this.jobTypeForm.patchValue(this.individualJobTypeData);
    this.statusDropDownList = this.statusDropDownListDuplicate.filter(
      (item: any) =>
        !this.selectedStatuses?.some(
          (savedItem: any) => item?.id == savedItem?.id
        )
    );
    if (
      this.individualJobTypeData?.additional_info?.style?.hasOwnProperty(
        'snubbed'
      ) ||
      this.individualJobTypeData?.additional_info?.style?.hasOwnProperty(
        'selected'
      )
    ) {
      this.jobTypeStyle = this.individualJobTypeData?.additional_info?.style;
    } else {
      this.jobTypeStyle = { selected: {}, snubbed: {} };
    }
    this.spinnerService.hide();
  }
  changeReverse(event: any) {
    this.selectedStatuses?.forEach((status: any, index: number) => {
      status.reverse = event;
    });
  }
}
