<nb-alert class="mb-2" *ngIf="cameraError" outline="danger">
  {{ cameraError }}
</nb-alert>

<div [ngClass]="detailType === 'updateJob' ? '' : 'view-home-details-height'">
  <!-- scan check points BEGIN -->
  <ng-container *ngIf="detailType === 'scanCheckPoint'">
    <div *ngIf="dupCheckpointList?.length == 0" class="my-3">
      <nb-alert outline="basic" style="font-weight: 500"
        >No Checkpoints Near You</nb-alert
      >
    </div>

    <div *ngIf="dupCheckpointList.length > 0">
      <div class="flex-grow-1">
        <nb-form-field *ngIf="!distanceCalculation">
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              nbInput
              fullWidth
              [(ngModel)]="searchFilter"
              placeholder="Search Checkpoints"
              (keyup)="onCPFilterApply($event)"
            />
            <label>Search Checkpoints</label>
          </div>

          <nb-icon
            nbSuffix
            *ngIf="showCPFilterClose"
            [icon]="'close-outline'"
            pack="eva"
            (click)="onCPClearFilter()"
          ></nb-icon>
        </nb-form-field>
      </div>
      <ng-container *ngIf="apidataUpdation === true && !offlineMode">
        <nb-alert class="my-2" outline="basic">
          <small>
            Fetching Data from Server
            <div class="sc-floating-right">
              <div id="wave">
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
              </div>
            </div>
          </small>
        </nb-alert>
      </ng-container>
      <div class="list-fixed-height row sc-mt-6">
        <div
          class="gy-2"
          *ngFor="let checkPoint of nearestCheckPointsList"
          (click)="openCamPopup(checkPoint)"
        >
          <div class="sc-card">
            <div class="table-card-data">
              <div class="strong">
                {{ checkPoint?.name }}
              </div>
              <div>
                {{ checkPoint?.company }}
              </div>
              <div class="small-font">
                {{ checkPoint?.full_address }}
              </div>
              <div *ngIf="checkPoint?.qr_code" class="sc-mt-6">
                <img
                  src="assets/images/qr-code-logo.png"
                  alt=""
                  width="26"
                  class="mb-0 pb-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- scan check points ENDS -->

  <!-- nearestJob list section start -->

  <ng-container *ngIf="detailType === 'updateJob'">
    <ng-container *ngIf="isGuardUser">
      <div *ngIf="dupnearestJobList?.length == 0" class="my-3">
        <hr />
        <nb-alert outline="basic">No Jobs Rostered Today</nb-alert>
        <button
          nbButton
          size="giant"
          routerLink="/roster"
          fullWidth
          status="primary"
        >
          View Roster
        </button>
      </div>
    </ng-container>

    <div *ngIf="dupnearestJobList.length > 0">
      <hr />
      <ng-container *ngIf="apidataUpdation === true && !offlineMode">
        <nb-alert class="my-2" outline="basic">
          <small>
            Fetching Data from Server
            <div class="sc-floating-right">
              <div id="wave">
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
              </div>
            </div>
          </small>
        </nb-alert>
      </ng-container>
      <div *ngIf="nearestJobList?.length" class="list-fixed-height row mt-1">
        <app-card-list
          [listCardData]="nearestJobList"
          [listCardDetails]="jobCardsDetails"
          [showTableFrom]="'jobsPage'"
          (rowClick)="openEventPage($event)"
        ></app-card-list>
      </div>
    </div>
  </ng-container>
  <!-- nearestJob List end -->

  <!-- Nearest Site List BEGIN -->
  <ng-container
    *ngIf="
      ['addClientPhoto', 'addClientComment', 'userPromptInfo'].includes(
        detailType
      )
    "
  >
    <div *ngIf="dupnearestClientList.length === 0" class="my-3">
      <nb-alert outline="basic" style="font-weight: 500"
        >No Clients Near You</nb-alert
      >
    </div>
    <ng-container *ngIf="nearestClientList?.length > 0">
      <div class="sc-head6 sc-text-center">Select a Client</div>
    </ng-container>

    <div *ngIf="dupnearestClientList.length > 0">
      <nb-form-field *ngIf="!distanceCalculation">
        <div class="form-floating">
          <input
            class="form-control"
            type="text"
            nbInput
            [(ngModel)]="searchFilter"
            fullWidth
            placeholder="Filter Results"
            (keyup)="onFilterClientsApply($event)"
          />
          <label>Filter Results</label>
        </div>

        <nb-icon
          nbSuffix
          *ngIf="showSiteFilterClose"
          [icon]="'close-outline'"
          pack="eva"
          (click)="onClearClientsFilter()"
        ></nb-icon>
      </nb-form-field>
      <ng-container *ngIf="apidataUpdation === true && !offlineMode">
        <nb-alert class="my-2" outline="basic">
          <small>
            Fetching Data from Server
            <div class="sc-floating-right">
              <div id="wave">
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
              </div>
            </div>
          </small>
        </nb-alert>
      </ng-container>
      <div *ngIf="nearestClientList?.length == 0" class="my-3">
        <nb-alert outline="basic" style="font-weight: 500"
          >No Records Match Your Search Criteria</nb-alert
        >
      </div>

      <div class="list-fixed-height row sc-mt-6">
        <div
          *ngFor="let company of nearestClientList"
          (click)="updateCompany(company)"
          class="gy-2"
        >
          <div class="sc-card">
            <div class="table-card-data">
              <div class="strong">{{ company?.company_name }}</div>
              <div class="client-address">{{ company?.full_address }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Nearest Site List ENd -->

  <div
    *ngIf="detailType !== 'updateJob'"
    [ngClass]="
      detailType === 'scanCheckPoint'
        ? 'scan-checkpoint-buttons mobile-only section'
        : 'go-back mobile-only section'
    "
  >
    <div class="mx-2 mb-2">
      <button
        nbButton
        fullWidth
        class="nbbutton-long-text"
        size="giant"
        status="basic"
        (click)="goBack()"
      >
        Go Back
      </button>
    </div>
  </div>
</div>

<ng-template #commentRef let-commentRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <!--api returns an empty client list-->
    <div *ngIf="!apidataUpdation && !nearestClientList?.length">
      <nb-alert>No clients found</nb-alert>
      <div class="col" (click)="commentRef.close('close')">
        <button nbButton fullWidth size="giant" ghost>Go Back</button>
      </div>
    </div>

    <div
      *ngIf="apidataUpdation || (!apidataUpdation && nearestClientList?.length)"
    >
      <div class="col">
        <textarea
          class="form-control"
          fullWidth
          rows="10"
          cols="32"
          maxlength="340"
          [disabled]="
            !apidataUpdation && !nearestClientList?.length ? true : false
          "
          type="text"
          placeholder="Add a Comment..."
          [(ngModel)]="newComment"
        ></textarea>
      </div>
      <div class="row row-cols-2 sc-mt-6">
        <div class="col" (click)="commentRef.close('close')">
          <button nbButton fullWidth size="giant" ghost>Go Back</button>
        </div>
        <div class="col">
          <button
            (click)="commentRef.close()"
            nbButton
            size="giant"
            fullWidth
            class="nbbutton-long-text"
            [disabled]="!newComment"
          >
            Add Comment
          </button>
        </div>
      </div>
    </div>
  </nb-card>
</ng-template>
