<nb-card class="dialog-box">
  <nb-card-header
    >{{ updateView ? "Edit Client Details" : "Add New Client" }}
    <nb-icon
      (click)="onCloseDialogue()"
      [icon]="'close-outline'"
      class="sc-floating-right close-icon"
      pack="eva"
    ></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="clientForm" appFormEnterAction>
      <nb-stepper #stepper>
        <nb-step [stepControl]="nameForm" label="Contact">
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              nbInput
              id="company_name"
              formControlName="company_name"
              autocomplete="off"
              placeholder="Company Name"
              maxlength="60"
              (keyup.enter)="nextStepper($event)"
              autofocus
              fullWidth
            />
            <label for="company_name">Client Name</label>
          </div>

          <div class="row row-cols-2">
            <div class="col">
              <button
                nbButton
                fullWidth
                size="giant"
                type="button"
                ghost
                (click)="onCloseDialogue()"
              >
                Cancel
              </button>
            </div>
            <div class="col">
              <button
                nbButton
                type="button"
                fullWidth
                size="giant"
                nbStepperNext
                [disabled]="!clientForm.value?.company_name"
              >
                Next
              </button>
            </div>
          </div>
        </nb-step>
        <nb-step [stepControl]="diffForm" label="Contact">
          <div>
            <div class="form-floating">
              <input
                autocomplete="off"
                id="addressLookup"
                autofocus
                class="form-control"
                fullWidth
                #addressAutoInput
                (input)="onAddressSearch($event)"
                nbInput
                type="text"
                placeholder="Address Lookup"
                [formControl]="searchControl"
                [nbAutocomplete]="autoNgModel"
              />
              <label for="addressLookup">Address Lookup</label>
            </div>
            <nb-autocomplete
              #autoNgModel
              (selectedChange)="addressSelected($event)"
            >
              <nb-option [value]="' '"
                ><b> Enter address manually </b>
              </nb-option>
              <nb-option
                *ngFor="let item of addrLookupData$ | async"
                [value]="item"
                (click)="addressSelected(item)"
              >
                <div>
                  <span
                    ><b>{{ item.address1 + " " + item.address2 }}</b
                    >,</span
                  ><br />
                  <small
                    >{{ item.address3 ? item.address3 + " " : "" }}
                    {{ item.city_name }} {{ item.state_code }}
                    {{ item.postcode }}</small
                  >
                </div>
              </nb-option>
            </nb-autocomplete>

            <div *ngIf="enteredAddresslookup === true || updateView === true">
              <nb-alert
                outline="danger"
                style="font-weight: 500"
                *ngIf="
                  clientForm.controls['address1'].invalid ||
                  clientForm.controls['address2'].invalid ||
                  clientForm.controls['city_name'].invalid ||
                  clientForm.controls['postcode'].invalid
                "
              >
                Unable to get exact address, please fill in missing
                information</nb-alert
              >
              <div class="form-floating">
                <input
                  class="form-control"
                  type="tel"
                  nbInput
                  fullWidth
                  id="address1"
                  formControlName="address1"
                  maxlength="30"
                  autocomplete="off"
                  placeholder="Street No."
                />
                <label for="address1">Street Number</label>
              </div>
              <div class="form-floating">
                <input
                  class="form-control"
                  type="text"
                  nbInput
                  fullWidth
                  id="address2"
                  maxlength="100"
                  formControlName="address2"
                  autocomplete="off"
                  placeholder="Street"
                />
                <label for="address2">Street Name</label>
              </div>
              <div class="form-floating">
                <input
                  class="form-control"
                  type="text"
                  nbInput
                  fullWidth
                  id="address3"
                  formControlName="address3"
                  maxlength="100"
                  autocomplete="off"
                  placeholder="Unit"
                />
                <label for="address3">Address Prefix (Suite/Lot/Unit)</label>
              </div>
              <div class="form-floating">
                <input
                  class="form-control"
                  type="text"
                  nbInput
                  id="city_name"
                  fullWidth
                  formControlName="city_name"
                  maxlength="100"
                  (input)="forceUppercaseConditionally($event)"
                  autocomplete="off"
                  placeholder="City"
                />
                <label for="city_name">Suburb</label>
              </div>

              <div class="form-floating">
                <input
                  class="form-control"
                  type="tel"
                  nbInput
                  id="postcode"
                  fullWidth
                  formControlName="postcode"
                  autocomplete="off"
                  placeholder="Post Code"
                  maxlength="30"
                />
                <label for="postcode">Post Code</label>
              </div>

              <nb-select
                fullWidth
                placeholder="State"
                formControlName="state_code"
                [(selected)]="selectedItem"
              >
                <nb-option
                  *ngFor="let state of stateData"
                  [value]="state.code"
                  (click)="changeAddress(state)"
                  >{{ state.code }}</nb-option
                >
              </nb-select>
            </div>
          </div>
          <div class="row row-cols-2">
            <div class="col">
              <button nbButton size="giant" nbStepperPrevious fullWidth ghost>
                Back
              </button>
            </div>
            <div class="col">
              <button
                id="next1btn"
                type="button"
                (click)="updateData()"
                [disabled]="!clientForm.valid"
                nbButton
                size="giant"
                fullWidth
              >
                Submit
              </button>
            </div>
          </div>
        </nb-step>
      </nb-stepper>
    </form>
  </nb-card-body>
</nb-card>
