import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { AppService } from 'src/app/app.service';
import { WebcamImageComponent } from 'src/app/shared/comp/webcam-image/webcam-image.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { nearestJobDetail } from 'src/global.variables';
import { JobsService } from '../jobs/jobs.service';
import { PagesService } from '../pages.service';
import { ProfileService } from '../profile/profile.service';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'app-internal-message',
  templateUrl: './internal-message.component.html',
  styleUrls: ['./internal-message.component.scss'],
})
export class InternalMessageComponent implements OnInit {
  userMessageList: any = [];
  selectedUser: any;
  messageList: any = [];
  subscriberUserId: any;
  chatMessage: string = '';
  bottomSectionFixedHeight: string = '';
  assigneeSearchList: any = [];
  assigneeList: any = [];
  jobListData: any = [];
  selectedJobData: any = {};
  jobCardsDetails: any = {
    groupDetails: (row: any) => {
      for (let i = 0; i < this.statusData.length; i++) {
        if (this.statusData[i].name === row?.status_name) {
          return {
            'border-color': `${this.statusData[i].additional_info?.style?.snubbed['border-color']}`,
          };
        }
      }

      return {};
    },
  };
  statusData: any = [];
  //pagination components
  previous: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;
  dialogRef: any;
  mobileDevice: boolean = window.innerWidth < 770;
  newChat: boolean = false;
  isDispatchUser = this.dataCheckService.isDispatchUser();
  isAdmin = this.dataCheckService.isUserAdmin();

  constructor(
    private appService: AppService,
    private profileService: ProfileService,
    private userService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private pageService: PagesService,
    private dataCheckService: DataCheckService,
    private scroll: ViewportScroller,
    private jobService: JobsService,
    private dialogService: NbDialogService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
        this.goBack();
      }
    });
    this.selectedUser = JSON.parse(
      sessionStorage.getItem('counterPart') || '{}'
    );
    this.route.params.subscribe({
      next: (params) => {
        if (this.mobileDevice) {
          if (this.route.snapshot.fragment && this.selectedUser?.id) {
            this.getUserMessage(this.selectedUser);
          } else {
            this.getUserMessageList();
          }
        } else {
          if (this.route.snapshot.fragment && this.selectedUser?.id) {
            this.getUserMessage(this.selectedUser);
          }
          this.getUserMessageList();
        }
      },
    });
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    const pageYOffset = window.scrollY;

    if (pageYOffset === 0 && this.totalRows > this.messageList?.length) {
      this.getOldMessages();
    }
  }
  getOldMessages() {
    this.previous = this.previous + this.rows;
    this.getUserMessage(this.selectedUser);
  }

  ngOnInit(): void {
    this.gtJobStatuses();
    this.subscriberUserId =
      this.appService.getUserData()?.subscriber?.subscriber_user_id;
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('counterPart');
  }
  formatJobStatuses(response: any) {
    this.statusData = response?.statuses;

    this.statusData.push({
      id: '0',
      name: 'Closed',
      additional_info: {
        style: {
          snubbed: {
            'color': 'var(--color-basic-1100)',
            'background-color': 'var(--color-basic-100)',
            'border-color': 'var(--color-basic-1100)',
            'border': '1px solid var(--color-basic-1100)',
          },
        },
      },
    });
    this.jobCardsDetails.individualDetails = nearestJobDetail(
      this.statusData,
      3
    );
    this.getNormalJobList();
  }
  gtJobStatuses() {
    this.jobService.getJobParams().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.formatJobStatuses(response);
      }
    });
  }
  getUserMessageList() {
    this.userService
      .getUserMessageList({ group: 1 })
      .subscribe((response: any) => {
        if (response?.status == 'success') {
          this.userMessageList = response?.data;
        } else {
        }
      });
  }
  getUserMessage(userData: any) {
    this.selectedUser = userData;
    sessionStorage.setItem('counterPart', JSON.stringify(userData));
    this.router.navigate(['/message'], { fragment: userData?.id });
    let params: any = { counter_part_id: userData?.id };

    if (this.rows) {
      params['rows'] = this.rows;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    this.userService.getUserMessageList(params).subscribe((response: any) => {
      if (response?.status == 'success') {
        this.totalRows = response?.total_count;
        this.formatMessageList(response?.data);
        this.calculateFixedBottomHeight();
      } else {
      }
    });
  }
  sendMessage() {
    let body: any = {
      counter_part_id: this.selectedUser?.id,
      message: this.chatMessage,
    };
    if (this.selectedJobData?.event_id) {
      body[
        this.selectedJobData?.job_type_name?.toLowerCase() === 'patrol'
          ? 'patrol_route_id'
          : 'job_id'
      ] = this.selectedJobData?.event_id;
    }
    this.userService.sendMessage(body).subscribe((response: any) => {
      if (response?.status == 'success') {
        this.messageList = [];
        this.formatMessageList(response?.data);

        this.chatMessage = '';
        this.calculateFixedBottomHeight();
      } else {
      }
    });
  }
  formatMessageList(messageData: any) {
    let tempPrevMessageList = [...(this.messageList || [])];
    let newList = (messageData || []).reverse();
    this.messageList = newList.concat(tempPrevMessageList);

    if (this.newChat) {
      this.getUserMessageList();
      this.newChat = false;
    }
  }
  goBack() {
    this.messageList = [];
    this.selectedUser = null;
    sessionStorage.removeItem('counterPart');
    this.getUserMessageList();
    this.router.navigate(['/message']);
  }
  calculateFixedBottomHeight() {
    // Get the bottom-section-fixed element by ID
    setTimeout(() => {
      const bottomSectionFixed = document.getElementById(
        'bottom-section-fixed'
      );

      if (bottomSectionFixed) {
        this.bottomSectionFixedHeight =
          bottomSectionFixed.offsetHeight.toString() + 'px';
      }
    }, 200);
  }
  searchAssignee(event?: any) {
    if (event.target.value?.length > 2) {
      let params: any = { full_name: event.target.value };
      this.jobService.getAssignees(params).subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.assigneeSearchList = response?.data?.filter(
            (guard: any) => guard?.id != this.subscriberUserId
          );
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
    }
  }
  getGuardList() {
    this.jobService.getAssignees({}).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.assigneeList = response?.data?.filter(
          (guard: any) => guard?.id != this.subscriberUserId
        );
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }

  openTemplate(template: TemplateRef<any>) {
    this.assigneeList = [];
    this.getGuardList();
    this.dialogRef = this.dialogService.open(template, {
      context: {},
    });
  }
  redirectToJob(messageData: any) {
    if (messageData?.job) {
      this.router.navigateByUrl(`/job-detail/${messageData?.job}`, {
        state: messageData?.job,
      });
      window.localStorage.setItem('urlId', messageData?.job);
    } else {
      this.router.navigate([
        '/view-route',
        {
          rKey: String(messageData?.patrol_route),
        },
      ]);
    }
  }
  openCamPopupDialog() {
    this.dialogRef = this.dialogService.open(WebcamImageComponent, {
      context: {},
      hasBackdrop: true,
    });
    this.dialogRef.onClose.subscribe((value: any) => {
      if (value && value !== 'error') {
        this.sendFileMessage(value);
      }
    });
  }
  sendFileMessage(value: any) {
    this.appService.setLoadingText('Uploading Details');

    let fileData: FormData = new FormData();

    fileData.append('file', value.actualFileUpload);
    if (value.newComment) {
      fileData.append('message', value.newComment);
    }
    let url = `gt_message`;
    fileData.append('counter_part_id', this.selectedUser?.id);
    fileData.append('image_source', value?.uploadType);
    if (this.selectedJobData?.event_id) {
      fileData.append(
        this.selectedJobData?.job_type_name?.toLowerCase() === 'patrol'
          ? 'patrol_route_id'
          : 'job_id',
        this.selectedJobData?.event_id
      );
    }
    this.appService.formDataApi(url, fileData).then((response: any) => {
      if (response?.data) {
        this.formatMessageList(response?.data);
      }
      this.appService.setLoadingText(' ');
    });
  }
  onOpenImage(event: any, requestRef: TemplateRef<any>) {
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;
    this.dialogRef = this.dialogService.open(requestRef, {
      closeOnBackdropClick: true,
      context: { src: srcAttr.nodeValue },
    });
  }

  getNormalJobList() {
    this.jobService
      .getAllJobs({ time_offset: new Date().getTimezoneOffset() })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.jobListData = response?.data;
        }
      });
  }

  linkJob(requestRef: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(requestRef, {
      closeOnBackdropClick: true,
      context: {},
    });
  }

  onJobSelect(jobData: any) {}
}
