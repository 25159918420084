<nb-card class="dialog-box">
  <nb-card-header>
    Add Security Licence
    <!-- <nb-icon
      (click)="closeDialogue()"
      [icon]="'close-outline'"
      class="sc-floating-right"
      pack="eva"
    ></nb-icon> -->
  </nb-card-header>
  <nb-card-body>
    <ng-container>
      <form [formGroup]="licenseForm" appFormEnterAction>
        <div>
          <label for="issuer_state">State</label>
          <nb-select
            size="giant"
            fullWidth
            placeholder="State"
            formControlName="issuer_state"
            [(selected)]="selectedItem"
          >
            <nb-option
              *ngFor="let state of stateData"
              [value]="state.code"
              (click)="selectState(state)"
              >{{ state.code }}</nb-option
            >
          </nb-select>
        </div>
        <nb-form-field>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              fullWidth
              nbInput
              id="license_class"
              formControlName="license_class"
              autocomplete="off"
              placeholder="Licence Class"
              (input)="onLicenceClassSearch($event)"
              [nbAutocomplete]="licenseClassAutoNGModel"
            />
            <label for="license_class">Licence Class / Category</label>
          </div>
        </nb-form-field>

        <nb-autocomplete #licenseClassAutoNGModel>
          <nb-option
            *ngFor="let item of licenseClassData"
            [value]="item.license_class_name"
          >
            <div>
              {{ item.license_class_name }}
            </div>
          </nb-option>
        </nb-autocomplete>

        <div class="form-floating">
          <input
            class="form-control"
            type="text"
            nbInput
            fullWidth
            id="license_number"
            formControlName="license_number"
            autocomplete="off"
            placeholder="Security Licence ID Number"
          />
          <label for="license_number">Security Licence ID Number</label>
        </div>
        <div class="form-floating">
          <input
            class="form-control"
            nbInput
            fullWidth
            placeholder="Licence Expiry"
            [nbDatepicker]="dateTimePicker1"
            formControlName="valid_till"
          />
          <label for="license_number">Licence Expiry</label>
          <nb-datepicker #dateTimePicker1 [min]="validDate"></nb-datepicker>
        </div>
        <div class="row row-cols-2">
          <div class="col">
            <button
              nbButton
              fullWidth
              ghost
              size="giant"
              (click)="closeDialogue()"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              nbButton
              [disabled]="!licenseForm.valid"
              fullWidth
              size="giant"
              (click)="addLicense()"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </nb-card-body>
</nb-card>
