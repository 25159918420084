import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import * as Highcharts from 'highcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateTimePickerComponent } from 'src/app/shared/comp/date-time-picker/date-time-picker.component';
import { formatDateTimeStamp } from 'src/global.variables';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit {
  jobAnalyticsData: any;
  jobAnalyticsTableData: any;
  subscriberAnalyticsData: any;
  subscriberAnalyticsTableData: any;
  mostUsedPlan: number = 0;
  leastUsedPlan: number = 0;
  planSubsHeaders: any;
  planJobHeaders: any;
  subsHighcharts = Highcharts;
  jobHighcharts = Highcharts;
  showSubsChart: any;
  showJobChart: any;
  jobStatusData: any;

  showAwsChart: boolean = false;
  transactionsData: any = [];
  managerNotesHeader: any = [
    {
      name: 'Sites',
    },
  ];
  awsDailyStorageOptons: any = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Daily Storage Information',
    },
    credits: { enabled: false },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: '%',
      },
    },

    yAxis: {
      stackLabels: {
        style: {
          color: 'black',
          fontWeight: 'bold',
        },
        enabled: true,
        crop: false,
        overflow: 'none',
        formatter: function (this: any) {
          return `${this.total} MB`;
        },
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointPadding: 0,
        groupPadding: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
  };
  awsWeeklyStorageOptons: any = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Weekly Storage Information',
    },
    credits: { enabled: false },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: '%',
      },
    },

    yAxis: {
      stackLabels: {
        style: {
          color: 'black',
          fontWeight: 'bold',
        },
        enabled: true,
        crop: false,
        overflow: 'none',
        formatter: function (this: any) {
          return `${this.total} MB`;
        },
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointPadding: 0,
        groupPadding: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
  };
  awsChartOptons: any = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Files Uploaded Average',
    },
    credits: { enabled: false },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: '%',
      },
    },

    yAxis: {
      min: 0,
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'black',
        },
      },
      title: {
        text: 'File Count',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      dataLabels: {
        enabled: true,
      },
    },
  };
  jobStatusChartOptions: any = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Subscriber Jobs Status Wise',
    },
    credits: { enabled: false },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: '%',
      },
    },

    yAxis: {
      min: 0,
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'black',
        },
      },
      title: {
        text: 'Job Count',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      dataLabels: {
        enabled: true,
      },
    },
  };
  chartSubsOptions: any = {
    chart: {
      type: 'pie',
    },
    title: {
      text: 'Subscription Plan',
    },
    credits: { enabled: false },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: '%',
      },
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: '{point.name}: {point.y:.1f}%',
        },
      },
    },
  };

  chartJobOptions: any = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Subscriber Average Jobs',
    },
    credits: { enabled: false },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: '%',
      },
    },

    yAxis: {
      min: 0,
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'black',
        },
      },
      title: {
        text: 'Job Count',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
  };

  notesHeader = [
    {
      header: 'Site',
      name: 'site_name',
      searchKey: 'site_name',
      sortKey: 'site_name',
    },
    {
      header: 'Client',
      name: 'company_name',
      searchKey: 'company_name',
      sortKey: 'company_name',
    },
    {
      header: 'Notes',
      name: 'manager_notes',
      searchKey: 'manager_notes',
      sortKey: 'manager_notes',
    },
    {
      header: 'Subscriber',
      name: 'subscriber_name',
      searchKey: 'subscriber_name',
      sortKey: 'subscriber_name',
    },
  ];
  managerNotesData: any;
  dataBaseDetails: any;

  dataBaseDetailTable = [
    {
      header: 'Model/ DB',
      name: 'table_name',
      searchKey: 'table_name',
      sortKey: 'table_name',
    },
    {
      header: 'Size',
      name: 'total_size',
      sortKey: 'total_size',
    },
    {
      header: 'Row Count',
      name: 'total_count',
      sortKey: 'total_count',
    },
  ];
  dialogeRef: any;
  dateRange: any = {};
  dateRangeValue: any;

  // transaction params : hourly trasnactions
  hourlyTransactionData: any = [];
  hourlyTransactionDateRange: any;
  hourlyTransactionDateRangeValue: any;
  weekDay: any;
  weekDayMapping = [
    { key: null, value: 'All' },
    { key: 1, value: 'Monday' },
    { key: 2, value: 'Tuesday' },
    { key: 3, value: 'Wednesday' },
    { key: 4, value: 'Thursday' },
    { key: 5, value: 'Friday' },
    { key: 6, value: 'Saturday' },
    { key: 7, value: 'Sunday' },
  ];

  constructor(
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService,
    private dialogService: NbDialogService,
    private router: Router
  ) {
    this.showSubsChart = false;
    this.showJobChart = false;
    this.planSubsHeaders = [
      { name: 'Usage Plan', count: 0, expande: false, arrayData: [] },
      { name: 'Active Plan', count: 0, expande: false, arrayData: [] },
      { name: 'Least Popular Plan', count: 0, expande: false, arrayData: [] },
    ];

    this.planJobHeaders = [
      {
        name: 'Job Status ',
        count: 0,
        expande: false,
        arrayData: [],
        subTableHeaders: [
          { header: 'Subscriber', name: 'subscriber_name' },
          { header: 'New Status', name: 'New' },
          { header: 'In Progress', name: 'In Progress' },
          { header: 'Completed Status ', name: 'Complete' },
          { header: 'Cancelled by client', name: 'Cancelled by client' },
        ],
      },
      {
        name: 'Created Job Average',
        count: 'Average',
        expande: false,
        arrayData: [],
        subTableHeaders: [
          { header: 'Subscriber', name: 'subscriber_name' },
          { header: 'Day ', name: 'day_avg_count' },
          { header: 'Week ', name: 'week_avg_count' },
          { header: 'Month', name: 'month_avg_count' },
        ],
      },
      {
        name: 'Completed Job Average',
        count: 'Average',
        expande: false,
        arrayData: [],
        subTableHeaders: [
          { header: 'Subscriber', name: 'subscriber_name' },
          { header: 'Day ', name: 'day_avg_count' },
          { header: 'Week ', name: 'week_avg_count' },
          { header: 'Month', name: 'month_avg_count' },
        ],
      },
      {
        name: 'Subscriber with least jobs',
        count: 0,
        expande: false,
        arrayData: [{}],
        subTableHeaders: [
          { header: 'Subscriber', name: 'subscriber_name' },
          { header: 'Count ', name: 'job_count' },
        ],
      },
      {
        name: 'Subscriber with most jobs',
        count: 0,
        expande: false,
        arrayData: [],
        subTableHeaders: [
          { header: 'Subscriber', name: 'subscriber_name' },
          { header: 'Count ', name: 'job_count' },
        ],
      },
    ];
  }
  mostActiveSubscriberDetailTable = [
    {
      header: 'Subscriber',
      name: 'name',
      sortKey: 'name',
      searchKey: 'name',
    },
    { header: 'Transactions Count', name: 'count', sortKey: 'count' },
  ];

  ngOnInit(): void {
    let today = new Date();
    today.setDate(new Date().getDate() - 30);

    this.dateRange = {
      start: today,
      end: new Date(),
    };
    this.hourlyTransactionDateRange = {
      start: today,
      end: new Date(),
    };
    this.getMostActiveSubscribers();
    this.getAnalyticsData();

    this.getHourlyTransactions();
  }
  getAnalyticsData() {
    let body = {};
    let params: any = {};
    this.spinnerService.show();
    params['job'] = 1;
    params['subscription_plan'] = 1;
    params['manager_notes'] = 1;
    params['database_details'] = 1;
    this.adminService
      .getAnalyticsData(body, params)
      .subscribe((response: any) => {
        this.jobAnalyticsData = response.data.job;

        this.managerNotesData = response?.data?.manager_notes;
        this.dataBaseDetails = response?.data?.database_details;
        this.getAverageJobData();

        this.subscriberAnalyticsData = response.data.subscription_plan;
        this.subscriberAnalyticsTableData = response.data.subscription_plan;
        this.subscriberAnalyticsTableData.plan_usage_count.forEach(
          (element: any) => {
            this.planSubsHeaders[0].count =
              this.planSubsHeaders[0].count + element.plan_count;
          }
        );
        this.planSubsHeaders[0].arrayData =
          this.subscriberAnalyticsTableData.plan_usage_count;
        this.subscriberAnalyticsTableData.plan_active_count.forEach(
          (element: any) => {
            this.planSubsHeaders[1].count =
              this.planSubsHeaders[1].count + element.plan_count;
          }
        );
        this.planSubsHeaders[1].arrayData =
          this.subscriberAnalyticsTableData.plan_active_count;

        this.subscriberAnalyticsTableData.cancelled_plan.forEach(
          (element: any) => {
            this.planSubsHeaders[2].count =
              this.planSubsHeaders[2].count + element.plan_count;
          }
        );
        this.planSubsHeaders[2].arrayData =
          this.subscriberAnalyticsTableData.cancelled_plan;
        this.chartSubsOptions.series = [
          {
            name: 'Subscription Plan',
            colorByPoint: true,
            data: [
              {
                name: 'Plan_Usage',
                y: this.planSubsHeaders[0].count,
                drilldown: 'Plan_Usage',
              },
              {
                name: 'Active_Plans',
                y: this.planSubsHeaders[1].count,
                drilldown: 'Active_Plans',
              },
              {
                name: 'Least_Popular_Plans',
                y: this.planSubsHeaders[2].count,
                drilldown: 'Least_Popular_Plans',
              },
            ],
          },
        ];
        this.chartSubsOptions.drilldown = {
          series: [
            {
              name: 'Plan_Usage',
              id: 'Plan_Usage',
              data: [
                ['Standard Monthly', 1],
                ['Plus Annually', 1],
                ['Plus Monthly', 1],
              ],
            },
            {
              name: 'Active_Plans',
              id: 'Active_Plans',
              data: [
                ['Standard Monthly', 1],
                ['Plus Annually', 1],
                ['Plus Monthly', 1],
              ],
            },
            {
              name: 'Least_Popular_Plans',
              id: 'Least_Popular_Plans',
              data: [
                ['Standard Monthly', 1],
                ['Plus Annually', 1],
                ['Plus Monthly', 1],
              ],
            },

            {},
            {},
          ],
        };
        this.jobAnalyticsTableData = response.data.job;
        this.planJobHeaders[0].count =
          this.planJobHeaders[0].count +
          this.jobAnalyticsTableData?.job_status_wise_subscribers?.table_data
            ?.length;

        this.planJobHeaders[3].count =
          this.planJobHeaders[3].count +
          this.jobAnalyticsTableData?.subscriber_with_least_jobs?.job_count;

        this.planJobHeaders[4].count =
          this.planJobHeaders[4].count +
          this.jobAnalyticsTableData?.subscriber_with_most_jobs?.job_count;

        this.planJobHeaders[0].arrayData =
          this.jobAnalyticsTableData?.job_status_wise_subscribers?.table_data;
        this.planJobHeaders[1].arrayData =
          this.jobAnalyticsTableData?.jobs_average_count_subscriber_wise?.table_data;
        this.planJobHeaders[2].arrayData =
          this.jobAnalyticsTableData?.jobs_completed_average_count_subscriber_wise?.table_data;
        this.planJobHeaders[3].arrayData[0] =
          this.jobAnalyticsTableData?.subscriber_with_least_jobs;
        this.planJobHeaders[4].arrayData[0] =
          this.jobAnalyticsTableData?.subscriber_with_most_jobs;
        this.spinnerService.hide();
      });
  }

  expandColumns(data: any) {
    data.expanded = !data.expanded;
  }
  getJobStatusWiseData() {
    this.jobStatusData =
      this.jobAnalyticsData?.job_status_wise_subscribers?.chart_data;
    this.jobStatusChartOptions.series = this.jobStatusData?.status_data;
    this.jobStatusChartOptions.xAxis = {
      categories: this.jobStatusData?.subscribers,
    };
  }
  getAverageJobData() {
    let createdAvg = this.jobAnalyticsData?.jobs_average_count_subscriber_wise;
    let completedAvg =
      this.jobAnalyticsData?.jobs_completed_average_count_subscriber_wise;

    this.chartJobOptions.series = [
      ...createdAvg?.chart_data?.avg_data,
      ...completedAvg?.chart_data?.avg_data,
    ];
    this.chartJobOptions.xAxis = {
      categories: createdAvg?.chart_data?.subscribers,
    };
  }
  openRangePicker(where: string = 'mostActive') {
    this.dialogeRef = this.dialogService.open(DateTimePickerComponent, {
      context: {
        dateRange:
          where === 'mostActive'
            ? this.dateRange
            : this.hourlyTransactionDateRange,
        showFrom: 'tablePage',
      },
    });
    this.dialogeRef.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        if (where === 'mostActive') {
          this.dateRange = value;
          this.getMostActiveSubscribers();
        } else {
          this.hourlyTransactionDateRange = value;
          this.getHourlyTransactions();
        }
      }
    });
  }
  changeWeekDay(event: any) {
    this.weekDay = this.weekDayMapping?.find(
      (day: any) => day.value === event.target.value
    )?.key;
    this.hourlyTransactionData = [];
    this.getHourlyTransactions();
  }
  getMostActiveSubscribers() {
    this.spinnerService.show();
    let body = {
      start_date: this.dateRange.start,
      end_date: this.dateRange.end,
    };
    this.dateRangeValue = `${String(
      formatDateTimeStamp(this.dateRange?.start, 'd MMM y HH:mm', 'en_US')
    )} - ${String(
      formatDateTimeStamp(this.dateRange?.end, 'd MMM y HH:mm', 'en_US')
    )}`;
    this.adminService
      .getAnalyticsData(body, { transactions: 1 })
      .subscribe((response: any) => {
        this.transactionsData = response?.data?.transactions || [];
        this.spinnerService.hide();
      });
  }
  onRowClick(data: any) {
    this.router.navigate([
      '/gtadmin/subscribers-detail',
      { sKey: String(data?.id) },
    ]);
  }

  getHourlyTransactions() {
    this.spinnerService.show();
    let body: any = {
      start_date: this.hourlyTransactionDateRange.start,
      end_date: this.hourlyTransactionDateRange.end,
      time_offset: new Date().getTimezoneOffset(),
    };

    if (typeof this.weekDay === 'number') {
      body.week_day = this.weekDay;
    }
    this.hourlyTransactionDateRangeValue = `${String(
      formatDateTimeStamp(
        this.hourlyTransactionDateRange?.start,
        'd MMM y HH:mm',
        'en_US'
      )
    )} - ${String(
      formatDateTimeStamp(
        this.hourlyTransactionDateRange?.end,
        'd MMM y HH:mm',
        'en_US'
      )
    )}`;
    this.adminService
      .getAnalyticsData(body, { hourly_transactions: 1 })
      .subscribe((response: any) => {
        this.hourlyTransactionData = (
          response?.data?.transactions || []
        ).reverse();
        this.spinnerService.hide();
      });
  }
}
