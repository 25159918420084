import { NbMenuItem } from '@nebular/theme';
export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'home-outline',
    link: '/dashboard',
    home: true,
  },
];

export const SUPER_USER_MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'home-outline',
    link: '/gtadmin/dashboard',
  },
  {
    title: 'Support',
    icon: 'message-square-outline',
    link: '/gtadmin/support',
  },
  {
    title: 'Subscribers',
    icon: 'people-outline',
    link: '/gtadmin/subscribers',
  },
  {
    title: 'App Users',
    icon: 'person-done-outline',
    link: '/gtadmin/app-users',
  },
  {
    title: 'Beta Users',
    icon: 'people-outline',
    link: '/gtadmin/beta-users',
  },
  {
    title: 'Releases',
    icon: 'code-download-outline',
    link: '/gtadmin/releases',
  },

  {
    title: 'Subscription Plans',
    icon: 'cube-outline',
    link: '/gtadmin/subscription-plans',
  },

  {
    title: 'App Routes',
    icon: 'options-outline',
    link: '/gtadmin/app-routes',
  },
  {
    title: 'Job Status',
    icon: 'save-outline',
    link: '/gtadmin/job-status',
  },
  {
    title: 'Job Types',
    icon: 'save-outline',
    link: '/gtadmin/job-type',
  },
  {
    title: 'Super Users',
    icon: 'person-done-outline',
    link: '/gtadmin/super-users',
  },
];

export const appUserMenu: NbMenuItem[] = [
  { title: 'Edit Profile' },

  {
    title: 'Subscription',
    link: '/subscription',
  },
  { title: 'Support', link: '/support' },
  // { title: 'Install App' },
  { title: 'Change Log' },
  { title: 'Change Account' },
  { title: 'Clock Out' },
  { title: 'Sign Out' },
];

export const superUserMenu: NbMenuItem[] = [
  { title: 'Edit Profile' },
  { title: 'Change Log' },
  { title: 'Sign Out' },
];
