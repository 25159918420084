import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  NbDialogService,
  NbGlobalLogicalPosition,
  NbIconLibraries,
  NbThemeService,
} from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { globalLicenseInfo } from '../../../../global.variables';
import { AppService } from '../../../app.service';
import { AddEditLicenseDetailsComponent } from '../../../shared/comp/add-edit-license-details/add-edit-license-details.component';
import { ConfirmDialogComponent } from '../../../shared/comp/confirm-dialog/confirm-dialog.component';
import { CustomerService } from '../../customer/customer.service';
import { ProfileService } from '../profile.service';

import { Router } from '@angular/router';
import { startRegistration } from '@simplewebauthn/browser';
import { ChangePasswordComponent } from '../../../shared/comp/change-password/change-password.component';
import { WebcamImageComponent } from '../../../shared/comp/webcam-image/webcam-image.component';
import { DataCheckService } from '../../../shared/service/data-check.service';
import { PagesService } from '../../pages.service';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  userDetails: any;
  dialogref: any;
  logicalPositions = NbGlobalLogicalPosition;
  userData: any;
  profileForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(''),
    first_name: new UntypedFormControl(''),
    last_name: new UntypedFormControl(''),
    mobile_number: new UntypedFormControl(''),
  });
  message: string = '';

  userProfileUrl: any;
  imageUploaded: boolean = false;

  actualUploadFile: any;
  fileData: any = [];

  cardActionIcons = globalLicenseInfo?.icons;
  licenseDetails = {
    individualDetails: globalLicenseInfo?.details,
  };
  userLicenses: any = [];

  userGroups: any;
  currentTheme: any;
  cameraError: any;
  isSuperUser: any;
  isTrustedDevice: boolean = false;
  muteNotification: number = 100;
  stateTimeZones: any;
  selectedTimeZone: any;
  notificationVolumes = [
    { value: 0, display: 'Mute' },
    { value: 25, display: '25 %' },
    { value: 50, display: '50 %' },
    { value: 75, display: '75 %' },
    { value: 100, display: '100 %' },
  ];
  isDeviceRegistered: boolean = false;
  constructor(
    private spinnerService: NgxSpinnerService,
    private appService: AppService,
    private profileService: ProfileService,
    private iconLibraries: NbIconLibraries,
    private dialog: NbDialogService,
    private customerService: CustomerService,
    private dialogService: NbDialogService,
    private pageService: PagesService,
    private router: Router,
    private themeService: NbThemeService,
    private dataCheckService: DataCheckService,
    private cdr: ChangeDetectorRef
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
      }
    });
    this.iconLibraries.registerFontPack('fas', {
      packClass: 'fas',
      iconClassPrefix: 'fa',
    });
  }

  async ngOnInit(): Promise<void> {
    this.profileService.getFidoDevices().subscribe(async (response: any) => {
      let currentDevice: any = await this.appService.getDeviceDetail();
      this.isDeviceRegistered = response?.data?.web_authn[0].some(
        (registeredDevice: any) => {
          return (
            registeredDevice.device.device === currentDevice.device &&
            registeredDevice.device.device_type === currentDevice.deviceType &&
            registeredDevice.device.device_os === currentDevice.os &&
            registeredDevice.device.browser === currentDevice.browser
          );
        }
      );
      if (this.isDeviceRegistered === true) {
        let userData = this.appService.getUserData();
        userData.checks.fido_credentials = true;
        userData.checks.fido_login = true;
        this.appService.setUserData(userData);
        this.cdr.detectChanges();
      }
    });
    this.userData = this.appService.getUserData();
    this.muteNotification = this.pageService.getNotificationVolume();
    this.isSuperUser = this.dataCheckService.isSuperUser();
    this.isTrustedDevice = this.dataCheckService.isTrustedDevice();
    if (this.userData?.profile?.profile_image_url) {
      this.userProfileUrl = this.userData?.profile?.profile_image_url;
      this.imageUploaded = true;
    } else {
      this.userProfileUrl = 'assets/images/img_avatar_blank.png';
    }
    this.spinnerService.show();
    this.getProfileData();
    this.getTimeZoneDetails();
  }
  getTimeZoneDetails() {
    this.stateTimeZones = [];
    [
      'Australia/Darwin',
      'Australia/Sydney',
      'Australia/Brisbane',
      'Australia/Adelaide',
      'Australia/Hobart',
      'Australia/Melbourne',
      'Australia/Perth',
    ].forEach((timeZone: any) => {
      this.stateTimeZones.push(this.getTimezoneInfo(timeZone));
    });
  }

  getProfileData() {
    this.spinnerService.show();
    this.profileService
      .fetchUserProfile(this.isSuperUser ? 'admin' : 'api')
      .subscribe((res) => {
        if (res['status'] === 'success') {
          this.formatProfileData(res['data']);
          this.spinnerService.hide();
        }
      });
  }
  formatProfileData(data: any) {
    this.userDetails = data;
    this.selectedTimeZone = data?.time_zone;
    if (data?.time_zone) {
      this.userDetails.timeZone = this.getTimezoneInfo(data?.time_zone);
    }

    if (this.isSuperUser !== true) {
      this.userGroups = [
        ...this.userDetails?.user_group?.map((item: any) => {
          return item?.name;
        }),
      ].join(',');
    }
    this.profileForm.reset(this.userDetails);
    this.userLicenses = data?.license;
    this.profileForm.get('email')?.disable();
    if (this.userDetails?.profile_image) {
      this.userProfileUrl = this.userDetails?.profile_image;
      this.imageUploaded = true;
      let userData = this.appService.getUserData();
      userData.profile.profile_image_url = this.userProfileUrl;

      this.appService.setUserData(userData);
    } else {
      this.userProfileUrl = 'assets/images/img_avatar_blank.png';
    }
  }

  editProfile(template: TemplateRef<any>) {
    const dialogref = this.dialogService.open(template, {
      context: {},
    });
  }

  sendPasswordResetLink(userDetails: any) {
    let dialogMsg = 'Are you sure you want to reset your password?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      context: {
        title: 'Change Password',
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        const position = this.logicalPositions.TOP_START;
        const duration = 0;
        this.spinnerService.show();
        if (this.isSuperUser === true) {
          this.spinnerService.hide();
          const dialogRefCP = this.dialog.open(ChangePasswordComponent, {
            context: '',
            dialogClass: 'model-full',
          });
          dialogRefCP.onClose.subscribe((value) => {});
        } else {
          this.spinnerService.hide();
          const dialogRefCP = this.dialog.open(ChangePasswordComponent, {
            context: '',
            dialogClass: 'model-full',
          });
          dialogRefCP.onClose.subscribe((value) => {});
        }
      }
    });
  }
  addNewLicense() {
    const dialogRef = this.dialogService.open(AddEditLicenseDetailsComponent, {
      context: {},
      dialogClass: 'model-full',
    });
    dialogRef.onClose.subscribe((value: any) => {
      if (value !== 'close' && value !== undefined) {
        this.userLicenses = value;
      }
    });
  }

  onSubmitClicked(passwordForm: any) {
    const position = this.logicalPositions.BOTTOM_END;
    if (passwordForm) {
      this.profileService.changePassword(passwordForm).subscribe((response) => {
        if (response['status'] == 'success') {
          this.pageService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
    }
  }

  UpdateProfileDetails() {
    if (this.profileForm.valid) {
      this.profileForm.get('email')?.enable();
      let requestData = { data: this.profileForm.value };
      requestData['data']['time_zone'] = this.selectedTimeZone;
      if (!this.profileForm.value?.mobile_number) {
        this.profileForm.controls['mobile_number'].setValue(null);
      }
      this.profileService
        .updateProfileDetails(requestData, this.isSuperUser ? 'admin' : 'api')
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            let userData = this.appService.getUserData();
            userData.profile = response['data'];
            this.appService.setUserData(userData);
            // window.location.reload();
            this.pageService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });

            this.formatProfileData(response['data']);
          } else {
            this.pageService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
        });
    }
  }
  deleteLicense(license: any) {
    let dialogMsg = 'Are you sure you want to delete this licence?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      context: {
        title: 'Delete Licence',
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.spinnerService.show();
        this.customerService
          .deleteLicense(license.id, 0)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.userLicenses = response['data'];
              this.spinnerService.hide();
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.spinnerService.hide();
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    });
  }
  checkEmailEnter(event: any) {
    let dialogMsg =
      'We will send an email to your new email address to validate your request.';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      context: {
        title: 'Update Email',
        message: dialogMsg,
        body: true,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value.value === 'Yes' && value.data != undefined) {
        this.spinnerService.show();
        let new_email = value.data;
        this.profileService
          .updateEmail({ new_email: new_email }, { get_link: 1 })
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.spinnerService.hide();
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    });
  }
  openCamPopup() {
    this.dialogref = this.dialogService.open(WebcamImageComponent, {
      context: { showFrom: 'userProfilePage' },
    });

    this.dialogref.onClose.subscribe((value: any) => {
      if (value && value !== 'error') {
        this.actualUploadFile = value.actualFileUpload;
        this.onFileSubmit();
      } else if (value == 'error') {
        this.cameraError = 'ERROR: Unable to access your camera';
      }
    });
  }

  onFileSubmit() {
    this.spinnerService.show();
    let fileData: FormData = new FormData();
    fileData.append('file', this.actualUploadFile);

    this.appService
      .updateProfileImage(
        `profile/update_profile_image`,
        fileData,
        this.isSuperUser
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          let userData = this.appService.getUserData();
          userData.profile.profile_image_url = response['url'];
          this.appService.setUserData(userData);
          this.pageService.setMessage({
            // successMessage: response['message'],
            errorMessage: '',
          });

          this.actualUploadFile = null;
          this.dialogref.close();
          window.location.reload();
        } else {
          this.pageService.setMessage({
            errorMessage: response['message'],
            successMessage: '',
          });
        }
        this.actualUploadFile = null;
        this.spinnerService.hide();
      });
  }
  removeImage() {
    let dialogMsg = `Are you sure to remove the profile picture?`;
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: 'Remove Picture',
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        let params = { remove_profile_image: 1 };
        this.spinnerService.show();
        this.profileService
          .removeProfileImage(params)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              let userData = this.appService.getUserData();
              userData.profile.profile_image_url = null;
              this.appService.setUserData(userData);
              this.pageService.setMessage({
                // successMessage: response['message'],
                errorMessage: '',
              });
              window.location.reload();
            } else {
              this.pageService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
            this.spinnerService.hide();
          });
      }
    });
  }
  async fidoStartRegistration(responseBody: any) {
    try {
      let regResp = await startRegistration(JSON.parse(responseBody));
      if (regResp) {
        this.profileService
          .fidoRegister(regResp, { verify: 1 })
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              let userData = this.appService.getUserData();
              userData.checks.fido_credentials = true;
              userData.checks.fido_login = true;
              this.isDeviceRegistered = true;
              this.appService.setUserData(userData);
              window.location.reload();
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    } catch (err: any) {
      console.error('Error during registration process:', err);
      if (err === 'The authenticator was previously registered') {
        let userData = this.appService.getUserData();
        userData.checks.fido_login = true;
        this.isDeviceRegistered = true;
        this.appService.setUserData(userData);
        this.cdr.detectChanges();
        window.location.reload();
      }
    }
  }

  fidoRegister() {
    this.profileService.fidoRegister().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.fidoStartRegistration(response['data']);
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }

  changeNotificationVolume() {
    this.spinnerService.show();
    this.profileService
      .updateUser(
        {
          data: {
            mute_notification: this.muteNotification,
          },
        },
        { save_preference: 1 }
      )
      .subscribe((response: any) => {
        if (response?.status === 'success') {
          let userData = this.appService.getUserData();
          if (userData?.preferences) {
            userData.preferences.mute_notification = this.muteNotification;
            this.appService.setUserData(userData);
          }
          this.pageService.setMessage({
            successMessage: 'Notification Sound Updated',
            errorMessage: '',
          });
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  toggleTheme() {
    let userData = this.appService.getUserData();
    this.currentTheme =
      userData?.preferences?.theme === 'default' ? 'dark' : 'default';
    userData.preferences.theme = this.currentTheme;

    this.appService.setUserData(userData);
    this.themeService.changeTheme(this.currentTheme);
    this.profileService
      .updateUser(
        { data: { theme: this.currentTheme ? this.currentTheme : 'default' } },
        { save_preference: 1 }
      )
      .subscribe((response: any) => {});
    window.location.reload();
  }
  mobileNumberCheck() {
    if (!this.profileForm.value?.mobile_number) {
      this.profileForm.controls['mobile_number'].setValue(null);
      this.profileForm.controls['mobile_number'].removeValidators([
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ]);
    } else {
      this.profileForm.controls['mobile_number'].setValidators([
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ]);
    }
  }

  getTimezoneInfo(timeZone: string): {
    timezone: string;
    localTime: string;
    gmtOffset: string;
  } {
    const now = new Date();
    const options: Intl.DateTimeFormatOptions = {
      timeZone,
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    };

    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timeZone,
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    });

    let offsetString: any = formatter
      .formatToParts(now)
      .find((part) => part.type === 'timeZoneName')?.value;
    const utcIndex = offsetString.indexOf('GMT');
    offsetString = offsetString.slice(utcIndex + 3);
    // Check if minutes part is zero
    if (offsetString.length <= 3) {
      offsetString += ':00';
    }

    const info: any = {
      localTime: now.toLocaleTimeString('en-AU', options),
      gmtOffset: `(UTC ${offsetString})`,
      value: timeZone,
    };
    return info;
  }
}
