import { Component, Input, OnInit, Optional } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { PagesService } from 'src/app/pages/pages.service';
import { IncidentsService } from '../../incidents.service';

@Component({
  selector: 'app-add-edit-incident-type',
  templateUrl: './add-edit-incident-type.component.html',
  styleUrls: ['./add-edit-incident-type.component.scss'],
})
export class AddEditIncidentTypeComponent implements OnInit {
  @Input() incidentTypeData: any;
  @Input() name: any;
  incidentTypeForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
  });
  constructor(
    private spinnerService: NgxSpinnerService,
    @Optional()
    protected dialogRef: NbDialogRef<AddEditIncidentTypeComponent>,

    private pageService: PagesService,
    private incidentService: IncidentsService
  ) {}

  ngOnInit(): void {
    if (this.incidentTypeData?.id) {
      this.incidentTypeForm.controls['name'].setValue(
        this.incidentTypeData.name
      );
    } else {
      this.incidentTypeForm.reset();
      if (this.name) {
        this.incidentTypeForm.controls['name'].setValue(this.name);
      }
    }
  }
  updateData() {
    if (this.incidentTypeForm.valid) {
      var requestData = this.incidentTypeForm.value;
      this.spinnerService.show();
      if (!this.incidentTypeData?.id) {
        this.incidentService
          .createIncidentType(requestData)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();
              let subscriberStats = JSON.parse(
                localStorage.getItem('subscriberStats') || '{}'
              );

              subscriberStats.company_count =
                (subscriberStats?.company_count || 0) + 1;

              localStorage.setItem(
                'subscriberStats',
                JSON.stringify(subscriberStats)
              );

              this.onCloseDialogue(response);
              this.pageService.setMessage({
                errorMessage: '',
                successMessage: response['message'],
              });
              this.incidentTypeForm.reset();
            } else {
              this.spinnerService.hide();
              this.pageService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      } else {
        requestData['id'] = this.incidentTypeData.id;
        this.incidentService
          .updateIncidentTypeDetailById(requestData)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();

              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this.onCloseDialogue(response);
            } else {
              this.spinnerService.hide();
              this.pageService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      }
    }
  }
  onCloseDialogue(value?: any) {
    this.dialogRef.close(value);
  }
}
