import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoadGuard } from '../shared/guards/load.guard';
import { ProtectGuard } from '../shared/guards/protect.guard';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { AnalyticsComponent } from './admin/analytics/analytics.component';
import { AppRoutesComponent } from './admin/app-routes/app-routes.component';
import { AppUsersComponent } from './admin/app-users/app-users.component';
import { BetaUserComponent } from './admin/beta-user/beta-user.component';
import { EventLogsComponent } from './admin/event-logs/event-logs.component';
import { AddEditJobTypeComponent } from './admin/job-type-builder/add-edit-job-type/add-edit-job-type.component';
import { JobStatusComponent } from './admin/job-type-builder/job-status/job-status.component';
import { JobTypeComponent } from './admin/job-type-builder/job-type/job-type.component';
import { PaymentsComponent } from './admin/payments/payments.component';
import { ReleaseComponent } from './admin/release/release.component';
import { SubscribersComponent } from './admin/subscribers/subscribers.component';
import { ViewSubscribersDetailsComponent } from './admin/subscribers/view-subscribers-details/view-subscriber-details.component';
import { SubscriptionPlansComponent } from './admin/subscription-plans/subscription-plans.component';
import { SuperUsersComponent } from './admin/super-users/super-users.component';
import { UserFeedbackComponent } from './admin/user-feedback/user-feedback.component';
import { AppUserFeedbackComponent } from './app-user-feedback/app-user-feedback.component';
import { AddEditCheckpointComponent } from './checkpoints/add-edit-checkpoint/add-edit-checkpoint.component';
import { CheckpointComponent } from './checkpoints/checkpoint.component';
import { ViewCheckpointComponent } from './checkpoints/view-checkpoint/view-checkpoint.component';
import { CustomerComponent } from './customer/customer.component';
import { ViewCustomerComponent } from './customer/view-customer/view-customer.component';
import { HomeComponent } from './home/home.component';
import { AddIncidentComponent } from './incidents/add-incident/add-incident.component';
import { ListIncidentsComponent } from './incidents/list-incidents/list-incidents.component';
import { ViewIncidentComponent } from './incidents/view-incident/view-incident.component';
import { InternalMessageComponent } from './internal-message/internal-message.component';
import { AddJobComponent } from './jobs/add-job/add-job.component';
import { JobsDetailEventsComponent } from './jobs/jobs-detail-events/jobs-detail-events.component';
import { JobsComponent } from './jobs/jobs.component';
import { PatrolDetailEventsComponent } from './jobs/patrol-detail-events/patrol-detail-events.component';
import { KeyAuditComponent } from './key-audit/key-audit.component';
import { MissedEventsComponent } from './missed-events/missed-events.component';
import { MonitoringCompanyComponent } from './monitoring-company/monitoring-company.component';
import { NotificationCountResolver } from './notification.resolver';
import { PagesComponent } from './pages.component';
import { CompanyProfileComponent } from './profile/company-profile/company-profile.component';
import { UserProfileComponent } from './profile/user-profile/user-profile.component';
import { ReportsComponent } from './reports/reports.component';
import { ResponseTypeComponent } from './response-type/response-type.component';
import { RosterComponent } from './roster-schedule/roster/roster.component';
import { SubscriptionComponent } from './subscription/subscription.component';

import { ErrorPageComponent } from '../auth/auth/error-page/error-page.component';
import { UserHistoryComponent } from './admin/app-users/user-history/user-history.component';
import { ListIncidentTypesComponent } from './incidents/incident-type/list-incident-types/list-incident-types.component';
import { SosAlertsComponent } from './sos-alerts/sos-alerts.component';
import { AddEditViewUserGroupComponent } from './user-group/add-edit-view-user-group/add-edit-view-user-group.component';
import { UserGroupComponent } from './user-group/user-group.component';
import { AddEditUserPromptComponent } from './user-prompts/add-edit-user-promt/add-edit-user-prompt.component';
import { ListUserPromptsComponent } from './user-prompts/list-user-prompts/list-user-prompts.component';
import { UserUpdatesLogComponent } from './user-updates-log/user-updates-log.component';
import { GuardsOnlineComponent } from './users/guards-online/guards-online.component';
import { LicencesComponent } from './users/licences/licences.component';
import { UsersComponent } from './users/users.component';
import { SmartNetComponent } from './admin/smart-net/smart-net.component';
const routes: Routes = [
  {
    // contains paths that will be available to normal user whose logged into the gt-app
    path: '',
    component: PagesComponent,
    canActivate: [ProtectGuard],

    children: [
      {
        path: 'dashboard',
        component: HomeComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'jobs',
        component: JobsComponent,
        canLoad: [LoadGuard],
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'add-job',
        component: AddJobComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'job-detail/:jobKey',
        component: JobsDetailEventsComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'client',
        pathMatch: 'full',

        component: CustomerComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'view-client',
        component: ViewCustomerComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'view-route',
        component: PatrolDetailEventsComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'profile',
        component: UserProfileComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'company-profile',
        component: CompanyProfileComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'Admin',
        component: AdminComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'subscription',
        component: SubscriptionComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'users',
        component: UsersComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'licences',
        component: LicencesComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'user-groups',
        component: UserGroupComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'user-group',
        component: AddEditViewUserGroupComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'checkpoints',
        component: CheckpointComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'add-checkpoint',
        component: AddEditCheckpointComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'view-checkpoints',
        component: ViewCheckpointComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'user-events',
        component: UserUpdatesLogComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'reports',
        component: ReportsComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'support',
        component: AppUserFeedbackComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'response-type',
        component: ResponseTypeComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'incident-type',
        component: ListIncidentTypesComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'monitoring-company',
        component: MonitoringCompanyComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'incidents',
        component: ListIncidentsComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'add-incident',
        component: AddIncidentComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'view-incident',
        component: ViewIncidentComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'roster',
        component: RosterComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'guards',
        component: GuardsOnlineComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'missed-events',
        component: MissedEventsComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },

      {
        path: 'message',
        component: InternalMessageComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'key-audit',
        component: KeyAuditComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'user-prompts',
        component: ListUserPromptsComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'user-prompt',
        component: AddEditUserPromptComponent,
        resolve: { notificationCount: NotificationCountResolver },
      },
      {
        path: 'permissions',
        component: ErrorPageComponent,
      },
      {
        path: 'sos-alerts',
        component: SosAlertsComponent,
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
  {
    // contains paths that will be available to the super-users of the app.
    path: 'gtadmin',
    component: PagesComponent,
    canActivate: [ProtectGuard],
    children: [
      {
        path: 'subscription-plans',
        component: SubscriptionPlansComponent,
      },
      {
        path: 'super-users',
        component: SuperUsersComponent,
      },
      {
        path: 'subscribers',
        component: SubscribersComponent,
      },
      {
        path: 'payments',
        component: PaymentsComponent,
      },
      {
        path: 'subscribers-detail',
        component: ViewSubscribersDetailsComponent,
      },
      {
        path: 'event-logs',
        component: EventLogsComponent,
      },
      {
        path: 'analytics',
        component: AnalyticsComponent,
      },
      {
        path: 'dashboard',
        component: AdminDashboardComponent,
      },

      {
        path: 'app-routes',
        component: AppRoutesComponent,
      },
      {
        path: 'app-users',
        component: AppUsersComponent,
      },

      {
        path: 'job-status',
        component: JobStatusComponent,
      },
      {
        path: 'job-type',
        component: JobTypeComponent,
      },
      {
        path: 'beta-users',
        component: BetaUserComponent,
      },
      {
        path: 'job-type/add',
        component: AddEditJobTypeComponent,
      },
      {
        path: 'job-type/edit',
        component: AddEditJobTypeComponent,
      },
      {
        path: 'support',
        component: UserFeedbackComponent,
      },

      {
        path: 'releases',
        component: ReleaseComponent,
      },
      {
        path: 'profile',
        component: UserProfileComponent,
      },
      {
        path: 'user-history',
        component: UserHistoryComponent,
      },
      {
        path: 'smart-net',
        component: SmartNetComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
