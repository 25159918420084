<nb-alert class="mb-2" *ngIf="cameraError" outline="danger">
  {{ cameraError }}
</nb-alert>
<nb-card>
  <nb-card-body class="mobile-only gt-p-small">
    <div class="row row-cols-1">
      <div class="col" *ngIf="isSuperUser !== true">
        <div class="row row-cols-2">
          <div
            class="profile-picture-container"
            [ngClass]="imageUploaded ? 'col col-7' : 'col-6 offset-3'"
          >
            <img
              class="profile-picture-mobile"
              src="{{ userProfileUrl }}"
              alt="User-Profile-Image"
            />
          </div>
          <div class="col col-5">
            <div class="row row-cols-1" *ngIf="imageUploaded">
              <div class="col">
                <button
                  nbButton
                  class="nbbutton-long-text"
                  fullWidth
                  size="giant"
                  (click)="openCamPopup()"
                >
                  Change Picture
                </button>
              </div>
              <div class="col">
                <button
                  nbButton
                  class="nbbutton-long-text"
                  fullWidth
                  (click)="removeImage()"
                  size="giant"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="!imageUploaded">
          <div class="col sc-text-center">
            <button
              status="basic"
              nbButton
              size="small"
              (click)="openCamPopup()"
            >
              + Add Profile Picture
            </button>
          </div>
        </div>
      </div>

      <div class="col sc-mt-6">
        <div class="row sc-mt-6">
          <div class="badge-key">Name</div>
          <div class="badge-value">{{ userDetails?.full_name }}</div>

          <div class="badge-key">Email</div>
          <div class="badge-value">{{ userDetails?.email }}</div>

          <div class="badge-key" *ngIf="userDetails?.mobile_number">Mobile</div>
          <div class="badge-value" *ngIf="userDetails?.mobile_number">
            {{ userDetails?.mobile_number }}
          </div>

          <div class="badge-key" *ngIf="userDetails?.time_zone">Timezone</div>
          <div class="badge-value" *ngIf="userDetails?.time_zone">
            {{ userDetails?.time_zone }}
            {{ userDetails?.timeZone?.gmtOffset }}
          </div>
          <div class="badge-key">
            Notification Sound

            <span class="sc-floating-right me-4">{{ muteNotification }} </span>
          </div>

          <div class="badge-value gt-p-small">
            <input
              style="width: 95%"
              type="range"
              [min]="0"
              placeholder="Notification Sound"
              [max]="100"
              [(ngModel)]="muteNotification"
              (mouseup)="changeNotificationVolume()"
              (touchend)="changeNotificationVolume()"
            />
          </div>
        </div>
        <div class="row row-cols-2">
          <div class="col">
            <button
              class="nbbutton-long-text"
              status="basic"
              nbButton
              size="giant"
              fullWidth
              (click)="editProfile(profileDialog)"
            >
              Update Details
            </button>
          </div>
          <ng-container *ngIf="isTrustedDevice">
            <div class="col">
              <button
                class="nbbutton-long-text"
                status="basic"
                nbButton
                size="giant"
                fullWidth
                (click)="sendPasswordResetLink(userDetails)"
              >
                Reset Password
              </button>
            </div>
            <div class="col">
              <button
                class="nbbutton-long-text"
                status="basic"
                nbButton
                size="giant"
                fullWidth
                (click)="checkEmailEnter($event)"
              >
                Update Email
              </button>
            </div>
          </ng-container>

          <div class="col">
            <button
              class="nbbutton-long-text"
              status="basic"
              nbButton
              size="giant"
              fullWidth
              (click)="toggleTheme()"
            >
              Switch Theme
            </button>
          </div>
        </div>
        <div class="row" *ngIf="!isDeviceRegistered && isTrustedDevice">
          <div class="col">
            <button
              class="nbbutton-long-text"
              status="primary"
              nbButton
              size="giant"
              fullWidth
              (click)="fidoRegister()"
            >
              Enable WebAuthn
            </button>
          </div>
        </div>
        <div class="row" *ngIf="!isDeviceRegistered && isTrustedDevice">
          <a
            href="https://youtu.be/zJPNuORkvvk?t=61"
            target="_blank"
            rel="noopener"
          >
            <button
              class="nbbutton-long-text"
              fullWidth
              nbButton
              outline
              size="giant"
              status="primary"
            >
              What is WebAuthn?
            </button>
          </a>
        </div>
      </div>
    </div>

    <div
      style="
        border-top: 0.5rem solid var(--background-basic-color-3);
        padding: 0.5rem;
      "
    >
      <div *ngIf="isSuperUser !== true">
        <div>
          <app-card-list
            [listCardData]="userLicenses"
            [listCardDetails]="licenseDetails"
            [cardActionIcons]="cardActionIcons"
            (actionClick)="deleteLicense($event?.event)"
          ></app-card-list>
        </div>

        <div class="mt-3">
          <button
            nbButton
            class="nbbutton-long-text"
            status="primary"
            fullWidth
            size="giant"
            (click)="addNewLicense()"
          >
            + Add Licence
          </button>
        </div>
      </div>
    </div>

    <div></div>
  </nb-card-body>

  <!-- mobile view ends -->
  <!-- desktop view starts -->
  <nb-card-body class="large-view-only gt-p-1">
    <div style="display: flex">
      <div class="profile-dp" *ngIf="isSuperUser !== true">
        <div *ngIf="imageUploaded" lass="div.stretchy-wrapper">
          <img
            class="profile-picture"
            src="{{ userProfileUrl }}"
            alt="User-Profile-Image"
          />
        </div>
        <div
          *ngIf="!imageUploaded"
          class="div.stretchy-wrapper pointer"
          (click)="openCamPopup()"
        >
          <img
            class="profile-picture"
            src="{{ userProfileUrl }}"
            alt="User-Profile-Image"
          />
        </div>

        <div class="row" *ngIf="!imageUploaded">
          <div class="col">
            <button
              nbButton
              status="basic"
              fullWidth
              size="medium"
              (click)="openCamPopup()"
            >
              + Add Profile Picture
            </button>
          </div>
        </div>
        <div *ngIf="imageUploaded">
          <div>
            <button nbButton fullWidth size="small" (click)="openCamPopup()">
              Update
            </button>
          </div>
          <div>
            <button nbButton fullWidth (click)="removeImage()" size="small">
              Clear
            </button>
          </div>
        </div>
      </div>

      <div style="margin: 0rem; flex-grow: 1; max-width: 1080px">
        <div class="row">
          <div>
            <span class="badge-key">First Name</span>
            <span class="badge-value"> {{ userDetails?.first_name }}</span>
          </div>
          <div>
            <span class="badge-key">Last Name</span>
            <span class="badge-value"> {{ userDetails?.last_name }}</span>
          </div>
          <div *ngIf="userDetails?.mobile_number">
            <span class="badge-key">Mobile</span>
            <span class="badge-value"> {{ userDetails?.mobile_number }}</span>
          </div>
          <div>
            <span class="badge-key">Email</span>
            <span class="badge-value"> {{ userDetails?.email }}</span>
          </div>
          <div *ngIf="userDetails?.time_zone">
            <span class="badge-key">Timezone</span>
            <span class="badge-value">
              {{ userDetails?.time_zone }}
              {{ userDetails?.timeZone?.gmtOffset }}
            </span>
          </div>
          <div>
            <div class="badge-key">Notification Sound</div>

            <div class="badge-value gt-p-small">
              <input
                style="width: 95%"
                type="range"
                [min]="0"
                placeholder="Notification Sound"
                [max]="100"
                [(ngModel)]="muteNotification"
                (mouseup)="changeNotificationVolume()"
                (touchend)="changeNotificationVolume()"
              />
              <span class="sc-floating-right ms-4"
                >{{ muteNotification }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-buttons">
        <div class="row row-cols-1">
          <div class="col" *ngIf="!isDeviceRegistered && isTrustedDevice">
            <button
              nbButton
              fullWidth
              status="primary"
              (click)="fidoRegister()"
            >
              Enable WebAuthn
            </button>
          </div>

          <div class="col" *ngIf="!isDeviceRegistered && isTrustedDevice">
            <a
              href="https://youtu.be/zJPNuORkvvk?t=61"
              target="_blank"
              rel="noopener"
            >
              <button fullWidth nbButton outline status="primary">
                What is WebAuthn?
              </button>
            </a>
          </div>

          <div class="col">
            <button
              nbButton
              fullWidth
              status="basic"
              (click)="editProfile(profileDialog)"
            >
              Update Details
            </button>
          </div>
          <ng-container *ngIf="isTrustedDevice">
            <div class="col">
              <button
                nbButton
                fullWidth
                status="basic"
                (click)="sendPasswordResetLink(userDetails)"
              >
                Reset Password
              </button>
            </div>
            <div class="col" *ngIf="isSuperUser !== true">
              <button
                nbButton
                fullWidth
                status="basic"
                (click)="checkEmailEnter($event)"
              >
                Update Email
              </button>
            </div>
          </ng-container>

          <div class="col">
            <button nbButton fullWidth status="basic" (click)="toggleTheme()">
              Switch Theme
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="sc-mt-9" *ngIf="isSuperUser !== true">
      <strong
        >Security Licences
        <button nbButton class="gt-edit-btn" (click)="addNewLicense()">
          + Add Security Licence
        </button></strong
      >
      <div class="mt-2">
        <app-card-list
          [listCardData]="userLicenses"
          [listCardDetails]="licenseDetails"
          [cardActionIcons]="cardActionIcons"
          (actionClick)="deleteLicense($event?.event)"
        ></app-card-list>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<ng-template #profileDialog let-profileRef="dialogRef">
  <nb-card class="dialog-box">
    <nb-card-body>
      <form [formGroup]="profileForm">
        <div class="form-floating">
          <input
            class="form-control"
            fullWidth
            type="text"
            nbInput
            formControlName="first_name"
            id="first_name"
            maxlength="150"
            required
          />
          <label for="first_name">First Name</label>
        </div>
        <div class="form-floating">
          <input
            class="form-control"
            fullWidth
            type="text"
            nbInput
            formControlName="last_name"
            id="last_name"
            required
          />
          <label for="last_name">Last Name</label>
        </div>

        <div class="form-floating">
          <input
            class="form-control"
            fullWidth
            type="tel"
            minlength="10"
            maxlength="10"
            nbInput
            (input)="mobileNumberCheck()"
            formControlName="mobile_number"
            id="mobile_number"
          />
          <label for="mobile_number">Telephone</label>
          <ng-container
            *ngIf="
              profileForm.controls['mobile_number'].invalid &&
              profileForm.controls['mobile_number'].touched
            "
          >
          </ng-container>
        </div>
        <div class="form-floating">
          <nb-select
            fullWidth
            size="large"
            placeholder="Timezone"
            [(selected)]="selectedTimeZone"
          >
            <nb-option
              *ngFor="let timeZone of stateTimeZones"
              [value]="timeZone.value"
            >
              <div>{{ timeZone.value }} {{ timeZone.gmtOffset }}</div>
            </nb-option>
          </nb-select>
        </div>

        <div class="row row-cols-2">
          <div class="col">
            <button
              nbButton
              size="giant"
              ghost
              (click)="profileRef.close()"
              fullWidth
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              nbButton
              fullWidth
              size="giant"
              (click)="UpdateProfileDetails(); profileRef.close()"
              [disabled]="!profileForm.valid"
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</ng-template>
