import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-job-type',
  templateUrl: './job-type.component.html',
  styleUrls: ['./job-type.component.scss'],
})
export class JobTypeComponent implements OnInit {
  columns = [
    {
      header: 'Job Type',
      name: 'name',
    },
    {
      header: 'Created By',
      nestedValue: (row: any) => {
        return row?.created_by?.full_name;
      },
    },
    // {
    //   header: 'Created On',
    //   name: 'created_date',
    //   datetimeObj: true,
    // },
  ];

  jobStatus: any;
  dialogref: any;

  editData: any;

  totalStatus: any;
  previous: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;
  statusData: any;
  selectedStyle: any = [];
  selectedStatusName: any = [];
  constructor(private adminService: AdminService, private router: Router) {}

  ngOnInit(): void {
    this.getAllJobTypeList();
  }

  getAllJobTypeList() {
    let params: any = {};
    if (this.rows) {
      params['page_size'] = this.rows;
    }
    if (this.previous) {
      params['page'] = this.pageNum;
    }
    this.adminService.getJobtypeList(params).subscribe((resp: any) => {
      {
        this.jobStatus = resp['results'];
        this.totalRows = resp?.count;
        this.totalPages = Math.ceil(this.totalRows / this.rows);
      }
    });
  }

  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getAllJobTypeList();
  }

  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getAllJobTypeList();
  }

  rowClick(data: any) {
    this.router.navigate([
      '/gtadmin/job-type/edit',
      { jTKey: String(data?.id) },
    ]);
  }
}
