import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanLoad,
  Resolve,
  Route,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';

@Injectable({
  providedIn: 'root',
})
export class LoadGuard implements CanLoad, Resolve<LoadGuard> {
  constructor(private router: Router, private appService: AppService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): LoadGuard | Observable<LoadGuard> | Promise<LoadGuard> {
    throw new Error('Method not implemented.');
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    var userData = this.appService.getUserData();
    if (userData != null && userData.access) {
      return true;
    } else {
      this.router.navigate(['./login']);
      return false;
    }
  }
}
