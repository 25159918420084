<div class="large-view-only">
  <div class="row">
    <div class="col-12 sc-text-align-right">
      <small>Items per page</small>
      <nb-select
        outline
        class="pagination-dropdown"
        placeholder="Show"
        [(ngModel)]="pageSize"
        [selected]="pageSize"
        size="small"
      >
        <nb-option
          *ngFor="let paginationval of paginationValues"
          [value]="paginationval"
          (click)="onChangePaginationDropdown()"
        >
          {{ paginationval }}
        </nb-option>
      </nb-select>

      <span *ngIf="totalPages > 1">
        <button
          size="small"
          nbButton
          [disabled]="previous === 0"
          (click)="
            previous = previous - pageSize; pageNum = pageNum - 1; onClickPrev()
          "
        >
          <nb-icon [icon]="'arrowhead-left'" pack="eva" class="ico-color">
          </nb-icon>
        </button>

        <span> {{ pageNum }} of {{ totalPages }} </span>
        <button
          size="small"
          nbButton
          [disabled]="previous + pageSize >= totalRows"
          (click)="
            previous = previous + pageSize; pageNum = pageNum + 1; onClickNext()
          "
        >
          <nb-icon [icon]="'arrowhead-right'" pack="eva" class="ico-color">
          </nb-icon>
        </button>
      </span>
    </div>
  </div>
</div>
<div class="sc-text-center mobile-only mt-2">
  <button
    *ngIf="totalPages > 1"
    nbButton
    type="button"
    [disabled]="previous === 0"
    (click)="
      previous = previous - pageSize; pageNum = pageNum - 1; onClickNext()
    "
  >
    Prev
  </button>

  <span *ngIf="totalPages > 1" class="pageXofY">
    {{ pageNum }} of {{ totalPages }}
  </span>
  <button
    *ngIf="totalPages > 1"
    nbButton
    type="button"
    [disabled]="previous + pageSize >= totalRows"
    (click)="
      previous = previous + pageSize; pageNum = pageNum + 1; onClickNext()
    "
  >
    Next
  </button>
</div>
