import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { browserGpsInfo, checkScanDistanceGreater } from 'src/global.variables';
import { CheckpointService } from '../checkpoints/checkpoint.service';
import { PagesService } from '../pages.service';
import {
  checkPointHistoryTable,
  checkPointScanTable,
  sosAlertDetailView,
  subscriberTableDetails,
  userGroupTableDetails,
  userUpdateTable,
} from '../tableDetails';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'app-user-updates-log',
  templateUrl: './user-updates-log.component.html',
  styleUrls: ['./user-updates-log.component.scss'],
})
export class UserUpdatesLogComponent implements OnInit {
  @Input()
  eventType: any;
  showDetails: boolean = false;
  userEvents: any;
  userEventsTemp: any;
  selectedEvent: any;
  userEventDetails: any = userUpdateTable.mobileTable;
  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };
  userEventDetailsTableView: any = userUpdateTable.desktopTable;

  mapLatLong: any;
  uploadedImage: any;
  selectedEventType: string = 'patrol';
  sortKeys: any = [];
  searchParams: any;

  dateRange: any;
  apiFilter: boolean = true;
  scrollPosition: any;
  browserGpsInfo: any = browserGpsInfo;

  //pagination components
  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;

  subscriberUserId: any;
  newComment: any;
  dialogref: any;

  previousComment: any;
  cpId: any;
  eventTitle = 'Patrol History';
  showMap: boolean = true;

  tempDataList: any = [];
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;
  clockedInTime: any;
  scanDistanceInfo: any = {};
  constructor(
    private userService: UsersService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private pageService: PagesService,
    private appService: AppService,
    private dialog: NbDialogService,
    private checkpointService: CheckpointService,
    private router: Router,
    private dataCheckService: DataCheckService
  ) {
    let userData = this.appService.getUserData();
    this.subscriberUserId = userData?.subscriber?.subscriber_user_id;
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.route.params.subscribe({
      next: (params) => {
        if (this.route.snapshot.fragment) {
          if (this.route.snapshot.fragment === 'checkpointScans') {
            this.eventTitle = 'Scan History';
            this.userEventDetails = checkPointScanTable.mobileTable;
            this.userEventDetailsTableView = checkPointScanTable.desktopTable;
            this.apiFilter = false;
            if (params?.['cpKey']) {
              this.cpId = params?.['cpKey'];
            }
          } else if (this.route.snapshot.fragment === 'lastEvents') {
            this.selectedEventType = 'guard_last_events';
            this.sortKeys = ['-updated_at'];
            this.eventTitle = 'Event Log';
            this.userEventDetails = {
              individualDetails: [
                {
                  name: 'updated_at',
                  datetimeObj: true,
                  cardRowStyle: {
                    'font-size': 'small',
                  },
                },
                {
                  name: 'event_type',
                  cardRowStyle: {
                    'font-size': 'small',
                  },
                },
                {
                  name: 'action',
                  cardRowStyle: {
                    'font-weight': 'bold',
                  },
                },
                {
                  name: 'company_name',
                },
                {
                  name: 'detail',
                },
              ],
            };
            this.userEventDetailsTableView = [
              {
                header: 'Date',
                name: 'updated_at',
                datetimeObj: true,
              },
              {
                header: 'Event Type',
                name: 'event_type',
              },
              {
                header: 'Event Action',
                name: 'action',
              },
              {
                header: 'Client',
                name: 'company_name',
              },
              {
                header: 'Event',
                name: 'detail',
              },
            ];
          }
        }
      },
    });
  }

  ngOnInit(): void {
    if (this.eventType) {
      if (this.eventType == 'userGroup') {
        this.selectedEventType = 'user_group';
        this.userEventDetails = userGroupTableDetails.mobileTable;
        this.userEventDetailsTableView = userGroupTableDetails?.desktopTable;
      } else if (this.eventType == 'subscriber') {
        this.userEventDetails = subscriberTableDetails.mobileTable;
        this.userEventDetailsTableView = subscriberTableDetails?.desktopTable;
        this.selectedEventType = 'subscriber';
      } else if (this.eventType == 'checkpoint') {
        this.userEventDetails = checkPointHistoryTable.mobileTable;
        this.userEventDetailsTableView = checkPointHistoryTable.desktopTable;
        this.selectedEventType = 'checkpoint';
      }
    }
    this.getUserUpdateLog();
  }
  getUserUpdateLog() {
    this.spinnerService.show();

    let body: any = {
      event_type: this.selectedEventType,
      search_filters: {},
    };
    if (this.selectedEventType == 'guard_last_events') {
      let latestValue: any;
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      this.clockedInTime = this.dataCheckService.lastClockedInTime();
      if (this.clockedInTime) {
        latestValue = new Date(
          Math.max(yesterday.getTime(), new Date(this.clockedInTime).getTime())
        );
      } else {
        latestValue = yesterday;
      }

      body = {
        event_type: this.selectedEventType,
        start_date: latestValue,
        end_date: new Date(),

        sub_user_id: this.subscriberUserId,
      };
    }
    if (this.dateRange) body.date_range = this.dateRange;
    if (this.sortKeys) body.sort_key = this.sortKeys;
    if (this.searchParams) body.search_filters = this.searchParams;
    if (this.pageSize) body.rows = this.pageSize;
    if (this.previous) body.previous = this.previous;
    if (this.cpId) body.search_filters.check_point_id = this.cpId;

    this.showMap = false;
    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.userEvents = response['data'];

        this.tempDataList = this.userEvents;
        this.userEventsTemp = response['data'];
        this.totalRows = response?.total_size;
        this.totalPages = Math.ceil(this.totalRows / this.pageSize);
        this.showDetails = false;
        this.spinnerService.hide();
        this.showMap = true;
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }
  onTableRowClick(event: any) {
    this.scrollPosition = window.scrollY;
    this.showDetails = true;
    this.selectedEvent = event;
    this.tableStyle = {
      'overflow': 'auto',
      'max-height': '35vh',
    };
    if (event?.gps) this.mapLatLong = { ...event?.gps };
    this.uploadedImage = event?.file;
    this.scanDistanceInfo = checkScanDistanceGreater(this.selectedEvent);
  }

  handleDateChange(event: any) {
    if (event?.end) {
      this.dateRange = event;
      if (this.apiFilter) {
        this.getUserUpdateLog();
      } else {
        this.userEvents = this.userEventsTemp?.filter(
          (item: any) =>
            new Date(item?.['updated_at']).getTime() >=
              this.dateRange?.start.getTime() &&
            new Date(item?.['updated_at']).getTime() <=
              this.dateRange?.end.getTime()
        );
      }
    }
  }
  sortColumn(body: any) {
    this.sortKeys = body?.sortList || [];
    this.searchParams = body?.searchParams || {};
    this.getUserUpdateLog();
  }
  searchColumn(event: any) {
    this.dateRange = event?.date || null;
    this.searchParams = event?.searchParams || {};
    this.getUserUpdateLog();
  }
  hideDetails() {
    this.showDetails = false;

    // let scrollPosition: any = window.localStorage.getItem('scrollToView');
    setTimeout(() => {
      window.scrollTo(0, this.scrollPosition);
    }, 100);
  }
  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.getUserUpdateLog();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.getUserUpdateLog();
  }
  editComment(commentData: any, requestRef: TemplateRef<any>) {
    this.newComment = commentData?.detail;
    this.previousComment = commentData?.detail;
    this.dialogref = this.dialog.open(requestRef, {
      context: commentData,
    });
  }
  addComment(data: any) {
    if (this.newComment && this.newComment.trim()) {
      this.spinnerService.show();
      let body = {
        company_id: data?.company_id,
        comment: this.newComment,
        id: data?.id,
        edit_comment: 1,
      };
      this.checkpointService.updateClient(body).subscribe((response: any) => {
        this.getUserUpdateLog();
        this.newComment = '';
        this.showDetails = false;
        this.spinnerService.hide();
        window.location.reload();
      });
    }
  }

  canEditComment(eventData: any) {
    return (
      this.subscriberUserId == eventData?.subs_user_id &&
      eventData?.company_id &&
      new Date().getTime() - new Date(eventData?.updated_at).getTime() <
        72 * 3600 * 1000
    );
  }
  getLastCommentEditedDateTime(history: any) {
    let sortedComments = history?.sort(
      (a: any, b: any) =>
        new Date(a?.updated_at).getTime() - new Date(b?.updated_at).getTime()
    );
    return sortedComments[sortedComments?.length - 1];
  }
  getPromptValue(promptData: any) {
    return Array.isArray(promptData?.value)
      ? promptData?.value?.join(', ')
      : promptData?.value;
  }
}
