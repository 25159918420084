import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss'],
})
export class CardListComponent implements OnInit, OnChanges {
  @Input() showTableFrom: any;
  @Input() showDetailsCondition: boolean = false;
  @Input() showCallGuardButton: boolean = false;
  @Output() actionClick = new EventEmitter();
  @Output() rowClick = new EventEmitter();
  @Output() showDetailClick = new EventEmitter();
  @Output() assigneeSelect = new EventEmitter();
  @Input() listCardData: any;
  @Input() listCardDetails: any;
  @Input() cardActionIcons: any;

  cardIndividualDetails: any;
  cardGroupDetails: any;

  slicedAssigneeCount: any = 3;
  slicedUsersCount: any = 3;

  constructor() {}

  ngOnInit(): void {
    // card data
    this.cardIndividualDetails = this.listCardDetails?.individualDetails;
    this.cardGroupDetails = this.listCardDetails?.groupDetails;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.cardIndividualDetails = this.listCardDetails?.individualDetails;
    this.cardGroupDetails = this.listCardDetails?.groupDetails;
  }
  onActionClick(event: any, type: any) {
    this.actionClick.emit({ event, type });
  }

  onRowClick(rowData: any) {
    this.rowClick.emit(rowData);
  }
  showDetails(rowData: any) {
    this.showDetailClick.emit(rowData);
  }

  onAssigneeSelect(assignee: any) {
    this.assigneeSelect.emit(assignee);
  }

  slicedData(data: any[]): any[] {
    return data.slice(0, this.slicedUsersCount);
  }

  showAllAssignees(assignees: any) {
    this.slicedUsersCount = assignees?.length;
  }
}
