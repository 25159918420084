import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IncidentsService {
  constructor(private http: HttpClient) {}

  getIncidents(body?: any, params?: any) {
    return this.http.post(`/api/incident/list_incidents/`, body, {
      params: params,
    });
  }
  retrieveIncident(id: any, params?: any) {
    return this.http
      .get(`/api/incident/${id}/`, { params: params })
      .pipe(map((response) => response));
  }
  addIncident(body: any, params?: any) {
    return this.http
      .post('/api/incident/', body, { params: params })
      .pipe(map((response) => response));
  }
  updateIncident(body: any, params?: any) {
    return this.http
      .post(`/api/incident/update/`, body, { params: params })
      .pipe(map((response) => response));
  }
  deleteIncident(id: any) {
    return this.http
      .delete(`/api/incident/${id}`)
      .pipe(map((response) => response));
  }

  //incident Type related APIs
  fetchIncidentTypeList(params?: any) {
    return this.http
      .get('/api/incident_type/', { params: params })
      .pipe(map((response) => response));
  }
  createIncidentType(requestData?: any, params?: any) {
    return this.http
      .post('/api/incident_type/', requestData)
      .pipe(map((response) => response));
  }
  updateIncidentTypeDetailById(requestData: any) {
    const body = {
      ...requestData,
    };
    return this.http
      .put(`/api/incident_type/${requestData.id}/`, body)
      .pipe(map((response) => response));
  }
  deleteIncidentType(id: any, params?: any) {
    let body = {};
    return this.http.put(`api/incident_type/${id}/`, body, {
      params: params,
    });
  }
}
