import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { CheckpointService } from 'src/app/pages/checkpoints/checkpoint.service';
import { JobsService } from 'src/app/pages/jobs/jobs.service';
import { PagesService } from 'src/app/pages/pages.service';
import { DataCheckService } from '../../service/data-check.service';
import { AddCustomerComponent } from '../add-customer/add-customer.component';

// To use Html5Qrcode (more info below)
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { Html5Qrcode } from 'html5-qrcode';
import { Observable, of } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { gTDB } from 'src/app/db';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { showGeoFenceAlert, sortArrayOfObject } from 'src/global.variables';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { PrompterComponent } from '../prompter/prompter.component';
import { SaveNewAddressComponent } from '../save-new-address/save-new-address.component';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() checkPointScanData: any;
  @Input() showFrom: string = 'userMenu';
  @Input() cachedQRCheckpoints: any = [];
  @Input() patrolRouteId: any;
  checkPointForm: UntypedFormGroup = new UntypedFormGroup({
    qr_text: new UntypedFormControl('', Validators.required),
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.max(100),
    ]),
    company: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
    is_locked: new UntypedFormControl(false),
    company_id: new UntypedFormControl('', Validators.required),
    addressLookup: new UntypedFormControl(''),
    min_distance: new UntypedFormControl(100, [
      Validators.max(1000),
      Validators.required,
    ]),

    address1: new UntypedFormControl(),
    address2: new UntypedFormControl(),
    address3: new UntypedFormControl(),
    city_name: new UntypedFormControl(''),
    state_code: new UntypedFormControl(''),
    country_name: new UntypedFormControl(''),
    postcode: new UntypedFormControl(''),
    longitude: new UntypedFormControl(null),
    latitude: new UntypedFormControl(null),
  });
  dialogref: any;
  customerListData: any = [];
  registerScan: boolean = true;
  scanInProgress: boolean = false;

  backupMinDistanceValue: number = 0;
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;
  addrLookupData$: Observable<any> | any;
  showForm: boolean = false;
  cameraDevices: any = [];

  html5torch: boolean = false;
  devieTorchOn: boolean = false;

  html5QrCode!: Html5Qrcode;
  qrScanErrorMessage: string = '';

  qr_config = {
    fps: 29,
    qrbox: { width: 250, height: 250 },
    rememberLastUsedCamera: true,
    showTorchButtonIfSupported: true,
  };
  zoomRange: any = {};
  showMap: boolean = false;
  gps: any;
  originalLatLng: any = {};
  userLocation: any = {};

  isPwa = Boolean(window.matchMedia('(display-mode: standalone)').matches);
  cameraFeedDetected: boolean = true;
  offlineMode = !navigator.onLine;

  constructor(
    @Optional() protected dialogRef: NbDialogRef<QrCodeComponent>,
    private dialogService: NbDialogService,
    private jobService: JobsService,
    private checkpointService: CheckpointService,
    private spinnerService: NgxSpinnerService,
    private pageService: PagesService,
    private dataCheckService: DataCheckService,
    private profileService: ProfileService,
    private appService: AppService,
    private route: Router,
    private swPush: SwPush
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();

    this.pageService.isOnEvent.next(true);
    window.addEventListener('online', this.offlineCheck.bind(this));
    window.addEventListener('offline', this.offlineCheck.bind(this));
  }
  offlineCheck() {
    this.offlineMode = !navigator.onLine;
  }
  async ngAfterViewInit(): Promise<void> {
    this.pageService.isOnEvent.next(true);

    this.html5QRReader();

    if (!this.cachedQRCheckpoints?.length) {
      this.cachedQRCheckpoints = await this.getQRCheckpointList();
    }
  }

  onCloseDialogue() {
    try {
      if ([2, 3].includes(this.html5QrCode?.getState())) {
        this.html5QrCode?.stop();
      }
    } catch (error) {}

    this.pageService.isOnEvent.next(false);
    this.dialogRef.close(false);
  }
  ngOnDestroy(): void {
    try {
      if ([2, 3].includes(this.html5QrCode?.getState())) {
        this.html5QrCode?.stop();
      }
    } catch (error) {}

    this.pageService.isOnEvent.next(false);
  }

  html5QRReader() {
    this.spinnerService.show();
    this.html5QrCode = new Html5Qrcode('html5-qr-reader');
    const config = this.qr_config;
    // if we want to default camera to back may need to use this in iphones
    //  this.html5QrCode.start(
    //    { facingMode: 'environment' },
    //    config,
    //    this.qrCodeSuccessCallback,
    //    this.qrCodeErrorCallback
    //  );

    Html5Qrcode.getCameras() // Call the static method on the class
      .then((devices) => {
        setTimeout(() => {
          this.cameraDevices = devices;

          this.appService
            .getGpsCoordinates()
            .then((gps: any) => (this.userLocation = gps));

          if (devices && devices.length) {
            let devcieID = devices[devices.length - 1]?.id;
            this.html5QrCode
              .start(
                devcieID,
                config,
                (decodedText, decodedResult) => {
                  this.html5torch = false;
                  this.qrValueAction(decodedText);
                  this.html5QrCode.stop();
                },
                (errorMessage) => {
                  // parse error, ignore it.
                }
              )
              .then(() => {
                this.checkAndCallAfterFuntions();
              })
              .catch((error) => {
                this.spinnerService.hide();
                console.log(error, 'HTML% ERROER');
              });
          }
          // setTimeout is for firefox as it is not able to get devices fast
        }, 1000);
      })
      .catch((err) => {
        this.spinnerService.hide();
      });
  }
  checkCameraFeed() {
    try {
      const videoElement = document.querySelector('video');

      if (videoElement) {
        videoElement.addEventListener('playing', () => {
          setTimeout(() => {
            // this is for firefox as height is 0 without a timeout even if the feed is there
            this.spinnerService.hide();
            this.cameraFeedDetected = isVideoPlaying(videoElement);
            if (this.cameraFeedDetected) {
              this.isTorchSupported(this.html5QrCode);
              this.isZoomSupported(this.html5QrCode);
            }
          }, 1000);
        });
      } else {
        this.spinnerService.hide();
        this.cameraFeedDetected = false;
      }
      setTimeout(() => {
        // this is for iphones as some devices are not listening to 'playing' eventListner
        this.spinnerService.hide();
        this.cameraFeedDetected = isVideoPlaying(videoElement);
        if (this.cameraFeedDetected) {
          this.isTorchSupported(this.html5QrCode);
          this.isZoomSupported(this.html5QrCode);
        }
      }, 2000);

      function isVideoPlaying(video: any) {
        return (
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > 2 &&
          video.videoWidth > 0 &&
          video.videoHeight > 0
        );
      }
    } catch (error) {
      this.spinnerService.hide();
      this.cameraFeedDetected = false;
    }
  }
  checkAndCallAfterFuntions() {
    if (this.html5QrCode.getState() === 2) {
      this.checkCameraFeed();
    } else {
      setTimeout(() => {
        this.checkAndCallAfterFuntions();
      }, 1000);
    }
  }
  isZoomSupported(html5Qrcode: Html5Qrcode) {
    let settings: any = html5Qrcode.getRunningTrackCapabilities();
    if ('zoom' in settings) {
      this.zoomRange = { ...settings?.zoom, value: 1 };
    }
  }
  async applyZoom() {
    let constraints: any = {
      advanced: [{ zoom: this.zoomRange?.value }],
    };
    let val = await this.html5QrCode?.applyVideoConstraints(constraints);
  }

  isTorchSupported(html5Qrcode: Html5Qrcode) {
    let settings = html5Qrcode.getRunningTrackSettings();

    this.html5torch = 'torch' in settings;
  }
  async turnOffOnTorchHTML5(action: string) {
    if (action == 'on') {
      let constraints: any = {
        torch: true,
        advanced: [{ torch: true }],
      };
      await this.html5QrCode?.applyVideoConstraints(constraints);
      let settings: any = this.html5QrCode?.getRunningTrackSettings();
      if (settings?.torch === true) {
        this.devieTorchOn = true;
      } else {
        this.devieTorchOn = false;
      }
    } else {
      let constraints: any = {
        torch: false,
        advanced: [{ torch: false }],
      };
      this.devieTorchOn = false;
      await this.html5QrCode?.applyVideoConstraints(constraints);
    }
  }
  qrLookup() {
    if (this.swPush.isEnabled) {
      const scannedQRData: any = this.cachedQRCheckpoints?.find(
        (qrCp: any) => qrCp?.qr_code === this.checkPointForm.value?.qr_text
      );
      if (scannedQRData) {
        let data: any = {
          qr_text: this.checkPointForm.value?.qr_text,
          check_point_id: scannedQRData?.id,
          user_prompts: scannedQRData?.user_prompts,
          user_prompt_count: scannedQRData?.user_prompt_count,
          minDistance: scannedQRData?.min_distance,
          gps: scannedQRData?.gps,
          checkpointDetail: {
            name: scannedQRData?.name,
            company: scannedQRData?.company,
          },
        };
        if (this.patrolRouteId) {
          data.patrol_route_id = this.patrolRouteId;
        }

        this.openUserPrompt(data);
      } else {
        this.alreadyRegistered();
      }
    } else {
      this.alreadyRegistered();
    }
  }

  async alreadyRegistered() {
    this.spinnerService.show();

    this.checkpointService
      .getCheckpoints({
        qr_lookup: 1,
        qr_text: this.checkPointForm.value?.qr_text,
      })
      .subscribe(async (response: any) => {
        if (response?.status == 'failure' && response?.id) {
          this.spinnerService.hide();
          if (this.showFrom === 'home') {
            let data: any = {
              qr_text: this.checkPointForm.value?.qr_text,
              check_point_id: response?.id,
              user_prompts: response?.user_prompts,
              user_prompt_count: (response?.user_prompts || [])?.length,
              minDistance: response?.min_distance,
              gps: response?.gps,
              checkpointDetail: {
                name: response?.name,
                company: response?.company,
              },
            };
            if (this.patrolRouteId) {
              data.patrol_route_id = this.patrolRouteId;
            }
            this.openUserPrompt(data);
          } else {
            this.pageService.setMessage({
              successMessage: 'ERROR: This QR code has already been registered',
              status: 'danger',
            });
            this.onCloseDialogue();
          }
        } else {
          this.spinnerService.hide();
          if (this.showFrom === 'home') {
            const qrInQueues = await this.checkQrinQueue(
              this.checkPointForm.value?.qr_text
            );
            if (qrInQueues) {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: 'Already in Queue',
              });
              this.onCloseDialogue();
            } else {
              const dialogRef = this.dialogService.open(
                ConfirmDialogComponent,
                {
                  context: {
                    title: 'QR Code Not Recognized',
                    message: `Press confirm to add new QR Checkpoint`,
                  },
                }
              );
              dialogRef.onClose.subscribe((value: any) => {
                if (value === 'Yes') {
                  this.showForm = true;
                } else {
                  this.onCloseDialogue();
                }
              });
            }
          } else {
            this.showForm = true;
          }
        }
      });
  }
  async checkGeoFence(checkpointData: any) {
    return new Promise(async (resolve) => {
      if (checkpointData?.minDistance && checkpointData?.gps) {
        if (!this.userLocation?.lat) {
          this.userLocation = await this.appService.getGpsCoordinates();
        }

        resolve(
          showGeoFenceAlert(
            checkpointData?.minDistance,
            checkpointData?.gps?.lat,
            this.userLocation?.lat,
            checkpointData?.gps?.lon,
            this.userLocation?.lon
          )
        );
      } else {
        resolve(false);
      }
    });
  }
  async scanQRCheckpoint(checkpointData: any) {
    const geoFenceRadiusAlert: any = await this.checkGeoFence(checkpointData);
    if (geoFenceRadiusAlert) {
      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        context: {
          title: '',
          message: `You are outside the geofence radius. Ignore and upload scan?`,
        },
      });
      dialogRef.onClose.subscribe((value: any) => {
        if (value === 'Yes') {
          this.onScanQR(checkpointData);
        } else {
          this.onCloseDialogue();
        }
      });
    } else {
      this.onScanQR(checkpointData);
    }
  }

  onScanQR(body: any) {
    if (!this.scanInProgress) {
      this.scanInProgress = true;
      this.spinnerService.show();
      this.jobService.scanCheckPoint(body).subscribe((response: any) => {
        if (response['status'] === 'success') {
          this.route.navigate(['/dashboard']);
          this.dialogRef.close(response);
          this.pageService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
            status: 'info',
          });
        } else {
          this.dialogRef.close(true);
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
            status: 'danger',
          });
        }

        this.spinnerService.hide();
      });
    }
  }
  getSortedPromtList(arrayList: any) {
    return sortArrayOfObject(arrayList, 'order', 'desc');
  }
  openUserPrompt(checkPointData: any) {
    if (checkPointData?.user_prompt_count > 0) {
      let promptResults: any = [];
      let counter = 0;
      const dialogRefs: any = [];
      this.getSortedPromtList(checkPointData?.user_prompts)?.forEach(
        (prompt: any) => {
          const dialogref = this.dialogService.open(PrompterComponent, {
            context: { promptData: prompt?.prompts },
          });
          dialogRefs.push(dialogref);
          dialogref.onClose.subscribe((value: any) => {
            if (value) {
              counter += 1;
              if (value?.length) {
                promptResults.push({
                  data: value,
                  prompt_data: { id: prompt?.id, name: prompt?.name },
                });
              }
              if (counter === checkPointData?.user_prompt_count) {
                checkPointData.prompt_data = JSON.stringify(promptResults);
                this.scanQRCheckpoint(checkPointData);
              }
            } else {
              // this.spinnerService.show();
              if (value === false) {
                dialogRefs?.forEach((dialogRef: any) => dialogRef?.close());
                this.pageService.setMessage({
                  successMessage: '',
                  errorMessage: 'Checkpoint was not scanned',
                  status: 'warning',
                });
                this.onCloseDialogue();
              }
            }
          });
        }
      );
    } else {
      this.scanQRCheckpoint(checkPointData);
    }
  }

  registerQR(body: any) {
    this.spinnerService.show();
    if (this.registerScan) body.scan_after_register = true;
    let reqBody = { form_data: JSON.stringify(body) };

    this.checkpointService.registerQRCode(reqBody).subscribe((response) => {
      if (response?.status == 'success') {
        this.spinnerService.hide();
        this.pageService.setMessage({
          successMessage: response?.message,
          errorMessage: '',
        });
        this.dialogRef.close(true);
      } else {
        this.spinnerService.hide();

        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response?.message,
        });
      }
    });
  }
  async formatBody() {
    return new Promise((resolve) => {
      if (!this.checkPointForm.valid) {
        resolve(false);
      } else {
        resolve(this.checkPointForm.value);
      }
    });
  }
  async onClickSubmit() {
    let body = await this.formatBody();

    if (body) {
      this.registerQR(body);
    }
  }
  shiftFocus(elementId: string, time?: number) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    }, time);
  }
  onClientSelect(data: any) {
    if (data === ' ') {
      this.openAddCustomerForm();
    }
    if (data?.id) {
      this.checkPointForm.controls['company_id'].setValue(data.id);
      this.checkPointForm.controls['company'].setValue(data.company_name);
      this.shiftFocus('description');
    }
  }
  openAddCustomerForm() {
    this.dialogref = this.dialogService.open(AddCustomerComponent, {
      context: { customerName: this.checkPointForm.value?.company },
      dialogClass: 'model-full',
    });

    this.dialogref.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.onClientSelect(value?.data);
      }
    });
  }
  onClientSearch(event: any) {
    if (event.target.value?.length > 2) {
      this.jobService
        .getSearchClients(event.target.value)
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.customerListData = res['data'];
          }
        });
    }
  }

  qrValueAction(qrCodeValue: string) {
    this.checkPointForm.controls['qr_text'].setValue(qrCodeValue);
    if (this.checkPointScanData) {
      this.dialogRef.close(qrCodeValue);
    } else {
      this.qrLookup();
    }
  }
  showMapTrue() {
    this.spinnerService.show();
    this.showMap = false;

    setTimeout(async () => {
      this.showMap = true;
      let device_info = await this.appService.getGpsCoordinates();
      this.gps = device_info;
      this.spinnerService.hide();
    }, 100);
  }
  backPreviousStep() {
    this.showMap = false;
    this.checkPointForm.controls['is_locked'].setValue(false);
    this.checkPointForm.controls['longitude'].removeValidators(
      Validators.required
    );
    this.checkPointForm.controls['latitude'].removeValidators(
      Validators.required
    );
  }
  showHideAddressFields(event: any) {
    if (event === true) {
      this.checkPointForm.controls['longitude'].setValidators(
        Validators.required
      );
      this.checkPointForm.controls['latitude'].setValidators(
        Validators.required
      );
    }
  }
  onAddressSearch(addressSearch?: any) {
    if (addressSearch?.target?.value?.length > 2) {
      this.profileService
        .addressLookup(addressSearch?.target?.value)
        .subscribe((res: any) => {
          this.addrLookupData$ = of(res['data']);
        });
    }
  }
  addressSelected(address: any) {
    if (address === ' ') {
      this.addPlace();
    } else if (address && typeof address === 'object') {
      this.addrLookupData$ = undefined;
      this.gps = { lat: address?.latitude, lon: address?.longitude };
      this.checkPointForm.controls['address1'].setValue(address?.address1);
      this.checkPointForm.controls['addressLookup'].setValue(
        address?.address1 +
          ' ' +
          address?.address2 +
          ' ' +
          address?.city_name +
          ' ' +
          address?.state_code +
          ' ' +
          address?.postcode
      );

      this.checkPointForm.controls['address2'].setValue(address?.address2);
      this.checkPointForm.controls['address3'].setValue(address?.address3);
      this.checkPointForm.controls['city_name'].setValue(address?.city_name);
      this.checkPointForm.controls['postcode'].setValue(address?.postcode);
      this.checkPointForm.controls['state_code'].setValue(address?.state_code);
      this.checkPointForm.controls['country_name'].setValue(
        address?.country_name
      );
      this.checkPointForm.controls['latitude'].setValue(address?.latitude);
      this.checkPointForm.controls['longitude'].setValue(address?.longitude);

      this.showMap = true;
      this.disableId('addressSearch');
    }
  }
  disableId(idVal: string = 'addressSearch') {
    var element = <HTMLInputElement>document.getElementById(idVal);
    if (element) {
      element.disabled = false;
      element.blur();
    }
  }
  emitData(event: any) {
    this.checkPointForm.controls['latitude'].setValue(event.lat);
    this.checkPointForm.controls['longitude'].setValue(event.lng);

    this.gps.lat = event.lat;
    this.gps.lon = event.lng == undefined ? event.lon : event.lng;
  }
  updateMinDistance(event: any) {
    // method to update the min distance emitted from the map component
    this.checkPointForm.controls['min_distance'].setValue(event);
  }
  addPlace(context = { showFrom: 'cpPage' }) {
    this.showMap = false;
    const dialogRef = this.dialogService.open(SaveNewAddressComponent, {
      dialogClass: 'model-full',
      context: context,
    });
    dialogRef.onClose.subscribe((value: any) => {
      if (value != 'close') {
        if (value) {
          this.addressSelected(value);
        }
      }
    });
  }

  async getQRCheckpointList() {
    return new Promise(async (resolve) => {
      try {
        if (this.swPush.isEnabled) {
          const result: any = await gTDB.cachedData
            .where({ shortPath: '/api/check_point/list_qr_checkpoints/' })
            .toArray();

          resolve(result?.[0]?.data);
        }
      } catch (error) {
        resolve([]);
      }
    });
  }
  async checkQrinQueue(qrValue: string) {
    return new Promise(async (resolve) => {
      try {
        if (this.swPush.isEnabled) {
          const result = await gTDB.gtSavedData.toArray();

          resolve(
            result?.some(
              (item: any) =>
                item?.pathName === '/api/check_point/register_qr/' &&
                !item?.apiCallSuccess &&
                !item?.retry_count &&
                JSON.parse(item?.form_data)?.qr_text === qrValue
            )
          );
        } else {
          resolve(false);
        }
      } catch (error) {
        resolve(false);
      }
    });
  }
}
