<!--Install PWA APP steps TEMPLATE -->
<nb-card style="padding: 0 1rem" class="dialog-box">
  <nb-card-header class="sc-text-center">
    Installation Guide
    <nb-icon
      [icon]="'close-outline'"
      class="sc-floating-right"
      pack="eva"
      (click)="close('close')"
    >
    </nb-icon>
  </nb-card-header>
  <nb-card-body id="install-app" class="scrollable-y">
    <div class="installation-guide-dialog">
      <div *ngIf="currentStep >= 0" class="installation-step">
        <img
          (click)="onOpenImage($event, imgRef)"
          [src]="installationSteps[currentStep]?.imgSrc"
          alt="Step Image"
        />
      </div>

      <div class="row row-cols-auto">
        <div class="col" *ngIf="currentStep > 0">
          <button
            tabindex="-1"
            nbButton
            size="giant"
            fullWidth
            (click)="navigate(-1)"
          >
            Back
          </button>
        </div>
        <div class="col" *ngIf="currentStep < installationSteps?.length - 1">
          <button
            fullWidth
            nbButton
            status="primary"
            size="giant"
            (click)="navigate(1)"
          >
            Next
          </button>
        </div>
        <div class="col" *ngIf="currentStep === installationSteps?.length - 1">
          <button
            fullWidth
            nbButton
            status="primary"
            size="giant"
            (click)="close('close')"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
<!-- Modal -->
<ng-template #imgRef let-data let-imgTempRef="dialogRef">
  <nb-card style="margin: 0">
    <nb-card-body (click)="imgTempRef.close()">
      <img
        src="{{ fullImgSrc }}"
        style="
          max-width: 100%;
          cursor: pointer;
          max-height: 95vh;
          border: 3px var(--background-basic-color-3) solid;
          border-radius: 2%;
        "
        class="modal-hover-opacity"
      />
    </nb-card-body>
  </nb-card>
</ng-template>
