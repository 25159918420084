import { CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import {
  NbDialogService,
  NbLayoutScrollService,
  NbMediaBreakpointsService,
  NbMenuService,
  NbPopoverDirective,
  NbSidebarService,
  NbThemeService,
  NbTooltipDirective,
} from '@nebular/theme';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { interval, takeWhile } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';
import { MultipleScubcribersComponent } from '../shared/comp/multiple-scubcribers/multiple-scubcribers.component';
import { DataCheckService } from '../shared/service/data-check.service';
import {
  MENU_ITEMS,
  SUPER_USER_MENU_ITEMS,
  appUserMenu,
  superUserMenu,
} from './pages-menu';
import { PagesService } from './pages.service';
import { ProfileService } from './profile/profile.service';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SwPush } from '@angular/service-worker';
import { PermissionsService } from '../auth/auth/permissions.service';
import { gTDB } from '../db';
import { ConfirmDialogComponent } from '../shared/comp/confirm-dialog/confirm-dialog.component';
import { QrCodeComponent } from '../shared/comp/qr-code/qr-code.component';
import { WhatsNewComponent } from '../shared/comp/whats-new/whats-new.component';
import { AppChecksComponent } from './app-checks/app-checks.component';
import { JobsService } from './jobs/jobs.service';
import { OrientationLockService } from './lock-orientation.service';
declare let html2canvas: any;

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit {
  userPictureOnly: boolean = false;
  loggedInUser: any = {};
  userData: any;
  // notification params
  notificationData: any = [];
  previous: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;

  notificationCount: number = 0;
  showWelfareCheckButton: boolean = false;
  userMenu = appUserMenu;
  @ViewChild('toggleButton')
  toggleButton!: ElementRef;
  @ViewChild('menu')
  menu!: ElementRef;
  @ViewChild('menuSside')
  menuSside!: ElementRef;
  showBottomButton = true;
  windowScrolled: boolean = false;

  @ViewChild(NbPopoverDirective) popover: any;
  @ViewChildren(NbPopoverDirective) popovers: any =
    QueryList<NbPopoverDirective>;
  showPendingCheckpoints: boolean = false;
  isAdmin: any;
  currentTheme: any;
  hasMultipleAssociation: boolean = false;
  ipAddress: any;
  patrolData: any;
  showTrialExpireCountDown: boolean = false;
  trialStatus: any;
  expiresTimeDifference: any;
  subscriberStats: any;

  expandIcon: boolean = false;
  errorMessage: any;
  successMessage: any;
  gtBtmBtns: boolean = true;

  items: any = [];
  dialogref: any;
  changeDialogRef: any;
  @ViewChild('search')
  search!: ElementRef;
  fileData: any = [];

  feedbackScreenShot: any;
  feedback: any;

  feedbackForm = new FormGroup({
    feedback: new FormControl('', [
      Validators.required,
      Validators.maxLength(340),
    ]),
  });

  isSuperUser: any;

  isInBackgroundSync: boolean = false;
  isAppOnline: boolean = true;
  isOnEvent: boolean = false;
  offlineMode: boolean = false;
  messageChannel = new MessageChannel();
  isDragging = false;

  dragging: boolean = false;
  intervalId: any;
  messageStatus: any;
  isSubscriberAdmin: boolean = false;
  isDispatchUser: boolean = false;
  settingItems: any = [];

  deferredPrompt: any;

  isGuardUser: boolean = false;
  isClockedIn: boolean = false;
  isHomeComponent: boolean = false;
  allowedPaths: boolean = false;
  isSubscriptionActive: boolean = true;

  isPreviousSubscribed: boolean = false;

  sosAlerts: any = [];
  sosDialogBoxOpened: any;

  sosAlertCount: number = 0;
  isAlertActive: boolean = false;
  beepIntervalSubscription: any;
  permissionErrorStatus: any;
  feedbackBtnObstructionView: boolean = window.innerWidth >= 610;

  @ViewChild(NbTooltipDirective, { static: false }) tooltip: any;
  constructor(
    private themeService: NbThemeService,
    private router: Router,
    private route: ActivatedRoute,
    protected bpService: NbMediaBreakpointsService,
    private readonly sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private scrollService: NbLayoutScrollService,
    private appService: AppService,
    private profileService: ProfileService,
    private spinnerService: NgxSpinnerService,
    private dialogService: NbDialogService,
    private deviceService: DeviceDetectorService,
    private pageService: PagesService,
    private dialog: NbDialogService,
    private dataCheckService: DataCheckService,
    private ngZone: NgZone,
    private orientationLockService: OrientationLockService,
    private jobService: JobsService,
    private cdr: ChangeDetectorRef,
    private swPush: SwPush,
    private permissionsService: PermissionsService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
        this.changeDialogRef?.close();
      }
    });
    this.offlineMode = !navigator?.onLine;
    this.updateAppCheck();

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar

        this.enableScrollOnBody();
        this.pageService.refreshToken().then((value: any) => {
          if (value) this.updateLoggedInUserDetail();
        });
      }

      if (event instanceof NavigationEnd) {
        // Hide progress spinner,alert message or progress bar

        this.errorMessage = null;
        this.successMessage = null;
        this.isHomeComponent = event.urlAfterRedirects === '/dashboard';
        const freePaths = ['/profile', '/support', '/subscription'];
        if (this.isAdmin || this.isDispatchUser) freePaths.push('/sos-alerts');
        let urlPath = event.urlAfterRedirects;
        try {
          let urlPaths = event.urlAfterRedirects.split(';');
          urlPath = urlPaths[0];
        } catch (error) {}

        this.allowedPaths = freePaths.includes(urlPath);
      }

      if (event instanceof NavigationError) {
        // Hide progress spinner or progress bar
        // Present error to user
        console.error(event.error);
      }
      this.isSubscriptionActive =
        this.dataCheckService.checkIsSubscriptionOrTestUser();
    });
    this.windowScrolled = false;
    this.userData = this.appService.getUserData();
    this.isSuperUser = this.dataCheckService?.isSuperUser();
    this.isSubscriberAdmin = this.dataCheckService?.isSubscriberAdmin();
    this.isAdmin = this.dataCheckService?.isUserAdmin();
    this.isDispatchUser = this.dataCheckService?.isDispatchUser();
    this.isGuardUser = this.dataCheckService?.isGuardUser();
    this.isClockedIn = this.dataCheckService?.isGuardUserClockendIn();
    this.isPreviousSubscribed =
      this.dataCheckService?.checkIsPreviouslySubscribedAndSubscriptionInActive();
    this.hasMultipleAssociation =
      this.dataCheckService?.hasMultipleSubscriberAssociation();

    if (this.userData?.checks?.trial_end) {
      if (environment.devMode === true) {
        this.showTrialExpireCountDown = false;
        this.trialStatus = '';
      } else {
        this.trialStatus = interval(1000).subscribe((x) => {
          this.getTimeDifference();
        });
      }
    }
    this.currentTheme = this.userData?.preferences?.theme || 'default';
    this.themeService.changeTheme(this.currentTheme);

    this.menuService.onItemSelect().subscribe((item: any) => {
      document.body.classList.remove('sidebar-open');
      if (window.innerWidth < 575) {
        this.sidebarService.collapse('menu-sidebar');
      }
    });
    // Scrollbar Service to hide/show the side navbar
    this.scrollService.onScroll().subscribe((value) => {
      if (window.innerWidth < 100) {
        this.sidebarService.collapse('menu-sidebar');
      }
      if (window.scrollY > 600) {
        this.windowScrolled = true;
      } else {
        this.windowScrolled = false;
      }
    });
    this.menuService.onSubmenuToggle().subscribe((item: any) => {
      var otherMenuItem: any;
      if (window.innerWidth < 576) {
        // Mobile view
        otherMenuItem = document.getElementById('otheMenuMob');
      } else if (window.innerWidth >= 576 && window.innerWidth < 768) {
        // Tablet view
        otherMenuItem = document.getElementById('otherMenuItemsCompact');
      } else {
        // Desktop view (Assuming window.innerWidth is greater than or equal to 768)
        otherMenuItem = document.getElementById('otheMenu');
      }

      if (item.item.expanded === true) {
        otherMenuItem.style.display = 'none';
        if (window.innerWidth < 1200 && window.innerWidth > 575) {
          this.sidebarService.expand('menu-sidebar');
        }
      } else {
        otherMenuItem.style.display = 'block';
      }
    });
    this.menuService.onItemClick().subscribe((item: any) => {
      if (this.router.url == item.item.link) {
        window.location.reload();
      }
      if (item.item['title'] === 'Edit Profile') {
        this.router.navigate(['/profile']);
      }
      if (item.item['title'] === 'Edit Profile' && this.isSuperUser) {
        this.router.navigate(['/gtadmin/profile']);
      }
      if (window.innerWidth < 575) {
        this.sidebarService.collapse('menu-sidebar');
      }
      if (item.item['title'] === 'Sign Out') {
        if (this.isGuardUser && this.isClockedIn) {
          this.dialogref = this.dialog.open(ConfirmDialogComponent, {
            context: {
              title: 'Sign Out',
              message: 'This will also clock you out.',
            },
          });
          this.dialogref.onClose.subscribe((value: any) => {
            if (value === 'Yes') {
              let params = {
                clock_out: 1,
              };
              this.spinnerService.show();
              this.profileService
                .guardClockedInOut(params)
                .subscribe((response: any) => {
                  if (response?.status == 'success') {
                    this.spinnerService.hide();
                    this.pageService.setMessage({
                      successMessage: response?.message,
                      errorMessage: '',
                    });
                    let userData = this.appService.getUserData();
                    userData.user_token = response?.user_token;
                    this.appService.setUserData(userData);
                  } else {
                    this.pageService.setMessage({
                      successMessage: '',
                      errorMessage: response?.message,
                    });
                    this.spinnerService.hide();
                  }
                  // setTimeout(() => {
                  //   window.location.reload();
                  // });
                });
              this.logOut();
            }
          });
        } else {
          this.logOut();
        }
      }
      if (item.item['title'] === 'My Company') {
        this.router.navigate(['/company-profile']);
      }

      if (item.item['title'] === 'Subscription') {
        this.router.navigate(['/subscription']);
      }
      if (item.item['title'] === 'Change Log') {
        this.openChangeLog();
      }
      if (item.item['title'] === 'Register QR Code') {
        this.registerQRCode();
      }
      if (item.item['title'] === 'Clock Out' && this.isClockedIn) {
        this.clockedInOut();
      }

      if (item.item['title'] === 'Change Account') {
        const fileImportDialogRef = this.dialogService.open(
          MultipleScubcribersComponent,
          {
            dialogClass: 'model-full',
          }
        );
        fileImportDialogRef.onClose.subscribe((value) => {
          if (value !== 'close') {
            window.location.reload();
          }
        });
      }

      this.sidebarService
        .getSidebarState('menu-sidebar')
        .subscribe((x: any) => {
          if (window.innerWidth < 1025 && window.innerWidth > 575) {
            this.sidebarService.compact('menu-sidebar');
          }
        });
    });
    this.pageService.returnMessage().subscribe((msg) => {
      this.ngZone.run(() => {
        this.errorMessage = msg?.errorMessage;
        this.successMessage = msg?.successMessage;
        this.messageStatus = msg?.successMessage ? 'primary' : 'warning';
        if (msg?.status) {
          this.messageStatus = msg?.status;
        }
        setTimeout(
          () => {
            this.successMessage = null;
            this.errorMessage = null;
          },
          msg?.timeOut ? msg?.timeOut : 5000
        );
      });
    });
  }
  openChangeLog() {
    this.spinnerService.show();
    this.profileService.getVersionInfo().subscribe((response: any) => {
      if (response?.status == 'success') {
        this.spinnerService.hide();

        this.dialogref = this.dialogService.open(WhatsNewComponent, {
          context: { appVersionUpdates: response },
        });
      }
      this.spinnerService.hide();
    });
  }

  updateAppCheck() {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      // Stash the event so it can be triggered later
      this.deferredPrompt = event;
      // to trigger the installation prompt
    });
  }

  showSubscriptionOption(): boolean {
    if (this.isSubscriberAdmin) {
      if (this.dataCheckService.isTestAccount()) return false;
      if (this.dataCheckService.isTrialAccount()) return true;
      return true;
    }

    return false;
  }

  async arrangeUserMenu() {
    this.userMenu = appUserMenu;
    this.userMenu = this.userMenu.filter((item: any) => {
      if (item.title === 'Clock Out') {
        return Boolean(this.isClockedIn && this.isSubscriptionActive);
      } else if (item.title === 'Subscription') {
        return Boolean(
          this.showSubscriptionOption() && this.checkPrevouslySubscribed()
        );
      } else if (item.title === 'Register QR Code') {
        return (this.isAdmin || this.isClockedIn) && this.isSubscriptionActive;
      } else if (item.title === 'Edit Profile') {
        return this.checkPrevouslySubscribed();
      } else if (item.title === 'Support') {
        return this.checkPrevouslySubscribed();
      } else if (item.title === 'Change Log') {
        return this.checkPrevouslySubscribed();
      } else if (item.title === 'Change Account') {
        return this.hasMultipleAssociation;
      } else {
        return true;
      }
    });
  }
  async getSideBar() {
    this.spinnerService.show();

    this.profileService
      .getUserConfig({
        remember_login: this.dataCheckService.isTrustedDevice() ? 1 : 0,
        time_offset: new Date().getTimezoneOffset(),
      })
      .subscribe((resp: any) => {
        if (resp['status'] == 'success') {
          let permissionToken = resp?.user_permission_token;
          let userData = this.appService.getUserData();
          if (permissionToken) {
            userData.user_token = permissionToken;
          }
          if (resp?.preferences) {
            userData.preferences = resp?.preferences;
          }
          this.appService.setUserData(userData);
          this.isSubscriptionActive =
            this.dataCheckService.checkIsSubscriptionOrTestUser();
          if (this.isSubscriberAdmin && !this.isSubscriptionActive) {
            this.router.navigate([
              '/subscription',
              {
                state: 'Account Verified! Subscription Required',
              },
            ]);
          }
          if (!this.isGuardUser || this.isClockedIn || this.isAdmin) {
            this.items = resp['data'];
            if (this.items.length === 0) {
              this.items = MENU_ITEMS;
            }

            if (this.isAdmin) {
              this.settingItems.push({
                title: 'Manage',
                icon: 'settings-2-outline',
                children: [
                  {
                    title: 'Company Profile',
                    link: '/company-profile',
                    icon: 'pantone-outline',
                  },
                  {
                    title: 'Guards & Groups',
                    link: '/users',
                    icon: 'people-outline',
                  },
                  {
                    title: 'Incident Types',
                    link: '/incident-type',
                    icon: 'layers-outline',
                  },
                  {
                    title: 'Monitoring Companies',
                    link: '/monitoring-company',
                    icon: 'shield-outline',
                  },
                  {
                    title: 'Response Types',
                    link: '/response-type',
                    icon: 'layers-outline',
                  },
                  {
                    title: 'User Prompts',
                    link: '/user-prompts',
                    icon: 'settings-outline',
                  },
                ],
              });
            }
          } else {
            this.items = [];
          }
        }
        setTimeout(() => {
          this.spinnerService.hide();
        }, 1000);
        this.arrangeUserMenu();
      });
  }

  checkOnlineOffline() {
    // if the user is not on middle of the update reload the page when the device comes back online
    this.isAppOnline = navigator?.onLine;
    if (!this.isOnEvent) {
      let offlineReloadCheck = this.offlineMode;
      this.offlineMode = !navigator?.onLine;

      if (this.offlineMode == false && offlineReloadCheck) {
        this.appService.getAddress().subscribe((response: any) => {
          if (response['status'] == 'success') {
            localStorage.setItem('syncData', 'true');
            window.location.reload();
          } else {
            setTimeout(() => {
              localStorage.setItem('syncData', 'true');
              window.location.reload();
            }, 2000);
          }
        });
      }
      if (this.offlineMode && !this.isOnEvent) {
        this.ngZone.run(() => {
          this.successMessage = `Disconnected from Internet. Running in offline mode.`;

          this.messageStatus = 'danger';
          setTimeout(() => {
            this.successMessage = null;
            this.messageStatus = null;
            this.router.navigate(['/dashboard']);
          }, 3000);
        });
      }
    }
  }
  syncQueuedData() {
    setTimeout(() => {
      if (!this.isInBackgroundSync) {
        this.isInBackgroundSync = true;

        // ask the service worker to start sync
        navigator?.serviceWorker?.controller?.postMessage({
          type: 'syncData',
        });
        // send a fallbackcheck to see background sync is complete
        setTimeout(() => {
          this.fallBackCheck();
        }, 2000);
      }
    }, 100);
  }
  fallBackCheck() {
    /// a delayed to check whether the background sync is over or not
    if (this.isInBackgroundSync) {
      navigator?.serviceWorker?.controller?.postMessage({
        type: 'syncDataCheck',
      });
    }
  }
  serviceWorkerOperations() {
    navigator?.serviceWorker?.controller?.postMessage(
      {
        type: 'INIT_PAGES_PORT',
      },
      [this.messageChannel.port2]
    );

    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event?.data?.type === 'PUSH') {
        this.getNotificationCount(true);
      } else if (event?.data?.type === 'queuedActionCheck') {
        if (
          event?.data?.data &&
          !this.isInBackgroundSync &&
          navigator?.onLine
        ) {
          this.syncQueuedData();
        }
      } else if (event?.data?.type === 'syncDataCheck') {
        if (this.isInBackgroundSync) {
          if (event?.data?.syncInProgress) {
            setTimeout(() => {
              this.fallBackCheck();
            }, 5000);
          } else {
            this.isInBackgroundSync = false;
          }
        }
      }
    });

    // Listen to the response
    this.messageChannel.port1.onmessage = (event) => {
      if (event?.data?.type === 'syncComplete') {
        if (this.isInBackgroundSync) {
          this.isInBackgroundSync = false;
        }
      }

      if (event?.data?.message) {
        this.ngZone.run(() => {
          if (event?.data?.status === 'success') {
            this.pageService.setMessage({
              successMessage: event?.data?.message,
              errorMessage: '',
            });
          } else {
            this.pageService.setMessage({
              errorMessage: event?.data?.message,
              successMessage: '',
            });
          }
        });
      }
    };
    if (localStorage.getItem('syncData') === 'true' && navigator?.onLine) {
      localStorage.removeItem('syncData');
      this.syncQueuedData();
    }
  }
  modifyAppStatus(value: string) {
    const statusMsg = this.isInBackgroundSync
      ? 'Status: Synchronizing Data'
      : this.isAppOnline
      ? 'Status: Online'
      : 'Status: Offline';
    switch (value) {
      case 'locationDisabled':
        this.permissionErrorStatus = {
          icon: 'alert-triangle',
          status: 'danger',
          message: `${statusMsg}\nError: Location access not given`,
        };
        break;
      case 'cameraDisabled':
        this.permissionErrorStatus = {
          icon: 'alert-triangle',
          status: 'danger',
          message: `${statusMsg}\nError: Camera access not given`,
        };
        break;
      case 'notificationDisabled':
        this.permissionErrorStatus = {
          icon: 'alert-triangle',
          status: 'danger',
          message: `${statusMsg}\nError: Notification access not given`,
        };
    }
    if (window.innerWidth < 770) {
      this.tooltip?.show();

      setTimeout(() => {
        this.tooltip?.hide();
      }, 3000);
    }
  }

  async ngOnInit() {
    if (this.isSuperUser !== true && this.userData?.access) {
      await this.getSideBar();
    }

    this.getNotificationCount();
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isIOS) {
      document.addEventListener('gesturestart', function (e) {
        e.preventDefault();
      });

      document.addEventListener('gesturechange', function (e) {
        e.preventDefault();
      });
      document.addEventListener('gestureend', function (e) {
        e.preventDefault();
      });
    } else {
      document.addEventListener(
        'touchstart',
        function (e) {
          document.body.style.touchAction = 'manipulation';
        },
        { passive: true }
      );
    }

    this.pageService.isOnEvent.subscribe((isOnEvent: any) => {
      this.ngZone.run(() => {
        this.isOnEvent = isOnEvent;
        this.checkOnlineOffline();
      });
    });
    this.pageService.sWOperations.subscribe((operation: any) => {
      if (operation?.syncData) {
        this.syncQueuedData();
      }
    });
    this.pageService.sosAlertActive.subscribe((value: any) => {
      if (value === 'turnOff') {
        this.stopAlert();
      }
    });
    this.appService.permissionErrorAlert.subscribe((value: any) => {
      this.modifyAppStatus(value);
    });
    this.pageService.routeChangeSubject.subscribe((data: any) => {
      this.notificationCount = data?.count;
      this.showWelfareCheckButton = !this.isAdmin
        ? this.isClockedIn
          ? data?.pending_welfare_check
          : false
        : data?.pending_welfare_check;
      this.appVersionCheck(data?.last_item);
      this.updateQRCheckPointList(data?.qr_checkpoint_checksum);

      this.getSOSAlerts(data?.sos_alerts || 0);

      this.updateClientList(data?.client_checksum);
    });
    navigator?.serviceWorker?.controller?.postMessage({
      type: 'queuedActionCheck',
    });

    window.addEventListener('online', this.checkOnlineOffline.bind(this));
    window.addEventListener('offline', this.checkOnlineOffline.bind(this));

    this.cdr.detectChanges();
    this.serviceWorkerOperations();

    if (this.isSuperUser == true) {
      this.userMenu = superUserMenu;
      this.items = SUPER_USER_MENU_ITEMS;
    }

    if (window.innerWidth >= 1200) {
      this.expandIcon = true;
    }

    let userData = this.appService.getUserData();
    this.currentTheme = userData?.preferences?.theme || 'default';

    this.updateLoggedInUserDetail();

    if (!this.offlineMode) {
      this.userAppChecks();
      if (localStorage.getItem('loginSuccess') === 'true') {
        setTimeout(() => {
          this.afterLoginActions();
        }, 1000);
      }
    }
    this.removeStaleIndexedDbItems();
  }
  updateLoggedInUserDetail() {
    let userData = this.appService.getUserData();
    this.loggedInUser = {
      profile: userData?.profile?.profile_image_url,
      name: `${this.userData?.profile?.first_name} ${this.userData?.profile?.last_name}`,
    };
  }
  async appVersionCheck(lastItem: any) {
    const versionData: any = await this.appService.appVersionCheck({
      last_item: lastItem,
    });
    const latestVersion = versionData?.latestVersion;
    const currentVersion = versionData?.currentVersion;

    if (
      currentVersion?.version &&
      latestVersion?.last_item?.version !== currentVersion?.version
    ) {
      this.appService.updateAppVersionIndexedDb(
        latestVersion?.last_item?.version
      );
      this.appService.updateApp();
    }
    if (!currentVersion) {
      this.appService.updateAppVersionIndexedDb(
        latestVersion?.last_item?.version
      );
    }
  }

  getNotificationCount(showMessage = false) {
    this.profileService.getNotificationCount().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.notificationCount = response?.count;

        if (!this.isSuperUser) {
          this.updateQRCheckPointList(response?.qr_checkpoint_checksum);
          this.getSOSAlerts(response?.sos_alerts || 0);
          this.updateClientList(response?.client_checksum);
        }

        if (showMessage) {
          if (this.notificationCount)
            this.pageService.setMessage({
              errorMessage: '',
              successMessage: `You have a new notification`,
            });
        } else {
          this.appVersionCheck(response?.last_item);
        }
      } else {
        this.errorMessage = response['message'];
      }
    });
  }

  logOut() {
    if (this.isSuperUser === true) {
      this.appService.logOut();
    } else {
      this.spinnerService.show();
      let userData = this.appService.getUserData();

      this.profileService
        .logOut({ theme: userData?.preferences?.theme })
        .subscribe((response: any) => {
          if (response?.status === 'success') {
            this.appService.logOut();
          } else {
            this.errorMessage = response['message'];
          }
        });
    }
  }
  toggleSidebar(event: any): boolean {
    event.preventDefault();
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.expandIcon = !this.expandIcon;
    document.body.classList.toggle('sidebar-open');
    return false;
  }
  navigateHome() {
    // this.menuService.navigateHome();
    this.router.navigate(['/dashboard']);
    document.body.classList.remove('sidebar-open');
    return false;
  }
  scrollToTop() {
    this.scrollService.scrollTo(0, 0);
    this.windowScrolled = false;
  }
  refreshPage() {
    if (navigator?.onLine) {
      window.location.reload();
    }
  }
  async openNotifcationTemplate(notificationPopover: TemplateRef<any>) {
    if (!(await this.permissionsService.checkNotificationPermission())) {
      this.modifyAppStatus('notificationDisabled');
    }
    this.rows = 10;
    this.previous = 0;
    this.pageNum = 1;
    if (this.notificationCount > 0) {
      this.getNotifications();
      this.dialogref = this.dialog.open(notificationPopover);
    } else if (
      this.notificationCount == 0 &&
      this.sosAlertCount &&
      (this.isAdmin || this.isDispatchUser)
    ) {
      this.showSOSAlerts();
    }
  }

  getNotifications() {
    let params: any = {};
    if (this.isSuperUser != true) {
      if (this.rows) {
        params['rows'] = this.rows;
      }
      if (this.previous) {
        params['previous'] = this.previous;
      }
      this.spinnerService.show();
      this.profileService
        .getNotifications(params)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.notificationData = response['data'];
            this.totalRows = response?.total_count;
            this.totalPages = Math.ceil(this.totalRows / this.rows);

            this.notificationCount - this.totalRows;
          } else {
            this.errorMessage = response['message'];
          }
          this.spinnerService.hide();
        });
    }
  }
  onChangeNotificationPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getNotifications();
  }
  redirectNotification(notification: any) {
    if (
      this.isAdmin ||
      ((this.isGuardUser || this.isDispatchUser) && this.isClockedIn)
    ) {
      if (notification?.event_id) {
        if (['job'].includes(notification?.event_type)) {
          this.spinnerService.show();
          this.jobService
            .getJobById(notification.event_id)
            .subscribe((response: any) => {
              if (response['status'] == 'success') {
                let job = response['data'];
                this.router.navigateByUrl(`/job-detail/${job.job_key}`, {
                  state: job.id,
                });
                window.localStorage.setItem('urlId', job.id);
                this.spinnerService.hide();
              } else {
                this.spinnerService.hide();
              }
            });
        } else if (notification?.event_type === 'user_license') {
          this.router.navigate(['/profile']);
        } else if (notification?.event_type === 'sos_alert') {
          this.router.navigate(['/sos-alerts']);
        } else if (notification?.event_type === 'company_license') {
          this.router.navigate(['/company-profile']);
        } else if (notification?.event_type === 'patrol_job') {
          this.router.navigate([
            '/view-route',
            {
              rKey: String(notification?.event_id),
            },
          ]);
        }
      } else {
        if (notification?.event_type === 'version_release') {
          this.openChangeLog();
        } else if (notification?.event_type === 'feedback') {
          this.router.navigate(['/support']);
        } else if (notification?.event_type === 'sos_alert') {
          this.showSOSAlerts();
        } else if (
          notification?.event_type === 'welfare_check' &&
          (this.isAdmin || this.isDispatchUser)
        ) {
          this.router.navigate(['/missed-events']);
        }
      }
      this.deleteNotification(notification);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  clearNotifications() {
    // this.toasterService.show('', 'Not all notifications could be cleared');
    this.spinnerService.show();
    this.profileService
      .deleteNotifications({ clear_all: true }, {})
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.spinnerService.hide();
          this.notificationData = [];
          this.popover?.hide();
          this.getNotificationCount();
          // this.getNotifications();
        } else {
          this.spinnerService.hide();
          this.errorMessage = response['message'];
        }
      });
  }
  deleteNotification(notification: any) {
    this.profileService
      .deleteNotifications({}, { id_list: [notification.id] })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.spinnerService.hide();
          this.notificationData = this.notificationData.filter(
            (item: any) => !(item.id === notification.id)
          );
          if (!this.notificationData?.length) {
            this.dialogref?.close();
          }
          this.notificationCount = this.notificationData?.length;
        } else {
          this.spinnerService.hide();
          this.errorMessage = response['message'];
        }
      });
  }

  allocateTimeUnits(timeDifference: any) {
    let milliSecondsInASecond = 1000;
    let hoursInADay = 24;
    let minutesInAnHour = 60;
    let SecondsInAMinute = 60;
    let secondsToDday = Math.floor(
      (timeDifference / milliSecondsInASecond) % SecondsInAMinute
    );
    let minutesToDday = Math.floor(
      (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
        SecondsInAMinute
    );
    let hoursToDday = Math.floor(
      (timeDifference /
        (milliSecondsInASecond * minutesInAnHour * SecondsInAMinute)) %
        hoursInADay
    );
    let daysToDday = Math.floor(
      timeDifference /
        (milliSecondsInASecond *
          minutesInAnHour *
          SecondsInAMinute *
          hoursInADay)
    );

    return `${daysToDday} D ${hoursToDday} HR ${minutesToDday} Min and ${secondsToDday} Sec`;
  }
  getTimeDifference() {
    this.showTrialExpireCountDown = true;
    this.expiresTimeDifference =
      new Date(this.userData?.checks?.trial_end).getTime() -
      new Date().getTime();
    if (this.expiresTimeDifference <= 0) {
      this.trialStatus = ``;
    } else {
      this.trialStatus =
        `Trial ends in ` + this.allocateTimeUnits(this.expiresTimeDifference);
    }
  }

  capture(feedBackType: any, requestRef: TemplateRef<any>) {
    this.spinnerService.show();

    this.feedbackForm.controls['feedback'].setValue(null);

    if (feedBackType == 'draggableBtn') {
      this.showHideDraggableFeedbackBtn(true);
      if (this.dragging) {
        this.dragging = false;
      }
    }
    const device = this.deviceService.getDeviceInfo();

    if (device?.device !== 'iPhone') {
      this.captureScreenShot();
    }
    setTimeout(() => {
      this.spinnerService.hide();
      this.dialogref = this.dialog.open(requestRef, {
        context: {},
      });
    }, 100);
    this.appService
      .getCurrentVersionInfo()
      .then((data: any) => sessionStorage.setItem('appVersion', data?.version));

    // this.spinnerService.hide();
    this.dialogref?.onClose.subscribe((value: any) => {
      if (value === 'close') {
        this.feedback = '';
      }
    });
  }

  async captureScreenShot() {
    try {
      let section = document.querySelector('#mainContainer');
      const canvas = await html2canvas(section, {
        logging: false,
        useCORS: true,
      }).then((canvas: any) => {
        const arr = canvas.toDataURL().split(',');
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        this.spinnerService.hide();
        this.feedbackScreenShot = new File(
          [u8arr],
          `Upload_${(this.fileData.length = 1)}`,
          {
            type: 'image/jpeg',
          }
        );
      });
    } catch (error) {
      console.error('Error capturing screenshot:', error);
    }
  }

  addFeedback() {
    const feedbackValue: any = this.feedbackForm.get('feedback')?.value;
    this.spinnerService.show();
    let fileData: FormData = new FormData();
    fileData.append('file', this.feedbackScreenShot);
    fileData.append('feedback', feedbackValue);

    fileData.append(
      'app_version',
      String(sessionStorage.getItem('appVersion') || '')
    );
    this.appService
      .formDataApi(`feedback/submit_feedback`, fileData)
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.pageService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
          this.feedback = '';
          this.dialogref.close();
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }

  handleDragStart(event: CdkDragStart): void {
    this.dragging = true;
  }
  onDragEnded(event: CdkDragEnd): void {}
  showHideDraggableFeedbackBtn(value: any) {
    if (value === true) {
      let element: any = document.getElementById('globalFeedbackbtn');
      element.style.visibility = 'hidden';
    } else {
      let element: any = document.getElementById('globalFeedbackbtn');
      element.style.visibility = 'visible';
    }
  }
  clearAlert() {
    this.successMessage = null;
    this.errorMessage = null;
  }
  enableScrollOnBody() {
    document.body.classList.remove('sidebar-open');
  }

  clockedInOut() {
    this.spinnerService.show();
    let params = {};
    if (this.isClockedIn === true) {
      this.spinnerService.hide();
      params = {
        clock_out: 1,
      };
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        context: {
          title: 'Clock Out',
          message: 'Confirm you want to clock off.',
        },
      });
      dialogRef.onClose.subscribe((value) => {
        if (value === 'Yes') {
          this.spinnerService.show();
          this.profileService
            .guardClockedInOut(params)
            .subscribe((response: any) => {
              if (response?.status == 'success') {
                this.spinnerService.hide();
                this.pageService.setMessage({
                  successMessage: response?.message,
                  errorMessage: '',
                });
                let userData = this.appService.getUserData();
                userData.user_token = response?.user_token;
                this.appService.setUserData(userData);
              } else {
                this.pageService.setMessage({
                  successMessage: '',
                  errorMessage: response?.message,
                });
                this.spinnerService.hide();
              }
              setTimeout(() => {
                window.location.reload();
              });
            });
        }
      });
    } else {
      this.profileService
        .guardClockedInOut(params)
        .subscribe((response: any) => {
          if (response?.status == 'success') {
            this.spinnerService.hide();
            this.pageService.setMessage({
              successMessage: response?.message,
              errorMessage: '',
            });
            let userData = this.appService.getUserData();
            userData.user_token = response?.user_token;
            this.appService.setUserData(userData);
          } else {
            this.pageService.setMessage({
              successMessage: '',
              errorMessage: response?.message,
            });
            this.spinnerService.hide();
          }
          setTimeout(() => {
            window.location.reload();
          });
        });
    }
  }
  welfareCheckIn() {
    this.spinnerService.show();
    this.profileService
      .updateUser({}, { check_in: 1 })
      .subscribe((response: any) => {
        if (response?.status == 'success') {
          this.spinnerService.hide();
          this.pageService.setMessage({
            successMessage: response?.message,
            errorMessage: '',
          });
          this.showWelfareCheckButton = false;
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response?.message,
          });
          this.spinnerService.hide();
        }
      });
  }

  lockScreen() {
    this.orientationLockService.lockOrientation();
  }
  async userAppChecks() {
    //check whther user has set password, first name last name etc
    if (!this.isSuperUser) {
      if (
        this.dataCheckService.hasPasswordSet() === false ||
        window.sessionStorage.getItem('resetPasswordPWA')
      ) {
        const dialogRefUser = this.dialogService.open(AppChecksComponent, {
          context: {},
        });
        dialogRefUser.onClose.subscribe(async (value: any) => {
          this.spinnerService.hide();
          window.location.reload();
          await this.getSideBar();
        });
      }
    }
  }
  registerQRCode() {
    this.dialogref = this.dialogService.open(QrCodeComponent, {
      context: {},
    });
    this.dialogref.onClose.subscribe((value: any) => {});
  }
  checkPrevouslySubscribed() {
    return (
      !this.isAdmin ||
      this.dataCheckService.checkIsPreviouslySubscribedAndSubscriptionInActive()
    );
  }

  afterLoginActions() {
    this.callNearestApis();
    this.requestSubscription();
    localStorage.removeItem('loginSuccess');
  }
  // call the job/cp/cleint apis to build the indexed db cache

  callNearestApis() {
    try {
      this.jobService
        .getNearestCheckpointList()
        .subscribe((response: any) => {});
      this.jobService.getAllQRCheckpointList().subscribe((response: any) => {});
      this.jobService.getAllClientList().subscribe((response: any) => {});
      this.jobService.getNearesSiteList().subscribe((response: any) => {});
      this.jobService
        .getNearestJobs({
          time_offset: new Date().getTimezoneOffset(),
        })
        .subscribe((response: any) => {});
    } catch (error) {}
  }
  async checkExisitingSubscription() {
    return new Promise((resolve) => {
      this.swPush.subscription.subscribe((res: any) => {
        resolve(res);
      });
    });
  }
  async unsubscribeFromPush() {
    try {
      await this.swPush.unsubscribe();
      // Successfully unsubscribed
    } catch (err) {}
  }
  requestSubscription = async () => {
    if (!this.swPush.isEnabled) {
      this.pageService.setMessage({
        successMessage: '',
        errorMessage: 'Warning: You have not enabled push notifications',
      });

      return;
    }

    this.swPush
      .requestSubscription({
        serverPublicKey: environment.vapIDPKey,
      })
      .then((_) => {
        this.profileService
          .registerPushNotification({ subscription: JSON.stringify(_) })
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      })
      .catch(async (_) => {
        let existingSub = await this.checkExisitingSubscription();

        if (existingSub) {
          await this.unsubscribeFromPush();
          await this.requestSubscription();
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: _,
          });
        }
      });
  };
  async removeStaleIndexedDbItems() {
    try {
      const filterDate = new Date();
      filterDate.setDate(filterDate.getDate() - 3);

      if (this.swPush.isEnabled) {
        const result = await gTDB.gtSavedData.toArray();
        const staleRecords = result
          ?.filter(
            (item: any) =>
              new Date(item?.sync_time).getTime() < filterDate.getTime()
          )
          ?.map((item: any) => item?.indexDBId);

        if (staleRecords?.length) {
          this.deleteCachedData(staleRecords);
        }
      }
    } catch (error) {}
  }

  deleteCachedData(keys: any) {
    try {
      gTDB.gtSavedData.bulkDelete(keys);
    } catch (error) {
      console.log(error);
    }
  }
  updateQRCheckPointList(qrCpCheckSum: any) {
    if (qrCpCheckSum?.qr_checkpoint_count === 0) {
      try {
        if (this.swPush.isEnabled) {
          gTDB.cachedData
            .where({
              shortPath: '/api/check_point/list_qr_checkpoints/',
            })
            .delete();
        }
      } catch (error) {}
    } else {
      try {
        if (this.swPush.isEnabled) {
          gTDB.cachedData
            .where({ shortPath: '/api/check_point/list_qr_checkpoints/' })
            .toArray()
            .then((response: any) => {
              const checkpointData: any = response?.[0]?.data || [];
              const cachedQrCheckpointCount: number = checkpointData?.length;
              const lastUpdatedDate: any = checkpointData?.[0]?.last_updated_at
                ? new Date(checkpointData?.[0]?.last_updated_at)
                : null;
              if (
                qrCpCheckSum?.last_updated_at &&
                lastUpdatedDate &&
                lastUpdatedDate?.getTime() <
                  new Date(qrCpCheckSum?.last_updated_at).getTime()
              ) {
                this.jobService
                  .getAllQRCheckpointList()
                  .subscribe((response: any) => {});
              }

              if (
                cachedQrCheckpointCount !== qrCpCheckSum?.qr_checkpoint_count
              ) {
                this.jobService
                  .getAllQRCheckpointList()
                  .subscribe((response: any) => {});
              }
            });
        }
      } catch (error) {
        console.log(error, 'ERROR');
      }
    }
  }
  updateClientList(clientCheckSum: any) {
    if (clientCheckSum?.company_count === 0) {
      if (this.swPush.isEnabled) {
        gTDB.cachedData
          .where({
            shortPath: '/api/company/list_all_companies/',
          })
          .delete();
      }
    } else {
      try {
        if (this.swPush.isEnabled) {
          gTDB.cachedData
            .where({ shortPath: '/api/company/list_all_companies/' })
            .toArray()
            .then((response: any) => {
              const clientData: any = response?.[0]?.data || [];
              const cachedClientCount: number = clientData?.length;
              const lastUpdatedDate: any = clientData?.[0]?.last_updated_at
                ? new Date(clientData?.[0]?.last_updated_at)
                : null;
              if (
                clientCheckSum?.last_updated_at &&
                lastUpdatedDate &&
                lastUpdatedDate?.getTime() <
                  new Date(clientCheckSum?.last_updated_at).getTime()
              ) {
                this.jobService
                  .getAllClientList()
                  .subscribe((response: any) => {});
              }

              if (cachedClientCount !== clientCheckSum?.company_count) {
                this.jobService
                  .getAllClientList()
                  .subscribe((response: any) => {});
              }
            });
        }
      } catch (error) {
        console.log(error, 'ERROR');
      }
    }
  }
  showSOSAlerts() {
    this.router.navigate([
      '/sos-alerts',
      { alertCounts: String(this.sosAlertCount) },
    ]);
  }
  getSOSAlerts(alertCount: number = 0) {
    if (alertCount == 0) {
      this.isAlertActive = false;

      this.sosAlertCount = 0;
      this.beepIntervalSubscription?.unsubscribe();
      this.pageService.sosAlertActive.next('alertMuted');
    } else if (
      alertCount != this.sosAlertCount &&
      (this.isAdmin || this.isDispatchUser)
    ) {
      this.pageService.sosAlertActive.next('alertActive');
      this.isAlertActive = true;
      this.alertUserWithSoundFeedback();
      this.sosAlertCount = alertCount;
      this.router.navigate([
        '/sos-alerts',
        { alertCounts: String(alertCount) },
      ]);
    }
  }

  stopAlert() {
    this.pageService.sosAlertActive.next('alertMuted');
    this.isAlertActive = false;
    this.beepIntervalSubscription?.unsubscribe();
  }
  alertUserWithSoundFeedback() {
    this.beepIntervalSubscription = interval(1000)
      .pipe(takeWhile(() => this.isAlertActive))
      .subscribe((x) => {
        try {
          window.navigator.vibrate(200);
        } catch (error) {
          console.log(error, 'ERROR');
        }
        this.pageService.playChord(200, [1000], 100);
      });
  }
}
