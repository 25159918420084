import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-upload-type',
  templateUrl: './upload-type.component.html',
  styleUrls: ['./upload-type.component.scss'],
})
export class UploadTypeComponent implements OnInit {
  uploadType: any;
  hasCameraOnDevice: boolean = true;
  constructor(
    protected dialogRef: NbDialogRef<ConfirmDialogComponent>,
    private toastrService: NbToastrService
  ) {}

  async ngOnInit(): Promise<void> {
    this.hasCameraOnDevice = await this.hasCamera();

    if (!this.hasCameraOnDevice) {
      this.uploadType = 'device';
      this.onDismis('device');
    }
  }
  hasCamera(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        resolve(false);
        return;
      }

      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const videoInputDevices = devices.filter(
            (device) => device.kind === 'videoinput'
          );
          resolve(videoInputDevices.length > 0);
        })
        .catch((err) => {
          console.error('Error accessing media devices.', err);
          resolve(false);
        });
    });
  }
  onDismis(close?: any) {
    // Close the dialog, return false
    this.dialogRef.close(close);
  }
}
