import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbGlobalLogicalPosition, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { MustMatch } from 'src/app/shared/must-match-validator';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
@Component({
  selector: 'app-external-app',
  templateUrl: './external-app.component.html',
  styleUrls: ['./external-app.component.scss'],
})
export class ExternalAppComponent implements OnInit {
  logicalPositions = NbGlobalLogicalPosition;
  showErrorMsg: any;
  userEmailForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });
  refreshToken: any;
  userLinkedSubscribers: any = [];
  selectSubscriberToken: any;
  showDetail: any;
  showExpiryEmail: boolean = false;
  userData: any;
  showPassword: boolean = false;
  // password change section
  passwordForm: UntypedFormGroup = new UntypedFormGroup(
    {
      password: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
      confirm_password: new UntypedFormControl(null, [
        MustMatch('password', 'confirm_password'),
      ]),

      v_code: new UntypedFormControl(null),
    },
    {
      validators: MustMatch('password', 'confirm_password'),
    }
  );

  //email change section
  emailForm: UntypedFormGroup = new UntypedFormGroup({
    old_email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]),
  });
  rememberLogin: number | undefined;
  isSuperUser: any;
  changeEmailPressed: boolean = false;
  downloadFileUrlS3Dict: any = {};
  constructor(
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private toasterService: NbToastrService,
    private appService: AppService,
    private router: Router,
    private profileService: ProfileService,
    private authService: AuthService,
    private dataCheckService: DataCheckService
  ) {}
  getMultipleSubscriber(response: any) {
    this.userLinkedSubscribers = response['data'];
    this.selectSubscriberToken = response['token'];
    this.rememberLogin = response['remember_login'] == true ? 1 : 0;
    this.userData = this.appService.getUserData();
  }
  appLogIn(response: any) {
    this.appService.setUserData(response);
    this.refreshToken = response['token'];
    if (this.userData?.access) {
      this.authService.checkDevice().subscribe((response) => {
        this.spinnerService.hide();
      });
    }

    if (response?.link_from === 'user_invitation') {
      this.redirectDashBoard();
    }

    if (response?.link_from === 'registration') {
      this.redirectDashBoard();
    }
    if (!response?.link_from) this.redirectDashBoard();
    else {
      this.showDetail = response?.link_from;
    }
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.isSuperUser = this.dataCheckService.isSuperUser();
    var key = this.route.snapshot.paramMap.get('key');
    if (key) {
      this.appService
        .gtExternalLink('register_login/check_gt_link', {}, key, false)
        .then((response: any) => {
          if (response['status'] === 'success') {
            if (response?.link_from === 'stop_mail_alerts') {
              this.showErrorMsg = response['message'];
              localStorage.clear();
              setTimeout(() => {
                this.router.navigate([`/login`]);
              }, 4000);
            }

            this.userData = response;
            if (response?.data) {
              this.getMultipleSubscriber(response);
            } else {
              if (response?.s3_url) {
                this.downloadFileUrlS3Dict = {
                  file_url: response?.s3_url,
                  file_name: response?.file_name,
                };
              } else if (response?.link_from === 'unblock_account') {
                localStorage.setItem('unblock_account', response?.message);
                this.router.navigate([`/login`]);
              } else {
                this.appLogIn(response);
              }
            }

            this.spinnerService.hide();
          } else {
            if (
              ['registration', 'user_invitation', 'change_password'].includes(
                response['link_from']
              )
            ) {
              this.showExpiryEmail = true;
            } else {
              localStorage.clear();
              setTimeout(() => {
                this.router.navigate([`/login`]);
              }, 4000);
            }

            this.spinnerService.hide();
            this.showErrorMsg = response['message'];
          }
          this.spinnerService.hide();
        });
    }
  }
  downloadS3File() {
    this.spinnerService.show();
    const a = document.createElement('a');
    a.href = this.downloadFileUrlS3Dict?.file_url;
    a.download = this.downloadFileUrlS3Dict?.file_name;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.spinnerService.hide();
  }
  refreshExpiredLink() {
    let key: any = this.route.snapshot.params;
    this.refreshToken = key.key;
    this.showExpiryEmail = false;
    this.profileService
      .refreshAndSendRegistrationLink(
        this.userEmailForm.value,
        this.refreshToken
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.showErrorMsg = undefined;
          this.toasterService.show('', response['message']);
        } else {
          this.showErrorMsg = response['message'];
        }
      });
  }
  selectSubscriber(subscriber: any) {
    this.spinnerService.show();
    this.authService
      .selectSubscriber(
        this.selectSubscriberToken,
        subscriber.id,
        this.rememberLogin
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          // this.spinnerService.hide();

          this.appService.setUserData(response);
          this.redirectDashBoard();
        } else {
          this.spinnerService.hide();
        }
      });
  }
  redirectDashBoard() {
    if (this.isSuperUser === true) {
      this.router.navigate(['gtadmin/dashboard']);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  changePassword(val?: any) {
    if (this.passwordForm.valid) {
      this.profileService
        .changePassword(this.passwordForm.value)
        .subscribe((response) => {
          if (response['status'] == 'success') {
            this.redirectDashBoard();
            this.toasterService.show('', response['message']);
          } else {
            this.showErrorMsg = response['message'];
          }
        });
    } else {
      this.showErrorMsg = 'Entered Password Error';
    }
  }
  changeEmail() {
    this.spinnerService.show();
    let key: any = this.route.snapshot.params;
    this.refreshToken = key.key;
    let duration = 10000;
    if (this.emailForm.valid) {
      this.changeEmailPressed = true;
      this.profileService
        .updateEmail(this.emailForm.value, { b64_string: this.refreshToken })
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.toasterService.show('', response['message'], { duration });
            setTimeout(() => {
              this.redirectDashBoard();
            }, 1000);
          } else {
            this.changeEmailPressed = false;
            this.toasterService.danger('', response['message'], { duration });
            this.showErrorMsg = response['message'];
          }
          this.spinnerService.hide();
        });
    } else {
      this.showErrorMsg = 'Entered Email Error';
    }
  }
  //Method to change the password input type
  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  onCloseDialogue() {
    this.redirectDashBoard();
  }
}
