import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { PagesService } from '../../pages.service';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-beta-user',
  templateUrl: './beta-user.component.html',
  styleUrls: ['./beta-user.component.scss'],
})
export class BetaUserComponent implements OnInit {
  @ViewChild('singlebetaUserTemplate') singlebetaUserTemplate: any =
    TemplateRef<any>;
  @ViewChild('revokeReInviteTemplate') revokeReInviteTemplate: any =
    TemplateRef<any>;
  betaSubscriberTableData: any;
  tableColumns: any;

  showTypeAhead: boolean = true;
  showSearchBox: boolean = false;
  globalFilter: any;

  previous: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;

  betaSubscriberForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', Validators.required),
    test_user_validity: new UntypedFormControl(''),
  });

  // beta history section

  betaHistory = {
    columns: [
      { header: 'Subscriber', name: 'subscriber_name' },
      { header: 'Email', name: 'invited_email' },
      { header: 'Action', name: 'event_action' },
      {
        header: 'Updated At',
        name: 'updated_at',
        datetimeObj: true,
      },
      {
        header: 'Updated By',
        nestedValue: (row: any) => {
          return row?.user?.full_name;
        },
      },
    ],
  };
  reportData: any;

  previousHistory: number = 0;
  rowsHistory: number = 10;
  pageNumHistory: number = 1;
  totalPagesHistory: number = 0;
  totalRowsHistory: number = 0;
  selectedHistory: any;

  //bulk update variables
  expiryTime: any;
  changeReason: any;
  betaSubscribers: any;
  boolSelectSubscribers: boolean = false;
  selectedSubscribers: any = [];
  betaUserData: any;
  singleExpiryTime: any;
  singlechangeReason: any;
  bulkExpire: boolean = false;
  betaStatus: any;
  uploadedFile: any;
  bulkImportExpiry: any;
  forcedReInvite: boolean = false;
  bulkImportReason: any;
  updateBetaStaus: any;
  expireRevokeReason: any;
  reInviteTimeValidity: any;
  expireReInviteBetaUser: any;

  constructor(
    private spinnerService: NgxSpinnerService,
    private adminService: AdminService,
    private appService: AppService,
    private _location: Location,
    private dialogService: NbDialogService,
    private router: Router,
    private pageService: PagesService
  ) {
    this.getAllBetaUsers();
    this.getBetaUserHistory();

    this.tableColumns = {
      columns: [
        {
          header: 'Beta User',
          name: 'test_user',
          sortKey: 'test_user',
        },
        {
          header: 'Subscriber',
          name: 'subscriber_name',
        },
        {
          header: 'Invited At',
          name: 'created_at',
          datetimeObj: true,
        },
        {
          header: 'Invited By',
          nestedValue: (row: any) => {
            return row?.user?.full_name;
          },
        },

        {
          header: 'Validtiy',
          name: 'test_user_validity',
          datetimeObj: true,
        },
        {
          header: 'Invited Validity',
          name: 'validity',
          datetimeObj: true,
        },
        { header: 'Mail', name: 'email' },
      ],
    };
  }

  ngOnInit(): void {}
  getAllBetaUsers() {
    this.spinnerService.show();
    let params: any = {};
    if (this.globalFilter) {
      params.subscriber_name = this.globalFilter;
    }
    if (this.betaStatus) {
      params[
        this.betaStatus == 1 ? 'invited_beta_users' : 'invited_non_beta_users'
      ] = 1;
    }

    if (this.rows) {
      params['page_size'] = this.rows;
    }
    if (this.previous) {
      params['page'] = this.pageNum;
    }

    this.spinnerService.show();
    this.adminService.getBetaUserList(params).subscribe((response: any) => {
      this.betaSubscriberTableData = response;
      this.totalRows = response?.count;
      this.totalPages = Math.ceil(this.totalRows / this.rows);
      this.spinnerService.hide();
    });
  }

  onRowClick(data: any) {
    if (data?.subscriber_id) {
      this.betaUserData = data;
      this.openTemplate(this.singlebetaUserTemplate);
    } else {
      this.expireReInviteBetaUser = data;

      this.openTemplate(this.revokeReInviteTemplate);
    }
  }

  goBack() {
    this._location.back();
  }
  onFilterApply(event: any) {
    this.showSearchBox = true;
    this.pageNum = 1;
    if (event?.target?.value?.length === 0) {
      this.showTypeAhead = true;
      this.globalFilter = '';
      this.getAllBetaUsers();
    }
    if (event?.target?.value && event?.target?.value?.length >= 3) {
      this.getAllBetaUsers();

      this.showTypeAhead = false;
    }
  }

  onClearFilter(filterType?: string) {
    this.showSearchBox = false;
    this.globalFilter = '';
    this.showTypeAhead = true;
    this.getAllBetaUsers();
  }

  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getAllBetaUsers();
  }

  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getAllBetaUsers();
  }
  createBetaSUbscriber() {
    this.spinnerService.show();

    this.adminService
      .createBetaSubscriber(this.betaSubscriberForm.value)
      .subscribe((response: any) => {
        if (response['status'] === 'success') {
          this.pageService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
          this.getAllBetaUsers();
        } else {
          this.pageService.setMessage({
            errorMessage: response['message'],
            successMessage: '',
          });
        }
        this.spinnerService.hide();
        this.getAllBetaUsers();
      });
  }
  onClearForm() {
    this.betaSubscriberForm.reset();
  }
  openTemplate(template: TemplateRef<any>) {
    const dialogref = this.dialogService.open(template, {
      context: {},
    });
  }

  onClickPaginationHistory(event: any) {
    this.previousHistory = event.previous;
    this.pageNumHistory = event.pageNum;
    this.rowsHistory = event.pageSize;
    this.getBetaUserHistory();
  }

  onChangePaginationHistory(event: any) {
    this.previousHistory = event.previous;
    this.pageNumHistory = event.pageNum;
    this.rowsHistory = event.pageSize;
    this.getBetaUserHistory();
  }
  getBetaUserHistory() {
    this.spinnerService.show();
    let params: any = {};

    if (this.rowsHistory) {
      params['page_size'] = this.rowsHistory;
    }
    if (this.previousHistory) {
      params['page'] = this.pageNumHistory;
    }

    this.spinnerService.show();
    this.adminService.getBetaUserHistory(params).subscribe((response: any) => {
      this.reportData = response;
      this.totalRowsHistory = response?.count;
      this.totalPagesHistory = Math.ceil(
        this.totalRowsHistory / this.rowsHistory
      );
      this.spinnerService.hide();
    });
  }
  onHistoryRowClick(data: any) {
    this.selectedHistory = data;
  }
  serachBetaSubscribers(event: any) {
    this.spinnerService.show();
    let params: any = { invited_beta_users: 1 };
    if (event.target.value) {
      params.subscriber_name = event.target.value;
    }

    this.spinnerService.show();
    this.adminService.getBetaUserList(params).subscribe((response: any) => {
      this.betaSubscribers = response;
      this.betaSubscribers.results = response?.results.filter(
        (value1: any) =>
          !this.selectedSubscribers.some(
            (value2: any) => value1?.id === value2?.id
          )
      );

      this.spinnerService.hide();
    });
  }
  getInvitedSubscribers(template: TemplateRef<any>) {
    this.spinnerService.show();
    let params: any = { invited_non_beta_users: 1 };

    this.spinnerService.show();
    this.adminService.getBetaUserList(params).subscribe((response: any) => {
      this.betaSubscribers = response;
      if (this.betaSubscribers.count > 0) {
        this.openTemplate(template);
      } else {
        this.pageService.setMessage({
          errorMessage: 'No invited non beta accounts found',
          successMessage: '',
        });
      }
      this.spinnerService.hide();
    });
  }
  getBetaSubscribers(template: TemplateRef<any>) {
    this.spinnerService.show();
    let params: any = { invited_beta_users: 1 };

    this.spinnerService.show();
    this.adminService.getBetaUserList(params).subscribe((response: any) => {
      this.betaSubscribers = response;
      if (this.betaSubscribers.count > 0) {
        this.openTemplate(template);
      } else {
        this.pageService.setMessage({
          errorMessage: 'No beta accounts found',
          successMessage: '',
        });
      }
      this.spinnerService.hide();
    });
  }
  bulkUpdateBetaUsers(body: any, params: any) {
    this.adminService.bulkChange(body, params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.pageService.setMessage({
          successMessage: res['message'],
          errorMessage: '',
        });
        this.boolSelectSubscribers = false;
        this.selectedSubscribers = [];
        this.expiryTime = null;
        this.changeReason = null;
      } else {
        this.pageService.setMessage({
          errorMessage: res['message'],
          successMessage: '',
        });
      }
      this.getAllBetaUsers();
      this.getBetaUserHistory();
    });
  }
  bulkUpdateBeta() {
    let body: any = { reason: this.changeReason };
    let params: any = {};
    if (this.bulkExpire) {
      if (this.expiryTime) {
        body.end_date = this.expiryTime;
        params.bulk_end_date = 1;
      } else {
        params.bulk_cancel = 1;
      }
    } else {
      params.bulk_activate = 1;
      if (this.expiryTime) {
        body.end_date = this.expiryTime;
      }
    }

    if (this.boolSelectSubscribers) {
      if (!this.selectedSubscribers?.length) {
        this.pageService.setMessage({
          errorMessage: 'Please select beta users',
          successMessage: '',
        });
      } else {
        body.beta_user_ids = [
          ...new Set([...this.selectedSubscribers].map((item) => item.id)),
        ];
        this.bulkUpdateBetaUsers(body, params);
      }
    } else {
      this.bulkUpdateBetaUsers(body, params);
    }
  }
  manualSelect(event: any) {
    this.boolSelectSubscribers = event;
  }
  removeSubscribers(subscriber: any): void {
    this.selectedSubscribers = this.selectedSubscribers.filter(
      (sId: any) => sId?.id !== subscriber?.id
    );
  }

  addSubscribers(id: number, name: string): void {
    if (this.selectedSubscribers.some((user: any) => user?.id === id)) {
      this.pageService.setMessage({
        errorMessage: 'Subscriber Already added',
        successMessage: '',
      });
    } else {
      this.selectedSubscribers.push({
        id: id,
        name: name,
      });
    }
  }
  changeBetaFeat() {
    let body: any = { conversion: 1, reason: this.singlechangeReason };
    if (this.singleExpiryTime) {
      body = {
        end_date: this.singleExpiryTime,
        reason: this.singlechangeReason,
      };
    }

    this.adminService
      .updateBetaUser(this.betaUserData?.subscriber_id, body)
      .subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.singlechangeReason = null;
          this.singleExpiryTime = null;
          this.pageService.setMessage({
            successMessage: res['message'],
            errorMessage: '',
          });
        } else {
          this.pageService.setMessage({
            errorMessage: res['message'],
            successMessage: '',
          });
        }
        this.getAllBetaUsers();
        this.getBetaUserHistory();
      });
  }
  selectBetaType(event: any) {
    this.betaStatus = event;
    this.getAllBetaUsers();
  }
  handleFileInput(event: any) {
    this.uploadedFile = event?.target?.files.item(0);
  }
  onFileSubmit() {
    if (this.uploadedFile) {
      this.spinnerService.show();
      let fileData: FormData = new FormData();
      fileData.append('file', this.uploadedFile);
      if (this.forcedReInvite) fileData.append('re_invite', '1');
      if (this.bulkImportExpiry)
        fileData.append(
          'test_user_validity',
          new Date(this.bulkImportExpiry).toISOString()
        );
      if (this.bulkImportReason) {
        fileData.append('reason', this.bulkImportReason);
      }
      this.appService
        .formDataApi(`beta_user/bulk_import/`, fileData, true)
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.pageService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
          } else {
            this.pageService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
          this.spinnerService.hide();
        });
    }
  }
  reInvitation(event: any) {
    this.forcedReInvite = event;
  }
  expireReInviteUser() {
    if (
      this.expireReInviteBetaUser &&
      this.expireRevokeReason &&
      this.updateBetaStaus
    ) {
      let body: any = { reason: this.expireRevokeReason };
      if (this.reInviteTimeValidity) {
        body.test_user_validity = this.reInviteTimeValidity;
      }
      let params: any = {
        id: this.expireReInviteBetaUser?.id,
      };
      params[this.updateBetaStaus == 'reInvite' ? 're_invite' : 'revoke'] = 1;
      this.adminService.bulkChange(body, params).subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.pageService.setMessage({
            successMessage: res['message'],
            errorMessage: '',
          });
          this.boolSelectSubscribers = false;
          this.selectedSubscribers = [];
          this.expiryTime = null;
          this.changeReason = null;
          this.expireRevokeReason = null;
          this.updateBetaStaus = null;
          this.reInviteTimeValidity = null;
        } else {
          this.pageService.setMessage({
            errorMessage: res['message'],
            successMessage: '',
          });
        }
        this.getAllBetaUsers();
        this.getBetaUserHistory();
      });
    }
  }
}
