<div>
  <!-- MOBILE VIEW -->
  <div class="mobile-only gt-p-small">
    <nb-tabset (changeTab)="changeTab($event)">
      <nb-tab tabTitle="{{ nbTabs.tab1 }}">
        <div *ngIf="!showDetails">
          <nb-form-field>
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                nbInput
                [(ngModel)]="clientName"
                fullWidth
                placeholder="Search/Select client"
                (input)="onClientSearch($event)"
                [nbAutocomplete]="autoNgModel"
                required
              />
              <label>Search/Select client</label>
            </div>
            <nb-icon
              nbSuffix
              *ngIf="selectedClient && clientName"
              [icon]="'close-outline'"
              class="sc-floating-right"
              pack="eva"
              (click)="
                selectedClient = null;
                clientName = null;
                showTable = false;
                userEvents = [];
                totalPages = 0
              "
            ></nb-icon>
          </nb-form-field>

          <nb-autocomplete #autoNgModel>
            <nb-option
              *ngFor="let item of customerDetails"
              [value]="item.company_name"
            >
              <div (click)="onClientSelect(item)">
                <span
                  ><b>{{ item?.company_name }}</b
                  >,</span
                >
                <small> {{ item?.address?.full_address }}</small>
              </div>
            </nb-option>
          </nb-autocomplete>

          <div class="col form-floating sc-mt-6">
            <input
              class="form-control"
              size="giant"
              nbInput
              fullWidth
              placeholder="Pick Date Range"
              readonly
              [value]="dateRangeValue"
              (click)="openRangePicker()"
            />
            <label>Pick Date Range</label>
          </div>
          <nb-rangepicker
            #formpicker
            (rangeChange)="handleDateChange($event)"
          ></nb-rangepicker>

          <div class="col sc-mt-6" *ngIf="userEvents?.length && allowPDFExport">
            <button
              nbButton
              class="nbbutton-long-text"
              fullWidth
              status="primary"
              size="giant"
              (click)="openTemplate(emailUserDialog)"
            >
              Email Report (.pdf)
            </button>
          </div>
        </div>
        <div *ngIf="!showDetails" class="sc-mt-6">
          <!---list of cards-->
          <app-card-list
            *ngIf="userEvents?.length"
            [listCardData]="userEvents"
            [listCardDetails]="userEventDetails"
            (rowClick)="onTableRowClick($event)"
          ></app-card-list>
          <div *ngIf="totalPages > 0">
            <app-pagination-control
              [pageSize]="pageSize"
              [pageNum]="pageNum"
              [previous]="previous"
              [totalRows]="totalRows"
              [totalPages]="totalPages"
              (onClickPagination)="onClickPagination($event)"
              (onChangePagination)="onChangePagination($event)"
            >
            </app-pagination-control>
          </div>
          <div *ngIf="userEvents?.length == 0" class="my-3">
            <nb-alert outline="basic" style="font-weight: 500"
              >No Records Match Your Search Criteria</nb-alert
            >
          </div>

          <!-- <div *ngIf="userEvents?.length < 1">
            <nb-alert outline="basic" style="font-weight: 500"
              >No Events Match Your Search Criteria</nb-alert
            >
          </div> -->
        </div>
        <div *ngIf="showDetails" class="sc-mt-6">
          <!-- seleted event section-->
          <div>
            <button nbButton size="giant" fullWidth (click)="hideDetails()">
              Back
            </button>
          </div>
        </div>
      </nb-tab>
      <nb-tab tabTitle="{{ nbTabs.tab2 }}">
        <div class="sc-mt-6">
          <nb-radio-group
            style="display: flex; justify-content: center"
            [(ngModel)]="selectedJobReport"
            name="ng-model-group"
          >
            <nb-radio
              (valueChange)="jobReportChange($event)"
              *ngFor="let option of jobReportTypes"
              [value]="option"
            >
              {{ option }}
            </nb-radio>
          </nb-radio-group>
        </div>
        <div class="col form-floating sc-mt-6">
          <input
            class="form-control"
            size="giant"
            nbInput
            fullWidth
            placeholder="Pick Date Range"
            readonly
            [value]="dateRangeValue"
            (click)="openRangePicker()"
          />
          <label>Pick Date Range</label>
        </div>
        <div
          (click)="mailBillingReport()"
          *ngIf="userEvents?.length && allowBillingReportExport"
          class="sc-mt-6"
        >
          <button
            title="Email Report"
            nbButton
            size="giant"
            class="sc-mt-0"
            fullWidth
            status="primary"
          >
            Email Report (*.xlsx)
          </button>
        </div>
        <div class="sc-mt-6">
          <app-card-list
            *ngIf="userEvents?.length"
            [listCardData]="userEvents"
            [listCardDetails]="jobReportTableDetailsMobile"
            (rowClick)="onJobTableRowClick($event)"
          ></app-card-list>
        </div>
      </nb-tab>
      <nb-tab tabTitle="{{ nbTabs.tab3 }}">
        <div *ngIf="!showDetails">
          <nb-form-field>
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                nbInput
                [(ngModel)]="clientName"
                fullWidth
                placeholder="Search/Select client"
                (input)="onClientSearch($event)"
                [nbAutocomplete]="autoNgModelDRMobile"
                required
              />
              <label>Search/Select client</label>
            </div>
            <nb-icon
              nbSuffix
              *ngIf="selectedClient && clientName"
              [icon]="'close-outline'"
              class="sc-floating-right"
              pack="eva"
              (click)="
                selectedClient = null;
                clientName = null;
                showTable = false;
                userEvents = [];
                totalPages = 0
              "
            ></nb-icon>
          </nb-form-field>

          <nb-autocomplete #autoNgModelDRMobile>
            <nb-option
              *ngFor="let item of customerDetails"
              [value]="item.company_name"
            >
              <div (click)="onClientSelect(item)">
                <span
                  ><b>{{ item?.company_name }}</b
                  >,</span
                >
                <small> {{ item?.address?.full_address }}</small>
              </div>
            </nb-option>
          </nb-autocomplete>

          <div class="col form-floating sc-mt-6">
            <input
              class="form-control"
              size="giant"
              nbInput
              fullWidth
              placeholder="Pick Date Range"
              readonly
              [value]="dateRangeValue"
              (click)="openRangePicker()"
            />
            <label>Pick Date Range</label>
          </div>
          <nb-rangepicker
            #formpicker
            (rangeChange)="handleDateChange($event)"
          ></nb-rangepicker>

          <div class="col sc-mt-6" *ngIf="userEvents?.length && allowPDFExport">
            <button
              nbButton
              class="nbbutton-long-text"
              fullWidth
              status="primary"
              size="giant"
              (click)="exportData('pdf')"
            >
              Email Report (.pdf)
            </button>
          </div>
        </div>
        <div *ngIf="!showDetails" class="sc-mt-6">
          <!---list of cards-->
          <app-card-list
            *ngIf="userEvents?.length"
            [listCardData]="userEvents"
            [listCardDetails]="userEventDetails"
            (rowClick)="onTableRowClick($event)"
          ></app-card-list>
          <div *ngIf="totalPages > 0">
            <app-pagination-control
              [pageSize]="pageSize"
              [pageNum]="pageNum"
              [previous]="previous"
              [totalRows]="totalRows"
              [totalPages]="totalPages"
              (onClickPagination)="onClickPagination($event)"
              (onChangePagination)="onChangePagination($event)"
            >
            </app-pagination-control>
          </div>
          <div *ngIf="userEvents?.length == 0" class="my-3">
            <nb-alert outline="basic" style="font-weight: 500"
              >No Records Match Your Search Criteria</nb-alert
            >
          </div>

          <!-- <div *ngIf="userEvents?.length < 1">
            <nb-alert outline="basic" style="font-weight: 500"
              >No Events Match Your Search Criteria</nb-alert
            >
          </div> -->
        </div>
        <div *ngIf="showDetails" class="sc-mt-6">
          <!-- seleted event section-->
          <div>
            <button nbButton size="giant" fullWidth (click)="hideDetails()">
              Back
            </button>
          </div>
        </div>
      </nb-tab>
      <nb-tab tabTitle="{{ nbTabs.tab4 }}" *ngIf="userPrompts?.length">
        <div *ngIf="!showDetails">
          <nb-form-field>
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                nbInput
                [(ngModel)]="clientName"
                fullWidth
                placeholder="Search/Select client"
                (input)="onClientSearch($event)"
                [nbAutocomplete]="autoNgModelPrompt"
                required
              />
              <label>Search/Select client</label>
            </div>
            <nb-icon
              nbSuffix
              *ngIf="selectedClient && clientName"
              [icon]="'close-outline'"
              class="sc-floating-right"
              pack="eva"
              (click)="
                selectedClient = null;
                clientName = null;
                showTable = false;
                userEvents = [];
                totalPages = 0
              "
            ></nb-icon>
          </nb-form-field>

          <nb-autocomplete #autoNgModelPrompt>
            <nb-option
              *ngFor="let item of customerDetails"
              [value]="item.company_name"
            >
              <div (click)="onClientSelect(item)">
                <span
                  ><b>{{ item?.company_name }}</b
                  >,</span
                >
                <small> {{ item?.address?.full_address }}</small>
              </div>
            </nb-option>
          </nb-autocomplete>
          <div class="col mt-2">
            <button
              [disabled]="!selectedClient?.id"
              class="nbbutton-long-text"
              fullWidth
              status="primary"
              size="giant"
              nbButton
              (click)="openTemplate(filterPromptDialog)"
            >
              User Prompt(s)
            </button>
          </div>

          <div class="col form-floating sc-mt-6">
            <input
              class="form-control"
              size="giant"
              nbInput
              fullWidth
              placeholder="Pick Date Range"
              readonly
              [value]="dateRangeValue"
              (click)="openRangePicker()"
            />
            <label>Pick Date Range</label>
          </div>
          <nb-rangepicker
            #formpicker
            (rangeChange)="handleDateChange($event)"
          ></nb-rangepicker>

          <div class="col sc-mt-6" *ngIf="userEvents?.length && allowPDFExport">
            <button
              nbButton
              class="nbbutton-long-text"
              fullWidth
              status="primary"
              size="giant"
              (click)="openTemplate(emailUserDialog)"
            >
              Email Report (*.xlsx)
            </button>
          </div>
        </div>
        <div *ngIf="!showDetails" class="sc-mt-6">
          <!---list of cards-->
          <app-card-list
            *ngIf="userEvents?.length"
            [listCardData]="userEvents"
            [listCardDetails]="userEventDetails"
            (rowClick)="onTableRowClick($event)"
          ></app-card-list>
          <div *ngIf="totalPages > 0">
            <app-pagination-control
              [pageSize]="pageSize"
              [pageNum]="pageNum"
              [previous]="previous"
              [totalRows]="totalRows"
              [totalPages]="totalPages"
              (onClickPagination)="onClickPagination($event)"
              (onChangePagination)="onChangePagination($event)"
            >
            </app-pagination-control>
          </div>
          <div *ngIf="userEvents?.length == 0" class="my-3">
            <nb-alert outline="basic" style="font-weight: 500"
              >No Records Match Your Search Criteria</nb-alert
            >
          </div>

          <!-- <div *ngIf="userEvents?.length < 1">
            <nb-alert outline="basic" style="font-weight: 500"
              >No Events Match Your Search Criteria</nb-alert
            >
          </div> -->
        </div>
        <div *ngIf="showDetails" class="sc-mt-6">
          <!-- seleted event section-->
          <div>
            <button nbButton size="giant" fullWidth (click)="hideDetails()">
              Back
            </button>
          </div>
        </div>
      </nb-tab>
    </nb-tabset>
  </div>
  <!-- DESKTOP VIEW -->
  <div class="large-view-only">
    <nb-tabset (changeTab)="changeTab($event)">
      <nb-tab tabTitle="{{ nbTabs.tab1 }}">
        <div class="d-flex">
          <div class="flex-grow-1">
            <nb-form-field class="sc-search-input-box">
              <div class="form-floating">
                <input
                  required
                  class="form-control"
                  type="text"
                  nbInput
                  [(ngModel)]="clientName"
                  fullWidth
                  placeholder="Client Name"
                  (input)="onClientSearch($event)"
                  [nbAutocomplete]="autoNgModelDesk"
                />
                <label>Client Name</label>
              </div>
              <nb-icon
                nbSuffix
                *ngIf="selectedClient && clientName"
                [icon]="'close-outline'"
                class="sc-floating-right"
                pack="eva"
                (click)="
                  selectedClient = null;
                  clientName = null;
                  showTable = false;
                  userEvents = [];
                  totalPages = 0
                "
              ></nb-icon>
            </nb-form-field>
          </div>
          <nb-autocomplete #autoNgModelDesk>
            <nb-option
              *ngFor="let item of customerDetails"
              (click)="onClientSelect(item)"
            >
              <div>
                <span
                  ><b>{{ item?.company_name }}</b
                  >,</span
                >
                <small> {{ item?.address?.full_address }}</small>
              </div>
            </nb-option>
          </nb-autocomplete>

          <div *ngIf="userEvents?.length && allowPDFExport">
            <div class="sc-add-btn" (click)="openTemplate(emailUserDialog)">
              <button
                title="Email Report"
                nbButton
                size="giant"
                class="sc-mt-0"
                status="primary"
              >
                Email Report (.pdf)
              </button>
            </div>
          </div>
        </div>

        <!-- TABLE -->
        <div class="row sc-mt-6" *ngIf="showTable">
          <div class="col">
            <app-table
              [desktopTableData]="userEvents"
              [desktopTableDetails]="userEventDetailsTableView"
              [tableStyle]="tableStyle"
              (rowClick)="onTableRowClick($event)"
              (sortColumn)="sortColumn($event)"
              (searchColumn)="searchColumn($event)"
              [apiFilter]="apiFilter"
              [otherParms]="{ dateRange: dateRange }"
            ></app-table>

            <div *ngIf="totalPages > 0" class="sc-mt-6">
              <app-pagination-control
                [pageSize]="pageSize"
                [pageNum]="pageNum"
                [previous]="previous"
                [totalRows]="totalRows"
                [totalPages]="totalPages"
                (onClickPagination)="onClickPagination($event)"
                (onChangePagination)="onChangePagination($event)"
              >
              </app-pagination-control>
            </div>
            <div *ngIf="userEvents?.length == 0" class="my-3">
              <nb-alert outline="basic" style="font-weight: 500"
                >No Records Match Your Search Criteria</nb-alert
              >
            </div>
          </div>
        </div>
      </nb-tab>

      <!-- * nbTabs.tab2 == JOB REPORTS / ADMIN BILLING REPORTS sc-4064 -->
      <nb-tab tabTitle="{{ nbTabs.tab2 }}">
        <div class="w-25 m-auto">
          <nb-radio-group
            [(ngModel)]="selectedJobReport"
            style="display: flex; justify-content: center"
          >
            <nb-radio
              (valueChange)="jobReportChange($event)"
              *ngFor="let option of jobReportTypes"
              [value]="option"
            >
              {{ option }}
            </nb-radio>
          </nb-radio-group>
          <div clas="sc-mt-6">
            <button
              (click)="mailBillingReport()"
              *ngIf="userEvents?.length && allowBillingReportExport"
              title="Email Report"
              nbButton
              size="large"
              class="sc-mt-0"
              status="primary"
              fullWidth
            >
              Email Report (*.xlsx)
            </button>
          </div>
        </div>
        <div class="sc-mt-6 sc-text-center"></div>

        <div>
          <app-table
            [desktopTableData]="userEvents"
            [desktopTableDetails]="jobReportTableDetails"
            [tableStyle]="tableStyle"
            [otherParms]="{ dateRange: dateRange }"
            (rowClick)="onJobTableRowClick($event)"
            (sortColumn)="sortColumn($event)"
            (searchColumn)="searchColumn($event)"
            [apiFilter]="apiFilter"
          ></app-table>
        </div>
        <div *ngIf="userEvents?.length == 0" class="my-3">
          <nb-alert outline="basic" style="font-weight: 500"
            >No Records Match Your Search Criteria</nb-alert
          >
        </div>
      </nb-tab>
      <nb-tab tabTitle="{{ nbTabs.tab3 }}">
        <div class="d-flex">
          <div class="flex-grow-1">
            <nb-form-field class="sc-search-input-box">
              <div class="form-floating">
                <input
                  required
                  class="form-control"
                  type="text"
                  nbInput
                  [(ngModel)]="clientName"
                  fullWidth
                  placeholder="Client Name"
                  (input)="onClientSearch($event)"
                  [nbAutocomplete]="autoNgModelDRDesk"
                />
                <label>Client Name</label>
              </div>
              <nb-icon
                nbSuffix
                *ngIf="selectedClient && clientName"
                [icon]="'close-outline'"
                class="sc-floating-right"
                pack="eva"
                (click)="
                  selectedClient = null;
                  clientName = null;
                  showTable = false;
                  userEvents = [];
                  totalPages = 0
                "
              ></nb-icon>
            </nb-form-field>
          </div>
          <nb-autocomplete #autoNgModelDRDesk>
            <nb-option
              *ngFor="let item of customerDetails"
              (click)="onClientSelect(item)"
            >
              <div>
                <span
                  ><b>{{ item?.company_name }}</b
                  >,</span
                >
                <small> {{ item?.address?.full_address }}</small>
              </div>
            </nb-option>
          </nb-autocomplete>

          <div *ngIf="userEvents?.length && allowPDFExport">
            <div class="sc-add-btn" (click)="exportData('pdf')">
              <button
                title="Email Report"
                nbButton
                size="giant"
                class="sc-mt-0"
                status="primary"
              >
                Email Report (.pdf)
              </button>
            </div>
          </div>
        </div>

        <!-- TABLE -->
        <div class="row sc-mt-6" *ngIf="showTable">
          <div class="col">
            <app-table
              [desktopTableData]="userEvents"
              [desktopTableDetails]="userEventDetailsTableView"
              [tableStyle]="tableStyle"
              (rowClick)="onTableRowClick($event)"
              (sortColumn)="sortColumn($event)"
              (searchColumn)="searchColumn($event)"
              [apiFilter]="apiFilter"
              [otherParms]="{ dateRange: dateRange }"
            ></app-table>

            <div *ngIf="totalPages > 0" class="sc-mt-6">
              <app-pagination-control
                [pageSize]="pageSize"
                [pageNum]="pageNum"
                [previous]="previous"
                [totalRows]="totalRows"
                [totalPages]="totalPages"
                (onClickPagination)="onClickPagination($event)"
                (onChangePagination)="onChangePagination($event)"
              >
              </app-pagination-control>
            </div>
            <div *ngIf="userEvents?.length == 0" class="my-3">
              <nb-alert outline="basic" style="font-weight: 500"
                >No Records Match Your Search Criteria</nb-alert
              >
            </div>
          </div>
        </div>
      </nb-tab>
      <nb-tab tabTitle="{{ nbTabs.tab4 }}" *ngIf="userPrompts?.length">
        <div class="d-flex">
          <div class="flex-grow-1">
            <nb-form-field class="sc-search-input-box">
              <div class="form-floating">
                <input
                  required
                  class="form-control"
                  type="text"
                  nbInput
                  [(ngModel)]="clientName"
                  fullWidth
                  placeholder="Client Name"
                  (input)="onClientSearch($event)"
                  [nbAutocomplete]="autoNgModelDeskPrompt"
                />
                <label>Client Name</label>
              </div>
              <nb-icon
                nbSuffix
                *ngIf="selectedClient && clientName"
                [icon]="'close-outline'"
                class="sc-floating-right"
                pack="eva"
                (click)="
                  selectedClient = null;
                  clientName = null;
                  showTable = false;
                  userEvents = [];
                  totalPages = 0
                "
              ></nb-icon>
            </nb-form-field>
          </div>
          <nb-autocomplete #autoNgModelDeskPrompt>
            <nb-option
              *ngFor="let item of customerDetails"
              (click)="onClientSelect(item)"
            >
              <div>
                <span
                  ><b>{{ item?.company_name }}</b
                  >,</span
                >
                <small> {{ item?.address?.full_address }}</small>
              </div>
            </nb-option>
          </nb-autocomplete>

          <div class="sc-add-btn">
            <button
              [disabled]="!selectedClient?.id"
              class="nbbutton-long-text"
              fullWidth
              status="primary"
              size="giant"
              nbButton
              (click)="openTemplate(filterPromptDialog)"
            >
              User Prompt(s)
            </button>
          </div>

          <div *ngIf="userEvents?.length && allowPDFExport">
            <div class="sc-add-btn" (click)="openTemplate(emailUserDialog)">
              <button
                title="Email Report"
                nbButton
                size="giant"
                class="sc-mt-0"
                status="primary"
              >
                Email Report (*.xlsx)
              </button>
            </div>
          </div>
        </div>

        <!-- TABLE -->
        <div class="row sc-mt-6" *ngIf="showTable">
          <div class="col">
            <app-table
              [desktopTableData]="userEvents"
              [desktopTableDetails]="userEventDetailsTableView"
              [tableStyle]="tableStyle"
              (rowClick)="onTableRowClick($event)"
              (sortColumn)="sortColumn($event)"
              (searchColumn)="searchColumn($event)"
              [apiFilter]="apiFilter"
              [otherParms]="{ dateRange: dateRange }"
            ></app-table>

            <div *ngIf="totalPages > 0" class="sc-mt-6">
              <app-pagination-control
                [pageSize]="pageSize"
                [pageNum]="pageNum"
                [previous]="previous"
                [totalRows]="totalRows"
                [totalPages]="totalPages"
                (onClickPagination)="onClickPagination($event)"
                (onChangePagination)="onChangePagination($event)"
              >
              </app-pagination-control>
            </div>
            <div *ngIf="userEvents?.length == 0" class="my-3">
              <nb-alert outline="basic" style="font-weight: 500"
                >No Records Match Your Search Criteria</nb-alert
              >
            </div>
          </div>
        </div>
      </nb-tab>
    </nb-tabset>
  </div>

  <!--details section (should be hidden while switching tabs)-->
  <div
    class="row row-cols-1 row-cols-sm-1 row-cols-md-2 gt-p-small"
    *ngIf="showDetails"
  >
    <div class="col-md-5">
      <nb-card class="events-details-section">
        <div
          *ngIf="
            [1, 2, 5].includes(selectedEvent?.event_action) &&
            selectedEvent?.detail
          "
          class="mt-2"
        >
          <div class="chat-info">
            <strong>{{ selectedEvent?.action | titlecase }}</strong>
            <span
              *ngIf="
                selectedEvent?.history_changes?.length &&
                (subscriberUserId == selectedEvent?.subs_user_id ||
                  isAdmin ||
                  isDispatchUser)
              "
            >
              | Edited [{{
                getLastCommentEditedDateTime(selectedEvent?.history_changes)
                  ?.updated_at | customdate : "d MMM y HH:mm"
              }}]</span
            >
          </div>
          <div
            class="chat-data fs-6"
            (click)="
              canEditComment(selectedEvent)
                ? editComment(selectedEvent, commentRef)
                : ''
            "
          >
            {{ selectedEvent?.detail }}
            <div
              class="sc-floating-right"
              *ngIf="canEditComment(selectedEvent)"
            >
              <nb-icon pack="eva" icon="edit-outline"></nb-icon>
            </div>
          </div>
        </div>
        <ng-container>
          <div class="sc-head6">Event Details</div>
          <div class="row row-cols-1 row-cols-xxl-2">
            <ng-container *ngFor="let indColumn of userEventDetailsTableView">
              <ng-container
                *ngIf="
                  (indColumn.nestedValue
                    ? indColumn.nestedValue(selectedEvent)
                    : selectedEvent[indColumn.name]
                  )?.trim()
                "
              >
                <div>
                  <span class="badge-key" style="text-transform: uppercase">
                    {{ indColumn?.header }}</span
                  >
                  <ng-container *ngIf="indColumn?.datetimeObj == true">
                    <span class="badge-value">
                      {{
                        indColumn.nestedValue
                          ? (indColumn.nestedValue(selectedEvent)
                            | date
                              : (indColumn.dateTimeFormat
                                  ? indColumn.dateTimeFormat
                                  : "d MMM y HH:mm"))
                          : (selectedEvent[indColumn.name]
                            | date
                              : (indColumn.dateTimeFormat
                                  ? indColumn.dateTimeFormat
                                  : "d MMM y HH:mm"))
                      }}
                    </span></ng-container
                  >
                  <ng-container *ngIf="!indColumn?.datetimeObj">
                    <span class="badge-value">
                      {{
                        indColumn.nestedValue
                          ? indColumn.nestedValue(selectedEvent)
                          : selectedEvent[indColumn.name] == "null" ||
                            selectedEvent[indColumn.name] == "undefined"
                          ? "N/A"
                          : selectedEvent[indColumn.name]
                      }}
                    </span></ng-container
                  >
                </div>
              </ng-container>
            </ng-container>
            <div *ngIf="selectedEvent?.checkpoint_details?.min_distance">
              <span class="badge-key" style="text-transform: uppercase">
                Geofence Radius (metres)</span
              >
              <span class="badge-value">{{
                selectedEvent?.checkpoint_details?.min_distance
                  | number : "1.0-0"
              }}</span>
            </div>
          </div>
          <div
            *ngIf="scanDistanceInfo?.scanDistance"
            class="fw-bold text-danger"
          >
            <span class="badge-value">
              Scanned {{ scanDistanceInfo?.scanDistance }}M away from the
              Checkpoint</span
            >
          </div>
        </ng-container>

        <div
          *ngIf="selectedEvent?.recorded_at && !selectedEvent?.delayed_event"
          nbTooltip="This event was captured when the user was offline"
        >
          <span class="badge-key" style="color: brown">CAPTURED TIME</span>
          <span class="badge-value strong">
            {{ selectedEvent?.recorded_at | customdate : "d MMM y HH:mm" }}
          </span>
        </div>
        <ng-container *ngIf="selectedEvent?.prompts?.length">
          <div *ngFor="let item of selectedEvent?.prompts">
            <div class="sc-head6">
              {{ item?.prompt_data?.name ? item?.prompt_data?.name : "Prompt" }}
            </div>

            <div *ngFor="let dataItem of item?.data">
              <ng-template
                *ngTemplateOutlet="promptTemplate; context: { value: dataItem }"
              ></ng-template>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="selectedEvent?.device_details">
          <div class="sc-head6">Device Details</div>
          <div class="row row-cols-1 row-cols-xxl-2">
            <div *ngFor="let item of selectedEvent?.device_details | keyvalue">
              <div *ngIf="item?.value">
                <span class="badge-key" style="text-transform: uppercase">
                  {{ item.key }}</span
                >
                <span class="badge-value"> {{ item.value }} </span>
              </div>
            </div>

            <div>
              <span
                class="badge-key"
                nbTooltip="{{browserGpsInfo?.[selectedEvent?.gps?.source]?.description}}"
                nbTrigger="focus"
                [ngClass]="browserGpsInfo?.[selectedEvent?.gps?.source] ? 'strong' : ''"
                [nbTooltipDisabled]="!browserGpsInfo?.[selectedEvent?.gps?.source]"
              >
                LOCATION
              </span>
              <span
                class="badge-value"
                nbTooltip="{{browserGpsInfo?.[selectedEvent?.gps?.source]?.description}}"
                nbTrigger="focus"
                [nbTooltipDisabled]="!browserGpsInfo?.[selectedEvent?.gps?.source]"
                [ngClass]="browserGpsInfo?.[selectedEvent?.gps?.source] ? 'danger-text': ''"
              >
                {{ selectedEvent?.gps?.lat }},
                {{ selectedEvent?.gps?.lon }}
              </span>
            </div>
          </div>
        </ng-container>
      </nb-card>
    </div>

    <div class="col-md-7">
      <div [ngClass]="uploadedImage ? 'row row-cols-md-2' : 'row row-cols-1'">
        <div class="col-md-6" *ngIf="uploadedImage">
          <img class="event-image" src="{{ uploadedImage }}" alt="unplash" />
        </div>
        <div [ngClass]="uploadedImage ? 'col-md-6' : 'col'">
          <app-map-leaflet
            *ngIf="mapLatLong"
            [latLon]="mapLatLong"
            [scannedCheckpointDetails]="selectedEvent?.checkpoint_details"
            [mapId]="'event-location'"
          ></app-map-leaflet>
          <div class="sc-text-center" style="font-size: small">
            {{ selectedEvent?.gps?.lat }},
            {{ selectedEvent?.gps?.lon }}
          </div>
        </div>
      </div>
    </div>
    <button
      class="mobile-only"
      *ngIf="showDetails"
      nbButton
      size="giant"
      fullWidth
      (click)="hideDetails()"
    >
      Back
    </button>
  </div>
</div>

<!-- Report users dialog box -->
<ng-template #emailUserDialog let-uDRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-body>
      <nb-tag-list class="nb-tag-list">
        <nb-tag
          *ngFor="let user of selectedUsers"
          [text]="user.name"
          removable
          (remove)="removeUser(user)"
          nbTooltip="{{ user?.email }}  |
          {{ user?.mobile_number }}"
        ></nb-tag>
      </nb-tag-list>
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          nbTagInput
          fullWidth
          placeholder="Email Recipients"
          (input)="getUsers($event)"
          [nbAutocomplete]="autoNgModelUsers"
        />
        <label>Email Recipients</label>
      </div>
      <nb-autocomplete #autoNgModelUsers>
        <nb-option (click)="addToContactsTemp()"> Add New Contact </nb-option>
        <nb-option
          *ngFor="let user of usersList"
          (click)="
            addUser(
              user?.pk,
              user?.user_full_name ? user.user_full_name : user.user_email,
              user
            )
          "
        >
          <div>
            {{ user?.user_full_name ? user.user_full_name : user.user_email }}
            <div>
              <small>
                {{ user?.user_full_name ? user.user_email : "" }}
                {{
                  user?.user_mobile_number
                    ? " , " + user?.user_mobile_number
                    : ""
                }}
              </small>
            </div>
          </div>
        </nb-option></nb-autocomplete
      >

      <div class="row row-cols-2 sc-mt-6">
        <div class="col">
          <button
            class="nbbutton-long-text"
            size="giant"
            (click)="uDRef.close()"
            nbButton
            fullWidth
            ghost
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            class="nbbutton-long-text"
            [disabled]="!selectedUsers?.length"
            size="giant"
            (click)="
              exportData(selectedPromptIds?.length ? 'csv' : 'pdf');
              uDRef.close()
            "
            nbButton
            fullWidth
          >
            Send
          </button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<!-- edit COMMENT POPOVER / DIALOG -->
<ng-template #commentRef let-commentRef="dialogRef" let-data>
  <nb-card class="dialog-box">
    <nb-card-header class="sc-text-center">
      {{ data?.detail ? "Edit Comment" : "Add Comment" }}

      <nb-icon
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
        (click)="commentRef.close(); newComment = ''"
      >
      </nb-icon>
    </nb-card-header>
    <div class="row-cols-1 gy-2">
      <div class="col">
        <textarea
          class="form-control"
          rows="10"
          cols="32"
          wrap="hard"
          maxlength="340"
          type="text"
          placeholder="Start typing"
          [(ngModel)]="newComment"
        ></textarea>
      </div>
      <div class="col sc-mt-6">
        <button
          [disabled]="!newComment || previousComment === newComment"
          (click)="addComment(data); commentRef.close()"
          nbButton
          size="giant"
          fullWidth
        >
          {{ data?.detail ? "Update" : "Add" }}
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>

<!-- email billing report template -->
<ng-template #billReportRef let-billReportRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center">
      Send Billing Report

      <nb-icon
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
        (click)="billReportRef.close(); billingReportEmailForm.reset()"
      >
      </nb-icon>
    </nb-card-header>
    <form [formGroup]="billingReportEmailForm">
      <div class="form-floating">
        <input
          class="form-control"
          fullWidth
          type="text"
          nbInput
          formControlName="emails"
          required
        />
        <label>Email</label>
      </div>

      <div class="col sc-mt-6">
        <button
          [disabled]="!billingReportEmailForm.valid"
          (click)="mailBillingReport(); billReportRef.close()"
          nbButton
          size="giant"
          fullWidth
        >
          Mail Report
        </button>
      </div>
    </form>
  </nb-card>
</ng-template>

<!--looped prompt template-->
<ng-template let-dataItem="value" #promptTemplate>
  <div *ngIf="dataItem?.description">
    <span class="badge-key" style="text-transform: uppercase">
      {{ dataItem?.description }}</span
    >
    <!--TODO: convert into pipe-->
    <span class="badge-value"> {{ getPromptValue(dataItem) }} </span>
  </div>

  <div *ngFor="let option of dataItem?.options">
    <ng-template
      *ngTemplateOutlet="promptTemplate; context: { value: option }"
    ></ng-template>
  </div>
</ng-template>

<ng-template #filterPromptDialog let-filterPromptDialog="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center">
      Prompts

      <nb-icon
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
        (click)="filterPromptDialog.close()"
      >
      </nb-icon>
    </nb-card-header>

    <ng-container>
      <table class="table">
        <tbody>
          <tr
            *ngFor="let prompt of userPrompts; let i = index"
            class="cursor-pointer border-bottom"
          >
            <td class="">
              <nb-checkbox
                [checked]="prompt?.selected"
                (checkedChange)="selectUnselectPrompt(prompt)"
              >
                {{ prompt?.name }}
              </nb-checkbox>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row row-cols-2 mt-3">
        <div class="col">
          <button
            nbButton
            size="giant"
            fullWidth
            ghost
            (click)="filterPromptDialog.close()"
          >
            Cancel
          </button>
        </div>

        <div class="col">
          <button
            type="button"
            [disabled]="!selectedPromptIds?.length"
            nbButton
            size="giant"
            fullWidth
            status="primary"
            (click)="searchData(); filterPromptDialog.close()"
          >
            Search
          </button>
        </div>
      </div>
    </ng-container>
  </nb-card>
</ng-template>
