import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {
  paymentData: any;
  tableStyle = {
    'overflow': 'auto',
    'max-height': '800px',
  };
  dateRange: any;

  paymentDetailsTableView: any = [
    {
      header: 'Subscriber',
      name: 'subscriber_name',
      sortKey: 'subscriber_name',
      searchKey: 'subscriber_name',
    },
    {
      header: 'Paid By',
      name: 'invoice_paid_by',
    },

    {
      header: 'Invoice Date',
      name: 'invoice_created_at',
      datetimeObj: true,
      sortKey: 'invoice_created_at',
      dateRangeKey: 'invoice_created_at',
    },
    {
      header: 'Invoice Amount(AUD)',
      name: 'invoice_amount',
      sortKey: 'invoice_amount',
    },
    {
      header: 'Invoice Card',
      nestedValue: (row: any) => {
        return row?.['invoice_card_details']?.['last_4'];
      },
    },
    {
      header: 'Invoice Number',
      name: 'invoice_num',
    },
    {
      header: 'Square Date',
      name: 'square_created_at',
      datetimeObj: true,
      sortKey: 'square_created_at',
      dateRangeKey: 'square_created_at',
    },
    {
      header: 'Square Amount(AUD)',
      name: 'square_amount',
      sortKey: 'square_amount',
    },
    {
      header: 'Square Status',
      name: 'square_status',
    },
    {
      header: 'Square Card',
      nestedValue: (row: any) => {
        return row?.['square_card_details']?.['last_4'];
      },
    },
  ];
  invoiceListData: any = [];
  invoiceTableView = [
    {
      header: 'Subscriber',
      name: 'subscriber_company_name',
      searchKey: 'subscriber_company_name',
      sortKey: 'subscriber_company_name',
    },
    {
      header: 'Invoice Number',
      name: 'invoice_num',
    },
    {
      header: 'Invoice Date',
      name: 'generated_date',
      datetimeObj: true,
      sortKey: 'generated_date',
      dateRangeKey: 'generated_date',
    },
    {
      header: 'Amount(AUD)',
      name: 'amount',
      sortKey: 'amount',
    },
    {
      header: 'Misc Amount(AUD)',
      nestedValue: (row: any) => {
        return (
          (row?.user_transactions?.allotted_slots || 0) *
          (row?.user_transactions?.user_price || 0)
        );
      },
    },
    {
      header: 'Start',
      name: 'invoice_period_start',
      datetimeObj: true,
      sortKey: 'invoice_period_start',
      dateTimeFormat: 'd MMM y',
    },
    {
      header: 'End',
      name: 'invoice_period_end',
      datetimeObj: true,
      sortKey: 'invoice_period_end',
      dateTimeFormat: 'd MMM y',
    },
    {
      header: 'Invoice Card',
      name: 'card_last4',
    },
  ];
  rowsInvoices: number = 10;
  pageNumInvoices: number = 0;
  sortKeyInvoices: any;
  otherParamsInvoices: any = {};
  previousErrorLogs: number = 0;
  totalPagesErrorLogs: number = 0;
  searchParams: any = {};

  constructor(
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getInvoices();
    this.getPayments();
  }

  getPayments() {
    this.spinnerService.show();
    this.adminService.getPayments().subscribe((resp: any) => {
      this.paymentData = resp['data'];
      this.spinnerService.hide();
    });
  }
  getInvoices() {
    let params: any = {};
    if (this.rowsInvoices) {
      params['page_size'] = this.rowsInvoices;
    }
    if (this.pageNumInvoices) {
      params['page'] = this.pageNumInvoices;
    }
    if (this.sortKeyInvoices) {
      params['order_by'] = this.sortKeyInvoices;
    }
    if (this.searchParams) {
      Object.entries(this.searchParams).forEach(
        ([key, value]) => (params[key] = value)
      );
    }
    this.adminService.getInvoices().subscribe((response: any) => {
      this.invoiceListData = response;

      this.totalPagesErrorLogs = Math.ceil(response?.count / this.rowsInvoices);
      this.otherParamsInvoices.paginationData = {
        tableTotalRows: response?.count,
        tablePreviousRows: this.previousErrorLogs,
        tableRows: this.rowsInvoices,
        tablePageNum: this.pageNumInvoices,
        tableTotalPages: this.totalPagesErrorLogs,
      };
    });
  }
  onChangePaginationInvoices(data: any) {
    this.previousErrorLogs = data?.paginationData?.tablePreviousRows;
    this.pageNumInvoices = data?.paginationData?.tablePageNum;
    this.rowsInvoices = data?.paginationData?.tableRows;

    this.otherParamsInvoices.paginationData = {
      ...data?.paginationData,
    };
    this.getInvoices();
  }
  sortColumn(body: any) {
    this.sortKeyInvoices = body?.sortList || [];

    this.getInvoices();
  }
  searchColumnInvoices(event: any) {
    this.searchParams = event?.searchParams || {};
    this.getInvoices();
  }
}
