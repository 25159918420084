<nb-card class="p-0 m-0">
  <div *ngIf="!qrCodeList?.length" class="row p-0 m-0">
    <p>
      Intended for use with 63.5 x 72mm blank labels
      <a
        href="https://www.averyproducts.com.au/blank-labels/rectangle-72x63mm?mat=hdl"
        target="_blank"
        rel="noopener"
        class="strong"
        >(L7164)</a
      >
    </p>
    <p>QR Codes must be deployed and registered at location</p>
    <div class="row p-0 m-0 mt-3">
      <div class="col col-lg-3">
        <button
          nbButton
          fullWidth
          size="giant"
          (click)="openTemplate(); existingQRCodes = false"
          class="nbbutton-long-text"
        >
          New QR Codes
        </button>
      </div>
    </div>
    <div class="row p-0 m-0">
      <div class="col col-lg-3 mt-2">
        <button
          class="nbbutton-long-text"
          nbButton
          fullWidth
          size="giant"
          (click)="
            openTemplate(existingQrGenerateTemplateRef); getQRCheckpoints()
          "
        >
          Reprint Existing Codes
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="qrCodeList?.length">
    <div class="row row-cols-auto">
      <div *ngFor="let qr of qrCodeList; let i = index" class="row m-auto">
        <qrcode
          style="display: none"
          [imageSrc]="qrImageSrc"
          [imageHeight]="350"
          [imageWidth]="350"
          [id]="qr?.qr_code + i"
          [qrdata]="qr?.qr_code"
          [ariaLabel]="qr?.qr_code"
          [colorDark]="'#000000ff'"
          [colorLight]="'#ffffffff'"
          [elementType]="'canvas'"
          [errorCorrectionLevel]="'H'"
          [margin]="1"
          [scale]="1"
          [width]="1200"
          (qrCodeURL)="onChangeURL(qr, qr?.qr_code + i)"
        ></qrcode>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-4">
        <nb-alert outline="basic" role="alert" class="mb-0">
          QR Codes Ready - QTY: {{ qrCodeList?.length }}
        </nb-alert>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-4">
        <button
          class="nbbutton-long-text"
          nbButton
          fullWidth
          size="giant"
          [disabled]="!(qrCodeList?.length === qrCodeb64ImageUrls?.length)"
          (click)="downloadQRCodes(1)"
        >
          Email Code(s)
        </button>
      </div>
    </div>
  </div>
</nb-card>

<ng-template #qrGenerateTemplateRef let-qrGenerateTemplateRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header>
      Generate New QR Codes

      <nb-icon
        (click)="qrGenerateTemplateRef.close()"
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
      ></nb-icon>
    </nb-card-header>

    <div class="sc-mt-6 sc-text-center strong">
      Quantity
      <nb-select [(selected)]="qrCount">
        <nb-option *ngFor="let no of [12, 24, 36]" [value]="no">
          {{ no }}</nb-option
        >
      </nb-select>

      <div class="col sc-mt-6" *ngIf="qrCount > 0">
        <button nbButton fullWidth size="giant" (click)="generateNQRcodes()">
          Submit
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>

<!--print out qr codes for existing qr codes-->
<ng-template
  #existingQrGenerateTemplateRef
  let-existingQrGenerateTemplateRef="dialogRef"
>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header>
      Select Checkpoints
      <nb-icon
        (click)="existingQrGenerateTemplateRef.close()"
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
      ></nb-icon>
      <span *ngIf="selectedQrCheckpoints?.length">
        [{{ selectedQrCheckpoints?.length }} Selected]
      </span>
    </nb-card-header>

    <div class="sc-mt-6">
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          nbTagInput
          fullWidth
          placeholder="Search"
          (input)="filterQRCheckpoints($event)"
        />
        <label>Search</label>
      </div>

      <div class="sc-mt-6">
        <table>
          <tbody>
            <tr *ngFor="let checkpoint of qrCPList">
              <td>
                <nb-checkbox
                  [checked]="checkpoint.selected"
                  (checkedChange)="
                    addRemoveCheckpoint($event, checkpoint);
                    checkpoint.selected = !checkpoint?.selected
                  "
                >
                </nb-checkbox>
              </td>
              <td>
                {{ checkpoint?.name }}
                <div class="small-font">
                  {{ checkpoint?.address?.full_address }}
                </div>
                <div class="small-font">
                  {{ checkpoint?.company }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="sc-mt-6">
          <app-pagination-control
            [pageSize]="qrCpRows"
            [pageNum]="qrCpPageNum"
            [previous]="qrCpPrevious"
            [totalRows]="qrCpTotalCount"
            [totalPages]="qrCpTotalPages"
            (onClickPagination)="onQrClickPagination($event)"
            (onChangePagination)="onQrChangePagination($event)"
          >
          </app-pagination-control>
        </div>
      </div>
    </div>

    <div class="row row-cols-2 sc-mt-6">
      <div class="col">
        <button
          type="button"
          (click)="
            existingQrGenerateTemplateRef.close();
            selectedQrCheckpoints = [];
            qrCPList = []
          "
          nbButton
          fullWidth
          ghost
          size="giant"
        >
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          type="submit"
          [disabled]="!selectedQrCheckpoints?.length"
          nbButton
          fullWidth
          size="giant"
          (click)="
            existingQrGenerateTemplateRef.close(); generateExistingQRCodes()
          "
        >
          Next
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>
