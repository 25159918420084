import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient) {}
  notificationCount: any;
  fetchUserProfile(url = 'api') {
    return this.http
      .get(`/${url}/profile/`)
      .pipe(map((response: any) => response));
  }
  logOut(data: any) {
    const body = {
      ...data,
    };
    return this.http
      .post(`api/logout/`, body)
      .pipe(map((response: any) => response));
  }
  addUserViaLinkProfile(requestData: any) {
    const body = requestData.data;
    return this.http
      .post(`api/profile/`, body)
      .pipe(map((response) => response));
  }
  updateUser(body: any, params: any) {
    return this.http
      .post(`api/profile/update_user/`, body, { params: params })
      .pipe(map((response) => response));
  }

  fetchCompanyDetails(params?: any) {
    return this.http
      .get('/api/company_profile/', { params: params })
      .pipe(map((response: any) => response));
  }

  associatedCompanies(data?: any, params?: any) {
    const body = {
      ...data,
    };
    return this.http
      .post('/api/company_profile/associated_companies/', body, {
        params: params,
      })
      .pipe(map((response: any) => response));
  }
  changePasswordFromLink(data: any, params: any) {
    const body = {
      ...data,
    };
    return this.http
      .post(`api/external_api/change_password/`, body, { params: params })
      .pipe(map((response: any) => response));
  }
  changePassword(data: any, params?: any) {
    const body = {
      ...data,
    };
    return this.http
      .post(`api/profile/change_password/`, body, { params: params })
      .pipe(map((response: any) => response));
  }
  changeAdminPassword(data: any, params?: any) {
    const body = {
      ...data,
    };
    return this.http
      .post(`admin/user/change_password/`, body, { params: params })
      .pipe(map((response: any) => response));
  }
  updateProfileDetails(requestData: any, url = 'api') {
    let data = requestData.data;
    const body = {
      ...data,
    };
    return this.http.post(`${url}/profile/`, data);
  }
  removeUserAssociation() {
    return this.http
      .post('api/profile/remove_association/', {})
      .pipe(map((response: any) => response));
  }

  updateEmail(body?: any, params?: any) {
    return this.http
      .post('api/profile/update_email/', body, {
        params: params,
      })
      .pipe(map((response) => response));
  }
  getNotifications(params: any) {
    return this.http
      .get('api/notification/', {
        params: params,
      })
      .pipe(map((response) => response));
  }
  deleteNotifications(params: any, body: any) {
    return this.http
      .post('api/notification/delete/', body, { params: params })
      .pipe(map((response) => response));
  }

  getNotificationCount() {
    return this.http
      .get('api/notification/get_count/')
      .pipe(map((response) => response));
  }
  getUsersNotificationCount(): Observable<any> {
    return this.notificationCount;
  }
  checkPhoneValidation(body: any) {
    return this.http
      .post('api/profile/validate/', body)
      .pipe(map((response) => response));
  }
  refreshAndSendRegistrationLink(email: any, token: any) {
    return this.http
      .post('api/external_api/send_new_link/', email, {
        params: { token: token },
      })
      .pipe(map((response) => response));
  }

  getJobUrlExpiry(params: any) {
    return this.http
      .get('api/external_api/job_url_expiry/', { params: params })
      .pipe(map((response) => response));
  }

  manageJob(url: any, body: any, token: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('token', token);
    headers = headers.append('Content-type', 'application/json');
    headers = headers.append('Accept', 'application/json');

    return this.http
      .post(`api/external_api/${url}/`, body, { headers })
      .pipe(map((response) => response));
  }

  addressLookup(input: any, params?: any) {
    return this.http.post(
      'api/address/lookup/',
      { search_str: input },
      { params: params }
    );
  }
  fidoRegister(body?: any, params?: any) {
    return this.http.post('api/profile/fido_register/', body, {
      params: params,
    });
  }
  removeProfileImage(params: any) {
    return this.http
      .post(`api/profile/update_profile_image/`, {}, { params: params })
      .pipe(map((response: any) => response));
  }
  getUserConfig(params?: any) {
    return this.http
      .get(`api/profile/user_config/`, { params: params })
      .pipe(map((response: any) => response));
  }
  registerPushNotification(body: any, params?: any) {
    return this.http
      .post(`api/profile/notification_register/`, body, { params: params })
      .pipe(map((response) => response));
  }
  getFeedbacks(params?: any) {
    return this.http
      .get(`api/feedback/`, { params: params })
      .pipe(map((response: any) => response));
  }
  getFeedbackById(id: number) {
    return this.http
      .get(`api/feedback/${id}/`)
      .pipe(map((response: any) => response));
  }
  updateFeedback(id: number, body: any, params?: any) {
    return this.http
      .put(`api/feedback/${id}/`, body, { params: params })
      .pipe(map((response: any) => response));
  }

  getVersionInfo() {
    return this.http
      .get(`api/get_version_history/`)
      .pipe(map((response: any) => response));
  }
  guardClockedInOut(params?: any) {
    return this.http
      .post(`api/profile/clock_user/`, {}, { params: params })
      .pipe(map((response: any) => response));
  }
  getFidoDevices() {
    return this.http.get(`api/devices/`).pipe(map((response: any) => response));
  }
}
