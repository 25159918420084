<div class="cam-template">
  <nb-card class="background-black">
    <nb-alert class="mx-2 mb-0" *ngIf="cameraError" outline="danger">
      {{ cameraError }}
    </nb-alert>
    <div class="cam-template">
      <!-- only permits live photos from users-->

      <input
        id="fileInputLive"
        nbInput
        style="display: none; cursor: pointer"
        type="file"
        accept="image/*"
        capture="environment"
        (change)="handleFileInput($event.target)"
      />

      <!-- only permits photos uploaded from the device of users users-->
      <input
        id="fileInput"
        nbInput
        style="display: none; cursor: pointer"
        type="file"
        accept=".jpg, .jpeg, .png"
        (change)="handleFileInput($event.target)"
      />
      <ng-container *ngIf="!imageUrl">
        <ng-container *ngIf="isCameraOpen && !actualUploadFile && !imageUrl">
          <div class="row">
            <button
              nbButton
              id="fileInput"
              class="nbbutton-long-text"
              (click)="retry(); imageUrl = null"
              fullWidth
              size="giant"
              status="primary"
              hero
            >
              Retry
            </button>
          </div>
          <div class="row mt-2">
            <button
              nbButton
              class="nbbutton-long-text"
              (click)="close()"
              fullWidth
              size="giant"
            >
              Cancel
            </button>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <ng-container
      *ngIf="(webcamImage || imageUrl) && showFrom !== 'updateClient'"
    >
      <img src="{{ webcamImage ? webcamImage : imageUrl }}" />
      <div class="form-floating sc-mt-6" *ngIf="showFrom != 'userProfilePage'">
        <textarea
          class="form-control"
          fullWidth
          maxlength="300"
          type="text"
          placeholder="Add a Comment..."
          [(ngModel)]="newComment"
        ></textarea>
        <label>Add a Comment...</label>
      </div>
      <div class="row row-cols-2 mt-1">
        <div class="col" (click)="close()">
          <button nbButton fullWidth size="giant" ghost>Cancel</button>
        </div>
        <div class="col">
          <button
            (click)="onSavePhoto()"
            *ngIf="showFrom !== 'scanCheckPoint'"
            fullWidth
            nbButton
            status="primary"
            size="giant"
          >
            Save
          </button>

          <button
            (click)="onFileSubmit()"
            *ngIf="showFrom === 'scanCheckPoint'"
            [disabled]="!imageUrl && !webcamImage"
            nbButton
            class="nbbutton-long-text"
            fullWidth
            status="basic"
            size="giant"
          >
            {{ btnLable }}
          </button>
        </div>
      </div>
    </ng-container>

    <!-- container to manipulate/block app from client update-->
    <ng-container
      *ngIf="(webcamImage || imageUrl) && showFrom == 'updateClient'"
    >
      <!--api returns an empty client list-->
      <div
        *ngIf="!apidataUpdation && clientCount === 0"
        class="empty-add-photo"
      >
        <nb-alert>No clients found</nb-alert>
        <div class="col" (click)="close()">
          <button nbButton fullWidth size="giant" ghost>Go Back</button>
        </div>
      </div>

      <div *ngIf="apidataUpdation || (!apidataUpdation && clientCount > 0)">
        <img src="{{ webcamImage ? webcamImage : imageUrl }}" />

        <div class="form-floating sc-mt-6">
          <textarea
            class="form-control"
            fullWidth
            maxlength="300"
            type="text"
            placeholder="Add a Comment..."
            [disabled]="!apidataUpdation && clientCount === 0 ? true : false"
            [(ngModel)]="newComment"
          ></textarea>
          <label>Add a Comment...</label>
        </div>
        <div class="row row-cols-2 sc-mt-6">
          <div class="col" (click)="close()">
            <button nbButton fullWidth size="giant" ghost>Go Back</button>
          </div>
          <div class="col">
            <button
              (click)="onSavePhoto()"
              nbButton
              size="giant"
              fullWidth
              status="primary"
              class="nbbutton-long-text"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </nb-card>
</div>
