import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { PagesService } from '../../pages.service';
import { UserPromptService } from '../user-prompt.service';
@Component({
  selector: 'app-list-user-prompts',
  templateUrl: './list-user-prompts.component.html',
  styleUrls: ['./list-user-prompts.component.scss'],
})
export class ListUserPromptsComponent implements OnInit {
  previousUserPrompt: number = 0;
  pageSizeUserPrompt: number = 10;
  pageNumUserPrompt: number = 1;
  totalPagesUserPrompt: number = 0;
  totalCountUserPrompt: number = 0;
  userPromptList: any = [];

  cardIcons = [
    {
      condition: function (row: any) {
        return {
          icon: 'copy-outline',
          status: 'basic',
          title: 'Copy Prompt',
          confirm: true,
          show: 'top',
          type: 'copy',
        };
      },
    },
    {
      condition: function (row: any) {
        return {
          icon: 'trash-2-outline',
          status: 'basic',
          title: 'Delete Prompt',
          confirm: true,
          show: 'top',
          type: 'delete',
        };
      },
    },
  ];
  userPromptCardDetils = {
    individualDetails: [
      {
        name: 'name',
        cardRowStyle: { 'font-weight': 'bolder' },
      },
      {
        nestedValue: (row: any) => {
          if (row?.show_on_home == true) return '[SHOWN IN HOME]';
          else return;
        },
        cardRowStyle: {
          'margin-top': '8px',
        },
      },
    ],
  };
  paginatedPromptData: any;
  selectedPagePrompts: any;
  page: number = 0;
  promptName: any;
  visibleOnHomePage: boolean = false;
  promptStatusOptions = [
    { key: 1, value: 'All' },
    { key: 2, value: 'Assigned' },
    { key: 3, value: 'Unassigned' },
  ];
  promptStatusSelected: number = 1;
  constructor(
    private userPromptService: UserPromptService,
    private dialogService: NbDialogService,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private pageService: PagesService
  ) {}

  ngOnInit(): void {
    this.getAllUserPrompts();
  }
  getAllUserPrompts() {
    let params: any = {};
    if (this.pageSizeUserPrompt) {
      params['rows'] = this.pageSizeUserPrompt;
    }
    if (this.visibleOnHomePage) {
      params['show_on_home'] = 1;
    }
    if (this.previousUserPrompt) {
      params['previous'] = this.previousUserPrompt;
    }
    if (this.promptStatusSelected == 2) {
      params['assigned'] = 1;
    } else if (this.promptStatusSelected == 3) {
      params['unassigned'] = 1;
    }

    this.userPromptService
      .getUserPromptList(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.userPromptList = response?.data;
          this.totalCountUserPrompt = response['total_size'];
          this.totalPagesUserPrompt = Math.ceil(
            this.totalCountUserPrompt / this.pageSizeUserPrompt
          );
        }
      });
  }
  getPromptDetail(promptData: any) {
    this.router.navigate([
      '/user-prompt',
      {
        uPKey: String(promptData?.id),
      },
    ]);
  }
  actionClick(promptData: any, copyTemplate?: any) {
    if (promptData?.type === 'delete') {
      this.deletePrompt(promptData?.event);
    } else {
      this.copyUserPromp(promptData?.event, copyTemplate);
    }
  }
  copyUserPromp(promptData: any, copyTemplate: any) {
    let dialogMsg = 'Copy User Prompt';
    this.promptName = promptData?.name;
    const dialogRef = this.dialogService.open(copyTemplate, {
      context: {
        title: 'Copy User Prompt?',
        message: dialogMsg,
        data: promptData,
      },
    });
    dialogRef.onClose.subscribe(async (dialogResponse) => {
      if (dialogResponse === 'Yes') {
        this.getUserPromptDetail(promptData?.id);
      }
    });
  }
  deletePrompt(promptData: any) {
    let dialogMsg = 'Delete User Prompt';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: 'Are you sure?',
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((dialogResponse) => {
      if (dialogResponse === 'Yes') {
        this.userPromptService
          .deleteUserPrompt(promptData?.id)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.getAllUserPrompts();
            }
          });
      }
    });
  }
  onChangePagination(eventData: any) {
    this.previousUserPrompt = eventData.previous;
    this.pageNumUserPrompt = eventData.pageNum;
    this.pageSizeUserPrompt = eventData.pageSize;
    this.getAllUserPrompts();
  }
  onConfirmCopy(userPrompId: any) {
    this.getUserPromptDetail(userPrompId);
  }
  getUserPromptDetail(userPromptId: any) {
    this.userPromptService
      .getUserPromptById(userPromptId)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.paginatedPromptData = [...response?.data?.prompts];
          this.selectedPagePrompts = this.paginatedPromptData[this.page];
          this.spinnerService.show();
          const body: any = {
            name: this.promptName,
            prompts: this.paginatedPromptData,
          };
          this.userPromptService
            .createUserPrompt(body)
            .subscribe((response: any) => {
              this.spinnerService.hide();
              if (response?.status === 'success') {
                this.pageService.setMessage({
                  successMessage: response?.message,
                  errorMessage: '',
                });
                this.getAllUserPrompts();
              } else {
                this.pageService.setMessage({
                  successMessage: '',
                  errorMessage: response?.message,
                });
              }
              this.spinnerService.hide();
            });
        }
      });
  }
}
