import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { DataCheckService } from 'src/app/shared/service/data-check.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  isSuperUser: any;
  constructor(
    private appService: AppService,
    private router: Router,
    private dataCheckService: DataCheckService
  ) {}

  ngOnInit(): void {
    this.isSuperUser = this.dataCheckService.isSuperUser();
  }
  goToHome() {
    let userData = this.appService.getUserData();
    this.isSuperUser = this.dataCheckService.isSuperUser();
    if (userData && userData.access) {
      if (this.isSuperUser === true) {
        this.router.navigate(['gtadmin/dashboard']);
      } else {
        this.router.navigate(['/dashboard']);
      }
    } else {
      this.router.navigate(['/login']);
    }
  }
}
