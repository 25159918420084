<div class="mobile-only gt-p-small">
  <!-- case where there are atleaset 5 recorrds-->
  <div *ngIf="totalResponseCount > searchRemovalCap">
    <nb-form-field class="sc-search-input-box">
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          nbInput
          fullWidth
          placeholder="Search Response Types"
          [(ngModel)]="globalFilter"
          (keyup)="onFilterApply($event)"
        />
        <label>Search Response Types</label>
      </div>
      <nb-icon
        nbSuffix
        *ngIf="showTypeAhead === true"
        [icon]="'search-outline'"
        pack="eva"
        (click)="onFilterApply(true)"
      ></nb-icon>
      <nb-icon
        nbSuffix
        *ngIf="showTypeAhead === false"
        [icon]="'close-outline'"
        pack="eva"
        (click)="onClearFilter()"
      ></nb-icon>
    </nb-form-field>
  </div>

  <div *ngIf="isAdmin === true">
    <button
      size="giant"
      class="status-button nbbutton-long-text"
      fullWidth
      (click)="openAddResponseForm()"
      nbButton
      hero
      status="primary"
    >
      + Add Response Type
    </button>
  </div>
</div>
<!-- DESKTOP VIEW -->
<div
  class="large-view-only gt-p-1"
  *ngIf="totalResponseCount > searchRemovalCap"
>
  <div class="flex-center">
    <nb-form-field class="sc-search-input-box">
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          nbInput
          fullWidth
          placeholder="Search Response Types"
          [(ngModel)]="globalFilter"
          (keyup)="onFilterApply($event)"
        />
        <label>Search Response Types</label>
      </div>
      <nb-icon
        class="ico-color"
        nbSuffix
        *ngIf="showTypeAhead === true"
        [icon]="'search-outline'"
        pack="eva"
        (click)="onFilterApply(true)"
      ></nb-icon>
      <nb-icon
        class="ico-color"
        nbSuffix
        *ngIf="showTypeAhead === false"
        [icon]="'close-outline'"
        pack="eva"
        (click)="onClearFilter()"
      ></nb-icon>
    </nb-form-field>
    <div class="sc-add-btn" *ngIf="isAdmin === true">
      <!-- Add Response Button -->
      <button
        title="Add Response"
        class="sc-mt-0"
        size="giant"
        nbButton
        hero
        status="primary"
        type="button"
        (click)="openAddResponseForm()"
      >
        + Add Response Type
      </button>
    </div>
  </div>
</div>

<div
  class="row"
  *ngIf="isAdmin === true && totalResponseCount <= searchRemovalCap"
>
  <!-- case where there are less than 5 records ; don't show search bar-->
  <div class="row" style="margin-bottom: 1rem">
    <!-- Add Response Button -->
    <div class="col-sm-6 col-lg-3 sc-pb-0 large-view-only">
      <button
        size="giant"
        class="status-button"
        fullWidth
        (click)="openAddResponseForm()"
        nbButton
        hero
        status="primary"
      >
        + Add Response Type
      </button>
    </div>
  </div>
</div>

<div *ngIf="responseCount > 0">
  <app-card-list
    [listCardData]="responsePaginationData[pageNum]"
    [listCardDetails]="responseCardDetils"
    [cardActionIcons]="cardActionIcons"
    (actionClick)="deleteResponse($event?.event)"
    (rowClick)="openEditResponseForm($event)"
  ></app-card-list>
</div>
<div *ngIf="responseCount < 1">
  <nb-alert outline="basic">No records found</nb-alert>
</div>

<!--Response pagination BEGINS-->
<div>
  <app-pagination-control
    [pageSize]="pageSize"
    [pageNum]="pageNum"
    [previous]="previous"
    [totalRows]="responseCount"
    [totalPages]="totalPages"
    (onClickPagination)="onClickPagination($event)"
    (onChangePagination)="onChangePagination($event)"
  >
  </app-pagination-control>
</div>
