<nb-card class="dialog-box gt-p-small">
  <nb-card-header>Change Account </nb-card-header>
  <nb-card-body>
    <div class="row row-cols-1 sc-text-center gy-2">
      <em>Select instance</em>
      <div
        *ngFor="let subscriber of userLinkedSubscribers"
        (click)="selectSubscriber(subscriber)"
        class="col"
      >
        <nb-card class="contact-card">
          <div class="table-card-data pointer">
            <div class="strong">
              {{ subscriber.name }}
            </div>
            <div class="small-font">
              {{ subscriber?.address?.full_address }}
            </div>
          </div>
        </nb-card>
      </div>
    </div>

    <div class="row sc-mt-9">
      <div class="col">
        <button nbButton size="giant" fullWidth (click)="onCloseDialogue()">
          Cancel
        </button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
