import { formatDate } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { DateTimePickerComponent } from '../date-time-picker/date-time-picker.component';
import { formatDateTimeStamp } from 'src/global.variables';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements OnInit, OnChanges {
  @Input() tableColumns: any;
  @Output() actionClick = new EventEmitter();
  @Output() rowClick = new EventEmitter();

  @Input() desktopTableData: any;
  @Input() desktopTableDetails: any;
  @Input() tableRowManager: any;
  @Input() tableStyle: any;
  @Input() otherParms: any;
  @Input() tableActionItems: any;
  @Input() apiFilter: any = false;
  @Output() searchColumn = new EventEmitter();
  @Output() sortColumn = new EventEmitter();
  @Output() clearFilter = new EventEmitter();
  @Input() showTableFrom: any;
  cardIndividualDetails: any;
  cardGroupDetails: any;
  openSearchBox: boolean = false;
  selectedIndex: any;
  dateRange: any;
  itemsList: any = [];
  desktopTableDataTemp: any;
  appliedFilters: any = [];
  appliedSorts: any = [];

  sortKeys: any;
  dialogeRef: any;
  constructor(private dialogService: NbDialogService) {}
  addDateRangeToList(event: any, column?: any) {
    let keyValue = this.desktopTableDetails?.filter(
      (item: any) => item?.datetimeObj == true
    )[0];

    let dateKey = column
      ? column?.header
      : keyValue
      ? keyValue?.header
      : keyValue?.name;
    this.appliedFilters = this.appliedFilters?.filter(
      (item: any) => item?.key != dateKey
    );
    this.appliedFilters.push({
      key: dateKey,
      value: `[${String(
        formatDateTimeStamp(event?.start, 'd MMM y HH:mm', 'en_US')
      )}] to [${String(
        formatDateTimeStamp(event?.end, 'd MMM y HH:mm', 'en_US')
      )}]`,
      date: event,
      datetimeObj: true,
      field: column
        ? column?.dateRangeKey
        : keyValue
        ? keyValue?.dateRangeKey
        : keyValue?.name,
    });
  }
  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    this.dateRange = this.otherParms?.dateRange;
    this.desktopTableDataTemp = this.desktopTableData;
    if (this.dateRange?.end) this.addDateRangeToList(this.dateRange);
    if (!this.apiFilter) {
      this.applyFilters();
    }
  }
  onActionClick(event: any, type: any) {
    this.actionClick.emit({ event, type });
  }

  onRowClick(rowData: any) {
    this.rowClick.emit(rowData);
  }

  getSearchParams() {
    let searchParams: any = {};
    let body: any = {};
    this.appliedFilters.forEach((element: any) => {
      if (element?.datetimeObj) {
        searchParams[
          `${element?.field ? element?.field : 'updated_at'}__range`
        ] = [element?.date?.start, element?.date?.end];
        body.date = element?.date;
      } else if (element?.column?.dropdownKey) {
        searchParams[element?.column?.dropdownKey] = element?.valueId;
      } else {
        searchParams[
          `${
            element?.column?.searchKey
              ? element?.column?.searchKey
              : element?.column?.name
          }__icontains`
        ] = element?.value;
      }
      body = { ...body, searchParams: searchParams };
    });
    return body;
  }

  sortField(column: any) {
    column['sortOrder'] =
      column?.sortOrder === 'asc'
        ? 'desc'
        : column?.sortOrder === 'desc'
        ? null
        : 'asc';

    this.desktopTableDetails.forEach((item: any) => {
      if (item?.name !== column?.name) {
        item.sortOrder = null;
      }
    });
    if (this.apiFilter) {
      let sortKeyValue: any = column?.sortKey
        ? typeof column?.sortKey === 'string'
          ? column?.sortKey
          : column?.name
        : column?.name;
      let sortKey: any =
        column?.sortOrder == 'desc'
          ? `-${sortKeyValue}`
          : column?.sortOrder == 'asc'
          ? sortKeyValue
          : '';
      let body = this.getSearchParams();

      body.sortColumn = column;
      if (sortKey) body.sortList = [sortKey];
      this.sortColumn.emit(body);
    } else {
      this.applyFilters();
      if (column?.sortOrder) {
        if (column?.datetimeObj) {
          this.desktopTableDataTemp =
            column?.sortOrder === 'desc'
              ? this.desktopTableDataTemp.sort(
                  (a: any, b: any) =>
                    Number(
                      new Date(
                        b?.[column?.sortKey ? column?.sortKey : column?.name]
                      )
                    ) -
                    Number(
                      new Date(
                        a?.[column?.sortKey ? column?.sortKey : column?.name]
                      )
                    )
                )
              : this.desktopTableDataTemp.sort(
                  (a: any, b: any) =>
                    Number(
                      new Date(
                        a?.[column?.sortKey ? column?.sortKey : column?.name]
                      )
                    ) -
                    Number(
                      new Date(
                        b?.[column?.sortKey ? column?.sortKey : column?.name]
                      )
                    )
                );
        } else {
          this.desktopTableDataTemp = this.sortArrayOfObject(
            this.desktopTableDataTemp,
            column?.sortKey ? column?.sortKey : column?.name,
            column?.sortOrder
          );
        }
      }
    }
  }
  searchField(column: any) {
    column.searched = true;
    let colKey = column?.header ? column?.header : column?.name;

    this.appliedFilters = this.appliedFilters?.filter(
      (item: any) => item?.key != colKey
    );
    this.appliedFilters.push({
      key: colKey,
      value: column?.searchValue,
      column: column,
    });
    this.applyFilters();

    column.searchValue = null;
    column.openSearchBox = false;
  }

  onTableRowClick(rowData: any, index: Number) {
    if (!this.tableActionItems) {
      this.selectedIndex = index;
    }
    this.rowClick.emit(rowData);
  }
  handleDateRange(event: any, column?: any) {
    if (event?.end) {
      this.dateRange = event;
      this.addDateRangeToList(this.dateRange, column);
      this.applyFilters();
      if (column) {
        column.openSearchRangeBox = false;
      }
    }
  }

  removeFilter(keyValue: any) {
    if (keyValue === 0) this.dateRange = null;
    this.appliedFilters = this.appliedFilters?.filter(
      (item: any) => item?.key !== keyValue
    );
    this.applyFilters();
  }
  applyFilters() {
    if (this.appliedFilters?.length) {
      if (this.apiFilter) {
        let body = this.getSearchParams();
        if (body) this.searchColumn.emit(body);
      } else {
        this.desktopTableDataTemp = this.desktopTableData;
        this.appliedFilters.forEach((element: any) => {
          if (element?.datetimeObj) {
            let elementField = element?.field ? element?.field : 'updated_at';
            this.desktopTableDataTemp = this.desktopTableDataTemp?.filter(
              (item: any) =>
                new Date(item?.[elementField]).getTime() >=
                  element?.date?.start.getTime() &&
                new Date(item?.[elementField]).getTime() <=
                  element?.date?.end.getTime()
            );
          } else {
            this.desktopTableDataTemp = this.desktopTableDataTemp?.filter(
              (item: any) =>
                item?.[element?.column?.name]
                  ?.toLowerCase()
                  .includes(element?.value)
            );
          }
        });
      }
    } else {
      if (this.apiFilter) {
        this.searchColumn.emit({});
      } else {
        this.desktopTableDataTemp = this.desktopTableData;
      }
    }
  }

  sortArrayOfObject(arr: any, key: any, sort_order = 'asc') {
    const order = sort_order.trim().toLowerCase();
    return arr.sort((a: any, b: any) => {
      if (a[key] === null) return order === 'asc' ? 1 : -1;
      if (b[key] === null) return order === 'asc' ? -1 : 1;
      if (a[key] > b[key]) {
        return order === 'asc' ? 1 : -1;
      } else if (a[key] < b[key]) {
        return order === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }
  getTableRowStyle(data: any, i: number) {
    return i === this.selectedIndex
      ? {
          'background-color': 'var(--background-alternative-color-4)',
          'color': 'var(--text-alternate-color)',
          'cursor': 'pointer',
        }
      : {
          cursor: 'pointer',
          ...(this.otherParms?.tableRowStyle
            ? this.otherParms?.tableRowStyle(data)
            : {}),
        };
  }
  selectStatus(event: any, column: any) {
    this.appliedFilters = this.appliedFilters?.filter(
      (item: any) => item?.key != column?.name
    );

    this.appliedFilters.push({
      key: column?.name,
      value: event?.name,
      valueId: event?.id,
      column: column,
    });
    this.applyFilters();

    column.opendropDownBox = false;
  }
  openRangePicker(column: any) {
    this.dialogeRef = this.dialogService.open(DateTimePickerComponent, {
      context: {
        dateRange: this.dateRange,
        showFrom: 'tablePage',
      },
    });
    this.dialogeRef.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.handleDateRange(value, column);
      }
    });
  }
}
