<nb-card class="gt-p-1">
  <nb-tabset>
    <nb-tab tabTitle="App Users">
      <div>
        <button nbButton size="giant" status="primary" (click)="filterUsers()">
          {{ appUserFilterBtnDict[appUserFilter] }}
        </button>
      </div>

      <app-gt-table
        [desktopTableUIData]="appUserData"
        [desktopTableDetails]="appUserTableDetail"
        [otherParams]="{ paginationData: true }"
        (rowClick)="redirectUserHistory($event)"
      >
      </app-gt-table>
    </nb-tab>
    <nb-tab tabTitle="Locked Users">
      <app-gt-table
        [desktopTableUIData]="lockedUserData"
        [desktopTableDetails]="lockedUserTableDetail"
        (rowClick)="unlockUser($event)"
        [otherParams]="{ paginationData: true }"
      >
      </app-gt-table>
    </nb-tab>
    <nb-tab tabTitle="Push Notification">
      <nb-card-header>
        <nb-form-field class="sc-search-input-box">
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              nbInput
              #customerAutoInput
              fullWidth
              placeholder="Filter Users"
              [(ngModel)]="globalFilter"
              (keyup)="onFilterApply($event)"
            />
            <label>Filter Users</label>
          </div>
          <nb-icon
            nbSuffix
            *ngIf="showTypeAhead === true"
            [icon]="'search-outline'"
            pack="eva"
            (click)="onFilterApply(true)"
          ></nb-icon>
          <nb-icon
            nbSuffix
            *ngIf="showTypeAhead === false"
            [icon]="'close-outline'"
            class="sc-floating-right"
            pack="eva"
            (click)="onClearFilter()"
          ></nb-icon>
        </nb-form-field>
      </nb-card-header>
      <div class="sc-floating-right my-2">
        <button
          nbButton
          status="primary"
          size="large"
          (click)="sendNotification({}, notifTemplate)"
        >
          Send Notifcation
        </button>
      </div>

      <div>
        <app-table
          [desktopTableData]="pushData?.results"
          [desktopTableDetails]="pushNotificationTable.columns"
          (rowClick)="sendNotification($event, notifTemplate)"
        >
        </app-table>
      </div>

      <!-- Pagination section begin -->
      <div class="sc-mt-6">
        <app-pagination-control
          [pageSize]="rows"
          [pageNum]="pageNum"
          [previous]="previous"
          [totalRows]="totalRows"
          [totalPages]="totalPages"
          (onClickPagination)="onClickPagination($event)"
          (onChangePagination)="onChangePagination($event)"
        >
        </app-pagination-control>
      </div>
    </nb-tab>
  </nb-tabset>
</nb-card>

<ng-template #notifTemplate let-data let-notifRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header>
      Send Notification to
      {{
        sendNotifData?.user
          ? sendNotifData?.user?.first_name +
            " " +
            sendNotifData?.user?.last_name
          : "All"
      }}
    </nb-card-header>
    <nb-card-body>
      <div class="form-floating">
        <input
          class="form-control"
          fullWidth
          nbInput
          type="text"
          [(ngModel)]="title"
        />
        <label for="inputService">Notification Title</label>
      </div>
      <div class="form-floating">
        <textarea
          class="form-control"
          nbInput
          fullWidth
          placeholder="Notification Message"
          autocomplete="off"
          [(ngModel)]="message"
        ></textarea>

        <label for="inputService">Notification Message</label>
      </div>
      <div class="sc-mt-6" *ngIf="sendNotifData?.user">
        <nb-checkbox (checkedChange)="togglePrimary($event)">
          Send To All Devices
        </nb-checkbox>
      </div>

      <div class="row row-cols-2">
        <div class="col">
          <button
            fullWidth
            (click)="
              notifRef.close(); message = null; title = null; allDevices = false
            "
            nbButton
            size="giant"
            ghost
          >
            Close
          </button>
        </div>
        <div class="col">
          <button
            fullWidth
            nbButton
            size="giant"
            status="success"
            (click)="sendMessage(); notifRef.close()"
          >
            Send
          </button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
