import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  TemplateRef,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { gTDB } from 'src/app/db';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { QrCodeComponent } from 'src/app/shared/comp/qr-code/qr-code.component';
import { SchedulerComponent } from 'src/app/shared/comp/scheduler/scheduler.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import {
  browserGpsInfo,
  checkScanDistanceGreater,
  convertTime12to24,
  convertTime24to12,
  getFormattedRepeatDetail,
} from 'src/global.variables';
import { CheckpointService } from '../../checkpoints/checkpoint.service';
import { PagesService } from '../../pages.service';
import { RosterScheduleService } from '../../roster-schedule/roster-schedule.service';
import { AddEditUsersComponent } from '../../users/add-edit-users/add-edit-users.component';
import { JobsService } from '../jobs.service';

@Component({
  selector: 'app-patrol-detail-events',
  templateUrl: './patrol-detail-events.component.html',
  styleUrls: ['./patrol-detail-events.component.scss'],
})
export class PatrolDetailEventsComponent implements OnInit {
  routeId: any;
  detailData: any;
  formattedRepeatDetails: string = '';

  // change schedule component
  validStartDate = new Date();

  startDate: any;
  endDate: any;
  startTime: any;
  startDateAndTime: any;
  endTime: any;
  endDateAndTime: any;
  bottomSectionFixedHeight: string = '130px';

  errorMessage: any;
  showStartDetail: boolean = true;
  updateSchedulerForm: UntypedFormGroup = new UntypedFormGroup({
    end_time: new FormControl(null),
    start_time: new FormControl(null),
    start_day: new FormControl(new Date()),
    end_day: new FormControl(null, Validators.required),
    repeat_times: new UntypedFormControl(null),
    repeat_type: new UntypedFormControl(null, Validators.required),
    repeat_details: new UntypedFormControl([]),
    repeat_interval: new UntypedFormControl(1, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    position: new UntypedFormControl(null, []),
    week_day: new UntypedFormControl(null, []),
    month_repeat_case: new UntypedFormControl(null, []),
  });
  dialogRef: any;

  patrolRouteCpRows: number = 10;
  patrolRouteCpPrevious: number = 0;
  patrolRouteCpPageNum: number = 1;
  patrolRouteCpTotalPages: number = 0;

  patrolRouteCPList: any = [];
  patrolRouteCpTotalCount: number = 0;
  checkpointSearchList: any = [];
  selectedCheckpoints: any = [];
  assigneeList: any = [];
  selectedAssignees: any = [];

  assigneeSearchList: any = [];
  scheduleStepperActive: boolean = false;
  showMap: boolean = false;
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;
  canScheduleBeUpdated: boolean = false;
  showWelfareItem: boolean = true;
  hasScheduleStarted: boolean = false;
  schedulerForm: UntypedFormGroup = new UntypedFormGroup({
    end_time: new FormControl(null),
    start_time: new FormControl(null),
    start_day: new FormControl(new Date()),
    end_day: new FormControl(null, Validators.required),
    repeat_times: new UntypedFormControl(null),
    repeat_type: new UntypedFormControl(null, Validators.required),
    repeat_details: new UntypedFormControl([]),
    repeat_interval: new UntypedFormControl(1, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    position: new UntypedFormControl(null, []),
    week_day: new UntypedFormControl(null, []),
    month_repeat_case: new UntypedFormControl(null, []),
  });

  scheduleDuration: any;
  intervalData: any;
  newPatrolName: any;
  selectedInterval: any;

  scheduleCheck: boolean = true;
  updateTask: any;
  patrolUpdateLog: any = [];
  patrolLogUpdates: any = [];
  patrolLogTableDetail: any = [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      sortKey: 'updated_at',
      dateRangeKey: 'updated_at',
    },
    {
      header: 'Update By',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },

    {
      header: 'Event',
      name: 'action',
      sortKey: 'action',
      searchKey: 'action',
    },
    {
      header: 'Update',
      name: 'detail',
      sortKey: 'detail',
      searchKey: 'detail',
    },
    {
      header: 'Client',
      name: 'company_name',
      sortKey: 'company_name',
      searchKey: 'company_name',
    },
  ];
  subscriberUserId: any;
  offlineMode: boolean = false;
  newComment: any;
  previousComment: any;
  fullImgSrc: any;

  filterPatrolData: any = { checkpoint: [], company: [] };
  selectedEvent: any;
  scanComplete: boolean = false;
  messageChannel = new MessageChannel();
  apidataUpdation: boolean = false;

  missedCheckpoints: any = [];

  hideSpinner: boolean = true;
  newInfromation: any;
  uploadType: string = 'live';
  hasQrCheckpoint: boolean = false;

  indexedDbListData: any = [];

  patrolTime = { startTime: new Date(), endTime: new Date() };

  patrolUpdatesBackup: any = [];
  scanDistanceInfo: any = {};
  browserGpsInfo: any = browserGpsInfo;
  scannedCheckpoints: any = {};
  largeView: Boolean = window.innerWidth >= 770;
  patrolRouteStatusKey: number = 0;

  patrolRouteStatus: any = {
    1: {
      value: 'Complete',
      style: {
        'color': 'var(--color-basic-100)',
        'background-color': 'var(--color-basic-1100)',
        'border-color': 'var(--color-basic-1100)',
        'border': '1px solid var(--color-basic-1100)',
        'border-radius': '5px',
        'padding': '0.5rem',
        'max-width': 'fit-content',
        'margin-left': '0.5rem',
        'margin-bottom': ' 0.8rem',
        'font-weight': 700,
      },
    },
    2: {
      value: 'Completed with missed checkpoint scans',
      style: {
        'color': 'var(--color-basic-100)',
        'background-color': 'var(--color-basic-1100)',
        'border-color': 'var(--color-basic-1100)',
        'border': '1px solid var(--color-basic-1100)',
        'border-radius': '5px',
        'padding': '0.5rem',
        'max-width': 'fit-content',
        'margin-left': '0.5rem',
        'margin-bottom': ' 0.8rem',
        'font-weight': 700,
      },
    },
    3: {
      value: 'Started',
      style: {
        'color': `var(--color-basic-100)`,
        'background-color': `var(--color-success-500)`,
        'border': `1px solid var(--color-success-500)`,
        'border-radius': '5px',
        'padding': '0.5rem',
        'max-width': 'fit-content',
        'margin-left': '0.5rem',
        'margin-bottom': ' 0.8rem',
        'font-weight': 700,
      },
    },
    4: {
      value: 'Started - Assigned Guard Not Clocked In',
      style: {
        'color': `var(--color-basic-100)`,
        'background-color': `var(--color-danger-500)`,
        'border': `1px solid var(--color-danger-500)`,
        'border-radius': '5px',
        'padding': '0.5rem',
        'max-width': 'fit-content',
        'margin-left': '0.5rem',
        'margin-bottom': ' 0.8rem',
        'font-weight': 700,
      },
    },
    5: {
      // Future Days
      value: 'Not Started',
      style: {
        'color': `var(--color-basic-100)`,
        'background-color': `var(--color-warning-500)`,
        'border': `1px solid var(--color-warning-500)`,
        'border-radius': '5px',
        'padding': '0.5rem',
        'max-width': 'fit-content',
        'margin-left': '0.5rem',
        'margin-bottom': ' 0.8rem',
        'font-weight': 700,
      },
    },
    6: {
      // Today
      value: 'Not Started',
      style: {
        'color': `var(--color-basic-100)`,
        'background-color': `var(--color-danger-500)`,
        'border': `1px solid var(--color-danger-500)`,
        'border-radius': '5px',
        'padding': '0.5rem',
        'max-width': 'fit-content',
        'margin-left': '0.5rem',
        'margin-bottom': ' 0.8rem',
        'font-weight': 700,
      },
    },
  };
  constructor(
    private checkpointService: CheckpointService,
    private spinnerService: NgxSpinnerService,
    private pageService: PagesService,
    private jobService: JobsService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private appService: AppService,
    private dataCheckService: DataCheckService,
    private rsService: RosterScheduleService,
    private ngZone: NgZone,
    private swUpdate: SwUpdate,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });
    this.route.params.subscribe({
      next: (params) => {
        if (params?.['rKey']) {
          this.routeId = params?.['rKey'];
          this.spinnerService.show();
          this.getPatrolRouteDetail();
        }
      },
    });
  }
  updateOnlineStatus() {
    // case where user is not doing update, which implies we can update the ui from offline to online or vice versa
    let offlineReloadCheck = this.offlineMode;
    this.offlineMode = !navigator?.onLine;

    if (this.offlineMode == false && offlineReloadCheck) {
      this.pageService.sWOperations.next({ syncData: true });
    }
  }
  offlineChange() {
    window.addEventListener('online', this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.offlineChange.bind(this));
    this.offlineMode = !navigator?.onLine;
  }
  getPromptValue(promptData: any) {
    return Array.isArray(promptData?.value)
      ? promptData?.value?.join(', ')
      : promptData?.value;
  }
  canEditComment(eventData: any) {
    return (
      this.subscriberUserId == eventData?.subs_user_id &&
      eventData?.company_id &&
      new Date().getTime() - new Date(eventData?.updated_at).getTime() <
        72 * 3600 * 1000
    );
  }
  ngOnInit(): void {
    this.subscriberUserId =
      this.appService.getUserData()?.subscriber?.subscriber_user_id;
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.offlineMode = !navigator?.onLine;
    navigator?.serviceWorker?.controller?.postMessage(
      {
        type: 'INIT_PATROL_DETAIL_PORT',
      },
      [this.messageChannel.port2]
    );
    // // Listen to the response
    this.messageChannel.port1.onmessage = (event) => {
      if (event?.data?.data) {
        this.ngZone.run(() => {
          setTimeout(() => {
            this.detailData = event?.data?.data;
            this.formatHistory(event?.data);
            this.formatExistingData();

            setTimeout(() => {
              this.formatCachedList();
            }, 1000);
            this.apidataUpdation = false;
          }, 100);
        });
      }
      if (event?.data?.history) {
        this.formatHistory(event?.data);

        setTimeout(() => {
          this.formatCachedList();
        }, 1000);
      }
      if (event?.data?.message) {
        if (event?.data?.status == 'success') {
          this.pageService.setMessage({
            successMessage: this.offlineMode
              ? 'Action Queued'
              : event?.data?.message,
            errorMessage: '',
          });
        } else {
          this.pageService.setMessage({
            errorMessage: event?.data?.message,
            successMessage: '',
          });
        }
      }
    };
  }
  formatDateWithoutTime = (date: any) => {
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };
  getTimeDifference() {
    let startTime = this.detailData?.schedule_event_date
      ? new Date(this.detailData?.schedule_event_date)
      : new Date();
    let endTime = this.detailData?.schedule_event_date
      ? new Date(this.detailData?.schedule_event_date)
      : new Date();

    if (this.detailData?.roster_scheduler?.repeat_type == 'once') {
      startTime = new Date(
        this.detailData?.roster_scheduler?.start_day +
          ' ' +
          this.detailData?.roster_scheduler?.start_time
      );
      endTime = new Date(
        this.detailData?.roster_scheduler?.end_day +
          ' ' +
          this.detailData?.roster_scheduler?.end_time
      );
    } else {
      if (
        this.detailData?.roster_scheduler?.start_time >
        this.detailData?.roster_scheduler?.end_time
      ) {
        endTime.setDate(startTime.getDate() + 1);
      }
      this.setTime(startTime, this.detailData?.roster_scheduler?.start_time);
      this.setTime(endTime, this.detailData?.roster_scheduler?.end_time);
    }

    this.patrolTime = { startTime: startTime, endTime: endTime };

    let startTimeCopy = new Date(startTime.getTime());
    let endTimeCopy = new Date(endTime.getTime());
    this.scheduleDuration = this.getTimeDiff(startTimeCopy, endTimeCopy);
    this.scheduleCheckFn(startTimeCopy, endTimeCopy);
  }
  onLoadMore() {
    this.patrolRouteCpPrevious =
      this.patrolRouteCpPrevious + this.patrolRouteCpRows;

    this.getPatrolRouteCheckpoints();
  }
  pad(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
  getTimeDiff(startTime: any, endTime: any) {
    let diffInTime = endTime?.getTime() - startTime?.getTime();

    if (!diffInTime) return '00:00';
    let seconds = Math.floor(diffInTime / 1000);
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    this.intervalData = [
      { name: 'No Welfare Checks', value: 0 },
      { name: 'Welfare Checks Every 5 mins', value: 5 },
      { name: 'Welfare Checks Every 15 mins', value: 15 },
      { name: 'Welfare Checks Every 30 mins', value: 30 },
    ];
    if (hours > 1) {
      this.intervalData.push({
        name: 'Welfare Checks Every 1 Hour',
        value: 60,
      });
    }
    if (hours > 2) {
      this.intervalData.push({
        name: 'Welfare Checks Every 2 Hours',
        value: 120,
      });
    }
    return `${this.pad(hours)}:${this.pad(minutes)}`;
  }
  setTime(date: Date, time: string) {
    const [hours, minutes] = time?.split(':');
    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes));
  }
  sheduleIfChecks() {
    const now = new Date();

    const startTime = new Date(
      this.detailData.roster_scheduler.start_day +
        ' ' +
        this.detailData.roster_scheduler.start_time
    );
    const endTime = new Date(
      this.detailData.roster_scheduler.end_day +
        ' ' +
        this.detailData.roster_scheduler.end_time
    );

    const canUpdateSchedule =
      endTime.getTime() > now.getTime() &&
      (!this.detailData?.schedule_event_date ||
        this.formatDateWithoutTime(
          new Date(this.detailData?.schedule_event_date)
        ) >= this.formatDateWithoutTime(now));

    if (!(this.isAdmin || this.isDispatchUser)) {
      this.canScheduleBeUpdated = false;
      this.showWelfareItem = canUpdateSchedule;

      return;
    }

    this.canScheduleBeUpdated = canUpdateSchedule;

    this.showWelfareItem = this.canScheduleBeUpdated;

    this.hasScheduleStarted = startTime.getTime() < new Date().getTime();
  }
  scheduleCheckFn(startDatetime: any, endDateTime: any) {
    this.scheduleCheck =
      !this.detailData?.roster_scheduler?.repeat_type ||
      this.isAdmin ||
      this.isDispatchUser;
    if (!this.scheduleCheck) {
      const today = this.formatDateWithoutTime(new Date());

      this.ngZone.run(() => {
        this.scheduleCheck =
          this.formatDateWithoutTime(startDatetime) <= today &&
          this.formatDateWithoutTime(endDateTime) >= today;
      });
    }
  }

  getPatrolRouteDetail() {
    this.pageService.isOnEvent.next(true);
    this.rsService
      .getPatrolRoute({
        patrol_route_id: this.routeId,
      })
      .subscribe((response: any) => {
        // this.formatData(response);
        if (response?.status === 'success') {
          this.detailData = response?.data;

          this.formatHistory(response);

          this.formatExistingData();
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }
  openUserDialogBox(user: any) {
    const dialogRefUser = this.dialogService.open(AddEditUsersComponent, {
      context: {
        editUser: true,
        detailData: user,
      },
    });
    dialogRefUser.onClose.subscribe((value: any) => {});
  }
  formatExistingData() {
    //check whether offline and localhost, if so change apidataUpdation = false
    if (!this.swUpdate.isEnabled || this.offlineMode) {
      this.apidataUpdation = false;
    }
    this.spinnerService.hide();
    this.dialogRef?.close();

    this.selectedCheckpoints = JSON.parse(
      JSON.stringify(this.detailData?.check_points)
    );
    this.selectedAssignees = JSON.parse(
      JSON.stringify(this.detailData?.subscriber_users)
    );
    this.filterPatrolData = { checkpoint: [], company: [] };
    this.hasQrCheckpoint = false;
    this.detailData?.check_points?.forEach((checkpoint: any) => {
      if (!this.filterPatrolData.checkpoint.includes(checkpoint?.id)) {
        this.filterPatrolData.checkpoint.push(checkpoint?.id);
      }
      if (!this.filterPatrolData.company.includes(checkpoint?.company_id)) {
        this.filterPatrolData.company.push(checkpoint?.company_id);
      }
      if (checkpoint?.qr_code) this.hasQrCheckpoint = true;
    });
    let startDay = this.detailData?.schedule_event_date
      ? new Date(this.detailData?.schedule_event_date)
      : this.detailData?.roster_scheduler?.start_day
      ? new Date(this.detailData?.roster_scheduler?.start_day)
      : new Date();
    startDay.setDate(startDay.getDate() - 1);
    this.validStartDate = startDay;
    this.getTimeDifference();
    this.sheduleIfChecks();
    this.updateSchedulerForm.patchValue(this.detailData?.roster_scheduler);

    this.startDate = new Date(this.updateSchedulerForm.value.start_day);
    this.endDate = new Date(this.updateSchedulerForm.value.end_day);
    this.startTime = convertTime24to12(
      this.updateSchedulerForm.value.start_time
    );
    var [hours, minutes] = this.startTime.split(':');
    this.startDateAndTime = new Date(this.startDate);
    this.startDateAndTime.setHours(parseInt(hours));
    this.startDateAndTime.setMinutes(parseInt(minutes));
    this.endTime = convertTime24to12(this.updateSchedulerForm.value.end_time);
    this.showStartDetail = this.startDate > new Date();
    this.formattedRepeatDetails = getFormattedRepeatDetail(
      this.detailData?.roster_scheduler?.repeat_type,
      this.detailData?.roster_scheduler?.repeat_details
    );
    this.selectedInterval = this.detailData?.interval_check
      ? this.detailData?.interval_check
      : this.intervalData[0].value;
    this.findScannedCheckpoints();
    this.calculateFixedBottomHeight();
  }

  openTemplate(template: TemplateRef<any>, updateItem: string) {
    this.patrolRouteCPList = [];
    this.assigneeList = [];
    if (updateItem === 'checkpoint') {
      this.selectedCheckpoints = JSON.parse(
        JSON.stringify(this.detailData?.check_points)
      );
      this.patrolRouteCPList = [];
      this.patrolRouteCpRows = 10;
      this.patrolRouteCpPrevious = 0;
      this.patrolRouteCpPageNum = 1;
      this.patrolRouteCpTotalPages = 0;
      this.getPatrolRouteCheckpoints();
    } else if (updateItem === 'assignee') {
      this.getPatrolRouteAssignees();
    } else if (updateItem === 'name') {
      this.newPatrolName = this.detailData?.name;
    }
    this.dialogRef = this.dialogService.open(template, {
      context: {},
    });
  }
  openAssigneeDetail(assignee: any) {
    if (assignee.id) {
      const dialogRefUser = this.dialogService.open(AddEditUsersComponent, {
        context: {
          editUser: true,
          detailData: assignee,
        },
      });
      dialogRefUser.onClose.subscribe((value: any) => {
        if (value === 'Yes') {
        }
      });
    }
  }

  getPatrolRouteCheckpoints(event?: any) {
    let params: any = {};
    if (this.patrolRouteCpRows) {
      params['rows'] = this.patrolRouteCpRows;
    }
    if (this.patrolRouteCpPrevious) {
      params['previous'] = this.patrolRouteCpPrevious;
    }

    if (event?.target?.value) {
      params['name'] = event.target.value;
    }
    this.spinnerService.show();
    this.checkpointService.getCheckpoints(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.patrolRouteCPList.push(...response['data']);
        this.patrolRouteCPList = this.patrolRouteCPList?.filter(
          (x: any, index: any, y: any) =>
            y?.findIndex((t: any) => t?.id === x?.id) === index
        );

        this.patrolRouteCpTotalCount = response['total_size'];
        this.patrolRouteCpTotalPages = Math.ceil(
          this.patrolRouteCpTotalCount / this.patrolRouteCpRows
        );
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
      this.spinnerService.hide();
    });
  }
  removeSelectedCP(selectedIndex: number) {
    this.showMap = false;
    this.selectedCheckpoints = this.selectedCheckpoints?.filter(
      (checkpoint: any, index: number) => index !== selectedIndex
    );
  }
  addSelectedCP(addCheckpoint: any) {
    this.showMap = false;
    this.selectedCheckpoints.push(addCheckpoint);
  }

  searchCheckPoints(event?: any) {
    if (event.target.value?.length > 2) {
      let params: any = { name: event.target.value };

      this.checkpointService
        .getCheckpoints(params)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.patrolRouteCPList = response?.data;
            this.patrolRouteCpTotalCount = response['total_size'];
            this.patrolRouteCpTotalPages = Math.ceil(
              this.patrolRouteCpTotalCount / this.patrolRouteCpRows
            );
          } else {
            this.pageService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
        });
    } else if (!event.target.value?.length) {
      this.patrolRouteCPList = [];
      this.patrolRouteCpRows = 10;
      this.patrolRouteCpPrevious = 0;
      this.patrolRouteCpPageNum = 1;
      this.patrolRouteCpTotalPages = 0;
      this.getPatrolRouteCheckpoints();
    }
  }
  getPatrolRouteAssignees(event?: any) {
    this.spinnerService.show();
    this.jobService.getAssignees({}).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.assigneeList = response['data'];

        this.makeAssigneeSelectionChanges();
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
      this.spinnerService.hide();
    });
  }
  removeSelectedAssignee(deleteAssignee: any) {
    this.selectedAssignees = this.selectedAssignees?.filter(
      (assignee: any) => assignee?.id !== deleteAssignee?.id
    );
    this.makeAssigneeSelectionChanges();
  }
  addSelectedAssignee(addAssignee: any) {
    if (
      this.selectedAssignees?.some(
        (assignee: any) => assignee?.id === addAssignee?.id
      )
    ) {
      this.pageService.setMessage({
        errorMessage: 'Assignee Already Selected',
        successMessage: '',
      });
    } else {
      this.selectedAssignees.push(addAssignee);
    }

    this.makeAssigneeSelectionChanges();
  }
  addRemovessignee(event: any, assignee: any) {
    if (event) {
      this.addSelectedAssignee(assignee);
    } else {
      this.removeSelectedAssignee(assignee);
    }
  }
  makeAssigneeSelectionChanges() {
    this.assigneeList.forEach((assignee: any) => {
      assignee.selected = this.selectedAssignees?.some(
        (selectedAssignee: any) => selectedAssignee?.id === assignee?.id
      );
    });
  }

  searchAssignee(event?: any) {
    if (event.target.value?.length > 2) {
      let params: any = { full_name: event.target.value };
      this.jobService.getAssignees(params).subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.assigneeSearchList = response?.data?.filter(
            (value1: any) =>
              !this.selectedAssignees?.some(
                (value2: any) => value1?.id === value2?.id
              )
          );
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
    }
  }
  checkpointUpdateDisabling(): boolean {
    if (!this.selectedCheckpoints?.length) {
      return true;
    }

    const selectedCheckpoints = this.selectedCheckpoints || [];
    const checkPoints = this.detailData?.check_points || [];

    return (
      selectedCheckpoints.length === 0 ||
      (selectedCheckpoints.length === checkPoints.length &&
        selectedCheckpoints.every(
          (obj1: any, index: number) => obj1.id === checkPoints[index]?.id
        ))
    );
  }
  assigneeUpdateDisabling() {
    if (!this.selectedAssignees?.length) {
      return true;
    }

    if (
      this.selectedAssignees?.length ===
        this.detailData?.subscriber_users?.length &&
      this.selectedAssignees?.every((obj1: any) =>
        this.detailData?.subscriber_users?.some(
          (obj2: any) => obj1?.id === obj2?.id
        )
      )
    ) {
      return true;
    }

    return false;
  }
  updatePatrolRoute(updateType: string) {
    this.spinnerService.show();

    let updateData: any = { patrol_route_id: this.routeId };
    let reqParams: any = {};

    switch (updateType) {
      case 'checkpoint':
        updateData.checkpoints = this.selectedCheckpoints?.map(
          (checkpoint: any) => ({
            id: checkpoint?.id,
          })
        );

        reqParams.update_checkpoints = 1;
        break;

      case 'assignee':
        updateData.assignees = [
          ...new Set(
            this.selectedAssignees?.map((assignee: any) => assignee?.id)
          ),
        ];
        reqParams.update_assignees = 1;
        break;

      case 'name':
        updateData.name = this.newPatrolName;
        reqParams.update_name = 1;
        break;
      case 'welfare_interval':
        updateData.interval_check = this.selectedInterval;
        reqParams.update_interval_check = 1;
        break;
      case 'patrol_brief':
        updateData.patrol_brief = this.newInfromation;
        reqParams.update_patrol_brief = 1;
        break;
    }

    this.rsService
      .managePatrolRoute(updateData, reqParams)
      .subscribe((response: any) => {
        if (response?.status === 'success') {
          this.detailData = response?.data;

          this.formatHistory(response);
          this.formatExistingData();
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }
  shiftClick(elementID: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementID);
      element?.click();
      element?.focus();
    }, 100);
  }
  getEndTime(startDateTime?: any) {
    let date = new Date();
    if (startDateTime) {
      date = new Date(startDateTime);
    }

    let hours = date.getHours() + 1;

    if (hours > 23) {
      let nextDay = new Date(this.startDateAndTime);
      nextDay.setDate(nextDay.getDate() + 1);

      this.endDate = nextDay;
      this.updateSchedulerForm.controls['end_day'].setValue(nextDay);
      hours = 0;
    }
    let minutes = date.getMinutes();

    // Pad single digit minutes with a leading zero
    let minutesString = minutes < 10 ? '0' + minutes : '' + minutes;

    return `${hours}:${minutesString}`;
  }

  formateEndDate(event: any) {
    this.endDate = event;
    this.endDateAndTime = event;
    this.updateSchedulerForm.controls['end_day'].setValue(this.endDate);
    // if (!this.showRecurringView) {
    //   this.shiftClick('endTime');
    // }
  }

  formatEndTime(event: any) {
    this.endTime = convertTime12to24(event);
    var [hours, minutes] = this.endTime.split(':');
    this.endDateAndTime = new Date(this.endDate);
    this.endDateAndTime.setHours(parseInt(hours));
    this.endDateAndTime.setMinutes(parseInt(minutes));
    this.updateSchedulerForm.controls['end_time'].setValue(this.endTime);
    if (this.startDateAndTime.getTime() >= this.endDateAndTime.getTime()) {
      this.errorMessage = 'Invalid end date and time';

      setTimeout(() => {
        this.endTime = null;
        this.updateSchedulerForm.controls['end_time'].setValue(null);
      });
    }
  }

  deletePatrolRoute() {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: 'Are you sure?',
        message: 'This will remove all the route and schedule information!',
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.spinnerService.show();
        this.rsService
          .managePatrolRoute(
            {
              patrol_route_id: this.routeId,
            },
            { delete_schedule: 1 }
          )
          .subscribe((response: any) => {
            // this.formatData(response);
            if (response?.status === 'success') {
              this.router.navigate(['/roster']);
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }

            this.spinnerService.hide();
          });
      }
    });
  }
  openScheduleTemplate(template: TemplateRef<any>) {
    if (this.isAdmin === true || this.isDispatchUser === true) {
      if (
        (this.detailData?.roster_scheduler?.repeat_type === 'once' &&
          this.hasScheduleStarted) ||
        (this.hasScheduleStarted &&
          this.formatDateWithoutTime(
            new Date(this.detailData?.schedule_event_date)
          ) == this.formatDateWithoutTime(new Date()))
      ) {
        if (this.detailData?.roster_scheduler?.repeat_type !== 'once') {
          this.updateSchedulerForm.controls['start_day'].setValue(
            this.detailData?.schedule_event_date
              ? new Date(this.detailData?.schedule_event_date)
              : this.startDate
          );
        }
        this.updateSchedulerForm.controls['end_day'].setValue(
          this.detailData?.schedule_event_date
            ? new Date(this.detailData?.schedule_event_date)
            : this.endDate
        );
        this.endDate = this.detailData?.schedule_event_date
          ? new Date(this.detailData?.schedule_event_date)
          : this.endDate;
        this.updateSchedulerForm.controls['repeat_type'].setValue('once');
        this.dialogRef = this.dialogService.open(template);
      } else {
        this.schedulerForm.patchValue(this.detailData?.roster_scheduler);
        // this.schedulerForm.controls['start_day'].setValue(new Date());
        this.dialogRef = this.dialogService.open(SchedulerComponent, {
          context: {
            schedulerForm: this.schedulerForm,
            editSchedule: true,
            repeatAllowed:
              this.detailData?.roster_scheduler?.repeat_type != 'once',
          },
        });
        this.dialogRef.onClose.subscribe((value: any) => {
          if (value) {
            const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
              context: {
                title: 'Are you sure?',
              },
            });
            dialogRef.onClose.subscribe((dialogResponse) => {
              if (dialogResponse === 'Yes') {
                this.updateSchedule();
              }
            });

            this.schedulerForm.patchValue(value);
          }
        });
      }
    }
  }
  formatScheduleForm(form: any) {
    form.value.start_time = convertTime12to24(form.value.start_time);
    form.value.end_time = convertTime12to24(form.value.end_time);
    form.value.start_day =
      form.value.start_day == ''
        ? ''
        : formatDate(form.value.start_day, 'yyyy-MM-dd', 'en');
    form.value.end_day =
      form.value.end_day == null
        ? null
        : formatDate(form.value.end_day, 'yyyy-MM-dd', 'en');
    let timezoneOffset = new Date();
    return {
      time_offset: timezoneOffset.getTimezoneOffset(),
      ...form.value,
    };
  }

  getUpdateScheduleParams() {
    let params: any = {};

    let paramKey: any = this.hasScheduleStarted
      ? 'started_schedule'
      : 'future_schedule';
    if (
      this.formatDateWithoutTime(
        new Date(this.detailData?.schedule_event_date)
      ) == this.formatDateWithoutTime(new Date()) &&
      this.hasScheduleStarted &&
      this.detailData?.roster_scheduler?.repeat_type !== 'once'
    ) {
      paramKey = 'started_job';
    }
    params[paramKey] = 1;
    return params;
  }
  updateSchedule() {
    this.spinnerService.show();

    let data: any = {
      patrol_route_id: this.detailData?.id,
      time_offset: new Date().getTimezoneOffset(),
      ...((this.detailData?.roster_scheduler?.repeat_type === 'once' &&
        this.hasScheduleStarted) ||
      (this.hasScheduleStarted &&
        this.formatDateWithoutTime(
          new Date(this.detailData?.schedule_event_date)
        ) == this.formatDateWithoutTime(new Date()))
        ? this.formatScheduleForm(this.updateSchedulerForm)
        : this.schedulerForm.value),
    };

    this.rsService
      .updateRosterSchedule(
        this.detailData?.roster_scheduler?.id,
        data,
        this.getUpdateScheduleParams()
      )
      .subscribe((response: any) => {
        // this.formatData(response);
        if (response?.status === 'success') {
          if (
            this.detailData?.roster_scheduler?.repeat_type === 'once' &&
            this.hasScheduleStarted
          )
            this.getPatrolRouteDetail();
          else {
            this.router.navigate(['/roster']);
          }
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }
  openIntervalCheckTemplate(template: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(template);
  }
  updateInterval(interval: Number) {
    this.selectedInterval = interval;
    this.updatePatrolRoute('welfare_interval');
  }
  async closeDetailedHome() {
    if (
      [
        'scanCheckPoint',
        'addClientComment',
        'addClientPhoto',
        'updateJob',
      ].includes(this.updateTask)
    ) {
      this.updateTask = null;
    }
    if (!this.swUpdate.isEnabled || this.offlineMode) {
      this.getPatrolUpdates();
    }
    await this.formatCachedList();
    navigator?.serviceWorker?.controller?.postMessage({
      type: 'offlineDataCheck',
    });
  }
  getLastCommentEditedDateTime(history: any) {
    let sortedComments = history?.sort(
      (a: any, b: any) =>
        new Date(a?.updated_at).getTime() - new Date(b?.updated_at).getTime()
    );
    return sortedComments[sortedComments?.length - 1];
  }
  editComment(commentData: any, requestRef: TemplateRef<any>) {
    this.newComment = commentData?.detail;
    this.previousComment = commentData?.detail;
    this.dialogRef = this.dialogService.open(requestRef, {
      context: commentData,
    });
  }
  addComment(data: any) {
    if (this.newComment && this.newComment.trim()) {
      this.spinnerService.show();
      let body = {
        company_id: data?.company_id,
        comment: this.newComment,
        id: data?.id,
        edit_comment: 1,
        patrol_route_id: this.detailData?.id,
      };
      this.checkpointService.updateClient(body).subscribe((response: any) => {
        this.newComment = '';
        this.formatCachedList();
        this.spinnerService.hide();
      });
    }
  }
  onOpenImage(event: any, requestRef: TemplateRef<any>) {
    const imgElem = event.target;
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;
    this.fullImgSrc = srcAttr.nodeValue;
    this.dialogRef = this.dialogService.open(requestRef, {
      closeOnBackdropClick: true,
      context: {},
    });
  }
  getPatrolUpdates() {
    this.rsService
      .managePatrolRoute(
        {
          patrol_route_id: this.routeId,
        },
        { history: 1 }
      )
      .subscribe((response: any) => {
        // this.formatData(response);
        if (response?.status === 'success') {
          this.formatHistory(response);
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }
  async formatHistory(response: any) {
    this.patrolUpdateLog = JSON.parse(JSON.stringify(response?.history?.data));
    this.patrolUpdatesBackup = JSON.parse(
      JSON.stringify(response?.history?.data)
    );

    await this.formatCachedList();
    this.missedCheckpoints = response?.history?.missed_checkpoints;

    this.findScannedCheckpoints();
    this.patrolRouteStatusKey = this.scanComplete
      ? 1
      : response?.history?.patrol_route_status;
  }
  async formatCachedList() {
    this.patrolUpdateLog = JSON.parse(JSON.stringify(this.patrolUpdatesBackup));
    const loggedInUsersUpdates = this.patrolUpdateLog?.filter(
      (item: any) =>
        item?.subs_user_id == this.subscriberUserId &&
        [0, 1, 2, 6, 7].includes(item?.event_action)
    );

    this.indexedDbListData = await this.getAllIndexedDbItems();

    let remainingDataList: any = this.indexedDbListData?.filter(
      (cachedItem: any) =>
        cachedItem?.patrol_route_id === this.detailData?.id &&
        !loggedInUsersUpdates?.some(
          (item: any) =>
            item?.index_db_id == cachedItem?.indexDBId &&
            new Date(cachedItem?.updated_at * 1000).getTime() ===
              new Date(item?.recorded_at).getTime()
        )
    );

    remainingDataList?.forEach((item: any) => {
      let data = this.formatCachedData(item);

      if (
        !this.patrolUpdateLog?.some(
          (item: any) =>
            item?.cachedData && item?.index_db_id == data?.index_db_id
        )
      ) {
        this.patrolUpdateLog.push(data);
      }
    });
    this.patrolUpdateLog = this.patrolUpdateLog?.sort(
      (a: any, b: any) =>
        new Date(a?.updated_at).getTime() - new Date(b?.updated_at).getTime()
    );
    this.patrolLogUpdates = this.patrolUpdateLog?.filter(
      (history: any) => !history.source
    );
    this.cdr.detectChanges();
    let updatedPatrolEvents: any = this.indexedDbListData
      ?.filter(
        (cachedItem: any) =>
          cachedItem?.patrol_route_id === this.detailData?.id &&
          cachedItem?.apiCallSuccess &&
          loggedInUsersUpdates?.some(
            (item: any) =>
              (item?.index_db_id == cachedItem?.indexDBId &&
                new Date(cachedItem?.updated_at * 1000).getTime() ===
                  new Date(item?.recorded_at).getTime()) ||
              (cachedItem?.id && item?.id && cachedItem?.id === item?.id)
          )
      )
      ?.map((item: any) => item?.indexDBId);

    this.deleteCachedData(updatedPatrolEvents);
  }

  findScannedCheckpoints() {
    let alreadyScannedCheckpoint: any = this.patrolLogUpdates
      ?.filter(
        (update: any) =>
          [0, 6].includes(update?.event_action) &&
          !update?.cachedData &&
          this.patrolTime.startTime.getTime() <=
            new Date(update?.updated_at).getTime() &&
          new Date(update?.updated_at).getTime() <=
            this.patrolTime.endTime.getTime()
      )
      ?.map((update: any) => update?.event_id);

    this.scannedCheckpoints = {};

    alreadyScannedCheckpoint?.forEach((item: any) => {
      if (this.scannedCheckpoints[item]) {
        this.scannedCheckpoints[item]++;
      } else {
        this.scannedCheckpoints[item] = 1;
      }
    });
    this.detailData?.check_points?.forEach((checkpoint: any) => {
      if (
        this.scannedCheckpoints?.[checkpoint?.id] &&
        this.scannedCheckpoints?.[checkpoint?.id] > 0
      ) {
        checkpoint.isScanned = true;
        this.scannedCheckpoints[checkpoint?.id] =
          this.scannedCheckpoints[checkpoint?.id] - 1;
      }
    });

    this.calculateFixedBottomHeight();

    this.scanComplete = this.detailData?.check_points?.every(
      (checkpoint: any) => checkpoint?.isScanned
    );
  }
  formatCachedData(item: any) {
    let eventDict: any = {};
    if (item?.pathName === '/api/patrol/add_update/') {
      if (item?.image_source === 'live') {
        eventDict = { event_action: 2, action: 'Uploaded Image' };
      } else if (item?.image_source === 'device') {
        eventDict = { event_action: 7, action: 'File Uploaded' };
      } else {
        eventDict = { event_action: 1, action: 'Added Comment' };
      }
    } else if (item?.pathName === '/api/patrol/scan_checkpoint/') {
      eventDict = item?.qr_text
        ? { event_action: 6, action: 'Scanned Qr' }
        : { event_action: 0, action: 'Scanned Checkpoint' };
    }
    return {
      cachedData: true,
      detail: item?.comment || null,
      gps: item?.device_info?.gps,
      updated_at: item?.updated_at * 1000,
      recorded_at: item?.updated_at * 1000,
      index_db_id: item?.indexDBId,
      file: item?.file || null,
      subs_user_id: this.subscriberUserId,
      ...eventDict,
      device_details: {
        Ip: item?.device_info?.gps?.ip,
        Browser: `${item?.device_info?.browser} - ${item?.device_info?.browser_version}`,
        Device: `${item?.device_info?.device} - ${item?.device_info?.deviceType} `,
      },
    };
  }
  viewCheckpointDetail(checkpoint: any) {
    this.router.navigate([
      '/view-checkpoints',
      { cpKey: String(checkpoint?.id) },
    ]);
  }
  calculateFixedBottomHeight() {
    // Get the bottom-section-fixed element by ID

    const bottomSectionFixed = document.getElementById('bottom-section-fixed');

    if (bottomSectionFixed) {
      this.bottomSectionFixedHeight =
        bottomSectionFixed.offsetHeight.toString() === '0'
          ? '180' + 'px'
          : bottomSectionFixed.offsetHeight.toString() + 'px';
    }
  }
  onTableRowClick(eventData: any) {
    this.selectedEvent = eventData;
    this.scanDistanceInfo = checkScanDistanceGreater(this.selectedEvent);
  }
  getImgSrc(file: File): SafeUrl {
    let url = URL.createObjectURL(file);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  onOpenFile(file: any, template: TemplateRef<any>) {
    this.hideSpinner = false;
    if (file?.extension === '.pdf') {
      this.downloadFile(file);
    } else {
      this.dialogRef = this.dialogService.open(template, {
        closeOnBackdropClick: true,
        context: file,
      });
    }
  }

  downloadFile(file: any) {
    const a = document.createElement('a');
    a.href = file?.file_url;

    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  deleteFile(file: any) {
    let dialogMsg = `Permanently delete file`;
    this.dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: `Delete File`,
        message: dialogMsg,
      },
    });
    this.dialogRef.onClose.subscribe((value: any) => {
      if (value === 'Yes') {
        this.spinnerService.show();

        this.deletePatrolFile(file);
      }
    });
  }

  deletePatrolFile(file: any) {
    this.spinnerService.show();
    this.rsService
      .managePatrolRoute(
        {
          patrol_route_id: this.routeId,
          file_id: file?.id,
        },
        { remove_patrol_brief: 1 }
      )
      .subscribe((response: any) => {
        // this.formatData(response);
        if (response?.status === 'success') {
          this.detailData = response?.data;

          this.formatHistory(response);
          this.formatExistingData();
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }
  handleFileInput(event: any) {
    this.uploadPatrolBrief(event?.target?.files.item(0));
  }
  uploadPatrolBrief(file: any) {
    let params: any = {};
    params['add_patrol_brief'] = 1;
    let formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('patrol_route_id', this.routeId);
    this.spinnerService.show();
    this.appService
      .formDataApi(
        'roster_schedule/manage_patrol_route',
        formData,
        false,
        params
      )
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.detailData = response?.data;

          this.formatHistory(response);
          this.formatExistingData();
        } else {
          this.pageService.setMessage({
            errorMessage: response['message'],
            successMessage: '',
          });
        }

        this.spinnerService.hide();
      });
  }
  openPatrolBriefInformationTemplate(template: TemplateRef<any>) {
    this.newInfromation = this.detailData?.patrol_brief;

    this.dialogRef = this.dialogService.open(template, {
      context: {},
    });
  }
  openQRCode() {
    this.dialogRef = this.dialogService.open(QrCodeComponent, {
      context: { showFrom: 'home', patrolRouteId: this.detailData?.id },
    });
    this.dialogRef.onClose.subscribe((value: any) => {
      if (value?.history) {
        this.formatHistory(value);
      } else {
        this.getPatrolUpdates();
      }
    });
  }
  async getAllIndexedDbItems() {
    return new Promise(async (resolve) => {
      try {
        setTimeout(async () => {
          const data = await gTDB.gtSavedData.toArray();

          resolve(data);
        }, 500);
      } catch (error) {
        console.log(error, 'INDEXED DB ERROR');
        resolve([]);
      }
    });
  }
  deleteCachedData(keys: []) {
    try {
      gTDB.gtSavedData.bulkDelete(keys);
    } catch (error) {
      console.log(error);
    }
  }
  dropNewCheckPoint(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.selectedCheckpoints,
      event.previousIndex,
      event.currentIndex
    );
  }
  checkPointCounter(checkpointId: any) {
    return this.selectedCheckpoints?.filter(
      (item: any) => item?.id === checkpointId
    )?.length;
  }
  removeCheckpointFromSelection(checkpointId: any) {
    let lastIndex = this.selectedCheckpoints
      ?.map((item: any) => item?.id)
      .lastIndexOf(checkpointId);

    if (lastIndex != -1) {
      this.selectedCheckpoints?.splice(lastIndex, 1);
    }
  }
}
