import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { DataCheckService } from 'src/app/shared/service/data-check.service';

@Component({
  selector: 'app-error-page503',
  templateUrl: './error-page503.component.html',
  styleUrls: ['./error-page503.component.scss'],
})
export class ErrorPage503Component implements OnInit, OnDestroy {
  userData: any;
  isSuperUser: any;
  countDownValue: number = 0;
  countDownInterval: any;
  constructor(
    private appService: AppService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private spinnerService: NgxSpinnerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.userData = this.appService.getUserData();
    this.isSuperUser = this.dataCheckService.isSuperUser();

    if (await this.isServerOnline()) {
      this.goToHome();
    } else {
      this.checkServerStatus();
    }

    this.spinnerService.hide();
  }
  ngOnDestroy(): void {
    if (this.countDownInterval) clearInterval(this.countDownInterval);
  }
  goToHome() {
    if (this.userData && this.userData.access) {
      if (this.isSuperUser === true) {
        this.router.navigate(['gtadmin/dashboard']);
      } else {
        this.router.navigate(['/dashboard']);
      }
    } else {
      this.router.navigate(['/login']);
    }
  }
  checkServerStatus() {
    const checkInterval = 10000;
    this.countDownValue = checkInterval / 1000;
    this.updateCountdown();

    setTimeout(async () => {
      if (await this.isServerOnline()) {
        this.goToHome();
      } else {
        this.checkServerStatus();
      }
    }, checkInterval);
  }
  updateCountdown() {
    if (this.countDownInterval) {
      clearInterval(this.countDownInterval);
    }

    this.countDownInterval = setInterval(() => {
      if (this.countDownValue > 0) {
        this.countDownValue--;
      } else {
        clearInterval(this.countDownInterval);
      }
    }, 1000);
  }
  isServerOnline() {
    this.spinnerService.show();

    return new Promise((resolve) => {
      try {
        this.appService.getAddress().subscribe({
          next: (response: any) => {
            resolve(response?.status === 'success');

            this.spinnerService.hide();
          },
          error: (err) => {
            resolve(false);

            this.spinnerService.hide();
          },
        });
      } catch (error) {
        resolve(false);

        this.spinnerService.hide();
      }
    });
  }
}
