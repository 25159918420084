import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbAuthModule, NbAuthService, NbTokenService } from '@nebular/auth';
import {
  NbActionsModule,
  NbAlertModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbDatepickerModule,
  NbDialogModule,
  NbFormFieldModule,
  NbGlobalLogicalPosition,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbMenuModule,
  NbPopoverModule,
  NbSelectModule,
  NbSidebarModule,
  NbStepperModule,
  NbTabsetModule,
  NbTagModule,
  NbThemeModule,
  NbTimepickerModule,
  NbToastrModule,
  NbUserModule,
  NbWindowModule,
} from '@nebular/theme';
import { RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service } from 'ng-recaptcha';
import { NgxSpinnerModule } from 'ngx-spinner';
import { WebcamModule } from 'ngx-webcam';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth.interceptor';
import { AuthModule } from './auth/auth/auth.module';
import { PagesModule } from './pages/pages.module';
environment;
const recaptchaV3SiteKey = environment.recaptcha.siteKey;
// import { NgxPaginationModule } from 'ngx-pagination';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { environment } from 'src/environments/environment';
import { NotificationCountResolver } from './pages/notification.resolver';
import { AddUserComponent } from './shared/add-user/add-user.component';

@NgModule({
  declarations: [AppComponent, AddUserComponent],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    PagesModule,
    BrowserAnimationsModule,
    QRCodeModule,
    NgxSpinnerModule,
    HttpClientModule,
    AuthModule,
    NbAlertModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbDialogModule.forRoot({
      closeOnBackdropClick: false,
    }),
    NbMenuModule.forRoot(),
    NbWindowModule.forRoot(),
    NbFormFieldModule,
    NbIconModule,
    NbActionsModule,
    NbUserModule,
    NbCardModule,
    FormsModule,
    ReactiveFormsModule,
    NbInputModule,
    NbButtonModule,
    NbEvaIconsModule,
    RouterModule,
    NbAutocompleteModule,
    NbSidebarModule.forRoot(),
    NbAuthModule.forRoot(),
    NbToastrModule.forRoot({
      position: NbGlobalLogicalPosition.TOP_START,
      status: 'basic',
      icon: '',
      destroyByClick: true,
      duration: 10000,
    }),
    WebcamModule,
    NbPopoverModule,
    NgxMatTimepickerModule,
    NbStepperModule,
    NbSelectModule,
    NbDatepickerModule,
    NbTimepickerModule.forRoot({
      localization: {
        hoursText: 'Hr',
        minutesText: 'Min',
        secondsText: 'Sec',
        ampmText: 'Am/Pm',
      },
    }),
    DragDropModule,
    NbTabsetModule,
    NbTagModule,
    ServiceWorkerModule.register('gt-service-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    ReCaptchaV3Service,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: recaptchaV3SiteKey,
    },
    NbAuthService,
    NbTokenService,
    NotificationCountResolver,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
