<!-- MOBILE -->
<div class="mobile-only px-2">
  <form [formGroup]="formdata">
    <nb-stepper #stepper>
      <nb-step>
        <div class="form-floating">
          <input
            autofocus
            id="mobnameField"
            class="form-control"
            fullWidth
            formControlName="name"
            nbInput
            type="text"
            placeholder="Name"
            autocomplete="off"
          />

          <label>Name</label>
        </div>
        <div class="row">
          <div class="col">
            <button
              size="giant"
              nbButton
              fullWidth
              ghost
              [routerLink]="['/roster']"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              size="giant"
              nbButton
              fullWidth
              nbStepperNext
              [disabled]="!formdata.value?.name"
              (click)="getPatrolRouteCheckpoints()"
            >
              Next
            </button>
          </div>
        </div>
      </nb-step>
      <nb-step>
        <div>
          <div class="strong mb-1">
            {{ formdata.value?.name }}
          </div>
          Select Checkpoints

          <span *ngIf="patrolJobType?.checkpoints?.data?.length">
            [{{ patrolJobType?.checkpoints?.data?.length }} Added]
          </span>
          <div class="row row-cols-2 my-3">
            <div class="col">
              <button
                type="button"
                nbButton
                fullWidth
                ghost
                size="giant"
                nbStepperPrevious
                (click)="
                  patrolRouteCPList = [];
                  patrolRouteCpRows = 10;
                  patrolRouteCpPrevious = 0;
                  patrolRouteCpPageNum = 1;
                  patrolRouteCpTotalPages = 0
                "
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                type="submit"
                nbButton
                fullWidth
                size="giant"
                nbStepperNext
                [disabled]="!patrolJobType?.checkpoints?.data?.length"
                (click)="getPatrolRouteAssignees()"
              >
                Next
              </button>
            </div>
          </div>

          <div class="sc-mt-6">
            <table class="w-100">
              <tbody cdkDropList (cdkDropListDropped)="dropCheckPoint($event)">
                <tr
                  *ngFor="
                    let checkpoint of patrolJobType?.checkpoints?.data;
                    let i = index
                  "
                  class="pointer"
                >
                  <td>
                    <div class="d-flex">
                      <div class="me-2" cdkDrag>
                        <nb-icon [icon]="'menu-outline'" pack="eva"></nb-icon>
                      </div>
                      <div class="flex-grow-1">
                        {{ checkpoint?.name }} [{{ checkpoint?.company }}]
                        <div class="small-font">
                          {{ checkpoint?.address?.full_address }}
                        </div>
                      </div>
                      <div *ngIf="checkpoint?.qr_code" class="me-2">
                        <img
                          src="assets/images/qr-code-logo.png"
                          alt=""
                          width="26"
                          class="mb-0 pb-0"
                        />
                      </div>
                      <div (click)="removeSelectedCP(i)">
                        <button
                          nbButton
                          status="danger"
                          size="small"
                          class="m-0"
                        >
                          Del
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <hr />
              <div class="form-floating">
                <input
                  class="form-control"
                  type="text"
                  nbTagInput
                  fullWidth
                  placeholder="Filter Checkpoints"
                  (input)="searchCheckPoints($event)"
                />
                <label>Filter Checkpoints</label>
                <!-- USER LIST -->
              </div>
              <tbody>
                <tr
                  *ngIf="
                    patrolRouteCpTotalPages === 1 &&
                    patrolRouteCPList?.length > 1
                  "
                >
                  <td (click)="addAllCheckpoints()">
                    <div class="float-end">
                      <button
                        nbButton
                        status="primary"
                        size="small"
                        class="m-0"
                      >
                        Add All
                      </button>
                    </div>
                  </td>
                </tr>
                <tr
                  *ngFor="let checkpoint of patrolRouteCPList"
                  class="pointer"
                >
                  <td (click)="addSelectedCP(checkpoint)">
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        {{ checkpoint?.name }} [{{ checkpoint?.company }}]
                        <div class="small-font">
                          {{ checkpoint?.address?.full_address }}
                        </div>
                      </div>
                      <div *ngIf="checkpoint?.qr_code" class="me-2">
                        <img
                          src="assets/images/qr-code-logo.png"
                          alt=""
                          width="26"
                          class="mb-0 pb-0"
                        />
                      </div>
                      <div>
                        <button
                          nbButton
                          status="primary"
                          size="small"
                          class="m-0"
                        >
                          add
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              class="sc-mt-6"
              *ngIf="
                patrolRouteCpTotalPages > 1 &&
                patrolRouteCpTotalCount != patrolRouteCPList?.length
              "
            >
              <button
                type="button"
                nbButton
                fullWidth
                size="giant"
                (click)="onLoadMore()"
              >
                Load More
              </button>
            </div>
          </div>

          <div class="row row-cols-2 sc-mt-6">
            <div class="col">
              <button
                type="button"
                nbButton
                fullWidth
                ghost
                size="giant"
                nbStepperPrevious
                (click)="
                  patrolRouteCPList = [];
                  patrolRouteCpRows = 10;
                  patrolRouteCpPrevious = 0;
                  patrolRouteCpPageNum = 1;
                  patrolRouteCpTotalPages = 0
                "
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                type="submit"
                nbButton
                fullWidth
                size="giant"
                nbStepperNext
                [disabled]="!patrolJobType?.checkpoints?.data?.length"
                (click)="getPatrolRouteAssignees()"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </nb-step>
      <nb-step>
        <div class="strong mb-1">
          {{ formdata.value?.name }} >
          {{ patrolJobType?.checkpoints?.data?.length }} Checkpoints
        </div>
        Select Assignees

        <span *ngIf="patrolJobType?.assignees?.data?.length">
          [{{ patrolJobType?.assignees?.data?.length }} Added]
        </span>
        <div class="form-floating">
          <input
            class="form-control"
            id="mobassigneeField"
            fullWidth
            (input)="searchAssignee($event)"
            nbInput
            type="text"
            placeholder="Assign Guard"
            formControlName="assigneeName"
            [nbAutocomplete]="assigneeNgModelMobile"
            autocomplete="off"
          />
          <label>Assign Guard</label>
        </div>
        <nb-autocomplete
          #assigneeNgModelMobile
          (selectedChange)="addSelectedAssignee($event)"
        >
          <nb-option
            *ngFor="let assignee of assigneeSearchList"
            [value]="assignee"
          >
            {{ assignee?.full_name }}
          </nb-option>
        </nb-autocomplete>

        <div class="sc-mt-6">
          <table>
            <tbody>
              <tr *ngFor="let assignee of assigneeList" class="pointer">
                <td>
                  <nb-checkbox
                    [checked]="assignee.selected"
                    (checkedChange)="addRemovessignee($event, assignee)"
                  >
                  </nb-checkbox>
                </td>
                <td (click)="addRemovessignee(!assignee?.selected, assignee)">
                  {{ assignee?.full_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row row-cols-2 sc-mt-6">
          <div class="col">
            <button
              type="button"
              nbButton
              fullWidth
              ghost
              size="giant"
              nbStepperPrevious
              (click)="assigneeList = []"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="submit"
              nbButton
              fullWidth
              size="giant"
              nbStepperNext
              [disabled]="!patrolJobType?.assignees?.data?.length"
            >
              Next
            </button>
          </div>
        </div>
      </nb-step>
      <nb-step>
        Patrol Information (Optional)
        <div class="my-2">
          <textarea
            class="form-control"
            cols="20"
            rows="10"
            wrap="hard"
            maxlength="900"
            fullWidth
            type="text"
            formControlName="patrol_brief"
          ></textarea>
        </div>
        <div class="file-container">
          <div
            *ngIf="patrolJobType.patrol_brief_files.value"
            class="patrol-brief-file"
            class
          >
            {{ patrolJobType.patrol_brief_files.value?.name }}

            <div>
              <button
                nbButton
                fullWidth
                ghost
                (click)="patrolJobType.patrol_brief_files.value = null"
              >
                Remove
              </button>
            </div>
          </div>
          <div>
            <label
              for="file-upload"
              class="patrol-brief-file"
              *ngIf="!patrolJobType.patrol_brief_files.value"
            >
              Upload Patrol Information
            </label>
            <input
              id="file-upload"
              type="file"
              accept="application/pdf, image/*"
              (change)="handleFileInput($event)"
            />
          </div>
        </div>
        <div class="row row-cols-2 sc-mt-6">
          <div class="col">
            <button
              fullWidth
              type="button"
              nbStepperPrevious
              nbButton
              ghost
              size="giant"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="submit"
              nbButton
              fullWidth
              size="giant"
              nbStepperNext
              (click)="formatScheduleForm()"
            >
              Next
            </button>
          </div>
        </div>
      </nb-step>
      <nb-step>
        <div class="strong mb-1">
          {{ formdata.value?.name }} >
          {{ patrolJobType?.checkpoints?.data?.length }} Checkpoints >
          {{ patrolJobType?.assignees?.data?.length }} Assignees
        </div>
        <div class="row row-cols-2">
          <div class="col">
            <button
              fullWidth
              type="button"
              nbStepperPrevious
              nbButton
              ghost
              size="giant"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              fullWidth
              (click)="onAddJob()"
              nbButton
              status="primary"
              size="giant"
              [disabled]="
                !patrolJobType.checkpoints?.data?.length ||
                !patrolJobType.assignees?.data?.length ||
                addJobPressed
              "
            >
              Add Job
            </button>
          </div>
        </div>
        <app-scheduler
          *ngIf="scheduleStepperActive"
          [dialogBox]="false"
          [schedulerForm]="schedulerForm"
          [editSchedule]="true"
        ></app-scheduler>
        <div>
          <nb-select
            (click)="getIntervalData()"
            class="welfare-check-dropdown"
            fullWidth
            [selected]="intervalData[0].value"
            placeholder="Welfare Check Interval"
            status="primary"
            size="large"
            hero
          >
            <nb-option
              [value]="interval.value"
              *ngFor="let interval of intervalData"
              (click)="updateInterval(interval)"
              >{{ interval.name }}</nb-option
            >
          </nb-select>
        </div>
      </nb-step>
    </nb-stepper>
  </form>
</div>
<!-- Desktop View -->
<div class="large-view-only">
  <div class="col">
    <form [formGroup]="formdata">
      <nb-stepper #stepper>
        <nb-step>
          <div class="col-6">
            <div class="form-floating">
              <input
                autofocus
                id="desknameField"
                class="form-control"
                fullWidth
                formControlName="name"
                nbInput
                type="text"
                placeholder="Name"
                autocomplete="off"
              />

              <label>Name</label>
            </div>
            <div class="row">
              <div class="col">
                <button
                  size="giant"
                  nbButton
                  fullWidth
                  ghost
                  [routerLink]="['/roster']"
                >
                  Cancel
                </button>
              </div>
              <div class="col">
                <button
                  size="giant"
                  nbButton
                  fullWidth
                  nbStepperNext
                  [disabled]="!formdata.value?.name"
                  (click)="getPatrolRouteCheckpoints()"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </nb-step>
        <nb-step>
          <div>
            <div class="strong mb-1 sc-head4">
              {{ formdata.value?.name }}
            </div>

            <div class="mt-3">
              Select Checkpoints
              <span *ngIf="patrolJobType?.checkpoints?.data?.length">
                [{{ patrolJobType?.checkpoints?.data?.length }} Added]
              </span>
            </div>
            <div class="row row-cols-2">
              <div class="col" style="max-height: 70vh; overflow-y: auto">
                <table class="w-100">
                  <div class="form-floating">
                    <input
                      class="form-control"
                      type="text"
                      nbTagInput
                      fullWidth
                      placeholder="Filter Checkpoints"
                      (input)="searchCheckPoints($event)"
                    />
                    <label>Filter Checkpoints</label>
                    <!-- USER LIST -->
                  </div>

                  <tbody>
                    <tr
                      *ngIf="
                        patrolRouteCpTotalPages === 1 &&
                        patrolRouteCPList?.length > 1
                      "
                    >
                      <td (click)="addAllCheckpoints()">
                        <div class="float-end">
                          <button
                            nbButton
                            status="primary"
                            size="small"
                            class="m-0"
                          >
                            Add All
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr
                      *ngFor="let checkpoint of patrolRouteCPList"
                      class="pointer"
                    >
                      <td (click)="addSelectedCP(checkpoint)">
                        <div class="d-flex">
                          <div class="flex-grow-1">
                            {{ checkpoint?.name }} [{{ checkpoint?.company }}]
                            <div class="small-font">
                              {{ checkpoint?.address?.full_address }}
                            </div>
                          </div>
                          <div *ngIf="checkpoint?.qr_code" class="me-2">
                            <img
                              src="assets/images/qr-code-logo.png"
                              alt=""
                              width="26"
                              class="mb-0 pb-0"
                            />
                          </div>
                          <div>
                            <button
                              nbButton
                              status="primary"
                              size="small"
                              class="m-0"
                            >
                              add
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="sc-mt-6"
                  *ngIf="
                    patrolRouteCpTotalPages > 1 &&
                    patrolRouteCpTotalCount != patrolRouteCPList?.length
                  "
                >
                  <button
                    type="button"
                    nbButton
                    fullWidth
                    size="giant"
                    (click)="onLoadMore()"
                  >
                    Load More
                  </button>
                </div>
              </div>
              <div class="col" style="max-height: 70vh; overflow-y: auto">
                <table class="w-100">
                  <tbody
                    cdkDropList
                    (cdkDropListDropped)="dropCheckPoint($event)"
                  >
                    <tr
                      *ngFor="
                        let checkpoint of patrolJobType?.checkpoints?.data;
                        let i = index
                      "
                      class="pointer"
                    >
                      <td>
                        <div class="d-flex">
                          <div class="me-2" cdkDrag>
                            <nb-icon
                              [icon]="'menu-outline'"
                              pack="eva"
                            ></nb-icon>
                          </div>
                          <div class="flex-grow-1">
                            {{ checkpoint?.name }} [{{ checkpoint?.company }}]
                            <div class="small-font">
                              {{ checkpoint?.address?.full_address }}
                            </div>
                          </div>
                          <div *ngIf="checkpoint?.qr_code" class="me-2">
                            <img
                              src="assets/images/qr-code-logo.png"
                              alt=""
                              width="26"
                              class="mb-0 pb-0"
                            />
                          </div>

                          <div (click)="removeSelectedCP(i)">
                            <button
                              nbButton
                              status="danger"
                              size="small"
                              class="m-0"
                            >
                              Del
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row row-cols-2 sc-mt-6">
              <div class="col">
                <button
                  type="button"
                  nbButton
                  fullWidth
                  ghost
                  size="giant"
                  nbStepperPrevious
                  (click)="
                    patrolRouteCPList = [];
                    patrolRouteCpRows = 10;
                    patrolRouteCpPrevious = 0;
                    patrolRouteCpPageNum = 1;
                    patrolRouteCpTotalPages = 0
                  "
                >
                  Back
                </button>
              </div>
              <div class="col">
                <button
                  type="submit"
                  nbButton
                  fullWidth
                  size="giant"
                  nbStepperNext
                  [disabled]="!patrolJobType?.checkpoints?.data?.length"
                  (click)="getPatrolRouteAssignees()"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </nb-step>

        <nb-step>
          <div class="col-6">
            <div class="strong mb-1">
              {{ formdata.value?.name }} >
              {{ patrolJobType?.checkpoints?.data?.length }} Checkpoints
            </div>
            Select Assignees

            <span *ngIf="patrolJobType?.assignees?.data?.length">
              [{{ patrolJobType?.assignees?.data?.length }} Added]
            </span>
            <div class="form-floating">
              <input
                class="form-control"
                id="mobassigneeField"
                fullWidth
                (input)="searchAssignee($event)"
                nbInput
                type="text"
                placeholder="Assign Guard"
                formControlName="assigneeName"
                [nbAutocomplete]="assigneeNgModelDesk"
                autocomplete="off"
              />
              <label>Assign Guard</label>
            </div>
            <nb-autocomplete
              #assigneeNgModelDesk
              (selectedChange)="addSelectedAssignee($event)"
            >
              <nb-option
                *ngFor="let assignee of assigneeSearchList"
                [value]="assignee"
              >
                {{ assignee?.full_name }}
              </nb-option>
            </nb-autocomplete>

            <div class="sc-mt-6">
              <table>
                <tbody>
                  <tr *ngFor="let assignee of assigneeList" class="pointer">
                    <td>
                      <nb-checkbox
                        [checked]="assignee.selected"
                        (checkedChange)="addRemovessignee($event, assignee)"
                      >
                      </nb-checkbox>
                    </td>
                    <td
                      (click)="addRemovessignee(!assignee?.selected, assignee)"
                    >
                      {{ assignee?.full_name }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row row-cols-2 sc-mt-6">
              <div class="col">
                <button
                  type="button"
                  nbButton
                  fullWidth
                  ghost
                  size="giant"
                  nbStepperPrevious
                  (click)="assigneeList = []"
                >
                  Back
                </button>
              </div>
              <div class="col">
                <button
                  type="submit"
                  nbButton
                  fullWidth
                  size="giant"
                  nbStepperNext
                  [disabled]="!patrolJobType?.assignees?.data?.length"
                  (click)="formatScheduleForm()"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </nb-step>
        <nb-step>
          <div class="col-6">
            <div class="my-2">
              <textarea
                class="form-control"
                placeholder="Patrol Information (Optional)"
                cols="20"
                rows="10"
                wrap="hard"
                maxlength="900"
                fullWidth
                type="text"
                formControlName="patrol_brief"
              ></textarea>
            </div>
            <div class="file-container">
              <div
                *ngIf="patrolJobType.patrol_brief_files.value"
                class="patrol-brief-file"
                class
              >
                {{ patrolJobType.patrol_brief_files.value?.name }}

                <div>
                  <button
                    nbButton
                    fullWidth
                    ghost
                    (click)="patrolJobType.patrol_brief_files.value = null"
                  >
                    Remove
                  </button>
                </div>
              </div>
              <div>
                <label
                  for="file-upload"
                  class="patrol-brief-file"
                  *ngIf="!patrolJobType.patrol_brief_files.value"
                >
                  Upload Patrol Information
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept="application/pdf, image/*"
                  (change)="handleFileInput($event)"
                />
              </div>
            </div>
            <div class="row row-cols-2 sc-mt-6">
              <div class="col">
                <button
                  fullWidth
                  type="button"
                  nbStepperPrevious
                  nbButton
                  ghost
                  size="giant"
                >
                  Back
                </button>
              </div>
              <div class="col">
                <button
                  type="submit"
                  nbButton
                  fullWidth
                  size="giant"
                  nbStepperNext
                  (click)="formatScheduleForm()"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </nb-step>
        <nb-step>
          <div class="col-6">
            <div class="strong mb-1">
              {{ formdata.value?.name }} >
              {{ patrolJobType?.checkpoints?.data?.length }} Checkpoints >
              {{ patrolJobType?.assignees?.data?.length }} Assignees
            </div>
            <app-scheduler
              *ngIf="scheduleStepperActive"
              [dialogBox]="false"
              [schedulerForm]="schedulerForm"
              [editSchedule]="true"
            ></app-scheduler>
            <div>
              <nb-select
                (click)="getIntervalData()"
                class="welfare-check-dropdown"
                fullWidth
                [selected]="intervalData[0].value"
                placeholder="Welfare Check Interval"
                status="primary"
                hero
              >
                <nb-option
                  [value]="interval.value"
                  *ngFor="let interval of intervalData"
                  (click)="updateInterval(interval)"
                  >{{ interval.name }}</nb-option
                >
              </nb-select>
            </div>
            <div class="row row-cols-2 mt-4">
              <div class="col">
                <button
                  fullWidth
                  type="button"
                  nbStepperPrevious
                  nbButton
                  ghost
                  size="giant"
                >
                  Back
                </button>
              </div>
              <div class="col">
                <button
                  fullWidth
                  (click)="onAddJob()"
                  nbButton
                  status="primary"
                  size="giant"
                  [disabled]="
                    !patrolJobType.checkpoints?.data?.length ||
                    !patrolJobType.assignees?.data?.length ||
                    addJobPressed
                  "
                >
                  Add Job
                </button>
              </div>
            </div>
          </div>
        </nb-step>
      </nb-stepper>
    </form>
  </div>
</div>

<ng-template #checkpointDetails let-data>
  <nb-card class="dialog-box">
    <app-map-leaflet
      [latLon]="{
        lat: data.item?.address?.latitude,
        lon: data.item?.address?.longitude
      }"
      [mapId]="'cpLocation'"
    ></app-map-leaflet>
  </nb-card>
</ng-template>
