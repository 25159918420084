import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NbDialogService, NbStepperComponent } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { SubscriptionService } from './subscription.service';

import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { AddPaymentCardComponent } from 'src/app/shared/comp/add-payment-card/add-payment-card.component';
import { invoiceDataDetail } from 'src/global.variables';
import { CustomerService } from '../customer/customer.service';
import { PagesService } from '../pages.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper') stepper: any = NbStepperComponent;

  appPlans: any;
  subscriptions: any = [];
  expiredSubscriptions: any = [];
  currentSubscription: any;
  queuedSubscriptions: any = [];

  subscriptionInvoices: any = [];
  paymentCards: any = [];
  showPlans: boolean = false;
  hasActivePlan: boolean = true;
  hasPreviouslySubscribed: boolean = false;
  trialAllowedUsers: number = 0;

  invoiceDetails: any = invoiceDataDetail.card;
  invoiceIcons = [
    {
      condition: function () {
        return {
          icon: 'download-outline',
          status: 'primary',
          title: 'Download',
          type: 'download',
        };
      },
    },
  ];

  randomNumber: number = 0;
  showReasonText: boolean = false;
  cancelReason: any;
  feedbackOptions: any;

  transactionEventHeaders = [
    { name: 'event_key', header: 'ID' },
    {
      name: 'updated_at',
      header: 'Date',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
    },
    { name: 'client', header: 'Client' },
  ];
  transactionHeaders = [
    {
      header: 'Date',
      name: 'date',
    },
    {
      header: 'Transactions',
      name: 'transaction_count',
    },
  ];
  dateRange: any;
  subscriberTransactions: any;
  rowData: any;

  couponCode: any;
  showApplyCouponForm: boolean = false;
  isCouponApplied: boolean = false;
  errorCouponCode: boolean = false;
  showCouponInput: boolean = false;
  dataAfterCouponApplied: any;

  showPaymentForm: boolean = false;

  pageLoaded: boolean = false;
  firstTimeSubscriber: boolean = false;
  exBeta: boolean = false;

  isPWAApp: boolean = Boolean(
    window.matchMedia('(display-mode: standalone)').matches
  );

  queuedPlanData: any;
  queuedPlanDataCopy: any;

  allottedSlots: number = 1;

  selectedPlan: any = {};
  totalAddedUsers: any;
  addUser: boolean = false;
  minAllottedSlots: number = 1;

  isTrialApplicable: boolean = false;

  queuedSelectedPlanData: any = {};
  isSubscriberLocked: boolean = false;

  changePlanFromGuardsPage: boolean = false;
  constructor(
    private appService: AppService,
    private spinnerService: NgxSpinnerService,
    private subscriptionService: SubscriptionService,
    private dialog: NbDialogService,
    private customerService: CustomerService,
    private router: Router,
    private pageService: PagesService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe({
      next: (params: any) => {
        if (this.route.snapshot.fragment) {
          this.addUser = this.route.snapshot.fragment === 'addUser';
          this.changePlanFromGuardsPage =
            this.route.snapshot.fragment === 'changePlan';
        }
      },
    });
    this.subscriptionService
      .getSubscriberSubscriptionStatus()
      .subscribe((response: any) => {
        this.hasActivePlan = response['is_active'];
        this.exBeta = response['beta_user'];
        this.trialAllowedUsers = response['trial_allowed_users'];
        this.hasPreviouslySubscribed = response['previously_subscribed'];
        this.isTrialApplicable = response['is_trial_applicable'];
        this.isSubscriberLocked = response?.lock_plan || false;
      });
  }
  ngOnInit(): void {
    this.isfirstTimeSubscriber();
  }
  ngAfterViewInit(): void {
    this.getValues();
  }
  isfirstTimeSubscriber() {
    const decodedToken: any = jwt_decode(
      this.appService.getUserData()?.user_token
    );
    this.firstTimeSubscriber =
      localStorage.getItem('firstTimeSubscriber') == 'true' ||
      (decodedToken?.subscriber?.test_user === false &&
        decodedToken?.subscriber?.subscription_count
          ?.total_subscription_count === 0);
  }

  getValues() {
    this.spinnerService.show();
    this.subscriptions = [];
    this.isCouponApplied = false;
    this.showApplyCouponForm = false;
    this.showCouponInput = false;
    this.couponCode = null;
    this.dataAfterCouponApplied = null;
    this.queuedPlanData = null;
    this.queuedPlanDataCopy = null;
    let promises = [
      this.getPlans(),
      this.getSavedCards(),
      this.getInvoicesBySubscription(),
      this.getSubscribedPlans(),
    ];

    Promise.all(promises).then(() => {
      this.spinnerService.show();

      setTimeout(() => {
        if (this.changePlanFromGuardsPage) {
          this.router.navigate(['/subscription']);
          this.showPlans = true;
        }
        if (this.showPlans && this.appPlans?.length == 1) {
          this.onPlanClick(this.appPlans[0]);
        }
        this.pageLoaded = true;
        setTimeout(() => {
          this.spinnerService.hide();
        }, 500);
      }, 2500);
    });
  }
  getSubscribedPlans() {
    this.queuedSelectedPlanData = {};
    this.subscriptionService.getHavingPlans().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.totalAddedUsers = response['total_user_count'];
        this.subscriptions = response['data'];
        this.expiredSubscriptions = this.subscriptions.filter(
          (item: any) => item?.status == 'Expired'
        );
        this.queuedSubscriptions = this.subscriptions.filter(
          (item: any) => item?.status == 'Upcoming Plan'
        );
        this.currentSubscription = this.subscriptions.find(
          (item: any) => item?.is_current_subscription
        );
        this.hasPreviouslySubscribed = Boolean(
          this.expiredSubscriptions?.length
        );
        if (this.queuedSubscriptions?.length) {
          this.queuedSelectedPlanData = this.queuedSubscriptions[0];
        } else {
          if (
            this.currentSubscription?.plan?.id &&
            this.currentSubscription?.recurring_plan
          ) {
            if (
              this.currentSubscription?.plan?.reset_date &&
              new Date(this.currentSubscription?.plan?.reset_date).getTime() <=
                new Date(this.currentSubscription?.subscription_end).getTime()
            ) {
              this.queuedSelectedPlanData.plan =
                this.currentSubscription?.plan?.app_subscription_plan;
            } else if (
              this.currentSubscription?.app_coupon?.usage_count &&
              this.currentSubscription?.app_coupon?.repeatable_count &&
              this.currentSubscription?.app_coupon?.usage_count >=
                this.currentSubscription?.app_coupon?.repeatable_count
            ) {
              this.queuedSelectedPlanData.plan =
                this.currentSubscription?.plan?.selected_subscription_plan;
            }
          }
        }

        if (this.currentSubscription?.plan && this.addUser) {
          this.allottedSlots = this.totalAddedUsers;

          this.makePaymentView(this.currentSubscription?.plan);
        }
        this.showPlans = Boolean(!this.currentSubscription);

        if (this.currentSubscription) {
          const amtPayable =
            (this.currentSubscription?.app_coupon?.payable_amount
              ? this.currentSubscription?.app_coupon?.payable_amount
              : this.currentSubscription?.plan?.final_amount) +
            (this.totalAddedUsers >
            this.currentSubscription?.plan?.allowed_users
              ? (this.totalAddedUsers -
                  this.currentSubscription?.plan?.allowed_users) *
                this.currentSubscription?.plan?.user_cost
              : 0);
          let amtSavable: number = 0;
          let selectedIndex: any;

          setTimeout(() => {
            this.appPlans?.forEach((plan: any, i: number) => {
              if (plan?.total_cost < amtPayable) {
                plan.amtSavable = amtPayable - plan?.total_cost;
                if (amtPayable - plan?.total_cost > amtSavable) {
                  amtSavable = amtPayable - plan?.total_cost;
                  selectedIndex = i;
                }
              }
            });
            if (selectedIndex >= 0) {
              this.appPlans[selectedIndex]['recommended'] = true;
            }
          }, 1000);
        }
      } else {
        if (!this.hasPreviouslySubscribed) this.showPlans = true;
      }
    });
  }
  getPlans() {
    this.subscriptionService.getSubscriptionPlans().subscribe((res: any) => {
      if (res['status'] === 'success') {
        this.appPlans = res['data'];
      }
    });
  }

  changeSubscription(plan?: any) {
    this.showApplyCouponForm = true;
    this.minAllottedSlots = Math.max(plan?.allowed_users, this.totalAddedUsers);
    this.allottedSlots = this.minAllottedSlots;
    this.queuedPlanData = {
      plan: plan,
    };
    this.queuedPlanDataCopy = JSON.parse(JSON.stringify(this.queuedPlanData));
  }
  queueSubscription() {
    this.spinnerService.show();
    var requestData = {
      plan_id: this.queuedPlanDataCopy?.plan.id,
      coupon_code: this.couponCode,
      allotted_slots: this.allottedSlots,
    };
    this.subscriptionService
      .createSubscription(requestData)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.hasActivePlan = true;
          this.getValues();

          this.showPlans = false;
          this.showPaymentForm = false;
          this.showApplyCouponForm = false;
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.pageService.setMessage({
            errorMessage: response['message'],
            successMessage: '',
          });
          this.showPaymentForm = false;
        }
      });
  }

  getSavedCards() {
    this.subscriptionService.getSavedCards().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.paymentCards = response['data'];
      }
    });
  }

  changeSubscriptionRecursion(subscription: any) {
    let dialogMsg = '';
    let title = '';
    let status = '';
    if (subscription.recurring_plan) {
      dialogMsg =
        'Turning off auto renew means we will not automatically charge your card and renew your subscription.';
      title = 'CONFIRM';
      status = 'cancel_recursion';
    } else {
      dialogMsg =
        'By turning on auto renew you give us permission to automatically charge your card and renew your subscription.';
      title = 'CONFIRM';
      status = 'reactivate_recursion';
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      context: {
        title: title,
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.spinnerService.show();
        this.subscriptionService
          .changeSubscriptionRecursion(subscription.id, status)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              setTimeout(() => {
                this.getSubscribedPlans();
                this.spinnerService.hide();
              }, 500);

              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
                timeOut: 1000,
              });
            } else {
              this.spinnerService.hide();
              this.pageService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      }
    });
  }

  deleteCard(card: any) {
    let dialogMsg = 'Are you sure you want to remove this payment card? ';
    if (this.paymentCards?.length == 1) {
      if (this.queuedSubscriptions?.length) {
        dialogMsg += 'Your future plans will be removed.';
      }
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      context: {
        title: 'Delete Payment Card',
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((value: any) => {
      if (value === 'Yes') {
        this.spinnerService.show();
        this.subscriptionService
          .deleteSavedCard(card.id)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
              this.getSubscribedPlans();
              this.paymentCards = response?.data;
              this.spinnerService.hide();
            } else {
              this.spinnerService.hide();
              this.pageService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      }
    });
  }
  getInvoicesBySubscription() {
    this.spinnerService.show();
    this.subscriptionService
      .getInvoicesBySubscription()
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.subscriptionInvoices = response['data'];
        }
      });
  }

  downloadInvoice(data: any) {
    this.spinnerService.show();
    this.subscriptionService
      .downloadInvoiceById(data.id)
      .subscribe((response): any => {
        var downloadURL = window.URL.createObjectURL(<any>response);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${data.invoice_reference}.pdf`;
        link.click();
        this.spinnerService.hide();
      });
  }

  makePaymentView(plan: any) {
    this.spinnerService.show();
    this.selectedPlan = plan;
    this.showPaymentForm = true;
    // setTimeout(() => {
    //   this.spinnerService.hide();
    // }, 100);
  }
  onPlanClick(event: any) {
    this.allottedSlots = this.totalAddedUsers;
    this.currentSubscription
      ? this.changeSubscription(event)
      : this.makePaymentView(event);
  }
  randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  deleteSubscriberTemplate(template: TemplateRef<any>) {
    this.subscriptionService.getFeedBackOptions().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.feedbackOptions = response['data'];
      }
    });
    this.randomNumber = this.randomInteger(1000, 9999);
    this.dialog.open(template, {
      context: {},
    });
  }
  deleteSubscriber() {
    if (this.cancelReason) {
      this.spinnerService.show();
      this.subscriptionService
        .deleteSubscriberAccount({ feedback: this.cancelReason })
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.spinnerService.hide();

            localStorage.clear();
            localStorage.setItem('from', 'deletion');
            this.router.navigate(['/delete-account']);
          } else {
            this.spinnerService.hide();
            this.pageService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
    } else {
      this.pageService.setMessage({
        errorMessage: 'Provide a reason for deletion of your account',
        successMessage: '',
      });
    }
  }
  checkValidation(event: any) {
    if (event?.target?.value == this.randomNumber) {
      this.stepper.next();
    }
  }
  selectDeleteReason(reason: string) {
    if (reason === 'Other') {
      this.showReasonText = true;
      this.cancelReason = null;
    } else {
      this.showReasonText = false;
      this.cancelReason = reason;
    }
  }
  openTemplate(template: TemplateRef<any>, subscription: any) {
    const dialogRef = this.dialog.open(template, {
      context: subscription,
    });
  }
  getSubscriberTransactions() {
    this.spinnerService.show();
    let params = { get_transactions: 1 };
    let reuestData: any = {};
    if (this.dateRange) {
      reuestData.date_range = this.dateRange;
    }
    this.customerService
      .updateCompanyDetails(reuestData, params)
      .subscribe((response: any) => {
        if (response['status'] === 'success') {
          this.subscriberTransactions = response?.data;
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }

  onTransactionRowClick(data: any) {
    this.dateRange = { start: data.date, end: data.date };
    this.getSubscriberTransactions();
    this.rowData = [data];
  }
  expandColumns(data: any) {
    data.expanded = !data.expanded;
  }
  onRowClick(event: any) {
    if (event?.event_id) {
      this.router.navigateByUrl(`/job-detail/${event?.event_key}`, {
        state: event?.event_id,
      });
      window.localStorage.setItem('urlId', event?.event_id);
    }
  }

  getRoundOffValue(value: any, cent = true) {
    return Math.round(value * 100 * (cent ? 100 : 1)) / 100;
  }
  deleteQueuedPlan(subscription: any) {
    let dialogMsg = 'Are you sure you want to cancel auto renew?';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      context: {
        title: 'Cancel Auto Renew',
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((value: any) => {
      if (value === 'Yes') {
        this.spinnerService.show();
        this.subscriptionService
          .deleteQueuedPlan({ subscription_id: subscription?.id })
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              window.location.reload();
            } else {
              this.spinnerService.hide();
              this.pageService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      }
    });
  }
  onApplyCouponCode() {
    this.spinnerService.show();
    let body = {
      coupon_code: this.couponCode,
      plan_id: this.queuedPlanData?.plan?.id,
    };
    this.subscriptionService
      .applyCouponCode(body)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.pageService.setMessage({
            successMessage: 'Coupon Applied',
            errorMessage: '',
          });

          this.isCouponApplied = true;
          this.dataAfterCouponApplied = response['data'];
          if (this.dataAfterCouponApplied.effective_plan) {
            this.queuedPlanData.plan =
              this.dataAfterCouponApplied?.effective_plan;
          }
        } else {
          this.pageService.setMessage({
            successMessage: '',

            errorMessage: 'Invalid Coupon',
            timeOut: 1000,
          });
          this.errorCouponCode = true;
        }
        this.spinnerService.hide();
      });
  }
  forceUppercaseConditionally(event: any) {
    this.couponCode = event.target.value.toUpperCase();
  }
  removeAppliedCoupon() {
    this.isCouponApplied = false;
    this.couponCode = null;
    this.dataAfterCouponApplied = null;
    this.queuedPlanData = JSON.parse(JSON.stringify(this.queuedPlanDataCopy));
  }

  addNewCard() {
    const dialogRef = this.dialog.open(AddPaymentCardComponent, {
      context: {},
    });
    dialogRef.onClose.subscribe((value) => {
      this.getSavedCards();
    });
  }
  getBillingDate(subscriptionData: any) {
    if (subscriptionData?.recurring_plan || this.queuedSelectedPlanData?.id) {
      const endDay = new Date(subscriptionData?.subscription_end);

      endDay.setDate(endDay.getDate() + 1);
      return endDay;
    } else {
      return subscriptionData?.subscription_end;
    }
  }
  changeCardStatus(cardData: any) {
    if (!cardData?.is_primary) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        context: {
          title: 'Manage Payment Card',
          message: `Are you sure you want to make this the default payment card?`,
        },
      });

      dialogRef.onClose.subscribe((value: any) => {
        if (value === 'Yes') {
          this.spinnerService.show();
          this.subscriptionService
            .cardStatusChange(cardData.id)
            .subscribe((response: any) => {
              if (response['status'] == 'success') {
                this.spinnerService.hide();
                this.pageService.setMessage({
                  successMessage: response['message'],
                  errorMessage: '',
                });
                this.paymentCards = response?.data;
              } else {
                this.spinnerService.hide();
                this.pageService.setMessage({
                  errorMessage: response['message'],
                  successMessage: '',
                });
              }
            });
        }
      });
    }
  }
  increment() {
    this.allottedSlots++;
  }

  decrement() {
    if (this.allottedSlots > 1) {
      this.allottedSlots--;
    }
  }
  cancelPaymentForm() {
    this.showPaymentForm = false;
    if (this.addUser === true) {
      this.router.navigate(['/users']);
    }
  }
  getExtraUserCost(): number {
    const extraUsers =
      this.allottedSlots - this.queuedPlanData?.plan?.allowed_users;
    if (extraUsers <= 0) {
      return 0;
    }
    return extraUsers * this.queuedPlanData?.plan?.user_cost;
  }

  getTotalAmount(): number {
    let totalAmount = this.queuedPlanData?.plan?.final_amount;
    if (this.allottedSlots > this.queuedPlanData?.plan?.allowed_users) {
      totalAmount += this.getExtraUserCost();
    }
    if (this.dataAfterCouponApplied) {
      if (this.dataAfterCouponApplied?.details?.discount_amount) {
        totalAmount =
          totalAmount - this.dataAfterCouponApplied?.details?.discount_amount;
      }
      if (this.dataAfterCouponApplied?.details?.discount_percentage) {
        const discountPercentage =
          this.dataAfterCouponApplied.details.discount_percentage;
        const discountAmount = (totalAmount * discountPercentage) / 100;
        totalAmount -= discountAmount;
      }
    }
    return totalAmount;
  }
  getGSTAmount() {
    return (this.getTotalAmount() * 0.1).toFixed(2);
  }
}
