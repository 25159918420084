<div class="mobile-only">
  <div class="gt-logo-mobile">
    <img
      src="../../../assets/images/Guard_Tracker-Stacked.png"
      alt="Guard Tracker"
    />
  </div>
</div>
<div class="large-view-only">
  <div class="gt-logo-desktop">
    <img
      src="../../../assets/images/Guard_Tracker-Lateral_Long_default.png"
      alt="Guard Tracker"
    />
  </div>
</div>

<div *ngIf="!userLinkedSubscribers?.length">
  <section>
    <nb-alert *ngIf="message" outline="basic" role="alert">
      {{ message }}
    </nb-alert>
  </section>

  <ng-container>
    <form
      #f="ngForm"
      [formGroup]="loginForm"
      (ngSubmit)="login()"
      aria-labelledby="title"
    >
      <div class="form-floating">
        <input
          class="form-control"
          nbInput
          fullWidth
          type="text"
          [status]="
            loginForm.controls['username'].touched
              ? loginForm.controls['username'].invalid
                ? 'danger'
                : 'basic'
              : ''
          "
          [attr.aria-invalid]="
            loginForm.controls['username'].invalid &&
            loginForm.controls['username'].touched
              ? true
              : null
          "
          formControlName="username"
          (input)="checkEmail()"
          id="username"
          placeholder="Username"
          pattern=".+@.+\..+"
          autocorrect="off"
          autocapitalize="off"
        />
        <label for="username">Email Address</label>
      </div>
      <div *ngIf="showForm === 'userNameForm'">
        <button
          nbButton
          fullWidth
          id="form-enter-submit-btn"
          type="submit"
          size="giant"
          [disabled]="!loginForm.valid"
        >
          Next
        </button>
      </div>

      <!--if entered email is in db and this is the first time login of that email -->
      <ng-container *ngIf="showForm === 'vCodeForm'">
        <div class="form-floating">
          <input
            nbInput
            fullWidth
            class="form-control"
            type="tel"
            name="auth_code"
            id="verCode"
            formControlName="auth_code"
            placeholder="Verification Code"
            [maxlength]="6"
            autofocus
            autocomplete="off"
          />
          <label for="verCode">Verification Code</label>
        </div>

        <ng-container
          *ngIf="
            loginForm.controls['auth_code'].invalid &&
            loginForm.controls['auth_code'].touched
          "
        >
          <p
            class="error-message"
            *ngIf="loginForm.controls['auth_code'].errors"
          >
            Verification code is required!
          </p>
        </ng-container>
        <div>
          <button nbButton size="giant" fullWidth [disabled]="!loginForm.valid">
            Verify
          </button>
        </div>
      </ng-container>

      <!--if entered email is in db and user has logged in at-least once -->
      <ng-container
        *ngIf="showForm === 'passwordForm' || showForm === 'dummyPasswordForm'"
      >
        <ng-container
          *ngIf="
            !sendMeSignInLinkClicked &&
            (isPasswordAlreadyset || showForm === 'dummyPasswordForm')
          "
        >
          <nb-form-field id="passwordInputField">
            <div class="form-floating">
              <input
                class="form-control"
                nbInput
                fullWidth
                autofocus
                [type]="getInputType()"
                name="password"
                id="inputPassword"
                formControlName="password"
                placeholder="Password"
                [minlength]="8"
                [status]="
                  loginForm.controls['password'].touched
                    ? loginForm.controls['password'].invalid
                      ? 'danger'
                      : 'basic'
                    : ''
                "
              />
              <label for="inputPassword">Password</label>
            </div>
            <nb-icon
              nbSuffix
              (click)="hideShowPassword()"
              [icon]="showPassword ? 'eye-outline' : 'eye-off-outline'"
              pack="eva"
              [attr.aria-label]="
                showPassword ? 'hide password' : 'show password'
              "
            >
            </nb-icon>
          </nb-form-field>
          <div class="d-flex" *ngIf="!pwaApp">
            <!-- Remember me option save email on exit -->
            <nb-checkbox formControlName="remember_login">
              This is a trusted device
            </nb-checkbox>
            <nb-icon
              class="ms-1"
              [icon]="'info-outline'"
              pack="eva"
              nbTooltip="Checking this box will allow you to perform additional functions including resetting your password and changing your email address"
              nbTrigger="focus"
              status="primary"
            ></nb-icon>
          </div>
        </ng-container>
        <div *ngIf="showForm === 'dummyPasswordForm'">
          <!--no need to show magic link and webauthn option as the email is not in our db-->
          <button nbButton size="giant" fullWidth [disabled]="!loginForm.valid">
            Sign In
          </button>
        </div>

        <div *ngIf="showForm === 'passwordForm'">
          <!--case when the email address is in the db and the user has logged in at-least once-->
          <div [ngClass]="fidoRegistered ? 'row row-cols-2' : 'row-cols-1'">
            <ng-container
              *ngIf="isPasswordAlreadyset && !sendMeSignInLinkClicked"
            >
              <div class="col">
                <button
                  nbButton
                  size="giant"
                  fullWidth
                  [disabled]="!loginForm.valid"
                >
                  Sign In
                </button>
              </div>

              <div class="col" *ngIf="fidoRegistered">
                <button
                  nbButton
                  size="giant"
                  fullWidth
                  type="button"
                  (click)="authenticate()"
                  class="nbbutton-long-text"
                >
                  Use Webauthn
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="!isPasswordAlreadyset">
              <div>
                <button
                  nbButton
                  fullWidth
                  size="giant"
                  type="button"
                  (click)="sendVCode()"
                >
                  Get One Time Pin
                </button>
              </div>
            </ng-container>

            <div
              class="col-12"
              *ngIf="
                !sendMeSignInLinkClicked &&
                !hideSignInLInkForgotPasswordBtn &&
                isPasswordAlreadyset
              "
            >
              <div class="magic-link-header-container">
                <hr class="hr-left" />
                <strong> or Request a One Time Pin </strong>
                <hr class="hr-right" />
              </div>
              <p class="sc-text-center" id="magic-link-text">
                We can email you a one time pin so you can sign in with out
                having to type your password.
              </p>

              <button
                nbButton
                fullWidth
                size="giant"
                type="button"
                (click)="sendVCode()"
              >
                Forgot Password
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </form>

    <div class="mt-2 text-center small-font" *ngIf="currentAppVersion">
      {{ currentAppVersion }}
    </div>
  </ng-container>

  <ng-container *ngIf="linkExpired">
    <form [formGroup]="userEmailForm" appFormEnterAction>
      <div class="form-floating">
        <input
          type="text"
          fullWidth
          class="form-control"
          nbInput
          id="email"
          autocomplete="off"
          placeholder="Email Address"
          autofocus
          formControlName="email"
          autocorrect="off"
          autocapitalize="off"
        />
        <label for="email">Email</label>
      </div>
      <div>
        <button
          outline
          type="button"
          [disabled]="!userEmailForm.valid"
          (click)="refreshExpiredLink()"
          nbButton
          size="giant"
          fullWidth
        >
          Submit
        </button>
      </div>
    </form>
  </ng-container>
</div>

<ng-container *ngIf="userLinkedSubscribers?.length">
  <section>
    <nb-alert *ngIf="message" outline="basic" role="alert">
      {{ message }}
    </nb-alert>
  </section>
  <div class="row row-cols-1 sc-text-center gy-2 sc-mt-9">
    <em>Select instance</em>
    <div
      *ngFor="let subscriber of userLinkedSubscribers"
      (click)="selectSubscriber(subscriber)"
      class="col"
    >
      <nb-card class="contact-card">
        <div class="table-card-data pointer">
          <div class="strong">
            {{ subscriber.name }}
          </div>
          <div class="small-font">
            {{ subscriber?.address?.full_address }}
          </div>
        </div>
      </nb-card>
    </div>
  </div>
</ng-container>
