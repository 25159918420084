<ng-container *ngIf="barChartData?.length && maxChartValue">
  <div class="bar-chart">
    <div *ngFor="let bar of barChartData">
      <div>
        <div class="value">
          {{ getBarValue("getBarValue", bar, [charInfo]) }}
        </div>

        <div
          class="bar"
          [ngStyle]="getBarValue('getBarStyle', bar, [charInfo, maxChartValue])"
        ></div>

        <div class="label">
          {{ getBarValue("getBarLabel", bar, [charInfo]) }}
        </div>
      </div>
    </div>

    <ng-container *ngIf="averageValue">
      <span class="avg-value" [ngStyle]="{ bottom: averageValueInPx }">
        Average {{ averageValue }}</span
      >
      <div
        class="avg-dotted-line"
        [ngStyle]="{ bottom: averageValueInPx }"
      ></div>
    </ng-container>
  </div>
</ng-container>
