import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient) {}

  //Subscription Plan APIs
  getAllSubscriptionPlansForAdmin(params?: any) {
    return this.http.get(`/admin/subscription_plan/`, { params: params });
  }

  getPlanLevelAndInterval() {
    return this.http.get(`/admin/subscription_plan/get_options/`);
  }
  createSubscriptionPlan(requestData: any) {
    return this.http
      .post('admin/subscription_plan/', requestData)
      .pipe(map((response) => response));
  }
  editSubscriptionPlan(requestData: any) {
    return this.http
      .put(`admin/subscription_plan/${requestData.id}/`, requestData)
      .pipe(map((response) => response));
  }
  getSubscribersBySubscription(id: any) {
    let body = {};
    return this.http
      .post(`admin/subscription_plan/${id}/get_subscriptions/`, body)
      .pipe(map((response) => response));
  }

  //Subscribers APIs
  getSubscribersListForAdmin(params?: any) {
    return this.http.get(`/admin/subscriber/`, { params: params });
  }

  getSubscriberDataById(params: any, subscriberId: number) {
    return this.http.get(`/admin/subscriber/${subscriberId}/`, {
      params: params,
    });
  }
  // api to save subscribere config
  saveSubscriberConfig(subscriberId: number, body: any, params?: any) {
    return this.http.post(`/admin/subscriber/${subscriberId}/config/`, body, {
      params: params,
    });
  }
  // api to save global subscribere config
  saveGlobalSubscriberConfig(body: any, params?: any) {
    return this.http.post(`/admin/ui_route/`, body, {
      params: params,
    });
  }
  // api to create a cancel one or all subscription of a particular subscriber
  cancelSubscriptionPlan(subscriberId: number, params?: any) {
    return this.http.post(
      `/admin/subscriber/${subscriberId}/cancel_subscription/`,
      {},

      {
        params: params,
      }
    );
  }
  // api to create a new subscription for a particular subscriber
  createSubscriberSubscriptionPlan(
    subscriberId: number,

    params: any
  ) {
    return this.http.post(
      `/admin/subscriber/${subscriberId}/create_subscription/`,
      {},

      {
        params: params,
      }
    );
  }
  //Add Balance to subscriber account
  updateSubscriber(requestData: any, params?: any) {
    return this.http.put(`/admin/subscriber/${requestData.id}/`, requestData, {
      params: params,
    });
  }

  //Event Logs APIs

  getEventLogs(params?: any) {
    return this.http.get('/admin/event_log/', { params: params });
  }

  //Analytics APIs
  getAnalyticsData(body: any, params: any) {
    return this.http.post('/admin/analytics/', body, { params: params });
  }
  getAdminDashBoard(body?: any, params?: any) {
    return this.http.post('/admin/dashboard/', body, { params: params });
  }
  getAllReminders(params?: any) {
    return this.http.get('/admin/reminder/', { params: params });
  }
  createNewReminder(body?: any, params?: any) {
    return this.http.post('/admin/reminder/', body, { params: params });
  }
  editReminder(id: Number, body?: any, params?: any) {
    return this.http.put(`/admin/reminder/${id}/`, body, { params: params });
  }
  deleteReminder(id: Number) {
    return this.http.delete(`/admin/reminder/${id}/`);
  }
  getAppRoutes(params?: any) {
    return this.http.get('admin/app_routes/', { params: params });
  }

  saveAppRoute(body: any, params?: any) {
    return this.http.post('/admin/app_routes/', body, { params: params });
  }
  reorderAppRoutes(body: any, params?: any) {
    return this.http.post('/admin/app_routes/rearrange/', body, {
      params: params,
    });
  }
  editAppRoute(id: number, body: any, params?: any) {
    return this.http.put(`/admin/app_routes/${id}/`, body, { params: params });
  }
  deleteAppRoute(id: Number) {
    return this.http.delete(`/admin/app_routes/${id}/`);
  }
  getPushDevices(params?: any) {
    return this.http.get(`/admin/push_notification/`, { params: params });
  }

  sendNotification(body: any, params?: any) {
    return this.http.post('/admin/push_notification/', body, {
      params: params,
    });
  }

  getSuperUsers(params?: any) {
    return this.http.get(`/admin/user/`, { params: params });
  }
  getPayments(body?: any, params?: any) {
    return this.http.post('/admin/payment/', body, { params: params });
  }
  getInvoices(params?: any) {
    return this.http.get('/admin/payment/', { params: params });
  }
  getFeedBack(params?: any) {
    return this.http.get('admin/feedback/', { params: params });
  }

  saveFeedBack(body: any, params?: any) {
    return this.http.post('/admin/feedback/', body, { params: params });
  }
  deleteFeedback(id: Number) {
    return this.http.delete(`/admin/feedback/${id}/`);
  }

  // api to get subscriber transactions
  getTransactions(subscriberId: number, body: any, params?: any) {
    return this.http.post(
      `/admin/subscriber/${subscriberId}/get_transactions/`,
      body,
      {
        params: params,
      }
    );
  }

  //error-log api
  getAllErrors(params?: any) {
    return this.http.get('/admin/error_log/', { params: params });
  }

  //Job Status APIs
  getJobStatusList(params?: any) {
    return this.http.get('/admin/job_status/', { params: params });
  }

  createJobStatus(body: any, params?: any) {
    return this.http.post('/admin/job_status/', body, { params: params });
  }
  editJobStatus(id: number, body: any, params?: any) {
    return this.http.put(`/admin/job_status/${id}/`, body, { params: params });
  }

  //Job Type APIs
  getJobtypeList(params?: any) {
    return this.http.get('/admin/job_type/', { params: params });
  }
  getJobtypeById(id: any) {
    return this.http.get(`/admin/job_type/${id}`);
  }
  createJobtype(body: any) {
    return this.http.post('/admin/job_type/', body);
  }
  editJobtype(id: number, body: any) {
    return this.http.put(`/admin/job_type/${id}/`, body);
  }

  // beta user apis
  getBetaUserList(params?: any) {
    return this.http.get('/admin/beta_user/', { params: params });
  }
  getBetaUserById(id: any) {
    return this.http.get(`/admin/beta_user/${id}`);
  }

  updateBetaUser(id: number, body: any) {
    return this.http.put(`/admin/beta_user/${id}/`, body);
  }
  createBetaSubscriber(body: any, params?: any) {
    return this.http.post(`/admin/beta_user/`, body, {
      params: params,
    });
  }
  getBetaUserHistory(params?: any) {
    return this.http.get('/admin/beta_user/get_history/', { params: params });
  }
  // bulk change beta Status
  bulkChange(body?: any, params?: any) {
    return this.http.post(`/admin/beta_user/bulk_update/`, body, {
      params: params,
    });
  }
  // trial period log
  changeTrialPeriod(body: any, params?: any) {
    return this.http.post(`/admin/trial_period/`, body, {
      params: params,
    });
  }
  getTrialPeriodLog(params?: any) {
    return this.http.get('/admin/trial_period/', {
      params: params,
    });
  }
  //trial change api
  changeSubscriberTrial(body?: any, params?: any) {
    return this.http.post(`/admin/subscriber/trial_change/`, body, {
      params: params,
    });
  }

  //get Subscibers feedback data

  getFeedbackData(params?: any) {
    return this.http.get('/admin/subscriber_feedback/', { params: params });
  }
  getFeedbackDataById(id: number) {
    return this.http.get(`/admin/subscriber_feedback/${id}/`);
  }

  editFeedback(id: number, body: any, params?: any) {
    return this.http.put(`/admin/subscriber_feedback/${id}/`, body, {
      params: params,
    });
  }

  //management commands api
  getManagementCmds(params?: any) {
    return this.http.get('/admin/management_cmd/', { params: params });
  }
  callManagementCmd(body: any, params?: any) {
    return this.http.post('/admin/management_cmd/', body, { params: params });
  }
  editGitHistory(id: number, body: any, params?: any) {
    return this.http.put(`/admin/management_cmd/${id}/`, body, {
      params: params,
    });
  }
  // normal user list api
  getAppusers(params?: any) {
    return this.http.get('/admin/user/app_users', { params: params });
  }
  manageAppUser(body?: any, params?: any) {
    return this.http.post('/admin/user/manage_user/', body, { params: params });
  }

  getReportContacts(params?: any) {
    return this.http.get('/admin/report_contacts/', { params: params });
  }

  //coupon apis
  getCouponTypes() {
    return this.http.get(`/admin/coupon/get_options/`);
  }
  getAllCoupons(params?: any) {
    return this.http.get(`/admin/coupon/`, { params: params });
  }

  createCoupon(requestData: any) {
    return this.http
      .post('admin/coupon/', requestData)
      .pipe(map((response) => response));
  }
  editCoupon(id: string, requestData: any) {
    return this.http
      .put(`admin/coupon/${id}/`, requestData)
      .pipe(map((response) => response));
  }
  modifySubscription(requestData: any) {
    return this.http
      .post(`admin/subscription/`, requestData)
      .pipe(map((response) => response));
  }
  //admin report apis
  getAdminReportTypes() {
    return this.http.get(`/admin/admin_report/get_options/`);
  }
  getAllAdminReports(params?: any) {
    return this.http.get(`/admin/admin_report/`, { params: params });
  }

  createAdminReport(requestData: any) {
    return this.http
      .post('admin/admin_report/', requestData)
      .pipe(map((response) => response));
  }
  editAdminReport(id: string, requestData: any) {
    return this.http
      .put(`admin/admin_report/${id}/`, requestData)
      .pipe(map((response) => response));
  }
  deleteAdminReport(id: string) {
    return this.http
      .delete(`admin/admin_report/${id}/`)
      .pipe(map((response) => response));
  }

  // subscriber related data apis
  subscriberRelatedGETApis(path: String, params: any) {
    return this.http
      .get(`admin/subscriber_${path}/`, { params: params })
      .pipe(map((response) => response));
  }
  subscriberRelatedPOSTApis(path: String, body: any, params: any) {
    return this.http
      .post(`admin/subscriber_${path}/`, body, { params: params })
      .pipe(map((response) => response));
  }

  //Smart Net job APIs.
  getSmartNetData(body?: any, params?: any) {
    return this.http
      .post(`/api/smart_net/`, body, {
        params: params,
      })
      .pipe(map((response: any) => response));
  }

  //failed report-log api
  getAllFailedReports(params?: any) {
    return this.http.get('/admin/failed_report/', { params: params });
  }
  retryFailedReport(id: string, body?: any) {
    return this.http.put(`/admin/failed_report/${id}/`, body);
  }
}
