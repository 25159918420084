import { formatDate } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { CustomerService } from 'src/app/pages/customer/customer.service';
import { PagesService } from 'src/app/pages/pages.service';
import { UsersService } from 'src/app/pages/users/users.service';
import { yesterday } from 'src/global.variables';

@Component({
  selector: 'app-add-edit-license-details',
  templateUrl: './add-edit-license-details.component.html',
  styleUrls: ['./add-edit-license-details.component.scss'],
})
export class AddEditLicenseDetailsComponent implements OnInit {
  licenseForm: UntypedFormGroup = new UntypedFormGroup({
    issuer_state: new UntypedFormControl(null, [Validators.required]),
    license_class: new UntypedFormControl(null, [Validators.required]),
    license_number: new UntypedFormControl(null, [Validators.required]),
    valid_till: new UntypedFormControl(null, [Validators.required]),
  });
  stateData: any = [];
  showAddLicenseForm: any;
  classData: any[] = [];
  selectedItem: any;
  @Input() licenseState: any;
  @Input() companyLicense: boolean = false;
  @ViewChild('dialog') dialog: any;
  showLicenseAddClass: boolean = false;
  licenseClassData: any = [];
  validDate = yesterday;

  constructor(
    private appService: AppService,
    private toasterService: NbToastrService,
    protected dialogRef: NbDialogRef<AddEditLicenseDetailsComponent>,
    private userService: UsersService,
    private spinnerService: NgxSpinnerService,
    private customerService: CustomerService,
    private pageService: PagesService
  ) {}

  ngOnInit(): void {
    this.getStates(1);
    if (this.licenseState)
      this.licenseForm.controls['issuer_state'].setValue(this.licenseState);
  }
  getStates(countryId: any) {
    this.appService.getStates(+countryId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateData = response['data'];
        if (this.licenseState) {
          let selectedState = this.stateData.filter(
            (item: any) =>
              item.code.toLowerCase() === this.licenseState.toLowerCase()
          );
          if (selectedState.length) {
            this.selectState(selectedState[0]);
            this.licenseForm.controls['issuer_state'].setValue(
              this.licenseState
            );
          }
        }
      }
    });
  }

  selectState(data: any) {
    this.userService.getClassByState(data.id).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.classData = response['data'];
        this.licenseClassData = response['data'];
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }
  addNewClass(newLicense: string) {
    if (this.licenseForm.value['issuer_state']) {
      let data = {
        issuer_state: this.licenseForm.value['issuer_state'],
        license_class_name: newLicense,
      };
      this.userService.addNewClass(data).subscribe((response: any) => {
        this.classData = [...this.classData, response['data']];
        this.showLicenseAddClass = false;
        this.licenseForm.controls['license_class'].setValue(
          response['data'].license_class_name
        );
      });
    } else {
      this.toasterService.warning(
        'Warning',
        'Please select the state to add new licence guard.'
      );
    }
  }
  closeDialogue(data = 'close') {
    this.dialogRef.close(data);
  }

  addLicense() {
    if (this.licenseForm.valid) {
      this.licenseForm.value.valid_till = formatDate(
        this.licenseForm.value.valid_till,
        'yyyy-MM-dd',
        'en'
      );
      this.spinnerService.show();
      this.userService
        .addLicense(this.licenseForm.value, {
          is_subscriber: this.companyLicense ? 1 : 0,
        })
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.pageService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });

            this.closeDialogue(response['data']);
            this.licenseForm.reset();
          } else {
            this.pageService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
    } else {
      this.toasterService.warning('', 'invalid data');
    }

    this.spinnerService.hide();
  }

  onLicenceClassSearch(event: any) {
    this.showLicenseAddClass = false;
    if (event?.target?.value?.length === 0) {
      this.licenseClassData = this.classData;
    } else {
      this.licenseClassData = this.classData.filter((item: any) =>
        item.license_class_name
          ?.toLowerCase()
          .includes(event?.target?.value?.toLowerCase())
      );
      if (!this.licenseClassData?.length) this.showLicenseAddClass = true;
    }
  }
}
