<nb-card class="gt-p-small">
  <div>
    <div class="d-flex">
      <button
        class="sc-mt-0 nbbutton-long-text"
        size="giant"
        nbButton
        (click)="openTemplate(bulkTrialChangeTemplate); searchSubscribers()"
      >
        Bulk Trial Change
      </button>
      <button
        class="sc-mt-0 nbbutton-long-text mx-1"
        size="giant"
        nbButton
        status="info"
        (click)="getSubscriberStorage()"
      >
        {{ subscriberStorageData ? "Hide AWS Storage" : "Show AWS Storage" }}
      </button>
      <button
        *ngIf="subscriberTableData?.inactive_accounts"
        class="sc-mt-0 nbbutton-long-text mx-1"
        size="giant"
        nbButton
        status="warning"
        (click)="getNormalInactiveSubscribers()"
      >
        {{ inactiveAccounts ? "Show All" : "Inactive Accounts" }}
      </button>
      <button
        *ngIf="subscriberTableData?.closed_accounts"
        class="sc-mt-0 nbbutton-long-text mx-1"
        size="giant"
        nbButton
        [status]="closedAccounts ? '' : 'danger'"
        (click)="closedNormalSubscribers()"
      >
        {{ closedAccounts ? "Normal A/c s" : "Closed A/c s" }}
      </button>
    </div>
  </div>
  <app-gt-card
    class="mobile-only"
    [mobileCardBackendData]="subscriberTableData?.results"
    [mobileCardDetails]="mobileCardSubscriberDetails"
    [otherParams]="otherParamsSubscribers"
    (paginationChange)="onChangePagination($event)"
    (sortColumn)="sortColumn($event)"
    (rowClick)="onRowClick($event)"
    (searchColumn)="searchColumn($event)"
  >
  </app-gt-card>
  <app-gt-table
    class="large-view-only"
    [desktopTableBackendData]="subscriberTableData?.results"
    [desktopTableDetails]="subscriberListTableDetail"
    [otherParams]="otherParamsSubscribers"
    (paginationChange)="onChangePagination($event)"
    (sortColumn)="sortColumn($event)"
    (rowClick)="onRowClick($event)"
    (searchColumn)="searchColumn($event)"
  >
  </app-gt-table>

  <nb-tabset>
    <nb-tab tabTitle="Wallet History">
      <ng-container *ngIf="showData && reportData?.length">
        <app-gt-table
          [desktopTableUIData]="reportData"
          [desktopTableDetails]="tableHeadersHistory.columns"
          [otherParams]="{ paginationData: true }"
        >
        </app-gt-table>
      </ng-container>
    </nb-tab>
  </nb-tabset>
</nb-card>

<!--dialog box for changing trial of multiple subscribers-->

<ng-template
  #bulkTrialChangeTemplate
  let-data
  let-bulkTrialChangeTemp="dialogRef"
>
  <nb-card class="gt-p-small dialog-box">
    <nb-card-header>
      Are you sure to bulk {{ bulkExpire ? "expire" : "change" }} subscriber
      trial period?
      <nb-icon
        (click)="
          bulkTrialChangeTemp.close();
          bulkExpire = true;
          selectedSubscribers = [];
          expiryTime = null;
          changeReason = null;
          boolSelectSubscribers = false
        "
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon
    ></nb-card-header>
    <nb-card-body>
      <div>
        {{
          boolSelectSubscribers
            ? selectedSubscribers?.length
            : dropdownSubscribers?.count
        }}
        subscribers will be affected.

        <div class="sc-floating-right">
          <nb-toggle
            status="primary"
            toggle-width="1.25"
            toggle-height="0.25"
            (checkedChange)="manualSelect($event)"
            >{{
              boolSelectSubscribers ? "Select All" : "Manual select"
            }}</nb-toggle
          >
        </div>
      </div>
      <div *ngIf="boolSelectSubscribers">
        <nb-tag-list class="nb-tag-list">
          <nb-tag
            *ngFor="let group of selectedSubscribers"
            [text]="group.name"
            removable
            (remove)="removeSubscribers(group)"
          ></nb-tag>
        </nb-tag-list>
        <div class="form-floating">
          <input
            class="form-control"
            type="text"
            nbTagInput
            fullWidth
            placeholder="Select/Search  Subscribers"
            (input)="searchSubscribers($event)"
            [nbAutocomplete]="autoGrpNgModel"
          />
          <label>Select/Search Subscribers</label>
        </div>
        <nb-autocomplete #autoGrpNgModel>
          <nb-option
            *ngFor="let subscriber of dropdownSubscribers.results"
            (click)="
              addSubscribers(subscriber?.subscriber_id, subscriber?.name)
            "
            >{{ subscriber?.name }}
          </nb-option></nb-autocomplete
        >
      </div>
      <div *ngIf="!expiryTime"></div>

      <div *ngIf="expiryTime">
        The subscriber trial will be valid till
        {{ expiryTime | customdate : "d MMM y HH:mm" }}
      </div>
      <div class="col sc-mt-9">
        <textarea
          class="form-control"
          fullWidth
          rows="5"
          type="text"
          placeholder="Add a Reason..."
          [(ngModel)]="changeReason"
        ></textarea>
      </div>
      <div class="row row-cols-2 sc-mt-9">
        <div class="col">
          <div class="form-floating" *ngIf="!expiryTime">
            <input
              class="form-control"
              nbInput
              fullWidth
              placeholder="Trial End Date"
              [nbDatepicker]="dateTimePicker2"
              [(ngModel)]="expiryTime"
            />
            <label>Trial End Date</label>
            <nb-datepicker #dateTimePicker2></nb-datepicker>
          </div>
          <button
            fullWidth
            size="giant"
            ghost
            (click)="
              bulkExpire = true;
              selectedSubscribers = [];
              expiryTime = null;
              changeReason = null
            "
            nbButton
            class="sc-mt-0"
            *ngIf="expiryTime"
          >
            Clear
          </button>
        </div>
        <div class="col">
          <button
            fullWidth
            size="giant"
            nbButton
            class="sc-mt-0"
            (click)="bulkUpdate(); bulkTrialChangeTemp.close()"
            [disabled]="!changeReason"
          >
            Expire
          </button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
