import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { gTDB } from 'src/app/db';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  constructor(private http: HttpClient, private swPush: SwPush) {}
  fetchJobDetails(requestData?: any, params?: any) {
    return this.http
      .post('/api/job/list_jobs/', requestData, { params: params })
      .pipe(map((response) => response));
  }

  getNearestJobs(body?: any, params?: any) {
    return this.http
      .post('/api/job/list_nearest/', body, { params: params })
      .pipe(map((response) => response));
  }
  getCompletedJobs(body?: any, params?: any) {
    return this.http
      .post('/api/job/list_completed/', body, { params: params })
      .pipe(map((response) => response));
  }
  getJobParams() {
    return this.http
      .get('/api/job/get_options/')
      .pipe(map((response) => response));
  }
  createJob(requestData: any, params?: any) {
    return this.http
      .post('api/job/', requestData, { params: params })
      .pipe(map((response) => response));
  }

  getSearchClients(searchData: any) {
    return from(this.fetchCompaniesFromIndexedDb(searchData)).pipe(
      switchMap((dataFromIndexedDb: any) => {
        if (dataFromIndexedDb && dataFromIndexedDb?.length > 0) {
          return of({ status: 'success', data: dataFromIndexedDb });
        } else {
          return this.http
            .get('/api/company/', { params: { search_str: searchData } })
            .pipe(map((response) => response));
        }
      })
    );
    // let indexDbClientList: any = await this.fetchCompaniesFromIndexedDb(
    //   searchData
    // );
    // if (indexDbClientList?.length) {
    //   return of({ status: 'success', data: indexDbClientList });
    // }
  }

  getJobById(jobId: any) {
    const body = {};
    return this.http
      .get(`api/job/${jobId}`, body)
      .pipe(map((response) => response));
  }
  getMobileJobDetailById(jobId: any) {
    return this.http
      .get(`api/job/get_detail/`, { params: { job_id: jobId } })
      .pipe(map((response) => response));
  }
  updateJob(requestData: any) {
    let data = requestData.data;
    const body = {
      ...data,
    };
    return this.http
      .put(requestData.url, body)
      .pipe(map((response) => response));
  }

  getAssignees(param: any) {
    return this.http.get('api/users/get_active_users/', { params: param });
  }
  addNewOption(requestData: any) {
    return this.http.get(`api/${requestData.url}`);
  }
  getSNProviderBillTo() {
    return this.http.get('api/client/smartnet/provider_bill_to/');
  }

  getDashBoardData(params: any) {
    return this.http.get(`/api/dashboard/`, { params: params });
  }

  sendRenewUserLicenseNotifiaction(userId: number, licenseId: number) {
    return this.http.get(`/api/dashboard/send_notification/`, {
      params: { user_id: userId, license_id: licenseId },
    });
  }

  scanCheckPoint(requestData: any) {
    return this.http
      .post(`/api/patrol/scan_checkpoint/`, requestData)
      .pipe(map((response: any) => response));
  }

  getNearestCheckpointList(body?: any, params?: any) {
    return this.http
      .post(`/api/check_point/list_nearest/`, body, { params: params })
      .pipe(map((response: any) => response));
  }
  getAllQRCheckpointList(params?: any) {
    return this.http
      .get(`/api/check_point/list_qr_checkpoints/`, { params: params })
      .pipe(map((response: any) => response));
  }
  getAllClientList(params?: any) {
    return this.http
      .get(`/api/company/list_all_companies/`, { params: params })
      .pipe(map((response: any) => response));
  }
  getNearesSiteList(body?: any, params?: any) {
    return this.http
      .post(`/api/site/list_nearest/`, body, { params: params })
      .pipe(map((response: any) => response));
  }

  jobUpdate(body?: any, params?: any) {
    return this.http
      .post(`/api/job/update_job/`, body, { params: params })
      .pipe(map((response: any) => response));
  }
  getAllJobs(body?: any, params?: any) {
    return this.http
      .post('/api/job/get_all_jobs/', body, { params: params })
      .pipe(map((response) => response));
  }

  async fetchCompaniesFromIndexedDb(searchStr: string) {
    return new Promise(async (resolve) => {
      if (this.swPush.isEnabled) {
        try {
          const clientListData: any = await gTDB.cachedData
            .where({ shortPath: '/api/company/list_all_companies/' })
            .toArray();

          const clientData: any = clientListData?.[0]?.data || [];

          const filteredClientList = clientData?.filter(
            (client: any) =>
              client?.company_name
                ?.toLowerCase()
                .includes(searchStr?.toLowerCase()) ||
              client?.address?.full_address
                ?.toLowerCase()
                .includes(searchStr?.toLowerCase())
          );

          resolve(filteredClientList);
        } catch (error) {
          resolve([]);
        }
      } else {
        resolve([]);
      }
    });
  }
}
