import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { AddCustomerComponent } from 'src/app/shared/comp/add-customer/add-customer.component';
import { FileImportComponent } from 'src/app/shared/comp/file-import/file-import.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { CustomerService } from './customer.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit {
  customerDetails: any;
  customerView: any;
  detailData: any;
  globalFilter: any;
  isFilterApplied: boolean = false;
  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };
  clientCardDetils = {
    cardInfo: {
      individualDetails: [
        {
          name: 'company_name',
          cardRowStyle: { 'font-weight': 'bolder' },
        },
        {
          nestedValue: (row: any) => {
            return row?.address?.full_address;
          },
          cardRowStyle: {
            'font-size': 'small',
          },
        },
      ],
    },
    icons: [
      {
        condition: function (row: any) {
          return {
            icon: 'archive-outline',
            status: 'basic',
            title: 'Archive Client',
            confirm: true,
            show: 'top',
            type: 'archive',
          };
        },
      },
    ],
  };
  showTypeAhead: boolean = true;
  showAutoCustomerDetails$: Observable<any> | undefined;
  userData: any;
  clientCount: number = 0;
  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  clientPaginationData: any = {};
  fileImportInProgress: any;
  totalClientCount: number = 0;
  searchRemovalCap: number = 5;
  addReportUsers: boolean = false;
  userGroup: any;
  isDispatchUser: boolean = false;
  isAdmin: any;
  archivedCount: any;
  showArchivedList: boolean = false;
  lastSearchStr: any;
  apiCallMade: boolean = false;
  lastApiResponseHadData: boolean = true;
  viewType: string = 'cardView';

  sortKey: any;

  clientDetailsTableView: any = [
    {
      header: 'Name',
      name: 'company_name',
      sortKey: 'company_name',
    },
    {
      header: 'Address',

      nestedValue: (row: any) => {
        return row?.address?.full_address;
      },
    },
  ];
  otherParams: any;
  constructor(
    private customerService: CustomerService,
    private spinnerService: NgxSpinnerService,
    private _location: Location,
    private dialogService: NbDialogService,
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private dataCheckService: DataCheckService
  ) {
    this.route.params.subscribe({
      next: (params) => {
        this.addReportUsers = false;
        if (this.route.snapshot.fragment) {
          if (this.route.snapshot.fragment === 'addReportUsers') {
            this.addReportUsers = true;
          }
        }
      },
    });
  }

  ngOnInit(): void {
    this.userData = this.appService.getUserData();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.pageSize = Number(this.userData?.preferences?.client_page_size || 5);
    this.spinnerService.show();
    this.fetchCompanyList();
    if (window.innerWidth >= 770) {
      this.viewType = 'tableView';
    } else {
      this.viewType = 'cardView';
    }
  }

  fetchCompanyList() {
    let params: any = {};

    if (this.pageSize) {
      params['rows'] = this.pageSize;
      let userData = this.appService.getUserData();
      if (userData?.preferences) {
        userData.preferences.client_page_size = this.pageSize;
        this.appService.setUserData(userData);
      }
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    if (this.globalFilter) {
      params['search_str'] = this.globalFilter;
    }
    if (this.sortKey) {
      params['sort_key'] = this.sortKey;
    }
    if (this.addReportUsers) {
      params['report_users'] = 1;
    }
    if (this.showArchivedList) {
      params['is_inactive'] = 1;
    }

    if (
      !this.clientPaginationData?.[this.pageNum]?.length ||
      this.globalFilter
    ) {
      this.spinnerService.show();
      this.customerService.fetchCustomerList(params).subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.isFilterApplied = false;

          this.customerDetails = res['data'];
          this.clientCount = res['total_size'];
          this.archivedCount = res?.archived_count || 0;

          this.totalClientCount = res?.['company_count'] || 0;
          if (this.totalClientCount === 0 && this.isAdmin === true) {
            this.openAddClientForm();
          }
          this.totalPages = Math.ceil(this.clientCount / this.pageSize);
          this.showAutoCustomerDetails$ = undefined;

          this.clientPaginationData[this.pageNum] = [...this.customerDetails];
          this.fileImportInProgress = res['file_import'];

          this.spinnerService.hide();
          this.otherParams = {
            paginationData: {
              tableTotalRows: this.clientCount,
              tablePreviousRows: this.previous,
              tableRows: this.pageSize,
              tablePageNum: this.pageNum,
              tableTotalPages: this.totalPages,
            },
          };
        } else {
          this.spinnerService.hide();
        }
      });
    }
  }
  goBack() {
    if (this.customerView == true) {
      this.globalFilter = '';
      this.customerView = false;
    } else {
      this._location.back();
    }
  }
  openAddClientForm() {
    const dialogRef = this.dialogService.open(AddCustomerComponent, {
      context: {},
      dialogClass: 'model-full',
    });
    dialogRef.onClose.subscribe((value) => {
      this.spinnerService.hide();
      if (value !== 'close') {
        this.clientPaginationData = {};
        this.fetchCompanyList();
      }
    });
  }
  closeDialogue() {
    this.customerView = false;
  }

  openCustomerView(customer: any) {
    this.router.navigate(['/view-client', { cKey: String(customer.id) }]);
  }
  onFilterSearch() {
    let params: any = { rows: 5, search_str: this.globalFilter };
    if (this.showArchivedList) {
      params['is_inactive'] = 1;
    }
    if (this.lastSearchStr) {
      !this.globalFilter.includes(this.lastSearchStr)
        ? (this.apiCallMade = true)
        : (this.apiCallMade = false);
    } else {
      this.apiCallMade = true;
    }
    if (this.apiCallMade || this.lastApiResponseHadData) {
      this.customerService.fetchCustomerList(params).subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.showAutoCustomerDetails$ = of(res['data']);
          this.showAutoCustomerDetails$.subscribe((result: any) => {
            this.lastApiResponseHadData = result.length > 0;
          });
          this.lastSearchStr = this.globalFilter;
        }
      });
    }
  }

  onFilterApply(event: any) {
    if (event?.target?.value && event?.target?.value?.length >= 3) {
      this.isFilterApplied = true;

      this.onFilterSearch();
      this.showTypeAhead = true;
    }
    if (
      event === true &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.isFilterApplied = true;
      this.previous = 0;
      this.clientPaginationData = {};
      this.fetchCompanyList();
      this.showTypeAhead = false;
    } else if (
      event.key === 'Enter' &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.previous = 0;
      this.isFilterApplied = true;
      this.clientPaginationData = {};
      this.fetchCompanyList();
      this.showTypeAhead = false;
    }
  }
  onClearFilter() {
    this.pageNum = 1;
    this.previous = 0;
    this.clientPaginationData = {};
    this.globalFilter = '';
    this.showTypeAhead = true;
    this.isFilterApplied = false;
    this.spinnerService.show();
    this.fetchCompanyList();
  }
  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.fetchCompanyList();
    if (this.globalFilter) this.showTypeAhead = false;
  }
  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.clientPaginationData = {};
    this.fetchCompanyList();
    if (this.globalFilter) this.showTypeAhead = false;
  }
  onChangeTablePagination(data: any) {
    this.previous = data?.paginationData?.tablePreviousRows;
    this.pageNum = data?.paginationData?.tablePageNum;
    this.pageSize = data?.paginationData?.tableRows;

    this.clientPaginationData = {};
    this.fetchCompanyList();
    if (this.globalFilter) this.showTypeAhead = false;
    this.otherParams = {
      paginationData: { ...data?.paginationData },
    };
  }

  openUploadDialogBox() {
    const fileImportDialogRef = this.dialogService.open(FileImportComponent, {
      dialogClass: 'model-full',
    });
    fileImportDialogRef.onClose.subscribe((value) => {
      if (value !== 'close') {
        window.location.reload();
      }
    });
  }

  getArchivedUnarchivedList() {
    this.showArchivedList = !this.showArchivedList;
    this.pageNum = 1;
    this.previous = 0;
    this.clientPaginationData = {};
    this.globalFilter = '';
    this.showTypeAhead = true;
    this.isFilterApplied = false;
    this.spinnerService.show();
    this.fetchCompanyList();
  }
  sortColumn(body: any) {
    this.sortKey = body?.sortList;
    this.clearValues();
    this.fetchCompanyList();
  }
  switchTableCardView() {
    this.viewType == 'tableView'
      ? (this.viewType = 'cardView')
      : (this.viewType = 'tableView');
    // this.updatePreferences();
  }
  searchColumn(event: any) {
    this.globalFilter = event?.searchParams;
    this.fetchCompanyList();
  }
  clearValues() {
    this.previous = 0;
    this.previous = 0;
    this.pageNum = 1;
    this.pageNum = 1;
    this.globalFilter = '';
    this.clientPaginationData = {};
    this.showTypeAhead = true;
  }
}
