import { Component, OnInit, TemplateRef } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { AdminService } from '../admin.service';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { PagesService } from '../../pages.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.scss'],
})
export class UserFeedbackComponent implements OnInit {
  feedbackTableView: any = {
    colums: [
      {
        header: 'Key',
        name: 'feedback_key',
      },

      {
        header: 'Subscriber',
        searchKey: 'subscriber__name',
        nestedValue: (row: any) => {
          return row?.subscriber?.name;
        },
      },
      {
        header: 'User',
        nestedValue: (row: any) => {
          return row?.created_by?.full_name;
        },
      },

      {
        header: 'Status',
        name: 'status',
        searchKey: 'action_status',
      },
      {
        header: 'Created at',
        name: 'created_at',
        datetimeObj: true,
      },
    ],
  };

  previous: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;
  selectedEvent: any;
  feedbackData: any;
  uploadedImage: any;
  fullImgSrc: any;
  dialogref: any;
  feedbcackstatusList: any = [];
  feedbackForm: UntypedFormGroup = new UntypedFormGroup({
    feedback: new UntypedFormControl(''),
    description: new UntypedFormControl('', Validators.required),
    status: new UntypedFormControl('', Validators.required),
  });
  newComment: any;
  sortKey: any;
  feedbackStatus: any = [];
  showDefaultFeedbacks: boolean = true;
  searchFilters: any;
  constructor(
    private toasterService: NbToastrService,
    private adminService: AdminService,
    private _location: Location,
    private spinnerService: NgxSpinnerService,
    private dialog: NbDialogService,
    private pageService: PagesService,
    private router: Router
  ) {
    this.getFeedback();
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
      }
    });
  }

  ngOnInit(): void {}
  getFeedback() {
    let params: any = {};
    if (this.searchFilters) {
      this.showDefaultFeedbacks = false;
      Object.entries(this.searchFilters).forEach(
        ([key, value]) => (params[key] = value)
      );
    }
    if (this.rows) {
      params['page_size'] = this.rows;
    }
    if (this.previous) {
      params['page'] = this.pageNum;
    }
    if (this.sortKey) {
      params['sort_key'] = this.sortKey;
    }
    if (this.showDefaultFeedbacks) {
      params['show_default'] = 1;
    }

    this.spinnerService.show();
    this.selectedEvent = null;
    this.adminService.getFeedbackData(params).subscribe((response: any) => {
      this.feedbackData = response?.results;
      this.feedbcackstatusList = response['action_statuses'];

      this.feedbackStatus = this.feedbcackstatusList.map((status: any) => ({
        id: status[0],
        name: status[1],
      }));
      this.totalRows = response?.count;
      this.totalPages = Math.ceil(this.totalRows / this.rows);
      this.spinnerService.hide();
    });
  }

  oneventClick(event: any) {
    this.spinnerService.show();
    if (event?.id) {
      this.adminService
        .getFeedbackDataById(event.id)
        .subscribe((response: any) => this.formatEvent(response));
    }
  }
  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getFeedback();
  }
  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getFeedback();
  }

  onOpenImage(event: any, requestRef: TemplateRef<any>) {
    const imgElem = event.target;
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;
    this.fullImgSrc = srcAttr.nodeValue;
    this.dialogref = this.dialog.open(requestRef, {
      closeOnBackdropClick: true,
      context: {},
    });
  }
  actionClick(template: TemplateRef<any>) {
    this.feedbackForm.controls['status'].setValue(this.selectedEvent?.status);
    this.dialogref = this.dialog.open(template, {
      context: {},
      dialogClass: 'model-full',
    });
    this.dialogref.onClose.subscribe((value: any) => {
      this.spinnerService.hide();
      this.getFeedback();
    });
  }
  onClearForm() {
    this.feedbackForm.reset();
  }
  onClickSubmit() {
    this.spinnerService.show();
    if (this.feedbackForm.valid) {
      let requestData = this.feedbackForm.value;
      this.feedbcackstatusList.forEach((item: any) => {
        if (this.feedbackForm.controls['status'].value === item[1]) {
          requestData.status = String(item[0]);
        }
      });

      this.adminService
        .editFeedback(this.selectedEvent.id, requestData, { change_status: 1 })
        .subscribe((response: any) => {
          if (response?.status == 'failure') {
            this.pageService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          } else {
            this.formatEvent(response?.data);
          }
          this.feedbackForm.reset();
          this.dialogref.close('close');
        });
    }
  }
  addComment() {
    if (this.newComment) {
      this.spinnerService.show();
      this.adminService
        .editFeedback(
          this.selectedEvent.id,
          { description: this.newComment },
          { add_comment: 1 }
        )
        .subscribe((response: any) => {
          this.spinnerService.hide();
          if (response?.status == 'failure') {
            this.pageService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          } else {
            this.formatEvent(response?.data);
          }
        });
    }
  }
  formatEvent(response: any) {
    this.selectedEvent = response;

    this.feedbackForm.controls['status'].setValue(this.selectedEvent?.status);
    this.newComment = null;

    this.spinnerService.hide();
    this.uploadedImage = this.selectedEvent?.file_url;
    setTimeout(() => {
      var messageBody: any = document.querySelector('.list-fixed-length');
      if (messageBody) {
        messageBody.scrollTop =
          messageBody?.scrollHeight - messageBody?.clientHeight;
      }
    }, 100);
  }
  sortColumn(body: any) {
    this.sortKey = body?.sortList || [];

    this.getFeedback();
  }
  searchColumn(event: any) {
    this.searchFilters = event?.searchParams;

    this.getFeedback();
  }
}
