<div>
  <div class="sc-head5">Most Active Subscribers</div>

  <div class="col-6 form-floating sc-mt-6">
    <input
      class="form-control"
      size="giant"
      nbInput
      fullWidth
      placeholder="Pick Date Range"
      readonly
      [value]="dateRangeValue"
      (click)="openRangePicker()"
    />
    <label>Pick Date Range</label>
  </div>
  <div class="my-3">
    <app-gt-table
      [desktopTableUIData]="transactionsData"
      (rowClick)="onRowClick($event)"
      [desktopTableDetails]="mostActiveSubscriberDetailTable"
      [otherParams]="{ paginationData: true }"
    >
    </app-gt-table>
  </div>
  <div class="sc-head5 sc-mt-6">
    Subscription Details:<span class="sc-floating-right"
      ><nb-toggle
        status="primary"
        toggle-width="1.25"
        toggle-height="0.25"
        (checkedChange)="showSubsChart = !showSubsChart"
        >Show Chart</nb-toggle
      ></span
    >
  </div>
  <div *ngIf="showSubsChart == false">
    <table class="table-desktop">
      <thead>
        <tr>
          <th class="table-desktop-cell" scope="col">Plan Type</th>
          <th class="table-desktop-cell" scope="col">Total Count</th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr colspan="10" *ngFor="let planHeader of planSubsHeaders">
          <td class="table-desktop-cell">
            <nb-icon
              *ngIf="planHeader.expanded"
              (click)="expandColumns(planHeader)"
              [icon]="'arrow-down'"
              pack="eva"
            ></nb-icon
            ><nb-icon
              *ngIf="!planHeader.expanded"
              (click)="expandColumns(planHeader)"
              [icon]="'arrow-right'"
              pack="eva"
            ></nb-icon
            >{{ planHeader.name }}
            <table *ngIf="planHeader.expanded" class="table-desktop sc-ml-1">
              <thead>
                <tr>
                  <th class="table-desktop-cell" scope="col">Name</th>
                  <th class="table-desktop-cell" scope="col">Count</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let usagePlan of planHeader.arrayData">
                  <td class="table-desktop-cell">
                    {{ usagePlan.subscription_plan__name }}
                  </td>
                  <td class="table-desktop-cell">{{ usagePlan.plan_count }}</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td class="table-desktop-cell">{{ planHeader.count }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="showSubsChart == true">
    <highcharts-chart
      [Highcharts]="subsHighcharts"
      [options]="chartSubsOptions"
      style="width: 100%; height: 400px; display: block"
    >
    </highcharts-chart>
  </div>
</div>
<div class="sc-head5 sc-mt-9">
  Job Details:<span class="sc-floating-right"
    ><nb-toggle
      status="primary"
      toggle-width="1.25"
      toggle-height="0.25"
      (checkedChange)="showJobChart = !showJobChart"
      >Show Chart</nb-toggle
    ></span
  >
</div>
<div *ngIf="showJobChart == false">
  <table class="table-desktop sc-ml-1">
    <thead>
      <tr>
        <th class="table-desktop-cell" scope="col">Subscriber's Job</th>
        <th class="table-desktop-cell" scope="col">Total Count</th>
      </tr>
    </thead>
    <tbody>
      <tr colspan="4" *ngFor="let planHeader of planJobHeaders; let i = index">
        <td class="table-desktop-cell">
          <nb-icon
            *ngIf="planHeader.expanded"
            (click)="expandColumns(planHeader)"
            [icon]="'arrow-down'"
            pack="eva"
          ></nb-icon
          ><nb-icon
            *ngIf="!planHeader.expanded"
            (click)="expandColumns(planHeader)"
            [icon]="'arrow-right'"
            pack="eva"
          ></nb-icon
          >{{ planHeader.name }}
          <app-gt-table
            *ngIf="planHeader.expanded"
            [desktopTableUIData]="planHeader.arrayData"
            [desktopTableDetails]="planHeader.subTableHeaders"
            [otherParams]="{ paginationData: true }"
          >
          </app-gt-table>
        </td>
        <td class="table-desktop-cell">{{ planHeader.count }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="showJobChart == true">
  <highcharts-chart
    [Highcharts]="jobHighcharts"
    [options]="chartJobOptions"
    style="width: 100%; height: 400px; display: block"
  >
  </highcharts-chart>
</div>
<div *ngIf="showJobChart == true">
  <highcharts-chart
    [Highcharts]="jobHighcharts"
    [options]="jobStatusChartOptions"
    style="width: 100%; height: 400px; display: block"
  >
  </highcharts-chart>
</div>

<!-- transactions and micro-transactions filtering by date-->

<div class="sc-head5 sc-mt-6">Managers Notes:</div>
<app-gt-table
  [desktopTableUIData]="managerNotesData"
  [desktopTableDetails]="notesHeader"
  [otherParams]="{ paginationData: true }"
>
</app-gt-table>

<div class="sc-head5 sc-mt-6">Database Details:</div>
<app-gt-table
  [desktopTableUIData]="dataBaseDetails"
  [desktopTableDetails]="dataBaseDetailTable"
  [otherParams]="{ paginationData: true }"
>
</app-gt-table>

<div>
  <div>Hourly Transactions</div>
  <div class="row row-cols-lg-2 g-2">
    <div class="form-floating col-lg-6">
      <input
        class="form-control"
        size="lg"
        placeholder="Pick Date Range"
        readonly
        [value]="hourlyTransactionDateRangeValue"
        (click)="openRangePicker('hourly')"
      />
      <label for="dateRange">Pick Date Range</label>
    </div>
    <div class="form-floating col-lg-6">
      <select
        class="form-select form-select-lg"
        (change)="changeWeekDay($event)"
      >
        <option *ngFor="let week of weekDayMapping" [value]="week.value">
          {{ week.value }}
        </option>
      </select>
    </div>
  </div>

  <ng-container *ngIf="hourlyTransactionData?.length">
    <app-bar-chart
      [barChartData]="hourlyTransactionData"
      [charInfo]="{
        label: { name: 'hour' },
        value: { name: 'count' }
      }"
    ></app-bar-chart
  ></ng-container>

  <app-gt-table
    [desktopTableUIData]="hourlyTransactionData"
    [desktopTableDetails]="[
      {
        header: 'Hour',
        name: 'hour'
      },
      { header: 'Transactions Count', name: 'count', sortKey: 'count' }
    ]"
    [otherParams]="{ paginationData: true }"
  >
  </app-gt-table>
</div>
