<!-- MOBILE -->
<div class="mobile-only px-2">
  <div *ngIf="responseJobType.site.data" class="strong ms-2 mb-2">
    {{ responseJobType.client.value }}
  </div>
  <form [formGroup]="formdata">
    <ng-container>
      <div class="form-floating">
        <input
          id="mobsiteField"
          autofocus
          class="form-control"
          fullWidth
          formControlName="siteName"
          (input)="onSiteSearch($event)"
          nbInput
          type="text"
          placeholder="Site Name"
          [nbAutocomplete]="siteACMob"
          autocomplete="off"
        />
        <label>Site Name</label>
      </div>
      <nb-autocomplete #siteACMob (selectedChange)="onSelectSite($event)">
        <nb-option [value]="' '" *ngIf="isAdmin || isDispatchUser">
          <b> Add Site</b>
        </nb-option>
        <nb-option *ngFor="let item of siteList" [value]="item">
          <div>
            <div class="strong">
              {{ item?.company_name }}
            </div>
            <div class="small-font uppercase">
              {{ item?.company?.company_name }}
            </div>
            <div class="small-font">{{ item?.address?.full_address }}</div>
          </div>
        </nb-option>
      </nb-autocomplete>

      <ng-container *ngIf="responseJobType.site.data">
        <!--display this section only after a site has been selected-->
        <div
          class="form-floating"
          *ngIf="selectedStatus.enable_assignees === true"
        >
          <input
            class="form-control"
            id="mobassigneeField"
            fullWidth
            (input)="onAssigneeSearch($event)"
            nbInput
            type="text"
            placeholder="Assign Guard"
            formControlName="assigneeName"
            [nbAutocomplete]="assigneeNgModelMobile"
            autocomplete="off"
          />
          <label>Assign Guard</label>
        </div>
        <nb-autocomplete
          #assigneeNgModelMobile
          (selectedChange)="onAssigneeSelect($event)"
        >
          <nb-option *ngFor="let assignee of assigneeList" [value]="assignee">
            {{ assignee?.full_name }}
            <small *ngIf="!assignee?.validStateLicense">
              <nb-icon [icon]="'shield-off-outline'" pack="eva"> </nb-icon>
            </small>
          </nb-option>
        </nb-autocomplete>

        <nb-form-field>
          <div class="form-floating">
            <input
              id="mobrtField"
              class="form-control"
              fullWidth
              (input)="onRtSearch($event)"
              nbInput
              type="text"
              placeholder="Response Type"
              formControlName="responseTypeName"
              [nbAutocomplete]="ResponseTypeNgModelMobile"
              autocomplete="off"
            />
            <label>Response Type</label>
          </div>

          <nb-icon
            nbSuffix
            (click)="
              this.formdata.controls['responseTypeName'].setValue(null);
              this.responseJobType.rt.value = null;
              this.responseJobType.rt.data = null
            "
            icon="close-outline"
            pack="eva"
          >
          </nb-icon>
        </nb-form-field>
        <nb-autocomplete
          #ResponseTypeNgModelMobile
          (selectedChange)="onResponseTypeSelect($event)"
        >
          <nb-option [value]="' '" *ngIf="isAdmin">
            <b> Add Response Type</b>
          </nb-option>
          <nb-option
            *ngFor="let responseType of responseTypeList"
            [value]="responseType"
          >
            {{ responseType.name }}
          </nb-option>
        </nb-autocomplete>

        <nb-form-field>
          <div class="form-floating">
            <input
              class="form-control"
              id="mobmcField"
              fullWidth
              (input)="onMcSearch($event)"
              nbInput
              type="text"
              placeholder="Monitoring Company"
              formControlName="monitoringCompanyName"
              [nbAutocomplete]="MonitoringCompanyNgModel"
              autocomplete="off"
            />
            <label>Monitoring Company</label>
          </div>
          <nb-icon
            nbSuffix
            (click)="
              formdata.controls['monitoringCompanyName'].setValue(null);
              responseJobType.mc.value = null;
              responseJobType.mc.data = null
            "
            icon="close-outline"
            pack="eva"
          >
          </nb-icon>
        </nb-form-field>
        <nb-autocomplete
          #MonitoringCompanyNgModel
          (selectedChange)="onMonitoringSelect($event)"
          ><nb-option [value]="' '" *ngIf="isAdmin" class="strong">
            <b> Add Monitoring Company</b>
          </nb-option>
          <nb-option
            *ngFor="let companyData of monitoringCompanyList"
            [value]="companyData"
          >
            {{ companyData.company_name }}
          </nb-option>
        </nb-autocomplete>

        <div class="col">
          <button
            (click)="openTemplate(addInfoDialogRef)"
            nbButton
            class="nbbutton-long-text"
            size="giant"
            fullWidth
            [ngClass]="responseJobType.ai.value ? '' : 'outline-btn'"
          >
            Additional Information
          </button>
        </div>
        <div class="form-floating">
          <input
            class="form-control"
            id="mobeIField"
            fullWidth
            nbInput
            type="text"
            placeholder="Ext. Job ID"
            formControlName="externalJobId"
            autocomplete="off"
            maxlength="32"
          />
          <label>Ext. Job ID</label>
        </div>
        <div class="form-floating">
          <input
            id="mobocField"
            class="form-control"
            fullWidth
            formControlName="siteContactName"
            nbInput
            type="text"
            placeholder="Onsite Contact (Optional)"
            [nbAutocomplete]="contactAutoNgModel"
            autocomplete="off"
          />
          <label>Onsite Contact (Optional)</label>
          <nb-autocomplete
            #contactAutoNgModel
            (selectedChange)="selectContact($event)"
          >
            <nb-option
              [value]="' '"
              *ngIf="isAdmin && responseJobType.site.data"
            >
              <b> Add New Contact</b>
            </nb-option>
            <nb-option *ngFor="let contact of siteContacts" [value]="contact">{{
              contact?.full_name
            }}</nb-option>
          </nb-autocomplete>
        </div>
      </ng-container>
      <div>
        <button
          fullWidth
          [disabled]="!responseJobType.site.data || addJobPressed"
          nbButton
          status="primary"
          size="giant"
          (click)="onAddJob()"
        >
          Add Job
        </button>
        <button
          fullWidth
          id="cancel-btn"
          type="button"
          [routerLink]="['/jobs']"
          [queryParams]="{ cancel: true }"
          nbButton
          ghost
          size="giant"
        >
          Cancel
        </button>
      </div>
    </ng-container>
  </form>
</div>

<!-- DESKTOP -->
<div class="large-view-only">
  <div class="row row-cols-2">
    <div class="col job-detail-section">
      <!--job details section-->

      <form [formGroup]="formdata">
        <!-- client name -->
        <div
          *ngIf="responseJobType.client.data"
          class="job-detail-event-without-pointer"
        >
          <span class="job-detail-key">{{ responseJobType.client.name }}</span>
          <span class="job-detail-value"
            >{{ responseJobType.client.value }}
          </span>
        </div>
        <!--site section-->
        <div>
          <div
            (click)="editOtherJobTypeItem('site')"
            class="job-detail-event"
            *ngIf="!responseJobType.site.edit"
          >
            <span class="job-detail-key">{{ responseJobType.site.name }}</span>
            <span class="job-detail-value"
              >{{ responseJobType.site.value }}
              <span class="job-detail-icon">
                <nb-icon icon="edit-outline" pack="eva"> </nb-icon>
              </span>
            </span>
          </div>
          <div
            class="form-floating edit-field"
            *ngIf="responseJobType.site.edit"
          >
            <input
              autofocus
              class="form-control"
              fullWidth
              formControlName="siteName"
              (input)="onSiteSearch($event)"
              nbInput
              type="text"
              autocomplete="off"
              placeholder="Search/Select Site"
              [nbAutocomplete]="siteACDesk"
              id="desksiteField"
            />
            <label>Search/Select Site</label>
          </div>

          <nb-autocomplete #siteACDesk (selectedChange)="onSelectSite($event)">
            <nb-option [value]="' '" *ngIf="isAdmin">
              <b> Add Site</b>
            </nb-option>
            <nb-option *ngFor="let item of siteList" [value]="item">
              <div>
                <div class="strong">
                  {{ item?.company_name }}
                </div>
                <div class="small-font uppercase">
                  {{ item?.company?.company_name }}
                </div>
                <div class="small-font">{{ item?.address?.full_address }}</div>
              </div>
            </nb-option>
          </nb-autocomplete>
        </div>

        <!--assignee section-->
        <div>
          <div
            (click)="editOtherJobTypeItem('assignee')"
            class="job-detail-event"
            *ngIf="!responseJobType.assignee.edit"
          >
            <span class="job-detail-key">{{
              responseJobType.assignee.name
            }}</span>
            <span class="job-detail-value"
              >{{ responseJobType.assignee.value }}
              <span class="job-detail-icon">
                <nb-icon icon="edit-outline" pack="eva"> </nb-icon>
              </span>
            </span>
          </div>
          <div
            class="form-floating edit-field"
            *ngIf="responseJobType.assignee.edit"
          >
            <input
              autofocus
              class="form-control"
              fullWidth
              (input)="onAssigneeSearch($event)"
              nbInput
              type="text"
              placeholder="Assign Guard"
              [nbAutocomplete]="assigneeACDesk"
              formControlName="assigneeName"
              autocomplete="off"
              id="deskassigneeField"
            />
            <label>Assign Guard</label>
          </div>

          <nb-autocomplete
            #assigneeACDesk
            (selectedChange)="onAssigneeSelect($event)"
          >
            <nb-option *ngFor="let assignee of assigneeList" [value]="assignee">
              {{ assignee?.full_name }}
              <small *ngIf="!assignee?.validStateLicense">
                <nb-icon [icon]="'shield-off-outline'" pack="eva"> </nb-icon>
              </small>
            </nb-option>
          </nb-autocomplete>
        </div>

        <!--response type section-->

        <div>
          <div
            (click)="editOtherJobTypeItem('rt')"
            class="job-detail-event"
            *ngIf="!responseJobType.rt.edit"
          >
            <span class="job-detail-key">{{ responseJobType.rt.name }}</span>
            <span class="job-detail-value"
              >{{ responseJobType.rt.value }}
              <span class="job-detail-icon">
                <nb-icon icon="edit-outline" pack="eva"> </nb-icon>
              </span>
            </span>
          </div>
          <div class="form-floating edit-field" *ngIf="responseJobType.rt.edit">
            <input
              id="deskrtField"
              class="form-control"
              fullWidth
              (input)="onRtSearch($event)"
              nbInput
              type="text"
              placeholder="Response Type"
              formControlName="responseTypeName"
              [nbAutocomplete]="rtACDesk"
              autocomplete="off"
            />
            <label>Response Type</label>
          </div>

          <nb-autocomplete
            #rtACDesk
            (selectedChange)="onResponseTypeSelect($event)"
          >
            <nb-option [value]="' '" *ngIf="isAdmin">
              <b> Add Response Type</b>
            </nb-option>
            <nb-option
              *ngFor="let responseType of responseTypeList"
              [value]="responseType"
            >
              {{ responseType.name }}
            </nb-option>
          </nb-autocomplete>
        </div>

        <!--monitoring fields information section-->

        <div>
          <div
            (click)="editOtherJobTypeItem('mc')"
            class="job-detail-event"
            *ngIf="!responseJobType.mc.edit"
          >
            <span class="job-detail-key">{{ responseJobType.mc.name }}</span>
            <span class="job-detail-value"
              >{{ responseJobType.mc.value }}
              <span class="job-detail-icon">
                <nb-icon icon="edit-outline" pack="eva"> </nb-icon>
              </span>
            </span>
          </div>
          <div class="form-floating edit-field" *ngIf="responseJobType.mc.edit">
            <input
              class="form-control"
              id="deskmcField"
              fullWidth
              (input)="onMcSearch($event)"
              nbInput
              type="text"
              placeholder="Monitoring Company"
              formControlName="monitoringCompanyName"
              [nbAutocomplete]="mcACDesk"
              autocomplete="off"
            />
            <label>Monitoring Company</label>
          </div>

          <nb-autocomplete
            #mcACDesk
            (selectedChange)="onMonitoringSelect($event)"
            ><nb-option [value]="' '" *ngIf="isAdmin" class="strong">
              <b> Add Monitoring Company</b>
            </nb-option>
            <nb-option
              *ngFor="let companyData of monitoringCompanyList"
              [value]="companyData"
            >
              {{ companyData.company_name }}
            </nb-option>
          </nb-autocomplete>
        </div>

        <!--additional information section-->
        <div>
          <div
            (click)="editOtherJobTypeItem('ai')"
            class="job-detail-event"
            *ngIf="!responseJobType.ai.edit"
          >
            <span class="job-detail-key">{{ responseJobType.ai.name }}</span>
            <span class="job-detail-value"
              >{{ responseJobType.ai.value }}
              <span class="job-detail-icon">
                <nb-icon icon="edit-outline" pack="eva"> </nb-icon>
              </span>
            </span>
          </div>
          <div class="edit-field" *ngIf="responseJobType.ai.edit">
            <textarea
              class="form-control"
              placeholder="Additional Information (Optional)"
              cols="20"
              rows="10"
              wrap="hard"
              maxlength="900"
              fullWidth
              type="text"
              formControlName="requestDetail"
              [(ngModel)]="responseJobType.ai.value"
              id="deskaiField"
            ></textarea>
          </div>
        </div>
        <!--external job id section-->
        <div>
          <div
            (click)="editOtherJobTypeItem('eI')"
            class="job-detail-event"
            *ngIf="!responseJobType.eI.edit"
          >
            <span class="job-detail-key">{{ responseJobType.eI.name }}</span>
            <span class="job-detail-value"
              >{{ responseJobType.eI.value }}
              <span class="job-detail-icon">
                <nb-icon icon="edit-outline" pack="eva"> </nb-icon>
              </span>
            </span>
          </div>
          <div class="edit-field form-floating" *ngIf="responseJobType.eI.edit">
            <input
              class="form-control"
              id="deskeIField"
              fullWidth
              nbInput
              type="text"
              placeholder="External Job ID"
              formControlName="externalJobId"
              [(ngModel)]="responseJobType.eI.value"
              autocomplete="off"
              maxlength="32"
            />
            <label>External Job ID</label>
          </div>
        </div>
        <!--on site contact section-->
        <div>
          <div
            (click)="editOtherJobTypeItem('oc')"
            class="job-detail-event"
            *ngIf="!responseJobType.oc.edit"
          >
            <span class="job-detail-key">{{ responseJobType.oc.name }}</span>
            <span class="job-detail-value"
              >{{ responseJobType.oc.value }}
              <span class="job-detail-icon">
                <nb-icon icon="edit-outline" pack="eva"> </nb-icon> </span
            ></span>
          </div>
          <div class="edit-field form-floating" *ngIf="responseJobType.oc.edit">
            <input
              id="deskocField"
              class="form-control"
              fullWidth
              formControlName="siteContactName"
              nbInput
              type="text"
              placeholder="Onsite Contact (Optional)"
              [nbAutocomplete]="ocACDesk"
              autocomplete="off"
            />
            <label>Onsite Contact (Optional)</label>
            <nb-autocomplete #ocACDesk (selectedChange)="selectContact($event)">
              <nb-option [value]="' '">
                <b> Add New Contact</b>
              </nb-option>
              <nb-option
                *ngFor="let contact of siteContacts"
                [value]="contact"
                >{{ contact?.full_name }}</nb-option
              >
            </nb-autocomplete>
          </div>
        </div>

        <div>
          <button
            fullWidth
            (click)="onAddJob()"
            nbButton
            size="giant"
            [disabled]="!responseJobType.site.data || addJobPressed"
          >
            Add Job
          </button>
          <button
            fullWidth
            type="button"
            [routerLink]="['/jobs']"
            [queryParams]="{ cancel: true }"
            nbButton
            ghost
            size="giant"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
    <div class="col">
      <!--job site map section-->

      <div
        class="col"
        *ngIf="
          showSiteMap &&
          responseJobType.site?.data?.address?.latitude &&
          responseJobType.site?.data?.address?.longitude
        "
      >
        <app-map-leaflet
          [latLon]="{
            lat: responseJobType.site?.data?.address?.latitude,
            lon: responseJobType.site?.data?.address?.longitude
          }"
          [mapId]="'largeJob'"
        ></app-map-leaflet>
        <div
          *ngIf="
            responseJobType.site?.data?.address?.latitude &&
            responseJobType.site?.data?.address?.longitude
          "
          style="
            text-align: center;
            font-size: 0.75rem;
            text-transform: uppercase;
          "
        >
          Lat: {{ responseJobType.site?.data?.address?.latitude }} Lon:
          {{ responseJobType.site?.data?.address?.longitude }}
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Additional Information POPOVER / DIALOG -->
<ng-template #addInfoDialogRef let-data let-addInfoDialogRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center"
      >Additional Information
    </nb-card-header>

    <!-- Request Detail Section start-->
    <div class="row">
      <div class="col">
        <textarea
          class="form-control"
          placeholder="Additional Information"
          cols="20"
          rows="10"
          wrap="hard"
          maxlength="900"
          fullWidth
          type="text"
          [(ngModel)]="responseJobType.ai.value"
        ></textarea>
      </div>
    </div>

    <div class="row sc-mt-6">
      <div class="col flex-center">
        <button
          nbButton
          class="nbbutton-long-text"
          fullWidth
          size="giant"
          (click)="addInfoDialogRef.close(); addAI()"
        >
          {{ responseJobType.ai.value ? "Save" : "Close" }}
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>

<!--license reminder checkTemplate-->
<ng-template
  #licenceCheckTemplateRef
  let-data
  let-licenceCheckTemplateRef="dialogRef"
>
  <nb-card class="dialog-box">
    <nb-card-header
      >WARNING
      <nb-icon
        (click)="
          licenceCheckTemplateRef.close(); editOtherJobTypeItem('assignee')
        "
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <p>
        You have not added any security licences for
        {{ responseJobType?.site?.data?.address?.state_code }}
      </p>
      <p>
        Licences can be added in
        <span
          [routerLink]="['/company-profile']"
          (click)="ignoreLicenseCheck(); licenceCheckTemplateRef.close()"
          >My Company</span
        >
      </p>

      <div>
        <nb-checkbox
          (checkedChange)="licenseReminderField = !licenseReminderField"
          >Don't show this again</nb-checkbox
        >
      </div>

      <div class="row row-cols-2">
        <div class="col">
          <button
            nbButton
            matRipple
            fullWidth
            ghost
            size="giant"
            (click)="ignoreLicenseCheck(); licenceCheckTemplateRef.close()"
          >
            Ignore
          </button>
        </div>
        <div class="col">
          <button
            nbButton
            matRipple
            size="giant"
            (click)="showLicensePopup(); licenceCheckTemplateRef.close()"
            fullWidth
          >
            Add
          </button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
