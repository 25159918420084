import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/auth/auth.service';
import { AddCustomerComponent } from 'src/app/shared/comp/add-customer/add-customer.component';
import { AddEditSiteComponent } from 'src/app/shared/comp/add-edit-site/add-edit-site.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { CustomerService } from '../../customer/customer.service';
import { JobsService } from '../../jobs/jobs.service';
import { PagesService } from '../../pages.service';
import { AddEditIncidentTypeComponent } from '../incident-type/add-edit-incident-type/add-edit-incident-type.component';
import { IncidentsService } from '../incidents.service';
@Component({
  selector: 'app-add-incident',
  templateUrl: './add-incident.component.html',
  styleUrls: ['./add-incident.component.scss'],
})
export class AddIncidentComponent implements OnInit {
  formdata = new UntypedFormGroup({
    clientName: new UntypedFormControl('', Validators.required),
    siteName: new UntypedFormControl('', Validators.required),
  });
  contactForm: UntypedFormGroup = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    title: new UntypedFormControl(),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    mobile_number: new UntypedFormControl('', [
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9]*$'),
    ]),
  });
  incidentDict: any = {
    client: {
      name: 'Client',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    site: { name: 'Site', value: '', edit: false, data: null, tempValue: '' },
    incidentType: {
      name: 'Incident Type',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },

    incidentTime: {
      name: 'Incident Time',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    iTime: {
      name: 'I Time',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    iDay: {
      name: 'I Day',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
  };
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;
  siteLicenceReminderCheck: boolean = false;
  licenseReminderField: boolean = false;
  showSiteMap: boolean = false;

  userData: any;

  dialogRef: any;

  siteList: any = [];
  addIncidentButtonPressed: boolean = false;

  clientList: any = [];
  incidentTypeList: any = [];
  tempIncidentTypeList: any = [];
  todayDate: any = new Date();

  constructor(
    private incidentService: IncidentsService,
    private dialogService: NbDialogService,
    private customerService: CustomerService,
    private authService: AuthService,
    private pageService: PagesService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private spinnerService: NgxSpinnerService,
    private jobService: JobsService
  ) {
    this.userData = this.authService.getUserData();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.siteLicenceReminderCheck = this.userData?.checks?.license_reminder;

    router.events.subscribe((event: any) => {
      // close dialog boxes if any are open when back is pressed
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });
  }

  ngOnInit(): void {
    this.incidentDict.incidentTime.value = new Date();

    this.incidentDict.iDay.value = new Date();
    // this.incidentDict.iTime.value = this.convertTime12to24(new Date());
    this.checkEndTime();
    this.getIncidentTypes();
    this.editInputField('client');
  }
  onItSearch(event: any) {
    this.incidentDict.incidentType.tempValue = event.target.value;
    if (event?.target?.value.length > 2) {
      this.getIncidentTypes({ search_str: event?.target?.value });
    }

    this.incidentDict.incidentType.data = null;
  }

  getIncidentTypes(params?: any) {
    this.incidentService.fetchIncidentTypeList(params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.incidentTypeList = res['data'];
      }
    });
  }
  checkEndTime() {
    var abc: any;
    abc = new Date();
    abc.setHours(parseInt(abc.getHours()));
    abc.setMinutes(parseInt(abc.getMinutes()));
    abc.setSeconds(5);
    abc = abc.toLocaleTimeString('en-Gb', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      hourCycle: 'h23',
    });
    abc = abc.replace(':05', '');

    this.incidentDict.iTime.value = abc;
  }

  onClientSearch(event: any) {
    // in case add client auto fill the form
    this.clearValues('client');
    this.incidentDict.client.tempValue = event.target.value;
    if (event.target.value?.length > 2) {
      this.jobService
        .getSearchClients(event.target.value)
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.clientList = res['data'];
          }
        });
    }
  }
  onClientSelect(data: any) {
    if (data === ' ') {
      this.openAddCustomerForm();
    }
    if (data?.id) {
      this.clearValues('site');
      this.incidentDict.client.value = data?.company_name;
      this.incidentDict.client.data = data;
      this.formdata.controls['clientName'].setValue(data?.company_name);
      this.formdata.controls['siteName'].setValue(null);
      this.editInputField('site');

      this.customerService
        .getSites({ company: data?.id, is_active: 1 })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.siteList = res['data'];
          }
        });
    }
  }
  onSearchIncidentType(event: any) {
    if (event.target.value?.length > 0) {
      this.incidentTypeList = this.tempIncidentTypeList?.filter((item: any) =>
        item?.toLowerCase().includes(event.target.value)
      );
    } else {
      this.incidentTypeList = this.tempIncidentTypeList;
    }
  }
  onSelectIncidentType(event: any) {
    if (event === ' ') {
      this.openAddIncidentType();
    }
    if (event?.id) {
      this.incidentDict.incidentType.value = event?.name;
      this.incidentDict.incidentType.data = event;
      this.editInputField('iDay');
    }
  }
  openAddCustomerForm() {
    this.dialogRef = this.dialogService.open(AddCustomerComponent, {
      context: { customerName: this.incidentDict.client.tempValue },
      dialogClass: 'model-full',
    });

    this.dialogRef.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.onClientSelect(value?.data);
        // this.value = value?.data?.company_name;
      }
    });
  }
  openAddIncidentType() {
    this.dialogRef = this.dialogService.open(AddEditIncidentTypeComponent, {
      context: {
        name: this.incidentDict.incidentType.tempValue,
      },
    });

    this.dialogRef.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.onSelectIncidentType(value?.data);
      }
    });
  }

  clearValues(_field: string) {
    if (_field == 'client') {
      this.incidentDict.client = {
        ...this.incidentDict.client,
        ...{ value: '', data: null, tempValue: '' },
      };

      this.incidentDict.site = {
        ...this.incidentDict.site,
        ...{ value: '', data: null, tempValue: '' },
      };
    }
    if (_field == 'site') {
      this.incidentDict.site = {
        ...this.incidentDict.site,
        ...{ value: '', data: null, tempValue: '' },
      };
    }
  }

  onSiteSearch(event: any) {
    // in case add client auto fill the form
    this.clearValues('site');
    this.incidentDict.site.tempValue = event.target.value;
    if (event.target.value?.length > 2) {
      this.customerService
        .getSites({
          search_str: event.target.value,
          company: this.incidentDict.client.data?.id,
          is_active: 1,
        })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.siteList = res['data'];
          }
        });
    }
  }
  onSelectSite(data: any) {
    if (data === ' ') {
      this.openAddSiteForm();
    }

    if (data?.id) {
      this.clearValues('oc');
      this.showSiteMap = true;
      this.incidentDict.site.value = data?.company_name;
      this.incidentDict.site.data = data;
      this.formdata.controls['siteName'].setValue(data?.company_name);

      this.editInputField('incidentType');
    }
  }
  openAddSiteForm() {
    if (this.incidentDict.client.data) {
      this.dialogRef = this.dialogService.open(AddEditSiteComponent, {
        context: {
          detailData: { company: this.incidentDict.client.data?.id },
          siteAddress: this.incidentDict.site.tempValue,
        },
        dialogClass: 'model-full',
      });
      this.dialogRef.onClose.subscribe((value: any) => {
        if (value !== 'close') {
          this.onSelectSite(value);
        }
      });
    } else {
      this.pageService.setMessage({
        errorMessage: 'Please select a client first',
        successMessage: '',
      });
    }
  }
  onAddIncident() {
    this.spinnerService.show();
    this.addIncidentButtonPressed = true;
    let incidentData = {
      site_id: this.incidentDict?.site?.data?.id,
      incident_time: this.incidentDict.incidentTime.value,

      incident_type: this.incidentDict.incidentType.data?.id,
    };
    this.incidentService
      .addIncident(incidentData)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          if (response?.data)
            this.router.navigate([
              '/view-incident',
              { inKey: String(response?.data?.id) },
            ]);
        } else {
          this.addIncidentButtonPressed = false;
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }

  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      if (['mobiTimeField', 'deskTimeField'].includes(elementId)) {
        element?.click();
      }
      element?.focus();
    });
  }
  editInputField(field: string) {
    for (let key in this.incidentDict) {
      this.incidentDict[key]['edit'] = false;
    }
    this.incidentDict[field]['edit'] = true;
    if (window.innerWidth > 769) {
      this.shiftFocus(`desk${field}Field`);
    } else {
      this.shiftFocus(`mob${field}Field`);
    }
  }
  formateStartDate(event: any) {
    this.editInputField('iTime');
    this.incidentDict.iDay.value = event;
    this.incidentDict.incidentTime.value = event;
  }

  formatStartTime(event: any) {
    let startTime = this.convertTime12to24(event);
    var [hours, minutes] = startTime.split(':');
    let startDateTime = new Date(this.incidentDict.incidentTime.value);
    startDateTime.setHours(parseInt(hours));
    startDateTime.setMinutes(parseInt(minutes));

    if (startDateTime.getTime() > new Date().getTime()) {
      this.pageService.setMessage({
        successMessage: '',
        errorMessage: 'cannot set future date',
      });
      this.editInputField('iTime');
    } else {
      this.incidentDict.iTime.value = event;
      this.incidentDict.incidentTime.value = startDateTime;
    }
  }
  convertTime12to24(time12h: any) {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours == '12') {
      hours = '00';
    }

    if (modifier.toUpperCase() === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }
}
