<nb-card class="dialog-box">
  <nb-card-header
    >Bulk Upload Clients

    <nb-icon
      (click)="onCloseDialogue()"
      [icon]="'close-outline'"
      class="sc-floating-right"
      pack="eva"
    ></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <table>
      <thead>
        <tr>
          <th>Client Name</th>
          <th>Sub Address*</th>
          <th>Street Number</th>
          <th>Street Name</th>
          <th>City Name</th>
          <th>State**</th>
          <th>Post Code</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Guard Tracker</td>
          <td>Suite 2</td>
          <td>345</td>
          <td>PACIFIC HIGHWAY</td>
          <td>LINDFIELD</td>
          <td>NSW</td>
          <td>2070</td>
        </tr>
      </tbody>
    </table>

    <em>
      * Optional Field
      <br />
      ** Will only accept short form for State
      <div class="sc-list">
        Australian Capital Territory-ACT,New South Wales-NSW ,Northern
        Territory-NT, Queensland-QLD,South Australia-SA, Tasmania-TAS,
        Victoria-VIC,Western Australia-WA
      </div>
    </em>

    <hr />
    <div class="row row-cols-2">
      <div class="col">
        <button
          fullWidth
          nbButton
          class="nbbutton-long-text"
          size="medium"
          status="primary"
          (click)="downloadTemplate()"
        >
          Download Template
        </button>
      </div>

      <div class="col">
        <button
          fullWidth
          nbButton
          class="nbbutton-long-text"
          size="medium"
          status="primary"
          (click)="uploadFile()"
        >
          {{ uploadedFile ? "Change File" : "Upload File" }}
        </button>

        <input
          #fileInput
          id="fileInput"
          nbInput
          style="display: none; cursor: pointer"
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          (change)="handleFileInput($event)"
        />
      </div>
    </div>

    <div class="flex-center">
      <button
        nbButton
        size="giant"
        status="primary"
        (click)="onFileSubmit()"
        [disabled]="!uploadedFile"
      >
        Upload {{ uploadedFile?.name }}
      </button>
    </div>
  </nb-card-body></nb-card
>
