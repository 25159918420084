<nb-card class="gt-p-1">
  <app-gt-table
    [desktopTableUIData]="paymentData"
    [desktopTableDetails]="paymentDetailsTableView"
    [otherParams]="{ paginationData: true }"
  >
  </app-gt-table>
</nb-card>

<nb-card class="gt-p-1">
  <app-gt-table
    [desktopTableUIData]="invoiceListData.results"
    [desktopTableDetails]="invoiceTableView"
    [otherParams]="otherParamsInvoices"
    (paginationChange)="onChangePaginationInvoices($event)"
    (searchColumn)="searchColumnInvoices($event)"
    (sortColumn)="sortColumn($event)"
  >
  </app-gt-table>
</nb-card>
