import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateTimePickerComponent } from 'src/app/shared/comp/date-time-picker/date-time-picker.component';
import { CheckpointService } from '../checkpoints/checkpoint.service';
import { PagesService } from '../pages.service';
import { UsersService } from '../users/users.service';
import { formatDateTimeStamp } from 'src/global.variables';

@Component({
  selector: 'app-missed-events',
  templateUrl: './missed-events.component.html',
  styleUrls: ['./missed-events.component.scss'],
})
export class MissedEventsComponent implements OnInit {
  //missed welfare check data variables
  totalRowsMissedWelfareCheck: number = 0;
  previousMissedWelfareCheck: number = 0;
  pageSizeMissedWelfareCheck: number = 10;
  pageNumMissedWelfareCheck: number = 1;
  totalPagesMissedWelfareCheck: number = 0;
  searchParamsMissedWelfareCheck: any;
  sortKeysMissedWelfareCheck: any = ['-missed_at'];
  dateRangeMissedWelfareCheck: any;
  dateRangeValueMissedWelfareCheck: any;

  otherParamsMissedWelfareCheck: any;
  missedWelfareData: any;
  tableDetailMissedWelfareCheck = [
    {
      header: 'Guard',
      name: 'full_name',
      searchKey: 'full_name',
      sortKey: 'full_name',
    },
    {
      header: 'Event',
      name: 'event_key',
    },
    {
      header: 'Time Range',
      nestedValue: (row: any) => {
        return `${row?.start_time} - ${row?.end_time}`;
      },
    },
    {
      header: 'Missed At',
      name: 'missed_at',
      datetimeObj: true,
      dateRangeKey: 'missed_at',
      sortKey: 'missed_at',
      default: true,
    },

    {
      header: 'Last Activity At',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
    },
    {
      header: 'Last Activity',
      name: 'action',
    },
  ];
  mobileDetailMissedWelfareCheck = {
    individualDetails: [
      {
        name: 'full_name',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
          'font-weight': 'bold',
        },
      },
      {
        prefix: 'Missed At: ',
        name: 'missed_at',

        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
          'margin-top': '0.5rem',
          'font-weight': 'bold',
        },
      },

      {
        prefix: 'Last Activity: ',
        nestedValue: (row: any) => {
          return `${row?.action} | ${String(
            formatDateTimeStamp(row?.updated_at, 'd MMM y HH:mm', 'en_US')
          )}`;
        },

        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };

  //missed checkpoint data variables
  totalRowsMissedCheckpoint: number = 0;
  previousMissedCheckpoint: number = 0;
  pageSizeMissedCheckpoint: number = 10;
  pageNumMissedCheckpoint: number = 1;
  totalPagesMissedCheckpoint: number = 0;
  searchParamsMissedCheckpoint: any;
  sortKeysMissedCheckpoint: any = [];
  dateRangeMissedCheckpoint: any;
  dateRangeValueMissedCheckpoint: any;

  otherParamsMissedCheckpoint: any;
  missedCheckpointData: any;
  tableDetailMissedCheckpoint = [
    {
      header: 'Checkpoint',
      name: 'checkpoint_name',
      searchKey: 'check_point__name',
      sortKey: 'check_point__name',
    },
    {
      header: 'Address',
      name: 'checkpoint_address',
      searchKey: 'full_address',
      sortKey: 'full_address',
    },
    {
      header: 'Client',
      name: 'company_name',
      searchKey: 'check_point__company__company_name',
      sortKey: 'check_point__company__company_name',
    },
    {
      header: 'Patrol Route',
      name: 'patrol_route_name',
      searchKey: 'patrol_route__name',
      sortKey: 'patrol_route__name',
    },
    {
      header: 'Guards',
      nestedValue: (row: any) => {
        return row?.guards?.join(', ');
      },
      searchKey: 'guards',
    },

    {
      header: 'Date',
      name: 'missed_at',
      datetimeObj: true,
      dateTimeFormat: 'd MMM y',
      dateRangeKey: 'missed_at',
      sortKey: 'missed_at',
      default: true,
    },
    {
      header: 'Time Range',
      name: 'time_range',
    },
  ];

  mobileDetailMissedCheckpoint = {
    individualDetails: [
      {
        name: 'checkpoint_name',
        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 600,
        },
      },
      {
        name: 'checkpoint_address',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'company_name',
        cardRowStyle: {
          'font-weight': 600,
        },
      },
      {
        prefix: 'Patrol Route : ',
        name: 'patrol_route_name',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        prefix: 'Guards : ',
        nestedValue: (row: any) => {
          return row?.guards?.join(', ');
        },
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'time_range',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },
      {
        name: 'missed_at',
        dateTimeFormat: 'd MMM y',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };

  dialogeRef: any;
  defaultTimeRange: any;
  selectedMissedWelfare: any;
  constructor(
    private userService: UsersService,
    private spinnerService: NgxSpinnerService,
    private dialog: NbDialogService,
    private pageService: PagesService,
    private checkpointService: CheckpointService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const today = new Date();
    today.setDate(new Date().getDate() - 1);

    this.defaultTimeRange = {
      start: today,
      end: new Date(),
    };

    this.formatDateRangeValueMissedWelfareCheck(this.defaultTimeRange);

    this.formatDateRangeValueMissedCheckpointCheck(this.defaultTimeRange);
  }
  getMissedWelfareCheckData() {
    this.spinnerService.show();

    let body: any = {
      event_type: 'missed_welfare_checks',
    };
    if (this.dateRangeMissedWelfareCheck && window.innerWidth < 770)
      body.date_range = this.dateRangeMissedWelfareCheck;

    if (this.sortKeysMissedWelfareCheck)
      body.sort_key = this.sortKeysMissedWelfareCheck;

    if (this.searchParamsMissedWelfareCheck)
      body.search_filters = this.searchParamsMissedWelfareCheck;

    if (this.pageSizeMissedWelfareCheck) {
      body['rows'] = this.pageSizeMissedWelfareCheck;
    }
    if (this.previousMissedWelfareCheck) {
      body['previous'] = this.previousMissedWelfareCheck;
    }

    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.missedWelfareData = response['data'];

        this.spinnerService.hide();
        this.totalRowsMissedWelfareCheck = response['total_size'];

        this.totalPagesMissedWelfareCheck = Math.ceil(
          this.totalRowsMissedWelfareCheck / this.pageSizeMissedWelfareCheck
        );
        this.otherParamsMissedWelfareCheck = {
          paginationData: {
            tableTotalRows: this.totalRowsMissedWelfareCheck,
            tablePreviousRows: this.previousMissedWelfareCheck,
            tableRows: this.pageSizeMissedWelfareCheck,
            tablePageNum: this.pageNumMissedWelfareCheck,
            tableTotalPages: this.totalPagesMissedWelfareCheck,
          },
        };
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }
  openRangePicker() {
    this.dialogeRef = this.dialog.open(DateTimePickerComponent, {
      context: {
        dateRange: this.dateRangeMissedWelfareCheck,
        showFrom: 'tablePage',
      },
    });
    this.dialogeRef.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.formatDateRangeValueMissedWelfareCheck(value);
      }
    });
  }
  formatDateRangeValueMissedWelfareCheck(value: any) {
    this.dateRangeMissedWelfareCheck = value;

    this.previousMissedWelfareCheck = 0;
    this.pageNumMissedWelfareCheck = 1;

    this.getMissedWelfareCheckData();
    if (
      this.dateRangeMissedWelfareCheck?.start &&
      this.dateRangeMissedWelfareCheck?.end
    )
      this.dateRangeValueMissedWelfareCheck = `${String(
        formatDate(
          this.dateRangeMissedWelfareCheck?.start,
          'd MMM y HH:mm',
          'en_US'
        )
      )} - ${String(
        formatDate(
          this.dateRangeMissedWelfareCheck?.end,
          'd MMM y HH:mm',
          'en_US'
        )
      )}`;
  }
  formatDateRangeValueMissedCheckpointCheck(value: any) {
    this.dateRangeMissedCheckpoint = value;

    this.previousMissedCheckpoint = 0;
    this.pageNumMissedCheckpoint = 1;

    this.getMissedCheckpointData();
    if (
      this.dateRangeMissedCheckpoint?.start &&
      this.dateRangeMissedCheckpoint?.end
    )
      this.dateRangeValueMissedCheckpoint = `${String(
        formatDate(
          this.dateRangeMissedCheckpoint?.start,
          'd MMM y HH:mm',
          'en_US'
        )
      )} - ${String(
        formatDate(
          this.dateRangeMissedCheckpoint?.end,
          'd MMM y HH:mm',
          'en_US'
        )
      )}`;
  }
  onChangePaginationMobileMissedWelfareCheck(event: any) {
    this.previousMissedWelfareCheck = event.previous;
    this.pageNumMissedWelfareCheck = event.pageNum;
    this.pageSizeMissedWelfareCheck = event.pageSize;
    this.getMissedWelfareCheckData();
  }
  onChangePaginationMissedWelfareCheck(data: any) {
    this.previousMissedWelfareCheck = data?.paginationData?.tablePreviousRows;
    this.pageNumMissedWelfareCheck = data?.paginationData?.tablePageNum;
    this.pageSizeMissedWelfareCheck = data?.paginationData?.tableRows;

    this.getMissedWelfareCheckData();

    this.otherParamsMissedWelfareCheck.paginationData = {
      ...data?.paginationData,
    };
  }
  sortColumnMissedWelfareCheck(sortData: any) {
    this.resetPagenMissedWelfareCheck();
    this.sortKeysMissedWelfareCheck = sortData?.sortList || [];
    this.getMissedWelfareCheckData();
  }

  searchColumnMissedWelfareCheck(searchData: any) {
    this.resetPagenMissedWelfareCheck();
    this.searchParamsMissedWelfareCheck = searchData?.searchParams || {};

    this.getMissedWelfareCheckData();
  }
  resetPagenMissedWelfareCheck() {
    this.totalRowsMissedWelfareCheck = 0;
    this.previousMissedWelfareCheck = 0;
    this.pageSizeMissedWelfareCheck = 10;
    this.pageNumMissedWelfareCheck = 1;
    this.totalPagesMissedWelfareCheck = 0;
    this.otherParamsMissedWelfareCheck.paginationData = {
      tableTotalRows: this.totalRowsMissedWelfareCheck,
      tablePreviousRows: this.previousMissedWelfareCheck,
      tableRows: this.pageSizeMissedWelfareCheck,
      tablePageNum: this.pageNumMissedWelfareCheck,
      tableTotalPages: this.totalPagesMissedWelfareCheck,
    };
  }
  getMissedCheckpointData() {
    this.spinnerService.show();

    let body: any = {};
    if (this.dateRangeMissedCheckpoint && window.innerWidth < 770)
      body.date_range = this.dateRangeMissedCheckpoint;

    if (this.sortKeysMissedCheckpoint)
      body.sort_key = this.sortKeysMissedCheckpoint;
    if (this.searchParamsMissedCheckpoint)
      body.search_filters = this.searchParamsMissedCheckpoint;

    if (this.pageSizeMissedCheckpoint) {
      body['rows'] = this.pageSizeMissedCheckpoint;
    }
    if (this.previousMissedCheckpoint) {
      body['previous'] = this.previousMissedCheckpoint;
    }

    this.checkpointService
      .missedCheckpoints(body)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.missedCheckpointData = response['data'];

          this.spinnerService.hide();
          this.totalRowsMissedCheckpoint = response['total_size'];

          this.totalPagesMissedCheckpoint = Math.ceil(
            this.totalRowsMissedCheckpoint / this.pageSizeMissedCheckpoint
          );
          this.otherParamsMissedCheckpoint = {
            paginationData: {
              tableTotalRows: this.totalRowsMissedCheckpoint,
              tablePreviousRows: this.previousMissedCheckpoint,
              tableRows: this.pageSizeMissedCheckpoint,
              tablePageNum: this.pageNumMissedCheckpoint,
              tableTotalPages: this.totalPagesMissedCheckpoint,
            },
          };
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
          this.spinnerService.hide();
        }
      });
  }
  openRangePickerMissedCheckpoint() {
    this.dialogeRef = this.dialog.open(DateTimePickerComponent, {
      context: {
        dateRange: this.dateRangeMissedCheckpoint,
        showFrom: 'tablePage',
      },
    });
    this.dialogeRef.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.formatDateRangeValueMissedCheckpointCheck(value);
      }
    });
  }
  onChangePaginationMobileMissedCheckpoint(event: any) {
    this.previousMissedCheckpoint = event.previous;
    this.pageNumMissedCheckpoint = event.pageNum;
    this.pageSizeMissedCheckpoint = event.pageSize;
    this.getMissedCheckpointData();
  }
  onChangePaginationMissedCheckpoint(data: any) {
    this.previousMissedCheckpoint = data?.paginationData?.tablePreviousRows;
    this.pageNumMissedCheckpoint = data?.paginationData?.tablePageNum;
    this.pageSizeMissedCheckpoint = data?.paginationData?.tableRows;

    this.getMissedCheckpointData();

    this.otherParamsMissedCheckpoint.paginationData = {
      ...data?.paginationData,
    };
  }
  sortColumnMissedCheckpoint(sortData: any) {
    this.resetPageMissedCheckPoint();
    this.sortKeysMissedCheckpoint = sortData?.sortList || [];
    this.getMissedCheckpointData();
  }
  searchColumnMissedCheckpoint(searchData: any) {
    this.resetPageMissedCheckPoint();

    this.searchParamsMissedCheckpoint = searchData?.searchParams || {};
    this.getMissedCheckpointData();
  }
  resetPageMissedCheckPoint() {
    this.totalRowsMissedCheckpoint = 0;
    this.previousMissedCheckpoint = 0;
    this.pageSizeMissedCheckpoint = 10;
    this.pageNumMissedCheckpoint = 1;
    this.totalPagesMissedCheckpoint = 0;
    this.otherParamsMissedCheckpoint.paginationData = {
      tableTotalRows: this.totalRowsMissedCheckpoint,
      tablePreviousRows: this.previousMissedCheckpoint,
      tableRows: this.pageSizeMissedCheckpoint,
      tablePageNum: this.pageNumMissedCheckpoint,
      tableTotalPages: this.totalPagesMissedCheckpoint,
    };
  }
}
