import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { globalMonitoringCompanyInfo } from 'src/global.variables';
import { CustomerService } from '../customer/customer.service';
import { PagesService } from '../pages.service';
import { AddEditResponseTypeComponent } from './add-edit-response-type/add-edit-response-type.component';
@Component({
  selector: 'app-response-type',
  templateUrl: './response-type.component.html',
  styleUrls: ['./response-type.component.scss'],
})
export class ResponseTypeComponent implements OnInit {
  globalFilter: any;
  responseCardDetils: any;
  userData: any;
  isAdmin: any;
  pageSize: number = 5;
  isDispatchUser: any;
  isFilterApplied: any;
  previous: number = 0;
  pageNum: number = 1;
  totalPages: number = 0;
  responseDetails: any;
  responseCount: any;
  totalResponseCount: any;
  responsePaginationData: any = {};
  searchRemovalCap: number = 5;
  cardActionIcons = globalMonitoringCompanyInfo?.icons;
  lastSearchStr: any;
  lastApiResponseHadData: boolean = true;
  apiCallMade: boolean = false;
  showTypeAhead: any;
  showAutoResponseDetails$: Observable<any> | undefined;
  responseData: any;
  updateView: any;
  dialogref: any;
  constructor(
    private customerService: CustomerService,
    private spinnerService: NgxSpinnerService,
    private _location: Location,
    private dialogService: NbDialogService,
    private appService: AppService,
    private pageService: PagesService,
    private dataCheckService: DataCheckService
  ) {
    this.responseCardDetils = {
      individualDetails: [
        {
          name: 'name',
          cardRowStyle: { 'font-weight': 'bolder' },
        },
      ],
    };
  }

  ngOnInit(): void {
    this.userData = this.appService.getUserData();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.pageSize = Number(this.userData?.preferences?.client_page_size || 5);
    this.spinnerService.show();
    this.fetchResponseTypeList();
  }

  fetchResponseTypeList() {
    let params: any = {};
    if (this.pageSize) {
      params['rows'] = this.pageSize;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    if (this.globalFilter) {
      params['search_str'] = this.globalFilter;
    }
    this.spinnerService.show();
    this.customerService.fetchResponseTypeList(params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.isFilterApplied = false;

        this.responseDetails = res['data'];
        this.lastApiResponseHadData = this.responseDetails.length > 0;
        this.lastSearchStr = this.globalFilter;
        this.responseCount = res['total_size'];

        this.totalResponseCount = res?.['response_type_count'] || 0;
        this.totalPages = Math.ceil(this.responseCount / this.pageSize);
        this.responsePaginationData[this.pageNum] = [...this.responseDetails];
      }
      this.spinnerService.hide();
    });
  }
  onFilterApply(event: any) {
    if (event?.target?.value?.length === 0) {
      this.showTypeAhead = true;

      this.globalFilter = '';
      this.fetchResponseTypeList();
    }
    if (event?.target?.value && event?.target?.value?.length > 2) {
      if (this.lastSearchStr) {
        !this.globalFilter.includes(this.lastSearchStr)
          ? (this.apiCallMade = true)
          : (this.apiCallMade = false);
      } else {
        this.apiCallMade = true;
      }
      if (this.apiCallMade || this.lastApiResponseHadData) {
        this.isFilterApplied = true;
        this.fetchResponseTypeList();
        this.showTypeAhead = true;
      }
    }
    if (
      event === true &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.isFilterApplied = true;
      this.previous = 0;
      this.responsePaginationData = {};
      this.fetchResponseTypeList();
      this.showTypeAhead = false;
    } else if (
      event.key === 'Enter' &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.previous = 0;
      this.isFilterApplied = true;
      this.responsePaginationData = {};
      this.fetchResponseTypeList();
      this.showTypeAhead = false;
    }
  }
  onClearFilter() {
    this.pageNum = 1;
    this.previous = 0;
    this.responsePaginationData = {};
    this.globalFilter = '';
    this.showTypeAhead = true;
    this.isFilterApplied = false;
    this.spinnerService.show();
    this.fetchResponseTypeList();
  }
  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.fetchResponseTypeList();
    if (this.globalFilter) this.showTypeAhead = false;
  }
  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.responsePaginationData = {};
    this.fetchResponseTypeList();
    if (this.globalFilter) this.showTypeAhead = false;
  }
  openAddResponseForm() {
    this.updateView = false;
    this.dialogref = this.dialogService.open(AddEditResponseTypeComponent, {
      context: { updateView: this.updateView },
    });
    this.dialogref.onClose.subscribe((value: any) => {
      if (value != 'close') {
        this.fetchResponseTypeList();
      }
    });
  }
  openEditResponseForm(data: any) {
    this.responseData = data;
    this.updateView = true;
    this.dialogref = this.dialogService.open(AddEditResponseTypeComponent, {
      context: { updateView: this.updateView, responseData: this.responseData },
    });
    this.dialogref.onClose.subscribe((value: any) => {
      if (value != 'close') {
        this.fetchResponseTypeList();
      }
    });
  }
  deleteResponse(data: any) {
    let dialogMsg = 'WARNING: This action cannot be reversed.';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: 'Delete Response Type',
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.spinnerService.show();
        this.customerService
          .deleteResponseType(data.id, { delete: 1 })
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.fetchResponseTypeList();
              this.spinnerService.hide();
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.spinnerService.hide();
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    });
  }
  onCloseDialogue() {
    this.dialogref.close();
  }
}
