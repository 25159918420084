<nb-card>
  <!-- mobile only view STARTS-->
  <nb-card-body class="mobile-only gt-p-small">
    <nb-form-field>
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          nbInput
          fullWidth
          placeholder="Search Jobs"
          [(ngModel)]="globalFilter"
          (keyup)="onFilterApply($event)"
          [nbAutocomplete]="autoNgModel"
        />
        <label>Search Jobs</label>
      </div>
      <nb-autocomplete #autoNgModel>
        <nb-option
          *ngFor="let item of dropDownJobs"
          [value]="item.name"
          (click)="openIndividualJob(item)"
        >
          <div>
            <div class="strong">{{ item.job_key }} ({{ item.status }})</div>
            <small>
              {{ item.company }}
            </small>
          </div>
        </nb-option>
      </nb-autocomplete>
      <nb-icon
        nbSuffix
        class="ico-color"
        *ngIf="showTypeAhead === true"
        [icon]="'search-outline'"
        pack="eva"
        (click)="onFilterApply(true)"
      ></nb-icon>
      <nb-icon
        nbSuffix
        class="ico-color"
        *ngIf="showTypeAhead === false"
        [icon]="'close-outline'"
        pack="eva"
        (click)="resetFilters()"
      ></nb-icon>
    </nb-form-field>
    <div class="d-flex">
      <div class="flex-grow-1">
        <div *ngIf="isAdmin || isDispatchUser === true">
          <button
            class="sc-mt-0"
            fullWidth
            size="giant"
            (click)="showAllJobsDesktop()"
            nbButton
            [status]="defaultJob ? 'primary' : 'basic'"
          >
            {{ defaultJob ? "Show All" : "Reset" }}
          </button>
        </div>
      </div>
      <div class="sc-add-btn" *ngIf="isAdmin || isDispatchUser === true">
        <button size="giant" nbButton class="sc-mt-0" [nbPopover]="sortRef">
          <nb-icon class="ico-color" icon="flip-outline" pack="eva"> </nb-icon>
        </button>
      </div>
      <div class="sc-add-btn">
        <button
          size="giant"
          nbButton
          class="sc-ml-1 sc-mt-0"
          (click)="switchMobileView()"
        >
          <nb-icon
            [icon]="viewType == 'cardView' ? 'pin-outline' : 'grid-outline'"
            pack="eva"
          >
          </nb-icon>
        </button>
      </div>
    </div>

    <div class="col-12" *ngIf="isAdmin || isDispatchUser === true">
      <button
        fullWidth
        size="giant"
        (click)="openAddJobForm()"
        nbButton
        hero
        status="primary"
      >
        + Alarm Response
      </button>
    </div>

    <div
      *ngIf="listJobData?.length && viewType == 'mapView' && !paginationChange"
    >
      <app-map-pins-view
        [listData]="listJobData"
        (openItemPage)="openIndividualJob($event)"
        [mapFrom]="'mobileJobPage'"
      ></app-map-pins-view>
      <div>
        <app-pagination-control
          [pageSize]="pageSize"
          [pageNum]="pageNum"
          [previous]="previous"
          [totalRows]="totalRows"
          [totalPages]="totalPages"
          (onClickPagination)="onClickPagination($event)"
          (onChangePagination)="onClickPagination($event)"
        >
        </app-pagination-control>
      </div>
    </div>

    <div *ngIf="viewType == 'cardView'">
      <nb-list
        nbInfiniteList
        [threshold]="1"
        listenWindowScroll="true"
        (bottomThreshold)="getMobileJobs()"
      >
        <nb-list-item *ngFor="let jobList of infinteJobList">
          <app-card-list
            [listCardData]="jobList"
            [listCardDetails]="jobCardsDetails"
            [showTableFrom]="'jobsPage'"
            (rowClick)="openIndividualJob($event)"
          ></app-card-list>
        </nb-list-item>
      </nb-list>
      <div *ngIf="infinteJobListJobCount < totalRows">
        <nb-alert outline="basic" style="font-weight: 500"
          >Scroll down for more</nb-alert
        >
      </div>
    </div>

    <div *ngIf="totalJobs < 1">
      <nb-alert outline="basic" style="font-weight: 500"
        >No Jobs Match Your Search Criteria</nb-alert
      >
    </div>
  </nb-card-body>

  <!-- mobile only view ENDS-->

  <!-- large devices only view STARTS-->
  <nb-card-body class="large-view-only gt-p-1">
    <div *ngIf="viewType != 'tableView'">
      <nb-form-field>
        <div class="form-floating">
          <input
            class="form-control"
            type="text"
            nbInput
            fullWidth
            placeholder="Search Jobs"
            [(ngModel)]="globalFilter"
            (keyup)="onFilterApply($event)"
            [nbAutocomplete]="autoNgModel"
          />
          <label>Search Jobs</label>
        </div>
        <nb-autocomplete #autoNgModel>
          <nb-option
            *ngFor="let item of dropDownJobs"
            [value]="item.name"
            (click)="openIndividualJob(item)"
          >
            <div>
              <div class="strong">{{ item.job_key }} ({{ item.status }})</div>
              <small>
                {{ item.company }}
              </small>
            </div>
          </nb-option>
        </nb-autocomplete>
        <nb-icon
          nbSuffix
          class="ico-color"
          *ngIf="showTypeAhead === true"
          [icon]="'search-outline'"
          pack="eva"
          (click)="onFilterApply(true)"
        ></nb-icon>
        <nb-icon
          nbSuffix
          class="ico-color"
          *ngIf="showTypeAhead === false"
          [icon]="'close-outline'"
          pack="eva"
          (click)="resetFilters()"
        ></nb-icon>
      </nb-form-field>
    </div>
    <ng-container *ngIf="isAdmin || isDispatchUser === true">
      <div class="row row-cols-3">
        <div class="col">
          <button
            fullWidth
            size="giant"
            nbButton
            (click)="openAddJobForm()"
            hero
            status="primary"
          >
            + Alarm Response
          </button>
        </div>
        <div class="col">
          <button
            fullWidth
            size="giant"
            (click)="showAllJobsDesktop()"
            nbButton
            [status]="defaultJob ? 'primary' : 'basic'"
          >
            {{ defaultJob ? "Show All" : "Reset" }}
          </button>
        </div>

        <div class="col">
          <button
            size="giant"
            nbButton
            class="sc-floating-right sc-ml-1"
            (click)="switchTableCardView()"
          >
            <nb-icon
              class="ico-color"
              [icon]="viewType == 'tableView' ? 'pin-outline' : 'menu-outline'"
              pack="eva"
            >
            </nb-icon>
          </button>
        </div>
      </div>
    </ng-container>

    <div>
      <ng-container
        *ngIf="
          listJobData?.length && viewType == 'mapView' && !paginationChange
        "
      >
        <app-map-pins-view
          [listData]="listJobData"
          [mapFrom]="'desktopJobPage'"
          (openItemPage)="openIndividualJob($event)"
        ></app-map-pins-view>
        <div>
          <app-pagination-control
            [pageSize]="pageSize"
            [pageNum]="pageNum"
            [previous]="previous"
            [totalRows]="totalRows"
            [totalPages]="totalPages"
            (onClickPagination)="onClickPagination($event)"
            (onChangePagination)="onClickPagination($event)"
          >
          </app-pagination-control>
        </div>
      </ng-container>
      <app-gt-table
        *ngIf="viewType == 'tableView'"
        [desktopTableBackendData]="listJobData"
        [desktopTableDetails]="jobDetailsTableView"
        [tableStyle]="tableStyle"
        [otherParams]="otherParams"
        (paginationChange)="onChangePagination($event)"
        (sortColumn)="sortColumn($event)"
        (searchColumn)="searchColumn($event)"
        (rowClick)="openIndividualJob($event)"
      ></app-gt-table>
    </div>

    <!--jobs data(table/card) section ENDS-->

    <!--No jobs case-->
    <div *ngIf="totalJobs < 1">
      <nb-alert outline="basic" style="margin: 1rem; font-weight: 500"
        >No Jobs Match Your Search Criteria</nb-alert
      >
    </div>
  </nb-card-body>

  <!-- large devices only view ENDS-->
</nb-card>

<!-- sort popover -->
<ng-template #sortRef>
  <nb-card>
    <div *ngFor="let item of jobDetailsTableView">
      <div class="row row-cols-2 sc-mt-9" (click)="sortField(item)">
        <div class="col-10">
          <button
            nbButton
            class="gt-edit-btn"
            fullWidth
            [ghost]="item?.sortOrder ? false : true"
          >
            {{ item.header }}
          </button>
        </div>
        <div class="col-2">
          <nb-icon
            pack="eva"
            [icon]="
              item?.sortOrder == 'asc'
                ? 'arrow-upward-outline'
                : 'arrow-downward-outline'
            "
            class="sc-floating-right"
            [ngClass]="item?.sortOrder ? 'ico-color' : ''"
          ></nb-icon>
        </div>
      </div>
    </div>
  </nb-card>
</ng-template>
