<div>
  <!-- MOBILE VIEW -->
  <div class="mobile-only gt-p-small">
    <nb-tabset (changeTab)="changeTab($event)">
      <nb-tab tabTitle="{{ tabTitles.tab1 }}">
        <div style="padding-bottom: 60px">
          <div class="form-floating mt-1 mx-1">
            <input
              class="form-control"
              (input)="onGuardSearch($event)"
              nbInput
              fullWidth
              autocomplete="off"
              type="text"
              placeholder="Guards"
              [nbAutocomplete]="autoNgModel"
              [(ngModel)]="guardName"
            /><label>Guards</label>
          </div>

          <nb-autocomplete #autoNgModel>
            <nb-option
              *ngFor="let guard of guardNbOptionList"
              (click)="onGuardSelect(guard)"
            >
              <div>
                {{ guard?.user_name }}
              </div>
            </nb-option>
          </nb-autocomplete>
          <div class="sc-mt-6" *ngIf="showMap === true">
            <app-map-pins-view
              [listData]="guardLastLocationData"
              [mapFrom]="'guardLocationMobile'"
              [selectedGuardUser]="selectedGuardUser"
              (popupOpened)="selectGuard($event)"
            ></app-map-pins-view>
          </div>
          <div>
            <ng-template *ngTemplateOutlet="guardInfoTemplate"></ng-template>
          </div>
        </div>
        <div class="my-2 fixed-back-btn">
          <button
            nbButton
            size="giant"
            fullWidth
            status="primary"
            routerLink="/dashboard"
          >
            Back
          </button>
        </div>
      </nb-tab>
      <nb-tab tabTitle="{{ tabTitles.tab2 }}">
        <div *ngIf="showClockedInUsers" class="sc-mt-6">
          <app-card-list
            *ngIf="onlineUsers?.length"
            [listCardData]="onlineUsers"
            [listCardDetails]="userOnlineEventDetails"
            (rowClick)="onUserSelection($event)"
          ></app-card-list>
          <div *ngIf="totalPages > 0">
            <app-pagination-control
              [pageSize]="pageSize"
              [pageNum]="pageNum"
              [previous]="previous"
              [totalRows]="totalRows"
              [totalPages]="totalPages"
              (onClickPagination)="onChangePagination($event)"
              (onChangePagination)="onChangePagination($event)"
            >
            </app-pagination-control>
          </div>
        </div>
        <div *ngIf="!showClockedInUsers" class="sc-mt-6">
          <div class="form-floating">
            <input
              class="form-control"
              size="giant"
              nbInput
              fullWidth
              placeholder="Pick Date Range"
              readonly
              [value]="dateRangeValue"
              (click)="openRangePicker()"
            />
            <label>Pick Date Range</label>
          </div>
          <div class="sc-mt-6" *ngIf="selectedGuard?.id">
            <div class="sc-text-center strong sc-head5 mb-1 mt-3">
              {{ selectedGuard?.full_name }}
            </div>

            <div *ngIf="userEvents?.length == 0" class="my-3">
              <nb-alert outline="basic" style="font-weight: 500"
                >No Records Match Your Search Criteria</nb-alert
              >
            </div>

            <div *ngIf="selectedGuardLocationInfo" class="sc-mt-6">
              <div class="sc-text-center strong sc-head6 mb-3">
                {{ selectedGuardLocationInfo?.event_type }} |
                {{
                  selectedGuardLocationInfo?.updated_at
                    | customdate : "d MMM y HH:mm"
                }}
              </div>
              <div class="mt-2">
                <app-map-leaflet
                  *ngIf="mapLatLong"
                  [latLon]="mapLatLong"
                  mapId="guardHistoryLocationMobile"
                ></app-map-leaflet>
              </div>

              <div class="sc-text-center small-font">
                {{ selectedGuardLocationInfo?.gps?.lat }},
                {{ selectedGuardLocationInfo?.gps?.lon }}
              </div>

              <div
                class="sc-text-center mt-2"
                *ngIf="totalRowsUserLocationHistory > 0"
              >
                <button
                  (click)="prevLocation()"
                  nbButton
                  type="button"
                  [disabled]="selectedGuardLocationInfo?.index === 1"
                >
                  Newer
                </button>

                <span>
                  {{ selectedGuardLocationInfo?.index }}
                  of {{ totalRowsUserLocationHistory }}
                </span>

                <button
                  (click)="nextLocation()"
                  nbButton
                  type="button"
                  [disabled]="
                    selectedGuardLocationInfo?.index >=
                    totalRowsUserLocationHistory
                  "
                >
                  Older
                </button>
              </div>
            </div>
          </div>

          <div class="my-2 fixed-back-btn">
            <button
              nbButton
              size="giant"
              fullWidth
              status="primary"
              (click)="
                selectedGuard = null;
                showClockedInUsers = true;
                selectedGuardLocationInfo = {};
                getUserOnline()
              "
            >
              Back
            </button>
          </div>
        </div>
      </nb-tab>
    </nb-tabset>
  </div>
  <!-- DESKTOP VIEW -->
  <div class="large-view-only">
    <nb-tabset (changeTab)="changeTab($event)">
      <nb-tab tabTitle="{{ tabTitles.tab1 }}">
        <div class="form-floating mt-1 mx-1">
          <input
            class="form-control"
            (input)="onGuardSearch($event)"
            nbInput
            fullWidth
            autocomplete="off"
            type="text"
            placeholder="Guards"
            [(ngModel)]="guardName"
            [nbAutocomplete]="autoNgModelDesk"
          /><label>Guards</label>
        </div>

        <nb-autocomplete #autoNgModelDesk>
          <nb-option
            *ngFor="let guard of guardNbOptionList"
            (click)="onGuardSelect(guard)"
          >
            <div>
              {{ guard?.user_name }}
            </div>
          </nb-option>
        </nb-autocomplete>

        <div class="sc-mt-6" *ngIf="showMap === true">
          <app-map-pins-view
            [listData]="guardLastLocationData"
            [mapFrom]="'guardLocationDesktop'"
            [selectedGuardUser]="selectedGuardUser"
            (popupOpened)="selectGuard($event)"
          ></app-map-pins-view>
        </div>
        <div>
          <ng-template *ngTemplateOutlet="guardInfoTemplate"></ng-template>
        </div>
      </nb-tab>
      <nb-tab tabTitle="{{ tabTitles.tab2 }}">
        <div *ngIf="showClockedInUsers" class="sc-mt-6">
          <app-gt-table
            [desktopTableBackendData]="onlineUsers"
            [desktopTableDetails]="userOnlineDetailsTableView"
            [tableStyle]="tableStyle"
            [otherParams]="otherParams"
            (sortColumn)="sortColumn($event)"
            (rowClick)="onUserSelection($event)"
            (paginationChange)="onChangeTablePagination($event)"
          >
          </app-gt-table>
        </div>
        <div *ngIf="!showClockedInUsers" class="sc-mt-6">
          <div style="display: flex">
            <div style="flex-grow: 3" class="form-floating">
              <input
                class="form-control"
                size="giant"
                nbInput
                fullWidth
                placeholder="Pick Date Range"
                readonly
                [value]="dateRangeValue"
                (click)="openRangePicker()"
              />
              <label>Pick Date Range</label>
            </div>
            <div style="flex-grow: 1; margin-left: 0.5rem">
              <button
                nbButton
                size="giant"
                fullWidth
                status="primary"
                (click)="
                  selectedGuard = null;
                  showClockedInUsers = true;
                  getUserOnline()
                "
              >
                Back
              </button>
            </div>
          </div>
          <div class="d-flex">
            <div class="flex-grow-2 form-floating"></div>
            <div class="flex-grow-1"></div>
          </div>
          <div>
            <div class="sc-text-center strong sc-head5 mb-1 mt-3">
              {{ selectedGuard?.full_name }}
            </div>
            <div class="sc-mt-6" *ngIf="selectedGuard?.id">
              <app-gt-table
                *ngIf="userEvents?.length"
                [desktopTableBackendData]="userEvents"
                [desktopTableDetails]="clockedUserTableDetails"
                [tableStyle]="tableStyle"
                [otherParams]="otherParamsUserLocationHistory"
                (sortColumn)="sortColumnUserLocation($event)"
                (rowClick)="showUserLocation($event)"
                (paginationChange)="
                  onChangeUserLocationHistoryPagination($event)
                "
              >
              </app-gt-table>

              <div *ngIf="userEvents?.length == 0" class="my-3">
                <nb-alert outline="basic" style="font-weight: 500"
                  >No Records Match Your Search Criteria</nb-alert
                >
              </div>

              <div *ngIf="selectedGuardLocationInfo" class="sc-mt-9">
                <app-map-leaflet
                  *ngIf="mapLatLong"
                  [latLon]="mapLatLong"
                  mapId="guardHistoryLocationDesktop"
                ></app-map-leaflet>
                <div class="sc-text-center">
                  {{ selectedGuardLocationInfo?.gps?.lat }},
                  {{ selectedGuardLocationInfo?.gps?.lon }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nb-tab>
    </nb-tabset>
  </div>
</div>
<ng-template #guardInfoTemplate>
  <ng-container *ngIf="guardLatestInfoBackup?.length">
    <div class="row row-cols-auto my-3 gap-2">
      <div
        *ngFor="let key of guardStatusKeys"
        class="d-flex align-items-center"
      >
        <nb-checkbox
          [checked]="guardStatus?.[key]?.selected"
          (checkedChange)="filterGuardByStatus(key)"
        >
          <div [ngStyle]="guardStatus?.[key]?.circle" class="mx-2"></div>
          {{guardStatus?.[key]?.name}}</nb-checkbox
        >
      </div>
    </div>

    <table class="guard-info w-100" *ngIf="guardLatestInfo?.length">
      <tbody>
        <tr
          *ngFor="let guard of guardLatestInfo; let i = index"
          class="border-bottom pointer"
          (click)="selectGuard(guard)"
          [ngStyle]="
            selectedGuardIndex === i
              ? {
                  'background-color': 'var(--color-basic-500)',
                  'color': 'var(--color-basic-700)',
                  'cursor': 'pointer'
                }
              : {}
          "
        >
          <td class="pe-3 ps-2">
            <div [ngStyle]="guardStatus?.[guard?.status]?.circle"></div>
          </td>
          <td>
            {{ guard?.user_name }}
          </td>
          <td>
            <tbody *ngIf="guard?.shifts?.length">
              <tr *ngFor="let shift of guard?.shifts">
                <td>
                  {{ shift?.event_name }}
                  <span class="text-danger small">
                    [{{ getScheduleTimeRange(shift?.schedule_details) }}]
                  </span>
                </td>
              </tr>
            </tbody>
            <div *ngIf="!guard?.shifts?.length" class="fw-bold">
              NO JOB ASSIGNED
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</ng-template>
