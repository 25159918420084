import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NbDialogRef,
  NbDialogService,
  NbStepperComponent,
  NbToastrService,
} from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { AddCustomerComponent } from 'src/app/shared/comp/add-customer/add-customer.component';
import { SaveNewAddressComponent } from 'src/app/shared/comp/save-new-address/save-new-address.component';
import { JobsService } from '../../jobs/jobs.service';
import { PagesService } from '../../pages.service';
import { ProfileService } from '../../profile/profile.service';
import { UserPromptService } from '../../user-prompts/user-prompt.service';
import { CheckpointService } from '../checkpoint.service';

@Component({
  selector: 'app-add-edit-checkpoint',
  templateUrl: './add-edit-checkpoint.component.html',
  styleUrls: ['./add-edit-checkpoint.component.scss'],
})
export class AddEditCheckpointComponent implements OnInit {
  @Input()
  checkPointForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    company: new UntypedFormControl('', Validators.required),
    company_id: new UntypedFormControl('', Validators.required),
    min_distance: new UntypedFormControl(100, [
      Validators.max(1000),
      Validators.required,
    ]),

    description: new UntypedFormControl(),
    address1: new UntypedFormControl(),
    address2: new UntypedFormControl(),
    address3: new UntypedFormControl(),
    city_name: new UntypedFormControl(''),
    state_code: new UntypedFormControl(''),
    country_name: new UntypedFormControl(''),
    postcode: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.max(99999999)])
    ),
    longitude: new UntypedFormControl('', [Validators.required]),
    latitude: new UntypedFormControl('', [Validators.required]),
  });

  @Input() showFrom: any;
  @Input() routeName: any;
  @ViewChild('addressAutoInput') addressAutoInput: any;

  @ViewChild('stepper') stepper: any = NbStepperComponent;
  showHeaderName: any;
  addrLookupData$: Observable<any> | any;
  siteData: any;
  customer: any;
  value: any;
  gps = { lat: 0, lon: 0 };
  addressValue: any;
  nameValue: any;
  startForm: any;
  midForm: any;
  endForm: any;
  showMap: any;
  latLon: any;
  siteValue: any = null;
  distanceFromLocation: any;
  showWebcam: any;
  dialogref: any;
  webcamImage: any;

  actualUploadFile: any;
  fileData: any = [];
  clientValue: any;
  existingCheckpoints: any = [];
  newCheckpoints: any = [];
  currentAddress: any;
  manualAddress: boolean = false;
  Sites: any;
  searchClient: any;
  createSite: boolean = false;
  customerDetails: any;
  showCurrentLocationOptions: boolean = false;
  addressLookupData: any;
  searchControl: FormControl = new FormControl();
  clientId: any;
  clientName: any;
  qrText: any;
  qrImageUrl: any;
  cpKey: any;
  lastApiResponseHadData: boolean = true;
  lastSearchStr: any;
  apiCallMade: boolean = false;
  minKm: number = 5;
  maxKm: number = 1000;
  nearestKmValue = 100;
  // prompt variables
  selectedUserPrompts: any = [];
  availableUserPrompts: any = [];

  userPromptsRows: number = 5;
  userPromptsPrevious: number = 0;
  userPromptsPageNum: number = 1;
  userPromptsTotalPages: number = 0;
  userPromptsTotalCount: number = 0;
  userPromptsSearchResults: any = [];

  reOrderPrompts: boolean = false;
  mobileView: boolean = window.innerWidth < 770;
  constructor(
    @Optional() protected dialogRef: NbDialogRef<AddEditCheckpointComponent>,
    private checkpointService: CheckpointService,
    private toasterService: NbToastrService,
    private spinnerService: NgxSpinnerService,
    private appService: AppService,
    private dialogService: NbDialogService,
    private profileService: ProfileService,
    private jobService: JobsService,
    private pageService: PagesService,
    private _location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private userPromptService: UserPromptService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
      }
    });
    this.showMap = false;
    this.showHeaderName = false;
    this.searchControl.valueChanges.subscribe((data: any) => {
      if (typeof data === 'object') {
        this.searchControl.setValue(data.full_address);
      }
    });
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    this.clientName = this.route.snapshot.paramMap.get('clientName');
  }

  ngOnInit() {
    this.shiftFocus('addressSearch');
    this.getUserPrompts();
  }

  onClientSelect(data: any) {
    if (data === ' ') {
      this.openAddCustomerForm();
      this.checkPointForm.controls['company_id'].setValue(null);
      this.checkPointForm.controls['company'].setValue(null);
    }
    if (data?.id) {
      this.checkPointForm.controls['company_id'].setValue(data.id);
      this.checkPointForm.controls['company'].setValue(data.company_name);
      this.shiftFocus('description');
    }
  }
  selectClient() {
    this.checkPointForm.controls['company_id'].setValue(this.clientId);
    this.checkPointForm.controls['company'].setValue(this.clientName);
    this.shiftFocus('description');
  }
  onAddressSearch(addressSearch?: any) {
    if (addressSearch?.target?.value?.length > 2) {
      if (this.lastSearchStr) {
        !this.searchControl.value.includes(this.lastSearchStr)
          ? (this.apiCallMade = true)
          : (this.apiCallMade = false);
      } else {
        this.apiCallMade = true;
      }
      if (this.apiCallMade || this.lastApiResponseHadData) {
        this.profileService
          .addressLookup(addressSearch?.target?.value)
          .subscribe((res: any) => {
            this.addrLookupData$ = of(res['data']);
            this.addrLookupData$.subscribe((result: any) => {
              this.lastApiResponseHadData = result.length > 0;
            });
            this.lastSearchStr = this.searchControl.value;
          });
      }
    }
  }
  shiftFocus(elementId: string, time: number = 100) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      if (element) {
        element?.focus();
      }
    }, time);
  }

  addressSelected(address: any) {
    if (address === ' ') {
      this.addPlace({ latLon: undefined, showFrom: 'cpPage' });
    } else if (address && typeof address === 'object') {
      this.addrLookupData$ = undefined;
      this.addressValue = '';
      this.addressValue = address?.address1 + ' ' + address?.address2;
      this.checkPointForm.controls['name'].setValue(
        address?.address1 + ' ' + address?.address2
      );
      this.checkPointForm.controls['name'].updateValueAndValidity();
      this.nameValue = address?.address1 + ' ' + address?.address2;

      this.latLon = { lat: 0, lon: 0 };
      this.latLon.lat = address?.latitude;
      this.latLon.lon = address?.longitude;

      this.gps = { ...this.latLon };
      this.checkPointForm.controls['address1'].setValue(address?.address1);
      this.checkPointForm.controls['address2'].setValue(address?.address2);
      this.checkPointForm.controls['address3'].setValue(address?.address3);
      this.checkPointForm.controls['city_name'].setValue(address?.city_name);
      this.checkPointForm.controls['postcode'].setValue(address?.postcode);
      this.checkPointForm.controls['state_code'].setValue(address?.state_code),
        this.checkPointForm.controls['country_name'].setValue(
          address?.country_name
        );
      this.checkPointForm.controls['latitude'].setValue(address?.latitude);
      this.checkPointForm.controls['longitude'].setValue(address?.longitude);
      this.showMap = true;

      var element = <HTMLInputElement>document.getElementById('addressSearch');
      element.disabled = false;
      element.blur();
      if (this.clientId) {
        this.selectClient();
      } else {
        this.shiftFocus('cpName');
      }
    }
  }
  onClearForm() {
    this.reOrderPrompts = false;
    this.selectedUserPrompts = [];
    this.availableUserPrompts?.forEach((item: any) => (item.selected = false));
    this.checkPointForm.reset();
  }
  onCloseDialogue() {
    this.dialogRef.close('close');
  }
  emitData(event: any) {
    if (event.existing) {
      this.gps.lat = 0;
      this.gps.lon = 0;
      event.existing.forEach((element: any) => {
        element['counter'] = 1;
        this.existingCheckpoints.push(element);
      });
    } else {
      this.checkPointForm.controls['latitude'].setValue(event.lat);
      this.checkPointForm.controls['longitude'].setValue(event.lng);
      this.gps.lat = event.lat;
      this.gps.lon = event.lng == undefined ? event.lon : event.lng;
    }
  }
  updateMinDistance(event: any) {
    // method to update the min distance emitted from the map component
    this.checkPointForm.controls['min_distance'].setValue(event);
  }
  addCheckpointCount() {
    let subscriberStats = JSON.parse(
      localStorage.getItem('subscriberStats') || '{}'
    );
    subscriberStats.checkpoint_count =
      (subscriberStats?.checkpoint_count || 0) + 1;
    localStorage.setItem('subscriberStats', JSON.stringify(subscriberStats));
  }

  onClickSubmit() {
    let requestData = this.checkPointForm.value;
    requestData.user_prompt_ids = [
      ...new Set([...this.selectedUserPrompts].map((item) => item?.id)),
    ];

    if (requestData.site_id == null || requestData.site_id == undefined) {
      delete requestData.site_id;
    }

    if (this.checkPointForm.valid) {
      this.spinnerService.show();
      this.checkpointService
        .addCheckPoint(requestData)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.addCheckpointCount();
            this.newCheckpoints.push(response['data']);
            this.checkPointForm.reset();
            if (this.newCheckpoints.length > 0) {
              if (this.dialogRef !== null && this.dialogRef !== undefined) {
                this.dialogRef.close(this.newCheckpoints);
                this.newCheckpoints = [];
              }
            }
            this.pageService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
            this._location.back();
          } else {
            this.pageService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
          this.spinnerService.hide();
        });
    }

    if (this.existingCheckpoints.length > 0) {
      this.existingCheckpoints = [
        ...new Set(
          this.existingCheckpoints.map((item: any) => {
            item.id;
            return {
              ...item,
            };
          })
        ),
      ];
      this.dialogRef.close(this.existingCheckpoints);
      this.existingCheckpoints = [];
      this.toasterService.show('', 'Existing checkpoint Added');
    }
  }

  addPlace(context = {}) {
    this.showMap = false;
    this.dialogref = this.dialogService.open(SaveNewAddressComponent, {
      dialogClass: 'model-full',
      context: context,
    });
    this.dialogref.onClose.subscribe((value: any) => {
      if (value != 'close') {
        if (value) {
          this.addressSelected(value);

          this.searchControl.setValue(
            value?.address1 +
              ' ' +
              value?.address2 +
              ' ' +
              value?.city_name +
              ' ' +
              value?.state_code +
              ' ' +
              value?.postcode
          );

          if (this.nameValue && this.addressValue) {
            this.shiftFocus('cpName', 1000);
          }
        }
      } else {
        this.searchControl.setValue('');
      }
    });
  }

  onClientSearch(event: any) {
    this.searchClient = event.target.value;
    if (this.searchClient?.length > 2) {
      this.jobService
        .getSearchClients(this.searchClient)
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.customerDetails = res['data'];
          }
        });
    }
  }

  openAddCustomerForm() {
    this.dialogref = this.dialogService.open(AddCustomerComponent, {
      context: { customerName: this.searchClient },
      dialogClass: 'model-full',
    });

    this.dialogref.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.onClientSelect(value?.data);
      } else {
        this.searchClient = '';
      }
    });
  }

  async useCurrentLocation() {
    this.spinnerService.show();
    this.latLon = await this.appService.getGpsCoordinates();
    this.gps = this.latLon;
    this.spinnerService.hide();
    this.addPlace({ latLon: this.latLon, showFrom: 'cpPage' });
  }
  goBack() {
    this.spinnerService.show();
    setTimeout(() => {
      this.showMap = true;
      this.spinnerService.hide();
    }, 200);
  }

  searchPrompts(event: any) {
    if (event.target.value?.length > 2) {
      const params: any = { search_str: event.target.value };
      this.userPromptService
        .getUserPromptList(params)
        .subscribe((response: any) => {
          this.userPromptsSearchResults = response?.data?.filter(
            (value1: any) =>
              !this.selectedUserPrompts.some(
                (value2: any) => value1?.id === value2?.id
              )
          );
        });
    }
  }

  onLoadMore() {
    this.userPromptsPrevious = this.userPromptsPrevious + this.userPromptsRows;

    this.getUserPrompts();
  }
  getUserPrompts(event?: any) {
    let params: any = {};
    if (this.userPromptsRows) {
      params['rows'] = this.userPromptsRows;
    }
    if (this.userPromptsPrevious) {
      params['previous'] = this.userPromptsPrevious;
    }

    if (event?.target?.value) {
      params['name'] = event.target.value;
    }

    this.userPromptService
      .getUserPromptList(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          response?.data.forEach((item1: any) => {
            if (
              !this.availableUserPrompts.some(
                (item2: any) => item1?.id === item2?.id
              )
            ) {
              this.availableUserPrompts.push(item1);
            }
          });
          this.makeSelectionChanges();
          this.userPromptsTotalCount = response['total_size'];
          this.userPromptsTotalPages = Math.ceil(
            this.userPromptsTotalCount / this.userPromptsRows
          );
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  removeSelectedUserPrompt(deleteUserPrompt: any) {
    this.selectedUserPrompts = this.selectedUserPrompts?.filter(
      (savedPrompt: any) => savedPrompt?.id !== deleteUserPrompt?.id
    );

    setTimeout(() => {
      this.makeSelectionChanges();
    }, 100);
  }
  addSelectedUserPrompt(userPrompt: any) {
    if (
      this.selectedUserPrompts?.some(
        (savedPrompt: any) => savedPrompt?.id === userPrompt?.id
      )
    ) {
      this.pageService.setMessage({
        errorMessage: 'Checkpoint Already Selected',
        successMessage: '',
      });
    } else {
      this.selectedUserPrompts.push(userPrompt);
      if (
        !this.availableUserPrompts?.some(
          (savedPrompt: any) => savedPrompt?.id === userPrompt?.id
        )
      ) {
        this.availableUserPrompts.push(userPrompt);
      }
    }

    setTimeout(() => {
      this.makeSelectionChanges();
    }, 100);
  }
  addRemoveUserPrompt(event: any, userPrompt: any) {
    if (event) {
      this.addSelectedUserPrompt(userPrompt);
    } else {
      this.removeSelectedUserPrompt(userPrompt);
    }
  }
  makeSelectionChanges() {
    this.availableUserPrompts.forEach((checkpoint: any) => {
      checkpoint.selected = this.selectedUserPrompts?.some(
        (selectedCp: any) => selectedCp?.id === checkpoint?.id
      );
    });
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
}
