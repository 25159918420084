import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbAuthModule } from '@nebular/auth';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAlertModule,
  NbAutocompleteModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbChatModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbFormFieldModule,
  NbGlobalLogicalPosition,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbPopoverModule,
  NbRadioModule,
  NbSelectModule,
  NbSidebarModule,
  NbStepperModule,
  NbTabsetModule,
  NbTagModule,
  NbTimepickerModule,
  NbToastrModule,
  NbToggleModule,
  NbTooltipModule,
  NbTreeGridModule,
  NbUserModule,
} from '@nebular/theme';
import { QRCodeModule } from 'angularx-qrcode';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { WebcamModule } from 'ngx-webcam';
import { AddCustomerComponent } from '../shared/comp/add-customer/add-customer.component';
import { AddEditLicenseDetailsComponent } from '../shared/comp/add-edit-license-details/add-edit-license-details.component';
import { AddEditSiteComponent } from '../shared/comp/add-edit-site/add-edit-site.component';
import { AddPaymentCardComponent } from '../shared/comp/add-payment-card/add-payment-card.component';
import { CardListComponent } from '../shared/comp/card-list/card-list.component';
import { ChangePasswordComponent } from '../shared/comp/change-password/change-password.component';
import { ConfirmDialogComponent } from '../shared/comp/confirm-dialog/confirm-dialog.component';
import { ContactComponent } from '../shared/comp/contact/contact.component';
import { DateTimePickerComponent } from '../shared/comp/date-time-picker/date-time-picker.component';
import { DragMapComponent } from '../shared/comp/drag-map/drag-map.component';
import { EventScheudulerComponent } from '../shared/comp/event-scheuduler/event-scheuduler.component';
import { FileImportComponent } from '../shared/comp/file-import/file-import.component';
import { GtCardComponent } from '../shared/comp/gt-card/gt-card.component';
import { GtTableComponent } from '../shared/comp/gt-table/gt-table.component';
import { LicenseListComponent } from '../shared/comp/license-list/license-list.component';
import { MapLeafletComponent } from '../shared/comp/map-leaflet/map-leaflet.component';
import { MapPinsViewComponent } from '../shared/comp/map-pins-view/map-pins-view.component';
import { MultipleScubcribersComponent } from '../shared/comp/multiple-scubcribers/multiple-scubcribers.component';
import { PrompterComponent } from '../shared/comp/prompter/prompter.component';
import { QrCodeComponent } from '../shared/comp/qr-code/qr-code.component';
import { SaveNewAddressComponent } from '../shared/comp/save-new-address/save-new-address.component';
import { SchedulerComponent } from '../shared/comp/scheduler/scheduler.component';
import { TableComponent } from '../shared/comp/table/table.component';
import { UploadTypeComponent } from '../shared/comp/upload-type/upload-type.component';
import { WebcamImageComponent } from '../shared/comp/webcam-image/webcam-image.component';
import { PaginationControlComponent } from '../shared/pagination-control/pagination-control.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { AnalyticsComponent } from './admin/analytics/analytics.component';
import { AppRoutesComponent } from './admin/app-routes/app-routes.component';
import { AppUsersComponent } from './admin/app-users/app-users.component';
import { BetaUserComponent } from './admin/beta-user/beta-user.component';
import { EventLogsComponent } from './admin/event-logs/event-logs.component';
import { AddEditJobTypeComponent } from './admin/job-type-builder/add-edit-job-type/add-edit-job-type.component';
import { JobStatusComponent } from './admin/job-type-builder/job-status/job-status.component';
import { JobTypeComponent } from './admin/job-type-builder/job-type/job-type.component';
import { PaymentsComponent } from './admin/payments/payments.component';
import { ReleaseComponent } from './admin/release/release.component';
import { SubscribersComponent } from './admin/subscribers/subscribers.component';
import { ViewSubscribersDetailsComponent } from './admin/subscribers/view-subscribers-details/view-subscriber-details.component';
import { AddSubscriptionPlanComponent } from './admin/subscription-plans/add-subscription-plan/add-subscription-plan.component';
import { SubscriptionPlansComponent } from './admin/subscription-plans/subscription-plans.component';
import { SuperUsersComponent } from './admin/super-users/super-users.component';
import { UserFeedbackComponent } from './admin/user-feedback/user-feedback.component';
import { AppChecksComponent } from './app-checks/app-checks.component';
import { AppUserFeedbackComponent } from './app-user-feedback/app-user-feedback.component';
import { AddEditCheckpointComponent } from './checkpoints/add-edit-checkpoint/add-edit-checkpoint.component';
import { CheckpointComponent } from './checkpoints/checkpoint.component';
import { QrComponent } from './checkpoints/qr/qr.component';
import { ViewCheckpointComponent } from './checkpoints/view-checkpoint/view-checkpoint.component';
import { CustomerComponent } from './customer/customer.component';
import { ViewCustomerComponent } from './customer/view-customer/view-customer.component';
import { HomeComponent } from './home/home.component';
import { UpdateEventsComponent } from './home/update-events/update-events.component';
import { AddIncidentComponent } from './incidents/add-incident/add-incident.component';
import { ListIncidentsComponent } from './incidents/list-incidents/list-incidents.component';
import { ViewIncidentComponent } from './incidents/view-incident/view-incident.component';
import { InternalMessageComponent } from './internal-message/internal-message.component';
import { AddJobComponent } from './jobs/add-job/add-job.component';
import { OtherComponent } from './jobs/add-job/other/other.component';
import { PatrolComponent } from './jobs/add-job/patrol/patrol.component';
import { ResponseComponent } from './jobs/add-job/response/response.component';
import { JobsDetailEventsComponent } from './jobs/jobs-detail-events/jobs-detail-events.component';
import { JobsComponent } from './jobs/jobs.component';
import { PatrolDetailEventsComponent } from './jobs/patrol-detail-events/patrol-detail-events.component';
import { KeyAuditComponent } from './key-audit/key-audit.component';
import { MissedEventsComponent } from './missed-events/missed-events.component';
import { AddEditMonitoringCompanyComponent } from './monitoring-company/add-edit-monitoring-company/add-edit-monitoring-company.component';
import { MonitoringCompanyComponent } from './monitoring-company/monitoring-company.component';
import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { CompanyProfileComponent } from './profile/company-profile/company-profile.component';
import { UserProfileComponent } from './profile/user-profile/user-profile.component';
import { PwaInstallationGuideComponent } from './pwa-installation-guide/pwa-installation-guide.component';
import { ReportsComponent } from './reports/reports.component';
import { AddEditResponseTypeComponent } from './response-type/add-edit-response-type/add-edit-response-type.component';
import { ResponseTypeComponent } from './response-type/response-type.component';
import { RosterComponent } from './roster-schedule/roster/roster.component';
import { PaymentFormComponent } from './subscription/payment-form/payment-form.component';
import { SubscriptionComponent } from './subscription/subscription.component';

import { GeoFenceAlertComponent } from '../shared/comp/geo-fence-alert/geo-fence-alert.component';
import { WhatsNewComponent } from '../shared/comp/whats-new/whats-new.component';
import { CustomDatePipe } from '../shared/pipes/customdate.pipe';
import { AdminReportComponent } from './admin/admin-report/admin-report.component';
import { BarChartComponent } from './admin/analytics/bar-chart/bar-chart.component';
import { UserHistoryComponent } from './admin/app-users/user-history/user-history.component';
import { UserLogComponent } from './admin/app-users/user-log/user-log.component';
import { SmartNetComponent } from './admin/smart-net/smart-net.component';
import { SubscriberAppDataComponent } from './admin/subscribers/view-subscribers-details/subscriber-app-data/subscriber-app-data.component';
import { SubscriberRosterComponent } from './admin/subscribers/view-subscribers-details/subscriber-app-data/subscriber-roster/subscriber-roster.component';
import { AddEditIncidentTypeComponent } from './incidents/incident-type/add-edit-incident-type/add-edit-incident-type.component';
import { ListIncidentTypesComponent } from './incidents/incident-type/list-incident-types/list-incident-types.component';
import { SosAlertsComponent } from './sos-alerts/sos-alerts.component';
import { AddEditViewUserGroupComponent } from './user-group/add-edit-view-user-group/add-edit-view-user-group.component';
import { UserGroupComponent } from './user-group/user-group.component';
import { AddEditUserPromptComponent } from './user-prompts/add-edit-user-promt/add-edit-user-prompt.component';
import { ListUserPromptsComponent } from './user-prompts/list-user-prompts/list-user-prompts.component';
import { UserUpdatesLogComponent } from './user-updates-log/user-updates-log.component';
import { AddEditUsersComponent } from './users/add-edit-users/add-edit-users.component';
import { GuardsOnlineComponent } from './users/guards-online/guards-online.component';
import { LicencesComponent } from './users/licences/licences.component';
import { UsersComponent } from './users/users.component';

@NgModule({
  declarations: [
    PagesComponent,
    JobsComponent,
    AddJobComponent,
    CustomerComponent,
    ViewCustomerComponent,
    ContactComponent,
    AddCustomerComponent,
    CompanyProfileComponent,
    SubscriptionComponent,
    TableComponent,
    GtTableComponent,
    PaymentFormComponent,
    ConfirmDialogComponent,
    UsersComponent,
    AddEditUsersComponent,
    AdminComponent,
    SubscriptionPlansComponent,
    AddSubscriptionPlanComponent,
    SubscribersComponent,
    EventLogsComponent,
    AnalyticsComponent,
    ViewSubscribersDetailsComponent,
    AdminDashboardComponent,
    CheckpointComponent,
    AddEditCheckpointComponent,
    MapLeafletComponent,
    AddEditLicenseDetailsComponent,
    SchedulerComponent,
    SaveNewAddressComponent,
    EventScheudulerComponent,
    PaginationControlComponent,
    ViewCheckpointComponent,
    AddEditViewUserGroupComponent,
    UserUpdatesLogComponent,
    FileImportComponent,
    UserGroupComponent,
    AppChecksComponent,
    AddEditSiteComponent,
    UserProfileComponent,
    MultipleScubcribersComponent,
    ChangePasswordComponent,
    AppRoutesComponent,
    SuperUsersComponent,
    PaymentsComponent,
    CardListComponent,
    LicenseListComponent,
    JobsDetailEventsComponent,
    BetaUserComponent,
    JobTypeComponent,
    JobStatusComponent,
    AddEditJobTypeComponent,
    DragMapComponent,
    UserFeedbackComponent,
    WebcamImageComponent,
    AppUserFeedbackComponent,
    DateTimePickerComponent,
    CustomDatePipe,
    AddPaymentCardComponent,
    HomeComponent,
    UpdateEventsComponent,
    ReleaseComponent,
    MonitoringCompanyComponent,
    ResponseTypeComponent,
    MapPinsViewComponent,
    AddEditMonitoringCompanyComponent,
    AddEditResponseTypeComponent,
    QrCodeComponent,
    QrComponent,
    ResponseComponent,
    OtherComponent,
    ListIncidentsComponent,
    ViewIncidentComponent,
    AddIncidentComponent,
    RosterComponent,
    AppUsersComponent,
    LicencesComponent,
    ReportsComponent,
    PatrolComponent,
    PatrolDetailEventsComponent,
    GuardsOnlineComponent,
    PwaInstallationGuideComponent,
    MissedEventsComponent,
    UploadTypeComponent,
    GtCardComponent,

    InternalMessageComponent,
    KeyAuditComponent,
    PrompterComponent,
    AddEditUserPromptComponent,
    ListUserPromptsComponent,
    AdminReportComponent,
    WhatsNewComponent,
    GeoFenceAlertComponent,
    UserLogComponent,
    SubscriberAppDataComponent,
    BarChartComponent,
    SubscriberRosterComponent,
    UserHistoryComponent,
    SosAlertsComponent,
    ListIncidentTypesComponent,
    AddEditIncidentTypeComponent,
    SmartNetComponent,
  ],
  exports: [PaymentFormComponent],
  imports: [
    CommonModule,
    NbIconModule,
    NbDialogModule.forRoot({
      closeOnBackdropClick: false,
    }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    // NbThemeModule.forRoot({ name: 'dark' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbUserModule,
    QRCodeModule,
    NbActionsModule,
    NbCardModule,
    NbContextMenuModule,
    FormsModule,
    NbToastrModule.forRoot({
      position: NbGlobalLogicalPosition.TOP_START,
      status: 'basic',
      icon: '',
      destroyByClick: true,
      duration: 10000,
    }),
    NbAutocompleteModule,
    NbSelectModule,
    ReactiveFormsModule,
    NbInputModule,
    NbFormFieldModule,
    NbButtonModule,
    NbStepperModule,
    NbAlertModule,
    NbRadioModule,
    NbToggleModule,
    NbDatepickerModule.forRoot(),
    NbAuthModule.forRoot(),
    WebcamModule,
    NbBadgeModule,
    NbPopoverModule,
    NbListModule,
    NbCheckboxModule,
    NbAccordionModule,
    HighchartsChartModule,
    NbTreeGridModule,
    NbTabsetModule,
    NgxMatTimepickerModule,
    NbTimepickerModule.forRoot({
      localization: {
        hoursText: 'Hr',
        minutesText: 'Min',
        secondsText: 'Sec',
        ampmText: 'Am/Pm',
      },
    }),
    DragDropModule,
    NbTagModule,
    NbTooltipModule,
    PagesRoutingModule,
    NbChatModule,
  ],
})
export class PagesModule {}
