<nb-card *ngIf="!checkPointForm.value?.qr_text" class="qr-dialog">
  <div class="sc-mt-6 scrollable-y">
    <div *ngIf="!cameraFeedDetected" class="mt-5">
      <nb-alert outline="warning" role="alert" accent="info">
        <div>
          There was a problem getting your camera feed, please try the
          following:<br />
          1. Check your browser permissions and ensure camera access is
          allowed.<br />
          2. Try running Guard Tracker in a new browser tab.<br />
          3. Power off your device, wait 5 seconds, power on and try again.
        </div>
      </nb-alert>
    </div>
    <div id="html5-qr-reader"></div>
  </div>

  <div class="gt-p-1" *ngIf="zoomRange?.max && zoomRange?.min">
    <label style="color: white" class="mx-2"> Zoom</label>
    <label style="color: white" class="sc-floating-right mx-2">
      {{ zoomRange.value }} x</label
    >
    <input
      style="width: 100%"
      type="range"
      [min]="zoomRange?.min"
      placeholder="Zoom"
      [max]="zoomRange?.max"
      [(ngModel)]="zoomRange.value"
      (mouseup)="applyZoom()"
      (touchend)="applyZoom()"
    />
  </div>

  <div
    [ngClass]="
      html5torch ? 'row row-cols-2 gt-p-small' : 'row row-cols-1 gt-p-small'
    "
  >
    <div class="col">
      <button
        nbButton
        size="giant"
        status="danger"
        hero
        fullWidth
        (click)="onCloseDialogue()"
      >
        Cancel
      </button>
    </div>
    <div class="col" *ngIf="html5torch">
      <button
        status="primary"
        hero
        (click)="turnOffOnTorchHTML5(devieTorchOn ? 'off' : 'on')"
        nbButton
        fullWidth
        size="giant"
        class="nbbutton-long-text"
      >
        {{ devieTorchOn ? "Turn off Torch" : "Turn on Torch" }}
      </button>
    </div>
  </div>
</nb-card>

<nb-card
  class="dialog-box gt-p-small"
  *ngIf="checkPointForm.value?.qr_text && showForm"
>
  <nb-card-header>
    {{ checkPointScanData?.id ? "Scan Checkpoint" : "Register QR Code" }}

    <div *ngIf="checkPointForm.value?.qr_text" class="sc-text-center">
      <span class="uppercase strong">
        {{
          checkPointForm.value?.qr_text.length == 44
            ? checkPointForm.value?.qr_text.slice(32)
            : checkPointForm.value?.qr_text
        }}</span
      >
    </div>
  </nb-card-header>

  <!--register QR html code-->

  <!-- register an existing QR to a new checkpoint-->

  <div class="sc-mt-6" *ngIf="checkPointForm.value?.qr_text && showForm">
    <div *ngIf="offlineMode">
      <nb-alert status="danger">
        NO INTERENT CONNECTIVITY: Some features will not be avaliable
      </nb-alert>
    </div>
    <form [formGroup]="checkPointForm">
      <nb-stepper #stepper>
        <nb-step>
          <div class="form-floating">
            <input
              class="form-control"
              fullWidth
              formControlName="name"
              nbInput
              [maxlength]="100"
              type="text"
              (keyup.enter)="shiftFocus('clientName')"
              placeholder="Checkpoint Name"
            />
            <label for="inputService">Checkpoint Name</label>
          </div>

          <div class="form-floating">
            <input
              autocomplete="off"
              class="form-control"
              fullWidth
              id="clientName"
              formControlName="company"
              (input)="onClientSearch($event)"
              nbInput
              type="text"
              placeholder="Client"
              [nbAutocomplete]="clientACMob"
            />

            <label>Client</label>
          </div>

          <nb-autocomplete
            #clientACMob
            (selectedChange)="onClientSelect($event)"
          >
            <nb-option
              [value]="' '"
              *ngIf="isAdmin || isDispatchUser"
              [disabled]="offlineMode"
            >
              <b> Add New Client</b>
            </nb-option>
            <nb-option *ngFor="let item of customerListData" [value]="item">
              <div>
                <div class="strong">
                  {{ item?.company_name }}
                </div>
                <div>
                  <small>{{ item?.address?.full_address }}</small>
                </div>
              </div>
            </nb-option>
          </nb-autocomplete>
          <div class="form-floating">
            <input
              class="form-control"
              fullWidth
              id="description"
              formControlName="description"
              nbInput
              type="text"
              placeholder="Description (Optional)"
            /><label for="inputAssignee">Description (Optional)</label>
          </div>

          <div class="sc-mt-9">
            <nb-checkbox
              (checkedChange)="registerScan = !registerScan"
              [checked]="registerScan"
              >Scan Checkpoint After Adding</nb-checkbox
            >
          </div>
          <div
            class="mt-4 d-flex align-items-center"
            *ngIf="isAdmin || isDispatchUser"
          >
            <nb-checkbox
              [disabled]="offlineMode"
              formControlName="is_locked"
              (checkedChange)="showHideAddressFields($event)"
              >Set Address & Lock Checkpoint</nb-checkbox
            >
            <nb-icon
              [icon]="'alert-circle-outline'"
              class="mb-1 ms-4"
              pack="eva"
              nbTooltip="When ticked, checkpoint can only be scanned at checkpoint location."
              nbTrigger="focus"
              status="primary"
            ></nb-icon>
          </div>

          <div class="row row-cols-2 sc-mt-6">
            <div class="col">
              <button
                type="button"
                (click)="onCloseDialogue()"
                nbButton
                fullWidth
                size="giant"
                class="nbbutton-long-text"
              >
                Cancel
              </button>
            </div>
            <div
              class="col"
              *ngIf="
                checkPointForm.value.is_locked && (isAdmin || isDispatchUser)
              "
            >
              <button
                type="button"
                class="nbbutton-long-text"
                nbButton
                fullWidth
                size="giant"
                [disabled]="!checkPointForm.valid"
                nbStepperNext
                (click)="showMapTrue()"
              >
                Select Location
              </button>
            </div>
            <div class="col" *ngIf="!checkPointForm.value.is_locked">
              <button
                type="submit"
                [disabled]="!checkPointForm.valid"
                nbButton
                status="primary"
                fullWidth
                size="giant"
                class="nbbutton-long-text"
                (click)="onClickSubmit()"
              >
                Add Checkpoint
              </button>
            </div>
          </div>
        </nb-step>

        <nb-step>
          <div class="row row-cols-2">
            <div class="col">
              <button
                nbStepperPrevious
                nbButton
                class="nbbutton-long-text"
                fullWidth
                ghost
                size="giant"
                (click)="backPreviousStep()"
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                type="submit"
                nbButton
                status="primary"
                class="nbbutton-long-text"
                fullWidth
                [disabled]="!checkPointForm.valid"
                size="giant"
                (click)="onClickSubmit()"
              >
                Add Checkpoint
              </button>
            </div>
          </div>

          <div *ngIf="showMap && gps">
            <div class="form-floating">
              <input
                id="addressSearch"
                class="form-control"
                #addressAutoInput
                (input)="onAddressSearch($event)"
                nbInput
                fullWidth
                autocomplete="off"
                formControlName="addressLookup"
                type="text"
                placeholder="Address"
                [nbAutocomplete]="autoNgModelEditCheckpoint"
              /><label for="addressLookup">Address</label>
            </div>

            <nb-autocomplete
              #autoNgModelEditCheckpoint
              (selectedChange)="addressSelected($event)"
            >
              <nb-option [value]="' '"
                ><b> Enter address manually </b>
              </nb-option>
              <nb-option
                *ngFor="let item of addrLookupData$ | async"
                [value]="item"
              >
                <div>
                  <span
                    ><b>{{ item.address1 + " " + item.address2 }}</b
                    >,</span
                  ><br />
                  <small
                    >{{ item.address3 ? item.address3 + " " : "" }}
                    {{ item.city_name }} {{ item.state_name }}
                    {{ item.postcode }}</small
                  >
                </div>
              </nb-option>
            </nb-autocomplete>
            <app-map-leaflet
              [latLon]="gps"
              [mapId]="'checkpointEditmapid'"
              [cursorDraggable]="true"
              (emitData)="emitData($event)"
              (updateMinDistance)="updateMinDistance($event)"
            ></app-map-leaflet>
            <div
              *ngIf="
                (checkPointForm.value?.latitude &&
                  checkPointForm.value?.longitude) ||
                (gps.lat && gps.lon)
              "
              style="
                text-align: center;
                text-transform: uppercase;
                font-size: 0.7rem;
              "
            >
              <span style="font-size: 0.7rem">
                Lat:
                {{
                  checkPointForm.value?.latitude
                    ? checkPointForm.value?.latitude
                    : gps.lat
                }}
                Lon:
                {{
                  checkPointForm.value?.longitude
                    ? checkPointForm.value?.longitude
                    : gps.lon
                }}
              </span>
              <div>Drag the marker to set coordinates</div>
            </div>
          </div>
        </nb-step>
      </nb-stepper>
    </form>
  </div>
</nb-card>
