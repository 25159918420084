<nb-card class="dialog-box">
  <nb-card-header
    >{{
      editUser
        ? detailData?.full_name
          ? detailData?.full_name
          : "User Info"
        : "Add User"
    }}
    <nb-icon
      (click)="onCloseDialogue('close')"
      [icon]="'close-outline'"
      class="sc-floating-right"
      pack="eva"
    ></nb-icon>
  </nb-card-header>

  <nb-card-body class="scrollable-y">
    <ng-container *ngIf="!editUser">
      <form [formGroup]="userForm" #f="ngForm" appFormEnterAction>
        <nb-stepper #stepper [linear]="linearMode">
          <nb-step [stepControl]="selectForm" label="Name">
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                fullWidth
                nbInput
                id="email"
                formControlName="email"
                autocomplete="off"
                placeholder="Email"
                autocorrect="off"
                autofocus
                autocapitalize="off"
              />
              <label for="email">Email</label>
            </div>
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                nbInput
                fullWidth
                id="first_name"
                formControlName="first_name"
                autocomplete="off"
                placeholder="First Name"
                autofocus
              />
              <label>First Name</label>
            </div>
            <div class="form-floating">
              <input
                fullWidth
                class="form-control"
                type="text"
                nbInput
                id="last_name"
                formControlName="last_name"
                autocomplete="off"
                placeholder="Last Name"
                autofocus
              />
              <label>Last Name</label>
            </div>
            <div *ngIf="super_admin != true">
              <nb-select
                size="giant"
                fullWidth
                multiple
                [(selected)]="selectedGroup"
                placeholder="User Groups"
                formControlName="group_ids"
              >
                <nb-option
                  *ngFor="let grp of userGroups"
                  [disabled]="grp.group_id === 4"
                  [value]="grp.id"
                  matRipple
                  >{{ grp.name }}</nb-option
                >
              </nb-select>
            </div>
            <div class="row row-cols-2">
              <div class="col">
                <button
                  type="button"
                  fullWidth
                  (click)="onClearForm()"
                  nbButton
                  size="giant"
                  ghost
                >
                  Clear
                </button>
              </div>
              <div class="col">
                <button
                  fullWidth
                  [disabled]="!userForm.valid"
                  nbButton
                  size="giant"
                  (click)="sendAddUserLink()"
                >
                  Submit
                </button>
              </div>
            </div>
          </nb-step>
        </nb-stepper>
      </form>
    </ng-container>

    <!-- USER DETAILS DIALOG / POPOVER -->
    <ng-container *ngIf="editUser">
      <div
        *ngIf="detailData?.last_activity_time"
        class="mb-3 sc-text-center"
        style="font-weight: 500; display: block; font-size: 0.8rem"
      >
        Last activity
        {{ detailData?.last_activity_time | customdate : "d MMM y HH:mm" }}
      </div>

      <div class="row row-cols-1">
        <div class="col sc-text-center">
          <img
            *ngIf="detailData?.profile_image"
            src="{{ detailData?.profile_image }}"
            alt="User-Profile-Image"
            height="120"
          />
        </div>

        <div class="row sc-mt-6">
          <div *ngIf="detailData?.first_name">
            <div class="badge-key">Name</div>
            <div class="badge-value">{{ detailData?.full_name }}</div>
          </div>

          <div>
            <div class="badge-key">Status</div>
            <div class="badge-value">{{ detailData?.user_app_status }}</div>
          </div>

          <div class="badge-key">Email</div>
          <div class="badge-value">
            <a href="mailto: {{ detailData?.email }}">{{
              detailData?.email
            }}</a>
          </div>
          <ng-container *ngIf="detailData?.mobile_number">
            <div class="badge-key">
              Mobile
              <span><nb-icon pack="eva" icon="phone-outline"></nb-icon></span>
            </div>
            <div class="badge-value">
              <a href="tel:{{ detailData?.mobile_number }} "
                >{{ detailData?.mobile_number }}
              </a>
            </div>
          </ng-container>
          <ng-container *ngIf="isAdmin && fromUserPage">
            <div class="badge-key">Groups</div>
            <nb-select
              *ngIf="selectedUserGroups?.length && userGroups?.length"
              size="medium"
              fullWidth
              size="large"
              multiple
              (selectedChange)="assignGroups($event)"
              [(selected)]="selectedUserGroups"
              placeholder="User Groups"
              class="mb-2 mt-0"
            >
              <nb-option
                [disabled]="disableSelectOption(grp)"
                *ngFor="let grp of userGroups"
                [value]="grp.id"
                matRipple
                >{{ grp.name }}</nb-option
              >
            </nb-select>
          </ng-container>
          <ng-container *ngIf="isAdmin && !fromUserPage">
            <div class="badge-key">Groups</div>
            <div class="badge-value">
              {{ userGroupNames }}
            </div>
          </ng-container>
        </div>
      </div>

      <ng-container
        *ngIf="!detailData?.is_registered && isAdmin && fromUserPage"
      >
        <div class="col">
          <button
            size="large"
            class="nbbutton-long-text"
            hero
            status="primary"
            nbButton
            (click)="reInviteUser(detailData)"
          >
            Resend User Sign In Link
          </button>
        </div>
      </ng-container>

      <div class="row row-cols-2" *ngIf="adminCheck() && fromUserPage">
        <div class="col">
          <button
            size="large"
            fullWidth
            class="nbbutton-long-text"
            [status]="detailData?.is_active ? 'warning' : 'success'"
            nbButton
            (click)="disableOrEnableUser(detailData?.id)"
          >
            {{ detailData?.is_active ? "Disable Sign-In" : "Enable Sign-In" }}
          </button>
        </div>

        <div class="col">
          <button
            size="large"
            fullWidth
            status="danger"
            outline
            class="nbbutton-long-text"
            nbButton
            (click)="deleteUserById(detailData)"
          >
            Delete User
          </button>
        </div>
      </div>

      <div *ngIf="detailData?.is_superuser">
        <button
          fullWidth
          status="danger"
          type="button"
          nbButton
          (click)="deleteSuperUserById(detailData)"
        >
          Delete User
        </button>
      </div>
      <div class="mt-3 sc-text-center" *ngIf="!detailData?.license?.length">
        <nb-alert
          >This user has not saved any Security Licences on their user
          profile</nb-alert
        >
      </div>
      <!-- LIST OF USER LICENCES -->
      <div *ngIf="detailData.license?.length">
        <hr />
        <div class="sc-head6">
          <strong>Licenses</strong>
        </div>
        <app-license-list
          [listLicenseData]="detailData?.license"
          [listCardDetails]="licenseDetails"
        ></app-license-list>
      </div>
    </ng-container>
  </nb-card-body>
</nb-card>
