<div id="sc-card-container">
  <nb-card-body>
    <div
      *ngIf="fileImportInProgress"
      class="alert alert-warning alert-dismissible fade show"
      role="alert"
    >
      <strong>File(s) import in progress ({{ fileImportInProgress }})</strong>
    </div>

    <nb-alert
      *ngIf="showArchivedList"
      class="strong sc-text-center mb-3"
      outline="basic"
    >
      Showing Deleted Clients
    </nb-alert>

    <!-- MOBILE VIEW -->
    <div class="mobile-only">
      <!-- case where there are atleaset 5 recorrds-->
      <div *ngIf="totalClientCount > searchRemovalCap">
        <nb-form-field class="sc-search-input-box">
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              nbInput
              fullWidth
              placeholder="Search"
              [(ngModel)]="globalFilter"
              (keyup)="onFilterApply($event)"
              [nbAutocomplete]="autoNgModel"
            />
            <label>Search</label>
          </div>
          <nb-icon
            nbSuffix
            *ngIf="showTypeAhead === true"
            [icon]="'search-outline'"
            pack="eva"
            (click)="onFilterApply(true)"
          ></nb-icon>
          <nb-icon
            nbSuffix
            *ngIf="showTypeAhead === false"
            [icon]="'close-outline'"
            pack="eva"
            (click)="onClearFilter()"
          ></nb-icon>
        </nb-form-field>
      </div>
      <div
        *ngIf="
          (isAdmin === true || isDispatchUser === true) && !showArchivedList
        "
      >
        <button
          size="giant"
          class="status-button"
          fullWidth
          hero
          status="primary"
          (click)="openAddClientForm()"
          nbButton
        >
          + Add Client
        </button>
      </div>
    </div>

    <!-- DESKTOP VIEW -->
    <div class="large-view-only" *ngIf="totalClientCount > searchRemovalCap">
      <div class="flex-center">
        <nb-form-field class="sc-search-input-box">
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              nbInput
              fullWidth
              placeholder="Search Clients"
              [(ngModel)]="globalFilter"
              (keyup)="onFilterApply($event)"
              [nbAutocomplete]="autoNgModel"
            />
            <label>Search Clients</label>
          </div>
          <nb-icon
            class="ico-color"
            nbSuffix
            *ngIf="showTypeAhead === true"
            [icon]="'search-outline'"
            pack="eva"
            (click)="onFilterApply(true)"
          ></nb-icon>
          <nb-icon
            class="ico-color"
            nbSuffix
            *ngIf="showTypeAhead === false"
            [icon]="'close-outline'"
            pack="eva"
            (click)="onClearFilter()"
          ></nb-icon>
        </nb-form-field>
        <div
          class="sc-add-btn"
          *ngIf="isAdmin === true || isDispatchUser === true"
        >
          <!-- Add Client Button -->
          <button
            title="Add Client"
            class="sc-mt-0"
            size="giant"
            nbButton
            hero
            status="primary"
            type="button"
            (click)="openAddClientForm()"
          >
            + Add Client
          </button>
        </div>

        <!-- Archived/ Unarchived List -->
        <div class="sc-add-btn" *ngIf="archivedCount && isAdmin">
          <button
            class="sc-mt-0"
            size="giant"
            nbButton
            type="button"
            (click)="getArchivedUnarchivedList()"
          >
            {{ showArchivedList ? "Show Active" : "Show Deleted" }}
          </button>
        </div>

        <!-- Bulk Upload -->
        <div class="sc-add-btn" *ngIf="isAdmin === true">
          <button
            class="sc-mt-0 sc-add-btn-width"
            size="giant"
            nbButton
            type="button"
            (click)="openUploadDialogBox()"
          >
            <nb-icon
              class="ico-bulk-upload"
              [icon]="'upload'"
              pack="eva"
            ></nb-icon>
          </button>
        </div>
        <div>
          <button
            size="giant"
            nbButton
            class="sc-ml-1 mt-0"
            (click)="switchTableCardView()"
          >
            <nb-icon
              class="ico-color"
              [icon]="viewType == 'tableView' ? 'grid-outline' : 'menu-outline'"
              pack="eva"
            >
            </nb-icon>
          </button>
        </div>
      </div>
      <div></div>
    </div>
    <nb-autocomplete #autoNgModel>
      <nb-option
        *ngFor="let item of showAutoCustomerDetails$ | async"
        [value]="item"
        (click)="openCustomerView(item)"
      >
        <div class="strong">
          {{ item.company_name }}
        </div>
      </nb-option>
    </nb-autocomplete>
    <div
      class="row"
      *ngIf="
        (isAdmin === true || isDispatchUser === true) &&
        totalClientCount <= searchRemovalCap
      "
    >
      <!-- case where there are less than 5 records ; don't show search bar-->
      <div class="row">
        <!-- Add Client Button -->
        <div class="col-sm-6 col-lg-3 sc-pb-0 large-view-only">
          <button
            size="giant"
            class="status-button"
            hero
            status="primary"
            fullWidth
            (click)="openAddClientForm()"
            nbButton
          >
            + Add Client
          </button>
        </div>

        <!-- Bulk Upload -->
        <div
          class="col-sm-6 col-lg-3 sc-pb-0 large-view-only"
          *ngIf="isAdmin === true"
        >
          <button
            size="giant"
            class="status-button"
            fullWidth
            (click)="openUploadDialogBox()"
            nbButton
          >
            Bulk Upload
          </button>
        </div>
        <div
          *ngIf="archivedCount && isAdmin"
          (click)="getArchivedUnarchivedList()"
          class="col-sm-6 col-lg-3 sc-pb-0 large-view-only"
        >
          <button
            size="giant"
            class="nbbutton-long-text status-button"
            fullWidth
            nbButton
          >
            {{ showArchivedList ? "Active Clients" : "Archived clients" }}
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="clientCount > 0">
      <ng-container *ngIf="viewType == 'cardView'">
        <div class="sc-mt-6">
          <app-card-list
            [listCardData]="clientPaginationData[pageNum]"
            [listCardDetails]="clientCardDetils.cardInfo"
            (rowClick)="openCustomerView($event)"
          ></app-card-list>
        </div>

        <!--customer pagination BEGINS-->
        <div>
          <app-pagination-control
            [pageSize]="pageSize"
            [pageNum]="pageNum"
            [previous]="previous"
            [totalRows]="clientCount"
            [totalPages]="totalPages"
            (onClickPagination)="onClickPagination($event)"
            (onChangePagination)="onChangePagination($event)"
          >
          </app-pagination-control>
        </div>
        <!--customer pagination ENDS-->
      </ng-container>
      <ng-container *ngIf="clientCount && viewType == 'tableView'">
        <div class="row row-cols-1" #target>
          <!-- TABLE OF CHECKPOINTS -->
          <div class="col">
            <app-gt-table
              *ngIf="viewType == 'tableView'"
              [desktopTableBackendData]="clientPaginationData[pageNum]"
              [desktopTableDetails]="clientDetailsTableView"
              [tableStyle]="tableStyle"
              [otherParams]="otherParams"
              (sortColumn)="sortColumn($event)"
              (rowClick)="openCustomerView($event)"
              (paginationChange)="onChangeTablePagination($event)"
            >
            </app-gt-table>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="clientCount < 1">
      <nb-alert outline="basic">No records found</nb-alert>
    </div>
    <div
      *ngIf="archivedCount && isAdmin"
      (click)="getArchivedUnarchivedList()"
      class="mobile-only mt-2"
    >
      <button
        fullWidth
        nbButton
        [ngClass]="showArchivedList ? '' : 'nbbutton-long-text'"
        [size]="showArchivedList ? 'giant' : 'medium'"
      >
        {{ showArchivedList ? "Go Back" : "Deleted Clients" }}
      </button>
    </div>
  </nb-card-body>
</div>
