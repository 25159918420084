  <div class="mobile-only">
    <div class="gt-logo-mobile">
      <img
        src="../../../assets/images/Guard_Tracker-Stacked.png"
        alt="Guard Tracker"
      />
    </div>
  </div>
  <div class="large-view-only">
    <div class="gt-logo-desktop">
      <img
        src="../../../assets/images/Guard_Tracker-Lateral_Long_default.png"
        alt="Guard Tracker"
      />
    </div>
  </div>

<div class="gt-p-1">
  <ng-container *ngIf="showErrorMsg">
    <div
      *ngIf="showErrorMsg !== undefined"
      class="alert alert-warning alert-dismissible fade show"
      role="alert"
    >
      <strong>{{ showErrorMsg }}</strong>
    </div>

    <form
      [formGroup]="userEmailForm"
      appFormEnterAction
      *ngIf="showExpiryEmail"
    >
      <div class="form-floating">
        <input
          fullWidth
          class="form-control"
          type="text"
          nbInput
          id="email"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          placeholder="Email Address"
          autofocus
          formControlName="email"
        />
        <label for="email">Email Address</label>
      </div>
      <div>
        <button
          class="sc-floating-right"
          type="button"
          [disabled]="!userEmailForm.valid"
          (click)="refreshExpiredLink()"
          nbButton
          size="giant"
        >
          Submit
        </button>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="userLinkedSubscribers?.length">
    <div class="row row-cols-1 sc-text-center gy-2">
      <em>Select instance</em>
      <div
        *ngFor="let subscriber of userLinkedSubscribers"
        (click)="selectSubscriber(subscriber)"
        class="col"
      >
        <nb-card class="contact-card">
          <div class="table-card-data pointer">
            <div class="strong">
              {{ subscriber.name }}
            </div>
            <div class="small-font">
              {{ subscriber?.address?.full_address }}
            </div>
          </div>
        </nb-card>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showDetail === 'change_password'">
    <form
      [formGroup]="passwordForm"
      (ngSubmit)="changePassword()"
      appFormEnterAction
    >
      <nb-form-field>
        <div class="form-floating">
          <input
            class="form-control"
            nbInput
            fullWidth
            [type]="getInputType()"
            name="password"
            id="password"
            formControlName="password"
            placeholder="New Password"
            [status]="'success'"
            [minlength]="8"
            [status]="
              passwordForm.controls['password'].dirty
                ? passwordForm.controls['password'].invalid
                  ? 'danger'
                  : 'primary'
                : ''
            "
          />
          <label for="password">New Password</label>
        </div>
        <nb-icon
          nbSuffix
          (click)="toggleShowPassword()"
          [icon]="showPassword ? 'eye-outline' : 'eye-off-outline'"
          pack="eva"
        >
        </nb-icon>
      </nb-form-field>
      <small *ngIf="passwordForm.controls['password'].hasError('minlength')"
        >Must have at least 8 characters</small
      >

      <div class="form-floating">
        <input
          class="form-control"
          nbInput
          fullWidth
          [type]="getInputType()"
          name="confirm_password"
          id="confirm_password"
          formControlName="confirm_password"
          placeholder="Confirm Password"
          [status]="'success'"
          [minlength]="8"
          [status]="
            passwordForm.controls['password'].dirty
              ? passwordForm.controls['password'].invalid
                ? 'danger'
                : 'primary'
              : ''
          "
        />
        <label for="confirm_password">Confirm Password</label>
      </div>
      <small
        *ngIf="
          passwordForm.controls['confirm_password'].value &&
          passwordForm.hasError('notMatch')
        "
        >Passwords do not match</small
      >

      <div class="row row-cols-2">
        <div class="col">
          <button
            nbButton
            size="giant"
            fullWidth
            ghost
            (click)="onCloseDialogue()"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            nbButton
            id="form-enter-submit-btn"
            size="giant"
            [disabled]="!passwordForm.valid"
            fullWidth
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="showDetail === 'update_email'">
    <form [formGroup]="emailForm" (ngSubmit)="changeEmail()" appFormEnterAction>
      <div class="form-floating">
        <input
          class="form-control"
          nbInput
          fullWidth
          type="text"
          name="old_email"
          id="old_email"
          formControlName="old_email"
          placeholder="Enter current email address"
          [status]="'success'"
          [minlength]="8"
          [status]="
            emailForm.controls['old_email'].dirty
              ? emailForm.controls['old_email'].invalid
                ? 'danger'
                : 'primary'
              : ''
          "
          autocorrect="off"
          autocapitalize="off"
        />
        <label for="old_email">Enter current email address</label>
      </div>

      <div col-6 offset-sm-6>
        <button
          class="sc-floating-right"
          nbButton
          size="giant"
          id="form-enter-submit-btn"
          [status]="'success'"
          [disabled]="!emailForm.valid || changeEmailPressed"
        >
          Submit
        </button>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="downloadFileUrlS3Dict?.file_url">
    <div class="text-center">
      <button
        nbButton
        size="large"
        [status]="'primary'"
        (click)="downloadS3File()"
      >
        Download Report
      </button>
    </div>
  </ng-container>
</div>
