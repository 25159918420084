<nb-card [ngClass]="dialogBox ? 'dialog-box gt-p-small' : ''">
  <nb-card-header *ngIf="dialogBox">
    Schedule
    <nb-icon
      (click)="onCloseDialogue()"
      [icon]="'close-outline'"
      class="sc-floating-right"
      pack="eva"
    ></nb-icon
  ></nb-card-header>
  <div>
    <nb-alert
      *ngIf="errorMessage"
      status="warning"
      closable
      (close)="onAlertDismis()"
    >
      {{ errorMessage }}
    </nb-alert>
    <ng-container>
      <form [formGroup]="schedulerForm" appFormEnterAction>
        <ng-container *ngIf="!showRecurringView">
          <!-- one time events-->
          <div class="row my-0 row-cols-2">
            <div class="form-floating">
              <input
                nbInput
                id="startDay"
                class="form-control"
                fullWidth
                placeholder="Start Date"
                [nbDatepicker]="dateTimePicker0"
                formControlName="start_day"
                [(ngModel)]="startDate"
              /><label for="inputAssignee">Start</label>
              <nb-datepicker
                (dateChange)="formateStartDate($event)"
                #start_day
                [min]="validStartDate"
                #dateTimePicker0
              ></nb-datepicker>
            </div>
            <div class="form-floating">
              <input
                nbInput
                class="form-control"
                type="text"
                id="startTime"
                placeholder="Start Time"
                fullWidth
                formControlName="start_time"
                [ngxMatTimepicker]="timePicker1"
                [(ngModel)]="startTime"
              /><label for="inputAssignee">Start Time</label>
              <ngx-mat-timepicker
                (timeSet)="formatStartTime($event)"
                #timePicker1
              ></ngx-mat-timepicker>
            </div>

            <div class="form-floating">
              <input
                nbInput
                class="form-control"
                fullWidth
                id="endDay"
                placeholder="End Date"
                [nbDatepicker]="dateTimePicker1"
                formControlName="end_day"
                [(ngModel)]="endDate"
              /><label for="inputAssignee">End</label>
              <nb-datepicker
                (dateChange)="formateEndDate($event)"
                #end_day
                #dateTimePicker1
                [min]="startDate"
              ></nb-datepicker>
            </div>
            <div class="form-floating">
              <input
                nbInput
                class="form-control"
                type="text"
                placeholder=" End Time"
                fullWidth
                id="endTime"
                [(ngModel)]="endTime"
                formControlName="end_time"
                [ngxMatTimepicker]="timePicker2"
              /><label for="inputAssignee">End Time</label>
              <ngx-mat-timepicker
                (timeSet)="formatEndTime($event)"
                #timePicker2
              ></ngx-mat-timepicker>
            </div>
          </div>
        </ng-container>
        <!-- repeat event details-->
        <ng-container *ngIf="showRecurringView">
          <div class="row my-0 row-cols-2">
            <div class="form-floating col-12">
              <input
                nbInput
                id="startDay"
                class="form-control"
                fullWidth
                placeholder="Start Date"
                [nbDatepicker]="dateTimePicker0"
                formControlName="start_day"
                [(ngModel)]="startDate"
              /><label for="inputAssignee">Start</label>
              <nb-datepicker
                (dateChange)="formateStartDate($event)"
                #start_day
                [min]="validStartDate"
                #dateTimePicker0
              ></nb-datepicker>
            </div>
            <div class="form-floating">
              <input
                nbInput
                class="form-control"
                type="text"
                id="startTime"
                placeholder="Start Time"
                fullWidth
                formControlName="start_time"
                [ngxMatTimepicker]="timePicker1"
                [(ngModel)]="startTime"
              /><label for="inputAssignee">Start Time</label>
              <ngx-mat-timepicker
                (timeSet)="formatStartTime($event)"
                #timePicker1
              ></ngx-mat-timepicker>
            </div>

            <div class="form-floating">
              <input
                nbInput
                class="form-control"
                type="text"
                placeholder="End Time"
                fullWidth
                id="endTime"
                [(ngModel)]="endTime"
                formControlName="end_time"
                [ngxMatTimepicker]="timePicker2"
              /><label for="inputAssignee">End Time</label>
              <ngx-mat-timepicker
                (timeSet)="formatEndTime($event)"
                #timePicker2
              ></ngx-mat-timepicker>
            </div>
          </div>

          <div class="sc-mt-6">
            <nb-radio-group
              style="display: flex; justify-content: center"
              formControlName="repeat_type"
              [(ngModel)]="selectedRepeatType"
              name="ng-model-group"
            >
              <nb-radio
                (valueChange)="onRepeatTypeChange($event)"
                *ngFor="let option of repeatTypes"
                [value]="option.value"
              >
                {{ option.label }}
              </nb-radio>
            </nb-radio-group>
          </div>

          <div
            class="sc-mt-6 sc-text-center"
            *ngIf="
              selectedRepeatType === 'daily' ||
              selectedRepeatType === 'weekly' ||
              selectedRepeatType === 'monthly'
            "
          >
            <div>
              Recur every
              <input
                style="
                  width: 10%;
                  padding-left: 0.5rem;
                  padding-right: 0;
                  margin: auto;
                "
                nbInput
                type="tel"
                size="tiny"
                formControlName="repeat_interval"
                value="1"
              />

              {{
                selectedRepeatType === "daily"
                  ? "day(s)"
                  : selectedRepeatType === "weekly"
                  ? "week(s) on"
                  : "month(s) on"
              }}
            </div>

            <div *ngIf="selectedRepeatType === 'weekly'" class="sc-mt-6">
              <div>
                <nb-select
                  fullWidth
                  status="primary"
                  matRipple
                  multiple
                  size="giant"
                  [(selected)]="selectedWeekDays"
                  formControlName="repeat_details"
                  placeholder="Select Week Days"
                >
                  <nb-option
                    *ngFor="
                      let day of [
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                        'Saturday',
                        'Sunday'
                      ]
                    "
                    [value]="day"
                    matRipple
                    >{{ day }}
                  </nb-option>
                </nb-select>
              </div>
            </div>
          </div>

          <div *ngIf="selectedRepeatType === 'monthly'" class="sc-mt-6">
            <div (click)="changeMonthType('day')">
              <nb-select
                size="giant"
                fullWidth
                placeholder="Days of Month"
                multiple
                [(selected)]="selectedDays"
                formControlName="repeat_details"
              >
                <nb-option *ngFor="let aDay of availabledays" [value]="aDay">{{
                  aDay
                }}</nb-option>
              </nb-select>
              <!-- <div class="form-floating">
                <input
                  class="form-control"
                  nbInput
                  type="tel"
                  fullWidth
                  formControlName="repeat_details"
                  placeholder="Day of Month"
                />
                <label>Day of Month</label>
              </div> -->
            </div>
            <div class="sc-text-center strong">OR</div>
            <div (click)="changeMonthType('date')">
              <nb-select
                fullWidth
                formControlName="position"
                name="position"
                placeholder="Select Position"
                size="giant"
              >
                <nb-option
                  *ngFor="let freq of frequencyDetails"
                  [value]="freq.value"
                  matRipple
                >
                  {{ freq.name | titlecase }}
                </nb-option>
              </nb-select>

              <nb-select
                fullWidth
                formControlName="week_day"
                name="week_day"
                size="giant"
                placeholder="Select Week Day"
                (selectedChange)="updateRepeatDetails()"
              >
                <nb-option
                  *ngFor="
                    let day of [
                      'Monday',
                      'Tuesday',
                      'Wednesday',
                      'Thursday',
                      'Friday',
                      'Saturday',
                      'Sunday'
                    ]
                  "
                  [value]="day"
                  matRipple
                >
                  {{ day }}
                </nb-option>
              </nb-select>
            </div>
          </div>
          <div class="form-floating">
            <input
              nbInput
              class="form-control"
              fullWidth
              id="endDay"
              placeholder="End Date"
              [nbDatepicker]="dateTimePicker1"
              formControlName="end_day"
              [(ngModel)]="endDate"
            /><label for="inputAssignee">End</label>
            <nb-datepicker
              (dateChange)="formateEndDate($event)"
              #end_day
              #dateTimePicker1
              [min]="startDate"
            ></nb-datepicker>
          </div>
        </ng-container>
      </form>
    </ng-container>
    <div
      [ngClass]="
        dialogBox ? 'row row-cols-2 sc-mt-6' : 'row row-cols-1 sc-mt-6'
      "
    >
      <div [ngClass]="dialogBox ? 'col' : 'col-12'">
        <button
          *ngIf="repeatAllowed"
          nbButton
          fullWidth
          size="giant"
          (click)="
            showRecurringView ? cancelRecuringView() : makeRecuringView()
          "
        >
          {{ showRecurringView ? "One Time" : "Repeat" }}
        </button>
      </div>
      <div class="col" *ngIf="dialogBox">
        <button
          nbButton
          fullWidth
          size="giant"
          (click)="submitSchedule()"
          [disabled]="!schedulerForm.valid"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</nb-card>
