<div class="my-3 slider-container">
  <input
    class="w-100 slider"
    type="range"
    [min]="0"
    [disabled]="disableSlider"
    [max]="listData?.length - 1"
    [(ngModel)]="currentSliderValue"
    (ngModelChange)="onSliderChange()"
    (mouseup)="checkPagination()"
    (touchend)="checkPagination()"
  />
</div>

<div class="fw-bolder text-center">
  {{ currentMarker?.event_type }}

  <div class="mt-3 small-font fw-bold">
    <div *ngIf="distanceFromPrevMarker" class="text-danger">
      {{ distanceFromPrevMarker }} M away from previous position in
      {{ timeDifference }}
    </div>

    {{ currentMarker?.gps?.lat }} , {{ currentMarker?.gps?.lon }} [{{
     browserGpsInfo?.[currentMarker?.gps?.source]



    }}]
    <div class="mt-2 sc-head6 fw-bold">
      {{ currentMarker?.updated_at | date : "YYYY MMM dd HH:mm:SS" }}
    </div>
  </div>
</div>

<div
  id="appUsers"
  class="map"
  style="position: relative; z-index: 0; max-height: 100%; max-width: 100%"
></div>
