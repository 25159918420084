<div>
  <!-- table for desktop STARTS -->

  <nb-tag-list *ngIf="appliedFilters?.length" class="mt-2">
    <nb-tag
      *ngFor="let filter of appliedFilters"
      [text]="filter?.key + ' : ' + filter?.value"
      (remove)="removeFilter(filter?.key)"
      removable
    ></nb-tag>
  </nb-tag-list>
  <div [ngStyle]="tableStyle" class="sc-mt-6">
    <table id="table-desktop" class="table-desktop">
      <thead>
        <tr>
          <th
            [ngStyle]="
              showTableFrom === 'jobsPage' ? { 'font-size': '0.8rem' } : {}
            "
            *ngFor="let indColumn of desktopTableDetails"
            style="padding: 1rem 0rem 1rem 0.5rem; vertical-align: top"
          >
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span>{{ indColumn.header }}</span>
              <div style="display: flex; flex-direction: row">
                <nb-icon
                  *ngIf="!indColumn?.openSearchBox && indColumn?.sortKey"
                  pack="eva"
                  [icon]="
                    indColumn?.sortOrder == 'asc'
                      ? 'arrow-upward-outline'
                      : 'arrow-downward-outline'
                  "
                  class="sc-floating-right"
                  (click)="sortField(indColumn)"
                  [ngClass]="indColumn?.sortOrder ? 'ico-color' : ''"
                ></nb-icon>
                <nb-icon
                  *ngIf="!indColumn?.openSearchBox && indColumn?.searchKey"
                  pack="eva"
                  icon="search-outline"
                  (click)="indColumn.openSearchBox = true"
                  class="sc-floating-right ico-color"
                ></nb-icon>
                <nb-icon
                  *ngIf="
                    indColumn?.openSearchBox ||
                    indColumn?.openSearchRangeBox ||
                    indColumn?.opendropDownBox
                  "
                  nbSuffix
                  [icon]="'close-outline'"
                  class="sc-floating-right ico-color"
                  pack="eva"
                  (click)="
                    indColumn.openSearchBox = false;
                    indColumn.searched = false;
                    indColumn.openSearchRangeBox = false;
                    indColumn.opendropDownBox = false
                  "
                ></nb-icon>
                <nb-icon
                  *ngIf="!indColumn?.opendropDownBox && indColumn?.dropdownKey"
                  nbSuffix
                  [icon]="'arrow-ios-downward-outline'"
                  class="sc-floating-right ico-color"
                  pack="eva"
                  (click)="indColumn.opendropDownBox = true"
                ></nb-icon>
                <nb-icon
                  *ngIf="
                    !indColumn?.openSearchRangeBox &&
                    indColumn?.dateRangeKey &&
                    indColumn?.dateRangeTimePicker
                  "
                  nbSuffix
                  [icon]="'calendar-outline'"
                  class="sc-floating-right ico-color"
                  pack="eva"
                  (click)="openRangePicker(indColumn)"
                ></nb-icon>
                <nb-icon
                  *ngIf="
                    !indColumn?.openSearchRangeBox &&
                    indColumn?.dateRangeKey &&
                    !indColumn?.dateRangeTimePicker
                  "
                  nbSuffix
                  [icon]="'calendar-outline'"
                  class="sc-floating-right ico-color"
                  pack="eva"
                  (click)="indColumn.openSearchRangeBox = true"
                ></nb-icon>
              </div>
            </div>
            <div>
              <input
                *ngIf="indColumn?.openSearchRangeBox"
                nbInput
                fullWidth
                placeholder="Pick Date Range"
                [nbDatepicker]="formpicker"
                [(ngModel)]="dateRange"
              />

              <nb-rangepicker
                #formpicker
                (rangeChange)="handleDateRange($event, indColumn)"
              ></nb-rangepicker>

              <nb-select
                *ngIf="indColumn?.opendropDownBox"
                fullWidth
                placeholder="Status"
                (selectedChange)="selectStatus($event, indColumn)"
              >
                <nb-option
                  *ngFor="let status of indColumn?.dropDownList()"
                  [value]="status"
                  >{{ status?.name }}</nb-option
                >
              </nb-select>

              <nb-form-field *ngIf="indColumn?.openSearchBox">
                <input
                  type="text"
                  fieldSize="small"
                  nbInput
                  [(ngModel)]="indColumn.searchValue"
                  fullWidth
                  placeholder="Search"
                  (keyup.enter)="searchField(indColumn)"
                />

                <nb-icon
                  nbSuffix
                  *ngIf="indColumn?.searchValue"
                  [icon]="'search-outline'"
                  pack="eva"
                  (click)="searchField(indColumn)"
                ></nb-icon>
              </nb-form-field>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let indData of desktopTableDataTemp; let i = index"
          [ngStyle]="getTableRowStyle(indData, i)"
          (click)="onTableRowClick(indData, i)"
        >
          <td
            *ngFor="let indColumn of desktopTableDetails; let j = index"
            class="table-desktop-cell"
            [ngStyle]="
              indColumn?.conditionalColumnStyle
                ? indColumn?.conditionalColumnStyle(indData)
                : indColumn?.tableColumnStyle
            "
            [ngStyle]="
              showTableFrom === 'jobsPage' ? { 'font-size': '0.85rem' } : {}
            "
            [nbTooltip]="
              indColumn?.toolTipData ? indColumn?.toolTipData(indData) : ''
            "
            [nbTooltipDisabled]="indColumn?.toolTipData ? false : true"
          >
            <!-- rows where objects are datetime objects STARTS-->

            <!--style whole row either based on condition or common to all -->
            <div *ngIf="indColumn?.datetimeObj == true">
              <!--a row is typically divided into 3 prefix, main content and suffix, style each of them based on conditions or common -->
              <span
                [ngStyle]="
                  indColumn?.conditionalPrefixStyle
                    ? indColumn?.conditionalPrefixStyle(indData)
                    : indColumn?.prefixStyle
                "
              >
                <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
                {{
                  indColumn?.prefixNestedValue
                    ? indColumn?.prefixNestedValue(indData)
                    : indColumn?.prefix
                    ? indColumn?.prefix
                    : ""
                }}
              </span>
              <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
              <!-- main content which will be passed from the backend STARTS-->
              <span
                [ngStyle]="
                  indColumn?.conditionalColumnDataStyle
                    ? indColumn?.conditionalColumnDataStyle(indData)
                    : indColumn?.columnDataStyle
                "
              >
                {{
                  indColumn.nestedValue
                    ? (indColumn.nestedValue(indData)
                      | customdate
                        : (indColumn.dateTimeFormat
                            ? indColumn.dateTimeFormat
                            : "d MMM y HH:mm"))
                    : (indData[indColumn.name]
                      | customdate
                        : (indColumn.dateTimeFormat
                            ? indColumn.dateTimeFormat
                            : "d MMM y HH:mm"))
                }}
              </span>
              <!-- main content which will be passed from the backend ENDS-->
              <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
              <span
                [ngStyle]="
                  indColumn?.conditionalSuffixStyle
                    ? indColumn?.conditionalSuffixStyle(indData)
                    : indColumn?.suffixStyle
                "
              >
                {{
                  indColumn?.suffixNestedValue
                    ? indColumn?.suffixNestedValue(indData)
                    : indColumn?.suffix
                    ? indColumn?.suffix
                    : ""
                }}
              </span>
              <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
            </div>
            <!-- rows where objects are datetime objects ENDS-->

            <!-- rows where objects are normal objects STARTS-->

            <!--style whole row either based on condition or common to all -->
            <div *ngIf="indColumn?.datetimeObj !== true">
              <!--a row is typically divided into 3 prefix, main content and suffix, style each of them based on conditions or common -->
              <span
                [ngStyle]="
                  indColumn?.conditionalPrefixStyle
                    ? indColumn?.conditionalPrefixStyle(indData)
                    : indColumn?.prefixStyle
                "
              >
                <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
                {{
                  indColumn?.prefixNestedValue
                    ? indColumn?.prefixNestedValue(indData)
                    : indColumn?.prefix
                    ? indColumn?.prefix
                    : ""
                }}
              </span>
              <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
              <!-- main content which will be passed from the backend STARTS-->
              <span
                [ngStyle]="
                  indColumn?.conditionalColumnDataStyle
                    ? indColumn?.conditionalColumnDataStyle(indData)
                    : indColumn?.columnDataStyle
                "
              >
                {{
                  indColumn.nestedValue
                    ? indColumn.nestedValue(indData)
                    : indData[indColumn.name] == "null" ||
                      indData[indColumn.name] == "undefined"
                    ? "N/A"
                    : indData[indColumn.name]
                }}
              </span>
              <!-- main content which will be passed from the backend ENDS-->
              <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
              <span
                [ngStyle]="
                  indColumn?.conditionalSuffixStyle
                    ? indColumn?.conditionalSuffixStyle(indData)
                    : indColumn?.suffixStyle
                "
              >
                {{
                  indColumn?.suffixNestedValue
                    ? indColumn?.suffixNestedValue(indData)
                    : indColumn?.suffix
                    ? indColumn?.suffix
                    : ""
                }}
              </span>
              <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
            </div>
            <!-- rows where objects are normal objects ENDS-->
            <div
              *ngIf="tableActionItems && indColumn?.showAction === true"
              class="table-icon-class"
            >
              <span *ngFor="let actionIcon of tableActionItems">
                <nb-icon
                  *ngIf="
                    actionIcon.condition ? actionIcon.condition(indData) : true
                  "
                  class="ico-color"
                  nbSpinnerStatus="primary"
                  (click)="onActionClick(indData, actionIcon.type)"
                  [icon]="actionIcon.icon"
                  [status]="actionIcon.status"
                  [title]="actionIcon.title"
                  pack="eva"
                ></nb-icon>
              </span>
            </div>
          </td>

          <td
            *ngIf="tableRowManager"
            class="table-desktop-cell"
            [ngStyle]="
              showTableFrom === 'jobsPage' ? { 'font-size': '0.85rem' } : {}
            "
          >
            <div *ngFor="let action of tableRowManager">
              <button
                *ngIf="action.condition ? action.condition(indData) : true"
                nbButton
                fullWidth
                (click)="onActionClick(indData, action.name)"
                size="giant"
              >
                {{ action.name }}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- table for desktop ENDS -->
</div>
