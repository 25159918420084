import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-license-list',
  templateUrl: './license-list.component.html',
  styleUrls: ['./license-list.component.scss'],
})
export class LicenseListComponent implements OnInit {
  @Input() listLicenseData: any;
  @Input() listCardDetails: any;
  cardGroupDetails: any;
  cardIndividualDetails: any;

  constructor() {}

  ngOnInit(): void {
    this.cardIndividualDetails = this.listCardDetails?.individualDetails;
  }
}
