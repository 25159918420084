<ng-container>
  <router-outlet> </router-outlet>
  <ngx-spinner
    bdColor="rgba(0,0,0,0.8)"
    size="large"
    color="#fff"
    type="ball-clip-rotate"
    [fullScreen]="true"
    >{{ loadingText }}</ngx-spinner
  ></ng-container
>
<!-- Warning Message of switching from .live to .com.au  -->
<!-- <ng-container *ngIf="isDotLive">
  <div
    *ngIf="isPwa"
    role="alert"
    class="alert alert-warning m-0 fade show text-center"
  >
    <div>
      Please sign in at
      <a
        class="terms-link pointer"
        onclick="openInSystemBrowser($event)"
        rel="noopener noreferrer"
        >https://app.guardtracker.com.au/</a
      >
    </div>
    <div>
      This version of the app has been discontinued, please uninstall and/or
      remove this app from your device
    </div>
  </div>

  <div
    *ngIf="!isPwa"
    role="alert"
    class="alert alert-warning m-0 fade show text-center"
  >
    Please switch to
    <a
      class="terms-link pointer"
      href="https://app.guardtracker.com.au/"
      target="_blank"
      >https://app.guardtracker.com.au/</a
    >
    as we will be retiring app.guardtracker.live soon.
  </div>
</ng-container> -->
