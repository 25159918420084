<div class="mt-2">
  <nb-tag-list *ngIf="appliedFilters?.length">
    <nb-tag
      *ngFor="let filter of appliedFilters"
      [text]="filter?.key + ' : ' + filter?.value"
      (remove)="removeFilter(filter?.key)"
      removable
    ></nb-tag>
  </nb-tag-list>
  <div *ngIf="filterSortData?.length">
    <div
      style="padding: 1rem 0rem 1rem 0.5rem; vertical-align: top"
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <span>{{ selectedCardDetail.header }}</span>
      <div style="display: flex; flex-direction: row mt-1">
        <nb-icon
          *ngIf="
            !selectedCardDetail?.openSearchBox && selectedCardDetail?.sortKey
          "
          pack="eva"
          [icon]="
            selectedCardDetail?.sortOrder == 'asc'
              ? 'arrow-upward-outline'
              : 'arrow-downward-outline'
          "
          class="sc-floating-right"
          (click)="sortField(selectedCardDetail)"
          [ngClass]="selectedCardDetail?.sortOrder ? 'ico-color' : ''"
        ></nb-icon>

        <nb-icon
          *ngIf="
            selectedCardDetail?.openSearchBox ||
            selectedCardDetail?.openSearchRangeBox ||
            selectedCardDetail?.opendropDownBox
          "
          nbSuffix
          [icon]="'close-outline'"
          class="sc-floating-right ico-color"
          pack="eva"
          (click)="
            selectedCardDetail.openSearchBox = false;
            selectedCardDetail.searched = false;
            selectedCardDetail.openSearchRangeBox = false;
            selectedCardDetail.opendropDownBox = false
          "
        ></nb-icon>
        <nb-icon
          *ngIf="
            !selectedCardDetail?.opendropDownBox &&
            selectedCardDetail?.dropdownKey
          "
          nbSuffix
          [icon]="'arrow-ios-downward-outline'"
          class="sc-floating-right ico-color"
          pack="eva"
          (click)="selectedCardDetail.opendropDownBox = true"
        ></nb-icon>
        <nb-icon
          *ngIf="
            !selectedCardDetail?.openSearchRangeBox &&
            selectedCardDetail?.dateRangeKey &&
            selectedCardDetail?.dateRangeTimePicker
          "
          nbSuffix
          [icon]="'calendar-outline'"
          class="sc-floating-right ico-color"
          pack="eva"
          (click)="openRangePicker(selectedCardDetail)"
        ></nb-icon>
        <nb-icon
          *ngIf="
            !selectedCardDetail?.openSearchRangeBox &&
            selectedCardDetail?.dateRangeKey &&
            !selectedCardDetail?.dateRangeTimePicker
          "
          nbSuffix
          [icon]="'calendar-outline'"
          class="sc-floating-right ico-color"
          pack="eva"
          (click)="selectedCardDetail.openSearchRangeBox = true"
        ></nb-icon>
      </div>
    </div>
    <div>
      <input
        *ngIf="selectedCardDetail?.openSearchRangeBox"
        nbInput
        fullWidth
        placeholder="Pick Date Range"
        [nbDatepicker]="formpicker"
        [(ngModel)]="dateRange"
      />

      <nb-rangepicker
        #formpicker
        (rangeChange)="handleDateRange($event, selectedCardDetail)"
      ></nb-rangepicker>

      <nb-select
        *ngIf="selectedCardDetail?.opendropDownBox"
        fullWidth
        placeholder="Status"
        (selectedChange)="selectStatus($event, selectedCardDetail)"
      >
        <nb-option
          *ngFor="let status of selectedCardDetail?.dropDownList()"
          [value]="status"
          >{{ status?.name }}</nb-option
        >
      </nb-select>

      <nb-form-field *ngIf="selectedCardDetail?.searchKey">
        <input
          type="text"
          fieldSize="medium"
          nbInput
          [(ngModel)]="selectedCardDetail.searchValue"
          fullWidth
          placeholder="Search {{ selectedCardDetail.header }}"
          (keyup.enter)="searchField(selectedCardDetail)"
        />

        <nb-icon
          nbSuffix
          *ngIf="selectedCardDetail?.searchValue"
          [icon]="'search-outline'"
          pack="eva"
          (click)="searchField(selectedCardDetail)"
        ></nb-icon>
      </nb-form-field>
    </div>
    <ng-container *ngIf="filterSortData?.length > 1">
      <div class="mt-2 mb-2 d-flex justify-content-around">
        <button
          (click)="showNextCardDetail()"
          nbButton
          size="small"
          fullWidth
          class="w-50"
        >
          <nb-icon icon="arrow-ios-downward-outline" pack="eva"></nb-icon>
        </button>
      </div>
    </ng-container>
  </div>
  <div class="gy-3 row row-cols-md-2 row-cols-xxl-3 row-cols-xxxl-6">
    <!-- Card Sections split into 3 sections-->
    <div *ngFor="let data of cardData; let i = index" class="gt-card-style">
      <div *ngFor="let indCard of mobileCardDetails" (click)="onRowClick(data)">
        <!--style whole row either based on condition or common to all -->
        <div
          [ngStyle]="
            indCard?.conditionalRowStyle
              ? indCard?.conditionalRowStyle(data)
              : indCard?.cardRowStyle
          "
        >
          <div
            *ngIf="
              data?.showHeader &&
              (indCard.nestedValue
                ? indCard.nestedValue(data)
                : data[indCard.name]) != null
            "
            [ngStyle]="
              indCard?.conditionalHeaderRowStyle
                ? indCard?.conditionalHeaderRowStyle(data)
                : indCard?.cardHeaderRowStyle
            "
          >
            <!-- header content which will be passed from the backend STARTS-->
            <span
              class="card-key"
              [ngStyle]="
                indCard?.conditionalHeaderRowDataStyle
                  ? indCard?.conditionalHeaderRowDataStyle(data)
                  : indCard?.rowHeaderDataStyle
              "
            >
              {{
                indCard.nestedHeaderValue
                  ? indCard.nestedHeaderValue(data)
                  : indCard.header
              }}
            </span>
            <!-- header content which will be passed from the backend ENDS-->
          </div>
          <!--a row is typically divided into 3 prefix, main content and suffix, style each of them based on conditions or common -->
          <span
            [ngStyle]="
              indCard?.conditionalPrefixStyle
                ? indCard?.conditionalPrefixStyle(data)
                : indCard?.prefixStyle
            "
          >
            <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
            {{
              indCard?.prefixNestedValue
                ? indCard?.prefixNestedValue(data)
                : indCard?.prefix
                ? indCard?.prefix
                : ""
            }}
          </span>
          <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
          <!-- main content which will be passed from the backend STARTS-->

          <span
            class="card-value"
            [ngStyle]="
              indCard?.conditionalRowDataStyle
                ? indCard?.conditionalRowDataStyle(data)
                : indCard?.rowDataStyle
            "
          >
            <ng-container *ngIf="indCard?.datetimeObj == true">
              {{
                indCard.nestedValue
                  ? (indCard.nestedValue(data)
                    | date
                      : (indCard.dateTimeFormat
                          ? indCard.dateTimeFormat
                          : "d MMM y HH:mm"))
                  : (data[indCard.name]
                    | date
                      : (indCard.dateTimeFormat
                          ? indCard.dateTimeFormat
                          : "d MMM y HH:mm"))
              }}</ng-container
            >
            <ng-container *ngIf="indCard?.datetimeObj != true">
              {{
                indCard.nestedValue
                  ? indCard.nestedValue(data)
                  : data[indCard.name]
              }}</ng-container
            >
          </span>
          <!-- main content which will be passed from the backend ENDS-->
          <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
          <span
            [ngStyle]="
              indCard?.conditionalSuffixStyle
                ? indCard?.conditionalSuffixStyle(data)
                : indCard?.suffixStyle
            "
          >
            {{
              indCard?.suffixNestedValue
                ? indCard?.suffixNestedValue(data)
                : indCard?.suffix
                ? indCard?.suffix
                : ""
            }}
          </span>
          <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
        </div>
        <!-- rows where objects are normal objects ENDS-->
      </div>
      <div class="sc-floating-right">
        <div>
          <nb-toggle
            toggle-width="0.25"
            toggle-height="0.25"
            size="tiny"
            status="primary"
            (checkedChange)="data.showHeader = !data?.showHeader"
          ></nb-toggle>
        </div>
        <div class="small-font">Header</div>
      </div>
    </div>
    <div *ngIf="otherParams?.paginationData" class="sc-mt-6">
      <app-pagination-control
        [pageSize]="cardRows"
        [pageNum]="cardPageNum"
        [previous]="cardPreviousRows"
        [totalRows]="cardTotalRows"
        [totalPages]="cardTotalPages"
        (onClickPagination)="onChangePagination($event)"
        (onChangePagination)="onChangePagination($event)"
      >
      </app-pagination-control>
    </div>
  </div>
</div>
