<nb-card class="dialog-box">
  <nb-card-body>
    <ng-container *ngIf="currentStatus === 'profileDetails'">
      <form
        [formGroup]="profileForm"
        (ngSubmit)="updateProfileData()"
        appFormEnterAction
      >
        <nb-stepper #stepper>
          <nb-step [stepControl]="startForm" label="profile">
            <div class="sc-head5">Enter your details</div>

            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                nbInput
                fullWidth
                id="first_name"
                formControlName="first_name"
                autocomplete="off"
                placeholder="First Name"
                autofocus
              />
              <label>First Name</label>
            </div>
            <div class="form-floating">
              <input
                fullWidth
                class="form-control"
                type="text"
                nbInput
                id="last_name"
                formControlName="last_name"
                autocomplete="off"
                placeholder="Last Name"
                autofocus
              />
              <label>Last Name</label>
            </div>
            <div class="form-floating">
              <input
                fullWidth
                class="form-control"
                nbInput
                id="mobile_number"
                formControlName="mobile_number"
                autocomplete="off"
                placeholder="Mobile (optional)"
                autofocus
                type="tel"
                minlength="10"
                maxlength="10"
                pattern="\d*"
              />
              <label>Mobile (optional)</label>
            </div>

            <div class="row">
              <div class="col col-6 offset-6">
                <button
                  class="sc-floating-right"
                  nbButton
                  size="giant"
                  fullWidth
                  [disabled]="!profileForm.valid"
                  *ngIf="isSuperUser === true"
                >
                  Submit
                </button>
                <button
                  *ngIf="!isSuperUser"
                  size="giant"
                  [disabled]="!profileForm.valid"
                  nbButton
                  fullWidth
                  nbStepperNext
                >
                  Next
                </button>
              </div>
            </div>
          </nb-step>
          <nb-step [stepControl]="endForm" label="license">
            <form [formGroup]="licenseForm" appFormEnterAction>
              <div class="sc-head5">Optional: Add a security licence</div>
              <div>
                <!-- <label for="issuer_state">State</label> -->
                <nb-select
                  fullWidth
                  placeholder="State"
                  formControlName="issuer_state"
                  [(selected)]="selectedItem"
                >
                  <nb-option
                    *ngFor="let state of stateData"
                    [value]="state.code"
                    (click)="selectState(state)"
                    >{{ state.code }}</nb-option
                  >
                </nb-select>
              </div>
              <nb-form-field>
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="text"
                    fullWidth
                    nbInput
                    id="license_class"
                    formControlName="license_class"
                    autocomplete="off"
                    placeholder="Licence Class"
                    [(ngModel)]="newlicense"
                    (input)="onLicenceClassSearch($event)"
                    [nbAutocomplete]="licenseClassAutoNGModel"
                  />
                  <label for="license_class">Licence Class / Category</label>
                </div>
                <nb-icon
                  *ngIf="showLicenseAddClass"
                  (click)="addNewClass(newlicense)"
                  [icon]="'plus-outline'"
                  nbSuffix
                  pack="eva"
                ></nb-icon>
              </nb-form-field>

              <nb-autocomplete #licenseClassAutoNGModel>
                <nb-option
                  *ngFor="let item of licenseClassData"
                  [value]="item.license_class_name"
                >
                  <div>
                    {{ item.license_class_name }}
                  </div>
                </nb-option>
              </nb-autocomplete>

              <div class="form-floating">
                <input
                  class="form-control"
                  type="text"
                  nbInput
                  fullWidth
                  id="license_number"
                  formControlName="license_number"
                  autocomplete="off"
                  placeholder="Security Licence ID Number"
                />
                <label for="license_number">Security Licence ID Number</label>
              </div>
              <div class="form-floating">
                <input
                  class="form-control"
                  nbInput
                  fullWidth
                  placeholder="Licence Expiry"
                  [nbDatepicker]="dateTimePicker1"
                  formControlName="valid_till"
                />
                <label for="license_number">Licence Expiry</label>
                <nb-datepicker #dateTimePicker1 [min]="today"></nb-datepicker>
              </div>
            </form>

            <div class="row">
              <div class="col-6">
                <button
                  fullWidth
                  size="giant"
                  type="button"
                  nbButton
                  nbStepperPrevious
                >
                  Back
                </button>
              </div>
              <div class="col-6" *ngIf="!licenseForm.valid">
                <button
                  fullWidth
                  [disabled]="licenseForm.valid"
                  nbButton
                  size="giant"
                  class="nbbutton-long-text"
                >
                  Skip & Submit
                </button>
              </div>
              <div class="col-6" *ngIf="licenseForm.valid">
                <button fullWidth nbButton size="giant">Submit</button>
              </div>
            </div>
          </nb-step>
        </nb-stepper>
      </form>
    </ng-container>
    <ng-container *ngIf="currentStatus === 'passwordSet'">
      <form
        [formGroup]="passwordForm"
        (ngSubmit)="changePassword()"
        appFormEnterAction
      >
        <nb-form-field>
          <div class="form-floating">
            <input
              class="form-control"
              nbInput
              fullWidth
              [type]="getInputType()"
              name="password"
              id="password"
              formControlName="password"
              placeholder="New Password"
              [status]="'success'"
              [minlength]="8"
              [status]="
                passwordForm.controls['password'].touched
                  ? passwordForm.controls['password'].invalid
                    ? 'danger'
                    : 'primary'
                  : ''
              "
            />
            <label for="password">New Password</label>
          </div>

          <nb-icon
            nbSuffix
            (click)="toggleShowPassword()"
            [icon]="showPassword ? 'eye-outline' : 'eye-off-outline'"
            pack="eva"
          >
          </nb-icon>
        </nb-form-field>

        <small
          class="error-message"
          *ngIf="passwordForm.controls['password'].hasError('minlength')"
          >Must have at least 8 characters</small
        >

        <div class="form-floating">
          <input
            class="form-control"
            nbInput
            fullWidth
            [type]="getInputType()"
            name="confirm_password"
            id="confirm_password"
            formControlName="confirm_password"
            placeholder="Confirm Password"
            [status]="'success'"
            [minlength]="8"
            [status]="
              passwordForm.controls['password'].touched
                ? passwordForm.controls['password'].invalid
                  ? 'danger'
                  : 'primary'
                : ''
            "
          />
          <label for="confirm_password">Confirm Password</label>
        </div>
        <small
          class="error-message"
          *ngIf="
            passwordForm.controls['confirm_password'].value &&
            passwordForm.controls['confirm_password'].invalid
          "
          >Passwords do not match</small
        >

        <div [ngClass]="addCancelButton ? 'row row-cols-2' : 'row row-cols-1'">
          <div class="col" *ngIf="addCancelButton">
            <button
              nbButton
              size="giant"
              fullWidth
              ghost
              (click)="onCloseDialogue()"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              nbButton
              id="form-enter-submit-btn"
              size="giant"
              [disabled]="!passwordForm.valid"
              fullWidth
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </nb-card-body>
</nb-card>
