import {
  Component,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { WebcamImageComponent } from 'src/app/shared/comp/webcam-image/webcam-image.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { PagesService } from '../../pages.service';
import { IncidentsService } from '../incidents.service';

import { Location } from '@angular/common';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AddEditIncidentTypeComponent } from '../incident-type/add-edit-incident-type/add-edit-incident-type.component';
@Component({
  selector: 'app-view-incident',
  templateUrl: './view-incident.component.html',
  styleUrls: ['./view-incident.component.scss'],
})
export class ViewIncidentComponent implements OnInit {
  @ViewChild('resolTimeTemplateRef') resolTimeTemplateRef: any =
    TemplateRef<any>;
  contactForm: UntypedFormGroup = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    title: new UntypedFormControl(),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    mobile_number: new UntypedFormControl('', [
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9]*$'),
    ]),
  });

  incidentHistoryTableDetails: any = [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      sortKey: 'updated_at',
      dateRangeKey: 'updated_at',
    },
    {
      header: 'Update By',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },

    {
      header: 'Update',
      name: 'action',
      sortKey: 'action',
      searchKey: 'action',
    },
  ];
  histChangeTableView: any = [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      sortKey: 'updated_at',
      dateRangeKey: 'updated_at',
    },
    {
      header: 'Update By',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ];
  tableStyle = {
    'overflow': 'auto',
    'max-height': '400px',
  };
  incidentId: any;

  detailData: any;

  isAdmin: boolean = false;
  isCreatedUser: boolean = false;
  isDispatcher: boolean = false;

  isIncidentSubmitted: boolean = false;
  dialogRef: any;
  newValue: any;
  previousTextValue: any;
  subscriberUserId: any;
  userData: any;

  incidentCommentFiles: any = [];
  incidentHistory: any = [];
  selectedUsers: any = [];
  userList: any = [];
  resolutionDay: any;
  resolutionTime: any;
  resolutionDateTime: any;

  selectedHistoryEvent: any;

  todayDate: any = new Date();
  minResDate: any;
  resDateTimeErrorMessage: any;

  lastInputLength: number = 0;
  callApi: boolean = true;
  previousdateTimeValue: any;
  dateTimeValue: any;
  newDateValue: any;
  newTimeValue: any;

  incidentTypeList: any;
  previousincidentTypeValue: any;
  incidentTypeValue: any;

  emailRecipients: string = '';
  slicedUsersCount: any = 1;

  displayAllUsers: boolean = false;
  bottomSectionFixedHeight: string = '200 px';
  promptSendReport: boolean = false;
  constructor(
    private spinnerService: NgxSpinnerService,
    private pageService: PagesService,
    private appService: AppService,
    private dialogService: NbDialogService,
    private dataCheckService: DataCheckService,
    private incidentService: IncidentsService,
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location
  ) {
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });

    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatcher = this.dataCheckService.isDispatchUser();

    this.route.params.subscribe({
      next: (params) => {
        if (params?.['inKey']) {
          this.incidentId = params?.['inKey'];

          this.getIncidentDetail();
        }
      },
    });
  }

  ngOnInit(): void {
    this.userData = this.appService.getUserData();

    this.subscriberUserId = this.userData?.subscriber?.subscriber_user_id;
    this.resolutionDateTime = new Date();
    this.resolutionDay = new Date();
    // this.incidentDict.iTime.value = this.convertTime12to24(new Date());
    this.checkEndTime();
  }

  checkEndTime() {
    var abc: any;
    abc = new Date();
    abc.setHours(parseInt(abc.getHours()));
    abc.setMinutes(parseInt(abc.getMinutes()));
    abc.setSeconds(5);
    abc = abc.toLocaleTimeString('en-Gb', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      hourCycle: 'h23',
    });
    abc = abc.replace(':05', '');

    this.resolutionTime = abc;
  }

  getIncidentDetail() {
    this.spinnerService.show();
    this.incidentService
      .retrieveIncident(this.incidentId)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.formatData(response);
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }
  formatData(response: any) {
    this.detailData = response?.data;
    this.incidentHistory = response?.history;
    this.emailRecipients = response?.recipient_emails?.join('\n ');
    this.incidentCommentFiles = [
      ...response?.history?.filter((item: any) =>
        //1 = added comment
        //2 = added photo
        //11 = uploaded photo
        [2, 11].includes(item?.event_action)
      ),
    ];
    this.isCreatedUser = Boolean(
      this.subscriberUserId === this.detailData?.subscriber_user?.id
    );

    this.isIncidentSubmitted = this.detailData?.submitted_at || false;
    this.spinnerService.hide();
    this.minResDate = new Date(this.detailData?.incident_time);
    this.minResDate.setDate(this.minResDate.getDate() - 1);
    this.ngZone.run(() => {
      setTimeout(() => {
        this.calculateFixedBottomHeight();
        this.spinnerService.hide();
      }, 300);
    });
    this.clearValues();
  }
  clearValues() {
    this.newValue = null;
    this.previousTextValue = null;
    this.selectedUsers = [];
    this.callApi = true;
    this.lastInputLength = 0;
    this.dialogRef?.close();
  }

  openCommentTemplate(template: TemplateRef<any>, commentData?: any) {
    let dialogContext: any = {};
    if (commentData) {
      this.newValue = commentData?.detail;
      this.previousTextValue = this.newValue;
      dialogContext = commentData;
    }
    this.dialogRef = this.dialogService.open(template, {
      context: dialogContext,
    });
    this.dialogRef.onClose.subscribe((value: any) => {
      if (value) {
        this.formatData(value);
      }
    });
  }
  openExtraInfoTemplate(template: TemplateRef<any>, extrainfoType?: string) {
    this.newValue =
      extrainfoType == 'ca'
        ? this.detailData?.corrective_actions
        : this.detailData?.information;
    this.previousTextValue = this.newValue;

    this.dialogRef = this.dialogService.open(template, {
      context: { extrainfoType: extrainfoType },
    });
  }
  openHistoryChangeTemplate(template: TemplateRef<any>, data?: any) {
    this.dialogRef = this.dialogService.open(template, {
      context: data,
    });
  }
  openupdateIncidentTimeTemplate(template: TemplateRef<any>) {
    this.newDateValue = new Date(this.detailData?.incident_time);
    this.setIncidentTime();
    this.dateTimeValue = this.detailData?.incident_time;
    this.previousdateTimeValue = this.dateTimeValue;

    this.dialogRef = this.dialogService.open(template, {
      context: {},
    });
  }
  openupdateIncidentTypeTemplate(template: TemplateRef<any>) {
    this.getIncidentTypes();
    this.incidentTypeValue = this.detailData?.incident_type;
    this.previousincidentTypeValue = this.detailData?.incident_type;
    this.dialogRef = this.dialogService.open(template, {
      context: {},
    });
  }
  mapsSelector(lat: any, lon: any) {
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.platform.indexOf('iPhone') != -1 ||
      navigator.platform.indexOf('iPad') != -1 ||
      navigator.platform.indexOf('iPod') != -1
    )
      window.open(`maps://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
    /* else use Google */ else
      window.open(`https://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
  }
  addComment(data?: any) {
    if (this.newValue && this.newValue.trim()) {
      this.spinnerService.show();
      let body: any = {
        incident_id: this.detailData?.id,
        comment: this.newValue,
      };
      if (Object.keys(data).length) {
        body.id = data?.id;
        body.edit_comment = 1;
      }
      if (body) {
        this.updateIncident(body);
      }
    }
  }
  updateIncident(body: any, params?: any) {
    this.spinnerService.show();
    this.incidentService
      .updateIncident(body, params)
      .subscribe((response: any) => {
        if (response?.status == 'success') {
          if (response?.data) {
            this.detailData = response['data'];
            this.dialogRef?.close();
            this.formatData(response);
            if (this.promptSendReport) {
              this.promptSendReport = false;
              this.openPromptSendReport();
            }
            this.pageService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
          }
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }
  openPromptSendReport() {
    let dialogMsg = 'Send Report Now?';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: 'Confirmation Needed',
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((dialogResponse) => {
      if (dialogResponse === 'Yes') {
        this.getAllUsersContacts();
        this.dialogService.open(this.resolTimeTemplateRef);
      }
    });
  }
  openCamPopup(uploadType: string = 'live') {
    this.dialogRef = this.dialogService.open(WebcamImageComponent, {
      context: { showFrom: 'updateIncident', uploadType: uploadType },
    });
    this.dialogRef.onClose.subscribe((value: any) => {
      if (value && value !== 'error') {
        this.onFileSubmit(value);
      } else if (value == 'error') {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: 'ERROR: Unable to access your camera',
        });
      }
    });
  }

  //Method to upload the photo
  onFileSubmit(imageData: any) {
    this.appService.setLoadingText('Uploading Details');
    if (!imageData?.actualFileUpload) {
      this.pageService.setMessage({
        successMessage: '',
        errorMessage: 'ERROR: Please add a photo',
      });
    } else {
      this.spinnerService.show();
      let fileData: FormData = new FormData();

      fileData.append('file', imageData?.actualFileUpload);
      if (imageData?.newComment) {
        fileData.append('comment', imageData?.newComment);
      }
      let url = `incident/update`;
      fileData.append('incident_id', this.detailData?.id);
      fileData.append('image_source', imageData?.uploadType);

      this.appService.formDataApi(url, fileData).then((response: any) => {
        if (response?.data) {
          this.formatData(response);
        }
        this.appService.setLoadingText(' ');
        this.spinnerService.hide();
      });
    }
  }
  onOpenImage(event: any, requestRef: TemplateRef<any>, data: any) {
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;

    this.dialogRef = this.dialogService.open(requestRef, {
      closeOnBackdropClick: true,
      context: { src: srcAttr.nodeValue, data: data },
    });
  }
  redirectToClientPage() {
    this.router.navigate([
      '/view-client',
      { cKey: String(this.detailData?.company?.id) },
    ]);
  }

  updateExtraInfo(infoData: any) {
    let body: any = { incident_id: this.detailData?.id };
    body[
      infoData?.extrainfoType == 'ca' ? 'corrective_actions' : 'information'
    ] = this.newValue;
    if (body) {
      this.promptSendReport = Boolean(infoData?.extrainfoType == 'ca');
      this.updateIncident(body);
    }
  }
  updateincidentTime(infoData: any) {
    let body: any = { incident_id: this.detailData?.id };

    body['incident_time'] = this.dateTimeValue;
    if (body) {
      this.updateIncident(body);
    }
  }

  onSearchIncidentType(event: any) {
    this.previousincidentTypeValue = event?.target?.value;
    if (event?.target?.value.length > 2) {
      this.getIncidentTypes({ search_str: event?.target?.value });
    }
  }
  getIncidentTypes(params?: any) {
    this.incidentService.fetchIncidentTypeList(params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.incidentTypeList = res?.data?.filter(
          (_type: any) => _type?.id !== this.detailData?.incident_type_id
        );
      }
    });
  }

  submitIncident() {
    this.spinnerService.show();
    let markedUSers = this.selectedUsers?.filter(
      (user: any) => !user?.isSnubbed
    );

    let body: any = {
      incident_id: this.detailData?.id,
      user_ids: [...new Set([...markedUSers].map((item) => item.pk))],
    };
    if (this.isIncidentSubmitted) {
      body.resend_report = true;
    } else {
      body.resolution_time = this.resolutionDateTime;
      body.submit = true;
    }

    this.updateIncident(body);
  }
  formateStartDate(event: any) {
    this.resolutionDay = event;
    this.resolutionDateTime = event;
  }

  formatStartTime(event: any) {
    let startTime = this.convertTime12to24(event);

    var [hours, minutes] = startTime.split(':');
    let startDateTime = new Date(this.resolutionDateTime);

    startDateTime.setHours(parseInt(hours));
    startDateTime.setMinutes(parseInt(minutes));

    if (startDateTime.getTime() > new Date().getTime()) {
      this.resDateTimeErrorMessage = 'Cannot Select a Future Date time';
      this.shiftFocus('rTimeField');
      this.pageService.setMessage({
        successMessage: '',
        errorMessage: this.resDateTimeErrorMessage,
      });
    } else if (
      new Date(this.detailData?.incident_time).getTime() >
      startDateTime.getTime()
    ) {
      this.resDateTimeErrorMessage =
        'Resolution Time should be greater than the incident time';
      this.pageService.setMessage({
        successMessage: '',
        errorMessage: this.resDateTimeErrorMessage,
      });
      this.shiftFocus('rTimeField');
    } else {
      this.resolutionDateTime = startDateTime;
      this.resolutionTime = event;
      this.resDateTimeErrorMessage = '';
    }
  }
  convertTime12to24(time12h: any) {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours == '12') {
      hours = '00';
    }

    if (modifier.toUpperCase() === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }
  markResolved(template: TemplateRef<any>) {
    this.getAllUsersContacts();
    this.dialogRef = this.dialogService.open(template, {
      context: {},
    });
  }
  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);

      element?.click();

      element?.focus();
    });
  }

  getAllUsersContacts() {
    this.incidentService
      .retrieveIncident(this.detailData?.id, { get_contacts: 1 })
      .subscribe((response: any) => {
        if (response.status === 'success') {
          this.selectedUsers = response.data;
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response.message,
          });
        }
      });
  }

  deletePhoto(incidentPhoto: any) {
    let body: any = {
      incident_id: this.detailData?.id,
      delete_photo: 1,
      incident_update_id: incidentPhoto?.data?.id,
    };
    if (body) {
      this.updateIncident(body);
    }
  }
  setIncidentTime() {
    var abc: any;
    abc = new Date(this.detailData?.incident_time);
    abc.setHours(parseInt(abc.getHours()));
    abc.setMinutes(parseInt(abc.getMinutes()));
    abc.setSeconds(5);
    abc = abc.toLocaleTimeString('en-Gb', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      hourCycle: 'h23',
    });
    abc = abc.replace(':05', '');

    this.newTimeValue = abc;
  }
  formateNewStartDate(event: any) {
    this.dateTimeValue = event;
  }
  formatNewStartTime(event: any) {
    let startTime = this.convertTime12to24(event);
    var [hours, minutes] = startTime.split(':');
    let startDateTime = new Date(this.dateTimeValue);
    startDateTime.setHours(parseInt(hours));
    startDateTime.setMinutes(parseInt(minutes));

    if (startDateTime.getTime() > new Date().getTime()) {
      this.pageService.setMessage({
        successMessage: '',
        errorMessage: 'cannot set future date',
      });
    } else {
      this.newTimeValue = event;
      this.dateTimeValue = startDateTime;
    }
  }
  deleteIncident() {
    let dialogMsg = 'Delete Incident?';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: 'Confirmation Needed',
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((dialogResponse) => {
      if (dialogResponse === 'Yes') {
        this.spinnerService.show();
        this.incidentService
          .deleteIncident(this.detailData?.id)
          .subscribe((response: any) => {
            if (response?.status == 'success') {
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
              this._location.back();
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }

            this.spinnerService.hide();
          });
      }
    });
  }
  slicedData(data: any[]): any[] {
    return this.displayAllUsers ? data : data?.slice(0, this.slicedUsersCount);
  }
  hasUnsnubbedUsers(): boolean {
    return this.selectedUsers.some((user: any) => !user.isSnubbed);
  }
  calculateFixedBottomHeight() {
    // Get the bottom-section-fixed element by ID

    const bottomSectionFixed = document.getElementById('fixedBtn');

    if (bottomSectionFixed) {
      this.bottomSectionFixedHeight =
        bottomSectionFixed.offsetHeight.toString() + 'px';
    }
  }
  onSelectIncidentType(event: any) {

    if (event === ' ') {
   
      this.openAddIncidentType();
    }
    if (event?.id) {
      this.incidentTypeValue = event?.name;

      let body: any = {
        incident_id: this.detailData?.id,
        incident_type: event?.id,
      };

      if (body) {
        this.updateIncident(body);
      }
    }
  }

  openAddIncidentType() {
    const incidentTypeDialogRef = this.dialogService.open(
      AddEditIncidentTypeComponent,
      {
        context: {
          name: this.previousincidentTypeValue,
        },
      }
    );

    incidentTypeDialogRef.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.onSelectIncidentType(value?.data);
      }
    });
  }
}
