import { Component, OnInit } from '@angular/core';
import {  NbDialogService } from '@nebular/theme';
import {  Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddEditUsersComponent } from '../users/add-edit-users/add-edit-users.component';
import { UsersService } from '../users/users.service';
import { AddEditViewUserGroupComponent } from './add-edit-view-user-group/add-edit-view-user-group.component';
@Component({
  selector: 'app-user-group',
  templateUrl: './user-group.component.html',
  styleUrls: ['./user-group.component.scss'],
})
export class UserGroupComponent implements OnInit {
  globalFilter: any;
  showTypeAhead: boolean = true;
  users: any;
  userType: any;

  selectedIndex: any;
  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRegisteredUsers: number = 0;
  totalUserCount: any;

  userGroupPaginationData: any = {};
  totalUserGroupCount: any;
  showUserGroups: boolean = true;
  showUserHistory: boolean = false;

  userGroupCardDetails = {
    individualDetails: [
      {
        name: 'name',
        cardRowStyle: {
          'font-weight': 'bolder',
        },
      },
      {
        name: 'description',
        cardRowStyle: {
          'margin-top': '0.5rem',
        },
      },
    ],
  };
  dialogref: any;

  desktopView: boolean = window.innerWidth >= 770;
  constructor(
    private userService: UsersService,
    private dialog: NbDialogService,
    private router: Router,
    private appService: AppService,
    private spinnerService: NgxSpinnerService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
      }
    });
    if (JSON.parse(localStorage.getItem('appChecks') || '{}')?.userCount) {
      this.totalRegisteredUsers = JSON.parse(
        localStorage.getItem('appChecks') || '{}'
      )?.userCount;
    }
  }
  ngOnInit(): void {
    let userData = this.appService.getUserData();
    this.pageSize = userData?.preferences?.user_page_size || 10;
    this.getUserGroups();
  }

  onFilterApply(event: any) {
    if (event?.target?.value && event?.target?.value?.length >= 3) {
      this.previous = 0;
      this.previous = 0;
      this.getUserGroups(true);
      this.showTypeAhead = true;
    }
    if (
      event === true &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.previous = 0;
      this.getUserGroups();
      this.showTypeAhead = false;
    } else if (
      event.key === 'Enter' &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.previous = 0;

      this.getUserGroups();
      this.showTypeAhead = false;
    }
  }
  onClearFilter() {
    this.previous = 0;
    this.previous = 0;
    this.pageNum = 1;
    this.pageNum = 1;
    this.globalFilter = '';
    this.userGroupPaginationData = {};
    this.showTypeAhead = true;
    this.getUserGroups();
  }

  getUserGroups(val = false) {
    let params: any = {};
    if (this.pageSize) {
      params['rows'] = this.pageSize;
      let userData = this.appService.getUserData();
      if (userData?.preferences) {
        userData.preferences.user_page_size = this.pageSize;
        this.appService.setUserData(userData);
      }
    }
    if (this.userType) {
      params.user_type = this.userType;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }
    if (this.globalFilter) {
      params['globalFilter'] = this.globalFilter;
    }
    if (
      !this.userGroupPaginationData?.[this.pageNum]?.length ||
      this.globalFilter
    ) {
      this.spinnerService.show();
      this.userService.getUserGroups(params).subscribe((response: any) => {
        if (response['status'] == 'success') {
          if (val) {
            this.users = response['data'];
          } else {
            this.userGroupPaginationData[this.pageNum] = [
              // hide Admin group
              ...response['data']?.filter((grp: any) => grp?.group_id !== 4),
            ];
            this.totalUserGroupCount = response['total_size'];
            this.totalPages = Math.ceil(
              this.totalUserGroupCount / this.pageSize
            );
          }
        }
        this.spinnerService.hide();
      });
    }
  }

  rowClick(event: any) {
    if (!this.showUserGroups) {
      this.dialogref = this.dialog.open(AddEditUsersComponent, {
        context: {
          editUser: true,
          detailData: event,
        },
      });
      this.dialogref.onClose.subscribe((value: any) => {
        if (value !== 'close') {
          this.userGroupPaginationData = {};
          this.getUserGroups();
        }
      });
    } else {
      this.router.navigate(['/user-group', { uGKey: String(event?.id) }]);
    }
  }
  openAddUserGroupForm() {
    this.dialogref = this.dialog.open(AddEditViewUserGroupComponent, {
      context: {},
    });
    this.dialogref.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.userGroupPaginationData = {};
        this.getUserGroups();
      }
    });
  }
  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.getUserGroups();
  }
  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.getUserGroups();
  }
  selectUserType(type: any) {
    if (type === 'user_group') {
      this.router.navigate(['/user-groups'], {
        fragment: 'usergroup',
      });
      this.userGroupPaginationData = {};
      this.getUserGroups();
      this.showUserGroups = true;
    } else {
      this.showUserGroups = false;
      this.userType = type;

      this.router.navigate(['/user-groups'], {
        fragment: type,
      });
    }
  }
  adminCheck(user: any) {
    if (user?.user_group?.some((grp: any) => grp?.name === 'Administrators')) {
      return true;
    }
    return false;
  }
}
