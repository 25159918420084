<nb-card *ngIf="detailData?.id">
  <!-- MOBILE ONLY -->
  <nb-card-body class="mobile-only gt-p-small">
    <div class="col mb-3" *ngIf="isAdmin && !detailData?.is_validated">
      <button
        nbButton
        status="primary"
        hero
        (click)="openTemplate(validateCPTemplate)"
        fullWidth
        size="giant"
        class="nbbutton-long-text btnValidateCheckpoint"
      >
        Validate Checkpoint
      </button>
    </div>
    <div class="badge-key">Checkpoint Name</div>
    <div class="d-flex">
      <div class="flex-grow-1 d-flex align-items-center">
        <div
          class="sc-head4 pointer ms-1"
          (click)="
            isAdmin
              ? detailData?.is_validated
                ? openTemplate(editCPTemplate)
                : openTemplate(validateCPTemplate)
              : ''
          "
        >
          {{ detailData?.name }}

          <nb-icon
            class="ms-auto text-basic-color"
            nbSuffix
            icon="edit-2"
            *ngIf="
              isAdmin
                ? detailData?.is_validated
                  ? true
                  : detailData?.address?.state_code
                  ? true
                  : false
                : false
            "
            pack="eva"
          ></nb-icon>
        </div>
      </div>
      <div
        class="sc-floating-right"
        *ngIf="isAdmin"
        (click)="deleteCheckPoint(detailData)"
      >
        <button
          nbButton
          status="danger"
          outline
          title="Delete Checkpoint"
          size="small"
        >
          <nb-icon icon="trash" pack="eva" status="danger"></nb-icon>
        </button>
      </div>
    </div>

    <!-- LEAFLET MAP -->
    <div class="row">
      <app-map-leaflet
        *ngIf="mobileView"
        style="height: 40vh"
        [latLon]="{
          lat: detailData?.latitude,
          lon: detailData?.longitude,
          geoFenceRadius: detailData?.min_distance
        }"
        mapId="mobileMap"
      ></app-map-leaflet>
      <div class="latlon-value" *ngIf="detailData?.latitude">
        lat: {{ detailData?.latitude }}, lon:
        {{ detailData?.longitude }}
      </div>
    </div>

    <!-- CHECKPOINT DETAILS -->
    <div class="row mt-3">
      <div>
        <span class="badge-key">Client</span>
        <span class="badge-value">{{ detailData?.company }}</span>
      </div>

      <div *ngIf="!detailData?.qr_code || detailData?.is_locked">
        <span class="badge-key"
          >Geofence Radius
          <nb-icon
            class="ms-auto"
            nbSuffix
            [icon]="'alert-circle-outline'"
            pack="eva"
            nbTooltip="Checkpoint can only be scanned when within this distance of address"
            nbTrigger="focus"
          ></nb-icon>
        </span>
        <ng-container *ngIf="detailData?.is_validated">
          <form [formGroup]="checkPointForm" class="me-3">
            <span class="badge-value">
              <input
                class="range-slider-input m-0"
                style="width: 100%"
                type="range"
                [min]="minKm"
                placeholder="Geofence Radius (metres)"
                [max]="maxKm"
                [value]="nearestKmValue"
                formControlName="min_distance"
                (mouseup)="updateCP(checkPointForm)"
                (touchend)="updateCP(checkPointForm)"
              />

              <div class="form-floating">
                <input
                  class="form-control"
                  fullWidth
                  id="description"
                  formControlName="min_distance"
                  nbInput
                  type="tel"
                  [min]="minKm"
                  placeholder="Geofence Radius (metres)"
                  [max]="maxKm"
                  [(ngModel)]="nearestKmValue"
                  (blur)="updateGeoDistance()"
                  pattern="\d*"
                /><label for="inputAssignee">Geofence Radius (metres)</label>
              </div>
            </span>
          </form>
        </ng-container>
        <ng-container *ngIf="!detailData?.is_validated">
          <span class="badge-value"
            >{{ detailData?.min_distance | number : "1.0-0" }} metres</span
          >
        </ng-container>

        <!-- <span class="badge-value"
          >{{ detailData?.min_distance | number : "1.0-0" }} metres</span
        > -->
      </div>
      <div *ngIf="detailData?.description">
        <span class="badge-key">Description</span>
        <span class="badge-value">
          <div class="ellipsis" nbTooltip="{{ detailData?.description }}">
            {{ detailData?.description }}
          </div>
        </span>
      </div>

      <div class="mb-3" *ngIf="detailData?.qr_code">
        <span class="badge-key">Checkpoint Value </span>
        <span class="badge-value"
          ><img
            src="assets/images/qr-code-logo.png"
            alt=""
            class="me-1"
            width="38"
          />{{ detailData?.qr_code }}
        </span>
      </div>

      <div
        *ngIf="!detailData?.user_prompts?.length && detailData?.is_validated"
      >
        <button
          nbButton
          class="giant"
          (click)="openTemplate(postScanPromptTemplate)"
        >
          Add Post Scan Prompt
        </button>
      </div>

      <div *ngIf="detailData?.user_prompts?.length">
        <span
          class="badge-key mb-2"
          (click)="openTemplate(postScanPromptTemplate)"
          >Post Scan Prompts
          <nb-icon pack="eva" status="primary" icon="plus-square"></nb-icon
        ></span>
        <span class="badge-value">
          <div
            *ngFor="let prompt of detailData?.user_prompts"
            (click)="getPromptDetail(prompt)"
            class="p-2"
          >
            {{ prompt?.name }}
          </div>
          <div
            *ngIf="!detailData?.user_prompts?.length"
            (click)="openTemplate(postScanPromptTemplate)"
          >
            No post scan prompts configured
          </div>
        </span>
      </div>
    </div>

    <div class="col" *ngIf="isAdmin && detailData?.scanned_count">
      <button
        class="nbbutton-long-text"
        nbButton
        (click)="onClickScanHistory()"
        fullWidth
        size="giant"
      >
        Scan History
      </button>
    </div>
  </nb-card-body>

  <!-- DESKTOP VIEW -->
  <nb-card-body class="large-view-only gt-p-1">
    <div class="row row-cols-2">
      <!-- DETAILS SECTION -->
      <div class="col col-xl-8">
        <div class="badge-key">Checkpoint Name</div>
        <div class="row">
          <div class="d-flex">
            <div class="flex-grow-1 d-flex align-items-center">
              <img
                *ngIf="detailData?.qr_code"
                class="me-3"
                src="assets/images/qr-code-logo.png"
                alt=""
                width="64"
              />
              <div
                class="sc-head4 pointer"
                (click)="
                  isAdmin
                    ? detailData?.is_validated
                      ? openTemplate(editCPTemplate)
                      : detailData?.address?.state_code
                      ? openTemplate(validateCPTemplate)
                      : ''
                    : ''
                "
              >
                {{ detailData?.name }}

                <nb-icon
                  class="ms-auto text-basic-color"
                  nbSuffix
                  icon="edit-2"
                  *ngIf="
                    isAdmin
                      ? detailData?.is_validated
                        ? true
                        : detailData?.address?.state_code
                        ? true
                        : false
                      : false
                  "
                  pack="eva"
                ></nb-icon>
              </div>
            </div>
            <div
              class="sc-floating-right"
              *ngIf="isAdmin"
              (click)="deleteCheckPoint(detailData)"
            >
              <button
                nbButton
                status="danger"
                outline
                title="Delete Checkpoint"
              >
                <nb-icon icon="trash" pack="eva" status="danger"></nb-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="row sc-mt-9">
          <div>
            <span class="badge-key">Client</span>
            <span class="badge-value">{{ detailData?.company }}</span>
          </div>
          <div>
            <span class="badge-key">Coordinates</span>
            <span class="badge-value"
              >{{ detailData?.latitude }}, {{ detailData?.longitude }}</span
            >
          </div>

          <div *ngIf="!detailData?.qr_code || detailData?.is_locked">
            <span class="badge-key"
              >Geofence Radius (metres)
              <nb-icon
                class="ms-auto"
                nbSuffix
                [icon]="'alert-circle-outline'"
                pack="eva"
                nbTooltip="Checkpoint can only be scanned when within this distance of address"
                nbTrigger="focus"
              ></nb-icon
            ></span>
            <form [formGroup]="checkPointForm" class="d-inline-block">
              <span class="badge-value">
                <input
                  *ngIf="detailData?.is_validated"
                  class="range-slider-input mx-0"
                  type="range"
                  [min]="minKm"
                  placeholder="Geofence Radius (metres)"
                  [max]="maxKm"
                  [value]="nearestKmValue"
                  formControlName="min_distance"
                  (mouseup)="updateCP(checkPointForm)"
                  (touchend)="updateCP(checkPointForm)"
                />
                <output class="sc-floating-right output-margin-auto">{{
                  checkPointForm.value.min_distance
                }}</output>
              </span>
            </form>

            <!-- <span class="badge-value">{{
              detailData?.min_distance | number : "1.0-0"
            }}</span> -->
          </div>

          <div *ngIf="detailData?.description">
            <span class="badge-key">Description</span>
            <span class="badge-value">{{ detailData?.description }}</span>
          </div>

          <div *ngIf="detailData?.qr_code">
            <span class="badge-key">Checkpoint Value </span>
            <span class="badge-value">{{ detailData?.qr_code }}</span>
          </div>
        </div>

        <div
          *ngIf="!detailData?.user_prompts?.length && detailData?.is_validated"
        >
          <button
            nbButton
            class="giant"
            (click)="openTemplate(postScanPromptTemplate)"
          >
            Add Post Scan Prompt
          </button>
        </div>

        <div *ngIf="detailData?.user_prompts?.length">
          <span
            class="badge-key mb-2"
            (click)="openTemplate(postScanPromptTemplate)"
            >Post Scan Prompts
            <nb-icon pack="eva" status="primary" icon="plus-square"></nb-icon
          ></span>
          <span class="badge-value pointer">
            <div
              *ngFor="let prompt of detailData?.user_prompts"
              (click)="getPromptDetail(prompt)"
              class="p-2"
            >
              {{ prompt?.name }}
            </div>
            <div
              *ngIf="!detailData?.user_prompts?.length"
              (click)="openTemplate(postScanPromptTemplate)"
            >
              No post scan prompts configured
            </div>
          </span>
        </div>

        <div *ngIf="isAdmin">
          <div>
            <button
              *ngIf="isAdmin && !detailData?.is_validated"
              nbButton
              hero
              status="primary"
              (click)="openTemplate(validateCPTemplate)"
              size="giant"
              class="nbbutton-long-text btnValidateCheckpoint"
            >
              Validate Checkpoint
            </button>
          </div>
        </div>
      </div>

      <!-- LEAFLET MAP SECTION -->
      <div class="col col-xl-4">
        <app-map-leaflet
          *ngIf="!mobileView"
          [latLon]="{
            lat: detailData?.latitude,
            lon: detailData?.longitude,
            geoFenceRadius: detailData?.min_distance
          }"
          mapId="viewCheckpointDesktopMap"
        ></app-map-leaflet>
      </div>
    </div>
  </nb-card-body>

  <nb-card-body>
    <!-- DESKTOP VIEW -->
    <div class="large-view-only">
      <!-- history section start -->
      <nb-tabset>
        <nb-tab tabTitle="Scan History">
          <div *ngIf="userEvents?.length">
            <div>
              <app-table
                [desktopTableData]="userEvents"
                [desktopTableDetails]="userEventDetailsTableView"
                [tableStyle]="tableStyle"
                [otherParms]="{ dateRange: dateRange }"
                (rowClick)="onTableRowClick($event)"
              ></app-table>
            </div>
            <div
              class="row row-cols-1 row-cols-sm-1 row-cols-md-2 gt-p-small"
              *ngIf="showDetails"
            >
              <div class="col-md-5">
                <nb-card class="events-details-section">
                  <ng-container>
                    <div class="sc-head6">Event Details</div>
                    <div class="row row-cols-1 row-cols-xxl-2">
                      <ng-container
                        *ngFor="let indColumn of userEventDetailsTableView"
                      >
                        <ng-container
                          *ngIf="
                            (indColumn.nestedValue
                              ? indColumn.nestedValue(selectedEvent)
                              : selectedEvent[indColumn.name]
                            )?.trim()
                          "
                        >
                          <div>
                            <span
                              class="badge-key"
                              style="text-transform: uppercase"
                            >
                              {{ indColumn?.header }}</span
                            >
                            <ng-container
                              *ngIf="indColumn?.datetimeObj == true"
                            >
                              <span class="badge-value">
                                {{
                                  indColumn.nestedValue
                                    ? (indColumn.nestedValue(selectedEvent)
                                      | date
                                        : (indColumn.dateTimeFormat
                                            ? indColumn.dateTimeFormat
                                            : "d MMM y HH:mm"))
                                    : (selectedEvent[indColumn.name]
                                      | date
                                        : (indColumn.dateTimeFormat
                                            ? indColumn.dateTimeFormat
                                            : "d MMM y HH:mm"))
                                }}
                              </span></ng-container
                            >
                            <ng-container *ngIf="!indColumn?.datetimeObj">
                              <span class="badge-value">
                                {{
                                  indColumn.nestedValue
                                    ? indColumn.nestedValue(selectedEvent)
                                    : selectedEvent[indColumn.name] == "null" ||
                                      selectedEvent[indColumn.name] ==
                                        "undefined"
                                    ? "N/A"
                                    : selectedEvent[indColumn.name]
                                }}
                              </span></ng-container
                            >
                          </div>
                        </ng-container>
                      </ng-container>
                      <div
                        *ngIf="selectedEvent?.checkpoint_details?.min_distance"
                      >
                        <span
                          class="badge-key"
                          style="text-transform: uppercase"
                        >
                          Geofence Radius (metres)</span
                        >
                        <span class="badge-value">{{
                          selectedEvent?.checkpoint_details?.min_distance
                            | number : "1.0-0"
                        }}</span>
                      </div>
                    </div>
                    <div
                      *ngIf="scanDistanceInfo?.scanDistance"
                      class="fw-bold text-danger"
                    >
                      <span class="badge-value">
                        Scanned {{ scanDistanceInfo?.scanDistance }}M away from
                        the Checkpoint</span
                      >
                    </div>
                  </ng-container>
                  <div
                    *ngIf="
                      selectedEvent?.recorded_at &&
                      !selectedEvent?.delayed_event
                    "
                    nbTooltip="This event was captured when the user was offline"
                  >
                    <span class="badge-key" style="color: brown"
                      >CAPTURED TIME</span
                    >
                    <span class="badge-value strong">
                      {{
                        selectedEvent?.recorded_at
                          | customdate : "d MMM y HH:mm"
                      }}
                    </span>
                  </div>

                  <ng-container *ngIf="selectedEvent?.prompts?.length">
                    <div *ngFor="let item of selectedEvent?.prompts">
                      <div class="sc-head6">
                        {{
                          item?.prompt_data?.name
                            ? item?.prompt_data?.name
                            : "Prompt"
                        }}
                      </div>

                      <div *ngFor="let dataItem of item?.data">
                        <ng-template
                          *ngTemplateOutlet="
                            promptTemplate;
                            context: { value: dataItem }
                          "
                        ></ng-template>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="selectedEvent?.device_details">
                    <div class="sc-head6">Device Details</div>
                    <div class="row row-cols-1 row-cols-xxl-2">
                      <div
                        *ngFor="
                          let item of selectedEvent?.device_details | keyvalue
                        "
                      >
                        <div *ngIf="item?.value">
                          <span
                            class="badge-key"
                            style="text-transform: uppercase"
                          >
                            {{ item.key }}</span
                          >
                          <span class="badge-value"> {{ item.value }} </span>
                        </div>
                      </div>

                      <div>
                        <span
                          class="badge-key"
                          nbTooltip="{{browserGpsInfo?.[selectedEvent?.gps?.source]?.description}}"
                          nbTrigger="focus"
                          [ngClass]="browserGpsInfo?.[selectedEvent?.gps?.source] ? 'strong' : ''"
                          [nbTooltipDisabled]="!browserGpsInfo?.[selectedEvent?.gps?.source]"
                        >
                          LOCATION
                        </span>
                        <span
                          class="badge-value"
                          nbTooltip="{{browserGpsInfo?.[selectedEvent?.gps?.source]?.description}}"
                          nbTrigger="focus"
                          [nbTooltipDisabled]="!browserGpsInfo?.[selectedEvent?.gps?.source]"
                          [ngClass]="browserGpsInfo?.[selectedEvent?.gps?.source] ? 'danger-text': ''"
                        >
                          {{ selectedEvent?.gps?.lat }},
                          {{ selectedEvent?.gps?.lon }}
                        </span>
                      </div>
                    </div>
                  </ng-container>
                </nb-card>
              </div>

              <div class="col-md-7">
                <div
                  [ngClass]="
                    uploadedImage ? 'row row-cols-md-2' : 'row row-cols-1'
                  "
                >
                  <div class="col-md-6" *ngIf="uploadedImage">
                    <img
                      class="event-image"
                      src="{{ uploadedImage }}"
                      alt="unplash"
                    />
                  </div>
                  <div [ngClass]="uploadedImage ? 'col-md-6' : 'col'">
                    <app-map-leaflet
                      *ngIf="mapLatLong"
                      [latLon]="mapLatLong"
                      [scannedCheckpointDetails]="
                        selectedEvent?.checkpoint_details
                      "
                      mapId="large-scan-map"
                    ></app-map-leaflet>
                    <div class="sc-text-center" style="font-size: small">
                      {{ selectedEvent?.gps?.lat }},
                      {{ selectedEvent?.gps?.lon }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="userEvents?.length < 1" class="gt-p-1">
            <nb-alert outline="basic" style="font-weight: 500"
              >No scans recorded for this checkpoint</nb-alert
            >
          </div>
        </nb-tab>
        <nb-tab tabTitle="Changes">
          <div class="sc-mt-6">
            <app-table
              [desktopTableData]="checkpointHistoryData"
              [desktopTableDetails]="checkpointHistoryTable"
              (rowClick)="onCPHistoryClick($event)"
            ></app-table>
          </div>
          <div class="row row-cols-2" *ngIf="showHistoryDetails">
            <div class="col-md-5">
              <nb-card class="events-details-section">
                <ng-container *ngIf="selectedHistoryEvent?.device_details">
                  <div class="sc-head6">Device Details</div>
                  <div class="row row-cols-1 row-cols-xxl-2">
                    <div
                      *ngFor="
                        let item of selectedHistoryEvent?.device_details
                          | keyvalue
                      "
                    >
                      <div *ngIf="item?.value">
                        <span
                          class="badge-key"
                          style="text-transform: uppercase"
                        >
                          {{ item.key }}</span
                        >
                        <span class="badge-value"> {{ item.value }} </span>
                      </div>
                    </div>

                    <div>
                      <span
                        class="badge-key"
                        nbTooltip="{{browserGpsInfo?.[selectedHistoryEvent?.gps?.source]?.description}}"
                        nbTrigger="focus"
                        [ngClass]="browserGpsInfo?.[selectedHistoryEvent?.gps?.source] ? 'strong' : ''"
                        [nbTooltipDisabled]="!browserGpsInfo?.[selectedHistoryEvent?.gps?.source]"
                      >
                        LOCATION
                      </span>
                      <span
                        class="badge-value"
                        nbTooltip="{{browserGpsInfo?.[selectedHistoryEvent?.gps?.source]?.description}}"
                        nbTrigger="focus"
                        [nbTooltipDisabled]="!browserGpsInfo?.[selectedHistoryEvent?.gps?.source]"
                        [ngClass]="browserGpsInfo?.[selectedHistoryEvent?.gps?.source] ? 'danger-text': ''"
                      >
                        {{ selectedHistoryEvent?.gps?.lat }},
                        {{ selectedHistoryEvent?.gps?.lon }}
                      </span>
                    </div>
                  </div>
                </ng-container>
              </nb-card>
            </div>
            <div class="col-md-7">
              <app-map-leaflet
                *ngIf="mapLatLongHistory"
                [latLon]="mapLatLongHistory"
                [mapId]="'cpHistoryLocation'"
              ></app-map-leaflet>
            </div>
          </div>
        </nb-tab>
      </nb-tabset>
    </div>
  </nb-card-body>
</nb-card>

<!-- edit CP template-->
<ng-template #editCPTemplate let-editCPRef="dialogRef">
  <nb-card class="gt-p-small dialog-box">
    <nb-card-header> Edit Checkpoint</nb-card-header>
    <nb-card-body class="scrollable-y">
      <form [formGroup]="checkPointForm">
        <nb-stepper #stepper>
          <nb-step>
            <div class="form-floating">
              <input
                class="form-control"
                fullWidth
                formControlName="name"
                nbInput
                type="text"
                placeholder="Checkpoint Name"
                (keyup.enter)="stepper.next()"
              />
              <label for="inputService">Checkpoint Name</label>
            </div>

            <div class="form-floating">
              <input
                class="form-control"
                fullWidth
                id="description"
                formControlName="description"
                nbInput
                type="text"
                placeholder="Description (Optional)"
              /><label for="inputAssignee">Description (Optional)</label>
            </div>

            <div
              class="mt-4 d-flex align-items-center"
              *ngIf="detailData?.qr_code"
            >
              <nb-checkbox formControlName="is_locked"
                >Lock Checkpoint</nb-checkbox
              >
              <nb-icon
                [icon]="'alert-circle-outline'"
                class="mb-1 ms-4"
                pack="eva"
                nbTooltip="When box is ticked, checkpoint can only be scanned at checkpoint location."
                nbTrigger="focus"
                status="primary"
              ></nb-icon>
            </div>
            <div class="sc-mt-6 row row-cols-2">
              <div class="col">
                <button
                  type="button"
                  class="nbbutton-long-text"
                  nbButton
                  fullWidth
                  ghost
                  size="giant"
                  (click)="editCPRef.close(); resetValues()"
                >
                  Close
                </button>
              </div>
              <div class="col">
                <ng-container *ngIf="detailData?.qr_code"
                  ><button
                    *ngIf="checkPointForm.value.is_locked"
                    type="button"
                    class="nbbutton-long-text"
                    nbButton
                    fullWidth
                    size="giant"
                    nbStepperNext
                    (click)="showMapTrue()"
                  >
                    Update Location
                  </button>
                  <button
                    *ngIf="!checkPointForm.value.is_locked"
                    type="button"
                    class="nbbutton-long-text"
                    nbButton
                    fullWidth
                    size="giant"
                    (click)="updateCP(checkPointForm)"
                  >
                    Update
                  </button>
                </ng-container>
                <ng-container *ngIf="!detailData?.qr_code">
                  <!-- normal checkpoint cases-->
                  <button
                    type="button"
                    class="nbbutton-long-text"
                    nbButton
                    fullWidth
                    size="giant"
                    nbStepperNext
                    (click)="showMapTrue()"
                  >
                    Update Location
                  </button>
                </ng-container>
              </div>
            </div>
          </nb-step>

          <nb-step>
            <div class="row row-cols-2">
              <div class="col">
                <button
                  nbStepperPrevious
                  nbButton
                  class="nbbutton-long-text"
                  fullWidth
                  ghost
                  size="giant"
                  (click)="showMap = false"
                >
                  Back
                </button>
              </div>
              <div class="col">
                <button
                  type="submit"
                  nbButton
                  class="nbbutton-long-text"
                  fullWidth
                  [disabled]="
                    !checkPointForm.valid || (!detailData?.qr_code && pinIsFar)
                  "
                  size="giant"
                  (click)="updateCP(checkPointForm)"
                >
                  Update
                </button>
              </div>
            </div>

            <div *ngIf="showMap && gps">
              <nb-alert
                *ngIf="pinIsFar && !detailData?.qr_code"
                status="danger"
                class="sc-mb-1"
              >
                Pin is too far from Address
              </nb-alert>
              <div class="form-floating">
                <input
                  id="addressSearch"
                  class="form-control"
                  #addressAutoInput
                  (input)="onAddressSearch($event)"
                  nbInput
                  fullWidth
                  autocomplete="off"
                  formControlName="addressLookup"
                  type="text"
                  placeholder="Address"
                  [nbAutocomplete]="autoNgModelEditCheckpoint"
                /><label for="addressLookup">Address</label>
              </div>

              <nb-autocomplete
                #autoNgModelEditCheckpoint
                (selectedChange)="addressSelected($event)"
              >
                <nb-option [value]="' '"
                  ><b> Enter address manually </b>
                </nb-option>
                <nb-option
                  *ngFor="let item of addrLookupData$ | async"
                  [value]="item"
                >
                  <div>
                    <span
                      ><b>{{ item.address1 + " " + item.address2 }}</b
                      >,</span
                    ><br />
                    <small
                      >{{ item.address3 ? item.address3 + " " : "" }}
                      {{ item.city_name }} {{ item.state_name }}
                      {{ item.postcode }}</small
                    >
                  </div>
                </nb-option>
              </nb-autocomplete>
              <app-map-leaflet
                [latLon]="gps"
                [mapId]="'checkpointEditmapid'"
                [cursorDraggable]="true"
                (emitData)="emitData($event, true)"
                (updateMinDistance)="updateMinDistance($event)"
              ></app-map-leaflet>
              <div
                *ngIf="
                  checkPointForm.value?.latitude &&
                  checkPointForm.value?.longitude
                "
                style="
                  text-align: center;
                  text-transform: uppercase;
                  font-size: 0.7rem;
                "
              >
                <span style="font-size: 0.7rem">
                  Lat: {{ checkPointForm.value?.latitude }} Lon:
                  {{ checkPointForm.value?.longitude }}
                </span>
                <div>Drag the marker to set coordinates</div>
              </div>
            </div>
          </nb-step>
        </nb-stepper>
      </form>
    </nb-card-body>
  </nb-card>
</ng-template>

<!--validate checkpoint template-->
<ng-template #validateCPTemplate let-validateCPTemplate="dialogRef">
  <nb-card class="gt-p-small dialog-box">
    <nb-card-header>VALIDATE CHECKPOINT</nb-card-header>

    <form [formGroup]="checkPointForm">
      <nb-stepper #stepper>
        <nb-step>
          <div class="form-floating">
            <input
              class="form-control"
              fullWidth
              formControlName="name"
              nbInput
              type="text"
              placeholder="Checkpoint Name"
              (keyup.enter)="stepper.next()"
            />
            <label for="inputService">Checkpoint Name</label>
          </div>
          <div class="mt-4 d-flex align-items-center">
            <nb-checkbox formControlName="is_locked"
              >Lock Checkpoint</nb-checkbox
            >
            <nb-icon
              [icon]="'alert-circle-outline'"
              class="mb-1 ms-4"
              pack="eva"
              nbTooltip="When box is ticked, checkpoint can only be scanned at checkpoint location."
              nbTrigger="focus"
              status="primary"
            ></nb-icon>
          </div>

          <div class="sc-mt-6 row row-cols-2">
            <div class="col">
              <button
                type="button"
                nbButton
                fullWidth
                ghost
                size="giant"
                (click)="validateCPTemplate.close(); resetValues()"
              >
                Close
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                nbButton
                fullWidth
                [disabled]="!checkPointForm.value?.company_id"
                size="giant"
                (click)="stepperSkipNExtStep()"
              >
                Next
              </button>
            </div>
          </div>
        </nb-step>
        <nb-step>
          <div class="sc-mt-6 row row-cols-2">
            <div class="col">
              <button
                nbButton
                fullWidth
                ghost
                size="giant"
                nbStepperPrevious
                (click)="showMap = false"
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                nbButton
                fullWidth
                size="giant"
                nbStepperNext
                [disabled]="!checkPointForm.value?.latitude"
              >
                Next
              </button>
            </div>
          </div>
          <div class="form-floating">
            <input
              id="addressSearchEdit"
              class="form-control"
              #addressAutoInput
              (input)="onAddressSearch($event)"
              nbInput
              fullWidth
              autocomplete="off"
              formControlName="addressLookup"
              type="text"
              placeholder="Address"
              [nbAutocomplete]="autoNgModel"
            /><label for="addressLookup">Address</label>
          </div>

          <nb-autocomplete
            #autoNgModel
            (selectedChange)="addressSelected($event)"
          >
            <nb-option [value]="' '"
              ><b> Enter address manually </b>
            </nb-option>
            <nb-option
              *ngFor="let item of addrLookupData$ | async"
              [value]="item"
            >
              <div>
                <span
                  ><b>{{ item.address1 + " " + item.address2 }}</b
                  >,</span
                ><br />
                <small
                  >{{ item.address3 ? item.address3 + " " : "" }}
                  {{ item.city_name }} {{ item.state_name }}
                  {{ item.postcode }}</small
                >
              </div>
            </nb-option>
          </nb-autocomplete>

          <div *ngIf="showMap && gps">
            <app-map-leaflet
              [latLon]="gps"
              [mapId]="'mapid'"
              [cursorDraggable]="checkPointForm.value?.is_locked"
              (emitData)="emitData($event)"
              (updateMinDistance)="updateMinDistance($event)"
            ></app-map-leaflet>
            <div
              *ngIf="
                checkPointForm.value?.latitude &&
                checkPointForm.value?.longitude
              "
              style="
                text-align: center;
                text-transform: uppercase;
                font-size: 0.7rem;
              "
            >
              <span style="font-size: 0.7rem">
                Lat: {{ checkPointForm.value?.latitude }} Lon:
                {{ checkPointForm.value?.longitude }}
              </span>
              <div>Drag the marker to set coordinates</div>
            </div>
          </div>
        </nb-step>
        <nb-step>
          <nb-alert status="danger" role="alert">
            <small> WARNING: Client cannot be changed once set</small>
          </nb-alert>
          <div class="form-floating">
            <input
              autocomplete="off"
              class="form-control"
              fullWidth
              id="clientName"
              formControlName="company"
              (input)="onClientSearch($event)"
              nbInput
              type="text"
              placeholder="Client"
              [nbAutocomplete]="clientACMob"
            />

            <label>Client</label>
          </div>

          <nb-autocomplete
            #clientACMob
            (selectedChange)="onClientSelect($event)"
          >
            <nb-option [value]="' '">
              <b> Add New Client</b>
            </nb-option>
            <nb-option *ngFor="let item of customerDetails" [value]="item">
              <div>
                <div class="strong">
                  {{ item?.company_name }}
                </div>
                <div>
                  <small>{{ item?.address?.full_address }}</small>
                </div>
              </div>
            </nb-option>
          </nb-autocomplete>
          <div class="row row-cols-2">
            <div class="col">
              <button
                nbButton
                fullWidth
                ghost
                size="giant"
                (click)="stepperSkipStep()"
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                nbButton
                fullWidth
                status="primary"
                [disabled]="!checkPointForm.valid"
                size="giant"
                (click)="
                  updateCP(checkPointForm, true); validateCPTemplate.close()
                "
              >
                Validate
              </button>
            </div>
          </div>
        </nb-step>
      </nb-stepper>
    </form>
  </nb-card>
</ng-template>
<ng-template #postScanPromptTemplate let-postScanPromptTemplate="dialogRef">
  <nb-card class="gt-p-small dialog-box">
    <nb-card-header>POST SCAN PROMPTS</nb-card-header>
    <nb-stepper>
      <nb-step>
        <div class="form-floating">
          <input
            class="form-control"
            type="text"
            nbTagInput
            fullWidth
            placeholder="Select/Search Prompts"
            (input)="searchPrompts($event)"
            [nbAutocomplete]="autoGrpNgModel"
          />
          <label>Search</label>
        </div>
        <nb-autocomplete #autoGrpNgModel>
          <nb-option
            *ngFor="let userPrompt of userPromptsSearchResults"
            (click)="addSelectedUserPrompt(userPrompt)"
            >{{ userPrompt.name }}
          </nb-option></nb-autocomplete
        >

        <table class="user-prompt-table">
          <tbody>
            <tr
              *ngFor="let prompt of availableUserPrompts; let i = index"
              class="pointer"
            >
              <td>
                <nb-checkbox
                  [checked]="prompt.selected"
                  (checkedChange)="addRemoveUserPrompt($event, prompt)"
                >
                </nb-checkbox>
              </td>

              <td (click)="addRemoveUserPrompt(!prompt?.selected, prompt)">
                {{ prompt?.name }}
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="my-3"
          *ngIf="
            userPromptsTotalPages > 1 &&
            userPromptsTotalCount != availableUserPrompts?.length
          "
        >
          <button
            type="button"
            nbButton
            fullWidth
            size="giant"
            (click)="onLoadMore()"
          >
            Load More
          </button>
        </div>

        <div class="row row-cols-3 mt-2">
          <div class="col">
            <button
              type="button"
              nbButton
              fullWidth
              (click)="postScanPromptTemplate.close()"
              ghost
              size="giant"
            >
              Close
            </button>
          </div>
          <div class="col">
            <button
              type="submit"
              nbButton
              fullWidth
              size="giant"
              class="nbbutton-long-text"
              (click)="postScanPromptTemplate.close()"
              routerLink="/user-prompt"
            >
              Create New
            </button>
          </div>
          <div class="col" *ngIf="selectedUserPrompts?.length < 2">
            <button
              type="submit"
              nbButton
              fullWidth
              size="giant"
              (click)="updateCP(checkPointForm)"
            >
              Update
            </button>
          </div>
          <div class="col" *ngIf="selectedUserPrompts?.length > 1">
            <button nbStepperNext nbButton fullWidth size="giant">Order</button>
          </div>
        </div>
      </nb-step>
      <nb-step>
        <div
          cdkDropList
          #currentList="cdkDropList"
          [cdkDropListData]="selectedUserPrompts"
          class="drag-drop-container drag-list sc-mt-6"
          (cdkDropListDropped)="drop($event)"
        >
          <table class="drag-box">
            <tbody>
              <tr
                *ngFor="let prompt of selectedUserPrompts; let i = index"
                class="grab-item"
              >
                <td cdkDrag>
                  <div class="pe-1 cursor-grab">
                    <nb-icon
                      icon="menu"
                      pack="eva"
                      class="cursor-grab"
                    ></nb-icon>
                  </div>
                </td>

                <td>
                  {{ prompt?.name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row row-cols-2">
          <div class="col">
            <button
              type="button"
              nbButton
              fullWidth
              nbStepperPrevious
              ghost
              size="giant"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="submit"
              nbButton
              fullWidth
              size="giant"
              (click)="updateCP(checkPointForm)"
            >
              Update
            </button>
          </div>
        </div>
      </nb-step>
    </nb-stepper>
  </nb-card>
</ng-template>
<!--looped prompt template-->
<ng-template let-dataItem="value" #promptTemplate>
  <div *ngIf="dataItem?.description">
    <span class="badge-key" style="text-transform: uppercase">
      {{ dataItem?.description }}</span
    >
    <!--TODO: convert into pipe-->
    <span class="badge-value"> {{ getPromptValue(dataItem) }} </span>
  </div>

  <div *ngFor="let option of dataItem?.options">
    <ng-template
      *ngTemplateOutlet="promptTemplate; context: { value: option }"
    ></ng-template>
  </div>
</ng-template>
