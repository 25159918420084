<nb-card-body>
  <!-- MOBILE VIEW -->
  <div class="mobile-only gt-p-small">
    <!-- case where there are atleaset 5 recorrds-->
    <div *ngIf="totalClientCount > searchRemovalCap">
      <nb-form-field class="sc-search-input-box">
        <div class="form-floating">
          <input
            class="form-control"
            type="text"
            nbInput
            fullWidth
            placeholder="Search Company"
            [(ngModel)]="globalFilter"
            (keyup)="onFilterApply($event)"
            [nbAutocomplete]="autoNgModel"
          />
          <label>Search Companies</label>
        </div>
        <nb-icon
          nbSuffix
          *ngIf="showTypeAhead === true"
          [icon]="'search-outline'"
          pack="eva"
          (click)="onFilterApply(true)"
        ></nb-icon>
        <nb-icon
          nbSuffix
          *ngIf="showTypeAhead === false"
          [icon]="'close-outline'"
          pack="eva"
          (click)="onClearFilter()"
        ></nb-icon>
      </nb-form-field>
    </div>
    <div *ngIf="isAdmin === true">
      <button
        size="giant"
        class="status-button"
        fullWidth
        (click)="openAddCompanyForm()"
        nbButton
        hero
        status="primary"
      >
        + Add Company
      </button>
    </div>
  </div>

  <!-- DESKTOP VIEW -->
  <div
    class="large-view-only gt-p-1"
    *ngIf="totalClientCount > searchRemovalCap"
  >
    <div class="flex-center">
      <nb-form-field class="sc-search-input-box">
        <div class="form-floating">
          <input
            class="form-control"
            type="text"
            nbInput
            fullWidth
            placeholder="Search Companies"
            [(ngModel)]="globalFilter"
            (keyup)="onFilterApply($event)"
            [nbAutocomplete]="autoNgModel"
          />
          <label>Search Companies</label>
        </div>
        <nb-icon
          class="ico-color"
          nbSuffix
          *ngIf="showTypeAhead === true"
          [icon]="'search-outline'"
          pack="eva"
          (click)="onFilterApply(true)"
        ></nb-icon>
        <nb-icon
          class="ico-color"
          nbSuffix
          *ngIf="showTypeAhead === false"
          [icon]="'close-outline'"
          pack="eva"
          (click)="onClearFilter()"
        ></nb-icon>
      </nb-form-field>
      <div class="sc-add-btn" *ngIf="isAdmin === true">
        <!-- Add Company Button -->
        <button
          title="Add Company"
          class="sc-mt-0"
          size="giant"
          nbButton
          hero
          status="primary"
          type="button"
          (click)="openAddCompanyForm()"
        >
          + Add Company
        </button>
      </div>
    </div>
  </div>
  <nb-autocomplete #autoNgModel>
    <nb-option
      *ngFor="let item of showAutoCustomerDetails$ | async"
      [value]="item"
    >
      <div>
        <div>
          <span
            ><b>{{ item.company_name }}</b></span
          >
        </div>
      </div>
    </nb-option>
  </nb-autocomplete>
  <div
    class="row"
    *ngIf="isAdmin === true && totalClientCount <= searchRemovalCap"
  >
    <!-- case where there are less than 5 records ; don't show search bar-->
    <div class="row" style="margin-bottom: 1rem">
      <!-- Add Company Button -->
      <div class="col-sm-6 col-lg-3 sc-pb-0 large-view-only">
        <button
          size="giant"
          fullWidth
          hero
          status="primary"
          (click)="openAddCompanyForm()"
          nbButton
        >
          + Add Company
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="clientCount > 0">
    <app-card-list
      [listCardData]="clientPaginationData[pageNum]"
      [listCardDetails]="clientCardDetils"
      [cardActionIcons]="cardActionIcons"
      (actionClick)="deleteCompany($event?.event)"
      (rowClick)="openEditCompanyForm($event)"
    ></app-card-list>

    <!--customer pagination BEGINS-->
    <div>
      <app-pagination-control
        [pageSize]="pageSize"
        [pageNum]="pageNum"
        [previous]="previous"
        [totalRows]="clientCount"
        [totalPages]="totalPages"
        (onClickPagination)="onClickPagination($event)"
        (onChangePagination)="onChangePagination($event)"
      >
      </app-pagination-control>
    </div>
    <!--customer pagination ENDS-->
  </div>
  <div *ngIf="clientCount < 1">
    <nb-alert outline="basic">No records found</nb-alert>
  </div>
</nb-card-body>
