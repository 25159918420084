<nb-card class="dialog-box gt-p-small">
  <ng-container *ngIf="selectedPagePrompts?.length">
    <nb-card-body>
      <div *ngFor="let field of selectedPagePrompts" class="mb-3">
        <ng-template
          *ngTemplateOutlet="formTemplate; context: { value: field }"
        ></ng-template>
      </div>
    </nb-card-body>
  </ng-container>
  <ng-container>
    <span class="error-message">Fields marked * cannot be empty </span>
    <div
      class="sc-mt-6"
      [ngClass]="page > 0 ? 'row row-cols-3' : 'row row-cols-2'"
    >
      <div class="col">
        <button nbButton (click)="closeDalog(false)" fullWidth size="large">
          Cancel
        </button>
      </div>
      <div class="col" *ngIf="page > 0">
        <button
          nbButton
          [disabled]="page === 0"
          (click)="changePagination(false)"
          fullWidth
          size="large"
        >
          Back
        </button>
      </div>
      <div class="col" *ngIf="preview">
        <button
          nbButton
          fullWidth
          status="primary"
          size="large"
          (click)="
            page + 1 < promptData?.length ? changePagination() : closeDalog()
          "
        >
          {{ page + 1 < promptData?.length ? "Next" : "OK" }}
        </button>
      </div>
      <div class="col" *ngIf="!preview">
        <button
          nbButton
          fullWidth
          status="primary"
          [disabled]="
            page + 1 < promptData?.length ? validatepage() : valdiateForm()
          "
          size="large"
          (click)="
            page + 1 < promptData?.length ? changePagination() : savePromtData()
          "
        >
          {{ page + 1 < promptData?.length ? "Next" : "OK" }}
        </button>
      </div>
    </div>
  </ng-container></nb-card
>
<ng-template let-field="value" #formTemplate>
  <ng-container *ngIf="field.type === 'output'">
    <div class="field-output-description" *ngIf="field.description">
      {{ field.description }}
      <span class="error-message" *ngIf="field?.required">*</span>
    </div>
  </ng-container>
  <ng-container *ngIf="field.type === 'input'">
    <div [ngSwitch]="field.field">
      <div class="field-input-description" *ngIf="field.description">
        {{ field.description }}
        <span class="error-message" *ngIf="field?.required">*</span>
      </div>

      <ng-container *ngSwitchCase="'text'">
        <div>
          <textarea
            rows="1"
            class="form-control"
            [placeholder]=""
            [required]="field?.required"
            [(ngModel)]="field.value"
          ></textarea>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'checkbox'">
        <div *ngFor="let opt of field.options">
          <div
            class="pointer form-check m-2"
            (click)="selectCheckBox(field, opt.key)"
          >
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="field.value.includes(opt.key)"
            />
            {{ opt.key }}
          </div>
        </div>
        <div
          *ngIf="field.allowedSelections > 1"
          class="small-font sc-floating-right"
        >
          Choose up to {{ field.allowedSelections }}
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="getSubOptions(field)">
      <div *ngFor="let subField of getSubOptions(field)">
        <ng-template
          *ngTemplateOutlet="formTemplate; context: { value: subField }"
        ></ng-template>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
