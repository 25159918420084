import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { NbDialogService } from '@nebular/theme';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { QrCodeComponent } from 'src/app/shared/comp/qr-code/qr-code.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { JobsService } from '../jobs/jobs.service';
import { PagesService } from '../pages.service';
import { ProfileService } from '../profile/profile.service';
import { PwaInstallationGuideComponent } from '../pwa-installation-guide/pwa-installation-guide.component';
import { UserPromptService } from '../user-prompts/user-prompt.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  updateTask: any = 'updateJob';
  userData: any;
  staleDays: any;
  dashBoardData: any;
  subscriberStats: any;
  jobStats: any;
  userStats: any;
  isDispatchUser: any;
  isAdmin: any;
  offlineMode: boolean = false;
  isSuperUser: boolean = false;
  dialogRef: any;
  messageChannel = new MessageChannel();
  offlineData: any;
  isGuardUser: boolean;
  isGuardClockedIn: boolean;
  deferredPrompt: any;
  isAppInstalled: boolean = false;
  isSubscriberAdmin: boolean = false;
  isSubscriptionActive: boolean = false;
  bottomSectionFixedHeight: string = '180px';
  isPageLoad: boolean = false;
  isSOSButtonPressed: boolean = false;
  isOnEvent: boolean = false;

  userPrompts: any = [];
  updateEvenDetails: any = {};

  timer: any;
  pressStartTime: any;
  pressDurationThreshold: number = 3000; // 3 seconds
  currentDevice: any;
  cameraOnDevice: boolean = true;

  constructor(
    private appService: AppService,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private dialogService: NbDialogService,
    private pageService: PagesService,
    private dataCheckService: DataCheckService,
    private jobService: JobsService,
    private deviceService: DeviceDetectorService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private userPromptService: UserPromptService,
    private profileService: ProfileService,
    private swUpdate: SwUpdate
  ) {
    this.userData = this.appService.getUserData();
    this.offlineMode = !navigator?.onLine;
    this.isSuperUser = this.dataCheckService.isSuperUser();
    this.currentDevice = this.deviceService.getDeviceInfo();
    if (!this.userData?.access) {
      this.router.navigate(['/login']);
    }
    if (this.isSuperUser === true) {
      this.router.navigate(['/gtadmin/dashboard']);
    }
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isGuardUser = this.dataCheckService.isGuardUser();
    this.isGuardClockedIn = this.dataCheckService.isGuardUserClockendIn();
    this.isSubscriberAdmin = this.dataCheckService.isSubscriberAdmin();
    this.isSubscriptionActive =
      this.dataCheckService.checkIsSubscriptionOrTestUser();
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      // Stash the event so it can be triggered later
      this.deferredPrompt = event;
      // to trigger the installation prompt
    });
  }

  isComingFromOnlineCheck() {
    this.offlineDataCheck();
    let offlineReloadCheck = this.offlineMode;
    this.offlineMode = !navigator?.onLine;
    if (this.offlineMode == false && offlineReloadCheck) {
      this.appService.getAddress().subscribe((response: any) => {
        if (response['status'] == 'success') {
          localStorage.setItem('syncData', 'true');
          window.location.reload();
        } else {
          setTimeout(() => {
            localStorage.setItem('syncData', 'true');
            window.location.reload();
          }, 2000);
        }
      });
    }
  }
  ngOnDestroy(): void {
    if (this.isOnEvent && !this.offlineMode) {
      this.pageService.isOnEvent.next(false);
    }
  }
  async ngAfterViewInit(): Promise<void> {
    setTimeout(() => {
      this.ngZone.run(() => {
        this.cdr.detectChanges();
        this.spinnerService.hide();
      });
    }, 100);
  }
  ngOnInit() {
    this.spinnerService.show();
    this.pageService.isOnEvent.subscribe((isOnEvent: any) => {
      this.ngZone.run(() => {
        this.isOnEvent = isOnEvent;
      });
    });
    if (this.isSubscriberAdmin && !this.isSubscriptionActive) {
      this.router.navigate([
        '/subscription',
        {
          state: 'Account Verified! Subscription Required',
        },
      ]);
    } else {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      if (!isIOS) {
        window.addEventListener('appinstalled', (event) => {
          // PWA app is installed
          this.isAppInstalled = true;
        });
      }
      if (window.matchMedia('(display-mode: standalone)').matches) {
        this.isAppInstalled = true;
      }
      window.addEventListener(
        'online',
        this.isComingFromOnlineCheck.bind(this)
      );
      window.addEventListener(
        'offline',
        this.isComingFromOnlineCheck.bind(this)
      );
      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault();
        // Stash the event so it can be triggered later
        this.deferredPrompt = event;
        let device = this.deviceService.getDeviceInfo();
        if (
          window.matchMedia('(display-mode: standalone)').matches ||
          (device.device !== 'iPhone' && !this.deferredPrompt)
        ) {
          this.isAppInstalled = true;
        }
      });

      this.router.navigate(['/']);
      if (!this.isSuperUser) {
        this.getHomePageData();
        this.getHomeScreenPrompts();
      }

      this.offlineDataCheck();
      // this.serviceWorkerOperations();
      this.spinnerService.hide();
    }
    this.ngZone.run(() => {
      setTimeout(() => {
        this.isPageLoad = true;
        this.calculateFixedBottomHeight();
      }, 300);
    });

    if (
      this.updateTask === 'updateJob' &&
      sessionStorage.getItem('updateAvailable') === 'true' &&
      this.swUpdate?.isEnabled
    ) {
      //making sure that the user is in home page and is doing nothing, so that we can trigger the swupdate
      sessionStorage.removeItem('updateAvailable');

      this.swUpdate?.activateUpdate().then(() => {
        // caches.keys().then((names) => {
        //   console.log(names, 'names');

        //   for (let name of names) {
        //     caches.delete(name);
        //   }
        // });
        //   .then(() => {
        //     window.location.reload();
        //   });
        this.ngZone.run(() => {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        });
      });
    }
  }

  offlineDataCheck() {
    navigator?.serviceWorker?.controller?.postMessage({
      type: 'offlineDataCheck',
    });

    // listening for messages from service worker
    navigator?.serviceWorker?.addEventListener('message', (event) => {
      if (event?.data?.type === 'offlineDataCheck') {
        this.formatOfflineSavedData(event?.data);
      }
    });
  }

  closeDetailedHome() {
    if (
      [
        'scanCheckPoint',
        'addClientComment',
        'addClientPhoto',
        'userPromptInfo',
      ].includes(this.updateTask)
    ) {
      this.updateTask = 'updateJob';
    }
    navigator?.serviceWorker?.controller?.postMessage({
      type: 'offlineDataCheck',
    });
  }

  getHomePageData() {
    this.spinnerService.show();
    let param: any = { time_offset: new Date().getTimezoneOffset() };
    if (this.staleDays) {
      param.stale_days = this.staleDays;
    }

    this.jobService.getDashBoardData(param).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.dashBoardData = response['data'];

        this.formatDashBoardData(this.dashBoardData);
      } else {
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }

  formatDashBoardData(data: any) {
    //As route feat is not added at the moment no need to call this api.
    // this.getPendingCheckPoints();
    this.subscriberStats = data.subscriber_stats;

    localStorage.setItem(
      'subscriberStats',
      JSON.stringify(data.subscriber_stats)
    );
    this.jobStats = data?.job_stats;

    this.userStats = data?.user_stats;
    this.isPageLoad = true;

    this.spinnerService.hide();
  }
  getHomeScreenPrompts() {
    this.userPromptService
      .getHomeUserPromptList({})
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.userPrompts = response?.data;
        }
      });
  }

  redirectToPage(string: any) {
    if (string === 'addReportUsers') {
      this.router.navigate(['/client'], {
        fragment: 'addReportUsers',
      });
    } else if (string == 'patrolHistory') {
      this.router.navigate(['/user-events']);
    } else if (string == 'lastEvents') {
      this.router.navigate(['/user-events'], { fragment: 'lastEvents' });
    } else if (string == 'myJobs') {
      this.router.navigate(['/jobs'], {
        fragment: 'jobs',
      });
    } else if (string == 'unassignedJobs') {
      this.router.navigate(['/jobs'], {
        fragment: 'unassignedJobs',
      });
    } else if (string == 'staleJobs') {
      this.router.navigate(['/jobs'], {
        fragment: 'staleJobs',
      });
    } else if (string == 'licenceAudit') {
      this.router.navigate(['/users'], {
        fragment: 'licenceAudit',
      });
    } else if (string == 'guardLocations') {
      this.router.navigate(['/guards']);
    } else if (string == 'validateQr') {
      this.router.navigate(['/checkpoints'], {
        fragment: 'validateQr',
      });
    } else if (string == 'incidents') {
      this.router.navigate(['/incidents'], {});
    }
  }
  isDictionary(item: any): boolean {
    return item && typeof item === 'object' && item.constructor === Object;
  }

  formatOfflineSavedData(data: any) {
    this.offlineData = data;

    this.offlineData.savedData = this.offlineData?.savedData?.filter(
      (savedItem: any) => !savedItem?.apiCallSuccess && !savedItem?.retry_count
    );
  }
  jsonParse(formData: any) {
    try {
      return this.isDictionary(formData) ? formData : JSON.parse(formData);
    } catch (error) {
      return {};
    }
  }

  installPWAApp() {
    this.spinnerService.show();
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isIOS) {
      this.dialogRef = this.dialogService.open(PwaInstallationGuideComponent, {
        context: {},
      });
      this.dialogRef.onClose.subscribe((value: any) => {});

      this.spinnerService.hide();
    } else {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.spinnerService.hide();
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then((choiceResult: any) => {
          if (choiceResult.outcome === 'accepted') {
            this.isAppInstalled = true;
            localStorage.setItem('isAppInstalled', 'true');
          }

          // Clear the deferredPrompt variable
          this.deferredPrompt = null;

          // Hide the install button after installation
        });
      } else {
        this.spinnerService.hide();
        this.pageService.setMessage({
          successMessage: '',
          errorMessage: 'ERROR: Guard Tracker may already be installed',
        });
      }
    }
  }

  calculateFixedBottomHeight() {
    // Get the bottom-section-fixed element by ID
    const bottomSectionFixed = document.getElementById('fixedBtn');
    if (bottomSectionFixed) {
      this.bottomSectionFixedHeight =
        bottomSectionFixed.offsetHeight.toString() === '0'
          ? '180' + 'px'
          : bottomSectionFixed.offsetHeight.toString() + 'px';
      this.cdr.detectChanges();
    }
  }
  async openQRCode() {
    if (await this.hasCamera()) {
      if ((await this.checkCameraPermission()) === true) {
        this.appService.permissionErrorAlert.next('cameraDisabled');
      } else {
        this.dialogRef = this.dialogService.open(QrCodeComponent, {
          context: {
            showFrom: 'home',
            cachedQRCheckpoints: this.offlineData?.qrCheckpointData?.data,
          },
        });
        this.dialogRef.onClose.subscribe((value: any) => {
          this.offlineDataCheck();
        });
      }
    } else {
      this.cameraOnDevice = false;
      this.pageService.setMessage({
        successMessage: '',
        errorMessage: 'Unable to access camera',
        status: 'danger',
      });
    }
  }

  handleSOSPress(
    isPressed: boolean,
    alertSOSTemplateRef: TemplateRef<any>,
    confirmSOSTemplate: TemplateRef<any>
  ) {
    this.isSOSButtonPressed = isPressed;
    if (isPressed) {
      this.pressStartTime = Date.now();
      this.timer = setTimeout(() => {
        this.callSOSApi(confirmSOSTemplate);
        this.isSOSButtonPressed = false;
      }, this.pressDurationThreshold);
    } else {
      clearTimeout(this.timer);
      const pressDuration = Date.now() - this.pressStartTime;
      if (pressDuration < this.pressDurationThreshold) {
        this.dialogRef = this.dialogService.open(alertSOSTemplateRef, {
          context: {},
        });
      }
      this.isSOSButtonPressed = false;
    }
  }

  callSOSApi(alertSOSTemplateRef: TemplateRef<any>) {
    this.spinnerService.show();
    this.profileService
      .updateUser({}, { send_sos: 1 })
      .subscribe((response: any) => {
        if (response?.status == 'success') {
          this.spinnerService.hide();
          this.dialogRef = this.dialogService.open(alertSOSTemplateRef, {
            context: {},
          });
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response?.message,
          });
          this.spinnerService.hide();
        }
      });
  }
  async checkCameraPermission() {
    try {
      if (!navigator.permissions) {
        // Permissions API not supported
        return false;
      }

      const permission = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      });

      return Boolean(
        !['granted', 'prompt'].includes(permission.state) &&
          this.currentDevice.device !== 'iPhone'
      );
    } catch (error) {
      console.log('Error checking camera permission:', error);
      return false;
    }
  }
  hasCamera(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        resolve(false);
        return;
      }

      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const videoInputDevices = devices.filter(
            (device) => device.kind === 'videoinput'
          );
          resolve(videoInputDevices.length > 0);
        })
        .catch((err) => {
          console.error('Error accessing media devices.', err);
          resolve(false);
        });
    });
  }
}
