import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../../admin.service';

import { formatDate } from '@angular/common';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PagesService } from 'src/app/pages/pages.service';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { subscriberStatus } from 'src/global.variables';

@Component({
  selector: 'app-view-subscribers',
  templateUrl: './view-subscriber-details.component.html',
  styleUrls: ['./view-subscriber-details.component.scss'],
})
export class ViewSubscribersDetailsComponent implements OnInit {
  @ViewChild('microTransactionTemplateRef')
  microTransactionTemplateRef!: TemplateRef<any>;

  @Input() subscriberData: any;

  transactionHeaders = [
    {
      name: 'updated_at',
      header: 'Date',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      dateTimeFormat: 'medium',
      sortKey: 'updated_at',
    },
    {
      name: 'client',
      header: 'Client',
      sortKey: 'client',
      searchKey: 'client',
    },
    {
      name: 'user_name',
      header: 'Actioned By',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
    {
      name: 'is_offline',
      header: 'Oflline Event',
      sortKey: 'is_offline',
      searchKey: 'is_offline',
    },
  ];

  subscriberUserTable = [
    {
      header: 'Name',

      nestedValue: (row: any) => {
        if (row?.['full_name']) return row?.['full_name'];
        else return '[USER NOT SIGNED-IN YET]';
      },
    },
    {
      header: 'Email',
      name: 'email',
    },

    {
      header: 'Group',
      name: 'user_groups',
      cardRowStyle: {
        'font-weight': 'bold',
      },
    },
  ];
  managerNotesTable = [
    {
      name: 'site_name',
      header: 'Site',
      sortKey: 'site_name',
      searchKey: 'site_name',
    },
    {
      name: 'company_name',
      header: 'Client',

      searchKey: 'company_name',
      sortKey: 'company_name',
    },
    {
      name: 'manager_notes',
      header: 'Notes',
      searchKey: 'manager_notes',
      sortKey: 'manager_notes',
    },
  ];
  microTransactionTableData: any = [];
  lockedPlanTableColumn = [
    { header: 'Name', name: 'name' },
    {
      header: 'Amount',
      nestedValue: (row: any) => {
        return `${row?.['amount']} ${row?.['currency']}`;
      },
    },
    { header: 'Trial Days', name: 'trial_days' },
    { header: 'Trial Users', name: 'trial_users_allowed' },
    { header: 'Alwd Users', name: 'allowed_users' },
    { header: 'User Price', name: 'user_cost' },
    { header: 'Alwd Trn', name: 'allowed_transactions' },
    { header: 'Trn  Price', name: 'transaction_price' },
    {
      header: 'Plan Type',
      nestedValue: (row: any) => {
        return row?.plan_type?.name;
      },
    },
    {
      header: 'Plan level',
      nestedValue: (row: any) => {
        return row?.['plan_level']?.['name'];
      },
    },
    {
      header: 'Plan Interval',
      nestedValue: (row: any) => {
        return row?.['plan_interval']?.['name'];
      },
    },
  ];

  dialogref: any;
  subscriberTab: boolean = true;
  //pagination for routes

  walletHeaders = [
    {
      header: 'Amount',
      name: 'amount',
    },
    {
      header: 'Transaction Type',
      nestedValue: (row: any) => {
        let tTypes: any = { D: 'Debit', C: 'Credit' };
        if (row.hasOwnProperty('type') && tTypes.hasOwnProperty(row.type)) {
          return tTypes[row['type']];
        }
        return null;
      },
    },
    {
      header: 'Description',
      name: 'description',
    },
    {
      header: 'Updated On',
      name: 'date',
      datetimeObj: true,
    },
    {
      header: 'User',
      nestedValue: (row: any) => {
        return row?.['transacted_by']?.['full_name'];
      },
    },
  ];
  dailyTtransactionHeaders = [
    {
      header: 'Date',
      name: 'date',
    },
    {
      header: 'Transactions',
      name: 'transaction_count',
    },
    {
      header: 'Rate',
      name: 'price',
      suffix: ' c',
    },
    {
      header: 'Sub Total',
      name: 'sub_total',
      suffix: ' AUD',
    },
  ];
  otherParamsSubscriptions: any = {
    paginationData: true,
    tableRowStyle: (rowData: any) => {
      if (!rowData.recurring_plan) {
        return {
          opacity: '0.7',
        };
      }
      return {};
    },
  };

  subscriberSubscriptions: any = [];
  pastSubscriberSubscriptions: any = [];
  subscriptionTableDetail: any = [
    {
      header: 'Started On',
      name: 'subscription_date',
      datetimeObj: true,
      dateTimeFormat: 'd MMM y',
      conditionalColumnStyle: (rowData: any) => {
        if (rowData?.is_active) {
          return {
            'background-color': 'var(--color-success-500)',
            'color': 'var(--color-basic-100)',
          };
        }
        return {};
      },
    },
    {
      header: 'Ended On',
      name: 'subscription_end',
      datetimeObj: true,
      dateTimeFormat: 'd MMM y',
    },
    {
      header: 'Plan Name',
      nestedValue: (rowData: any) => {
        return rowData?.subscription_plan?.name;
      },
    },
    {
      header: 'Amount',
      nestedValue: (rowData: any) => {
        return rowData?.coupon_info?.payable_amount
          ? rowData?.coupon_info?.payable_amount
          : rowData?.subscription_plan?.amount;
      },
    },
    {
      header: 'Cur. Amount',
      nestedValue: (rowData: any) => {
        return rowData?.subscription_plan?.app_subscription_plan?.amount;
      },
      tableColumnStyle: {
        'background-color': 'var(--color-danger-500)',
        'color': 'var(--color-basic-100)',
      },
    },
    {
      header: 'Alwd Trns',
      nestedValue: (rowData: any) => {
        return rowData?.subscription_plan?.allowed_transactions;
      },
    },
    {
      header: 'Cur. Alwd Trns',
      nestedValue: (rowData: any) => {
        return rowData?.subscription_plan?.app_subscription_plan
          ?.allowed_transactions;
      },
      tableColumnStyle: {
        'background-color': 'var(--color-danger-500)',
        'color': 'var(--color-basic-100)',
      },
    },
    {
      header: 'Trn Price ',
      nestedValue: (rowData: any) => {
        return rowData?.subscription_plan?.transaction_price;
      },
    },

    {
      header: 'Cur. Trn Price ',
      nestedValue: (rowData: any) => {
        return rowData?.subscription_plan?.app_subscription_plan
          ?.transaction_price;
      },
      tableColumnStyle: {
        'background-color': 'var(--color-danger-500)',
        'color': 'var(--color-basic-100)',
      },
    },
    {
      header: 'Alwd Guards',
      nestedValue: (rowData: any) => {
        return rowData?.subscription_plan?.allowed_users;
      },
    },
    {
      header: 'Cur. Alwd Guards',
      nestedValue: (rowData: any) => {
        return rowData?.subscription_plan?.app_subscription_plan?.allowed_users;
      },
      tableColumnStyle: {
        'background-color': 'var(--color-danger-500)',
        'color': 'var(--color-basic-100)',
      },
    },
    {
      header: 'Guard Price ',
      nestedValue: (rowData: any) => {
        return rowData?.subscription_plan?.user_cost;
      },
    },
    {
      header: 'Cur. Guard Price ',
      nestedValue: (rowData: any) => {
        return rowData?.subscription_plan?.app_subscription_plan?.user_cost;
      },
      tableColumnStyle: {
        'background-color': 'var(--color-danger-500)',
        'color': 'var(--color-basic-100)',
      },
    },
    {
      header: 'Total Slots',
      nestedValue: (rowData: any) => {
        return rowData?.allotted_slots;
      },
      tableColumnStyle: {
        'background-color': 'var(--color-info-500)',
        'color': 'var(--color-basic-100)',
      },
    },

    {
      header: 'Coupon End Day',
      nestedValue: (rowData: any) => {
        if (rowData?.coupon_info?.subscription_end_date) {
          return rowData?.coupon_info?.subscription_end_date === 'Unlimited'
            ? 'Unlimited'
            : String(
                formatDate(
                  rowData?.coupon_info?.subscription_end_date,
                  'd MMM y',
                  'en_US'
                )
              );
        }

        return;
      },
    },

    {
      header: 'Forced Reset Date',
      nestedValue: (rowData: any) => {
        return rowData?.subscription_plan?.reset_date;
      },
      datetimeObj: true,
      dateTimeFormat: 'd MMM y',
    },
  ];

  subscriptionPlanTab: boolean = false;

  subscriberTransactions: any;
  dateRange: any;
  walletTransactions: any;
  expiryTime: any;
  changeReason: any;

  subscriberUsers: any = [];

  subscriberId: any;

  trialChangeForm: UntypedFormGroup = new UntypedFormGroup({
    trial_end: new UntypedFormControl(''),
    reason: new UntypedFormControl('', Validators.required),
  });

  //wallet amount change
  subscriberWalletForm: UntypedFormGroup = new UntypedFormGroup({
    balance: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl('', Validators.required),
    id: new UntypedFormControl(''),
  });

  individualMicroTransactions: any;
  managerNotes: any;

  constructor(
    private spinnerService: NgxSpinnerService,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private pageService: PagesService
  ) {
    this.route.params.subscribe({
      next: (params) => {
        if (params?.['sKey']) {
          this.subscriberId = params?.['sKey'];
          this.getSubscriberData();
        }
      },
    });
  }

  ngOnInit(): void {}
  getSubscriberData() {
    this.spinnerService.show();
    this.pastSubscriberSubscriptions = [];
    this.subscriberSubscriptions = [];
    this.adminService
      .getSubscriberDataById({}, this.subscriberId)
      .subscribe((response: any) => {
        this.subscriberData = response;
        const statusDict: any = subscriberStatus.statusValues;
        if (
          statusDict.hasOwnProperty(this.subscriberData?.status) &&
          this.subscriberData?.status
        ) {
          this.subscriberData.accountStatus =
            statusDict[this.subscriberData.status];
        }

        const statusStyleDict: any = subscriberStatus.statusStyles;

        if (
          statusStyleDict.hasOwnProperty(this.subscriberData?.status) &&
          this.subscriberData?.status
        ) {
          this.subscriberData.statusStyle = {
            ...statusStyleDict[this.subscriberData?.status],
            'width': '100%',
            'height': '3rem',
            'display': 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'font-weight': 'bolder',
            'font-size': '1.5rem',
          };
        }

        this.subscriberTransactions = response?.transactions;

        this.getMicroTransactionsChartData(response?.transactions);
        // this.trialChangeForm.controls['trial_end'].setValue(
        //   response?.trial_end_validity
        // );
        this.walletTransactions = response?.wallet_transactions;
        this.subscriberUsers = response?.users;
        this.managerNotes = response?.manager_notes;
        this.subscriberSubscriptions = response?.subscriptions?.filter(
          (subscription: any) =>
            new Date(subscription?.subscription_end).getTime() >=
            new Date().getTime()
        );
        this.pastSubscriberSubscriptions = response?.subscriptions?.filter(
          (subscription: any) =>
            new Date(subscription?.subscription_end).getTime() <
            new Date().getTime()
        );

        this.spinnerService.hide();
      });
  }

  openTemplate(template: TemplateRef<any>, data?: any) {
    {
      this.dialogref = this.dialogService.open(template, {
        context: { data: data },
      });
    }
  }

  changeTab(event: any) {
    this.subscriptionPlanTab = true;
  }

  expandColumns(data: any) {
    data.expanded = !data.expanded;
  }
  getSubscriberTransactions() {
    this.spinnerService.show();

    let reuestData: any = {};
    if (this.dateRange) {
      reuestData.date_range = this.dateRange;
    }
    this.adminService
      .getTransactions(this.subscriberData?.id, reuestData, {})
      .subscribe((response: any) => {
        if (response['status'] === 'success') {
          this.subscriberTransactions = response?.data;
          this.getMicroTransactionsChartData(response?.data);

          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      });
  }
  handleDateChange(event: any) {
    if (event?.end) {
      this.dateRange = event;
      this.getSubscriberTransactions();
    }
  }

  getMicroTransactionsChartData(subscriberTransactions: any) {
    this.microTransactionTableData = [];
    subscriberTransactions.forEach((item: any) => {
      this.microTransactionTableData.push({
        name:
          item?.name === 'created'
            ? 'Job Created'.toUpperCase()
            : item?.name?.toUpperCase(),
        count: item?.data?.length,
      });
    });
  }
  changeTrial() {
    if (this.trialChangeForm.valid) {
      this.spinnerService.show();
      this.adminService
        .changeSubscriberTrial({
          ...this.trialChangeForm.value,
          subscriber_id: this.subscriberData?.id,
        })
        .subscribe((response: any) => {
          this.spinnerService.hide();
          if (response?.status === 'success') {
            this.pageService.setMessage({
              successMessage: 'Updated Successfully',
              errorMessage: '',
            });
          } else {
            this.pageService.setMessage({
              successMessage: '',
              errorMessage: response?.message,
            });
          }
          window.location.reload();
        });
    }
  }

  openWalletTemplate(tempalate: TemplateRef<any>) {
    this.subscriberWalletForm.controls['id'].setValue(this.subscriberData?.id);
    this.dialogref = this.dialogService.open(tempalate, {
      context: {},
      dialogClass: 'model-full',
    });
  }
  onWalletUpdate() {
    if (this.subscriberWalletForm.valid) {
      this.spinnerService.show();
      let requestData = this.subscriberWalletForm.value;
      this.adminService
        .updateSubscriber(requestData)
        .subscribe((response: any) => {
          this.dialogref.close();
          this.spinnerService.hide();
          this.getSubscriberData();
        });
    }
  }
  dateTimeCheck(endTime: any) {
    let trialEndDate = new Date(endTime);
    let currentDateTime = new Date();
    return trialEndDate.getTime() < currentDateTime.getTime() ? false : true;
  }
  openDetailedTransactionView(tranType: string) {
    let _tranType = tranType === 'JOB CREATED' ? 'created' : tranType;
    let transactionData = this.subscriberTransactions.filter(
      (_type: any) => _type?.name?.toLowerCase() === _tranType?.toLowerCase()
    );

    if (transactionData?.length > 0 && transactionData[0]?.data) {
      this.individualMicroTransactions = transactionData[0]?.data;
      this.individualMicroTransactions.sort(
        (a: any, b: any) =>
          Number(new Date(a?.updated_at)) - Number(new Date(b?.updated_at))
      );

      this.dialogref = this.dialogService.open(
        this.microTransactionTemplateRef,
        {
          context: { header: tranType },
          dialogClass: 'model-full',
        }
      );
    }
  }
  forceResetSubscription(subscriptionData: any, tempalate: TemplateRef<any>) {
    if (subscriptionData?.recurring_plan) {
      this.dialogref = this.dialogService.open(tempalate, {
        context: subscriptionData,
        dialogClass: 'model-full',
      });
    }
  }
  forceResetDate(date: any, data: any) {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: 'Force Reset Subscription',
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.spinnerService.show();
        this.adminService
          .modifySubscription({
            reset_date: date,
            subscription_plan_id: data?.subscription_plan?.id,
          })
          .subscribe((response: any) => {
            if (response?.status == 'success') {
              this.spinnerService.hide();
              this.getSubscriberData();
              this.pageService.setMessage({
                successMessage: response?.message,
                errorMessage: '',
              });
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response?.message,
              });
              this.spinnerService.hide();
            }
          });
      }
    });
  }
  disableEnableSubscriber() {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: `${
          this.subscriberData?.is_active ? 'Disable' : 'Enable'
        } Subscriber`,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.spinnerService.show();

        this.adminService
          .updateSubscriber(this.subscriberData, { enable_disable: 1 })
          .subscribe((response: any) => {
            this.spinnerService.hide();
            this.getSubscriberData();
          });
      }
    });
  }
  lockUnlockAccount() {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: `Are you sure`,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.spinnerService.show();

        this.adminService
          .updateSubscriber(this.subscriberData, { lock_unlock: 1 })
          .subscribe((response: any) => {
            this.spinnerService.hide();
            this.getSubscriberData();
          });
      }
    });
  }
  sendWeeklyReport() {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: `Are you sure`,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.spinnerService.show();

        this.adminService
          .updateSubscriber(this.subscriberData, { send_weekly_report: 1 })
          .subscribe((response: any) => {
            if (response?.status === 'success')
              this.pageService.setMessage({
                successMessage: response?.message,
                errorMessage: '',
              });

            this.spinnerService.hide();
            this.getSubscriberData();
          });
      }
    });
  }
}
