<nb-card class="dialog-box">
  <nb-card-header>
    {{ scheduleData ? "Edit" : "Add" }} Patrol Report
    <nb-icon
      (click)="onCloseDialogue()"
      [icon]="'close-outline'"
      class="sc-floating-right close-icon"
      pack="eva"
    ></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <div class="form-floating">
      <input
        nbInput
        class="form-control"
        [(ngModel)]="reportName"
        fullWidth
        type="text"
        placeholder="Description"
        required
        id="description"
      />
      <label>Description</label>
    </div>

    <!-- <div class="form-floating">
      <input
        nbInput
        class="form-control"
        [(ngModel)]="dynamicLastDays"
        fullWidth
        type="number"
        placeholder="Last {{ dynamicLastDays }} Days"
      />
      <label>{{
        dynamicLastDays
          ? "Last " + dynamicLastDays + " Days"
          : "Dynamic day range"
      }}</label>
    </div> -->

    <div class="form-floating">
      <input
        class="form-control"
        [disabled]="disableInputField"
        type="text"
        nbTagInput
        fullWidth
        placeholder="Send Report To"
        (input)="getUsers($event)"
        [nbAutocomplete]="autoNgModel"
      />
      <label>Send Reports To</label>
    </div>
    <nb-autocomplete #autoNgModel>
      <nb-option *ngIf="isAdmin" (click)="addToContactsTemp()">
        Add New Contact
      </nb-option>

      <nb-option *ngFor="let user of usersList" (click)="addUser(user)"
        ><div>
          {{ user?.user_full_name
          }}<nb-badge
            *ngIf="user?.location_contact_id"
            text="Onsite Contact"
            class="extension-badge"
          >
          </nb-badge>
          <div>
            <small>
              {{ user?.user_full_name ? user.user_email : "" }}
              {{
                user?.user_mobile_number && user?.user_mobile_number !== "0"
                  ? " , " + user?.user_mobile_number
                  : ""
              }}
            </small>
          </div>
        </div>
      </nb-option></nb-autocomplete
    >

    <nb-tag-list class="nb-tag-list" *ngIf="selectedUsers?.length">
      <nb-tag
        *ngFor="let user of selectedUsers"
        [text]="user.full_name"
        removable
        (remove)="removeUser(user)"
        (click)="
          user?.is_only_location_contact === true
            ? openUserTemplate(user)
            : openUserDialogBox(user)
        "
      ></nb-tag>
    </nb-tag-list>
    <hr />
    <!-- <div>
      <b>Scheduler</b>
      <div class="sc-floating-right">
        <strong>{{ selectedRepeatType | titlecase }}</strong>
        <a *ngIf="showRecurringView == false" (click)="makeRecuringView()">
          <nb-icon [icon]="'repeat-outline'" pack="eva"></nb-icon>
          Repeat
        </a>
        <a *ngIf="showRecurringView == true" (click)="cancelRecuringView()">
          <nb-icon [icon]="'repeat-outline'" pack="eva"></nb-icon>
          Cancel
        </a>
      </div>
    </div> -->

    <div class="strong">Send Report</div>
    <form [formGroup]="schedulerForm" appFormEnterAction>
      <div>
        <nb-radio-group
          formControlName="repeat_type"
          name="ng-model-group"
          style="display: flex; justify-content: center"
        >
          <nb-radio *ngFor="let option of repeatTypes" [value]="option.value">
            {{ option.label }}
          </nb-radio>
        </nb-radio-group>
      </div>

      <div class="form-floating">
        <input
          class="form-control"
          nbInput
          type="text"
          fullWidth
          formControlName="mail_time"
          [ngxMatTimepicker]="timePicker1"
          readonly
        />
        <label>Time of Day</label>
        <!-- <nb-timepicker
          #timepicker
          singleColumn="true"
          twelveHoursFormat="true"
          (onSelectTime)="formatTime($event)"
        ></nb-timepicker> -->
        <ngx-mat-timepicker
          (timeSet)="formatTime($event)"
          #timePicker1
          [hoursOnly]="true"
        ></ngx-mat-timepicker>
      </div>
      <div class="form-floating" *ngIf="!scheduleData">
        <input
          class="form-control"
          nbInput
          fullWidth
          placeholder="Start Date "
          [nbDatepicker]="dateTimePicker0"
          [attr.disabled]="scheduleData"
          formControlName="start_day"
        />
        <nb-datepicker #dateTimePicker0 [min]="startDate"></nb-datepicker>
        <label>Start Date</label>
      </div>

      <div class="sc-mt-6" *ngIf="!scheduleData">
        <button
          [disabled]="
            !schedulerForm.valid || !reportName || !selectedUsers?.length
          "
          nbButton
          size="giant"
          fullWidth
          (click)="saveSchedule()"
          class="nbbutton-long-text"
        >
          Add Report Schedule
        </button>
      </div>
      <div class="sc-mt-6 row row-cols-2" *ngIf="scheduleData">
        <div class="col">
          <button
            nbButton
            size="giant"
            fullWidth
            ghost
            (click)="deleteSchdeule()"
          >
            Delete
          </button>
        </div>
        <div class="col">
          <button
            [disabled]="
              !schedulerForm.valid || !reportName || !selectedUsers?.length
            "
            nbButton
            size="giant"
            fullWidth
            class="nbbutton-long-text"
            (click)="updateSchedule()"
          >
            Update Schedule
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
