import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent implements OnInit {
  constructor(private router: Router) {
    if (localStorage.getItem('from') !== 'deletion') {
      this.router.navigate(['/404']);
    }
    localStorage.clear();
  }

  ngOnInit(): void {}
}
