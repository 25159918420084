<nb-card>
  <nb-card-body>
    <nb-alert *ngIf="nbAlertMessages?.length" status="danger">
      <div>
        <nb-icon
          [icon]="'close-outline'"
          class="sc-floating-right close-icon"
          pack="eva"
          (click)="nbAlertMessages = []"
        >
        </nb-icon>

        {{ nbAlertMessages?.length }} saved reminder
        {{ nbAlertMessages?.length > 1 ? "s" : "" }} requires your urgent
        attention
      </div>
    </nb-alert>
  </nb-card-body>

  <nb-card-body>
    <nb-tabset (changeTab)="tst($event)">
      <nb-tab tabTitle="Failed Events">
        <app-gt-table
          [desktopTableBackendData]="errorLogData"
          [desktopTableDetails]="errorLogColumns"
          [otherParams]="otherParamsErrorLog"
          (paginationChange)="onChangePaginationErrorLog($event)"
        >
        </app-gt-table>

        <div class="mt-5" *ngIf="failedReportsData?.length">
          <div class="fw-bold">Failed Reports</div>
          <app-gt-table
            [desktopTableBackendData]="failedReportsData"
            [desktopTableDetails]="failedReportColumns"
            [otherParams]="otherParamsFailedReports"
            (paginationChange)="onChangePaginationFailedReports($event)"
            (rowClick)="retrySendingFailedReport($event)"
          >
          </app-gt-table>
        </div>
      </nb-tab>

      <nb-tab tabTitle="{{ bigTabs.tab4.title }}">
        <div>
          <app-gt-table
            [desktopTableBackendData]="scheduleData"
            [desktopTableDetails]="schdeuleDataTableDetail"
            [otherParams]="otherParamsSchedule"
            (paginationChange)="onChangePaginationSchedules($event)"
            (sortColumn)="sortColumnSchedules($event)"
            (searchColumn)="searchColumnSchedules($event)"
          >
          </app-gt-table>
        </div>
      </nb-tab>
      <nb-tab tabTitle="{{ bigTabs.tab5.title }}">
        <div>
          <button size="giant" nbButton (click)="openTemplate(addReminderDBox)">
            Add Reminder
          </button>
        </div>
        <div>
          <app-gt-table
            [desktopTableBackendData]="reminderData?.results"
            [desktopTableDetails]="reminderDetailsTableView"
            (rowClick)="onReminderClick($event, addReminderDBox)"
            (sortColumn)="sortColumn($event)"
            (searchColumn)="searchColumn($event)"
          >
          </app-gt-table>
        </div>
        <div class="sc-mt-9">
          <nb-alert> Recipients : {{ reminderData?.recipients }} </nb-alert>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Users">
        <div *ngIf="showUserChart">
          <app-gt-table
            [desktopTableUIData]="userTableData"
            [desktopTableDetails]="userTableDetail"
            [otherParams]="{ paginationData: true }"
          >
          </app-gt-table>
        </div>
        <div class="sc-text-center" *ngIf="showUserChart">
          <highcharts-chart
            [Highcharts]="dashBoardHighcharts"
            [options]="pieChartOptions"
            style="width: 100%; height: 400px; display: block"
          >
          </highcharts-chart>
        </div>
      </nb-tab>
      <nb-tab tabTitle="{{ bigTabs.tab6.title }}">
        <div class="sc-head5">
          AWS Details:<span class="sc-floating-right"
            ><nb-toggle
              status="primary"
              toggle-width="1.25"
              toggle-height="0.25"
              (checkedChange)="
                showAwsChart = !showAwsChart;
                showDailyUsageChart = !showDailyUsageChart
              "
              >Show Chart</nb-toggle
            ></span
          >
        </div>
        <div *ngIf="showAwsChart == false">
          <app-gt-table
            [desktopTableUIData]="awsData?.aws_daily"
            [desktopTableDetails]="[
              { header: 'Day', name: 'date' },
              { header: 'Storage', name: 'storage_used' }
            ]"
            [otherParams]="{ paginationData: true }"
          >
          </app-gt-table>
          <div class="mt-3">
            <app-gt-table
              [desktopTableUIData]="awsData?.aws_weekly"
              [desktopTableDetails]="[
                { header: 'Week', name: 'week' },
                { header: 'Storage', name: 'storage_used' }
              ]"
              [otherParams]="{ paginationData: true }"
            >
            </app-gt-table>
          </div>
        </div>

        <div *ngIf="showAwsChart == true">
          <highcharts-chart
            *ngIf="showDailyUsageChart"
            [Highcharts]="dashBoardHighcharts"
            [options]="awsDailyStorageOptons"
            style="width: 100%; height: 400px; display: block"
          >
          </highcharts-chart>
          <div class="sc-mt-6">
            <app-pagination-control
              [pageSize]="dailyStoragePageSize"
              [pageNum]="dailyStoragePageNum"
              [previous]="dailyStoragePrevious"
              [totalRows]="dailyStorageTotalRows"
              [totalPages]="dailyStorageTotalPages"
              (onClickPagination)="onClickPaginationDailyStorage($event)"
              (onChangePagination)="onClickPaginationDailyStorage($event)"
            >
            </app-pagination-control>
          </div>

          <highcharts-chart
            [Highcharts]="dashBoardHighcharts"
            [options]="awsWeeklyStorageOptons"
            style="width: 100%; height: 400px; display: block"
          >
          </highcharts-chart>
          <highcharts-chart
            [Highcharts]="dashBoardHighcharts"
            [options]="awsChartOptons"
            style="width: 100%; height: 400px; display: block"
          >
          </highcharts-chart>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Statistics">
        <div class="sc-text-center" *ngIf="showBarChart">
          <highcharts-chart
            [Highcharts]="dashBoardHighcharts"
            [options]="barChartOptons"
            style="width: 100%; height: 400px; display: block"
          >
          </highcharts-chart>
        </div>
        <div class="sc-text-center sc-mt-9" *ngIf="showBarChart">
          <highcharts-chart
            [Highcharts]="dashBoardHighcharts"
            [options]="fileUploadChart"
            style="width: 100%; height: 400px; display: block"
          >
          </highcharts-chart>
        </div>
      </nb-tab>

      <nb-tab tabTitle="Jobs">
        <div class="sc-text-center" *ngIf="showJobChart">
          <highcharts-chart
            [Highcharts]="dashBoardHighcharts"
            [options]="pieChartOptions"
            style="width: 100%; height: 800px; display: block"
          >
          </highcharts-chart>
        </div>
      </nb-tab>

      <nb-tab tabTitle="Transactions">
        <div class="sc-text-center" *ngIf="showTransactionChart">
          <highcharts-chart
            [Highcharts]="dashBoardHighcharts"
            [options]="dailyTransactionChartOptions"
            style="width: 100%; height: 400px; display: block"
          >
          </highcharts-chart>
        </div>
      </nb-tab>

      <nb-tab tabTitle="{{ bigTabs.tab1.title }}">
        <ng-container *ngIf="bigTabs.tab1.showTab">
          <app-payments></app-payments>
        </ng-container>
      </nb-tab>

      <nb-tab tabTitle="{{ bigTabs.tab2.title }}">
        <ng-container *ngIf="bigTabs.tab2.showTab">
          <app-analytics></app-analytics>
        </ng-container>
      </nb-tab>
      <nb-tab tabTitle="{{ bigTabs.tab3.title }}">
        <ng-container *ngIf="bigTabs.tab3.showTab">
          <app-event-logs></app-event-logs>
        </ng-container>
      </nb-tab>
      <nb-tab tabTitle="{{ bigTabs.tab7.title }}">
        <div>
          <app-gt-table
            [desktopTableBackendData]="uploadedReportData"
            [desktopTableDetails]="uploadedReportTableDetail"
            [otherParams]="otherParamsUploadedReports"
            (paginationChange)="onChangePaginationUploadedReports($event)"
            (sortColumn)="sortColumnUploadedReports($event)"
            (searchColumn)="searchColumnUploadedReports($event)"
          >
          </app-gt-table>
        </div>
      </nb-tab>
      <nb-tab tabTitle="{{ bigTabs.tab8.title }}">
        <div>
          <app-gt-table
            [desktopTableUIData]="cancelledReportData"
            [desktopTableDetails]="cancelledReportTableDetail"
            [otherParams]="{ paginationData: true }"
          >
          </app-gt-table>
        </div>

        <div>
          <app-gt-table
            [desktopTableUIData]="cancelledReportTimeFrameData?.month"
            [desktopTableDetails]="cancelledReportTimeFrameTableDetail?.month"
            [otherParams]="{ paginationData: true }"
          >
          </app-gt-table>
        </div>

        <div>
          <app-gt-table
            [desktopTableUIData]="cancelledReportTimeFrameData?.year"
            [desktopTableDetails]="cancelledReportTimeFrameTableDetail?.year"
            [otherParams]="{ paginationData: true }"
          >
          </app-gt-table>
        </div>
      </nb-tab>
      ,<nb-tab tabTitle="{{ bigTabs.tab9.title }}">
        <ng-container *ngIf="bigTabs.tab9.showTab">
          <app-admin-report></app-admin-report>
        </ng-container>
      </nb-tab>
    </nb-tabset>
  </nb-card-body>
</nb-card>

<ng-template #addReminderDBox let-arRef="dialogRef">
  <nb-card class="dialog-box gt-p-1">
    <form [formGroup]="reminderForm">
      <div class="form-floating">
        <input
          class="form-control"
          fullWidth
          formControlName="name"
          nbInput
          type="text"
          placeholder="Reminder Name"
        />
        <label>Reminder Name</label>
      </div>

      <div class="sc-mt-6">
        <textarea
          class="form-control"
          fullWidth
          type="text"
          placeholder="Reminder Description..."
          formControlName="description"
          rows="5"
        ></textarea>
      </div>
      <div class="row row-cols-2">
        <div class="col">
          <div class="form-floating sc-mt-6">
            <input
              fullWidth
              class="form-control"
              nbInput
              placeholder="Pick Date"
              [nbDatepicker]="dateTimePicker"
              formControlName="expiring_time"
            />
            <nb-datepicker #dateTimePicker></nb-datepicker>
          </div>
        </div>
        <div class="col m-auto sc-text-center">
          <nb-checkbox formControlName="send_warning">
            Send Mail Report
          </nb-checkbox>
        </div>
      </div>

      <div [ngClass]="selectedReminder ? 'row row-cols-3' : 'row row-cols-2'">
        <div class="col">
          <button size="giant" ghost nbButton fullWidth (click)="arRef.close()">
            Close
          </button>
        </div>
        <div class="col" *ngIf="selectedReminder">
          <button
            size="giant"
            ghost
            nbButton
            fullWidth
            (click)="deleteReminder(selectedReminder); arRef.close()"
          >
            Delete
          </button>
        </div>
        <div class="col">
          <button
            size="giant"
            nbButton
            fullWidth
            (click)="addReminder(); arRef.close()"
            [disabled]="!reminderForm.valid"
          >
            {{ selectedReminder ? "Edit" : "Add" }}
          </button>
        </div>
      </div>
    </form>
  </nb-card>
</ng-template>
