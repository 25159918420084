<div>
  <app-table
    [desktopTableData]="eventData"
    [desktopTableDetails]="eventsTableView"
    (rowClick)="oneventClick($event)"
  ></app-table>
</div>
<!-- Pagination section begin -->
<div class="sc-mt-9">
  <app-pagination-control
    [pageSize]="rows"
    [pageNum]="pageNum"
    [previous]="previous"
    [totalRows]="totalRows"
    [totalPages]="totalPages"
    (onClickPagination)="onClickPagination($event)"
    (onChangePagination)="onChangePagination($event)"
  >
  </app-pagination-control>
</div>
<!-- DEVICE DETAILS -->
<div *ngIf="selectedEvent?.event_name === 'FAILED REPORT'">
  <div class="badge-key">Report Name</div>
  <div class="badge-value">{{ selectedEvent?.event_key }}</div>
</div>
<div class="row row-cols-2 sc-mt-9" *ngIf="selectedEvent?.device">
  <div class="col">
    <div class="gt-event-details">
      <div class="row row cols-2">
        <div class="col-3"><small>Browser</small></div>
        <div class="col-9">
          {{ selectedEvent?.device?.browser }} -
          {{ selectedEvent?.device?.browser_version }}
        </div>
        <div class="col-3"><small>Browser</small></div>
        <div class="col-9">
          {{ selectedEvent?.device?.device_type }} -
          {{ selectedEvent?.device?.device_os }}
          {{
            selectedEvent?.device?.device !== "Unknown"
              ? selectedEvent?.device?.device
              : ""
          }}
        </div>
        <div class="col-3"><small>Ip</small></div>
        <div class="col-9">{{ selectedEvent?.device?.ip }}</div>
      </div>
    </div>
    <div
      class="gt-event-details"
      *ngIf="
        !['SUBSCRIBER SUBSCRIPTION PLAN', 'FAILED REPORT'].includes(
          selectedEvent?.event_name
        )
      "
    >
      <div *ngFor="let item of selectedEvent?.event_changes | keyvalue">
        <div *ngIf="item?.value" class="row row-cols-2">
          <div class="col-4">
            <small>{{ item.key }}</small>
          </div>
          <div class="col-8">
            <strong
              ><small> {{ item.value }}</small>
            </strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <app-map-leaflet
      [latLon]="{
        lat: selectedEvent?.device?.lat,
        lon: selectedEvent?.device?.lon
      }"
    ></app-map-leaflet>
  </div>
</div>

<table
  class="sc-mt-9"
  *ngIf="selectedEvent?.event_name === 'SUBSCRIBER SUBSCRIPTION PLAN'"
>
  <thead *ngIf="selectedEvent?.event_changes?.invoices?.length">
    <tr>
      <th>Invoice Amount</th>
      <th>Subscriber</th>
      <th>Registered Email</th>
    </tr>
  </thead>
  <tbody *ngIf="selectedEvent?.event_changes?.invoices?.length">
    <tr *ngFor="let item of selectedEvent?.event_changes?.invoices">
      <td>{{ item?.amount }}</td>
      <td>{{ item?.subscriber_company_name }}</td>
      <td>{{ item?.subscriber_email }}</td>
    </tr>
  </tbody>
</table>
<div class="row row-cols-2">
  <div class="col">
    <table>
      <thead *ngIf="selectedEvent?.event_changes?.subscriptions?.length">
        <tr>
          <th>Subscription Start</th>
          <th>Subscription End</th>
        </tr>
      </thead>
      <tbody *ngIf="selectedEvent?.event_changes?.subscriptions?.length">
        <tr *ngFor="let item of selectedEvent?.event_changes?.subscriptions">
          <td>{{ item?.subscription_date }}</td>
          <td>{{ item?.subscription_end }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col">
    <table>
      <thead *ngIf="selectedEvent?.event_changes?.plans?.length">
        <tr>
          <th>Plan Name</th>
          <th>Plan Amount</th>
        </tr>
      </thead>
      <tbody *ngIf="selectedEvent?.event_changes?.plans?.length">
        <tr *ngFor="let item of selectedEvent?.event_changes?.plans">
          <td>{{ item?.name }}</td>
          <td>{{ item?.amount }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
