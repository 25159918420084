<nb-alert class="mx-2 mb-2" *ngIf="cameraError" outline="danger">
  {{ cameraError }}
</nb-alert>
<ng-container *ngIf="apidataUpdation === true && !offlineMode">
  <nb-alert class="mb-2" outline="basic">
    <small>
      Fetching Data from Server
      <div class="sc-floating-right">
        <div id="wave">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </small>
  </nb-alert>
</ng-container>

<ng-container *ngIf="!detailData && offlineMode">
  <nb-alert class="mb-2" outline="basic">
    <small>Job Data Not Found</small>
  </nb-alert>
</ng-container>
<nb-card *ngIf="detailData">
  <div
    class="status-card mobile-only"
    *ngIf="selectedJobStatus?.status_identity?.value === 4"
  >
    <div class="status-content show">
      <div class="my-1 onsite-time">Onsite: {{ onsiteTimer }}</div>
    </div>
  </div>
  <nb-card-body>
    <!-- MOBILE VIEW -->
    <div class="mobile-only">
      <div
        class="px-2"
        [ngStyle]="
          detailData?.status?.status_identity?.value === 4
            ? { 'padding-top': '2.5rem' }
            : {}
        "
      >
        <nb-alert
          *ngIf="detailData?.status?.status_identity?.value === 1"
          class="my-2 alert-dismissible fade show"
          [ngStyle]="
            detailData?.can_reopen
              ? selectedJobStatus?.additional_info?.style?.snubbed
              : {
                  color: 'var(--color-basic-100)',
                  background: 'var(--color-basic-1100)'
                }
          "
        >
          <div class="strong">
            {{ detailData?.can_reopen ? "JOB COMPLETED" : "JOB CLOSED" }}
          </div>
        </nb-alert>
      </div>
      <!--cancellation reason-->
      <div class="px-1">
        <div
          class="my-2 alert alert-danger alert-dismissible fade show small-font"
          role="alert"
          *ngIf="
            cancelReasonData && detailData?.status?.status_identity?.value === 2
          "
        >
          <div>
            <div>CANCELLED:</div>
            <div class="strong uppercase">
              {{ detailData?.modified_date | date : "d MMM y H:mm" }}
            </div>
          </div>
          <div *ngIf="cancelReasonData?.reason">
            <div>CANCEL REASON:</div>
            <div class="strong">
              {{ cancelReasonData?.reason }}
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div
          class="job-status"
          [style]="selectedJobStatus?.additional_info?.style?.snubbed"
        ></div>
        <div
          class="row p-1 main-section"
          [style.padding-bottom]="
            applyMaxHeight ? bottomSectionFixedHeight + ' !important' : null
          "
        >
          <div>
            <div class="badge-key">Job Type</div>
            <div class="badge-value">
              {{ detailData?.service_type?.name }}
              <span *ngIf="detailData?.response_type?.name"
                >&nbsp;-&nbsp;<span class="strong">{{
                  detailData?.response_type?.name
                }}</span></span
              >
            </div>
          </div>

          <div *ngIf="detailData?.roster_scheduler?.repeat_type">
            <div class="badge-key">Start Date</div>
            <div class="badge-value">
              {{
                detailData?.schedule_event_date
                  ? (detailData?.schedule_event_date | date : "d MMM y")
                  : (detailData?.roster_scheduler?.start_day | date : "d MMM y")
              }}
            </div>

            <div
              class="badge-key"
              (click)="
                offlineMode
                  ? showOfflineMessage()
                  : (isAdmin === true || isDispatchUser === true) &&
                    ![1, 2, 3].includes(
                      detailData?.status?.status_identity?.value
                    ) &&
                    canScheduleBeUpdated
                  ? openScheduleTemplate(changeScheduleTemplateRef)
                  : ''
              "
            >
              Shift [Start - End]
              <nb-icon
                *ngIf="
                  (isAdmin === true || isDispatchUser === true) &&
                  ![1, 2, 3].includes(
                    detailData?.status?.status_identity?.value
                  ) &&
                  canScheduleBeUpdated
                "
                pack="eva"
                icon="edit"
                class="color-basic-text ms-2"
              ></nb-icon>
            </div>
            <div class="badge-value">
              {{ detailData?.roster_scheduler?.start_time }} -
              {{ detailData?.roster_scheduler?.end_time }}
            </div>

            <div>
              <div class="badge-key">Job Duration</div>
              <div class="badge-value">{{ scheduleDuration }} Hours</div>
            </div>

            <div
              *ngIf="
                showWelfareItem &&
                (isAdmin || isDispatchUser || detailData?.interval_check > 0)
              "
            >
              <div
                class="badge-key"
                (click)="
                  offlineMode
                    ? showOfflineMessage()
                    : (isAdmin === true || isDispatchUser === true) &&
                      ![1, 2, 3].includes(
                        detailData?.status?.status_identity?.value
                      )
                    ? openIntervalCheckTemplate(welfareIntervalTemplate)
                    : ''
                "
              >
                Welfare Checks
                <nb-icon
                  *ngIf="isAdmin === true || isDispatchUser === true"
                  pack="eva"
                  icon="edit"
                  class="color-basic-text"
                ></nb-icon>
              </div>
              <div class="badge-value">
                {{ detailData?.interval_check }} mins
              </div>
            </div>
          </div>

          <div>
            <div class="badge-key">Site</div>
            <div class="badge-value strong">
              {{ detailData?.sites[0]?.company_name }}
            </div>
          </div>

          <div>
            <div
              class="badge-key"
              (click)="
                offlineMode ? showOfflineMessage() : redirectToClientPage()
              "
            >
              Client
              <nb-icon
                class="ms-1 color-basic-text"
                nbSuffix
                [icon]="'link-outline'"
                pack="eva"
                *ngIf="!offlineMode"
              >
              </nb-icon>
            </div>
            <div class="badge-value strong">
              {{ detailData?.company?.company_name }}
            </div>
          </div>

          <div
            class="flex-center"
            (click)="
              mapsSelector(
                detailData?.sites[0]?.address?.latitude,
                detailData?.sites[0]?.address?.longitude
              )
            "
          >
            <div>
              <div class="badge-key">Address</div>
              <div class="badge-value strong">
                {{
                  detailData?.sites[0]?.address?.address3
                    ? detailData?.sites[0]?.address?.address3 + " / "
                    : ""
                }}
                {{ detailData?.sites[0]?.address?.address1 }}
                {{ detailData?.sites[0]?.address?.address2 }},
                {{ detailData?.sites[0]?.address?.city_name }}
                {{ detailData?.sites[0]?.address?.state_code }}
                {{ detailData?.sites[0]?.address?.postcode }}
              </div>
            </div>
            <div>
              <nb-icon
                class="big-menu-icon primary-500"
                [icon]="'navigation-outline'"
                pack="eva"
              >
              </nb-icon>
            </div>
          </div>

          <!-- Showing Monitoring Company and Response Type for Response type job i.e type_identity = 0(Response) -->
          <ng-container
            *ngIf="
              (detailData?.monitoring_company?.id ||
                isAdmin === true ||
                isDispatchUser === true) &&
              detailData?.service_type?.type_identity === 0
            "
          >
            <div
              [ngClass]="
                detailData?.monitoring_company?.id &&
                detailData?.status?.status_identity?.value !== 1 &&
                detailData?.status?.status_identity?.value !== 2 &&
                detailData?.status?.status_identity?.value !== 3
                  ? ''
                  : ''
              "
            >
              <div
                class="badge-key"
                (click)="
                  offlineMode
                    ? showOfflineMessage()
                    : !detailData?.monitoring_company?.id &&
                      detailData?.status?.status_identity?.value !== 1 &&
                      detailData?.status?.status_identity?.value !== 2 &&
                      detailData?.status?.status_identity?.value !== 3
                    ? modifyMonitoringCompany(modifyMCTemplate)
                    : ''
                "
              >
                Monitoring Company
                <nb-icon
                  pack="eva"
                  icon="external-link-outline"
                  class="color-basic-text"
                  *ngIf="!detailData?.monitoring_company?.id"
                ></nb-icon>
              </div>
              <div class="badge-value">
                {{
                  detailData?.monitoring_company?.company_name
                    ? detailData?.monitoring_company?.company_name
                    : "N/A"
                }}
              </div>
            </div>
          </ng-container>

          <div
            [ngClass]="isAdmin === true || isDispatchUser === true ? '' : ''"
          >
            <div class="badge-key">Job ID</div>
            <div class="badge-value">{{ detailData?.job_key }}</div>
          </div>

          <div *ngIf="detailData?.service_type?.type_identity === 0">
            <div
              class="badge-key"
              [ngClass]="
                detailData?.status?.status_identity?.value !== 1 &&
                detailData?.status?.status_identity?.value !== 2 &&
                detailData?.status?.status_identity?.value !== 3
                  ? ''
                  : ''
              "
              (click)="
                offlineMode
                  ? showOfflineMessage()
                  : (isAdmin === true || isDispatchUser === true) &&
                    detailData?.status?.status_identity?.value !== 1 &&
                    detailData?.status?.status_identity?.value !== 2 &&
                    detailData?.status?.status_identity?.value !== 3
                  ? modifyExternalJobId(modifyExternalJobIdTemplate)
                  : ''
              "
            >
              External Job ID
              <nb-icon
                pack="eva"
                icon="edit"
                class="color-basic-text"
              ></nb-icon>
            </div>
            <div class="badge-value">
              {{ detailData?.ext_job_id ? detailData?.ext_job_id : "N/A" }}
            </div>
          </div>

          <div *ngIf="isAdmin || isDispatchUser">
            <div
              class="badge-key"
              [ngClass]="
                detailData?.status?.status_identity?.value !== 1 &&
                detailData?.status?.status_identity?.value !== 2 &&
                detailData?.status?.status_identity?.value !== 3
                  ? ''
                  : ''
              "
              (click)="
                offlineMode
                  ? showOfflineMessage()
                  : getSiteContacts(modifySiteContactRef)
              "
            >
              Onsite Contacts

              <div *ngIf="detailData?.location_contacts?.length > 1">
                [{{ detailData?.location_contacts?.length }}]
              </div>
              <nb-icon
                class="color-basic-text"
                [icon]="'external-link-outline'"
                pack="eva"
              ></nb-icon>
            </div>
            <div class="badge-value">
              <div class="row row-cols-auto">
                <div
                  *ngIf="detailData?.location_contacts?.length"
                  class="pointer col"
                  (click)="
                    openSiteContactTemplate(detailData?.location_contacts[0])
                  "
                >
                  {{ detailData?.location_contacts[0]?.full_name }}
                </div>
                <div
                  *ngIf="detailData?.location_contacts?.length > 1"
                  class="col pointer"
                  fullWidth
                  size="tiny"
                  (click)="
                    offlineMode
                      ? showOfflineMessage()
                      : getSiteContacts(modifySiteContactRef)
                  "
                ></div>
              </div>
            </div>
          </div>

          <div *ngIf="isAdmin === true || isDispatchUser === true">
            <div
              class="badge-key pointer"
              [ngClass]="
                detailData?.status?.status_identity?.value !== 1 &&
                detailData?.status?.status_identity?.value !== 2 &&
                detailData?.status?.status_identity?.value !== 3
                  ? ''
                  : ''
              "
              (click)="
                offlineMode
                  ? showOfflineMessage()
                  : modifyAssignees(modifyAssigneeRef)
              "
            >
              {{
                detailData?.assignee?.length > 1
                  ? "Assigned Guards"
                  : "Assigned Guard"
              }}
              <nb-icon
                class="color-basic-text"
                [icon]="'external-link-outline'"
                pack="eva"
              ></nb-icon>
              <div *ngIf="detailData?.assignee?.length > 1">
                [{{ detailData?.assignee?.length }}]
              </div>
            </div>

            <div class="badge-value">
              <div class="row row-cols-auto">
                <div
                  (click)="
                    offlineMode
                      ? showOfflineMessage()
                      : openAssigneeDetail(detailData?.assignee[0])
                  "
                  [ngStyle]="
                    detailData?.assignee[0]?.status == 'ASSIGNED'
                      ? { color: 'var(--text-color-basic)' }
                      : {
                          'color': 'var(--text-danger-color)',
                          'font-weight': '600'
                        }
                  "
                >
                  {{ detailData?.assignee[0]?.full_name }}
                </div>

                <div
                  *ngIf="detailData?.assignee?.length > 1"
                  class="col pointer"
                  fullWidth
                  size="tiny"
                  (click)="
                    offlineMode
                      ? showOfflineMessage()
                      : openAssigneeDetail(detailData?.assignee[0])
                  "
                >
                  <nb-icon
                    nbSuffix
                    class="color-basic-text"
                    [icon]="'expand-outline'"
                    pack="eva"
                  ></nb-icon>
                </div>

                <div
                  (click)="
                    offlineMode
                      ? showOfflineMessage()
                      : modifyAssignees(modifyAssigneeRef)
                  "
                  class="person-list"
                  *ngIf="!detailData?.assignee?.length"
                  style="color: var(--color-danger-500)"
                >
                  Not Assigned
                </div>
              </div>
            </div>
          </div>

          <div
            (click)="
              detailData?.status?.status_identity?.value !== 1 &&
              detailData?.status?.status_identity?.value !== 2 &&
              detailData?.status?.status_identity?.value !== 3
                ? offlineMode
                  ? showOfflineMessage()
                  : modifyRequestDetails(requestRef)
                : ''
            "
          >
            <div class="badge-key">
              Additional Information
              <nb-icon
                pack="eva"
                icon="edit"
                class="color-basic-text"
                *ngIf="isAdmin === true || isDispatchUser === true"
              ></nb-icon>
            </div>
            <div class="badge-value">
              <pre class="request-detail-text"
                >{{
                  detailData?.request_details
                    ? detailData?.request_details
                    : "No details provided"
                }}   </pre
              >
            </div>
          </div>
          <!-- site information section STARTS -->
          <div *ngIf="detailData?.sites[0]?.site_information">
            <div class="badge-key">Site Information</div>
            <div class="badge-value">
              <pre class="request-detail-text"
                >{{
                  detailData?.sites[0]?.site_information
                    ? detailData?.sites[0]?.site_information
                    : "No details provided"
                }}   </pre
              >
            </div>
          </div>

          <!-- site information section ENDS -->

          <!-- site files section STARTS-->
          <div class="sc-mt-6" *ngIf="detailData?.sites[0]?.site_files?.length">
            <div class="gt-form-box-border gt-form-box">
              <div [ngClass]="isAdmin ? 'header pointer' : 'header'">
                Site Files
              </div>
              <div class="row row-cols-1 sc-mt-6">
                <div
                  *ngFor="let file of detailData?.sites[0]?.site_files"
                  class="col item-list pointer"
                >
                  <div class="row row-cols-2">
                    <div
                      class="col-10"
                      (click)="onOpenFile(file, previewTempRef)"
                      style="align-items: center; display: flex"
                    >
                      <nb-badge
                        text="{{ file?.extension }}"
                        class="extension-badge"
                      >
                      </nb-badge>
                      {{ file?.description }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- site files section ENDS-->

          <!-- site keys section STARTS -->
          <div class="sc-mt-6" *ngIf="detailData?.sites[0]?.site_keys?.length">
            <div class="gt-form-box-border gt-form-box">
              <div class="header">Site Key(s)</div>
              <div class="row row-cols-1 sc-mt-6">
                <div
                  *ngFor="let siteKey of detailData?.sites[0]?.site_keys"
                  class="col item-list pointer"
                >
                  <ul class="mb-0 key-list">
                    <li>&nbsp; {{ siteKey }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- site keys section ENDS -->

          <!-- Comments -->
          <ng-container
            *ngIf="detailData?.status?.status_identity?.value !== 1"
          >
            <div
              *ngFor="
                let msg of jobCommentsFiles.slice().reverse();
                let i = index
              "
            >
              <div>
                <!-- timestamp -->
                <div class="chat-info p-1">
                  <span *ngIf="msg?.file">
                    Photo
                    {{
                      msg?.event_action === 19 ? "[Device Library]" : "[Camera]"
                    }}
                    |
                  </span>
                  {{ msg?.updated_at | date : "d MMM y HH:mm" }} |
                  {{
                    subscriberUserId == msg?.subs_user_id
                      ? "You"
                      : msg?.user_name
                  }}
                  <span
                    *ngIf="
                      msg?.history_changes?.length &&
                      (subscriberUserId == msg?.subs_user_id ||
                        isAdmin ||
                        isDispatchUser)
                    "
                  >
                    | Edited [{{
                      getLastCommentEditedDateTime(msg?.history_changes)
                        ?.updated_at | date : "d MMM y HH:mm"
                    }}]</span
                  >
                  <span class="float-end" *ngIf="msg?.cachedData">
                    <nb-icon
                      icon="alert-triangle"
                      pack="eva"
                      status="warning"
                      nbTooltip="This update is not yet added to the server."
                    >
                    </nb-icon>
                  </span>
                </div>

                <!-- comment / photo -->
                <div class="chat-data">
                  <div
                    (click)="
                      subscriberUserId == msg?.subs_user_id &&
                      !offlineMode &&
                      !msg?.cachedData &&
                      msg?.action !== 'Changed Status'
                        ? editComment(msg, commentRef)
                        : ''
                    "
                  >
                    {{ msg?.action === "Changed Status" ? "STATUS: " : "" }}
                    {{
                      msg?.detail === "null" || msg?.detail === "undefined"
                        ? ""
                        : msg?.detail
                    }}
                    <div
                      class="sc-floating-right"
                      *ngIf="
                        subscriberUserId == msg?.subs_user_id &&
                        !offlineMode &&
                        !msg?.cachedData &&
                        msg?.action !== 'Changed Status'
                      "
                    >
                      <nb-icon pack="eva" icon="edit-outline"></nb-icon>
                    </div>
                  </div>
                  <ng-container *ngIf="msg?.file">
                    <ng-container *ngIf="msg?.cachedData">
                      <img
                        class="zoomImage"
                        [src]="getImgSrc(msg?.file)"
                        height="120"
                        width="120"
                    /></ng-container>
                    <ng-container *ngIf="!msg?.cachedData">
                      <img
                        class="zoomImage"
                        (click)="onOpenImage($event, imgRef)"
                        src="{{ msg?.file }}"
                        height="120"
                        width="120"
                    /></ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div
        id="bottom-section-fixed"
        class="bottom-section"
        *ngIf="scheduleCheck"
      >
        <div
          class="px-2 my-0"
          *ngIf="
            ![1, 2, 3].includes(detailData?.status?.status_identity?.value)
          "
        >
          <div
            *ngIf="selectedJobStatus?.name"
            class="job-status-text mb-2 mt-1"
            [style]="selectedJobStatus?.additional_info?.style?.snubbed"
          >
            {{ selectedJobStatus?.name }}
          </div>

          <div *ngIf="!offlineMode">
            <form>
              <nb-select
                class="status-job-dropdown my-0"
                fullWidth
                name="selectedControl"
                id="selectedControl"
                status="primary"
                [(selected)]="selectedStatus"
                (selectedChange)="
                  changeJobStatus(
                    $event,
                    jobCancelTemplate,
                    onsiteTimeTempalteRef
                  )
                "
                placeholder="Change Status"
                size="giant"
                status="primary"
                filled
                hero
              >
                <nb-option
                  *ngFor="let item of statusList; let i = index"
                  [value]="item"
                >
                  {{ item?.status?.name }}
                </nb-option>
              </nb-select>
            </form>
          </div>
        </div>

        <div
          class="p-2"
          *ngIf="![2, 3].includes(detailData?.status?.status_identity?.value)"
        >
          <div>
            <!-- Comment and Photo buttons -->
            <div
              class="row row-cols-1"
              *ngIf="
                detailData?.status?.status_identity?.value == 1 &&
                detailData?.can_reopen
              "
            >
              <div class="col" *ngIf="!offlineMode && lastStatus?.status?.id">
                <button
                  class="nbbutton-long-text"
                  nbButton
                  fullWidth
                  size="giant"
                  (click)="moveJobBack()"
                  status="primary"
                  hero
                >
                  Reopen Job
                </button>
              </div>
            </div>

            <div
              class="row row-cols-2"
              *ngIf="detailData?.status?.status_identity?.value !== 1"
            >
              <div *ngIf="detailData?.service_type?.enable_comments === true">
                <button
                  class="nbbutton-long-text"
                  nbButton
                  fullWidth
                  size="giant"
                  hero
                  status="primary"
                  (click)="openAddCommentTemplate(commentRef)"
                  title="Add Comment"
                >
                  <nb-icon
                    icon="message-square-outline"
                    pack="eva"
                    style="color: var(--color-basic-300)"
                  ></nb-icon>
                </button>
              </div>
              <div *ngIf="detailData?.service_type?.enable_uploads === true">
                <button
                  class="nbbutton-long-text"
                  nbButton
                  fullWidth
                  size="giant"
                  status="primary"
                  hero
                  (click)="openCamPopup()"
                  title="Add Photo"
                >
                  <nb-icon
                    icon="camera-outline"
                    pack="eva"
                    style="color: var(--color-basic-300)"
                  ></nb-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MOBILE ONLY END-->

    <!-- DESKTOP VIEW -->
    <div class="large-view-only gt-p-1">
      <!-- JOBS OTHER STATUS [start/in transit/in progress status] starts-->
      <ng-container
        *ngIf="
          detailData?.status?.status_identity?.value !== 1 &&
          detailData?.status?.status_identity?.value !== 2 &&
          detailData?.status?.status_identity?.value !== 3
        "
      >
        <div class="col">
          <div
            class="mb-2"
            [nbPopover]="mapRef"
            [nbPopoverContext]="{
              address: detailData?.sites[0]?.address
            }"
            nbButton
            ghost
            size="giant"
            style="color: var(--text-basic-color); white-space: normal"
          >
            <div style="font-size: 1.4rem" class="strong">
              {{ detailData?.sites[0]?.company_name }}
            </div>
          </div>
        </div>
        <div class="row row-cols-2">
          <div class="col-12">
            <!-- FIRST ROW: Additional Information (Additional Information), Action Buttons-->
            <div class="row row-cols-2">
              <!-- LEFT SECTION -->
              <div class="col">
                <div class="job-details-section">
                  <div class="badge-key">Address</div>
                  <div class="badge-value">
                    {{
                      detailData?.sites[0]?.address?.address3
                        ? detailData?.sites[0]?.address?.address3 + " / "
                        : ""
                    }}
                    {{ detailData?.sites[0]?.address?.address1 }}
                    {{ detailData?.sites[0]?.address?.address2 }},
                    {{ detailData?.sites[0]?.address?.city_name }}
                    {{ detailData?.sites[0]?.address?.state_code }}
                    {{ detailData?.sites[0]?.address?.postcode }}
                  </div>
                </div>

                <div class="job-details-section">
                  <div class="badge-key">Job Type</div>
                  <div class="badge-value">
                    {{ detailData?.service_type?.name }}
                    <span *ngIf="detailData?.response_type?.name">
                      &nbsp;-&nbsp;
                      <span class="strong">
                        {{ detailData?.response_type?.name }}
                      </span>
                    </span>
                  </div>
                </div>

                <div
                  class="pointer job-details-section"
                  (click)="redirectToClientPage()"
                >
                  <div class="badge-key strong">
                    Client
                    <nb-icon
                      class="ms-3 color-basic-text"
                      nbSuffix
                      icon="link-2-outline"
                      pack="eva"
                    ></nb-icon>
                  </div>
                  <div class="badge-value strong">
                    {{ detailData?.company?.company_name }}
                  </div>
                </div>

                <div
                  class="job-details-section"
                  *ngIf="isAdmin || isDispatchUser"
                >
                  <div
                    class="badge-key strong pointer"
                    (click)="getSiteContacts(modifySiteContactRef)"
                  >
                    Onsite Contacts
                    <div *ngIf="detailData?.location_contacts?.length > 1">
                      [{{ detailData?.location_contacts?.length }}]
                    </div>
                    <nb-icon
                      class="ms-3 color-basic-text"
                      nbSuffix
                      icon="external-link-outline"
                      pack="eva"
                    ></nb-icon>
                  </div>

                  <div class="badge-value">
                    <div class="row row-cols-auto">
                      <div
                        *ngIf="detailData?.location_contacts?.length"
                        class="pointer col"
                        (click)="
                          openSiteContactTemplate(
                            detailData?.location_contacts[0]
                          )
                        "
                      >
                        {{ detailData?.location_contacts[0]?.full_name }}
                      </div>
                      <div
                        *ngIf="detailData?.location_contacts?.length > 1"
                        class="col pointer"
                        fullWidth
                        size="tiny"
                        (click)="getSiteContacts(modifySiteContactRef)"
                      ></div>

                      <div
                        class="person-list"
                        *ngIf="!detailData?.location_contacts?.length"
                        style="color: var(--color-danger-500)"
                      >
                        No Site Contacts Defined
                      </div>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="detailData?.roster_scheduler?.repeat_type">
                  <!-- ONCE -->
                  <div class="job-details-section">
                    <div class="badge-key">Start Date</div>
                    <div class="badge-value">
                      {{
                        detailData?.schedule_event_date
                          ? (detailData?.schedule_event_date | date : "d MMM y")
                          : (detailData?.roster_scheduler?.start_day
                            | date : "d MMM y")
                      }}
                    </div>
                  </div>

                  <div class="job-details-section">
                    <div
                      class="badge-key"
                      (click)="
                        (isAdmin === true || isDispatchUser === true) &&
                        ![1, 2, 3].includes(
                          detailData?.status?.status_identity?.value
                        ) &&
                        canScheduleBeUpdated
                          ? openScheduleTemplate(changeScheduleTemplateRef)
                          : ''
                      "
                    >
                      Shift [Start - End]
                      <nb-icon
                        *ngIf="
                          (isAdmin === true || isDispatchUser === true) &&
                          ![1, 2, 3].includes(
                            detailData?.status?.status_identity?.value
                          ) &&
                          canScheduleBeUpdated
                        "
                        pack="eva"
                        icon="edit"
                        class="color-basic-text ms-2"
                      ></nb-icon>
                    </div>
                    <div class="badge-value">
                      {{ detailData?.roster_scheduler?.start_time }}
                      -
                      {{ detailData?.roster_scheduler?.end_time }}
                    </div>
                  </div>

                  <div
                    *ngIf="detailData?.roster_scheduler?.repeat_type"
                    class="job-details-section"
                  >
                    <div class="badge-key">Job Duration</div>
                    <div class="badge-value">{{ scheduleDuration }} Hours</div>
                  </div>
                </ng-container>

                <div
                  *ngIf="
                    showWelfareItem &&
                    (isAdmin ||
                      isDispatchUser ||
                      detailData?.interval_check > 0)
                  "
                  class="job-details-section"
                >
                  <div
                    [ngClass]="
                      isAdmin === true || isDispatchUser === true
                        ? 'pointer strong'
                        : ''
                    "
                    class="badge-key"
                    (click)="
                      offlineMode
                        ? showOfflineMessage()
                        : (isAdmin === true || isDispatchUser === true) &&
                          detailData?.status?.status_identity?.value !== 1 &&
                          detailData?.status?.status_identity?.value !== 2 &&
                          detailData?.status?.status_identity?.value !== 3
                        ? openIntervalCheckTemplate(welfareIntervalTemplate)
                        : ''
                    "
                  >
                    Welfare Checks
                    <nb-icon
                      *ngIf="isAdmin === true || isDispatchUser === true"
                      pack="eva"
                      icon="edit"
                      class="color-basic-text ms-3"
                    ></nb-icon>
                  </div>
                  <div class="badge-value">
                    {{ detailData?.interval_check }} mins
                  </div>
                </div>

                <div
                  *ngIf="isAdmin === true || isDispatchUser === true"
                  class="job-details-section"
                >
                  <div
                    class="badge-key strong pointer"
                    (click)="modifyAssignees(modifyAssigneeRef)"
                  >
                    {{
                      detailData?.assignee?.length > 1
                        ? "Assigned Guards"
                        : "Assigned Guard"
                    }}
                    <div *ngIf="detailData?.assignee?.length > 1">
                      [{{ detailData?.assignee?.length }}]
                    </div>
                    <nb-icon
                      class="ms-3 color-basic-text"
                      nbSuffix
                      icon="external-link-outline"
                      pack="eva"
                    ></nb-icon>
                  </div>

                  <div class="badge-value">
                    <div class="row row-cols-auto">
                      <div *ngIf="detailData?.assignee?.length > 0">
                        <div
                          [nbPopover]="assigneeRef"
                          nbPopoverTrigger="hint"
                          [nbTooltipDisabled]="
                            detailData?.assignee[0]?.status === 'ASSIGNED'
                          "
                          [nbPopoverContext]="{
                            assignee: detailData?.assignee[0]
                          }"
                          [ngStyle]="
                            detailData?.assignee[0]?.status == 'ASSIGNED'
                              ? { color: 'var(--text-color-basic)' }
                              : {
                                  'color': 'var(--text-danger-color)',
                                  'font-weight': '600'
                                }
                          "
                        >
                          <span
                            class="pointer"
                            (click)="
                              openAssigneeDetail(detailData?.assignee[0])
                            "
                          >
                            {{ detailData?.assignee[0]?.full_name }}
                          </span>
                          <span
                            *ngIf="detailData?.assignee?.length > 1"
                            class="color-basic-text strong pointer ms-3"
                            (click)="modifyAssignees(modifyAssigneeRef)"
                          >
                            + {{ detailData?.assignee?.length - 1 }} more</span
                          >
                        </div>
                      </div>

                      <div
                        (click)="modifyAssignees(modifyAssigneeRef)"
                        *ngIf="!detailData?.assignee?.length"
                        style="color: var(--color-danger-500)"
                      >
                        Not Assigned
                      </div>
                    </div>
                  </div>
                </div>

                <div class="job-details-section">
                  <div class="badge-key">Job Status</div>
                  <div
                    class="badge-value job-status-text"
                    [style]="selectedJobStatus?.additional_info?.style?.snubbed"
                  >
                    {{
                      selectedJobStatus?.name
                        ? selectedJobStatus?.name
                        : "NOT STARTED"
                    }}
                  </div>
                </div>

                <div
                  class="row row-cols-sm-1 row-cols-xl-2 mt-2 me-5"
                  *ngIf="scheduleCheck"
                >
                  <div class="col my-0 col-xl-4">
                    <button
                      *ngIf="detailData?.service_type?.enable_comments === true"
                      nbButton
                      fullWidth
                      size="large"
                      (click)="openAddCommentTemplate(commentRef)"
                      nbPopoverPlacement="bottom"
                    >
                      + Add Comment
                    </button>
                  </div>

                  <div class="col my-0 col-xl-7">
                    <form>
                      <nb-select
                        class="status-job-dropdown"
                        fullWidth
                        name="selectedControl"
                        id="changeStatus"
                        status="primary"
                        [(selected)]="selectedStatus"
                        (selectedChange)="
                          changeJobStatus(
                            $event,
                            jobCancelTemplate,
                            onsiteTimeTempalteRef
                          )
                        "
                        placeholder="Change Status"
                        hero
                        size="large"
                      >
                        <nb-option
                          *ngFor="let item of statusList; let i = index"
                          [value]="item"
                        >
                          {{ item?.status?.name }}
                        </nb-option>
                      </nb-select>
                    </form>
                  </div>
                </div>
              </div>

              <!-- RIGHT SECTION -->
              <div class="col" id="right-section">
                <div
                  [ngClass]="
                    isAdmin === true || isDispatchUser === true
                      ? 'job-details-section'
                      : 'job-details-section'
                  "
                >
                  <div class="badge-key">Job ID</div>
                  <div class="badge-value">{{ detailData?.job_key }}</div>
                </div>
                <div
                  *ngIf="detailData?.service_type?.type_identity === 0"
                  (click)="
                    isAdmin === true || isDispatchUser === true
                      ? modifyExternalJobId(modifyExternalJobIdTemplate)
                      : ''
                  "
                  [ngClass]="
                    isAdmin === true || isDispatchUser === true
                      ? 'pointer job-details-section'
                      : 'job-details-section'
                  "
                >
                  <div class="badge-key strong pointer">
                    External Job ID
                    <nb-icon
                      pack="eva"
                      icon="external-link-outline"
                      class="ms-3"
                      *ngIf="!detailData?.monitoring_company?.id"
                    ></nb-icon>
                  </div>
                  <div class="badge-value" *ngIf="detailData?.ext_job_id">
                    {{ detailData?.ext_job_id }}
                  </div>
                </div>
                <!-- Showing Monitoring Company and Response Type -->
                <ng-container
                  *ngIf="
                    (detailData?.monitoring_company?.id ||
                      isAdmin === true ||
                      isDispatchUser === true) &&
                    detailData?.service_type?.type_identity === 0
                  "
                >
                  <div
                    (click)="
                      detailData?.monitoring_company?.id
                        ? ''
                        : modifyMonitoringCompany(modifyMCTemplate)
                    "
                    [ngClass]="
                      detailData?.monitoring_company?.id ? '' : 'pointer strong'
                    "
                    class="job-details-section"
                  >
                    <div class="badge-key">
                      Monitoring Company
                      <nb-icon
                        pack="eva"
                        icon="external-link-outline"
                        class="ms-3"
                        *ngIf="!detailData?.monitoring_company?.id"
                      ></nb-icon>
                    </div>
                    <div class="badge-value">
                      <div class="row row-cols-auto">
                        <div>
                          {{ detailData?.monitoring_company?.company_name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <div
                  [ngClass]="
                    isAdmin === true || isDispatchUser === true
                      ? 'pointer job-details-section'
                      : 'job-details-section'
                  "
                  (click)="modifyRequestDetails(requestRef)"
                >
                  <div
                    [ngClass]="
                      isAdmin === true || isDispatchUser === true
                        ? 'badge-key strong'
                        : 'badge-key '
                    "
                  >
                    Additional Information
                    <nb-icon
                      class="ms-3 color-basic-text"
                      nbSuffix
                      [icon]="'external-link-outline'"
                      pack="eva"
                      *ngIf="
                        !offlineMode &&
                        (isAdmin === true || isDispatchUser === true)
                      "
                    >
                    </nb-icon>
                  </div>
                  <div class="badge-value request-details-desktop">
                    <pre class="request-detail-text"
                      >{{
                        detailData?.request_details
                          ? detailData?.request_details
                          : "No details provided"
                      }}   </pre
                    >
                  </div>
                </div>

                <div
                  *ngIf="detailData?.sites[0]?.site_information"
                  class="job-details-section"
                >
                  <div class="badge-key">Site Information</div>
                  <div class="badge-value request-details-desktop">
                    <pre class="request-detail-text"
                      >{{
                        detailData?.sites[0]?.site_information
                          ? detailData?.sites[0]?.site_information
                          : "No details provided"
                      }}   </pre
                    >
                  </div>
                </div>

                <div
                  *ngIf="detailData?.sites[0]?.site_files?.length"
                  class="job-details-section"
                >
                  <div class="badge-key">Site Files</div>
                  <div class="badge-value request-details-desktop">
                    <div class="row row-cols-1">
                      <div
                        *ngFor="let file of detailData?.sites[0]?.site_files"
                        class="col item-list pointer"
                      >
                        <div>
                          <div
                            (click)="onOpenFile(file, previewTempRef)"
                            style="align-items: center; display: flex"
                          >
                            <nb-badge
                              text="{{ file?.extension }}"
                              class="extension-badge"
                            >
                            </nb-badge>
                            {{ file?.description }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="detailData?.sites[0]?.site_keys?.length"
                  class="job-details-section mt-4"
                >
                  <div class="badge-key">Site Keys</div>
                  <div class="badge-value request-details-desktop">
                    <div class="row row-cols-1">
                      <div
                        *ngFor="let siteKey of detailData?.sites[0]?.site_keys"
                        class="col item-list"
                      >
                        <ul class="mb-0 key-list">
                          <li>&nbsp; {{ siteKey }}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- JOBS OTHER STATUS [start/in transit/in progress status] ends-->

      <!-- COMPLETED JOBS status starts-->
      <ng-container
        *ngIf="detailData?.status?.status_identity?.value === 1"
        id="COMPLETED-CLOSED"
      >
        <nb-alert
          class="mt-2 alert-dismissible fade show"
          [ngStyle]="
            detailData?.can_reopen
              ? selectedJobStatus?.additional_info?.style?.snubbed
              : {
                  color: 'var(--color-basic-100)',
                  background: 'var(--color-basic-1100)'
                }
          "
        >
          <div class="strong">
            {{ detailData?.can_reopen ? "JOB COMPLETED" : "JOB CLOSED" }}
          </div>
        </nb-alert>

        <div class="col">
          <div
            class="mt-3 mb-4"
            [nbPopover]="mapRef"
            [nbPopoverContext]="{
              address: detailData?.sites[0]?.address
            }"
            nbButton
            ghost
            size="giant"
            style="color: var(--text-basic-color); white-space: normal"
          >
            <div style="font-size: 1.4rem" class="strong">
              {{ detailData?.sites[0]?.company_name }}
            </div>
          </div>
        </div>
        <div class="row row-cols-2">
          <!-- LEFT SECTION -->
          <div class="col">
            <!-- Additional Information (Additional Information) part -->
            <div class="job-details-section">
              <div class="badge-key">Address</div>
              <div class="badge-value">
                {{
                  detailData?.sites[0]?.address?.address3
                    ? detailData?.sites[0]?.address?.address3 + " / "
                    : ""
                }}
                {{ detailData?.sites[0]?.address?.address1 }}
                {{ detailData?.sites[0]?.address?.address2 }},
                {{ detailData?.sites[0]?.address?.city_name }}
                {{ detailData?.sites[0]?.address?.state_code }}
                {{ detailData?.sites[0]?.address?.postcode }}
              </div>
            </div>

            <div class="job-details-section">
              <div class="badge-key">Job Type</div>
              <div class="badge-value">
                {{ detailData?.service_type?.name }}
                <div *ngIf="detailData?.response_type?.name">
                  &nbsp;-&nbsp;
                  <div class="strong">
                    {{ detailData?.response_type?.name }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="pointer job-details-section"
              (click)="redirectToClientPage()"
            >
              <div class="badge-key strong">
                Client
                <nb-icon
                  class="ms-3 color-basic-text"
                  nbSuffix
                  icon="link-2-outline"
                  pack="eva"
                ></nb-icon>
              </div>
              <div class="badge-value strong">
                {{ detailData?.company?.company_name }}
              </div>
            </div>

            <div
              class="job-details-section"
              *ngIf="detailData?.location_contacts?.length > 1"
            >
              <div
                class="badge-key pointer"
                (click)="getSiteContacts(modifySiteContactRef)"
              >
                Onsite Contacts
                <div *ngIf="detailData?.location_contacts?.length > 1">
                  [{{ detailData?.location_contacts?.length }}]
                </div>
              </div>
              <div class="badge-value">
                <div class="row row-cols-auto">
                  <div
                    *ngIf="detailData?.location_contacts?.length"
                    class="pointer col"
                    (click)="
                      openSiteContactTemplate(detailData?.location_contacts[0])
                    "
                  >
                    {{ detailData?.location_contacts[0]?.full_name }}
                  </div>
                  <div
                    *ngIf="detailData?.location_contacts?.length > 1"
                    class="col pointer"
                    fullWidth
                    size="tiny"
                    (click)="getSiteContacts(modifySiteContactRef)"
                  >
                    <nb-icon
                      nbSuffix
                      class="color-basic-text"
                      [icon]="'expand-outline'"
                      pack="eva"
                    ></nb-icon>
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngIf="detailData?.service_type?.type_identity === 0">
              <div
                class="job-details-section"
                *ngIf="isAdmin === true || isDispatchUser === true"
              >
                <div class="badge-key">Monitoring Company</div>
                <div class="badge-value">
                  <div class="row row-cols-auto">
                    <div>
                      {{
                        detailData?.monitoring_company?.company_name
                          ? detailData?.monitoring_company?.company_name
                          : "N/A"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div
              class="job-details-section"
              *ngIf="isAdmin === true || isDispatchUser === true"
            >
              <div
                class="badge-key pointer"
                (click)="modifyAssignees(modifyAssigneeRef)"
              >
                {{
                  detailData?.assignee?.length > 1
                    ? "Assigned Guards"
                    : "Assigned Guard"
                }}
                <div *ngIf="detailData?.assignee?.length > 1">
                  [{{ detailData?.assignee?.length }}]
                </div>
              </div>
              <div class="badge-value">
                <div class="row row-cols-auto">
                  <div
                    class="pointer"
                    (click)="openAssigneeDetail(detailData?.assignee[0])"
                    [nbPopover]="assigneeRef"
                    nbPopoverTrigger="hint"
                    [nbTooltipDisabled]="
                      detailData?.assignee[0]?.status === 'ASSIGNED'
                    "
                    [nbPopoverContext]="{
                      assignee: detailData?.assignee[0]
                    }"
                    [ngStyle]="
                      detailData?.assignee[0]?.status == 'ASSIGNED'
                        ? { color: 'var(--text-color-basic)' }
                        : {
                            'color': 'var(--text-danger-color)',
                            'font-weight': '600'
                          }
                    "
                  >
                    {{ detailData?.assignee[0]?.full_name }}
                  </div>

                  <div
                    *ngIf="detailData?.assignee?.length > 1"
                    class="col pointer"
                    fullWidth
                    size="tiny"
                    (click)="modifyAssignees(modifyAssigneeRef)"
                  >
                    <nb-icon
                      nbSuffix
                      class="color-basic-text"
                      [icon]="'expand-outline'"
                      pack="eva"
                    ></nb-icon>
                  </div>

                  <div
                    (click)="modifyAssignees(modifyAssigneeRef)"
                    class="person-list"
                    *ngIf="!detailData?.assignee?.length"
                    style="color: var(--color-danger-500)"
                  >
                    Not Assigned
                  </div>
                </div>
              </div>
            </div>
            <div class="job-details-section">
              <div class="badge-key">Job Status</div>
              <div class="badge-value job-status-text">
                {{ detailData?.can_reopen ? "Complete" : "Closed" }}
              </div>
            </div>

            <div
              class="col mt-2"
              *ngIf="detailData?.can_reopen && lastStatus?.status?.id"
            >
              <button
                nbButton
                class="w-50"
                size="giant"
                status="warning"
                hero
                (click)="moveJobBack()"
              >
                Reopen Job
              </button>
            </div>
          </div>

          <!-- RIGHT SECTION -->
          <div class="col">
            <div class="job-details-section">
              <div class="badge-key">Job ID</div>
              <div class="badge-value">{{ detailData?.job_key }}</div>
            </div>
            <div
              class="job-details-section"
              *ngIf="detailData?.service_type?.type_identity === 0"
            >
              <div class="badge-key">External Job ID</div>
              <div class="badge-value">
                {{ detailData?.ext_job_id ? detailData?.ext_job_id : "N/A" }}
              </div>
            </div>

            <div class="job-details-section">
              <div class="badge-key">Additional Information</div>
              <div class="badge-value request-details-desktop">
                <pre class="request-detail-text"
                  >{{
                    detailData?.request_details
                      ? detailData?.request_details
                      : "No details provided"
                  }}   </pre
                >
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- COMPLETED / CLOSED JOBS status ends-->

      <!-- CANCELLED JOBS starts-->
      <ng-container
        *ngIf="
          detailData?.status?.status_identity?.value === 2 ||
          detailData?.status?.status_identity?.value === 3
        "
        id="CANCELLED"
      >
        <!--cancellation reason-->
        <div
          class="mt-2 alert alert-danger alert-dismissible fade show small-font"
          role="alert"
          *ngIf="cancelReasonData"
        >
          <div>
            <div>CANCELLED:</div>
            <div class="strong uppercase">
              {{ detailData?.modified_date | date : "d MMM y H:mm" }}
            </div>
          </div>
          <div *ngIf="cancelReasonData?.reason">
            <div>CANCEL REASON:</div>
            <div class="strong">
              {{ cancelReasonData?.reason }}
            </div>
          </div>
        </div>

        <div class="col">
          <div
            class="mt-3 mb-4"
            [nbPopover]="mapRef"
            [nbPopoverContext]="{
              address: detailData?.sites[0]?.address
            }"
            nbButton
            ghost
            size="giant"
            style="color: var(--text-basic-color); white-space: normal"
          >
            <div style="font-size: 1.4rem" class="strong">
              {{ detailData?.sites[0]?.company_name }}
            </div>
          </div>
        </div>
        <div class="row row-cols-2">
          <!-- LEFT SECTION -->
          <div class="col">
            <!-- Additional Information (request details) part -->
            <div class="job-details-section">
              <div class="badge-key">Address</div>
              <div class="badge-value">
                {{
                  detailData?.sites[0]?.address?.address3
                    ? detailData?.sites[0]?.address?.address3 + " / "
                    : ""
                }}
                {{ detailData?.sites[0]?.address?.address1 }}
                {{ detailData?.sites[0]?.address?.address2 }},
                {{ detailData?.sites[0]?.address?.city_name }}
                {{ detailData?.sites[0]?.address?.state_code }}
                {{ detailData?.sites[0]?.address?.postcode }}
              </div>
            </div>
            <div class="job-details-section">
              <div class="badge-key">Job Type</div>
              <div class="badge-value">
                {{ detailData?.service_type?.name }}
                <div *ngIf="detailData?.response_type?.name">
                  &nbsp;-&nbsp;
                  <div class="strong">
                    {{ detailData?.response_type?.name }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="pointer job-details-section"
              (click)="redirectToClientPage()"
            >
              <div class="badge-key strong">
                Client
                <nb-icon
                  class="ms-3"
                  nbSuffix
                  icon="link-2-outline"
                  pack="eva"
                ></nb-icon>
              </div>
              <div class="badge-value strong">
                {{ detailData?.company?.company_name }}
              </div>
            </div>
            <div class="job-details-section">
              <div
                class="badge-key pointer"
                (click)="getSiteContacts(modifySiteContactRef)"
              >
                Onsite Contacts
                <div *ngIf="detailData?.location_contacts?.length > 1">
                  [{{ detailData?.location_contacts?.length }}]
                </div>
              </div>
              <div class="badge-value">
                <div class="row row-cols-auto">
                  <div
                    *ngIf="detailData?.location_contacts?.length"
                    class="pointer col"
                    (click)="
                      openSiteContactTemplate(detailData?.location_contacts[0])
                    "
                  >
                    {{ detailData?.location_contacts[0]?.full_name }}
                  </div>
                  <div
                    *ngIf="detailData?.location_contacts?.length > 1"
                    class="col pointer"
                    fullWidth
                    size="tiny"
                    (click)="getSiteContacts(modifySiteContactRef)"
                  >
                    <nb-icon
                      nbSuffix
                      class="color-basic-text"
                      [icon]="'expand-outline'"
                      pack="eva"
                    ></nb-icon>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="job-details-section"
              *ngIf="isAdmin === true || isDispatchUser === true"
            >
              <div
                class="badge-key pointer"
                (click)="modifyAssignees(modifyAssigneeRef)"
              >
                {{
                  detailData?.assignee?.length > 1
                    ? "Assigned Guards"
                    : "Assigned Guard"
                }}
                <div *ngIf="detailData?.assignee?.length > 1">
                  [{{ detailData?.assignee?.length }}]
                </div>
              </div>
              <div class="badge-value">
                <div class="row row-cols-auto">
                  <div
                    class="pointer"
                    (click)="openAssigneeDetail(detailData?.assignee[0])"
                    [nbPopover]="assigneeRef"
                    nbPopoverTrigger="hint"
                    [nbTooltipDisabled]="
                      detailData?.assignee[0]?.status === 'ASSIGNED'
                    "
                    [nbPopoverContext]="{
                      assignee: detailData?.assignee[0]
                    }"
                    [ngStyle]="
                      detailData?.assignee[0]?.status == 'ASSIGNED'
                        ? { color: 'var(--text-color-basic)' }
                        : {
                            'color': 'var(--text-danger-color)',
                            'font-weight': '600'
                          }
                    "
                  >
                    {{ detailData?.assignee[0]?.full_name }}
                  </div>

                  <div
                    *ngIf="detailData?.assignee?.length > 1"
                    class="col pointer"
                    fullWidth
                    size="tiny"
                    (click)="modifyAssignees(modifyAssigneeRef)"
                  >
                    <nb-icon
                      nbSuffix
                      class="color-basic-text"
                      [icon]="'expand-outline'"
                      pack="eva"
                    ></nb-icon>
                  </div>

                  <div
                    (click)="modifyAssignees(modifyAssigneeRef)"
                    class="person-list"
                    *ngIf="!detailData?.assignee?.length"
                    style="color: var(--color-danger-500)"
                  >
                    Not Assigned
                  </div>
                </div>
              </div>
            </div>
            <ng-container
              *ngIf="
                detailData?.service_type?.type_identity === 0 &&
                detailData?.monitoring_company?.company_name
              "
            >
              <div
                class="job-details-section"
                *ngIf="isAdmin === true || isDispatchUser === true"
              >
                <div class="badge-key">Monitoring Company</div>
                <div class="badge-value">
                  <div class="row row-cols-auto">
                    <div>
                      {{
                        detailData?.monitoring_company?.company_name
                          ? detailData?.monitoring_company?.company_name
                          : "N/A"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- RIGHT SECTION -->
          <div class="col">
            <div class="job-details-section">
              <div class="badge-key strong">Job ID</div>
              <div class="badge-value">{{ detailData?.job_key }}</div>
            </div>
            <div
              class="job-details-section"
              *ngIf="detailData?.service_type?.type_identity === 0"
            >
              <div class="badge-key">External Job ID</div>
              <div class="badge-value">
                {{ detailData?.ext_job_id ? detailData?.ext_job_id : "N/A" }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- CANCELLED JOBS ends-->

      <!-- history section start -->

      <ng-container *ngIf="isAdmin || isDispatchUser">
        <!-- TABLE -->
        <div
          [ngClass]="
            showDetails ? 'mt-4 row row-cols-1' : 'mt-4 row row-cols-2'
          "
        >
          <div
            [ngClass]="showDetails ? 'col-5 pointer' : 'col-12 pointer'"
            #target
          >
            <app-table
              [desktopTableData]="jobHistory"
              [desktopTableDetails]="jobDetailsTableView"
              [tableStyle]="tableStyle"
              (rowClick)="onTableRowClick($event)"
              [otherParms]="{ dateRange: dateRange }"
            ></app-table>
          </div>
          <div class="col-7 sc-mt-9" *ngIf="showDetails">
            <div
              [ngClass]="uploadedImage ? 'row row-cols-md-2' : 'row row-cols-1'"
            >
              <div class="col-md-6" *ngIf="uploadedImage">
                <img
                  class="zoomImage"
                  (click)="onOpenImage($event, imgRef)"
                  *ngIf="uploadedImage"
                  src="{{ uploadedImage }}"
                  alt="unplash"
                  style="max-height: 100%; max-width: 100%"
                />
              </div>
              <div [ngClass]="uploadedImage ? 'col-md-6' : 'col'">
                <app-map-leaflet
                  *ngIf="mapLatLong"
                  [latLon]="mapLatLong"
                  [mapId]="'jobHistoryLocation'"
                ></app-map-leaflet>
              </div>
            </div>
          </div>
        </div>

        <!-- SELECTED DETAILS -->
        <div
          class="gt-event-details"
          *ngIf="selectedEvent?.detail || selectedEvent?.device_details"
        >
          <div
            [ngClass]="
              selectedEvent?.detail ? 'flex-center' : 'd-flex flex-row-reverse'
            "
          >
            <!-- COMMENT DETAILS -->
            <div
              *ngIf="selectedEvent?.detail"
              style="margin-right: 1rem"
              class="flex-fill"
            >
              <div>
                <strong>{{ selectedEvent?.action | titlecase }}</strong>
                <span
                  *ngIf="
                    selectedEvent?.history_changes?.length &&
                    (subscriberUserId == selectedEvent?.subs_user_id ||
                      isAdmin ||
                      isDispatchUser)
                  "
                >
                  | Edited [{{
                    getLastCommentEditedDateTime(selectedEvent?.history_changes)
                      ?.updated_at | date : "d MMM y HH:mm"
                  }}]</span
                >
              </div>

              <div class="sc-head6 request-detail-text">
                {{
                  selectedEvent?.detail === "null" ||
                  selectedEvent?.detail === "undefined"
                    ? ""
                    : selectedEvent?.detail
                }}
                <nb-icon
                  *ngIf="
                    subscriberUserId == selectedEvent?.subs_user_id &&
                    !offlineMode &&
                    !selectedEvent?.cachedData &&
                    (selectedEvent?.event_action === 7 ||
                      selectedEvent?.event_action === 8)
                  "
                  (click)="editComment(selectedEvent, commentRef)"
                  pack="eva"
                  icon="edit-outline"
                ></nb-icon>
              </div>

              <pre>{{
                selectedEvent?.user_provided_timestamp | date : "d MMM y HH:mm"
              }}</pre>
            </div>

            <!-- DEVICE DETAILS -->
            <div style="width: 30rem">
              <div
                class="row row-cols-2 pointer"
                *ngIf="
                  selectedEvent?.recorded_at && !selectedEvent?.delayed_event
                "
                nbTooltip="This event was captured when the user was offline"
              >
                <div class="col-3 pointer strong" style="color: brown">
                  CAPTURED TIME
                </div>
                <div class="col-9" style="display: flex; align-items: center">
                  <strong
                    ><small>
                      {{
                        selectedEvent?.recorded_at | date : "d MMM y HH:mm"
                      }}</small
                    >
                  </strong>
                </div>
              </div>
              <div
                *ngFor="let item of selectedEvent?.device_details | keyvalue"
              >
                <div *ngIf="item?.value" class="row row-cols-2">
                  <div class="col-3">
                    <small>{{ item.key }}</small>
                  </div>
                  <div class="col-9" style="display: flex; align-items: center">
                    <strong
                      ><small> {{ item.value }}</small>
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <!-- history section end -->
  </nb-card-body>
</nb-card>

<!-- Dialogue for not holding the location's licence -->
<ng-template #assigneeRef let-data>
  <nb-card>
    <div *ngIf="data?.assignee?.status !== 'ASSIGNED'">
      May not be licensed for this job
    </div>
  </nb-card>
</ng-template>
<!-- Dialogue for assign users -->
<ng-template #modifyAssigneeRef let-data let-modifyAssigneeRef="dialogRef">
  <nb-card class="dialog-box">
    <nb-card-header class="sc-text-center"> Assigned Guards </nb-card-header>
    <nb-card-body>
      <div
        class="form-floating"
        *ngIf="
          detailData?.status?.status_identity?.value !== 1 &&
          detailData?.status?.status_identity?.value !== 2 &&
          detailData?.status?.status_identity?.value !== 3
        "
      >
        <input
          class="form-control"
          fullWidth
          (input)="getAssignees($event)"
          nbInput
          type="text"
          placeholder="Search/Select Assignee"
          [nbAutocomplete]="assigneeNgModel"
        /><label>Search/Select Guards</label>
      </div>
      <nb-autocomplete #assigneeNgModel>
        <nb-option
          (click)="onAssigneeSelect(guard)"
          *ngFor="let guard of users"
          [value]="guard.full_name"
          >{{ guard?.full_name ? guard.full_name : guard.email }}
          <small *ngIf="guard?.validStateLicense">
            <nb-icon
              class="sc-floating-right"
              [icon]="'done-all-outline'"
              pack="eva"
              [attr.aria-label]="'User with a valid state license'"
            >
            </nb-icon>
            <!-- {{ guard?.validStateLicense.license_class }} -->
          </small>
        </nb-option>
      </nb-autocomplete>

      <div
        *ngFor="let assignee of detailData?.assignee"
        class="row row-cols-2 gy-3 gt-p-small"
      >
        <div
          class="col d-flex align-items-center pointer"
          (click)="openAssigneeDetail(assignee)"
        >
          {{ assignee.full_name }}
        </div>
        <div
          class="col"
          *ngIf="
            detailData?.status?.status_identity?.value !== 1 &&
            detailData?.status?.status_identity?.value !== 2 &&
            detailData?.status?.status_identity?.value !== 3
          "
        >
          <nb-icon
            [icon]="'close-outline'"
            class="sc-floating-right close-icon"
            pack="eva"
            (click)="onAssigneeDeselect(assignee)"
          >
          </nb-icon>
        </div>
      </div>
      <div class="sc-mt-6">
        <button
          nbButton
          fullWidth
          size="giant"
          (click)="modifyAssigneeRef.close()"
        >
          Close
        </button>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<!-- ADD COMMENT POPOVER / DIALOG -->
<ng-template #commentRef let-commentRef="dialogRef" let-data>
  <nb-card class="dialog-box">
    <nb-card-header class="sc-text-center">
      {{ data?.detail ? "Edit Comment" : "Add Comment" }}

      <nb-icon
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
        (click)="commentRef.close(); newComment = ''"
      >
      </nb-icon>
    </nb-card-header>
    <div class="row-cols-1 gy-2">
      <div class="col">
        <textarea
          class="form-control"
          rows="10"
          cols="32"
          wrap="hard"
          maxlength="340"
          type="text"
          placeholder="Start typing"
          [(ngModel)]="newComment"
        ></textarea>
      </div>
      <div class="col sc-mt-6">
        <button
          (click)="addComment(data); commentRef.close()"
          nbButton
          size="giant"
          fullWidth
          [disabled]="
            data?.detail
              ? !newComment || previousComment === newComment
              : !newComment
          "
        >
          {{ data?.detail ? "Update" : "Add" }}
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>

<!-- Cancel Job dialoge -->
<ng-template #jobCancelTemplate let-jobCancelTemplate="dialogRef">
  <nb-card class="cancel-job-dialog-box dialog-box">
    <nb-card-header>
      Confirmation Needed
      <nb-icon
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
        (click)="jobCancelTemplate.close()"
      >
      </nb-icon>
    </nb-card-header>
    <nb-card-body>
      <p>Cancel Job for {{ detailData?.company?.company_name }}</p>
      <p>WARNING: This action cannot be reversed.</p>

      <div class="form-floating">
        <input
          class="form-control"
          fullWidth
          nbInput
          type="text"
          [(ngModel)]="cancelReason"
          placeholder="Cancellation reason"
        /><label>Cancellation Reason</label>
      </div>

      <div class="flex-center">
        <button
          nbButton
          type="button"
          status="danger"
          [disabled]="!cancelReason"
          (click)="deleteJob()"
        >
          Cancel Job
        </button>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<!-- UPDATE SITE CONTACTS POPOVER / DIALOG -->
<ng-template #modifySiteContactRef let-modifySiteContactRef="dialogRef">
  <nb-card class="dialog-box">
    <nb-card-header class="sc-text-center">Site Contacts </nb-card-header>
    <nb-card-body>
      <!--if check here so that user can see the list but can cahnge them only for particular statuses-->
      <div
        *ngIf="
          detailData?.status?.status_identity?.value !== 1 &&
          detailData?.status?.status_identity?.value !== 2 &&
          detailData?.status?.status_identity?.value !== 3 &&
          (isAdmin === true || isDispatchUser === true)
        "
      >
        <nb-select fullWidth placeholder="Select Onsite Contact" size="giant">
          <nb-option (click)="addLocationContact()">
            Add Onsite Contact</nb-option
          >
          <nb-option
            *ngFor="let contact of siteContactListTemp"
            (click)="addSiteContact(contact?.id)"
            >{{ contact?.full_name }}</nb-option
          >
        </nb-select>
      </div>

      <div
        *ngFor="let contact of detailData?.location_contacts"
        class="row row-cols-2 gy-3 gt-p-small"
      >
        <div
          class="col d-flex align-items-center pointer"
          (click)="openSiteContactTemplate(contact)"
        >
          {{ contact?.full_name }}
        </div>
        <div
          class="col"
          *ngIf="
            detailData?.status?.status_identity?.value !== 1 &&
            detailData?.status?.status_identity?.value !== 2 &&
            detailData?.status?.status_identity?.value !== 3
          "
        >
          <nb-icon
            [icon]="'close-outline'"
            class="sc-floating-right close-icon"
            pack="eva"
            (click)="onSiteContactDeselect(contact)"
          >
          </nb-icon>
        </div>
      </div>

      <div class="sc-mt-6">
        <button
          nbButton
          fullWidth
          size="giant"
          (click)="modifySiteContactRef.close()"
        >
          Close
        </button>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>

<!-- ASSIGNEE POPOVER -->

<ng-template #mapRef let-data>
  <nb-card class="dialog-box">
    <app-map-leaflet
      [latLon]="{ lat: data?.address?.latitude, lon: data?.address?.longitude }"
      [mapId]="'siteLocation'"
    ></app-map-leaflet>
    <!-- map section ends -->
  </nb-card>
</ng-template>

<!-- Additional Information POPOVER / DIALOG -->
<ng-template #requestRef let-data let-requestRef="dialogRef">
  <nb-card class="dialog-box">
    <nb-card-header class="sc-text-center"
      >Additional Information
    </nb-card-header>

    <!-- Request Detail Section start-->
    <div class="row">
      <div class="col">
        <textarea
          class="form-control"
          placeholder="Additional Information"
          cols="20"
          rows="5"
          wrap="hard"
          maxlength="900"
          fullWidth
          type="text"
          [(ngModel)]="newRequest"
        ></textarea>
      </div>
    </div>

    <div class="row row-cols-2 sc-mt-6">
      <div class="col">
        <button
          nbButton
          fullWidth
          size="giant"
          ghost
          (click)="requestRef.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          nbButton
          size="giant"
          fullWidth
          (click)="updateRequestDetails()"
          (click)="requestRef.close()"
        >
          Update
        </button>
      </div>
    </div>

    <!-- Request Detail Section end-->
  </nb-card>
</ng-template>

<!-- Modal -->
<ng-template #imgRef let-data let-imgTempRef="dialogRef">
  <nb-card style="margin: 0">
    <nb-card-body (click)="imgTempRef.close()">
      <img
        src="{{ fullImgSrc }}"
        style="
          max-width: 100%;
          cursor: pointer;
          max-height: 95vh;
          border: 3px var(--background-basic-color-3) solid;
          border-radius: 2%;
        "
        class="modal-hover-opacity"
      />
    </nb-card-body>
  </nb-card>
</ng-template>

<!-- UPDATE MONITORING COMPANY DIALOG -->
<ng-template #modifyMCTemplate let-modifyMCTemplate="dialogRef">
  <nb-card class="dialog-box">
    <nb-card-header class="sc-text-center">Monitoring Company </nb-card-header>
    <nb-card-body>
      <nb-alert status="danger">
        WARNING: This cannot be changed once set
      </nb-alert>
      <div class="form-floating">
        <input
          class="form-control"
          fullWidth
          (input)="onMcSearch($event)"
          nbInput
          type="text"
          placeholder="Search/Select Monitoring Company"
          [nbAutocomplete]="mcAutoCOmplete"
        /><label>Search/Select Monitoring Company</label>
      </div>
      <nb-autocomplete #mcAutoCOmplete>
        <nb-option (click)="openAddMonitoringCompany()">
          Add Monitoring Company</nb-option
        >
        <nb-option (click)="addMC(mc)" *ngFor="let mc of monitoringCompanyList"
          >{{ mc?.company_name }}
        </nb-option>
      </nb-autocomplete>

      <div class="sc-mt-6">
        <button
          nbButton
          fullWidth
          size="giant"
          (click)="modifyMCTemplate.close()"
        >
          Cancel
        </button>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
<!--enlarge image template-->

<ng-template #previewTempRef let-data let-previewTempRef="dialogRef">
  <nb-card style="margin: 0" class="gt-p-small">
    <nb-card-header>
      <div>
        {{ data?.description }}
        <div class="sc-floating-right">
          <nb-icon
            pack="eva"
            icon="close-outline"
            (click)="previewTempRef.close()"
          ></nb-icon>
        </div>
      </div>
    </nb-card-header>
    <div>
      <div style="position: absolute; left: 50%; top: 50%" *ngIf="!hideSpinner">
        <div id="wave">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>

      <img
        src="{{ data?.file_url }}"
        style="max-width: 100%; max-height: 90vh"
        class="modal-hover-opacity"
        (load)="hideSpinner = true"
      />
    </div>
  </nb-card>
</ng-template>
<!--enlarge image template-->

<!-- JOB ID DIALOG -->
<ng-template
  #modifyExternalJobIdTemplate
  let-data
  let-modifyExternalJobIdTemplate="dialogRef"
>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center">JOB ID </nb-card-header>

    <!-- Job Id Section start-->
    <div class="row">
      <div class="col form-floating">
        <input
          siz="giant"
          class="form-control"
          fullWidth
          nbInput
          type="text"
          placeholder="External Job ID"
          [(ngModel)]="externalNewJobId"
          autocomplete="off"
          maxlength="32"
        />
        <label>External Job ID</label>
      </div>
    </div>

    <div class="row row-cols-2 sc-mt-6">
      <div class="col">
        <button
          nbButton
          fullWidth
          size="giant"
          ghost
          (click)="modifyExternalJobIdTemplate.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          nbButton
          size="giant"
          [disabled]="
            externalOldJobId === externalNewJobId || !externalNewJobId
          "
          fullWidth
          (click)="updateJobId()"
          (click)="modifyExternalJobIdTemplate.close()"
        >
          Update
        </button>
      </div>
    </div>

    <!-- Request Detail Section end-->
  </nb-card>
</ng-template>
<!-- Add OnSite/Offsite datetime model -->
<ng-template
  #onsiteTimeTempalteRef
  let-onsiteTimeTempalteRef="dialogRef"
  let-data
>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center">
      Enter {{ data.name }} Date and Time
    </nb-card-header>

    <div class="row">
      <div class="col my-2">
        <div class="row row-cols-2">
          <div class="col">
            <div class="form-floating">
              <input
                id="mobiDayField"
                nbInput
                class="form-control"
                fullWidth
                [ngModelOptions]="{ standalone: true }"
                placeholder="Date"
                [nbDatepicker]="dateTimePicker0"
                [(ngModel)]="dateValue"
              /><label for="inputAssignee">Date</label>
              <nb-datepicker
                (dateChange)="formateNewStartDate($event)"
                #start_day
                #dateTimePicker0
                [min]="jobCreationDate"
              ></nb-datepicker>
            </div>
          </div>
          <div class="col">
            <div class="form-floating">
              <input
                nbInput
                id="mobiTimeField"
                class="form-control"
                type="text"
                [ngModelOptions]="{ standalone: true }"
                placeholder="onsite Time"
                fullWidth
                [ngxMatTimepicker]="timePicker1"
                [(ngModel)]="timeValue"
              /><label for="inputAssignee">Time </label>
              <ngx-mat-timepicker
                (timeSet)="formatNewStartTime($event)"
                #timePicker1
              ></ngx-mat-timepicker>
            </div>
          </div>
        </div>
      </div>

      <div class="my-1">
        <button
          (click)="onsiteTimeTempalteRef.close(data)"
          nbButton
          size="giant"
          fullWidth
          [disabled]="!dateValue && !timeValue"
          [status]="'primary'"
        >
          Update
        </button>
      </div>
      <div class="my-1">
        <button
          (click)="onsiteTimeTempalteRef.close(false); dateTimeValue = ''"
          nbButton
          fullWidth
          size="giant"
        >
          Cancel
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>

<!-- JOB WELFARE INTERVAL DIALOG -->
<ng-template
  #welfareIntervalTemplate
  let-data
  let-welfareIntervalTemplate="dialogRef"
>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center">Welfare Checks </nb-card-header>

    <nb-select
      class="welfare-check-dropdown"
      fullWidth
      placeholder="Welfare Checks"
      size="giant"
      [selected]="selectedInterval"
    >
      <nb-option
        *ngFor="let interval of intervalData"
        [value]="interval.value"
        (click)="updateInterval(interval.value)"
        >{{ interval.name }}</nb-option
      >
    </nb-select>

    <div class="row row-cols-1 sc-mt-6">
      <div class="col">
        <button
          nbButton
          fullWidth
          size="giant"
          ghost
          (click)="welfareIntervalTemplate.close()"
        >
          Cancel
        </button>
      </div>
    </div>

    <!-- Request Detail Section end-->
  </nb-card>
</ng-template>

<ng-template
  #changeScheduleTemplateRef
  let-changeScheduleTemplateRef="dialogRef"
>
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header class="sc-text-center">
      Change Schedule
      <nb-icon
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
        (click)="changeScheduleTemplateRef.close()"
      >
      </nb-icon>
    </nb-card-header>
    <form [formGroup]="updateSchedulerForm">
      <div class="sc-mt-6">
        <div class="row my-0 row-cols-2">
          <div class="form-floating">
            <input
              nbInput
              class="form-control"
              fullWidth
              id="endDay"
              placeholder="End Date"
              [nbDatepicker]="dateTimePicker1"
              formControlName="end_day"
              [(ngModel)]="endDate"
            /><label for="inputAssignee">End</label>
            <nb-datepicker
              (dateChange)="formateEndDate($event)"
              #end_day
              #dateTimePicker1
              [min]="validStartDate"
            ></nb-datepicker>
          </div>
          <div class="form-floating">
            <input
              nbInput
              class="form-control"
              type="text"
              placeholder="Job End Time"
              fullWidth
              id="endTime"
              [(ngModel)]="endTime"
              formControlName="end_time"
              [ngxMatTimepicker]="timePicker1"
            /><label for="inputAssignee">Job End Time</label>
            <ngx-mat-timepicker
              (timeSet)="formatEndTime($event)"
              #timePicker1
            ></ngx-mat-timepicker>
          </div>
        </div>
      </div>
    </form>
    <div class="sc-mt-6">
      <button
        (click)="updateSchedule(); changeScheduleTemplateRef.close()"
        nbButton
        fullWidth
        size="giant"
      >
        Update schedule
      </button>
    </div>
  </nb-card>
</ng-template>
