<nb-card class="gt-p-small">
  <nb-card-body *ngIf="!validateQR">
    <nb-tabset (changeTab)="changeTab($event)">
      <nb-tab tabTitle="{{ tabNames.tab1 }}">
        <!-- MOBILE VIEW -->
        <div class="mobile-only">
          <div
            class="d-flex"
            *ngIf="(isAdmin || isDispatchUser) && totalRows > 0"
          >
            <div class="flex-grow-1">
              <nb-form-field class="sc-search-input-box">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="text"
                    nbInput
                    #customerAutoInput
                    fullWidth
                    placeholder="Filter Checkpoints"
                    [(ngModel)]="globalFilter"
                    (keyup)="onFilterApply($event); paginationChange = true"
                  />
                  <label>Filter Checkpoints</label>
                </div>
                <nb-icon
                  nbSuffix
                  *ngIf="showTypeAhead === true"
                  [icon]="'search-outline'"
                  pack="eva"
                  (click)="paginationChange = true; onFilterApply(true)"
                ></nb-icon>
                <nb-icon
                  nbSuffix
                  *ngIf="showTypeAhead === false"
                  [icon]="'close-outline'"
                  class="sc-floating-right"
                  pack="eva"
                  (click)="paginationChange = true; onClearFilter()"
                ></nb-icon>
              </nb-form-field>
            </div>

            <div class="sc-add-btn">
              <button
                size="giant"
                nbButton
                class="sc-floating-right sc-ml-1 sc-mt-0"
                (click)="switchMobileView()"
              >
                <nb-icon
                  [icon]="
                    viewType == 'cardView' ? 'pin-outline' : 'grid-outline'
                  "
                  pack="eva"
                >
                </nb-icon>
              </button>
            </div>
          </div>

          <div *ngIf="isAdmin || isDispatchUser">
            <button
              title="Add Checkpoint"
              type="button"
              fullWidth
              hero
              status="primary"
              nbButton
              class="nbbutton-long-text my-1"
              size="giant"
              (click)="addCheckpoints()"
            >
              + Add Checkpoint
            </button>
          </div>

          <div *ngIf="(isAdmin || isDispatchUser) && qrToBeValidatedCount">
            <button
              class="nbbutton-long-text my-1"
              nbButton
              hero
              status="danger"
              fullWidth
              size="giant"
              (click)="validateCheckpoints()"
            >
              Validate Checkpoints
            </button>
          </div>

          <div>
            <button
              fullWidth
              nbButton
              class="nbbutton-long-text my-1"
              size="giant"
              (click)="openQRCode()"
              status="primary"
            >
              Register QR Code
            </button>
          </div>

          <div class="mt-1" *ngIf="isAdmin || isDispatchUser">
            <app-map-pins-view
              *ngIf="
                checkPointList && viewType == 'mapView' && !paginationChange
              "
              [listData]="checkPointList"
              [mapFrom]="'mobileCpPage'"
              (openItemPage)="onCheckpointRedirect($event)"
            ></app-map-pins-view>
          </div>

          <div
            class="row row-cols-1 gy-2"
            *ngIf="viewType == 'cardView' && (isAdmin || isDispatchUser)"
          >
            <div
              *ngFor="let cp of checkPointList"
              (click)="onCheckpointRedirect(cp)"
              class="sc-card"
            >
              <div class="table-card-data pointer">
                <div>
                  <div *ngIf="cp?.qr_code" class="mb-0 pb-0 sc-floating-right">
                    <img
                      src="assets/images/qr-code-logo.png"
                      alt=""
                      width="26"
                      *ngIf="cp?.qr_code"
                      class="mb-0 pb-0"
                    />
                  </div>
                  <span class="strong">
                    {{ cp?.name }}
                  </span>
                </div>
                <div class="mt-2">
                  {{ cp?.company }}
                </div>
                <div class="small-font">{{ cp?.partial_address }}</div>
              </div>
            </div>
          </div>
          <div *ngIf="isAdmin || isDispatchUser">
            <app-pagination-control
              [pageSize]="rows"
              [pageNum]="pageNum"
              [previous]="previous"
              [totalRows]="totalRows"
              [totalPages]="totalPages"
              (onClickPagination)="onClickPagination($event)"
              (onChangePagination)="onChangePagination($event)"
            >
            </app-pagination-control>
          </div>
        </div>
        <!-- DESKTOP VIEW -->
        <div class="large-view-only" *ngIf="isAdmin || isDispatchUser">
          <div class="d-flex">
            <div class="flex-grow-1" *ngIf="totalRows > 0">
              <nb-form-field class="sc-search-input-box">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="text"
                    nbInput
                    #customerAutoInput
                    fullWidth
                    placeholder="Filter Checkpoints"
                    [(ngModel)]="globalFilter"
                    (keyup)="onFilterApply($event); paginationChange = true"
                  />
                  <label>Filter Checkpoints</label>
                </div>
                <nb-icon
                  nbSuffix
                  *ngIf="showTypeAhead === true"
                  [icon]="'search-outline'"
                  pack="eva"
                  (click)="paginationChange = true; onFilterApply(true)"
                ></nb-icon>
                <nb-icon
                  nbSuffix
                  *ngIf="showTypeAhead === false"
                  [icon]="'close-outline'"
                  class="sc-floating-right"
                  pack="eva"
                  (click)="paginationChange = true; onClearFilter()"
                ></nb-icon>
              </nb-form-field>
            </div>

            <div class="sc-add-btn" *ngIf="isAdmin">
              <button
                title="Add Checkpoint"
                type="button"
                hero
                status="primary"
                nbButton
                size="giant"
                class="sc-mt-0"
                (click)="addCheckpoints()"
              >
                + Add Checkpoint
              </button>
            </div>

            <div
              *ngIf="(isAdmin || isDispatchUser) && qrToBeValidatedCount"
              class="sc-add-btn"
            >
              <button
                class="nbbutton-long-text sc-mt-0"
                nbButton
                hero
                status="danger"
                fullWidth
                size="giant"
                (click)="validateCheckpoints()"
              >
                Validate Checkpoints
              </button>
            </div>
            <div class="sc-add-btn" *ngIf="totalRows > 0">
              <button
                size="giant"
                nbButton
                class="sc-floating-right sc-ml-1 sc-mt-0"
                (click)="switchLargeView()"
              >
                <nb-icon
                  class="ico-color"
                  [icon]="
                    viewType == 'tableView' ? 'pin-outline' : 'menu-outline'
                  "
                  pack="eva"
                >
                </nb-icon>
              </button>
            </div>
          </div>
          <div>
            <app-map-pins-view
              *ngIf="
                checkPointList && viewType == 'mapView' && !paginationChange
              "
              [listData]="checkPointList"
              [mapFrom]="'desktopCpPage'"
              (openItemPage)="onCheckpointRedirect($event)"
            ></app-map-pins-view>
          </div>

          <div
            [ngClass]="mapLatLong ? 'row row-cols-2' : 'row row-cols-1'"
            #target
            *ngIf="checkPointList && viewType == 'tableView'"
          >
            <!-- TABLE OF CHECKPOINTS -->
            <div class="col">
              <app-table
                [desktopTableData]="checkPointList"
                [desktopTableDetails]="chekPointDetailsTableView"
                [tableStyle]="tableStyle"
                (rowClick)="onCheckPointClick($event)"
                (sortColumn)="sortColumn($event)"
                (searchColumn)="searchColumn($event)"
                [apiFilter]="'true'"
              ></app-table>
              <div>
                <app-pagination-control
                  [pageSize]="rows"
                  [pageNum]="pageNum"
                  [previous]="previous"
                  [totalRows]="totalRows"
                  [totalPages]="totalPages"
                  (onClickPagination)="onClickPagination($event)"
                  (onChangePagination)="onChangePagination($event)"
                >
                </app-pagination-control>
              </div>
            </div>

            <!-- IF ROW IS SELECTED, SHOW MAP AND BUTTONS -->
            <div class="col" *ngIf="selectedCp">
              <app-map-leaflet
                *ngIf="mapLatLong"
                [latLon]="mapLatLong"
                [mapId]="'checkpointLocation'"
              ></app-map-leaflet>

              <div
                class="sc-text-center fs-5 my-2"
                *ngIf="selectedCp?.address?.postcode"
              >
                {{
                  selectedCp?.address?.address3
                    ? selectedCp?.address?.address3 + " / "
                    : ""
                }}
                {{ selectedCp?.address?.address1 }}
                {{ selectedCp?.address?.address2 }}

                {{ selectedCp?.address?.city_name }}
                {{ selectedCp?.address?.state_code }}
                {{ selectedCp?.address?.postcode }}
              </div>
              <div class="row row-cols-2">
                <div class="col">
                  <button
                    class="nbbutton-long-text"
                    nbButton
                    size="giant"
                    (click)="onCheckpointRedirect(selectedCp)"
                    fullWidth
                  >
                    Edit Checkpoint
                  </button>
                </div>
                <div class="col">
                  <button
                    nbButton
                    (click)="redirectCompany(selectedCp)"
                    fullWidth
                    size="giant"
                  >
                    View Client
                  </button>
                </div>
                <div class="col-6" *ngIf="isAdmin">
                  <!-- Only allow administrators to delete checkpoints -->
                  <button
                    nbButton
                    outline
                    status="danger"
                    (click)="deleteCheckPoint(selectedCp)"
                    fullWidth
                    size="giant"
                  >
                    <nb-icon pack="eva" status="danger" icon="trash"></nb-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nb-tab>

      <!-- ? Comment out QR Section for production unless ready -->
      <!--QR Code section-->
      <nb-tab tabTitle="{{ tabNames.tab2 }}" *ngIf="isAdmin">
        <app-qr *ngIf="qrCodePage"> </app-qr>
      </nb-tab>

      <!-- Changes Tab -->
      <nb-tab
        tabTitle="{{ tabNames.tab3 }}"
        *ngIf="isAdmin || isDispatchUser === true"
      >
        <app-user-updates-log [eventType]="'checkpoint'">
        </app-user-updates-log>
      </nb-tab>
    </nb-tabset>
  </nb-card-body>
  <!--validate qr code section-->
  <div *ngIf="validateQR">
    <div class="sc-head5 mb-3">Validate Checkpoints</div>
    <p>
      {{
        checkPointList?.length
          ? "These are checkpoints registered by the guards that require validation. Checkpoints cannot be scanned or shown in reports until validated."
          : "There are no checkpoints needing validation."
      }}
    </p>

    <!-- MOBILE VIEW -->
    <div class="mobile-only">
      <div class="d-flex" *ngIf="!validateQR">
        <div class="flex-grow-1">
          <nb-form-field class="sc-search-input-box">
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                nbInput
                #customerAutoInput
                fullWidth
                placeholder="Filter Checkpoints"
                [(ngModel)]="globalFilter"
                (keyup)="onFilterApply($event); paginationChange = true"
              />
              <label>Filter Checkpoints</label>
            </div>
            <nb-icon
              nbSuffix
              *ngIf="showTypeAhead === true"
              [icon]="'search-outline'"
              pack="eva"
              (click)="paginationChange = true; onFilterApply(true)"
            ></nb-icon>
            <nb-icon
              nbSuffix
              *ngIf="showTypeAhead === false"
              [icon]="'close-outline'"
              class="sc-floating-right"
              pack="eva"
              (click)="paginationChange = true; onClearFilter()"
            ></nb-icon>
          </nb-form-field>
        </div>

        <div class="sc-add-btn">
          <button
            size="giant"
            nbButton
            class="sc-floating-right sc-ml-1 sc-mt-0"
            (click)="switchMobileView()"
          >
            <nb-icon
              [icon]="viewType == 'cardView' ? 'pin-outline' : 'grid-outline'"
              pack="eva"
            >
            </nb-icon>
          </button>
        </div>
      </div>

      <div class="mt-1">
        <app-map-pins-view
          *ngIf="checkPointList && viewType == 'mapView' && !paginationChange"
          [listData]="checkPointList"
          [mapFrom]="'mobileCpPage'"
          (openItemPage)="onCheckpointRedirect($event)"
        ></app-map-pins-view>
      </div>

      <div class="row row-cols-1 gy-2" *ngIf="viewType == 'cardView'">
        <div
          *ngFor="let cp of checkPointList"
          (click)="onCheckpointRedirect(cp)"
          class="sc-card"
        >
          <div class="table-card-data pointer">
            <div class="strong">
              {{ cp?.name }}
            </div>
            <div class="my-2">
              {{ cp?.company }}
            </div>
            <div class="mb-0 pb-0">
              <img
                src="assets/images/qr-code-logo.png"
                alt=""
                width="26"
                *ngIf="cp?.qr_code"
                class="mb-0 pb-0"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="sc-mt-6">
        <app-pagination-control
          [pageSize]="rows"
          [pageNum]="pageNum"
          [previous]="previous"
          [totalRows]="totalRows"
          [totalPages]="totalPages"
          (onClickPagination)="onClickPagination($event)"
          (onChangePagination)="onChangePagination($event)"
        >
        </app-pagination-control>
      </div>

      <div *ngIf="checkPointList?.length">
        <button
          nbButton
          fullWidth
          class="nbbutton-long-text"
          (click)="reloadPage()"
          size="giant"
        >
          Back
        </button>
      </div>

      <div *ngIf="!checkPointList?.length">
        <button
          nbButton
          fullWidth
          class="nbbutton-long-text"
          (click)="reloadPage()"
          size="giant"
        >
          Back
        </button>
      </div>
    </div>
    <!-- DESKTOP VIEW -->
    <div class="large-view-only">
      <div class="d-flex">
        <div class="flex-grow-1">
          <nb-form-field class="sc-search-input-box">
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                nbInput
                #customerAutoInput
                fullWidth
                placeholder="Filter Checkpoints"
                [(ngModel)]="globalFilter"
                (keyup)="onFilterApply($event); paginationChange = true"
              />
              <label>Filter Checkpoints</label>
            </div>
            <nb-icon
              nbSuffix
              *ngIf="showTypeAhead === true"
              [icon]="'search-outline'"
              pack="eva"
              (click)="paginationChange = true; onFilterApply(true)"
            ></nb-icon>
            <nb-icon
              nbSuffix
              *ngIf="showTypeAhead === false"
              [icon]="'close-outline'"
              class="sc-floating-right"
              pack="eva"
              (click)="paginationChange = true; onClearFilter()"
            ></nb-icon>
          </nb-form-field>
        </div>

        <div class="sc-add-btn">
          <button
            size="giant"
            nbButton
            class="sc-floating-right sc-ml-1 sc-mt-0"
            (click)="switchLargeView()"
          >
            <nb-icon
              class="ico-color"
              [icon]="viewType == 'tableView' ? 'pin-outline' : 'menu-outline'"
              pack="eva"
            >
            </nb-icon>
          </button>
        </div>
      </div>
      <div>
        <app-map-pins-view
          *ngIf="checkPointList && viewType == 'mapView' && !paginationChange"
          [listData]="checkPointList"
          [mapFrom]="'desktopCpPage'"
          (openItemPage)="onCheckpointRedirect($event)"
        ></app-map-pins-view>
      </div>

      <div
        [ngClass]="mapLatLong ? 'row row-cols-2' : 'row row-cols-1'"
        #target
        *ngIf="checkPointList && viewType == 'tableView'"
      >
        <!-- TABLE OF CHECKPOINTS -->
        <div class="col">
          <app-table
            [desktopTableData]="checkPointList"
            [desktopTableDetails]="chekPointDetailsTableView"
            [tableStyle]="tableStyle"
            (rowClick)="onCheckpointRedirect($event)"
            (sortColumn)="sortColumn($event)"
            [apiFilter]="'true'"
          ></app-table>
          <div class="sc-mt-6">
            <app-pagination-control
              [pageSize]="rows"
              [pageNum]="pageNum"
              [previous]="previous"
              [totalRows]="totalRows"
              [totalPages]="totalPages"
              (onClickPagination)="onClickPagination($event)"
              (onChangePagination)="onChangePagination($event)"
            >
            </app-pagination-control>
          </div>
        </div>
      </div>
    </div>
  </div>
</nb-card>

<ng-template #mapRef let-data>
  <nb-card class="dialog-box">
    <nb-card-header>
      {{ data?.cp?.name }}
    </nb-card-header>

    <app-map-leaflet
      [latLon]="{
        lat: data?.cp?.address?.latitude,
        lon: data?.cp?.address?.longitude
      }"
      [mapId]="'checkpointLocationRef'"
    ></app-map-leaflet>
    <!-- map section ends -->
  </nb-card>
</ng-template>
