<nb-layout>
  <nb-layout-column>
    <nb-card>
      <nb-card-header>
        <div class="gt-logo-mobile">
          <img
            src="../../../assets/images/Guard_Tracker-Stacked.png"
            alt="Guard Tracker"
          />
        </div>
        <div id="title">User Sign Up</div>
      </nb-card-header>
      <nb-card-body
        class="gt-margin-auto"
        *ngIf="!showSuccessMsg && !showErrorMsg"
      >
        <div>
          Enter the last 4 digits of your mobile number for verification.
        </div>
        <ng-container>
          <form
            [formGroup]="userPhoneForm"
            appFormEnterAction
            *ngIf="showPhoneValidation"
          >
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                nbInput
                id="last_4"
                formControlName="last_4"
                autocomplete="off"
                fullWidth
                placeholder="Enter Last 4 digit of mobile number"
                autofocus
                (keyup)="checkPhoneValidation($event)"
              />
              <label for="last_4">Mobile number</label>
            </div>
          </form>
          <form
            *ngIf="!showPhoneValidation"
            [formGroup]="userForm"
            (ngSubmit)="registerUser()"
            appFormEnterAction
          >
            <nb-stepper #stepper [linear]="linearMode">
              <nb-step [stepControl]="userForm" label="Name">
                <button nbButton nbStepperNext>Next</button>
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-xl-6 form-floating">
                    <input
                      class="form-control"
                      type="text"
                      nbInput
                      fullWidth
                      id="first_name"
                      formControlName="first_name"
                      autocomplete="off"
                      placeholder="First Name"
                      autofocus
                    />
                    <label for="first_name">First Name</label>
                  </div>
                  <div class="col-md-6 col-lg-6 col-xl-6 form-floating">
                    <input
                      fullWidth
                      class="form-control"
                      type="text"
                      nbInput
                      id="last_name"
                      formControlName="last_name"
                      autocomplete="off"
                      placeholder="Last Name"
                      autofocus
                    />
                    <label for="last_name">Last Name</label>
                  </div>
                </div>
              </nb-step>
              <nb-step [stepControl]="guardLicenseForm" label="Name">
                <div>
                  <button type="button" nbButton nbStepperPrevious>Prev</button>
                  <button
                    class="sc-floating-right"
                    *ngIf="!guardLicenseForm.valid"
                    nbButton
                    size="giant"
                    class="nbbutton-long-text"
                  >
                    Skip & Submit
                  </button>
                  <button
                    class="sc-floating-right"
                    *ngIf="guardLicenseForm.valid"
                    [disabled]="!guardLicenseForm.valid"
                    nbButton
                    size="giant"
                  >
                    Submit
                  </button>
                </div>

                <form [formGroup]="guardLicenseForm" appFormEnterAction>
                  <div>
                    <label for="issuer_state">State</label>
                    <nb-select
                      fullWidth
                      placeholder="State"
                      formControlName="issuer_state"
                      [(selected)]="selectedItem"
                    >
                      <nb-option
                        *ngFor="let state of stateData"
                        [value]="state.code"
                        (click)="selectState(state)"
                        >{{ state.code }}</nb-option
                      >
                    </nb-select>
                  </div>
                  <nb-form-field>
                    <div class="form-floating">
                      <input
                        class="form-control"
                        type="text"
                        fullWidth
                        nbInput
                        id="license_class"
                        formControlName="license_class"
                        autocomplete="off"
                        placeholder="Licence Class"
                        [(ngModel)]="newlicense"
                        (input)="onLicenceClassSearch($event)"
                        [nbAutocomplete]="licenseClassAutoNGModel"
                      />
                      <label for="license_class"
                        >Licence Class / Category</label
                      >
                    </div>
                    <nb-icon
                      *ngIf="showLicenseAddClass"
                      (click)="addNewClass(newlicense)"
                      [icon]="'plus-outline'"
                      nbSuffix
                      pack="eva"
                    ></nb-icon>
                  </nb-form-field>

                  <nb-autocomplete #licenseClassAutoNGModel>
                    <nb-option
                      *ngFor="let item of licenseClassData"
                      [value]="item.license_class_name"
                    >
                      <div>
                        {{ item.license_class_name }}
                      </div>
                    </nb-option>
                  </nb-autocomplete>

                  <div class="form-floating">
                    <input
                      class="form-control"
                      type="text"
                      nbInput
                      fullWidth
                      id="license_number"
                      formControlName="license_number"
                      autocomplete="off"
                      placeholder="Security Licence ID Number"
                    />
                    <label for="license_number"
                      >Security Licence ID Number</label
                    >
                  </div>
                  <div class="form-floating">
                    <input
                      class="form-control"
                      nbInput
                      fullWidth
                      placeholder="Licence Expiry"
                      [nbDatepicker]="dateTimePicker1"
                      formControlName="valid_till"
                    />
                    <label for="license_number">Licence Expiry</label>
                    <nb-datepicker #dateTimePicker1></nb-datepicker>
                  </div>
                </form>
              </nb-step>
            </nb-stepper>
          </form>
        </ng-container>
      </nb-card-body>
      <nb-card-body>
        <nb-alert
          *ngIf="showSuccessMsg == true"
          aria-label="Sign-In"
          accent="info"
          >{{ successMessage }}
        </nb-alert>
        <ng-container *ngIf="showErrorMsg">
          <nb-alert aria-label="Sign-In" accent="warning"
            >{{ showErrorMsg }}
          </nb-alert>
          <div *ngIf="showErrorMsg"></div>
          <form [formGroup]="userEmailForm" appFormEnterAction>
            <div class="form-floating">
              <input
                fullWidth
                class="form-control"
                type="text"
                nbInput
                id="email"
                autocomplete="off"
                placeholder="Email Address"
                autofocus
                formControlName="email"
                autocorrect="off"
                autocapitalize="off"
              />
              <label for="email">Email Address</label>
            </div>
            <div>
              <button
                class="sc-floating-right"
                type="button"
                [disabled]="!userEmailForm.valid"
                (click)="refreshExpiredLink()"
                nbButton
                status="success"
              >
                Submit
              </button>
            </div>
          </form>
        </ng-container>
      </nb-card-body>
    </nb-card>
  </nb-layout-column>

  <nb-layout-footer>
    <span>
      <p>
        Copyright © 2024
        <a href="https://sendem.com.au/" target="blank">Sendem Pty Ltd</a>. All
        Rights Reserved.
      </p>
    </span>
  </nb-layout-footer>
</nb-layout>
