import { Location } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { AddCustomerComponent } from 'src/app/shared/comp/add-customer/add-customer.component';
import { AddEditSiteComponent } from 'src/app/shared/comp/add-edit-site/add-edit-site.component';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { ContactComponent } from 'src/app/shared/comp/contact/contact.component';
import { EventScheudulerComponent } from 'src/app/shared/comp/event-scheuduler/event-scheuduler.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { PagesService } from '../../pages.service';
import { companyTableDetails } from '../../tableDetails';
import { AddEditUsersComponent } from '../../users/add-edit-users/add-edit-users.component';
import { UsersService } from '../../users/users.service';
import { CustomerService } from '../customer.service';

@Component({
  selector: 'app-view-customer',
  templateUrl: './view-customer.component.html',
  styleUrls: ['./view-customer.component.scss'],
})
export class ViewCustomerComponent implements OnInit {
  customerForm: UntypedFormGroup = new UntypedFormGroup({
    is_individual: new UntypedFormControl(false, Validators.required),
    company_name: new UntypedFormControl(),
    address1: new UntypedFormControl('', Validators.required),
    address2: new UntypedFormControl('', Validators.required),
    address3: new UntypedFormControl(''),
    addressLookup: new UntypedFormControl(''),
  });
  contactForm: UntypedFormGroup = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    title: new UntypedFormControl(),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    mobile_number: new UntypedFormControl(null, [
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9]*$'),
    ]),
  });
  @Input() detailData: any = [];
  selectedEmail: any;
  hasJobs: boolean = false;
  hasCheckPoints: boolean = false;
  contactListTemp: any = [];
  siteContactListTemp: any = [];
  siteData: any = [];
  globalFilter: any;
  isFilterApplied: boolean = false;
  showTypeAhead: boolean = false;
  totalSiteCount: number = 0;
  previous: number = 0;
  pageSize: number = 5;
  pageNum: number = 1;
  totalPages: number = 0;
  searchString: any;
  showDetails: boolean = false;
  clientId: Number = 0;
  selectedEvent: any;
  userStr: any;
  userEventDetails = companyTableDetails?.mobileTable;
  userEventDetailsTableView = companyTableDetails?.desktopTable;
  clientHistory: any = [];

  tableStyle = {
    'overflow': 'auto',
    'max-height': '400px',
  };
  dateRange: any;
  clientData: any;
  dialogref: any;
  editUserDialogdialogref: any;
  mapLatLong: any;
  uploadedImage: any;
  selectedSite: any;

  siteCount: number = 0;
  showSiteList: boolean = false;
  fullSiteList: any = [];
  selectedUsers: any = [];
  usersList: any = [];
  disableInputField: boolean = false;
  userListData: any = [];
  userGroupListData: any = [];
  userData: any;
  selectedUserGroups: any = [];
  disableGroupInputField: boolean = false;
  userGroupList: any = [];
  isAdmin: any;
  isDispatchUser: any;
  newInfromation: any;
  patrolReportSchedules: any = [];
  clientFiles: any = [];
  selectedSiteFiles: any = [];
  uploadFile: any;
  fileUploadError: any;
  fileDescription: any;
  slicedClientFiles: any = [];
  slicedSelectedSiteFiles: any = [];

  selectedSiteKeys: any = [];
  slicedSelectedSiteKeys: any = [];
  hideSpinner: boolean = true;
  selectedSiteId: any;

  siteKeyList: any = [];

  siteReportUsers: any = [];
  slicedSiteReportUsers: any = [];
  apiCallMade: boolean = false;
  lastSearchStr: any;
  lastApiResponseHadData: boolean = true;

  constructor(
    private dialogService: NbDialogService,
    private customerService: CustomerService,
    private spinnerService: NgxSpinnerService,
    private toasterService: NbToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private userService: UsersService,
    private pageService: PagesService,
    private dataCheckService: DataCheckService,
    private _location: Location
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
        this.editUserDialogdialogref?.close();
      }
    });
    this.route.params.subscribe({
      next: (params) => {
        if (params?.['cKey']) {
          this.clientId = params?.['cKey'];
          this.getCustomerDetailById();
        }
      },
    });
  }

  ngOnInit(): void {
    if (window.localStorage.getItem('userData')) {
      this.userData = this.appService.getUserData();
      this.isAdmin = this.dataCheckService.isUserAdmin();
      this.isDispatchUser = this.dataCheckService.isDispatchUser();
    }
  }
  formatData(response: any) {
    this.clientData = response;
    this.clientFiles = response['company_files'];
    this.slicedClientFiles = this.clientFiles?.slice(0, 3);
    this.detailData = response['data'];
    this.clientHistory = response['history'];
    this.hasJobs = Boolean(response?.data?.company_stats?.job_count);
    this.hasCheckPoints = Boolean(
      response?.data?.company_stats?.checkpoint_count
    );
    this.contactListTemp = response['contacts'] || [];
    this.siteCount = response?.data?.company_stats?.site_count;
    this.siteData = response['sites'] || [];
    this.userListData = response['users'] || [];
    this.userGroupListData = response['user_groups'] || [];
    this.patrolReportSchedules = response['patrol_report_schedules'] || [];
  }
  onTableRowClick(event: any) {
    this.showDetails = true;
    this.selectedEvent = event;

    if (event?.gps) this.mapLatLong = { ...event?.gps };
    this.uploadedImage = event?.file;
  }
  getCustomerDetailById() {
    this.spinnerService.show();
    this.customerService
      .getCompanyDetailById(this.clientId)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.formatData(response);
        }
        this.spinnerService.hide();
      });
  }
  getSiteDetailById(siteId: any) {
    this.selectedSiteId = siteId;
    this.spinnerService.show();
    this.customerService
      .getSiteById(this.selectedSiteId)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.formatSiteData(response);
        }
        this.spinnerService.hide();
      });
  }
  formatSiteData(response: any) {
    this.siteKeyList = [];
    this.selectedSite = response?.data;
    this.siteContactListTemp = this.selectedSite?.contacts || [];
    let gpscoords = {
      lat: this.selectedSite?.address?.latitude,
      lon: this.selectedSite?.address?.longitude,
    };
    this.mapLatLong = gpscoords;
    this.showSiteList = true;
    this.selectedSiteFiles = this.selectedSite?.site_files || [];
    this.slicedSelectedSiteFiles = this.selectedSiteFiles?.slice(0, 3);

    this.selectedSiteKeys = this.selectedSite?.site_keys || [];
    this.slicedSelectedSiteKeys = this.selectedSiteKeys?.slice(0, 3);

    this.siteReportUsers = this.selectedSite?.report_users || [];
    this.slicedSiteReportUsers = this.siteReportUsers?.slice(0, 3);

    this.selectedSiteKeys?.forEach((item: any) =>
      this.siteKeyList.push({ value: item })
    );
  }
  getCustomerContacts() {
    this.spinnerService.show();
    this.customerService
      .getCustomerContacts(this.clientId)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.contactListTemp = response['data'];
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      });
  }

  getCustomerSitesById() {
    let params: any = { company: this.clientId, site_contacts: 1 };
    if (this.pageSize) {
      params['rows'] = this.pageSize;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }
    if (this.searchString) {
      params['search_str'] = this.searchString;
    }

    this.spinnerService.show();
    this.customerService.getSites(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.fullSiteList = response['data'];
        this.totalSiteCount = response['total_size'];
        if (this.totalSiteCount > this.pageSize) this.showTypeAhead = true;

        this.totalPages = Math.ceil(this.totalSiteCount / this.pageSize);
      }
      this.spinnerService.hide();
    });
  }

  addToContactsTemp(patrolReportUsers = false) {
    if (this.isAdmin || this.isDispatchUser) {
      if (this.selectedEmail) {
        this.contactForm.controls['email'].setValue(this.selectedEmail);
      }
      let siteData: any;
      if (this.selectedSite) {
        siteData = this.selectedSite;
      }
      let clientData: any = this.detailData;
      clientData['contacts'] = this.contactListTemp;
      this.dialogref = this.dialogService.open(ContactComponent, {
        context: {
          clientData: clientData,
          siteData: siteData,
          contactForm: this.contactForm,
          patrolReportUsers: patrolReportUsers,
          contact_type: patrolReportUsers ? 'jc' : 'lc',
        },
        dialogClass: 'model-full',
      });
      this.dialogref.onClose.subscribe((value: any) => {
        this.spinnerService.hide();
        if (value !== 'false') {
          if (this.selectedSite?.id) {
            this.getSiteDetailById(this.selectedSiteId);
          } else {
            this.getCustomerDetailById();
          }
          this.editUserDialogdialogref?.close();
        }
      });
    }
  }

  addNewSite() {
    this.dialogref = this.dialogService.open(AddEditSiteComponent, {
      context: { detailData: { company: this.detailData?.id } },
      dialogClass: 'model-full',
    });
    this.dialogref.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.selectedSite = value;
        this.getCustomerDetailById();
        this.getCustomerSitesById();
      }
    });
  }

  openJobView() {
    this.router.navigate([
      '/jobs',
      {
        clientId: this.clientId,
      },
    ]);
  }
  openCPView() {
    this.router.navigate([
      '/checkpoints',
      {
        clientId: this.clientId,
      },
    ]);
  }
  updateData() {}

  getSiteContacts() {
    this.spinnerService.show();
    this.customerService
      .getSiteContacts(this.selectedSite?.id)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.siteContactListTemp = response['data'];
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      });
  }
  primaryContact(contact: any) {
    this.customerService
      .editLocationContact(contact?.id)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.pageService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
          this.getSiteContacts();
        }
      });
  }

  onFilterApply(event: any) {
    if (!event?.target?.value) {
      this.onClearFilter();
    }

    if (event?.target?.value && event?.target?.value?.length >= 3) {
      this.isFilterApplied = true;
      this.searchString = event?.target?.value;
      this.getCustomerSitesById();
    }
    if (
      event === true &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.isFilterApplied = true;
    } else if (
      event.key === 'Enter' &&
      this.globalFilter != '' &&
      this.globalFilter != null
    ) {
      this.isFilterApplied = true;
    }
  }
  onClearFilter() {
    this.globalFilter = '';
    this.isFilterApplied = false;
    this.searchString = null;
    this.getCustomerSitesById();
  }

  clientStatusChange() {
    let status = this.detailData?.is_active ? 'Deactivate' : 'Activate';

    this.dialogref = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: `${status}  client? `,
        message: ` Do you want to ${status.toLowerCase()} this client? `,
      },
    });
    this.dialogref.onClose.subscribe((value: any) => {
      if (value === 'Yes') {
        this.customerService
          .enableDisableCompanyById(this.detailData?.id)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.formatData(response);
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            }
            this.pageService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          });
      }
    });
  }

  openEditTemplate() {
    this.dialogref = this.dialogService.open(AddCustomerComponent, {
      context: { updateView: true, companyData: this.detailData },
      dialogClass: 'model-full',
    });
    this.dialogref.onClose.subscribe((value: any) => {
      this.spinnerService.hide();
      if (value !== 'close') {
        this.formatData(value);
      }
    });
  }

  addNewSiteContact() {
    if (this.isAdmin || this.isDispatchUser) {
      this.dialogref = this.dialogService.open(ContactComponent, {
        context: {
          contactForm: this.contactForm,
          siteData: this.selectedSite,
          contact_type: 'lc',
        },
        dialogClass: 'model-full',
      });
      this.dialogref.onClose.subscribe((value: any) => {
        if (value !== 'false') {
          this.siteContactListTemp = value;

          this.getSiteDetailById(this.selectedSiteId);
        }
      });
    }
  }

  editSite(site: any, name = true) {
    if (this.isAdmin || this.isDispatchUser) {
      const addSiteDialogRef = this.dialogService.open(AddEditSiteComponent, {
        context: { detailData: site, editSite: true, siteName: name },
        dialogClass: 'model-full',
      });
      addSiteDialogRef.onClose.subscribe((value) => {
        if (value !== 'close') {
          this.selectedSite = value;
          this.getCustomerSitesById();
          this.getSiteDetailById(this.selectedSiteId);
        }
      });
    }
  }
  onClickPagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;

    this.getCustomerSitesById();
  }
  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;

    this.getCustomerSitesById();
  }
  getUsers(event: any = null) {
    if (this.userStr && this.userStr.length > 2) {
      this.apiCallMade = true;
    }
    if (this.selectedUsers.some((user: any) => user?.id === 0)) {
      this.toasterService.danger(
        '',
        'Already selected anyone. Remove that to add particular users'
      );
    } else {
      let value = event?.target?.value;
      let params: any = { union: 1, company_id: this.detailData?.id };
      if (value) {
        params['search_str'] = value;
      }
      if (this.lastSearchStr) {
        !this.userStr.includes(this.lastSearchStr)
          ? (this.apiCallMade = true)
          : (this.apiCallMade = false);
      } else {
        this.apiCallMade = true;
      }
      if (this.apiCallMade || this.lastApiResponseHadData) {
        this.customerService
          .searchLocationContacts(params)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              let users = response['data'];
              users = users.filter(
                (value1: any) =>
                  !this.selectedUsers.some(
                    (value2: any) => value1.pk === value2.pk
                  )
              );
              this.usersList = users;
              this.lastApiResponseHadData = this.usersList.length > 0;
              this.lastSearchStr = this.userStr;
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    }
  }

  removeUser(deleteUser: any): void {
    this.selectedUsers = this.selectedUsers.filter(
      (user: any) => user?.pk !== deleteUser?.pk
    );

    if (deleteUser?.id === 0 || !this.selectedUsers.length)
      this.disableInputField = false;
  }

  addUser(user: any): void {
    if (this.selectedUsers.some((_user: any) => _user?.pk === user?.pk)) {
      this.toasterService.warning('', 'User/Email already added');
    } else {
      let data = {
        pk: user?.pk,
        email: user?.user_email,
        first_name: user?.user_first_name,
        last_name: user?.user_last_name,
        full_name: user?.user_full_name,
        location_contact_id: user?.location_contact_id,
        mobile_number: user?.user_mobile_number,
      };

      this.selectedUsers.push(data);
      this.usersList = this.usersList.filter(
        (value1: any) =>
          !this.selectedUsers.some((value2: any) => value1.pk === value2.pk)
      );
    }
  }

  async getRequestData(listData: any) {
    return new Promise(async (resolve) => {
      if (!listData.length) resolve(null);
      let data: any = {};
      await Promise.all(
        listData.map((item: any) => {
          const {
            pk,
            full_name,
            email,
            is_only_location_contact,
            id,
            ...rest
          } = item;
          data[item.pk] = {
            ...rest,
          };
        })
      );

      resolve(data);
    });
  }
  addRemoveSiteReportUsers(userIds: any) {
    this.spinnerService.show();

    this.customerService
      .updateSite(
        this.selectedSite?.id,
        {
          user_ids: userIds,
        },
        { manage_report_users: 1 }
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.formatSiteData(response);
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }
  addRemoveClientReportUsers(userIds: any) {
    this.spinnerService.show();

    this.customerService
      .updateClient(
        this.detailData?.id,
        {
          user_ids: userIds,
        },
        { manage_report_users: 1 }
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.formatData(response);
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }
  async addRemoveUsers() {
    let userIds = await this.getRequestData(this.selectedUsers);
    if (this.selectedSite?.id) {
      this.addRemoveSiteReportUsers(userIds);
    } else {
      this.addRemoveClientReportUsers(userIds);
    }
  }

  openTemplate(template: TemplateRef<any>) {
    this.userStr = '';
    this.selectedUsers = [];
    if (this.selectedSite?.id) {
      this.selectedSite?.report_users?.forEach((item: any) => {
        this.selectedUsers.push(item);
      });
    } else {
      this.clientData?.users?.forEach((item: any) => {
        this.selectedUsers.push(item);
      });
    }

    if (this.isAdmin || this.isDispatchUser) {
      this.editUserDialogdialogref = this.dialogService.open(template, {
        context: {},
      });
    }
  }
  openUserDialogBox(user: any) {
    const dialogRefUser = this.dialogService.open(AddEditUsersComponent, {
      context: {
        editUser: true,
        detailData: user,
      },
    });
    dialogRefUser.onClose.subscribe((value: any) => {
      if (value !== 'false') {
        if (this.selectedSite) {
          this.getSiteDetailById(this.selectedSiteId);
        } else {
          this.getCustomerDetailById();
        }
      }
    });
  }

  openSiteContactTemplate(
    data: any,
    clientData: any,
    siteData: any,
    contact_type: any
  ) {
    this.dialogref = this.dialogService.open(ContactComponent, {
      context: {
        contactData: data,
        clientData: clientData,
        siteData: siteData,
        contact_type: contact_type,
      },
      dialogClass: 'model-full',
    });
    this.dialogref.onClose.subscribe((dialogResponse: any) => {
      if (dialogResponse !== 'false') {
        if (clientData) {
          this.getCustomerDetailById();
        }
        if (siteData) {
          this.getSiteDetailById(this.selectedSiteId);
        }
      }
    });
  }
  openSchedulerTemplate(schedule?: any) {
    this.dialogref = this.dialogService.open(EventScheudulerComponent, {
      context: { detailData: this.detailData, scheduleData: schedule },
    });
    this.dialogref.onClose.subscribe((value: any) => {
      if (value !== 'false') {
        this.formatData(value);
      }
    });
  }
  openInformationTemplate(template: TemplateRef<any>, siteInfo = false) {
    this.newInfromation = siteInfo
      ? this.selectedSite?.information
      : this.detailData?.information;
    this.dialogref = this.dialogService.open(template, {
      context: { siteInfo: siteInfo },
    });
  }
  openManagerInfoTemplate(template: TemplateRef<any>) {
    this.newInfromation = this.selectedSite?.manager_notes;
    this.dialogref = this.dialogService.open(template, {
      context: {},
    });
  }
  openFileUploadTemplate(template: TemplateRef<any>) {
    this.fileUploadError = null;
    this.fileDescription = null;
    this.dialogref = this.dialogService.open(template, {
      context: {},
    });
  }
  openSiteKeysTemplate(template: TemplateRef<any>) {
    if (!this.siteKeyList?.length) {
      this.siteKeyList.push({});
    }
    this.dialogref = this.dialogService.open(template, {
      context: {},
    });
  }

  updateClientInformation() {
    this.spinnerService.show();
    this.customerService
      .updateClient(
        this.detailData?.id,
        {
          information: this.newInfromation,
        },
        { update_information: 1 }
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.formatData(response);
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }
  async fileCheck(fileExtension: string, fileSize: number) {
    let fileLimit: number = 1024 * 1024 * 8; // 8MB
    return new Promise((resolve) => {
      if (!['pdf', 'jpeg', 'jpg', 'png'].includes(fileExtension)) {
        this.fileUploadError = 'Unsupported file format';
        resolve(false);
      } else if (fileSize > fileLimit) {
        this.fileUploadError = 'File Too Large ' + fileSize + ' / ' + fileLimit;
        resolve(false);
      } else {
        this.fileUploadError = null;
        resolve(true);
      }
    });
  }
  async handleFileInput(event: any) {
    let file = event?.target?.files.item(0);
    let fileSize: number = file.size;
    let fileName: string = file.name;
    let fileExtension: string = fileName.split('.').pop() || '';
    let fileCheck = await this.fileCheck(fileExtension, fileSize);
    if (fileCheck) {
      this.uploadFile = event?.target?.files.item(0);
    }
  }

  onUploadFile() {
    if (this.uploadFile) {
      this.spinnerService.show();
      let fileData: FormData = new FormData();
      fileData.append('file', this.uploadFile);
      fileData.append('upload_files', '1');
      fileData.append('description', this.fileDescription);
      let urlPath = this.selectedSite?.id
        ? `site/${this.selectedSite?.id}/update`
        : `company/${this.detailData?.id}/update`;

      this.appService.formDataApi(urlPath, fileData).then((response: any) => {
        if (response['status'] == 'success') {
          if (this.selectedSite?.id) {
            this.formatSiteData(response);
          } else {
            this.formatData(response);
          }
          this.fileUploadError = null;
          this.fileDescription = null;
          this.uploadFile = null;
          this.spinnerService.hide();
        } else {
          this.fileUploadError = null;
          this.fileDescription = null;
          this.uploadFile = null;
          this.spinnerService.hide();
          // TODO: Toaster to show status and message to user
        }
      });
    }
  }
  onOpenFile(file: any, template: TemplateRef<any>) {
    this.hideSpinner = false;
    if (file?.extension === '.pdf') {
      this.downloadFile(file);
    } else {
      this.dialogref = this.dialogService.open(template, {
        closeOnBackdropClick: true,
        context: file,
      });
    }
  }
  onOpenImage(event: any, template: TemplateRef<any>) {}
  downloadFile(file: any) {
    const a = document.createElement('a');
    a.href = file?.file_url;
    a.download = `${file?.name}${file?.extension}`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  deleteSiteFile(file: any) {
    this.customerService
      .updateSite(this.selectedSite?.id, {
        file_id: file?.id,
        delete_file: 1,
      })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.formatSiteData(response);
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }
  deleteClientFile(file: any) {
    this.customerService
      .updateClient(this.detailData?.id, {
        file_id: file?.id,
        delete_file: 1,
      })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.formatData(response);
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }
  deleteFile(file: any) {
    let dialogMsg = `Permanently delete file: ${file.description}`;
    this.dialogref = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: `Delete File`,
        message: dialogMsg,
      },
    });
    this.dialogref.onClose.subscribe((value: any) => {
      if (value === 'Yes') {
        this.spinnerService.show();
        if (this.selectedSite?.id) {
          this.deleteSiteFile(file);
        } else {
          this.deleteClientFile(file);
        }
      }
    });
  }
  archiveUnarchive() {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: `${this.detailData?.is_active ? 'Delete' : 'Restore'}  Client `,
        message: `${
          this.detailData?.is_active
            ? 'You will not be able to create jobs or scan checkpoints for deleted clients.'
            : 'Restore client'
        } `,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.spinnerService.show();
        this.customerService
          .enableDisableCompanyById(this.detailData?.id)
          .subscribe((response: any) => {
            if (response?.status == 'success') {
              this._location.back();
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.spinnerService.hide();
          });
      }
    });
  }
  updateSiteInformation() {
    this.spinnerService.show();
    this.customerService
      .updateSite(
        this.selectedSite?.id,
        {
          information: this.newInfromation,
        },
        { update_information: 1 }
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.formatSiteData(response);
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }
  shiftFocus(elementId: string, time?: number) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element.focus();
    }, time);
  }
  addNewSiteKeyField(index: number) {
    this.siteKeyList.push({});
    this.shiftFocus(`siteKey${index + 1}`, 500);
  }
  deleteSiteKeyField(index: number) {
    this.siteKeyList.splice(index, 1);
    if (!this.siteKeyList?.length) {
      this.siteKeyList.push({});
    }
  }
  updateSiteKeys() {
    this.spinnerService.show();
    this.customerService
      .updateSite(
        this.selectedSite?.id,
        {
          site_keys: this.siteKeyList?.map((item: any) => item?.value),
        },
        { update_site_keys: 1 }
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.formatSiteData(response);
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }
  updateSiteManagerNotes() {
    this.spinnerService.show();
    this.customerService
      .updateSite(
        this.selectedSite?.id,
        {
          manager_notes: this.newInfromation,
        },
        { update_manager_notes: 1 }
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.formatSiteData(response);
        } else {
          this.pageService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }
  deleteSite(site: any) {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: ` Delete Site `,
        message: `You will not be able to create jobs or incidents for deleted Sites. `,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.spinnerService.show();
        this.customerService
          .enableDisableSiteById(site?.id)
          .subscribe((response: any) => {
            if (response?.status == 'success') {
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
              if (this.showSiteList === true) {
                this.selectedSite = undefined;
                this.getCustomerSitesById();
              }
              this.getCustomerDetailById();
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.spinnerService.hide();
          });
      }
    });
  }
}
