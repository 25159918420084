<div
  [id]="mapFrom"
  class="map"
  style="position: relative; z-index: 0; max-height: 100%; max-width: 100%"
></div>

<ng-template #itemDetailRef let-data let-siteCTempRef="dialogRef">
  <nb-card class="pointer">
    <nb-card-body>
      <!-- Job Pin ToolTip Template -->
      <div
        class="table-card-data"
        *ngIf="['mobileJobPage', 'desktopJobPage'].includes(data?.mapFrom)"
      >
        <div class="mb-2">
          <div class="job-card-small-text">
            {{ data?.service_type }} - {{ data?.job_key }}
            <div class="sc-floating-right">
              <div>{{ data?.status }}</div>
            </div>
          </div>
          <div class="strong mb-1">
            {{ data?.company }}
          </div>
          <div>{{ data?.sites }}</div>
        </div>
        <div class="job-card-small-text">
          CREATED: {{ data?.created_date | customdate : "d MMM y HH:mm" }}
        </div>
        <div
          class="job-card-small-text"
          [ngStyle]="{
            color: data?.staleJob ? 'var(--color-danger-500)' : ''
          }"
        >
          UPDATED: {{ data?.modified_date | customdate : "d MMM y HH:mm" }}
        </div>
      </div>

      <!-- Checkpoint Pin ToolTip Template -->
      <div
        class="table-card-data pointer"
        *ngIf="['mobileCpPage', 'desktopCpPage'].includes(data?.mapFrom)"
      >
        <div class="row row-cols-2">
          <div class="col-10 col-sm-11">
            <div class="strong">
              {{ data?.name }}
            </div>
            <div class="my-2">
              {{ data?.company }}
            </div>
            <small>
              {{ data?.address?.full_address }}
            </small>
          </div>
        </div>
      </div>

      <!-- Incidnet Pin ToolTip Template -->
      <div
        class="table-card-data pointer"
        *ngIf="
          ['mobileIncidentPage', 'desktopIncidentPage'].includes(data?.mapFrom)
        "
        [ngStyle]="{
          'background-color': data?.submitted_at
            ? 'var(--color-success-500)'
            : ''
        }"
      >
        <div class="strong">
          {{ data?.incident_key }}
        </div>

        <small>
          {{ data?.full_address }}
        </small>
        <div *ngIf="data?.submitted_at">
          Submitted At :
          {{ data?.submitted_at | customdate : "d MMM y HH:mm" }}
        </div>
      </div>
      <!-- Guard Location Pin ToolTip Template -->
      <div
        class="table-card-data pointer p-0"
        *ngIf="
          ['guardLocationDesktop', 'guardLocationMobile'].includes(
            data?.mapFrom
          )
        "
      >
        <div class="">
          {{ data?.user_name }}
        </div>

        <div class="small-font">
          Last Seen At: {{ data?.updated_at | customdate : "d MMM y HH:mm" }}
        </div>
        <div
          class="mt-3 sc-text-center guard-location-call"
          *ngIf="data?.mobile_number"
        >
          <a href="tel:{{ data?.mobile_number }}">
            <nb-icon pack="eva" icon="phone-call" status="success"></nb-icon>
            Call
          </a>
        </div>
      </div>

      <!-- Guard Location Pin ToolTip Template -->
      <div
        class="table-card-data pointer p-0"
        *ngIf="
          [
            'guardLocationHistoryMobile',
            'guardLocationHistoryDesktop'
          ].includes(data?.mapFrom)
        "
      >
        <div class="small-font">
          {{ data?.event_type }}
        </div>
        <div class="small-font">
          {{ data?.updated_at | customdate : "d MMM y HH:mm" }}
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
