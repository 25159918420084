import { Component, Input, OnInit, Optional } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerService } from '../../customer/customer.service';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-add-edit-monitoring-company',
  templateUrl: './add-edit-monitoring-company.component.html',
  styleUrls: ['./add-edit-monitoring-company.component.scss'],
})
export class AddEditMonitoringCompanyComponent implements OnInit {
  @Input() updateView: any;
  @Input() name: String = '';
  @Input() companyData: any;
  companyForm: UntypedFormGroup = new UntypedFormGroup({
    company_name: new UntypedFormControl('', [Validators.required]),
  });
  constructor(
    private customerService: CustomerService,
    private spinnerService: NgxSpinnerService,
    @Optional()
    protected dialogRef: NbDialogRef<AddEditMonitoringCompanyComponent>,

    private pageService: PagesService
  ) {}

  ngOnInit(): void {
    if (this.updateView === false) {
      this.companyForm.reset();
      if (this.name) {
        this.companyForm.controls['company_name'].setValue(this.name);
      }
    } else {
      this.companyForm.controls['company_name'].setValue(
        this.companyData.company_name
      );
    }
  }
  updateData() {
    if (this.companyForm.valid) {
      var requestData = this.companyForm.value;
      this.spinnerService.show();
      if (!this.updateView) {
        this.customerService
          .createMonitoringCompany(requestData)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();
              let subscriberStats = JSON.parse(
                localStorage.getItem('subscriberStats') || '{}'
              );

              subscriberStats.company_count =
                (subscriberStats?.company_count || 0) + 1;

              localStorage.setItem(
                'subscriberStats',
                JSON.stringify(subscriberStats)
              );
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
              this.onCloseDialogue(response);
              this.companyForm.reset();
              this.updateView = false;
            } else {
              this.spinnerService.hide();
              this.pageService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      } else {
        requestData['id'] = this.companyData.id;
        this.customerService
          .updateMonitoringCompanyDetailById(requestData)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();

              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this.onCloseDialogue(response);
              this.updateView = false;
            } else {
              this.spinnerService.hide();
              this.pageService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      }
    }
  }
  onCloseDialogue(value?: any) {
    this.dialogRef.close(value);
  }
}
