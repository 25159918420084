<nb-card class="gt-p-1">
  <div>
    <button
      size="giant"
      nbButton
      (click)="openTemplate(routeTemplate); editData = null"
    >
      Add a New Route
    </button>
    <div class="sc-floating-right">
      <button size="giant" nbButton (click)="saveOrdering()">
        Save Ordering
      </button>
    </div>
  </div>

  <div
    cdkDropList
    #currentList="cdkDropList"
    [cdkDropListData]="appRoutes"
    class="drag-list sc-mt-6"
    (cdkDropListDropped)="drop($event)"
  >
    <table class="table drag-box">
      <thead>
        <tr>
          <th>Order</th>
          <th>Title</th>
          <th>Icon</th>
          <th>Admin</th>
          <th>Dispatcher</th>
          <th>User</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of appRoutes; let i = index" cdkDrag>
          <td>{{ i }}</td>
          <td>{{ item?.route_title }}</td>
          <td>
            <nb-icon nbSuffix [icon]="item?.route_icon" pack="eva"> </nb-icon>
          </td>
          <td>
            <nb-icon
              nbSuffix
              [status]="item?.show_subscriber_admin ? 'success' : 'danger'"
              [icon]="
                item?.show_subscriber_admin
                  ? 'checkmark-outline'
                  : 'close-outline'
              "
              pack="eva"
            >
            </nb-icon>
          </td>
          <td>
            <nb-icon
              nbSuffix
              [status]="item?.show_subscriber_dispatcher ? 'success' : 'danger'"
              [icon]="
                item?.show_subscriber_dispatcher
                  ? 'checkmark-outline'
                  : 'close-outline'
              "
              pack="eva"
            >
            </nb-icon>
          </td>
          <td>
            <nb-icon
              nbSuffix
              [status]="item?.show_subscriber_user ? 'success' : 'danger'"
              [icon]="
                item?.show_subscriber_user
                  ? 'checkmark-outline'
                  : 'close-outline'
              "
              pack="eva"
            >
            </nb-icon>
          </td>

          <td>
            <nb-icon
              [icon]="'edit'"
              pack="eva"
              ghost
              (click)="editAppRoute(item, routeTemplate)"
            >
            </nb-icon>
          </td>
          <td>
            <nb-icon
              [icon]="'trash'"
              pack="eva"
              ghost
              (click)="deleteRoute(item)"
            >
            </nb-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Pagination section begin -->
  <!-- <div class="sc-mt-6">
    <app-pagination-control
      [pageSize]="rows"
      [pageNum]="pageNum"
      [previous]="previous"
      [totalRows]="totalRows"
      [totalPages]="totalPages"
      (onClickPagination)="onClickPagination($event)"
      (onChangePagination)="onChangePagination($event)"
    >
    </app-pagination-control>
  </div> -->
</nb-card>

<ng-template #routeTemplate let-data let-routeRef="dialogRef">
  <nb-card class="dialog-box gt-p-small">
    <nb-card-header>
      {{ editData ? "Edit Route" : "Add new Route" }}
      <nb-icon
        (click)="onCloseDialogue()"
        [icon]="'close-outline'"
        class="sc-floating-right"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <form [formGroup]="appRouteForm" (ngSubmit)="onClickSubmit()">
        <div class="form-floating">
          <input
            class="form-control"
            fullWidth
            formControlName="route_link"
            maxlength="25"
            nbInput
            type="text"
          />
          <label for="inputService">Route Link</label>
        </div>
        <div class="form-floating">
          <input
            class="form-control"
            fullWidth
            formControlName="route_title"
            maxlength="25"
            nbInput
            type="text"
            (input)="forceUppercaseConditionally($event)"
          />
          <label for="inputService">Route Title</label>
        </div>

        <nb-form-field>
          <div class="form-floating">
            <input
              class="form-control"
              fullWidth
              formControlName="route_icon"
              nbInput
              type="text"
            />
            <label for="inputService">Route Icon</label>
          </div>

          <nb-icon nbSuffix [icon]="appRouteForm.value?.route_icon" pack="eva">
          </nb-icon>
        </nb-form-field>

        <div class="row row-cols-2 sc-mt-6">
          <div class="col">
            <nb-checkbox formControlName="show_subscriber_admin"
              >Show To All Subscriber Admins</nb-checkbox
            >
          </div>
          <div class="col">
            <nb-checkbox formControlName="show_subscriber_dispatcher"
              >Show To All Subscriber Dispatcher</nb-checkbox
            >
          </div>
          <div class="col">
            <nb-checkbox formControlName="show_subscriber_user"
              >Show To All Subscriber Users</nb-checkbox
            >
          </div>
        </div>

        <div class="row row-cols-2">
          <div class="col">
            <button
              fullWidth
              (click)="onClearForm()"
              nbButton
              size="giant"
              ghost
            >
              Clear
            </button>
          </div>
          <div class="col">
            <button
              fullWidth
              size="giant"
              [disabled]="!appRouteForm.valid"
              nbButton
              status="success"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</ng-template>
