<div class="mobile-only mb-3 gt-p-small">
  <div
    class="sc-head6 sc-text-center"
    *ngIf="
      ['patrol', 'guard_last_events'].includes(selectedEventType) &&
      !showDetails
    "
  >
    {{ eventTitle }}
  </div>

  <div
    class="d-flex"
    *ngIf="!showDetails && !['guard_last_events'].includes(selectedEventType)"
  >
    <div class="flex-grow-1 form-floating">
      <input
        class="form-control"
        nbInput
        fullWidth
        placeholder="Pick Date Range"
        [nbDatepicker]="formpicker"
        [(ngModel)]="dateRange"
      />
      <label for="date_range">Pick Date Range</label>
      <nb-rangepicker
        #formpicker
        (rangeChange)="handleDateChange($event)"
      ></nb-rangepicker>
    </div>
  </div>

  <div class="mt-1">
    <ng-container *ngIf="!showDetails">
      <div *ngIf="userEvents?.length">
        <app-card-list
          [listCardData]="userEvents"
          [listCardDetails]="userEventDetails"
          (rowClick)="onTableRowClick($event)"
        ></app-card-list>

        <div class="sc-mt-6">
          <app-pagination-control
            [pageSize]="pageSize"
            [pageNum]="pageNum"
            [previous]="previous"
            [totalRows]="totalRows"
            [totalPages]="totalPages"
            (onClickPagination)="onClickPagination($event)"
            (onChangePagination)="onChangePagination($event)"
          >
          </app-pagination-control>
        </div>
      </div>
      <div *ngIf="userEvents?.length == 0" class="my-3">
        <nb-alert outline="basic" style="font-weight: 500"
          >No Records Found</nb-alert
        >
      </div>
    </ng-container>
  </div>
</div>

<!-- Show Table on Desktop View -->
<div class="large-view-only gt-p-1">
  <div class="sc-head5 mb-3" *ngIf="['patrol'].includes(selectedEventType)">
    {{ eventTitle }}
  </div>

  <app-table
    [desktopTableData]="userEvents"
    [desktopTableDetails]="userEventDetailsTableView"
    [tableStyle]="tableStyle"
    [otherParms]="{ dateRange: dateRange }"
    (rowClick)="onTableRowClick($event)"
    (sortColumn)="sortColumn($event)"
    (searchColumn)="searchColumn($event)"
    [apiFilter]="apiFilter"
  ></app-table>

  <div class="sc-mt-6">
    <app-pagination-control
      [pageSize]="pageSize"
      [pageNum]="pageNum"
      [previous]="previous"
      [totalRows]="totalRows"
      [totalPages]="totalPages"
      (onClickPagination)="onClickPagination($event)"
      (onChangePagination)="onChangePagination($event)"
    >
    </app-pagination-control>
  </div>
  <div *ngIf="userEvents?.length == 0" class="my-3">
    <nb-alert outline="basic" style="font-weight: 500"
      >No Records Found</nb-alert
    >
  </div>
</div>

<div
  class="row row-cols-1 row-cols-sm-1 row-cols-md-2 gt-p-small"
  *ngIf="showDetails"
>
  <div class="col-md-5">
    <nb-card class="events-details-section">
      <div
        *ngIf="
          [1, 2, 5].includes(selectedEvent?.event_action) &&
          selectedEvent?.detail
        "
        class="mt-2"
      >
        <div class="chat-info">
          <strong>{{ selectedEvent?.action | titlecase }}</strong>
          <span
            *ngIf="
              selectedEvent?.history_changes?.length &&
              (subscriberUserId == selectedEvent?.subs_user_id ||
                isAdmin ||
                isDispatchUser)
            "
          >
            | Edited [{{
              getLastCommentEditedDateTime(selectedEvent?.history_changes)
                ?.updated_at | date : "d MMM y HH:mm"
            }}]</span
          >
        </div>
        <div
          class="chat-data fs-6"
          (click)="
            canEditComment(selectedEvent)
              ? editComment(selectedEvent, commentRef)
              : ''
          "
        >
          {{ selectedEvent?.detail }}
          <div class="sc-floating-right" *ngIf="canEditComment(selectedEvent)">
            <nb-icon pack="eva" icon="edit-outline"></nb-icon>
          </div>
        </div>
      </div>
      <ng-container>
        <div class="sc-head6">Event Details</div>
        <div class="row row-cols-1 row-cols-xxl-2">
          <ng-container *ngFor="let indColumn of userEventDetailsTableView">
            <ng-container
              *ngIf="
                (indColumn.nestedValue
                  ? indColumn.nestedValue(selectedEvent)
                  : selectedEvent[indColumn.name]
                )?.trim()
              "
            >
              <div>
                <span class="badge-key" style="text-transform: uppercase">
                  {{ indColumn?.header }}</span
                >
                <ng-container *ngIf="indColumn?.datetimeObj == true">
                  <span class="badge-value">
                    {{
                      indColumn.nestedValue
                        ? (indColumn.nestedValue(selectedEvent)
                          | date
                            : (indColumn.dateTimeFormat
                                ? indColumn.dateTimeFormat
                                : "d MMM y HH:mm"))
                        : (selectedEvent[indColumn.name]
                          | date
                            : (indColumn.dateTimeFormat
                                ? indColumn.dateTimeFormat
                                : "d MMM y HH:mm"))
                    }}
                  </span></ng-container
                >
                <ng-container *ngIf="!indColumn?.datetimeObj">
                  <span class="badge-value">
                    {{
                      indColumn.nestedValue
                        ? indColumn.nestedValue(selectedEvent)
                        : selectedEvent[indColumn.name] == "null" ||
                          selectedEvent[indColumn.name] == "undefined"
                        ? "N/A"
                        : selectedEvent[indColumn.name]
                    }}
                  </span></ng-container
                >
              </div>
            </ng-container>
          </ng-container>
          <div *ngIf="selectedEvent?.acknowledge_info?.updated_at">
            <span class="badge-key"> Guard has been contacted</span>
            <span class="badge-value">{{
              selectedEvent?.acknowledge_info?.inquired ? "Yes" : "No"
            }}</span>
          </div>
          <div *ngIf="selectedEvent?.acknowledge_info?.comment">
            <span class="badge-key" style="text-transform: uppercase">
              Details and actions taken</span
            >
            <span class="badge-value">{{
              selectedEvent?.acknowledge_info?.comment
            }}</span>
          </div>
          <div *ngIf="selectedEvent?.checkpoint_details?.min_distance">
            <span class="badge-key" style="text-transform: uppercase">
              Geofence Radius (metres)</span
            >
            <span class="badge-value">{{
              selectedEvent?.checkpoint_details?.min_distance | number : "1.0-0"
            }}</span>
          </div>
        </div>
        <div *ngIf="scanDistanceInfo?.scanDistance" class="fw-bold text-danger">
          <span class="badge-value">
            Scanned {{ scanDistanceInfo?.scanDistance }}M away from the
            Checkpoint</span
          >
        </div>
      </ng-container>

      <div
        *ngIf="selectedEvent?.recorded_at && !selectedEvent?.delayed_event"
        nbTooltip="This event was captured when the user was offline"
      >
        <span class="badge-key" style="color: brown">CAPTURED TIME</span>
        <span class="badge-value strong">
          {{ selectedEvent?.recorded_at | date : "d MMM y HH:mm" }}
        </span>
      </div>
      <ng-container *ngIf="selectedEvent?.prompts?.length">
        <div *ngFor="let item of selectedEvent?.prompts">
          <div class="sc-head6">
            {{ item?.prompt_data?.name ? item?.prompt_data?.name : "Prompt" }}
          </div>

          <div *ngFor="let dataItem of item?.data">
            <ng-template
              *ngTemplateOutlet="promptTemplate; context: { value: dataItem }"
            ></ng-template>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedEvent?.device_details">
        <div class="sc-head6">Device Details</div>
        <div class="row row-cols-1 row-cols-xxl-2">
          <div *ngFor="let item of selectedEvent?.device_details | keyvalue">
            <div *ngIf="item?.value">
              <span class="badge-key" style="text-transform: uppercase">
                {{ item.key }}</span
              >
              <span class="badge-value"> {{ item.value }} </span>
            </div>
          </div>

          <div>
            <span
              class="badge-key"
              nbTooltip="{{browserGpsInfo?.[selectedEvent?.gps?.source]?.description}}"
              nbTrigger="focus"
              [ngClass]="browserGpsInfo?.[selectedEvent?.gps?.source] ? 'strong' : ''"
              [nbTooltipDisabled]="!browserGpsInfo?.[selectedEvent?.gps?.source]"
            >
              LOCATION
            </span>
            <span
              class="badge-value"
              nbTooltip="{{browserGpsInfo?.[selectedEvent?.gps?.source]?.description}}"
              nbTrigger="focus"
              [nbTooltipDisabled]="!browserGpsInfo?.[selectedEvent?.gps?.source]"
              [ngClass]="browserGpsInfo?.[selectedEvent?.gps?.source] ? 'danger-text': ''"
            >
              {{ selectedEvent?.gps?.lat }},
              {{ selectedEvent?.gps?.lon }}
            </span>
          </div>
        </div>
      </ng-container>
    </nb-card>
  </div>

  <div class="col-md-7">
    <div [ngClass]="uploadedImage ? 'row row-cols-md-2' : 'row row-cols-1'">
      <div class="col-md-6" *ngIf="uploadedImage">
        <img class="event-image" src="{{ uploadedImage }}" alt="unplash" />
      </div>
      <div [ngClass]="uploadedImage ? 'col-md-6' : 'col'">
        <app-map-leaflet
          *ngIf="mapLatLong"
          [latLon]="mapLatLong"
          [scannedCheckpointDetails]="selectedEvent?.checkpoint_details"
          [mapId]="'event-location'"
        ></app-map-leaflet>
        <div class="sc-text-center" style="font-size: small">
          {{ selectedEvent?.gps?.lat }},
          {{ selectedEvent?.gps?.lon }}
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showDetails" class="pb-2 fixed-back-btn mobile-only">
  <button
    nbButton
    size="giant"
    fullWidth
    status="primary"
    (click)="hideDetails()"
  >
    Back
  </button>
</div>
<!-- edit COMMENT POPOVER / DIALOG -->
<ng-template #commentRef let-commentRef="dialogRef" let-data>
  <nb-card class="dialog-box">
    <nb-card-header class="sc-text-center">
      {{ data?.detail ? "Edit Comment" : "Add Comment" }}

      <nb-icon
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
        (click)="commentRef.close(); newComment = ''"
      >
      </nb-icon>
    </nb-card-header>
    <div class="row-cols-1 gy-2">
      <div class="col">
        <textarea
          class="form-control"
          rows="10"
          cols="32"
          wrap="hard"
          maxlength="340"
          type="text"
          placeholder="Start typing"
          [(ngModel)]="newComment"
        ></textarea>
      </div>
      <div class="col sc-mt-6">
        <button
          [disabled]="!newComment || previousComment === newComment"
          (click)="addComment(data); commentRef.close()"
          nbButton
          size="giant"
          fullWidth
        >
          {{ data?.detail ? "Update" : "Add" }}
        </button>
      </div>
    </div>
  </nb-card>
</ng-template>

<!--looped prompt template-->
<ng-template let-dataItem="value" #promptTemplate>
  <div *ngIf="dataItem?.description">
    <span class="badge-key" style="text-transform: uppercase">
      {{ dataItem?.description }}</span
    >
    <!--TODO: convert into pipe-->
    <span class="badge-value"> {{ getPromptValue(dataItem) }} </span>
  </div>

  <div *ngFor="let option of dataItem?.options">
    <ng-template
      *ngTemplateOutlet="promptTemplate; context: { value: option }"
    ></ng-template>
  </div>
</ng-template>
