import { Location } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/auth/auth.service';
import { CustomerService } from 'src/app/pages/customer/customer.service';
import { AddEditMonitoringCompanyComponent } from 'src/app/pages/monitoring-company/add-edit-monitoring-company/add-edit-monitoring-company.component';
import { PagesService } from 'src/app/pages/pages.service';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { AddEditResponseTypeComponent } from 'src/app/pages/response-type/add-edit-response-type/add-edit-response-type.component';
import { AddEditLicenseDetailsComponent } from 'src/app/shared/comp/add-edit-license-details/add-edit-license-details.component';
import { AddEditSiteComponent } from 'src/app/shared/comp/add-edit-site/add-edit-site.component';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { ContactComponent } from 'src/app/shared/comp/contact/contact.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { JobsService } from '../../jobs.service';

@Component({
  selector: 'app-response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.scss'],
})
export class ResponseComponent implements OnInit {
  @Input() selectedStatus: any;
  @ViewChild('licenceCheckTemplateRef') licenceCheckTemplateRef: any;
  formdata = new UntypedFormGroup({
    clientName: new UntypedFormControl('', Validators.required),
    siteName: new UntypedFormControl('', Validators.required),
    siteContactName: new UntypedFormControl(''),
    monitoringCompanyName: new UntypedFormControl(''),
    responseTypeName: new UntypedFormControl(''),
    assigneeName: new UntypedFormControl(),
    requestDetail: new UntypedFormControl(),
    externalJobId: new UntypedFormControl(),
  });
  contactForm: UntypedFormGroup = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    title: new UntypedFormControl(),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    mobile_number: new UntypedFormControl('', [
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('^[0-9]*$'),
    ]),
  });
  isAdmin: boolean = false;
  isDispatchUser: boolean = false;
  siteLicenceReminderCheck: boolean = false;
  licenseReminderField: boolean = false;
  showSiteMap: boolean = false;
  addJobPressed: boolean = false;
  selectedClient: any;
  responseJobType: any = {
    jobType: {
      name: 'job Type',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    client: {
      name: 'Client',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    site: { name: 'Site', value: '', edit: false, data: null, tempValue: '' },
    assignee: {
      name: 'Assignee',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    ai: {
      name: 'Additional Information',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    eI: {
      name: 'External Job Id',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    oc: {
      name: 'On Site Contact',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    rt: {
      name: 'Response Type',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    mc: {
      name: 'monitoring company',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
  };

  clientList: any = [];
  siteList: any = [];
  siteContacts: any = [];
  assigneeList: any = [];
  responseTypeList: any = [];
  monitoringCompanyList: any = [];
  companyLicenseList: any = [];
  dialogRef: any;
  userData: any;
  constructor(
    private toastrService: NbToastrService,
    private _location: Location,
    private jobService: JobsService,
    private profileService: ProfileService,
    private dialogService: NbDialogService,
    private customerService: CustomerService,
    private authService: AuthService,
    private pageService: PagesService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private spinnerService: NgxSpinnerService
  ) {
    this.userData = this.authService.getUserData();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.siteLicenceReminderCheck = this.userData?.checks?.license_reminder;

    router.events.subscribe((event: any) => {
      // close dialog boxes if any are open when back is pressed
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });
  }

  ngOnInit(): void {
    this.responseJobType.jobType.value = this.selectedStatus?.name;
    this.responseJobType.jobType.data = this.selectedStatus;
    this.getAssignees();
    this.getMonitoringCompanyList();
    this.getResponseTypes();
    this.editOtherJobTypeItem('site');
  }
  clearValues(_field: string) {
    if (_field == 'site') {
      this.responseJobType.site = {
        ...this.responseJobType.site,
        ...{ value: '', data: null, tempValue: '' },
      };
      this.responseJobType.oc = {
        ...this.responseJobType.oc,
        ...{ value: '', data: null, tempValue: '' },
      };
    }
    if (_field == 'oc') {
      this.responseJobType.oc = {
        ...this.responseJobType.oc,
        ...{ value: '', data: null, tempValue: '' },
      };
    }
  }

  onSiteSearch(event: any) {
    // in case add client auto fill the form
    this.clearValues('site');
    this.responseJobType.site.tempValue = event.target.value;
    if (event.target.value?.length > 2) {
      this.customerService
        .getSites({
          search_str: event.target.value,
          is_active: 1,
        })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.siteList = res['data'];
          }
        });
    }
  }
  doSiteLicenseCheck() {
    if (
      !this.companyLicenseList.some(
        (i: any) => i === this.responseJobType.site?.data?.address?.state_code
      )
    ) {
      this.dialogRef = this.dialogService.open(this.licenceCheckTemplateRef, {
        context: {},
        closeOnEsc: true,
      });
      this.dialogRef.onClose.subscribe((value: any) => {
        this.editOtherJobTypeItem('assignee');
      });
    }
  }
  ignoreLicenseCheck() {
    this.siteLicenceReminderCheck = false;
    this.editOtherJobTypeItem('assignee');
    if (this.licenseReminderField === true) {
      let params = { stop_notification: 1 };
      this.customerService
        .updateCompanyDetails({}, params)
        .subscribe((response: any) => {
          this.toastrService.show(response['message']);
          this.userData['checks']['license_reminder'] = false;
          this.authService.setUserData(this.userData);
        });
    }
  }
  async onSelectSite(data: any) {
    if (data === ' ') {
      this.openAddSiteForm();
    }

    if (data?.id) {
      this.clearValues('oc');
      this.showSiteMap = true;
      this.responseJobType.site.value = data?.company_name;
      this.responseJobType.site.data = data;
      this.formdata.controls['siteName'].setValue(data?.company_name);
      if (data?.company) {
        this.responseJobType.client.value = data?.company?.company_name;
        this.responseJobType.client.data = data?.company;
        this.formdata.controls['clientName'].setValue(data?.company_name);
      }
      this.assigneeList = await this.getGuardDetails(this.assigneeList);
      this.getSiteContacts(data.id);
      if (this.siteLicenceReminderCheck && this.isAdmin == true) {
        this.doSiteLicenseCheck();
      } else {
        this.editOtherJobTypeItem('assignee');
      }
    }
  }
  openAddSiteForm() {
    if (this.responseJobType.client.data) {
      this.dialogRef = this.dialogService.open(AddEditSiteComponent, {
        context: {
          detailData: { company: this.selectedClient },
          siteAddress: this.responseJobType.site.tempValue,
          showFrom: 'addJob',
        },
        dialogClass: 'model-full',
      });
      this.dialogRef.onClose.subscribe((value: any) => {
        if (value !== 'close') {
          this.onSelectSite(value);
        }
      });
    } else {
      this.pageService.setMessage({
        errorMessage: 'Please select a client first',
        successMessage: '',
      });
      this.dialogRef = this.dialogService.open(AddEditSiteComponent, {
        context: {
          detailData: { company: this.selectedClient },
          siteAddress: this.responseJobType.site.tempValue,
          showFrom: 'addJob',
        },
        dialogClass: 'model-full',
      });
      this.dialogRef.onClose.subscribe((value: any) => {
        if (value !== 'close') {
          value.company = {
            company_name: value.selectedClient,
            id: value.company,
          };
          this.onSelectSite(value);
        }
      });
    }
  }
  getSiteContacts(id: any) {
    this.customerService.getSiteContacts(id).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.siteContacts = response['data'];
      } else {
        this.pageService.setMessage({
          errorMessage: 'ERROR MESSAGE',
          successMessage: '',
        });
      }
    });
  }
  selectContact(contact: any) {
    if (contact == ' ') {
      this.addNewContactForm();
    }
    if (contact?.id) {
      this.responseJobType.oc.value = contact?.full_name;
      this.responseJobType.oc.data = contact;
      this.formdata.controls['siteContactName'].setValue(contact?.full_name);
    }
  }
  addNewContactForm() {
    if (this.responseJobType.site.data) {
      let siteData: any = this.responseJobType.site.data;
      siteData['contacts'] = this.siteContacts;
      this.dialogRef = this.dialogService.open(ContactComponent, {
        context: {
          contactForm: this.contactForm,
          siteData: siteData,
          addNewSiteContact: true,
          contact_type: 'lc',
        },
        dialogClass: 'model-full',
      });
      this.dialogRef.onClose.subscribe((value: any) => {
        if (value !== 'false') {
          if (value && value?.length) {
            this.siteContacts = value;

            let lastAddedContact =
              this.siteContacts[this.siteContacts?.length - 1];
            if (lastAddedContact) this.selectContact(lastAddedContact);
          }
        }
      });
    }
  }
  validLicenseCheck(guardLicenses: any) {
    return guardLicenses.some(
      (license: any) =>
        license.issuer_state ===
        this.responseJobType.site?.data?.address?.state_code
    );
  }
  async getGuardDetails(guardsData: any) {
    return new Promise((resolve) => {
      guardsData?.forEach((guard: any) => {
        guard['validStateLicense'] = this.validLicenseCheck(guard?.license);
      });

      resolve(guardsData);
    });
  }
  onAssigneeSearch(event: any) {
    if (event.target.value?.length > 2) {
      this.getAssignees({ full_name: event?.target?.value });
    }
  }
  getAssignees(params?: any) {
    this.jobService.getAssignees(params).subscribe(async (response: any) => {
      if (response['status'] == 'success') {
        this.assigneeList = await this.getGuardDetails(response['data']);
      }
    });
  }
  onAssigneeSelect(assignee: any) {
    if (assignee?.id) {
      this.responseJobType.assignee.value = assignee?.full_name;
      this.responseJobType.assignee.data = assignee;
      this.formdata.controls['assigneeName'].setValue(assignee?.full_name);
      if (assignee?.validStateLicense) {
        this.editOtherJobTypeItem('ai');
      } else {
        let dialogMsg;
        if (this.userData.profile.email === assignee.email) {
          dialogMsg =
            'You have not added a security licence for this location on your profile, are you sure you want to take this job?';
        } else {
          dialogMsg = `${
            assignee.full_name || assignee.email
          } does not hold a security licence for this location, assign to ${
            assignee.full_name || assignee.email
          } anyway?`;
        }
        this.dialogRef = this.dialogService.open(ConfirmDialogComponent, {
          context: {
            title: 'Job Assignment',
            message: dialogMsg,
          },
        });
        this.dialogRef.onClose.subscribe((value: any) => {
          if (value === 'Yes') {
            this.responseJobType.assignee.value = assignee?.full_name;
            this.responseJobType.assignee.data = assignee;
            this.formdata.controls['assigneeName'].setValue(
              assignee?.full_name
            );
            this.editOtherJobTypeItem('rt');
          } else {
            this.responseJobType.assignee.value = '';
            this.responseJobType.assignee.data = null;
            this.formdata.controls['assigneeName'].setValue(null);
          }
        });
      }
    }
  }
  onAddJob() {
    this.addJobPressed = true;
    this.spinnerService.show();
    let data = {
      job_type_id: this.selectedStatus.id,
      company_id: this.responseJobType?.client?.data?.id,
      location: [this.responseJobType?.site?.data?.id],
      is_abandoned: false,
      request: this.formdata.value.requestDetail,
      new_assignee: this.responseJobType?.assignee?.data?.id,
      location_contact_id: this.responseJobType?.oc?.data?.id,
      monitoring_company_id: this.responseJobType?.mc?.data?.id,
      response_type_id: this.responseJobType?.rt?.data?.id,
      external_job_id: this.formdata.value.externalJobId,
    };
    this.jobService.createJob(data).subscribe((res: any) => {
      if (res['status'] == 'success') {
         this.pageService.setMessage({
           errorMessage: '',
           successMessage: 'Job Dispatched',
         });
        this._location.back();
      } else {
        this.addJobPressed = false;
        this.pageService.setMessage({
          errorMessage: res['message'],
          successMessage: '',
        });
      }

      this.spinnerService.hide();
    });
  }

  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    });
  }
  editOtherJobTypeItem(field: string) {
    for (let key in this.responseJobType) {
      this.responseJobType[key]['edit'] = false;
    }
    this.responseJobType[field]['edit'] = true;
    if (window.innerWidth > 769) {
      this.shiftFocus(`desk${field}Field`);
    } else {
      this.shiftFocus(`mob${field}Field`);
    }
  }
  openTemplate(template: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(template, {
      context: {},
    });
  }
  getCompanyLicenses() {
    let params = { license_check: 1 };
    this.profileService
      .fetchCompanyDetails(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.userData['checks']['license_reminder'] =
            response?.data?.license_reminder;
          this.siteLicenceReminderCheck = response?.data?.license_reminder;
          this.authService.setUserData(this.userData);
          this.companyLicenseList = response?.data?.license.map(
            (i: any) => i?.issuer_state
          );
        }
      });
  }
  showLicensePopup() {
    this.dialogRef = this.dialogService.open(AddEditLicenseDetailsComponent, {
      context: {
        licenseState: this.responseJobType?.site?.data?.address?.state_code,
        companyLicense: true,
      },
      dialogClass: 'model-full',
    });
    this.dialogRef.onClose.subscribe((value: any) => {
      if (value != 'close') {
        this.companyLicenseList = value;
        this.dialogRef.close();
      }
    });
  }
  onRtSearch(event: any) {
    this.responseJobType.rt.tempValue = event.target.value;
    if (event?.target?.value.length > 2) {
      this.getResponseTypes({ search_str: event?.target?.value });
    }
    this.responseJobType.rt.value = null;
    this.responseJobType.rt.data = null;
  }

  getResponseTypes(params?: any) {
    this.customerService.fetchResponseTypeList(params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.responseTypeList = res['data'];
      }
    });
  }
  onMonitoringSelect(company: any) {
    if (company === ' ') {
      this.openAddMonitoringCompany();
    }
    if (company?.id) {
      this.formdata.controls['monitoringCompanyName'].setValue(
        company?.company_name
      );
      this.responseJobType.mc.value = company?.company_name;
      this.responseJobType.mc.data = company;
      if (window.innerWidth > 769) {
        this.editOtherJobTypeItem('ai');
      } else {
        var element = <HTMLInputElement>document.getElementById(`mobmcField`);
        element?.blur();
      }
    }
  }
  openAddMonitoringCompany() {
    this.dialogRef = this.dialogService.open(
      AddEditMonitoringCompanyComponent,
      {
        context: {
          updateView: false,
          name: this.responseJobType.mc.tempValue,
        },
      }
    );

    this.dialogRef.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.onMonitoringSelect(value?.data);
      } else {
        this.getMonitoringCompanyList();
      }
    });
  }
  onResponseTypeSelect(responseType: any) {
    if (responseType === ' ') {
      this.openAddResponseType();
    }
    if (responseType?.id) {
      this.formdata.controls['responseTypeName'].setValue(responseType?.name);
      this.responseJobType.rt.value = responseType?.name;
      this.responseJobType.rt.data = responseType;
      this.editOtherJobTypeItem('mc');
    }
  }
  openAddResponseType() {
    this.dialogRef = this.dialogService.open(AddEditResponseTypeComponent, {
      context: {
        updateView: false,
        name: this.responseJobType.rt.tempValue,
      },
    });

    this.dialogRef.onClose.subscribe((value: any) => {
      if (value !== 'close') {
        this.onResponseTypeSelect(value?.data);
      }
    });
  }
  onMcSearch(event: any) {
    // in case add client auto fill the form
    this.responseJobType.mc.tempValue = event.target.value;
    if (event?.target?.value.length > 2) {
      this.getMonitoringCompanyList({ search_str: event?.target?.value });
    }
    this.responseJobType.mc.value = null;
    this.responseJobType.mc.data = null;
  }
  getMonitoringCompanyList(params?: any) {
    // this.spinnerService.show();
    this.customerService
      .fetchMonitoringCompanyList(params)
      .subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.monitoringCompanyList = res['data'];
        }
        // this.spinnerService.hide();
      });
  }
  addAI() {
    this.formdata.controls['requestDetail'].setValue(
      this.responseJobType.ai.value
    );
  }
}
