<div class="gt-p-small">
  <div class="row row-cols-1 row-cols-lg-3">
    <div class="col">
      <button
        size="giant"
        class="status-button"
        hero
        status="primary"
        routerLink="/user-prompt"
        nbButton
        fullWidth
      >
        + New Prompt
      </button>
    </div>
    <div class="col">
      <div class="row row-cols-2">
        <div class="col">
          <button
            size="giant"
            class="status-button nbbutton-long-text"
            fullWidth
            [status]="visibleOnHomePage ? 'primary' : 'basic'"
            type="button"
            (click)="
              visibleOnHomePage = !visibleOnHomePage; getAllUserPrompts()
            "
            nbButton
          >
            {{ visibleOnHomePage ? "Show All" : "Visible on Home" }}
          </button>
        </div>
        <div class="col">
          <nb-select
            fullWidth
            size="giant"
            [(selected)]="promptStatusSelected"
            class="status-button"
          >
            <nb-option
              [value]="option.key"
              *ngFor="let option of promptStatusOptions"
              (click)="getAllUserPrompts()"
              >{{ option.value }}</nb-option
            >
          </nb-select>
        </div>
      </div>
    </div>
  </div>
  <div class="p-2 my-3" outline="info">
    Prompts are used to show or collect information after a checkpoint has been
    scanned.
  </div>
  <app-card-list
    [listCardData]="userPromptList"
    [listCardDetails]="userPromptCardDetils"
    [cardActionIcons]="cardIcons"
    [showDetailsCondition]="true"
    (actionClick)="actionClick($event, copyPromptTemplateRef)"
    (showDetailClick)="getPromptDetail($event)"
  ></app-card-list>

  <div class="sc-mt-6" *ngIf="userPromptList?.length">
    <app-pagination-control
      [pageSize]="pageSizeUserPrompt"
      [pageNum]="pageNumUserPrompt"
      [previous]="previousUserPrompt"
      [totalRows]="totalCountUserPrompt"
      [totalPages]="totalPagesUserPrompt"
      (onClickPagination)="onChangePagination($event)"
      (onChangePagination)="onChangePagination($event)"
    >
    </app-pagination-control>
  </div>
  <div *ngIf="userPromptList?.length == 0" class="my-3">
    <nb-alert outline="basic" style="font-weight: 500"
      >No Records Found</nb-alert
    >
  </div>
</div>
<ng-template
  #copyPromptTemplateRef
  let-copyPromptTemplateRef="dialogRef"
  let-data
>
  <nb-card class="dialog-box">
    <nb-card-header>
      Copy User Prompt
      <nb-icon
        (click)="copyPromptTemplateRef.close()"
        [icon]="'close-outline'"
        class="sc-floating-right close-icon"
        pack="eva"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <div class="form-floating my-3 col-xs-12">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="promptName"
          placeholder="Prompt Name"
        />
        <label>Prompt Name</label>
      </div>
      <div class="row row-cols-2">
        <div class="col" (click)="copyPromptTemplateRef.close()">
          <button tabindex="-1" nbButton ghost size="giant" fullWidth>
            Cancel
          </button>
        </div>
        <div
          id="confirmBtn"
          class="col"
          (click)="onConfirmCopy(data?.data?.id); copyPromptTemplateRef.close()"
        >
          <button fullWidth nbButton size="giant">Confirm</button>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
