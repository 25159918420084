import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit {
  @Input() barChartData: any = [];
  @Input() charInfo: any;
  maxChartValue: number = 0;
  defaultChartHeight: number = 500; // in pixels
  averageValue: number = 0;
  averageValueInPx: any;
  barChartDefaultStyle = {
    width: '100%',
    background: '#0d6efd',
  };
  ngOnInit(): void {
    const barChartValue: any = this.charInfo?.value?.name;

    const allAvailableValues = this.barChartData?.map(
      (item: any) => item?.[barChartValue]
    );

    this.maxChartValue =
      Math.max(...allAvailableValues) || this.defaultChartHeight;

    try {
      const sumValue =
        allAvailableValues?.reduce((sum: any, val: any) => sum + val) || 1;

      this.averageValue = Math.round(sumValue / 24);

      this.averageValueInPx = `${
        Math.round(
          this.defaultChartHeight * (this.averageValue / this.maxChartValue)
        ) +
        (20 + 26 + 25)
      }px`; // adding the padding  of bar chart class and the height of bar label and height og avg value
    } catch (error) {
      this.maxChartValue =
        Math.max(...allAvailableValues) || this.defaultChartHeight;
    }
  }
  getBarValue(action: string, value: any, args: any) {
    switch (action) {
      case 'getBarStyle':
        const barChartInfo: any = args[0];
        const maxChartValue: any = args[1];
        const barChartValueKey: any = barChartInfo?.value?.name;

        const barHeight = `${
          this.defaultChartHeight * (value?.[barChartValueKey] / maxChartValue)
        }px`;
        let newStyle = {
          ...this.barChartDefaultStyle,
          ...barChartInfo?.value?.style,
          height: barHeight,
        };

        return newStyle;

      case 'getBarLabel':
        const barChartLabelInfo: any = args[0];
        const barChartLabelKey: any = barChartLabelInfo?.label?.name;

        return value?.[barChartLabelKey];
      case 'getBarValue':
        const barChartValueInfo: any = args[0];
        const barChartValue: any = barChartValueInfo?.value?.name;

        return value?.[barChartValue];
    }
  }
}
