import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogService, NbStepperComponent } from '@nebular/theme';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { SubscriptionService } from 'src/app/pages/subscription/subscription.service';
import { SaveNewAddressComponent } from 'src/app/shared/comp/save-new-address/save-new-address.component';
import { MustMatch } from 'src/app/shared/must-match-validator';
import { DynamicScriptLoadService } from 'src/app/shared/service/dynamic-script-load.service';
import { AuthService } from '../../auth.service';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  registerForm: UntypedFormGroup = new UntypedFormGroup(
    {
      add_trial: new UntypedFormControl(1),
      company_name: new UntypedFormControl('', [Validators.required]),
      addressLookup: new UntypedFormControl(''),
      address1: new UntypedFormControl('', [Validators.required]),
      address2: new UntypedFormControl('', [Validators.required]),
      address3: new UntypedFormControl(''),
      city_name: new UntypedFormControl('', [Validators.required]),
      postcode: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.min(800),
          Validators.max(9999),
          Validators.maxLength(4),
        ])
      ),
      longitude: new UntypedFormControl('', [Validators.required]),
      latitude: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
      vCode: new UntypedFormControl(''),
      state_code: new UntypedFormControl(''),
      first_name: new UntypedFormControl('', [Validators.required]),
      last_name: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
      confirm_password: new UntypedFormControl(null, [
        MustMatch('password', 'confirm_password'),
      ]),
      mobile_number: new UntypedFormControl(null),
    },
    {
      validators: MustMatch('password', 'confirm_password'),
    }
  );
  startForm: any;
  midForm: any;
  endForm: any;
  showPassword: boolean = false;
  isFetchedRegisterDetails: boolean = false;
  enteredAddresslookup: boolean = false;
  @ViewChild('addressAutoInput') input: any;
  @ViewChild('phoneInput') phoneInput: any;
  @ViewChild('next1Btn', { read: ElementRef, static: false })
  nextBtn1!: ElementRef;
  @ViewChild('stepper1') stepper1: any = NbStepperComponent;
  @ViewChild('stepper2') stepper2: any = NbStepperComponent;
  submitted: any;
  message: any;
  headerText: any;
  showMessageForm: any;
  error: any;
  showDetailForm: any = false;
  addressObjectValue: any;
  addressValue: any;
  addrLookupData$: Observable<any> | undefined;
  cityData$: Observable<any> | undefined;
  filteredOptions: Observable<any> | undefined;
  cityValue: any;
  selectedItem: any;
  stateData: any[] = [];
  countryData: any[] = [];
  linearMode: any;
  showSignInLink: boolean = false;
  isPolicyAccepted: any = false;
  gotVerificationCode: boolean = false;
  verificationToken: any;
  latLon: any;
  firstAddress: any;
  showMap: boolean = false;
  searchControl: FormControl = new FormControl();
  restartSignUp: boolean = false;
  restartMessage: string = '';
  showUpcomingPlanMessage: any;
  plans: any;
  subscriptionData: any = {};
  paymentData: any;
  smartConnectBalance: any;
  card_holder_name: any;
  coupon_code: any;
  dialogRef: any;
  incompleteRegistration: boolean = false;

  constructor(
    private spinnerService: NgxSpinnerService,
    private scriptLoader: DynamicScriptLoadService,
    private cd: ChangeDetectorRef,
    private appService: AppService,
    private dialogService: NbDialogService,
    private authService: AuthService,
    public router: Router,
    private subscriptionService: SubscriptionService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    var data = this.router.getCurrentNavigation()?.extras.state;
    if (data) {
    } else {
      // Loading the script for recaptcha
      this.scriptLoader
        .load('google-recaptcha')
        .then((data) => {})
        .catch((error) => console.error(error));
    }
    this.getCountries();
    this.searchControl.valueChanges.subscribe((data) => {
      if (typeof data === 'object') {
        this.searchControl.setValue(data.full_address);
      }
    });
  }

  ngOnInit(): void {
    var element = document.querySelector('nb-card');
    element?.setAttribute('id', 'registerCard');
    // Call the get_IP API if IP address is not capture of user
    if (!localStorage.getItem('ipAddress')) {
      this.appService.setIp();
    }
    this.spinnerService.show();
    // Check and Fetch the register details if it exists
    let registerDetails: any = localStorage.getItem('registerDetails');
    registerDetails = JSON.parse(registerDetails);
    if (registerDetails?.expiry) {
      if (new Date().getTime() > registerDetails?.expiry) {
        localStorage.removeItem('registerDetails');
      } else {
        this.registerForm.patchValue(registerDetails?.values);
        this.enteredAddresslookup = true;
        if (this.registerForm.valid) {
          this.isFetchedRegisterDetails = true;
        }
      }
    }
    this.appService.permissionErrorAlert.subscribe((value: any) => {
      if (value === 'locationDisabled') {
        this.message = 'Error: Location access not given';
      }
    });
    this.spinnerService.hide();
  }

  getCountries(data?: any) {
    this.appService.getAddress().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.countryData = response['data'];
        this.filteredOptions = of(this.countryData);
        let defaultCountry = this.countryData.filter(
          (country: any) => country.name == 'Australia'
        )[0];
        if (defaultCountry) {
          this.registerForm.get('country')?.setValue(defaultCountry.name);
          this.getStates(defaultCountry.id);
        } else {
          this.registerForm.get('country')?.setValue('Australia');
        }
      } else {
      }
    });
  }
  getStates(countryId: any) {
    this.appService.getStates(+countryId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateData = response['data'];
      } else {
      }
    });
  }
  addressSelected(address: any) {
    if (address === 'add') {
      this.addPlace();
    } else if (address && typeof address === 'object') {
      this.addressValue = address?.address1 + ' ' + address?.address2;
      this.enteredAddresslookup = true;
      this.addrLookupData$ = undefined;
      this.addressObjectValue = address;
      this.registerForm.patchValue({
        address1: address?.address1,
        address2: address?.address2,
        address3: address?.address3,
        city_name: address?.city_name,
        postcode: address?.postcode,
        state_code: address?.state_code,
        country: address?.country_name,
        latitude: address?.latitude,
        longitude: address?.longitude,
      });
      var element = <HTMLInputElement>document.getElementById('addressSearch');
      element.blur();
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);
    }
  }
  toggleLinearMode() {
    this.linearMode = !this.linearMode;
  }
  onAddressSearch(addressSearch: any) {
    if (addressSearch?.target?.value?.length > 2) {
      this.appService
        .addressLookupShared(addressSearch.target.value)
        .subscribe((res: any) => {
          this.addrLookupData$ = of(res['data']);
        });
      this.enteredAddresslookup = false;
    }
  }
  citySearch(citySearch: any) {
    this.appService
      .cityLookup(citySearch.target.value)
      .subscribe((res: any) => {
        this.cityData$ = of(res['data']);
      });
  }
  citySelected(city: any) {
    this.cityValue = city;
    if (city) {
      var city_obj;
      if (Object.keys(city).length > 1) {
        city_obj = {
          // city: city.name,
          postcode: city.postcode,
          state: city.state.code,
          country: city.state.country,
        };
      } else {
        city_obj = { city: city.name };
      }
      this.registerForm.patchValue(city_obj);
    }
  }

  acceptPrivacyPolicy(event: any) {
    this.isPolicyAccepted = event;
  }
  sendVerificationCode() {
    let registerDetails: any = localStorage.getItem('registerDetails');
    registerDetails = JSON.parse(registerDetails);
    if (this.registerForm.value.email != registerDetails?.values?.email) {
      this.authService
        .sendVerificationCode(
          this.registerForm.controls['email'].value,
          this.registerForm.controls['first_name'].value,
          this.registerForm.controls['last_name'].value
        )
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.gotVerificationCode = true;
            this.verificationToken = response['token'];
            // TODO: save the token expiration time which will expire in 10 minutes
            // const verificationToken = {
            //   verificationToken:this.verificationToken,
            //   verificationExpiry: new Date().getTime() + 10 * 60 * 1000

            // }
            // localStorage.setItem('verificationToken',JSON.stringify(verificationToken))
            localStorage.setItem('verificationToken', this.verificationToken);
          } else {
            this.error = response['message'];
            if (
              response?.sign_in &&
              this.registerForm.controls['email'].value
            ) {
              this.incompleteRegistration = true;
            }
            this.showSignInLink = true;
          }
        });
    }
  }
  // Method to verify the verification code sent in user's email
  verifyVerificationCode(event: any) {
    if (event?.target?.value?.length >= 4) {
      this.spinnerService.show();
      this.verificationToken = localStorage.getItem('verificationToken')
        ? localStorage.getItem('verificationToken')
        : this.verificationToken;
      const body = { v_code: this.registerForm.controls['vCode'].value };
      this.appService
        .gtExternalLink(
          'external_api/verify_v_code',
          body,
          this.verificationToken
        )
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.verificationToken = response['token'];
            localStorage.setItem('verificationToken', this.verificationToken);
            this.error = '';
            this.finalRegister();
          } else {
            this.error = response['message'];
            if (
              response?.sign_in &&
              this.registerForm.controls['email'].value
            ) {
              this.incompleteRegistration = true;
            }

            this.showSignInLink = true;
            this.spinnerService.hide();
            if (response['restart'] === true) {
              localStorage.removeItem('registerDetails');
              localStorage.removeItem('verificationToken');
              this.restartSignUp = true;
              this.error = 'We could not verify your account';
            }
          }
        });
    }
  }
  //Method to change the password input type
  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  //Method to register the user after verifying email
  finalRegister(): void {
    if (this.registerForm.valid) {
      this.spinnerService.show();
      this.submitted = true;
      this.appService
        .gtExternalLink(
          'register_login/register',
          this.registerForm.value,
          this.verificationToken
        )
        .then((response: any) => {
          this.submitted = false;
          if (response) {
            if (response['status'] == 'success') {
              localStorage.removeItem('registerDetails');
              this.appService.setUserData(response);
              this.spinnerService.hide();
              localStorage.setItem('firstTimeSubscriber', 'true');
              setTimeout(() => {
                this.router.navigate(['/dashboard']);
              }, 100);
            } else {
              this.error = response['message'];
              this.spinnerService.hide();
            }
          }
          this.cd.detectChanges();
        });
    } else {
    }
  }

  // Set the Registration details in local-storage
  saveRegisterDetail() {
    const item = {
      values: this.registerForm.value,
      expiry: new Date().getTime() + 2 * 60 * 60 * 1000,
    };
    localStorage.setItem('registerDetails', JSON.stringify(item));
  }

  addPlace() {
    let latLonVal: any = this.latLon;
    if (this.firstAddress) {
      latLonVal = {
        lat: this.firstAddress?.latitude,
        lon: this.firstAddress?.longitude,
      };
    }
    this.showMap = false;
    const dialogRef = this.dialogService.open(SaveNewAddressComponent, {
      dialogClass: 'model-full',
      context: {},
    });
    dialogRef.onClose.subscribe((value: any) => {
      if (value != 'close') {
        if (value) {
          this.addressSelected(value);
          if (this.searchControl.value === 'add') {
            this.searchControl.setValue(
              value?.address1 +
                ' ' +
                value?.address2 +
                ' ' +
                value?.city_name +
                ' ' +
                value?.state_code +
                ' ' +
                value?.postcode
            );
          }
        }
      } else {
        this.searchControl.setValue('');
      }
    });
  }
  refresh(): void {
    window.location.reload();
  }
  forceUppercaseConditionally(event: any) {
    this.registerForm.controls['city_name'].setValue(
      event.target.value.toUpperCase()
    );
  }

  getPlans() {
    this.subscriptionService.getSubscriptionPlans().subscribe((res: any) => {
      if (res['status'] === 'success') {
        this.plans = res['data'];
      } else {
        this.showUpcomingPlanMessage = res['message'];
      }
    });
  }
  signInLinkOrRoute() {
    if (this.incompleteRegistration) {
      this.recaptchaV3Service.execute('score').subscribe((token) => {
        this.spinnerService.show();
        this.error = null;
        this.authService
          .sendSignInLink({
            data: {
              ...{ username: this.registerForm.controls['email'].value },
              recaptcha_token: token,
              sign_in: 0,
            },
          })
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.message = response['message'];
              this.spinnerService.hide();
            }
            if (response['status'] == 'failure') {
              this.message = response['message'];
              this.spinnerService.hide();
            } else {
              this.message =
                'We have sent you a link to sign up, please check your email including your junk/spam folder.';
              this.spinnerService.hide();
            }
          });
      });
    } else {
      this.router.navigate(['/login']);
    }
  }
}
