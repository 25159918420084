import { Component, Input, OnInit, Optional, TemplateRef } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { ConfirmDialogComponent } from 'src/app/shared/comp/confirm-dialog/confirm-dialog.component';
import { DataCheckService } from 'src/app/shared/service/data-check.service';
import { globalLicenseInfo } from 'src/global.variables';
import { PagesService } from '../../pages.service';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-add-edit-users',
  templateUrl: './add-edit-users.component.html',
  styleUrls: ['./add-edit-users.component.scss'],
})
export class AddEditUsersComponent implements OnInit {
  selectForm: UntypedFormGroup | undefined;
  nameForm: UntypedFormGroup | undefined;

  userForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    first_name: new UntypedFormControl('', Validators.required),
    last_name: new UntypedFormControl('', Validators.required),
    group_ids: new UntypedFormControl([]),
  });

  linearMode = true;

  stateData: any[] = [];
  showNewLicenseForm: any;
  groupData: any[] = [];
  formSubmitted: any;
  classData: any[] = [];
  selectedItem: any;
  guardsPage: any;
  licenseData: any;
  updateLicenseData: any;
  addView: any;
  updateView: any;
  newlicense: any;
  groupSelected: any;
  userTypes = ['Guard', 'User'];
  @Input() editUser: any;
  @Input() detailData: any;
  @Input() fromUserPage: boolean = false;
  @Input() super_admin: boolean = false;
  showNewLicenceForm: any;
  selectedGroup: any = [];
  userData: any;
  licenseDetails = {
    individualDetails: globalLicenseInfo?.details,
  };
  userGroups: any;
  userGroupNames: any;
  lastActivity: any;
  userStatus: any = {
    'Offline': 'var(--color-basic-500)',
    'Signed In': 'var(--color-success-500)',
    'On Patrol': 'var(--color-warning-500)',
  };
  isSuperUser: any;
  userDialogueRef: any;
  selectedUserGroups: any = [];
  isAdmin: boolean = false;

  constructor(
    @Optional() protected dialogRef: NbDialogRef<AddEditUsersComponent>,
    private toastrService: NbToastrService,
    private appService: AppService,
    private userService: UsersService,
    private fb: UntypedFormBuilder,
    private spinnerService: NgxSpinnerService,
    private dialog: NbDialogService,
    private router: Router,
    private pageService: PagesService,
    private dataCheckService: DataCheckService,
    private dialogService: NbDialogService
  ) {
    this.classData = [];
    this.userData = this.appService.getUserData();
    this.isSuperUser = this.dataCheckService.isSuperUser();
    this.isAdmin = this.dataCheckService.isUserAdmin();
  }

  ngOnInit(): void {
    if (this.editUser == true) {
      this.spinnerService.show();
      this.super_admin == true
        ? this.userService
            .getSuperUserById(
              this.detailData.pk ? this.detailData.pk : this.detailData.id
            )
            .subscribe((response: any) => {
              if (response['status'] == 'success') {
                this.detailData = response['data'];
              }
              this.spinnerService.hide();
            })
        : this.userService
            .getUserById(
              this.detailData.pk ? this.detailData.pk : this.detailData.id
            )
            .subscribe((response: any) => {
              if (response['status'] == 'success') {
                this.formatData(response);
              }
              this.spinnerService.hide();
            });
    }
    if (this.fromUserPage) {
      this.getUserGroups();
    }
  }
  formatData(response: any) {
    this.selectedUserGroups = [];
    let userGroups: any = [];
    this.detailData = response['data'];

    this.lastActivity = response['last_activity'];
    this.detailData?.user_group.forEach((item: any) => {
      userGroups.push(item?.name);
      if (item?.group_identity !== 4) {
        this.selectedUserGroups.push(item.id);
      }
    });

    this.userGroupNames = userGroups.join(',');
  }

  getUserGroups() {
    this.spinnerService.show();
    this.userService.getUserGroups({ rows: 50 }).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.userGroups = response['data']?.filter(
          (item: any) => item?.group_id !== 4
        );
        let index = this.userGroups
          .map((object: any) => object.name)
          .indexOf('Guards');
        if (index != -1) {
          this.selectedGroup = [this.userGroups[index].id];
          this.userForm.controls['group_ids'].setValue([
            this.userGroups[index].id,
          ]);
        }
      }
      this.spinnerService.hide();
    });
  }
  getStates() {
    this.appService.getStates(1).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateData = response['data'];
      }
    });
  }

  onCloseDialogue(val = 'submit') {
    this.dialogRef.close(val);
  }
  onClearForm() {
    this.userForm.reset();
  }
  toggleLinearMode() {
    this.linearMode = !this.linearMode;
  }

  //Send the invitation link in user's email
  sendAddUserLink() {
    this.spinnerService.show();
    if (this.super_admin == true) {
      let requestData = { data: this.userForm.value };
      this.userService.addSuperAdmin(requestData).subscribe((response: any) => {
        this.pageService.setMessage({
          successMessage: 'Super User added!',
          errorMessage: '',
        });
        this.addView = this.updateView = false;
        this.spinnerService.hide();
        this.onCloseDialogue();
      });
    } else {
      let requestData = { data: this.userForm.value };
      this.userService.addUser(requestData).subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.addView = this.updateView = false;

          this.onCloseDialogue(response['data']);
        } else {
          this.pageService.setMessage({
            errorMessage: response['message'],
            successMessage: '',
          });
        }
        this.spinnerService.hide();
      });
    }
  }
  disableOrEnableUser(id: any) {
    this.spinnerService.show();
    this.userService.userEnableDisable(id).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.pageService.setMessage({
          successMessage: response['message'],
          errorMessage: '',
        });
      } else {
        this.pageService.setMessage({
          errorMessage: response['message'],
          successMessage: '',
        });
      }
      this.spinnerService.hide();
      this.onCloseDialogue();
    });
  }
  reInviteUser(data: any) {
    let dialogMsg = `Send ${data?.email} another email to sign into Guard Tracker?`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      context: {
        title: 'Resend User Sign In Link',
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        let requestData = { data: { subscriber_user_id: data?.id } };
        this.userService
          .addUser(requestData, { re_invite: 1 })
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.pageService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
            this.onCloseDialogue();
          });
      }
    });
  }
  deleteUserById(data: any) {
    let dialogMsg = `Are you sure you want to delete this user?`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      context: {
        title: 'Delete User?',
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.spinnerService.show();
        this.userService.deleteUserById(data?.id).subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.pageService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
          } else {
            this.pageService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
          this.spinnerService.hide();
          this.onCloseDialogue();
        });
      }
    });
  }
  deleteSuperUserById(data: any) {
    let dialogMsg = `Are you sure to delete this super user ? `;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      context: {
        title: `Delete Super User ${data?.full_name || data?.email}`,
        message: dialogMsg,
      },
    });
    dialogRef.onClose.subscribe((value) => {
      if (value === 'Yes') {
        this.userService
          .deleteSuperUserById(data?.id)
          .subscribe((response: any) => {
            this.onCloseDialogue();
          });
      }
    });
  }
  redirectUserGroup(group: any) {
    this.dialogRef.close('close');
    this.router.navigate(['/user-group', { uGKey: String(group?.id) }]);
  }
  adminCheck() {
    if (
      !this.detailData?.user_group?.some((grp: any) =>
        [4].includes(grp?.group_identity)
      )
    ) {
      return true;
    }
    return false;
  }
  assignGroups(groupIds?: any) {
    if (groupIds.length === 0) {
      this.pageService.setMessage({
        successMessage: '',
        errorMessage: 'The user must be in one of the groups.',
      });
    } else {
      const selected = groupIds.filter(
        (group: any) => !this.selectedUserGroups.includes(group)
      );
      const unselected = this.selectedUserGroups.filter(
        (group1: any) => !groupIds.includes(group1)
      );
      if (selected.length > 0) {
        // add user from the group

        this.userService
          .updateUser(
            {
              id: this.detailData.id,
              group_id: selected[0],
            },
            { add_group: 1 }
          )
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.formatData(response);

              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
      if (unselected.length > 0) {
        // remove user from the group

        this.userService
          .updateUser(
            {
              id: this.detailData.id,
              group_id: unselected[0],
            },
            { remove_group: 1 }
          )
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.formatData(response);

              this.pageService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.pageService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    }
  }

  openUserGroupPopup(dialog: TemplateRef<any>) {
    this.userDialogueRef = this.dialogService.open(dialog, {
      context: {},
    });
  }
  disableSelectOption(grpData: any) {
    if (
      this.selectedUserGroups?.length === 1 &&
      this.selectedUserGroups?.some((grpId: any) => grpId === grpData?.id)
    ) {
      // if only one group is there block its unselection
      return true;
    }
    if (grpData?.group_id === 1 && this.detailData?.subscriber_admin) {
      // block removal of subscriber admin
      return true;
    }
    if (
      grpData?.group_id === 1 &&
      this.userData?.subscriber?.subscriber_user_id === this.detailData?.id
    ) {
      // logged in Managers user cannot remove himself from Managers group
      return true;
    }
    if (grpData?.group_id === 4) {
      // block Allowing to select the other user as Administrator group.
      return true;
    }
    return false;
  }
}
