<!--management command calling option-->
<nb-card class="gt-p-1">
  <nb-card-body>
    <nb-tabset (changeTab)="changeTab($event)">
      <nb-tab tabTitle="{{ managementTabs.tab1 }}">
        <app-table
          [desktopTableData]="paginatedGitHistoryData"
          [desktopTableDetails]="gitHistoryTable"
          (rowClick)="gitVersionClick($event)"
        >
        </app-table>

        <div>
          <app-pagination-control
            [pageSize]="rowsReleases"
            [pageNum]="pageNumReleases"
            [previous]="previousReleases"
            [totalRows]="totalRowsReleases"
            [totalPages]="totalPagesReleases"
            (onClickPagination)="onClickReleasePagination($event)"
            (onChangePagination)="onChangeReleasePagination($event)"
          >
          </app-pagination-control>
        </div>
        <div class="sc-mt-6 gt-p-1" *ngIf="selectedGitVersionData">
          <div class="row row-cols-auto">
            <div>
              <span class="badge-key">Commit Hash</span>
              <span class="badge-value">
                {{ selectedGitVersionData?.commit_hash }}</span
              >
            </div>
            <div>
              <span class="badge-key">Commit Time</span>
              <span class="badge-value">
                {{
                  selectedGitVersionData?.commit_time
                    | customdate : "d MMM y HH:mm"
                }}</span
              >
            </div>
            <div>
              <span class="badge-key">Last Updated</span>
              <span class="badge-value">
                {{
                  selectedGitVersionData?.updated_at
                    | customdate : "d MMM y HH:mm"
                }}
                by {{ selectedGitVersionData?.updated_by }}</span
              >
            </div>
            <div>
              <span class="badge-key">Stack Type</span>
              <span class="badge-value">
                {{ selectedGitVersionData?.stack_name }}</span
              >
            </div>
            <div>
              <span class="badge-key">Notification Sent</span>
              <span
                class="badge-value"
                *ngIf="selectedGitVersionData?.management_command"
              >
                {{
                  selectedGitVersionData?.updated_at
                    | customdate : "d MMM y HH:mm"
                }}
                by {{ selectedGitVersionData?.updated_by }}</span
              >
              <span
                class="badge-value"
                *ngIf="!selectedGitVersionData?.management_command"
              >
                Not Sent</span
              >
            </div>
            <div>
              <span class="badge-key">Version</span>
              <span class="badge-value">
                {{ selectedGitVersionData?.version }}</span
              >
            </div>
          </div>

          <div class="row row-cols-2">
            <div class="col">
              <div
                [ngClass]="
                  selectedGitVersionData?.management_command
                    ? 'sc-head5'
                    : 'sc-head5 pointer'
                "
                (click)="
                  selectedGitVersionData?.management_command
                    ? ''
                    : openTemplate(changeLogRef);
                  newChangeLog = selectedCommitMessages
                "
              >
                Change Log<nb-icon
                  [icon]="'edit-outline'"
                  pack="eva"
                  *ngIf="!selectedGitVersionData?.management_command"
                >
                </nb-icon>
              </div>
              <div style="white-space: pre-line">
                {{ selectedCommitMessages }}
              </div>
            </div>
            <div class="col">
              <div
                [ngClass]="
                  selectedGitVersionData?.management_command
                    ? 'sc-head5'
                    : 'sc-head5 pointer'
                "
                (click)="
                  selectedGitVersionData?.management_command
                    ? ''
                    : openTemplate(versionRef);
                  splitVersion();
                  newChangeLog = selectedCommitMessages
                "
              >
                Version<nb-icon
                  [icon]="'edit-outline'"
                  pack="eva"
                  *ngIf="!selectedGitVersionData?.management_command"
                >
                </nb-icon>
              </div>
              <div>
                {{ selectedGitVersionData?.version }}
              </div>
            </div>
            <div class="col-12 sc-mt-9">
              <div class="sc-head5">Once Run Management Commands</div>
              <div
                *ngIf="
                  perReleaseManagementCmdData?.length &&
                  !selectedGitVersionData?.management_command?.length
                "
              >
                <app-table
                  [desktopTableData]="perReleaseManagementCmdData"
                  [desktopTableDetails]="managementCmdTable"
                  (rowClick)="managementRowClick($event, false)"
                >
                </app-table>
              </div>

              <nb-alert
                *ngIf="
                  !perReleaseManagementCmdData?.length &&
                  selectedGitVersionData?.management_command
                "
              >
                <div>
                  {{ selectedGitVersionData?.management_command?.u_name }} ran
                  at
                  <span
                    *ngFor="
                      let time of selectedGitVersionData?.management_command
                        ?.run_time
                    "
                    >{{ getLocalTime(time?.time) }}</span
                  >
                </div>
              </nb-alert>
            </div>
          </div>
        </div>
      </nb-tab>
      <nb-tab tabTitle="{{ managementTabs.tab2 }}">
        <app-table
          [desktopTableData]="customManagementCmdData"
          [desktopTableDetails]="managementCmdTable"
          (rowClick)="managementRowClick($event)"
        >
        </app-table>
      </nb-tab>
    </nb-tabset>
  </nb-card-body>
</nb-card>

<!--change log messages component-->
<ng-template #changeLogRef let-data let-changeLogRef="dialogRef">
  <nb-card class="dialog-box">
    <nb-card-header class="sc-text-center">Change Log Messages </nb-card-header>

    <div class="row">
      <div class="col">
        <textarea
          class="form-control"
          placeholder="Change Log Messages"
          rows="8"
          fullWidth
          type="text"
          [(ngModel)]="newChangeLog"
        ></textarea>
      </div>
    </div>

    <div class="row row-cols-2 sc-mt-6">
      <div class="col">
        <button
          nbButton
          fullWidth
          size="giant"
          ghost
          (click)="changeLogRef.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          nbButton
          size="giant"
          fullWidth
          (click)="updateChangeLog(); changeLogRef.close()"
        >
          Update
        </button>
      </div>
    </div>

    <!-- Request Detail Section end-->
  </nb-card>
</ng-template>

<!--change version component-->
<ng-template #versionRef let-data let-versionRef="dialogRef">
  <nb-card class="dialog-box">
    <nb-card-header class="sc-text-center">Change Version </nb-card-header>
    <form [formGroup]="versionForm" class="gt-p-1">
      <div class="row row-cols-3">
        <div class="col form-floating">
          <input
            class="form-control"
            fullWidth
            type="number"
            nbInput
            placeholder="Major Version"
            formControlName="major"
          />
          <label>Major Version</label>
        </div>
        <div class="col form-floating">
          <input
            class="form-control"
            fullWidth
            type="number"
            nbInput
            placeholder="Minor Version"
            formControlName="minor"
          />
          <label>Minor Version</label>
        </div>
        <div class="col form-floating">
          <input
            class="form-control"
            fullWidth
            type="number"
            nbInput
            placeholder="Patches"
            formControlName="patch"
          />
          <label>Patches</label>
        </div>
      </div>

      <div class="row row-cols-2 sc-mt-6">
        <div class="col">
          <button
            nbButton
            fullWidth
            size="giant"
            ghost
            (click)="versionRef.close()"
          >
            Cancel
          </button>
        </div>

        <div class="col">
          <button
            [disabled]="!versionForm"
            nbButton
            size="giant"
            fullWidth
            (click)="updateVersion(); versionRef.close()"
          >
            Update
          </button>
        </div>
      </div>
    </form>

    <!-- Request Detail Section end-->
  </nb-card>
</ng-template>
